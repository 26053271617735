import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { LabelValidators } from '../label-validators';
import { Label } from '../label.model';
import { NgStyle, NgClass, AsyncPipe } from '@angular/common';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';

@Component({
  selector: 'bx-inline-label-creator',
  templateUrl: './inline-label-creator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LabelValidators],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgStyle, NgClass, FormErrorsComponent, AsyncPipe],
})
export class InlineLabelCreatorComponent extends CleanUp {
  @Output() readonly createLabel = new EventEmitter<Omit<Label, 'id'>>();

  private readonly colorControl = new FormControl('#f47b20', { initialValueIsDefault: true });
  private readonly nameControl = new FormControl(null, this.labelValidators.nameValidators());
  readonly form = new FormGroup({
    color: this.colorControl,
    name: this.nameControl,
  });
  readonly swatchColor$ = currentValueAndChanges(this.colorControl, this.ngUnsubscribe);
  readonly hasNameError$: Observable<boolean> = this.nameControl.statusChanges.pipe(
    map((status) => this.nameControl.dirty && status === 'INVALID'),
    takeUntil(this.ngUnsubscribe),
  );

  constructor(private readonly labelValidators: LabelValidators) {
    super();
  }

  create() {
    this.nameControl.updateValueAndValidity();
    if (!this.form.valid) {
      // Trigger nameError$ emission and display error
      this.nameControl.markAsDirty();
      this.nameControl.updateValueAndValidity();
      return;
    }
    this.createLabel.emit({
      color: this.colorControl.value,
      name: this.nameControl.value.trim(),
    });
    this.form.reset();
  }
}
