import { defer, NEVER, Observable, of as observableOf, OperatorFunction } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ActionsUnion,
  moveFiles,
  moveFilesFailure,
  moveFilesSuccess,
  removeFiles,
  removeFilesFailure,
  removeFilesSuccess,
  updateFileName,
  updateFileNameFailure,
  updateFileNameSuccess,
} from './files-store/files.actions';
import { GaalServerService } from '../../GaalServer.service';
import { FolderHttpV2Service } from '../../../../nucleus/v2/folder-http.v2.service';
import { select, Store } from '@ngrx/store';
import { State } from './files-table-state.model';
import { selectFileByID, selectFilesTableFolderID } from './files-table.selectors';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { hasGeneiousClass } from '../../document-selection-signature/document-selection-signature.model';
import { selectIsAuthenticatedVerifying } from '../../auth/auth.selectors';
import { clearFilesTableStateFiles } from './files-table.actions';
import { DataManagementService } from '@geneious/nucleus-api-client';

@Injectable()
export class FilesTableEffects {
  updateFileName$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateFileName.type),
      this.getDocumentByIDFromFilesTable(),
      // Stream into groups by file ID.
      // This allows it to debounce based on fileID.
      // e.g. multiple updateFileName events on the same file within the debounceTime will be debounced,
      // but multiple updateFileName events on different files within the debounceTime won't be debounced.
      groupBy((file) => file.action.payload.fileID),
      mergeMap((group) => group.pipe(debounceTime(1000))),
      mergeMap((file) => this.renameDocumentOnServer(file.action, file.documentClass)),
    ),
  );

  removeFiles$ = createEffect(() =>
    this.actions.pipe(
      ofType(removeFiles.type),
      withLatestFrom(this.store.pipe(select(selectFilesTableFolderID))),
      mergeMap(([action, folderID]) => {
        // TODO: Replace with dataManagementService.deleteFolderChildren once BX-7349 is implemented.
        return this.folderHttpV2Service
          .deleteChildren(folderID, action.payload.selectAll, action.payload.fileIDs)
          .pipe(
            map(() => removeFilesSuccess({ payload: { action: action } })),
            catchError(() => observableOf(removeFilesFailure({ payload: { action: action } }))),
          );
      }),
    ),
  );

  moveFiles$ = createEffect(() =>
    this.actions.pipe(
      ofType(moveFiles.type),
      withLatestFrom(this.store.pipe(select(selectFilesTableFolderID))),
      mergeMap(([action, folderID]) => {
        return this.dataManagementService
          .moveFolderChildren(folderID, {
            destinationFolder: action.payload.toFolderID,
            documentIDs: action.payload.fileIDs,
            selectAll: action.payload.selectAll,
          })
          .pipe(
            map(() => moveFilesSuccess({ payload: { action: action } })),
            catchError(() => observableOf(moveFilesFailure({ payload: { action: action } }))),
          );
      }),
    ),
  );

  error$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(updateFileNameFailure.type),
        mergeMap((action) => {
          if (action.payload.errorStatus === 409) {
            this.renameFailedDialogRef = this.dialogService.showAlertDialog({
              title: 'Rename Failed',
              content: `Couldn't rename document to '${action.payload.action.payload.name}' because the name was already edited`,
            });
          } else if (action.payload.errorStatus === 404) {
            this.renameFailedDialogRef = this.dialogService.showAlertDialog({
              title: 'Rename Failed',
              content: `Document doesn't exist`,
            });
          } else if (action.payload.errorStatus === 403) {
            this.renameFailedDialogRef = this.dialogService.showAlertDialog({
              title: 'Rename Failed',
              content: 'You do not have permission to rename this document',
            });
          } else {
            // If an unexpected error, then ask the user if they want to try the rename again.
            this.renameFailedDialogRef = this.dialogService.showAlertDialog({
              title: 'Rename Failed',
              content: 'Something went wrong renaming this document. Maybe try again.',
            });
          }
          // This Effect has dispatch false as it never returns an action, thus return NEVER.
          return NEVER;
        }),
        catchError(() => NEVER),
      ),
    { dispatch: false },
  );

  /**
   * Clear all files in the files table state whenever the selected folder has changed.
   * This avoids the possibility of the incorrect documents leaking into other files tables.
   */
  clearFilesOnSelectedFolderChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType('SELECT_FOLDER'),
      map(({ id }) => id),
      distinctUntilChanged(),
      map(() => clearFilesTableStateFiles()),
    ),
  );

  private renameFailedDialogRef: NgbModalRef;

  constructor(
    private store: Store<State>,
    private actions: Actions<ActionsUnion>,
    private gaalService: GaalServerService,
    private folderHttpV2Service: FolderHttpV2Service,
    private dataManagementService: DataManagementService,
    private dialogService: DialogService,
  ) {
    this.store
      .pipe(
        select(selectIsAuthenticatedVerifying),
        filter((authenticated) => !authenticated),
      )
      .subscribe(() => {
        if (this.renameFailedDialogRef) {
          this.renameFailedDialogRef.close();
        }
      });
  }

  private getDocumentByIDFromFilesTable(): OperatorFunction<
    ReturnType<typeof updateFileName>,
    { action: ReturnType<typeof updateFileName>; documentClass: string }
  > {
    return switchMap((action) => {
      return this.store.pipe(select(selectFileByID(action.payload.fileID))).pipe(
        first(),
        map((file) => ({ action, documentClass: file.metadata.documentClass })),
      );
    });
  }

  private renameDocumentOnServer(
    action: ReturnType<typeof updateFileName>,
    documentClass: string,
  ): Observable<ReturnType<typeof updateFileNameSuccess | typeof updateFileNameFailure>> {
    return defer(() => {
      if (
        hasGeneiousClass(
          documentClass,
          'com.biomatters.geneious.publicapi.documents.sequence.SequenceDocument',
        )
      ) {
        return this.gaalService.renameDocument(
          action.payload.fileID,
          action.payload.name,
          action.payload.blobRevision,
        );
      } else {
        return this.dataManagementService.updateDocument(
          action.payload.fileID,
          action.payload.blobRevision,
          {
            name: action.payload.name,
          },
        );
      }
    }).pipe(
      map((response) =>
        updateFileNameSuccess({
          payload: {
            action: action,
            newBlobRevision:
              'documentRevision' in response
                ? response.documentRevision
                : response.data.updatedDocument.revision,
          },
        }),
      ),
      catchError((error) => {
        return observableOf(
          updateFileNameFailure({
            payload: {
              action: action,
              errorStatus: error,
            },
          }),
        );
      }),
    );
  }
}
