import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, map, of } from 'rxjs';
import { DATABASE_TYPE, DatabaseTypeDisplayInfo, DatabaseTypeEnum } from '../database-type';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-database-type-control',
  templateUrl: './database-type-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatIconModule, AsyncPipe],
})
export class DatabaseTypeControlComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  @Input() control: AbstractControl;
  dbTypes$: Observable<DatabaseTypeDisplayInfo[]>;

  ngOnInit(): void {
    this.dbTypes$ = this.getDatabaseTypes();
    this.control.addValidators(this.mustBeValidDatabaseType);
  }

  private readonly mustBeValidDatabaseType: ValidatorFn = (control) =>
    !control.value || control.value === DatabaseTypeEnum.UNCLASSIFIED
      ? { invalidDatabaseType: true }
      : null;

  /**
   * Gets the database types available to the user. Scaffold will only be included if the user has
   * a scaffold feature enabled.
   * @returns the available database types in a list
   */
  private getDatabaseTypes(): Observable<DatabaseTypeDisplayInfo[]> {
    const defaultTypes = [
      DatabaseTypeEnum.GERMLINE,
      DatabaseTypeEnum.TEMPLATE,
      DatabaseTypeEnum.GENERAL_TEMPLATE,
      DatabaseTypeEnum.FEATURE,
      DatabaseTypeEnum.LINKER,
    ];
    return of(defaultTypes).pipe(
      map((databaseTypes) => databaseTypes.map((dbType) => DATABASE_TYPE[dbType])),
    );
  }
}
