import { Column } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  output,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { AppState } from '../../../core/core.store';
import {
  fetchUISetting,
  toggleGridSidebarHiddenState,
} from '../../../core/user-settings/ui-settings/ui-settings.actions';
import { selectUISettingsForComponent } from '../../../core/user-settings/ui-settings/ui-settings.selectors';
import { GridState } from '../grid.interfaces';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass, AsyncPipe } from '@angular/common';
import { GridSidebarProfilesManagementComponent } from './grid-sidebar-profiles-management/grid-sidebar-profiles-management.component';
import { ColumnManagementSidebarItemComponent } from '../column-management-sidebar-item/column-management-sidebar-item.component';

/**
 * Displays the Sidebar profiles and column management to the right of the grid components.
 */
@Component({
  selector: 'bx-grid-sidebar',
  templateUrl: './grid-sidebar.component.html',
  styleUrls: ['./grid-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FaIconComponent,
    NgClass,
    GridSidebarProfilesManagementComponent,
    ColumnManagementSidebarItemComponent,
    AsyncPipe,
  ],
})
export class GridSidebarComponent extends CleanUp implements OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex h-100 border-top rounded-0';

  @Input() targetComponent: string;
  /**
   * Grid-sidebars are commonly used in conjuction with either a grid/table OR sequence viewer. In the first case we pass in a list of
   * all columns the sidebar needs to know about. These are forwarded on to each `sidebar item` so these components can make use of them.
   */
  @Input() columns: Column[];
  /**
   * Grid-sidebars are commonly used in conjuction with either a grid/table OR sequence viewer. In the first case we pass in the gridState
   * the sidebar needs to know about. These are forwarded on to each `sidebar item` so these components can make use of them.
   */
  @Input() gridState: GridState;

  readonly columnsVisibilityChanged = output<ColumnsVisibilityChangedEvent>();
  readonly newGridState = output<NewGridStateEvent>();
  readonly resetGridState = output<ResetGridStateEvent>();
  readonly focusColumn = output<FocusColumnEvent>();

  // Whether the sidebar is shown or not.
  readonly hidden$ = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  readonly filteredColumns$ = this.completeOnDestroy(new BehaviorSubject<Column[]>([]));
  private userSettingSubscription = new Subscription();

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnChanges({ columns, targetComponent }: SimpleChanges) {
    if (targetComponent) {
      this.userSettingSubscription.unsubscribe();
      this.store.dispatch(fetchUISetting({ targetComponent: this.targetComponent }));
      this.userSettingSubscription = this.store
        .select(selectUISettingsForComponent(this.targetComponent))
        // Don't listen to updates; just get this at the start. Otherwise the round trip takes too long and the UI looks jerky.
        // If no setting has been used previously then default to hidden.
        .subscribe(({ sidebarHidden }) =>
          this.hidden$.next(typeof sidebarHidden === 'undefined' ? false : sidebarHidden),
        );
    }

    if (columns || targetComponent) {
      this.updateComponents(this.columns);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.userSettingSubscription.unsubscribe();
  }

  assetTargetComponentIsSet() {
    if (!this.targetComponent) {
      console.warn(`Target component is not set, profiles won't be enabled`);
    }
  }

  onApplyProfile(gridState: GridState) {
    this.newGridState.emit({
      event: 'NewGridState',
      gridState: gridState,
    });
  }

  toggleHidden() {
    this.hidden$.next(!this.hidden$.getValue());

    if (this.targetComponent) {
      this.store.dispatch(
        toggleGridSidebarHiddenState({
          targetComponent: this.targetComponent,
          hidden: this.hidden$.getValue(),
        }),
      );
    }
  }

  updateComponents(columns?: Column[]) {
    const filtered = columns ? GridSidebarComponent.filterColumns(columns) : [];
    this.filteredColumns$.next(filtered);
  }

  static filterColumns(columns: Column[]) {
    return columns.filter((column) => {
      const isGeneiousRowIndex = column.getColDef().field === 'geneious_row_index';
      return !isGeneiousRowIndex;
    });
  }
}

export interface ColumnsVisibilityChangedEvent {
  event: 'ColumnsVisibilityChanged';
  visibilityChanged: { visible?: string[]; hidden?: string[] };
}

export interface NewGridStateEvent {
  event: 'NewGridState';
  gridState: GridState;
}

export interface ResetGridStateEvent {
  event: 'ResetGridState';
}

export interface FocusColumnEvent {
  event: 'FocusColumnEvent';
  colId: string;
}
