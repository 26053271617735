import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccessLevel } from '../../permissions/access-level.interface';

@Component({
  selector: 'bx-access-level-option',
  templateUrl: './access-level-option.component.html',
  styleUrls: ['./access-level-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AccessLevelOptionComponent {
  @Input() accessLevel: AccessLevel;
}
