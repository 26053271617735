<div class="modal-header">
  <h4 class="modal-title d-flex align-items-center" data-spec="update-ref-db-modal-title">
    Reclassify Database
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069531"
      target="_blank"
      rel="noopener noreferrer"
      class="ms-2 d-flex align-items-center text-decoration-none"
    >
      <mat-icon>info</mat-icon>
    </a>
  </h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="close('canceled')"
    data-spec="update-ref-db-close-btn"
  ></button>
</div>

<div class="modal-body">
  <form class="px-3">
    <bx-database-type-control
      [control]="databaseTypeControl"
      containerSize="sm"
    ></bx-database-type-control>
  </form>

  @if ((submitState$ | async) === 'error') {
    <span class="text-danger" data-spec="update-ref-db-error"
      >An error occurred while updating the database type. Please try again.
    </span>
  }
</div>

@if (submitState$ | async; as submitState) {
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      ngbAutofocus
      bx-spinner-button
      [spin]="submitState === 'submitting'"
      [disabled]="submitState === 'disabled' || submitState === 'submitting'"
      (click)="submit()"
      data-spec="update-ref-db-submit-btn"
    >
      Update
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="close('canceled')"
      data-spec="update-ref-db-cancel-btn"
    >
      Close
    </button>
  </div>
}
