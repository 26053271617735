import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feedback } from './models/feedback.model';
import { APP_CONFIG, AppConfig } from '../../app/app.config';

@Injectable({
  providedIn: 'root',
})
export class FeedbackHttpV2NucleusService {
  private readonly endpoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient,
  ) {
    this.endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/feedback`;
  }

  submitFeedback(feedback: Feedback): Observable<SubmitFeedbackResponse> {
    return this.httpClient.post<SubmitFeedbackResponse>(this.endpoint, feedback);
  }
}

export interface FeedbackResponse extends Feedback {
  id: string;
  createdAt: string;
  zendeskReference?: number;
}

export interface SubmitFeedbackResponse {
  data: FeedbackResponse;
}
