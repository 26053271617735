<label class="form-label mb-0 w-100 d-inline-flex justify-content-between">
  <small class="me-1 overflow-hidden text-nowrap text-truncate text-muted fw-bold">
    {{ name }}
  </small>
  <input
    class="float-end"
    [ngbTooltip]="tooltip"
    placement="top"
    container="body"
    [disabled]="isIdColumn"
    type="checkbox"
    [checked]="include"
    (change)="checkboxClicked($event)"
  />
</label>
