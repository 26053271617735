import {
  Tooltip,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from 'highcharts';
import { roundForGraph } from '../../../shared/utils/rounding';

export const groupedColumnChartTooltipFormatter: TooltipFormatterCallbackFunction = function (
  tooltip: Tooltip,
) {
  const { point, series } = this as TooltipFormatterContextObject;
  const yAxisLabel = this.series.yAxis.options.title.text;
  // The y axis label for Antibody Annotator data contains a '%' symbol if it's in percentage units.
  const isPercentage = yAxisLabel.includes('%');
  const header = `<b>${series.xAxis.options.title.text}: ${
    point.options.name || point.options.x
  }</b> (3 s.f.)</br>`;

  const pointsInStack = series.chart.series
    // TODO: remove @ts-ignore when we update highcharts typing
    // @ts-ignore
    .filter((s) => s.xData.includes(point.category))
    .map((s) => s.points.find((p) => p.category === point.category));

  const currentSectionName = series.name;
  const body = pointsInStack
    .map((p) => {
      const content = isPercentage ? `${roundForGraph(p.y)}%` : `${roundForGraph(p.y)}`;
      return currentSectionName === p.series.name
        ? `<strong>${p.series.name}: ${content}</strong>`
        : `${p.series.name}: ${content}`;
    })
    .join(`</br>`);
  return header + body;
};
