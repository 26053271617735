import { Injectable } from '@angular/core';
import {
  DataManagementService,
  DocumentHistoryData,
  DocumentHistoryDataEventType,
  JobService,
} from '@geneious/nucleus-api-client';
import { forkJoin, Observable, of } from 'rxjs';
import { Job } from '../../../nucleus/services/models/job.model';
import { filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectOrganizationID } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class JobHistoryService {
  constructor(
    private jobService: JobService,
    private dataManagementService: DataManagementService,
    private store: Store<AppState>,
  ) {}

  getCreationJobForDocument(documentID: string): Observable<Job | undefined> {
    return this.dataManagementService.getHistory(documentID).pipe(
      map((historyItems) =>
        historyItems.data
          .filter((historyItem) => !!historyItem.jobID)
          .find((item) => item.eventType === DocumentHistoryDataEventType.Created),
      ),
      switchMap((historyItem) =>
        historyItem ? this.getJobFromHistory(historyItem) : of(undefined),
      ),
    );
  }

  /**
   * Get the list of jobs that created or modified the document
   *
   * @param documentID The ID of the document to obtain the jobs.
   * @returns An observable of the list of Jobs that created or modified the document
   */
  getJobsForDocument(documentID: string): Observable<Job[]> {
    return this.dataManagementService.getHistory(documentID).pipe(
      filter(
        (historyItems) => historyItems.data.filter((historyItem) => !!historyItem.jobID).length > 0,
      ),
      switchMap((historyItems) =>
        forkJoin(historyItems.data.map((historyItem) => this.getJobFromHistory(historyItem))),
      ),
    );
  }

  private getJobFromHistory(history: DocumentHistoryData): Observable<Job> {
    return this.store.pipe(
      select(selectOrganizationID),
      take(1),
      switchMap((organizationID) =>
        this.jobService.getOrganizationJob(organizationID, history.jobID),
      ),
      pluck('data'),
    );
  }
}
