import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CleanUp } from '../../../shared/cleanup';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { DocumentTableStateService } from '../document-table-state/document-table-state.service';
import { combineLatest, concat, NEVER, Observable, of, ReplaySubject } from 'rxjs';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import { sortAntibodyRegionByName } from '../../../shared/sort.util';
import { SelectComponent } from '../../../shared/select/select.component';
import { AsyncPipe } from '@angular/common';

interface TableGroup {
  name: string;
  tables: DocumentTable[];
}

@Component({
  selector: 'bx-document-table-picker',
  templateUrl: './document-table-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SelectComponent, FormsModule, ReactiveFormsModule, AsyncPipe],
})
export class DocumentTablePickerComponent extends CleanUp implements OnInit, OnChanges, OnDestroy {
  @Input() documentID: string;
  @Input() selectedTable: DocumentTable;
  @Output() selectedTableChanged = new EventEmitter<DocumentTable>();

  allTables$: Observable<DocumentTable[]>;

  readonly noRegionGroup = 'Other';
  readonly combinationRegionGroup = 'Combination Regions';
  readonly lengthsGroup = 'Lengths';
  allTablesGrouped$: Observable<TableGroup[]>;
  allTableOptions$: Observable<SelectGroup[]>;

  tablesLoading$: Observable<boolean>;
  readonly tableSelectionControl = new FormControl<string>(undefined);

  private documentID$ = new ReplaySubject<string>(1);

  constructor(private documentTableStateService: DocumentTableStateService) {
    super();
  }

  ngOnChanges({ documentID, selectedTable }: SimpleChanges) {
    if (documentID && documentID.currentValue) {
      this.documentID$.next(documentID.currentValue);
    }
    if (selectedTable && selectedTable.currentValue) {
      this.tableSelectionControl.setValue((selectedTable.currentValue as DocumentTable).name);
    }
  }

  ngOnInit() {
    this.allTables$ = this.documentID$.pipe(
      switchMap((documentID) =>
        concat(of([]), this.documentTableStateService.getTables(documentID)).pipe(
          // Ignore errors.
          catchError(() => NEVER),
        ),
      ),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.allTablesGrouped$ = this.allTables$.pipe(map((tables) => this.groupTables(tables)));

    this.allTableOptions$ = this.allTablesGrouped$.pipe(
      distinctUntilChanged((i1, i2) => this.isEqual(i1, i2)),
      map((tableGroups) =>
        tableGroups.map(
          (group) =>
            new SelectGroup(
              group.tables.map(
                (table) =>
                  new SelectOption(
                    this.removeGroupNameFromItem(table.displayName, group.name),
                    table.name,
                  ),
              ),
              group.name,
            ),
        ),
      ),
      map((optionGroups) => {
        const otherGroupIndex = optionGroups.findIndex(({ label }) => label === this.noRegionGroup);
        if (otherGroupIndex !== -1) {
          delete optionGroups[otherGroupIndex].label;
        }
        return optionGroups;
      }),
    );

    this.tablesLoading$ = this.documentID$.pipe(
      switchMap((documentID) => this.documentTableStateService.getTablesFetchingState(documentID)),
      map((fetchingState) => fetchingState.fetching),
    );

    combineLatest([this.tablesLoading$, this.allTables$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([tablesLoading, tables]) => {
        if (tablesLoading || tables.length === 0) {
          this.tableSelectionControl.disable({ emitEvent: false });
        } else {
          this.tableSelectionControl.enable({ emitEvent: false });
        }
      });

    this.tableSelectionControl.valueChanges
      .pipe(withLatestFrom(this.allTables$), takeUntil(this.ngUnsubscribe))
      .subscribe(([selectedTable, tables]) => {
        this.selectedTableChanged.emit(tables.find((table) => table.name === selectedTable));
      });

    this.allTables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((tables) => {
      if (tables.length > 0 && !this.tableSelectionControl.value) {
        this.tableSelectionControl.reset(tables[0].name);
      } else if (tables.length === 0) {
        this.tableSelectionControl.reset();
      }
    });
  }

  /**
   * Produces a map of group name to document tables for the provided document tables. The grouping is done with each
   * table's first clusterGroup's first regionOrGene as a grouping key with other tables. This may not be a great
   * assumption and could need more thinking put into it if this method becomes more used in the future.
   *
   *
   * @param tables
   * @private
   */
  private groupTables(tables: DocumentTable[]): TableGroup[] {
    const map = tables.reduce((accumulator, currentValue) => {
      const chainNames = [
        ...new Set(
          currentValue.metadata?.clusters?.clusterGroups.flatMap((clusterGroup) =>
            clusterGroup.regionOrGenes.map((rorg) => rorg.chainName),
          ),
        ),
      ];
      const chains = [
        ...new Set(
          currentValue.metadata?.clusters?.clusterGroups.flatMap((clusterGroup) =>
            clusterGroup.regionOrGenes.map((rorg) => rorg.chain),
          ),
        ),
      ];
      let groupName = this.noRegionGroup;
      if (currentValue.metadata?.tableContentType === 'ComparisonLengths') {
        groupName = this.lengthsGroup;
      } else if (chainNames.length > 1 || chains.length > 1) {
        groupName = this.combinationRegionGroup;
      } else if (chainNames.length === 1 && chainNames[0] != null) {
        groupName = chainNames[0] + ' Regions';
      } else if (chains.length === 1 && chains[0] != null) {
        groupName = chains[0] + ' Regions'; // Fall back to chain when chainName is null
      }

      if (accumulator.has(groupName)) {
        accumulator.get(groupName).push(currentValue);
      } else {
        accumulator.set(groupName, [currentValue]);
      }
      return accumulator;
    }, new Map<string, DocumentTable[]>());

    // Turn our map into a list to make it easy to iterate over.
    const groups: TableGroup[] = [];
    map.forEach((value, key) => {
      groups.push({
        name: key,
        tables: value.sort((a, b) => sortAntibodyRegionByName(a.displayName, b.displayName)),
      });
    });

    // Sort the list in a custom order defined by PM.
    // TODO: this implementation is pretty hacky.
    return groups.sort((g1, g2) => {
      // No chain tables
      // ... other tables sorted alphabetically
      // Combination tables
      // Length tables
      if (g1.name === this.noRegionGroup || g2.name === this.lengthsGroup) {
        return -1000000;
      } else if (g2.name === this.noRegionGroup || g1.name === this.lengthsGroup) {
        return 1000000;
      } else if (g1.name === this.combinationRegionGroup) {
        return 99999;
      } else if (g2.name === this.combinationRegionGroup) {
        return -99999;
      }
      return g1.name.localeCompare(g2.name);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.documentID$.complete();
  }

  removeGroupNameFromItem(displayName: string, groupName: string) {
    // Eg if the group name is 'Heavy-1 Regions', then we can remove 'Heavy-1:' prefix from any items.
    const prefix =
      (groupName.endsWith(' Regions')
        ? groupName.substring(0, groupName.length - ' Regions'.length)
        : groupName) + ': ';
    return displayName.startsWith(prefix) ? displayName.substring(prefix.length) : displayName;
  }

  isEqual(items1: TableGroup[], items2: TableGroup[]) {
    if (items1.length !== items2.length) {
      return false;
    }

    for (let i = 0; i < items1.length; i++) {
      const tables1 = items1[i].tables;
      const tables2 = items2[i].tables;
      if (tables1.length !== tables2.length) {
        return false;
      }
      for (let j = 0; j < tables1.length; j++) {
        if (tables1[j].name !== tables2[j].name) {
          return false;
        }
      }
    }

    return true;
  }
}
