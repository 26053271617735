<form [formGroup]="form">
  <bx-card headerTitle="Rename Method">
    <div class="mb-3 row">
      <label for="aspectToRename" class="col-sm-4 col-lg-3 col-form-label">
        Aspect to rename:
      </label>
      <div class="col">
        <select formControlName="selectedRenameMethod" id="aspectToRename" class="form-select">
          <option value="renamableOption2">Fields of Document</option>
          <option value="renamableOption0" [disabled]="!isSequenceList">
            Sequences in Sequence List
          </option>
        </select>
      </div>
    </div>

    <div class="row mb-0">
      <label for="propertyToRename" class="col-sm-4 col-lg-3 col-form-label">
        Property to rename:
      </label>
      <div class="col">
        <select formControlName="attributeProcedure" id="propertyToRename" class="form-select">
          <option value="nameProcedure">Name of Sequence</option>
          <!-- TODO Enable description when we can figure out how to make it work -->
          <!--<option value="descriptionProcedure">Description of Sequence</option>-->
          <!-- TODO Molecule type, not sure what the option is for that. -->
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card>
    <div bxCardHeader class="form-check">
      <input
        type="radio"
        id="advancedCheckboxFalse"
        [value]="false"
        formControlName="advancedCheckbox"
        class="form-check-input"
      />
      <label for="advancedCheckboxFalse" class="form-check-label">Basic</label>
    </div>

    <div formGroupName="basicOptions">
      <div class="mb-3 row">
        <div class="col-2 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="radio"
              id="appendRadioButton"
              value="appendRadioButton"
              formControlName="task"
              name="task"
              class="form-check-input"
            />
            <label for="appendRadioButton" class="form-check-label">Add:</label>
          </div>
        </div>
        <div class="col-sm-4 col-lg-5">
          <input
            type="text"
            id="addString"
            formControlName="appendTextField"
            name="addString"
            class="form-control"
          />
        </div>
        <span class="col-sm-4 col-lg-3 col-form-label ps-2">to:</span>
        <div class="col-2">
          <select formControlName="appendPositionComboBox" class="form-select">
            <option value="endValue">end</option>
            <option value="startValue">start</option>
          </select>
        </div>
      </div>

      <div class="row mb-0">
        <div class="col-2 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <!-- NOTE That the typo in "removeRremoveRadioButtonadioButton" is intentional and required. -->
            <input
              type="radio"
              id="removeRremoveRadioButtonadioButton"
              value="removeRremoveRadioButtonadioButton"
              formControlName="task"
              name="task"
              class="form-check-input"
            />
            <label for="removeRremoveRadioButtonadioButton" class="form-check-label">Remove:</label>
          </div>
        </div>
        <div class="col-sm-4 col-lg-5">
          <input
            type="number"
            formControlName="removeHowMuchIntegerOption"
            min="1"
            class="form-control"
          />
        </div>
        <div class="col-sm-4 col-lg-3 col-form-label ps-2">character(s) from:</div>
        <div class="col-2">
          <select formControlName="removePositionComboBox" class="form-select">
            <option value="endValue">end</option>
            <option value="startValue">start</option>
          </select>
        </div>
      </div>
    </div>
  </bx-card>

  <bx-card class="mb-0">
    <div bxCardHeader class="form-check">
      <input
        type="radio"
        id="advancedCheckboxTrue"
        formControlName="advancedCheckbox"
        class="form-check-input"
        [value]="true"
      />
      <label for="advancedCheckboxTrue" class="form-check-label">Advanced</label>
    </div>

    <div formGroupName="advancedOptions">
      <div class="mb-3 form-check">
        <input
          type="radio"
          id="entireFieldOption"
          value="entireFieldOption"
          formControlName="replaceWhatTypeRadioOption"
          name="replaceWhatTypeRadioOption"
          class="form-check-input"
        />
        <label for="entireFieldOption" class="form-check-label">Replace entire field</label>
      </div>

      <div class="mb-0">
        <div class="row mb-2">
          <div class="col-sm-6 col-lg-4 d-flex align-items-center">
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="matchesOption"
                value="matchesOption"
                formControlName="replaceWhatTypeRadioOption"
                name="replaceWhatTypeRadioOption"
                class="form-check-input"
              />
              <label for="matchesOption" class="form-check-label">Replace parts matching:</label>
            </div>
          </div>
          <div class="col-6">
            <input
              type="text"
              formControlName="replaceWhatTextField"
              class="form-control"
              title="Text to match"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-6 col-lg-4"></div>
          <div class="col-6 d-flex align-items-center">
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="ignoreCaseCheckbox"
                formControlName="ignoreCaseCheckbox"
                name="ignoreCaseCheckbox"
                class="form-check-input"
              />
              <label for="ignoreCaseCheckbox" class="form-check-label">Ignore case</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="isRegexCheckbox"
                formControlName="isRegexCheckbox"
                name="isRegexCheckbox"
                class="form-check-input"
              />
              <label for="isRegexCheckbox" class="form-check-label">Regex</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-lg-4 col-form-label text-end pe-3">
            <label class="form-label" for="replaceWithTextField">With:</label>
          </div>
          <div class="col-6">
            <input
              type="text"
              id="replaceWithTextField"
              formControlName="replaceWithTextField"
              name="replaceWithTextField"
              class="form-control"
              title="The text to insert"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
  </bx-card>
</form>
