@if ({ searchOpened: searchOpened$ | async }; as state) {
  <nav
    class="navbar navbar-light flex-nowrap"
    [ngClass]="{ 'bg-nav': !state.searchOpened, 'bg-brand shadow': state.searchOpened }"
  >
    <div class="container-fluid p-0">
      <a class="navbar-brand" routerLink="/getting-started">
        <img
          id="home-button"
          src="../../../../assets/branding/geneious-small.svg"
          alt="Home"
          height="20px"
          width="20px"
        />
      </a>
      <ng-content></ng-content>
    </div>
  </nav>
}
