import { Injectable } from '@angular/core';
import { v4 as UUID } from 'uuid';
import { GaalServerStore } from './GaalServer.store';
import { Observable } from 'rxjs';
import {
  EditSequenceResponse,
  GaalServerHttpService,
  RenameDocumentResponse,
} from './GaalServerHttp.service';
import { EditedSequences } from '../features/sequence-viewer-angular/sequence-editing-controls/sequence-editing-controls.component';
import Annotation from '@geneious/sequence-viewer/plugins/Annotations/Cache/Annotation';
import { SequenceData } from '@geneious/sequence-viewer/types';

export interface AnnotationRecord {
  annotationId: string;
  documentId: string;
  annotation: Annotation;
  // Index of sequence in the list of all sequences from ALL selected documents combined.
  sequenceIndex: number;
  // Index of sequence in the document it's in.
  sequenceIndexInDocument: number;
}

export interface AnnotationRecordAndIndex extends AnnotationRecord {
  annotationIndex: number;
}

export interface DocumentRecord {
  documentId: string;
  sequenceIndex: number;
  sequenceIndexInDocument: number;
}

@Injectable({
  providedIn: 'root',
})
export class GaalServerService {
  constructor(
    private gaalServerHttpService: GaalServerHttpService,
    private gaalServerStoreService: GaalServerStore,
  ) {}

  clearMap(): void {
    this.gaalServerStoreService.clear();
  }

  buildMap(documents: SequenceData[]): SequenceData[] {
    // TODO Make a new service (from this one) for each session, rather than storing it in here which anyone can access at any time.
    const { modified, annotationRecords, documentRecords } = loadAnnotations(documents);
    this.gaalServerStoreService.loadAnnotations(annotationRecords, documentRecords);
    console.log('modified', modified);
    // Have to send ids through to sequence viewer.
    return modified;
  }

  addAnnotation(sequenceIndex: number, annotation: any) {
    return this.gaalServerStoreService.addAnnotation(sequenceIndex, annotation);
  }

  editAnnotation(annotation: any) {
    return this.gaalServerStoreService.editAnnotation(annotation);
  }

  removeAnnotation(annotationId: string) {
    return this.gaalServerStoreService.removeAnnotation(annotationId);
  }

  editSequence(
    index: number,
    body: { documentRevision: number; sequence: string },
    id: string,
  ): Observable<Object> {
    return this.gaalServerHttpService.editSequence(index, body, id);
  }

  editSequencesInPlace(
    documentID: string,
    documentRevision: number,
    editedSequences: EditedSequences,
  ): Observable<EditSequenceResponse> {
    return this.gaalServerHttpService.editSequences(documentID, documentRevision, editedSequences);
  }

  renameDocument(
    id: string,
    name: string,
    blobRevision: number,
  ): Observable<RenameDocumentResponse> {
    return this.gaalServerHttpService.renameDocument(id, name, blobRevision);
  }
}

function loadAnnotations(sequences: any[]) {
  const annotationRecords: any[] = [];
  const documentRecords: any[] = [];

  const modified = sequences.map((document: any, index: number) => {
    // SV sequences seem to be 1 indexed.
    const sequenceIndex = index + 1;
    documentRecords.push({
      // Id of document this sequence is in.
      documentId: document.documentId,
      // Index of sequence in the list of all sequences from ALL selected documents combined.
      sequenceIndex,
      // Index of sequence in the document it's in.
      sequenceIndexInDocument: document.sequenceIndexInDocument,
    });

    document.annotations = document.annotations.map((annotation: any) => {
      annotation.id = UUID();
      // Be aware the order the annotations are added to list list is relied upon by the update architecture
      // and therefore very important.
      annotationRecords.push({
        annotationId: annotation.id as string,
        documentId: document.documentId as string,
        annotation,
        // Index of sequence in the list of all sequences from ALL selected documents combined.
        sequenceIndex,
        // Index of sequence in the document it's in.
        sequenceIndexInDocument: document.sequenceIndexInDocument,
      });

      return annotation;
    });
    return document;
  });

  return { modified, annotationRecords, documentRecords };
}
