import { FilePickerService } from './file-picker.service';
import { FolderService } from '../../core/folders/folder.service';
import { Observable, Subscription } from 'rxjs';
import { TreeItem } from '../../core/folders/models/folder.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { first, map, switchMap } from 'rxjs/operators';
import { Item } from '../../../nucleus/v2/models/item.v2.model';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core/core.store';
import { selectSharedWorkspaceID } from '../../core/auth/auth.selectors';
import { AsyncPipe } from '@angular/common';
import { FilePickerTreeComponent } from './file-picker-tree/file-picker-tree.component';

@Component({
  selector: 'bx-file-picker',
  templateUrl: './file-picker.component.html',
  providers: [FilePickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FilePickerTreeComponent, AsyncPipe],
})
export class FilePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileTypes: string[];
  @Input() filter: (file: Item) => boolean;
  @Input() control: FormControl<Item[]>;
  @Input() noFilesSelectedMessage: string;
  @Output() filesSelected = new EventEmitter<Item[]>();

  folders$: Observable<TreeItem[]>;
  totalSelected$: Observable<number>;
  subscriptions = new Subscription();

  constructor(
    private store: Store<AppState>,
    private folderService: FolderService,
    private filePickerService: FilePickerService,
  ) {}

  ngOnInit() {
    this.folders$ = this.store.pipe(
      select(selectSharedWorkspaceID),
      first(),
      switchMap((selectSharedWorkspaceID) => this.folderService.get(selectSharedWorkspaceID)),
      switchMap((folder) => this.folderService.getChildren(folder.id)),
      first(),
    );

    this.totalSelected$ = this.filePickerService.selectedChanged$.pipe(
      map((selected) => selected.length),
    );

    this.subscriptions.add(
      this.filePickerService.selectedChanged$.subscribe((sequences) => {
        this.control.setValue(sequences);
        this.filesSelected.emit(sequences);
      }),
    );
  }

  ngOnChanges() {
    this.filePickerService.reset();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
