<form [formGroup]="form">
  <p class="px-2 text-muted">Annotate nucleotide or protein VDJ and VJ regions in one easy step.</p>

  @if (exceedAllowedSequenceCount) {
    <div class="alert alert-danger" data-testid="exceed-allowed-sequence-count-message">
      <p class="my-0">
        {{ exceedAllowedSequenceCountMessage }}
      </p>
    </div>
  }

  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label
        for="database_customDatabase"
        class="col-4 col-form-label"
        ngbTooltip="The database(s) selected will be used as the references for identifying and annotating query sequences."
      >
        Reference database(s):
      </label>
      <div class="col-8">
        <bx-multi-select
          id="database_customDatabase"
          formControlName="database_customDatabase"
          [selectOptions]="referenceDatabases$ | async"
          emptyMessage="No reference databases are available"
          entityName="reference database"
          [showMultiple]="multipleRefDbsEnabled$ | async"
        >
        </bx-multi-select>
        <bx-form-errors [control]="form.get('database_customDatabase')"></bx-form-errors>
      </div>
    </div>

    <div class="mb-3 row">
      <label
        for="chain"
        class="col-4 col-form-label"
        ngbTooltip="The expected chain type(s) present in query sequences. The selected option allows Antibody Annotator to apply the appropriate part of the database to increase accuracy."
      >
        Selected sequences are:
      </label>
      <div class="col-8">
        <select id="chain" formControlName="sequences_chain" class="form-select">
          <option value="singleUnknownChain">Single chain (either heavy or light)</option>
          <option value="lightChain">Single chain (light)</option>
          <option value="heavyChain">Single chain (heavy)</option>
          <option value="bothChainsNoLinker">Both chains in single sequence</option>
          <option value="bothChainsNoLinkerOppositeDirections">
            Both chains in single sequence (opposite directions)
          </option>
          <option value="bothChainsWithLinker">
            Both chains in single sequence with linker (scFv)
          </option>
          @if (twoHeavyChainsEnabled$ | async) {
            <option value="twoHeavyChainsNoLinker">Two heavy chains in single sequence</option>
            <option value="twoHeavyChainsWithLinker">
              Two heavy chains in single sequence with linker
            </option>
          }
        </select>
      </div>
    </div>

    <div class="row mb-0">
      <label
        for="sequences_annotationStyle"
        class="col-4 col-form-label"
        ngbTooltip="Antibody numbering scheme will be used to define CDR boundaries"
      >
        Antibody numbering:
      </label>
      <div class="col-4">
        <select
          id="sequences_annotationStyle"
          formControlName="sequences_annotationStyle"
          class="form-select"
        >
          @for (style of annotationStyleOptions; track style) {
            <option [value]="style.value">
              {{ style.label }}
            </option>
          }
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
