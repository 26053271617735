@if (showTotalSelected) {
  <bx-total-selected
    [totalSelected]="noOfRowsSelected"
    [totalRows]="totalNoOfRows"
    [resultSetMax]="resultSetMax"
    [additionalMessage]="additionMessage"
  >
  </bx-total-selected>
}
@if (!columnsReady) {
  <bx-page-message class="loading-message">
    <div class="d-flex align-items-center flex-nowrap">
      <bx-spinner class="me-2"></bx-spinner>
      @if (showTimer) {
        <span>{{ loadingMessage$ | async }}</span>
      } @else {
        <span>Loading&#8230;</span>
      }
    </div>
  </bx-page-message>
}
@if (columnsReady) {
  <div class="d-flex h-100 overflow-hidden" data-testid="bx-grid-ready">
    <ag-grid-angular
      class="ag-theme-balham flex-grow-1 w-100 overflow-hidden"
      [gridOptions]="gridOptions"
      [tooltipShowDelay]="500"
      (gridReady)="onReady()"
      (cellClicked)="onCellClicked($event)"
      (rowDoubleClicked)="rowDoubleClicked.emit($event.data)"
      (selectionChanged)="onSelectionChanged()"
      (dragStopped)="onDragStopped()"
      (columnPinned)="onColumnPinned()"
      (columnVisible)="onColumnVisible()"
      (columnResized)="onColumnResized()"
      (sortChanged)="onSortChanged()"
      (newColumnsLoaded)="onNewColumnsLoaded()"
      (displayedColumnsChanged)="onDisplayedColumnsChanged()"
    >
    </ag-grid-angular>
    <bx-grid-sidebar
      [columns]="columnsChanged | async"
      [gridState]="gridState$ | async"
      [targetComponent]="profileComponent"
      (columnsVisibilityChanged)="onColumnsVisibilityChanged($event)"
      (newGridState)="setGridState($event.gridState)"
      (resetGridState)="resetToGridStateDefault()"
      (focusColumn)="focusColumn($event.colId)"
    ></bx-grid-sidebar>
  </div>
}
