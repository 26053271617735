@if (jobData$ | async; as data) {
  @if (data.result !== null) {
    <a
      [routerLink]="data.result.link"
      [queryParams]="data.result.queryParams"
      oncontextmenu="event.stopPropagation();"
      >{{ data.result.name }}
    </a>
  }
  @if (data.download !== null) {
    <a
      target="_self"
      onclick="event.stopPropagation();"
      oncontextmenu="event.stopPropagation();"
      [href]="data.download"
      class="badge bg-primary"
      download
      >Download</a
    >
  }
  <!-- Not all jobs must have a result, if they are in progress or failed.-->
  @if (data.download === null && data.result === null) {
    <span class="text-muted">
      {{ placeholder }}
    </span>
  }
}
