import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { faChevronRight, faEllipsisV, faFolder } from '@fortawesome/free-solid-svg-icons';
import {
  NgbModalRef,
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { CleanUp } from 'src/app/shared/cleanup';
import { SelectionState } from '../../features/grid/grid.component';
import { DialogService } from '../../shared/dialog/dialog.service';
import { CreateReferenceDbDialogComponent } from '../blast/create-reference-db-dialog/create-reference-db-dialog.component';
import { DatabaseRootTypeEnum } from '../blast/database-root-type';
import { UpdateReferenceDbFormComponent } from '../blast/update-reference-db-dialog/update-reference-db-dialog.component';
import { AppState } from '../core.store';
import { FolderInfoComponent } from '../folder-info/folder-info.component';
import { FolderService } from '../folders/folder.service';
import {
  ActionFolderKindParam,
  DatabaseFolder,
  DatabaseRootFolder,
  Folder,
  FolderActions,
  FolderTreeItem,
  NonFolderTreeItem,
  TreeItem,
} from '../folders/models/folder.model';
import { NewFolderState } from '../folders/store/expand.reducer';
import { ExpandService } from '../folders/store/expand.service';
import { folderIsSelected } from '../folders/store/folder.selectors';
import { ManageSharingDialogComponent } from '../manage-sharing/manage-sharing-dialog/manage-sharing-dialog.component';
import { CreateCollectionsFolderFormComponent } from '../master-database/create-collections-folder-form/create-collections-folder-form.component';
import { MasterDatabaseFormDialogComponent } from '../master-database/master-database-form-dialog/master-database-form-dialog.component';
import { NavigationService } from '../navigation/navigation.service';
import { PipelineDialogService } from '../pipeline-dialogs/pipeline-dialog.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { FolderMovingDirective } from './folder-moving/folder-moving.directive';
import { UploadDropDirective } from '../upload/upload-drop.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FolderTreeIconComponent } from './folder-tree-icon/folder-tree-icon.component';
import { FolderTreeNameComponent } from './folder-tree-name/folder-tree-name.component';
import { SingleClickDirective } from '../../shared/click-handlers/single-click.directive';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { NewFolderComponent } from './new-folder/new-folder.component';

@Component({
  selector: 'bx-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    FolderMovingDirective,
    UploadDropDirective,
    FaIconComponent,
    FolderTreeIconComponent,
    FolderTreeNameComponent,
    SingleClickDirective,
    MatIconModule,
    SpinnerComponent,
    NgbDropdown,
    NgbDropdownAnchor,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NewFolderComponent,
    AsyncPipe,
  ],
})
export class FolderTreeComponent
  extends CleanUp
  implements OnInit, OnChanges, OnDestroy, FolderActions
{
  @HostBinding('class') readonly hostClass = 'd-block';
  isSaving$: BehaviorSubject<boolean> = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  isMoving$: Observable<boolean>;
  expanded$: Observable<boolean>;
  selected$: Observable<boolean>;
  createChild$: Observable<boolean>;
  newFolder$: Observable<NewFolderState>;
  children$: Observable<TreeItem[]>;
  menuOpen = false;
  path: string;
  readonly icons = {
    toggleExpand: faChevronRight,
    folder: faFolder,
    more: faEllipsisV,
  };

  @Input() folder: TreeItem;
  @Input() openByDefault = false;
  @Input() parentPath = '';

  private manageSharingDialogRef: MatDialogRef<ManageSharingDialogComponent>;
  private confirmationDialogRef: NgbModalRef;
  private infoDialogReference: NgbModalRef;
  private createDatabaseModalRef: NgbModalRef;

  get secondaryIcon(): string | undefined {
    return this.folder instanceof FolderTreeItem ? this.folder.secondaryIcon : undefined;
  }

  constructor(
    private store: Store<AppState>,
    private folderService: FolderService,
    private expandService: ExpandService,
    protected dialog: MatDialog,
    private dialogService: DialogService,
    private pipelineDialogService: PipelineDialogService,
    private navigationService: NavigationService,
  ) {
    super();
  }

  ngOnInit() {
    this.path = `${this.parentPath}/${this.folder.name}`;

    if (this.openByDefault) {
      this.expandService.expand(this.folder.id);
    }

    this.children$ =
      this.folder instanceof NonFolderTreeItem
        ? of(this.folder.children)
        : this.folderService
            .getChildren(this.folder.id)
            .pipe(map((folders) => this.sortDatabaseFoldersIntoGroups(folders)));
    this.selected$ = this.store
      .select(folderIsSelected(this.folder.id))
      .pipe(takeUntil(this.ngUnsubscribe));
    this.expanded$ = this.expandService
      .isExpanded(this.folder.id)
      .pipe(takeUntil(this.ngUnsubscribe));

    this.newFolder$ = this.expandService.getNewFolderState().pipe(takeUntil(this.ngUnsubscribe));
    this.createChild$ = this.expandService
      .hasNewFolderChild(this.folder.id)
      .pipe(takeUntil(this.ngUnsubscribe));

    this.isMoving$ = this.folderService.movingFolder$.pipe(
      map((folder) => folder && folder.id === this.folder.id),
      takeUntil(this.ngUnsubscribe),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.folder) {
      this.folder.actions.forEach(
        (action) => (action.callback = () => this[action.id](action.params)),
      );
      this.path = `${this.parentPath}/${this.folder.name}`;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.infoDialogReference) {
      this.infoDialogReference.dismiss();
    }

    if (this.manageSharingDialogRef) {
      this.manageSharingDialogRef.close();
    }

    if (this.createDatabaseModalRef) {
      this.createDatabaseModalRef.dismiss();
    }

    if (this.confirmationDialogRef) {
      this.confirmationDialogRef.dismiss();
    }
  }

  toggleExpand() {
    this.expandService.toggleExpanded(this.folder.id);
  }

  onClick() {
    this.selected$.pipe(first()).subscribe((selected) => {
      if (selected) {
        this.rename();
      } else {
        this.selectFolder();
        this.expandService.toggleExpanded(this.folder.id);
      }
    });
  }

  onSaveStart() {
    this.isSaving$.next(true);
  }

  onSaveEnd() {
    this.isSaving$.next(false);
  }

  rename() {
    if (this.folder.features.canRename) {
      this.expandService.startRenaming(this.folder.id);
    }
  }

  selectFolder() {
    this.navigationService.goToFolder(this.folder);
  }

  setMenuShown(shown: boolean) {
    this.menuOpen = shown;
  }

  createChildFolder(params: ActionFolderKindParam) {
    this.expandService.startNewFolder(this.folder.id, params.folderKind || 0);
  }

  onStoppedMakingNewChild(newChild?: Folder) {
    // This event can be fired when the user either creates a new child OR when they cancel making a new child.
    if (newChild) {
      this.navigationService.goToFolder(newChild);
      this.expandService.expand(this.folder.id);
    }
    this.expandService.cancelNewFolder();
  }

  remove() {
    this.confirmationDialogRef = this.dialogService.showConfirmationDialog({
      title: `Delete the folder: ${this.folder.name}?`,
      content: 'All contents will be lost and cannot be recovered.',
      confirmationButtonText: 'Delete',
      confirmationButtonColor: 'danger',
    });

    this.confirmationDialogRef.result
      .then((confirmed) => {
        if (confirmed) {
          this.isSaving$.next(true);

          this.folderService
            .remove(this.folder.id)
            .pipe(
              first(),
              catchError((error) => {
                this.isSaving$.next(false);
                return error;
              }),
              switchMap(() => this.selected$),
            )
            .subscribe((isSelected) => {
              // Users can remove folders other than the one they have currently selected.
              if (isSelected) {
                // If the user HAS removed the folder they're currently viewing.
                this.isSaving$.next(false);
              }
            });
        }
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
      })
      .catch(() => {});
  }

  manageSharing() {
    this.manageSharingDialogRef = this.dialog.open(ManageSharingDialogComponent, {
      width: '700px',
      data: this.folder.id,
    });
  }

  setDatabaseType() {
    this.dialogService.showDialogV2({
      component: UpdateReferenceDbFormComponent,
      injectableData: { folder: this.folder },
    });
  }

  info() {
    this.infoDialogReference = this.dialogService.showDialog({
      component: FolderInfoComponent,
      injectableData: {
        folder: this.folder,
      },
    });
  }

  newReferenceDatabase() {
    this.createDatabaseModalRef = this.dialogService.showDialogV2({
      component: CreateReferenceDbDialogComponent,
      injectableData: { folder: this.folder },
      options: CreateReferenceDbDialogComponent.MODAL_OPTIONS,
    });
  }

  newMasterDatabaseFolder() {
    this.createDatabaseModalRef = this.dialogService.showDialogV2({
      component: CreateCollectionsFolderFormComponent,
      injectableData: { folder: this.folder },
    });
  }

  newMasterDatabase() {
    this.createDatabaseModalRef = this.pipelineDialogService.showDialog({
      component: MasterDatabaseFormDialogComponent,
      folderID: this.folder.id,
      selected: new SelectionState(),
      otherVariables: { creatingDatabase: true },
    });
  }

  trackById(_index: number, item: { id: string }): string {
    return item.id;
  }

  private isReferenceDatabaseRoot() {
    return (
      this.folder instanceof DatabaseRootFolder &&
      this.folder.databaseRootType === DatabaseRootTypeEnum.REFERENCE
    );
  }

  /**
   * If the current folder is the Reference Database Root folder ('Reference Sequences') then sort the folder children
   * into 2 groups. The first group is the 'provided' databases and the rest in the second group.
   * This is so users can see the provided databases sorted at the top first.
   */
  private sortDatabaseFoldersIntoGroups(folders: TreeItem[]): TreeItem[] {
    if (this.isReferenceDatabaseRoot()) {
      return [
        ...folders.filter((folder) => (folder as DatabaseFolder).provided),
        ...folders.filter((folder) => !(folder as DatabaseFolder).provided),
      ];
    } else {
      return folders;
    }
  }
}
