@if (message) {
  <div class="mb-3">
    <p class="px-2 text-muted">
      {{ message }}
    </p>
  </div>
}

<form [formGroup]="form">
  <bx-card headerTitle="Region for Motif Search">
    <div class="form-check mb-2">
      <input
        type="radio"
        id="alignEntireSequence"
        [value]="false"
        formControlName="alignCds"
        name="alignCds"
        class="form-check-input"
      />
      <label class="form-check-label" for="alignEntireSequence">Entire sequence</label>
    </div>

    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="alignCds"
            [value]="true"
            formControlName="alignCds"
            name="alignCds"
            class="form-check-input"
          />
          <label class="form-check-label" for="alignCds">Extract region with name:</label>
        </div>
      </div>
      <div class="col">
        <bx-select
          [selectOptions]="annotateRegionsOptions$ | async"
          [compareWith]="compareRegion"
          id="region"
          formControlName="region"
        >
          @if (!(annotateRegionsOptions$ | async)?.length) {
            <div bxCustomFormErrors class="text-danger small">
              No annotations present on any selected sequences.
            </div>
          }
        </bx-select>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Set Motif Type">
    <div class="form-check mb-2">
      <input
        type="radio"
        id="aaMotifsOnly"
        [value]="false"
        formControlName="includeClassMotif"
        name="includeClassMotif"
        class="form-check-input"
      />
      <label for="aaMotifsOnly" class="form-check-label">Amino acids only</label>
    </div>
    <div class="form-check">
      <input
        type="radio"
        id="includeBoth"
        [value]="true"
        formControlName="includeClassMotif"
        name="includeClassMotif"
        class="form-check-input"
      />
      <label for="includeBoth" class="form-check-label">Amino acids and amino acid classes</label>
    </div>
  </bx-card>

  <bx-card headerTitle="Motif Settings">
    <div class="mb-3 row">
      <label for="minMotifLength" class="col-sm-8 col-lg-4 col-form-label mb-sm-3 mb-lg-0">
        Minimum motif length:
      </label>
      <div class="col-sm-4 col-lg-2">
        <input
          type="number"
          id="minMotifLength"
          formControlName="minMotifLength"
          min="1"
          class="form-control"
        />
      </div>
      <div class="col-1 d-sm-none d-lg-block"></div>
      <label for="maxMotifLength" class="col-sm-8 col-lg-3 col-form-label">
        Maximum motif length:
      </label>
      <div class="col-sm-4 col-lg-2">
        <input
          type="number"
          id="maxMotifLength"
          formControlName="maxMotifLength"
          min="1"
          class="form-control"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="minMotifCount" class="col-sm-8 col-lg-4 col-form-label">
        Minimum motif occurrences:
      </label>
      <div class="col-sm-4 col-lg-2">
        <input
          type="number"
          id="minMotifCount"
          formControlName="minMotifCount"
          min="1"
          class="form-control"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="numMotifs" class="col-sm-8 col-lg-4 col-form-label">
        Number of motifs to identify:
      </label>
      <div class="col-sm-4 col-lg-2">
        <input
          type="number"
          id="numMotifs"
          formControlName="numMotifs"
          min="1"
          class="form-control"
        />
      </div>
    </div>

    <div class="row mb-0">
      <label for="splitOn" class="col-sm-8 col-lg-4 col-form-label">
        Exclude amino acid(s) from motif:
      </label>
      <div class="col-4">
        <input
          type="string"
          id="splitOn"
          placeholder="For example: C,A"
          formControlName="splitOn"
          class="form-control"
        />
      </div>
    </div>
  </bx-card>
</form>
