import { Pipe, PipeTransform } from '@angular/core';

/**
 * Constructs the annotation name with the represented chain.
 * e.g. `CDR3` with a chain of `Heavy` would be constructed as `Heavy CDR3`.
 */
@Pipe({
  name: 'annotationNameWithChain',
  standalone: true,
})
export class AnnotationNameWithChainPipe implements PipeTransform {
  transform(name: string, chain?: 'Heavy' | 'Light'): string {
    return chain ? chain + ' ' + name : name;
  }
}
