import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ViewerDataService } from '../../../../core/viewers-v2/viewer-data/viewer-data.service';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { AnnotatedPluginDocument } from '../../../../core/geneious';
import { CleanUp } from '../../../../shared/cleanup';
import { ViewerComponent } from '../../../../core/viewers-v2/viewers-v2.config';
import { DocumentSelectionSignature } from '../../../../core/document-selection-signature/document-selection-signature.model';
import {
  annotatedPluginDocumentViewerSelector,
  antibodyAnnotatorViewerSelector,
} from '../../../../core/viewer-components/viewer-selectors';
import {
  isViewerMultipleTableDocumentSelection,
  ViewerDocumentData,
  ViewerDocumentSelection,
  ViewerMultipleTableDocumentSelection,
  ViewerResultData,
} from '../../../../core/viewer-components/viewer-document-data';
import { DocumentTableType } from '../../../../../nucleus/services/documentService/document-table-type';
import { FormatterService } from '../../../../shared/formatter.service';
import { SequenceDataService } from '../../../../core/sequence-viewer/sequence-data.service';
import { SequenceLogoDatasource } from '../../datasources/sequence-logo-datasource';
import { ChartPresenterComponent } from '../../chart-presenter/chart-presenter.component';
import { PageMessageComponent } from '../../../../shared/page-message/page-message.component';

import { LoadingComponent } from '../../../../shared/loading/loading.component';
import { ToolstripComponent } from '../../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';

@ViewerComponent({
  key: 'graph-sequence-logo-viewer',
  title: 'Sequence Logo',
  selectors: [
    annotatedPluginDocumentViewerSelector([
      DocumentSelectionSignature.forNucleotideAlignments(1, 1),
      DocumentSelectionSignature.forProteinAlignments(1, 1),
    ]),
    antibodyAnnotatorViewerSelector(
      [
        {
          min: 1,
          max: Number.MAX_SAFE_INTEGER,
          tableType: DocumentTableType.INEXACT_CLUSTER,
        },
        {
          min: 1,
          max: Number.MAX_SAFE_INTEGER,
          tableType: DocumentTableType.CLUSTER_GENE,
        },
        {
          min: 1,
          max: Number.MAX_SAFE_INTEGER,
          tableType: DocumentTableType.CLUSTERS,
        },
      ],
      (selection) => {
        // This is to account for document that were created prior to BX-6433, which doesn't have the required Cluster Contents columns.
        return selection.columns.some((column) => column.colID.startsWith('Cluster Contents'));
      },
    ),
  ],
})
@Component({
  selector: 'bx-sequence-logo-viewer',
  templateUrl: './sequence-logo-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    LoadingComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    ChartPresenterComponent,
  ],
})
export class SequenceLogoViewerComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto';
  @ViewChild(ChartPresenterComponent) graph: ChartPresenterComponent;

  message: string;
  maxAlignmentLength = 175;
  data: any;
  isDataFormatted = false;
  sequenceType = 'AminoAcid';
  numberOfSequences: number;
  datasource: SequenceLogoDatasource;
  private state$: Observable<ViewerDocumentSelection>;
  private state: ViewerDocumentSelection | ViewerMultipleTableDocumentSelection;

  constructor(
    private cd: ChangeDetectorRef,
    private viewerDataService: ViewerDataService<ViewerDocumentData | ViewerResultData>,
    private sequenceDataService: SequenceDataService,
  ) {
    super();

    this.state$ = this.viewerDataService
      .getData('graph-sequence-logo-viewer')
      .pipe(map((data) => data.selection));
  }

  ngOnInit() {
    this.state$
      .pipe(
        tap((state) => (this.state = state)),
        // @see https://iamturns.com/continue-rxjs-streams-when-errors-occur/
        switchMap((state) =>
          of(state).pipe(
            catchError((error) => {
              this.message = error;
              return EMPTY;
            }),
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state) => this.initialize(state));
  }

  exportGraph() {
    if (this.graph) {
      this.graph.exportGraph(this.state?.rows[0]?.name);
    }
  }

  exportGraphAsTable() {
    if (this.graph) {
      this.graph.exportGraphAsTable({
        documentName: this.state?.rows[0]?.name,
        headerText: 'Position',
      });
    }
  }

  private initialize(state: ViewerDocumentSelection) {
    this.cd.markForCheck();

    if (state.totalSelected < 1) {
      this.message = 'Select a cluster to view sequence logo.';
    } else if (state.totalSelected > 1) {
      this.message = 'Select a single cluster to view sequence logo.';
    } else if (isViewerMultipleTableDocumentSelection(state)) {
      // NGS documents.
      const firstSelectedRow = state.selectedRows[0];
      if (firstSelectedRow['Unique'] < 2) {
        this.message = 'The selected cluster must have at least two unique sequences.';
      } else if (
        !firstSelectedRow['Cluster Contents'] &&
        !firstSelectedRow['Cluster Contents (Top 100)']
      ) {
        throw new Error(
          "Table must contain column 'Cluster contents' or 'Cluster contents (Top 100)'.",
        );
      } else {
        this.data = firstSelectedRow;

        this.sequenceType = 'AminoAcid';
        this.numberOfSequences = firstSelectedRow['Total'];
        this.message = null;
        this.datasource = new SequenceLogoDatasource(
          this.numberOfSequences,
          this.data,
          this.isDataFormatted,
          this.sequenceType,
        );
      }
    } else {
      // Alignment documents.
      const firstSelectedRow: AnnotatedPluginDocument = state.rows[0];
      const selectedIDs = state.rows.map((row) => row.id);

      if (firstSelectedRow.getAllFields().sequence_length > this.maxAlignmentLength) {
        this.message = `Selected alignment must not be longer than ${this.maxAlignmentLength} residues.`;
      } else {
        this.message = null;
        this.isDataFormatted = true;
        this.extractFromJSONAndProcess(selectedIDs[0]);
      }
    }
  }

  private extractFromJSONAndProcess(id: string) {
    this.sequenceDataService
      .getSequenceForEntity(id)
      .toPromise()
      .then((json) => {
        const sequences = Array.isArray(json) ? json : json.sequences;
        const extracted = sequences.map((sequence) => {
          const total =
            sequence.metadata['BX_Total combined sequences'] ??
            sequence.metadata['BX_Identity Cluster Count'] ??
            sequence.metadata['BX_Total'];
          const count = FormatterService.isNumeric(total) ? Number(total) : 1;
          return {
            sequence: sequence.sequence.sequence,
            count: count,
            type: sequence.sequence.sequenceType,
          };
        });
        this.data = extracted;
        this.sequenceType = extracted[0].type;
        this.numberOfSequences = extracted.reduce((total, sequence) => total + sequence.count, 0);
        this.message = null;

        this.datasource = new SequenceLogoDatasource(
          this.numberOfSequences,
          this.data,
          this.isDataFormatted,
          this.sequenceType,
        );

        this.cd.markForCheck();
      });
  }
}
