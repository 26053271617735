import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class RemoveUMIDuplicatesJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'remove-umi-duplicates',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: RemoveUMIDuplicatesJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: RemoveUMIDuplicatesJobOptionsV1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface RemoveUMIDuplicatesJobOptionsV1 {
  optionValues: {
    useStart: boolean;
    startLength?: number;
    useEnd: boolean;
    endLength?: number;
    allowUMIMismatch: boolean;
    discardShortSequences: boolean;
    discardSequencesShorterThan?: number;
    discardLowQualitySequences: boolean;
    discardLowQualitySequencesPercent?: number;
    dontClusterPercent: number;
  };
}
