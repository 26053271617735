import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  TrackByFunction,
} from '@angular/core';
import { ChipsService } from './chips.service';
import { Chip } from './index';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { CleanUp } from '../cleanup';
import { AsyncPipe } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { ChipAddButtonComponent } from './chip-add-button/chip-add-button.component';

@Component({
  selector: 'bx-chips',
  templateUrl: './chips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChipsService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ChipComponent, ChipAddButtonComponent, AsyncPipe],
})
export class ChipsComponent extends CleanUp implements OnChanges, ControlValueAccessor {
  @HostBinding('class') readonly hostClass = 'd-block';
  @Input() addButtonPopoverContentComponent: ComponentType<any>;
  @Input() addButtonPopoverContentData: unknown;
  @Input() readonlyChips: Chip[];

  disabled$ = new BehaviorSubject(false);
  readonly trackByID: TrackByFunction<Chip> = (_i, chip) => chip.id;

  constructor(public chipsService: ChipsService) {
    super();
    this.chipsService.chips$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chips) => this.onChange(chips));

    this.ngUnsubscribe.subscribe(() => this.disabled$.complete());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readonlyChips) {
      this.chipsService.setReadonlyChips(this.readonlyChips);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  writeValue(chips: Chip[]): void {
    this.chipsService.setChips(chips);
  }

  private onChange = (value: Chip[]) => {};
  private onTouched = () => {};
}
