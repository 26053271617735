import { combineLatest, merge, Observable, of as observableOf, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  publishReplay,
  refCount,
  switchMap,
} from 'rxjs/operators';
import { ColDef } from '@ag-grid-community/core';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { annotatedPluginDocumentViewerSelector } from '../viewer-selectors';
import { ViewerDocumentData, ViewerDocumentSelection } from '../viewer-document-data';
import { DocumentPartOld } from '../../../../nucleus/v2/models/blob.v2.model';
import { ClientGridComponent } from '../../../features/grid/client-grid/client-grid.component';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@ViewerComponent({
  key: 'motif-report-viewer',
  title: 'Motif Report',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
        1,
        1,
      ),
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.geneious.publicapi.documents.sequence.DefaultSequenceListDocument',
        1,
        1,
      ),
    ],
    (data) => {
      const row = data.selection.rows[0];
      // Please don't change this, Scaffold v2 motif reports have additional versioning as a suffix.
      return row.getDocumentPartWithPrefix('BX_MOTIF_REPORT') != null;
    },
  ),
})
@Component({
  selector: 'bx-motif-report-viewer',
  templateUrl: './motif-report-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, LoadingComponent, ClientGridComponent, AsyncPipe],
})
export class MotifReportViewerComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = [
    'flex-grow-1',
    'flex-shrink-1',
    'd-flex',
    'flex-column',
    'overflow-auto',
    'p-3',
  ];
  @ViewChild(ClientGridComponent, { static: true }) clientGrid: ClientGridComponent;

  motifs$: Observable<Motif[]>;
  title$: Observable<string>;
  isLoading$: Observable<boolean>;
  openedAt: number;

  headers$: Observable<ColDef[]>;

  DEFAULT_DISPLAY_MSG = 'Please select a document with annotated motifs.';
  errorMessage$: Observable<string>;

  private state$: Observable<ViewerDocumentSelection>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private documentHttpService: DocumentHttpV2Service,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
  ) {
    const data$ = this.viewerDataService.getData('motif-report-viewer');
    this.state$ = data$.pipe(map((data) => data.selection));
  }

  ngOnInit() {
    this.openedAt = Date.now();

    const stateChange$ = this.state$.pipe(
      distinctUntilChanged((state1, state2) => state1.rows[0].id === state2.rows[0].id),
    );

    const selectedReport$ = stateChange$.pipe(
      switchMap((state) => this.getSelectedReport(state)),
      publishReplay(1),
      refCount(),
    );

    this.title$ = selectedReport$.pipe(
      filter((report) => !!report),
      map((report) => report.name),
    );

    this.motifs$ = merge(
      stateChange$.pipe(map(() => [])),
      selectedReport$.pipe(map((report) => (report ? report.motifs : []))),
    );

    this.headers$ = selectedReport$.pipe(
      map((report) => this.getHeaders(report && report.type === MotifReportType.enriched)),
    );

    this.errorMessage$ = merge(
      stateChange$.pipe(map(() => '')),
      selectedReport$.pipe(map((report) => (report ? '' : this.DEFAULT_DISPLAY_MSG))),
    );

    this.isLoading$ = merge(
      stateChange$.pipe(map(() => true)),
      selectedReport$.pipe(map(() => false)),
    );

    combineLatest([this.motifs$, this.headers$]).subscribe(([motifs, headers]) => {
      this.clientGrid.setColumnDefs(headers);
      this.clientGrid.setRowData(motifs);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getSelectedReport(viewerSelection: ViewerDocumentSelection): Observable<MotifReportDoc | null> {
    const firstSelectedDocument = viewerSelection.rows[0];
    const reportBlobRef: DocumentPartOld = firstSelectedDocument
      ? firstSelectedDocument.getDocumentPartWithPrefix('BX_MOTIF_REPORT')
      : null;
    if (firstSelectedDocument && reportBlobRef) {
      return this.documentHttpService
        .getDocumentPart(firstSelectedDocument.id, reportBlobRef.name, 'json')
        .pipe(
          map((report) => {
            report.type =
              MotifReportType.enriched === reportBlobRef.name
                ? MotifReportType.enriched
                : MotifReportType.exact;
            return report;
          }),
        );
    } else {
      return observableOf(null);
    }
  }

  getHeaders(isLogoMotif: boolean): ColDef[] {
    // Common columns.
    const count: ColDef = {
      field: 'count',
      headerName: 'Total Count',
      suppressMenu: true,
    };
    const numSequences: ColDef = {
      field: 'numSequences',
      headerName: 'Number of Sequences',
      suppressMenu: true,
    };

    if (isLogoMotif) {
      const columns: ColDef[] = [
        {
          field: 'consensus',
          headerName: 'Motif Consensus',
          width: 300,
          suppressMenu: true,
        },
        count,
        {
          field: 'name',
          headerName: 'Motif Type',
          valueGetter: (params) => 'Enriched Motif',
          suppressMenu: true,
        },
        numSequences,
      ];
      Object.keys(friendlyLogoColumnNames).forEach((colKey) => {
        columns.push({
          field: colKey,
          headerName: friendlyLogoColumnNames[colKey as keyof typeof friendlyLogoColumnNames],
          suppressMenu: true,
        });
      });
      return columns;
    } else {
      return [
        {
          field: 'sequence',
          headerName: 'Motif',
          width: 300,
          suppressMenu: true,
        },
        count,
        {
          field: 'isClassMotif',
          headerName: 'Motif Type',
          valueGetter: (params: any) =>
            params.data.classMotif ? 'Amino Acid Class' : 'Amino Acid',
          suppressMenu: true,
        },
        {
          field: 'selected',
          headerName: 'Selected for annotation',
          suppressMenu: true,
          cellDataType: 'text',
        },
        numSequences,
      ];
    }
  }
}

export interface MotifReportDoc {
  name: string;
  type: MotifReportType;
  statistics: any[];
  motifs: (EnrichedMotif | ExactMotif)[];
}

export enum MotifReportType {
  exact = 'BX_MOTIF_REPORT',
  enriched = 'BX_MOTIF_REPORT_ENRICHED_1',
}

export interface Motif {
  name: string;
  count: number;
  numSequences?: number;
}

// Returned from Motif reports created using the V1 Motif annotator pipeline.
export interface ExactMotif extends Motif {
  sequence: string;
  isClassMotif: boolean;
  selected: boolean;
}

// Returned from Motif reports created using the new Logo Motif analysis pipeline (bundled with Scaffold Annotator v2).
export interface EnrichedMotif extends Motif {
  // id can be used to connect to Motif annotations on sequences.
  id: number;
  consensus: string;
  logLikelihood: number;
  mixingParameter: number;
  informationContent: number;
  complexityScore: number;
  Evalue: number;
  BIC: number;
  AIC: number;
}

const friendlyLogoColumnNames = {
  logLikelihood: 'Log Likelihood',
  mixingParameter: 'Mixing Parameter',
  informationContent: 'Information Content',
  complexityScore: 'Complexity Score',
  Evalue: 'E Value',
  BIC: 'BIC',
  AIC: 'AIC',
};
