import { ColDef, GridOptions } from '@ag-grid-community/core';
import { DatePipe, AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { JobConfiguration, JobSearchResult, JobStatus } from '@geneious/nucleus-api-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../../features/grid/datasource/grid.resource';
import { SelectionState, GridComponent } from '../../../features/grid/grid.component';
import {
  ProgressRendererComponent,
  ProgressRendererData,
} from '../../../features/grid/progress-renderer/progress-renderer.component';
import { CleanUp } from '../../../shared/cleanup';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import { JobFeedbackDialogComponent } from '../job-feedback-dialog/job-feedback-dialog.component';
import { JobsService } from '../job.service';
import { LinkComponent } from '../../link.component';
import { getFriendlyJobName } from '../../pipeline/pipeline-constants';
import { JobsResultRendererComponent } from './jobs-result-renderer/jobs-result-renderer.component';
import { JobStatusRendererComponent } from './jobs-status-renderer/job-status-renderer.component';
import { RunFromJsonComponent } from '../../pipeline-dialogs/run-from-json/run-from-json.component';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { JobsInsightsRendererComponent } from './jobs-insights-renderer/jobs-insights-renderer.component';
import { SnakeComponent } from '../../../features/easter-eggs/snake/snake.component';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

type JobStatusData = {
  status?: { kind: JobStatus['kind']; progress?: number };
};
export class JobProgressRendererData implements ProgressRendererData {
  isStarting(data: JobStatusData): boolean {
    return (
      data.status?.kind === 'Queued' ||
      (data.status?.kind === 'Running' && data.status.progress === 0)
    );
  }

  isCompleted(data: JobStatusData): boolean {
    return data.status?.kind === 'Completed';
  }

  isCompletedWithErrors(data: JobStatusData): boolean {
    // this status is currently used for FileUpload only
    return false;
  }

  isFailed(data: JobStatusData): boolean {
    return data.status?.kind === 'Failed';
  }

  isCancelled(data: JobStatusData): boolean {
    return data.status?.kind === 'Cancelled';
  }
}

export const progressColDef = {
  field: 'status.progress',
  headerName: 'Progress',
  width: 50,
  valueGetter: (params: any) => {
    return JobsTableComponent.safelyGetJob(params.data, (job: JobSearchResult) =>
      String(JobsTableComponent.getProgress(job)),
    );
  },
  cellRenderer: ProgressRendererComponent,
  cellRendererParams: new JobProgressRendererData(),
  sortable: false,
} satisfies ColDef;

@Component({
  selector: 'bx-jobs-table',
  templateUrl: './jobs-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SnakeComponent,
    SettingsBreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    GridComponent,
    AsyncPipe,
  ],
})
export class JobsTableComponent extends CleanUp implements OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @ViewChild(GridComponent) gridComponent: GridComponent;
  columnDefs$: Observable<ColDef[]>;
  datasource: IGridResource;
  gridOptions: GridOptions = { cacheBlockSize: 100 };
  naturalSort: boolean;
  selectionState: SelectionState;
  runFromJsonEnabled$: Observable<boolean>;

  code = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];
  codeIndex = 0;
  playSnake = false;
  snakeEventListener: any;
  cancelButtonDisabled$: Subject<boolean> = new BehaviorSubject<boolean>(true);

  private jobFeedbackModalRef: NgbModalRef;
  private cancelJobConfirmationDialogRef: NgbModalRef;
  private runFromJsonModalRef: NgbModalRef;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private activityStreamService: ActivityStreamService,
    private jobService: JobsService,
    private router: Router,
    private ngbModal: NgbModal,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private pipelineDialogService: PipelineDialogService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super();
    this.runFromJsonEnabled$ = this.featureSwitchService
      .isEnabledOnce('runFromJson')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.datasource = this.buildResource();

    this.snakeEventListener = (event: KeyboardEvent) => this.startSnake(event);
    document.addEventListener('keydown', this.snakeEventListener);
    this.columnDefs$ = this.featureSwitchService.isEnabledOnce('jobInsights').pipe(
      map((enabled) => {
        const standardColumnDefs: ColDef[] = [];
        standardColumnDefs.push(
          {
            field: 'config.pipeline.name',
            headerName: 'Name',
            width: 100,
            valueGetter: (params) => {
              const renderLogic = (job: any) =>
                getFriendlyJobName(job.config.pipeline.name, job.config.parameters.options);
              return JobsTableComponent.safelyGetJob(params.data, renderLogic);
            },
            sortable: false,
          },
          progressColDef,
        );

        if (enabled) {
          standardColumnDefs.push({
            field: 'insights',
            headerName: 'Job Insights',
            tooltipValueGetter: (params) => JobsTableComponent.insightsTooltipValueGetter(params),
            valueGetter: (params) => JobsTableComponent.insightsValueGetter(params),
            cellRenderer: JobsInsightsRendererComponent,
            sortable: false,
          });
        }
        standardColumnDefs.push(
          {
            field: 'status',
            headerName: 'Status',
            tooltipValueGetter: (params) => JobsTableComponent.statusValueGetter(params),
            valueGetter: (params) => JobsTableComponent.statusValueGetter(params),
            cellRenderer: JobStatusRendererComponent,
            width: 300,
            sortable: true,
          },
          {
            field: 'results',
            headerName: 'Results',
            cellRenderer: JobsResultRendererComponent,
            valueGetter: (params) => {
              return JobsTableComponent.safelyGetJob(params.data, (job: JobSearchResult) => {
                if (job.status.kind === 'Completed') {
                  return `View Results`;
                } else {
                  return job.status.kind === 'Failed' ? 'Unavailable' : 'Pending...';
                }
              });
            },
            width: 200,
            sortable: false,
          },
          {
            field: 'link',
            headerName: 'Input Folder',
            cellRenderer: LinkComponent,
            valueGetter: (params) => {
              const getFolderOrShowError = (job: any) => {
                const hasSelection = job.config.parameters && job.config.parameters.selection;
                return hasSelection ? job.config.parameters.selection.folderId : '-';
              };
              return JobsTableComponent.safelyGetJob(params.data, (job: any) => {
                const folderId = getFolderOrShowError(job);
                return `Folder with ID ${folderId}`;
              });
            },
            width: 250,
            sortable: false,
          },
          {
            field: 'lastUpdated',
            headerName: 'Last Updated',
            valueFormatter: (params) => {
              const renderLogic = (job: JobSearchResult) => {
                const date = new Date(job.status.dateTime);
                const datePipe = new DatePipe('en');
                return datePipe.transform(date, 'medium');
              };
              return JobsTableComponent.safelyGetJob(params.data, renderLogic);
            },
            valueGetter: (params) => {
              const job = params.data;
              return job && job.status ? job.status.dateTime : '';
            },
            sortable: true,
          },
          {
            field: 'parameters',
            headerName: 'Parameters',
            tooltipValueGetter: (params) =>
              JobsTableComponent.safelyGetJob(params.data, (job: JobSearchResult) =>
                // display first six lines of parameters
                JSON.stringify(job.config.parameters, null, 1).split('\n').slice(0, 6).join('\n'),
              ),
            valueGetter: (params) =>
              JobsTableComponent.safelyGetJob(params.data, (job: JobSearchResult) =>
                JSON.stringify(job.config.parameters),
              ),
            sortable: false,
          },
          {
            field: 'user',
            headerName: 'User',
            valueGetter: (_) => 'You',
            width: 100,
            sortable: false,
          },
          {
            field: 'location',
            headerName: 'Location',
            valueGetter: (_) => 'Web UI',
            width: 100,
            sortable: false,
          },
        );
        return standardColumnDefs;
      }),
    );
    this.naturalSort = false;
  }

  listenToJobActivity() {
    this.activityStreamService
      .listenToJobActivity()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.gridComponent.refreshInfiniteCache());
  }

  showJobDetails() {
    this.router.navigate([`/jobs/${this.selectionState.selectedRows[0].id}`]);
  }

  cancelJob() {
    const selectedRow = this.selectionState.selectedRows[0];
    const name = getFriendlyJobName(
      selectedRow.config.pipeline.name,
      selectedRow.config.parameters.options,
    );

    this.cancelJobConfirmationDialogRef = this.dialogService.showConfirmationDialog({
      title: `Are you sure you want to cancel ${name} job?`,
      confirmationButtonColor: 'danger',
      cancelButtonText: 'No',
      confirmationObservable: this.jobService.cancel(this.selectionState.selectedRows[0].id).pipe(
        tap(() => this.cancelButtonDisabled$.next(true)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return of({
              errorMessage:
                'Error occurred while cancelling job. It may have already completed, failed or been cancelled.',
            });
          } else {
            return of({ errorMessage: 'An unexpected error occurred when cancelling this job.' });
          }
        }),
      ),
    });
  }

  openJobFeedbackDialog() {
    const selectedRow = this.selectionState.selectedRows[0];
    const name = getFriendlyJobName(
      selectedRow.config.pipeline.name,
      selectedRow.config.parameters.options,
    );

    this.jobFeedbackModalRef = this.ngbModal.open(JobFeedbackDialogComponent, {
      centered: true,
      size: 'lg',
    });
    this.jobFeedbackModalRef.componentInstance.jobID = selectedRow.id;
    this.jobFeedbackModalRef.componentInstance.pipelineName = name;
  }

  openRunFromJson() {
    const selectedRow = this.selectionState.selectedRows[0];
    const jobConfiguration: JobConfiguration = selectedRow?.config ?? null;
    this.runFromJsonModalRef = this.pipelineDialogService.showDialog({
      component: RunFromJsonComponent,
      folderID: null,
      selected: null,
      otherVariables: {
        jobConfiguration,
      },
    });
  }

  onSelectionChanged(selectionState: SelectionState) {
    this.selectionState = selectionState;
    if (this.selectionState.noOfRowsSelected === 1) {
      const kind = this.selectionState.firstRow.status.kind;
      this.cancelButtonDisabled$.next(!['Running', 'Queued'].includes(kind));
    } else {
      this.cancelButtonDisabled$.next(true);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    document.removeEventListener('keydown', this.snakeEventListener);
    this.cancelButtonDisabled$.complete();
    if (this.jobFeedbackModalRef) {
      this.jobFeedbackModalRef.close();
    }
    if (this.runFromJsonModalRef) {
      this.runFromJsonModalRef.close();
    }
    if (this.cancelJobConfirmationDialogRef) {
      this.cancelJobConfirmationDialogRef.close();
    }
  }

  startSnake(event: KeyboardEvent) {
    if (this.code[this.codeIndex] === event.key) {
      this.codeIndex++;
      if (this.codeIndex >= this.code.length) {
        this.playSnake = true;
        this.cd.markForCheck();
      }
    } else {
      this.codeIndex = 0;
    }
  }

  static safelyGetJob(
    maybeJob: JobSearchResult | undefined,
    renderLogic: (job: JobSearchResult) => string,
  ) {
    return maybeJob ? renderLogic(maybeJob) : '';
  }

  static getProgress(job: JobSearchResult): number {
    const kind = job.status.kind;
    const isDone = ['Completed', 'Failed', 'Cancelled'].includes(kind);
    const progress = (job.status as { progress?: number }).progress ?? 0;
    return isDone ? 100 : progress;
  }

  static statusValueGetter(params: any) {
    const renderLogic = (job: JobSearchResult) => {
      const status = params.data.status;
      const kind = status.kind.replace('Job', '');
      const messages = job.status.messages;
      const firstMessage = messages && messages.length ? `: ${messages[0]}` : '';
      return job.status.kind === 'Completed' ? 'Completed' : kind + firstMessage;
    };
    return JobsTableComponent.safelyGetJob(params.data, renderLogic);
  }

  static insightsTooltipValueGetter(params: any) {
    const renderLogic = (job: JobSearchResult) => {
      const insights = [];
      if (job.logSummary.warningCount > 0) {
        insights.push(`Warnings: ${job.logSummary.warningCount}`);
      }
      if (job.logSummary.infoCount > 0) {
        insights.push(`Info: ${job.logSummary.infoCount}`);
      }
      return insights.join(', ');
    };
    return JobsTableComponent.safelyGetJob(params.data, renderLogic);
  }

  static insightsValueGetter(params: any) {
    const renderLogic = (job: JobSearchResult) => JSON.stringify(job.logSummary);
    return JobsTableComponent.safelyGetJob(params.data, renderLogic);
  }

  private buildResource(): IGridResource {
    const jobService = this.jobService;
    class JobsTableResource implements IGridResource {
      query(params: IGetRowsRequestMinimal) {
        return jobService.getUserJobs(params.startRow, params.endRow, params.sortModel, true).pipe(
          map((jobs) => ({
            data: jobs.data,
            metadata: {
              ...jobs.metadata.page,
              total: jobs.metadata.page.total ?? jobs.data.length,
            },
          })),
        );
      }
    }

    return new JobsTableResource();
  }
}
