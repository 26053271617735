<form
  #searchForm="ngForm"
  id="search-form"
  name="searchForm"
  (ngSubmit)="onSubmit()"
  class="d-flex align-items-center input-group rounded"
>
  <input
    type="text"
    id="search-field"
    class="form-control"
    name="searchQuery"
    [(ngModel)]="searchQuery"
    placeholder="Search your data..."
    aria-label="Search"
    class="form-control form-control-lg bg-transparent border-0"
    (focus)="setFocused(true)"
    (blur)="setFocused(false)"
  />
  <span
    [ngClass]="{ invisible: !searchQuery }"
    role="button"
    (click)="clearSearch()"
    class="d-flex px-2 align-items-center text-dark search-btn-transparent"
  >
    <fa-icon [icon]="clearIcon"></fa-icon>
  </span>
  <div ngbDropdown [container]="null" class="mx-0">
    <button
      id="button-basic"
      ngbDropdownToggle
      type="button"
      class="btn h-100 dropdown-toggle dropdown-toggle-split rounded-0 border-0 search-btn-transparent"
      aria-controls="dropdown-basic"
      title="Search Type"
    >
      <span class="d-none d-sm-inline">{{ searchTypeLabels[selectedType()] }} </span>
      <span class="caret"></span>
    </button>
    <ul
      id="dropdown-basic"
      ngbDropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="button-basic"
    >
      <li
        role="menuitem"
        ngbTooltip="Searches name, description, target antigen, and notes of files and folders that you have access to. Partial match."
        [openDelay]="500"
        placement="right"
      >
        <button
          type="button"
          ngbDropdownItem
          (click)="selectedType.set(GlobalSearchTypes.FILE_FOLDER)"
        >
          {{ searchTypeLabels[GlobalSearchTypes.FILE_FOLDER] }}
        </button>
      </li>
      <li
        *bxShowIf="'CDR3SearchUI'"
        role="menuitem"
        ngbTooltip="Searches Heavy and Light CDR3 regions of Result documents that you have access to. Exact match."
        [openDelay]="500"
        #resultTooltip="ngbTooltip"
        placement="right"
      >
        <button type="button" ngbDropdownItem (click)="selectedType.set(GlobalSearchTypes.RESULT)">
          {{ searchTypeLabels[GlobalSearchTypes.RESULT] }}
        </button>
      </li>
    </ul>
  </div>
  <button
    type="submit"
    class="btn search-btn-transparent border-0 mx-0"
    id="search-button"
    title="Search"
  >
    <fa-icon [icon]="searchIcon"></fa-icon>
  </button>
</form>
