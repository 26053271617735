import {
  Component,
  EventEmitter,
  SimpleChanges,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  AnnotationsPlugin,
  ColorByAnnotationPlugin,
  ConsensusPlugin,
  GraphsPlugin,
  HighlightingPlugin,
  IdentityPlugin,
  SortingPlugin,
  TranslationsPlugin,
  WuKabatPlugin,
} from '@geneious/sequence-viewer/plugins';
import {
  AnnotationTypeData,
  MetadataColumnOptions,
  SortCriteria,
} from '@geneious/sequence-viewer/types';
import { SequenceColors } from '@geneious/shared-constants';
import { ColorSchemeCollection } from '@geneious/shared-constants/types';
import {
  NgbTooltip,
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionHeader,
  NgbAccordionToggle,
  NgbCollapse,
  NgbAccordionCollapse,
  NgbAccordionBody,
} from '@ng-bootstrap/ng-bootstrap';
import { SequenceViewerMetadataService } from '../../../core/sequence-viewer/sequence-viewer-metadata.service';
import { isMetadataColumnGroup } from '../sequence-viewer-utils';
import * as constants from '../sequence-viewer.constants';
import {
  MetadataColumnAbomination,
  MetadataColumnGroup,
  MetadataColumnOrGroup,
} from '../sequence-viewer.interfaces';
import { SequenceTopology, SequenceViewerService } from '../sequence-viewer.service';
import { CollapsiblePanelComponent } from '../../../shared/collapsible-panel/collapsible-panel.component';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { SortComponent } from './sort/sort.component';

/**
 * Sidebar options panel for Sequence Viewer.
 */
@Component({
  selector: 'sv-options-panel',
  templateUrl: './options-panel.component.html',
  standalone: true,
  imports: [
    CollapsiblePanelComponent,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgClass,
    FaIconComponent,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    FormsModule,
    SortComponent,
    NgbTooltip,
  ],
})
export class OptionsPanelComponent implements OnInit, OnChanges {
  @HostBinding('class') readonly hostClass = 'd-flex h-100';

  @Input() metadataColumnOrder: string[];
  @Input() circularModeEnabled: boolean;
  @Input() isInEditingMode: boolean;

  columns: MetadataColumnOrGroup[] = [];

  readonly panelButtonClasses =
    'btn btn-link w-100 d-flex align-items-center text-body text-decoration-none bg-light rounded-0 border-bottom';

  get referenceExists(): boolean {
    return Number.isInteger(this.sequenceViewerService.referenceSequenceIndex);
  }

  get relativeTo(): string {
    if (
      this.sequenceViewerService.referenceSequenceIndex === null ||
      this.sequenceViewerService.highlighting.useConsensus
    ) {
      return 'consensus';
    } else {
      return 'reference';
    }
  }

  set relativeTo(raw) {
    this.highlighting.useConsensus = raw === 'consensus';
  }

  get sortCriteria(): SortCriteria[] {
    return this.sorting.currentSort;
  }

  get liabilityScoreHigh() {
    return this.sequenceViewerService.liabilityScoreHigh;
  }

  set liabilityScoreHigh(raw) {
    this.sequenceViewerService.liabilityScoreHigh = raw;
    this.sequenceViewerService.dirty();
  }

  get liabilityScoreLow() {
    return this.sequenceViewerService.liabilityScoreLow;
  }

  set liabilityScoreLow(raw) {
    this.sequenceViewerService.liabilityScoreLow = raw;
    this.sequenceViewerService.dirty();
  }

  get translations(): TranslationsPlugin {
    return this.sequenceViewerService.translations;
  }

  get annotations(): AnnotationsPlugin {
    return this.sequenceViewerService.annotations;
  }

  get graphs(): GraphsPlugin {
    return this.sequenceViewerService.graphs;
  }

  get identity(): IdentityPlugin {
    return this.sequenceViewerService.identity;
  }

  get wuKabat(): WuKabatPlugin {
    return this.sequenceViewerService.wuKabat;
  }

  get wuKabatMaxScore() {
    // Round maxPossibleScore to 2dp.
    return Math.round(this.wuKabat.maxPossibleScore * 100) / 100;
  }

  get consensus(): ConsensusPlugin {
    return this.sequenceViewerService.consensus;
  }

  get highlighting(): HighlightingPlugin {
    return this.sequenceViewerService.highlighting;
  }

  get colorByAnnotation(): ColorByAnnotationPlugin {
    return this.sequenceViewerService.colorByAnnotation;
  }

  get sorting(): SortingPlugin {
    return this.sequenceViewerService.sorting;
  }

  get tree() {
    return this.sequenceViewerService.tree;
  }

  get metadata() {
    return this.sequenceViewerService.metadata;
  }

  get linearView() {
    return !this.sequenceViewerService.circular;
  }

  set linearView(value: boolean) {
    this.sequenceViewerService.circular = !value;
  }

  get showDNAControls() {
    return this.sequenceViewerService.showDNAControls;
  }

  get showAlignmentControls() {
    return this.sequenceViewerService.showAlignmentControls;
  }

  get pinReference(): boolean {
    return this.sequenceViewerService.sorting.pinReference;
  }

  set pinReference(raw: boolean) {
    this.sequenceViewerService.sorting.pinReference = raw;
  }

  get sortingEnabled() {
    return this.sequenceViewerService.sortingEnabled;
  }

  get colorScheme(): string {
    return this.sequenceViewerService.colorScheme;
  }

  set colorScheme(name: string) {
    const old = this.sequenceViewerService.colorScheme;
    this.sequenceViewerService.colorScheme = name;
    this.handleColorChange(old, name);
  }

  get translationColorScheme(): string {
    return this.sequenceViewerService.translations.colorScheme;
  }

  set translationColorScheme(name: string) {
    this.sequenceViewerService.translations.colorScheme = name;
  }

  get heatmapEnabled() {
    return this.identity.isHeatmap && this.wuKabat.isHeatmap;
  }

  set heatmapEnabled(enabled) {
    this.identity.isHeatmap = enabled;
    this.wuKabat.isHeatmap = enabled;
  }

  get chromatogramEnabled(): any {
    return this._chromatogramEnabled;
  }

  set chromatogramEnabled(value) {
    this._chromatogramEnabled = value;
    this.graphs.chromatogram = this._allGraphs && value;
  }

  get qualitiesEnabled(): any {
    return this._qualitiesEnabled;
  }

  set qualitiesEnabled(value) {
    this._qualitiesEnabled = value;
    this.graphs.qualities = this._allGraphs && value;
  }

  get identityEnabled(): any {
    return this._identityEnabled;
  }

  set identityEnabled(value) {
    this._identityEnabled = value;
    this.identity.enabled = this._allGraphs && value;
  }

  get wuKabatEnabled(): any {
    return this._wuKabatEnabled;
  }

  set wuKabatEnabled(value) {
    this._wuKabatEnabled = value;
    this.wuKabat.enabled = this._allGraphs && value;
  }

  set allGraphs(raw) {
    // @see SequenceViewerDirective.defaultOptions().
    this._allGraphs = raw;
    this.graphs.chromatogram = raw && this._chromatogramEnabled;
    this.graphs.qualities = raw && this._qualitiesEnabled;
    this.identity.enabled = raw && this.identityEnabled;
    this.wuKabat.enabled = raw && this.wuKabatEnabled;
  }

  get allGraphs() {
    return this._allGraphs;
  }

  get types(): AnnotationTypeData[] {
    const types = this.annotations ? this.annotations.typesArray : [];
    return types.filter((type) => type.count > 0);
  }

  get liabilityColors() {
    return this.sequenceViewerService.liabilityColors;
  }

  get numSequences(): number {
    return this.sequenceViewerService.numSequences;
  }

  get topology(): SequenceTopology {
    return this.sequenceViewerService.sequenceTopology;
  }

  get showSidebarDrawer() {
    return this.columns.length || this.tree;
  }

  get showSorting() {
    return this.sorting && this.sortingEnabled && this.numSequences > 1;
  }

  get highlightingControlsDisabled() {
    return this.sequenceViewerService.highlightingControlsDisabled;
  }
  @Output() optionChangedSideBar: EventEmitter<Object> = new EventEmitter();
  @Output() liabilityScoreThresholdChanged = new EventEmitter();

  framesOptions = constants.FRAME_OPTIONS;
  tableOptions = constants.TABLE_OPTIONS;
  filter = '';

  readonly consensusThresholdOptions = [
    { label: '0% - Majority', value: 0 },
    { label: '25%', value: 0.25 },
    { label: '50% - Strict', value: 0.5 },
    { label: '75%', value: 0.75 },
    { label: 'Identical', value: 1 },
  ];

  readonly highlightOptions = [
    { label: 'Agreements', value: 'agreements' },
    { label: 'Disagreements', value: 'disagreements' },
    { label: 'All Disagreements', value: 'allDisagreements' },
  ];

  private _allGraphs: boolean;
  private _chromatogramEnabled: boolean;
  private _qualitiesEnabled: boolean;
  private _identityEnabled: boolean;
  private _wuKabatEnabled: boolean;
  private openAccordions: { [type: string]: boolean } = {};
  private closedGroups: { [type: string]: boolean } = {};

  // TODO Replace timeout with ng-bootstrap [autoClose] after upgrading to ng-bootstrap@3.3.0.
  private timeout: any;

  constructor(
    private sequenceViewerService: SequenceViewerService,
    private svMetadataService: SequenceViewerMetadataService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isInEditingMode && this.isInEditingMode) {
      this.sequenceViewerService.circular = false;
    }
  }

  ngOnInit() {
    // Set initial enabled state of graphs checkboxes based on saved preferences. This allows restoring a state where
    // some graphs were saved as enabled but all graphs were saved as disabled; we still need to indicate the enabled ones.
    const graphsCheckboxesInitialState = this.sequenceViewerService.graphsCheckboxesInitialState;
    this._allGraphs = graphsCheckboxesInitialState.all;
    this._chromatogramEnabled = graphsCheckboxesInitialState.chromatogram;
    this._qualitiesEnabled = graphsCheckboxesInitialState.qualities;
    this._identityEnabled = graphsCheckboxesInitialState.identity;
    this._wuKabatEnabled = graphsCheckboxesInitialState.wuKabat;

    this.sequenceViewerService.bind('ready', () => {
      const flatMetadataColumns: MetadataColumnAbomination[] =
        this.sequenceViewerService.metadataColumns.flatMap((current) => {
          if (isMetadataColumnGroup(current)) {
            const groupName = current.name;
            return current.columns.map(
              (column): MetadataColumnAbomination => ({
                ...column,
                groupName,
              }),
            );
          }
          return [current];
        });

      const sortedMetadataColumns = this.svMetadataService.sortMetadataColumns(
        flatMetadataColumns,
        this.metadataColumnOrder,
      );

      this.columns = sortedMetadataColumns.reduce((acc, current) => {
        if (acc.length > 0) {
          const currentGroupName = current.groupName;
          const lastGroup = acc[acc.length - 1];

          if (isMetadataColumnGroup(lastGroup) && lastGroup.name === currentGroupName) {
            const updatedLastGroup = {
              ...lastGroup,
              columns: lastGroup.columns.concat(this.getSVMetadataColumn(current)),
            };
            return acc.slice(0, -1).concat([updatedLastGroup]);
          }
        }

        if (current.groupName) {
          return acc.concat([
            { name: current.groupName, columns: this.getSVMetadataColumn(current) },
          ]);
        }

        return acc.concat(this.getSVMetadataColumn(current));
      }, [] as MetadataColumnOrGroup[]);
    });
  }

  caretAccordion(id: string) {
    const isAccordionOpen = this.openAccordions[id];
    return isAccordionOpen ? 'chevron-down' : 'chevron-right';
  }

  openAccordion(id: string) {
    this.openAccordions[id] = true;
  }

  closeAccordion(id: string) {
    this.openAccordions[id] = false;
  }

  caretGroup(id: string) {
    const isGroupClosed = this.closedGroups[id];
    return isGroupClosed ? 'chevron-right' : 'chevron-down';
  }

  openGroup(name: string) {
    this.closedGroups[name] = !this.closedGroups[name];
  }

  showChildColumns(name: string) {
    return !this.closedGroups[name];
  }

  // The list of annotation types is not available when the form is created so select a default value on user interaction.
  selectFirstAnnotationType() {
    const plugin = this.colorByAnnotation;
    if (plugin && !plugin.annotationType && this.types.length > 0) {
      plugin.annotationType = this.types[0].key;
    }
  }

  optionChanged(payload: Object) {
    // Emit option change up the chain from the sidebar to the sequence viewer component which emits to BX.
    this.optionChangedSideBar.emit(payload);
  }

  annotationTypeObject(annotation: string, value: boolean): Object {
    return { annotations: { types: { [annotation]: value } } };
  }

  metadataColumnObject(column: string, value: boolean): Object {
    return { metadataColumns: { [column]: value } };
  }

  clearPreferencesObject(shiftKey: boolean): Object {
    return shiftKey ? { clear_all_preferences: true } : { clear_global_preferences: true };
  }

  changeSort(criteria: SortCriteria, index: number) {
    this.sortCriteria[index] = criteria;
    this.sort();
  }

  removeSort(index: number) {
    this.sortCriteria.splice(index, 1);
    this.sort();
  }

  colorTrackFn(index: number, item: { label: string; value: string }) {
    return item.value;
  }

  addSortCriteria() {
    // Only allow 1 null sorting section.
    if (this.sorting.currentSort.some((criterion) => criterion.type === null)) {
      return;
    }

    this.sortCriteria.push({ type: null });
  }

  handleColorChange(old: string, neu: string) {
    if (this.highlighting) {
      if (neu === 'byAnnotation') {
        this.sequenceViewerService.highlightingControlsDisabled = true;
        this.sequenceViewerService.highlightingWasEnabled = this.highlighting.enabled;
        // Disable the plugin.
        this.sequenceViewerService.highlighting.enabled = false;
      } else if (old === 'byAnnotation') {
        // Restore highlighting plugin enabled state when switching off Color by annotation.
        this.sequenceViewerService.highlightingControlsDisabled = false;
        this.sequenceViewerService.highlighting.enabled =
          this.sequenceViewerService.highlightingWasEnabled;
      }
    }
  }

  validateLiabilityScore(tooltip: NgbTooltip) {
    if (
      this.sequenceViewerService.liabilityScoreLow > this.sequenceViewerService.liabilityScoreHigh
    ) {
      tooltip.open();

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        tooltip.close();
      }, 3000);
    } else {
      tooltip.close();
      clearTimeout(this.timeout);
      this.timeout = null;
      this.liabilityScoreThresholdChanged.emit();
    }
  }

  colorSchemes(translation?: boolean) {
    const schemes: ColorSchemeCollection =
      this.sequenceViewerService.showDNAControls && !translation
        ? SequenceColors.Nucleotide
        : SequenceColors.AminoAcid;

    return Object.keys(schemes)
      .filter((name) => !(translation && name === 'byAnnotation'))
      .map((name) => schemes[name])
      .filter((scheme) => !(translation && scheme.isForeground))
      .map((scheme) => {
        return {
          label: scheme.fullName,
          value: scheme.id,
        };
      });
  }

  asNumber(raw: any) {
    return Number(raw);
  }

  columnIsVisible(column: MetadataColumnOptions) {
    const query = this.filter.toLowerCase();
    return this.search(column, query);
  }

  groupIsVisible(column: MetadataColumnGroup) {
    const query = this.filter.toLowerCase();
    return column.columns.some((child) => this.search(child, query));
  }

  private sort() {
    const sort = this.sortCriteria.filter((criterion) => criterion.type);
    if (sort.length) {
      this.sorting.sortBy(sort);
    } else {
      this.sorting.sortBy([]);
    }
  }

  private search(column: MetadataColumnOrGroup, query: string) {
    const match = (name: string) => name.toLowerCase().includes(query);
    return match(column.name) || (column.label && match(column.label));
  }

  private getSVMetadataColumn(ngColumn: MetadataColumnAbomination): MetadataColumnAbomination[] {
    const find =
      this.metadata &&
      this.metadata.columns.find((svColumn: any) => svColumn.name === ngColumn.name);
    return find ? [find] : [];
  }
}
