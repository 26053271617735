<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'labels'], label: 'Labels' }]"
></bx-settings-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button class="btn btn-light" [routerLink]="['create']" type="button">New</button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      type="button"
      (click)="deleteSelectedLabels()"
      [disabled]="numSelected < 1"
    >
      Delete
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>
<bx-grid
  class="w-100 flex-grow-1 flex-shrink-1"
  [initialColumnDefs]="labelsColumnDefs"
  [datasource]="labelsResource"
  (selectionChanged)="onSelectionChanged($event)"
  tableType="labels"
  (gridReadyEmitter)="onGridReady()"
  profileComponent="labels"
>
</bx-grid>
