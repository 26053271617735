import { adapter } from './uploads-table.reducer';
import { createSelector } from '@ngrx/store';
import { AppState } from '../../core.store';

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const selectUploadsTable = (state: AppState) => {
  return state.uploadsTable;
};

export const selectAllUploads = createSelector(selectUploadsTable, selectAll);
