import { ICellRendererParams } from '@ag-grid-community/core';
import { Job } from '../../../../../nucleus/services/models/job.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { getFriendlyJobName, NucleusPipelineID } from '../../../pipeline/pipeline-constants';
import { JobInsightsComponent } from '../../../../shared/job-insights/job-insights.component';
import { JobLogSummary } from '@geneious/nucleus-api-client/model/job-log-summary';
import { JobSearchResult } from '@geneious/nucleus-api-client';

import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

/**
 * Handles rendering Job Status in the jobs table.
 */
@Component({
  selector: 'bx-jobs-insights-renderer',
  templateUrl: './jobs-insights-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JobInsightsComponent, RouterLink, NgbPopover, FaIconComponent],
})
export class JobsInsightsRendererComponent implements AgRendererComponent {
  @ViewChild(NgbPopover) popover: NgbPopover;
  @ViewChild(JobInsightsComponent) jobInsights: JobInsightsComponent;

  readonly faExclamationTriangle = faExclamationTriangle;
  readonly faInfoCircle = faInfoCircle;
  readonly INSIGHT_LIMIT = 5;

  logSummary: JobLogSummary;
  jobSearchResult: JobSearchResult;
  pipelineName: string;
  buttonData: { text: string; link: string };
  totalInsights: number;
  constructor(private cd: ChangeDetectorRef) {}

  update(params: ICellRendererParams) {
    this.jobSearchResult = params.data;
    if (!this.jobSearchResult?.logSummary) {
      return;
    }
    this.logSummary = this.jobSearchResult.logSummary;
    this.pipelineName = this.jobSearchResult
      ? getFriendlyJobName(
          this.jobSearchResult.config.pipeline.name as NucleusPipelineID,
          this.jobSearchResult.config.parameters?.options,
        )
      : '';
    this.totalInsights = this.logSummary.warningCount + this.logSummary.infoCount;
    this.buttonData = {
      link: this.getJobDetailsLink(this.jobSearchResult),
      text:
        this.totalInsights > this.INSIGHT_LIMIT
          ? `+ ${this.totalInsights - this.INSIGHT_LIMIT} more...`
          : 'Details',
    };
  }

  agInit(params: ICellRendererParams) {
    this.update(params);
    this.cd.markForCheck();
  }

  getJobDetailsLink(job: Job) {
    return `/jobs/${job?.id}`;
  }

  refresh(params: ICellRendererParams): boolean {
    this.update(params.data);
    return true;
  }
}
