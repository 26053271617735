import { Search } from './search.model';
import { SAVE_REFERRER, SAVE_SEARCH, SearchActions } from './search.actions';
import { GlobalSearchTypes } from '../search-bar/search-bar.component';

const query = <Search.Query>{
  queryString: '',
  queryType: GlobalSearchTypes.FILE_FOLDER,
  pagination: {
    offset: 0,
    limit: 1000,
  },
};

// Currently results and searchInProgress parameters are unused.
const initialState: Search.State = {
  query: query,
  results: [],
  referrerPath: '/workspace',
  searchInProgress: false,
};

export function searchReducer(
  state: Search.State = initialState,
  action: SearchActions,
): Search.State {
  switch (action.type) {
    case SAVE_SEARCH:
      return Object.assign({}, state, action.payload);
    case SAVE_REFERRER:
      return Object.assign({}, state, { referrerPath: action.payload });
    default:
      return state;
  }
}
