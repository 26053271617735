<div class="bx-page-container">
  <h3 class="mb-4">Bioregister Configuration</h3>
  <section>
    <form name="bioregister-config" [formGroup]="form">
      <div class="mb-3 row">
        <label for="bioregister-url" class="col-md-4 col-lg-3 col-form-label">URL</label>
        <div class="col-md-8 col-lg-9">
          <input
            type="url"
            id="bioregister-url"
            name="bioregister-url"
            formControlName="bioregisterURL"
            [formControl]="form.controls.bioregisterURL"
            class="form-control"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
          @if (form.controls.bioregisterURL.errors?.['required']) {
            <div class="invalid-feedback">URL is required.</div>
          }
          @if (form.controls.bioregisterURL.errors?.['pattern']) {
            <div class="invalid-feedback">URL is invalid.</div>
          }
        </div>
      </div>
      <div class="mb-3 row">
        <label for="sequenceEntityName" class="col-md-4 col-lg-3 col-form-label"
          >Sequence Entity Name</label
        >
        <div class="col-md-8 col-lg-9">
          <input
            id="sequenceEntityName"
            type="text"
            name="sequenceEntityName"
            [formControl]="form.controls.sequenceEntity.controls.name"
            class="form-control"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label for="sequenceEntityID" class="col-md-4 col-lg-3 col-form-label"
          >Sequence Entity ID</label
        >
        <div class="col-md-8 col-lg-9">
          <input
            id="sequenceEntityID"
            type="number"
            name="sequenceEntityID"
            [formControl]="form.controls.sequenceEntity.controls.id"
            class="form-control"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
          @if (form.controls.sequenceEntity.errors?.['idOrNameRequired']) {
            <div class="invalid-feedback">
              Either 'Sequence Entity Name' or 'Sequence Entity ID' is required
            </div>
          }
          @if (form.controls.sequenceEntity.controls.id.errors?.['pattern']) {
            <div class="invalid-feedback">'Sequence Entity ID' should be a number</div>
          }
        </div>
      </div>

      <div class="mb-3 row">
        <label for="complexEntityName" class="col-md-4 col-lg-3 col-form-label"
          >Complex Entity Name</label
        >
        <div class="col-md-8 col-lg-9">
          <input
            id="complexEntityName"
            type="text"
            name="complexEntityName"
            [formControl]="form.controls.complexEntity.controls.name"
            class="form-control"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label for="complexEntityID" class="col-md-4 col-lg-3 col-form-label"
          >Complex Entity ID</label
        >
        <div class="col-md-8 col-lg-9">
          <input
            id="complexEntityID"
            type="number"
            name="complexEntityID"
            [formControl]="form.controls.complexEntity.controls.id"
            class="form-control"
            ngFormControlValidator
            [validateOnBlur]="true"
          />
          @if (form.controls.complexEntity.errors?.['idOrNameRequired']) {
            <div class="invalid-feedback">
              Either 'Complex Entity Name' or 'Complex Entity ID' is required
            </div>
          }
          @if (form.controls.complexEntity.controls.id.errors?.['pattern']) {
            <div class="invalid-feedback">'Complex Entity ID' should be a number</div>
          }
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-4 col-lg-3 col-form-label">Mappings</label>
        <div class="col-md-8 col-lg-9">
          <bx-collapsible-card
            headerTitle="How do mappings work?"
            [collapseByDefault]="true"
            class="mb-3"
          >
            <div>
              Mappings define how Geneious Biologics columns get mapped to Bioregister entity
              values. Mappings are defined separately for registering either a light chain, or a
              heavy chain. Each mapping includes a
              <code>BxColumnName</code>, which specifies the name of the Biologics column whose
              value should be used and a <code>BioregisterField</code>, which specifies where to put
              the value in the Bioregister entity.
              <p></p>
              For example, the following snippet indicates that when registering a Heavy Chain
              sequence:
              <ul>
                <li>
                  The <code>Heavy CDR1</code> column value will be registered under the
                  <code>cdr1</code> field of the Bioregister entity.
                </li>
                <li>
                  The <code>Heavy FR1</code> column value will be registered under the
                  <code>framework 1</code> field of the Bioregister entity.
                </li>
              </ul>
              <bx-monospace-box>{{ sampleMapping }}</bx-monospace-box>
              Notes:
              <ul>
                <li>These mappings apply to your entire organization.</li>
                <li>Each mapping must match both a Biologics column and a Bioregister field.</li>
                <li>
                  When registering a sequence containing both chains, a heavy and light chain will
                  be registered as two separate Bioregister entities.
                </li>
                <li>
                  Biologics column <strong>ids</strong> may sometimes not match the display names
                  shown in Biologics. To get the id of a column, use it in a filter query, the
                  filter query will specify the correct id.
                </li>
              </ul>
            </div>
          </bx-collapsible-card>
          <bx-editable-json-area
            [bxFormControl]="form.controls.mappingConfiguration"
          ></bx-editable-json-area>
          <hr />
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-primary"
          (click)="onSubmit()"
          [disabled]="this.saveButtonDisabled$ | async"
        >
          Save
        </button>
      </div>
    </form>
  </section>
</div>
