@if (resultNameFormControl) {
  <bx-pipeline-output-name-control
    [nameFormControl]="resultNameFormControl"
    inputID="resultName"
    displayName="Result"
    tooltip="The file name of the result produced by the pipeline"
    [ngClass]="{ 'mb-3': outputNameFormControl }"
  ></bx-pipeline-output-name-control>
}

@if (outputNameFormControl) {
  <bx-pipeline-output-name-control
    [nameFormControl]="outputNameFormControl"
    inputID="outputName"
    displayName="Output folder"
    tooltip="(Optional) Put results in a new subfolder with the specified name"
  ></bx-pipeline-output-name-control>
}
