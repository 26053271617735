import { switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectFolderExpandState } from './expand.selectors';
import { fetchFolderChildren, stopWatchingFolderChildren } from './folder.actions';
import { selectFolder } from './folder.selectors';
import { toggleExpand } from './expand.actions';

@Injectable()
export class ExpandEffects {
  readonly observeExpandedFolder$ = createEffect(() =>
    this.actions.pipe(
      ofType(toggleExpand),
      mergeMap((action) => this.store.select(selectFolder(action.id)).pipe(take(1))),
      filter((folder) => !!folder),
      switchMap((folder) =>
        this.store.select(selectFolderExpandState(folder.id)).pipe(
          take(1),
          map((folderExpanded) => ({ folderID: folder.id, folderExpanded })),
        ),
      ),
      map(({ folderID, folderExpanded }) => {
        if (folderExpanded) {
          return fetchFolderChildren({ id: folderID });
        }
        return stopWatchingFolderChildren({ id: folderID });
      }),
    ),
  );

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
  ) {}
}
