import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { isViewerDocumentData, ViewerDocumentData } from '../viewer-document-data';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TableParserService } from '../../assay-data-v2/table-parser/table-parser.service';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { ColDef } from '@ag-grid-community/core';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import {
  ParsedTable,
  ParsedTableRow,
  ParsedTablesResult,
} from '../../assay-data-v2/table-parser/table-parser.model';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ClientGridComponent } from '../../../features/grid/client-grid/client-grid.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@ViewerComponent({
  key: 'table-viewer',
  title: 'Table Viewer',
  selector: (data: ViewerDocumentData) => {
    return (
      isViewerDocumentData(data) &&
      data.selection.rows.length === 1 &&
      (data.selection.rows[0].getAllFields().documentType === 'CSV' ||
        data.selection.rows[0].getAllFields().documentType === 'EXCEL')
    );
  },
})
@Component({
  selector: 'bx-table-viewer',
  templateUrl: './table-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FaIconComponent,
    ClientGridComponent,
    PageMessageComponent,
    LoadingComponent,
    AsyncPipe,
  ],
})
export class TableViewerComponent implements OnInit {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden';
  private readonly maxRowsToShow = 1000;

  tableRows$: Observable<ParsedTableRow[]>;
  tableHeaders$: Observable<ColDef[]>;
  message$: Observable<string>;
  isLoading$: Observable<boolean>;
  numberOfTables$: Observable<number>;
  currentTable$: Observable<ParsedTable>;
  currentTableIndex$: BehaviorSubject<number>;

  // Optional to display a table from a parsed file instead of the document id.
  @Input() tablesToShow$: Observable<ParsedTablesResult> = null;

  constructor(
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentHttpService: DocumentHttpV2Service,
    private tableParserService: TableParserService,
  ) {}

  ngOnInit(): void {
    let tables$: Observable<ParsedTablesResult> = this.getTables();

    this.currentTableIndex$ = new BehaviorSubject<number>(0);

    this.currentTable$ = combineLatest([this.currentTableIndex$, tables$]).pipe(
      map(([index, parsedTableResult]) => {
        return parsedTableResult.tables[index];
      }),
    );

    this.numberOfTables$ = tables$.pipe(map((parsedResult) => parsedResult.tables.length));

    this.message$ = this.currentTable$.pipe(
      map((table) => {
        if (table.rows.length > this.maxRowsToShow) {
          return `Showing ${this.maxRowsToShow} of ${table.rows.length} rows from '${table.displayName}'`;
        } else {
          return `Showing ${table.rows.length} rows from '${table.displayName}'`;
        }
      }),
    );

    this.tableRows$ = this.currentTable$.pipe(
      map((table) => table.rows.slice(0, this.maxRowsToShow)),
    );

    this.tableHeaders$ = this.currentTable$.pipe(
      map((table) => table.columns.map((row) => this.toColumnDef(row))),
    );

    this.isLoading$ = merge(of(true), this.currentTable$.pipe(map(() => false)));
  }

  nextTable() {
    this.currentTableIndex$.next(this.currentTableIndex$.value + 1);
  }

  previousTable() {
    this.currentTableIndex$.next(this.currentTableIndex$.value - 1);
  }

  toColumnDef(columnName: string): ColDef {
    return {
      field: columnName,
      headerName: columnName,
      resizable: true,
    };
  }

  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;

  private getTables(): Observable<ParsedTablesResult> {
    // If the tablesToShow input is provided, use that.
    if (this.tablesToShow$ != null) {
      return this.tablesToShow$;
    }

    // Otherwise, download and parse the tablesToShow based on the document id.
    const documentID$ = this.viewerDataService.getData('table-viewer').pipe(
      map((data) => data.selection.rows[0].id),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return documentID$.pipe(
      switchMap((documentID) =>
        combineLatest([
          this.documentHttpService.getDocumentPart(documentID, 'FILE', 'blob'),
          this.documentHttpService.get(documentID),
        ]),
      ),
      switchMap(([blob, document]) =>
        this.tableParserService.parse(new File([blob], document.name)),
      ),
      tap(() => this.currentTableIndex$.next(0)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
