import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import {
  GridComponent,
  SelectionStateV2,
  selectionStateV2ToSelectionState,
} from '../../../features/grid/grid.component';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { DocumentService } from '../../../../nucleus/services/documentService/document-service.v1';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  publishReplay,
  refCount,
  share,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Column, GridOptions } from '@ag-grid-community/core';
import { GridState, SortModel } from '../../../features/grid/grid.interfaces';
import { NgsSequenceViewerService } from '../ngs-sequence-viewer.service';
import { DocumentTablePickerComponent } from '../../document-table-service/document-table-picker/document-table-picker.component';
import { CleanUp } from '../../../shared/cleanup';
import { CursorDocumentQuery } from '../../../../nucleus/services/documentService/document-service.v1.http';
import { getRowIdentifier } from '../getRowIdentifier';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { FolderTreeItem } from '../../folders/models/folder.model';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
  parseToDocumentServiceColumns,
} from '../../../../nucleus/services/documentService/document-service.resource';
import { NgsFilterComponent } from '../ngs-filter/ngs-filter.component';
import { ViewerComponent, ViewersOverlays } from '../../viewers-v2/viewers-v2.config';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { selectionStateV2ToViewerMultipleTableDocumentSelection } from '../../viewer-components/viewers-helper';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import {
  annotatedPluginDocumentViewerSelector,
  masterDatabaseViewerSelector,
} from '../../viewer-components/viewer-selectors';
import {
  ViewerDocumentData,
  ViewerMultipleTableDocumentSelection,
  ViewerResultData,
} from '../../viewer-components/viewer-document-data';
import { annotatedResultViewerOverlays } from '../../viewer-components/viewer-overlays';
import { GenerateReportComponent } from '../../../features/report/generate-report/generate-report.component';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import {
  NgbModal,
  NgbModalRef,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  DocumentServiceTableInfoMap,
  DocumentTable,
} from '../../../../nucleus/services/documentService/types';
import {
  isAllSequencesTable,
  isChainCombinationsTable,
  isClusterTable,
  isComparisonClusterTable,
  isComparisonSummaryTable,
  isMasterDatabaseTable,
} from '../table-type-filters';
import { DocumentTableServiceGridContextMenuItems } from '../../document-table-service/grid-context-menu-item/document-table-service-grid-context-menu-item';
import { PipelineDialogData } from '../../pipeline-dialogs';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { documentTableViewerTitle } from '../../viewer-components/viewer-titles-functions';
import { MasterDatabaseFormDialogComponent } from '../../master-database/master-database-form-dialog/master-database-form-dialog.component';
import { DocumentTableViewerService } from '../../document-table-service/document-table-viewer.service';
import { OrgProfileCheckService } from '../../../shared/access-check/org-profile-check/org-profile-check.service';
import { ExtractReanalyzeComponent } from '../../pipeline-dialogs/extract-reanalyze/extract-reanalyze.component';
import { RegisterSequencesComponent } from '../../pipeline-dialogs/register-sequences/register-sequences.component';
import { NucleusPipelineID, PipelineFormID } from '../../pipeline/pipeline-constants';
import { getAllColumnsWithJoins } from '../../document-table-service/document-table-columns/get-all-columns-with-joins';
import { isColGroupDef } from '../../folders/models/colDefs';
import {
  ExportSequencesComponent,
  ExportSequencesDialogData,
} from '../../pipeline-dialogs/export-v2/export-sequences/export-sequences.component';
import {
  ExportNGSTableComponent,
  ExportNGSTableDialogData,
} from '../../pipeline-dialogs/export-v2/export-ngs-table/export-ngs-table.component';
import { ExtractSequencesDialogOptionsV3 } from 'src/nucleus/services/models/extractSequencesV3.model';
import {
  RestoreProgress,
  NgsTableRestoringOverlayComponent,
} from '../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import {
  DocumentActivityEventKind,
  JobActivityEventKind,
} from '../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  JobEventType,
  JobQueuedActivityEvent,
} from '../../../../nucleus/v2/models/activity-events/job-activity-event.model';
import { AnnotatorParamsService } from '../../pipeline-dialogs/extract-reanalyze/annotator-params.service';
import {
  AssayDataDialogState,
  AssayDataV2Component,
} from '../../assay-data-v2/assay-data-v2.component';
import {
  BulkAddLabelsComponent,
  BulkAddLabelsInputs,
} from '../../label/bulk-add-labels/bulk-add-labels.component';
import { MAX_ROWS_IN_BULK_UPDATE } from '../../document-table-edits/document-table-edits.reducer';
import { partitionArray } from '../../../../bx-common-extensions/array';
import { AntibodyAnnotatorOptionValues } from '../../pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import {
  RegisterSequencesLumaComponent,
  RegisterSequencesLumaDialogData,
} from '../../pipeline-dialogs/register-sequences-luma/register-sequences-luma.component';
import { AlignmentSequenceOptions } from '../../../../nucleus/services/models/alignmentOptions.model';
import { AsyncPipe } from '@angular/common';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { NgsPostProcessingPipelinesChooserComponent } from '../ngs-post-processing-pipelines-chooser/ngs-post-processing-pipelines-chooser.component';
import { TableProcessingNotifierComponent } from '../../document-service/table-processing-notifier/table-processing-notifier.component';
import { AngularSplitModule } from 'angular-split';
import { ViewersStateDirective } from '../../viewers-state/viewers-state.directive';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { NotesEditHandlerDirective } from '../../document-table-service/notes-edit-handler/notes-edit-handler.directive';
import { ViewersComponent } from '../../viewers-v2/viewers/viewers.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
const DIALOGS = [
  'addAssayData',
  'addLabels',
  'addSequences',
  'exportSequences',
  'exportTable',
  'extractRecluster',
  'generateReport',
  'registerSequences',
  'registerSequencesLuma',
] as const;
export type DialogKey = (typeof DIALOGS)[number];

@ViewerComponent({
  key: 'ngs-sequences-table-viewer',
  title: documentTableViewerTitle,
  selectors: [
    annotatedPluginDocumentViewerSelector(
      [
        DocumentSelectionSignature.forDocumentClass(
          'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
          1,
          1,
        ),
        DocumentSelectionSignature.forDocumentClass(
          'com.biomatters.plugins.nextgenBiologics.AntibodyComparisonDocument',
          1,
          1,
        ),
      ],
      (viewerData) => {
        return !viewerData.isPreviewView;
      },
    ),
    masterDatabaseViewerSelector((viewerData) => !viewerData.isPreviewView),
  ],
})
@Component({
  selector: 'bx-ngs-sequences-table-viewer-v2',
  templateUrl: './ngs-sequences-table-viewer-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentServiceResource, DocumentTableViewerService],
  standalone: true,
  imports: [
    ToolstripComponent,
    DocumentTablePickerComponent,
    ToolstripItemComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgsPostProcessingPipelinesChooserComponent,
    TableProcessingNotifierComponent,
    NgsFilterComponent,
    AngularSplitModule,
    ViewersStateDirective,
    PageMessageComponent,
    GridComponent,
    NotesEditHandlerDirective,
    NgsTableRestoringOverlayComponent,
    ViewersComponent,
    LoadingComponent,
    AsyncPipe,
  ],
})
export class NgsSequencesTableViewerV2Component extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden';
  readonly RESULT_SET_MAX = DocumentServiceResource.RESULT_SET_MAX;
  readonly CLUSTER_FULL_INFO_LIMIT = 1000;

  /** Filter received _from_ the filter component; asking the grid to perform it. */
  readonly filter$ = new BehaviorSubject('');
  /** Tell the filter component what value _to_ have. */
  readonly filterValue$ = new BehaviorSubject('');
  sequenceViewerDatasource: NgsSequenceViewerService;

  selectedTable$: ReplaySubject<DocumentTable> = new ReplaySubject<DocumentTable>();

  @ViewChild(GridComponent) gridComponent: GridComponent;
  @ViewChild(DocumentTablePickerComponent, { static: true })
  regionPicker: DocumentTablePickerComponent;
  @ViewChild(NgsFilterComponent) filterComponent: NgsFilterComponent;

  // Grid things.
  gridDatasource: DocumentServiceResource;
  gridDatasourceParams$: Observable<DocumentDatasourceParams>;
  gridOptions: GridOptions = {
    cacheBlockSize: 1000,
  };
  tableType$: Observable<any>;
  gridState$ = new ReplaySubject<GridState>(1);
  additionalMessage$: Observable<string>;

  document$: Observable<AnnotatedPluginDocument>;
  isAdminView$: Observable<boolean>;
  documentID$: Observable<string>;
  tableSelectionState$ = new BehaviorSubject<SelectionStateV2>(new SelectionStateV2());
  columns$ = new BehaviorSubject<Column[]>([]);
  sequenceMetadataOrder$: Observable<string[]>;

  pipelineQueryOptions$: Observable<CursorDocumentQuery>;
  sequencesExportOptions$: Observable<ExportSequencesDialogData>;
  ngsTableExportOptions$: Observable<ExportNGSTableDialogData>;
  extractOptions$: Observable<ExtractSequencesDialogOptionsV3>;
  alignmentSequenceOptions$: Observable<AlignmentSequenceOptions>;

  // Required for export (shouldn't this be in document selection state?).
  folderID$: Observable<string>;
  folder$: Observable<FolderTreeItem>;

  showAddAssayDataButton$: Observable<boolean>;
  addAssayDataButtonDisabled$: Observable<boolean>;
  exportSequencesButtonDisabled$: Observable<boolean>;
  exportTableButtonDisabled$: Observable<boolean>;
  generateReportButtonDisabled$: Observable<boolean>;
  generateReportTooltip$: Observable<string>;
  readonly showTableProcessingNotifier$ = this.completeOnDestroy(new BehaviorSubject(false));

  resourceError$: Observable<any>;

  canWrite$: Observable<boolean>;
  readOnly$: Observable<boolean>;

  bulkAddLabelsButton$: Observable<{ disableButton: boolean; tooltipMessage: string }>;

  ngsTableViewersData$: Observable<ViewerResultData>;
  readonly viewersOverlays: ViewersOverlays = annotatedResultViewerOverlays;
  disableAllPipelines$: Observable<boolean>;

  restoreProgress$: Observable<RestoreProgress>;
  tablesLoaded$: Observable<boolean>;
  tablesLoadingError$: Observable<string>;
  tableLoaded$: Observable<boolean>;
  gridContextMenuItems: DocumentTableServiceGridContextMenuItems;

  tableProfileKey$: Observable<'resultDocument' | 'comparisonsResultDocument' | 'masterDatabase'>;
  viewersStateKey$: Observable<'ngsSequencesTable' | 'ngsComparisonsTable' | 'masterDatabaseTable'>;
  isAntibodyAnnotatorResult$: Observable<boolean>;
  isMasterDatabase$: Observable<boolean>;
  isFreeOrg$: Observable<boolean>;

  creationPipeline$: Observable<NucleusPipelineID>;
  creationPipelineOptions$: Observable<AntibodyAnnotatorOptionValues | undefined>;
  subsamplingTooltip$: Observable<string>;
  extractReclusterButtonDisabled$: Observable<boolean>;

  registerSequenceTooltip$: Observable<string>;
  registerSequenceDisabled$: Observable<boolean>;
  showRegisterSequence$: Observable<boolean>;

  registerSequencesLumaTooltip$: Observable<string>;
  registerSequencesLumaDisabled$: Observable<boolean>;
  showRegisterSequencesLuma$: Observable<boolean>;

  private defaultTable$: Observable<DocumentTable | undefined>;

  private readonly openDialogEvent$ = new Subject<DialogKey>();

  private dialogRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private pipelineDialogService: PipelineDialogService,
    private featureSwitchService: FeatureSwitchService,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentService: DocumentService,
    private documentServiceResource: DocumentServiceResource,
    private ngsSequenceViewerService: NgsSequenceViewerService,
    private documentTableViewerService: DocumentTableViewerService,
    private documentTableStateService: DocumentTableStateService,
    private orgProfileCheckService: OrgProfileCheckService,
    private activityStreamService: ActivityStreamService,
    private annotatorParamsService: AnnotatorParamsService,
  ) {
    super();

    this.disableAllPipelines$ = this.featureSwitchService.isEnabledOnce('disableAllPipelines');
    this.document$ = this.viewerDataService.getData('ngs-sequences-table-viewer').pipe(
      map((data) => data.selection),
      map((selection) => selection.rows[0]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.isAdminView$ = this.viewerDataService.getData('ngs-sequences-table-viewer').pipe(
      map((data) => data.isAdminView),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.documentID$ = this.document$.pipe(
      map((document) => document.id),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    const documentType$: Observable<'ngsResult' | 'ngsComparison' | 'masterDatabase'> =
      this.document$.pipe(
        map((document) => document.type as 'ngsResult' | 'ngsComparison' | 'masterDatabase'),
      );

    this.tableProfileKey$ = documentType$.pipe(
      map((documentType) => {
        switch (documentType) {
          case 'ngsResult':
            return 'resultDocument';
          case 'ngsComparison':
            return 'comparisonsResultDocument';
          case 'masterDatabase':
            return 'masterDatabase';
        }
      }),
    );

    this.viewersStateKey$ = documentType$.pipe(
      map((documentType) => {
        switch (documentType) {
          case 'ngsResult':
            return 'ngsSequencesTable';
          case 'ngsComparison':
            return 'ngsComparisonsTable';
          case 'masterDatabase':
            return 'masterDatabaseTable';
        }
      }),
    );

    this.isAntibodyAnnotatorResult$ = documentType$.pipe(
      map((documentType) => documentType === 'ngsResult'),
    );

    this.isMasterDatabase$ = documentType$.pipe(
      map((documentType) => documentType === 'masterDatabase'),
    );

    this.isFreeOrg$ = orgProfileCheckService.hasOrgProfileCategoryOnce('free');

    this.gridDatasource = this.documentServiceResource;

    // For most results this would be the `DOCUMENT_TABLE_ALL_SEQUENCES` table.
    // For Comparison results this would be the `Summary` table.
    // For Collections this would be the `MASTER_DATABASE` table.
    // For now, we are only supporting the `DOCUMENT_TABLE_ALL_SEQUENCES` for
    // the intended feature of this observable: 'View Sequences in Clusters'.
    this.defaultTable$ = this.documentID$.pipe(
      withLatestFrom(documentType$),
      switchMap(([docID, documentType]) =>
        this.documentTableStateService
          .getQueryableTable(docID, this.getDefaultTable(documentType))
          // If the table doesn't exist, it will throw an error.
          .pipe(catchError(() => of(undefined))),
      ),
    );

    this.resourceError$ = this.documentServiceResource.onError();
    this.gridContextMenuItems = new DocumentTableServiceGridContextMenuItems(
      {
        setFilter: (filter) => this.filterValue$.next(filter),
        jumpToSequences: (filter, table) => this.jumpToSequences(filter, table),
        openDialog: (dialog) => this.openDialogEvent$.next(dialog),
      },
      this.tableSelectionState$,
      this.selectedTable$,
      this.defaultTable$,
    );
  }

  hasSCFV(table: DocumentTable) {
    const [heavyChains, _] = partitionArray(
      table.metadata?.chainNamesPossiblyPresent || [],
      (chain) => chain.toLowerCase().startsWith('heavy'),
    );
    // For now hardcoded that anything that's not VHH-VHH will have scfv export option.
    // In the future, we should fetch sequences regions & actually verify if scfv region existed for export or not.
    return heavyChains.length < 2;
  }
  isPeptideOrProteinResult(creationOptions: AntibodyAnnotatorOptionValues) {
    return creationOptions?.sequences_chain === 'genericSequence';
  }

  ngOnInit() {
    const allSequencesTableSelected$ = this.selectedTable$.pipe(map(isAllSequencesTable));
    const comparisonsSummaryTableSelected$ = this.selectedTable$.pipe(
      map(isComparisonSummaryTable),
    );

    this.tableType$ = this.document$.pipe(
      // TODO How can we make a better key?
      switchMapTo(this.selectedTable$),
      map((table) => table.name),
    );

    // Step towards removing the need for this completely.
    // Grid currently uses this (not datasource$ as we want it to) to determine when document selection changes.
    this.gridDatasourceParams$ = combineLatest([
      this.filter$,
      this.documentID$,
      this.selectedTable$,
    ]).pipe(
      map(([filterModel, documentID, selectedTable]) => ({
        filterModel,
        documentTableName: selectedTable.name,
        documentId: documentID,
      })),
    );

    this.additionalMessage$ = combineLatest([this.selectedTable$, this.documentID$]).pipe(
      switchMap(([selectedTable, documentID]) =>
        isClusterTable(selectedTable)
          ? this.clusterLimitMessage(documentID, selectedTable.name)
          : of(''),
      ),
    );

    const fetchingState$ = this.documentID$.pipe(
      switchMap((documentID) => this.documentTableStateService.getTablesFetchingState(documentID)),
    );

    this.tablesLoadingError$ = fetchingState$.pipe(
      filter((fetchingState) => fetchingState.error != null),
      withLatestFrom(this.isMasterDatabase$),
      map(([fetchingState, isMasterDatabase]) =>
        isMasterDatabase && fetchingState.error === 'No tables exist on this document'
          ? 'masterDatabaseNoTables'
          : fetchingState.error,
      ),
    );

    this.tablesLoaded$ = fetchingState$.pipe(
      map((fetchingState) => !fetchingState.fetching && !fetchingState.error),
    );

    this.restoreProgress$ = this.selectedTable$.pipe(
      distinctUntilChanged(
        (table1, table2) => table1.documentID === table2.documentID && table1.name === table2.name,
      ),
      switchMap((table) =>
        this.documentTableStateService.getRestoreTableState(table.documentID, table.name),
      ),
      filter((restoredState) => !restoredState.restored),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.tableLoaded$ = merge(
      merge(this.documentID$, this.selectedTable$).pipe(mapTo(false)),
      this.selectedTable$.pipe(
        switchMap((table) =>
          this.documentTableStateService.getQueryableTable(table.documentID, table.name),
        ),
        mapTo(true),
      ),
    );

    this.folderID$ = this.document$.pipe(map((document) => document.parentID));

    this.folder$ = this.document$.pipe(
      map((document) => document.parent as FolderTreeItem),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.canWrite$ = combineLatest([this.folder$, this.isAdminView$]).pipe(
      map(([folder, isAdminView]) => folder.hasWriteAccess() && !isAdminView),
      startWith(false),
    );

    this.readOnly$ = combineLatest([this.folder$, this.isAdminView$]).pipe(
      map(([folder, isAdminView]) => !folder.hasWriteAccess() || isAdminView),
      startWith(true),
    );

    this.sequenceViewerDatasource = this.ngsSequenceViewerService;

    const allTablesInfo$ = this.documentID$.pipe(
      switchMap((documentID) => this.documentTableStateService.getTablesMap(documentID)),
    );

    // TODO Fix this monstrosity.
    //  Need to avoid `this.getPipelineQueryOptions` being called with the wrong allTablesInfo and selectedTable.
    this.pipelineQueryOptions$ = allTablesInfo$.pipe(
      switchMap((allTablesInfo) =>
        combineLatest([this.filter$, this.selectedTable$, this.gridState$]).pipe(
          withLatestFrom(this.documentID$),
          filter(
            ([[filterModel, selectedTable, gridState], documentID]) =>
              selectedTable.documentID === documentID,
          ),
          map(([[filterModel, selectedTable, gridState]]) => ({
            filterModel,
            selectedTable,
            gridState,
          })),
          map(({ filterModel, selectedTable, gridState }) =>
            this.getPipelineQueryOptions(
              gridState.columnsState
                .filter((column) => column.sort)
                .sort((a, b) => a.sortIndex - b.sortIndex)
                .map((column) => ({ colId: column.colId, sort: column.sort })),
              selectedTable,
              filterModel,
              allTablesInfo,
            ),
          ),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.alignmentSequenceOptions$ = combineLatest([this.document$, this.selectedTable$]).pipe(
      map(([document, table]) => {
        const hasSCFV = this.hasSCFV(table);
        const isPeptideOrProteinResult = this.isPeptideOrProteinResult(
          document.getAnnotatorCreationOptions(),
        );
        return {
          hasSCFV,
          isGenericSequenceChainDocument: isPeptideOrProteinResult,
        };
      }),
    );

    this.ngsTableExportOptions$ = combineLatest([
      this.pipelineQueryOptions$,
      this.selectedTable$,
      this.gridState$,
      this.document$,
      this.tableSelectionState$,
    ]).pipe(
      map(([tableQuery, table, gridState, document, selection]) => {
        return {
          gridState,
          tableDisplayName: table.displayName,
          tableName: table.name,
          tableQuery: tableQuery,
          documentID: document.id,
          selection: {
            // When array indices are used as IDs, they are numbers rather than strings
            ids: selection.ids.filter((id) => id != null).map((id) => id.toString()),
            selectAll: selection.selectAll,
          },
        };
      }),
    );

    this.sequencesExportOptions$ = combineLatest([
      this.pipelineQueryOptions$,
      this.document$,
      this.tableSelectionState$,
      this.selectedTable$,
      this.canWrite$,
    ]).pipe(
      map(([tableQuery, document, selection, table, canWrite]) => {
        const isPeptideOrProteinResult = this.isPeptideOrProteinResult(
          document.getAnnotatorCreationOptions(),
        );
        const hasSCFV = this.hasSCFV(table);
        const estimatedNumberOfSequencesMoreThan1000 =
          selection.totalSelected > 1000 || this.getNumberOfAssociatedSequences(selection) > 1000;
        return {
          exportPipeline: PipelineFormID.EXPORT_NGS_SEQUENCES,
          documentID: document.id,
          extractionData: {
            tableQuery,
            annotatorDocumentID: document.id,
            selection: {
              // When array indices are used as IDs, they are numbers rather than strings
              ids: selection.ids.filter((id) => id != null).map((id) => id.toString()),
              selectAll: selection.selectAll,
            },
            table,
            hasSCFV,
            isGenericSequenceChainDocument: isPeptideOrProteinResult,
            isFromCombinedSequencesAlignment: false,
            isAlignmentContainsCombinedSequenceIDs: false,
          },
          estimatedNumberOfSequencesMoreThan1000,
          isReadOnlyFolder: !canWrite,
        };
      }),
    );

    this.extractOptions$ = combineLatest([
      this.pipelineQueryOptions$,
      this.documentID$,
      this.tableSelectionState$,
      this.selectedTable$,
    ]).pipe(
      map(([query, documentID, ngsSelectionState, selectedTable]) => {
        return {
          documentTableName: selectedTable.name,
          documentTableQuery: query,
          selection: {
            documentID: documentID,
            selectAll: ngsSelectionState.selectAll,
            ids: this.getRobustIDs(ngsSelectionState.rows, selectedTable),
          },
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // Clear filter if a different table name is selected.
    this.selectedTable$
      .pipe(distinctUntilKeyChanged('name'), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.filterComponent) {
          this.filterComponent.clearFilter();
        }
      });

    // Handle open dialog events
    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'exportSequences'),
        withLatestFrom(this.folderID$, this.tableSelectionState$, this.sequencesExportOptions$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, folderID, selectionState, options]) => {
        const component = ExportSequencesComponent;
        this.openPipelineModal(
          this.getJobDialogVariables(component, folderID, selectionState, options),
        );
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'exportTable'),
        withLatestFrom(this.folderID$, this.tableSelectionState$, this.ngsTableExportOptions$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, folderID, selectionState, options]) => {
        const component = ExportNGSTableComponent;
        this.openPipelineModal(
          this.getJobDialogVariables(component, folderID, selectionState, options),
        );
      });

    this.creationPipeline$ = this.document$.pipe(
      map((doc) => doc.getAllFields()),
      map((metadata) => metadata.originalAnnotatorPipeline),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.creationPipelineOptions$ = this.document$.pipe(
      map((doc) => doc.getAnnotatorCreationOptions()),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'extractRecluster'),
        withLatestFrom(
          this.folderID$,
          this.tableSelectionState$,
          this.extractOptions$,
          this.creationPipelineOptions$,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, folderID, selectionState, extractOptions, creationPipelineOptions]) => {
        const options = this.getJobDialogVariables(
          ExtractReanalyzeComponent,
          folderID,
          selectionState,
          {
            ...extractOptions,
            creationPipelineOptions: creationPipelineOptions,
          },
        );
        this.openPipelineModal(options);
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'generateReport'),
        withLatestFrom(this.document$, this.folderID$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, document, folderId]) => {
        this.dialogRef = this.modalService.open(GenerateReportComponent, { size: 'xl' });
        Object.assign(this.dialogRef.componentInstance, { document, folderId });
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'registerSequences'),
        withLatestFrom(
          this.document$,
          this.folderID$,
          this.tableSelectionState$,
          this.selectedTable$,
          this.defaultTable$,
          this.extractOptions$,
          allTablesInfo$,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        ([
          _,
          document,
          folderID,
          selectionState,
          selectedTable,
          defaultTable,
          extractionOptions,
          allTablesInfo,
        ]) => {
          const clusterColumns = selectedTable.columns;
          const defaultTableColumns = defaultTable.columns;
          const joinedColumns = parseToDocumentServiceColumns(
            getAllColumnsWithJoins(defaultTable.name, allTablesInfo)
              .filter(isColGroupDef)
              .flatMap((group) => group.children),
          );
          const isProteinDocument = document.hasNoNucleotides();
          const options = this.getJobDialogVariables(
            RegisterSequencesComponent,
            folderID,
            selectionState,
            {
              clusterColumns,
              defaultTableColumns,
              extractionOptions,
              isProteinDocument,
              joinedColumns,
            },
          );
          this.openPipelineModal(options);
        },
      );

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'registerSequencesLuma'),
        withLatestFrom(
          this.folderID$,
          this.tableSelectionState$,
          this.document$,
          this.pipelineQueryOptions$,
          this.selectedTable$,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, folderId, tableSelection, document, tableQuery, table]) => {
        const options: RegisterSequencesLumaDialogData = {
          fileSelection: { folderId, ids: [document.id], selectAll: false },
          table: {
            type: table.tableType,
            name: table.name,
            displayName: table.displayName,
            query: tableQuery,
            selection: tableSelection,
          },
        };
        this.openPipelineModal(
          this.getJobDialogVariables(
            RegisterSequencesLumaComponent,
            folderId,
            tableSelection,
            options,
          ),
        );
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'addSequences'),
        withLatestFrom(this.document$, this.folder$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, document, folder]) => {
        this.openPipelineModal({
          component: MasterDatabaseFormDialogComponent,
          folderID: folder.id,
          selected: {
            selectedRows: [document],
            ids: [document.id],
            noOfRowsSelected: 1,
            totalNoOfRows: 1,
            selectAll: false,
            firstRow: document,
          },
        });
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'addAssayData'),
        withLatestFrom(this.document$, this.selectedTable$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, document, documentTable]) => {
        this.dialogRef = this.modalService.open(AssayDataV2Component, {
          size: 'lg',
          backdrop: 'static',
        });
        const data: AssayDataDialogState = {
          document,
          documentTable,
          resource: this.gridDatasource,
        };
        Object.assign(this.dialogRef.componentInstance, data);
      });

    this.openDialogEvent$
      .pipe(
        filter((dialog) => dialog === 'addLabels'),
        withLatestFrom(this.selectedTable$, this.tableSelectionState$, this.pipelineQueryOptions$),
        map(([_, documentTable, selectionState, query]) => ({
          documentTable,
          selection: {
            rowNumbers: selectionState.rows.map((row) => row.row_number as number),
            selectAll: selectionState.selectAll,
            sqlQuery: query.where,
          },
          totalRowsSelected: selectionState.totalSelected,
        })),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((inputData: BulkAddLabelsInputs) => {
        this.dialogRef = this.modalService.open(BulkAddLabelsComponent);
        Object.assign(this.dialogRef.componentInstance, inputData);
      });

    this.bulkAddLabelsButton$ = this.tableSelectionState$.pipe(
      map((selectionState) => selectionState.totalSelected),
      distinctUntilChanged(),
      map((numRowsSelected) => {
        if (numRowsSelected <= 0) {
          return {
            tooltipMessage: 'Select the rows that you wish to label',
            disableButton: true,
          };
        }
        if (numRowsSelected > MAX_ROWS_IN_BULK_UPDATE) {
          return {
            tooltipMessage: `Only ${MAX_ROWS_IN_BULK_UPDATE} rows can be labeled at one time`,
            disableButton: true,
          };
        }
        return { tooltipMessage: 'Add labels to the current row selection', disableButton: false };
      }),
      startWith({ tooltipMessage: 'Add labels to the current row selection', disableButton: true }),
    );

    this.showAddAssayDataButton$ = combineLatest([
      this.isAntibodyAnnotatorResult$,
      this.isMasterDatabase$,
      this.isFreeOrg$,
    ]).pipe(
      map(
        ([isAAResult, isMasterDatabase, isFreeOrg]) =>
          (isAAResult || isMasterDatabase) && !isFreeOrg,
      ),
    );

    // When Assay Data button should be disabled.
    this.addAssayDataButtonDisabled$ = combineLatest(this.selectedTable$, this.canWrite$).pipe(
      map(([selectedTable, canWrite]) => {
        return (
          !canWrite ||
          (!isAllSequencesTable(selectedTable) &&
            !isChainCombinationsTable(selectedTable) &&
            !isMasterDatabaseTable(selectedTable))
        );
      }),
      startWith(true),
    );

    const noRowsSelected$: Observable<boolean> = this.tableSelectionState$.pipe(
      map((state) => state.totalSelected === 0),
    );

    this.exportTableButtonDisabled$ = noRowsSelected$.pipe(map((noRowsSelected) => noRowsSelected));

    this.exportSequencesButtonDisabled$ = combineLatest([
      noRowsSelected$,
      comparisonsSummaryTableSelected$,
    ]).pipe(
      map(
        ([noRowsSelected, comparisonsSummaryTableSelected]) =>
          noRowsSelected || comparisonsSummaryTableSelected,
      ),
      startWith(true),
    );

    this.generateReportButtonDisabled$ = combineLatest([this.canWrite$, this.isAdminView$]).pipe(
      map(([canWrite, isAdminView]) => isAdminView || !canWrite),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.generateReportTooltip$ = this.generateReportButtonDisabled$.pipe(
      map((isDisabled) =>
        isDisabled
          ? 'Cannot generate report on a document with read only status' // might also be due to viewing the doc in admin view, but that's kinda a read-only status too.
          : 'Generate a PDF report of this document',
      ),
      takeUntil(this.ngUnsubscribe),
    );

    this.ngsTableViewersData$ = combineLatest([
      this.tableSelectionState$.asObservable(),
      this.selectedTable$,
      this.columns$.asObservable(),
      this.document$.pipe(distinctUntilKeyChanged('id')),
      this.isAdminView$,
      this.filter$,
    ]).pipe(
      map(([state, selectedTable, columns, document, isAdminView, filter]) => {
        const selection: ViewerMultipleTableDocumentSelection =
          selectionStateV2ToViewerMultipleTableDocumentSelection(
            state,
            selectedTable.tableType,
            document,
          );

        return {
          selection: selection,
          resource: this.sequenceViewerDatasource,
          selectedTable: selectedTable,
          columns: columns.map((col) => ({
            colID: col.getColId(),
            headerName: col.getColDef().headerName,
          })),
          isAdminView: isAdminView,
          filter,
        };
      }),
      publishReplay(1),
      refCount(),
    );

    this.sequenceMetadataOrder$ = this.columns$.pipe(
      map((columns) => columns.map((col) => col.getColId())),
    );

    const isOldMotifAnnotatorResult$ = this.documentID$.pipe(
      switchMap((documentId) => this.annotatorParamsService.isOldMotifAnnotatorResult(documentId)),
      startWith(true),
      share(),
    );

    this.extractReclusterButtonDisabled$ = combineLatest([
      this.canWrite$,
      noRowsSelected$,
      allSequencesTableSelected$,
      isOldMotifAnnotatorResult$,
      this.creationPipelineOptions$,
    ]).pipe(
      map(
        ([
          canWrite,
          noRowsSelected,
          allSequencesTableSelected,
          isOldMotifAnnotatorResult,
          creationPipelineOptions,
        ]) =>
          !!creationPipelineOptions &&
          (!canWrite || noRowsSelected || !allSequencesTableSelected || isOldMotifAnnotatorResult),
      ),
      startWith(true),
      distinctUntilChanged(),
    );

    this.subsamplingTooltip$ = allSequencesTableSelected$.pipe(
      map((allSequencesTableSelected) => {
        if (allSequencesTableSelected) {
          return 'Extracts selected sequences, recalculates clusters for the subset, then saves results in a new Annotator document';
        } else {
          return 'Currently available for the main table only. To reanalyze a cluster, please select your clustered sequences in the All Sequences table using sorting or a filter';
        }
      }),
    );

    this.showRegisterSequence$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('registerSequencesBioregister'),
      this.isAntibodyAnnotatorResult$,
    ]).pipe(
      map(([registerSequencesEnabled, isAAResult]) => registerSequencesEnabled && isAAResult),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.registerSequenceTooltip$ = combineLatest([
      this.tableSelectionState$,
      allSequencesTableSelected$,
    ]).pipe(
      map(([selectionState, allSequencesTableSelected]) => {
        if (!allSequencesTableSelected) {
          return 'Select sequences from All Sequences table to register with Dotmatics Bioregister';
        } else if (selectionState.totalSelected === 0) {
          return 'Select one or more sequences to register with Dotmatics Bioregister.';
        } else {
          return 'Register selected sequences with Dotmatics Bioregister';
        }
      }),
    );

    this.registerSequenceDisabled$ = combineLatest([
      this.tableSelectionState$,
      allSequencesTableSelected$,
    ]).pipe(
      map(
        ([selectionState, allSequencesTableSelected]) =>
          selectionState.totalSelected === 0 || !allSequencesTableSelected,
      ),
      startWith(true),
    );

    this.registerSequencesLumaDisabled$ = combineLatest([
      this.tableSelectionState$,
      allSequencesTableSelected$,
      this.canWrite$,
    ]).pipe(
      map(
        ([selectionState, allSequencesTableSelected, canWrite]) =>
          selectionState.totalSelected === 0 || !allSequencesTableSelected || !canWrite,
      ),
      startWith(true),
      takeUntil(this.ngUnsubscribe),
    );

    this.showRegisterSequencesLuma$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('lumaIntegration'),
      this.isAntibodyAnnotatorResult$,
    ]).pipe(
      map(([registerSequencesEnabled, isAAResult]) => registerSequencesEnabled && isAAResult),
      takeUntil(this.ngUnsubscribe),
    );

    this.registerSequencesLumaTooltip$ = combineLatest([
      this.tableSelectionState$,
      allSequencesTableSelected$,
      this.canWrite$,
    ]).pipe(
      map(([selectionState, allSequencesTableSelected, canWrite]) => {
        if (!canWrite) {
          return "Write permissions are required to send this document's sequences to Luma";
        }
        if (!allSequencesTableSelected) {
          return 'Select sequences from All Sequences table to register in Luma';
        }
        if (selectionState.totalSelected === 0) {
          return 'Select one or more sequences to send to Luma';
        }
        return 'Send selected sequences to Luma';
      }),
    );

    const jobActivity$ = this.activityStreamService
      .listenToJobActivity()
      .pipe(map((activity) => activity.event));

    const documentActivity$ = this.documentID$.pipe(
      switchMap((documentID) => this.activityStreamService.listenToDocumentActivity(documentID)),
      map((activity) => activity.event),
    );

    documentActivity$
      .pipe(
        withLatestFrom(this.documentID$, this.selectedTable$),
        filter(
          ([event, _, selectedTable]) =>
            event.kind === DocumentActivityEventKind.TABLE_INDEX_COMPLETED &&
            event.tableName === selectedTable.name,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, documentID]) => this.documentTableStateService.refreshTables(documentID));

    jobActivity$
      .pipe(
        filter(
          (event): event is JobQueuedActivityEvent =>
            this.isRegisterSequenceJobQueued(event) || this.isAddClusterJobQueued(event),
        ),
        mergeMap((job) =>
          jobActivity$.pipe(
            filter(this.isJobCompletedEvent(job.jobID)),
            switchMap(() => this.documentID$),
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((documentID) => {
        this.documentTableStateService.refreshTables(documentID);
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.filter$.complete();
    this.filterValue$.complete();
    this.gridState$.complete();
    this.tableSelectionState$.complete();
    this.columns$.complete();
    this.selectedTable$.complete();
    this.gridContextMenuItems.cleanup();
    this.openDialogEvent$.complete();

    if (this.dialogRef) {
      this.dialogRef.dismiss();
    }
  }

  /** Public API **/

  handleColumnsChanged(columns: Column[]) {
    this.columns$.next(columns);
  }

  handleSelectionChanged(selectionState: SelectionStateV2) {
    this.tableSelectionState$.next(selectionState);
  }

  handleGridStateChanged(gridState: GridState) {
    this.gridState$.next(gridState);
  }

  /**
   * Called after the text in the filter field is changed, and we want to propagate this change to
   * the grid.
   * @param event
   */
  onFilterChange(event: string): void {
    this.filter$.next(event);
  }

  onRegionChanged(table: DocumentTable): void {
    this.tableSelectionState$.next(new SelectionStateV2());

    if (table) {
      this.selectedTable$.next(table);
      this.documentTableStateService.selectTable(table.documentID, table.name);
      this.documentTableViewerService.setSelectedTable(table);
    }
  }

  jumpToSequences(sequenceFilter: string, table: DocumentTable): void {
    this.selectedTable$.next(table);
    this.documentTableStateService.selectTable(table.documentID, table.name);
    this.documentTableViewerService.setSelectedTable(table);
    this.filterComponent.clearFilter();
    this.filterValue$.next(sequenceFilter);
    this.filter$.next(sequenceFilter);
  }

  openDialog(key: DialogKey) {
    if (!DIALOGS.includes(key)) {
      throw new Error('Invalid dialog key: ' + key);
    }
    this.openDialogEvent$.next(key);
  }

  getJobDialogVariables(
    modalComponent: Type<any>,
    folderId: string,
    state: SelectionStateV2,
    otherVariables: unknown,
  ): PipelineDialogData {
    return <PipelineDialogData>{
      component: modalComponent,
      folderID: folderId,
      selected: selectionStateV2ToSelectionState(state),
      otherVariables,
    };
  }

  /** Private API **/

  private openPipelineModal(dialogVariables: PipelineDialogData) {
    // TODO Extract the Nucleus file type validation service from PipelineChooserV1 to a service and share that here.
    // Note that we will probably need to move that logic to a server with DB access at some point, but that remains TBC.
    this.dialogRef = this.pipelineDialogService.showDialog(dialogVariables);
  }

  private getPipelineQueryOptions(
    sortModel: SortModel[],
    selectedTable: DocumentTable,
    filterModel: string,
    allTablesInfo: DocumentServiceTableInfoMap,
  ): CursorDocumentQuery {
    return {
      fields: [],
      orderBy: this.gridDatasource.getOrderByFromSortModel(
        sortModel,
        selectedTable.name,
        allTablesInfo,
      ),
      where: DocumentServiceResource.parseFilterModel(
        filterModel,
        this.gridDatasource.getCurrentColumns(),
      ),
      // Largest supported is 10000, but some tables have such large rows that it results in too much response data.
      // TODO the pipelines should be dictating this value, not the UI.
      cursorSize: 5000,
    };
  }

  /**
   * Get row ids in a flexible and manner that mimics the way pipelines get row ids.
   *
   * @param selectedRows represents the rows that are selected, unless selectAll = true, in which
   *     case these are the deselected rows.
   * @param selectedTable Indicates the table that the rows came from.
   * @returns {any}
   */
  private getRobustIDs(selectedRows: any[], selectedTable: DocumentTable): string[] {
    return selectedRows.map((row) => {
      const cluster =
        isClusterTable(selectedTable) || isComparisonClusterTable(selectedTable)
          ? selectedTable.name
          : undefined;
      return getRowIdentifier(row, cluster);
    });
  }

  // TODO Avoid making a duplicate query request here and instead somehow share the query data from the DocumentTableQueryService.
  private clusterLimitMessage(documentID: string, tableName: string): Observable<string> {
    // Query the first row in the table as we only need to get the `total` from the requests metadata  in the response.
    return this.documentService
      .queryTable(documentID, tableName, { fields: [], limit: 1 })
      .pipe(
        map((response) =>
          response.metadata.total > this.CLUSTER_FULL_INFO_LIMIT
            ? `Only first ${this.CLUSTER_FULL_INFO_LIMIT} clusters (by ID) have complete table information`
            : '',
        ),
      );
  }

  private isRegisterSequenceJobQueued(event: JobEventType): event is JobQueuedActivityEvent {
    return (
      event.kind === JobActivityEventKind.JOB_QUEUED &&
      event.jobConfig.pipeline.name === 'register-sequences'
    );
  }

  private isAddClusterJobQueued(event: JobEventType): event is JobQueuedActivityEvent {
    return (
      event.kind === JobActivityEventKind.JOB_QUEUED &&
      event.jobConfig.pipeline.name === 'add-cluster'
    );
  }

  private isJobCompletedEvent(jobID: string): (event: JobEventType) => boolean {
    return (event: JobEventType) => {
      return event.kind === JobActivityEventKind.JOB_COMPLETED && event.jobID === jobID;
    };
  }

  /**
   * Get the number of associated sequences in a selection.
   * It relies on associated sequences property of each row, which should be a list of number
   * separated by comma, indicating the sequences that associate with that row.
   *
   * @param selection Cluster table selection.
   * @private
   */
  private getNumberOfAssociatedSequences(selection: SelectionStateV2) {
    return selection.selectedRows.reduce((acc, row) => {
      const associatedSequences = NgsSequenceViewerService.getAssociatedSequencesProperty(row);
      if (!associatedSequences) {
        return acc;
      }
      return acc + associatedSequences.toString().split(',').length;
    }, 0);
  }

  private getDefaultTable(documentType: 'ngsResult' | 'ngsComparison' | 'masterDatabase') {
    if (documentType === 'ngsResult') {
      return 'DOCUMENT_TABLE_ALL_SEQUENCES';
    } else if (documentType === 'ngsComparison') {
      return 'DOCUMENT_TABLE_SUMMARY';
    }
    return 'MASTER_DATABASE';
  }
}
