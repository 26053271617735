import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { JobActivityEventKind } from '../../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { setJobStatus } from './job-websocket.actions';
import {
  clearAllJobs,
  clearNotInProgressJobs,
  fetchRunningJobsStatusesSuccess,
} from './jobs-status.actions';
import { JobsStatusStore, JobStatus } from './jobs-status.model';

export const adapter = createEntityAdapter<JobStatus>();

const initialState: JobsStatusStore = {
  ...adapter.getInitialState(),
};

const jobStatusesConsideredInProgress: readonly JobActivityEventKind[] = [
  JobActivityEventKind.JOB_QUEUED,
  JobActivityEventKind.JOB_STARTED,
  JobActivityEventKind.JOB_PROGRESSED,
  JobActivityEventKind.JOB_STAGE_INITIALIZED,
  JobActivityEventKind.JOB_STAGE_STARTED,
  JobActivityEventKind.JOB_STAGE_PROGRESSED,
];

export const jobsStatusReducer = createReducer(
  initialState,
  on(clearNotInProgressJobs, (state) => {
    return adapter.removeMany(
      (job) => !jobStatusesConsideredInProgress.includes(job.status),
      state,
    );
  }),
  on(clearAllJobs, () => initialState),
  on(setJobStatus, (state, job) => {
    return adapter.upsertOne(job, {
      ...state,
      // TODO business logic shouldn't be in reducers. This should probably be its own action.
      newJob: job.status === JobActivityEventKind.JOB_QUEUED ? job.id : undefined,
    });
  }),
  on(fetchRunningJobsStatusesSuccess, (state, { jobs }) => {
    return adapter.setAll(jobs, state);
  }),
);
