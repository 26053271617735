<div class="alert alert-warning" role="alert">
  Warning: Meta-data and modified column values will not be stored in the resulting Sequence list.
</div>

<form [formGroup]="form" class="px-2">
  <bx-pipeline-output
    [resultNameFormControl]="form.get('name')"
    [outputNameFormControl]="form.get('outputFolderName')"
  ></bx-pipeline-output>
</form>
