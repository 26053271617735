@if (playSnake) {
  <bx-snake (stop)="playSnake = false"> </bx-snake>
} @else {
  <bx-settings-breadcrumb
    [crumbs]="[{ link: ['', 'jobs'], label: 'Jobs' }]"
  ></bx-settings-breadcrumb>
  <bx-toolstrip>
    <bx-toolstrip-item>
      <button
        class="btn btn-light"
        title="View job details"
        [disabled]="selectionState?.noOfRowsSelected !== 1"
        (click)="showJobDetails()"
      >
        Details
      </button>
    </bx-toolstrip-item>
    <bx-toolstrip-item>
      <button
        id="jobs-table-cancel-button"
        class="btn btn-light"
        title="Cancel job"
        [disabled]="cancelButtonDisabled$ | async"
        (click)="cancelJob()"
      >
        Cancel
      </button>
    </bx-toolstrip-item>
    <bx-toolstrip-item>
      <button
        class="btn btn-light"
        title="Report a problem with the job"
        [disabled]="selectionState?.noOfRowsSelected !== 1"
        (click)="openJobFeedbackDialog()"
      >
        Report a problem
      </button>
    </bx-toolstrip-item>
    @if (runFromJsonEnabled$ | async) {
      <bx-toolstrip-item>
        <button
          class="btn btn-light"
          title="Run a job with the specified job parameters"
          (click)="openRunFromJson()"
          [disabled]="!selectionState || selectionState.noOfRowsSelected > 1"
        >
          {{
            !selectionState || selectionState.noOfRowsSelected === 0
              ? 'Run job from parameters'
              : 'Re-run job with edited parameters'
          }}
        </button>
      </bx-toolstrip-item>
    }
  </bx-toolstrip>
  <bx-grid
    class="w-100 flex-grow-1 flex-shrink-1"
    [initialColumnDefs]="columnDefs$ | async"
    [datasource]="datasource"
    [gridOptions]="gridOptions"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReadyEmitter)="listenToJobActivity()"
    tableType="jobs"
  >
  </bx-grid>
}
