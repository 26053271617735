import { createAction, props } from '@ngrx/store';
import { FolderKindsEnum } from '../models/folderKinds';

export const toggleExpand = createAction('[Folder Tree] Toggle Expand', props<{ id: string }>());

export const expand = createAction('[Folder Tree] Expand', props<{ ids: string[] }>());

export const startRename = createAction('[Folder Tree] Start Rename', props<{ id: string }>());

export const updateRename = createAction(
  '[Folder Tree] Update Name',
  props<{ newName?: string; error?: string }>(),
);

export const stopRename = createAction('[Folder Tree] Stop Rename');

export const startNewFolder = createAction(
  '[Folder Tree] Start New Folder',
  props<{ parentID: string; kind: FolderKindsEnum }>(),
);

export const updateNewFolder = createAction(
  '[Folder Tree] Update New Folder',
  props<{ name?: string; error?: string }>(),
);

export const stopNewFolder = createAction('[Folder Tree] Stop New Folder');

export const folderModified = createAction(
  '[Folder Tree] Folder Modified',
  props<{ time: number }>(),
);
