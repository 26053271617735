import { Action } from '@ngrx/store';
import * as NameSchemeEditor from './name-scheme-editor.reducers';
import { NameScheme } from '../../models/settings/name-scheme.model';

export const ACTION_PREFIX = '[name-scheme-editor]';
export const CREATE = ACTION_PREFIX + ' CREATE_USER';
export const FINALIZE_CREATION = ACTION_PREFIX + 'FINALIZE CREATION';
export const CANCEL = ACTION_PREFIX + 'CANCEL';
export const HANDLE_ERROR = ACTION_PREFIX + 'HANDLE ERROR';

export class Create implements Action {
  readonly type = CREATE;
  constructor(public payload: NameScheme) {}
}

export class FinalizeCreation implements Action {
  readonly type = FINALIZE_CREATION;
  constructor(public payload: NameSchemeEditor.State) {}
}

export class Cancel implements Action {
  readonly type = CANCEL;
  // the purpose of this action is to signal that the scheme creation process has cancelled so a payload isn't necessary
  constructor(public payload: any) {}
}

export class HandleError implements Action {
  readonly type = HANDLE_ERROR;
  constructor(public payload: { code: number; details: any }) {}
}

export type All = Create | FinalizeCreation | Cancel | HandleError;
