import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, map, startWith, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { MODAL_DATA } from 'src/app/shared/dialog';
import { BlastService } from '../../blast/blast.service';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { DatabaseFolder } from '../../folders/models/folder.model';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

type SubmitState = 'disabled' | 'enabled' | 'submitting' | 'error';

@Component({
  selector: 'bx-create-collections-folder-form',
  templateUrl: './create-collections-folder-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, FormsModule, ReactiveFormsModule, SpinnerButtonComponent, AsyncPipe],
})
export class CreateCollectionsFolderFormComponent extends CleanUp implements OnInit, OnDestroy {
  readonly form = new FormGroup({
    folderName: new FormControl(undefined, [Validators.required]),
  });

  readonly submitState$ = new BehaviorSubject<SubmitState>('disabled');
  readonly folder: DatabaseFolder;

  constructor(
    @Inject(MODAL_DATA) modalData: { folder: DatabaseFolder },
    private readonly modal: NgbActiveModal,
    private readonly blastService: BlastService,
  ) {
    super();
    this.folder = modalData.folder;
  }

  ngOnInit(): void {
    this.form.statusChanges
      .pipe(
        startWith(this.form.status),
        map((status) => (status === 'VALID' ? 'enabled' : 'disabled')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((submitState) => this.submitState$.next(submitState));
  }

  ngOnDestroy(): void {
    this.submitState$.complete();
  }

  submit() {
    const { folderName } = this.form.value;
    const databaseType = DatabaseTypeEnum.MASTER;
    this.submitState$.next('submitting');
    this.blastService.createBlast(this.folder.id, folderName, databaseType).subscribe({
      next: () => {
        this.folder.databaseType = databaseType;
        this.submitState$.next('enabled');
        this.close('completed');
      },
      error: () => this.submitState$.next('error'),
    });
  }

  close(reason: 'canceled' | 'completed') {
    this.submitState$.complete();
    if (reason === 'canceled') {
      this.modal.dismiss(reason);
    } else {
      this.modal.close();
    }
  }
}
