<bx-toolstrip>
  @if (showCreateNewMasterDatabaseButton) {
    <bx-toolstrip-item>
      <button class="btn btn-light" (click)="showCreateNewMasterDatabaseDialog()">
        New Collection
      </button>
    </bx-toolstrip-item>
  }

  <bx-toolstrip-item>
    <div class="btn-toolbar" role="toolbar">
      <div class="btn-group" role="group">
        @if (showUpload) {
          <button
            id="UploadButton"
            type="button"
            class="btn btn-light"
            ngbTooltip="Upload"
            placement="bottom"
            [disabled]="!canWrite()"
            [bxUploadButton]="folder.id"
            [bxUploadRoute]="folder?.route"
            bxDisableIf="disableAllPipelines"
          >
            <mat-icon class="mb-n1 bx-btn-icon">cloud_upload</mat-icon>
          </button>
        }
        @if (exportAndMoveShown$ | async) {
          <button
            type="button"
            ngbTooltip="Move"
            placement="bottom"
            id="moveDocsButton"
            data-testid="moveDocsButton"
            class="btn btn-light rounded-0"
            [disabled]="selected.totalSelected < 1 || !canWrite()"
            [ngbPopover]="moveDropdownTemplate"
            #moveDropdown="ngbPopover"
            autoClose="outside"
          >
            <mat-icon
              svgIcon="move"
              class="small mb-n2"
              [style.height]="'20px'"
              [style.width]="'20px'"
            ></mat-icon>
            <ng-template #moveDropdownTemplate>
              <bx-folder-tree-selector
                class="d-block h-static-2 overflow-y-auto"
                [singleSelectionMode]="true"
                [activeFolderID]="folder.id"
                [moveSubmitButton]="moveSubmitButton"
                [formControl]="selectedFolderIDsControl"
                data-testid="moveDocsFolderSelector"
              ></bx-folder-tree-selector>
              <div class="d-flex mt-3 justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  (click)="moveDropdown.close()"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-sm ms-2"
                  (click)="moveWidgetMove()"
                  bxDisableIf="disableAllPipelines"
                  data-testid="moveDocsSubmitBtn"
                  #moveSubmitButton
                >
                  Move {{ selected.ids.length }} file(s)
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger ms-2"
                  *bxShowIf="'disableAllPipelines'"
                  disabled="disabled"
                >
                  Readonly mode
                </button>
              </div>
            </ng-template>
          </button>
        }
        <button
          type="button"
          ngbTooltip="Rename"
          placement="bottom"
          class="btn btn-light"
          (click)="renameSomeWay()"
          [disabled]="
            selected.totalSelected < 1 ||
            !canWrite() ||
            (selected.totalSelected > 1 && !canBatchRename(selected))
          "
          bxDisableIf="disableAllPipelines"
          data-testid="rename-document"
        >
          <mat-icon class="mb-n1 bx-btn-icon">edit</mat-icon>
        </button>
        <button
          type="button"
          ngbTooltip="Delete"
          placement="bottom"
          class="btn btn-light"
          (click)="remove()"
          [disabled]="selected.totalSelected < 1 || !canWrite()"
          bxDisableIf="disableAllPipelines"
        >
          <mat-icon class="mb-n1 bx-btn-icon">delete</mat-icon>
        </button>
      </div>
    </div>
  </bx-toolstrip-item>

  @if (exportAndMoveShown$ | async) {
    <bx-toolstrip-item>
      <bx-export-toolstrip-item
        [gridState$]="gridState$"
        [folderID]="folder.id"
        [disabled]="exportDisabled$ | async"
        [selectionState]="selectionStateV2$ | async"
      >
      </bx-export-toolstrip-item>
    </bx-toolstrip-item>
  }

  @if (showPipelines) {
    <bx-toolstrip-item *bxHideIf="isAssociateOrg">
      <bx-pipeline-chooser-v2
        name="Pre-processing"
        [items$]="menuItems.preProcessing$"
        [folder]="folder"
      ></bx-pipeline-chooser-v2>
    </bx-toolstrip-item>
    <bx-toolstrip-item *bxHideIf="isAssociateOrg">
      <bx-pipeline-chooser-v2
        name="Annotation"
        *bxHideIf="isFreeOrg"
        [items$]="menuItems.annotation$"
        [folder]="folder"
      ></bx-pipeline-chooser-v2>
      <div
        [ngbTooltip]="annotateHelpMessage$ | async"
        placement="bottom"
        [disableTooltip]="(annotateHelpMessage$ | async) === null"
        [placement]="'bottom'"
        *bxShowIf="isFreeOrg"
      >
        <button
          class="btn btn-light"
          [disabled]="annotateDisabled$ | async"
          (click)="showAnnotateDialog()"
        >
          Annotate
        </button>
      </div>
    </bx-toolstrip-item>
    @if ((menuItems.postProcessing$ | async)?.length > 0) {
      <bx-toolstrip-item>
        <bx-pipeline-chooser-v2
          name="Post-processing"
          [items$]="menuItems.postProcessing$"
          [folder]="folder"
        ></bx-pipeline-chooser-v2>
      </bx-toolstrip-item>
    }
  }

  @if (showFolderInfo()) {
    <bx-toolstrip-item class="ms-auto">
      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group" role="group">
          <button
            type="button"
            ngbTooltip="Folder information"
            placement="left"
            class="btn btn-light"
            (click)="openFolderInfoModal()"
            data-testid="folder-info"
          >
            <mat-icon class="bx-btn-icon">info</mat-icon>
          </button>
        </div>
      </div>
    </bx-toolstrip-item>
  }
</bx-toolstrip>

<as-split
  [bxViewersState]="viewerStateKey"
  [bxViewerIndex]="1"
  unit="percent"
  direction="vertical"
  class="d-flex flex-grow-1"
  data-testid="files-table-split"
>
  <as-split-area
    [size]="$any('*')"
    class="grid-wrapper w-100 flex-grow-1"
    [bxUploadDrop]="folder.id"
    [bxUploadRoute]="folder?.route"
  >
    <div
      class="drop-zone border w-100"
      [ngClass]="{
        'border-primary': folder?.features.canUpload,
        'border-danger': !folder?.features.canUpload,
      }"
    >
      {{ uploadMessage }}
    </div>
    <bx-client-grid-v2
      class="h-100 w-100"
      [gridOptions]="gridOptions"
      [initialColumnDefs]="columnDefs"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (gridStateChanged)="handleGridStateChanged($event)"
      (selectionChanged)="handleSelectionChanged($event)"
      [tableType]="tableType"
      bxGridSelectionState
      [key]="folder.id"
      data-testid="files-table-grid"
    >
    </bx-client-grid-v2>
  </as-split-area>

  <as-split-area class="overflow-hidden" [minSize]="35" [maxSize]="80">
    @if (
      {
        viewersData: viewersData$ | async,
        isValidSelection: isValidSelection$ | async,
        openTabQueryParams: openTabQueryParams$ | async,
      };
      as state
    ) {
      <bx-viewers
        class="w-100 h-100 overflow-hidden"
        [key]="viewerStateKey"
        [data]="state.viewersData"
        [overlays]="viewersOverlays"
        data-testid="files-table-viewers"
      >
        @if (state.viewersData?.selection?.totalSelected > 0 && state.isValidSelection) {
          <div
            class="open-document-overlay w-100 pb-3 d-flex align-items-center justify-content-center"
          >
            <bx-open-document-button
              [editButtonVisible]="isDocumentEditButton$ | async"
              [text]="
                state.viewersData?.selection?.totalSelected === 1
                  ? 'Open Full Document'
                  : 'Open Full Documents'
              "
              [openQueryParams]="state.openTabQueryParams"
            ></bx-open-document-button>
          </div>
        }
      </bx-viewers>
    }
  </as-split-area>
</as-split>
