import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  ChainType,
  heavyGenes,
  heavyRegions,
  lightGenes,
  lightRegions,
  multiChainRegions,
  parseRegion,
  Regions,
  scFvRegions,
  toRegionString,
  UnprefixedRegions,
} from '../../core/antibodyAnnotatorRegions.service';
import { map } from 'rxjs/operators';
import { sortAntibodyRegionByName } from '../sort.util';
import { SelectOption } from '../../core/models/ui/select-option.model';
import { RegionSelectorChain } from './regions-selector.component';

@Injectable()
export class RegionsSelectorService {
  regions$ = new ReplaySubject<Regions[]>();
  regionOptions$ = this.regions$.pipe(
    map((regions: Regions[]) => regions.sort(sortAntibodyRegionByName)),
    map((regions: Regions[]) =>
      regions.map(parseRegion).map((region) => ({
        name: region.chain,
        option: new SelectOption(region.name, toRegionString(region)),
      })),
    ),
  );

  getRegionsForChain(chain: RegionSelectorChain): Regions[] {
    switch (chain) {
      case 'light':
        return [...lightRegions, ...lightGenes];
      case 'heavy':
        return [...heavyRegions, ...heavyGenes];
      case 'bothNoLinker':
        return [...heavyRegions, ...heavyGenes, ...lightRegions, ...lightGenes];
      case 'bothWithLinker':
        return [...heavyRegions, ...heavyGenes, ...lightRegions, ...lightGenes, ...scFvRegions];
      case 'twoHeavyNoLinker':
        return [
          ...this.getPrefixedRegions('Heavy', 2, [
            ...heavyRegions,
            ...heavyGenes,
          ] as UnprefixedRegions[]),
        ] as Regions[];
      case 'twoHeavyWithLinker':
        return [
          ...this.getPrefixedRegions('Heavy', 2, [
            ...heavyRegions,
            ...heavyGenes,
          ] as UnprefixedRegions[]),
          ...multiChainRegions,
        ] as Regions[];
      default:
        return [];
    }
  }

  getRegionsForSingleClone() {
    return [...heavyRegions, ...heavyGenes, ...lightRegions, ...lightGenes, ...scFvRegions];
  }

  getRegionsForGenericSequences(): Regions[] {
    const regions: Regions[] = ['Full Sequence'];
    regions.push('Template Region');
    regions.push('Template Matches');
    return regions;
  }

  getPrefixedRegions(prefix: ChainType, chains: number, regions: UnprefixedRegions[]): Regions[] {
    const allRegions: Regions[] = [];
    for (let i = 1; i < chains + 1; i++) {
      for (const region of regions) {
        allRegions.push(`${prefix}-${i}: ${region}` as Regions);
      }
    }
    return allRegions;
  }
}
