import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { AppState } from '../core.store';
import { selectLumaConfig } from '../organization-settings/organization-settings.selectors';
import { LumaConfig } from './luma-config/luma-config.model';

// The Luma API schema can be inspected here:
// https://infragistics-application.aqua.cluster-1.dev.luma.cloud.dotmatics.com/data-core/apps/82a135b8-727c-4382-a183-90881b4d36db/utilities/api-explorer
// Most object types have additional fields that we are not currently using

/** Schema for the `applicationVersion` object type. */
export type LumaApplicationVersion = {
  name: string;
  description: string;
  applicationId: string;
  applicationVersionId: string;
  state: 'DRAFT' | 'COMMITTED';
  enabled: boolean;
  isLatestAppVersion: boolean;
  version: {
    version: string;
  };
};
/**
 * Fields to include in the GraphQL query string for `applicationVersion` objects.
 * Must remain in sync with the properties in {@link LumaApplicationVersion}.
 */
const applicationVersionFields = `
      name
      description
      applicationId
      applicationVersionId
      state
      enabled
      isLatestAppVersion
      version {
        version
      }
`;

/** Schema for the `paginationInfo` object type. */
export type PaginationInfo = {
  totalCount: number;
};
/**
 * Fields to include in the GraphQL query string for `paginationInfo` objects.
 * Must remain in sync with the properties in {@link PaginationInfo}.
 */
const paginationInfoFields = `
      totalCount
`;

/** Schema for the `dataSource` object type. */
export type LumaDataSource = {
  id: string;
  name: string;
  description: string | null;
};
/**
 * Fields to include in the GraphQL query string for `dataSource` objects.
 * Must remain in sync with the propertiers in {@link LumaDataSource}.
 */
const dataSourceFields = `
      id
      name
      description
`;

type QueryResponse<T extends Record<string, unknown>> = Partial<T> & { errors?: LumaAPIError[] };
type LumaAPIError = {
  message: string;
  locations: { line: number; column: number }[];
};
/**
 * GraphQL responses contain the fields that were specified in the query. This interface models the
 * data type of each field that could be returned in the response. A query's response type can be
 * easily defined by picking a property from this interface for each field it queries.
 */
export type LumaResponseFields = {
  latestApplicationVersionsWithDrafts: QueryResponse<{
    data: LumaApplicationVersion[];
    paginationInfo: PaginationInfo;
  }>;
  dataSources: QueryResponse<{
    data: LumaDataSource[];
    paginationInfo: PaginationInfo;
  }>;
  applicationVersion: LumaApplicationVersion;
  dataSource: LumaDataSource;
};

export type PageVariables = { after: number; limit: number };
export type LumaVariables = {
  applicationId: string;
  applicationVersionId: string;
  dataSourceId: string;
  pagination: PageVariables;
};

// Queries are defined here because they are constants and are difficult to read when nested in a class

export const latestApplicationVersionsWithDraftsQuery = `query LatestApplicationVersionsWithDrafts($pagination: paginationInputType) {
  latestApplicationVersionsWithDrafts(pagination: $pagination) {
    data {${applicationVersionFields}}
    paginationInfo {${paginationInfoFields}}
  }
}`;

export const dataSourcesQuery = `query DataSources($applicationVersionId: ApplicationVersionId!) {
  dataSources(applicationVersionId: $applicationVersionId) {
    data {${dataSourceFields}}
    paginationInfo {${paginationInfoFields}}
  }
}`;

export const applicationVersionAndDataSourceQuery = `query ApplicationVersionAndDataSource($dataSourceId: SourceNodeId!, $applicationVersionId: ApplicationVersionId!) {
  dataSource(id: $dataSourceId, applicationVersionId: $applicationVersionId) {${dataSourceFields}}
  applicationVersion(applicationVersionId: $applicationVersionId) {${applicationVersionFields}}
}`;

@Injectable({
  providedIn: 'root',
})
export class LumaAPIService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
  ) {}

  /**
   * Sends a query for the `latestApplicationVersionsWithDrafts` field and extracts the
   * relevant data from the response.
   *
   * @param variables optional pagination variables
   * @param config the Luma connection configuration. If undefined, the config in the
   *    store will be used.
   * @returns a list of the latest application versions
   * @see {@link latestApplicationVersionsWithDraftsQuery}
   */
  latestApplicationVersionsWithDrafts(
    variables: { pagination?: PageVariables },
    config?: LumaConfig,
  ): Observable<LumaApplicationVersion[]> {
    return this.sendRequest<'latestApplicationVersionsWithDrafts'>(
      latestApplicationVersionsWithDraftsQuery,
      variables,
      config,
    ).pipe(map((res) => res.data.latestApplicationVersionsWithDrafts.data));
  }

  /**
   * Sends a query for the `dataSources` field and extracts the relevant data from the
   * response.
   *
   * @param variables specifying the application version and pagination
   * @param config the Luma connection configuration. If undefined, the config in the
   *    store will be used.
   * @returns a list of the data sources for the specified app
   * @see {@link dataSourcesQuery}
   */
  dataSources(
    variables: { applicationVersionId: string; pagination?: PageVariables },
    config?: LumaConfig,
  ): Observable<LumaDataSource[]> {
    return this.sendRequest<'dataSources'>(dataSourcesQuery, variables, config).pipe(
      map((res) => res.data.dataSources.data),
    );
  }

  /**
   * Sends a query for the `applicationVersion` and `dataSource` fields and extracts the
   * data from the response.
   *
   * @param variables specifying the application version and data source. If undefined,
   *    the values from the Luma config in the store will be used.
   * @param config the Luma connection configuration. If undefined, the config in the
   *    store will be used.
   * @returns information about the specified application version and data source
   * @see {@link applicationVersionAndDataSourceQuery}
   */
  applicationVersionAndDataSource(
    variables: { applicationVersionId: string; dataSourceId: string },
    config?: LumaConfig,
  ): Observable<{ applicationVersion: LumaApplicationVersion; dataSource: LumaDataSource }> {
    return this.sendRequest<'applicationVersion' | 'dataSource'>(
      applicationVersionAndDataSourceQuery,
      variables,
      config,
    ).pipe(map((res) => res.data));
  }

  /**
   * Retrieve the luma config saved in userSetting.
   */
  getConfig(): Observable<LumaConfig> {
    return this.selectLumaConfigProperties('lumaURL', 'lumaAPIKey');
  }

  private sendRequest<T extends keyof LumaResponseFields>(
    query: string,
    variables: Partial<LumaVariables>,
    config?: LumaConfig,
  ): Observable<QueryResponse<{ data: Pick<LumaResponseFields, T> }>> {
    const config$: Observable<LumaConfig> = config
      ? of(config)
      : this.selectLumaConfigProperties('lumaURL', 'lumaAPIKey');

    return config$.pipe(
      switchMap((config) =>
        this.http.post<{ data: Pick<LumaResponseFields, T> }>(
          `${config.lumaURL}/api/datacore-api/v1/graphql`,
          { query, variables },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${config.lumaAPIKey}`,
            },
          },
        ),
      ),
    );
  }

  /**
   * Plucks properties from the Luma config in the store. Throws an error if any of
   * the properties has an invalid value.
   *
   * @param properties to pluck from the config
   * @returns an observable containing the filtered config object
   */
  private selectLumaConfigProperties<K extends keyof LumaConfig>(
    ...properties: K[]
  ): Observable<Pick<LumaConfig, K>> {
    return this.store.select(selectLumaConfig).pipe(
      take(1),
      map((config) => {
        const filteredConfig: Partial<LumaConfig> = {};
        for (const prop of properties) {
          const value = config[prop];
          if (typeof value !== 'string' || value.length === 0) {
            throw new Error(
              `Invalid value for configuration property ${prop}: ${JSON.stringify(value)}`,
            );
          }
          filteredConfig[prop] = value;
        }
        return filteredConfig as Pick<LumaConfig, K>;
      }),
    );
  }
}
