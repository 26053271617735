<div class="modal-header">
  <h4 class="modal-title">Unable to Save Changes</h4>
</div>

<div class="modal-body">
  This document is out of date, likely because it has been edited recently. To view the new version,
  press "Discard Changes". Please note that your unsaved sequence edits will be lost. If you would
  like to review or record your changes first, press 'Cancel'.
</div>

<div class="modal-footer">
  <button class="btn btn-danger" (click)="reloadSequence()">Discard Changes</button>
  <button class="btn btn-secondary" (click)="this.activeModal.close()">Cancel</button>
</div>
