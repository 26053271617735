import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { AccessCondition } from '../../../shared/access-check/access-check-condition.model';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';
import { AccessConditionTextPipe } from '../../../shared/access-check/access-condition-text.pipe';

@Component({
  selector: 'bx-workspace-root-folder-detail',
  templateUrl: './workspace-root-folder-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, AsyncPipe, AccessConditionTextPipe],
})
export class WorkspaceRootFolderDetailComponent {
  @HostBinding('class') readonly hostClass = 'h-100';
  // True if user is in a free tier organization
  readonly isFreeOrg: AccessCondition = { orgProfiles: ['free'] };

  // If condition evaluates to true, first string will be chosen. Otherwise, the second.
  readonly titles = ['Workspace', 'Shared Workspace'];
  readonly messages = [
    'Contains all files and folders.',
    'Files and folders within your organization or subgroups.',
  ];
}
