import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalOptions, NgbCollapse, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  first,
  forkJoin,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { MODAL_DATA } from 'src/app/shared/dialog';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { selectTempFolderID } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { DatabaseRootFolder } from '../../folders/models/folder.model';
import { DocumentImportService } from '../../pipeline-dialogs/document-import/document-import.service';
import { BlastService } from '../blast.service';
import { DatabaseNameValidatorsService } from '../database-name-validators.service';
import { DatabaseTypeEnum } from '../database-type';
import { StepperComponent } from 'src/app/shared/stepper/stepper.component';
import { NucleusPipelineID } from '../../pipeline/pipeline-constants';
import { DataManagementService, NewFolderFolderType } from '@geneious/nucleus-api-client';
import { v4 as uuid } from 'uuid';
import { SequencesChain } from '../../pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import { MatIconModule } from '@angular/material/icon';
import { StepperStepsComponent } from '../../../shared/stepper/stepper-steps/stepper-steps.component';
import { StepperComponent as StepperComponent_1 } from '../../../shared/stepper/stepper.component';
import { StepComponent } from '../../../shared/stepper/step/step.component';
import { CreateReferenceDbDetailsFormComponent } from '../create-reference-db-details-form/create-reference-db-details-form.component';
import { CreateReferenceDbFormatFormComponent } from '../create-reference-db-format-form/create-reference-db-format-form.component';
import { CreateReferenceDbSummaryComponent } from '../create-reference-db-summary/create-reference-db-summary.component';
import { AsyncPipe } from '@angular/common';
import { PipelineVersionSelectorComponent } from '../../pipeline-dialogs/pipeline-dialog-v2/pipeline-version-selector/pipeline-version-selector.component';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

export type CreateReferenceDbForm = CreateReferenceDbDialogComponent['form'];
export type TemplateDatabaseSequencesChain = Extract<
  SequencesChain,
  'singleUnknownChain' | 'heavyChain' | 'lightChain'
>;

/**
 * The dialog component for creating a reference database. This is the top-level
 * "smart" component that handles form validation and submission. It renders the
 * dialog container, and the content is rendered by child components.
 *
 * If the user enables the automatic annotation feature, the dialog expands to
 * reveal a stepper form where the user can specify the options to be sent to
 * import-and-annotate-database pipeline. Otherwise, the reference database
 * folder will be created upon submission, and any files will be uploaded as-is.
 */
@Component({
  selector: 'bx-create-reference-db-dialog',
  templateUrl: './create-reference-db-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    NgbCollapse,
    StepperStepsComponent,
    NgbAlert,
    StepperComponent_1,
    StepComponent,
    CreateReferenceDbDetailsFormComponent,
    CreateReferenceDbFormatFormComponent,
    CreateReferenceDbSummaryComponent,
    PipelineVersionSelectorComponent,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class CreateReferenceDbDialogComponent extends CleanUp implements OnDestroy {
  /** Options to use when opening this compopnent in a modal */
  public static readonly MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static' };

  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;

  readonly form = new FormGroup({
    details: new FormGroup(
      {
        databaseName: new FormControl<string>(
          undefined,
          this.dbNameValidators.formControlOptions(),
        ),
        databaseType: new FormControl(DatabaseTypeEnum.GERMLINE, [Validators.required]),
        files: new FormControl<File[]>([]),
        autoAnnotateGermlineGenes: new FormControl(false),
      },
      (group) => this.atLeastOneFileWhenFormatEnabled(group as any),
    ),
    format: new FormGroup({
      referenceDatabase: new FormControl<string>(undefined, [Validators.required]),
      geneSourceName: new FormControl<'sequenceName' | 'sequenceListName'>('sequenceName'),
      sequencesChain: new FormControl<TemplateDatabaseSequencesChain>('singleUnknownChain'),
    }),
    acceptDisclaimer: new FormControl(false, [this.disclaimerAccepted]),
  });
  /**
   * The abstract control that is associated with each step. The control must be
   * valid for the Next/Create button to be enabled.
   */
  readonly stepControls = [
    this.form.controls.details,
    this.form.controls.format,
    this.form,
  ] as const;
  readonly parentFolderID: string;
  readonly submitState$ = new BehaviorSubject<'idle' | 'loading' | 'error'>('idle');
  private readonly isAutoAnnotate$ = currentValueAndChanges(
    this.form.controls.details.controls.autoAnnotateGermlineGenes,
  ).pipe(shareReplay(1), takeUntil(this.ngUnsubscribe));
  /**
   * When true, the stepper will be displayed. When false, the stepper is hidden
   * and the database is created after the first step is completed.
   */
  readonly enableStepper$ = this.isAutoAnnotate$;
  readonly basePipelineID$ = this.isAutoAnnotate$.pipe(
    map((isAutoAnnotate) => (isAutoAnnotate ? 'import-and-annotate-database' : 'document-import')),
  );
  readonly pipelineID$ = new ReplaySubject<NucleusPipelineID>(1);
  readonly databaseType$ = currentValueAndChanges(this.form.controls.details.controls.databaseType);

  constructor(
    @Inject(MODAL_DATA) modalData: { folder: DatabaseRootFolder },
    private readonly modal: NgbActiveModal,
    private readonly blastService: BlastService,
    private readonly dataManagementService: DataManagementService,
    private readonly documentImportService: DocumentImportService,
    private readonly dbNameValidators: DatabaseNameValidatorsService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {
    super();
    this.parentFolderID = modalData.folder.id;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.submitState$.complete();
    this.pipelineID$.complete();
  }

  submitForm() {
    this.submitState$.next('loading');
    const formValue = this.form.getRawValue();
    const details = formValue.details;
    /** Creates the reference DB folder and returns its ID */
    const createRefDBFolder$ = this.blastService
      .createBlast(this.parentFolderID, details.databaseName, details.databaseType)
      .pipe(
        map(({ id }) => id),
        first(),
      );
    /** Executes the import operation and then returns the reference DB folder ID */
    let importReferenceDatabase$: Observable<string>;
    if (formValue.details.autoAnnotateGermlineGenes) {
      importReferenceDatabase$ = this.importAndAnnotateDatabase(formValue, createRefDBFolder$);
    } else if (details.files?.length > 0) {
      // Import files but do not annotate
      importReferenceDatabase$ = forkJoin([
        createRefDBFolder$,
        this.pipelineID$.pipe(first()),
      ]).pipe(
        tap(([refDBFolderID, pipelineID]) =>
          this.documentImportService.import(refDBFolderID, '/database', details.files, pipelineID),
        ),
        map(([refDBFolderID]) => refDBFolderID),
      );
    } else {
      // Just create the reference database folder
      importReferenceDatabase$ = createRefDBFolder$;
    }
    importReferenceDatabase$.subscribe({
      next: (refDBFolderID) => {
        this.submitState$.next('idle');
        this.close('completed');
        this.router.navigateByUrl('/database/' + refDBFolderID);
      },
      error: (error) => {
        this.submitState$.next('error');
        Sentry.captureException(error);
      },
    });
  }

  /**
   * Submits the import-and-annotate-database job and returns the destination
   * reference database ID.
   *
   * @param formValue the form value at submit time
   * @param refDBFolderID$ an observable that emits the destination reference
   *    database folder ID
   * @returns the reference database folder ID
   */
  private importAndAnnotateDatabase(
    { details, format }: ReturnType<typeof this.form.getRawValue>,
    refDBFolderID$: Observable<string>,
  ): Observable<string> {
    // Create destination folder for document-import inside temporary folder
    // This is necessary for template databases, as document-import checks the
    // databaseType of the destination folder to determine whether the
    // DocumentField isNonGeneDatabase should be set to true
    const parentFolderID$ = this.store.select(selectTempFolderID).pipe(
      first(),
      switchMap((tempFolderID) =>
        this.dataManagementService.createFolder({
          name: 'AutoAnnotateDatabaseInput-' + uuid(),
          parentID: tempFolderID,
          folderType: NewFolderFolderType.Folder,
          metadata: { databaseType: details.databaseType },
        }),
      ),
      map((res) => res.data.folderID),
    );
    // Set up NGS options for the database type
    const ngsOptionValues: Record<string, boolean | string | string[]> = {
      database_databaseType: 'customDatabase',
      database_customDatabase: [format.referenceDatabase],
      sequences_addNumberingAnnotations: true,
    };
    if (details.databaseType === DatabaseTypeEnum.GERMLINE) {
      ngsOptionValues.geneSourceName = format.geneSourceName;
      ngsOptionValues.annotateSequencesAsGenesForNewDatabase = true;
    } else {
      ngsOptionValues.sequences_chain = format.sequencesChain;
      // These options are set automatically when annotateSequencesAsGenesForNewDatabase is true
      // But for template databases, we have to do it manually
      ngsOptionValues.createSummary = false;
      ngsOptionValues.annotateSequences = true;
    }

    // Launch job to import and annotate uploaded files
    return forkJoin([refDBFolderID$, this.pipelineID$.pipe(first()), parentFolderID$]).pipe(
      tap(([refDBFolderID, pipelineID, parentFolderID]) =>
        this.documentImportService.import(parentFolderID, '/database', details.files, pipelineID, {
          groupSequences: false,
          templateDatabase: details.databaseType === DatabaseTypeEnum.TEMPLATE,
          additionalJobParameters: {
            options: {
              geneiousOperationID: 'NextgenBiologicsAnnotator',
              optionValues: ngsOptionValues,
            },
            output: {
              outputFolderId: refDBFolderID,
            },
          },
        }),
      ),
      map(([refDBFolderID]) => refDBFolderID),
    );
  }

  close(reason: 'canceled' | 'completed') {
    if (reason === 'canceled') {
      this.modal.dismiss(reason);
    } else {
      this.modal.close();
    }
  }

  private atLeastOneFileWhenFormatEnabled(
    group: FormGroup<{
      files: FormControl<File[]>;
      autoAnnotateGermlineGenes: FormControl<boolean>;
    }>,
  ): ValidationErrors | null {
    return group.controls.files.value.length === 0 && group.controls.autoAnnotateGermlineGenes.value
      ? { noFiles: 'Select at least one file to upload and format' }
      : null;
  }

  private disclaimerAccepted(control: AbstractControl): ValidationErrors | null {
    return control.value ? null : { notAccepted: 'Please confirm that you understand' };
  }
}
