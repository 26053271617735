<bx-page-message>
  @if (isRestoreScreenVisible$ | async) {
    <div>
      <h2 data-spec="preview-unavailable-message">Preview is unavailable</h2>
      <p class="mb-4">Your document was archived because it was not accessed for 30 days.</p>
      <button class="btn btn-outline-primary" (click)="restoreDefaultTable()">
        Restore Document
      </button>
    </div>
  }
  @if (restoreProgress$ | async; as restoreProgress) {
    <div class="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
      @if (restoreProgress.error; as errorMessage) {
        <div>
          @if (errorMessage === 'Failed to restore document table') {
            <h2>An error occurred while restoring the table</h2>
            <p>Try reloading the document</p>
          }
          @if (errorMessage === 'Document table index state is absent') {
            <h2>An error occurred while restoring the table</h2>
            <p>
              Please contact support
              <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a>
            </p>
          }
        </div>
      } @else {
        <div>
          <h2 data-spec="restoring-document-message">Restoring your document from storage</h2>
          <p>This document was archived because it was not accessed for 30 days</p>
        </div>
      }
      @if (restoreProgress.progress !== null) {
        <ngb-progressbar
          class="w-100"
          type="info"
          [value]="restoreProgress.progress"
          [striped]="true"
          [animated]="true"
        >
        </ngb-progressbar>
      }
    </div>
  }
</bx-page-message>
