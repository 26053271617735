<div class="card-header d-flex" role="button" (click)="collapse.toggle()">
  <fa-icon
    class="ms-2 me-3"
    [icon]="faChevronRight"
    [rotate]="isCollapsed ? undefined : 90"
  ></fa-icon>
  <div class="flex-grow-1">
    <div class="custom-title-wrapper">
      <ng-content select="[title]"></ng-content>
    </div>
    <h5 class="mb-0 d-inline align-middle default-title-wrapper">{{ headerTitle }}</h5>
  </div>
</div>
<div
  class="card-body"
  [ngClass]="{ 'p-0': compact }"
  #collapse="ngbCollapse"
  [(ngbCollapse)]="isCollapsed"
  (ngbCollapseChange)="collapseChange.emit($event)"
>
  <ng-content></ng-content>
</div>
