import { GridOptions } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { combineLatest, merge, Observable, of, ReplaySubject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { DocumentTable } from 'src/nucleus/services/documentService/types';
import { JobActivityEventKind } from 'src/nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  JobEventType,
  JobQueuedActivityEvent,
} from 'src/nucleus/v2/models/activity-events/job-activity-event.model';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../../nucleus/services/documentService/document-service.resource';
import { GridComponent } from '../../../features/grid/grid.component';
import { GridState } from '../../../features/grid/grid.interfaces';
import { CleanUp } from '../../../shared/cleanup';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { DocumentTableViewerService } from '../../document-table-service/document-table-viewer.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { ViewerDocumentData } from '../../viewer-components/viewer-document-data';
import {
  annotatedPluginDocumentViewerSelector,
  masterDatabaseViewerSelector,
} from '../../viewer-components/viewer-selectors';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { MasterDatabaseFormDialogComponent } from '../../master-database/master-database-form-dialog/master-database-form-dialog.component';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { NgsSequencePreviewInfoBoxComponent } from '../ngs-sequence-preview-info-box/ngs-sequence-preview-info-box.component';
import { NotesEditHandlerDirective } from '../../document-table-service/notes-edit-handler/notes-edit-handler.directive';
import { NgsDocumentRestoreScreenComponent } from '../ngs-document-restore-screen/ngs-document-restore-screen.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@ViewerComponent({
  key: 'ngs-sequences-table-viewer-preview',
  title: 'Table Preview',
  selectors: [
    annotatedPluginDocumentViewerSelector(
      [
        DocumentSelectionSignature.forDocumentClass(
          'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
          1,
          1,
        ),
        DocumentSelectionSignature.forDocumentClass(
          'com.biomatters.plugins.nextgenBiologics.AntibodyComparisonDocument',
          1,
          1,
        ),
      ],
      (viewerData) => viewerData.isPreviewView,
    ),
    masterDatabaseViewerSelector((viewerData) => viewerData.isPreviewView),
  ],
})
@Component({
  selector: 'bx-ngs-sequences-table-preview-viewer',
  templateUrl: './ngs-sequences-table-preview-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentServiceResource, DocumentTableViewerService],
  standalone: true,
  imports: [
    PageMessageComponent,
    NgsSequencePreviewInfoBoxComponent,
    GridComponent,
    NotesEditHandlerDirective,
    NgsDocumentRestoreScreenComponent,
    LoadingComponent,
    AsyncPipe,
  ],
})
export class NgsSequencesTablePreviewViewerComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden';

  readonly RESULT_SET_MAX = 100;

  @ViewChild(GridComponent) gridComponent: GridComponent;

  // Grid things.
  gridDatasource: DocumentServiceResource;
  gridDatasourceParams$: Observable<DocumentDatasourceParams>;
  gridOptions: GridOptions = {
    cacheBlockSize: 1000,
    context: { previewMode: true },
  };
  tableType$: Observable<any>;
  gridState$ = new ReplaySubject<GridState>(1);
  additionalMessage$: Observable<string>;

  document$: Observable<AnnotatedPluginDocument>;
  documentID$: Observable<string>;

  resourceError$: Observable<any>;

  tablesLoaded$: Observable<boolean>;
  tablesLoadingError$: Observable<string>;
  tableLoaded$: Observable<boolean>;
  defaultTableName$: Observable<string>;

  tableProfileKey$: Observable<'resultDocument' | 'comparisonsResultDocument' | 'masterDatabase'>;
  viewersStateKey$: Observable<'ngsSequencesTable' | 'ngsComparisonsTable' | 'masterDatabaseTable'>;
  documentType$: Observable<'ngsResult' | 'ngsComparison' | 'masterDatabase'>;
  openQueryParams$: Observable<ViewerPageURLSelectionState>;

  totalNumberOfRows: number;

  private defaultTable$: Observable<DocumentTable | undefined>;

  constructor(
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentServiceResource: DocumentServiceResource,
    private documentTableStateService: DocumentTableStateService,
    private activityStreamService: ActivityStreamService,
    private readonly pipelineDialogService: PipelineDialogService,
  ) {
    super();
    const viewerData$ = this.viewerDataService.getData('ngs-sequences-table-viewer');
    this.openQueryParams$ = viewerData$.pipe(map((data) => data.openQueryParams));
    this.document$ = viewerData$.pipe(
      map((data) => data.selection),
      filter((selection) => selection.rows.length === 1),
      map((selection) => selection.rows[0]),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.documentID$ = this.document$.pipe(
      map((document) => document.id),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.documentType$ = this.document$.pipe(
      map((document) => document.type as 'ngsResult' | 'ngsComparison' | 'masterDatabase'),
      takeUntil(this.ngUnsubscribe),
    );

    this.tableProfileKey$ = this.documentType$.pipe(
      map((documentType) => {
        switch (documentType) {
          case 'ngsResult':
            return 'resultDocument';
          case 'ngsComparison':
            return 'comparisonsResultDocument';
        }
      }),
    );

    this.viewersStateKey$ = this.documentType$.pipe(
      map((documentType) => {
        switch (documentType) {
          case 'ngsResult':
            return 'ngsSequencesTable';
          case 'ngsComparison':
            return 'ngsComparisonsTable';
        }
      }),
    );

    this.gridDatasource = this.documentServiceResource;
    this.resourceError$ = this.documentServiceResource.onError();
  }

  ngOnInit() {
    // For most results this would be the `DOCUMENT_TABLE_ALL_SEQUENCES` table.
    // For Comparison results this would be the `Summary` table.
    // For Collections this would be the `MASTER_DATABASE` table.
    // For now, we are only supporting the `DOCUMENT_TABLE_ALL_SEQUENCES` for
    // the intended feature of this observable: 'View Sequences in Clusters'.
    this.defaultTable$ = this.documentID$.pipe(
      withLatestFrom(this.documentType$),
      switchMap(([docID, documentType]) =>
        this.documentTableStateService
          .getQueryableTable(docID, this.getDefaultTable(documentType))
          // If the table doesn't exist, it will throw an error.
          .pipe(catchError(() => of(undefined))),
      ),
      filter((table) => !!table),
      takeUntil(this.ngUnsubscribe),
    );
    this.defaultTableName$ = this.documentType$.pipe(
      map((documentType) => this.getDefaultTable(documentType)),
      takeUntil(this.ngUnsubscribe),
    );
    this.tableType$ = this.defaultTable$.pipe(
      filter((table) => !!table),
      map((table) => table.name),
      takeUntil(this.ngUnsubscribe),
    );

    // Step towards removing the need for this completely.
    // Grid currently uses this (not datasource$ as we want it to) to determine when document selection changes.
    this.gridDatasourceParams$ = combineLatest([this.documentID$, this.defaultTable$]).pipe(
      map(([documentID, defaultTable]) => ({
        filterModel: '',
        documentTableName: defaultTable.name,
        documentId: documentID,
      })),
    );

    const fetchingState$ = this.documentID$.pipe(
      switchMap((documentID) => this.documentTableStateService.getTablesFetchingState(documentID)),
      takeUntil(this.ngUnsubscribe),
    );

    this.tablesLoadingError$ = fetchingState$.pipe(
      filter((fetchingState) => fetchingState.error != null),
      withLatestFrom(this.documentType$),
      map(([fetchingState, documentType]) =>
        documentType === 'masterDatabase' &&
        fetchingState.error === 'No tables exist on this document'
          ? 'masterDatabaseNoTables'
          : fetchingState.error,
      ),
      takeUntil(this.ngUnsubscribe),
    );

    this.tablesLoaded$ = fetchingState$.pipe(
      map((fetchingState) => !fetchingState.fetching && !fetchingState.error),
    );

    this.tableLoaded$ = merge(
      this.documentID$.pipe(map(() => false)),
      this.defaultTable$.pipe(map(() => true)),
    ).pipe(takeUntil(this.ngUnsubscribe));

    const jobActivity = this.activityStreamService.listenToJobActivity().pipe(
      map((activity) => activity.event),
      takeUntil(this.ngUnsubscribe),
    );

    jobActivity
      .pipe(
        filter(this.isRegisterSequenceJobQueued),
        mergeMap((job) =>
          jobActivity.pipe(
            filter(this.isJobCompletedEvent(job.jobID)),
            switchMap(() => this.documentID$),
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((documentID) => {
        this.documentTableStateService.refreshTables(documentID);
      });
  }

  handleGridStateChanged() {
    setTimeout(() => {
      this.totalNumberOfRows = this.gridComponent.totalNoOfRows;
    }, 0);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.gridState$.complete();
  }

  /**
   * Opens the Collections import dialog.
   */
  importSequences(): void {
    this.document$
      .pipe(
        switchMap((document) =>
          this.pipelineDialogService.showDialog$({
            component: MasterDatabaseFormDialogComponent,
            folderID: document.parent.id,
            selected: {
              selectedRows: [document],
              ids: [document.id],
              noOfRowsSelected: 1,
              totalNoOfRows: 1,
              selectAll: false,
              firstRow: document,
            },
          }),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private isRegisterSequenceJobQueued(event: JobEventType): event is JobQueuedActivityEvent {
    return (
      event.kind === JobActivityEventKind.JOB_QUEUED &&
      event.jobConfig.pipeline.name === 'register-sequences'
    );
  }

  private isJobCompletedEvent(jobID: string): (event: JobEventType) => boolean {
    return (event: JobEventType) => {
      return event.kind === JobActivityEventKind.JOB_COMPLETED && event.jobID === jobID;
    };
  }

  private getDefaultTable(documentType: 'ngsResult' | 'ngsComparison' | 'masterDatabase') {
    if (documentType === 'ngsResult') {
      return 'DOCUMENT_TABLE_ALL_SEQUENCES';
    } else if (documentType === 'ngsComparison') {
      return 'DOCUMENT_TABLE_SUMMARY';
    }
    return 'MASTER_DATABASE';
  }
}
