import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentTableStateEffects } from './document-table-state/document-table-state.effects';
import { documentTableStateReducer } from './document-table-state/document-table-state.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('documentTableState', documentTableStateReducer),
    EffectsModule.forFeature([DocumentTableStateEffects]),
  ],
})
export class DocumentTableServiceModule {}
