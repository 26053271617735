import { FormStates } from '../form-state.model';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';
import {
  CombinationRegionChip,
  isCombinationRegionChip,
} from '../../../../shared/regions-selector/regions-selector.component';
import { Chip } from '../../../../shared/chips';
import { defaultRegionChips } from '../../../../shared/regions-selector/regions-selector';

// TODO Delete this migration once it has been deployed for maybe a few weeks or a month?
// Or maybe delete it once we've created/ran this migration via a database migration on Nucleus?
/**
 * Last used Cluster Combos will be added to the regionsSelector, replacing all default cluster combinations in the regionsSelector
 * only if the 'clusteringSelection' feature switch is enabled.
 *
 * If the 'clusteringSelection' feature switch is disabled and the user has regionsSelector settings saved in their form state,
 * then the cluster combinations in their regionSelector settings will be migrated back to the cluster combos.
 * This is there just in case we decide to enable the new 'clusterSelection' feature but disable it later due to a bug.
 */
export function ngsRegionsSelectorMigration(formStates: FormStates): FormStates {
  const ngsFormState = formStates[PipelineFormID.ANTIBODY_ANNOTATOR];
  if (ngsFormState) {
    const options = ngsFormState.options;

    if (!options.regionsSelector) {
      const { results_clusterCombos: _, ...remainingOptions } = options;
      return {
        ...formStates,
        [PipelineFormID.ANTIBODY_ANNOTATOR]: {
          ...formStates[PipelineFormID.ANTIBODY_ANNOTATOR],
          options: {
            ...remainingOptions,
            regionsSelector: convertClusterCombosToRegionsSelectorState(
              ngsFormState.options.results_clusterCombos,
            ),
          },
        },
      };
    } else {
      return formStates;
    }
  }

  return formStates;
}

// We need to add the default regions at the beginning of the regions/combinations list.
function convertClusterCombosToRegionsSelectorState(
  clusterCombos: string,
): (Chip | CombinationRegionChip)[] {
  try {
    const clusterComboLines = clusterCombos
      .split('\n')
      .map((part) => part.trim())
      .filter((part) => part !== '');
    const defaultSingleRegions = defaultRegionChips.filter(
      (chip) => !isCombinationRegionChip(chip),
    );
    return defaultSingleRegions.concat(
      convertClusterComboLinesToCombinationRegionChips(clusterComboLines),
    );
  } catch (error) {
    return [];
  }
}

/**
 * Converts Cluster combo lines to Combination Region chips and removes any duplicates by cluster combo name.
 */
function convertClusterComboLinesToCombinationRegionChips(clusterComboLines: string[]) {
  const savedClusterCombosMap = new Map<string, CombinationRegionChip>();
  clusterComboLines.forEach((line) => {
    const [clusterComboName, regionsString] = line.split(':');
    const regions = regionsString.split(',').map((region) => region.trim());
    savedClusterCombosMap.set(clusterComboName, {
      id: clusterComboName,
      label: clusterComboName,
      type: 'Combination',
      regions,
    });
  });
  return Array.from(savedClusterCombosMap, ([_, value]) => value);
}

function convertRegionsSelectorToClusterCombosState(
  regionsSelectorList: (Chip | CombinationRegionChip)[],
): string {
  return regionsSelectorList
    .filter(isCombinationRegionChip)
    .map((combinationRegion) => combinationRegion.id + ': ' + combinationRegion.regions.join(', '))
    .join('\n');
}
