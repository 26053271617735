import { Pipe, PipeTransform } from '@angular/core';
import { AnnotationNameAndCount } from '../../sequence-viewer/sequence-selection.service';
import { AnnotationNameWithChainPipe } from './annotation-name-with-chain.pipe';

/**
 * Returns a string representing the order of the selected regions and their chain.
 * e.g. if multiple regions were selected, they'd be displayed as: `[Heavy FR3]-[Heavy CDR3]-[Heavy FR4]`
 */
@Pipe({
  name: 'selectedRegionsOrderDisplay',
  standalone: true,
})
export class SelectedRegionsOrderDisplayPipe implements PipeTransform {
  private annotationNameWithChainPipe = new AnnotationNameWithChainPipe();

  transform(selectedRegions: AnnotationNameAndCount[]): string {
    if (selectedRegions == null) {
      return null;
    }

    return selectedRegions.reduce((regionOrder, region, index) => {
      const annotationName = this.annotationNameWithChainPipe.transform(region.name, region.chain);
      if (index === selectedRegions.length - 1) {
        return regionOrder + `[${annotationName}]`;
      } else {
        return regionOrder + `[${annotationName}]-`;
      }
    }, '');
  }
}
