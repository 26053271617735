<form [formGroup]="form">
  <p
    *bxShowIf="'barcodeSeparation'"
    ngbTooltip="This indicates the order of regions within the source sequence"
    placement="bottom"
    class="text-center"
  >
    [Adapter] - [Barcode(s)] - [UMI] - [TSO] - [Insert Sequence] - [TSO] - [UMI] - [Barcode(s)] -
    [Adapter]
  </p>

  <bx-card *bxShowIf="'barcodeSeparation'">
    <div bxCardHeader class="form-check form-check-inline">
      <input
        type="checkbox"
        id="hasAdapater"
        value=""
        formControlName="hasAdapater"
        class="form-check-input"
      />
      <label for="hasAdapater" class="form-check-label"> Adapter </label>
    </div>

    <div class="row">
      <label for="adapterLength" class="col-sm-3 col-lg-2 col-form-label"> Length: </label>
      <div class="col-3">
        <div class="input-group">
          <input
            type="number"
            id="adapterLength"
            formControlName="adapterLength"
            class="form-control"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
    </div>
  </bx-card>

  <bx-card *bxShowIf="'barcodeSeparation'">
    <div bxCardHeader class="form-check form-check-inline">
      <input
        type="checkbox"
        id="separateByBarcode"
        value=""
        formControlName="separateByBarcode"
        class="form-check-input"
        data-testid="barcodeForSeparation"
      />
      <label for="separateByBarcode" class="form-check-label"> Barcode (for separation) </label>
    </div>

    <div
      [bxFadeFormElementDirective]="{
        controlName: 'separateByBarcode',
        fadeWhenValueNotMatching: true,
      }"
    >
      <div class="mb-3">
        <div class="form-check">
          <input
            type="radio"
            name="barcodes_barcodeType"
            formControlName="barcodes_barcodeType"
            id="barcodeByLength"
            value="barcodeByLength"
            class="form-check-input"
          />
          <label for="barcodeByLength" class="form-check-label"> By length </label>
        </div>

        <div
          class="row"
          [bxFadeFormElementDirective]="{
            controlName: 'barcodes_barcodeType',
            fadeWhenValueNotMatching: 'barcodeByLength',
          }"
        >
          <div class="col-1"></div>
          <label for="barcodes_barcodeLength" class="col-sm-3 col-lg-2 col-form-label"
            >Length:</label
          >
          <div class="col-3">
            <div class="input-group">
              <input
                type="number"
                id="barcodes_barcodeLength"
                formControlName="barcodes_barcodeLength"
                class="form-control"
              />
              <span class="input-group-text">bp</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input
            type="radio"
            name="barcodes_barcodeType"
            formControlName="barcodes_barcodeType"
            id="barcodeByName"
            value="barcodeByName"
            class="form-check-input"
          />
          <label for="barcodeByName" class="form-check-label"> By name </label>
        </div>

        <div
          [bxFadeFormElementDirective]="{
            controlName: 'barcodes_barcodeType',
            fadeWhenValueNotMatching: 'barcodeByName',
          }"
        >
          <div class="row">
            <div class="col-1"></div>
            <label for="barcodes_barcodeNames" class="col-sm-3 col-lg-2 col-form-label"
              >Barcode names and sequences:</label
            >
            <div class="col">
              <textarea
                formControlName="barcodes_barcodeNames"
                id="barcodes_barcodeNames"
                rows="4"
                class="form-control"
                placeholder="Barcode-1 GATTACA&#10;Barcode-2 CCGGACA"
              >
              </textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-lg-3"></div>
            <div class="col form-text">
              <a
                href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070331"
                target="_blank"
              >
                Instructions for specifying barcodes
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 col-lg-3"></div>
            <div class="col">
              <div class="form-check">
                <input
                  type="checkbox"
                  id="barcodes_onlyKeepNamedBarcodes"
                  value=""
                  formControlName="barcodes_onlyKeepNamedBarcodes"
                  class="form-check-input"
                />
                <label for="barcodes_onlyKeepNamedBarcodes" class="form-check-label">
                  Discard sequences not matching named barcodes
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <label for="barcodes_minimumSequencesPerBarcode" class="col-sm-6 col-lg-3 col-form-label">
          Discard barcodes<br />containing fewer than:
        </label>
        <div class="col-sm-6 col-lg-3">
          <div class="input-group">
            <input
              type="number"
              id="barcodes_minimumSequencesPerBarcode"
              formControlName="barcodes_minimumSequencesPerBarcode"
              data-testid="barcodesMinSequences"
              class="form-control"
            />
            <span class="input-group-text">sequences</span>
          </div>
        </div>
        <span class="col-sm-12 col-lg text-center py-0"> or </span>
        <div class="col-sm-6 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="barcodes_minimumSequencesPerBarcodeDominantPercentage"
              formControlName="barcodes_minimumSequencesPerBarcodeDominantPercentage"
              data-testid="barcodesMinPercentage"
              class="form-control"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <label
          for="barcodes_minimumSequencesPerBarcodeDominantPercentage"
          class="col-sm-6 col-lg-3 col-form-label text-end"
        >
          of dominant barcode<br />(whichever is higher)
        </label>
      </div>
    </div>
  </bx-card>

  <bx-card>
    <div bxCardHeader class="form-check form-check-inline">
      <input
        type="checkbox"
        id="useStart"
        value=""
        formControlName="useStart"
        class="form-check-input"
      />
      <label for="useStart" class="form-check-label"> UMI </label>
    </div>
    <div class="row">
      <label for="startLength" class="col-sm-3 col-lg-2 col-form-label"> Length: </label>
      <div class="col-3">
        <div class="input-group">
          <input
            type="number"
            id="startLength"
            formControlName="startLength"
            class="form-control"
            data-testid="startLength"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
    </div>
  </bx-card>

  <bx-card *bxShowIf="'barcodeSeparation'">
    <div bxCardHeader class="form-check form-check-inline">
      <input
        type="checkbox"
        id="hasTSO"
        value=""
        formControlName="hasTSO"
        class="form-check-input"
      />
      <label for="hasTSO" class="form-check-label"> TSO </label>
    </div>

    <div class="row">
      <label for="TSO" class="col-sm-3 col-lg-2 col-form-label">Sequence:</label>
      <div class="col">
        <input
          type="text"
          class="form-control"
          id="TSO"
          ngFormControlValidator
          formControlName="TSO"
        />
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Settings">
    <div class="mb-3 row align-items-center">
      <label for="startEndProperties" class="col-7 col-form-label">
        {{ isLegacyUMI ? 'UMI is present at:' : 'Adapter/Barcode/UMI are present at:' }}
      </label>
      <div class="col">
        <select id="startEndProperties" formControlName="startEndProperties" class="form-select">
          @for (startEndOption of startEndOptions; track startEndOption) {
            <option [value]="startEndOption">
              {{ startEndOption }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="allowUMIMismatch"
        value=""
        formControlName="allowUMIMismatch"
        class="form-check-input"
      />
      <label for="allowUMIMismatch" class="form-check-label">
        {{
          isLegacyUMI
            ? 'Allow single mismatch in UMI'
            : 'Allow single mismatch in UMI, barcode, and TSO'
        }}
      </label>
    </div>

    <div class="mb-3 form-check" *bxShowIf="'barcodeSeparation'">
      <input
        type="checkbox"
        id="trimBarcodeUmiTso"
        value=""
        formControlName="trimBarcodeUmiTso"
        class="form-check-input"
        data-testid="trimBarcodes"
      />
      <label for="trimBarcodeUmiTso" class="form-check-label">
        Trim adapter, barcode, UMI, and TSO from results
      </label>
    </div>

    <div class="mb-3 row">
      <div class="col-7 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="discardShortSequences"
            value=""
            formControlName="discardShortSequences"
            class="form-check-input"
            data-testid="discardShortSequences"
          />
          <label for="discardShortSequences" class="form-check-label">
            Discard sequences shorter than:
          </label>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <input
            type="number"
            id="discardSequencesShorterThan"
            formControlName="discardSequencesShorterThan"
            class="form-control"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-7 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="discardLowQualitySequences"
            value=""
            formControlName="discardLowQualitySequences"
            class="form-check-input"
            data-testid="discardLowQualitySequences"
          />
          <label for="discardLowQualitySequences" class="form-check-label">
            Discard sequences with a chance of error over:
          </label>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <input
            type="number"
            id="discardLowQualitySequencesPercent"
            formControlName="discardLowQualitySequencesPercent"
            class="form-control"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <label for="dontClusterPercent" class="col-7 col-form-label">
        Collapse sequences with the same UMI if they are more than:
      </label>
      <div class="col">
        <div class="input-group">
          <input
            type="number"
            id="dontClusterPercent"
            formControlName="dontClusterPercent"
            class="form-control"
          />
          <span class="input-group-text">% identical</span>
        </div>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [outputNameFormControl]="form.get('outputFolderName')"></bx-pipeline-output>
  </bx-card>
</form>
