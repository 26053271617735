import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { merge, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { UsersService, FolderTransferStrategy } from '../users.service';
import {
  catchError,
  exhaustMap,
  first,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { selectUserID } from '../../auth/auth.selectors';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-users-deactivation-dialog',
  templateUrl: './users-deactivation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, SpinnerButtonComponent, AsyncPipe],
})
export class UsersDeactivationDialogComponent implements OnInit, OnDestroy {
  /** Injected by modal service **/
  userID: string;
  userEmail: string;

  readonly form = new FormGroup({
    folderTransferStrategy: new FormControl<FolderTransferStrategy>('transferAll'),
  });
  deactivateUserEvent$ = new Subject<void>();
  description$: Observable<string>;
  submitting$: Observable<boolean>;

  private currentUserID$: Observable<string>;
  private subscriptions = new Subscription();

  constructor(
    public ngbModalRef: NgbActiveModal,
    private usersService: UsersService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.currentUserID$ = this.store.pipe(
      select(selectUserID),
      first(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const folderTransferStrategyControl = this.form.get('folderTransferStrategy');
    this.description$ = folderTransferStrategyControl.valueChanges.pipe(
      startWith(folderTransferStrategyControl.value),
      map((strategy) => this.getConfirmationDialogContent(strategy, this.userEmail)),
    );

    const deactivateUser$ = this.deactivateUserEvent$.pipe(
      exhaustMap(() => this.getDeactivateUserObservable()),
      share(),
    );

    this.subscriptions.add(
      deactivateUser$.subscribe((success) => {
        if (success) {
          this.ngbModalRef.close(true);
        }
      }),
    );

    this.submitting$ = merge(this.deactivateUserEvent$.pipe(map(() => true)), deactivateUser$);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getConfirmationDialogContent(
    folderTransferStrategy: FolderTransferStrategy,
    userEmail: string,
  ): string {
    const prefixedStringMessage = `The user <b>${userEmail}</b> will be deactivated and you will`;
    if (folderTransferStrategy === 'transferAll') {
      return `${prefixedStringMessage} have all of their folders transferred to you`;
    } else if (folderTransferStrategy === 'transferShared') {
      return `${prefixedStringMessage} have their shared folders transferred to you`;
    } else {
      return `${prefixedStringMessage} lose all of their folders`;
    }
  }

  private getDeactivateUserObservable(): Observable<boolean> {
    return zip(of(this.form.value), this.currentUserID$).pipe(
      switchMap(([formValue, currentUserID]) =>
        this.usersService.disableUser(
          this.userID,
          formValue.folderTransferStrategy,
          this.getFolderTransferStrategyUser(formValue.folderTransferStrategy, currentUserID),
        ),
      ),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  private getFolderTransferStrategyUser(
    folderTransferStrategy: FolderTransferStrategy,
    userID: string,
  ): string | undefined {
    return folderTransferStrategy !== 'none' ? userID : undefined;
  }
}
