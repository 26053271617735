@if (
  {
    shouldRefresh: options.shouldRefresh$ | async,
    onRefresh: options.onRefresh,
    buttonText: options.buttonText$ | async,
  };
  as options
) {
  <button
    [disabled]="!options.shouldRefresh"
    type="button"
    class="btn btn-primary float-end"
    [ngClass]="options.shouldRefresh ? 'btn-primary' : 'btn-secondary'"
    ngbTooltip="Regenerate plot with new options"
    (click)="options.onRefresh()"
  >
    {{ options.buttonText }}
  </button>
}
