import { takeUntil } from 'rxjs/operators';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CleanUp } from '../shared/cleanup';
import { LogoutAction } from './auth/auth.actions';
import { AppState } from './core.store';
import { Store } from '@ngrx/store';
import { selectIsAuthenticatedAfterVerification } from './auth/auth.selectors';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class ActiveService extends CleanUp {
  private polling = false;
  private intNow: number;
  // Time in millseconds when the last poll to detect if the user has switched tabs.
  private lastPoll: number;
  // Maximum time after last request before we should log the user out.
  private readonly SESSION_EXPIRY: number;
  // Period to check whether the user's session has expired and if we should log them out in the UI.
  private readonly POLL_PERIOD: number;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private ngZone: NgZone,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    super();
    this.intNow = this.currentTime;
    this.lastPoll = this.currentTime;
    this.lastActivity = this.currentTime;

    const seconds = 1000;
    const minutes = 60 * seconds;
    this.SESSION_EXPIRY = (appConfig.sessionExpiresMinutes ?? 30) * minutes;
    this.POLL_PERIOD = 2 * seconds;

    // If authenticated, start the logout inactivity poller.
    this.store
      .pipe(selectIsAuthenticatedAfterVerification, takeUntil(this.ngUnsubscribe))
      .subscribe((authenticated) => {
        if (authenticated) {
          this.start();
        } else {
          this.stop();
        }
      });
  }

  start() {
    this.polling = true;
    // Return from another application or developer tools.
    window['onfocus'] = () => {
      this.logoutIfSessionHasExpired();
      this.updateLatestActivity();
    };
    // Leave the application.
    window['onblur'] = () => this.updateLatestActivity();
    this.pollSession();
  }

  stop() {
    delete window['onfocus'];
    this.polling = false;
  }

  get currentTime(): number {
    return new Date().getTime();
  }

  updateLatestActivity() {
    this.lastActivity = this.currentTime;
  }

  /**
   * Time in milliseconds since any of the following:
   * - the page was focused
   * - a network request was made through Angular.
   *   For the time being this ignores requests from workers that use the fetch API.
   */
  private set lastActivity(lastActivity: number) {
    // Set in local storage so persists across page loads and so the `http-interceptor` can write to it
    // without needing `active.service` to be injected (which causes cyclic injection errors). It's a bit of
    // a hack; hence why I've added this documentation.
    localStorage.setItem('lastActivity', lastActivity + '');
  }

  private get lastActivity() {
    return parseInt(localStorage.getItem('lastActivity') || this.currentTime + '', 0);
  }

  /**
   * Test if the user session is valid.
   */
  private pollSession() {
    if (this.polling) {
      this.logoutIfSessionHasExpired();
      // Run this outside of Angular Zone since this is a long polling function.
      // This avoids un-necessary Zone change detection performance.
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => this.pollSession(), this.POLL_PERIOD);
      });
    }
  }

  private logoutIfSessionHasExpired() {
    this.intNow = new Date().getTime();
    // We need to watch for two kinds of events:
    // - when the user switches to another application but leaves BX in the current tab & their session expires.
    // - user switches to another tab and then returns after their session has expired.
    const elapsed = this.intNow - this.lastActivity;
    // Logout if time is greater than the session expiry.
    // Check again to ensure we're still polling or otherwise run the risk of preventing a login.
    if (elapsed > this.SESSION_EXPIRY && this.polling) {
      // Run in Angular Zone as the rest of the function is running out of the zone.
      // We need authService.logout() to trigger change detection in the Angular zone for navigation.
      this.ngZone.run(() => {
        this.store.dispatch(new LogoutAction());
      });
    }
    this.lastPoll = this.intNow;
  }
}
