<label
  [ngClass]="colClasses.label"
  class="col-form-label"
  for="nameScheme"
  ngbTooltip="Name schemes are optionally used to extract information of interest from sequence names automatically. This information may be used to pair heavy/light chains and output name scheme fields as columns in the results table."
>
  Name scheme:
  <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625292">
    <mat-icon class="icon-small">help</mat-icon>
  </a>
</label>
<div [ngClass]="colClasses.control">
  <select
    [formControl]="control"
    id="nameScheme"
    class="form-select"
    data-spec="name-scheme-select"
    ngFormControlValidator
  >
    <option data-spec="name-scheme-option-none" [ngValue]="null"></option>
    @for (nameScheme of options$ | async; track nameScheme) {
      <option data-spec="name-scheme-option" [value]="nameScheme.value">
        {{ nameScheme.displayName }}
      </option>
    }
  </select>
  <bx-form-errors [control]="control" data-spec="name-scheme-error">
    @if (nameSchemeOverride$ | async; as nameSchemeOverride) {
      <a
        type="button"
        class="btn-link"
        (click)="resetNameScheme(nameSchemeOverride.id)"
        data-spec="name-scheme-reset"
      >
        Reset name scheme
      </a>
    }
  </bx-form-errors>
  @if (warning$ | async; as warning) {
    <span data-spec="name-scheme-warning" class="text-danger">
      {{ warning }}
    </span>
  }
  @if (info$ | async; as info) {
    <small data-spec="name-scheme-info" class="form-text text-muted">
      {{ info }}
    </small>
  }
</div>
