import { FormStateOptions, FormStates } from '../form-state.model';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';

// TODO Delete this migration once it has been deployed for maybe a few weeks or a month?
// Or maybe delete it once we've created/ran this migration via a database migration on Nucleus?
export function ngsComparisonMigration(formStates: FormStates): FormStates {
  const comparisonFormState = formStates[PipelineFormID.NGS_COMPARISON];
  if (comparisonFormState) {
    const options = comparisonFormState.options;
    if (options.reclusteringOptions) {
      return formStates;
    }

    const RECLUSTERING_OPTION_NAMES: string[] = [
      'reclustering_clusterMethod',
      'reclustering_sequenceIdentityThreshold',
      'reclustering_region',
    ];
    options.reclusteringOptions = RECLUSTERING_OPTION_NAMES.reduce((agg, optionName) => {
      if (options[optionName] != null) {
        agg[optionName] = options[optionName];
        delete options[optionName];
      }
      return agg;
    }, {} as FormStateOptions);
  }

  return formStates;
}
