import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class BatchJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'batch-rename',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: BatchJobOptionsV1;
  };

  constructor(parameters: { selection: SelectionOptionsV1; options: BatchJobOptionsV1 }) {
    this.parameters = parameters;
  }
}

export interface BatchJobOptionsV1 {}
