import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { first, map, share, shareReplay, tap } from 'rxjs/operators';
import { NGSJobOptionsV1 } from '../../../../nucleus/services/models/ngsOptions.model';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectOption } from '../../models/ui/select-option.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { AntibodyAnnotatorBaseComponent } from './antibody-annotator-base.component';
import { getNucleusPipelineID } from '../../pipeline/pipeline-constants';
import { PipelineAssociationsService } from '../../pipeline/pipeline-associations/pipeline-associations.service';
import {
  annotationStyleOptions,
  AntibodyAnnotatorOptionValues,
  getFreeOptions,
  SequencesAnnotationStyle,
  SequencesChain,
} from './antibody-annotator-option-values.model';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { restrictControlValues } from 'src/app/shared/utils/forms';
import { AnnotatedPluginDocument } from '../../geneious';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

/**
 * AntibodyAnnotator pipeline dialog component that has a reduced set of options
 * for free-tier users.
 */
@Component({
  selector: 'bx-antibody-annotator-free',
  templateUrl: './antibody-annotator-free.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbTooltip,
    MultiSelectComponent,
    FormErrorsComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class AntibodyAnnotatorFreeComponent
  extends AntibodyAnnotatorBaseComponent
  implements OnInit
{
  referenceDatabases$: Observable<SelectOption[][]>;
  multipleRefDbsEnabled$: Observable<boolean>;

  form = this.initFormControls();
  exceedAllowedSequenceCount = false;
  exceedAllowedSequenceCountMessage: string;
  twoHeavyChainsEnabled$: Observable<boolean>;

  readonly annotationStyleOptions = annotationStyleOptions;
  private readonly formDefaults = this.form.getRawValue();

  constructor(
    private readonly pipelineService: PipelineService,
    private readonly validatorService: PipelineFormControlValidatorsService,
    private pipelineAssociationService: PipelineAssociationsService,
    private featureSwitchService: FeatureSwitchService,
    @Optional() @Inject(PIPELINE_DIALOG_DATA) dialogData: PipelineDialogData | null,
  ) {
    super(dialogData);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.referenceDatabases$ = this.pipelineService.getReferenceDatabases().pipe(
      first(),
      tap((options) => {
        const refDBControl = this.form.controls.database_customDatabase;
        const databaseIDs = options.flatMap((group) => group.map((option) => option.value));
        restrictControlValues(refDBControl, databaseIDs, { takeUntil: this.ngUnsubscribe });
        if (databaseIDs[0] && !refDBControl.value?.length) {
          refDBControl.setValue([databaseIDs[0]]);
        }
      }),
      share(),
    );

    this.twoHeavyChainsEnabled$ = this.featureSwitchService
      .isEnabledOnce('twoHeavyChainsInSameSequence')
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );

    // This magic timeout is required, otherwise the control gets stuck in PENDING.
    // See AntibodyAnnotatorComponent for more details.
    setTimeout(() => {
      this.form.controls.database_customDatabase.setAsyncValidators(
        this.validatorService.databaseValidator(),
      );
      this.form.controls.database_customDatabase.updateValueAndValidity();
    });
    this.validateProfilePipelineParameters();
  }

  generateOutputFolderName(): string {
    return this.form.value.outputFolderName;
  }

  generateJobOptions(): NGSJobOptionsV1<AntibodyAnnotatorOptionValues> {
    const { resultName, outputFolderName, ...formValues } = this.form.value as ReturnType<
      typeof this.form.getRawValue
    >;
    return {
      resultName,
      optionValues: getFreeOptions(formValues),
    };
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  private validateProfilePipelineParameters() {
    const totalSequenceCount$: Observable<number> = this.selectionState$.pipe(
      map((selectionState) =>
        selectionState.selectedRows.reduce((acc, row) => this.getNumberOfSequences(acc, row), 0),
      ),
    );
    combineLatest([
      this.pipelineAssociationService.getProfilePipelineAssociationParameters(
        getNucleusPipelineID(this.pipelineFormID),
      ),
      totalSequenceCount$,
    ]).subscribe(([parameters, sequenceCount]) => {
      const maxInputSequenceCount = this.getMaxInputSequenceCount(parameters);
      if (sequenceCount > maxInputSequenceCount) {
        this.form.disable();
        this.exceedAllowedSequenceCount = true;
        this.exceedAllowedSequenceCountMessage = `Up to ${maxInputSequenceCount} sequences can be analyzed at once with your plan (Geneious Biologics Starter Plan). Please select less sequences, or contact us to request an upgrade.`;
      }
    });
  }

  /**
   * Changes in BX-6708 expects selectioState to contain APDs, but it's not always the case, therefore, this method assumes the row is json object if it's not an APD while calculating sequence count
   */
  private getNumberOfSequences(acc: any, row: any) {
    let numberOfSequences;
    if (row.type == 'sequence') {
      numberOfSequences = 1;
    } else if (row instanceof AnnotatedPluginDocument) {
      numberOfSequences = row.getAllFields().number_of_sequences;
    } else {
      numberOfSequences = row.number_of_sequences;
    }
    return +acc + parseInt(numberOfSequences);
  }

  private getMaxInputSequenceCount(parameters: any): number {
    if (parameters['maxInputSequenceCount']) {
      return parameters['maxInputSequenceCount'];
    }
    return Number.MAX_SAFE_INTEGER;
  }

  private initFormControls() {
    return new BxFormGroup({
      outputFolderName: JobDialogContent.getResultNameControl(),
      database_customDatabase: new BxFormControl<string[]>([], Validators.required),
      sequences_chain: new BxFormControl<SequencesChain>('singleUnknownChain', Validators.required),
      sequences_annotationStyle: new BxFormControl<SequencesAnnotationStyle>(
        'IMGT',
        Validators.required,
      ),
      resultName: JobDialogContent.getResultNameControl(),
    });
  }
}

/** Type of this.form.value */
export interface FormValues {
  database_customDatabase: string[];
  sequences_chain: SequencesChain;
  sequences_annotationStyle: SequencesAnnotationStyle;
  resultName?: string;
  outputFolderName?: string;
}
