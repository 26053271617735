import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { AnnotatedPluginDocument } from '../geneious';
import { Observable } from 'rxjs';
import { Item } from '../../../nucleus/v2/models/item.v2.model';
import { map } from 'rxjs/operators';
import { selectCurrentFiles, selectFileEntities } from './files-table-store/files-table.selectors';
import {
  moveFiles,
  removeFiles,
  updateFileName,
} from './files-table-store/files-store/files.actions';
import { Folder } from '../folders/models/folder.model';
import { clearFilesTableState } from './files-table-store/files-table.actions';
import { Dictionary } from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class FilesTableFacade {
  files$: Observable<Item[]>;
  filesDictionary$: Observable<Dictionary<Item>>;

  constructor(private store: Store<AppState>) {
    this.files$ = this.store.pipe(selectCurrentFiles);

    this.filesDictionary$ = this.store.pipe(select(selectFileEntities));
  }

  /**
   * Get file table files as Annotated Plugin Documents.
   *
   * @param ids - of the documents to retrieve as Annotated Plugin Documents.
   * @param folder - parent folder of a document to store as reference to in the Annotated Plugin Document.
   */
  getAPDs(ids: string[], folder?: Folder): Observable<AnnotatedPluginDocument[]> {
    return this.files$.pipe(
      map((items) => {
        return ids.map((id) => items.find((item) => item.id == id));
      }),
      map((items) => items.map((item) => AnnotatedPluginDocument.fromNucleusItem(item, folder))),
    );
  }

  /** Actions **/

  /**
   * Clear all data in the files table.
   * Doesn't actually do any deletion on the server, used to reset the state.
   */
  clear(): void {
    this.store.dispatch(clearFilesTableState());
  }

  /**
   * Move files to a new folder.
   *
   * @param ids - of the files to move.
   * @param selectAll - is all the files in the file table selected for moving.
   * @param toFolderID - of the new folder the files are going to move to.
   */
  moveFiles(ids: string[], selectAll: boolean, toFolderID: string): void {
    this.store.dispatch(
      moveFiles({ payload: { fileIDs: ids, selectAll, toFolderID: toFolderID } }),
    );
  }

  /**
   * Removes files permanently from the folder.
   * Deletes them on the server.
   *
   * @param ids - of the files to remove. Acts as ids to not remove if select all is true.
   * @param selectAll - whether select all is taken into account.
   */
  removeRows(ids: string[], selectAll: boolean): void {
    this.store.dispatch(removeFiles({ payload: { fileIDs: ids, selectAll: selectAll } }));
  }

  /**
   * Updates the file name of a file.
   *
   * @param id - of the file to update the name of.
   * @param name - new name of the file.
   * @param blobRevision - current blob revision of the file (will fail if it is wrong).
   */
  updateFileName(id: string, name: string, blobRevision: number): void {
    this.store.dispatch(
      updateFileName({
        payload: {
          fileID: id,
          name: name,
          blobRevision: blobRevision,
        },
      }),
    );
  }
}
