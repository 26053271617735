import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { Observable, of } from 'rxjs';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BioregisterService } from './bioregister.service';
import { SelectOption } from '../../models/ui/select-option.model';
import { catchError, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { DocumentServiceColumn } from '../../../../nucleus/services/documentService/types';
import {
  RegisterSequencesOptions,
  RegisterSequencesParams,
} from '../../../../nucleus/services/models/registerSequences.model';
import { ExtractSequencesDialogOptionsV3 } from 'src/nucleus/services/models/extractSequencesV3.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import {
  heavyGenes,
  heavyRegions,
  lightGenes,
  lightRegions,
} from '../../antibodyAnnotatorRegions.service';
import { CardComponent } from '../../../shared/card/card.component';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SelectComponent } from '../../../shared/select/select.component';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';

@Component({
  selector: 'bx-register-sequences',
  templateUrl: './register-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    SelectComponent,
    NgTemplateOutlet,
    MultiSelectComponent,
    SpinnerComponent,
    AsyncPipe,
  ],
})
export class RegisterSequencesComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  title = 'Register Sequences in Dotmatics Bioregister';
  form = new BxFormGroup({
    project: new BxFormControl(undefined, Validators.required),
    target: new BxFormControl(undefined, Validators.required),
    species: new BxFormControl(undefined, Validators.required),
    sampleIdColumn: new BxFormControl(undefined),
    nameColumn: new BxFormControl(undefined, Validators.required),
    heavyChainClonotypeColumn: new BxFormControl(undefined, Validators.required),
    lightChainClonotypeColumn: new BxFormControl(undefined, Validators.required),
    sequenceType: new BxFormControl('nucleotide', Validators.required),
    isotype: new BxFormControl([], Validators.required),
    experimentId: new BxFormControl(undefined, [Validators.required, Validators.maxLength(4000)]),
  });
  earlyRelease = false;
  knowledgeBaseArticle = '';

  numberOfSequences: number;
  folderId: string;

  selectedSequencesChains: string[];
  isProteinDocument: boolean;

  projects$: Observable<OptionsData>;
  targets$: Observable<OptionsData>;
  species$: Observable<OptionsData>;
  sampleIdColumns: SelectOption[];
  nameColumns: SelectOption[];
  heavyChainClonotypeColumns: SelectOption[];
  lightChainClonotypeColumns: SelectOption[];
  isotypes$: Observable<OptionsData>;

  heavyChainClonotypeColumnDisabled: boolean;
  lightChainClonotypeColumnDisabled: boolean;

  readonly ItemState = ItemState;
  private defaultTableColumns: DocumentServiceColumn[];
  private joinedColumns: DocumentServiceColumn[];
  private clusterColumns: DocumentServiceColumn[];
  private readonly formDefaults = this.form.getRawValue();
  private readonly otherVariables: RegisterSequencesDialogOptions;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) dialogData: PipelineDialogData<RegisterSequencesDialogOptions>,
    private bioregisterService: BioregisterService,
  ) {
    super('register-sequences', PipelineFormID.REGISTER_SEQUENCES);
    this.numberOfSequences = dialogData.selected.noOfRowsSelected;
    this.folderId = dialogData.folderID;
    this.otherVariables = dialogData.otherVariables;

    this.selectedSequencesChains = dialogData.selected.selectedRows.map((row) => row.Chain);

    this.heavyChainClonotypeColumnDisabled = this.selectedSequencesChains.every(
      (chain) => chain === 'Light',
    );
    this.lightChainClonotypeColumnDisabled = this.selectedSequencesChains.every(
      (chain) => chain === 'Heavy',
    );

    this.isProteinDocument = this.otherVariables.isProteinDocument;
    this.defaultTableColumns = this.otherVariables.defaultTableColumns || [];
    this.clusterColumns = this.otherVariables.clusterColumns || [];
    this.nameColumns = this.defaultTableColumns.map(
      (column) => new SelectOption(column.displayName, column.name),
    );
    this.joinedColumns = this.otherVariables.joinedColumns || [];
    this.nameColumns = this.defaultTableColumns.map(
      (column) => new SelectOption(column.displayName, column.name),
    );

    this.sampleIdColumns = [{ displayName: '-- Not Specified --', name: null }]
      .concat(this.joinedColumns)
      .map((column) => new SelectOption(column.displayName, column.name));

    this.heavyChainClonotypeColumns = [{ displayName: '-- Not Specified --', name: null }]
      .concat(
        this.clusterColumns.filter((column) =>
          [...heavyRegions, ...heavyGenes].some((region) => column.displayName.startsWith(region)),
        ),
      )
      .map((column) => new SelectOption(column.displayName, column.name));

    this.lightChainClonotypeColumns = [{ displayName: '-- Not Specified --', name: null }]
      .concat(
        this.clusterColumns.filter((column) =>
          [...lightRegions, ...lightGenes].some((region) => column.displayName.startsWith(region)),
        ),
      )
      .map((column) => new SelectOption(column.displayName, column.name));

    this.ensureCorrectClonotypeColumnsIsPreselected();

    this.projects$ = this.bioregisterService.getProjectOptions().pipe(this.mapOptionsToData);
    this.targets$ = this.bioregisterService.getTargetOptions().pipe(this.mapOptionsToData);
    this.species$ = this.bioregisterService.getSpeciesOptions().pipe(this.mapOptionsToData);
    this.isotypes$ = this.bioregisterService.getIsotypeOptions().pipe(this.mapOptionsToData);
  }

  ngOnInit(): void {
    this.isProteinDocument = this.otherVariables.isProteinDocument;
    this.defaultTableColumns = this.otherVariables.defaultTableColumns || [];
    this.clusterColumns = this.otherVariables.clusterColumns || [];

    if (this.isProteinDocument && this.form.get('sequenceType').value === 'nucleotide') {
      this.form.get('sequenceType').reset('protein');
    }

    if (this.nameColumns.length > 0) {
      const nameColumnOption = this.nameColumns.find((option) => option.value === 'Name');
      if (nameColumnOption) {
        this.form.get('nameColumn').reset(nameColumnOption.value);
      } else {
        this.form.get('nameColumn').reset(this.nameColumns[0].value);
      }
    }

    if (this.heavyChainClonotypeColumnDisabled) {
      this.form.get('heavyChainClonotypeColumn').disable();
    }

    if (this.lightChainClonotypeColumnDisabled) {
      this.form.get('lightChainClonotypeColumn').disable();
    }

    this.ensureFirstOptionSelected(this.projects$, 'project');
    this.ensureFirstOptionSelected(this.targets$, 'target');
    this.ensureFirstOptionSelected(this.species$, 'species');
    this.ensureSampleIdPlaceholderIsSelected();
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  run() {
    const options: RegisterSequencesOptions = {
      project: this.form.get('project').value,
      heavyChainClonotypeColumn: this.form.get('heavyChainClonotypeColumn').value,
      lightChainClonotypeColumn: this.form.get('lightChainClonotypeColumn').value,
      nameColumn: this.form.get('nameColumn').value,
      sampleIdColumn: this.form.get('sampleIdColumn').value,
      species: this.form.get('species').value,
      target: this.form.get('target').value,
      isProtein: this.form.get('sequenceType').value === 'protein',
      isotype: this.form.get('isotype').value,
      selectionParams: this.otherVariables.extractionOptions,
      experimentId: this.form.get('experimentId').value,
    };
    return new RegisterSequencesParams({
      selection: {
        ids: [this.otherVariables.extractionOptions.selection.documentID],
        folderId: this.folderId,
        selectAll: false,
      },
      options,
    });
  }

  private ensureSampleIdPlaceholderIsSelected() {
    const sampleIdDefault = this.sampleIdColumns.find((option) =>
      option.displayName.includes('-- Not Specified --'),
    );
    this.form.get('sampleIdColumn').reset(sampleIdDefault.value);
  }

  private ensureCorrectClonotypeColumnsIsPreselected() {
    if (this.clusterColumns.length > 0) {
      let heavyClonotypeColumnOption =
        this.heavyChainClonotypeColumns.find(
          (option) => option.value === 'Heavy CDR3' && !this.heavyChainClonotypeColumnDisabled,
        ) ?? this.heavyChainClonotypeColumns[0];
      this.form.get('heavyChainClonotypeColumn').reset(heavyClonotypeColumnOption.value);

      let lightClonotypeColumnOption =
        this.lightChainClonotypeColumns.find(
          (option) => option.value === 'Light CDR3' && !this.lightChainClonotypeColumnDisabled,
        ) ?? this.lightChainClonotypeColumns[0];
      this.form.get('lightChainClonotypeColumn').reset(lightClonotypeColumnOption.value);
    }
  }

  private ensureFirstOptionSelected(source$: Observable<OptionsData>, formControlName: string) {
    source$
      .pipe(
        filter((data) => [ItemState.RESOLVED, ItemState.NO_OPTION].includes(data.state)),
        map((data) => data.options),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((options) => {
        const control = this.form.get(formControlName);
        if (options.length > 0 && !control.value) {
          control.reset(options[0].value);
        } else if (
          control.value &&
          !options.map((option) => option.value).includes(control.value)
        ) {
          if (options.length === 0) {
            control.reset();
          } else {
            control.reset(options[0].value);
          }
        }
      });
  }

  private mapOptionsToData<T>(source$: Observable<SelectOption<T>[]>): Observable<OptionsData> {
    return source$.pipe(
      map((options) => {
        if (options.length > 0) {
          return { state: ItemState.RESOLVED, options } as OptionsDataResolved;
        }
        return NO_OPTION;
      }),
      startWith(LOADING_OPTIONS),
      catchError(() => of(ERRORED_OPTIONS)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}

export interface RegisterSequencesDialogOptions {
  extractionOptions: ExtractSequencesDialogOptionsV3;
  defaultTableColumns: DocumentServiceColumn[];
  clusterColumns: DocumentServiceColumn[];
  isProteinDocument: boolean;
  joinedColumns: DocumentServiceColumn[];
}

/**
 * State of item that is being fetched from the server.
 */
enum ItemState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
  NO_OPTION = 'NO_OPTION',
}

interface OptionsDataLoading {
  state: ItemState.LOADING;
  options?: undefined;
}
interface OptionsDataErrored {
  state: ItemState.ERROR;
  options?: undefined;
}
interface OptionsDataResolved {
  state: ItemState.RESOLVED;
  options: SelectOption[];
}
interface OptionsDataNoOption {
  state: ItemState.NO_OPTION;
  options?: [];
}
type OptionsData =
  | OptionsDataLoading
  | OptionsDataErrored
  | OptionsDataResolved
  | OptionsDataNoOption;

const LOADING_OPTIONS: OptionsDataLoading = { state: ItemState.LOADING };
const ERRORED_OPTIONS: OptionsDataErrored = { state: ItemState.ERROR };
const NO_OPTION: OptionsDataNoOption = { state: ItemState.NO_OPTION, options: [] };
