<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'name-schemes'], label: 'Name Schemes' }]"
></bx-settings-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      [routerLink]="['/name-schemes/new']"
      data-testid="new-name-scheme"
    >
      New
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="deleteNamingSchemes()"
      [disabled]="selectionState.noOfRowsSelected < 1"
    >
      Delete
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>
<bx-grid
  class="w-100 flex-grow-1 flex-shrink-1"
  [initialColumnDefs]="columnDefs"
  [datasource]="nameSchemeResource"
  (selectionChanged)="selectionChanged($event)"
  tableType="fileNamingSchemes"
  (gridReadyEmitter)="onGridReady()"
  profileComponent="fileNamingSchemes"
  data-testid="name-schemes-grid"
></bx-grid>
