@if (
  {
    data: data$ | async,
    legendConfig: legendConfig$ | async,
    branchTransform: branchTransform$ | async,
    autoColorBranches: autoColorBranches$ | async,
    tipLabelConfig: tipLabelConfig$ | async,
    defaultNodeTooltips: defaultNodeTooltips$ | async,
    heatmapConfig: heatmapConfig$ | async,
  };
  as params
) {
  @if (params.data && params.legendConfig && params.heatmapConfig && params.tipLabelConfig) {
    <bx-circular-tree-graph
      class="h-100"
      [data]="params.data.data"
      [tipLabelConfig]="params.tipLabelConfig"
      [branchTransform]="params.branchTransform"
      [coloringMetadataConfig]="params.data.coloringMetadataConfig"
      [heatmapConfig]="params.heatmapConfig"
      [legendConfig]="params.legendConfig"
      [autoColorBranches]="params.autoColorBranches"
      [defaultTooltips]="params.defaultNodeTooltips"
    ></bx-circular-tree-graph>
  }
}
