{
  "scaffoldAnalysis": {
    "help": "Select one or more nucleotide sequences (or a sequence list) to run this pipeline",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "group-sequences": {
    "help": "Select two or more sequence documents or sequence list documents, to group them into a single sequence list document",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 2,
            "to": 2147483647,
            "documentClass": "com.biomatters.geneious.publicapi.documents.sequence.SequenceDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.geneious.publicapi.documents.sequence.SequenceListDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.geneious.publicapi.documents.sequence.SequenceListDocument"
          },
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.geneious.publicapi.documents.sequence.SequenceDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "annotation-alignment": {
    "help": "Select one or more annotated sequences to run this pipeline",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "motif-annotator": {
    "help": "Select 1 - 5000 sequences to run this pipeline",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 5000,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 5000,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "extract-sequences": {
    "help": "Select one or more sequences to extract them to your folder",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 1000,
            "documentClass": "com.biomatters.geneious.common.documents.tables.MultipleTableDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "batch-rename": {
    "help": "Select any number of sequences or sequence lists to rename them using values from another column such as Organism",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "pair-chains": {
    "help": "Select two or more sequences, or one or more sequence list documents to pair their Heavy/Light chains",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 2,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 2
      }
    ]
  },
  "paired-reads": {
    "help": "Select one or more sequence list documents to link their reads as paired end reads",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "ngs": {
    "help": "Select one or more nucleotide OR protein sequences (or sequence list) to run this operation",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "peptide-annotator": {
    "help": "Select one or more nucleotide OR protein sequences (or sequence list) to run this operation",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "protein-annotator": {
    "help": "Select one or more nucleotide OR protein sequences (or sequence list) to run this operation",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 1
      }
    ]
  },
  "add-cluster": {
    "help": "Run this on exactly one Biologics Annotator Result",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "repair-sequences": {
    "help": "Select one or more nucleotide sequences to run this pipeline",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "ngs-comparisons": {
    "help": "Select two or more Biologics Annotator Results to run this pipeline",
    "selectionSignatures": [
      {
        "signatureAtoms": [
          {
            "from": 2,
            "to": 2147483647,
            "documentClass": "com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument"
          }
        ]
      }
    ]
  },
  "alignment": {
    "help": "Select 2-10,000 sequences to align them",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 2,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 2147483647,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "nucleotide",
        "sequencesInAlignmentMinimum": 2,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      },
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 2147483647,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 2
      },
      {
        "sequencesInAlignmentMaximum": 2147483647,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 2,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "batch-assemble": {
    "help": "Select two or more sequences to assemble them",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "allowSequencesInNucleotideAlignments": false,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 2,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "find-heterozygotes": {
    "help": "Select one or more nucleotide sequences to find heterozygote bases",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "allowSequencesInNucleotideAlignments": true,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "export": {
    "help": "Select any number of documents to export them",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 0,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "signatureAtoms": [
          {
            "from": 1,
            "to": 2147483647,
            "documentClass": "com.biomatters.geneious.publicapi.documents.PluginDocument"
          }
        ],
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 0,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "remove-umi-duplicates": {
    "help": "Select one or more nucleotide sequences or lists to remove reads with duplicate UMIs",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "trim-ends": {
    "help": "Select one or more nucleotide sequences or lists to trim ends",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "nucleotide",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "single-cell-antibody-analysis": {
    "help": "Select one or more nucleotide sequence list documents to perform Single Cell Antibody Annotator",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "ngs-antibody-annotator": {
    "help": "Select one or more nucleotide sequences (or sequence list) to run this operation",
    "selectionSignatures": [
      {
        "sequencesInAlignmentMaximum": 0,
        "nucleotideAlignmentMinimum": 0,
        "nucleotideSequenceMaximum": 2147483647,
        "proteinSequenceMaximum": 0,
        "proteinAlignmentMinimum": 0,
        "alignmentSequenceType": "aminoAcid",
        "sequencesInAlignmentMinimum": 0,
        "nucleotideAlignmentMaximum": 0,
        "proteinAlignmentMaximum": 0,
        "nucleotideSequenceMinimum": 1,
        "proteinSequenceMinimum": 0
      }
    ]
  },
  "master-database-search": {
    "help": "Select one or more sequences to search on",
    "selectionSignatures": []
  },
  "master-database-importer": {
    "help": "Select 1-1,000 sequences to search on",
    "selectionSignatures": []
  }
}
