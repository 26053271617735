import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import {
  MetadataColumnAbomination,
  RemoveSvOption,
} from '../../../features/sequence-viewer-angular/sequence-viewer.interfaces';
import { CleanUp } from '../../../shared/cleanup';
import { OverlaysService } from '../../../shared/overlays/overlays.service';
import {
  DocumentSelectionSignature,
  selectionSignatureMatches,
} from '../../document-selection-signature/document-selection-signature.model';
import { AnnotatedPluginDocument } from '../../geneious';
import { getRowIdentifier } from '../../ngs/getRowIdentifier';
import {
  SequenceDocument,
  SequenceSelectionService,
} from '../../sequence-viewer/sequence-selection.service';
import { SequenceViewerMetadataService } from '../../sequence-viewer/sequence-viewer-metadata.service';
import { SequenceViewerPreferencesService } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.service';
import { ViewerDocumentData, ViewerDocumentSelection } from '../viewer-document-data';
import { annotatedPluginDocumentViewerSelector } from '../viewer-selectors';
import { viewerSelectionToSelectionState } from '../viewers-helper';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';
import { FolderService } from '../../folders/folder.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { Folder } from '../../folders/models/folder.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectSequenceViewerPreference } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.selectors';
import { SequenceTopology } from '../../../features/sequence-viewer-angular/sequence-viewer.service';
import { AsyncPipe } from '@angular/common';
import { OpenDocumentButtonComponent } from '../../../shared/open-document-button/open-document-button.component';
import { SequenceViewerComponent } from '../../../features/sequence-viewer-angular/sequence-viewer.component';
import { FilesSequenceViewerExportMenuComponent } from './files-sequence-viewer-export/files-sequence-viewer-export-menu.component';
import { SequenceEditingControlsComponent } from '../../../features/sequence-viewer-angular/sequence-editing-controls/sequence-editing-controls.component';

@ViewerComponent({
  key: 'sequence-viewer',
  title: 'Sequence Viewer',
  selector: annotatedPluginDocumentViewerSelector([
    DocumentSelectionSignature.forNucleotideSequences(1, 2147483647),
    DocumentSelectionSignature.forProteinSequences(1, 2147483647),
    DocumentSelectionSignature.forNucleotideAlignments(1, 1),
    DocumentSelectionSignature.forProteinAlignments(1, 1),
  ]),
})
@Component({
  selector: 'bx-files-sequence-viewer',
  templateUrl: './files-sequence-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OpenDocumentButtonComponent,
    SequenceViewerComponent,
    FilesSequenceViewerExportMenuComponent,
    SequenceEditingControlsComponent,
    AsyncPipe,
  ],
})
export class FilesSequenceViewerComponent extends CleanUp implements OnDestroy {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column overflow-hidden flex-grow-1 flex-shrink-1';

  showSequenceViewer$: BehaviorSubject<boolean>;
  sequenceEditingEnabled$: Observable<boolean>;
  // Max number of Sequence Documents that can be selected.
  readonly MAX_COUNT_SELECTION = 100;
  readonly MAX_SEQUENCES_PER_DOCUMENT = 1000;
  readonly MAX_SEQUENCES = 5000;

  documentsType$: Observable<string>;
  sequenceTopology$: Observable<SequenceTopology>;
  referencePosition$: Observable<number>;
  sequenceMetadataOrder$: Observable<string[]>;

  // The sequence viewer component can be passed documents which it cannot show - we have to filter those out.
  // This variable represents the documents which we think are "ok" to show.
  numberOfValidDocuments$: Observable<number>;
  documents$: Observable<AnnotatedPluginDocument[]>;
  data$: Observable<SequenceDocument>;
  isLoading$: Observable<boolean>;
  metadataColumns$: Observable<MetadataColumnAbomination[]>;
  sequenceViewerPreferencesState$: Observable<any>;

  isComplexDocument$: Observable<boolean>;
  isReadonlyDocument$: Observable<boolean>;
  openDocumentQuery$: Observable<ViewerPageURLSelectionState>;

  sequenceSelectionEnabled$: Observable<boolean>;
  circularModeEnabled$: Observable<boolean>;
  toolbarWarningMsg$: Observable<string | null>;
  isInEditMode = false;

  private readonly noSvJsonReason$: Observable<string>;
  private readonly selectedSequenceType$: Observable<string>;
  private readonly viewerData$: Observable<ViewerDocumentData>;
  private readonly state$: Observable<ViewerDocumentSelection>;
  private readonly selectionIsValid$: Observable<boolean>;
  private readonly numberOfIgnoredDocuments$: Observable<number>;
  private readonly selectedDocumentIDs$: Observable<string[]>;
  private readonly optionValueChanges$ = new Subject<any>();
  private readonly optionRemoved$ = new Subject<any>();
  private readonly sequenceHelpText$: Observable<string>;
  private readonly sequenceSelectionChanged$ = new Subject<number[]>();

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private seqSelectionService: SequenceSelectionService,
    private viewerDataService: ViewerDataService,
    private overlaysService: OverlaysService,
    private sequenceViewerPreferencesService: SequenceViewerPreferencesService,
    private folderService: FolderService,
    private svMetadataService: SequenceViewerMetadataService,
    private store: Store<AppState>,
  ) {
    super();

    this.viewerData$ = this.viewerDataService.getData('sequence-viewer');
    this.state$ = this.viewerData$.pipe(
      map((data) => data.selection),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.showSequenceViewer$ = new BehaviorSubject<boolean>(true);
    this.isComplexDocument$ = this.viewerData$.pipe(map((data) => data.containsComplexDocument));
    this.openDocumentQuery$ = this.viewerData$.pipe(map((data) => data.openQueryParams));

    const folder$ = this.state$.pipe(
      filter((selection) => selection.rows.length > 0),
      map((selection) => selection.rows[0].parentID),
      switchMap((parentID) => this.folderService.get(parentID)),
    );
    this.isReadonlyDocument$ = folder$.pipe(
      map((folder) => PermissionsService.hasOnlyReadAccess(folder.permissions)),
    );

    const noSvJsonRow$ = this.state$.pipe(
      map((selection) => selection.rows.find((row) => row.getAllFields().noSvJson === 'true')),
    );

    this.noSvJsonReason$ = noSvJsonRow$.pipe(
      filter((noSvJsonRow) => !!noSvJsonRow),
      map((noSvJsonRow) => noSvJsonRow.getAllFields().noSvJsonReason || 'No sequences available'),
      startWith(undefined),
    );

    this.documents$ = this.state$.pipe(
      withLatestFrom(noSvJsonRow$),
      map(([selection, noSvJsonRow]) => {
        if (noSvJsonRow) {
          return [];
        }
        return viewerSelectionToSelectionState(selection).selectedRows;
      }),
    );

    this.numberOfValidDocuments$ = this.documents$.pipe(map((documents) => documents.length));

    const firstDocument$ = this.documents$.pipe(
      filter((documents) => documents.length > 0),
      map((documents) => documents[0] as any),
    );

    this.sequenceTopology$ = firstDocument$.pipe(
      map((document) => document.metadata.topology || 'linear'),
    );

    this.documentsType$ = firstDocument$.pipe(
      map((firstDoc) => firstDoc.documentType || firstDoc.metadata.documentType),
    );

    this.sequenceMetadataOrder$ = firstDocument$.pipe(
      map((firstDocument) => {
        const metadataOrder = firstDocument.metadata.sequenceMetadataOrder;
        return metadataOrder ? JSON.parse(metadataOrder).map((col: string) => `BX_${col}`) : [];
      }),
    );

    const maximumSelectionCountExceeded$: Observable<boolean> = this.state$.pipe(
      map((state) => state.totalSelected > this.MAX_COUNT_SELECTION),
    );

    this.referencePosition$ = firstDocument$.pipe(
      withLatestFrom(this.documentsType$),
      map(([firstDocument, documentType]) => {
        return documentType === 'Alignment' || documentType === 'Tree'
          ? Number(firstDocument.metadata.initialReferencePosition)
          : undefined;
      }),
    );

    this.selectionIsValid$ = combineLatest([this.numberOfValidDocuments$, this.state$]).pipe(
      map(([numberOfValidDocuments, state]) => {
        return state.totalSelected > 0 && numberOfValidDocuments > 0;
      }),
    );

    this.numberOfIgnoredDocuments$ = combineLatest([
      this.numberOfValidDocuments$,
      this.state$,
    ]).pipe(map(([numberOfValidDocuments, state]) => state.totalSelected - numberOfValidDocuments));

    this.selectedDocumentIDs$ = this.state$.pipe(
      map((selection) => selection.rows.map((row) => row.id)),
    );

    this.sequenceHelpText$ = combineLatest([
      this.noSvJsonReason$,
      this.numberOfValidDocuments$,
      this.selectionIsValid$,
      this.numberOfIgnoredDocuments$,
    ]).pipe(
      map(
        ([noSvJsonReason, numberOfValidDocuments, selectionIsValid, numberOfIgnoredDocuments]) => {
          if (noSvJsonReason) {
            return `Unable to display sequences\n${noSvJsonReason}`;
          } else if (numberOfValidDocuments === 0) {
            const message = [];
            if (!selectionIsValid && numberOfIgnoredDocuments > 0) {
              message.push('Unable to display sequences.');
            }
            message.push(
              `Select one or more sequence documents to view them (max ${this.MAX_COUNT_SELECTION}).`,
            );
            return message.join('\n');
          } else {
            return '';
          }
        },
      ),
    );

    const totalNumberOfSequences$ = this.documents$.pipe(
      map((documents) => {
        return documents.reduce((acc, document) => {
          const numberOfSequences = Number(document.getAllFields().number_of_sequences) || 1;
          return acc + numberOfSequences;
        }, 0);
      }),
    );

    const documentsToRetrieve$ = this.documents$.pipe(
      withLatestFrom(maximumSelectionCountExceeded$),
      // Retrieve and map sequences.
      map(([docs, limitExceeded]) => {
        if (limitExceeded) {
          return docs.slice(0, this.MAX_COUNT_SELECTION);
        } else {
          return docs;
        }
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    const numberOfFetchedSequences$ = documentsToRetrieve$.pipe(
      map((documents) => {
        return documents.reduce((acc, document) => {
          const numberOfSequences = Number(document.getAllFields().number_of_sequences) || 1;
          return acc + Math.min(numberOfSequences, this.MAX_SEQUENCES_PER_DOCUMENT);
        }, 0);
      }),
      map((count) => Math.min(count, this.MAX_SEQUENCES)),
    );

    const allSequencesData$ = documentsToRetrieve$.pipe(
      switchMap((docs) =>
        this.seqSelectionService.retrieveAllSequencesData(docs.map((row) => getRowIdentifier(row))),
      ),
      share(),
    );

    this.toolbarWarningMsg$ = allSequencesData$.pipe(
      withLatestFrom(
        totalNumberOfSequences$,
        maximumSelectionCountExceeded$,
        numberOfFetchedSequences$,
      ),
      map(
        ([
          sequenceDocument,
          totalNumberOfSequences,
          maximumSelectionCountExceeded,
          numberOfFetchedSequences,
        ]) => {
          if (sequenceDocument.sequences.length === totalNumberOfSequences) {
            return null;
          }
          if (maximumSelectionCountExceeded) {
            return `Showing first ${this.MAX_COUNT_SELECTION} documents. To view more
          sequences, group them via Pre-Processing > Group Sequences.`;
          }
          return `Showing first ${numberOfFetchedSequences} sequences only`;
        },
      ),
    );

    this.selectedSequenceType$ = allSequencesData$.pipe(
      map((sequenceDocument) => {
        const hasQualitySequence = sequenceDocument.sequences.some(
          (sequence) => sequence.sequence && sequence.sequence.qualities,
        );

        if (hasQualitySequence) {
          return 'QUAL';
        }

        for (const sequence of sequenceDocument.sequences) {
          if (sequence.sequence && sequence.sequence.sequenceType === 'Nucleotide') {
            return 'DNA';
          }
          if (sequence.sequence && sequence.sequence.sequenceType === 'AminoAcid') {
            return 'AA';
          }
        }

        return undefined;
      }),
      startWith(undefined),
    );

    this.data$ = allSequencesData$.pipe(
      // Remove `BX_` prefix from columns that are used by `score` if the sequence has that metadata property.
      map((sequenceDocument) => {
        const sequences = sequenceDocument.sequences.map((sequence: any) => {
          if (sequence.metadata) {
            const metadata: any = {};
            Object.keys(sequence.metadata).map((key) => {
              const keyWithoutBXPrefix = key.replace('BX_', '');
              if (this.svMetadataService.SCORE_COLUMNS.includes(keyWithoutBXPrefix)) {
                metadata[keyWithoutBXPrefix] = sequence.metadata[key];
              } else if (keyWithoutBXPrefix.toLowerCase() === 'labels') {
                sequence.metadata[key]
                  .split(',')
                  .forEach((label: string) => (metadata[`${label.trim()} (label)`] = true));
              } else {
                metadata[key] = sequence.metadata[key];
              }
            });
            return { ...sequence, ...{ metadata: metadata } };
          } else {
            return sequence;
          }
        });

        return { sequences, trees: sequenceDocument.trees, consensus: sequenceDocument.consensus };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.sequenceViewerPreferencesState$ = firstDocument$.pipe(
      switchMap((firstDocument) =>
        this.store.select(selectSequenceViewerPreference(firstDocument.id)),
      ),
      withLatestFrom(this.selectedSequenceType$),
      map(([data, selectedSequenceType]) => {
        // Inject the server-side quality colour scheme if selected documents include quality.
        if (selectedSequenceType === 'QUAL') {
          return {
            ...data,
            ...{
              sequencesPlugin: {
                ...data.sequencesPlugin,
                DNAColorScheme:
                  data.sequencesPlugin && data.sequencesPlugin.qualityColorScheme
                    ? data.sequencesPlugin.qualityColorScheme
                    : 'quality',
              },
            },
          };
        }
        return data;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.svMetadataService.liabilityScoreThresholdChanged({
      liabilityScoreLow: -2000,
      liabilityScoreHigh: -1000,
    });
    this.metadataColumns$ = this.data$.pipe(
      withLatestFrom(this.sequenceMetadataOrder$),
      map(([{ sequences }, order]) =>
        this.svMetadataService.buildMetadataColumnsFromSequences(sequences, true, order),
      ),
    );

    this.isLoading$ = merge(this.state$.pipe(map(() => true)), this.data$.pipe(map(() => false)));

    this.isLoading$
      .pipe(
        withLatestFrom(this.selectionIsValid$, this.sequenceHelpText$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isLoading, selectionIsValid, sequenceHelpText]) => {
        if (isLoading) {
          this.overlaysService.createLoadingEvent('sequence-viewer');
        } else if (!selectionIsValid) {
          this.overlaysService.createMessageEvent('sequence-viewer', sequenceHelpText);
        } else {
          this.overlaysService.hideOverlay('sequence-viewer');
        }
      });

    this.sequenceEditingEnabled$ = this.featureSwitchService.isEnabledOnce('sequenceEditing').pipe(
      withLatestFrom(this.viewerData$, folder$),
      map(
        ([isSequenceEditingEnabled, viewerData, folder]) =>
          isSequenceEditingEnabled &&
          !viewerData.isPreviewView &&
          selectionSignatureMatches(viewerData.selection.rows, [
            DocumentSelectionSignature.forNucleotideSequences(1, 1000),
            DocumentSelectionSignature.forProteinSequences(1, 1000),
          ]) &&
          folder instanceof Folder,
      ),
    );

    this.circularModeEnabled$ = this.featureSwitchService.isEnabledOnce('circularSequenceViewer');

    this.sequenceSelectionEnabled$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('sequencesSelection'),
      this.selectedDocumentIDs$,
    ]).pipe(
      map(
        ([isSequenceSelectionEnabled, selectedDocuments]) =>
          isSequenceSelectionEnabled && selectedDocuments.length === 1,
      ),
    );

    this.optionValueChanges$
      .pipe(
        withLatestFrom(this.selectedDocumentIDs$, this.selectedSequenceType$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([option, selectedDocuments, selectedSequenceType]) => {
        if (option['clear_global_preferences']) {
          this.sequenceViewerPreferencesService.clearOptions();

          this.reloadSequenceViewer();
        } else if (option['clear_all_preferences']) {
          this.sequenceViewerPreferencesService.clearOptions(selectedDocuments);

          this.reloadSequenceViewer();
        } else {
          this.sequenceViewerPreferencesService.upsertOption(
            option,
            selectedSequenceType,
            selectedDocuments,
          );
        }
      });

    this.optionRemoved$
      .pipe(withLatestFrom(this.selectedDocumentIDs$), takeUntil(this.ngUnsubscribe))
      .subscribe(([event, selectedDocuments]) => {
        this.sequenceViewerPreferencesService.removeOption(event, selectedDocuments);
      });

    this.sequenceSelectionChanged$
      .pipe(withLatestFrom(this.selectedDocumentIDs$), takeUntil(this.ngUnsubscribe))
      .subscribe(([indices, selectedDocuments]) => {
        // TODO: Support saving selection for multiple document selection.
        this.sequenceViewerPreferencesService.saveSequencesSelection(selectedDocuments[0], indices);
      });
  }

  liabilityScoreThresholdChanged(event: any) {
    this.svMetadataService.liabilityScoreThresholdChanged(event);
  }

  optionChanged(option: any) {
    this.optionValueChanges$.next(option);
  }

  optionRemoved(event: RemoveSvOption) {
    this.optionRemoved$.next(event);
  }

  reloadSequenceViewer() {
    this.showSequenceViewer$.next(false);
    // setTimeout to add a wait time to make sure the off/on toggling triggers.
    setTimeout(() => this.showSequenceViewer$.next(true));
  }

  enableEditing() {
    this.isInEditMode = true;
  }

  disableEditing() {
    this.isInEditMode = false;
  }

  sequencesSelectionChanged(indices: number[]) {
    this.sequenceSelectionChanged$.next(indices);
  }
}
