import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'bx-external-wrapper',
  templateUrl: './external-wrapper.component.html',
  standalone: true,
})
export class ExternalWrapperComponent {
  @HostBinding('class') readonly hostClass = [
    'd-flex',
    'justify-content-center',
    'h-100',
    'align-items-center',
    'p-0',
    'overflow-y-auto',
  ];
}
