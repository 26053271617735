import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { AssayDataLoggerService, Status } from '../assay-data-logger.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-assay-data-progress-bar',
  templateUrl: './assay-data-progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbProgressbar, AsyncPipe],
})
export class AssayDataProgressBarComponent implements OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-block flex-grow-1 position-relative';

  status$: Observable<Status>;

  constructor(private assayDataLogger: AssayDataLoggerService) {
    this.status$ = this.assayDataLogger.status$.asObservable();
  }

  ngOnDestroy() {
    this.assayDataLogger.stop();
  }
}
