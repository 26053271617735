import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { GraphDataFor, GraphId } from '../../ngs-graphs.model';
import { NgsReportService } from '../../../ngs-report-viewer/ngs-report.service';
import { SeriesColumnOptions } from 'highcharts';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-cluster-numbers-graph',
  templateUrl: './ngs-cluster-numbers-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ColumnChartComponent, AsyncPipe],
})
export class NgsClusterNumbersGraphComponent
  extends NgsBaseGraphComponent<NGSClusterNumbersGraphData, ColumnChartComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;

  @Input() aminoAcidOrNucleotide: 'aminoacid' | 'nucleotide';

  regions$: Observable<GraphOption[]>;
  region$ = new ReplaySubject<GraphOption>(1);
  graphId: GraphId;

  ngOnInit() {
    super.ngOnInit();
    this.graphId =
      this.aminoAcidOrNucleotide === 'aminoacid' ? 'clusterNumbers' : 'clusterNumbersNucleotide';
    const rawData$: Observable<GraphDataFor<typeof this.graphId>> = this.store.pipe(
      selectDataForNgsDocument(this.documentID, this.graphId),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
    );

    this.regions$ = rawData$.pipe(
      map((data) =>
        Object.keys(data.statistics.overview.clusters[0])
          .filter((header) => header !== 'Region' && header !== 'As % Of')
          .map((header) => ({ name: header, tableType: undefined, columns: undefined })),
      ),
    );
    this.regions$
      .pipe(
        filter((regions) => !!regions && regions.length > 0),
        take(1),
      )
      .subscribe((regions) => {
        this.region$.next(regions[0]);
      });
    this.data$ = combineLatest([this.region$, rawData$]).pipe(
      map(([{ name }, data]) => this.process(data, name)),
    );

    this.regions$
      .pipe(
        map((regions) => [
          {
            name: 'region',
            label: 'Filter By',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: regions[0]?.name,
            options: regions.map(({ name }) => {
              return {
                displayName: name,
                value: name,
              };
            }),
          } as GraphSidebarControl,
        ]),
      )
      .subscribe(this.controls$);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.region$.complete();
  }

  onControlsChanged({ region }: any) {
    this.regions$.pipe(take(1)).subscribe((regions) => {
      if (!!region) {
        this.region$.next(regions.find((reg) => reg.name === region));
      } else {
        this.region$.next(null);
      }
    });
  }

  private process(
    reportData: GraphDataFor<typeof this.graphId>,
    region: string,
  ): NGSClusterNumbersGraphData {
    const chartData = NgsReportService.getBarChartOfTypeWithAxis(
      reportData,
      region,
      'clusters',
      this.aminoAcidOrNucleotide,
    );
    return {
      title: chartData.title,
      xAxisTitle: chartData.xLabel,
      yAxisTitle: chartData.yLabel,
      data: chartData.data,
    };
  }
}

export interface NGSClusterNumbersGraphData {
  title: string;
  subtitle?: string;
  data: SeriesColumnOptions[];
  xAxisTitle: string;
  yAxisTitle: string;
}
