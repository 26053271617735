import { forkJoin, from, Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, first, map, mergeMap, shareReplay, tap, toArray } from 'rxjs/operators';
import { MergeResults, MergeRowsService } from './merge-rows.service';
import {
  AssayDataColDef,
  AssayDataFileHeaderComponent,
} from './assay-data-file-header/assay-data-file-header.component';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../nucleus/services/documentService/document-service.resource';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import { IGetRowsRequestMinimal } from '../../features/grid/datasource/grid.resource';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { isColDef, isColGroupDef } from '../folders/models/colDefs';
import { DocumentTableService } from '../document-table-service/document-table.service';
import { TableParserService } from './table-parser/table-parser.service';
import { DocumentTable } from '../../../nucleus/services/documentService/types';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';
import { CellValue, ParsedTable } from './table-parser/table-parser.model';
import { Papa } from 'ngx-papaparse';

@Injectable()
export class AssayDataV2Service {
  static readonly ROW_LIMIT = 10000;
  static readonly EMPTY_MERGE_RESULT: MergeResults = {
    mergedRows: [],
    errors: [],
    statistics: { matched: 0, fileTotal: 0, resultsTotal: 0 },
  };
  merged: MergeResults = AssayDataV2Service.EMPTY_MERGE_RESULT;

  // Results column descriptions as returned by the server
  private results: ResultsData;

  // Uploaded Assay data file to save against the result.
  private file: AssayTable;

  // The final matched result to be uploaded and joined to the document.
  private mergeResult: {
    columns: any[];
    rows: any[];
  } = {
    columns: [],
    rows: [],
  };

  private resultsIDColumn: any;
  private fileIDColumn: any;

  private resource: DocumentServiceResource;
  private resultsID: string;

  private fileNameFriendlySuffix: string;
  private documentTable: DocumentTable;

  constructor(
    private tableParserService: TableParserService,
    private mergeRowsService: MergeRowsService,
    private papa: Papa,
  ) {
    this.clear();
  }

  /**
   * Given a set of rows in assay data & set of rows in current table, this function will attempt to produce
   * a new set of rows & columns by joining the two set of rows.
   *
   * This new set of rows will from now on be referred to as "target rows" since we will use those rows to overwrite
   * whatever is currently in the table & "target columns" for the set of columns.
   *
   * The algorithm below is highly reduced, but it's based on these rules:
   *
   * Rules:
   * 1. If users just adding assay to a new table. We just need to add it normally without any processing.
   * 2. If users adding assay to existing assay group, we need to loop through each row in the assay data and compare with
   * the rows in table. The logic table for this check:
   *
   * - Match: row in table can be associated with row in assay data.
   * - HasAssayData: row in table has some value in at least one column of the existing assay data that were are merging into.
   *
   * ┌──────────┬─────────────────┬──────────────────────────────────────┐
   * │   Match  │   HasAssayData  │ Meaning                              │
   * ├──────────┼─────────────────┼──────────────────────────────────────┤
   * │   YES    │      NO         │ This is a new row in existing assay  │
   * │          │                 │ group.                               │
   * ├──────────┼─────────────────┼──────────────────────────────────────┤
   * │   NO     │      YES        │ This is existing row in existing     │
   * │          │                 │ assay group.                         │
   * ├──────────┼─────────────────┼──────────────────────────────────────┤
   * │          │                 │ This is a new row that will override │
   * │   YES    │      YES        │ an existing row in existing assay    │
   * │          │                 │ group.                               │
   * ├──────────┼─────────────────┼──────────────────────────────────────┤
   * │          │                 │ This is an existing row that doesn't │
   * │   NO     │      NO         │ match anything in assay data. Just   │
   * │          │                 │ ignore this.                         │
   * │          │                 │                                      │
   * └──────────┴─────────────────┴──────────────────────────────────────┘
   *
   * Tips: When debugging the rows. What ever column with the format `ASSAY_DATA:something:something` then it's existing
   * assay data. But if it's `some_column&&&something` then it's new assay that will get added (usually originate from
   * the assay data file). In the merging scenario, we do attempt to copy over existing assay data by copying the existing
   * assay data column into a new column with the new assay data format. Essentially, we are making a fake column in the
   * assay data file containing those existing assay data column that we're merging to.
   *
   * @param resultsIDColumn the matching column on the result table (usually all sequences table)
   * @param fileIDColumn the matching column on the assay data file
   * @param mergeIntoExistingTable the existing assay group that the assay data is merging to
   */
  matchRows(
    resultsIDColumn: string,
    fileIDColumn: string,
    mergeIntoExistingTable?: string,
  ): MergeResults {
    if (!this.file.rows || !this.file.rows.length) {
      return AssayDataV2Service.EMPTY_MERGE_RESULT;
    }
    const fileRows = [...this.file.rows];

    // This hold all the rows that are going to be modified.
    let targetRows: TableRow[];

    // Hold a list of values of the matching column of the assay rows that maybe used to override existing assay in table row.
    const overwrites: CellValue[] = [];

    // Rule 1: If it's just new rows. Use the rows in the assay file.
    if (!mergeIntoExistingTable) {
      targetRows = fileRows;
    }
    // Rule 2: If it's merging to an existing assay group then follow the rules.
    else {
      targetRows = [];
      const existingAssayColumnIds: string[] = this.getResultAssayColumns(
        mergeIntoExistingTable,
        this.results.columns,
      ).map((col) => col.colId || col.field);

      // Hold a list of copy of existing table rows with the existing assay data migrated as new assay data.
      const existingTableRows = [];

      // Make a copy of the existing table rows no matter if it has existing assay data or not.
      // However, if a table row has assay data (in the assay group that we're merging to),
      // then we can copy over those assay data to the "target" assay field. We are essentially
      // pretending that those existing assay data are new assay data, so they show up in the merging table.
      for (const tableRow of this.results.rows) {
        const targetRow = { ...tableRow };

        let isTableRowHasExistingAssayData = false;

        // Migrate existing assay as new assay data if exist
        for (const existingAssayColumnId of existingAssayColumnIds) {
          if (!targetRow[existingAssayColumnId]) {
            continue;
          }
          isTableRowHasExistingAssayData = true;
          const columnName = this.getColumnNameFromColumnId(existingAssayColumnId);
          const targetColumnId = this.generateAssayColumnKey(columnName);
          targetRow[targetColumnId] = targetRow[existingAssayColumnId];
        }

        existingTableRows.push(targetRow);

        // If the table have existing assay data then we will include it in our assay data merging update.
        if (isTableRowHasExistingAssayData) {
          targetRows.push(targetRow);
        }
      }

      // Attempt to loop through each assay row and match them with existing table row.
      for (const assayRow of this.file.rows) {
        // Uses weak equals (==) instead of strong equals (===) as we want to match number strings with numbers (e.g. 1 should equal "1").
        // eslint-disable-next-line eqeqeq
        const matchingTableRow = existingTableRows.find(
          (row) => row[resultsIDColumn] == assayRow[fileIDColumn],
        );

        let targetRow;

        // If there are no table row that match with this assay row then it's simply a new row in the table.
        if (!matchingTableRow) {
          targetRow = { ...assayRow };
          targetRows.push(targetRow);
          continue;
        }

        // If there's matching table row then we merge the assay row into the table row to override the assay data on
        // the table row (if such assay data exists).
        targetRow = { ...matchingTableRow, ...assayRow };

        const isTableRowOverwritten = Object.keys(assayRow).some((assayColumn) => {
          return matchingTableRow[assayColumn];
        });

        if (isTableRowOverwritten) {
          // This row maybe override by the assay row, so we keep track of this to warn users about it.
          overwrites.push(assayRow[fileIDColumn]);
        }

        const existingTargetRowIndex = targetRows.findIndex(
          (row) => row[resultsIDColumn] === matchingTableRow[resultsIDColumn],
        );

        // If the row that were are updating already part of the rows that we are going to update then we just update that
        // row rather than adding the updated row as a new row. This happens when you have existing assay data migrated
        // in the previous loop, and then it's updated in this loop as well (like adding a new column to existing assay group).
        if (existingTargetRowIndex !== -1) {
          targetRows[existingTargetRowIndex] = targetRow;
        } else {
          // Otherwise just add it as a new row.
          targetRows.push(targetRow);
        }
      }
    }

    // Begin mysterious merging algorithm
    this.mergeResult.rows = targetRows;

    this.merged = this.mergeRowsService.mergeResults(
      this.results.rows,
      targetRows,
      resultsIDColumn,
      fileIDColumn,
      this.getHeaderNameFromField(this.results.columns, resultsIDColumn),
      this.getHeaderNameFromField(this.file.columns, fileIDColumn),
    );
    // End mysterious merging algorithm

    // If merging into existing assay group then change the status to caution/warning for overwritten rows.
    if (mergeIntoExistingTable) {
      overwrites.forEach((value) => {
        this.merged.mergedRows.forEach((row) => {
          // eslint-disable-next-line eqeqeq
          if (row[fileIDColumn] == value) {
            row.status = '⚠';
            this.merged.warning = 'Some rows may be overwritten';
          }
        });
      });
    }

    return this.merged;
  }

  private getHeaderNameFromField(columns: (ColDef | ColGroupDef)[], field: string) {
    return AssayDataV2Service.flattenColumnGroups(columns).find((col) => col.field === field)
      .headerName;
  }

  /**
   * Clears and resets the assay data service.
   * Call this method whenever the results table or assay data file changes.
   *
   * @returns {Observable} Observable that resolves when initialization is complete.
   */
  initializeData(
    files: File[],
    resultsID: string,
    resource: DocumentServiceResource,
    documentTable: DocumentTable,
  ): Observable<InitializedData> {
    this.clear();
    // TODO We should not set these side effects.
    this.resource = resource;
    this.resultsID = resultsID;
    this.documentTable = documentTable;

    this.fileNameFriendlySuffix = this.getFriendlyName(files[0].name);

    if (files.some((file) => !this.hasValidExtension(file))) {
      return observableThrowError(
        `Invalid file extension for one of the files. Valid file extensions are: ".csv", ".xls" & ".xlsx"`,
      );
    }

    const resultsData$ = this.loadResultsData();
    const allTablesInAllFiles$ = this.getTablesFromFiles(files);
    const spreadsheetData$ = this.flattenAndMergeTables(allTablesInAllFiles$);
    const multipleTablesPerFileWarning$ = this.anyFilesHaveMultipleTables(allTablesInAllFiles$);

    return forkJoin([resultsData$, spreadsheetData$, multipleTablesPerFileWarning$]).pipe(
      tap(([results, fileData]) => {
        this.results = results;
        this.file = fileData;
      }),
      map(([results, fileData, multipleTables]) => {
        // This requires both results and fileData in order to determine non-duplicate column names.
        return {
          resultColumns: results.columns,
          fileColumns: fileData.columns,
          multipleTablesPerFile: multipleTables,
        };
      }),
    );
  }

  getParsedFile(includedColumns: { [field: string]: boolean }) {
    // DANGER!! Note:
    // * col.headerName = friendly description you see in the UI
    // * col.field = col.headerName with the filename and some ampersands appended to make the "id" of these new columns unique.
    // * the rows have `col.field` as the key; not `col.headerName`

    // User can opt to ignore some columns.
    const includedCols = this.mergeResult.columns.filter((col) => includedColumns[col.field]);

    const header = includedCols.map((col) => col.headerName);

    // Build array of unique column ids to address the rows with.
    const headerKeys = includedCols.map((col) => col.field);
    const rows = this.mergeResult.rows
      // It's important to use the headerKeys for 2x reasons.
      // 1. Implicitly only get keys on the row that are the user hasn't excluded.
      // 2. The order of the columns can now be different to the rows. So if we get each cell
      //    value according to the column value it will be ok
      .map((row) => headerKeys.map((key) => row[key]));

    const data = this.papa.unparse([header, ...rows], { delimiter: ',' });

    return new File([data], this.fileNameFriendlySuffix, {
      type: 'text/plain',
    });
  }

  private getColumnNameFromColumnId(columnId: string, mergeToExistingGroup?: string) {
    // Because master databases have the assay data merged into the table rather than use a join we need to remove the
    // column group name prefix that was added to the column names.
    return this.documentTable.tableType === DocumentTableType.MASTER_DATABASE
      ? columnId.slice(mergeToExistingGroup.length + 1)
      : DocumentTableService.splitAssayColumnKey(columnId).columnName;
  }

  selectColumn(
    resultColumnField: string,
    fileColumnField: string,
    mergeToExistingGroup?: string,
  ): Observable<any> {
    const resultsData$ = this.loadResultsData(resultColumnField).pipe(first());

    return resultsData$.pipe(
      map((result) => {
        let targetColumn: ColDef[] = [...this.file.columns];

        // If merging into existing group, then merge the existing assay data columns with the new columns.
        if (mergeToExistingGroup) {
          const existingColumns: ColDef[] = result.columns
            .filter(isColGroupDef)
            .find((col) => col.groupId === mergeToExistingGroup)
            .children.filter(isColDef)
            .map((colDef) => {
              const columnName = this.getColumnNameFromColumnId(colDef.field);
              return {
                ...colDef,
                field: this.generateAssayColumnKey(columnName),
              };
            });

          const fileColumnsWithoutExistingColumn = targetColumn.filter((fileColDef) => {
            return !existingColumns.some(
              (existingColDef) => existingColDef.field === fileColDef.field,
            );
          });

          targetColumn = [...existingColumns, ...fileColumnsWithoutExistingColumn];
        }

        this.mergeResult.columns = [...targetColumn];
        return {
          result: {
            columns: AssayDataV2Service.flattenColumnGroups(result.columns).filter((col: any) => {
              return col.field === resultColumnField;
            }),
            rows: result.rows,
          },
          file: {
            columns: targetColumn
              .sort((x, y) =>
                x.field === fileColumnField ? -1 : y.field === fileColumnField ? 1 : 0,
              )
              .map((col) => {
                // NOTE: this mutates the `this.file.columns` metadata which other code in getParsedFile() relies on..
                (col as AssayDataColDef).metadata = {
                  idColumn: col.field === fileColumnField,
                  include: true,
                  fileColumn: true,
                };
                col.headerComponent = AssayDataFileHeaderComponent;
                return col;
              }),
            rows: this.file.rows,
          },
        };
      }),
    );
  }

  static flattenColumnGroups(columns: (ColDef | ColGroupDef)[]) {
    return columns.reduce((agg, col) => {
      if (isColGroupDef(col)) {
        return agg.concat(col.children.map((child) => ({ ...child, groupId: col.groupId })));
      } else {
        return agg.concat(col);
      }
    }, [] as ColDef[]);
  }

  /**
   * Gets all results rows for an experiment. Our api is limited to a maximum page size of 'limit'
   * rows, so we need to make requests batched in sizes of 'limit'.
   *
   * @returns {Promise} Promise that resolves when file parsing is complete.
   */
  private loadResultsData(sortColumn?: string): Observable<ResultsData> {
    const sortModel = sortColumn ? [{ colId: sortColumn, sort: 'asc' }] : [];

    const params: IGetRowsRequestMinimal = {
      startRow: 0,
      endRow: AssayDataV2Service.ROW_LIMIT,
      sortModel,
      filterModel: undefined,
    };

    let query$: Observable<IGridResourceResponse<any>>;

    const datasourceParams: DocumentDatasourceParams = {
      documentId: this.resultsID,
      // Required for new DocumentService.
      documentTableName: this.documentTable.name,
      filterModel: '',
      readonly: true,
    };
    query$ = (<DocumentServiceResource>this.resource).query(params, datasourceParams);

    return query$.pipe(
      map((resp) => {
        return {
          columns: resp.columns,
          rows: resp.data,
        };
      }),
      first(),
      catchError((error) => {
        console.log(error);
        return observableThrowError(
          'Failed to retrieve results - close and try again later. If you continue to have problems, ' +
            'please <a href="https://help.geneiousbiologics.com">contact support.</a>',
        );
      }),
      tap((response) => (this.results.rows = response.rows)),
    );
  }

  private getResultAssayColumns(assayID: string, columns: (ColDef | ColGroupDef)[]): ColDef[] {
    return columns
      .filter(isColGroupDef)
      .find((col) => col.groupId === assayID)
      .children.filter(isColDef);
  }

  /**
   * A case insensitive check for duplicate strings.
   * @param array Array of strings
   * @returns True if duplicates exist
   */
  private hasDuplicates(array: string[]): boolean {
    return new Set(array.map((col) => col.toLowerCase())).size !== array.length;
  }

  private generateAssayColumnKey(header: string): string {
    return header + '&&&' + this.fileNameFriendlySuffix;
  }

  private getFriendlyName(name: string): string {
    return name.trim().split(' ').join('_').split('.').join('-');
  }

  private hasValidExtension(file: File): boolean {
    const extIndex = file.name.lastIndexOf('.');
    if (extIndex === -1) {
      return false;
    }
    return ['.csv', '.xls', '.xlsx'].includes(file.name.substring(extIndex));
  }

  private clear() {
    // TODO Should the results rows be cached?
    // Properties set on initiation that do not change for a single popup.
    this.results = {
      // Results column descriptions as returned by the server
      columns: [],
      rows: [],
    };

    this.file = {
      // Array of column descriptions created from assay data file
      name: '',
      columns: [],
      rows: [],
    };

    // Properties that change whenever the match is altered.
    this.resultsIDColumn = null;
    this.fileIDColumn = null;
    this.merged = AssayDataV2Service.EMPTY_MERGE_RESULT;
    this.mergeResult = {
      columns: [],
      rows: [],
    };
  }

  private getTablesFromFiles(files: File[]): Observable<AssayTable[][]> {
    return from(files).pipe(
      mergeMap((file) => this.tableParserService.parse(file), 5),
      map((uploadResult) => {
        const tablesInFile = uploadResult.tables;
        return tablesInFile.filter((table) => {
          const validAssayData = this.isValidAssayData(table);
          if (validAssayData.error) {
            console.error(validAssayData.error);
          }
          return validAssayData.isValid;
        });
      }),
      map((tables) => this.convertFileTablesToAssayTables(tables)),
      toArray(),
      shareReplay(1),
    );
  }

  private flattenAndMergeTables(parsedTables: Observable<AssayTable[][]>): Observable<AssayTable> {
    return parsedTables.pipe(map(this.flattenAndMergeAssayTables));
  }

  private isValidAssayData(table: ParsedTable): {
    isValid: boolean;
    error?: string;
  } {
    const numberOfRows = table.rows.length;
    const numberOfColumns = table.columns.length;
    if (numberOfRows === 0) {
      return { isValid: false, error: 'Merging not possible. No data was found.' };
    }
    if (numberOfColumns <= 1) {
      return { isValid: false, error: 'Merging not possible. Only one column was found.' };
    }
    const emptyColumns = table.columns.filter((col) => col.trim() === '');
    const nonEmptyColumns = numberOfColumns - emptyColumns.length;
    // Does it look like bad 16x24?
    if (numberOfRows === 30 && numberOfColumns === 25 && nonEmptyColumns < 2) {
      return { isValid: false, error: 'Looks like bad 16x24' };
    }
    // Check if there is going to be least two non empty columns left.
    if (nonEmptyColumns < 2) {
      return { isValid: false, error: 'Merging not possible. Only one column was found.' };
    }
    return { isValid: true };
  }

  private convertFileTablesToAssayTables(fileTables: ParsedTable[]): AssayTable[] {
    // For each table in the file, convert it into AssayFileData.
    const assayTables: AssayTable[] = [];

    fileTables.forEach((table) => {
      const newHeaders = table.columns;
      const name = table.displayName;

      const newColumns = newHeaders.map((header) => ({
        field: this.generateAssayColumnKey(header.trim()),
        headerName: header.trim(),
        metadata: { include: true },
      }));

      // Check that newly generated headers are still unique after being formatted ( ie. trimmed).
      const newColumnFields = newColumns.map((col) => col.field);
      if (this.hasDuplicates(newColumnFields)) {
        throw new Error(
          `Duplicate headers detected. Modify "${name}" so all headers are unique and try again.`,
        );
      }

      const rows = table.rows.map((row) => {
        // Apply the new column fields to the file so the column headers and row fields match.
        return Object.keys(row).reduce((agg, key) => {
          const field = this.generateAssayColumnKey(key);
          agg[field] = row[key];
          return agg;
        }, {} as any);
      });

      assayTables.push({
        name: name,
        columns: newColumns,
        rows,
      });
    });

    return assayTables;
  }

  private flattenAndMergeAssayTables(responses: AssayTable[][]): AssayTable {
    // Essentially this takes all tables across all files and flattens/merges them into a single assay table
    // Unique columns from each table are used and each row is kept (with undefined values for columns they are missing)
    return responses.flat(1).reduce(
      (agg, response) => {
        agg.columns = agg.columns.reduce(
          (acc, col2) => {
            if (response.columns.findIndex((col1) => col1.field === col2.field) === -1) {
              acc.push(col2);
            }
            return acc;
          },
          [...response.columns],
        );

        agg.rows = [...agg.rows, ...response.rows];
        agg.name = agg.name === '' ? response.name : `${agg.name}, ${response.name}`;

        return agg;
      },
      { name: '', columns: [], rows: [] },
    );
  }

  private anyFilesHaveMultipleTables(
    allTablesInAllFiles$: Observable<AssayTable[][]>,
  ): Observable<boolean> {
    return allTablesInAllFiles$.pipe(
      map((files) => {
        for (const tables of files) {
          if (tables.length > 1) {
            return true;
          }
        }
        return false;
      }),
    );
  }
}

interface ResultsData {
  columns: (ColDef | ColGroupDef)[];
  rows: { [colField: string]: string | number }[];
}

interface AssayTable {
  name: string;
  columns: AssayDataColDef[];
  rows: TableRow[];
}

interface TableRow {
  [colField: string]: CellValue;
}

export interface InitializedData {
  resultColumns: (ColDef | ColGroupDef)[];
  fileColumns: AssayDataColDef[];
  message?: string;
  multipleTablesPerFile?: boolean;
}
