import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ViewerComponent, ViewersOverlays } from '../../viewers-v2/viewers-v2.config';
import { annotatedPluginDocumentViewerSelector } from '../../viewer-components/viewer-selectors';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import {
  filter,
  map,
  mapTo,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ViewerDocumentData,
  ViewerMasterDatabaseSearchData,
} from '../../viewer-components/viewer-document-data';
import { AnnotatedPluginDocument } from '../../geneious';
import { combineLatest, concat, merge, Observable, of, Subject, Subscription, zip } from 'rxjs';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  RowDoubleClickedEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { annotatedResultViewerOverlays } from '../../viewer-components/viewer-overlays';
import { MasterDatabaseSequencesResource } from './master-database-sequences-resource.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import {
  DocumentServiceTableInfoMap,
  DocumentTable,
} from '../../../../nucleus/services/documentService/types';
import { DocumentService } from '../../../../nucleus/services/documentService/document-service.v1';
import { GridComponent } from '../../../features/grid/grid.component';
import {
  DocumentServiceDocumentQuery,
  UpdateRowsBody,
} from '../../../../nucleus/services/documentService/document-service.v1.http';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import {
  CLIENT_CHECKBOX_COLUMN_DEF,
  EXPAND_COLUMN_DEF,
} from '../../../features/grid/grid.constants';
import { retryOnErrorWithDelay } from '../../../../bx-operators/retry-on-error-with-delay';
import {
  ClientGridSelection,
  ClientGridComponent,
} from '../../../features/grid/client-grid/client-grid.component';
import { MasterDatabaseAnnotatedSequencesImporterDialogComponent } from '../master-database-annotated-sequences-importer-dialog/master-database-annotated-sequences-importer-dialog.component';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MasterDatabaseService } from '../master-database.service';
import {
  getAllColumnsWithJoins,
  isNameSchemeColumn,
} from '../../document-table-service/document-table-columns/get-all-columns-with-joins';
import { isColDef, isColGroupDef } from '../../folders/models/colDefs';
import { LabelRendererV2Component } from '../../label/label-renderer-v2/label-renderer-v2.component';
import { OrganizationSetting } from '../../models/settings/setting.model';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { DocumentTableQueryService } from '../../document-table-service/document-table-state/document-table-query.service';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { selectNameSchemeByID } from '../../organization-settings/organization-settings.selectors';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { AngularSplitModule } from 'angular-split';
import { ViewersStateDirective } from '../../viewers-state/viewers-state.directive';
import { ViewersComponent } from '../../viewers-v2/viewers/viewers.component';

@ViewerComponent({
  key: 'master-database-search-result-viewer',
  title: 'Collection Search Results',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
        1,
        1,
      ),
    ],
    (data) => {
      const row = data.selection.rows[0];
      return row.getDocumentPartWithPrefix('MASTER_DATABASE') !== undefined;
    },
  ),
})
@Component({
  selector: 'bx-master-database-search-result-viewer',
  templateUrl: './master-database-search-result-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MasterDatabaseSequencesResource],
  standalone: true,
  imports: [
    ToolstripComponent,
    FormsModule,
    ReactiveFormsModule,
    ToolstripItemComponent,
    PageMessageComponent,
    LoadingComponent,
    AngularSplitModule,
    ViewersStateDirective,
    ClientGridComponent,
    ViewersComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class MasterDatabaseSearchResultViewerComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column flex-grow-1 flex-shrink-1';
  masterDatabaseColumnDefs$: Observable<(ColDef | ColGroupDef)[]>;
  searchResultTableMatchesColumns$: Observable<ColDef[]>;
  rows$: Observable<any[]>;
  gridOptions: GridOptions;
  viewersData$: Observable<ViewerMasterDatabaseSearchData>;
  selection$ = new Subject<ClientGridSelection>();
  doubleClick$ = new Subject<RowDoubleClickedEvent>();
  searchResultTables$: Observable<DocumentTable[]>;
  selectedSearchResultTable = new FormControl<DocumentTable>(undefined);
  setBestMatchButtonDisabled$: Observable<boolean>;
  setBestMatchButtonText$: Observable<string>;
  setBestMatchClicked$ = new Subject<void>();
  addToCollectionButtonDisabled$: Observable<boolean>;
  addToCollectionsDialogOpenEvent$ = new Subject<void>();
  gridLoaded$: Observable<boolean>;
  readonly viewerStateKey = 'ngsMasterDatabaseResultTable';
  readonly viewersOverlays: ViewersOverlays = annotatedResultViewerOverlays;

  private readonly document$: Observable<AnnotatedPluginDocument>;
  private selectedSearchResultTable$: Observable<DocumentTable>;
  private documentTables$: Observable<DocumentServiceTableInfoMap>;
  private nameScheme$: Observable<OrganizationSetting | null>;
  private subscriptions = new Subscription();
  private modalRef: NgbModalRef;
  private documentData$: Observable<DocumentData>;

  constructor(
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentService: DocumentService,
    private documentTableStateService: DocumentTableStateService,
    private documentTableQueryService: DocumentTableQueryService,
    private masterDatabaseSequencesResource: MasterDatabaseSequencesResource,
    private pipelineDialogService: PipelineDialogService,
    private store: Store<AppState>,
  ) {
    this.gridOptions = {
      getRowId: ({ data }) => data['query:row_number'],
      masterDetail: true,
    };

    this.document$ = this.viewerDataService
      .getData('master-database-search-result-viewer')
      .pipe(map((data) => data.selection.rows[0]));

    this.nameScheme$ = this.document$.pipe(
      map((document) => document.getAllFields().fileNameSchemeID),
      switchMap((fileNameSchemeID) => {
        if (fileNameSchemeID) {
          return this.store
            .select(selectNameSchemeByID(fileNameSchemeID))
            .pipe(map((response) => response?.data));
        } else {
          return of(null);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.documentTables$ = this.document$.pipe(
      switchMap((document) => this.documentTableStateService.getTablesMap(document.id)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.documentData$ = zip(this.document$, this.documentTables$, this.nameScheme$).pipe(
      map(([document, tables, nameScheme]) => ({ document, tables, nameScheme })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  ngOnInit() {
    this.selectedSearchResultTable$ = this.selectedSearchResultTable.valueChanges.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.viewersData$ = this.selection$.pipe(
      withLatestFrom(this.document$, this.selectedSearchResultTable$),
      map(([selection, document, table]) => ({
        selection: {
          rows: selection.rows,
          subTableRows: selection.subTableRows,
          totalSelected: selection.rows.length + selection.subTableRows.length,
          tableType: DocumentTableType.MASTER_DATABASE_SEARCH_RESULT,
          document: document,
        },
        selectedTable: table,
        resource: this.masterDatabaseSequencesResource,
      })),
    );

    this.searchResultTables$ = this.getMasterListSearchResultTables().pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const searchResultTableColumns$: Observable<ColDef[]> = this.selectedSearchResultTable$.pipe(
      filter((table) => table != null),
      map((table) => table.columns.map((col) => ({ field: col.name, headerName: col.name }))),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.masterDatabaseColumnDefs$ = this.documentData$.pipe(
      withLatestFrom(searchResultTableColumns$),
      switchMap(([{ tables, nameScheme }, searchResultTableColumns]) =>
        this.getMasterColumnDefs(tables, nameScheme, searchResultTableColumns),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.searchResultTableMatchesColumns$ = searchResultTableColumns$.pipe(
      map((columns) => {
        const index = columns.findIndex((col) => col.field === 'sequence_match_id');
        const filteredColDefs = GridComponent.filterOutInvalidColDefs(columns.slice(index + 1), [
          'matches',
        ]);
        filteredColDefs.filter(isColDef).find((colDef) => colDef.field === 'Labels').cellRenderer =
          LabelRendererV2Component;
        return [CLIENT_CHECKBOX_COLUMN_DEF, ...filteredColDefs];
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.rows$ = this.selectedSearchResultTable$.pipe(
      withLatestFrom(this.document$),
      switchMap(([table, document]) =>
        this.documentTableQueryService
          .queryTable(document.id, table.name, {
            orderBy: [{ kind: 'descending', field: 'Total % Match' }],
          })
          .pipe(switchMap((response) => this.joinRowsWithAllSequencesTable(response, document))),
      ),
      withLatestFrom(this.searchResultTableMatchesColumns$),
      map(([rows, detailColumnDefs]) => {
        const detailCols = detailColumnDefs.map((def) => def.field);
        const groups: Map<number, any> = new Map();
        rows.forEach(({ matchRow, allSequencesRow }) => {
          const index = matchRow.query_sequence_row_index;
          const hiddenColumns = {
            geneious_row_index: index,
            row_number: matchRow.row_number,
            row_index: matchRow.row_index,
            row_uuid: matchRow.row_uuid,
            collection_id: matchRow.collection_id,
            sequence_match_id: matchRow.sequence_match_id,
          };
          const matchData: any = MasterDatabaseSearchResultViewerComponent.filterObjectEntries(
            matchRow,
            ([key]) => detailCols.includes(key),
            hiddenColumns,
          );
          const queryData: any = MasterDatabaseSearchResultViewerComponent.filterObjectEntries(
            matchRow,
            ([key]) => !detailCols.includes(key),
            allSequencesRow,
          );

          if (!groups.has(index)) {
            groups.set(index, {
              ...queryData,
              'Number of Matches': 0,
              matches: [],
            });
          }

          if (matchData['sequence_match_id'] !== undefined) {
            const query = groups.get(index);
            query.matches.push({ ...matchData, parentRowNumber: queryData['query:row_number'] });
            query.hasMatches = true;
            query['Number of Matches']++;

            if (MasterDatabaseService.isBestMatch(matchRow, queryData)) {
              groups.set(index, {
                'Best Match Name': matchData.Name,
                'Best Match Collection Name': matchData['Collection Name'],
                'Best Total % Match': matchData['Total % Match'],
                'Best Match BLAST Score': matchData['BLAST Score'],
                ...matchData,
                ...query,
              });
            }
          }
        });

        return Array.from(groups.values());
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.gridLoaded$ = merge(
      this.selectedSearchResultTable$.pipe(mapTo(false)),
      combineLatest([
        this.rows$,
        this.masterDatabaseColumnDefs$,
        this.searchResultTableMatchesColumns$,
      ]).pipe(mapTo(true)),
    );

    const bestMatchSet$: Observable<{ oldBestMatchURN: string; updatedRow: any }> =
      this.setBestMatchClicked$.pipe(
        withLatestFrom(this.selection$),
        map(([, selection]) => {
          const match =
            selection.subTableRows[0] ||
            selection.rows[0].matches.find((row: any) =>
              MasterDatabaseService.isBestMatch(row, selection.rows[0]),
            );
          const rowNode = this.gridOptions.api.getRowNode(match.parentRowNumber);
          const oldBestMatchURN = rowNode.data.best_match_urn;
          const updatedRow = MasterDatabaseSearchResultViewerComponent.updateBestMatchValues(
            rowNode,
            match,
          );
          return { oldBestMatchURN, updatedRow };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    const latestSelection$ = combineLatest([
      this.selection$,
      bestMatchSet$.pipe(startWith({})),
    ]).pipe(map(([selection]) => selection));

    this.setBestMatchButtonText$ = latestSelection$.pipe(
      map((selection) => {
        if (selection.subTableRows.length === 1) {
          return this.bestMatchSelected(selection) ? 'Unset best match' : 'Set best match';
        } else if (selection.rows.length === 1) {
          const rowNode = this.gridOptions.api.getRowNode(selection.rows[0]['query:row_number']);
          return rowNode.data.best_match_urn != null ? 'Unset best match' : 'Set best match';
        } else {
          return 'Set best match';
        }
      }),
      startWith('Set best match'),
    );

    this.setBestMatchButtonDisabled$ = latestSelection$.pipe(
      map((selection) => {
        if (selection.subTableRows.length === 1) {
          return false;
        } else if (selection.rows.length === 1) {
          const rowNode = this.gridOptions.api.getRowNode(selection.rows[0]['query:row_number']);
          return rowNode.data.best_match_urn == null;
        }

        return true;
      }),
      startWith(true),
    );

    this.addToCollectionButtonDisabled$ = this.selection$.pipe(
      map((selection) => selection.rows.length === 0),
      startWith(true),
    );

    this.subscriptions.add(
      bestMatchSet$
        .pipe(
          withLatestFrom(this.document$, this.selectedSearchResultTable$, this.rows$),
          mergeMap(([{ updatedRow }, document, table, rows]) => {
            const orderedRows = rows.sort((a, b) => a.row_number - b.row_number);
            const rowIndices = orderedRows.map((row) => row.row_number - row.row_index);
            const index = MasterDatabaseSearchResultViewerComponent.getIndex(
              rowIndices,
              updatedRow.row_number,
            );

            const body: any = {};
            for (
              let i = rowIndices[index];
              i < rowIndices[index] + orderedRows[index].matches.length;
              i++
            ) {
              body[i] = {
                columns: {
                  best_match_urn: {
                    value: updatedRow['best_match_urn'],
                  },
                },
              };
            }
            return this.documentService
              .updateRows(document.id, table.name, body)
              .pipe(retryOnErrorWithDelay(5000, 5, true));
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      bestMatchSet$
        .pipe(
          withLatestFrom(this.document$, this.selectedSearchResultTable$),
          mergeMap(([{ oldBestMatchURN, updatedRow }, document, table]) => {
            const requests = [];
            const bestMatch = updatedRow.matches.find((match: any) =>
              MasterDatabaseService.isBestMatch(match, updatedRow),
            );
            if (bestMatch) {
              requests.push(this.updateMasterDatabaseMatches(document, table, bestMatch));
            }

            if (oldBestMatchURN != null) {
              const oldBestMatch = updatedRow.matches.find((match: any) =>
                MasterDatabaseService.isBestMatch(match, { best_match_urn: oldBestMatchURN }),
              );
              requests.push(this.updateMasterDatabaseMatches(document, table, oldBestMatch, true));
            }
            return concat(...requests);
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.selectedSearchResultTable$.subscribe((table) => {
        const split = table.name.split('_');
        const uuid = split[split.length - 1];
        this.masterDatabaseSequencesResource.setBlobName(
          `MASTER_DATABASE_SEARCH_RESULT_ALIGNMENTS_${uuid}`,
        );
      }),
    );

    this.subscriptions.add(
      this.doubleClick$
        .pipe(filter((event) => event.data['Number of Matches'] > 0))
        .subscribe((event) => event.api.setRowNodeExpanded(event.node, !event.node.expanded)),
    );

    this.subscriptions.add(
      this.addToCollectionsDialogOpenEvent$
        .pipe(withLatestFrom(this.document$, this.selection$))
        .subscribe(([_, document, selection]) =>
          this.openAddToCollectionsDialog(document, selection),
        ),
    );

    this.subscriptions.add(
      this.searchResultTables$.pipe(filter((tables) => tables.length > 0)).subscribe({
        next: (tables) => {
          let selected = this.selectedSearchResultTable.value
            ? tables.find((table) => table.name === this.selectedSearchResultTable.value.name)
            : tables[0];
          selected = selected ? selected : tables[0];
          this.selectedSearchResultTable.setValue(selected);
        },
      }),
    );
  }

  ngOnDestroy() {
    this.addToCollectionsDialogOpenEvent$.complete();
    this.setBestMatchClicked$.complete();
    this.selection$.complete();
    this.doubleClick$.complete();
    this.subscriptions.unsubscribe();

    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  private openAddToCollectionsDialog(
    document: AnnotatedPluginDocument,
    selection: ClientGridSelection,
  ) {
    this.modalRef = this.pipelineDialogService.showDialog({
      component: MasterDatabaseAnnotatedSequencesImporterDialogComponent,
      folderID: document.parent.id,
      selected: {
        noOfRowsSelected: 1,
        totalNoOfRows: 1,
        selectAll: false,
        ids: [document.id],
        selectedRows: [document],
        firstRow: document,
      },
      otherVariables: {
        selection: {
          documentID: document.id,
          selectAll: false,
          ids: selection.rows.map((row) => row.query_sequence_row_index),
        },
        selectedTable: 'DOCUMENT_TABLE_ALL_SEQUENCES',
        documentTableQuery: {
          fields: [],
          orderBy: [{ kind: 'ascending', field: 'row_number' }],
        },
      },
    });
  }

  private static filterObjectEntries(
    originalObject: Object,
    filterFunction: (
      value: [string, unknown],
      index: number,
      array: [string, unknown][],
    ) => boolean,
    mergeObject: Object = {},
  ): Object {
    const filteredEntries = Object.entries(originalObject).filter(filterFunction);
    return Object.assign(mergeObject, ...Array.from(filteredEntries, ([k, v]) => ({ [k]: v })));
  }

  private static getIndex(rowIndices: number[], rowNumber: number): number {
    for (let i = rowIndices.length - 1; i >= 0; i--) {
      if (rowNumber >= rowIndices[i]) {
        return i;
      }
    }
  }

  private getMasterListSearchResultTables(): Observable<DocumentTable[]> {
    return this.documentTables$.pipe(
      withLatestFrom(this.document$),
      map(([tables, document]) => {
        return Object.keys(tables)
          .filter((tableName) => tableName.startsWith('MASTER_DATABASE_SEARCH_RESULT'))
          .map((tableName) => ({ documentID: document.id, name: tableName, ...tables[tableName] }))
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      }),
    );
  }

  private joinRowsWithAllSequencesTable(
    masterDatabaseTableResponse: IGridResourceResponse<any>,
    document: AnnotatedPluginDocument,
  ): Observable<
    {
      matchRow: any;
      allSequencesRow: any;
    }[]
  > {
    const indices = masterDatabaseTableResponse.data
      .map((row) => row.query_sequence_row_index)
      .filter((elem, pos, array) => array.indexOf(elem) === pos)
      .join(',');
    const query: DocumentServiceDocumentQuery = {
      where: `geneious_row_index IN (${indices})`,
    };
    return this.documentTableQueryService
      .queryTable(document.id, 'DOCUMENT_TABLE_ALL_SEQUENCES', query)
      .pipe(
        map((allSequencesResponse) => {
          return masterDatabaseTableResponse.data.map((row) => {
            const find = allSequencesResponse.data.find(
              (allSequencesRow) =>
                allSequencesRow.geneious_row_index === row.query_sequence_row_index,
            );
            return {
              matchRow: row,
              allSequencesRow: Object.keys(find).reduce(
                (agg, key) => {
                  agg['query:' + key] = find[key];
                  return agg;
                },
                {} as { [columnName: string]: any },
              ),
            };
          });
        }),
      );
  }

  private updateMasterDatabaseMatches(document: any, table: any, bestMatch: any, remove = false) {
    const urn = `${document.id}:${table.name}:${bestMatch.row_number}`;
    return this.documentTableQueryService
      .queryTable(bestMatch.collection_id, 'MASTER_DATABASE', {
        fields: ['row_number'],
        where: `row_uuid='${bestMatch.row_uuid}'`,
      })
      .pipe(
        switchMap((response) => {
          const subtractOrAdd = remove ? 'subtract' : 'add';
          const deltaUpdateRows: UpdateRowsBody = {
            [response.data[0].row_number]: {
              columns: {
                matches: {
                  [subtractOrAdd]: [urn],
                },
              },
            },
          };
          return this.documentService.updateRows(
            bestMatch.collection_id,
            'MASTER_DATABASE',
            deltaUpdateRows,
          );
        }),
        retryOnErrorWithDelay(5000, 5, true),
      );
  }

  private bestMatchSelected(selection: ClientGridSelection): boolean {
    const subTableRow = selection.subTableRows[0];
    const rowNode = this.gridOptions.api.getRowNode(subTableRow.parentRowNumber);
    return MasterDatabaseService.isBestMatch(subTableRow, rowNode.data);
  }

  private static hasBestMatchSet(selection: ClientGridSelection): boolean {
    return selection.rows.length === 1 && selection.rows[0].best_match_urn != null;
  }

  private static updateBestMatchValues(
    node: IRowNode,
    match: { collection_id: string; sequence_match_id: number; [key: string]: any },
  ): { [key: string]: any } {
    let rowData;
    if (MasterDatabaseService.isBestMatch(match, node.data)) {
      rowData = {
        ...node.data,
        'Best Match Name': null,
        'Best Match Collection Name': null,
        'Best Total % Match': null,
        'Best Match Blast Score': null,
        best_match_urn: null,
      };
    } else {
      rowData = {
        ...node.data,
        'Best Match Name': match['Name'],
        'Best Match Collection Name': match['Collection Name'],
        'Best Total % Match': match['Total % Match'],
        'Best Match BLAST Score': match['BLAST Score'],
        best_match_urn: MasterDatabaseService.getMatchUrn(match),
        ...match,
      };
    }
    node.setData(rowData);
    return rowData;
  }

  private getMasterColumnDefs(
    tables: DocumentServiceTableInfoMap,
    nameScheme: OrganizationSetting | null,
    searchResultTableColumns: ColDef[],
  ): Observable<(ColDef | ColGroupDef)[]> {
    return of(this.getQuerySequenceColumnDefs(tables, nameScheme)).pipe(
      map((querySequencesColumns) => {
        const bestMatchSequenceColumns: ColDef[] =
          this.getBestMatchSequenceColumns(searchResultTableColumns).filter(isColDef);
        const querySequenceColDefs = querySequencesColumns.filter(isColDef);
        const querySequenceGroupColumns = querySequencesColumns.filter(isColGroupDef);
        const nameField = querySequenceColDefs.find(
          (colDef) => colDef.field === 'query:Sequence Name',
        )
          ? 'query:Sequence Name'
          : 'query:Name';

        return [
          {
            field: nameField,
            headerName: 'Query Name',
          },
          EXPAND_COLUMN_DEF,
          CLIENT_CHECKBOX_COLUMN_DEF,
          {
            groupId: 'match_statistics',
            headerName: 'Match Statistics',
            children: [
              { field: 'Number of Matches', headerName: 'Number of Matches' },
              { field: 'Best Match Name', headerName: 'Best Match Name' },
              { field: 'Best Match Collection Name', headerName: 'Best Match Collection Name' },
              { field: 'Best Total % Match', headerName: 'Best Total % Match' },
              { field: 'Best Match BLAST Score', headerName: 'Best Match BLAST Score' },
            ],
          },
          {
            groupId: 'best_match',
            headerName: 'Best Match',
            children: bestMatchSequenceColumns,
          },
          {
            groupId: 'query_sequence',
            headerName: 'Query Sequence',
            children: querySequenceColDefs.filter((col) => col.field !== nameField),
          },
          ...querySequenceGroupColumns,
        ];
      }),
    );
  }

  private getQuerySequenceColumnDefs(
    tables: DocumentServiceTableInfoMap,
    nameScheme: OrganizationSetting | null,
  ): (ColDef | ColGroupDef)[] {
    const joinedColDefs = getAllColumnsWithJoins(
      'DOCUMENT_TABLE_ALL_SEQUENCES',
      tables,
      nameScheme,
    );
    const columnDefs = GridComponent.filterOutInvalidColDefs(joinedColDefs, [
      'matches',
      'best_match_urn',
      'Fully Annotated',
      'In Frame & Fully Annotated',
      'Without Stop Codons & In Frame & Fully Annotated',
    ]).filter(
      (col) =>
        (col?.headerName &&
          !col.headerName.match(
            /^.+(ID|Nucleotides|Length|Coverage %|Identity %|Similarity|Identity)$/,
          )) ||
        ['sequence_match_id', 'best_match_urn', 'Sequence Length'].includes(col.headerName),
    );
    return columnDefs.reduce(
      (columns, colDef) => {
        if (
          isColGroupDef(colDef) &&
          colDef.children.find((colDefChild: ColDef) => isNameSchemeColumn(colDefChild.field))
        ) {
          return columns.concat([
            {
              ...colDef,
              headerName: colDef.headerName + ' (Query Sequence)',
              children: colDef.children.map(makeQuerySequenceColDef),
            },
          ]);
        } else if (isColGroupDef(colDef)) {
          return columns.concat(
            colDef.children.map((colDefChild: ColDef) => ({
              ...makeQuerySequenceColDef(colDefChild),
              headerName: colDef.headerName + '-' + colDefChild.headerName,
            })),
          );
        } else {
          return columns.concat([makeQuerySequenceColDef(colDef)]);
        }
      },
      [] as (ColDef | ColGroupDef)[],
    );
  }

  private getBestMatchSequenceColumns(
    searchResultTableColumns: ColDef[],
  ): (ColDef | ColGroupDef)[] {
    const index = searchResultTableColumns.findIndex((col) => col.field === 'Notes');
    const joinedColDefs = [
      ...[{ field: 'Name', headerName: 'Name' }],
      ...searchResultTableColumns.slice(index),
    ];
    const columnDefs: ColDef[] = GridComponent.filterOutInvalidColDefs(joinedColDefs, [
      'row_number',
      'matches',
      'best_match_urn',
      'Fully Annotated',
      'In Frame & Fully Annotated',
      'Without Stop Codons & In Frame & Fully Annotated',
    ]).filter(
      (col) =>
        !col.headerName.match(
          /^.+(ID|Nucleotides|Length|Coverage %|Identity %|Similarity|Identity)$/,
        ) || ['sequence_match_id', 'best_match_urn', 'Sequence Length'].includes(col.headerName),
    );
    columnDefs.find((colDef) => colDef.field === 'Labels').cellRenderer = LabelRendererV2Component;
    return columnDefs;
  }
}

function makeQuerySequenceColDefField(name: string): string {
  return 'query:' + name;
}

function makeQuerySequenceColDef(colDef: ColDef): ColDef {
  return {
    ...colDef,
    colId: makeQuerySequenceColDefField(colDef.field),
    field: makeQuerySequenceColDefField(colDef.field),
    // Makes the 'Notes' column un-editable.
    editable: false,
  };
}

interface DocumentData {
  document: AnnotatedPluginDocument;
  tables: DocumentServiceTableInfoMap;
  nameScheme: OrganizationSetting;
}
