import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { JobResultTypes, JobStatus } from '@geneious/nucleus-api-client';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, startWith, take } from 'rxjs/operators';
import { Job, JobSearchResult } from '../../../../../nucleus/services/models/job.model';
import { CleanUp } from '../../../../shared/cleanup';
import { JobsService } from '../../job.service';
import { DocumentHttpV2Service } from '../../../../../nucleus/v2/document-http.v2.service';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-jobs-result-renderer',
  templateUrl: './jobs-result-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, AsyncPipe],
})
export class JobsResultRendererComponent extends CleanUp implements AgRendererComponent, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex align-items-center h-100';
  jobData$: Observable<JobResultAndLink>;
  // To be shown if there is no download or result link.
  placeholder: string;
  progress: number;
  status: string;

  constructor(
    private jobsService: JobsService,
    private documentHttpService: DocumentHttpV2Service,
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  agInit(params: ICellRendererParams): void {
    this.status = params.value;

    const job = params.data as JobSearchResult;
    // If no job, then we don't want to render this component.
    if (!job) {
      return;
    }

    this.placeholder = JobsResultRendererComponent.getPlaceholderText(job);
    let download$: Observable<string | null>;
    let result$: Observable<LinkWithName | null>;

    if (job && job.status.kind === 'Completed') {
      if (job.config.pipeline.name === 'export') {
        download$ = this.jobsService.getJobResultDownloadLink(
          job.id,
          'EXPORTED_FILE',
          job.resultSummary,
        );
        result$ = of(null);
      } else if (job.config.pipeline.name === 'export-sequence-viewer-image') {
        download$ = this.jobsService.getJobResultDownloadLink(job.id, 'FILE', job.resultSummary);
        result$ = of(null);
      } else if (job.resultSummary.singleResult?.resultType === JobResultTypes.FolderEntity) {
        download$ = of(null);
        result$ = of(this.getSingleFolderResultLink(job));
      } else {
        download$ = of(null);
        result$ = this.getDocumentResultLink(job);
      }
    } else {
      download$ = of(null);
      result$ = of(null);
    }
    this.jobData$ = forkJoin([
      download$.pipe(catchError(() => of(null))),
      result$.pipe(catchError(() => of(null))),
    ]).pipe(
      map(([download, result]) => ({ download, result })),
      startWith({ download: null, result: null }),
    );
  }

  getDocumentResultLink(job: JobSearchResult): Observable<LinkWithName> {
    if (job.resultSummary.singleResult) {
      return this.getSingleDocumentResultLink(job);
    } else {
      return this.jobsService.getJobResultsByJobID(job.id, job.submitter.organizationID).pipe(
        filter((results) => !!results.length),
        switchMap((results) => {
          const documentResults = results.filter(
            (res) => res.resultType === JobResultTypes.DocumentEntity,
          );
          const documentResultIDs = documentResults.map((result) => result.resultID);
          // assume that there will be at most ONE folder result, and it will be the output folder
          // this will need to be changed if/when multiple output folders can be created

          return this.documentHttpService.get(documentResults[0].resultID).pipe(
            map((doc) => {
              return {
                link: ['/folders', doc.parentID, 'files'],
                queryParams: { selectRowID: documentResultIDs.join(',') },
                name: `${Math.max(documentResults.length, 1)} Result${
                  Math.max(documentResults.length, 1) > 1 ? 's' : ''
                }`,
              };
            }),
          );
        }),
        take(1),
      );
    }
  }

  private getSingleDocumentResultLink(job: JobSearchResult): Observable<LinkWithName> {
    return this.documentHttpService.get(job.resultSummary.singleResult.resultID).pipe(
      take(1),
      map((doc) => {
        return {
          link: ['/folders', doc.parentID, 'files'],
          queryParams: { selectRowID: job.resultSummary.singleResult.resultID },
          name: '1 Result',
        };
      }),
    );
  }

  getSingleFolderResultLink(job: JobSearchResult): LinkWithName {
    return {
      link: ['/folders', job.resultSummary.singleResult.resultID, 'files'],
      queryParams: {},
      name: '1 Result',
    };
  }

  static getPlaceholderText(job: Job): string {
    const finished: JobStatus['kind'][] = [
      'Failed',
      'Failing',
      'Completed',
      'Cancelled',
      'Cancelling',
    ];
    return !finished.includes(job.status.kind) ? 'Pending...' : '-';
  }

  refresh(params: any): boolean {
    return false;
  }
}

class LinkWithName {
  link: string[];
  queryParams: any;
  name: string;
}

interface JobResultAndLink {
  result: LinkWithName | null;
  download: string | null;
}
