import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * A directive that focuses the element it is attached to after the view is initialized. Similar to ngbAutofocus, but that only works in modals.
 */
@Directive({
  selector: '[bxAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
}
