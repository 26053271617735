@if (logSummary) {
  <ng-template #popoverContent>
    <div class="w-100 d-flex flex-row justify-content-between mb-2">
      <b class="w-100 d-flex flex-row justify-content-center"
        >{{ totalInsights > INSIGHT_LIMIT ? 'Top 5 ' : '' }}Insights for {{ pipelineName }}</b
      >
      <button type="button" class="btn-close" onClick="popover.close()"></button>
    </div>
    <bx-job-insights
      [jobID]="jobSearchResult.id"
      [organizationID]="jobSearchResult.submitter.organizationID"
      [insightDisplayLimit]="INSIGHT_LIMIT"
      class="d-block mb-3"
    ></bx-job-insights>
    <div class="w-100 d-flex flex-row justify-content-end mb-2">
      @if (buttonData) {
        <button class="btn btn-light btn-sm" [routerLink]="buttonData.link">
          {{ buttonData.text }}
        </button>
      }
    </div>
  </ng-template>
  <div class="position-relative">
    @if (totalInsights > 0) {
      <div
        [ngbPopover]="popoverContent"
        container="body"
        placement="right"
        popoverClass="bx-popover-fs-base w-static-3"
        onClick="popover.open()"
        role="button"
        [animation]="true"
      >
        @if (logSummary?.warningCount > 0) {
          <fa-icon class="text-warning" [icon]="faExclamationTriangle"></fa-icon>
          {{ logSummary.warningCount }}
        }
        @if (logSummary?.infoCount > 0) {
          <fa-icon class="text-info" [icon]="faInfoCircle"></fa-icon>
          {{ logSummary.infoCount }}
        }
      </div>
    } @else {
      -
    }
  </div>
}
