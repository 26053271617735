import { Event, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

function isNavigationEnd(event: Event): event is NavigationEnd {
  return event instanceof NavigationEnd;
}

export function isNavigationEndEvent() {
  return (source$: Observable<Event>) => source$.pipe(filter(isNavigationEnd));
}
