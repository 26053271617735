<div class="btn-group" role="group">
  <a
    data-testid="open-document-btn"
    role="button"
    class="btn d-flex align-items-center"
    [ngClass]="buttonClass"
    [ngbTooltip]="tooltipTextButton"
    container="body"
    routerLink="/document"
    [queryParams]="openQueryParams"
    data-testid="open-document-view"
  >
    {{ text }}
  </a>
  @if (editButtonVisible) {
    <div class="border-light border-start border-end"></div>
    <a
      role="button"
      [ngClass]="buttonClass"
      class="btn px-2"
      [ngbTooltip]="tooltipEditButton"
      container="body"
      (click)="openInNewWindow(editQueryParams)"
      data-testid="open-document-edit"
    >
      <fa-icon [icon]="editIcon" class="mx-1"></fa-icon>
    </a>
  }
  <div class="border-light border-start border-end"></div>
  <a
    role="button"
    [ngClass]="buttonClass"
    class="btn d-flex align-items-center pb-1 px-2"
    [ngbTooltip]="tooltipPopoutButton"
    container="body"
    (click)="openInNewWindow(openQueryParams)"
    data-testid="open-document-new-tab"
  >
    <fa-icon [icon]="newTabIcon" class="mx-1"></fa-icon>
  </a>
</div>
