import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentServiceColumnDataType,
  DocumentTable,
} from '../../../../nucleus/services/documentService/types';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { CleanUp } from '../../../shared/cleanup';
import { distinctUntilChanged, take, withLatestFrom } from 'rxjs/operators';
import { SeriesClickEventObject, SeriesScatterOptions } from 'highcharts';
import { ScatterplotChartComponent } from '../../../features/graphs/scatterplot-chart/scatterplot-chart.component';
import { GraphControlTypeEnum, GraphSidebarControl } from '../../../features/graphs/graph-sidebar';
import { ExportableChartComponent } from '../../../features/graphs/exportable-chart';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import {
  selectDataForComparisonsDocument,
  selectParamsForComparisonsDocument,
} from '../ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.selectors';
import { ComparisonsGraphDocumentState } from '../ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.reducer';
import { SelectionForGraph } from '../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';
import { NgStyle, AsyncPipe } from '@angular/common';
import { GraphSidebarComponent } from '../../../features/graphs/graph-sidebar/graph-sidebar.component';

@Component({
  selector: 'bx-ngs-comparisons-scatterplot',
  templateUrl: './ngs-comparisons-scatterplot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ScatterplotChartComponent, NgStyle, GraphSidebarComponent, AsyncPipe],
})
export class NgsComparisonsScatterplotComponent
  extends CleanUp
  implements OnInit, OnDestroy, ExportableChartComponent
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100 w-100';
  @ViewChild(ScatterplotChartComponent) chartComponent: ScatterplotChartComponent;

  @Input() documentID: string;
  @Output() pointClicked = new EventEmitter<SeriesClickEventObject>();

  scatterplotParams$: Observable<ScatterplotParams>;
  scatterplotData$: Observable<ProcessedScatterplotData>;
  message$ = new BehaviorSubject<string>(null);
  selectableColumns$: Observable<SelectableColumn[]>;
  controls$: Observable<GraphSidebarControl[]>;

  selectedXAxis$ = new ReplaySubject<SelectableColumn>(1);
  selectedYAxis$ = new ReplaySubject<SelectableColumn>(1);
  columnSelection$: Observable<ColumnPair>;

  rowData$: Observable<Point[]>;
  selectedParams$: Observable<ComparisonsGraphDocumentState>;

  private readonly EXCLUDED_COLUMNS: string[] = ['geneious_row_index'];
  private tableName$: Observable<string>;
  private numberOfClusters$: Observable<number>;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.selectedParams$ = this.store.select(selectParamsForComparisonsDocument(this.documentID));
    this.selectableColumns$ = this.selectedParams$.pipe(
      map(({ selectedTable }) => {
        const cols = selectedTable.columns
          .filter(
            (col) =>
              [
                DocumentServiceColumnDataType.Integer,
                DocumentServiceColumnDataType.Double,
              ].includes(col.dataType.kind) && !this.EXCLUDED_COLUMNS.includes(col.name),
          )
          .map(({ name, displayName }) => ({ name, displayName, id: name }));
        const loggedCols = [];
        for (const col of cols) {
          if (col.name.includes('P-Value')) {
            loggedCols.push({
              name: col.name,
              displayName: `-Log10 ${col.displayName}`,
              id: `-Log10 ${col.name}`,
              transform: (val: number) => -1 * Math.log10(val),
            });
          }
        }
        return cols.concat(loggedCols);
      }),
    );

    this.columnSelection$ = combineLatest([
      this.selectedXAxis$.pipe(
        distinctUntilChanged(
          (prev, next) => prev.name === next.name && prev.displayName === next.displayName,
        ),
      ),
      this.selectedYAxis$.pipe(
        distinctUntilChanged(
          (prev, next) => prev.name === next.name && prev.displayName === next.displayName,
        ),
      ),
    ]).pipe(
      map(([xAxisColumn, yAxisColumn]) => ({ xAxisColumn, yAxisColumn })),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    // Set the default selected columns, we only do this once when the component is first created as
    // after that, we should just be able to reuse whatever is in the selectedAxis already.
    this.selectableColumns$.pipe(take(1)).subscribe((cols) => {
      if (cols.length < 2) {
        this.message$.next('Fewer than two columns are available for comparison');
        return;
      }

      const countColumns = cols.filter((col) => col.name.startsWith('Count '));
      if (countColumns.length >= 2) {
        this.selectedXAxis$.next(countColumns[0]);
        this.selectedYAxis$.next(countColumns[1]);
      } else {
        this.selectedXAxis$.next(cols[0]);
        this.selectedYAxis$.next(cols[1]);
      }
    });

    this.scatterplotParams$ = combineLatest([this.columnSelection$, this.selectedParams$]).pipe(
      map(([columnSelection, { selection, selectedTable, filter }]) => {
        return {
          selectedTable,
          selection,
          columnSelection,
          filter,
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.tableName$ = this.scatterplotParams$.pipe(
      map(
        (params) =>
          params.selectedTable.metadata?.clusters?.columnName ?? params.selectedTable.displayName,
      ),
    );

    this.rowData$ = combineLatest([
      this.store.select(selectDataForComparisonsDocument(this.documentID)),
      this.columnSelection$,
      this.tableName$,
    ]).pipe(
      map(([{ data }, columnSelection, tableName]) => {
        return data
          .map((row: Row) => this.getPointFromRow(row, columnSelection, tableName))
          .filter((p: Point) => !!p && (!!p.x || p.x === 0) && (!!p.y || p.y === 0))
          .reduce(this.mergeDuplicatePoints, [])
          .map((point) => this.prefixPointName(point, tableName));
      }),
    );

    this.numberOfClusters$ = this.store
      .select(selectDataForComparisonsDocument(this.documentID))
      .pipe(map(({ data }) => data.length));

    this.scatterplotData$ = combineLatest([
      this.rowData$,
      this.columnSelection$,
      this.tableName$,
      this.numberOfClusters$,
    ]).pipe(
      map(([data, columnSelection, tableName, numberOfClusters]) =>
        this.processScatterplotData(data, columnSelection, tableName, numberOfClusters),
      ),
    );

    this.controls$ = this.selectableColumns$.pipe(
      withLatestFrom(this.columnSelection$),
      map(([cols, currentColumnSelection]) => {
        // If there is already a selected column, and it's present in the new columns, use that.
        // Otherwise, use the default columns.
        const currentXAxis = currentColumnSelection.xAxisColumn;
        const currentYAxis = currentColumnSelection.yAxisColumn;
        let defaultXColumn = cols.find((col) => col.id === currentXAxis.id);
        let defaultYColumn = cols.find((col) => col.id === currentYAxis.id);

        return [
          {
            name: 'xAxis',
            label: 'X Axis',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: defaultXColumn?.id,
            options: cols.map(({ id, displayName }) => ({ displayName, value: id })),
          },
          {
            name: 'yAxis',
            label: 'Y Axis',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: defaultYColumn?.id,
            options: cols.map(({ id, displayName }) => ({ displayName, value: id })),
          },
        ];
      }),
    );
  }

  getPointFromRow(
    row: {
      [key: string]: any;
    },
    columnSelection: ColumnPair,
    tableName: string,
  ): Point {
    const xCol = columnSelection.xAxisColumn;
    const yCol = columnSelection.yAxisColumn;
    return {
      name: !!row[tableName] ? `${row[tableName]}` : '',
      x: getValueFromRow(xCol, row),
      y: getValueFromRow(yCol, row),
    };
  }

  processScatterplotData(
    data: Point[],
    columnSelection: ColumnPair,
    tableName: string,
    numberOfClusters: number,
  ): ProcessedScatterplotData {
    return {
      scatterplotOptions: [
        {
          type: 'scatter',
          showInLegend: false,
          data,
        },
      ],
      title: `Scatterplot of ${numberOfClusters} ${
        numberOfClusters === 1 ? 'cluster' : 'clusters'
      }`,
      subtitle: `Comparison of ${tableName}`,
      xAxisTitle: columnSelection.xAxisColumn.displayName,
      yAxisTitle: columnSelection.yAxisColumn.displayName,
    };
  }

  ngOnDestroy(): void {
    this.selectedXAxis$.complete();
    this.selectedYAxis$.complete();
    this.message$.complete();
  }

  onControlsChanged({ xAxis, yAxis }: any) {
    this.selectableColumns$.pipe(take(1)).subscribe((cols) => {
      if (!!xAxis) {
        this.selectedXAxis$.next(cols.find((col) => col.id === xAxis));
      } else {
        this.selectedXAxis$.next(null);
      }
      if (!!yAxis) {
        this.selectedYAxis$.next(cols.find((col) => col.id === yAxis));
      } else {
        this.selectedYAxis$.next(null);
      }
    });
  }

  onSidebarToggled() {
    // setTimeout is necessary to let the sidebar expand out before resizing the graph.
    setTimeout(() => this.resize(), 0);
  }

  resize(): void {
    if (this.chartComponent) {
      this.chartComponent.resize();
    }
  }

  exportAsImage() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.selection?.documentName),
      )
      .subscribe((documentName) => this.chartComponent.downloadImage(documentName));
  }

  exportAsTable() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.selection?.documentName),
      )
      .subscribe((documentName) =>
        this.chartComponent.downloadTable({
          documentName,
        }),
      );
  }

  private mergeDuplicatePoints = (existingPoints: Point[], newPoint: Point): Point[] => {
    // If the new point has the same x and y values as an existing point, don't add it and instead merge the names.
    let isDuplicate = false;
    for (const existing of existingPoints) {
      if (existing.x === newPoint.x && existing.y === newPoint.y) {
        isDuplicate = true;
        if (existing.name.length < 50) {
          existing.name = `${existing.name}, ${newPoint.name}`;
        } else if (!existing.name.endsWith('...')) {
          // append dots after we get past 50 characters instead of appending the name of new points.
          existing.name = existing.name + '...';
        }
        break;
      }
    }

    if (!isDuplicate) {
      existingPoints.push(newPoint);
    }

    return existingPoints;
  };

  private prefixPointName(p: Point, prefix: string): Point {
    return {
      ...p,
      name: `${prefix}: ${p.name}`,
    };
  }
}

export interface SelectableColumn {
  name: string;
  displayName: string;
  id: string;
  transform?: (val: number) => number;
}

export interface ColumnPair {
  xAxisColumn: SelectableColumn;
  yAxisColumn: SelectableColumn;
}

export interface ScatterplotParams {
  selectedTable: DocumentTable;
  selection: SelectionForGraph;
  columnSelection: ColumnPair;
  filter: string;
}

export interface ProcessedScatterplotData {
  scatterplotOptions: SeriesScatterOptions[];
  title: string;
  subtitle: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

export interface Row {
  [col: string]: any;
}

export interface Point {
  name: string;
  x: number;
  y: number;
}

function getValueFromRow(col: SelectableColumn, row: Row): number | null {
  return col.transform ? col.transform(row[col.name]) : row[col.name];
}
