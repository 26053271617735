/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, Input, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import {
  NGSJobOptionsV1,
  NGSJobParametersV1,
} from '../../../../nucleus/services/models/ngsOptions.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SequenceAlphabet } from '../../sequence-alphabet.model';
import { SequenceUtils } from '../../sequence-utils';
import { AntibodyAnnotatorOptionValues } from './antibody-annotator-option-values.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';

/**
 * Base class for AntibodyAnnotator dialog components. Sets common properties
 * to satisfy JobDialogContent. Contains logic for choosing a single source of
 * SelectionState for the base component.
 */
@Directive()
export abstract class AntibodyAnnotatorBaseComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  readonly title = 'Antibody Annotator';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly faChevronRight = faChevronRight;
  isNucleotides = true;

  /** Observable of selection state, populated by this base class in ngOnInit. */
  protected selectionState$?: Observable<SelectionState>;

  /**
   * Use this to populate selectedState if the component will not be provided with
   * the PIPELINE_DIALOG_DATA InjectionToken.
   */
  @Input() set selection$(selectionState$: Observable<SelectionState>) {
    this.selectionState$ = selectionState$.pipe(
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * AntibodyAnnotatorBaseComponent constructor
   *
   * @param dialogData Optionally injected in subclass constructor with PIPELINE_DIALOG_DATA token
   */
  constructor(private readonly dialogData: PipelineDialogData | null = null) {
    super('ngs', PipelineFormID.ANTIBODY_ANNOTATOR);
  }

  /**
   * If your subclass implements OnInit, don't forget to call super.ngOnInit()!
   */
  ngOnInit(): void {
    if (!this.selectionState$) {
      if (!this.dialogData) {
        throw new Error(
          'No value provided for selectionState$. Use the selected$ @Input or the PIPELINE_DIALOG_DATA token.',
        );
      }
      this.selectionState$ = of(this.dialogData.selected).pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
      );
    }
    this.selectionState$.subscribe((selection) => {
      this.isNucleotides = SequenceUtils.isSelectionOfType(
        SequenceAlphabet.NUCLEOTIDE,
        selection.selectedRows,
      );
    });
  }

  /**
   * Supplies the form values as NGSJobOptions. The AntibodyAnnotatorBaseComponent
   * handles submitting the job params to the JobService.
   */
  abstract generateJobOptions(): NGSJobOptionsV1<AntibodyAnnotatorOptionValues>;

  abstract generateOutputFolderName(): string;

  /**
   * Usage note: assumes that either the dialogData or folderId property is present.
   *
   * @see JobDialogContent#run()
   */
  run() {
    const options = this.generateJobOptions();
    const outputFolderName = this.generateOutputFolderName();
    return this.selectionState$.pipe(
      map((selectionState) => {
        return new NGSJobParametersV1(1, {
          options,
          selection: {
            selectAll: selectionState.selectAll,
            ids: selectionState.ids,
            folderId: this.dialogData?.folderID,
          },
          output: {
            outputFolderName: outputFolderName,
          },
        });
      }),
    );
  }
}
