import { createReducer, on } from '@ngrx/store';
import { NGSSequenceData, NGSStoreState } from './index';
import { createEntityAdapter } from '@ngrx/entity';
import {
  clearNGSState,
  fetchSequences,
  fetchSequencesFailure,
  fetchSequencesSuccess,
} from './ngs.actions';

export const adapter = createEntityAdapter<NGSSequenceData>();

export const initialState: NGSStoreState = {
  ...adapter.getInitialState(),
  fetching: false,
  error: false,
};

export const ngsReducer = createReducer(
  initialState,
  on(fetchSequences, (state, { documentID, ids }) => {
    // We don't want to remember a sequence in memory if it has been unselected.
    const sequencesToRemoveFromCache = (state.ids as number[]).filter((id) => !ids.includes(id));
    return {
      ...adapter.removeMany(sequencesToRemoveFromCache, state),
      documentID,
      fetching: true,
      error: false,
    };
  }),
  on(fetchSequencesSuccess, (state, { sequences }) => ({
    ...adapter.upsertMany(sequences, state),
    fetching: false,
  })),
  on(fetchSequencesFailure, (state) => ({ ...state, fetching: false, error: true })),
  on(clearNGSState, () => initialState),
);
