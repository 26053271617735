import {
  ChangeDetectionStrategy,
  Component,
  InjectionToken,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ChipsService } from '../chips.service';
import { ComponentPortal, ComponentType, PortalModule } from '@angular/cdk/portal';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

export const POPOVER_DATA = new InjectionToken<unknown>('POPOVER_DATA');

@Component({
  selector: 'bx-chip-add-button',
  templateUrl: './chip-add-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbPopover, FaIconComponent, PortalModule],
})
export class ChipAddButtonComponent implements OnInit {
  @ViewChild(NgbPopover, { static: true }) ngbPopover: NgbPopover;
  @Input() addButtonPopoverContentComponent: ComponentType<any>;
  @Input() addButtonPopoverContentData: unknown;
  @Input() disabled = false;

  faPlus = faPlus;
  componentPortal: ComponentPortal<any>;

  constructor(
    private chipsService: ChipsService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.componentPortal = new ComponentPortal(
      this.addButtonPopoverContentComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: ChipsService,
            useValue: this.chipsService,
          },
          {
            provide: NgbPopover,
            useValue: this.ngbPopover,
          },
          {
            provide: POPOVER_DATA,
            useFactory: () => this.addButtonPopoverContentData,
          },
        ],
        parent: this.injector,
      }),
      null,
    );
  }
}
