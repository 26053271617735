<bx-collapsible-panel id="controls-sidebar" panelClass="w-static-1 overflow-auto p-0">
  <div ngbAccordion class="bx-card-accordion">
    <div
      ngbAccordionItem
      (shown)="openAccordion('panel-colors'); selectFirstAnnotationType()"
      (hidden)="closeAccordion('panel-colors')"
      id="panel-colors"
    >
      <div ngbAccordionHeader>
        <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
          <small>Colors</small>
          <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-colors')"></fa-icon>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div role="region" ngbAccordionBody>
          <ng-template>
            <div class="row mb-2">
              <label class="col-form-label col-form-label-sm col-3" for="color-schemes"
                >Color</label
              >
              <div class="col">
                <select
                  id="color-schemes"
                  class="form-select form-select-sm"
                  [(ngModel)]="colorScheme"
                  (ngModelChange)="
                    optionChanged(
                      showDNAControls
                        ? { sequencesPlugin: { DNAColorScheme: $event } }
                        : { sequencesPlugin: { proteinColorScheme: $event } }
                    )
                  "
                >
                  @for (color of colorSchemes(); track colorTrackFn($index, color)) {
                    <option [value]="color.value">
                      {{ color.label }}
                    </option>
                  }
                </select>
              </div>
            </div>

            @if (colorByAnnotation) {
              <div class="row mb-0">
                <label class="col-form-label col-form-label-sm col-3" for="color-type">Type</label>
                <div class="col">
                  <select
                    id="color-type"
                    class="form-select form-select-sm"
                    [(ngModel)]="colorByAnnotation.annotationType"
                    (ngModelChange)="
                      optionChanged({ colorByAnnotation: { annotationType: $event } })
                    "
                    [disabled]="colorScheme !== 'byAnnotation'"
                  >
                    @for (type of types; track type) {
                      <option [value]="type.key">
                        {{ type.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
            }
          </ng-template>
        </div>
      </div>
    </div>

    @if (translations && showDNAControls) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-translations')"
        (hidden)="closeAccordion('panel-translations')"
        id="panel-translations"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <div class="form-check mb-0" (click)="$event.stopPropagation()">
              <input
                id="translations"
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="translations.enabled"
                (ngModelChange)="optionChanged({ translations: { enabled: $event } })"
              />
              <label class="form-check-label small" for="translations"> Translations </label>
            </div>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-translations')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="row mb-2">
                <label class="col-form-label col-form-label-sm col-3" for="frame">Frame</label>
                <div class="col">
                  <select
                    id="frame"
                    class="form-select form-select-sm"
                    [disabled]="!translations.enabled"
                    [(ngModel)]="translations.frames"
                    (ngModelChange)="optionChanged({ translations: { frames: $event } })"
                  >
                    @for (frame of framesOptions; track frame) {
                      <option [value]="frame.id">
                        {{ frame.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-form-label col-form-label-sm col-3" for="table">Table</label>
                <div class="col">
                  <select
                    id="table"
                    class="form-select form-select-sm"
                    [disabled]="!translations.enabled"
                    [(ngModel)]="translations.table"
                    (ngModelChange)="optionChanged({ translations: { table: $event } })"
                  >
                    @for (table of tableOptions; track table) {
                      <option [value]="table.id">
                        {{ table.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-form-label col-form-label-sm col-3" for="translations-color">
                  Color
                </label>
                <div class="col">
                  <select
                    id="translations-color"
                    class="form-select form-select-sm"
                    [(ngModel)]="translationColorScheme"
                    (ngModelChange)="optionChanged({ translations: { colorScheme: $event } })"
                  >
                    @for (color of colorSchemes(true); track colorTrackFn($index, color)) {
                      <option [value]="color.value">
                        {{ color.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div class="form-check mb-0">
                <input
                  id="show-nucleotides"
                  class="form-check-input"
                  aria-label="Show nucleotides"
                  type="checkbox"
                  [disabled]="!translations.enabled"
                  [(ngModel)]="translations.showNucleotides"
                  (ngModelChange)="optionChanged({ translations: { showNucleotides: $event } })"
                />
                <label class="form-check-label small" for="show-nucleotides"
                  >Show nucleotides</label
                >
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    @if (annotations) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-annotations')"
        (hidden)="closeAccordion('panel-annotations')"
        id="panel-annotations"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <div class="form-check mb-0" (click)="$event.stopPropagation()">
              <input
                id="annotations"
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="annotations.enabled"
                (ngModelChange)="optionChanged({ annotations: { enabled: $event } })"
              />
              <label class="form-check-label small" for="annotations">Annotations</label>
            </div>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-annotations')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="d-block ps-1">
                @if (showAlignmentControls) {
                  <div class="form-check">
                    <input
                      id="all-sequences"
                      class="form-check-input"
                      aria-label="All sequences"
                      type="radio"
                      [disabled]="!annotations.enabled"
                      [(ngModel)]="annotations.filter"
                      value="all"
                      (ngModelChange)="optionChanged({ annotations: { filter: $event } })"
                    />
                    <label for="all-sequences" class="form-check-label small">
                      All sequences
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      id="reference-only"
                      class="form-check-input"
                      aria-label="Reference only"
                      type="radio"
                      [disabled]="!annotations.enabled || !referenceExists"
                      [(ngModel)]="annotations.filter"
                      value="reference"
                      (ngModelChange)="optionChanged({ annotations: { filter: $event } })"
                    />
                    <label for="reference-only" class="form-check-label small">
                      Reference only
                    </label>
                  </div>
                  @if (consensus) {
                    <div class="form-check">
                      <input
                        id="consensus-only"
                        class="form-check-input"
                        aria-label="Consensus only"
                        type="radio"
                        [disabled]="!annotations.enabled"
                        [(ngModel)]="annotations.filter"
                        value="consensus"
                        (ngModelChange)="optionChanged({ annotations: { filter: $event } })"
                      />
                      <label for="consensus-only" class="form-check-label small">
                        Consensus only
                      </label>
                    </div>
                  }
                  <hr class="my-2" />
                }
                @for (type of types; track type) {
                  <div class="form-check">
                    <input
                      [id]="'svAnno_' + type.label"
                      type="checkbox"
                      class="form-check-input"
                      [disabled]="!annotations.enabled"
                      [(ngModel)]="type.visible"
                      (ngModelChange)="optionChanged(annotationTypeObject(type.label, $event))"
                    />
                    <label [for]="'svAnno_' + type.label" class="form-check-label small">
                      <span class="text-truncate" [title]="type.label">
                        {{ type.label }}
                      </span>
                      ({{ type.count }})
                    </label>
                  </div>
                }
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    @if (highlighting && showAlignmentControls) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-highlighting')"
        (hidden)="closeAccordion('panel-highlighting')"
        id="panel-highlighting"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <div class="form-check mb-0" (click)="$event.stopPropagation()">
              <input
                id="highlighting"
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="highlighting.enabled"
                (ngModelChange)="optionChanged({ highlighting: { enabled: $event } })"
              />
              <label for="highlighting" class="form-check-label small">Highlighting</label>
            </div>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-highlighting')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="row mb-2">
                <label for="highlight" class="col-form-label col-form-label-sm col-4">
                  Highlight
                </label>
                <div class="col">
                  <select
                    id="highlight"
                    class="form-select form-select-sm"
                    [(ngModel)]="highlighting.highlight"
                    [disabled]="highlightingControlsDisabled || !highlighting.enabled"
                    (ngModelChange)="optionChanged({ highlighting: { highlight: $event } })"
                  >
                    @for (highlight of highlightOptions; track highlight) {
                      <option [value]="highlight.value">
                        {{ highlight.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-form-label col-form-label-sm col-4" for="highlight-relative-to">
                  Relative to
                </label>
                <div class="col">
                  <select
                    id="highlight-relative-to"
                    class="form-select form-select-sm"
                    [(ngModel)]="relativeTo"
                    [disabled]="highlightingControlsDisabled || !highlighting.enabled"
                    (ngModelChange)="
                      optionChanged({ highlighting: { useConsensus: $event === 'consensus' } })
                    "
                  >
                    <option value="consensus">Consensus</option>
                    <option
                      value="reference"
                      [disabled]="!referenceExists"
                      [selected]="referenceExists"
                    >
                      Reference
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-check mb-0">
                <input
                  id="dots"
                  class="form-check-input"
                  aria-label="Show dots"
                  type="checkbox"
                  [(ngModel)]="highlighting.useDots"
                  [disabled]="highlightingControlsDisabled || !highlighting.enabled"
                  (ngModelChange)="optionChanged({ highlighting: { useDots: $event } })"
                />
                <label for="dots" class="form-check-label small">Show dots</label>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    @if (consensus && showAlignmentControls) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-consensus')"
        (hidden)="closeAccordion('panel-consensus')"
        id="panel-consensus"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <div class="form-check mb-0" (click)="$event.stopPropagation()">
              <input
                id="consensus"
                type="checkbox"
                class="form-check-input"
                [(ngModel)]="consensus.enabled"
                (ngModelChange)="optionChanged({ consensus: { enabled: $event } })"
              />
              <label class="form-check-label small" for="consensus">Consensus</label>
            </div>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-consensus')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="form-check mb-2">
                <input
                  id="quality-scores"
                  type="checkbox"
                  class="form-check-input"
                  [disabled]="!consensus.enabled"
                  [(ngModel)]="consensus.useQualityScores"
                  (ngModelChange)="optionChanged({ consensus: { useQualityScores: $event } })"
                />
                <label for="quality-scores" class="form-check-label small"> Quality Scores </label>
              </div>
              <div class="row mb-2">
                <label class="col-form-label col-form-label-sm col-4" for="relative-to">
                  Relative to
                </label>
                <div class="col">
                  <select
                    id="relative-to"
                    class="form-select form-select-sm"
                    [(ngModel)]="relativeTo"
                    [disabled]="highlightingControlsDisabled || !highlighting.enabled"
                    (ngModelChange)="
                      optionChanged({ highlighting: { useConsensus: $event === 'consensus' } })
                    "
                  >
                    <option value="consensus">Consensus</option>
                    <option
                      value="reference"
                      [disabled]="!referenceExists"
                      [selected]="referenceExists"
                    >
                      Reference
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-0">
                <label for="consensus-threshold" class="col-form-label col-form-label-sm col-4">
                  Threshold
                </label>
                <div class="col">
                  <select
                    id="consensus-threshold"
                    class="form-select form-select-sm"
                    [(ngModel)]="consensus.threshold"
                    [disabled]="!consensus.enabled || consensus.useQualityScores"
                    (ngModelChange)="optionChanged({ consensus: { threshold: asNumber($event) } })"
                  >
                    @for (threshold of consensusThresholdOptions; track threshold) {
                      <option [value]="threshold.value">
                        {{ threshold.label }}
                      </option>
                    }
                  </select>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    <div
      ngbAccordionItem
      (shown)="openAccordion('panel-graphs')"
      (hidden)="closeAccordion('panel-graphs')"
      id="panel-graphs"
    >
      <div ngbAccordionHeader>
        <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
          <div class="form-check mb-0" (click)="$event.stopPropagation()">
            <input
              id="graphs"
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="allGraphs"
              (ngModelChange)="optionChanged({ allGraphs: $event })"
            />
            <label class="form-check-label small" for="graphs">Graphs</label>
          </div>
          <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-graphs')"></fa-icon>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div role="region" ngbAccordionBody>
          <ng-template>
            @if (graphs) {
              @if (showDNAControls) {
                <div class="mb-2">
                  <div class="row mb-2">
                    <div class="col-8 d-flex align-items-center">
                      <div class="form-check">
                        <input
                          id="chromatogram-enabled"
                          type="checkbox"
                          class="form-check-input"
                          aria-label="Chromatogram"
                          [disabled]="!allGraphs"
                          [(ngModel)]="chromatogramEnabled"
                          (ngModelChange)="optionChanged({ graphs: { chromatogram: $event } })"
                        />
                        <label for="chromatogram-enabled" class="form-check-label small">
                          Chromatogram
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <input
                        id="chromatogram-height"
                        class="form-control form-control-sm ms-auto"
                        required
                        type="number"
                        [disabled]="!allGraphs"
                        [(ngModel)]="graphs.height"
                        (ngModelChange)="optionChanged({ graphs: { height: $event } })"
                        min="10"
                        max="200"
                        title="Height in pixels"
                      />
                    </div>
                  </div>
                  <div class="form-check ms-3">
                    <input
                      id="quality"
                      class="form-check-input"
                      type="checkbox"
                      aria-label="Quality"
                      [disabled]="!allGraphs"
                      [(ngModel)]="qualitiesEnabled"
                      (ngModelChange)="optionChanged({ graphs: { qualities: $event } })"
                    />
                    <label for="quality" class="form-check-label small">
                      Quality&nbsp;<span class="text-muted">(scale: 0-60)</span>
                    </label>
                  </div>
                </div>
              }
            }

            @if (showAlignmentControls) {
              <div class="row mb-2">
                <div class="col-8 d-flex align-items-center">
                  <div class="form-check">
                    <input
                      id="identity"
                      type="checkbox"
                      class="form-check-input"
                      aria-label="Pairwise identity"
                      [disabled]="!allGraphs"
                      [(ngModel)]="identityEnabled"
                      (ngModelChange)="optionChanged({ identity: { enabled: $event } })"
                    />
                    <label
                      for="identity"
                      class="form-check-label small"
                      title="Identity scale: 0-100%"
                    >
                      Pairwise identity
                    </label>
                  </div>
                </div>
                <div class="col">
                  <input
                    id="graph-height"
                    class="form-control form-control-sm ms-auto"
                    required
                    type="number"
                    [disabled]="!allGraphs"
                    [(ngModel)]="identity.height"
                    (ngModelChange)="optionChanged({ identity: { height: $event } })"
                    min="5"
                    max="100"
                    title="Height in pixels"
                  />
                </div>
              </div>
            }

            @if (showAlignmentControls) {
              <div class="row mb-2">
                <div class="col-8 d-flex align-items-center">
                  <div class="form-check">
                    <input
                      id="wu-kabat"
                      type="checkbox"
                      class="form-check-input"
                      aria-label="Wu-Kabat variability"
                      [disabled]="!allGraphs"
                      [(ngModel)]="wuKabatEnabled"
                      (ngModelChange)="optionChanged({ wuKabat: { enabled: $event } })"
                    />
                    <label
                      for="wu-kabat"
                      class="form-check-label small"
                      title="Wu-Kabat scale: 1-{{ wuKabatMaxScore }}"
                    >
                      Wu-Kabat variability
                    </label>
                  </div>
                </div>
                <div class="col">
                  <input
                    id="wu-kabat-height"
                    class="form-control form-control-sm ms-auto"
                    required
                    type="number"
                    [disabled]="!allGraphs"
                    [(ngModel)]="wuKabat.height"
                    (ngModelChange)="optionChanged({ wuKabat: { height: $event } })"
                    min="5"
                    max="100"
                    title="Height in pixels"
                  />
                </div>
              </div>
            }

            @if (showAlignmentControls) {
              <div class="form-check mb-0">
                <input
                  id="heatmaps"
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Use heatmaps"
                  [disabled]="!allGraphs"
                  [(ngModel)]="heatmapEnabled"
                  (ngModelChange)="
                    optionChanged({
                      wuKabat: { isHeatmap: $event },
                      identity: { isHeatmap: $event },
                    })
                  "
                />
                <label for="heatmaps" class="form-check-label small"> Use heatmaps </label>
              </div>
            }
          </ng-template>
        </div>
      </div>
    </div>

    @if (showSorting) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-sorting')"
        (hidden)="closeAccordion('panel-sorting')"
        id="panel-sorting"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <small>Sorting</small>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-sorting')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="d-flex flex-wrap align-items-center justify-content-between px-2">
                @if (showAlignmentControls) {
                  <div class="form-check">
                    <input
                      id="pin-reference"
                      class="form-check-input"
                      type="checkbox"
                      aria-label="Pin reference"
                      [(ngModel)]="pinReference"
                      (ngModelChange)="optionChanged({ sorting: { pinReference: $event } })"
                    />
                    <label for="pin-reference" class="form-check-label small">
                      Pin reference
                    </label>
                  </div>
                  <hr class="w-100 my-2" />
                }
                <label class="form-label small mb-0">Sort by</label>
                @for (criterion of sortCriteria; track criterion; let i = $index) {
                  <sv-sort
                    class="w-100 my-2"
                    [criteria]="criterion"
                    (criteriaRemoved)="removeSort(i)"
                    (sortChanged)="changeSort($event, i)"
                  >
                  </sv-sort>
                  <hr class="w-100 my-2" />
                }
                <button
                  type="button"
                  title="Add sort criteria"
                  class="btn btn-sm ms-auto py-0"
                  (click)="addSortCriteria()"
                >
                  <fa-icon [icon]="'plus'"></fa-icon>
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    @if (showSidebarDrawer) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-sidebar')"
        (hidden)="closeAccordion('panel-sidebar')"
        id="panel-sidebar"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <small>Sidebar</small>
            <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-sidebar')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              @if (tree && showAlignmentControls) {
                <div class="form-check">
                  <input
                    id="tree"
                    type="checkbox"
                    class="form-check-input"
                    aria-label="Tree"
                    [(ngModel)]="tree.enabled"
                    (ngModelChange)="optionChanged({ tree: { enabled: $event } })"
                    [disabled]="sortCriteria.length > 0"
                  />
                  <label for="tree" class="form-check-label small"> Show tree </label>
                </div>
              }
              @if (columns.length) {
                @if (tree && showAlignmentControls) {
                  <hr class="my-2" />
                }
                @if (columns.length) {
                  <div class="form-check mb-2">
                    <input
                      id="metadata-values"
                      type="checkbox"
                      class="form-check-input"
                      aria-label="Show values"
                      [(ngModel)]="metadata.showValue"
                      (ngModelChange)="optionChanged({ metadata: { showValue: $event } })"
                    />
                    <label for="metadata-values" class="form-check-label small">
                      Show values for metadata
                    </label>
                  </div>
                }
                @if (columns.length) {
                  <input
                    id="columns-filter"
                    type="text"
                    title="Filter columns"
                    class="form-control form-control-sm mb-2"
                    [(ngModel)]="filter"
                    placeholder="Filter"
                  />
                }
                @for (column of columns; track column; let i = $index) {
                  <div class="metadata mb-2">
                    @if (column.columns && groupIsVisible(column)) {
                      <label
                        class="form-check-label small fw-bold d-flex mb-1"
                        (click)="openGroup(column.name + i)"
                      >
                        <span class="text-truncate" [title]="column.name">
                          {{ column.name }}
                        </span>
                        <fa-icon [icon]="caretGroup(column.name + i)" class="ms-auto"></fa-icon>
                      </label>
                      @if (showChildColumns(column.name + i)) {
                        <div class="ms-3">
                          @for (child of column.columns; track child) {
                            @if (columnIsVisible(child)) {
                              <div class="form-check">
                                <input
                                  [id]="'svColGroup_' + child.name"
                                  type="checkbox"
                                  class="form-check-input"
                                  [(ngModel)]="child.enabled"
                                  [disabled]="!metadata.enabled"
                                  (ngModelChange)="
                                    optionChanged(metadataColumnObject(child.name, $event))
                                  "
                                />
                                <label
                                  [for]="'svColGroup_' + child.name"
                                  class="form-check-label d-block text-truncate"
                                >
                                  <small>{{ child.label }}</small>
                                </label>
                              </div>
                            }
                          }
                        </div>
                      }
                    }
                    @if (!column.columns && columnIsVisible(column)) {
                      <div class="form-check">
                        <input
                          [id]="'svCol_' + column.name"
                          class="form-check-input"
                          type="checkbox"
                          [(ngModel)]="column.enabled"
                          [disabled]="!metadata.enabled"
                          (ngModelChange)="optionChanged(metadataColumnObject(column.name, $event))"
                        />
                        <label
                          [for]="'svCol_' + column.name"
                          class="form-check-label d-block text-truncate"
                        >
                          <small>{{ column.label }}</small>
                        </label>
                      </div>
                      @if (column.label.toLowerCase() === 'liability score') {
                        <div
                          ngbTooltip="The bad score threshold can not be greater than the good score threshold."
                          triggers="manual"
                          #t="ngbTooltip"
                          class="d-flex flex-nowrap"
                        >
                          <div
                            class="col-1 rounded-1 my-1"
                            [style.background-color]="liabilityColors.red"
                          ></div>
                          <input
                            class="form-control form-control-sm col mx-1"
                            required
                            type="number"
                            [(ngModel)]="liabilityScoreLow"
                            (ngModelChange)="validateLiabilityScore(t)"
                            min="-10000"
                            [max]="liabilityScoreHigh"
                            step="10"
                            title="Bad score threshold"
                          />
                          <div
                            class="col-1 rounded-1 my-1"
                            [style.background-color]="liabilityColors.yellow"
                          ></div>
                          <input
                            class="form-control form-control-sm col mx-1"
                            required
                            type="number"
                            [(ngModel)]="liabilityScoreHigh"
                            (ngModelChange)="validateLiabilityScore(t)"
                            [min]="liabilityScoreLow"
                            max="10000"
                            step="10"
                            title="Good score threshold"
                          />
                          <div
                            class="col-1 rounded-1 my-1"
                            [style.background-color]="liabilityColors.green"
                          ></div>
                        </div>
                      }
                    }
                  </div>
                }
              }
            </ng-template>
          </div>
        </div>
      </div>
    }

    @if (circularModeEnabled) {
      <div
        ngbAccordionItem
        (shown)="openAccordion('panel-view')"
        (hidden)="closeAccordion('panel-view')"
        id="panel-view"
      >
        <div ngbAccordionHeader>
          <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
            <small>View</small>
            <fa-icon class="ms-auto" [icon]="caretAccordion('panel-view')"></fa-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div role="region" ngbAccordionBody>
            <ng-template>
              <div class="form-check mb-0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  aria-label="Linear View"
                  [(ngModel)]="linearView"
                  [disabled]="numSequences > 1 || topology === 'linear' || isInEditingMode"
                  (ngModelChange)="optionChanged({ circular: !$event })"
                />
                <label class="form-check-label small"> Linear View </label>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    }

    <div
      ngbAccordionItem
      (shown)="openAccordion('panel-profiles')"
      (hidden)="closeAccordion('panel-profiles')"
      id="panel-profiles"
    >
      <div ngbAccordionHeader>
        <button type="button" [ngClass]="panelButtonClasses" ngbAccordionToggle>
          <small>Preferences</small>
          <fa-icon class="ms-auto small" [icon]="caretAccordion('panel-profiles')"></fa-icon>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div role="region" ngbAccordionBody>
          <ng-template>
            <button
              type="button"
              class="btn btn-sm btn-outline-primary"
              (click)="optionChanged(clearPreferencesObject($event.shiftKey))"
            >
              Reset to Defaults
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</bx-collapsible-panel>
