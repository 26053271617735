@if (folder$ | async; as folder) {
  @if (folder) {
    <bx-breadcrumb [folder]="folder"></bx-breadcrumb>
  }
  @if (folder) {
    <bx-files-table
      class="flex-grow-1 flex-shrink-1"
      [folder]="folder"
      [tableType]="tableType"
      [selectRowIDs]="rowIDsToSelect$ | async"
      (selectRowIDsChanged)="onSelectRowIDsChanged($event)"
    ></bx-files-table>
  }
}
