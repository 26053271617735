import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilesTableEffects } from './files-table-store/files-table.effects';
import { filesTableReducer } from './files-table-store/files-table.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('filesTable', filesTableReducer),
    EffectsModule.forFeature([FilesTableEffects]),
  ],
})
export class FilesTableModule {}
