import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { RestoreStateError } from '../../document-table-service/document-table-state/document-table-state';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-ngs-table-restoring-overlay',
  templateUrl: './ngs-table-restoring-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, NgbProgressbar],
})
export class NgsTableRestoringOverlayComponent {
  @HostBinding('class') readonly hostClass = 'd-block w-100 h-100';
  @Input() restoreProgress: RestoreProgress;
}

export interface RestoreProgress {
  progress?: number;
  error?: RestoreStateError;
}
