import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { Point2D } from '../interfaces';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgTemplateOutlet],
})
export class LogoComponent implements OnInit {
  @Input() logo?: string;
  @Input() size = 1;
  @Input() fixedAt?: Point2D;
  @Input() href?: string;
  @Input() centered?: boolean;
  @Input() width?: string;

  path: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  ngOnInit() {
    if (!this.logo) {
      const isBiologics = this.config.geneiousProduct !== 'prime';
      const name = isBiologics ? 'geneious-biologics.png' : 'geneious-logo.svg';
      this.path = `../../assets/branding/${name}`;
      return;
    }

    this.path = `../../assets/branding/${this.logo}`;
  }
}
