import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';

/**
 * Displays form controls for adjusting the start & end positions of FR annotations.
 * To be used in annotator pipeline dialogs. Must be nested under a form group
 * containing these controls\*, each a `FormControl<number>`:
 *
 * - `sequences_HeavyFR1Start`
 * - `sequences_HeavyFR1End`
 * - `sequences_HeavyFR2Start`
 * - `sequences_HeavyFR2End`
 * - `sequences_HeavyFR3Start`
 * - `sequences_HeavyFR3End`
 * - `sequences_HeavyFR4Start`
 * - `sequences_HeavyFR4End`
 * - `sequences_LightFR1Start`
 * - `sequences_LightFR1End`
 * - `sequences_LightFR2Start`
 * - `sequences_LightFR2End`
 * - `sequences_LightFR3Start`
 * - `sequences_LightFR3End`
 * - `sequences_LightFR4Start`
 * - `sequences_LightFR4End`
 *
 * _\*(assuming a {@link formControlNamePrefix} of `sequences_`)_
 */
@Component({
  selector: 'bx-fr-adjustment-options',
  templateUrl: './fr-adjustment-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgFormControlValidatorDirective, NgbTooltipModule, ReactiveFormsModule],
})
export class FRAdjustmentOptionsComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  /**
   * The prefix for all form control names.
   */
  @Input() formControlNamePrefix: 'sequences_' | 'antibodyAnnotator_sequences_' = 'sequences_';
  /**
   * The form group that the controls are in
   */
  form: FormGroup<unknown>;

  constructor(private readonly formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.form = this.formGroupDirective.control;
  }
}
