@if (!message && datasource) {
  <bx-toolstrip class="toolbar">
    <bx-toolstrip-item>
      <div class="btn-group" ngbDropdown>
        <button
          id="button-basic"
          ngbDropdownToggle
          type="button"
          class="btn btn-light dropdown-toggle"
          aria-controls="dropdown-basic"
        >
          Export <span class="caret"></span>
        </button>
        <ul
          id="dropdown-basic"
          ngbDropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li
            role="menuitem"
            ngbTooltip="'Export this graph as a png image'"
            container="body"
            placement="right"
          >
            <button ngbDropdownItem (click)="exportGraph()">
              <span>Image (png)</span>
            </button>
          </li>
          <li
            role="menuitem"
            ngbTooltip="Export this graph as a csv"
            container="body"
            placement="right"
          >
            <button ngbDropdownItem (click)="exportGraphAsTable()">Table (csv)</button>
          </li>
        </ul>
      </div>
    </bx-toolstrip-item>
  </bx-toolstrip>
  <bx-chart-presenter [datasource]="datasource"> </bx-chart-presenter>
} @else {
  <bx-page-message>
    @if (message) {
      <span>
        {{ message }}
      </span>
    } @else {
      <bx-loading></bx-loading>
    }
  </bx-page-message>
}
