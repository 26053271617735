<button
  class="btn btn-primary rounded-circle"
  [ngbPopover]="popContent"
  [animation]="false"
  autoClose="outside"
  [disabled]="disabled"
  container="body"
  popoverClass="chips-add-form"
  type="button"
  data-testid="add-chip-button"
  aria-label="Add"
>
  <fa-icon [icon]="faPlus" class="align-middle"></fa-icon>
</button>

<ng-template #popContent>
  <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
</ng-template>
