import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chip, ChipsMap } from './index';
import { arrayToMap } from '../../../bx-common-extensions/array';
import { map } from 'rxjs/operators';

@Injectable()
export class ChipsService<T = undefined> {
  chipsMap$: Observable<ChipsMap<T>>;
  chips$: Observable<Chip<T>[]>;
  readonlyChips$ = new BehaviorSubject<Chip<T>[]>([]);

  private state$ = new BehaviorSubject<{ [id: string]: Chip<T> }>({});

  constructor() {
    this.chipsMap$ = this.state$.asObservable();
    this.chips$ = this.chipsMap$.pipe(map((state) => Object.keys(state).map((key) => state[key])));
  }

  addChips(newChips: Chip<T>[]) {
    this.state$.next({
      ...this.state$.getValue(),
      ...arrayToMap(newChips, 'id'),
    });
  }

  removeChip(id: string) {
    const { [id]: removedChip, ...chips } = this.state$.getValue();
    this.state$.next(chips);
  }

  setChips(chips: Chip<T>[]) {
    this.state$.next({
      ...arrayToMap(this.filterNonReadonlyChips(chips), 'id'),
    });
  }

  setReadonlyChips(chips: Chip<T>[]) {
    this.readonlyChips$.next(chips);
    this.setChips(Object.values(this.state$.value));
  }

  private filterNonReadonlyChips(chips: Chip<T>[]) {
    const readonlyChips = new Set(this.readonlyChips$.value.map((chip) => chip.id));
    return chips.filter((chip) => !readonlyChips.has(chip.id));
  }
}
