import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { AppState } from '../core.store';
import { Store } from '@ngrx/store';
import { selectIsAuthenticatedAfterVerification } from './auth.selectors';

/**
 * Prevent the current user from accessing external pages when they're logged in.
 */
@Injectable({
  providedIn: 'root',
})
export class ExternalAuthGuard {
  constructor(
    private readonly store: Store<AppState>,
    private readonly navigationService: NavigationService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.store.pipe(selectIsAuthenticatedAfterVerification).pipe(
      first(),
      switchMap((authenticated) =>
        authenticated ? this.navigationService.getHomeUrlTree() : of(true),
      ),
    );
  }
}
