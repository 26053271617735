import { Component, computed, effect, Inject, OnDestroy, OnInit, Signal } from '@angular/core';
import {
  FormControl,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SequenceData } from '@geneious/sequence-viewer/types';
import { Observable, of as observableOf, Subscription, ReplaySubject } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import {
  AlignmentAlgorithms,
  AlignmentClusterTypes,
  AlignmentJobOptionsV2,
  AlignmentJobParametersV2,
  AlignmentSequenceOptions,
  ChainNameQualifierName,
  ChainQualifierName,
  Region,
  RepresentDuplicatesBy,
  TreeBuilderAlgorithms,
} from '../../../../nucleus/services/models/alignmentOptions.model';
import { ExtractSequencesDialogOptionsV3 } from '../../../../nucleus/services/models/extractSequencesV3.model';
import { SelectionStateV2 } from '../../../features/grid/grid.component';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { geneticCodes } from '../../geneticCodes.constant';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import {
  isAlignmentSupportedComparisonTable,
  isAllSequencesTable,
  isChainCombinationsTable,
  isClusterTable,
  isExactClusterTable,
  isInexactClusterTable,
} from '../../ngs/table-type-filters';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SequenceAlphabet } from '../../sequence-alphabet.model';
import { SequenceUtils } from '../../sequence-utils';
import {
  AnnotationNameAndCount,
  SequenceSelectionService,
} from '../../sequence-viewer/sequence-selection.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  AlternativeStartEnum,
  considerStartOptions,
} from '../motif-annotator/motif-annotator.component';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { getRegionOptionLabel } from './alignment-helper';
import { FieldMapperAddFormComponent } from 'src/app/shared/field-mappers/field-mapper-add-form/field-mapper-add-form.component';
import {
  FieldMapper,
  FieldMatchKind,
  FieldReducerKind,
} from 'src/app/shared/field-mappers/field-mapper.model';
import { Chip } from 'src/app/shared/chips';
import { FieldMapperService } from 'src/app/shared/field-mappers/field-mapper.service';
import { SequenceViewerMetadataService } from '../../sequence-viewer/sequence-viewer-metadata.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { ChipsComponent } from '../../../shared/chips/chips.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';
import { SelectedRegionsOrderDisplayPipe } from './selected-regions-order-display.pipe';
import { toSignal } from '@angular/core/rxjs-interop';

export interface AlignmentDialogVariables {
  getSequences?: () => Observable<SequenceData[]>;
  selectedTable?: DocumentTable;
  documentTableSelection?: SelectionStateV2;
  extraction?: ExtractSequencesDialogOptionsV3;
  sequenceMetadataOrder?: string[];
  alignmentSequenceOptions: AlignmentSequenceOptions;
}

type AlignmentFormValue = ReturnType<AlignmentComponent['form']['getRawValue']>;

@Component({
  selector: 'bx-alignment',
  templateUrl: './alignment.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgClass,
    FormErrorsComponent,
    NgbTooltip,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    SpinnerComponent,
    CollapsibleCardComponent,
    ChipsComponent,
    PipelineOutputComponent,
    AsyncPipe,
    SelectedRegionsOrderDisplayPipe,
  ],
})
export class AlignmentComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog, OnDestroy
{
  earlyRelease: false;
  title = 'Alignment';
  message = '';
  knowledgeBaseArticle: string;

  readonly treeAlgorithmValid: ValidatorFn = (ctrl) =>
    PipelineFormControlValidatorsService.treeAlgorithmValid(this.totalSequencesSelected)(ctrl);

  readonly thresholdPercentageValid: ValidatorFn = ({ value }) =>
    value < 0 || value > 100
      ? { badPercentage: 'Threshold frequency must be between 0 and 100' }
      : null;

  readonly form = new BxFormGroup({
    resultName: JobDialogContent.getResultNameControl(),
    outputFolderName: JobDialogContent.getResultNameControl(),
    alignmentAlgorithm: new BxFormControl(AlignmentAlgorithms.MUSCLE, Validators.required),
    buildTree: new BxFormControl(true, Validators.required),
    treeAlgorithm: new BxFormControl(TreeBuilderAlgorithms.RAXML, [
      Validators.required,
      this.treeAlgorithmValid,
    ]),
    regionsToAlign: new BxFormGroup({
      regions: new FormControl<AnnotationNameAndCount[]>([]),
    }),
    translateFirst: new BxFormControl(true),
    translation: new BxFormGroup({
      frame: new BxFormControl<1 | 2 | 3>(1),
      geneticCode: new BxFormControl('Standard'),
      considerStart: new BxFormControl(AlternativeStartEnum.AUTO),
    }),
    alignEntireSequence: new BxFormControl(false),
    clusterOptions: new BxFormGroup({
      representClusterBy: new BxFormControl<AlignmentClusterTypes>(undefined),
      thresholdByValue: new BxFormControl(10, [Validators.required, Validators.min(0)]),
    }),
    combineDuplicateSequences: new BxFormControl(true),
    representDuplicatesBy: new BxFormControl(RepresentDuplicatesBy.HIGHEST_LIABILITY_SCORE),
    fieldMappers: new BxFormControl<Chip<FieldMapper>[]>([]),
  });
  readonly selectedRegionsToAlign: Signal<AnnotationNameAndCount[]> = toSignal(
    currentValueAndChanges(this.form.controls.regionsToAlign.controls.regions, this.ngUnsubscribe),
  );
  /** If we are aligning cluster table rows there are slightly different options. */
  readonly isAlignClusters: boolean;
  readonly supportsCombineDuplicateSequences: boolean;
  readonly totalSequencesSelected: number;
  readonly isNucleotideAlignment: Signal<boolean>;
  readonly clusterAlignOptions: { label: string; key: AlignmentClusterTypes }[];
  readonly showClusterThresholdOption: boolean;
  readonly tooltips = {
    regionSelectorDisabled: 'Disable "Align entire sequence" under Advanced to select regions',
    translationDisabled:
      'Enable "Translate nucleotides before aligning" to set translation options',
    translationFrameDisabled: 'Enable "Align entire sequence" to select a translation frame',
    fieldMappersDisabledCombineDuplicates:
      'Enable "Combine duplicate sequences" to set metadata field options',
    fieldMappersDisabledNoMetadata: 'No metadata fields on selected sequences',
  };
  readonly geneticCodes = geneticCodes;
  readonly considerStartOptions = considerStartOptions;
  readonly fieldMapperPopoverComponent = FieldMapperAddFormComponent;
  readonly representClusterByControl =
    this.form.controls.clusterOptions.controls.representClusterBy;
  readonly thresholdByValueControl = this.form.controls.clusterOptions.controls.thresholdByValue;

  private readonly annotatedRegions: Signal<AnnotationNameAndCount[]>;
  annotateRegionsOptions: Signal<SelectGroup<AnnotationNameAndCount>[]>;
  loadingRegions: Signal<boolean>;
  isTranslateEnabled: Signal<boolean>;
  isClusterFrequency: Signal<boolean>;
  showAlignedPerClusterWarning: Signal<boolean>;
  translationFrameDisabledMsg: Signal<string | null>;
  fieldMappersDisabledMsg: Signal<string | null>;
  fieldMapperData: Signal<{ fields: string[] }>;
  disableAlignRegionTooltip$ = new ReplaySubject<boolean>(1);
  alignRegionTooltip$ = new ReplaySubject<string>(1);

  private readonly formDefaults: AlignmentFormValue;
  private readonly selectedDocuments: Record<string, unknown>[];
  private readonly documentTableSelection?: SelectionStateV2;
  private readonly otherVariables: AlignmentDialogVariables;
  private subscriptions = new Subscription();

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private readonly dialogData: PipelineDialogData<AlignmentDialogVariables>,
    private readonly sequenceSelectionService: SequenceSelectionService,
    private readonly fieldMapperService: FieldMapperService,
    private readonly svMetadataService: SequenceViewerMetadataService,
  ) {
    super('alignment', PipelineFormID.ALIGNMENT);

    // Set field mapper value before the form defaults are saved
    const defaultFieldMapperChips: Chip<FieldMapper>[] = [
      ['BX_Heavy V Gene', FieldReducerKind.MODE] as const,
      ['BX_Light V Gene', FieldReducerKind.MODE] as const,
      ['BX_Heavy CDR3', FieldReducerKind.MODE] as const,
      ['BX_Score', FieldReducerKind.MEAN] as const,
    ].map(([fieldCode, reducerKind]) => {
      const mapper: FieldMapper = {
        matchers: [{ matchKind: FieldMatchKind.CODE_EQUALS, pattern: fieldCode }],
        reducer: { reducerKind },
      };
      const label = this.fieldMapperService.getLabel(mapper);
      return { id: label, value: mapper, label };
    });
    this.form.controls.fieldMappers.setValue(defaultFieldMapperChips);

    this.formDefaults = this.form.getRawValue();
    this.selectedDocuments = this.dialogData.selected.selectedRows;
    this.documentTableSelection = this.dialogData.otherVariables.documentTableSelection;
    this.otherVariables = this.dialogData.otherVariables;

    const table = this.otherVariables.selectedTable;
    this.isAlignClusters =
      table && (isClusterTable(table) || isAlignmentSupportedComparisonTable(table));

    this.supportsCombineDuplicateSequences =
      !table || isAllSequencesTable(table) || isChainCombinationsTable(table);

    this.totalSequencesSelected = this.documentTableSelection
      ? this.documentTableSelection.totalSelected
      : this.selectedDocuments.reduce((acc, row) => {
          acc += Number(row.number_of_sequences) || 1;
          return acc;
        }, 0);

    if (this.totalSequencesSelected > 1000) {
      this.form.controls.combineDuplicateSequences.setValue(true);
      this.form.controls.combineDuplicateSequences.disable();
    }

    this.clusterAlignOptions = this.getClusterAlignOptions();
    this.showClusterThresholdOption = this.clusterAlignOptions.some(
      ({ key }) =>
        key === AlignmentClusterTypes.THRESHOLD_COUNT ||
        key === AlignmentClusterTypes.THRESHOLD_PERCENTAGE,
    );

    const sequenceData = toSignal(this.retrieveSequenceData().pipe(share()));

    this.fieldMapperData = computed(() => {
      const sequences = sequenceData();
      const fieldSet = new Set<string>();
      for (const sequence of sequences) {
        for (const field in sequence.metadata) {
          if (!this.svMetadataService.HIDDEN_METADATA_FIELDS[field]) {
            fieldSet.add(field.startsWith('BX_') ? field : 'BX_' + field);
          }
        }
      }
      return { fields: Array.from(fieldSet).sort() };
    });

    this.annotatedRegions = computed(() => {
      const sequences = sequenceData();
      return SequenceSelectionService.getAllAnnotationNamesForRegionChooser(sequences, null, [
        'gene',
        'Position',
      ]);
    });

    this.annotateRegionsOptions = computed(() => {
      const regions = this.annotatedRegions();

      const options = regions.reduce((acc, curr) => {
        const option = new SelectOption(getRegionOptionLabel(curr), curr);
        const currentChainName = curr.chainName ?? curr.chain ?? 'Unknown';
        const chainIndex = acc.findIndex(({ label }) => label === currentChainName);
        if (chainIndex === -1) {
          acc.push(new SelectGroup([option], currentChainName));
        } else {
          acc[chainIndex].options.push(option);
        }
        return acc;
      }, [] as SelectGroup<AnnotationNameAndCount>[]);

      // Remove unknown options
      const unknownRegionIndex = options.findIndex(({ label }) => label === 'Unknown');
      delete options[unknownRegionIndex]?.label;
      return options;
    });

    this.loadingRegions = computed(() => {
      const regions = this.annotatedRegions();
      return !Array.isArray(regions);
    });

    // Set initial option when list of annotated regions changed.
    effect(() => {
      const regions = this.annotatedRegions();
      if (regions.length > 0) {
        this.form.controls.regionsToAlign.controls.regions.setValue([regions[0]]);
      }
    });

    const alignEntireSequenceControl = this.form.controls.alignEntireSequence;
    const isAlignEntireSequence = toSignal(
      currentValueAndChanges(alignEntireSequenceControl, this.ngUnsubscribe),
    );

    const representClusterBy = toSignal(
      currentValueAndChanges(this.representClusterByControl, this.ngUnsubscribe),
    );

    this.showAlignedPerClusterWarning = computed(() => {
      const representClusterType = representClusterBy();
      return (
        representClusterType === AlignmentClusterTypes.THRESHOLD_COUNT ||
        representClusterType === AlignmentClusterTypes.THRESHOLD_PERCENTAGE ||
        representClusterType === AlignmentClusterTypes.ALL
      );
    });

    this.isClusterFrequency = computed(() => {
      const representClusterType = representClusterBy();
      return representClusterType === AlignmentClusterTypes.THRESHOLD_PERCENTAGE;
    });

    this.isNucleotideAlignment = computed(() => {
      const representClusterType = representClusterBy();
      const aligningAllSequences =
        this.otherVariables.selectedTable && isAllSequencesTable(this.otherVariables.selectedTable);
      return aligningAllSequences ||
        representClusterType === AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE
        ? this.checkNucleotidesOnAllSequenceTable()
        : this.checkNucleotidesOnSelection();
    });

    const isCombineDuplicateSequences = toSignal(
      currentValueAndChanges(this.form.controls.combineDuplicateSequences, this.ngUnsubscribe),
    );
    this.fieldMappersDisabledMsg = computed(() => {
      const fields = this.fieldMapperData().fields;
      if (fields.length === 0) {
        return this.tooltips.fieldMappersDisabledNoMetadata;
      }

      if (!isCombineDuplicateSequences()) {
        return this.tooltips.fieldMappersDisabledCombineDuplicates;
      }

      return null;
    });

    this.isTranslateEnabled = toSignal(
      currentValueAndChanges(this.form.controls.translateFirst, this.ngUnsubscribe),
    );

    this.translationFrameDisabledMsg = computed(() => {
      const translate = this.isTranslateEnabled();
      const alignEntireSequence = isAlignEntireSequence();
      if (!translate && !this.isAlignClusters) {
        return this.tooltips.translationDisabled;
      }
      if (!alignEntireSequence && !this.isAlignClusters) {
        return this.tooltips.translationFrameDisabled;
      }
      return null;
    });

    const isBuildTreeEnabled = toSignal(
      currentValueAndChanges(this.form.controls.buildTree, this.ngUnsubscribe),
    );

    // Disable field mappings if combine duplicate sequences is disabled or if there is no metadata
    effect(() => {
      if (this.fieldMappersDisabledMsg() === null) {
        this.form.controls.fieldMappers.enable();
      } else {
        this.form.controls.fieldMappers.disable();
      }
      this.form.controls.fieldMappers.updateValueAndValidity();
    });

    // Set translation to true by default if user select representative sequence alignment.
    effect(() => {
      const translationControl = this.form.controls.translateFirst;
      const isTranslationEnabled = translationControl.value;
      if (
        representClusterBy() === AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE &&
        !isTranslationEnabled
      ) {
        translationControl.setValue(true);
      }
    });

    // If Align by regions is true, then a region selection should be required.
    const regionsControl = this.form.controls.regionsToAlign.controls.regions;
    effect(() => {
      const regions = this.annotatedRegions();
      const alignEntireSequence = isAlignEntireSequence();
      const representClusterType = representClusterBy();

      if (
        !alignEntireSequence &&
        ((this.isAlignClusters &&
          representClusterType === AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE) ||
          !this.isAlignClusters)
      ) {
        regionsControl.setValidators((ctrl) => {
          if (regions.length === 0) {
            return {
              noRegionAnnotations:
                'Region annotations must be present on all selected sequences - no valid annotations found',
            };
          }
          if (!ctrl.value?.length) {
            return { noRegionsSelected: 'Select at least one region to align' };
          }
          return null;
        });
        regionsControl.enable();
        this.disableAlignRegionTooltip$.next(true);
      } else {
        regionsControl.disable();
        this.disableAlignRegionTooltip$.next(false);
        if (
          this.isAlignClusters &&
          representClusterType !== AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE
        ) {
          this.alignRegionTooltip$.next(
            'Only applicable when representing cluster by highest liability score',
          );
        } else {
          this.alignRegionTooltip$.next(this.tooltips.regionSelectorDisabled);
        }
      }
    });

    // Set validators for threshold by value control.
    effect(() => {
      const clusterBy = representClusterBy();

      let validators = null;
      if (clusterBy === AlignmentClusterTypes.THRESHOLD_PERCENTAGE) {
        validators = [Validators.required, this.thresholdPercentageValid];
      } else if (clusterBy === AlignmentClusterTypes.THRESHOLD_COUNT) {
        validators = [Validators.required, Validators.min(0)];
      }

      if (validators === null) {
        this.thresholdByValueControl.disable();
      } else {
        this.thresholdByValueControl.enable();
        this.thresholdByValueControl.setValidators(validators);
        this.thresholdByValueControl.updateValueAndValidity();
      }
    });

    // Only enable translation options for nucleotide sequences
    effect(() => {
      const translationFormGroup = this.form.controls.translation;
      const isNucleotideAlignment = this.isNucleotideAlignment();

      if (isNucleotideAlignment) {
        const translate = this.isTranslateEnabled();
        const alignEntireSequence = isAlignEntireSequence();

        if (!translate) {
          translationFormGroup.disable();
          return;
        }
        translationFormGroup.controls.considerStart.enable();
        translationFormGroup.controls.geneticCode.enable();
        if (alignEntireSequence) {
          translationFormGroup.controls.frame.enable();
        } else {
          translationFormGroup.controls.frame.disable();
        }

        this.form.controls.translateFirst.enable();
      } else {
        this.form.controls.translateFirst.setValue(false);
        this.form.controls.translateFirst.disable();
        translationFormGroup.disable();
      }
    });

    // Enable treeAlgorithm only if buildTree is enabled
    effect(() => {
      if (isBuildTreeEnabled()) {
        this.form.controls.treeAlgorithm.enable();
      } else {
        this.form.controls.treeAlgorithm.disable();
      }
    });

    // Enable representDuplicateBy only if combineDuplicateSequences is enabled
    effect(() => {
      if (isCombineDuplicateSequences()) {
        this.form.controls.representDuplicatesBy.enable();
      } else {
        this.form.controls.representDuplicatesBy.disable();
      }
    });
  }

  ngOnInit() {
    // The field mapper control needs to be disabled until the data is ready
    this.form.controls.fieldMappers.disable();

    // Validate saved field mappers against the fields on this document
    const fieldMapperChips = this.form.controls.fieldMappers.value;
    if (fieldMapperChips?.length) {
      const fields = this.fieldMapperData().fields;
      const validatedChips = fieldMapperChips
        .filter((chip) =>
          chip.value.matchers.every(
            (matcher) =>
              matcher.matchKind !== FieldMatchKind.CODE_EQUALS || fields.includes(matcher.pattern),
          ),
        )
        .map(({ value }) => {
          // Regenerate label in case the logic has changed since it was saved
          const label = this.fieldMapperService.getLabel(value);
          return { id: label, value, label };
        });
      this.form.controls.fieldMappers.setValue(validatedChips);
    }

    // Set initial value depending on whether aligning clusters or not.
    // This prevents previously saved dialog options from an non-aligned cluster being used on aligned cluster.
    const alignEntireSequenceControl = this.form.controls.alignEntireSequence;
    if (this.isAlignClusters) {
      alignEntireSequenceControl.setValue(false);
      alignEntireSequenceControl.disable();
    }

    if (this.isAlignClusters) {
      this.representClusterByControl.setValue(this.clusterAlignOptions[0]?.key);
    } else {
      this.representClusterByControl.disable();
      this.thresholdByValueControl.disable();
    }

    // Set message based on what type of file/table input is selected.
    if (this.isAlignClusters) {
      if (isInexactClusterTable(this.otherVariables.selectedTable)) {
        this.message =
          'Align extracted sequences for selected clusters. The alignment will contain translated cluster regions.';
      } else {
        // TODO Update when we support alignments by threshold in similarity tables.
        this.message =
          'The alignment will contain unique translations representing the selected cluster regions.';
      }
    }

    // Set values for large selections
    if (this.totalSequencesSelected > 1000) {
      // MAFFT is faster at aligning large numbers of sequences.
      this.form.controls.alignmentAlgorithm.setValue(AlignmentAlgorithms.MAFFT);
      if (this.supportsCombineDuplicateSequences) {
        // to improve performance, combineDuplicateSequences is forced to be enabled for large numbers of sequences
        this.form.controls.combineDuplicateSequences.setValue(true);
        this.form.controls.combineDuplicateSequences.disable();
      }
    } else {
      this.form.controls.alignmentAlgorithm.setValue(AlignmentAlgorithms.MUSCLE);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }

  private checkNucleotidesOnAllSequenceTable() {
    return (this.selectedDocuments[0]?.metadata as any).molType == 'DNA';
  }

  private checkNucleotidesOnSelection() {
    return this.documentTableSelection
      ? this.checkNucleotidesOnTableSelection()
      : this.checkNucleotidesOnDocumentSelection();
  }

  private checkNucleotidesOnDocumentSelection() {
    return SequenceUtils.isSelectionOfType(
      SequenceAlphabet.NUCLEOTIDE,
      this.selectedDocuments as any[],
    );
  }

  private checkNucleotidesOnTableSelection() {
    if (this.otherVariables.selectedTable?.metadata.clusters?.containsNucleotides !== undefined) {
      //read from cluster table meta data
      return this.otherVariables.selectedTable?.metadata.clusters?.containsNucleotides;
    } else {
      console.log(
        'cant decide sequence type by cluster metadata therefore assume its nucleotides for being on the safe side',
      );
      return true; //fallback to the safe option
    }
  }

  /**
   * Convert the value from the Angular form to what the server expects.
   * Remove any Angular idiosyncrasies (for example that all values are set even when that doesn't
   * make sense.
   *
   * Note: When aligning cluster regions, all of these options will be ignored by the pipeline,
   * EXCEPT for `buildTree` and `extraction`.
   */
  private prepareJobOptions(): AlignmentJobOptionsV2 {
    const alignmentForm = this.form.getRawValue();
    const result: AlignmentJobOptionsV2 = {
      alignmentAlgorithm: alignmentForm.alignmentAlgorithm,
      resultName: alignmentForm.resultName,
    };

    if (this.supportsCombineDuplicateSequences) {
      result.combineDuplicateSequences = alignmentForm.combineDuplicateSequences;
      if (result.combineDuplicateSequences) {
        result.representDuplicatesBy = alignmentForm.representDuplicatesBy;
      }
    }

    if (alignmentForm.translateFirst) {
      result.translation = {
        geneticCode: alignmentForm.translation.geneticCode,
        useAlternativeStartCodon: alignmentForm.translation.considerStart,
      };
    }

    if (alignmentForm.buildTree) {
      result.treeAlgorithm = alignmentForm.treeAlgorithm;
    }

    if (this.isAlignClusters) {
      result.clusterOptions = {
        thresholdByValue: alignmentForm.clusterOptions.thresholdByValue || 0,
        representClusterBy: alignmentForm.clusterOptions.representClusterBy,
      };
      if (
        !alignmentForm.alignEntireSequence &&
        alignmentForm.clusterOptions.representClusterBy ===
          AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE &&
        alignmentForm.regionsToAlign?.regions
      ) {
        result.regions = this.createRegionsFromSelectedRegions(
          alignmentForm.regionsToAlign.regions,
        );
      }
    } else if (!alignmentForm.alignEntireSequence && alignmentForm.regionsToAlign?.regions) {
      //for all sequence table
      result.regions = this.createRegionsFromSelectedRegions(alignmentForm.regionsToAlign.regions);
    }

    if (result.regions == null && alignmentForm.translateFirst) {
      // Note that frames in the UI should be 1-3 but our backend expects 0-2 as a number (importantly not a string).
      result.translation.frame = alignmentForm.translation.frame - 1;
    }

    if (this.otherVariables?.extraction) {
      result.extraction = this.otherVariables.extraction;
    }
    if (this.otherVariables?.sequenceMetadataOrder) {
      const combinedSequenceFields =
        this.svMetadataService.ALIGNMENT_COMBINED_SEQUENCES_METADATA.map((key) =>
          key.replace('BX_', ''),
        );
      result.sequenceMetadataOrder = combinedSequenceFields
        .concat(
          this.svMetadataService.ALIGNMENT_REPRESENTATIVE_SEQUENCES_METADATA.map((key) =>
            key.replace('BX_', ''),
          ),
        )
        .concat(this.otherVariables.sequenceMetadataOrder);
    }

    if (result.combineDuplicateSequences && alignmentForm.fieldMappers?.length) {
      result.fieldMappers = alignmentForm.fieldMappers.map((chip) => chip.value);
    }

    return result;
  }

  retrieveSequenceData(): Observable<SequenceData[]> {
    return observableOf(this.otherVariables.getSequences).pipe(
      switchMap((getSequences) => {
        if (getSequences) {
          return getSequences();
        } else {
          // Limit the amount of sequence data queries made - we only need a representative list of CDS annotations.
          // TODO Check CDS annotations in sequence lists, when we have a concrete use case for this.

          const filterFunc = (item: Record<string, unknown>) =>
            item.type === 'sequence' || item.type === 'sequenceList';

          // Note this means the code is going to iterate through the entire sequence list to look for annotations.
          // If there is a use case for aligning millions of sequences then we may need to change this.
          return this.sequenceSelectionService.fetchSequenceData(
            this.selectedDocuments,
            200,
            filterFunc,
          );
        }
      }),
    );
  }

  /**
   * Called by the pipeline chooser.
   */
  run() {
    const outputFolderName = this.form.controls.outputFolderName.value;
    const parameters = {
      options: this.prepareJobOptions(),
      selection: {
        selectAll: false,
        folderId: this.dialogData.folderID,
        ids: this.dialogData.selected.ids,
      },
      output: { outputFolderName },
    };
    return new AlignmentJobParametersV2(parameters);
  }

  override getFormDefaults() {
    this.formDefaults.clusterOptions.representClusterBy = this.clusterAlignOptions[0]?.key;
    return this.formDefaults;
  }

  /**
   * Determines the appropriate cluster representation options, based on what type of result table
   * is currently selected.
   *
   * @returns Array of Cluster alignment options.
   */
  private getClusterAlignOptions(): { label: string; key: AlignmentClusterTypes }[] {
    const table: DocumentTable = this.otherVariables.selectedTable;
    if (!table) {
      return [];
    }

    if (isInexactClusterTable(table)) {
      if (!isAlignmentSupportedComparisonTable(table)) {
        return [
          this.getMajoritySequenceOption(table.displayName),
          this.getThresholdCountOption(table.displayName),
          this.getThresholdFrequencyOption(table.displayName),
          this.getAllSequenceOption(table.displayName),
          this.getRepresentativeOption(),
        ];
      } else {
        // Currently thresholding is not supported for inexact clusters on comparison documents.
        return [
          { label: 'Majority sequence for cluster region', key: AlignmentClusterTypes.MAJORITY },
        ];
      }
    } else if (isExactClusterTable(table)) {
      return [this.getMajoritySequenceOption(table.displayName), this.getRepresentativeOption()];
    } else if (isAlignmentSupportedComparisonTable(table)) {
      return [this.getMajoritySequenceOption(table.displayName)];
    } else {
      return [];
    }
  }

  private getRepresentativeOption() {
    return {
      label: 'Representative Sequence (by Liability Score)',
      key: AlignmentClusterTypes.HIGHEST_LIABILITY_SCORE,
    };
  }

  private getThresholdCountOption(tableName: string) {
    return {
      label: 'Threshold by count - ' + tableName,
      key: AlignmentClusterTypes.THRESHOLD_COUNT,
    };
  }
  private getThresholdFrequencyOption(tableName: string) {
    return {
      label: 'Threshold by frequency % - ' + tableName,
      key: AlignmentClusterTypes.THRESHOLD_PERCENTAGE,
    };
  }
  private getAllSequenceOption(tableName: string) {
    return { label: 'All sequences - ' + tableName, key: AlignmentClusterTypes.ALL };
  }

  private getMajoritySequenceOption(tableName: string) {
    return { label: 'Majority Sequence - ' + tableName, key: AlignmentClusterTypes.MAJORITY };
  }

  private createRegionsFromSelectedRegions(selectedRegions: AnnotationNameAndCount[]): Region[] {
    return selectedRegions.map((selectedRegion: AnnotationNameAndCount) => {
      const region: Region = {
        name: selectedRegion.name,
        qualifierFilters: [],
      };

      if (selectedRegion.chain) {
        region.qualifierFilters.push({
          name: ChainQualifierName,
          value: selectedRegion.chain,
        });
      }

      if (selectedRegion.chainName) {
        region.qualifierFilters.push({
          name: ChainNameQualifierName,
          value: selectedRegion.chainName,
        });
      }

      return region;
    });
  }
}
