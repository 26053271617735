<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'jobs'], label: 'Jobs' }, { label: 'Job Details' }]"
></bx-settings-breadcrumb>
<div class="bx-page-container bx-page-container-lg">
  <h3>Job Details: {{ (pipelineName$ | async) ?? '' }}</h3>
  <bx-job-details [job]="job$ | async" [compact]="false"></bx-job-details>

  <div class="d-flex justify-content-end align-items-center my-4">
    <button
      class="btn btn-warning me-2"
      type="button"
      (click)="openJobFeedbackDialog()"
      data-spec="job-feedback-btn"
    >
      Report a problem
    </button>
    <a class="btn btn-secondary" [routerLink]="['/jobs']">Back</a>
  </div>
</div>
