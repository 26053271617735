import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { Observable } from 'rxjs';
import {
  selectSequenceViewerPreference,
  selectSequenceViewerPreferencesGlobal,
} from './sequence-viewer-preferences.selectors';
import { RemoveSvOption } from '../../../features/sequence-viewer-angular/sequence-viewer.interfaces';
import { SequenceViewerPreferencesState } from './sequence-viewer-preferences.model';
import {
  clearSequencePreferences,
  removeSequencePreferencesOption,
  upsertSequencePreferences,
} from './sequence-viewer-preferences.actions';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SequenceViewerPreferencesService {
  readonly sequenceViewerPreferencesGlobal$: Observable<SequenceViewerPreferencesState>;

  // Specifies SV prefs options that should be saved per document. Using a top level property to exclude all sub-properties is allowed.
  private perDocumentOptions: PerDocumentOptions = {
    labels: true,
    tree: { width: true },
    zoom: true,
    range: true,
    selection: true,
    metadataColumns: true,
    metadataColumnWidths: true,
    reference: true,
    sorting: { currentSort: true },
    metadata: { labelHeight: true },
  };

  constructor(private store: Store<AppState>) {
    this.sequenceViewerPreferencesGlobal$ = this.store.pipe(
      select(selectSequenceViewerPreferencesGlobal),
    );
  }

  /**
   * Save sequences selection to sequence viewer preference
   * @param target the target of the preference
   * @param sequenceIndices the selected sequence original indices
   */
  saveSequencesSelection(target: string, sequenceIndices: number[]) {
    this.store
      .select(selectSequenceViewerPreference(target))
      .pipe(
        take(1),
        map((preference) => ({
          ...preference,
          sequenceSelection: {
            selection: sequenceIndices,
          },
        })),
      )
      .subscribe((newPreference) => {
        this.store.dispatch(
          upsertSequencePreferences({ target, sequenceViewerPreferencesState: newPreference }),
        );
      });
  }

  upsertOption(payload: any, dataType: string, target: string[]) {
    // Create a server-side quality colour scheme if the selected documents include qualities.
    if (dataType === 'QUAL' && payload.sequencesPlugin && payload.sequencesPlugin.DNAColorScheme) {
      payload.sequencesPlugin.qualityColorScheme = payload.sequencesPlugin.DNAColorScheme;
    }

    if (!this.isPerDocument(payload)) {
      target = ['global'];
    }

    // TODO Add support for per-document settings for multiple document selections.
    if (target.length === 1) {
      this.store.dispatch(
        upsertSequencePreferences({
          target: target[0],
          sequenceViewerPreferencesState: payload,
        }),
      );
    }
  }

  removeOption(payload: RemoveSvOption, target: string[]) {
    // Create the object being removed to determine the target.
    const fullPath = [...payload.path];
    fullPath.push(payload.key);
    const removeObject = fullPath.reduceRight(
      (key: Object, property: string) => ({ [property]: key }),
      null,
    );

    if (!this.isPerDocument(removeObject)) {
      target = ['global'];
    }

    // TODO Add support for per-document settings for multiple document selections.
    if (target.length === 1) {
      this.store.dispatch(
        removeSequencePreferencesOption({
          target: target[0],
          removedOption: payload,
        }),
      );
    }
  }

  clearOptions(target?: string[]) {
    // Always clear global options.
    this.store.dispatch(clearSequencePreferences({ target: 'global' }));

    // TODO Add support for per-document settings for multiple document selections.
    if (target && target.length === 1) {
      this.store.dispatch(clearSequencePreferences({ target: target[0] }));
    }
  }

  private isPerDocument(payload: any): boolean {
    return (Object.keys(this.perDocumentOptions) as (keyof PerDocumentOptions)[]).some(
      (property) => {
        if (this.perDocumentOptions[property].constructor === Object) {
          return Object.keys(this.perDocumentOptions[property]).some((innerProperty) => {
            if (payload[property] && payload[property][innerProperty] !== undefined) {
              return true;
            }
          });
        } else {
          if (payload[property] !== undefined) {
            return true;
          }
        }
      },
    );
  }
}

interface PerDocumentOptions {
  labels: boolean;
  tree: { width: boolean };
  zoom: boolean;
  range: boolean;
  selection: boolean;
  metadataColumns: boolean;
  metadataColumnWidths: boolean;
  reference: boolean;
  sorting: { currentSort: boolean };
  metadata: { labelHeight: boolean };
}
