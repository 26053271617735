import { createAction, props } from '@ngrx/store';
import { FeaturesState } from './features-state';

export const loadFeatures = createAction(
  '[Features] Load Features',
  props<{ organizationID: string }>(),
);
export const loadFeaturesSuccess = createAction(
  '[Features] Load Features Success',
  props<{ features: FeaturesState }>(),
);
export const loadFeaturesFailure = createAction('[Features] Load Features Failure');
export const changeFeature = createAction(
  '[Features] Change Feature',
  props<{ code: string; enabled: boolean }>(),
);
