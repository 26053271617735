@if (overlayEvents$ | async; as overlayEvent) {
  <div class="overlay bg-white">
    @if (overlayEvent.type === 'message') {
      <div class="overlay-message text-muted">
        @if (overlayEvent?.data?.message) {
          <span>{{ overlayEvent?.data?.message }}</span>
        }
        @if (overlayEvent?.data?.html) {
          <span [innerHTML]="overlayEvent?.data?.html"></span>
        }
      </div>
    }
    @if (overlayEvent.type === 'loading') {
      <div class="loading-spinner">
        <bx-loading></bx-loading>
      </div>
    }
  </div>
}
