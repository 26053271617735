import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as _throw } from 'rxjs';
import { BxHttpError } from './BxHttpError';
import { APP_CONFIG, AppConfig } from '../app.config';
import { EditedSequences } from '../features/sequence-viewer-angular/sequence-editing-controls/sequence-editing-controls.component';
import Annotation from '@geneious/sequence-viewer/plugins/Annotations/Cache/Annotation';

export interface EditSequenceResponse {
  revision: number;
  newDocumentId: string;
}

@Injectable({
  providedIn: 'root',
})
export class GaalServerHttpService {
  private endpoint: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private httpClient: HttpClient,
  ) {
    if (!config.gaalUrl) {
      console.warn('Warning, no GAAL url was set.');
    }
    this.endpoint = `${config.gaalUrl}/api`;
  }

  /**
   * Replace all annotations on the document with these ones.
   */
  setAnnotations(documentId: string, sequenceIndexInDocument: number, annotations: Annotation[]) {
    return this.httpClient.put(
      `${this.endpoint}/${documentId}/sequences/${sequenceIndexInDocument}/annotations`,
      annotations,
    );
  }

  editSequence(
    index: number,
    body: { documentRevision: number; sequence: string },
    id: string,
  ): Observable<Object> {
    return this.httpClient
      .put(`${this.endpoint}/api/documents/${id}/sequences/${index}`, body, {
        withCredentials: true,
      })
      .pipe(
        catchError((error: BxHttpError) => {
          if (error.status === 409) {
            window.alert(
              'Your changes could not be saved. The document may have been edited since you loaded it on this device. ' +
                'Try reloading the document.',
            );
          }
          return _throw(error);
        }),
      );
  }

  editSequences(
    documentID: string,
    documentRevision: number,
    editedSequences: EditedSequences,
    folderID?: string,
  ): Observable<EditSequenceResponse> {
    const body = {
      documentRevision,
      editedSequences,
      folderID,
    };
    return this.httpClient.patch<EditSequenceResponse>(
      `${this.endpoint}/documents/${documentID}/sequences`,
      body,
      {
        withCredentials: true,
      },
    );
  }

  renameDocument(
    id: string,
    name: string,
    documentRevision: number,
  ): Observable<RenameDocumentResponse> {
    const body = {
      name: name,
      documentRevision: documentRevision,
    };

    return this.httpClient.put<RenameDocumentResponse>(
      `${this.endpoint}/documents/${id}/name`,
      body,
      { withCredentials: true },
    );
  }

  // /**
  //  * Add a single annotation to a sequence document.
  //  * We expect this to append the annotation onto the end of the annotation array but aren't yet confident.
  //  */
  // addAnnotation(sequenceIndex: number, annotation: Annotation) {
  //   const {documentId} = this.getDocumentRecord(sequenceIndex);
  //   const url = `${this.endpoint}/${documentId}/annotations`;
  //   const body = [annotation];
  //   return this.httpClient.post(url, body);
  // }
  // /**
  //  * Sets (all of) a sequences' annotations.
  //  */
  // setAnnotations(annotationId: string, allAnnotations: string[]) {
  //   const url = this.generateUrlForAnnotation(annotationId);
  //   return this.httpClient
  //     .put(url, allAnnotations);
  //   // TODO Update the store.
  // }
  //
  //
  //
  //
  //
}

export interface RenameDocumentResponse {
  id: string;
  name: string;
  documentRevision: number;
  [type: string]: any;
}
