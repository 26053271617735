import { Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  SetMergePairedReadsJobOptionsV1_1,
  SetMergePairedReadsOptionsModelV1_1,
} from '../../../../nucleus/services/models/setMergePairedReadsOptions.model';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { Observable } from 'rxjs';
import { PipelineService } from '../../pipeline/pipeline.service';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentHttpV2Service } from '../../../../nucleus/v2/document-http.v2.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { rawValueChanges, restrictControlValue } from 'src/app/shared/utils/forms';
import { CardComponent } from '../../../shared/card/card.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-pair-chains',
  templateUrl: './pair-chains.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    ShowIfDirective,
    NgbTooltip,
    MatIconModule,
    NgFormControlValidatorDirective,
    FaIconComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class PairChainsComponent extends JobDialogContent implements OnInit, RunnableJobDialog {
  earlyRelease: false;
  title = 'Pair Heavy/Light Chains';
  knowledgeBaseArticle: string;
  selectedListsCount = 0;
  selectedSingleSequencesCount = 0;
  selectedListsSequenceCounts: Array<number> = [];
  disablePairsOfLists: boolean;
  disableInterlacingSequences: boolean;
  disableName: boolean;
  // Must be null to enable.
  disableNameScheme: boolean | null = null;
  nameSchemesWithId$: Observable<SelectOption[]>;
  nameSchemeStatusMessage: string;
  nameSchemeWarningMessage: string;
  exclamationIcon = faExclamationTriangle;
  disableInterlacingSequencesMessage: string;
  disablePairsOfListsMessage: string;

  form = new BxFormGroup({
    pairBy: new BxFormControl(undefined),
    fileNameSchemeID: new BxFormControl(undefined),
    outputFolderName: JobDialogContent.getResultNameControl(),
  });

  private formDefaults: any;
  private readonly selected: SelectionState;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private pipelineService: PipelineService,
    private documentHttpV2Service: DocumentHttpV2Service,
    private readonly featureSwitchService: FeatureSwitchService,
  ) {
    super('paired-reads', PipelineFormID.PAIR_CHAINS);
    this.selected = this.dialogData.selected;
    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit() {
    // Handle disabling/enabling and validating name scheme dropdown depending on whether pair by name scheme radio is selected.
    this.form
      .get('pairBy')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value === 'byNameScheme' && this.form.enabled) {
          this.form.get('fileNameSchemeID').setValidators([Validators.required]);
          this.form.get('fileNameSchemeID').enable();
        } else {
          this.form.get('fileNameSchemeID').clearValidators();
          this.form.get('fileNameSchemeID').disable();
        }
      });

    this.nameSchemesWithId$ = this.pipelineService.externalServices.nameSchemes
      .valueSource(['id'])
      .pipe(first());

    // Process name schemes from the server to validate name schemes saved on documents and conditionally display a status message.
    this.featureSwitchService
      .isEnabledOnce('nameSchemes')
      .pipe(
        filter((isEnabled) => isEnabled),
        switchMap(() => this.nameSchemesWithId$),
      )
      .subscribe((nameSchemesWithId) => {
        const fileNameSchemeIDControl = this.form.controls.fileNameSchemeID;
        restrictControlValue(
          fileNameSchemeIDControl,
          nameSchemesWithId.map((option) => option.value),
          {
            value$: rawValueChanges(
              this.form,
              (value) => value.fileNameSchemeID,
              this.ngUnsubscribe,
            ),
          },
        );

        const nameSchemesSetOnDocuments = this.pipelineService.nameSchemesSetOnDocuments(
          this.selected,
        );
        const nameSchemesWithIdSetOnDocuments =
          this.nameSchemesWithIdSetOnDocuments(nameSchemesWithId);

        if (nameSchemesSetOnDocuments.size > 0) {
          const firstScheme = nameSchemesSetOnDocuments.values().next().value;

          // Set the saved name scheme in the dropdown if there is only 1 and it has an id classification.
          if (
            nameSchemesSetOnDocuments.size === 1 &&
            nameSchemesWithIdSetOnDocuments.has(firstScheme)
          ) {
            fileNameSchemeIDControl.reset(firstScheme);
          } else {
            fileNameSchemeIDControl.reset(null);
          }

          this.nameSchemeStatusMessage =
            'The selected document(s) already have one or more name schemes associated. Running this operation will overwrite them with the selected name scheme.';
        } else if (nameSchemesWithId.length === 0) {
          this.disableNameScheme = true;
          this.nameSchemeWarningMessage =
            'No name schemes present, please create one or talk to your organization administrator.';
          // The documents selected have no name schemes associated with them, set the last used scheme.
        }
      });

    this.selectedListsCount = this.selected.selectedRows.reduce(
      (accumulator, doc) => accumulator + (doc.type === 'sequenceList' ? 1 : 0),
      0,
    );
    this.selectedSingleSequencesCount = this.selected.selectedRows.reduce(
      (accumulator, doc) => accumulator + (doc.type === 'sequence' ? 1 : 0),
      0,
    );
    this.selected.selectedRows.forEach((doc) => {
      if (doc.type === 'sequenceList') {
        this.selectedListsSequenceCounts.push(Number(doc.number_of_sequences));
      }
    });

    // Conditionally disable individual pair by options.
    this.disablePairsOfLists = this.pairsOfListsDisabled;
    this.disableInterlacingSequences = this.interlacingSequencesDisabled;
    this.disableName = this.nameDisabled;
    this.disableNameScheme = this.nameSchemeDisabled;

    this.setPairByDefault();
  }

  /**
   * Called by the pipeline chooser.
   */
  run() {
    // @see https://stackoverflow.com/questions/40148102/angular-2-disabled-controls-do-not-get-included-in-the-form-value
    const formValue = this.form.getRawValue();

    // Handle saving the selected name scheme for the selected document(s), if required.
    if (formValue.pairBy === 'byNameScheme') {
      this.selected.selectedRows.forEach((doc) => {
        if (
          !doc.metadata.fileNameSchemeID ||
          doc.metadata.fileNameSchemeID !== formValue.fileNameSchemeID
        ) {
          this.documentHttpV2Service
            .upsertMetadata(doc.id, { fileNameSchemeID: formValue.fileNameSchemeID })
            .subscribe();
        }
      });
    }

    const outputFolderName = formValue.outputFolderName;
    const parameters = {
      options: PairChainsComponent.prepareJobOptions(formValue),
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };
    return new SetMergePairedReadsOptionsModelV1_1(parameters);
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  // @see pairsOfListsDisabled() in set-merge-paired-reads.component.ts.
  get pairsOfListsDisabled() {
    // If the whole option set has already been disabled.
    if (this.form.get('pairBy').disabled) {
      return true;
    }

    const numListsOdd = this.selectedListsCount % 2 !== 0;
    // BX doesn't parse sequence list names selected to determine pairs. Only perform sequence number validation if 2 lists are selected.
    const unequalListsSequenceCounts =
      this.selectedListsSequenceCounts.length === 2 &&
      this.selectedListsSequenceCounts[0] !== this.selectedListsSequenceCounts[1];

    if (numListsOdd || this.selectedSingleSequencesCount > 0 || unequalListsSequenceCounts) {
      this.disablePairsOfListsMessage =
        'Select only an even number of sequence lists with equal numbers of sequences to enable this option.';
      return true;
    } else {
      // Must return null to enable.
      // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
      return null;
    }
  }

  // @see interlacingSequencesDisabled() in set-merge-paired-reads.component.ts.
  get interlacingSequencesDisabled() {
    // If the whole option set has already been disabled.
    if (this.form.get('pairBy').disabled) {
      return true;
    }

    const sequenceCountInListOdd = this.selectedListsSequenceCounts.some(
      (count) => count % 2 !== 0,
    );
    if (this.selectedSingleSequencesCount > 0 || sequenceCountInListOdd) {
      this.disableInterlacingSequencesMessage =
        'Select only sequence lists with even numbers of sequences to enable this option.';
      return true;
    } else {
      // Must return null to enable.
      // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
      return null;
    }
  }

  get nameDisabled() {
    // Must return null to enable.
    // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
    return this.form.get('pairBy').disabled ? true : null;
  }

  get nameSchemeDisabled() {
    // Must return null to enable.
    // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
    return this.form.get('pairBy').disabled || this.disableNameScheme ? true : null;
  }

  // Refrain from selecting disabled pair by radios if possible, based on a priority list.
  setPairByDefault() {
    if (!this.disableNameScheme) {
      this.form.get('pairBy').setValue('byNameScheme');
    } else if (!this.disableInterlacingSequences) {
      this.form.get('pairBy').setValue('interlaced');
    } else if (!this.disableName) {
      this.form.get('pairBy').setValue('byName');
    } else {
      this.form.get('pairBy').setValue('bySequenceList');
    }
  }

  // Return all currently existing name schemes saved to the selected documents that have id classifications.
  private nameSchemesWithIdSetOnDocuments(nameSchemesWithIdOnly: SelectOption[]): Set<string> {
    const savedNameSchemesWithId: Set<string> = new Set();

    this.selected.selectedRows.forEach((doc) => {
      if (
        doc.metadata.fileNameSchemeID &&
        nameSchemesWithIdOnly.some((scheme) => scheme.value === doc.metadata.fileNameSchemeID)
      ) {
        savedNameSchemesWithId.add(doc.metadata.fileNameSchemeID);
      }
    });

    return savedNameSchemesWithId;
  }

  static prepareJobOptions(formValue: any): SetMergePairedReadsJobOptionsV1_1 {
    return {
      pairBy: formValue.pairBy,
      fileNameSchemeID: formValue.fileNameSchemeID,
      relativeOrientation: 'forwardReverse',
      mergeRate: 'normal',
      expectedDistance: 200,
      dataType: 'Unknown',
      pairedType: 'Paired End',
      // We don't merge - only set in this operation so therefore it should always be false.
      // In the backend paired reads are the same as linked heavy/light chains (for now)...
      iterativelyTrim: false,
      isDoMerge: false,
    };
  }
}
