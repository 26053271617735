@if (
  {
    children: children$ | async,
    files: filteredFiles(files$ | async),
    expanded: expanded$ | async,
  };
  as state
) {
  @if (state.children?.length > 0 || state.files?.length > 0) {
    <div class="d-inline-block w-100">
      <button type="button" class="btn px-0 d-flex" (click)="toggleExpand()">
        <fa-icon
          [icon]="expandIcon"
          [rotate]="state.expanded ? 90 : 0"
          size="xs"
          class="me-2"
        ></fa-icon>
        <span>{{ folder.name }}</span>
      </button>
      <!-- DANGER! It's important not to load all the children ahead of time or we use up browser resources
      *ngIf prevents loading all the children -->
      @if (state.expanded) {
        <div class="children ms-3">
          @for (file of state.files; track file) {
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="file-{{ file.id }}"
                [checked]="isSelected(file.id)"
                (change)="checkboxClicked($event, file)"
              />
              <label for="file-{{ file.id }}" class="form-check-label">{{ file.name }}</label>
            </div>
          }
          @for (child of state.children; track child) {
            <bx-file-picker-tree [folder]="child" [filter]="filter" [fileTypes]="fileTypes">
            </bx-file-picker-tree>
          }
        </div>
      }
    </div>
  }
}
