import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { RepairSequencesParametersV1 } from '../../../../nucleus/services/models/repairSequences.model';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { AntibodyAnnotatorOptionValues } from '../antibody-annotator/antibody-annotator-option-values.model';
import { tap } from 'rxjs/operators';
import { PipelineService } from '../../pipeline/pipeline.service';
import { Observable } from 'rxjs';
import { SelectOption } from '../../models/ui/select-option.model';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { AsyncPipe } from '@angular/common';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../shared/card/card.component';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-repair-sequences',
  templateUrl: './repair-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbAlert,
    CardComponent,
    NgbTooltip,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class RepairSequencesComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  earlyRelease = true;
  title = 'Repair Sequences';
  knowledgeBaseArticle: string;

  form = new BxFormGroup({
    resultName: JobDialogContent.getResultNameControl(),
    repairRegions: new BxFormGroup(
      {
        repairRegionsLight: new BxFormControl([]),
        repairRegionsHeavy: new BxFormControl([]),
      },
      PipelineFormControlValidatorsService.someRequired(),
    ),
    qualityThreshold: new BxFormControl(10, [Validators.min(0), Validators.max(100)]),
    replacePrecision: new BxFormControl('replaceEntire'),
    overrideReferenceDatabaseID: new BxFormControl([]),
    outputFolderName: JobDialogContent.getResultNameControl(),
  });

  invalidDataError = false;
  antibodyDatabases$: Observable<SelectOption[][]>;
  referenceDbsFromOptionsAreAvailable$: Observable<boolean>;
  multipleRefDbsEnabled$: Observable<boolean>;

  private readonly formDefaults: any;
  private readonly selected: SelectionState;
  private readonly otherVariables: any;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<{
      creationPipelineOptions: AntibodyAnnotatorOptionValues;
    }>,
    private changeDetectorRef: ChangeDetectorRef,
    private pipelineService: PipelineService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super('repair-sequences', PipelineFormID.REPAIR_SEQUENCES);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
    this.otherVariables = this.dialogData.otherVariables;

    // Ideally we use the same reference database as what was used to create this doc. However sometimes the reference
    // db is unavailable, in which case we require that the user specify a different one.
    const referenceDbsFromOptions: string[] =
      this.dialogData.otherVariables.creationPipelineOptions.database_customDatabase;
    this.antibodyDatabases$ = this.pipelineService.getReferenceDatabases();
    this.referenceDbsFromOptionsAreAvailable$ = this.pipelineService
      .areReferenceDatabasesAvailable(referenceDbsFromOptions)
      .pipe(
        tap((value) => {
          this.form.controls.overrideReferenceDatabaseID.setValidators(
            value ? null : Validators.required,
          );
          this.form.controls.overrideReferenceDatabaseID.updateValueAndValidity();
        }),
      );
  }

  ngOnInit() {
    // Ensure we have antibody annotator options metadata as the pipeline relies on it.
    const creationPipelineOptions = this.dialogData.otherVariables.creationPipelineOptions;
    if (!creationPipelineOptions) {
      this.invalidDataError = true;
      this.form.disable();
      this.changeDetectorRef.markForCheck();
    }
    this.form.controls.overrideReferenceDatabaseID.setValue(undefined);
  }

  run() {
    const { outputFolderName, ...formValue } = this.form.value as ReturnType<
      typeof this.form.getRawValue
    >;

    const parameters = {
      options: { ...formValue, selection: null as any },
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName,
      },
    };

    // Pass through the selected sequences.
    parameters.options.selection =
      this.otherVariables.extraction && this.otherVariables.extraction.selection
        ? this.otherVariables.extraction.selection
        : null;

    return new RepairSequencesParametersV1(1, parameters);
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }
}
