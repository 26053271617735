import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnState,
  GetContextMenuItems,
  GridOptions,
  IRowNode,
} from '@ag-grid-community/core';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  combineLatest,
  forkJoin,
  Observable,
  of as observableOf,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { DocumentTableService } from '../../core/document-table-service/document-table.service';
import { isColDef } from '../../core/folders/models/colDefs';
import { GridStateService } from '../../core/grid-state/grid-state.service';
import { getRowIdentifier, RowWithID } from '../../core/ngs/getRowIdentifier';
import { CleanUp } from '../../shared/cleanup';
import { PageMessageComponent } from '../../shared/page-message/page-message.component';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { isModifierKeyPressed, isShiftKeyPressed } from '../../shared/utils/keyboard-events';
import { GridDatasource } from './datasource/grid.datasource';
import { IGetRowsRequestMinimal, IGridResource } from './datasource/grid.resource';
import {
  ColumnsVisibilityChangedEvent,
  GridSidebarComponent,
} from './grid-sidebar/grid-sidebar.component';
import { CHECKBOX_COLUMN_DEF, CHECKBOX_COLUMN_STATE, DEFAULT_GRID_OPTIONS } from './grid.constants';
import { GridState } from './grid.interfaces';
import { GridModule } from './grid.module';
import { GridService } from './grid.service';
import { TimerService } from './timer.service';
import { TotalSelectedComponent } from './total-selected/total-selected.component';

/**
 * Displays dynamic columns/rows from a given datasource. Will lazily load pages of data while the
 * user scrolls. Use this table for large datasets coming from a server.
 *
 * If you're planning a refactor of this class, note that the row selection code
 * is over-complicated and extremely fragile. Consider switching from the
 * infinite row model to the server-side row model - it would allow us to use
 * default renderers for checkboxes and rely on ag-grid's logic for calculating
 * selection state.
 */
@Component({
  selector: 'bx-grid',
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService, TimerService],
  standalone: true,
  imports: [
    TotalSelectedComponent,
    PageMessageComponent,
    SpinnerComponent,
    GridModule,
    GridSidebarComponent,
    AsyncPipe,
  ],
})
export class GridComponent extends CleanUp implements OnChanges, OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';
  noOfRowsSelected: number;
  totalNoOfRows: number;
  // Delay loading the grid until columns are ready (returned from the server).
  columnsReady = false;
  selectionChangedEmitter = new EventEmitter<SelectionState>();
  selectionV2ChangedEmitter = new EventEmitter<SelectionStateV2>();
  /**
   * Use to override default parameters. The passed-in instance will not be mutated
   * by AG Grid, so do not expect `api` or `columnApi` to be set.
   */
  @Input() gridOptions: GridOptions = <GridOptions>{};
  // If your requests might take a fairly long time, show the user a timer.
  @Input() showTimer: boolean;
  // Defined Initial column defs to while the initial request is occurring. This should be used if the columns are fixed (unchanging).
  @Input() initialColumnDefs: (ColDef | ColGroupDef)[] = [];
  @Input() datasource: IGridResource;
  @Input() datasourceParams: any;
  @Input() datasourceParams$: Observable<any> = observableOf({});
  // Message to display when the initial fetch returns no rows.
  @Input() noRowsOverlayMessage: string;
  // Limit of scrollable rows to show.
  @Input() resultSetMax = 0; // 0 = no truncation limit
  // key used to fetch the related profiles for this component. e.g. annotator results would set this to 'resultDocument'.
  @Input() profileComponent: string;
  // key that's used to fetch the related grid state. e.g. jobs table would have a tableType of 'jobs'.
  @Input() tableType: string;
  // additional message to show above the table.
  @Input() additionMessage: string;
  // custom actions for the right-click context menu.
  @Input() customContextMenuItems: GetContextMenuItems;
  @Input() showTotalSelected = true;
  @Input() selectable = true;

  /** Don't delete, this onCellEditingStopped @Output event is used by {@link NotesEditHandlerDirective} **/
  @Output() cellEditingStopped = new EventEmitter<CellEditingStoppedEvent>();
  @Output() gridStateChanged = new EventEmitter<GridState>();
  @Output() selectionChanged = this.selectionChangedEmitter;
  @Output() selectionV2Changed = this.selectionV2ChangedEmitter;
  @Output() gridReadyEmitter = new EventEmitter<void>();
  @Output() newColumnsLoaded = new EventEmitter<GridState>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() columnsChanged = new EventEmitter<Column[]>();
  @Output() resetGridState = new EventEmitter<void>();

  gridState$ = new ReplaySubject<GridState>(1);
  loadingMessage$: Observable<string>;

  // Set at `ngOnInit` and used by grid `onReady`
  private _originalDefs: ColDef[];
  private shiftSelectionAnchor: IRowNode | undefined;
  private tableType$ = new ReplaySubject<string>(1);
  private selectAllStateSubscription = Subscription.EMPTY;
  private totalSubscription = Subscription.EMPTY;
  private _datasource: GridDatasource;
  /**
   * Receives notifications that the grid state has changed. These notifications
   * are debounced before being handled in a subscription in ngOnInit.
   */
  private readonly gridStateUpdated$ = new Subject<void>();

  constructor(
    private gridStateService: GridStateService,
    private timerService: TimerService,
    private gridService: GridService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.gridOptions = this.gridOptions || <GridOptions>{};
    this.noOfRowsSelected = 0;
    this.totalNoOfRows = 0;
  }

  ngOnChanges({ gridState, tableType }: SimpleChanges): void {
    if (tableType) {
      this.tableType$.next(tableType.currentValue);
    }

    if (gridState && this.gridOptions.columnApi) {
      this.setGridState(gridState.currentValue);
    }
  }

  ngOnInit() {
    this.loadingMessage$ = this.timerService.loadingMessage$;
    // It's important that grid options are copied to a new instance.
    // If not then they are shared between all grid component instances which breaks them.
    this.gridOptions = { ...DEFAULT_GRID_OPTIONS, ...this.gridOptions };

    // Implement custom menu items.
    // Overrides Reset Columns so that side effects such as emitting the Grid State can be done.
    const resetColumnState = {
      name: 'Reset Columns State',
      action: () => this.resetToGridStateDefault(),
    };
    // https://www.ag-grid.com/javascript-grid-column-menu/
    this.gridOptions.getMainMenuItems = (params) => {
      return [
        'pinSubMenu',
        'separator',
        'autoSizeThis',
        'autoSizeAll',
        'separator',
        resetColumnState,
      ];
    };

    // Ideally this context should be in a service instead.
    this.gridOptions.context = this.gridOptions.context || {};

    this.gridOptions.getContextMenuItems = (params) => {
      const items = [
        'copy',
        'copyWithHeaders',
        'separator',
        'autoSizeThis',
        'autoSizeAll',
        resetColumnState,
      ];

      return this.customContextMenuItems
        ? [...items, ...this.customContextMenuItems(params)]
        : items;
    };
    this.gridOptions.allowContextMenuWithControlKey = true;

    const noRowsMessage = this.noRowsOverlayMessage || 'No data';
    this.gridOptions.overlayNoRowsTemplate = `<span style="color: rgba(0, 0, 0, 0.5)">${noRowsMessage}</span>`;

    this.gridOptions.onCellEditingStopped = (event) => {
      this.cellEditingStopped.next(event);
    };

    this.gridStateUpdated$.pipe(debounceTime(150), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      const gridState = this.getGridState();
      this.gridStateService.storeGridState(this.tableType, gridState);
      this.gridStateChanged.emit(gridState);
      this.gridState$.next(gridState);
    });

    // Whenever either the table (kind of data we're looking at) or the datasourceParams (e.g. document, filter etc)
    // change we want to restart the grid. We do NOT want to restart the grid each time `gridState$` changes for example
    // (that triggers a refresh whenever you try to resize a column!) We don't actually care what the new tableType is,
    // only that it has now changed.
    combineLatest([this.datasourceParams$, this.tableType$])
      .pipe(
        // Sometimes `gridState$ & datasourceParams$` get modified together, in which case they fire one after each other.
        // But we want them to fire together in this case. So setting an rxjs `timeout` (e.g. `debounceTime` of duration zero
        // enables both requests to come through together.
        debounceTime(0),
        tap(() => {
          if (this.gridOptions.api) {
            this.gridOptions.api.setRowCount(0);
            this.clearAllSelection();
          }
          this.columnsReady = false;
          this.totalNoOfRows = 0;
          this.totalSubscription.unsubscribe();
          this.cd.markForCheck();
          this.timerService.start();
        }),
        switchMap(([datasourceParams]) =>
          this.gridStateService.getGridStateSortModel(this.tableType).pipe(
            takeWhile((state) => state == undefined, true),
            map((sortModel) => sortModel ?? []),
            map((sortModel) => ({ datasourceParams, sortModel })),
          ),
        ),
        switchMap(({ datasourceParams, sortModel }) => {
          const params: IGetRowsRequestMinimal = {
            startRow: 0,
            endRow: this.gridOptions.cacheBlockSize,
            sortModel: sortModel,
            filterModel: null,
          };

          return this.datasource.query(params, datasourceParams).pipe(
            catchError((error) => {
              console.error('Caught error in the grid!', error);

              // Catch any http error so the browser doesn't crash.
              return observableOf({
                data: [],
                columns: [],
                metadata: {
                  total: 0,
                  limit: 0,
                  offset: 0,
                },
              });
            }),
            // Filter out invalid input (if an error has occurred).
            filter((data) => !!data),
            tap((data) => {
              this.timerService.stop();
              // Propagate changes.
              this.cd.markForCheck();
              // Remove the geneious_row_index column because it's not intended to be 'user-visible'
              const validDefs = GridComponent.filterOutInvalidColDefs(
                data && data.columns ? data.columns : [],
              );
              // Combine local columns with the server ones.
              const allDefs = this.mergeColDefs(validDefs, this.initialColumnDefs).map((column) => {
                // Enable tooltips on all columns that don't already have a tooltipField or a tooltipValueGetter defined
                // If a valueGetter is defined, this will take priority. Otherwise, use the field.
                if (!column.tooltipValueGetter && column.valueGetter) {
                  column.tooltipValueGetter = column.valueGetter;
                }
                if (!column.tooltipValueGetter && !column.tooltipField) {
                  column.tooltipField = column.field;
                }
                return column;
              });
              // Deep copy original column defs to avoid mutation.
              // NOTE: There is a mutation going along somewhere in this code, hence why a deep copy was required,
              // even though it shouldn't be.
              this._originalDefs = allDefs.map((colDef) => ({ ...colDef }));
              this._originalDefs = GridService.addHeaderTooltip(this._originalDefs);
              // Show the grid (hidden up until now).
              this.columnsReady = true;
              // The reason we set the datasource now (and not in "onReady" for example) is that we can save
              // "data" as the first request so the grid doesn't need to make another http call when it finally
              // is initialized.
              this._datasource = new GridDatasource(
                this.datasource,
                datasourceParams,
                data,
                this.resultSetMax,
              );

              // Propagate changes.
              this.cd.markForCheck();
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      // Just run this code once at initialization.
      .subscribe({
        error: (error) => console.log('There was an error in grid.', error),
      });
  }

  onColumnsVisibilityChanged(event: ColumnsVisibilityChangedEvent) {
    const { visible, hidden } = event.visibilityChanged;
    if (visible?.length) {
      this.gridOptions.columnApi.setColumnsVisible(visible, true);
    }
    if (hidden?.length) {
      this.gridOptions.columnApi.setColumnsVisible(hidden, false);
    }
  }

  /**
   * Called by the jobs table when an event is emitted. Ideally we'd use the
   * event payload instead of blindly refreshing.
   */
  refreshInfiniteCache() {
    this.gridOptions.api?.refreshInfiniteCache();
  }

  /**
   * At grid load we need to order the colDefs ("allDefs") according to any order specified by
   * "colState".
   */
  static applyColumnState(
    allDefs: (ColDef | ColGroupDef)[],
    columnState: ColumnState[] = [],
  ): (ColDef | ColGroupDef)[] {
    if (columnState.length === 0) {
      return allDefs;
    }
    // Checks whether one column is the same as the columnState column by colId/field.
    // colId should be considered first.
    const isSameColumn = (col: ColDef, colState: ColumnState): boolean =>
      col.colId === colState.colId || col.field === colState.colId;

    // Find any columns in "allDefs" which have matches in "colState" and order them by the order in "colState".
    const matches: ColDef[] = columnState
      .filter((stateCol) =>
        allDefs.filter((col) => isColDef(col)).find((main) => isSameColumn(main, stateCol)),
      )
      // Get the `columnDef` which corresponds to the matching `colState` & return it.
      .map((stateCol) => {
        const match: ColDef = allDefs
          .filter((col) => isColDef(col))
          .find((main) => isSameColumn(main, stateCol));
        // Copy any relevant properties across (e.g. width).
        return Object.assign(match, stateCol, { colId: match.colId });
      });
    // Get any remaining columns (e.g. in "allDefs", but not in "colState").
    const others: (ColDef | ColGroupDef)[] = allDefs.filter(
      (main) => !columnState.find((stateCol) => isSameColumn(main, stateCol)),
    );
    // Note that new columns are just tacked on the end; all in "colState" have priority.
    return [...matches, ...others];
  }

  /**
   * Merge any matching columns and tack the rest (that don't match) on the end.
   */
  mergeColDefs(serverDefs: any[], masterDefs: any[]) {
    const defs = serverDefs.map((serverDef) => {
      const masterDef = masterDefs.find((colDef) => colDef.field === serverDef.field);
      if (masterDef) {
        Object.assign(serverDef, masterDef);
      }
      delete serverDef.columnType;
      return serverDef;
    });
    const otherMasterDefs = masterDefs.filter(
      (masterDef) => !serverDefs.find((serverDef) => serverDef.field === masterDef.field),
    );
    return [...defs, ...otherMasterDefs];
  }

  /**
   * Called by the grid when it is ready. At this point `gridOptions.api` and
   * `gridOptions.columnApi` are available
   * @see  https://www.ag-grid.com/javascript-grid-events/
   */
  onReady() {
    this.gridOptions.api.setColumnDefs([
      ...(this.selectable
        ? [
            {
              ...CHECKBOX_COLUMN_DEF,
              cellRendererParams: { gridService: this.gridService },
              headerComponentParams: { gridService: this.gridService },
            },
          ]
        : []),
      ...this._originalDefs,
    ]);
    // Reset column state to match the new colDefs. This prevents the bug where Labels gets set
    // as the first column when switching from All Sequences to a cluster table.
    this.gridOptions.columnApi.resetColumnState();
    this.gridStateService
      .getGridStateOrdered(this.tableType, this.gridOptions.columnApi.getColumnState())
      .pipe(take(1))
      .subscribe((gridState) => this.setGridState(gridState));
    this.gridReadyEmitter.emit();

    this.selectAllStateSubscription.unsubscribe();

    this.selectAllStateSubscription = this.gridService.selectAllState$
      .pipe(
        skip(1),
        filter((state) => !state.indeterminate),
      )
      .subscribe((state) => this.setSelectAll(state.checked));

    this.totalSubscription.unsubscribe();
    // Skips the first, as the first value is ALWAYS 0 since it's a BehaviorSubject.
    this.totalSubscription = this._datasource.total$
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((total) => {
        this.totalNoOfRows = total;
        // Manually trigger onSelectionChanged() when total has changed so it gets emitted from this component with the new total.
        this.onSelectionChanged();
        if (total === 0 && this.gridOptions.api) {
          this.gridOptions.api.showNoRowsOverlay();
        } else {
          this.gridOptions.api.hideOverlay();
        }
      });

    this.gridOptions.api.setDatasource(this._datasource);

    // Initially emit the current grid state when ready.
    this.updateGridState();
  }

  onDragStopped() {
    this.updateGridState();
  }

  onColumnPinned() {
    this.updateGridState();
  }

  onColumnVisible() {
    this.updateGridState();
  }

  onColumnResized() {
    this.updateGridState();
  }

  onSortChanged() {
    this.updateGridState();
  }

  onNewColumnsLoaded() {
    const columnState = this.gridOptions.columnApi.getColumnState();
    if (columnState.length > 1) {
      this.newColumnsLoaded.emit(this.getGridState());
    }
  }

  onDisplayedColumnsChanged() {
    // We don't want the Selected Column to be exposed.
    const columns = this.gridOptions.columnApi
      .getAllGridColumns()
      .filter((col) => col.getColDef().field !== 'selected');
    this.columnsChanged.emit(columns);
  }

  onCellClicked(e: CellClickedEvent<RowWithID>): void {
    // If there is no data, then it must not be a valid column (i.e. it's a loading column) so ignore selection.
    if (!this.selectable || !e.data || getRowIdentifier(e.data) == null) {
      return;
    }

    const isCheckboxSelection = e.colDef.colId === 'selected';
    const selectAllState = this.gridService.selectAllState$.getValue();
    const shiftKeyPressed = isShiftKeyPressed(e.event as MouseEvent);
    const modifierKeyPressed = isModifierKeyPressed(e.event as MouseEvent);
    // Note: Check box selection works like selection with a modifier key pressed.
    // In both cases, if the shift key is pressed, then shift behaviour takes priority.
    const behavesAsModifier = modifierKeyPressed || isCheckboxSelection;
    let newState = !behavesAsModifier ? true : !e.node.isSelected();

    // Selecting a row resets selectAll, unless using a checkbox or modifier key.
    if (selectAllState.checked && !behavesAsModifier) {
      this.gridService.selectAllState$.next({ checked: false, indeterminate: false });
      newState = true;
    }

    e.node.setSelected(newState, !behavesAsModifier);

    if (!shiftKeyPressed) {
      this.shiftSelectionAnchor = e.node;
    }

    // If Shift Key Pressed, clear virtually selected rows.
    if (shiftKeyPressed) {
      this.clearVirtuallySelectedRows();
    }

    if (!selectAllState.checked && shiftKeyPressed) {
      this.handleShiftSelect(e.node);
    }

    this.gridService.updateDeselected(e.data, newState);
    this.calculateAndSetSelectAllState();
    this.forceRefreshCheckboxColumn();
  }

  onSelectionChanged() {
    const selectedRows = this.gridOptions.api
      .getSelectedRows()
      .concat([...this.gridService.virtuallySelectedRows.values()]);
    const selectAll = this.gridService.selectAllState$.getValue().checked;
    const deselectedRows = [...this.gridService.deselectedRows.values()];

    this.noOfRowsSelected = selectAll
      ? this.totalNoOfRows - deselectedRows.length
      : selectedRows.length;

    const ids = selectAll
      ? deselectedRows.map((row) => getRowIdentifier(row))
      : selectedRows.map((row) => getRowIdentifier(row));

    const rows = selectAll ? deselectedRows : selectedRows;

    /* V1 Selection State. */
    const selectionState: SelectionState = {
      noOfRowsSelected: this.noOfRowsSelected,
      totalNoOfRows: this.totalNoOfRows,
      selectAll,
      ids,
      selectedRows,
      firstRow: this.noOfRowsSelected > 0 ? selectedRows[0] : null,
    };

    this.selectionChangedEmitter.emit(selectionState);

    /* V2 Selection State. */
    const selectionStateV2: SelectionStateV2 = {
      totalSelected: this.noOfRowsSelected,
      total: this.totalNoOfRows,
      selectAll: selectAll,
      ids: ids,
      rows: rows,
      selectedRows: selectedRows,
    };

    this.selectionV2ChangedEmitter.emit(selectionStateV2);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tableType$.complete();
    this.gridState$.complete();
    this.gridStateUpdated$.complete();
    this.selectAllStateSubscription.unsubscribe();
    this.totalSubscription.unsubscribe();
  }

  // Purges the locally cached rows and requests the rows from the server again.
  // Keeps selection state unless specified.
  refresh(resetSelectionState = false): void {
    // Sometimes the UI can crash (just a little) if we call methods that don't exist.
    // e.g. the folder tree can stop indicating the selected folder node.
    if (this.gridOptions.api) {
      if (resetSelectionState) {
        this.clearAllSelection();
      }
      this.gridOptions.api.refreshInfiniteCache();
    } else {
      console.warn(
        `Failed to refresh infinite cache; this.gridOptions.api was undefined; do you have a stale subscription?`,
      );
    }
  }

  // Sets rows as de-selected and then refreshes the grid.
  // This doesn't yet actually remove rows from the grid as it just refreshes the entire grid since
  // it relies on data from the server.
  removeRows(ids?: string[]): void {
    this.clearAllSelection();
    this.calculateAndSetSelectAllState();
    this.refresh();
  }

  setGridState(gridState: GridState): void {
    if (this.gridOptions && this.gridOptions.api) {
      this.setColumnsState(gridState.columnsState);
    }
  }

  setColumnsState(columnsState: ColumnState[]) {
    const diff: ColumnState[] = this.getGridState()
      .columnsState.filter(
        (masterColumnState) =>
          !columnsState.find((columnState) => columnState.colId === masterColumnState.colId),
      )
      .map((masterColumnState) => ({ ...masterColumnState, ...{ hide: true } }));

    const mergedColumnsState = columnsState.concat(diff);

    this.gridOptions.columnApi.applyColumnState({
      // Always keep Checkbox Select column at the beginning pinned.
      state: [CHECKBOX_COLUMN_STATE, ...mergedColumnsState],
      applyOrder: true,
      defaultState: { sort: null, sortIndex: null },
    });
  }

  clearAllSelection() {
    this.gridService.clearAllSelection();
    this.noOfRowsSelected = 0;
    this.shiftSelectionAnchor = undefined;
    this.gridOptions.api.clearRangeSelection();
    this.gridOptions.api.deselectAll();
    this.gridService.selectAllState$.next({ checked: false, indeterminate: false });
    this.onSelectionChanged();
  }

  private calculateAndSetSelectAllState() {
    const selectAllState = this.gridService.selectAllState$.getValue();
    const selectedRows = this.gridOptions.api.getSelectedRows();
    const selectedCount = selectedRows.length + this.gridService.virtuallySelectedRows.size;
    const deselectedCount = this.gridService.deselectedRows.size;
    const indexLastRow = this.gridOptions.api.getModel().getRowCount();
    const partiallySelected =
      (selectAllState.checked && deselectedCount !== 0) ||
      (!selectAllState.checked && selectedCount > 0);
    const noneSelected = !selectAllState.checked && selectedCount === 0;
    if (deselectedCount === indexLastRow) {
      // When all rows are deselected.
      selectAllState.checked = false;
    } else if (selectedCount === indexLastRow) {
      // All rows are now selected.
      selectAllState.checked = true;
      selectAllState.indeterminate = false;
    } else if (noneSelected) {
      // If one row is selected and then we deselect the last row.
      selectAllState.indeterminate = false;
    } else if (partiallySelected) {
      // If we have selected all and then deselect one row.
      selectAllState.indeterminate = true;
    }

    this.gridService.selectAllState$.next(selectAllState);
  }

  static filterOutInvalidColDefs(
    serverCols: (ColDef | ColGroupDef)[],
    alsoExclude: string[] = [],
  ): (ColDef | ColGroupDef)[] {
    const excluded = [
      'geneious_row_index',
      'associated_sequences',
      'Associated Sequences',
      'row_uuid',
      'row_number',
      'row_id',
      'noSvJson',
      'noSvJsonReason',
      'No sequence viewer JSON available',
      'Reason why no sequence viewer JSON is available',
      'row_index',
      'query_sequence_row_index',
      'collection_id',
    ].concat(alsoExclude);
    return serverCols
      .filter((col) => !isColDef(col) || !excluded.some((field) => col.field.includes(field)))
      .map((col) => {
        if (isColDef(col)) {
          return col;
        } else {
          const colGroup: ColGroupDef = col;
          // Filter columns in group to remove any invalid ones.
          colGroup.children = colGroup.children.filter((child) => {
            if (isColDef(child)) {
              const columnSplit = DocumentTableService.splitAssayColumnKey(child.field);
              // Assay data columns contain the group name before a colon, name scheme columns don't.
              const columnNameWithoutGroup = columnSplit ? columnSplit.columnName : child.field;
              return !excluded.includes(columnNameWithoutGroup);
            } else {
              // TODO Handle multiple levels of group recursively when necessary.
              return true;
            }
          });
          return colGroup;
        }
      });
  }

  private clearVirtuallySelectedRows() {
    this.gridService.virtuallySelectedRows.clear();
  }

  protected focusColumn(colId: string) {
    this.gridOptions.api.ensureColumnVisible(colId);
  }

  /**
   * Store and emit the current grid state.
   * This method was getting spammed, so it is now debounced via the
   * {@link gridStateUpdated$} subject.
   */
  private updateGridState() {
    this.gridStateUpdated$.next();
  }

  // Force refreshes Checkbox Column so that it can be set.
  private forceRefreshCheckboxColumn() {
    this.gridOptions.api.refreshCells({ force: true, columns: [CHECKBOX_COLUMN_DEF.field] });
  }

  private getGridState(): GridState {
    const columnsState = this.gridOptions.columnApi
      .getColumnState()
      .filter((col) => col.colId !== 'selected');
    return { columnsState };
  }

  protected resetToGridStateDefault() {
    this.gridOptions.columnApi.resetColumnState();
    // Sort by the first column.
    const firstColumn = this._originalDefs[0];
    if (firstColumn) {
      this.gridOptions.columnApi.applyColumnState({
        state: [{ colId: firstColumn.colId || firstColumn.field, sort: 'asc' }],
        defaultState: { sort: null, sortIndex: null },
      });
    }
    this.gridStateService.clearGridState(this.tableType);
    this.resetGridState.emit();
  }

  /**
   * Handles Shift selection for local rows within memory and rows that are not in memory but on
   * the server.
   *
   * @param {IRowNode} clickedRow
   */
  private handleShiftSelect(clickedRow: IRowNode) {
    const keys = Object.keys(this.gridOptions.api.getCacheBlockState());
    const min = parseInt(keys[0], 10);
    const max = parseInt(keys[keys.length - 1], 10);
    const cacheBlockState: {
      blockNumber: number;
      endRow: number;
      pageStatus: 'loaded' | 'empty';
      startRow: number;
    }[] = [];
    const cacheBlockSize = this.gridOptions.cacheBlockSize;

    let startIndex = this.shiftSelectionAnchor ? this.shiftSelectionAnchor.rowIndex : 0;
    let endIndex = clickedRow.rowIndex;

    // startIndex must be lower than endIndex
    if (startIndex > endIndex) {
      endIndex = this.shiftSelectionAnchor.rowIndex;
      startIndex = clickedRow.rowIndex;
    }

    // Get Start/End Cache block by the index (cache blocks are per 100 rows).
    const startBlock = Math.floor(startIndex / cacheBlockSize);
    const endBlock = Math.floor(endIndex / cacheBlockSize);

    // Fill cacheBlockState with loaded and empty cache blocks.
    const gridCacheBlockState = this.gridOptions.api.getCacheBlockState();
    for (let i = min; i <= max; i++) {
      const block = gridCacheBlockState[i];
      const hasLoaded = block && block.pageStatus === 'loaded';
      const result = hasLoaded
        ? block
        : {
            blockNumber: i,
            startRow: i * cacheBlockSize,
            endRow: i * cacheBlockSize + cacheBlockSize,
            pageStatus: 'empty',
          };
      cacheBlockState.push(result);
    }

    // Retrieves empty cache blocks from the server and selects rows from the loaded cache blocks.
    // TODO Re-use virtually selected rows instead of requesting the same rows again if selection changes.
    const queries = [];
    for (let i = startBlock; i <= endBlock; i++) {
      const block = cacheBlockState[i];
      if (block.pageStatus === 'loaded') {
        const start =
          startIndex > block.startRow && startIndex <= block.endRow ? startIndex : block.startRow;
        const end =
          endIndex >= block.startRow && endIndex <= block.endRow ? endIndex : block.endRow;

        // Select Locally from the grid.
        this.selectRange(start, end);
      } else {
        const sortModel = this.gridOptions.columnApi
          .getColumnState()
          .filter((column) => column.sort)
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((column) => ({ sort: column.sort, colId: column.colId }));
        const gridRequestParams: IGetRowsRequestMinimal = {
          startRow: block.startRow,
          endRow: block.endRow,
          sortModel: sortModel,
          filterModel: undefined,
        };

        queries.push(this.datasource.query(gridRequestParams));
      }
    }

    // Request missing rows from the cache blocks and then set them as selected.
    // TODO cancel queries subscription if another selection is made.
    forkJoin(queries).subscribe((resources) => {
      // Virtually Selects range
      resources.forEach((res: any) => {
        res.data.forEach((row: any) => {
          this.gridService.virtuallySelectedRows.set(getRowIdentifier(row), row);
        });
      });

      this.calculateAndSetSelectAllState();
      this.forceRefreshCheckboxColumn();
      // Manually call onSelectionChanged() due to the delay of the server
      this.onSelectionChanged();
    });
  }

  /**
   * Selects Range of rows from a starting index to an end index.
   */
  private selectRange(startIndex: number, endIndex: number) {
    this.gridOptions.api.forEachNode((node) => {
      if (startIndex <= node.rowIndex && node.rowIndex <= endIndex) {
        // Tailing node in sequence (3rd param) means if this was looped/called 100 times, then onSelectionChanged()
        // will only be triggered for the last selected event.
        node.setSelected(true, false, 'api');
      }
    });
    this.calculateAndSetSelectAllState();
  }

  // TODO move function to gridService selectAll.
  private setSelectAll(selected: boolean) {
    this.gridService.clearAllSelection();
    this.shiftSelectionAnchor = undefined;
    if (selected) {
      // Select All rows. This will select rendered & un-rendered rows (this.gridOptions.api.selectAll does not).
      this.gridOptions.api.forEachNode((node) => node.setSelected(true, false, 'api'));
    } else {
      // Deselect all rows without triggering onSelectionChanged.
      this.gridOptions.api.setNodesSelected({
        nodes: this.gridOptions.api.getSelectedNodes(),
        newValue: false,
        source: 'api',
      });
    }
    this.clearVirtuallySelectedRows();
    this.forceRefreshCheckboxColumn();
    this.onSelectionChanged();
  }
}

/**
 * @deprecated Please use SelectionStateV2 where possible.
 */
export class SelectionState {
  noOfRowsSelected = 0;
  totalNoOfRows = 0;
  selectAll = false;
  ids: string[] = [];
  selectedRows: any[] = [];
  firstRow: any | null = null;
}

/**
 * Similar to V1 of SelectionState except no `firstRow` property and a new `rows` property that
 * follows ids when it comes to selection/de-selection.
 */
export class SelectionStateV2<T extends RowWithID = Record<string, unknown>> {
  /** Total number of rows selected. */
  totalSelected = 0;
  /** Total number of rows in the table. */
  total = 0;
  /** Whether select all has been enabled which affects ids/rows. */
  selectAll = false;
  /** ids of each row selected. Will be the de-selected ids if select all is enabled. */
  ids: string[] = [];
  /** rows of each row selected. Will be the de-selected rows if select all is enabled. */
  rows: T[] = [];
  /**
   * rows of each row selected regardless of the `selectAll` state.
   * This should be used sparingly as it will only contain the current selected rows in memory.
   * e.g. selectAll of 1000 rows may only populated selectedRows with the first 100 values.
   */
  selectedRows: T[] = [];
}

export function isSelectionStateV2(
  state: SelectionState | SelectionStateV2,
): state is SelectionStateV2 {
  return (<SelectionStateV2>state).totalSelected !== undefined;
}

export function selectionStateV2ToSelectionState(state: SelectionStateV2<unknown>): SelectionState {
  return {
    noOfRowsSelected: state.totalSelected,
    totalNoOfRows: state.total,
    selectAll: state.selectAll,
    ids: state.ids,
    selectedRows: state.selectedRows,
    firstRow: null,
  };
}
