import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColDef } from '@ag-grid-community/core';
import { NameSchemeConfigureFieldsEditDialogComponent } from './name-scheme-configure-fields-edit-dialog/name-scheme-configure-fields-edit-dialog.component';
import Token from '../../tokenizer-preview/Token';
import {
  ClientGridComponent,
  ClientGridSelection,
} from '../../../../features/grid/client-grid/client-grid.component';
import { INameSchemeField } from '../../../models/settings/name-scheme-field.model';
import { NameSchemeFieldsTemplateParser } from './name-scheme-fields-template-parser/name-scheme-fields-template-parser';
import { UtilService } from '../../../../shared/util.service';
import { CLASSIFICATION_OPTIONS } from '../name-scheme-editor.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToolstripComponent } from '../../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'bx-name-scheme-configure-fields',
  templateUrl: './name-scheme-configure-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToolstripComponent, ToolstripItemComponent, MatLegacyButtonModule, ClientGridComponent],
})
export class NameSchemeConfigureFieldsComponent implements OnChanges {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';

  @Input() tokens: Set<Token> = new Set<Token>();
  @Output() fieldsChanged = new EventEmitter<INameSchemeField[]>();
  fieldsColumnDefs: ColDef[];
  fields: INameSchemeField[] = [];
  selectedItem: INameSchemeField;
  selectedItems: INameSchemeField[] = [];

  @ViewChild(ClientGridComponent, { static: true }) nameSchemeGrid: ClientGridComponent;

  private idIterator = 0;
  private modalRef: NgbModalRef;

  constructor(
    private modal: NgbModal,
    private utilService: UtilService,
  ) {
    this.fieldsColumnDefs = [
      {
        field: 'id',
        headerName: '',
        width: 50,
        resizable: false,
        suppressMovable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueGetter: (_) => '',
      },
      {
        field: 'name',
        headerName: 'Column Name',
      },
      {
        field: 'template',
        headerName: 'Template',
      },
      {
        field: 'result',
        headerName: 'Example',
        valueGetter: (params) => {
          const tokens = Array.from(this.tokens);

          const parsedTemplate: string = utilService.sanitizeCell(
            NameSchemeFieldsTemplateParser.parse(params.data.template, tokens).result,
          );
          return parsedTemplate ? parsedTemplate : '';
        },
      },
      {
        field: 'classification',
        headerName: 'Classification',
        valueFormatter: (params) => {
          if (!params.value) {
            return 'Display column';
          }

          return CLASSIFICATION_OPTIONS.find((option) => option.value === params.value).label;
        },
      },
    ];
  }

  ngOnChanges({ tokens }: SimpleChanges) {
    if (tokens) {
      this.fields = [];
      this.selectedItem = undefined;
      this.selectedItems = [];
      this.nameSchemeGrid.setRowData(this.fields);
    }
  }

  openNewFieldDialog() {
    this.openFieldFormDialog().then((result) => {
      if (result) {
        // Assign an id to the field for easier determination for editing/deletion.
        result = Object.assign({}, result, { id: this.idIterator++ });
        this.fields.push(result);
        this.nameSchemeGrid.setRowData(this.fields);
        this.fieldsChangedEmit(this.fields);
      }
    });
  }

  openEditFieldDialog() {
    const selectedItem = this.selectedItem;
    this.openFieldFormDialog(Object.assign({}, selectedItem)).then((result) => {
      if (result) {
        // Existing Field
        const index = this.fields.findIndex((f) => {
          return f.id === selectedItem.id;
        });

        if (index >= 0) {
          this.fields[index] = result;
        }

        this.nameSchemeGrid.setRowData(this.fields);
        this.fieldsChangedEmit(this.fields);
      }
    });
  }

  removeFields() {
    // TODO Fix selection lingering.
    let index = this.fields.length;
    while (index--) {
      if (
        this.selectedItems.findIndex((selected) => selected.id === this.fields[index].id) !== -1
      ) {
        this.fields.splice(index, 1);
      }
    }
    this.nameSchemeGrid.setRowData(this.fields);
    this.fieldsChangedEmit(this.fields);
  }

  onSelectionChanged(selectionState: ClientGridSelection<INameSchemeField>) {
    this.selectedItems = selectionState.rows;
    if (this.selectedItems.length === 1) {
      const region = selectionState.rows[0];
      // Make sure to break references.
      this.selectedItem = Object.assign({}, region);
    } else {
      delete this.selectedItem;
    }
  }

  private fieldsChangedEmit(fields: INameSchemeField[]) {
    this.fieldsChanged.emit(fields.map(this.removeId));
  }

  private openFieldFormDialog(field?: INameSchemeField): Promise<INameSchemeField | undefined> {
    this.modalRef = this.modal.open(NameSchemeConfigureFieldsEditDialogComponent, {
      centered: true,
      backdrop: 'static',
    });
    Object.assign(this.modalRef.componentInstance, {
      modalData: { field, existingFields: this.fields, tokens: this.tokens },
    });

    return this.modalRef.result;
  }

  private removeId(field: INameSchemeField) {
    const { id: id, ...everythingElse } = field;
    return everythingElse;
  }
}
