import { Injectable, OnDestroy } from '@angular/core';
import { ViewersState } from '../viewers-v2/viewers-state/viewers-state';
import { ViewersStateStoreService } from './viewer-state-store/viewers-state-store.service';
import { ViewerHeight, ViewerStates } from './viewer-state-store/viewers-state.interface';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * ViewersStateStore
 *
 * Used as a replacement for the Injectable ViewersState from ViewersV2 Module.
 * This is responsible for handling the storage and assigning of the Viewers state
 * (height & last selected tab).
 */
@Injectable({
  providedIn: 'root',
})
export class ViewersStateStore extends ViewersState implements OnDestroy {
  private viewerStates: ViewerStates<ViewerHeight>;
  private subscriptions: Subscription = new Subscription();

  private onHeightChanged$: Subject<{ key: string; height: ViewerHeight<'percent'> }>;

  constructor(private viewersStateStoreService: ViewersStateStoreService) {
    super();

    this.onHeightChanged$ = new Subject();

    this.subscriptions.add(
      this.viewersStateStoreService
        .getViewerStates()
        .subscribe((states) => (this.viewerStates = states)),
    );

    this.subscriptions.add(
      this.onHeightChanged$.pipe(debounceTime(1000)).subscribe((event) => {
        this.viewersStateStoreService.updateHeight(event.key, event.height);
      }),
    );
  }

  ngOnDestroy(): void {
    this.onHeightChanged$.complete();
    this.subscriptions.unsubscribe();
  }

  onHeightChanged(key: string, height: ViewerHeight<'percent'>): void {
    this.onHeightChanged$.next({ key, height });
  }

  getHeight(key: string): ViewerHeight {
    const viewerState = this.viewerStates[key];
    return viewerState ? viewerState.height : super.getHeight(key);
  }
}
