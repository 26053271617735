import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {
  RegionSelectorChain,
  RegionsSelectorComponent,
} from '../../../../shared/regions-selector/regions-selector.component';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { CollapsibleCardComponent } from '../../../../shared/collapsible-card/collapsible-card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';

@Component({
  selector: 'bx-clustering-options-card',
  templateUrl: './clustering-options-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CollapsibleCardComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    MatIconModule,
    RegionsSelectorComponent,
    NgClass,
    NgFormControlValidatorDirective,
    AsyncPipe,
  ],
})
export class ClusteringOptionsCardComponent implements OnInit {
  @Input() collapseByDefault: boolean;
  @Input() clusterSummaryFilterEnabled: boolean = true;
  @Input() filterRegionsFor: RegionSelectorChain | 'singleClone' | 'genericSequence';
  @Input() chipsLoading$: Observable<boolean>;

  readonly faChevronRight = faChevronRight;
  form: FormGroup<unknown>;

  constructor(private parentFormGroup: FormGroupDirective) {}

  ngOnInit() {
    // Since this clustering options card component will always be used under a bigger form
    // (e.g Antibody Annotator, Single Clone, etc.), we want to connect this form to the parent form via
    // the FormGroupDirective for easier integration with the parent form. Note the parent form must contains
    // form controls that matches the name of the form controls in this form.
    this.form = this.parentFormGroup.control;
  }
}
