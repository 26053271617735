import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DocumentHistoryData } from '@geneious/nucleus-api-client';
import { combineLatest, Observable, Subject, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { DocumentHttpV2Service } from '../../../../nucleus/v2/document-http.v2.service';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';

@Component({
  selector: 'bx-document-summary-card',
  templateUrl: './document-summary-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CollapsibleCardComponent, NgTemplateOutlet, SpinnerComponent, AsyncPipe, DatePipe],
})
export class DocumentSummaryCardComponent implements OnInit {
  @Input() documentID$: Observable<string>;
  @Input() documentHistory$: Observable<DocumentHistoryData[]>;
  @Input() usersByID$: Observable<Record<string, { id: string; name: string }>>;
  summary$: Observable<SummaryData>;
  collapsed$ = new Subject<boolean>();
  /** Signifies missing data to the template */
  readonly DATA_MISSING = 'DATA_MISSING';

  constructor(private readonly documentService: DocumentHttpV2Service) {}

  ngOnInit(): void {
    // Zip these input observables as they emit in pairs
    const documentHistoryAndID$ = zip(this.documentID$, this.documentHistory$).pipe(
      map(([id, history]) => ({ id, history })),
    );
    const data$ = combineLatest([
      documentHistoryAndID$,
      this.usersByID$,
      this.collapsed$.pipe(startWith(false)),
    ]).pipe(
      // Ignore emissions that occur while card is collapsed
      filter(([_1, _2, collapsed]) => !collapsed),
      // Only allow emissions to pass if document ID has changed since last time
      // summary$ re-uses existing emission if document ID matches
      distinctUntilChanged(
        (id1, id2) => id1 === id2, // comparator
        ([historyAndID]) => historyAndID.id, // key selector
      ),
      switchMap(([historyAndID, users]) =>
        this.documentService
          .get(historyAndID.id)
          .pipe(map((document) => [document, historyAndID.history, users] as const)),
      ),
      map(([document, history, users]) => {
        const documentData = {
          documentID: document.documentID,
          createdAt: document.createdAt ?? this.DATA_MISSING,
          modifiedAt: document.modifiedAt,
          documentType: document.metadata.documentType,
        };
        if (history.length === 0) {
          return documentData;
        }
        const creationEvent = history[history.length - 1];
        const lastEvent = history[0];
        const creator = users[creationEvent.userID];
        const modifier = users[lastEvent.userID];
        return {
          ...documentData,
          createdBy: creator?.name,
          modifiedBy: this.isWithinOneSecond(document.modifiedAt, lastEvent.createdAt)
            ? modifier?.name
            : undefined,
        };
      }),
    );

    this.summary$ = combineLatest([this.documentID$, data$]).pipe(
      // Use data value if it matches selection, otherwise display placeholder
      map(([documentID, data]) => (documentID === data.documentID ? data : {})),
      startWith({}),
    );
  }

  private isWithinOneSecond(date1: string, date2: string): boolean {
    return Math.abs(new Date(date1).getTime() - new Date(date2).getTime()) < 1000;
  }
}

interface SummaryData {
  documentID?: string;
  createdAt?: string;
  createdBy?: string;
  modifiedAt?: string;
  modifiedBy?: string;
  documentType?: string;
}
