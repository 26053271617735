<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'users'], label: 'Users' }]"
></bx-settings-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="newButtonClickedEvent$.next()"
      [disabled]="newButtonDisabled$ | async"
    >
      Add New User
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="editButtonClickedEvent$.next()"
      [disabled]="(singleUserIsSelected$ | async) === false"
    >
      Edit User
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="deactivateButtonClickedEvent$.next()"
      [disabled]="deactivateButtonDisabled$ | async"
    >
      Deactivate User
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button class="btn btn-light" routerLink="/users/api-keys">Show All API Keys</button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-client-grid
  [columnDefs]="columnDefs"
  [rowData]="usersTableData$ | async"
  (rowDoubleClicked)="editButtonClickedEvent$.next()"
  (selectionChanged)="selection$.next($event)"
  class="flex-grow-1 flex-shrink-1"
>
</bx-client-grid>
