<div class="modal-body scrollable">
  <ngb-alert type="info" [dismissible]="false">
    <span
      >Appending columns from
      <b>{{ files && files.length > 1 ? files.length + ' files' : assayFileName }}</b>
      to document
      <b>{{ resultsName }}</b>
    </span>
  </ngb-alert>

  <form [formGroup]="form" class="container-fluid">
    <div class="mb-3 row">
      <label for="resultsIDColumn" class="col-4 col-form-label"
        >Matching column in the document</label
      >
      <bx-select
        class="col"
        [selectOptions]="resultColumnOptions$ | async"
        id="resultsIDColumn"
        [formControl]="resultIDColumnControl"
      >
      </bx-select>
    </div>

    <div class="mb-3 row">
      <label for="fileIDColumn" class="col-4 col-form-label"
        >Matching column in the assay data</label
      >
      <bx-select
        class="col"
        [selectOptions]="fileColumnOptions$ | async"
        id="fileIDColumn"
        [formControl]="fileIDColumnControl"
      >
      </bx-select>
    </div>

    <div class="mb-3">
      <div class="row mb-2">
        <div class="col-4 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="mergeOption"
              id="addToNewAssayDataGroup"
              [formControl]="isMergingToExistingGroup"
              [value]="false"
              [attr.disabled]="(submitting$ | async) ? true : null"
            />
            <label class="form-check-label" for="addToNewAssayDataGroup">
              Add to new assay data group:
            </label>
          </div>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            ngFormControlValidator
            formControlName="newGroupName"
            data-testid="newAssayGroupNameInput"
          />
          <div class="invalid-feedback">A valid name is required</div>
        </div>
      </div>
      @if (assayGroups$ | async; as assayGroups) {
        <div class="row align-items-center">
          <div class="col-4 d-flex align-items-center">
            <div
              class="form-check form-check-inline"
              ngbTooltip="There is no existing assay data attached to this table"
              [disableTooltip]="assayGroups.length > 0"
            >
              <input
                class="form-check-input"
                type="radio"
                name="mergeOption"
                id="addToExistingAssayDataGroup"
                [formControl]="isMergingToExistingGroup"
                [value]="true"
                [attr.disabled]="(submitting$ | async) || assayGroups.length === 0 ? true : null"
              />
              <label class="form-check-label" for="addToExistingAssayDataGroup">
                Add to existing assay data group:
              </label>
            </div>
          </div>
          <div class="col">
            <bx-select
              [formControl]="existingGroupControl"
              [selectOptions]="assayGroupOptions$ | async"
              data-testid="existingAssayGroupsPicker"
            >
            </bx-select>
          </div>
        </div>
      }
    </div>

    @if (isMultipleTablesPerFile$ | async) {
      <ngb-alert type="warning">
        Some of your files contain multiple tables. All tables will be used.
      </ngb-alert>
    }

    <div class="h-static-2 overflow-y-auto">
      <!-- TODO "Associated Sequences" coming up as the first column, should be ID and match the first dropdown. -->
      <!-- TODO This grid needs to sort by Match (descending) by default. -->
      <bx-client-grid
        [showTotalSelected]="false"
        class="assay-data-grid w-100 h-100"
      ></bx-client-grid>
    </div>
  </form>
</div>

<div class="modal-footer">
  @if (!(message$ | async)?.error) {
    <bx-assay-data-progress-bar></bx-assay-data-progress-bar>
  }
  @if (message$ | async; as message) {
    <span class="text-danger">
      {{ message.error ?? message.warning }}
    </span>
  }
  <button
    class="btn btn-primary"
    type="button"
    bx-spinner-button
    [disabled]="spinnerButtonDisabled$ | async"
    [spin]="submitting$ | async"
    (click)="submitAssayData()"
  >
    Add Assay Data
  </button>
  <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
</div>
