import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NewGraphWidget,
  NewImageWidget,
  NewReportWidget,
  NewResultTableWidget,
  NewSequenceLogoWidget,
  NewTableWidget,
  SummaryGraphType,
  WidgetType,
} from '../report.model';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { JobsService } from '../../../core/jobs/job.service';
import {
  ReportJobOptionsV2,
  ReportJobParametersV2,
} from '../../../../nucleus/services/models/reportOptionsModel';
import { AuthService, NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { FilesTableFacade } from '../../../core/files-table/files-table.facade';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import { naturalSortOnStringProperty } from '../../../shared/sort.util';
import { AnnotatedPluginDocument } from '../../../core/geneious';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportStateFacade } from '../report-store/report-state.facade';
import { ReportStateFlattened } from '../report-store/report-state';
import { DocumentTableStateService } from '../../../core/document-table-service/document-table-state/document-table-state.service';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { NucleusPipelineID } from 'src/app/core/pipeline/pipeline-constants';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { ReportComponent } from '../report.component';
import { CollapsiblePanelComponent } from '../../../shared/collapsible-panel/collapsible-panel.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { PipelineVersionSelectorComponent } from '../../../core/pipeline-dialogs/pipeline-dialog-v2/pipeline-version-selector/pipeline-version-selector.component';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

@Component({
  selector: 'bx-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReportComponent,
    CollapsiblePanelComponent,
    CollapsibleCardComponent,
    FormsModule,
    PipelineVersionSelectorComponent,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class GenerateReportComponent implements OnInit, OnDestroy {
  @Input() document: AnnotatedPluginDocument;
  @Input() folderId: string;

  submitting$ = new BehaviorSubject<boolean>(false);
  createButtonDisabled$: Observable<boolean>;
  customTables: NewTableWidget[] = [];
  resultTables: NewResultTableWidget[] = [];
  sequenceLogos: NewSequenceLogoWidget[] = [];
  images: NewImageWidget[] = [];
  hasNGSGraphs: boolean;
  customTableFilter: string;
  resultTableFilter: string;
  sequenceLogoFilter: string;
  graphFilter: string;
  imageFilter: string;
  panelVisible = true;

  readonly basePipelineID = 'report';
  readonly pipelineID$ = new BehaviorSubject<NucleusPipelineID>(this.basePipelineID);
  private baseUrlOverride: string | null = null;
  private subscriptions = new Subscription();
  private readonly reportState$: Observable<ReportStateFlattened>;

  private readonly _graphs: NewGraphWidget[] = [
    {
      title: 'Annotation rates',
      type: WidgetType.GRAPH_WIDGET,
      data: {
        code: SummaryGraphType.BAR_CHART,
        params: { source: 'json', jsonChart: 'annotationRates' },
      },
    },
    {
      title: 'Number of clusters',
      type: WidgetType.GRAPH_WIDGET,
      data: { code: SummaryGraphType.BAR_CHART, params: { source: 'json', jsonChart: 'clusters' } },
    },
    {
      title: 'Number of genes',
      type: WidgetType.GRAPH_WIDGET,
      data: { code: SummaryGraphType.BAR_CHART, params: { source: 'json', jsonChart: 'genes' } },
    },

    {
      title: 'Cluster diversity',
      type: WidgetType.GRAPH_WIDGET,
      data: {
        code: SummaryGraphType.BAR_CHART,
        params: { source: 'cluster', type: 'cluster_diversity' },
      },
    },
    {
      title: 'Cluster lengths',
      type: WidgetType.GRAPH_WIDGET,
      data: {
        code: SummaryGraphType.BAR_CHART,
        params: { source: 'cluster', type: 'cluster_lengths' },
      },
    },
    {
      title: 'Cluster sizes',
      type: WidgetType.GRAPH_WIDGET,
      data: {
        code: SummaryGraphType.BAR_CHART,
        params: { source: 'cluster', type: 'cluster_sizes' },
      },
    },

    {
      title: 'Amino Acid Distribution Chart',
      type: WidgetType.GRAPH_WIDGET,
      data: { code: SummaryGraphType.STACKED_BAR_CHART, params: { source: 'aminoAcid' } },
    },

    {
      title: 'Codon Distribution Chart',
      type: WidgetType.GRAPH_WIDGET,
      data: { code: SummaryGraphType.HEATMAP, params: { source: 'codon' } },
    },
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private reportStateFacade: ReportStateFacade,
    private documentHttpService: DocumentHttpV2Service,
    private documentTableStateService: DocumentTableStateService,
    private jobsService: JobsService,
    private authService: AuthService,
    private filesTableFacade: FilesTableFacade,
    @Inject(APP_CONFIG) appConfig: AppConfig,
  ) {
    this.reportState$ = this.reportStateFacade.getReportState();
    // Use the baseUrlOverride for PR environments
    if (appConfig.NUCLEUS_ENVIRONMENT === 'dev' && window.location.protocol === 'https:') {
      this.baseUrlOverride = window.location.origin;
    }
  }

  ngOnInit() {
    const noWidgetsSelected$ = this.reportStateFacade
      .getReportWidgets()
      .pipe(map((widgets) => widgets.length === 0));

    this.createButtonDisabled$ = combineLatest([this.submitting$, noWidgetsSelected$]).pipe(
      map(([submitting, noWidgetsSelected]) => submitting || noWidgetsSelected),
    );

    this.subscriptions.add(
      this.authService
        .currentUser()
        .pipe(map((response) => response.data))
        .subscribe((userInfo) => {
          this.reportStateFacade.initialiseReport(
            `Report of: ${this.document.name}`,
            new Date(),
            userInfo.user.email,
          );
          this.cd.markForCheck();
        }),
    );

    // Scaffold/Pyro/Reclustered Results do not have NGS Graphs associated with them.
    this.hasNGSGraphs = this.document.getAllFields().hideNGSGraphs !== 'true';

    const initialisation$ = forkJoin([
      this.getCustomTables(),
      this.getResultTables(),
      this.getSequenceLogos(),
      this.getImages(),
    ]);

    this.subscriptions.add(initialisation$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.submitting$.complete();
    this.reportStateFacade.clearReport();
  }

  get filteredCustomTables() {
    return (
      this.customTableFilter
        ? this.customTables.filter((table) =>
            table.title.toLowerCase().includes(this.customTableFilter.toLowerCase()),
          )
        : this.customTables
    ).sort(naturalSortOnStringProperty('title'));
  }

  get filteredResultTables() {
    return this.resultTableFilter
      ? this.resultTables.filter((table) =>
          table.title.toLowerCase().includes(this.resultTableFilter.toLowerCase()),
        )
      : this.resultTables;
  }

  get filteredSequenceLogos() {
    return (
      this.sequenceLogoFilter
        ? this.sequenceLogos.filter((sequenceLogo) =>
            sequenceLogo.title.toLowerCase().includes(this.sequenceLogoFilter.toLowerCase()),
          )
        : this.sequenceLogos
    ).sort(naturalSortOnStringProperty('title'));
  }

  get graphs(): NewGraphWidget[] {
    return this._graphs.map((graph) => ({
      ...graph,
      data: {
        ...graph.data,
        documentID: this.document.id,
      },
    }));
  }

  get filteredGraphs() {
    return (
      this.graphFilter
        ? this.graphs.filter((chart) =>
            chart.title.toLowerCase().includes(this.graphFilter.toLowerCase()),
          )
        : this.graphs
    ).sort(naturalSortOnStringProperty('title'));
  }

  get filteredImages() {
    return (
      this.imageFilter
        ? this.images.filter((image) =>
            image.title.toLowerCase().includes(this.imageFilter.toLowerCase()),
          )
        : this.images
    ).sort(naturalSortOnStringProperty('title'));
  }

  addWidget(widget: NewReportWidget) {
    // TODO Does the widget really need to be deep cloned?
    this.reportStateFacade.addWidget(JSON.parse(JSON.stringify(widget)));
  }

  confirm() {
    this.submitting$.next(true);
    this.uploadPDFData()
      .pipe(finalize(() => this.submitting$.next(false)))
      .subscribe(() => this.activeModal.close());
  }

  uploadPDFData(): Observable<NewJobResponse> {
    return this.documentHttpService
      .create({
        parentID: this.folderId,
        name: 'Report PDF Data',
        metadata: {
          // Ensure it's not shown to the user yet until the Pipeline updates this document with the PDF Blob.
          importStatus: 'InProgress',
        },
        documentType: 'file',
      })
      .pipe(
        withLatestFrom(this.reportState$),
        switchMap(([response, reportState]) => {
          const name = 'REPORT_JSON';
          const file = new File([JSON.stringify(reportState)], name);
          const pdfDocumentID = response.documentID;
          return this.documentHttpService
            .uploadAndCommitDocumentPart(pdfDocumentID, name, file)
            .pipe(map(() => pdfDocumentID));
        }),
        withLatestFrom(this.pipelineID$),
        switchMap(([pdfDocumentID, pipelineID]) => {
          const options: ReportJobOptionsV2 = { pdfDocumentID };
          if (this.baseUrlOverride) {
            options.uiBaseUrl = this.baseUrlOverride;
          }
          const config: ReportJobParametersV2 = {
            pipeline: {
              name: pipelineID,
              version: VersionEnum.Latest,
            },
            parameters: {
              options,
              selection: {
                selectAll: false,
                folderId: this.folderId,
                ids: [this.document.id],
              },
            },
          };
          return this.jobsService.createV2(config);
        }),
      );
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private getCustomTables(): Observable<any[]> {
    const motifTables$ = this.getMotifReport().pipe(
      tap((motifs) => {
        if (motifs.length > 0) {
          this.generateMotifTable(motifs);
          this.cd.markForCheck();
        }
      }),
    );
    const parameters$ = of(this.document).pipe(
      tap((document) => {
        this.generateParameters(document);
        this.cd.markForCheck();
      }),
      catchError(() => of([])),
    );
    return forkJoin([motifTables$, parameters$]);
  }

  private getResultTables(): Observable<NewResultTableWidget[]> {
    return this.documentTableStateService.getTables(this.document.id).pipe(
      take(1),
      map((tables) =>
        tables.map((table) => ({
          title: table.displayName,
          type: WidgetType.RESULT_TABLE_WIDGET as const,
          data: {
            documentID: this.document.id,
            documentTable: table.name,
          },
        })),
      ),
      tap((data) => {
        this.resultTables.push(...data);
        this.cd.markForCheck();
      }),
    );
  }

  private getMotifReport(): Observable<{ motif: string; count: number; numSequences: number }[]> {
    if (this.document.id) {
      return this.documentHttpService
        .getDocumentPart(this.document.id, 'BX_MOTIF_REPORT', 'json')
        .pipe(
          map((report) => {
            return report.motifs
              .filter((motif: any) => motif.selected)
              .map((motif: any) => {
                return {
                  motif: motif.sequence || motif.consensus,
                  count: motif.count,
                  numSequences: motif.numSequences,
                };
              })
              .sort((a: any, b: any) => b.count - a.count);
          }),
          catchError(() => of([])),
        );
    } else {
      return of([]);
    }
  }

  private generateMotifTable(motifs: any) {
    this.customTables.push({
      title: 'Motif Analysis',
      type: WidgetType.CUSTOM_TABLE_WIDGET,
      data: {
        body: {
          headers: ['Motif', 'Count', 'Number of Sequences'],
          rows: motifs.map((motif: any) => {
            return [motif.motif, motif.count, motif.numSequences];
          }),
        },
      },
    });
  }

  private getSequenceLogos(): Observable<any> {
    return this.documentHttpService.get(this.document.id).pipe(
      map((item) => AnnotatedPluginDocument.fromNucleusItemV2(item)),
      switchMap((document) => {
        if (!document.getAllFields().childAlignments) {
          return of(null);
        }

        const ids = document
          .getAllFields()
          .childAlignments.split(',')
          .filter((id: any) => id.length > 0);
        return from(ids);
      }),
      mergeMap((id) => {
        return this.documentHttpService.getDocumentPart(id as string, 'SEQUENCE_VIEW').pipe(
          // Some alignments may have been deleted.
          catchError(() => of(null)),
        );
      }),
      tap((entity) => {
        if (entity) {
          this.generateSequenceLogos(entity);
          this.cd.markForCheck();
        }
      }),
    );
  }

  private generateSequenceLogos(entity: any) {
    this.sequenceLogos.push({
      title: entity.name,
      type: WidgetType.SEQUENCE_LOGO_WIDGET,
      data: {
        body: entity.sequences.map((sequence: any) => ({
          sequence: sequence.sequence.sequence,
          count: 1,
          type: sequence.sequence.sequenceType,
        })),
      },
    });
  }

  private getImages(): Observable<Item[]> {
    return this.filesTableFacade.files$.pipe(
      take(1),
      map((files) => files.filter((file) => file.metadata.documentType === 'Image')),
      tap((files) => {
        this.generateImages(files);
        this.cd.markForCheck();
      }),
      catchError(() => of([])),
    );
  }

  private generateImages(files: Item[]) {
    this.images = files.map((file) => ({
      title: file.metadata.name,
      type: WidgetType.IMAGE_WIDGET,
      data: {
        documentID: file.id,
      },
    }));
  }

  private generateParameters(document: AnnotatedPluginDocument) {
    if (document.getAllFields().motifAnnotatorParameters) {
      const parameters = JSON.parse(document.getAllFields().motifAnnotatorParameters);
      this.customTables.push({
        // TODO Make this more generic. Currently it is tied to motif annotator parameters.
        title: 'Motif Analysis Parameters',
        type: WidgetType.CUSTOM_TABLE_WIDGET,
        data: {
          body: this.motifAnnotatorParametersMapping(parameters),
        },
      });
    }
  }

  private motifAnnotatorParametersMapping(parameters: any): {
    headers: string[];
    rows: (string | number)[][];
  } {
    const headers: string[] = ['Parameter', 'Value'];
    const rows: (string | number)[][] = [
      [
        'Region for Motif Search',
        parameters.alignCds ? `Extract region with name: ${parameters.cdsName}` : 'Entire sequence',
      ],
      [
        'Set Motif Type',
        parameters.includeClassMotif ? 'Amino acids and amino acid classes' : 'Amino acids only',
      ],
      ['Minimum motif length', parameters.minMotifLength],
      ['Maximum motif length', parameters.maxMotifLength],
      ['Minimum motif occurrences', parameters.minMotifCount],
      ['Number of motifs to identify', parameters.numMotifs],
      [
        'Exclude amino acid(s) from motif',
        parameters.splitOn.trim().length === 0 ? '-' : parameters.splitOn.trim(),
      ],
      ['Genetic code', parameters.translation.geneticCode],
      ['Consider alternative start codons', parameters.translation.useAlternativeStartCodon],
    ];

    return {
      headers,
      rows,
    };
  }
}
