import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CleanUp } from './cleanup';

/**
 * bxFadeFormElementDirective
 *
 * Fades the component by applying the css class .opaque-50 depending on the condition.
 * This directive requires the name of the form control and when to fade. There are three types of conditions you
 * can use to decide when to fade:
 * <ul>
 * <li>fadeWhenValueMatching: Fades this component only when the form control is matching the provided value</li>
 * <li>fadeWhenValueNotMatching: Fades this component when the form control is not matching the provided value</li>
 * <li>fadeWhenFunctionIsTrue: A function that will fade the component when it evaluates to true</li>
 * </ul>
 *
 * Example usage:
 *
 * <code>
 *   <div [bxFadeFormElementDirective]="{ controlName: 'title', fadeWhenValueNotMatching: 'dontFadePlease' }"></div>
 * </code>
 *
 */
@Directive({
  selector: '[bxFadeFormElementDirective]',
  standalone: true,
})
export class FadeFormElementDirective extends CleanUp implements OnDestroy, OnInit {
  private condition: Condition;

  @Input() set bxFadeFormElementDirective(condition: Condition) {
    this.condition = condition;
  }

  @HostBinding('class.opacity-50') isSemiOpaque: boolean;

  constructor(private formGroup: FormGroupDirective) {
    super();
  }

  ngOnInit(): void {
    const condition = this.condition;
    const control = this.formGroup.form.controls[condition.controlName];

    let functionToCheckFade: (newValue: any) => boolean;
    if ('fadeWhenValueNotMatching' in condition) {
      functionToCheckFade = (newValue) => newValue !== condition.fadeWhenValueNotMatching;
    } else if ('fadeWhenValueMatching' in condition) {
      functionToCheckFade = (newValue) => newValue === condition.fadeWhenValueMatching;
    } else {
      functionToCheckFade = condition.fadeWhenFunctionIsTrue;
    }

    control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((newValue) => {
      this.isSemiOpaque = functionToCheckFade(newValue);
    });

    // Make sure initial value is correct.
    this.isSemiOpaque = functionToCheckFade(control.value);
  }
}

/**
 * A condition object containing the control name and exactly one of the evaluation methods: fadeWhenValueNotMatching,
 * fadeWhenValueMatching or fadeWhenFunctionIsTrue
 */
type Condition = {
  controlName: string;
} & (
  | { fadeWhenValueNotMatching: any }
  | { fadeWhenValueMatching: any }
  | { fadeWhenFunctionIsTrue: (newValue: any) => boolean }
);
