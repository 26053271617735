@if (state$ | async; as state) {
  <ngb-progressbar
    class="w-100"
    [value]="state.progress"
    [type]="state.barColor"
    [textType]="state.textColor"
    [showValue]="false"
    [animated]="true"
  >
    <div class="position-relative w-100">{{ state.progress }}%</div>
  </ngb-progressbar>
}
