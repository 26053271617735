<div class="modal-header">
  <h4 class="modal-title">Your API key has been created</h4>
</div>

<div class="modal-body scrollable container-fluid">
  <div class="alert alert-info" role="alert">
    This will be the only time you see your API key. Please save your API key before dismissing this
    dialog.
  </div>

  <div class="input-group">
    <input
      type="text"
      label="API Key"
      class="form-control text-center"
      name="apiKey"
      #apiKeyInput
      [value]="apiKey.apiKey"
      (focus)="selectAll($event)"
      readonly
    />
    <button class="btn btn-outline-secondary" type="button" (click)="copyApiKey()">
      <fa-icon [icon]="copyIcon"></fa-icon>
    </button>
  </div>
  @if (showApiKeyCopied | async) {
    <p class="text-center text-success my-2">API key copied.</p>
  }
  @if (showApiKeyCopyError | async) {
    <p class="text-center text-danger my-2">
      Error while copying API key. Please copy it manually.
    </p>
  }
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">OK</button>
</div>
