import { Directive, Host, HostListener, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { GridSelectionStateService } from './grid-selection-state.service';
import { Subscription } from 'rxjs';
import { DeterminateSelectionState } from '../../features/grid/grid.interfaces';
import { ClientGridV2Component } from '../../features/grid/client-grid-v2/client-grid-v2.component';

/**
 * GridSelectionStateDirective
 *
 * Used to listen to selection changes in ClientGridV2Component's and store the changes against a given key into the Store.
 */
@Directive({
  selector: '[bxGridSelectionState]',
  standalone: true,
})
export class GridSelectionStateDirective implements OnDestroy {
  @Input() key: string;

  private getSelectionStateSub = Subscription.EMPTY;

  constructor(
    @Host() private gridComponent: ClientGridV2Component,
    private gridSelectionStateService: GridSelectionStateService,
  ) {
    if (this.gridComponent == null || !(this.gridComponent instanceof ClientGridV2Component)) {
      console.log(
        'Warn: Grid Selection State Directive must only be attached to ClientGridV2Component.',
      );
    }
  }

  @HostListener('selectionChanged', ['$event']) onSelectionChanged(
    state: DeterminateSelectionState,
  ) {
    this.gridSelectionStateService.storeSelectionState(this.key, state);
  }

  ngOnDestroy() {
    this.getSelectionStateSub.unsubscribe();
  }
}
