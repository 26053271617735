import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class SetMergePairedReadsOptionsModelV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'paired-reads',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: SetMergePairedReadsJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: SetMergePairedReadsJobOptionsV1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export class SetMergePairedReadsOptionsModelV1_1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'paired-reads',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: SetMergePairedReadsJobOptionsV1_1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: SetMergePairedReadsJobOptionsV1_1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface SetMergePairedReadsJobOptionsV1 {
  pairBy: 'interlaced' | 'bySequenceList' | 'splitting' | 'byName';
  relativeOrientation:
    | 'forwardForward'
    | 'forwardForwardInvertOrder'
    | 'forwardReverse'
    | 'reverseForward';
  expectedDistance: number;
  // Read Technology.
  dataType: string;
  pairedType: string;
  isDoMerge: boolean;
}

export interface SetMergePairedReadsJobOptionsV1_1 {
  pairBy: 'interlaced' | 'bySequenceList' | 'splitting' | 'byName';
  fileNameSchemeID?: string;
  relativeOrientation:
    | 'forwardForward'
    | 'forwardForwardInvertOrder'
    | 'forwardReverse'
    | 'reverseForward';
  mergeRate: 'low' | 'normal' | 'loose' | 'vloose';
  expectedDistance: number;
  // Read Technology.
  dataType: string;
  pairedType: string;
  isDoMerge: boolean;
  iterativelyTrim: boolean;
}
