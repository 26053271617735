<div class="bx-page-container bx-page-container-sm">
  <h2>Forgot your password?</h2>
  <p class="text-muted">To reset your password, enter your email address.</p>
  <form
    #requestChangeForm="ngForm"
    name="requestChangeForm"
    (ngSubmit)="submit(requestChangeForm)"
    novalidate
    class="mt-4 mb-5"
  >
    <div class="mb-3">
      <input
        class="form-control"
        type="email"
        name="email"
        placeholder="Email"
        [(ngModel)]="email"
        [disabled]="submitting"
        required
      />
    </div>
    <div class="d-flex justify-content-end">
      @if (showCancelButton) {
        <button class="btn btn-secondary me-2" type="button" [routerLink]="['/login']">
          Cancel
        </button>
      }
      <button
        class="btn btn-primary"
        id="submit-request"
        [disabled]="requestChangeForm.invalid || submitting"
        type="submit"
        bx-spinner-button
        [spin]="submitting"
      >
        Reset Password
      </button>
    </div>
  </form>
  <div class="text-end">
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069751"
      target="_blank"
      rel="noopener noreferrer"
      title="Login help"
      >Help</a
    >
  </div>
</div>
