import { ChangeDetectionStrategy, Component, HostBinding, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class RefreshOptions {
  constructor(
    public onRefresh: Function,
    public shouldRefresh$: Observable<boolean>,
    public buttonText$: Observable<string>,
  ) {}
}

@Component({
  selector: 'bx-sankey-refresh',
  templateUrl: './sankey-refresh.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, NgClass, AsyncPipe],
})
export class SankeyRefreshComponent {
  @HostBinding('class') readonly hostClass = 'd-block overflow-hidden float-end';

  constructor(public options: RefreshOptions) {}
}
