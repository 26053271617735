import { FormStates } from '../form-state.model';
import { ngsComparisonMigration } from './ngs-comparison-migration';
import { FeaturesState } from '../../../../features/feature-switch/store/features-state';
import { alignmentMigration } from './alignment-migration';
import { ngsRegionsSelectorMigration } from './ngs-regions-selector-migration';
import { ngsDefaultLiabilitiesMigration } from './ngs-default-liabilities-migration';
import { exportMigration } from './export-migration';

export type FormStateMigrationFunction = (
  formState: FormStates,
  features: FeaturesState,
) => FormStates;

const migrations: FormStateMigrationFunction[] = [
  ngsComparisonMigration,
  alignmentMigration,
  ngsRegionsSelectorMigration,
  ngsDefaultLiabilitiesMigration,
  exportMigration,
];

export function runMigrations(formStates: FormStates, features: FeaturesState): FormStates {
  return migrations.reduce((agg, migration) => {
    try {
      return migration(agg, features);
    } catch (error) {
      console.error(`Form state migration failed: ${migration.name}`, error);
      return agg;
    }
  }, formStates);
}
