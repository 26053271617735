@if ((isInSequenceEditMode$ | async) !== true) {
  <div [ngbTooltip]="sequenceEditTooltip" placement="bottom" class="btn-pad h-100">
    <button class="btn btn-light" [disabled]="readonly" (click)="enterSequenceEditMode()">
      <fa-icon [icon]="icon.edit" class="text-dark me-2"></fa-icon>
      Edit Sequences
    </button>
  </div>
} @else {
  <div class="d-flex h-100">
    <button
      type="button"
      class="btn btn-primary"
      bx-spinner-button
      [disabled]="readonly || (isSaving$ | async) || (undoDisabled$ | async)"
      [spin]="isSaving$ | async"
      (click)="handleSave()"
    >
      Save Changes
    </button>
    <button
      type="button"
      class="btn btn-secondary btn-pad ms-2"
      [disabled]="readonly || (isSaving$ | async)"
      (click)="handleCancel()"
    >
      Cancel
    </button>
    <div class="h-100 border-end mx-4"></div>
    <div class="btn-group" role="group">
      <button
        type="button"
        ngbTooltip="Insert/Replace"
        placement="bottom"
        class="btn btn-light"
        data-testid="insert-or-replace"
        [disabled]="readonly || (isSaving$ | async) || (insertDisabled$ | async)"
        (click)="handleInsert()"
      >
        <fa-icon [icon]="icon.insert" class="text-dark"></fa-icon>
      </button>
      <button
        type="button"
        ngbTooltip="Delete"
        placement="bottom"
        class="btn btn-light"
        [disabled]="readonly || (isSaving$ | async) || (deleteDisabled$ | async)"
        (click)="handleDelete()"
      >
        <fa-icon [icon]="icon.delete" class="text-dark"></fa-icon>
      </button>
      <button
        type="button"
        ngbTooltip="Undo"
        placement="bottom"
        class="btn btn-light"
        [disabled]="readonly || (isSaving$ | async) || (undoDisabled$ | async)"
        (click)="handleUndo()"
      >
        <fa-icon [icon]="icon.undo" class="text-dark"></fa-icon>
      </button>
      <button
        type="button"
        ngbTooltip="Redo"
        placement="bottom"
        class="btn btn-light"
        [disabled]="readonly || (isSaving$ | async) || (redoDisabled$ | async)"
        (click)="handleRedo()"
      >
        <fa-icon [icon]="icon.redo" class="text-dark"></fa-icon>
      </button>
    </div>
  </div>
}
