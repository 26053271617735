import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  NgbModalRef,
  NgbDropdown,
  NgbTooltip,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import {
  SelectionStateV2,
  selectionStateV2ToSelectionState,
} from '../../../features/grid/grid.component';
import { GridState } from '../../../features/grid/grid.interfaces';
import { DocumentUtils } from '../../document-utils';
import { ExportDocumentMetadataComponent } from '../../pipeline-dialogs/export-v2/export-document-metadata/export-document-metadata.component';
import { ExportDocumentFileComponent } from '../../pipeline-dialogs/export-v2/export-document-file/export-document-file.component';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { Item } from '@geneious/nucleus-api-client';
import { AsyncPipe } from '@angular/common';
import { CleanUp } from 'src/app/shared/cleanup';

/**
 * Toolstrip "Export" button for exporting documents as downloadable files.
 */
@Component({
  selector: 'bx-export-toolstrip-item',
  templateUrl: './export-toolstrip-item.component.html',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbTooltip,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    AsyncPipe,
  ],
})
export class ExportToolstripItemComponent extends CleanUp implements OnInit, OnDestroy, OnChanges {
  @Input() gridState$: Observable<GridState>;
  @Input() selectionState: SelectionStateV2<Item>;
  @Input() folderID: string;
  @Input() disabled: boolean;

  selectedDocuments$ = this.completeOnDestroy(new BehaviorSubject<Item[]>([]));
  helpMessage$: Observable<string | null>;
  disableDropdown$: Observable<boolean>;

  private gridState: GridState;
  private modalRef: NgbModalRef;

  constructor(private pipelineDialogService: PipelineDialogService) {
    super();
  }

  ngOnChanges({ selectionState }: SimpleChanges) {
    this.selectedDocuments$.next(selectionState?.currentValue.selectedRows ?? []);
  }

  ngOnInit() {
    this.gridState$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((gridState) => (this.gridState = gridState));

    const selectionInfo$: Observable<{ documentCount: number; allUnsupported: boolean }> =
      this.selectedDocuments$.pipe(
        map((documents) => ({
          documentCount: documents.length,
          allUnsupported: documents.every(DocumentUtils.isUnsupportedExportDocument),
        })),
        takeUntil(this.ngUnsubscribe),
        shareReplay(1),
      );

    this.helpMessage$ = selectionInfo$.pipe(
      map(({ documentCount, allUnsupported }) => {
        if (documentCount === 0) {
          return 'Select at least one document to export';
        }
        return allUnsupported
          ? `The selected document${documentCount > 1 ? 's' : ''} cannot be exported`
          : null;
      }),
    );

    this.disableDropdown$ = selectionInfo$.pipe(
      map(({ allUnsupported }) => this.disabled || allUnsupported),
      startWith(this.disabled),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  openExportDialog(exportFormat: 'documentFile' | 'documentMetadata') {
    this.modalRef = this.pipelineDialogService.showDialog({
      component:
        exportFormat === 'documentFile'
          ? ExportDocumentFileComponent
          : ExportDocumentMetadataComponent,
      folderID: this.folderID,
      selected: selectionStateV2ToSelectionState(this.selectionState),
      otherVariables: {
        gridState: this.gridState,
      },
    });
  }
}
