<div class="btn-group" container="body" placement="bottom-left" ngbDropdown>
  <button
    ngbDropdownToggle
    type="button"
    class="btn btn-light dropdown-toggle"
    aria-controls="export-dropdown"
  >
    Export <span class="caret"></span>
  </button>
  <ul id="dropdown-basic" ngbDropdownMenu class="dropdown-menu" role="menu">
    <li role="menuitem">
      <button
        ngbDropdownItem
        bxSequenceViewerExportButton
        [exportType]="'document'"
        data-testid="sequenceViewerDocumentExportButton"
      >
        Export {{ (exportSequencesVisible$ | async) ? 'Full ' : '' }}Document{{
          documentCount > 1 ? 's' : ''
        }}...
      </button>
    </li>
    <li role="menuitem">
      <button ngbDropdownItem bxSequenceViewerExportButton [exportType]="'image'">
        Export as Image...
      </button>
    </li>

    @if (exportAsTableVisible$ | async) {
      <li
        role="menuitem"
        [ngbTooltip]="exportAsTableDisabledToolTip"
        [disableTooltip]="!exportAsTableDisabled"
      >
        <button
          ngbDropdownItem
          bxSequenceViewerExportButton
          [exportType]="'table'"
          [disabled]="exportAsTableDisabled"
        >
          Export {{ (exportSequencesVisible$ | async) ? 'Full Document ' : '' }}as Table...
        </button>
      </li>
    }

    @if (exportSequencesVisible$ | async) {
      <li
        role="menuitem"
        [ngbTooltip]="'Select sequences to export'"
        [disableTooltip]="(exportSequencesDisabled$ | async) === false"
        placement="right"
      >
        <button
          bxSequenceViewerExportButton
          [exportType]="'sequences'"
          [exportSequencesDialogData]="alignmentExportDialogData$ | async"
          [disabled]="exportSequencesDisabled$ | async"
          ngbDropdownItem
        >
          Export/Extract Selected Sequences...
        </button>
      </li>
    }
  </ul>
</div>
