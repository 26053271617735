<div class="alert alert-info">
  Exporting {{ banner.numRows | number }} row{{ banner.numRows === 1 ? '' : 's' }} from the
  {{ banner.table }} table
</div>

<form name="exportNgsSequencesForm" [formGroup]="form">
  <bx-card headerTitle="Sequence Options" formGroupName="sequenceOptions">
    @if (isClusterTableSelected) {
      <div class="mb-3 row">
        <label for="extractFromCluster" class="col-lg-3 col-sm-4 col-form-label">
          Extract from cluster:
        </label>
        <div class="col-lg-6 col-sm-8">
          <select
            class="form-select"
            id="extractFromCluster"
            formControlName="extractFromCluster"
            data-spec="export-extract-from-cluster"
          >
            @for (option of extractFromClusterOptions; track option) {
              <option [value]="option.value">
                {{ option.label }}
              </option>
            }
          </select>
        </div>
      </div>
    }

    <div class="mb-3 row">
      <label for="extractRegions" class="col-lg-3 col-sm-4 col-form-label">
        Trim sequences to:
      </label>
      <div class="col-lg-6 col-sm-8">
        <select
          class="form-select"
          id="extractRegions"
          formControlName="extractRegions"
          data-spec="export-extract-regions"
        >
          @for (option of extractRegionOptions; track option) {
            <option [value]="option.value">
              {{ option.label }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-0">
      <div class="form-check">
        <input
          type="checkbox"
          id="translate"
          formControlName="translate"
          class="form-check-input"
          data-spec="export-translate"
        />
        <label for="translate" class="form-check-label"> Translate output </label>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0" formGroupName="outputOptions">
    <bx-pipeline-output-name-control
      [nameFormControl]="resultNameControl"
      inputID="result-name"
      displayName="Result"
      tooltip="The name of the exported sequences document"
      labelContainerClass="col-lg-3 col-sm-4"
      inputContainerClass="col-lg-9 col-sm-8"
      class="mb-3"
    ></bx-pipeline-output-name-control>

    <bx-pipeline-output-name-control
      [nameFormControl]="outputFolderNameControl"
      inputID="folder-name"
      displayName="Folder"
      [tooltip]="outputFolderNameTooltip"
      labelContainerClass="col-lg-3 col-sm-4"
      inputContainerClass="col-lg-9 col-sm-8"
      class="mb-0"
    ></bx-pipeline-output-name-control>
  </bx-card>
</form>
