import { InjectionToken } from '@angular/core';
import { Feature } from '../../features/feature-switch/features';

// TODO Give this a type.
export const PREVIEW_MODE_VIEWER_COMPONENTS_TOKEN = new InjectionToken<any[]>(
  'Preview Mode Viewer Components',
);
export const PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS_TOKEN = new InjectionToken<any[]>(
  'Preview Mode Complex Document Viewer Components',
);
export const FULL_VIEW_MODE_VIEWER_COMPONENTS_TOKEN = new InjectionToken<any[]>(
  'Full View Mode Viewer Components',
);

interface ViewerComponentMetadata {
  // Unique identifier of the Viewer.
  key: string;
  // Title of the Viewer that will be displayed in the Viewer Tab.
  title: string | ViewerTitleFn;
  // If specified, Viewer should only be visible if this feature switch is enabled
  featureSwitch?: Feature;
}

interface ViewerComponentMetadataSelector extends ViewerComponentMetadata {
  // Used to implement viewer visibility based on current selection.
  selector: ViewerSelector;
}

interface ViewerComponentMetadataSelectors extends ViewerComponentMetadata {
  // Used to implement viewer visibility based on current selection.
  selectors: ViewerSelector[];
}

export interface ViewerComponent {
  viewerComponentMetadata: ViewerComponentMetadataSelector | ViewerComponentMetadataSelectors;
}

export type ViewerSelector<D = any> = (data: D) => boolean;
export type ViewersOverlays<D = any> = (
  data: D | null | undefined,
  numberOfDisplayedViewers: number,
) => string;
export type ViewerTitleFn<D = any> = (data: D) => string;

/**
 * ViewerComponent Decorator.
 * Used as a class decorator for Viewer Components to provide required metadata.
 *
 * @param metadata required metadata when creating a Viewer Component.
 */
export function ViewerComponent(
  metadata: ViewerComponentMetadataSelector | ViewerComponentMetadataSelectors,
) {
  return function decorator(target: any) {
    target.viewerComponentMetadata = metadata;
  };
}
