import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Observable, takeUntil, tap } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import {
  NotifierTableEvent,
  TableProcessingNotifierService,
} from './table-processing-notifier.service';
import { AsyncPipe } from '@angular/common';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

@Component({
  selector: 'bx-table-processing-notifier',
  templateUrl: './table-processing-notifier.component.html',
  providers: [TableProcessingNotifierService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SpinnerButtonComponent, NgbTooltip, AsyncPipe, ToolstripItemComponent],
})
export class TableProcessingNotifierComponent extends CleanUp {
  @Output() showNotifier = new EventEmitter<boolean>();

  readonly activity$: Observable<NotifierTableEvent> =
    this.tableProcessingNotifierService.tableStatusNotifications$.pipe(
      tap((status) => this.showNotifier.emit((status === status?.message) != null)),
      takeUntil(this.ngUnsubscribe),
    );

  constructor(private readonly tableProcessingNotifierService: TableProcessingNotifierService) {
    super();
  }
}
