import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BuildInformationService } from '../build-information.service';
import { filter, take } from 'rxjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

/**
 * A component containing a template for an "update available" toast. It
 * listens for updates and shows the toast notification when one is available.
 *
 * A cleaner approach could be to remove the ng-template wrapper and pass this
 * class directly to the ToastService for it to render (similar to NgbModal)
 * - but ToastService does not currently support that.
 */
@Component({
  selector: 'bx-update-notifier',
  templateUrl: './update-notifier.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbAlert],
})
export class UpdateNotifierComponent implements OnInit {
  @ViewChild('updateToast', { static: true }) updateToast: TemplateRef<unknown>;

  constructor(
    private readonly buildInformation: BuildInformationService,
    private readonly toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.buildInformation.newUpdateAvailable$
      .pipe(
        filter((updateAvailable) => updateAvailable),
        take(1),
      )
      .subscribe(() =>
        this.toastService.show(this.updateToast, 'default', {
          dismissible: false,
          autohide: false,
        }),
      );
  }

  refresh() {
    window.location.reload();
  }
}
