import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import {
  selectGraphParamsForNgsDocument,
  selectDataForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { SeriesColumnOptions } from 'highcharts';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { NgsReportService } from '../../../ngs-report-viewer/ngs-report.service';
import { GraphDataFor } from '../../ngs-graphs.model';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-annotation-rates-graph',
  templateUrl: './ngs-annotation-rates-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ColumnChartComponent, AsyncPipe],
})
export class NgsAnnotationRatesGraphComponent
  extends NgsBaseGraphComponent<NGSAnnotationRatesGraphData, ColumnChartComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;

  regions$: Observable<GraphOption[]>;
  region$ = new ReplaySubject<GraphOption>(1);

  ngOnInit() {
    super.ngOnInit();
    this.regions$ = this.getRegions();
    this.regions$
      .pipe(
        filter((regions) => !!regions),
        take(1),
      )
      .subscribe((regions) => {
        this.region$.next(regions[0]);
      });
    this.selectedParams$ = this.store
      .select(selectGraphParamsForNgsDocument(this.documentID))
      .pipe(takeUntil(this.ngUnsubscribe));
    this.data$ = combineLatest([
      this.store.pipe(
        selectDataForNgsDocument<'annotationRates'>(this.documentID, 'annotationRates'),
        takeUntil(this.ngUnsubscribe),
      ),
      this.region$,
    ]).pipe(
      filter(([data, region]) => !!data && !!region),
      map(([data, { name }]) => this.process(data, name)),
    );

    this.regions$
      .pipe(
        map((regions) => [
          {
            name: 'region',
            label: 'Filter By',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: regions[0]?.name,
            options: regions.map(({ name }) => {
              return {
                displayName: name,
                value: name,
              };
            }),
          } as GraphSidebarControl,
        ]),
      )
      .subscribe(this.controls$);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.region$.complete();
  }

  private getRegions() {
    return this.store.pipe(
      selectDataForNgsDocument<'annotationRates'>(this.documentID, 'annotationRates'),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
      map((data) =>
        Object.keys(data.statistics.overview.annotationRates[0])
          .filter((header) => header !== 'Region' && header !== 'As % Of')
          .map((header) => ({
            name: header,
            tableType: undefined,
            columns: undefined,
          })),
      ),
    );
  }

  private process(
    reportData: GraphDataFor<'annotationRates'>,
    region: string,
  ): NGSAnnotationRatesGraphData {
    const chartData = NgsReportService.getBarChartOfTypeWithAxis(
      reportData,
      region,
      'annotationRates',
    );
    return {
      title: chartData.title,
      xAxisTitle: chartData.xLabel,
      yAxisTitle: chartData.yLabel,
      data: chartData.data,
    };
  }

  onControlsChanged({ region }: any) {
    this.regions$.pipe(take(1)).subscribe((regions) => {
      if (!!region) {
        this.region$.next(regions.find((reg) => reg.name === region));
      } else {
        this.region$.next(null);
      }
    });
  }
}

export interface NGSAnnotationRatesGraphData {
  title: string;
  subtitle?: string;
  data: SeriesColumnOptions[];
  xAxisTitle: string;
  yAxisTitle: string;
}
