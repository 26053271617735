import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

/**
 * Handles toggling the availability (disable/enable) of each given control based on the value of a parent control's boolean value.
 * One very common use case is having a checkbox that enables/disables an input field.
 *
 * @param parentControl - control that determines whether the other controls are disabled or not. Must emit boolean values.
 * @param controls - all controls that are dependent on the state of the parentControl.
 * @param unsubscribe - a subject that will be used with takeUntil to avoid memory leaks.
 */
export function toggleControlsAvailabilityOnBoolean(
  parentControl: FormControl<boolean>,
  controls: AbstractControl[],
  unsubscribe: Subject<any>,
) {
  parentControl.valueChanges
    .pipe(startWith(parentControl.value), takeUntil(unsubscribe))
    .subscribe((value) => {
      if (value) {
        controls.forEach((control) => control.enable());
      } else {
        controls.forEach((control) => control.disable());
      }
    });
}
