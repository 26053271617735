<div class="alert alert-info mb-3">
  {{ dialogData.selected | exportingRowsSelectedMessage }}
</div>

@if (unsupportedDocuments.namesToDisplay) {
  <div class="alert alert-warning mb-3">
    <span class="alert-question-icon">
      <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069731">
        <mat-icon class="icon-small">help</mat-icon>
      </a>
    </span>
    <p class="my-0">
      {{ unsupportedDocuments.count }} documents are currently not exportable and will be skipped:
      <br />
      <span>
        {{ unsupportedDocuments.namesToDisplay }}
      </span>
      @if (unsupportedDocuments.undisplayedCount > 0) {
        <span> and {{ unsupportedDocuments.undisplayedCount }} more </span>
      }
    </p>
  </div>
}

<form name="exportDocumentForm" [formGroup]="form">
  @if (showAnnotatorResultsOptions$ | async) {
    <bx-card
      headerTitle="Annotator Results"
      formGroupName="annotatorResultOptions"
      data-spec="annotator-results-options-card"
    >
      <div class="mb-3 form-check">
        <input
          type="radio"
          id="exportResultDocumentsFalse"
          formControlName="exportResultDocuments"
          [value]="false"
          class="form-check-input"
          data-spec="export-result-documents-false"
        />
        <label for="exportResultDocumentsFalse" class="form-check-label">
          Export as sequence list
        </label>
      </div>
      <div class="mb-3 form-check">
        <input
          type="radio"
          id="exportResultDocumentsTrue"
          formControlName="exportResultDocuments"
          [value]="true"
          class="form-check-input"
          data-spec="export-result-documents-true"
        />
        <label for="exportResultDocumentsTrue" class="form-check-label">
          Export full Biologics Annotator Result document
        </label>
      </div>
    </bx-card>
  }

  @if (showSequencesFormatOptions) {
    <bx-card
      headerTitle="Sequences"
      formGroupName="sequencesFormatOptions"
      data-spec="sequences-format-options-card"
    >
      <div class="mb-3 row">
        <label for="sequencesOutputType" class="col-lg-3 col-sm-5 col-form-label">
          Sequences output type:
        </label>
        <div class="col-lg-6 col-sm-7">
          <select
            class="form-select"
            id="sequencesOutputType"
            formControlName="sequencesOutputType"
            data-spec="sequences-output-type"
          >
            @for (outputFormat of sequenceOutputTypes; track outputFormat) {
              <option [value]="outputFormat.value">
                {{ outputFormat.label }}
              </option>
            }
          </select>
        </div>
      </div>
      <div class="form-check">
        <input
          type="checkbox"
          id="mergeGeneiousDocuments"
          formControlName="mergeGeneiousDocuments"
          value="true"
          class="form-check-input"
          data-spec="merge-geneious-documents"
        />
        <label
          for="mergeGeneiousDocuments"
          class="form-check-label"
          ngbTooltip="Requires output type to be Geneious"
          [disableTooltip]="enableMergeGeneiousDocumentsOption$ | async"
          placement="bottom"
        >
          Export into a single Geneious file
        </label>
      </div>
    </bx-card>
  }
</form>
