import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-feedback-confirmation-dialog',
  templateUrl: './feedback-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FeedbackConfirmationDialogComponent implements OnInit {
  @Input() zendeskReference: number;
  link: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.link = `https://help.geneiousbiologics.com/requests/${this.zendeskReference}`;
  }

  close() {
    this.activeModal.close();
  }
}
