import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Toast, ToastService } from './toast.service';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/core.store';
import { selectIsAuthenticated } from 'src/app/core/auth/auth.selectors';
import { Observable, distinctUntilChanged, first, map, switchMap, takeUntil } from 'rxjs';
import { CleanUp } from '../cleanup';
import { NavigationEnd, Router } from '@angular/router';
import { NgClass, AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { NgbToast, NgbToastHeader } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-toasts-container',
  styleUrls: ['./toasts-container.component.scss'],
  templateUrl: './toasts-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgbToast, NgbToastHeader, AsyncPipe, NgTemplateOutlet],
})
export class ToastsContainerComponent extends CleanUp implements OnInit, OnDestroy {
  toasts$: Observable<Toast[]>;
  /** Used to shifts the toast container below the navbar when it is visible */
  navbarIsVisible$: Observable<boolean>;

  constructor(
    public readonly toastService: ToastService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.toasts$ = this.toastService.getToasts().pipe(takeUntil(this.ngUnsubscribe));

    this.navbarIsVisible$ = this.store.pipe(
      select(selectIsAuthenticated),
      distinctUntilChanged(),
      // Wait for logout or login navigation to finish before shifting the container
      switchMap((isAuthenticated) =>
        this.router.events.pipe(
          first((event) => event instanceof NavigationEnd),
          map(() => isAuthenticated),
        ),
      ),
      takeUntil(this.ngUnsubscribe),
    );
  }

  isTemplate(toast: Toast) {
    return toast.content instanceof TemplateRef;
  }

  ngOnDestroy(): void {
    this.toastService.removeAll();
  }
}
