<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    tabindex="-1"
    (click)="close()"
  ></button>
</div>

<div class="modal-body scrollable container-fluid">
  <p class="mb-2">{{ content }}:</p>

  @if (originalBases?.length) {
    <bx-monospace-box class="mb-2">{{ originalBases }}</bx-monospace-box>
    <p class="mb-2">With:</p>
  }

  <form id="editSequence" [formGroup]="form" (ngSubmit)="applyEdit()">
    <textarea
      data-testid="sequence-input"
      class="form-control text-uppercase"
      ngFormControlValidator
      [formControl]="sequenceControl"
      [canMarkPristineInvalid]="false"
      (keydown)="handleKeydown($event)"
      placeholder="ACG..."
      autofocus="true"
    ></textarea>
    @if (sequenceControl.errors?.invalid) {
      <div class="invalid-feedback">
        {{ sequenceControl.errors.invalid }}
      </div>
    }
    @if (sequenceControl.errors?.required) {
      <div class="invalid-feedback">An input sequence is required.</div>
    }
  </form>
</div>

<div class="modal-footer">
  <button
    data-testid="submit-changes"
    class="btn btn-primary"
    [disabled]="sequenceControl.value.length === 0 || form.invalid"
    type="submit"
    form="editSequence"
  >
    {{ editAction }}
    @if (sequenceControl.value.length > 0) {
      {{ sequenceControl.value.length }} {{ sequenceUnit }}
    }
  </button>
  <button class="btn btn-secondary" type="button" (click)="close()">Close</button>
</div>
