@if (renaming$ | async) {
  <form name="renameForm" (submit)="onRenameSubmit()" class="d-flex align-items-center w-100 px-1">
    @if ({ error: nameError$ | async }; as state) {
      <input
        [formControl]="nameControl"
        name="folderName"
        class="name form-control form-control-sm w-100"
        [ngClass]="{ 'is-invalid': state.error }"
        (blur)="onBlur()"
        required
        bxAutofocus
        [ngbTooltip]="state.error"
        [disableTooltip]="!state.error"
      />
    }
  </form>
} @else {
  <small [ngClass]="{ 'fw-bold': bold }">
    {{ folder.name ?? '' }}
  </small>
}

<ng-template #name>
  <small [ngClass]="{ 'fw-bold': bold }">
    {{ folder.name ?? '' }}
  </small>
</ng-template>
