import {
  AuthenticateActionLabel,
  AuthenticateErrorActionLabel,
  AuthenticateSuccessActionLabel,
  BxActions,
  CancelMfaInProgressActionLabel,
  LogoutActionLabel,
  LogoutErrorActionLabel,
  LogoutSuccessActionLabel,
  MFARequiredActionLabel,
  SubmitForgotPasswordRequestActionLabel,
  SubmitForgotPasswordRequestFailureActionLabel,
  SubmitForgotPasswordRequestSuccessActionLabel,
  VerifyAuthenticationActionLabel,
  VerifyAuthenticationFailureActionLabel,
  VerifyAuthenticationSuccessActionLabel,
} from './auth.actions';
import { CurrentUserInfo } from '@geneious/nucleus-api-client';

export interface AuthState {
  authenticated: boolean;
  error?: string;
  errorCode?: number;
  loggingIn: boolean;
  verifying?: boolean;
  submittingForgotPassword: boolean;
  userInfo?: CurrentUserInfo;
  jwt?: string;
  mfaRequired?: boolean;
}

export const initialState: AuthState = {
  authenticated: false,
  loggingIn: false,
  verifying: false,
  submittingForgotPassword: false,
  mfaRequired: false,
};

export type LoginErrorState = Pick<AuthState, 'error' | 'errorCode' | 'loggingIn'>;

export function authReducer(state: AuthState = initialState, action: BxActions): AuthState {
  switch (action.type) {
    case AuthenticateActionLabel:
      return {
        ...state,
        loggingIn: true,
        error: null,
        errorCode: null,
        authenticated: false,
        verifying: false,
      };

    case AuthenticateSuccessActionLabel:
      return {
        ...state,
        userInfo: action.payload,
        loggingIn: false,
        authenticated: true,
        verifying: false,
      };

    case AuthenticateErrorActionLabel:
      return {
        ...state,
        loggingIn: false,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
        authenticated: false,
        verifying: false,
      };

    case LogoutActionLabel:
      return { ...state, ...{ loggingIn: false } };

    case MFARequiredActionLabel:
      return { ...state, mfaRequired: true, loggingIn: false };

    case CancelMfaInProgressActionLabel:
      return { ...state, mfaRequired: false, error: null, errorCode: null };

    case LogoutSuccessActionLabel:
      return {
        ...state,
        loggingIn: false,
        authenticated: false,
        verifying: false,
      };

    case LogoutErrorActionLabel:
      return { ...state, ...{ loggingIn: false } };

    case VerifyAuthenticationActionLabel:
      return { ...state, ...{ verifying: true } };

    case VerifyAuthenticationSuccessActionLabel:
      return {
        ...state,
        ...{
          authenticated: true,
          verifying: false,
          features: {},
          userInfo: action.payload.userInfo,
          jwt: action.payload.jwt,
        },
      };

    case VerifyAuthenticationFailureActionLabel:
      return {
        ...state,
        ...{
          authenticated: false,
          verifying: false,
          features: {},
        },
      };

    case SubmitForgotPasswordRequestActionLabel:
      return {
        authenticated: false,
        mfaRequired: false,
        verifying: false,
        loggingIn: false,
        submittingForgotPassword: true,
      };

    case SubmitForgotPasswordRequestSuccessActionLabel:
      return {
        authenticated: false,
        mfaRequired: false,
        verifying: false,
        loggingIn: false,
        submittingForgotPassword: false,
      };

    case SubmitForgotPasswordRequestFailureActionLabel:
      return {
        authenticated: false,
        mfaRequired: false,
        verifying: false,
        loggingIn: false,
        submittingForgotPassword: false,
      };

    default:
      return state;
  }
}
