import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  DocumentServiceColumn,
  DocumentServiceColumnDataType,
  DocumentServiceTableInfo,
  DocumentTable,
} from '../../../../nucleus/services/documentService/types';
import { FormControlWarning } from '../../../shared/form-helpers/abstract-control-warning/abstract-control-warning';
import { sanitizeDTSTableOrColumnName } from '../../../../nucleus/services/documentService/document-service.v1';

/**
 * Validates that there are no existing columns in the master database table that match new columns from the result document group
 * but have different data types.
 * Columns with the same name but different data types require type conversion. The Master Database Importer pipeline automatically
 * handles this.
 */
export function masterDatabaseGroupColumnConflictsValidator(
  masterDatabaseTable: DocumentServiceTableInfo,
  resultDocumentGroup: DocumentTable,
): ValidatorFn {
  const masterDatabaseGroups = masterDatabaseTable.columnGroups;
  return function (control: AbstractControl): ValidationErrors | null {
    const groupToMergeIntoControl = control.get('groupToMergeInto') as FormControlWarning;
    const groupMergeRadio: 'existing' | 'new' = control.value.groupMergeRadio;
    if (groupMergeRadio === 'existing') {
      const existingGroupName: string = control.value.groupToMergeInto;
      if (existingGroupName in masterDatabaseGroups) {
        const matchingColumns = matchingColumnsWithDifferentTypes(
          existingGroupName,
          masterDatabaseTable,
          resultDocumentGroup,
        );
        if (matchingColumns.length > 0) {
          groupToMergeIntoControl.setWarnings({
            ...groupToMergeIntoControl.warnings,
            mergeConflicts: generateWarningMessage(existingGroupName, matchingColumns),
          });
          return null;
        }
      }
    }

    groupToMergeIntoControl.setWarnings(null);
    return null;
  };
}

function generateWarningMessage(
  existingGroupName: string,
  matchingColumns: MatchingColumnWithDifferentDataType[],
): string {
  let message = `${matchingColumns.length > 1 ? 'Columns' : 'Column'} `;
  message += matchingColumns.map((col) => "'" + col.displayName + "'").join(', ');
  message += ` in Group '${existingGroupName}' contain conflicting data types. An automatic conversion will be attempted.`;
  return message;
}

function matchingColumnsWithDifferentTypes(
  existingGroupName: string,
  masterDatabaseTable: DocumentServiceTableInfo,
  resultDocumentGroup: DocumentTable,
): MatchingColumnWithDifferentDataType[] {
  const masterDatabaseGroup = masterDatabaseTable.columnGroups[existingGroupName];
  const masterDatabaseGroupColumns = masterDatabaseGroup.columns.map((columnName) =>
    masterDatabaseTable.columns.find((column) => column.name === columnName),
  );
  const resultDocumentGroupColumns = resultDocumentGroup.columns;
  const matchingColumns: MatchingColumnWithDifferentDataType[] = [];
  for (const column of resultDocumentGroupColumns) {
    const matchingColumn = getMatchingColumn(existingGroupName, masterDatabaseGroupColumns, column);
    if (matchingColumn) {
      matchingColumns.push({
        displayName: matchingColumn.displayName,
        originalType: matchingColumn.dataType.kind,
        newType: column.dataType.kind,
      });
    }
  }
  return matchingColumns;
}

function getMatchingColumn(
  groupName: string,
  masterDatabaseGroupColumns: DocumentServiceColumn[],
  resultDocumentGroupColumn: DocumentServiceColumn,
) {
  return masterDatabaseGroupColumns.find((mdColumn) => {
    // All columns in existing master database groups contain a prefix of the group name like 'group1-Name'.
    // This is for allowing groups to have columns with the same name as other groups.
    const columnPrefix = sanitizeDTSTableOrColumnName(groupName) + '-';
    const index = mdColumn.name.indexOf(columnPrefix) + columnPrefix.length;
    const columnName = mdColumn.name.substring(index);
    return (
      columnName === resultDocumentGroupColumn.name &&
      mdColumn.dataType.kind !== resultDocumentGroupColumn.dataType.kind
    );
  });
}

interface MatchingColumnWithDifferentDataType {
  displayName: string;
  originalType: DocumentServiceColumnDataType;
  newType: DocumentServiceColumnDataType;
}
