import {
  DocumentSelectionSignature,
  selectionSignatureMatches,
} from './document-selection-signature/document-selection-signature.model';
import { SequenceAlphabet } from './sequence-alphabet.model';
import { AnnotatedPluginDocument } from './geneious';

export namespace SequenceUtils {
  export function isSelectionOfType(
    sequenceType: SequenceAlphabet,
    documents: AnnotatedPluginDocument[],
  ): boolean {
    if (documents) {
      let signatures: DocumentSelectionSignature[] = [];
      switch (sequenceType) {
        case SequenceAlphabet.PROTEIN:
          signatures = [DocumentSelectionSignature.forProteinSequences(1, Number.MAX_SAFE_INTEGER)];
          break;
        case SequenceAlphabet.NUCLEOTIDE:
          signatures = [
            DocumentSelectionSignature.forNucleotideSequences(1, Number.MAX_SAFE_INTEGER),
          ];
          break;
      }
      return selectionSignatureMatches(documents, signatures);
    } else {
      return false;
    }
  }
}
