<div class="w-50 my-5">
  <bx-logo width="100%"></bx-logo>
</div>
<div class="text-center mb-5">
  @if (maintenanceInfo.message) {
    <p class="lead">{{ maintenanceInfo.message }}</p>
  }
  <p>
    {{ maintenanceInfo.startTime * 1000 | date: 'medium' }} -
    {{ maintenanceInfo.endTime * 1000 | date: 'medium' }}
  </p>
  <span>
    For further information, contact us
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/requests/new"
      target="_blank"
      rel="noopener noreferrer"
      >here</a
    >
  </span>
</div>
