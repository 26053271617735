import { FormStates } from '../form-state.model';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';

// TODO Delete this migration once it has been deployed for maybe a few weeks or a month?
// Or maybe delete it once we've created/ran this migration via a database migration on Nucleus?
export function alignmentMigration(formStates: FormStates): FormStates {
  const alignmentFormState = formStates[PipelineFormID.ALIGNMENT];
  if (alignmentFormState) {
    const options = alignmentFormState.options;
    if (options.regionsToAlign) {
      return formStates;
    }

    const { alignCds: ignored1, filterByChain: ignored2, ...remainingOptions } = options;
    return {
      ...formStates,
      [PipelineFormID.ALIGNMENT]: {
        ...formStates[PipelineFormID.ALIGNMENT],
        options: {
          ...remainingOptions,
          regionsToAlign: {
            alignByRegions: options.alignCds,
          },
        },
      },
    };
  }

  return formStates;
}
