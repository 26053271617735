import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { JobSearchResult } from '@geneious/nucleus-api-client';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TemporaryPopupComponent } from '../../../shared/temporary-popup/temporary-popup.component';
import { AppState } from '../../core.store';
import { folderIsSelected } from '../../folders/store/folder.selectors';
import { fetchRunningJobsStatuses } from '../store/jobs-status/jobs-status.actions';
import { JobStatusCount } from '../store/jobs-status/jobs-status.model';
import { selectJobsStatus, selectNewJob } from '../store/jobs-status/jobs-status.selectors';
import { CleanUp } from 'src/app/shared/cleanup';
import { FolderTreeLinkComponent } from '../../folder-tree-link/folder-tree-link.component';
import { RouterLink } from '@angular/router';
import { NgClass, AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-job-tree',
  templateUrl: './job-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FolderTreeLinkComponent,
    RouterLink,
    NgClass,
    SpinnerComponent,
    MatIconModule,
    TemporaryPopupComponent,
    AsyncPipe,
  ],
})
export class JobTreeComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  @ViewChild(TemporaryPopupComponent) popupComponent: TemporaryPopupComponent;

  status$: Observable<JobStatusCount>;
  selected$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(fetchRunningJobsStatuses());

    this.selected$ = this.store
      .select(folderIsSelected('Jobs'))
      .pipe(takeUntil(this.ngUnsubscribe));
    this.status$ = this.store.select(selectJobsStatus).pipe(takeUntil(this.ngUnsubscribe));
    this.store
      .select(selectNewJob)
      .pipe(
        filter((job) => !!job),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.popupComponent.triggerPopup());
  }
}

export interface JobsByStatus {
  running: JobSearchResult[];
  completed: JobSearchResult[];
  failed: JobSearchResult[];
  cancelled: JobSearchResult[];
}
