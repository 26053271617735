<form [formGroup]="form">
  <bx-stepper linear>
    <bx-step [stepControl]="form" label="Select Format">
      <ngb-alert type="info" [animation]="false" [dismissible]="false">
        <div class="">
          Importing
          <strong>
            {{ this.totalTables$ | async }} table{{ (this.totalTables$ | async) === 1 ? '' : 's' }}
          </strong>
          from {{ this.files.length }} file{{ this.files.length === 1 ? '' : 's' }}
        </div>
      </ngb-alert>
      <p class="px-2 text-muted">
        Select how you would like to import your CSV or Excel file(s). See our
        <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625092"
          >support page</a
        >
        for more information.
      </p>
      <bx-card headerTitle="Import as">
        <div class="form-check mb-2">
          <input
            type="radio"
            id="asTableDocumentOption"
            formControlName="formatOption"
            name="formatOption"
            [value]="ImportFormat.TABLE_DOCUMENT"
            class="form-check-input"
            data-spec="table-document-option"
          />
          <label for="asTableDocumentOption" class="form-check-label">
            <span>{{
              (this.totalTables$ | async) === 1 ? 'As Table Document' : 'As Table Documents'
            }}</span>
            <div class="form-text text-muted">
              Import CSV or Excel file(s) as a viewable table that can later be combined with
              Annotation Results as Assay Data.
            </div>
          </label>
        </div>
        <div class="form-check">
          <input
            type="radio"
            id="asSequenceListOption"
            formControlName="formatOption"
            name="formatOption"
            [value]="ImportFormat.SEQUENCE_LIST"
            class="form-check-input"
            data-spec="sequence-list-option"
            ngFormControlValidator
            [enableIsValidClass]="false"
          />
          <label for="asSequenceListOption" class="form-check-label">
            <span>{{ files.length === 1 ? 'As Sequence List' : 'As Sequence Lists' }}</span>
            <div class="text-muted form-text">
              Import CSV or Excel files(s) as a sequence list that can be used for analysis.
            </div>
          </label>
          <bx-form-errors
            [control]="form.controls.formatOption"
            data-spec="batching-error"
          ></bx-form-errors>
        </div>
      </bx-card>
    </bx-step>
    @if ((isTableDocumentFormat$ | async) === true) {
      <bx-step label="Preview" [stepControl]="tableDocumentImport.form">
        <bx-document-file-import-configuration
          #tableDocumentImport
          [files]="files"
          (importOptionsChange)="onImportOptionsChange($event)"
        >
        </bx-document-file-import-configuration>
      </bx-step>
    }
    @if ((isTableDocumentFormat$ | async) === false) {
      <bx-step
        label="Configure Import"
        [stepControl]="sequenceListImport.configurationForm"
        [disabled]="(selectionValidForSequenceListImport$ | async) === false"
      >
        <bx-table-to-sequence-list-configuration
          #sequenceListImport
          [files]="files"
          (importOptionsChange)="onImportOptionsChange($event)"
        >
        </bx-table-to-sequence-list-configuration>
      </bx-step>
    }
  </bx-stepper>
</form>
