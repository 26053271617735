import { Observable } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { handleTokenValidateErrors } from '../token/token-validate-dialog-errors';
import { AuthService } from '@geneious/nucleus-api-client';

@Injectable({
  providedIn: 'root',
})
export class EmailChangeConfirmationGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    return this.authService.validateToken({ token: route.fragment }).pipe(
      pluck('data'),
      map((response) => response.changeEmail),
      catchError((error: HttpErrorResponse) => {
        return handleTokenValidateErrors(
          this.dialogService,
          this.router,
          this.config.geneiousProduct,
          error,
          'change-email',
        );
      }),
    );
  }
}
