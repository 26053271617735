import { createReducer, on } from '@ngrx/store';
import { DismissibleStates } from './dismissible.model';
import { setDismissibleState } from './dismissible.actions';

export const initialState: DismissibleStates = {};

export const dismissibleReducer = createReducer(
  initialState,
  on(setDismissibleState, (currentState, { name, state }) => ({
    ...currentState,
    [name]: state,
  })),
);
