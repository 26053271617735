<div
  class="alert alert-info d-flex justify-content-between align-items-center mx-2 mt-2 mb-0"
  role="alert"
>
  <div></div>
  <div class="text-center">
    <b>Preview Mode:</b>
    @if (totalNumberOfSequences > maximumNumberOfSequences) {
      <span> Showing first {{ maximumNumberOfSequences }} annotated sequences. </span>
    }
    To perform advanced operations, please view the full document.
  </div>
  <bx-open-document-button
    [openQueryParams]="openQueryParams"
    buttonColor="primary"
  ></bx-open-document-button>
</div>
