import { Injectable } from '@angular/core';
import { AnnotationRecord, AnnotationRecordAndIndex, DocumentRecord } from './GaalServer.service';
import { AppState } from './core.store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import Annotation from '@geneious/sequence-viewer/plugins/Annotations/Cache/Annotation';

@Injectable({
  providedIn: 'root',
})
export class GaalServerStore {
  annotations$: Observable<GaalStore>;

  constructor(private store: Store<AppState>) {
    this.annotations$ = this.store.select('annotationReducer');
  }

  /**
   * On sequence viewer load.
   *
   * TODO Consider a session id or something so there's no chance of mix up between options.
   */
  loadAnnotations(annotationRecords: any[], documentRecords: any[]) {
    this.store.dispatch({
      type: GaalActionType.LOAD_ALL,
      payload: { annotationRecords, documentRecords },
    });
  }

  clear() {
    this.store.dispatch({ type: GaalActionType.CLEAR_ALL });
  }

  /**
   * When user requests to add an annotation.
   */
  addAnnotation(sequenceIndex: number, annotation: Annotation) {
    this.getDocumentRecord(sequenceIndex).subscribe((documentRecord) => {
      const { documentId, sequenceIndexInDocument } = documentRecord;
      this.store.dispatch({
        type: GaalActionType.ADD_ANNOTATION,
        payload: { sequenceIndexInDocument, documentId, sequenceIndex, annotation },
      });
    });
  }

  editAnnotation(annotation: Annotation) {
    this.getAnnotationRecordAndIndex(annotation.id).subscribe((annotationRecord) => {
      const { documentId, sequenceIndexInDocument } = annotationRecord;
      this.store.dispatch({
        type: GaalActionType.EDIT_ANNOTATION,
        payload: { documentId, annotationId: annotation.id, sequenceIndexInDocument, annotation },
      });
    });
  }

  removeAnnotation(annotationId: string) {
    this.getAnnotationRecordAndIndex(annotationId).subscribe((annotationRecord) => {
      const { documentId, sequenceIndexInDocument } = annotationRecord;
      this.store.dispatch({
        type: GaalActionType.REMOVE_ANNOTATION,
        payload: { documentId, annotationId, sequenceIndexInDocument },
      });
    });
  }

  getAnnotationRecordAndIndex(annotationId: string): Observable<AnnotationRecordAndIndex> {
    return this.annotations$.pipe(
      // Make sure to only do this once; otherwise we forever subscribe to all changes to the store.
      first(),
      map((annotations) =>
        this._getAnnotationRecordAndIndex(annotations.annotationRecords, annotationId),
      ),
    );
  }

  getAllAnnotationsForSequence(
    documentId: string,
    sequenceIndexInDocument: number,
  ): Observable<any> {
    return this.annotations$.pipe(
      // Make sure to only do this once; otherwise we forever subscribe to all changes to the store.
      first(),
      map((records) => {
        const annotationRecords = records.annotationRecords.filter(
          (record) =>
            record.documentId === documentId &&
            record.sequenceIndexInDocument === sequenceIndexInDocument,
        );

        return { annotationRecords, documentId, sequenceIndexInDocument };
      }),
    );
  }

  getDocumentRecord(sequenceIndex: number): Observable<DocumentRecord> {
    return this.annotations$.pipe(
      // Make sure to only do this once; otherwise we forever subscribe to all changes to the store.
      first(),
      map((store) => this._getDocumentRecord(store.documentRecords, sequenceIndex)),
    );
  }

  getDocumentRecordById(documentId: string): Observable<DocumentRecord> {
    return this.annotations$.pipe(
      // Make sure to only do this once; otherwise we forever subscribe to all changes to the store.
      first(),
      map((store) => this._getDocumentRecordById(store.documentRecords, documentId)),
    );
  }

  private _getDocumentRecordById(documentStore: any[], documentId: string): DocumentRecord {
    if (!documentStore || !documentStore.length) {
      throw new Error(`No documentStore has been built.`);
    }

    const found = documentStore.find((record) => record.documentId === documentId);
    if (!found) {
      throw new Error(
        `ERROR: No documentRecord with documentId "${documentId}" could not be found.`,
      );
    }

    return found;
  }

  private _getDocumentRecord(documentStore: any[], sequenceIndex: number): DocumentRecord {
    if (!documentStore || !documentStore.length) {
      throw new Error(`No documentStore has been built.`);
    }

    const found = documentStore.find((record) => record.sequenceIndex === sequenceIndex);
    if (!found) {
      throw new Error(
        `ERROR: No documentRecord with sequenceIndex "${sequenceIndex}" could not be found.`,
      );
    }

    return found;
  }

  private _getAnnotationRecordAndIndex(
    annotationRecords: any[],
    annotationId: string,
  ): AnnotationRecordAndIndex {
    if (!annotationRecords || !annotationRecords.length) {
      throw new Error(`No annotationMap has been built.`);
    }

    const annotationIndex = annotationRecords.findIndex(
      (item) => item.annotationId === annotationId,
    );
    if (annotationIndex === -1) {
      throw new Error(`ERROR: Annotation with id "${annotationId}" could not be found.`);
    }

    const found: AnnotationRecord = annotationRecords.find(
      (item) => item.annotationId === annotationId,
    );
    return { ...found, annotationIndex };
  }
}

export enum GaalActionType {
  // Load data.
  LOAD_ALL = '[Document store] Load annotations',
  CLEAR_ALL = '[Document store] Clear all',

  // Make changes.
  ADD_ANNOTATION = '[Document store] Add annotation',
  EDIT_ANNOTATION = '[Document store] Edit annotation',
  REMOVE_ANNOTATION = '[Document store] Remove annotation',

  // Save the changes.
  DOCUMENT_UPDATE_TRIGGER = '[Document store] Request update',
  DOCUMENT_UPDATE_SUCCESSFUL = '[Document store] Update successful',
  DOCUMENT_UPDATE_FAILED = '[Document store] Update failed',
}

export interface Action {
  type: GaalActionType;
  payload: any;
}

export interface GaalStore {
  annotationRecords: AnnotationRecord[];
  documentRecords: DocumentRecord[];
}

export function annotationReducer(
  initialState: GaalStore = { annotationRecords: [], documentRecords: [] },
  action: Action,
) {
  const modified = mutate(initialState, action);
  if (action.type.startsWith('[Document store]')) {
    console.log('Mutation:', action.type, modified);
  }
  return modified;
}

function mutate(
  initialState: GaalStore = { annotationRecords: [], documentRecords: [] },
  action: Action,
) {
  switch (action.type) {
    case GaalActionType.CLEAR_ALL:
      return { annotationRecords: [], documentRecords: [] };

    case GaalActionType.LOAD_ALL:
      return {
        annotationRecords: action.payload.annotationRecords,
        documentRecords: action.payload.documentRecords,
      };

    case GaalActionType.ADD_ANNOTATION:
      const { annotation, sequenceIndex } = action.payload;
      // Get document id.
      const documentRecord = getDocumentRecord(initialState.documentRecords, sequenceIndex);

      initialState.annotationRecords.push({
        annotation,
        annotationId: annotation.id as string,
        documentId: documentRecord.documentId as string,
        sequenceIndexInDocument: documentRecord.sequenceIndexInDocument,
        sequenceIndex: documentRecord.sequenceIndex,
      });

      return initialState;

    case GaalActionType.EDIT_ANNOTATION:
      const annotationIndex = findAnnotationIndex(
        initialState.annotationRecords,
        action.payload.annotationId,
      );
      const annotationRecords = initialState.annotationRecords[annotationIndex];
      console.log('edited:', action.payload);
      Object.assign(annotationRecords.annotation, action.payload.annotation);
      return initialState;

    case GaalActionType.REMOVE_ANNOTATION:
      initialState.annotationRecords = removeAnnotation(
        initialState.annotationRecords,
        action.payload.annotationId,
      );
      return initialState;

    default:
      return initialState;
  }
}

function getDocumentRecord(
  documentRecords: DocumentRecord[],
  sequenceIndex: number,
): DocumentRecord {
  if (!documentRecords || !documentRecords.length) {
    throw new Error(`No documentStore has been built.`);
  }

  const found = documentRecords.find((record) => record.sequenceIndex === sequenceIndex);
  if (!found) {
    throw new Error(
      `ERROR: No documentRecord with sequenceIndex "${sequenceIndex}" could not be found.`,
    );
  }

  return found;
}

function findAnnotationIndex(annotationRecords: any[], annotationId: string): number {
  const annotationIndex = annotationRecords.findIndex(
    (record) => record.annotationId === annotationId,
  );
  if (annotationIndex === -1) {
    console.error(`Failed to find annotation with id "${annotationId}"`);
  }
  return annotationIndex;
}

function removeAnnotation(annotationRecords: any[], annotationId: string): any[] {
  const annotationIndex = findAnnotationIndex(annotationRecords, annotationId);
  if (annotationIndex !== -1) {
    annotationRecords.splice(annotationIndex, 1);
  }
  return annotationRecords;
}
