import { createAction, union } from '@ngrx/store';
import { FileUploadGroup } from '../../models/file-upload.model';
import { FileUploadStatus } from '../../../../nucleus/v2/models/activity-events/file-upload-event.model';

export const FileUploadAddedActionLabel = '[Upload Table] File upload added';
export const FileUploadProgressActionLabel = '[Upload Table] File upload progress';
export const FileUploadProgressByFileIDActionLabel =
  '[Upload Table] File upload progress by file ID';
export const FileUploadCancelledActionLabel = '[Upload Table] File upload cancelled';
export const ResetFileUploadStatusActionLabel = '[Upload Table] Reset file upload status';
export const LoadFileUploadsActionLabel = '[Upload Table] Load file upload';
export const InitFileUploadActionLabel = '[Upload Table] File upload init';

export const loadFileUploadTable = createAction(LoadFileUploadsActionLabel);

export const initFileUploadTable = createAction(
  InitFileUploadActionLabel,
  (uploadGroups: FileUploadGroup[]) => ({ payload: { uploadGroups } }),
);

export const addFileUpload = createAction(
  FileUploadAddedActionLabel,
  (uploadGroup: FileUploadGroup) => ({ payload: { uploadGroup } }),
);

export const updateFileUpload = createAction(
  FileUploadProgressActionLabel,
  (uploadGroup: FileUploadGroup, oldID?: string) => ({ payload: { uploadGroup, oldID } }),
);

export const updateFileUploadByFileID = createAction(
  FileUploadProgressByFileIDActionLabel,
  (fileID: string, status: FileUploadStatus) => ({ payload: { fileID, status } }),
);

export const cancelUpload = createAction(
  FileUploadCancelledActionLabel,
  (uploadGroup: FileUploadGroup) => ({ payload: { uploadGroup } }),
);

export const resetFileUploadStatus = createAction(ResetFileUploadStatusActionLabel);

const actions = union({
  loadFileUploadTable,
  initFileUploadTable,
  addFileUpload,
  updateFileUpload,
  updateFileUploadByFileID,
  resetFileUploadStatus,
  cancelUpload,
});

export type UploadActions = typeof actions;
