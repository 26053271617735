import { ViewerSelector } from '../viewers-v2/viewers-v2.config';
import {
  DocumentSelectionSignature,
  selectionSignatureMatches,
} from '../document-selection-signature/document-selection-signature.model';
import {
  IMultipleTableDocumentSelectionSignature,
  multipleTableDocumentSelectionSignatureMatches,
} from '../document-selection-signature/multiple-table-document-selection-signature.model';
import {
  isViewerDocumentData,
  isViewerResultData,
  ViewerDocumentData,
  ViewerMultipleTableDocumentSelection,
  ViewerResultData,
} from './viewer-document-data';

/**
 * This file is used for Viewer Selectors.
 *
 * Create Viewer Selector functions here to use in the @ViewerComponent metadata of each viewer to define their visibility.
 * Every Viewer Selector should have unit tests in viewer-selectors.ts.
 */

/**
 * Checks against given Viewer selection function.
 * Use this instead of annotatedPluginDocumentViewerSelector if you don't want to use Geneious Prime selection signatures.
 */
export const viewerDocumentSelector = (func: ViewerSelector<ViewerDocumentData>) => {
  return (data: ViewerDocumentData) => {
    if (!isViewerDocumentData(data)) {
      return false;
    }

    return func(data);
  };
};

/**
 * Checks against given selection signatures for Annotated Plugin Documents.
 * Also takes a ViewerSelector function as an option second argument for further selector checks.
 * e.g.
 * selector: annotatedPluginDocumentViewerSelector([
 *  DocumentSelectionSignature.forDocumentClass('com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument', 1, 1),
 * ], (selection: ViewerSelection<ViewerDocumentData>) => selection.rows[0])
 *
 * @param selectionSignatures - selection signatures to show the viewer based on the selectionSignatureMatches functions.
 * @param func - ViewerSelector function to do further selection checking with, such as checking the specific data in the rows etc.
 */
export const annotatedPluginDocumentViewerSelector = (
  selectionSignatures: DocumentSelectionSignature[],
  func?: ViewerSelector<ViewerDocumentData>,
) => {
  return (data: ViewerDocumentData) => {
    if (!isViewerDocumentData(data)) {
      return false;
    }

    const selection = data.selection;
    const doSelectionSignaturesMatch = selectionSignatureMatches(
      selection.rows,
      selectionSignatures,
    );

    if (func) {
      return doSelectionSignaturesMatch && func(data);
    }

    return doSelectionSignaturesMatch;
  };
};

export function documentTableViewerSelector(
  selectionSignatures: IMultipleTableDocumentSelectionSignature[],
  func?: ViewerSelector<ViewerResultData>,
) {
  return (data: ViewerResultData) => {
    if (!isViewerResultData(data)) {
      return false;
    }

    return matchDocumentTableSelectors(data, selectionSignatures, func);
  };
}

export const antibodyAnnotatorViewerSelector = makeAntibodyResultViewerSelector(
  DocumentSelectionSignature.forAntibodyAnnotatorDocument(1, 1),
);
export const antibodyComparisonViewerSelector = makeAntibodyResultViewerSelector(
  DocumentSelectionSignature.forAntibodyComparisonDocument(1, 1),
);

export const masterDatabaseViewerSelector =
  (func?: ViewerSelector<ViewerDocumentData>) => (data: ViewerDocumentData) => {
    if (!isViewerDocumentData(data)) {
      return false;
    }

    const selection = data.selection;
    return selection.rows.length === 1 && selection.rows[0].type === 'masterDatabase' && func(data);
  };

/**
 * Checks against given selection signatures for given selection signature (Antibody Comparison or Antibody Annotator Documents).
 * Also takes a ViewerSelector function as an option second argument for further selector checks.
 * e.g.
 * selector: makeAntibodyResultViewerSelector(DocumentSelectionSignature.forAntibodyAnnotatorDocument(1, 1))([{
 *   min: 1,
 *   max: 2147483647,
 *   tableType: DocumentTable.Sequences,
 * }], (selection: ViewerSelection<ViewerResultData>) => selection.rows[0])
 *
 * @param documentSelectionSignature - Use either DocumentSelectionSignature.forAntibodyAnnotatorDocument() or DocumentSelectionSignature.forAntibodyComparisonDocument()
 */
function makeAntibodyResultViewerSelector(documentSelectionSignature: DocumentSelectionSignature) {
  /**
   * @param selectionSignatures - selection signatures to show the viewer based on the multipleTableDocumentSelectionSignatureMatches functions.
   * @param func - ViewerSelector function to do further selection checking with, such as checking the specific data in the rows etc.
   */
  return (
    selectionSignatures: IMultipleTableDocumentSelectionSignature[],
    func?: ViewerSelector<ViewerResultData>,
  ) => {
    return (data: ViewerResultData) => {
      if (
        !isViewerResultData(data) ||
        !selectionSignatureMatches([data.selection.document], [documentSelectionSignature])
      ) {
        return false;
      }

      return matchDocumentTableSelectors(data, selectionSignatures, func);
    };
  };
}

function matchDocumentTableSelectors(
  data: ViewerResultData,
  selectionSignatures: IMultipleTableDocumentSelectionSignature[],
  func?: ViewerSelector<ViewerResultData>,
) {
  const selection: ViewerMultipleTableDocumentSelection = data.selection;
  const doSelectionSignaturesMatch = multipleTableDocumentSelectionSignatureMatches(
    selection,
    selectionSignatures,
  );

  if (func) {
    return doSelectionSignaturesMatch && func(data);
  }

  return doSelectionSignaturesMatch;
}
