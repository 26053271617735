import { map, takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectUrl } from '../../router/store/router.selectors';
import { NgClass, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-logo-nav-bar',
  templateUrl: './logo-nav-bar.component.html',
  styleUrls: ['./logo-nav-bar.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, AsyncPipe],
})
export class LogoNavBarComponent extends CleanUp {
  readonly searchOpened$: Observable<boolean>;

  private readonly searchResultsURL = '/search-results';

  constructor(private readonly store: Store<AppState>) {
    super();
    // Used for changing the styling of the NavBar if global search page is open.
    this.searchOpened$ = this.store.select(selectUrl).pipe(
      map((url) => url?.startsWith(this.searchResultsURL)),
      takeUntil(this.ngUnsubscribe),
    );
  }
}
