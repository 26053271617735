export class NameSchemeField {
  // User assigned name for this field.
  name: string;
  // Text, Ignore, Well etc.
  type: string;
  template: string;
  classification?: string;

  constructor(name: string, type: string, template: string, classification?: string) {
    this.name = name;
    this.type = type;
    this.template = template;
    this.classification = classification;
  }

  equals(other: NameSchemeField) {
    return this.name === other.name && this.type === other.type;
  }
}

export interface INameSchemeField {
  id?: number;
  name: string;
  type: string;
  template: string;
  classification?: string;
}
