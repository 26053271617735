import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormControlOptions, ValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { firstValueFrom, map, shareReplay } from 'rxjs';
import { AppState } from '../core.store';
import { selectDatabaseFolders } from '../folders/store/folder.selectors';

@Injectable({
  providedIn: 'root',
})
export class DatabaseNameValidatorsService {
  constructor(private readonly store: Store<AppState>) {}

  readonly requiredName: ValidatorFn = (ctrl) =>
    !!ctrl.value?.trim() ? null : { required: 'Enter a name for your database' };

  readonly maxLength: ValidatorFn = (ctrl) => {
    if (!ctrl.value) {
      return null;
    }
    return ctrl.value.trim().length > 128 ? { maxLength: 'This name is too long' } : null;
  };

  private readonly restrictedNames = ['aux', 'con', 'prn', '.', '..'];
  readonly notRestrictedName: ValidatorFn = (ctrl) => {
    if (!ctrl.value) {
      return null;
    }
    const name = (ctrl.value as string).trim();
    return this.restrictedNames.includes(name)
      ? { restrictedName: `This name is not allowed` }
      : null;
  };

  private readonly invalidCharacterRegex = /[\\/":<>?*+\[\]|\n\t]/g;
  readonly invalidCharacters: ValidatorFn = (ctrl) => {
    if (!ctrl.value) {
      return null;
    }
    const value = (ctrl.value as string).trim();
    const regexMatch = value.match(this.invalidCharacterRegex);
    return regexMatch
      ? {
          invalidCharacters: `Name cannot contain the following characters: ${regexMatch}`,
        }
      : null;
  };

  private readonly restrictedSuffixes = ['.aux', '.con', '.prn', '.'];
  readonly notRestrictedSuffix: ValidatorFn = (ctrl) => {
    if (!ctrl.value) {
      return null;
    }
    const name = (ctrl.value as string).trim();
    const restrictedSuffix = this.restrictedSuffixes.find((suffix) => name.endsWith(suffix));
    return restrictedSuffix
      ? { restrictedSuffix: `Name cannot end with "${restrictedSuffix}"` }
      : null;
  };

  private readonly existingDatabaseNames$ = this.store.select(selectDatabaseFolders).pipe(
    map((folders) => folders.map((folder) => folder.name)),
    shareReplay(1),
  );
  readonly uniqueName: AsyncValidatorFn = async (ctrl) => {
    if (!ctrl.value) {
      return null;
    }
    const existingNames = await firstValueFrom(this.existingDatabaseNames$);
    if (existingNames.includes(ctrl.value.trim())) {
      return { unique: 'There is already a database with that name' };
    }
    return null;
  };

  formControlOptions(): FormControlOptions {
    return {
      validators: [
        this.requiredName,
        this.maxLength,
        this.notRestrictedName,
        this.notRestrictedSuffix,
        this.invalidCharacters,
      ],
      asyncValidators: [this.uniqueName],
    };
  }
}
