import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { documentTableViewerSelector } from '../../viewer-components/viewer-selectors';
import { isViewerResultData, ViewerResultData } from '../../viewer-components/viewer-document-data';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { isComparisonTableThatSupportsSequenceViewer } from '../table-type-filters';
import {
  NgsSequenceViewerBaseComponent,
  NGSSequenceViewerData,
} from './ngs-sequence-viewer-base.component';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { SequenceViewerPreferencesService } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.service';
import { SequenceViewerMetadataService } from '../../sequence-viewer/sequence-viewer-metadata.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { SequenceViewerComponent } from '../../../features/sequence-viewer-angular/sequence-viewer.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SequenceViewerExportButtonDirective } from '../../sequence-viewer/sequence-viewer-export-button.directive';

@ViewerComponent({
  key: 'ngs-sequence-viewer',
  title: 'Sequence Viewer',
  selector: documentTableViewerSelector(
    [
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.SEQUENCES,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.ANNOTATOR_RESULT_CHAIN_COMBINATIONS,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.CLUSTERS,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.CLUSTER_GENE,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.CODON_USAGE,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.INEXACT_CLUSTER,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.COMPARISON_CLUSTERS,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.COMPARISON_CLUSTER_LENGTH,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.MASTER_DATABASE,
      },
      {
        min: 1,
        max: 2147483647,
        tableType: DocumentTableType.MASTER_DATABASE_SEARCH_RESULT,
      },
    ],
    (data: ViewerResultData) => {
      if (isComparisonTableThatSupportsSequenceViewer(data.selectedTable)) {
        return data.selection.document.getAllFields()['supportsSequenceExtraction'] === 'true';
      } else {
        return true;
      }
    },
  ),
})
@Component({
  selector: 'bx-ngs-sequence-viewer',
  templateUrl: './ngs-sequence-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    LoadingComponent,
    SequenceViewerComponent,
    NgbTooltip,
    SequenceViewerExportButtonDirective,
    AsyncPipe,
  ],
})
export class NgsSequenceViewerComponent
  extends NgsSequenceViewerBaseComponent
  implements OnInit, OnDestroy
{
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden';
  exportAsImageButtonDisabled$: Observable<boolean>;

  constructor(
    viewerDataService: ViewerDataService<NGSSequenceViewerData>,
    sequenceViewerPreferencesService: SequenceViewerPreferencesService,
    svMetadataService: SequenceViewerMetadataService,
    store: Store<AppState>,
  ) {
    super(viewerDataService, sequenceViewerPreferencesService, svMetadataService, store);
  }

  ngOnInit() {
    super.ngOnInit();
    // TODO Remove this limit once BX-5983 is finished.
    this.exportAsImageButtonDisabled$ = combineLatest([this.sequences$, this.viewerData$]).pipe(
      map(
        ([sequences, data]) =>
          sequences.length > 200 || ((isViewerResultData(data) && data.isAdminView) ?? false),
      ),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  transformViewerData$(
    initialData$: Observable<NGSSequenceViewerData>,
  ): Observable<NGSSequenceViewerData> {
    return initialData$;
  }
}
