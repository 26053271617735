import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { JobSearchResult, JobStatus } from '@geneious/nucleus-api-client';
import { Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { HELP_CENTER_URL } from 'src/app/app.constants';
import { OrgProfileCheckService } from 'src/app/shared/access-check/org-profile-check/org-profile-check.service';
import { JobActivityEventKind } from '../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  JobResultAddedActivityEvent,
  isNonStageJobEvent,
} from '../../../nucleus/v2/models/activity-events/job-activity-event.model';
import { ActivityStreamService } from '../activity/activity-stream.service';
import { JobsService } from '../jobs/job.service';
import { LogoComponent } from '../../shared/logo/logo.component';
import { QuickAnalysisComponent } from './quick-analysis/quick-analysis.component';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';

type JobState = 'NoJob' | 'JobRunning' | 'JobCompleted' | 'JobFailed';

@Component({
  selector: 'bx-getting-started-page',
  templateUrl: './getting-started-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LogoComponent,
    QuickAnalysisComponent,
    SpinnerComponent,
    NgbTooltip,
    RouterLink,
    MatIconModule,
    AsyncPipe,
  ],
})
export class GettingStartedPageComponent implements OnInit, OnDestroy {
  jobState$: Observable<JobState>;
  helpArticleLink$: Observable<string>;
  readonly loadSampleDataBtnClick$ = new Subject<void>();
  readonly sampleDataFolderLink$ = new ReplaySubject<string>(1);

  private subscriptions = new Subscription();

  constructor(
    private readonly activityStreamService: ActivityStreamService,
    private readonly jobsService: JobsService,
    private readonly router: Router,
    private readonly orgProfileCheckService: OrgProfileCheckService,
  ) {}

  ngOnInit(): void {
    this.jobState$ = this.jobsService.getUserJobs().pipe(
      switchMap((jobs) => {
        const existingJob = jobs.data.find((job) => this.isSampleDataJob(job));
        const existingJobState = this.toJobState(existingJob?.status.kind);
        // If there is an existing sample data job that's still running, wait for it to finish
        if (existingJobState === 'JobRunning') {
          return this.listenToJob(existingJob.id).pipe(
            switchMap((stateOnCompletion) => this.createJobOnButtonClick(stateOnCompletion)),
            startWith(existingJobState),
          );
        }
        if (existingJobState === 'JobCompleted') {
          this.subscriptions.add(
            this.getSampleDataFolderID(
              existingJob.id,
              existingJob.submitter.organizationID,
            ).subscribe((id) => this.updateSampleDataFolderLink(id)),
          );
        }
        return this.createJobOnButtonClick(existingJobState);
      }),
      // Handle multiple subscriptions in template
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.helpArticleLink$ = this.orgProfileCheckService
      .hasOrgProfileCategoryOnce('free')
      .pipe(
        map(
          (isFreeOrg) =>
            isFreeOrg
              ? `${HELP_CENTER_URL}hc/en-us/articles/4409440992660` /* Getting Started - Starter*/
              : `${HELP_CENTER_URL}hc/en-us/articles/360045069411` /* Getting Started - Premium*/,
        ),
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Handles the click on the button to load sample data.
   *
   * @param initialState The initial state of the job (to be displayed in the UI)
   * @returns An observable that creates a job when the button is clicked
   */
  private createJobOnButtonClick(initialState: JobState): Observable<JobState> {
    return this.loadSampleDataBtnClick$.pipe(
      // Ignore any subsequent sample data button clicks until the job has finished.
      exhaustMap(() =>
        this.jobsService
          .createV2({
            pipeline: { name: 'organization-setup', version: 1 },
            parameters: {
              selection: {},
              options: { optionType: 'EXAMPLE_DATA_LOADING' },
            },
          })
          .pipe(
            switchMap((createdJob) => this.listenToJob(createdJob.data.jobID)),
            catchError((): Observable<JobState> => of('JobFailed')),
            startWith<JobState>('JobRunning'),
          ),
      ),
      startWith(initialState),
    );
  }

  /**
   * Waits for the job to complete or fail, and updates the jobState accordingly.
   *
   * @param jobID The ID of the job to watch
   */
  private listenToJob(jobID: string): Observable<JobState> {
    return this.activityStreamService.listenToJobActivity().pipe(
      filter((jobEvent) => isNonStageJobEvent(jobEvent.event) && jobEvent.event.jobID === jobID),
      map((jobEvent) => {
        const status = jobEvent.event.kind.toString();
        if (status === JobActivityEventKind.JOB_RESULT_ADDED) {
          this.updateSampleDataFolderLink((jobEvent.event as JobResultAddedActivityEvent).resultID);
          // Wait until job completes to navigate, otherwise sample data folder takes ages to load
        } else if (status === JobActivityEventKind.JOB_COMPLETED) {
          this.subscriptions.add(
            this.sampleDataFolderLink$
              .pipe(first())
              .subscribe((link) => this.router.navigateByUrl(link)),
          );
          return status;
        } else if (status === JobActivityEventKind.JOB_FAILED) {
          return status;
        } else if (status === JobActivityEventKind.JOB_CANCELLED) {
          return 'NoJob';
        }
        return null;
      }),
      filter((status) => status !== null),
      take(1),
    );
  }

  /**
   * Updates the sampleDataFolderLink$ Subject with a new link.
   *
   * @param folderID The folder to link to
   */
  private updateSampleDataFolderLink(folderID: string): void {
    this.sampleDataFolderLink$.next(`/folders/${folderID}/files`);
  }

  /**
   * Fetches the resultID (the sample data folder ID) from a completed job.
   *
   * @param jobID The job ID
   * @returns An observable containing the sample data folder ID
   */
  private getSampleDataFolderID(jobID: string, organizationID: string): Observable<string> {
    return this.jobsService.getJobResultsByJobID(jobID, organizationID).pipe(
      filter((jobResults) => !!jobResults),
      map((jobResults) => jobResults[0]?.resultID),
      filter((folderID) => !!folderID),
      take(1),
    );
  }

  /**
   * Checks if the specified job is for loading example data.
   *
   * @param job The job to check
   * @returns True if the job loads example data
   */
  private isSampleDataJob(job: JobSearchResult): boolean {
    return (
      job.config.pipeline.name === 'organization-setup' &&
      (job.config.parameters?.options as any)?.optionType === 'EXAMPLE_DATA_LOADING'
    );
  }

  /**
   * Converts an optional JobStatus to the simplified JobState used within this class.
   * Undefined is converted to 'NoJob'.
   *
   * @param jobStatus The job status
   * @returns The corresponding JobState
   */
  private toJobState(jobStatus?: JobStatus['kind']): JobState {
    switch (jobStatus) {
      case undefined:
      case 'Cancelled':
      case 'Cancelling':
        return 'NoJob';
      case 'Completed':
        return 'JobCompleted';
      case 'Failed':
      case 'Failing':
        return 'JobFailed';
      case 'Queued':
      case 'Running':
      case 'Unknown':
      default:
        return 'JobRunning';
    }
  }
}
