import { ViewerMultipleTableDocumentSelection } from '../viewer-components/viewer-document-data';
import { DocumentTableType } from '../../../nucleus/services/documentService/document-table-type';

export interface IMultipleTableDocumentSelectionSignature {
  min: number;
  max: number;
  tableType: DocumentTableType;
}

export function isMultipleTableDocumentSelectionSignature(
  selectionSignature: IMultipleTableDocumentSelectionSignature,
): selectionSignature is IMultipleTableDocumentSelectionSignature {
  return (<IMultipleTableDocumentSelectionSignature>selectionSignature).tableType !== undefined;
}

export function isMultipleTableDocumentSelectionSignatures(
  selectionSignatures: IMultipleTableDocumentSelectionSignature[],
): selectionSignatures is IMultipleTableDocumentSelectionSignature[] {
  return (<IMultipleTableDocumentSelectionSignature[]>selectionSignatures).every(
    isMultipleTableDocumentSelectionSignature,
  );
}

export function multipleTableDocumentSelectionSignatureMatches(
  selection: ViewerMultipleTableDocumentSelection,
  selectionSignatures: IMultipleTableDocumentSelectionSignature[],
) {
  if (selectionSignatures.length === 0) {
    return false;
  }

  const totalSelected = selection.totalSelected;
  const tableType = selection.tableType;

  return selectionSignatures.some((selectionSignature) => {
    return (
      totalSelected <= selectionSignature.max &&
      totalSelected >= selectionSignature.min &&
      tableType === selectionSignature.tableType
    );
  });
}
