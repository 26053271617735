import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { RegionsSelectorAddFormComponent } from './regions-selector-add-form/regions-selector-add-form.component';
import { Chip } from '../chips';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { defer, Observable, Subscription } from 'rxjs';
import { RegionsSelectorService } from './regions-selector.service';
import { map, startWith } from 'rxjs/operators';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ChipsComponent } from '../chips/chips.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

/**
 * Wraps the chips.component.ts. Handles region selection for the Antibody Annotator and possibly other similar pipelines.
 * The RegionsSelectorAddFormComponent is responsible for showing the popover form that allows a user to add single regions or combination
 * regions to the list.
 *
 * Implements ControlValueAccessor so has full reactive form integration.
 */
@Component({
  selector: 'bx-regions-selector',
  templateUrl: './regions-selector.component.html',
  styleUrls: ['./regions-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RegionsSelectorService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegionsSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    SpinnerComponent,
    ChipsComponent,
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
    AsyncPipe,
  ],
})
export class RegionsSelectorComponent
  implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
  @HostBinding('class') readonly hostClass = 'd-flex border';
  // Filters available regions for adding based on the chain.
  @Input() filterRegionsFor: RegionSelectorChain | 'singleClone' | 'genericSequence';
  @Input() loading = false;
  @Input() readonlyRegions: Chip[] = [];

  regionsSelectorAddFormComponent = RegionsSelectorAddFormComponent;
  chipsControl = new FormControl<Chip[]>([]);
  showWarning$: Observable<boolean>;
  faExclamationTriangle = faExclamationTriangle;

  private subscriptions = new Subscription();

  constructor(private regionsSelectorService: RegionsSelectorService) {}

  // TODO Replace with a @Input setter instead?
  ngOnChanges({ filterRegionsFor }: SimpleChanges) {
    if (filterRegionsFor) {
      if (this.filterRegionsFor === 'singleClone') {
        this.regionsSelectorService.regions$.next(
          this.regionsSelectorService.getRegionsForSingleClone(),
        );
        return;
      }
      if (this.filterRegionsFor === 'genericSequence') {
        this.regionsSelectorService.regions$.next(
          this.regionsSelectorService.getRegionsForGenericSequences(),
        );
        return;
      }
      this.regionsSelectorService.regions$.next(
        this.regionsSelectorService.getRegionsForChain(this.filterRegionsFor),
      );
    }
  }

  ngOnInit() {
    const chipsValueChanges$ = this.chipsControl.valueChanges;

    this.subscriptions.add(chipsValueChanges$.subscribe((values) => this.onChange(values)));

    // Start with the current chips control value when this observable is subscribed to.
    this.showWarning$ = defer(() => {
      return chipsValueChanges$.pipe(
        startWith(this.chipsControl.value),
        map((chips) => chips.length > 9),
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.chipsControl.disable() : this.chipsControl.enable();
  }

  writeValue(chips: (Chip | CombinationRegionChip)[]): void {
    this.chipsControl.setValue(chips ?? []);
  }

  private onChange = (value: Chip[]) => {};
  private onTouched = () => {};
}

export interface CombinationRegionChip extends Chip {
  type: 'Combination';
  regions: string[];
}

export function isCombinationRegionChip(chip: Chip): chip is CombinationRegionChip {
  return 'regions' in (chip as CombinationRegionChip);
}

export type RegionSelectorChain =
  | 'light'
  | 'heavy'
  | 'bothWithLinker'
  | 'bothNoLinker'
  | 'twoHeavyWithLinker'
  | 'twoHeavyNoLinker'
  | 'genericSequence';
