import { OrganizationSetting } from './setting.model';
import { NameSchemeField } from './name-scheme-field.model';

export class NameScheme extends OrganizationSetting {
  name: string;
  fields: NameSchemeField[];
  chainIndex: number;
  cloneIndex: number;
  delimiter: string;

  /**
   * Creates a Name Scheme from a Nucleus Setting.
   * @param setting A Nucleus Setting.
   * @returns {NameScheme}
   */
  static fromSetting(setting: any) {
    const scheme = new NameScheme();
    scheme.id = setting.id;
    scheme.name = setting.name;

    if (setting.data.fields) {
      scheme.fields = setting.data.fields
        // Ignore fields which are of an unexpected format.
        .filter((field: any) => field.type && field.name)
        .map(
          (settingField: any) =>
            new NameSchemeField(settingField.name, settingField.type, settingField.template),
        );
    }

    if (setting.data.chainIndex) {
      scheme.chainIndex = setting.data.chainIndex;
    }

    if (setting.data.cloneIndex) {
      scheme.cloneIndex = setting.data.cloneIndex;
    }

    return scheme;
  }

  toSetting() {
    const setting = new OrganizationSetting();
    setting.id = this.id;
    setting.name = this.name;
    setting.kind = 'file-naming-scheme';
    setting.organizationID = this.organizationID;
    setting.data = {
      fields: this.fields,
      chainIndex: this.chainIndex,
      cloneIndex: this.cloneIndex,
      delimiter: this.delimiter,
    };

    return setting;
  }
}
