import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { Observable } from 'rxjs';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  selectDataForNgsDocument,
  selectGraphParamsForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  getTotalColumn,
  GraphDocumentDataService,
} from '../../../../../features/graphs/graph-document-data.service';
import { IBarChartInfo } from '../../../../../features/graphs/column-chart/BarChartInfo.model';
import { ExtraSelectionDataFor, GraphDataFor } from '../../ngs-graphs.model';
import { sanitizeDTSTableOrColumnName } from '../../../../../../nucleus/services/documentService/document-service.v1';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { TableMetadata } from '../../../../../../nucleus/services/documentService/types';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../../../shared/page-message/page-message.component';
import { SuffixPipe } from '../../suffix.pipe';

@Component({
  selector: 'bx-ngs-cluster-sizes-graph',
  templateUrl: './ngs-cluster-sizes-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, ColumnChartComponent, AsyncPipe, SuffixPipe],
})
export class NgsClusterSizesGraphComponent
  extends NgsBaseGraphComponent<IBarChartInfo, ColumnChartComponent>
  implements OnInit
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;

  regions$: Observable<GraphOption[]>;

  ngOnInit() {
    super.ngOnInit();
    this.selectedParams$ = this.store
      .select(selectGraphParamsForNgsDocument(this.documentID))
      .pipe(takeUntil(this.ngUnsubscribe));
    this.data$ = this.store.pipe(
      selectDataForNgsDocument<'clusterSizes'>(this.documentID, 'clusterSizes'),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
      withLatestFrom(this.store.select(selectGraphParamsForNgsDocument(this.documentID))),
      map(([data, { currentSelection }]) =>
        this.process(data, {
          name: currentSelection.selectedTable.value.displayName,
          metadata: currentSelection.selectedTable.value.metadata,
          columns: currentSelection.selectedTable.value.columns.map((col) => col.displayName),
        }),
      ),
    );
  }

  private process(
    data: GraphDataFor<'clusterSizes'>,
    region: { name: string; metadata: TableMetadata; columns: string[] },
  ): IBarChartInfo {
    const xKey = sanitizeDTSTableOrColumnName(region.name);
    const yKey = getTotalColumn(
      region.columns,
      region.metadata?.clusters?.usedBeforeCollapsingFrequencies,
    );
    const xLabel = `${region.name} (top 25)`;
    const yLabel = 'Number of sequences';
    const name = `${region.name} counts`;
    return GraphDocumentDataService.formatBarChartData(data, name, xLabel, yLabel, xKey, yKey);
  }

  static query(
    region: string,
    metadata: TableMetadata,
    columns: string[],
  ): ExtraSelectionDataFor<'clusterSizes'> {
    const xKey = sanitizeDTSTableOrColumnName(region);
    const yKey = getTotalColumn(columns, metadata?.clusters?.usedBeforeCollapsingFrequencies);
    return {
      fields: [xKey, yKey],
      orderBy: [{ kind: 'descending', field: yKey }],
      limit: 25,
    };
  }
}
