<div class="modal-body">
  <form [formGroup]="form">
    <bx-stepper linear>
      <bx-step [stepControl]="form.controls.collection" label="Select Collection">
        <div class="row align-items-center">
          <label for="collection" class="col-4 col-form-label"> Collection: </label>
          @if ({ options: masterDatabaseSelectOptions$ | async }; as state) {
            <div class="col">
              <bx-select
                id="collection"
                [selectOptions]="state.options"
                formControlName="collection"
                entityName="collection"
                emptyMessage="No Collections"
                data-testid="selectCollection"
                [showIsValidOutline]="true"
                [showFormErrors]="true"
              ></bx-select>
            </div>
          }
        </div>
        @if (getCollectionsError$ | async; as error) {
          <span class="text-danger">{{ error }}</span>
        }
      </bx-step>

      @if (
        {
          groupsLoaded: groupsLoaded$ | async,
          resultDocumentGroups: resultDocumentGroups$ | async,
        };
        as state
      ) {
        <bx-step
          [stepControl]="form.get('columnGroups')"
          formArrayName="columnGroups"
          label="Column Groups"
          [optional]="state.groupsLoaded && state.resultDocumentGroups?.length === 0"
        >
          @if (state.groupsLoaded) {
            @if (state.resultDocumentGroups?.length === 0) {
              <bx-page-message>
                <h3>No groups on result</h3>
                <p class="text-muted">This step can be skipped</p>
              </bx-page-message>
            }
            @for (group of resultDocumentGroups$ | async; track group; let i = $index) {
              <bx-collapsible-card [headerTitle]="group.displayName" [formGroupName]="i">
                <div class="mb-3">
                  <div class="form-check mb-2">
                    <input
                      type="radio"
                      id="{{ i }}-addToExistingGroupRadio"
                      formControlName="groupMergeRadio"
                      class="form-check-input"
                      value="existing"
                    />
                    <label class="form-check-label" for="{{ i }}-addToExistingGroupRadio"
                      >Add to existing group</label
                    >
                  </div>
                  <select
                    class="form-select"
                    formControlName="groupToMergeInto"
                    ngFormControlValidator
                  >
                    @for (
                      masterDatabaseGroup of masterDatabaseGroups$ | async | keyvalue;
                      track masterDatabaseGroup
                    ) {
                      <option [value]="masterDatabaseGroup.key">
                        {{ masterDatabaseGroup.value.name }}
                      </option>
                    }
                  </select>
                  @if (getGroupToMergeIntoControl(i).warnings; as warnings) {
                    <span class="text-muted">
                      {{ warnings.mergeConflicts }}
                    </span>
                  }
                </div>
                <div class="mb-3">
                  <div class="form-check mb-2">
                    <input
                      type="radio"
                      id="{{ i }}-createNewGroupRadio"
                      formControlName="groupMergeRadio"
                      class="form-check-input"
                      value="new"
                    />
                    <label class="form-check-label" for="{{ i }}-createNewGroupRadio"
                      >Create New Group</label
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="{{ i }}-newGroupNameInput"
                    formControlName="newGroupName"
                    ngFormControlValidator
                  />
                  @if (
                    columnGroupsFormArray.at(i).get('newGroupName').hasError('newGroupNameExists')
                  ) {
                    <div class="invalid-feedback">
                      {{
                        columnGroupsFormArray
                          .at(i)
                          .get('newGroupName')
                          .getError('newGroupNameExists')
                      }}
                    </div>
                  }
                </div>
              </bx-collapsible-card>
            }
          }
        </bx-step>
      }

      <bx-step label="Summary">
        @if (summary$ | async; as summary) {
          <section>
            <h3>Collection</h3>
            <p>{{ summary.collectionName }}</p>
          </section>
          @if (summary.groupsToMerge.length > 0) {
            <section>
              <h3>Groups</h3>
              @for (group of summary.groupsToMerge; track group) {
                <div>
                  <h5>{{ group.groupName }}</h5>
                  @if (group.mergingInto) {
                    <p class="text-muted">Merging into {{ group.mergingInto }}</p>
                  }
                  @if (group.newGroupName) {
                    <p class="text-muted">Creating new group {{ group.newGroupName }}</p>
                  }
                </div>
              }
            </section>
          }
        }
      </bx-step>
    </bx-stepper>
  </form>
</div>
