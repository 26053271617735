<form [formGroup]="form">
  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label for="masterDatabaseIDs" class="col-5 col-form-label">Collections:</label>
      @if ({ options: masterDatabaseSelectOptions$ | async }; as state) {
        <div class="col-7">
          <bx-multi-select
            id="masterDatabaseIDs"
            [selectOptions]="state.options"
            formControlName="masterDatabaseIDs"
            entityName="collection"
            emptyMessage="No Collections"
            ngFormControlValidator
            data-testid="masterDatabases"
          ></bx-multi-select>
          <bx-form-errors [control]="form.controls.masterDatabaseIDs"></bx-form-errors>
        </div>
      }
    </div>

    <div class="mb-3 row align-items-center">
      <label class="col-5 col-form-label" for="region"> Search Region: </label>
      <div class="col-7">
        <bx-select
          [selectOptions]="annotateRegionsOptions$ | async"
          [compareWith]="compareRegion"
          id="region"
          formControlName="region"
          data-testid="region"
          [showFormErrors]="true"
        >
          @if ((regions$ | async)?.length === 0) {
            <div bxCustomFormErrors class="small text-danger">
              No valid annotations found on selected sequences.
            </div>
          }
        </bx-select>
      </div>
    </div>

    <div class="mb-3 row">
      <label class="col-5 col-form-label"> Compare By: </label>
      <div class="col-7">
        <div
          class="form-check"
          ngbTooltip="All query sequences must be Nucleotides to compare by Nucleotide"
          [disableTooltip]="isAllNucleotide$ | async"
        >
          <input
            id="compareByNucleotide"
            type="radio"
            value="NUCLEOTIDE"
            class="form-check-input"
            [attr.disabled]="(isAllNucleotide$ | async) ? null : true"
            formControlName="compareBy"
            ngFormControlValidator
          />
          <label for="compareByNucleotide" class="form-check-label">Nucleotide</label>
          <bx-form-errors [control]="form.controls.compareBy" class="mt-0"></bx-form-errors>
        </div>
        <div class="form-check">
          <input
            id="compareByProtein"
            type="radio"
            value="PROTEIN"
            class="form-check-input"
            formControlName="compareBy"
            ngFormControlValidator
          />
          <label for="compareByProtein" class="form-check-label">Protein</label>
        </div>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <label class="col-5 col-form-label" for="minimumSimilarityThreshold">
        Match Similarity Threshold:
      </label>
      <div class="col-7">
        <div class="input-group">
          <input
            id="minimumSimilarityThreshold"
            type="number"
            class="form-control"
            formControlName="minimumSimilarityThreshold"
            ngFormControlValidator
          />
          <span class="input-group-text">%</span>
          @if (form.get('minimumSimilarityThreshold').errors; as errors) {
            <div class="invalid-feedback">
              @if (errors && errors.min) {
                <div>Minimum of {{ errors.min.min }}% is supported</div>
              }
              @if (errors && errors.max) {
                <div>Maximum of {{ errors.max.max }}% is supported</div>
              }
              @if (errors && errors.required) {
                <div>Minimum similarity threshold is required</div>
              }
            </div>
          }
        </div>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <label class="col-5 col-form-label" for="maximumHits"> Maximum number of matches: </label>
      <div class="col-7">
        <input
          id="maximumHits"
          type="number"
          class="form-control"
          formControlName="maximumHits"
          ngFormControlValidator
        />
        @if (form.get('maximumHits').errors; as errors) {
          <div class="invalid-feedback">
            @if (errors.min) {
              <div>Must show at least {{ errors.min.min }} match</div>
            }
            @if (errors.max) {
              <div>Up to {{ errors.max.max }} matches is supported</div>
            }
            @if (errors.required) {
              <div>Maximum hits is required</div>
            }
          </div>
        }
      </div>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        id="onlyCompareSameLength"
        formControlName="onlyCompareSameLength"
        value="true"
        class="form-check-input"
      />
      <label class="form-check-label" for="onlyCompareSameLength">
        Only compare regions of the same length
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [resultNameFormControl]="form.get('resultName')"></bx-pipeline-output>
  </bx-card>
</form>
