<fieldset class="mb-3 row">
  <div class="col-4">
    <legend class="col-form-label" data-spec="db-type-legend">Database type:</legend>
  </div>
  <div class="col-8 pt-1">
    @for (dbType of dbTypes$ | async; track dbType) {
      <div class="form-check">
        <input
          type="radio"
          [id]="'dbType_' + dbType.type"
          [value]="dbType.type"
          [formControl]="control"
          class="form-check-input"
          name="type"
          data-spec="db-type-radio-input"
        />
        <label
          [for]="'dbType_' + dbType.type"
          class="form-check-label d-flex align-items-center"
          data-spec="db-type-radio-label"
        >
          <mat-icon
            [svgIcon]="dbType.icon"
            class="me-1 db-icon text-secondary text-center"
          ></mat-icon>
          <span>{{ dbType.name }}</span>
        </label>
      </div>
    }
  </div>
</fieldset>
