import { createAction, props } from '@ngrx/store';
import { RemoveSvOption } from '../../../features/sequence-viewer-angular/sequence-viewer.interfaces';
import {
  SequenceViewerPreferencesState,
  SequenceViewerPreferencesStates,
} from './sequence-viewer-preferences.model';

export const setAllSequenceViewerPreferences = createAction(
  '[Sequence Viewer Preferences] Set All',
  props<{ sequenceViewerPreferencesStates: SequenceViewerPreferencesStates }>(),
);

export const upsertSequencePreferences = createAction(
  '[Sequence Viewer Preferences] Upsert',
  props<{ target: string; sequenceViewerPreferencesState: SequenceViewerPreferencesState }>(),
);

export const clearSequencePreferences = createAction(
  '[Sequence Viewer Preferences] Clear Preferences',
  props<{ target: string }>(),
);

export const removeSequencePreferencesOption = createAction(
  '[Sequence Viewer Preferences] Remove Option',
  props<{ target: string; removedOption: RemoveSvOption }>(),
);
