<div class="modal-header">
  <h4 class="modal-title">Create Field</h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="modalRef.dismiss()"
  ></button>
</div>
<div class="modal-body">
  <form id="nameSchemeForm" (submit)="submit(nameSchemeForm)" #nameSchemeForm="ngForm">
    <div class="row">
      <div class="mb-3 col">
        <label class="form-label" for="nameSchemeName">Name</label>
        <input
          id="nameSchemeName"
          type="text"
          name="nameSchemeName"
          class="form-control"
          [(ngModel)]="field.name"
          #nameInput="ngModel"
          ngFormControlValidator
          [validateOnBlur]="true"
          ngbAutofocus
          autocomplete="off"
          required
          data-testid="name-scheme-field-name"
        />
        @if (nameSchemeForm.submitted && nameInput.hasError('required')) {
          <div class="invalid-feedback">Name is required</div>
        }
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col">
        <label
          for="classification"
          ngbTooltip="A field type lets you classify this field for downstream analyses. Classifications are used to automatically associate sequences, such as when assembling forward and reverse reads or pairing reads by chain. Select the ‘Display column’ option for normal non-classification fields that will appear in results."
          >Field Type</label
        >
        <select
          [(ngModel)]="field.classification"
          id="classification"
          name="classification"
          class="form-select"
          data-testid="name-scheme-field-type"
        >
          <option [ngValue]="undefined">Display column</option>
          @for (classification of classifications; track classification) {
            <option [value]="classification.value">
              {{ classification.label }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="selectNamePartChoice"
            name="templateConstructionChoice"
            value="selectNamePartChoice"
            [formControl]="templateConstructionChoice"
          />
          <label class="form-check-label" for="selectNamePartChoice">Select Name Part</label>
        </div>
        <select
          [(ngModel)]="selectedNamePart"
          id="namePart"
          name="selectedNamePart"
          class="form-select"
          ngFormControlValidator
          [validateOnBlur]="true"
          [disabled]="selectNamePartDisabled$ | async"
          data-testid="name-scheme-field-part"
        >
          @for (token of tokensAsList; track token) {
            <option [value]="'${' + token.index + '}'">
              {{ token.value }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="sections">
      <div class="file-name-sections mb-1">
        @for (token of tokensAsList; track token) {
          <div class="section">
            <span class="index">{{ '${' + token.index + '}' }}</span>
            <span class="name">{{ token.value }}</span>
          </div>
        }
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            id="constructTemplateChoice"
            name="templateConstructionChoice"
            value="constructTemplateChoice"
            [formControl]="templateConstructionChoice"
          />
          <label class="form-check-label" for="constructTemplateChoice"
            >Construct template e.g. {{ '${0}-${1}' }} (advanced)</label
          >
        </div>
        <input
          id="template"
          type="text"
          name="template"
          class="form-control"
          [(ngModel)]="field.template"
          (ngModelChange)="onTemplateChanged($event)"
          #templateInput="ngModel"
          ngFormControlValidator
          [validateOnBlur]="true"
          [disabled]="templateConstructionDisabled$ | async"
          [nameSchemeFieldsTemplateConstructorValidator]="tokensAsList"
          autocomplete="off"
          required
        />
        @if (nameSchemeForm.submitted && templateInput.hasError('required')) {
          <div class="invalid-feedback">Template is required</div>
        }
      </div>
    </div>
    <a
      target="_blank"
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069671-How-to-Create-a-Name-Scheme"
      >How to add fields</a
    >

    @if (parsedTemplate$ | async; as result) {
      @if (result.errors.length === 0 && result.result) {
        <div class="preview">
          <h5>Preview</h5>
          {{ result.result }}
        </div>
      }
      @if (result.errors.length > 0) {
        <div class="errors">
          @for (err of result.errors; track err) {
            <div class="error">
              {{ err.message }}
            </div>
          }
        </div>
      }
    }
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="submit"
    form="nameSchemeForm"
    data-testid="name-scheme-field-create"
  >
    Create
  </button>
  <button class="btn btn-secondary" (click)="modalRef.dismiss()">Cancel</button>
</div>
