import { JobConfiguration } from '@geneious/nucleus-api-client';
import { AntibodyAnnotatorOptionValues } from 'src/app/core/pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import { ExtractSequencesOptions } from './extractSequencesV3.model';
import { OutputOptions, SelectionOptionsV1 } from './jobParameters.model';
import { NGSJobOptionsV1 } from './ngsOptions.model';

export class ExtractReanalyzeParams implements JobConfiguration {
  pipeline = {
    name: 'extract-reanalyze',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: ExtractReanalyzeOptions;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: ExtractReanalyzeOptions;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface ExtractReanalyzeOptions {
  extractSequencesOptions: ExtractSequencesOptions;
  antibodyAnnotatorOptions: NGSJobOptionsV1<AntibodyAnnotatorOptionValues>;
  resultName: string;
}
