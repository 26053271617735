<div class="mb-3">
  <p class="mb-2">
    @if (totalSelected$ | async; as totalSelected) {
      <span> {{ totalSelected }} document{{ totalSelected === 1 ? '' : 's' }} selected </span>
    } @else {
      <span>{{ noFilesSelectedMessage || 'No documents selected' }}</span>
    }
  </p>
  <hr class="my-2" />
  <div class="h-static-4 overflow-y-auto">
    @for (folder of folders$ | async; track folder) {
      <bx-file-picker-tree
        [folder]="folder"
        [fileTypes]="fileTypes"
        [filter]="filter"
      ></bx-file-picker-tree>
    }
  </div>
</div>
