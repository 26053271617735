import { Component } from '@angular/core';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

@Component({
  selector: 'bx-reference-root-folder-detail',
  templateUrl: './reference-root-folder-detail.component.html',
  standalone: true,
  imports: [PageMessageComponent],
})
export class ReferenceRootFolderDetailComponent {
  title = 'Organization Databases';
  message = `Shared with your entire organization.

             Select a database type in the folder tree to see your databases.`;
}
