import { createActionGroup, props } from '@ngrx/store';
import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import { IGridResourceResponse } from '../../../../../nucleus/services/models/response.model';
import { SelectionForGraph } from '../../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';

export const comparisonsGraphDataActions = createActionGroup({
  source: 'Comparisons Graph Data',
  events: {
    'Update Selection': props<{
      id: string;
      selection?: SelectionForGraph;
      selectedTable?: DocumentTable;
      filter?: string;
    }>(),
    'Fetch Data': props<{
      id: string;
    }>(),
    'Fetch Data Success': props<{
      id: string;
      data: IGridResourceResponse<any>;
    }>(),
    'Fetch Data Fail': props<{ id: string }>(),
  },
});
