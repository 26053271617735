<div class="alert alert-info" data-spec="export-document-count">
  Exporting information from {{ numRows | number }} document{{ numRows === 1 ? '' : 's' }}
</div>

<form name="exportDocumentMetadataForm" [formGroup]="form" class="container">
  <bx-export-table-controls
    [tableTypeControl]="form.controls.tableOutputType"
    [exportedColumnsControl]="form.controls.exportedColumns"
    [numRows]="numRows"
  ></bx-export-table-controls>
</form>
