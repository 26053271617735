import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Job } from '../../../../../nucleus/services/models/job.model';
import { JobFeedbackDialogComponent } from '../../job-feedback-dialog/job-feedback-dialog.component';
import { JobsService } from '../../job.service';
import { getFriendlyJobName, NucleusPipelineID } from '../../../pipeline/pipeline-constants';
import { SettingsBreadcrumbComponent } from '../../../../shared/breadcrumb/settings-breadcrumb.component';
import { JobDetailsComponent } from '../../../../shared/job-details/job-details.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-job-details-page',
  templateUrl: './job-details-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SettingsBreadcrumbComponent, JobDetailsComponent, RouterLink, AsyncPipe],
})
export class JobDetailsPageComponent implements OnInit, OnDestroy {
  job$: Observable<Job>;
  pipelineName$: Observable<string>;
  private jobFeedbackModalRef: NgbModalRef;

  constructor(
    private readonly jobsService: JobsService,
    private readonly ngbModal: NgbModal,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.job$ = this.route.params.pipe(
      filter((params) => !!params.jobID),
      switchMap((params) => this.jobsService.getJobFromServer(params.jobID)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.pipelineName$ = this.job$.pipe(
      map((job) =>
        getFriendlyJobName(
          job.config.pipeline.name as NucleusPipelineID,
          job.config.parameters.options,
        ),
      ),
    );
  }

  ngOnDestroy(): void {
    if (this.jobFeedbackModalRef && !!this.jobFeedbackModalRef.close) {
      this.jobFeedbackModalRef.close();
    }
  }

  openJobFeedbackDialog() {
    this.job$
      .pipe(
        map((job) => ({
          jobID: job.id,
          pipelineName: getFriendlyJobName(
            job.config.pipeline.name as NucleusPipelineID,
            job.config.parameters.options,
          ),
        })),
        first(),
        catchError(() =>
          this.route.params.pipe(
            map((params) => ({ jobID: params.jobID, pipelineName: 'Unknown' })),
          ),
        ),
      )
      .subscribe(({ jobID, pipelineName }) => {
        this.jobFeedbackModalRef = this.ngbModal.open(JobFeedbackDialogComponent, {
          centered: true,
          size: 'lg',
        });
        this.jobFeedbackModalRef.componentInstance.jobID = jobID;
        this.jobFeedbackModalRef.componentInstance.pipelineName = pipelineName;
      });
  }
}
