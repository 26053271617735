import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { clearGridState, fetchGridState, updateGridState } from './grid-state.actions';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import {
  fetchUserSettingFromServer,
  fetchUserSettingSuccess,
  upsertUserSetting,
} from '../user-settings/user-settings.actions';
import { UserSettingsKinds } from '../user-settings/user-settings-types';
import { GridServerState, GridServerStateColumn } from './grid-state.interfaces';

@Injectable()
export class GridStateStoreEffects {
  fetchGridState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchGridState),
      map(({ id }) =>
        fetchUserSettingFromServer({ name: id, kind: UserSettingsKinds.TABLE_STATE }),
      ),
    ),
  );

  fetchGridStateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingSuccess),
      filter(({ userSetting }) => userSetting.kind === UserSettingsKinds.TABLE_STATE),
      map(({ userSetting }) => ({
        id: userSetting.name,
        gridState: migrateSortModelToColumnState(
          userSetting.data as GridServerState | OldGridServerState,
        ),
      })),
      map(({ id, gridState }) => updateGridState({ id, data: gridState })),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGridState),
      // Debounce requests to avoid un-necessary load.
      debounceTime(5000),
      map(({ id, data }) =>
        upsertUserSetting({ kind: UserSettingsKinds.TABLE_STATE, name: id, data }),
      ),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearGridState),
      tap((action) => console.log(`GridState cleared for ${action.id}`)),
      map(({ id }) =>
        upsertUserSetting({
          kind: UserSettingsKinds.TABLE_STATE,
          name: id,
          data: {
            columnsState: [],
          },
        }),
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}

/**
 * This migration can be removed when we think that every active user has
 * logged in since its release.
 */
function migrateSortModelToColumnState(gridState: OldGridServerState | GridServerState) {
  if ('sortModel' in gridState) {
    const { sortModel, columnsState } = gridState;
    const migratedColumns = columnsState.map((col) => {
      const sortIndex = sortModel.findIndex(({ colId }) => colId === col.colId);
      if (sortIndex === -1) {
        return col;
      }
      return {
        ...col,
        sort: sortModel[sortIndex].sort,
        sortIndex,
      };
    });
    return { columnsState: migratedColumns };
  }
  return gridState;
}

interface OldGridServerState {
  columnsState: GridServerStateColumn[];
  sortModel: {
    colId: string;
    sort: 'asc' | 'desc';
  }[];
}
