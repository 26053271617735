<div class="alert alert-info" role="alert" data-spec="table-selection-info">
  Sending sequences from {{ banner.numRows | number }} row{{ banner.numRows === 1 ? '' : 's' }} of
  the {{ banner.table }} table
</div>
@if ((isLumaConfigExist$ | async) === false) {
  <div class="alert alert-danger" role="alert" data-spec="luma-config-error">
    Luma configuration is not found. Please configure your Luma connection
    <a [routerLink]="['/luma']" class="alert-link">here</a> before continuing.
  </div>
}

@if (form.hasError('required')) {
  <div class="alert alert-warning" role="alert">
    An override destination is required when override option is enabled.
  </div>
}

<form name="luma-options" [formGroup]="form">
  <bx-card headerTitle="Options">
    <div class="row">
      <label for="lumaSetName" class="col-md-3 col-lg-2 col-form-label">Set Name</label>
      <div class="col-md-9 col-lg-10">
        <input
          type="text"
          id="lumaSetName"
          formControlName="setName"
          data-spec="luma-set-name"
          placeholder="Default"
          class="form-control"
        />
      </div>
    </div>
  </bx-card>

  @if (isLocalOrDevEnvironment) {
    <bx-card headerTitle="Developer Options" customClass="mb-0">
      @if (appsStatus$ | async; as lumaStatus) {
        @switch (lumaStatus) {
          @case ('loading') {
            <div class="w-100 h-100 bg-white d-flex align-items-center justify-content-center">
              <bx-spinner></bx-spinner><span class="ms-2">Connecting to Luma...</span>
            </div>
          }
          @case ('error') {
            <span class="text-muted"> Unable to connect to Luma </span>
          }
          @default {
            <div class="mb-3 form-check">
              <input
                type="checkbox"
                name="overrideDestination"
                formControlName="overrideDestination"
                id="overrideDestination"
                class="form-check-input"
                data-spec="luma-destination-override-checkbox"
              />
              <label for="overrideDestination" class="form-check-label">
                Override Application & Data Source
              </label>
            </div>
            @if ({ status: appsStatus$ | async, options: appOptions$ | async }; as state) {
              <div class="mb-3 row">
                <label
                  for="appID"
                  class="col-md-3 col-lg-2 col-form-label d-flex"
                  data-spec="luma-app-id-label"
                >
                  <span class="me-2">App</span>
                  @if (state.status === 'loading') {
                    <bx-spinner class="ms-auto"></bx-spinner>
                  }
                </label>
                <div class="col-md-9 col-lg-10">
                  <select
                    id="appID"
                    name="appID"
                    formControlName="appVersionID"
                    class="form-select"
                    ngFormControlValidator
                    [validateOnBlur]="true"
                    [canMarkPristineInvalid]="false"
                    data-spec="luma-app-id-input"
                  >
                    <option></option>
                    @for (option of state.options; track trackByValue($index, option)) {
                      <option [value]="option.value" data-spec="luma-app-id-option">
                        {{ option.displayName }}
                      </option>
                    }
                  </select>
                  <bx-form-errors
                    [control]="form.get('appVersionID')"
                    [showAllErrors]="false"
                    data-spec="luma-app-id-error"
                  ></bx-form-errors>
                </div>
              </div>
            }
            @if (
              {
                status: dataSourcesStatus$ | async,
                options: dataSourceOptions$ | async,
              };
              as state
            ) {
              <div class="mb-3 row">
                <label
                  for="dataSourceID"
                  class="col-md-3 col-lg-2 col-form-label d-flex"
                  data-spec="luma-data-source-label"
                >
                  <span class="me-2">Data Source</span>
                  @if (state.status === 'loading') {
                    <bx-spinner class="ms-auto"></bx-spinner>
                  }
                </label>
                <div class="col-md-9 col-lg-10">
                  <select
                    id="dataSourceID"
                    name="dataSourceID"
                    formControlName="dataSourceID"
                    class="form-select"
                    ngFormControlValidator
                    data-spec="luma-data-source-input"
                  >
                    <option></option>
                    @for (source of state.options; track trackByValue($index, source)) {
                      <option [value]="source.value" data-spec="luma-data-source-option">
                        {{ source.displayName }}
                      </option>
                    }
                  </select>
                  <bx-form-errors
                    [control]="form.get('dataSourceID')"
                    [showAllErrors]="false"
                    data-spec="luma-data-source-error"
                  ></bx-form-errors>
                </div>
              </div>
            }
          }
        }
      }
    </bx-card>
  }
</form>
