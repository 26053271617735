import { BehaviorSubject, combineLatest, merge, Observable, of as observableOf, zip } from 'rxjs';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  catchError,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FolderService } from '../folders/folder.service';
import ViewerState from '../viewer/viewer-state/viewer-state.model';
import { SelectionState } from '../../features/grid/grid.component';
import { Title } from '@angular/platform-browser';
import { ViewerPageService } from './viewer-page.service';
import { Folder, FolderTreeItem } from '../folders/models/folder.model';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { allMatch } from '../../../bx-operators/all-match';
import { DocumentUtils } from '../document-utils';
import { selectionStateToViewerDocumentSelection } from '../viewer-components/viewers-helper';
import { Search } from '../search/model/search.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { GlobalSearchTypes } from '../search/search-bar/search-bar.component';
import { OverlaysService } from '../../shared/overlays/overlays.service';
import { ViewerDocumentData } from '../viewer-components/viewer-document-data';
import { elapsedDays } from '../../shared/utils/date';
import { OrganizationProfileService } from '../organisation/organization-profile.service';
import { FolderUtils } from '../folders/folder.utils';
import { OrgProfileCheckService } from '../../shared/access-check/org-profile-check/org-profile-check.service';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  fetchFolderChildren,
  fetchFolderIfNeeded,
  fetchTopLevelFolderAccess,
} from '../folders/store/folder.actions';
import { selectUserInfo } from '../auth/auth.selectors';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DocumentExpiryBadgeComponent } from '../../shared/document-expiry-badge/document-expiry-badge.component';
import { ViewersComponent } from '../viewers-v2/viewers/viewers.component';
import { PageMessageComponent } from '../../shared/page-message/page-message.component';

@Component({
  selector: 'bx-viewer-page',
  templateUrl: './viewer-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewerPageService],
  standalone: true,
  imports: [
    RouterLink,
    FaIconComponent,
    DocumentExpiryBadgeComponent,
    ViewersComponent,
    PageMessageComponent,
    AsyncPipe,
  ],
})
export class ViewerPageComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';

  params$: Observable<ViewerPageURLSelectionState>;
  folder$: Observable<FolderTreeItem>;
  documents$: Observable<any[]>;
  selectionTitle$: Observable<string>;
  isRetrievingDocuments$: Observable<boolean>;
  error$: BehaviorSubject<string>;
  sequenceViewerState$: Observable<ViewerState>;
  initialFilter$: Observable<string>;
  selectionState$: Observable<SelectionState>;
  isEntireSelectionComparisonDocs$: Observable<boolean>;
  viewersSelection$: Observable<ViewerDocumentData>;
  remainingDays$: Observable<number>;
  expirable$: Observable<boolean>;
  isAdminView$: Observable<boolean>;
  isDocumentDetailsView$: Observable<boolean>;
  isNewTab$: Observable<boolean>;

  readonly faArrowLeft = faArrowLeft;
  private readonly defaultTitle: string;

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private folderService: FolderService,
    private title: Title,
    private store: Store<AppState>,
    private viewerPageService: ViewerPageService,
    private orgProfileService: OrganizationProfileService,
    private overlaysService: OverlaysService,
    private orgProfileCheckService: OrgProfileCheckService,
  ) {
    this.error$ = new BehaviorSubject(null);

    this.defaultTitle = this.title.getTitle();

    this.isDocumentDetailsView$ = this.activatedRoute.url.pipe(
      map((url) => url[0].path === 'document'),
    );

    this.isNewTab$ = this.activatedRoute.url.pipe(map((url) => url[0].path === 'view'));

    // When opening a new tab we need to repopulate the store, there might be more things to populate, but for now let's
    // just do the reference databases and the workspace folders.
    const userInfo$ = this.store.pipe(
      select(selectUserInfo),
      first((userInfo) => !!userInfo),
    );
    combineLatest([this.isNewTab$, userInfo$])
      .pipe(filter(([newTab]) => newTab))
      .subscribe(([, userInfo]) => {
        this.store.dispatch(fetchTopLevelFolderAccess({ id: userInfo.referenceDatabaseFolderID }));
        this.store.dispatch(fetchFolderChildren({ id: userInfo.sharedWorkspaceFolderID }));
      });

    this.params$ = this.activatedRoute.queryParams.pipe(
      map((params) => {
        const ids = Array.isArray(params.ids) ? params.ids : [params.ids];
        return {
          selectAll: params.selectAll === 'true',
          folderID: params.folderID,
          ids: params.ids ? ids : [],
          filter: params.filter || '',
          isAdminView: params.isAdminView ?? false,
          edit: params.edit === 'true',
        };
      }),
      switchMap((params) => {
        // Try to guess parent folder if we don't know it yet.
        if (!params.folderID && !params.selectAll) {
          // This is likely a redirect from the search table, which does not know parent folder IDs.
          return this.folderService.getParentFolderIDs(params.ids).pipe(
            map((parentFolderIDs) => {
              // Check that selected files only come from a single parent folder.
              if (parentFolderIDs.length === 1) {
                params.folderID = parentFolderIDs[0];
              }
              // Update query params without reloading page (this is for other components that might rely on query params)
              const updatedParams = {
                ...params,
                isAdminView: params.isAdminView || undefined,
                filter: params.filter || undefined,
              };
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: updatedParams,
                queryParamsHandling: 'merge',
              });
              return params;
            }),
          );
        }
        return observableOf(params);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // Filter for result document sequences table, intended to show relevant sequences after a redirect from the global search table.
    this.initialFilter$ = this.params$.pipe(
      // Only set a filter based on search if it is requested in URL and document selection is appropriate.
      filter((params) => params.filter === 'TRUE' && params.ids.length === 1),
      switchMap(() =>
        this.store.select('searchReducer').pipe(
          map((state: Search.State) => state.query),
          // If the search type is files table then filtering the result table would make no sense.
          filter((state) => state.queryType === GlobalSearchTypes.RESULT),
          map((state) => state.queryString),
          map((filterBy) => `['Heavy CDR3']\='${filterBy}' OR ['Light CDR3']\='${filterBy}'`),
        ),
      ),
    );

    this.folder$ = this.params$.pipe(
      filter((params) => params.folderID != null),
      //In case of nuclues admin querying some other org's documents in pop out view the folder service cant query the parent folder id
      //directly from the store as store contains own org's folders only. Therefore, if the folder is not in folder store it should retrieve
      //from server.
      tap((params) => this.store.dispatch(fetchFolderIfNeeded({ folderID: params.folderID }))),
      switchMap((params) => this.folderService.get(params.folderID)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.documents$ = this.params$.pipe(
      switchMap((params: ViewerPageURLSelectionState) => {
        return this.viewerPageService.fetchDocuments(params).pipe(
          map((documents) => {
            if (documents.length === 0) {
              throw new Error('Unable to retrieve any documents!');
            }
            return documents;
          }),
          catchError((error, caught) => {
            console.log('Error caught', error);
            this.error$.next(error.message);

            if (error.message === 'Unable to retrieve any documents!') {
              return caught;
            }
            return observableOf([]);
          }),
          take(3), // Retry fetching three times if we were unable to fetch any documents
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.isAdminView$ = this.params$.pipe(map((params) => params.isAdminView ?? false));

    const expiryDays$ = this.orgProfileService.getCurrentOrgProfileDocumentExpiryDays();
    this.expirable$ = expiryDays$.pipe(
      withLatestFrom(this.folder$, this.documents$),
      map(([expiryDays, parentFolder, documents]) => {
        const isHasDocumentCanExpire = documents.some((doc) => doc.canExpire ?? true);
        return (
          FolderUtils.isFolder(parentFolder as Folder) && isHasDocumentCanExpire && expiryDays > 0
        );
      }),
    );

    this.remainingDays$ = expiryDays$.pipe(
      withLatestFrom(this.documents$),
      map(([expiryDays, documents]) =>
        documents
          .filter((doc) => doc.canExpire ?? true)
          .map((document) => {
            const createTime = new Date(document.createdAt).getTime();
            return Math.ceil(expiryDays - elapsedDays(createTime));
          }),
      ),
      map((remainingDays) => Math.min.apply(null, remainingDays)),
      // In case all documents can't expire, remainingDays will be Infinity
      map((remainingDays) => (isFinite(remainingDays) ? remainingDays : 0)),
    );

    this.selectionTitle$ = this.documents$.pipe(
      map((docs) =>
        docs.length === 1 ? docs[0].metadata.name : docs.length + ' documents selected',
      ),
      tap((selectionTitle) => this.title.setTitle(selectionTitle)),
    );

    this.isRetrievingDocuments$ = merge(
      this.params$.pipe(map(() => true)),
      this.documents$.pipe(map(() => false)),
    );

    const paramsAndDocs$ = zip(this.params$, this.documents$).pipe(
      map((zipped) => ({ params: zipped[0], docs: zipped[1] })),
    );

    this.sequenceViewerState$ = paramsAndDocs$.pipe(
      map(
        (zipped) =>
          <ViewerState>{
            selectAll: zipped.params.selectAll,
            total: zipped.docs.length, // TODO Fix. Not correct total.
            ids: zipped.params.ids,
            rows: zipped.docs,
            totalSelected: zipped.docs.length,
          },
      ),
    );

    this.selectionState$ = paramsAndDocs$.pipe(
      map(
        (zipped) =>
          <SelectionState>{
            selectAll: zipped.params.selectAll,
            totalNoOfRows: zipped.docs.length, // TODO Fix. Not correct total.
            ids: zipped.params.ids,
            selectedRows: zipped.docs,
            noOfRowsSelected: zipped.docs.length,
          },
      ),
    );
  }

  ngOnInit() {
    this.isEntireSelectionComparisonDocs$ = this.selectionState$.pipe(
      map((selectionState) => selectionState.selectedRows),
      allMatch((row) => DocumentUtils.isComparisonDoc(row)),
    );

    this.viewersSelection$ = zip(
      this.selectionState$,
      this.folder$,
      this.orgProfileCheckService.hasOrgProfileCategory('free'),
      this.isAdminView$,
      this.params$,
    ).pipe(
      map(([state, folder, isFreeOrg, isAdminView, params]) => ({
        selection: selectionStateToViewerDocumentSelection(state, folder),
        isFreeOrg,
        isAdminView,
        openQueryParams: params,
      })),
    );
  }

  ngOnDestroy() {
    // Reset title to what it was (i.e. Geneious Biologics).
    this.title.setTitle(this.defaultTitle);
  }
}

export interface ViewerPageURLSelectionState {
  selectAll: boolean;
  folderID: string;
  ids?: string[];
  filter?: string;
  isAdminView?: boolean;
  edit?: boolean;
}
