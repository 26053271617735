<div class="modal-header">
  <h4 class="modal-title">Create Primer Set</h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="close('canceled')"
    data-spec="create-ref-db-close-btn"
  ></button>
</div>

<div class="modal-body">
  <form (submit)="createSet(createSetForm)" #createSetForm="ngForm" id="setForm">
    <div class="row mb-0">
      <div class="col-sm-3">
        <label for="setName" class="col-form-label">Set Name</label>
      </div>
      <div class="col-sm">
        <input
          id="setName"
          class="form-control"
          placeholder="Set"
          name="setName"
          [(ngModel)]="setName"
          #setNameInput="ngModel"
          required
          ngFormControlValidator
          [canMarkPristineInvalid]="false"
          [enableIsValidClass]="false"
        />
        @if (setNameInput.hasError('required')) {
          <div class="invalid-feedback">Name is required</div>
        }
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="submit"
    form="setForm"
    bx-spinner-button
    [spin]="submitting"
    [disabled]="submitting || createSetForm.invalid"
    (click)="createSet(createSetForm)"
  >
    Create
  </button>
  <button class="btn btn-secondary" (click)="close('canceled')">Cancel</button>
</div>
