import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ViewersStateStoreService } from './viewer-state-store/viewers-state-store.service';
import { viewerStatesReducer } from './viewer-state-store/viewers-state.reducers';
import { ViewersStateEffects } from './viewers-state.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('viewerStates', viewerStatesReducer),
    EffectsModule.forFeature([ViewersStateEffects]),
  ],
  providers: [ViewersStateStoreService],
})
export class ViewersStateModule {}
