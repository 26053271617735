import { AppState } from '../../core.store';
import { createSelector } from '@ngrx/store';

export const selectFoldersExpandState = (state: AppState) => state.folderExpands;

export const selectFolderExpandState = (id: string) =>
  createSelector(selectFoldersExpandState, (folders) => folders.expanded[id]);

export const selectRenameState = createSelector(selectFoldersExpandState, (state) => state.rename);

export const selectIsRenaming = (folderID: string) =>
  createSelector(selectRenameState, (rename) => rename?.folderID === folderID);

export const selectNewFolderState = createSelector(
  selectFoldersExpandState,
  (state) => state.newFolder,
);

export const selectHasNewFolderChild = (parentID: string) =>
  createSelector(selectNewFolderState, (newFolder) => newFolder?.parentID === parentID);

export const selectRenameError = createSelector(selectRenameState, (state) => state.error);
