<button
  class="bx-gutter-button btn btn-light border-start-0 border-bottom-0 border-top-0 rounded-0 d-flex align-items-center justify-content-center"
  (click)="toggleHidden()"
>
  <small class="toggle-label text-nowrap"
    >Table Preferences <fa-icon [icon]="(hidden$ | async) ? 'chevron-up' : 'chevron-down'"></fa-icon
  ></small>
</button>

@if ({ hidden: hidden$ | async }; as state) {
  <div
    class="w-static-1 p-0 flex-column"
    [ngClass]="{ 'd-none': state.hidden, 'd-flex': !state.hidden }"
  >
    @if (targetComponent) {
      <bx-grid-sidebar-profiles-management
        [gridState]="gridState"
        [targetComponent]="targetComponent"
        (profileApplied)="onApplyProfile($event)"
        class="mt-2"
      ></bx-grid-sidebar-profiles-management>
    }
    <div class="flex-grow-1 flex-shrink-1 overflow-y-scroll overflow-x-hidden px-3 my-2">
      <bx-column-management-sidebar-item
        [columns]="filteredColumns$ | async"
        [targetComponent]="targetComponent"
        (columnsVisibilityChanged)="columnsVisibilityChanged.emit($event)"
        (newGridState)="newGridState.emit($event)"
        (resetGridState)="resetGridState.emit($event)"
        (focusColumn)="focusColumn.emit($event)"
        data-testid="column-management"
      ></bx-column-management-sidebar-item>
    </div>
  </div>
}
