import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FolderTreeIcon } from '../folders/models/folder.model';
import { FolderTreeIconComponent } from '../folder-tree/folder-tree-icon/folder-tree-icon.component';

/**
 * Displays an icon and some text in the style of the folder tree. Designed to
 * be attached to an `a` element. Originally the `a` element was in this
 * component's template, but conditionally handling internal and external links
 * is so unwieldy (https://github.com/angular/angular/issues/24567) that I
 * thought it'd be better to let the user handle setting href or routerLink.
 */
@Component({
  selector: '[bx-folder-tree-link]',
  templateUrl: './folder-tree-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FolderTreeIconComponent],
})
export class FolderTreeLinkComponent {
  @HostBinding('class') readonly hostClass = 'text-decoration-none';
  @Input() linkIcon: FolderTreeIcon;
  @Input() linkText: string;
}
