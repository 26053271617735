import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '../../../app.config';

/**
 * Prevent geneious users from accessing pages they shouldn't be allowed to
 */
@Injectable({
  providedIn: 'root',
})
export class GeneiousGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private router: Router,
  ) {}

  canActivate() {
    if (this.config.geneiousProduct === 'prime') {
      this.router.navigate(['/not-found']);
    }
    return this.config.geneiousProduct !== 'prime';
  }
}
