import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { auditTime, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, ViewerState, ViewerStates } from './viewer-state-store/viewers-state.interface';
import { UserSettingsKinds } from '../user-settings/user-settings-types';
import {
  setAllViewerStates,
  updateViewerHeight,
  updateViewerSelectedTab,
} from './viewer-state-store/viewers-state.actions';
import {
  loadInitialUserSettingsSuccess,
  upsertUserSetting,
} from '../user-settings/user-settings.actions';
import { selectViewerState } from './viewer-state-store/viewers-state.selectors';
import { runHeightMigration } from './viewer-state-store/viewers-state-migrations';

@Injectable()
export class ViewersStateEffects {
  /**
   * Update User Settings with ViewersState whenever the UPDATE_HEIGHT or UPDATE_SELECTED_TAB action is called.
   */
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateViewerHeight, updateViewerSelectedTab),
      auditTime(1000),
      switchMap(({ key }) =>
        this.store.select(selectViewerState(key)).pipe(
          take(1),
          map((state) => ({ state, key })),
        ),
      ),
      map(({ state, key }) =>
        upsertUserSetting({
          kind: UserSettingsKinds.VIEWER_STATE,
          name: key,
          data: state,
        }),
      ),
    ),
  );

  setAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialUserSettingsSuccess),
      map(({ viewerStates }) => {
        return viewerStates.reduce((acc, setting) => {
          acc[setting.name] = setting.data as ViewerState;
          return acc;
        }, {} as ViewerStates);
      }),
      map(runHeightMigration),
      map((viewerStates) => setAllViewerStates({ viewerStates })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) {}
}
