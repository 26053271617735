import { finalize, first, map, mapTo, mergeMap, startWith } from 'rxjs/operators';
import { GLOBAL_ORG_GROUP_STRING } from '../manage-sharing.constants';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { ManageSharingService, SharingInformationResponse } from '../manage-sharing.service';
import { ACLEntity } from '../manage-sharing.models';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { PermissionsService } from '../../permissions/permissions.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageSharingListItemComponent } from '../manage-sharing-list-item/manage-sharing-list-item.component';
import { mapToOn } from '../../../../bx-operators/map-to-on';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { AccessLevels } from '../../permissions/access-level.const';
import { v4 as UUID } from 'uuid';
import { NewAccessControl } from '../../../../nucleus/v2/models/folders/access-controls/access-controls.v2.response';
import { PrincipalType } from '../../../../nucleus/v1-1/models/organizations/principals/principal-type.v1-1';
import {
  IS_FREE_ORG,
  IS_PREMIUM_ORG,
} from '../../../shared/access-check/access-check-condition.model';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { selectUserInfo, selectUserIsOrgAdmin } from '../../auth/auth.selectors';
import { AsyncPipe } from '@angular/common';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { HideIfDirective } from '../../../shared/access-check/directives/hide/hide-if.directive';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { ManageSharingOptionComponent } from '../manage-sharing-option/manage-sharing-option.component';

@Component({
  selector: 'bx-manage-sharing-dialog',
  templateUrl: './manage-sharing-dialog.component.html',
  styleUrls: ['./manage-sharing-dialog.component.scss'],
  providers: [ManageSharingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLegacyProgressBarModule,
    MatLegacyDialogModule,
    HideIfDirective,
    MatLegacyButtonModule,
    MatLegacyListModule,
    ManageSharingListItemComponent,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyOptionModule,
    ManageSharingOptionComponent,
    AsyncPipe,
  ],
})
export class ManageSharingDialogComponent implements OnInit, OnDestroy {
  @ViewChildren(ManageSharingListItemComponent)
  listOptions: QueryList<ManageSharingListItemComponent>;

  entities$: Subject<ACLEntity[]> = new BehaviorSubject<ACLEntity[]>([]);
  selectedEntities$: Observable<ACLEntity[]>;
  filteredEntities$: Observable<ACLEntity[]>;
  filteredGroupEntities$: Observable<ACLEntity[]>;
  filteredUserEntities$: Observable<ACLEntity[]>;
  aclEntities: ACLEntity[] = [];
  readonly searchInput = new FormControl<string>(undefined);
  loading$ = new BehaviorSubject(false);
  submitting$ = new BehaviorSubject(false);
  formDirty$ = new BehaviorSubject(false);
  changes: Subscription = new Subscription();
  formChanged$: Observable<void>;
  formSubmitDisabled$: Observable<boolean>;
  errorMessage$: Observable<string | void>;
  noFullAccessUsers$: Observable<boolean>;
  currentUserHasNoFullAccess$: Observable<boolean>;
  showProgressBar$: Observable<boolean>;
  showDialogForm$: Observable<boolean>;

  readonly isFreeOrg = IS_FREE_ORG;
  readonly isPremiumOrg = IS_PREMIUM_ORG;
  private onEntityAccessLevelChanged$ = new Subject<ACLEntity>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private folderID: string,
    public dialogRef: MatDialogRef<ManageSharingDialogComponent>,
    private store: Store<AppState>,
    private manageSharingService: ManageSharingService,
    private permissionsService: PermissionsService,
  ) {
    this.selectedEntities$ = this.entities$.pipe(
      map((entities) => entities.filter((entity) => entity.selected === true)),
    );

    this.showProgressBar$ = combineLatest([this.loading$, this.submitting$]).pipe(
      map(([loading, submitting]) => loading || submitting),
    );

    this.showDialogForm$ = this.loading$.pipe(map((loading) => !loading));

    this.formChanged$ = merge(
      this.onEntityAccessLevelChanged$.pipe(mapTo(undefined)),
      this.selectedEntities$.pipe(mapTo(undefined)),
    );

    this.noFullAccessUsers$ = combineLatest([
      this.onEntityAccessLevelChanged$.pipe(startWith(null)),
      this.selectedEntities$,
    ]).pipe(
      map(([onEntityAccessLevelChanged, selectedEntities]) => selectedEntities),
      map((entities) =>
        entities
          .filter((entity) => entity.principalType === PrincipalType.USER)
          .every((entity) => !entity.hasFullAccess()),
      ),
      startWith(false),
    );

    this.currentUserHasNoFullAccess$ = combineLatest([
      this.onEntityAccessLevelChanged$.pipe(startWith(null)),
      this.selectedEntities$,
      this.store.select(selectUserIsOrgAdmin),
    ]).pipe(
      map(([onEntityAccessLevelChanged, selectedEntities, isAdmin]) => ({
        selectedEntities,
        isAdmin,
      })),
      map(({ selectedEntities, isAdmin }) => {
        const currentUserACLEntity = selectedEntities.find((entity) => entity.isCurrentUser);
        const allUsersGroupACLEntity = selectedEntities.find((entity) => entity.isOrg);
        const allAdminUsersGroupACLEntity = selectedEntities.find(
          (entity) => entity.isGroup() && !entity.isOrg,
        );

        const allUsersHaveFullAccess = allUsersGroupACLEntity
          ? allUsersGroupACLEntity.hasFullAccess()
          : false;
        const allAdminUsersHaveFullAccess = allAdminUsersGroupACLEntity
          ? allAdminUsersGroupACLEntity.hasFullAccess()
          : false;

        if (currentUserACLEntity && !currentUserACLEntity.hasFullAccess()) {
          if (isAdmin) {
            return !allUsersHaveFullAccess && !allAdminUsersHaveFullAccess;
          } else {
            return !allUsersHaveFullAccess;
          }
        } else {
          return false;
        }
      }),
    );

    this.formSubmitDisabled$ = combineLatest([
      this.noFullAccessUsers$,
      this.formDirty$.pipe(map((dirty) => !dirty)),
      this.submitting$,
    ]).pipe(map((checks) => checks.some((check) => check)));

    this.errorMessage$ = merge(
      this.formChanged$,
      this.noFullAccessUsers$.pipe(
        mapToOn(
          'There has to be at least 1 remaining User with Full Access to this folder',
          (noAccess) => noAccess,
        ),
      ),
      this.currentUserHasNoFullAccess$.pipe(
        mapToOn(
          'You may be unable to manage sharing of this folder after this change',
          (noAccess) => noAccess,
        ),
      ),
    );

    const searchInput$ = this.searchInput.valueChanges.pipe(startWith(''));

    this.filteredEntities$ = combineLatest([this.entities$, searchInput$]).pipe(
      map(([entities, query]) => this.filterUnselectedEntities(entities, query)),
    );

    this.filteredGroupEntities$ = this.filteredEntities$.pipe(
      map((entity) =>
        entity.filter(
          (e) =>
            e.principalType === PrincipalType.GROUP ||
            e.principalType === PrincipalType.ORGANIZATION,
        ),
      ),
    );

    this.filteredUserEntities$ = this.filteredEntities$.pipe(
      map((entity) => entity.filter((e) => e.principalType === PrincipalType.USER)),
    );
  }

  ngOnInit() {
    this.loading$.next(true);
    this.manageSharingService
      .getSharingInformation(this.folderID)
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        }),
      )
      .subscribe(
        (resp) => {
          this.setupEntities(resp);
        },
        (error) => {
          this.dialogRef.close(true);
        },
      );
  }

  ngOnDestroy() {
    this.submitting$.complete();
    this.loading$.complete();
    this.formDirty$.complete();
    this.entities$.complete();
    this.onEntityAccessLevelChanged$.complete();
    this.changes.unsubscribe();
  }

  onEntityRemoved(aclEntity: ACLEntity) {
    this.setSelected(aclEntity.principalId, false);
  }

  onEntityAccessLevelChanged(aclEntity: ACLEntity) {
    this.formDirty$.next(true);
    this.onEntityAccessLevelChanged$.next(aclEntity);
  }

  onEntitySelectionChanged(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    this.setSelected(value.principalId, true);
    // Focus on newly added permission.
    // setTimeout required as listOptions won't be updated with the latest entities until the next javascript cycle.
    setTimeout(() => {
      this.listOptions.find((item) => item.item.principalId === value.principalId).focus();
    });
    this.searchInput.reset('');
  }

  saveChanges(): Observable<any> {
    return this.selectedEntities$.pipe(
      mergeMap((newPermissions: ACLEntity[]) => {
        const v2Perms: NewAccessControl[] = newPermissions.map((newPermission: ACLEntity) => ({
          permissions: PermissionsService.getAccessArray(newPermission.highestAccess),
          principalID: newPermission.principalId,
          principalType: newPermission.principalType,
        }));
        return this.permissionsService.updatePermissions(this.folderID, v2Perms);
      }),
    );
  }

  submitForm() {
    this.submitting$.next(true);
    this.changes.add(
      this.saveChanges()
        .pipe(
          first(),
          finalize(() => {
            this.dialogRef.disableClose = false;
            this.submitting$.next(false);
          }),
        )
        .subscribe((response) => {
          this.dialogRef.close(true);
        }),
    );
  }

  cancel() {
    if (this.changes) {
      this.changes.unsubscribe();
    }
    this.dialogRef.close();
  }

  /**
   * Stops closing the dialog when focused in autocomplete and pressing escape.
   * @param event
   */
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.stopImmediatePropagation();
      (event.target as HTMLInputElement).blur();
    }
  }

  private filterUnselectedEntities(entities: ACLEntity[], query: string | ACLEntity): ACLEntity[] {
    if (query instanceof ACLEntity) {
      // User has selected an item, no need to show any entities.
      return [];
    }
    const queryLowerCase = query ? query.toLowerCase() : '';
    return entities.filter((entity: ACLEntity) => {
      const lowercaseName = entity.displayName.toLowerCase();
      const lowercaseDescription = entity.description ? entity.description.toLowerCase() : '';
      return (
        entity.selected === false &&
        (lowercaseName.indexOf(queryLowerCase) >= 0 ||
          lowercaseDescription.indexOf(queryLowerCase) >= 0)
      );
    });
  }

  private setSelected(principalID: string, selected: boolean) {
    const changedEntities: ACLEntity[] = this.aclEntities.map((entity) => {
      if (entity.principalId === principalID) {
        entity.selected = selected;
      }
      return entity;
    });

    this.formDirty$.next(true);

    this.entities$.next(changedEntities);
  }

  private setupEntities(sharingInformation: SharingInformationResponse) {
    this.store.pipe(select(selectUserInfo), first()).subscribe((userInfo) => {
      let initialEntities: ACLEntity[] = [];
      sharingInformation.permissions.entries.forEach((permissions) => {
        initialEntities.push(
          this.manageSharingService.buildACLEntityFromAccessControl(permissions, userInfo.user.id),
        );
      });

      // If the default organization isn't already set as an access control, then show as an available selectable group.
      if (!initialEntities.find((entity) => entity.principalType === PrincipalType.ORGANIZATION)) {
        initialEntities.push(
          new ACLEntity({
            id: UUID(),
            principalId: userInfo.user.organizationID,
            principalType: PrincipalType.ORGANIZATION,
            displayName: userInfo.organizationName,
            description: `All members of ${userInfo.organizationName}`,
            highestAccess: AccessLevels.full,
            icon: 'business',
            iconType: 'material',
            selected: false,
            isCurrentUser: false,
            isOrg: true,
          }),
        );
      }

      sharingInformation.groups.forEach((group) => {
        let exists = false;
        initialEntities.forEach((entity: ACLEntity) => {
          if (group.principalId === entity.principalId) {
            entity.displayName = group.principalData.groupName;
            entity.isOrg =
              entity.displayName.toLowerCase().substring(0, GLOBAL_ORG_GROUP_STRING.length) ===
              GLOBAL_ORG_GROUP_STRING;
            entity.icon = entity.isOrg ? 'business' : 'people';
            exists = true;
          }
        });
        if (!exists) {
          initialEntities.push(
            this.manageSharingService.buildACLEntityFromPrincipal(group, userInfo.user.id),
          );
        }
      });

      sharingInformation.users.forEach((user) => {
        let exists = false;
        initialEntities.forEach((entity: ACLEntity) => {
          if (user.principalId === entity.principalId) {
            entity.displayName = `${user.principalData.givenName} ${
              user.principalData.familyName ?? ''
            }`;
            entity.description = user.principalData.email;
            entity.icon = 'person';
            exists = true;
          }
        });
        if (!exists) {
          initialEntities.push(
            this.manageSharingService.buildACLEntityFromPrincipal(user, userInfo.user.id),
          );
        }
      });

      initialEntities = this.sortEntities(initialEntities);

      this.loading$.next(false);
      this.aclEntities = initialEntities;
      this.entities$.next(this.aclEntities);
    });
  }

  // Sort list by name and put organization/groups and current user first.
  private sortEntities(entities: ACLEntity[]): ACLEntity[] {
    return entities.sort((a, b) => {
      if (a.isOrg) {
        return -1;
      }
      if (b.isOrg) {
        return 1;
      }
      if (a.isGroup()) {
        return -1;
      }
      if (b.isGroup()) {
        return 1;
      }
      if (a.isCurrentUser) {
        return -1;
      }
      if (b.isCurrentUser) {
        return 1;
      }
      return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
    });
  }
}
