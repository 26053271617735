@if ({ mfaRequired: mfaRequired$ | async }; as state) {
  <div class="bx-page-container bx-page-container-xs min-vh-100 d-flex flex-column">
    <form [formGroup]="form" class="mt-auto" (ngSubmit)="login(state.mfaRequired)">
      <bx-logo class="d-block pb-5 my-3 text-center" [width]="'100%'"></bx-logo>
      @if (state.mfaRequired === false) {
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            [formControl]="form.controls.login.controls.email"
            type="email"
            name="email"
            placeholder="Email"
            required
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
            data-spec="login-email"
          />
          <bx-form-errors [control]="form.controls.login.controls.email"></bx-form-errors>
        </div>
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            [formControl]="form.controls.login.controls.password"
            type="password"
            name="password"
            placeholder="Password"
            required
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
            data-spec="login-password"
          />
          <bx-form-errors [control]="form.controls.login.controls.password"></bx-form-errors>
        </div>
        <bx-environment-switcher class="mb-3"></bx-environment-switcher>
      } @else {
        <bx-mfa-verification></bx-mfa-verification>
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            [formControl]="form.controls.mfaCode"
            type="number"
            name="mfaCode"
            placeholder="Enter Code"
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
          />
          <bx-form-errors [formControl]="form.controls.mfaCode"></bx-form-errors>
        </div>
      }
      @if (loginError$ | async; as loginError) {
        <div class="text-danger text-center mb-3" data-spec="server-error-msg">
          {{ loginError }}
        </div>
      }
      <div class="w-100 d-flex justify-content-end">
        @if (!state.mfaRequired) {
          <button
            class="btn btn-link btn-sm me-2"
            type="button"
            [disabled]="loggingIn$ | async"
            [routerLink]="['/reset-password/request']"
          >
            Forgot password?
          </button>
        }
        <button
          id="login-button"
          data-spec="login-button"
          type="submit"
          class="btn btn-lg btn-primary"
          [disabled]="loggingInOrLockedOut$ | async"
          bx-spinner-button
          [spin]="loggingIn$ | async"
        >
          {{ state.mfaRequired ? 'Verify' : ' Sign In' }}
        </button>
      </div>
    </form>
    @if (state.mfaRequired === false) {
      <div class="w-100 py-4 my-4 d-flex align-items-center">
        <hr class="w-100" />
        <small class="text-muted mx-3">OR</small>
        <hr class="w-100" />
      </div>
      <div class="mb-auto">
        @if (lumaLoginEnabled) {
          <a
            (click)="navigateToLumaAuth()"
            class="btn btn-lg btn-outline-secondary w-100 mb-3 d-flex align-items-center justify-content-between"
          >
            <mat-icon svgIcon="luma" class="h-100 mt-n1"></mat-icon>
            <span>Sign in with <b>Luma</b></span>
            <div class="px-2"></div>
          </a>
        }
        <a
          (click)="navigateToAzure()"
          class="btn btn-lg btn-outline-secondary w-100 mb-3 d-flex align-items-center justify-content-between"
        >
          <mat-icon svgIcon="microsoft" class="h-100 mt-n1 bx-btn-icon"></mat-icon>
          <span>Sign in with <b>Microsoft</b></span>
          <div class="px-2"></div>
        </a>
        <a
          (click)="navigateToGoogle()"
          class="btn btn-lg btn-outline-secondary w-100 d-flex align-items-center justify-content-between"
        >
          <mat-icon svgIcon="google" class="h-100 mt-n1 bx-btn-icon"></mat-icon>
          <span>Sign in with <b>Google</b></span>
          <div class="px-2"></div>
        </a>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <a
          href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625472"
          target="_blank"
          rel="noopener noreferrer"
          title="Login help"
          >Trouble signing in?</a
        >
        <a
          href="https://www.geneious.com/biopharma/request-demo/"
          target="_blank"
          rel="noopener noreferrer"
          title="Request trial"
          >Request a Trial</a
        >
      </div>
    } @else {
      <div class="d-flex justify-content-center mt-3 mb-auto mfa-required-footer">
        <p><a routerLink="'#'" (click)="backToLogin()">Return to Login</a></p>
        <p class="mx-2 text-muted">•</p>
        <p>
          <a
            target="_blank"
            href="https://help.geneiousbiologics.com/hc/en-us/articles/25275064966420-Multi-factor-authentication#h_01HTXP677EFSTQK2AC902VS1T6"
            >Forgot code?</a
          >
        </p>
      </div>
    }
  </div>
}
