import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { Observable, of, Subject, switchMap, throwError } from 'rxjs';
import {
  CombinationRegionChip,
  RegionSelectorChain,
} from '../../../shared/regions-selector/regions-selector.component';
import {
  AntibodyAnnotatorOptionValues,
  defaultClusterCombos,
  defaultClusterCombosGenes,
  gaalOptions,
  getOnlyClusterTablesFor,
  getSelectedChain,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { clusterComboValidator } from '../../ngs/validators/cluster-combo-validator';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { catchError, map, share, takeUntil, tap } from 'rxjs/operators';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { ExtractReanalyzeParams } from 'src/nucleus/services/models/extractReanalyze.model';
import { ExtractSequencesDialogOptionsV3 } from 'src/nucleus/services/models/extractSequencesV3.model';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { ChainPrefix } from '../../antibodyAnnotatorRegions.service';
import { DocumentTable, TableMetadata } from '../../../../nucleus/services/documentService/types';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import { DocumentTableService } from '@geneious/nucleus-api-client';
import { Chip } from '../../../shared/chips';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { restrictControlValues } from 'src/app/shared/utils/forms';
import { isAllSequencesTable } from '../../ngs/table-type-filters';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-extract-reanalyze',
  templateUrl: './extract-reanalyze.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbTooltip,
    MultiSelectComponent,
    ClusteringOptionsCardComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class ExtractReanalyzeComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  title = 'Extract and Recluster';
  earlyRelease?: boolean;
  knowledgeBaseArticle?: string;

  folderId: string;
  dialogData: PipelineDialogData<
    ExtractSequencesDialogOptionsV3 & {
      creationPipeline: string;
      creationPipelineOptions: AntibodyAnnotatorOptionValues;
    }
  >;
  selectedChain$: Observable<RegionSelectorChain>;
  otherVariables: ExtractSequencesDialogOptionsV3 & {
    creationPipelineOptions: AntibodyAnnotatorOptionValues;
  };
  errors$: Subject<string> = new Subject();
  chainNamesPossiblyPresent$: Observable<ChainPrefix[]>;
  antibodyDatabases$: Observable<SelectOption[][]>;
  referenceDbsFromOriginalOptionsAreValid$: Observable<boolean>;
  multipleRefDbsEnabled$: Observable<boolean>;

  form = new BxFormGroup({
    // Note 'regionsSelector' value needs to be parsed into results_onlyClusterTablesFor before being sent to the
    // Antibody Annotator pipeline as parameters.
    regionsSelector: new BxFormControl<(Chip | CombinationRegionChip)[]>([], Validators.required),
    results_clusterCombos: new FormControl(defaultClusterCombos, clusterComboValidator),
    results_applySummaryScoreFilter: new BxFormControl(false),
    results_summaryScoreThreshold: new BxFormControl(-1000, [
      Validators.required,
      Validators.min(-99999),
      Validators.max(99999),
    ]),
    results_applySummaryFilter: new BxFormControl(false),
    results_summaryFilter: new BxFormControl('fullyAnnotated', Validators.required),
    outputFolderName: JobDialogContent.getResultNameControl(),
    database_customDatabase: new BxFormControl<string[]>([]),
    resultName: JobDialogContent.getResultNameControl(),
  });

  private readonly formDefaults = this.form.getRawValue();

  constructor(
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly documentTableStateService: DocumentTableStateService,
    private readonly documentTableService: DocumentTableService,
    @Inject(PIPELINE_DIALOG_DATA)
    dialogData: PipelineDialogData<
      ExtractSequencesDialogOptionsV3 & {
        creationPipeline: string;
        creationPipelineOptions: AntibodyAnnotatorOptionValues;
      }
    >,
    private pipelineService: PipelineService,
  ) {
    super('extract-reanalyze', PipelineFormID.EXTRACT_REANALYZE);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
    this.folderId = dialogData.folderID;
    this.otherVariables = dialogData.otherVariables;
    this.dialogData = dialogData;
  }

  ngOnInit() {
    this.selectedChain$ = of(
      getSelectedChain(this.otherVariables.creationPipelineOptions.sequences_chain),
    );

    this.form.controls.database_customDatabase.setValue(undefined);

    this.chainNamesPossiblyPresent$ = this.documentTableStateService
      .getTables(this.otherVariables.selection.documentID)
      .pipe(
        map((tables) => tables.filter((table) => isAllSequencesTable(table))),
        map((tables: DocumentTable[]) =>
          !!tables ? (tables[0].metadata?.chainNamesPossiblyPresent as ChainPrefix[]) : [],
        ),
      );

    const documentClusterTables$ = this.documentTableService
      .getTables(this.dialogData.otherVariables.selection.documentID)
      .pipe(
        map((res) => res.data),
        catchError((e) => {
          this.errors$.next('Error: could not retrieve the document table data');
          this.form.disable();
          return throwError(() => e);
        }),
        map((tables) => {
          return Object.values(tables).filter(
            ({ status, tableType }) =>
              status.kind === 'Idle' &&
              (tableType === 'AnnotatorResultClusters' ||
                tableType === 'AnnotatorResultClusterGene' ||
                tableType === 'AnnotatorResultInexactCluster'),
          );
        }),
        takeUntil(this.ngUnsubscribe),
      );

    documentClusterTables$.subscribe((tables) => {
      const displayTables: (Chip | CombinationRegionChip)[] = [];
      for (const table of tables) {
        const tableMetadata = table.metadata as unknown as TableMetadata;
        displayTables.push(ExtractReanalyzeComponent.getChipFromTableMetadata(tableMetadata));
      }
      this.form.controls.regionsSelector.setValue(displayTables);
    });

    const analysisType$ = documentClusterTables$.pipe(
      map((tables) => {
        if (tables && tables.length > 0) {
          const firstTableMetadata = tables[0].metadata as unknown as TableMetadata;
          return firstTableMetadata.analysisType;
        }
        return undefined;
      }),
    );

    // Ideally we use the same reference database as what was used to create this doc. However sometimes the reference
    // db is unavailable, in which case we require that the user specify a different one.
    let referenceDbsFromOptions =
      this.otherVariables.creationPipelineOptions.database_customDatabase;
    this.antibodyDatabases$ = this.getApplicableReferenceDatabases(analysisType$);

    // The reference db might not be set, or it might be set to a db that you can't access...
    this.referenceDbsFromOriginalOptionsAreValid$ = this.pipelineService
      .areReferenceDatabasesAvailable(referenceDbsFromOptions)
      .pipe(
        tap((value) => {
          this.form.controls.database_customDatabase.setValidators(
            value ? null : Validators.required,
          );
          this.form.controls.database_customDatabase.updateValueAndValidity();
        }),
      );

    this.form.controls.results_clusterCombos.setValue(defaultClusterCombosGenes);
  }

  ngOnDestroy(): void {
    this.errors$.complete();
  }

  run() {
    const { outputFolderName, resultName, regionsSelector, ...newOptions } = this.form.value;
    const oldOptions = this.otherVariables.creationPipelineOptions;

    delete oldOptions.clusterMethod;
    delete oldOptions.sequenceIdentityThreshold;
    delete oldOptions.region;
    newOptions.database_customDatabase = newOptions.database_customDatabase?.filter((db) => !!db); // As for generic analysis, we could have 'No selected database' which evaluates to null.

    return this.referenceDbsFromOriginalOptionsAreValid$.pipe(
      map((useReferenceDbsFromOriginalOptions: boolean) => {
        // These are the new options that are used for the re-analyzing. For any options not specified,
        // antibody annotator will use the previous run's value for.
        const antibodyAnnotatorOptions = {
          optionValues: {
            ...gaalOptions,
            ...newOptions,
            results_applyOnlyClusterTablesFor: true,
            results_onlyClusterTablesFor: getOnlyClusterTablesFor(regionsSelector),
          },
          resultName: '',
        };

        if (useReferenceDbsFromOriginalOptions) {
          antibodyAnnotatorOptions.optionValues.database_customDatabase = Array.isArray(
            oldOptions.database_customDatabase,
          )
            ? oldOptions.database_customDatabase
            : [oldOptions.database_customDatabase];
        } else {
          antibodyAnnotatorOptions.optionValues.database_customDatabase =
            this.form.controls.database_customDatabase.value;
        }
        const params = {
          output: {
            outputFolderName,
          },
          options: {
            extractSequencesOptions: this.otherVariables,
            antibodyAnnotatorOptions,
            resultName,
          },
          selection: {
            ids: [this.otherVariables.selection.documentID],
            folderId: this.folderId,
            selectAll: false,
          },
        };
        return new ExtractReanalyzeParams(params);
      }),
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  static isCombinationTable(tableMetadata: TableMetadata) {
    return tableMetadata?.clusters?.containsMultipleRegions;
  }

  static getChipFromTableMetadata(tableMetadata: TableMetadata): Chip | CombinationRegionChip {
    if (!tableMetadata) {
      return undefined;
    }

    if (!ExtractReanalyzeComponent.isCombinationTable(tableMetadata)) {
      return {
        id: tableMetadata.displayName,
        label: tableMetadata.displayName,
      };
    }

    const hasDifferentClusteringMethods =
      [
        ...new Set(
          tableMetadata.clusters?.clusterGroups.map((clusterGroup) => clusterGroup.clusterMethod) ??
            [],
        ),
      ].length > 1;
    const regions = [
      ...new Set(
        tableMetadata.clusters?.clusterGroups.flatMap((clusterGroup) => {
          const isSimilarity = clusterGroup.clusterMethod === 'SimilarityAminoAcid';
          const isIdentity =
            clusterGroup.clusterMethod === 'IdentityNucleotide' ||
            clusterGroup.clusterMethod === 'IdentityAminoAcid';
          return clusterGroup.regionOrGenes.map((rOrG) => {
            let regionName = (rOrG.chainName ? `${rOrG.chainName}: ` : '') + rOrG.name;
            if (isSimilarity && hasDifferentClusteringMethods) {
              regionName += ` (${clusterGroup.percentage}% Similarity)`;
            } else if (isIdentity && hasDifferentClusteringMethods) {
              regionName += ` (${clusterGroup.percentage}% Identity)`;
            }
            return regionName;
          });
        }) ?? [],
      ),
    ];

    return {
      id: tableMetadata.displayName,
      label: tableMetadata.displayName,
      type: 'Combination',
      regions: regions,
    };
  }

  private getApplicableReferenceDatabases(
    analysisType$: Observable<
      'AntibodyAnnotator' | 'SingleClone' | 'Comparisons' | 'NgsAnnotator' | 'GenericAnnotator'
    >,
  ) {
    // For generic analysis tpyes, we only want to show 'No Database' and General databases.
    return analysisType$.pipe(
      map((analysisType) => analysisType === 'GenericAnnotator'),
      switchMap((isGenericAnalysis) => {
        if (isGenericAnalysis) {
          const emptyDatabaseOption = new SelectOption('No Reference Database', undefined);
          return this.pipelineService.getDatabases(DatabaseTypeEnum.GENERAL_TEMPLATE).pipe(
            map((dbs) => {
              if (dbs[0].length === 0 && dbs[1].length === 0) {
                return [];
              }
              dbs[0].push(emptyDatabaseOption);
              return dbs;
            }),
            tap((options) =>
              restrictControlValues(
                this.form.controls.database_customDatabase,
                options.flatMap((group) => group.map((option) => option.value)),
                { takeUntil: this.ngUnsubscribe },
              ),
            ),
            share(),
          );
        }
        // But for other types of analysis, show all GERMLINE and TEMPLATE databases
        return this.pipelineService.getReferenceDatabases().pipe(
          tap((options) =>
            restrictControlValues(
              this.form.controls.database_customDatabase,
              options.flatMap((group) => group.map((option) => option.value)),
              { takeUntil: this.ngUnsubscribe },
            ),
          ),
        );
      }),
    );
  }
}
