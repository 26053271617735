import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FeatureSwitchService } from '../../../../features/feature-switch/feature-switch.service';
import { combineLatest, EMPTY, Observable, startWith } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { SequenceViewerService } from '../../../../features/sequence-viewer-angular/sequence-viewer.service';
import { SequenceViewerMetadataService } from '../../../sequence-viewer/sequence-viewer-metadata.service';
import {
  ExportSequencesDialogData,
  NGSExtractionData,
} from '../../../pipeline-dialogs/export-v2/export-sequences/export-sequences.component';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';
import { Job } from '../../../../../nucleus/services/models/job.model';
import { AlignmentJobParametersV2 } from '../../../../../nucleus/services/models/alignmentOptions.model';
import { partitionArray } from '../../../../../bx-common-extensions/array';
import { JobHistoryService } from '../../../jobs/job-history.service';
import { DataManagementService } from '@geneious/nucleus-api-client';
import { DocumentService } from '../../../../../nucleus/services/documentService/document-service.v1';
import { FolderService } from '../../../folders/folder.service';
import { OrderBy } from '../../../../../nucleus/services/documentService/document-service.v1.http';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { SequenceViewerExportButtonDirective } from '../../../sequence-viewer/sequence-viewer-export-button.directive';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-files-sequence-viewer-export-menu',
  templateUrl: './files-sequence-viewer-export-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    SequenceViewerExportButtonDirective,
    NgbTooltip,
    AsyncPipe,
  ],
})
export class FilesSequenceViewerExportMenuComponent implements OnInit {
  @Input() documentCount: number;
  @Input() documentType: string;
  @Input() documentID: string;

  exportAsTableDisabled = false;
  exportAsTableVisible$: Observable<boolean>;
  exportSequencesVisible$: Observable<boolean>;
  exportSequencesDisabled$: Observable<boolean>;
  exportAsTableDisabledToolTip =
    'Alignment results containing combined sequences from before February 2024 cannot be exported as a table';
  alignmentExportDialogData$: Observable<ExportSequencesDialogData>;

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private sequenceViewerService: SequenceViewerService,
    private sequenceViewerMetadataService: SequenceViewerMetadataService,
    private jobHistoryService: JobHistoryService,
    private dataManagementService: DataManagementService,
    private documentService: DocumentService,
    private folderService: FolderService,
  ) {
    this.exportAsTableVisible$ = featureSwitchService
      .isEnabledOnce('alignmentExportAsTable')
      .pipe(
        map(
          (enabled) =>
            enabled && (this.documentType === 'Alignment' || this.documentType === 'Tree'),
        ),
      );

    this.exportSequencesVisible$ = featureSwitchService
      .isEnabledOnce('sequencesSelection')
      .pipe(
        map(
          (enabled) =>
            enabled && (this.documentType === 'Alignment' || this.documentType === 'Tree'),
        ),
      );

    let idColumnName = this.sequenceViewerMetadataService.findUniqueMetadataColumnName(
      this.sequenceViewerService.sequences[0].metadata,
      this.sequenceViewerService.sequences[0].sequence.name,
    );
    //Disable export as table if any of the sequences missing the unique ID(probably BX_ID of the combined sequences in alignment created from all sequences table) as they cause the export pipeline failures.
    const containsSequencesWithoutIDs = this.sequenceViewerService.sequences.some(
      (seq) => seq.metadata[idColumnName] == null,
    );
    if (containsSequencesWithoutIDs) {
      this.exportAsTableDisabled = true;
    }
  }

  ngOnInit() {
    this.alignmentExportDialogData$ = this.getAlignmentSequencesExportDialogData();
    this.exportSequencesDisabled$ = this.alignmentExportDialogData$.pipe(
      map((data) => data === null),
      startWith(true),
    );
  }

  private getAlignmentSequencesExportDialogData(): Observable<ExportSequencesDialogData> {
    const creationAlignmentJob$ = this.jobHistoryService
      .getCreationJobForDocument(this.documentID)
      .pipe(
        filter((job) => !!job && job.config.pipeline.name.startsWith(PipelineFormID.ALIGNMENT)),
        map((job) => job as Job<AlignmentJobParametersV2>),
        startWith(null),
        shareReplay(1),
        catchError(() => EMPTY),
      );

    const alignedTable$ = creationAlignmentJob$.pipe(
      filter((job) => !!job && !!job.config.parameters.options.extraction),
      switchMap((job) => {
        const extractedDocumentID = job.config.parameters.options.extraction.selection.documentID;
        const extractedDocumentTable = job.config.parameters.options.extraction.documentTableName;
        return this.documentService.getTable(extractedDocumentID, extractedDocumentTable);
      }),
      startWith(null),
      shareReplay(1),
      catchError(() => EMPTY),
    );

    const originalAnnotatorResult$ = creationAlignmentJob$.pipe(
      filter((job) => !!job && !!job.config.parameters.options.extraction),
      map((job) => job.config.parameters.options.extraction.selection.documentID),
      switchMap((documentID) => this.dataManagementService.getDocument(documentID)),
      map((response) => response.data),
      startWith(null),
      shareReplay(1),
      catchError(() => EMPTY),
    );

    const isReadOnlyFolder$ = this.dataManagementService.getDocument(this.documentID).pipe(
      switchMap((document) => this.folderService.get(document.data.parentID)),
      map((folder) => !folder.hasWriteAccess()),
      startWith(false),
      catchError(() => EMPTY),
    );

    const extractionData$: Observable<NGSExtractionData> = combineLatest([
      creationAlignmentJob$,
      alignedTable$,
      originalAnnotatorResult$,
      this.sequenceViewerService.sequencesSelection$,
    ]).pipe(
      map(([job, table, annotatorResult, sequenceViewerSelection]) => {
        // These are the requirement to create extraction data for sequence extraction for export.
        // If none of these exist, the extraction data is returned as null and only sequences directly
        // from the alignment can be exported.
        if (!job || !table || !annotatorResult || sequenceViewerSelection.length === 0) {
          return null;
        }
        const tableName = job.config.parameters.options.extraction.documentTableName;
        const tableDisplayName = table.displayName;

        const isExtractedFromAllSequences = tableName === 'DOCUMENT_TABLE_ALL_SEQUENCES';
        const isFromCombinedSequencesAlignment =
          job.config.parameters.options.combineDuplicateSequences;

        const isAlignmentContainsCombinedSequenceIDs =
          isFromCombinedSequencesAlignment &&
          sequenceViewerSelection.every((sequence) => sequence.metadata['BX_All sequence IDs']);
        const tableQuery = {
          where: '',
          fields: [] as string[],
          orderBy: [
            {
              kind: 'ascending',
              field: isExtractedFromAllSequences ? 'ID' : `${tableDisplayName} ID`,
            },
          ] as OrderBy[],
          cursorSize: 5000,
        };

        const idMetadataKey = isExtractedFromAllSequences
          ? 'BX_ID'
          : Object.keys(sequenceViewerSelection[0].metadata).find((key) =>
              key.endsWith(`${tableDisplayName} ID`),
            );

        if (!idMetadataKey) {
          return null;
        }

        // All -1 because extraction code take in the index of the row instead of the id, which is 0-based.
        const selectionIDs = sequenceViewerSelection.map(
          (sequence) => (sequence.metadata[idMetadataKey] as number) - 1,
        );

        const [heavyChains, _] = partitionArray(
          table.metadata?.chainNamesPossiblyPresent || [],
          (chain) => chain.toLowerCase().startsWith('heavy'),
        );
        // For now hardcoded that anything that's not VHH-VHH will have scfv export option.
        // In the future, we should fetch sequences regions & actually verify if scfv region existed for export or not.
        const hasSCFV = heavyChains.length < 2;

        const creationOptions = annotatorResult.metadata.antibodyAnnotatorOptionValues
          ? JSON.parse(annotatorResult.metadata.antibodyAnnotatorOptionValues)
          : undefined;
        const isPeptideOrProteinResult = creationOptions?.sequences_chain === 'genericSequence';

        return {
          table: {
            ...table,
            name: tableName,
          },
          tableQuery,
          hasSCFV,
          isGenericSequenceChainDocument: isPeptideOrProteinResult,
          isFromCombinedSequencesAlignment,
          isAlignmentContainsCombinedSequenceIDs,
          annotatorDocumentID: annotatorResult.documentID,
          selection: {
            selectAll: false,
            ids: selectionIDs.map((id) => id.toString()),
          },
        };
      }),
    );

    return combineLatest([
      extractionData$,
      isReadOnlyFolder$,
      this.sequenceViewerService.sequencesSelection$,
    ]).pipe(
      map(([extractionData, isReadOnlyFolder, sequenceViewerSelection]) => {
        if (sequenceViewerSelection.length === 0) {
          return null;
        }

        return {
          exportPipeline: PipelineFormID.EXPORT_ALIGNMENT_SEQUENCES,
          documentID: this.documentID,
          estimatedNumberOfSequencesMoreThan1000: sequenceViewerSelection.length > 1000,
          isReadOnlyFolder,
          extractionData,
          sequenceViewerSelection,
        };
      }),
    );
  }
}
