<div class="alert alert-info">
  Exporting {{ numRows | number }} row{{ numRows === 1 ? '' : 's' }} from the {{ tableName }} table
</div>

<form name="exportNgsTableForm" [formGroup]="form" class="p-2">
  <bx-export-table-controls
    [tableTypeControl]="form.controls.tableOutputType"
    [exportedColumnsControl]="form.controls.exportedColumns"
    [numRows]="numRows"
  ></bx-export-table-controls>

  <div class="mb-0">
    <div class="form-check">
      <input
        type="checkbox"
        id="removeWellIDPadding"
        formControlName="removeWellIDPadding"
        value="true"
        class="form-check-input"
        data-spec="remove-well-id-padding"
      />
      <label for="removeWellIDPadding" class="form-check-label"> Remove Well ID Padding </label>
    </div>
  </div>
</form>
