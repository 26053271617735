import { first, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TreeItem } from '../../../core/folders/models/folder.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FolderService } from '../../../core/folders/folder.service';
import { FilePickerService } from '../file-picker.service';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-file-picker-tree',
  templateUrl: './file-picker-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, AsyncPipe],
})
export class FilePickerTreeComponent implements OnInit {
  @Input() folder: TreeItem;
  @Input() fileTypes: string[];
  @Input() filter: (file: Item) => boolean;

  expanded$ = new BehaviorSubject(false);
  children$: Observable<TreeItem[]>;
  files$: Observable<Item[]>;

  readonly expandIcon = faChevronRight;

  constructor(
    private folderService: FolderService,
    private filePickerService: FilePickerService,
  ) {}

  ngOnInit() {
    this.files$ = this.folderService
      .getFilteredChildrenEntities({
        folderID: this.folder.id,
        includeTypes: this.fileTypes,
        maxDepth: 1,
        pageLimit: 1000,
        pageOffset: 0,
      })
      .pipe(map((response) => response.data));

    this.children$ = this.folderService.getChildren(this.folder.id).pipe(
      map((results) => results as TreeItem[]),
      first(),
    );
  }

  filteredFiles(files: Item[]): Item[] {
    return files && this.filter ? files.filter(this.filter) : files;
  }

  toggleExpand() {
    this.expanded$.next(!this.expanded$.value);
  }

  checkboxClicked(event: Event, sequence: Item) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.filePickerService.selectSequence(sequence);
    } else {
      this.filePickerService.unselectSequence(sequence);
    }
  }

  isSelected(id: string): boolean {
    return this.filePickerService.isSelected(id);
  }
}
