import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  PIPELINE_DIALOG_DATA,
  PipelineDialogData,
} from '../../pipeline-dialogs/pipeline-dialog-v2/pipeline-dialog-v2';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  ExportSequenceViewerImageJobOptions,
  ExportSequenceViewerImageParameters,
  SequenceViewerExportState,
} from '../../../../nucleus/services/models/exportSequenceViewerImageOptions.model';
import { SelectionParametersV1 } from '../../../../nucleus/v2/models/jobSchemas/ngsOptions.model';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { map, take, takeUntil } from 'rxjs/operators';
import { JobResultDownloaderService } from '../../utils/job-result-downloader.service';
import { FolderService } from '../../folders/folder.service';
import { FolderKindsEnum } from '../../folders/models/folderKinds';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { NewJobResponse } from '@geneious/nucleus-api-client';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from '../../../shared/card/card.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-export-sequence-viewer-dialog',
  templateUrl: './export-sequence-viewer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CardComponent, PipelineOutputComponent],
})
export class ExportSequenceViewerDialogComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  title = 'Export Sequence Viewer Image';
  earlyRelease = false;
  knowledgeBaseArticle?: string;

  readonly form = new BxFormGroup({
    download: new BxFormControl(false),
    resultName: JobDialogContent.getResultNameControl(),
  });
  sortingEnabled = false;

  private readonly formDefaults: any;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    public dialogData: PipelineDialogData<ExportSequenceViewerDialogOptions>,
    private folderService: FolderService,
    private jobResultDownloaderService: JobResultDownloaderService,
  ) {
    super('export-sequence-viewer-image', PipelineFormID.EXPORT_SEQUENCE_VIEWER_IMAGE);

    this.formDefaults = this.form.value;
  }

  ngOnInit() {
    this.forceDownloadIfReadOnly();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  run() {
    const formValue = this.form.value;

    const selection: SelectionParametersV1 = {
      selectAll: this.dialogData.selected.selectAll,
      folderId: this.dialogData.folderID,
      ids: this.dialogData.selected.ids,
    };

    const options: ExportSequenceViewerImageJobOptions = {
      state: this.dialogData.otherVariables.sequenceViewerState,
      resultName: formValue.resultName,
    };

    if (this.dialogData.otherVariables.multipleTableDocumentSelection) {
      options.multipleTableDocumentSelection =
        this.dialogData.otherVariables.multipleTableDocumentSelection;
    } else if (this.dialogData.otherVariables.masterDatabaseSearchResultSelection) {
      options.masterDatabaseSearchResultSelection =
        this.dialogData.otherVariables.masterDatabaseSearchResultSelection;
    }

    return new ExportSequenceViewerImageParameters({ selection, options });
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    if (this.form.get('download').value) {
      this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
        newJobResponse.data.jobID,
        'FILE',
      );
    }
  }

  private forceDownloadIfReadOnly() {
    const folderID = this.dialogData.folderID;
    this.folderService
      .get(folderID)
      .pipe(
        take(1),
        // Should only ever be able to save images to folders of type Folder.
        // Databases etc should never have image files.
        map((folder) => !folder.hasWriteAccess() || folder.kind !== FolderKindsEnum.FOLDER),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((hasReadOnlyAccess) => {
        if (hasReadOnlyAccess) {
          this.form.get('download').setValue(true);
          // Maintain the form defaults so they don't find a way to undo the forced download true option.
          this.formDefaults.download = true;
          this.form.get('download').disable();
        }
      });
  }
}

export interface ExportSequenceViewerDialogOptions {
  sequenceViewerState: SequenceViewerExportState;
  multipleTableDocumentSelection?: {
    selectAll: boolean;
    total: number;
    totalSelected: number;
    tableType: DocumentTableType;
    selectedRows: any[];
  };
  masterDatabaseSearchResultSelection?: {
    rows: any[];
    subTableRows: any[];
    totalSelected: number;
    blobName: string;
  };
}
