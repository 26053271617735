import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, startWith } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from '../../../../app.config';
import { select, Store } from '@ngrx/store';
import { selectMFARequired } from '../../../auth/auth.selectors';
import { AppState } from '../../../core.store';
import { LogoComponent } from '../../../../shared/logo/logo.component';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MfaVerificationComponent } from '../../mfa-verification/mfa-verification.component';

/**
 * Login/Signup page for Free Tier users (Geneious Biologics Starter).
 * This component replaces the regular LoginComponent when the App config has 'freeTierLoginEnabled' enabled.
 *
 * TODO de-duplicate some of the form stuff from this component and the login.component.ts.
 *  that can be done in the future once we have a final design for this page.
 */
@Component({
  selector: 'bx-free-tier-login',
  templateUrl: './free-tier-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LogoComponent, MatIconModule, MfaVerificationComponent, AsyncPipe],
})
export class FreeTierLoginComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block vh-100 w-100 overflow-auto';
  microsoftSsoUrl: string;
  googleSsoUrl: string;
  actionText$: Observable<string>;
  ssoError$: Observable<string | undefined>;
  mfaRequired$: Observable<boolean>;

  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly route: ActivatedRoute,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.microsoftSsoUrl = this.buildSsoUrl('AzureAd2Client');
    this.googleSsoUrl = this.buildSsoUrl('GoogleOidcClient');
    this.actionText$ = this.route.url.pipe(
      map((url) => {
        const lastUrlSegment = url[url.length - 1].path;
        return lastUrlSegment === 'sign-up'
          ? 'Sign Up for Geneious Biologics'
          : 'Sign In to Geneious Biologics';
      }),
    );
    this.ssoError$ = this.route.data.pipe(
      map((data) => data.ssoError),
      take(1),
    );

    this.mfaRequired$ = this.store
      .pipe(select(selectMFARequired))
      .pipe(startWith(false), shareReplay({ bufferSize: 1, refCount: true }));
  }

  private buildSsoUrl(provider: string): string {
    return `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/${provider}?source=signup`;
  }
}
