import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Folder } from '../../folders/models/folder.model';
import { PipelineItem } from '../pipelineItem.model';
import { PermissionsService } from '../../permissions/permissions.service';
import { PipelineSelectionSignaturesService } from '../../pipeline-dialogs/pipeline-selection-signatures.service';
import { selectionSignatureMatches } from '../../document-selection-signature/document-selection-signature.model';
import { GridSelectionState } from '../../grid-selection-state/grid-selection-state.interfaces';
import { map, publishReplay, refCount, takeUntil } from 'rxjs/operators';
import { SelectionState } from '../../../features/grid/grid.component';
import { CleanUp } from '../../../shared/cleanup';
import { GridSelectionStateService } from '../../grid-selection-state/grid-selection-state.service';
import { FilesTableFacade } from '../../files-table/files-table.facade';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import {
  NgbModalRef,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-pipeline-chooser-v2',
  templateUrl: './bx-pipeline-chooser-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    AsyncPipe,
  ],
})
export class BxPipelineChooserV2Component extends CleanUp implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  // Title shown in the Dialog UI.
  @Input() name: string;
  // The current folder. Note that one day soon we may need to select files from >1 folder..
  // keep that in the back of your mind.
  @Input() folder: Folder;
  @Input() items$: Observable<PipelineItem[]>;

  processedItems$: Observable<PipelineItem[]>;
  canWrite: boolean;

  private selected: SelectionState;
  private modalRef: NgbModalRef;
  private selectionState$: Observable<SelectionState>;

  constructor(
    private pipelineDialogService: PipelineDialogService,
    private selectionSignatureService: PipelineSelectionSignaturesService,
    private gridSelectionStateService: GridSelectionStateService,
    private filesTableFacade: FilesTableFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.canWrite = this.folder
      ? PermissionsService.hasWriteAccess(this.folder.permissions)
      : false;
    const gridSelectionState$ = this.gridSelectionStateService.getSelectionState(this.folder.id);
    this.selectionState$ = combineLatest([
      gridSelectionState$,
      this.filesTableFacade.filesDictionary$,
    ]).pipe(
      map(([selection, files]) => {
        if (selection) {
          const selectedRows: Item[] = selection.rows.map((row) => files[row.id]);
          return {
            ids: selection.ids,
            selectAll: false,
            firstRow: selectedRows[0],
            selectedRows: selectedRows,
            noOfRowsSelected: selection.totalSelected,
            totalNoOfRows: selection.total,
          };
        } else {
          return {
            ids: [],
            selectAll: false,
            firstRow: null,
            selectedRows: [],
            noOfRowsSelected: 0,
            totalNoOfRows: 0,
          };
        }
      }),
    );

    this.selectionState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectionState) => {
      this.selected = selectionState;
    });

    this.processedItems$ = combineLatest([gridSelectionState$, this.items$]).pipe(
      takeUntil(this.ngUnsubscribe),
      map(([selection, items]) =>
        items.map((item) => {
          return <PipelineItem>{
            label: item.label,
            id: item.id,
            component: item.component,
            disabled: this.isDisabled(this.selectionSignatureService, item, selection),
            selectionState: item.selectionState,
            helpMessage: item.helpMessage ? item.helpMessage : this.getPipelineHelp(item),
            options: item.options,
          };
        }),
      ),
      publishReplay(1),
      refCount(),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    // Don't show these dialogs on the homepage if the user gets logged out.
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  showDialog(item: PipelineItem) {
    this.modalRef = this.pipelineDialogService.showDialog({
      component: item.component,
      folderID: this.folder.id,
      selected: item.selectionState || this.selected,
      otherVariables: item.options || {},
    });
  }

  isDisabled(
    selectionSignatureService: PipelineSelectionSignaturesService,
    item: PipelineItem,
    selection: GridSelectionState,
  ): boolean {
    const pipelineId = item.id;
    // TODO Make this more flexible when we have pipelines that actually work on read only folders.
    if (!this.canWrite) {
      return true;
    }
    // This code sucks, but requires alot of cleanup as some behaviour relies on the selection
    // signature check being skipped if disabled attribute is set.
    if (typeof item.disabled != 'undefined') {
      return item.disabled;
    }
    if (this.selectionSignatureService.isPipelineRegistered(pipelineId)) {
      const pipelineItemAcceptedSignatures =
        selectionSignatureService.getPipelineSelectionSignatures(pipelineId);
      if (!selection) {
        return true;
      }
      const hasRows = selection.totalSelected > 0 && !!selection.rows;
      const signatureMatches = selectionSignatureMatches(
        selection.rows,
        pipelineItemAcceptedSignatures,
      );
      return !hasRows || (hasRows && !signatureMatches);
    } else {
      console.error(
        `A pipeline exists (${item.label}/${pipelineId}) but has no corresponding selection signature entries. ` +
          `This is not permissible and indicates missing configuration/setup steps. ` +
          `Please add an entry to the selection signatures register, and look for any further missing pieces.`,
      );
      // pipeline isn't known and can't be run anyway
      return true;
    }
  }

  getPipelineHelp(item: PipelineItem): string {
    if (!this.canWrite) {
      return 'This folder is read-only. Please contact the folder admin to obtain write privileges.';
    }
    const pipelineId = item.id;
    const help = this.selectionSignatureService.getHelpMessage(pipelineId);
    return typeof help === 'string' ? help.replace('<html>', '').replace('</html>', '') : '';
  }

  uniqueId(index: number, item: PipelineItem): string {
    return item.id;
  }
}
