import { filter, shareReplay, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { IS_FREE_ORG } from '../../../shared/access-check/access-check-condition.model';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { selectedFolder } from '../../folders/store/folder.selectors';
import { CreateReferenceDbDialogComponent } from '../../blast/create-reference-db-dialog/create-reference-db-dialog.component';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { HideIfDirective } from '../../../shared/access-check/directives/hide/hide-if.directive';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';
import { AccessConditionTextPipe } from '../../../shared/access-check/access-condition-text.pipe';

@Component({
  selector: 'bx-blast-root-folder-detail',
  templateUrl: './blast-root-folder-detail.component.html',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    HideIfDirective,
    ToolstripComponent,
    ToolstripItemComponent,
    PageMessageComponent,
    AsyncPipe,
    AccessConditionTextPipe,
  ],
})
export class BlastRootFolderDetailComponent implements OnInit, OnDestroy {
  folder$: Observable<FolderTreeItem>;

  readonly isFreeOrg = IS_FREE_ORG;
  readonly title = 'Reference Sequences';
  readonly messages = [
    'Used to automatically annotate query sequences.',
    'Shared with your entire organization.',
  ];

  folder: FolderTreeItem;

  private createDatabaseModalRef: NgbModalRef;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.folder$ = this.store.pipe(
      select(selectedFolder),
      filter((folder) => folder != null),
      tap((folder) => (this.folder = folder)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  ngOnDestroy() {
    if (this.createDatabaseModalRef) {
      this.createDatabaseModalRef.close();
    }
  }

  newDatabase() {
    this.createDatabaseModalRef = this.dialogService.showDialogV2({
      component: CreateReferenceDbDialogComponent,
      injectableData: {
        folder: this.folder,
      },
      options: CreateReferenceDbDialogComponent.MODAL_OPTIONS,
    });
  }
}
