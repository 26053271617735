<form [formGroup]="form">
  <p class="text-muted px-2">
    Single Cell Antibody Annotator identifies dominant chains within your sequences. When run on
    barcoded sequences in combination with the Barcode Separation operation, it will identify one or
    more dominant chains for each barcode. It is suitable to use with single clones in wells or
    single cells in droplets. Please see the
    <a
      target="_blank"
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070011-Single-Clone-Antibody-Analysis"
      >support page</a
    >
    for more information.
  </p>
  <ngb-alert
    type="warning"
    [dismissible]="true"
    (closed)="dismissAlert$.next(true)"
    *bxDismissible="{ name: 'sc-positional-liabilities', dismiss$: dismissAlert$ }"
    >New Feature: Potential Positional Liabilities. Click "Reset to Defaults" to try now, or
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/11185576529940"
      target="_blank"
      class="alert-link"
    >
      go here
    </a>
    to learn more.
  </ngb-alert>
  @if (showLargeDataWarning) {
    <ngb-alert type="warning" [dismissible]="false">
      Very large datasets may take longer. We aim to get you results as quickly as possible, please
      <a
        href="https://help.geneiousbiologics.com/hc/en-us/requests/new"
        target="_blank"
        class="alert-link"
      >
        reach out
      </a>
      if your analysis is taking longer than expected and we can investigate.
    </ngb-alert>
  }

  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label
        class="col-3 col-form-label"
        for="antibodyDatabase"
        ngbTooltip="The database(s) used for annotating the consensus sequences in order to determine the V(D)J region."
      >
        Reference database(s):
      </label>
      <div class="col-9">
        <bx-multi-select
          id="antibodyDatabase"
          formControlName="antibodyDatabase"
          [selectOptions]="antibodyDatabases$ | async"
          emptyMessage="No reference databases are available"
          entityName="reference database"
          [showMultiple]="multipleRefDbsEnabled$ | async"
          ngFormControlValidator
          data-testid="antibodyDatabase"
        >
        </bx-multi-select>
        <bx-form-errors [control]="form.get('antibodyDatabase')"> </bx-form-errors>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <label class="col-lg-6 col-sm-5 col-form-label" for="fullyAnnotatedStart">
        Sequence region of interest is between:
      </label>
      <div class="col-2">
        <select
          formControlName="fullyAnnotatedStart"
          id="fullyAnnotatedStart"
          class="form-select"
          data-testid="startRegion"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="CDR3">CDR3</option>
          <option value="FR4">FR4</option>
        </select>
      </div>
      <span class="col-auto col-form-label">and</span>
      <div class="col-2">
        <select
          formControlName="fullyAnnotatedEnd"
          id="fullyAnnotatedEnd"
          class="form-select"
          data-testid="endRegion"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="CDR3">CDR3</option>
          <option value="FR4">FR4</option>
          <option value="Constant Region">Constant Region</option>
        </select>
      </div>
      <span class="col-auto col-form-label">inclusive</span>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="clusterVDJ"
            formControlName="clusterVDJ"
            class="form-check-input"
          />
          <label
            for="clusterVDJ"
            ngbTooltip="After assembly or grouping, regions are further clustered to merge sequences which differ due to sequencing errors."
            class="form-check-label"
          >
            Collapse regions at least:
          </label>
        </div>
      </div>
      <div class="col-sm-3 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="clusterVDJthresholdPercent"
            formControlName="clusterVDJthresholdPercent"
            ngFormControlValidator
            [enableIsValidClass]="false"
            class="form-control"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div class="col col-form-label">identical</div>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="retainUpstream"
            formControlName="retainUpstream"
            class="form-check-input"
          />
          <label
            for="retainUpstream"
            ngbTooltip="Retains the specified number of nucleotides upstream of the fully annotated region when trimming the ends of contigs in order to identify duplicates when ignoring incorrect contig ends. If a contig is not long enough to cover the specified range, it will be excluded from the next step in the pipeline."
            class="form-check-label"
          >
            Retain upstream of fully annotated region:
          </label>
        </div>
      </div>
      <div class="col-sm-3 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="retainUpstreamLength"
            formControlName="retainUpstreamLength"
            ngFormControlValidator
            [enableIsValidClass]="false"
            min="{{ (fullyAnnotatedStartCDR$ | async) ? minBufferCDR : 0 }}"
            class="form-control"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
      @if (fullyAnnotatedStartCDR$ | async) {
        <div class="col col-form-label text-muted">
          {{ labelCDR }}
        </div>
      }
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="retainDownstream"
            formControlName="retainDownstream"
            class="form-check-input"
          />
          <label
            for="retainDownstream"
            ngbTooltip="Retains the specified number of nucleotides downstream of the fully annotated region when trimming the ends of contigs in order to identify duplicates when ignoring incorrect contig ends. If a contig is not long enough to cover the specified range, it will be excluded from the next step in the pipeline."
            class="form-check-label"
          >
            Retain downstream of fully annotated region:
          </label>
        </div>
      </div>
      <div class="col-sm-3 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="retainDownstreamLength"
            formControlName="retainDownstreamLength"
            ngFormControlValidator
            [enableIsValidClass]="false"
            min="{{ (fullyAnnotatedEndCDR$ | async) ? minBufferCDR : 0 }}"
            class="form-control"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
      @if (fullyAnnotatedEndCDR$ | async) {
        <div class="col col-form-label text-muted">
          {{ labelCDR }}
        </div>
      }
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="associateDominantSignificantHeavyLightChains"
        formControlName="associateDominantSignificantHeavyLightChains"
        class="form-check-input"
      />
      <label
        for="associateDominantSignificantHeavyLightChains"
        ngbTooltip="If the dominant heavy and light chain sequences are both significant (above the threshold) then they are associated so that they appear in a single row in the result table."
        class="form-check-label"
      >
        Associate significant dominant heavy and light pair
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="keepUnmerged"
        formControlName="keepUnmerged"
        class="form-check-input"
      />
      <label
        class="form-check-label"
        for="keepUnmerged"
        ngbTooltip="Specifies that paired reads which failed to merge should be used in the next step of the pipeline. In use cases where pairs are expected to overlap, discarding unmerged pairs is recommended in order to improve assembly accuracy."
      >
        Keep unmerged reads
      </label>
    </div>

    <div class="mb-0 form-check">
      <input
        type="checkbox"
        id="deNovoAssemblyRequired"
        formControlName="deNovoAssemblyRequired"
        class="form-check-input"
        data-testid="deNovoAssemblyRequired"
      />
      <label
        class="form-check-label"
        for="deNovoAssemblyRequired"
        ngbTooltip="Perform de novo assembly if reads are fully annotated region fragments. Otherwise reads are grouped via the fully annotated region annotation."
      >
        De novo assembly required
      </label>
    </div>
  </bx-card>

  <bx-collapsible-card headerTitle="Analysis Options" [collapseByDefault]="true">
    <div class="mb-3 row align-items-center">
      <label
        for="antibodyAnnotator_sequences_annotationStyle"
        class="col-lg-4 col-sm-3 col-form-label"
        ngbTooltip="Antibody numbering scheme will be used to define CDR boundaries"
      >
        Antibody numbering:
      </label>
      <div class="col-4">
        <select
          id="antibodyAnnotator_sequences_annotationStyle"
          formControlName="antibodyAnnotator_sequences_annotationStyle"
          class="form-select"
        >
          @for (style of annotationStyleOptions; track style) {
            <option [value]="style.value">
              {{ style.label }}
            </option>
          }
        </select>
      </div>
      <div class="col-lg-4 col-sm-5 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="antibodyAnnotator_sequences_addNumberingAnnotations"
            formControlName="antibodyAnnotator_sequences_addNumberingAnnotations"
            class="form-check-input"
          />
          <label
            for="antibodyAnnotator_sequences_addNumberingAnnotations"
            ngbTooltip="Number amino acids in antibodies according to the numbering scheme. Slows analysis with large datasets."
            class="form-check-label"
          >
            Annotate Numbers
          </label>
        </div>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="includeGermlines"
        formControlName="includeGermlines"
        class="form-check-input"
        data-testid="includeGermlines"
      />
      <label for="includeGermlines" class="form-check-label">
        Annotate variants from reference database
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="calculateProteinStatistics"
        formControlName="calculateProteinStatistics"
        class="form-check-input"
      />
      <label
        for="calculateProteinStatistics"
        class="form-check-label"
        ngbTooltip="Calculate protein statistics such as Molecular Weight and Isoelectric Point for the VJ, VDJ and scFv regions."
      >
        Calculate protein statistics
      </label>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-4">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="includeLiabilities"
            formControlName="includeLiabilities"
            class="form-check-input"
          />
          <label
            for="includeLiabilities"
            ngbTooltip="Search and score motifs liable to post-translational modifications or any other types of modifications or beneficial motifs. Slows analysis with large datasets."
            class="form-check-label"
          >
            Find liabilities and assets:
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070271"
            >
              <mat-icon class="icon-small">help</mat-icon>
            </a>
          </label>
        </div>
      </div>
      <div class="col-8">
        <textarea
          formControlName="antibodyAnnotator_liabilities_liabilitiesText"
          rows="3"
          class="form-control"
          [attr.disabled]="liabilitiesTextDisabled$ | async"
        >
        </textarea>
      </div>
    </div>

    <div class="row align-items-center mb-0">
      <div class="col-sm-12 col-lg-6 d-flex align-items-center flex-nowrap flex-grow-1 mb-2">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="antibodyAnnotator_database_findAdditionalFeatures"
            formControlName="antibodyAnnotator_database_findAdditionalFeatures"
            class="form-check-input"
          />
          <label
            for="antibodyAnnotator_database_findAdditionalFeatures"
            ngbTooltip="Select a reference database to annotate additional features (such as signal peptides and promoters) on the query sequences."
            class="form-check-label text-nowrap"
          >
            Additional features:
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069831"
            >
              <mat-icon class="icon-small">help</mat-icon>
            </a>
          </label>
        </div>
        <bx-select
          id="antibodyAnnotator_database_featureDatabase"
          class="flex-grow-1"
          formControlName="antibodyAnnotator_database_featureDatabase"
          [selectOptions]="featureDatabases$ | async"
          [showFormErrors]="true"
        ></bx-select>
      </div>
      <div
        class="col-sm-12 col-lg-6 d-flex align-items-center flex-nowrap justify-content-end mb-2"
      >
        <label
          for="antibodyAnnotator_database_featureDatabaseMismatches"
          class="col-form-label text-nowrap ms-3 me-2"
          ngbTooltip="The maximum allowed mismatches that will be tolerated for reference feature annotation."
        >
          Mismatches %:
        </label>
        <input
          type="number"
          id="antibodyAnnotator_database_featureDatabaseMismatches"
          formControlName="antibodyAnnotator_database_featureDatabaseMismatches"
          ngFormControlValidator
          min="0"
          max="99"
          class="form-control me-3"
        />
        <label
          for="antibodyAnnotator_database_featureDatabaseGapSize"
          class="col-form-label text-nowrap me-2"
          ngbTooltip="The maximum allowed gap size that will be tolerated for reference feature annotation."
        >
          Gap size:
        </label>
        <input
          type="number"
          id="antibodyAnnotator_database_featureDatabaseGapSize"
          formControlName="antibodyAnnotator_database_featureDatabaseGapSize"
          ngFormControlValidator
          min="0"
          max="99"
          class="form-control"
        />
      </div>
    </div>
  </bx-collapsible-card>

  <bx-collapsible-card headerTitle="Filtering Options" [collapseByDefault]="true">
    <div class="mb-3 row align-items-center">
      <label class="col-sm-6 col-lg-4 col-form-label" for="minLength"
        >Only keep sequences longer than:</label
      >
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="minLength"
            formControlName="minLength"
            ngFormControlValidator
            class="form-control"
            data-testid="minLength"
          />
          <span class="input-group-text">bp</span>
        </div>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-sm-12 col-lg-4 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="onlyUseLongest"
            formControlName="onlyUseLongest"
            class="form-check-input"
            data-testid="onlyUseLongest"
          />
          <label
            for="onlyUseLongest"
            class="form-check-label"
            ngbTooltip="This is to improve performance on large data sets where excessive data significantly slows down analysis. 500 reads is generally sufficient"
          >
            Only use longest:
          </label>
        </div>
      </div>
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="onlyUseLongestLength"
            formControlName="onlyUseLongestLength"
            ngFormControlValidator
            class="form-control"
          />
        </div>
      </div>
      <label class="col col-form-label" for="onlyUseLongest">reads from each list/barcode</label>
    </div>

    <div class="mb-3 row align-items-center">
      <label class="col-sm-12 col-lg-4 col-form-label" for="strictMinimumVariantSupport"
        >Only keep regions with at least:</label
      >
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="strictMinimumVariantSupport"
            formControlName="strictMinimumVariantSupport"
            ngFormControlValidator
            class="form-control"
            data-testid="minVariant"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <label class="col col-form-label" for="strictMinimumVariantSupport">
        of the read count of the dominant same chain region
      </label>
    </div>

    <div class="mb-3 row align-items-center">
      <label
        class="col-sm-12 col-lg-4 col-form-label"
        for="minimumSignificantOfCellPercent"
        ngbTooltip="Regions deemed insignificant are retained but annotated as not significant."
        >Significant regions have at least:</label
      >
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="minimumSignificantOfCellPercent"
            formControlName="minimumSignificantOfCellPercent"
            ngFormControlValidator
            class="form-control"
            data-testid="minimumSignificantOfCellPercent"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <label class="col col-form-label" for="minimumSignificantOfCellPercent"
        >of the read count of the cell/barcode</label
      >
    </div>

    <div class="mb-3 row align-items-center">
      <label
        class="col-sm-12 col-lg-4 col-form-label"
        for="minimumSignificantReadCount"
        ngbTooltip="Regions deemed insignificant are retained but annotated as not significant."
        >Significant regions have at least:</label
      >
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="minimumSignificantReadCount"
            formControlName="minimumSignificantReadCount"
            ngFormControlValidator
            class="form-control"
            data-testid="minimumSignificantReadCount"
          />
        </div>
      </div>
      <label class="col col-form-label" for="minimumSignificantReadCount">reads</label>
    </div>

    <div class="row align-items-center mb-0">
      <label
        class="col-sm-12 col-lg-4 col-form-label"
        for="minimumVariantSupport"
        ngbTooltip="Regions deemed insignificant are retained but annotated as not significant."
        >Significant regions have at least:</label
      >
      <div class="col-sm-4 col-lg-2">
        <div class="input-group">
          <input
            type="number"
            id="minimumVariantSupport"
            formControlName="minimumVariantSupport"
            ngFormControlValidator
            class="form-control"
            data-testid="minimumVariantSupport"
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
      <label class="col col-form-label" for="minimumVariantSupport">
        of the read count of the dominant same chain region
      </label>
    </div>
  </bx-collapsible-card>

  <bx-clustering-options-card
    filterRegionsFor="singleClone"
    [clusterSummaryFilterEnabled]="false"
    [collapseByDefault]="true"
  ></bx-clustering-options-card>

  <bx-collapsible-card headerTitle="Advanced Options" [collapseByDefault]="true">
    <ng-container *bxShowIf="'nameSchemes'">
      <div class="mb-3 row align-items-center">
        <label for="nameScheme" class="col-6 col-form-label"> Document name scheme: </label>
        <div class="col-6">
          <select formControlName="fileNameSchemeID" id="nameScheme" class="form-select">
            <option [ngValue]="null"></option>
            @for (nameScheme of nameSchemes$ | async; track nameScheme) {
              <option [value]="nameScheme.value">
                {{ nameScheme.displayName }}
              </option>
            }
          </select>
        </div>
      </div>
      @if (nameSchemeWarningMessage) {
        <div class="mb-3 justify-content-center">
          <div id="nameSchemeHelp" class="col-7 col-form-label text-muted">
            {{ nameSchemeWarningMessage }}
          </div>
        </div>
      }
    </ng-container>

    <div class="mb-3 row align-items-center">
      <label
        class="col-6 col-form-label"
        for="antibodyAnnotator_sequences_queryGeneticCode"
        ngbTooltip="The genetic code to use for translating nucleotide sequences. Codes obtained from NCBI."
      >
        Genetic code:
      </label>
      <div class="col-6">
        <select
          id="antibodyAnnotator_sequences_queryGeneticCode"
          formControlName="antibodyAnnotator_sequences_queryGeneticCode"
          class="form-select"
        >
          @for (geneticCode of geneticCodes$ | async; track geneticCode) {
            <option [value]="geneticCode.value">
              {{ geneticCode.displayName }}
            </option>
          }
        </select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label
        for="antibodyAnnotator_genes_ambiguousGenesStrategy"
        class="col-6 col-form-label"
        ngbTooltip="How to handle cases where query sequences are equally close to multiple reference genes."
      >
        Record equal gene matches as:
      </label>
      <div class="col-6">
        <select
          formControlName="antibodyAnnotator_genes_ambiguousGenesStrategy"
          id="antibodyAnnotator_genes_ambiguousGenesStrategy"
          class="form-select"
        >
          <option value="partialFrequency">
            Each gene with partial frequency (e.g. frequency of 0.5 for each gene)
          </option>
          <option value="groupOfGenes">
            Group of genes (e.g. assigned to a cluster called IGHD1-26/IGHD2-15)
          </option>
          <option value="unknown">
            Unknown (treat it the same as if it didn't match any gene)
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="antibodyAnnotator_genes_includePseudoGenes"
            formControlName="antibodyAnnotator_genes_includePseudoGenes"
            class="form-check-input"
          />
          <label
            class="form-check-label"
            for="antibodyAnnotator_genes_includePseudoGenes"
            ngbTooltip="Some reference databases include pseudogenes in addition to functional genes. Selecting this option will allow classification of query sequences using these pseudogenes."
          >
            Include pseudogenes from database
          </label>
        </div>
      </div>
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="antibodyAnnotator_genes_includeOrfGenes"
            formControlName="antibodyAnnotator_genes_includeOrfGenes"
            class="form-check-input"
            data-testid="includeOrfGenes"
          />
          <label
            class="form-check-label"
            for="antibodyAnnotator_genes_includeOrfGenes"
            ngbTooltip="Some reference databases include ORF genes in addition to functional genes. Selecting this option will allow classification of query sequences using these ORFs."
          >
            Include ORF genes from database
          </label>
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <div class="col-6 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="trimPrimers"
            formControlName="trimPrimers"
            class="form-check-input"
          />
          <label class="form-check-label" for="trimPrimers">Trim primers:</label>
        </div>
      </div>
      <div class="col-6">
        <select formControlName="primers" id="primers" class="form-select" ngFormControlValidator>
          @for (db of primerDatabases$ | async; track db) {
            <option [value]="db.value">
              {{ db.displayName }}
            </option>
          }
        </select>
        <bx-form-errors [control]="form.get('primers')"> </bx-form-errors>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="forceFullRegionAnnotationOutsideCDR3"
        formControlName="forceFullRegionAnnotationOutsideCDR3"
        class="form-check-input"
        data-testid="annotateEntireRegions"
      />
      <label
        class="form-check-label"
        for="forceFullRegionAnnotationOutsideCDR3"
        ngbTooltip="If a CDR or FR annotation would be truncated due to mismatches, instead force it to end at the boundary of the respective CDR/FR region. That region will be complete and not truncated."
      >
        Always annotate entire regions (except CDR3)
      </label>
    </div>

    <hr />

    <bx-fr-adjustment-options
      class="mb-3"
      formControlNamePrefix="antibodyAnnotator_sequences_"
    ></bx-fr-adjustment-options>

    <div class="d-flex align-items-center mb-0" *bxShowIf="'showCustomAdvancedOptionsTextField'">
      <label
        ngbTooltip="Allows specifying custom options separated by a space, with = between each option name and values. If an option value needs to contain a space use '\s'"
        class="col-form-label text-nowrap me-3"
      >
        Custom Options:
      </label>
      <input
        type="text"
        id="customAdvancedOptions"
        formControlName="customAdvancedOptions"
        ngFormControlValidator
        class="form-control flex-grow-1"
      />
    </div>
  </bx-collapsible-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
