<form [formGroup]="form" class="container px-3">
  <div class="mb-3 row" data-spec="create-ref-db-name-control">
    <div class="col-4">
      <label for="databaseName" class="col-form-label pt-2"> Database name: </label>
    </div>
    <div class="col-8">
      <input
        type="text"
        class="form-control"
        id="databaseName"
        name="databaseName"
        formControlName="databaseName"
        placeholder="Enter a name"
        ngFormControlValidator
        [validateOnBlur]="true"
        ngbAutoFocus
        data-spec="create-ref-db-name-input"
      />
      <bx-form-errors
        [control]="form.controls.databaseName"
        [showAllErrors]="false"
      ></bx-form-errors>
    </div>
  </div>

  <bx-database-type-control [control]="form.controls.databaseType"></bx-database-type-control>

  <div class="mb-3 row" data-spec="create-ref-db-files-control">
    <div class="col-4">
      <label for="files" class="col-form-label">Sequence files:</label>
    </div>
    <div class="col-8">
      <button
        (click)="filesInput.click()"
        type="button"
        class="btn btn-light me-1"
        [ngClass]="{ 'is-invalid': form.hasError }"
      >
        <input
          #filesInput
          id="files"
          name="files"
          type="file"
          (change)="onFilesSelected($event.target.files)"
          class="form-control d-none"
          [accept]="['.geneious', '.fasta', '.fastq']"
          multiple="true"
          data-spec="create-ref-db-files-input"
        />
        Add Files
      </button>
      <bx-form-errors class="mt-1" [control]="form" [showAllErrors]="false"></bx-form-errors>
    </div>
  </div>

  @if ((numFiles$ | async) > 0) {
    <div class="row" data-spec="create-ref-db-file-selection">
      <div class="col">
        <ul class="mb-2 me-2">
          @for (file of selectedFiles$ | async; track file) {
            <li>
              <div class="d-flex align-items-center justify-content-between">
                <i class="text-truncate small" [title]="file.name">{{ file.name }}</i>
                <bx-clear-file-button
                  (click)="onFileDeselected(file)"
                  data-spec="create-ref-db-clear-file-btn"
                ></bx-clear-file-button>
              </div>
            </li>
          }
        </ul>
      </div>
    </div>
  }

  <div
    class="row mb-0"
    ngbTooltip="This option is only available for Germline Gene and Antibody Template databases"
    [disableTooltip]="enableAutoAnnotationOption$ | async"
    placement="left"
    data-spec="create-ref-db-auto-anno-control"
  >
    <div class="col-form-label d-flex align-items-center">
      <input
        type="checkbox"
        id="autoAnnotateGermlineGenes"
        formControlName="autoAnnotateGermlineGenes"
        value="true"
        class="form-check-inline"
        data-spec="create-ref-db-auto-anno-input"
      />
      <label for="autoAnnotateGermlineGenes" class="form-check-label">
        Automatically format unannotated sequences
      </label>
    </div>
  </div>
</form>
