import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  documentTableSelected,
  fetchDocumentTables,
  fetchDocumentTablesFailure,
  fetchDocumentTablesInBulk,
  fetchDocumentTablesSuccess,
  refreshTables,
  restoreDocumentTable,
  restoreDocumentTableFailure,
  restoreDocumentTableProgressUpdate,
  restoreDocumentTableSuccess,
} from './document-table-state.actions';
import {
  catchError,
  exhaustMap,
  filter,
  groupBy,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import { DocumentService } from '../../../../nucleus/services/documentService/document-service.v1';
import { FolderService } from '../../folders/folder.service';
import { GroupedObservable, Observable, of, zip } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { DocumentTableStore } from './document-table-state';
import {
  selectDocumentTable,
  selectDocumentTableNotRestoring,
  selectDocumentTableSequencesCount,
} from './document-table-state.selectors';
import {
  isAllSequencesTable,
  isChainCombinationsTable,
  isComparisonSummaryTable,
} from '../../ngs/table-type-filters';
import { sortByNaturalHumanAntibody } from './regions-sort';

@Injectable()
export class DocumentTableStateEffects {
  fetchDocumentTablesOnDocumentSelection$ = createEffect(() =>
    this.folderService.folderSelectionState$.pipe(
      filter((state) => state?.totalSelected === 1),
      map((selection) => selection.rows[0]),
      filter(
        (document) =>
          document.type === 'ngsResult' ||
          document.type === 'ngsComparison' ||
          document.type === 'masterDatabase',
      ),
      map((document) =>
        fetchDocumentTables({
          documentID: document.id,
          numberOfSequences: document.number_of_sequences,
        }),
      ),
    ),
  );

  fetchDocumentTablesInBulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDocumentTablesInBulk),
      map(({ documentIDs }) => documentIDs),
      mergeAll(),
      mergeMap((documentID) => this.getTablesForDocument(documentID), 10),
    ),
  );

  fetchDocumentTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDocumentTables, refreshTables),
      switchMap(({ documentID }) => this.getTablesForDocument(documentID)),
    ),
  );

  restoreIfArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentTableSelected),
      mergeMap(({ documentID, tableName }) => {
        return this.store.pipe(
          select(selectDocumentTableNotRestoring(documentID, tableName)),
          filter((table) => !!table),
          take(1),
          map((table) => table.indexState),
          filter((indexState) => indexState === 'archived' || indexState === 'absent'),
          switchMap((indexState) => {
            if (indexState === 'archived') {
              return of(restoreDocumentTable({ documentID, tableName }));
            } else {
              return of(
                restoreDocumentTableFailure({
                  documentID,
                  tableName,
                  error: 'Document table index state is absent',
                }),
              );
            }
          }),
        );
      }),
    ),
  );

  restoreDocumentTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreDocumentTable),
      groupBy((action) => action.documentID + action.tableName),
      mergeMap((group$) => this.restoreDocumentTable(group$), this.RESTORE_CONCURRENCY_LIMIT),
    ),
  );

  // Limit the number of restore requests at a time that can be made to the Nucleus Document Table Service by a user session.
  private RESTORE_CONCURRENCY_LIMIT = 2;

  constructor(
    private actions$: Actions,
    private documentService: DocumentService,
    private folderService: FolderService,
    private store: Store<DocumentTableStore>,
  ) {}

  private getTablesForDocument(documentID: string) {
    return this.documentService.getTables(documentID).pipe(
      map(sortByNaturalHumanAntibody),
      map((tables) => fetchDocumentTablesSuccess({ documentID, tables })),
      catchError((error) => {
        if (error.status === 403) {
          return of(
            fetchDocumentTablesFailure({
              documentID,
              reason: 'No tables exist on this document',
            }),
          );
        } else {
          return of(
            fetchDocumentTablesFailure({
              documentID,
              reason: 'Failed to fetch document tables',
            }),
          );
        }
      }),
    );
  }
  private restoreDocumentTable(
    group$: GroupedObservable<string, ReturnType<typeof restoreDocumentTable>>,
  ) {
    return group$.pipe(
      exhaustMap(({ documentID, tableName }) => {
        const numberOfSequences$ = this.store.pipe(
          select(selectDocumentTableSequencesCount(documentID)),
          take(1),
        );
        const table$ = this.store.pipe(select(selectDocumentTable(documentID, tableName)), take(1));
        const restoreTableCompleted$ = this.documentService.restoreTable(documentID, tableName);

        return zip(table$, numberOfSequences$).pipe(
          switchMap(([table, numberOfSequences]) => {
            return new Observable<Action>((observer) => {
              const longTableEquation = 2 * 10 ** -5 * (numberOfSequences ?? 100000) + 10;
              const shortTableEquation = 8 * 10 ** -7 * (numberOfSequences ?? 100000) + 5;
              const isLargeTable =
                isAllSequencesTable(table) ||
                isChainCombinationsTable(table) ||
                isComparisonSummaryTable(table);
              const restoreDurationEstimateSeconds = isLargeTable
                ? longTableEquation
                : shortTableEquation;
              const progressTime = 100 / restoreDurationEstimateSeconds;
              let progress = 0;
              let totalTime = 0;

              restoreTableCompleted$.subscribe({
                next: () => {
                  observer.next(restoreDocumentTableSuccess({ documentID, tableName }));
                  observer.complete();
                },
                error: () => {
                  observer.next(
                    restoreDocumentTableFailure({
                      documentID,
                      tableName,
                      error: 'Failed to restore document table',
                    }),
                  );
                },
              });

              const intervalListener = setInterval(() => {
                progress += progressTime;
                totalTime += 1000;
                observer.next(
                  restoreDocumentTableProgressUpdate({
                    documentID,
                    tableName,
                    progress: Math.min(100, progress),
                  }),
                );

                if (progress >= 100) {
                  clearInterval(intervalListener);
                }
              }, 1000);

              return () => {
                clearInterval(intervalListener);
              };
            }).pipe(
              // This theoretically should never happen, but it's possible.
              catchError(() =>
                of(
                  restoreDocumentTableFailure({
                    documentID,
                    tableName,
                    error: 'Failed to restore document table',
                  }),
                ),
              ),
            );
          }),
        );
      }),
    );
  }
}
