import { Component, ChangeDetectionStrategy, OnDestroy, HostBinding } from '@angular/core';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { CleanUp } from '../../../shared/cleanup';
import { ViewerDocumentData } from '../viewer-document-data';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { annotatedPluginDocumentViewerSelector } from '../viewer-selectors';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AsyncPipe } from '@angular/common';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

@ViewerComponent({
  key: 'pdf-report',
  title: 'PDF Viewer',
  selector: annotatedPluginDocumentViewerSelector([
    DocumentSelectionSignature.forDocumentClass(
      'com.biomatters.plugins.PDFdocument.PDFPluginDocument',
      1,
      1,
    ),
  ]),
})
@Component({
  selector: 'bx-pdf-report-viewer',
  templateUrl: './pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToolstripComponent, ToolstripItemComponent, AsyncPipe],
})
export class PdfViewerComponent extends CleanUp implements OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column flex-grow-1 flex-shrink-1';

  pdfFileUrl$: Observable<SafeResourceUrl>;
  private objectURL: string;

  constructor(
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentHttpService: DocumentHttpV2Service,
    private sanitizer: DomSanitizer,
  ) {
    super();

    // TODO make this more universal if we want to support different PDF files in the future.
    this.pdfFileUrl$ = viewerDataService.getData('pdf-viewer').pipe(
      tap(() => this.destroyPDFResourceReference()),
      filter((state) => state.selection.totalSelected > 0),
      switchMap((state) =>
        this.documentHttpService.getDocumentPart(state.selection.rows[0].id, 'FILE', 'blob'),
      ),
      map((blob) => URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }))),
      tap((url) => (this.objectURL = url)),
      map((url) => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyPDFResourceReference();
  }

  private destroyPDFResourceReference() {
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }
}
