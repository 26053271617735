<div class="bx-page-container bx-page-container-sm pb-5">
  <h2>Reset email sent</h2>
  <p>
    To reset your password, please check your email. If you have a Geneious account, we have sent
    you further instructions.
  </p>
  <div class="d-flex justify-content-end">
    <button class="btn btn-primary me-2" type="button" routerLink="/login">Back to Login</button>
    <button class="btn btn-secondary" type="button" [routerLink]="['/reset-password/request']">
      Resend Instructions
    </button>
  </div>
</div>
