import { GridState } from 'src/app/features/grid/grid.interfaces';
import {
  Column,
  ColumnsState,
  SequencesOutputType,
} from 'src/nucleus/services/models/exportOptions.model';

export const sequencesOutputTypeOptions: { label: string; value: SequencesOutputType }[] = [
  { label: 'Geneious', value: 'Geneious' },
  { label: 'Genbank', value: 'Genbank' },
  { label: 'Fasta', value: 'Fasta' },
  { label: 'Fasta Compressed', value: 'FastaCompressed' },
  { label: 'Fastq', value: 'Fastq' },
  { label: 'Fastq Compressed', value: 'FastqCompressed' },
  { label: 'Newick', value: 'Newick' },
];

/**
 * Converts GridState columns to ColumnsState for the export pipeline. Picks
 * only relevant properties and optionally filters out hidden columns that have
 * no sort properties.
 *
 * @param columnsState GridState columns to convert
 * @param includeHidden whether to include all columns in the ColumnsState,
 *    or filter out hidden columns that don't have sort properties
 * @returns the converted ColumnsState
 */
export function toColumnsState(
  columnsState: GridState['columnsState'],
  includeHidden = true,
): ColumnsState {
  const columns: Column[] = columnsState
    .filter((col) => includeHidden || !col.hide || col.sort || col.sortIndex != null)
    .map(({ colId, sort, sortIndex, hide }) => {
      const column: Column = { colId, name: colId };
      if (sort) {
        column.sort = sort;
      }
      if (sortIndex != undefined) {
        column.sortIndex = sortIndex;
      }
      // The only case where the pipeline should hide the column is when the user
      // requested to export only visible columns, but there was a hidden column
      // affecting the sort.
      if (hide && !includeHidden) {
        column.hide = hide;
      }
      return column;
    });
  return { columns };
}
