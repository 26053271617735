import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WORKSPACE_URL } from '../router/app.routing.model';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectUserIsOrgAdmin, selectSharedWorkspaceID } from './auth.selectors';
import { forkJoin } from 'rxjs';

/**
 * Protect internal application pages.
 */
@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate() {
    const isAdmin$ = this.store.pipe(select(selectUserIsOrgAdmin), first());
    const sharedWorkspaceFolderID$ = this.store.pipe(select(selectSharedWorkspaceID), first());
    return forkJoin([isAdmin$, sharedWorkspaceFolderID$]).pipe(
      map(([isAdmin, sharedWorkspaceFolderID]) =>
        isAdmin ? true : this.router.createUrlTree([`/${WORKSPACE_URL}`, sharedWorkspaceFolderID]),
      ),
    );
  }
}
