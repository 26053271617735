import {
  clearSequencePreferences,
  removeSequencePreferencesOption,
  setAllSequenceViewerPreferences,
  upsertSequencePreferences,
} from './sequence-viewer-preferences.actions';
import { SequenceViewerPreferencesStates } from './sequence-viewer-preferences.model';
import { createReducer, on } from '@ngrx/store';
import { mergeNestedObjects } from '../../../shared/utils/object';

export const initialState: SequenceViewerPreferencesStates = {};

export const sequenceViewerPreferencesReducer = createReducer(
  initialState,
  on(
    setAllSequenceViewerPreferences,
    (_, { sequenceViewerPreferencesStates }) => sequenceViewerPreferencesStates,
  ),
  on(upsertSequencePreferences, (state, { target, sequenceViewerPreferencesState }) => {
    const { [target]: targetState, ...newState } = state;
    return {
      ...newState,
      [target]: mergeNestedObjects(targetState || {}, sequenceViewerPreferencesState),
    };
  }),
  on(clearSequencePreferences, (state, { target }) => {
    const { [target]: _, ...newState } = state;
    return newState;
  }),
  on(removeSequencePreferencesOption, (state, { target, removedOption }) => {
    const { [target]: targetState, ...newState } = state;
    return {
      ...newState,
      [target]: removeOption(targetState, removedOption.path.concat(removedOption.key)),
    };
  }),
);

/**
 * Recursively removes an option key using the path to the key.
 * @param options SV options object stored on the server.
 * @param path An array mapping the path to the object key to remove.
 */
function removeOption(options: any, path: string[]) {
  const result = { ...options };
  const key = path.shift();

  // Delete if the right depth has been reached, or if:
  // Metadata columns are stored as Map<string, boolean> for enabled status on the server, but are provided to SV core as an array of objects with
  // many properties. This means if there is a validation error for an option in SV core, it will provide the path to the array element that failed
  // but this doesn't match the server. Delete the whole object if this happens.
  if (path.length === 0 || key === 'metadataColumns') {
    delete result[key];
  } else {
    // Recursion!
    result[key] = removeOption(options[key], path);
  }

  return result;
}
