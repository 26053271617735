<div class="alert alert-info" data-spec="export-document-count">Exporting alignment as a table</div>

<form name="exportDocumentMetadataForm" [formGroup]="form" class="container">
  <fieldset class="mb-3 row">
    <div class="col-4 col-lg-3">
      <legend class="col-form-label" data-spec="table-output-type-legend">
        Table output type:
      </legend>
    </div>
    <div class="col-8 col-lg-4">
      <div class="form-check" data-spec="table-output-type-xlsx-option">
        <input
          type="radio"
          id="tableOutputTypeExcel"
          value="xlsx"
          name="tableOutputType"
          [formControl]="form.controls.tableTypeControl"
          class="form-check-input"
          data-spec="table-output-type-xlsx-input"
        />
        <label for="tableOutputTypeExcel" class="form-check-label w-100"> Excel (.xlsx) </label>
      </div>
      <div class="form-check" data-spec="table-output-type-csv-option">
        <input
          type="radio"
          id="tableOutputTypeCsv"
          value="csv"
          name="tableOutputType"
          [formControl]="form.controls.tableTypeControl"
          class="form-check-input"
          data-spec="table-output-type-csv-input"
        />
        <label for="tableOutputTypeCsv" class="form-check-label w-100">
          Comma-Separated Values (.csv)
        </label>
      </div>
    </div>
  </fieldset>

  <fieldset class="mb-3 row">
    <div class="col-4 col-lg-3">
      <legend class="col-form-label" data-spec="exported-columns-legend">Metadata columns:</legend>
    </div>
    <div class="col-8">
      <bx-multi-select
        [selectOptions]="allMetadataColumns"
        [formControl]="form.controls.metadataColumns"
        entityName="Metadata column"
      ></bx-multi-select>
    </div>
  </fieldset>
  <div class="form-check">
    <input
      type="checkbox"
      id="include-consensus"
      [formControl]="form.controls.includeConsensusSequence"
      class="form-check-input"
      data-spec="summary-disclaimer-input"
    />
    <label class="form-check-label" for="include-consensus" data-spec="exported-columns-legend">
      Include consensus sequence
    </label>
  </div>
</form>
