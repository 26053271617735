<div class="modal-body scrollable">
  <p class="text-muted px-2">
    Assay data can be in csv or excel format. The assay data file must contain a column that matches
    a column in your annotator results.
  </p>
  <div class="container-fluid">
    <div class="row mb-2">
      <label for="dataSource" class="col-4 col-form-label">Choose file from:</label>
      <div class="col">
        <bx-select
          id="dataSource"
          [formControl]="assayDataSource"
          [selectOptions]="availableAssayDataSources"
        >
        </bx-select>
      </div>
    </div>

    <div class="row align-items-center mb-0">
      <label for="existingAssayData" class="col-4 col-form-label">Assay data files:</label>
      <div class="col">
        @if (assayDataSource.value === 'cloud') {
          <bx-multi-select
            id="existingAssayData"
            placeholder="Select file..."
            [formControl]="existingAssayDataFiles"
            [selectOptions]="availableAssayDataFilesOnCloud$ | async"
            emptyMessage="No assay data files available in the current folder"
          ></bx-multi-select>
        }
        @if (assayDataSource.value === 'fileSystem') {
          <bx-file-button
            btnClass="btn btn-light me-1"
            message="Browse Files"
            [fileTypes]="fileTypes"
            [multiple]="true"
            (newFiles)="handleFileUpload($event)"
          ></bx-file-button>
          @for (file of selectedAssayFiles$ | async; track file) {
            <span class="m-1">
              <span>{{ file.name }}</span>
              <bx-clear-file-button
                class="ms-1"
                (click)="handleClearFile(file)"
              ></bx-clear-file-button>
            </span>
          }
        }
      </div>
    </div>
  </div>
</div>
