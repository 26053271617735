@if (currentEnvironment !== 'other') {
  <select
    class="form-select form-select-lg"
    [value]="currentEnvironment"
    (change)="handleEnvironmentChange($event.target.value)"
    ngbTooltip="Switch to your organization region"
    placement="left"
    data-spec="env-select"
  >
    <option value="US">US Region</option>
    <option value="EU">EU Region</option>
  </select>
}
