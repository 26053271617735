<div class="mb-3 d-flex flex-row justify-content-between">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      name="freezeLayout"
      id="freezeLayout"
      [ngModel]="options.freezeLayout$ | async"
      (ngModelChange)="options.freezeLayout$.next($event)"
    />
    <label for="freezeLayout" class="form-check-label"> Freeze Layout </label>
  </div>
  <button
    type="button"
    class="btn btn-sm btn-primary"
    ngbTooltip="Reset graph layout"
    (click)="options.resetLayout()"
  >
    Reset layout
  </button>
</div>
