import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { ViewerComponent } from '../../../core/viewers-v2/viewers-v2.config';
import { annotatedPluginDocumentViewerSelector } from '../../../core/viewer-components/viewer-selectors';
import { DocumentSelectionSignature } from '../../../core/document-selection-signature/document-selection-signature.model';
import { TreeGraphDatasource } from '../datasources/tree-graph-datasource';
import { ViewerDataService } from '../../../core/viewers-v2/viewer-data/viewer-data.service';
import {
  ViewerDocumentData,
  ViewerResultData,
} from '../../../core/viewer-components/viewer-document-data';
import { map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SequenceDataService } from '../../../core/sequence-viewer/sequence-data.service';
import { CleanUp } from '../../../shared/cleanup';
import { ChartPresenterComponent } from '../chart-presenter/chart-presenter.component';
import { Observable } from 'rxjs';
import { SequenceViewerMetadataService } from '../../../core/sequence-viewer/sequence-viewer-metadata.service';
import { isMetadataColumnGroup } from '../../sequence-viewer-angular/sequence-viewer-utils';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.store';
import { selectSequenceViewerPreference } from '../../../core/user-settings/sequence-viewer-preferences/sequence-viewer-preferences.selectors';
import { NgsZoomService } from '../../../core/ngs/ngs-graphs/graph-zoom/ngs-zoom.service';
import {
  GraphZoomComponent,
  ZoomableChart,
} from '../../../core/ngs/ngs-graphs/graph-zoom/graph-zoom.component';

import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { AsyncPipe } from '@angular/common';
import { APP_NAME } from 'src/app/app.constants';

@ViewerComponent({
  key: 'tree-graph-viewer',
  title: 'Tree View',
  selectors: [
    annotatedPluginDocumentViewerSelector([
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.geneious.publicapi.implementations.DefaultPhylogenyDocument',
        1,
        1,
      ),
    ]),
  ],
})
@Component({
  selector: 'bx-tree-graph-viewer',
  templateUrl: './tree-graph-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    ChartPresenterComponent,
    PageMessageComponent,
    LoadingComponent,
    GraphZoomComponent,
    AsyncPipe,
  ],
})
export class TreeGraphViewerComponent extends CleanUp {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  datasource: TreeGraphDatasource;
  document$: Observable<{ name: string; id: string; metadata: { sequenceMetadataOrder?: string } }>;
  zoomControls$: Observable<ZoomableChart | null>;
  @ViewChild(ChartPresenterComponent) graph: ChartPresenterComponent;

  constructor(
    private cd: ChangeDetectorRef,
    private viewerDataService: ViewerDataService<ViewerDocumentData | ViewerResultData>,
    private sequenceDataService: SequenceDataService,
    private svMetadataService: SequenceViewerMetadataService,
    private store: Store<AppState>,
    private ngsZoomService: NgsZoomService,
  ) {
    super();
    this.ngsZoomService.deregisterZoomControls();
    this.zoomControls$ = this.ngsZoomService.getZoomControls();
    const selection$ = this.viewerDataService
      .getData('tree-graph-viewer')
      .pipe(map((data) => data.selection));

    this.document$ = selection$.pipe(map((selection) => selection.rows[0]));

    const sequenceDocument$ = this.document$.pipe(
      switchMap((document) => this.sequenceDataService.fetchSequences(document.id)),
    );

    const metadataColumnOrder$ = this.document$.pipe(
      map((document) => {
        const metadataOrder = document.metadata.sequenceMetadataOrder;
        return metadataOrder ? JSON.parse(metadataOrder).map((col: string) => `BX_${col}`) : [];
      }),
    );

    const metadata$ = this.document$.pipe(
      switchMap((doc) => this.store.select(selectSequenceViewerPreference(doc.id))),
    );
    sequenceDocument$
      .pipe(withLatestFrom(metadataColumnOrder$, metadata$), takeUntil(this.ngUnsubscribe))
      .subscribe(([document, metadataColumnOrder, sequenceViewerPreferences]) => {
        this.cd.markForCheck();

        const metadataColumns = this.svMetadataService.buildMetadataColumnsFromSequences(
          document.sequences,
        );

        let flattenMetadataColumns = metadataColumns.flatMap((current) => {
          if (isMetadataColumnGroup(current)) {
            const groupName = current.name === APP_NAME ? null : current.name;
            return current.columns.map((column) => {
              // Since we're using the Score metadata value designed for SequenceViewer, we need tc customise it to use
              // the key name when being used in tree view.
              const name = column.name.toLowerCase() === 'score' ? 'BX_Score' : column.name;
              return { ...column, name, groupName };
            });
          }
          return [current];
        });

        flattenMetadataColumns = this.svMetadataService.sortMetadataColumns(
          flattenMetadataColumns,
          metadataColumnOrder,
        );

        this.datasource = new TreeGraphDatasource(
          document,
          flattenMetadataColumns,
          sequenceViewerPreferences?.metadataColumns ?? {},
        );
      });
  }

  exportGraph() {
    if (this.graph) {
      this.document$.pipe(take(1)).subscribe((document) => {
        this.graph.exportGraph(document.name);
      });
    }
  }
}
