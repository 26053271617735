import { FormStates } from '../form-state.model';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';

// TODO Delete this migration once it has been migrated on Postgres.
export function exportMigration(formStates: FormStates): FormStates {
  // @ts-ignore Because export is deprecated but not sure if it's been completely migrated
  const exportFormState = formStates['export'];
  if (!exportFormState) {
    return formStates;
  }

  const exportFormStateOptions: OldExportOptions = exportFormState.options as OldExportOptions;
  // @ts-ignore Because export is deprecated but not sure if it's been completely migrated
  const { export: _, ...formStatesExtracted } = formStates;

  if (
    !('exportOption' in exportFormStateOptions) &&
    'sequencesFormatForm' in exportFormStateOptions
  ) {
    const { sequencesFormatForm, ...otherOptions } = exportFormStateOptions;
    return {
      ...formStatesExtracted,
      [PipelineFormID.EXPORT_DOCUMENT_FILE]: {
        options: {
          ...otherOptions,
          sequencesFormatOptions: {
            ...sequencesFormatForm,
          },
        },
      },
    };
  } else if (
    !('exportOption' in exportFormStateOptions) &&
    'tableFormatForm' in exportFormStateOptions
  ) {
    return {
      ...formStatesExtracted,
      [PipelineFormID.EXPORT_DOCUMENT_METADATA]: {
        options: {
          ...exportFormStateOptions.tableFormatForm,
          tableOutputType: exportFormStateOptions.tableFormatForm.tableOutputType.toLowerCase(),
        },
      },
    };
  } else if ('exportOption' in exportFormStateOptions) {
    if (exportFormStateOptions.exportOption === 'Sequences') {
      return {
        ...formStatesExtracted,
        [PipelineFormID.EXPORT_NGS_SEQUENCES]: {
          options: {
            outputOptions: {
              saveAsNewDocument: false,
              autoDownload: true,
              sequencesOutputType: exportFormStateOptions.sequencesFormatForm.sequencesOutputType,
            },
          },
        },
      };
    } else {
      return {
        ...formStatesExtracted,
        [PipelineFormID.EXPORT_NGS_TABLE]: {
          options: {
            ...exportFormStateOptions.tableFormatForm,
            tableOutputType: exportFormStateOptions.tableFormatForm.tableOutputType.toLowerCase(),
          },
        },
      };
    }
  }

  return formStates;
}

type OldExportOptions = DocumentExportOptions | DocumentMetadataExportOptions | NGSExportOptions;

interface DocumentExportOptions {
  annotatorResultOptions: {
    exportResultDocuments: boolean;
  };
  sequencesFormatForm: {
    sequencesOutputType: string;
    mergeGeneiousDocuments?: boolean;
  };
}

interface DocumentMetadataExportOptions {
  tableFormatForm: {
    exportedColumns: string;
    tableOutputType: string;
  };
}

type NGSExportOptions = NGSSequencesExportOptions | NGSTableExportOptions;

interface NGSSequencesExportOptions {
  exportOption: 'Sequences';
  sequencesFormatForm: {
    sequencesOutputType: string;
    exportResultDocuments?: boolean;
    mergeGeneiousDocuments?: boolean;
  };
}

interface NGSTableExportOptions {
  exportOption: 'Table';
  tableFormatForm: {
    exportedColumns: string;
    tableOutputType: string;
    removeWellIDPadding: boolean;
  };
}
