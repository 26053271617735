import { BehaviorSubject, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, filter, finalize, share, switchMap, takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FolderService } from '../folders/folder.service';
import { Folder } from '../folders/models/folder.model';
import { CleanUp } from '../../shared/cleanup';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { AsyncPipe } from '@angular/common';
import { ShowIfDirective } from '../../shared/access-check/directives/show/show-if.directive';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';

@Component({
  selector: 'bx-experiment-info',
  templateUrl: './folder-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    ShowIfDirective,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class FolderInfoComponent extends CleanUp implements OnInit, OnDestroy {
  /** Injected by modal service **/
  folder: Folder;
  /** **/

  title: 'Folder Information';
  readonly metadataForm = new FormGroup({
    targetAntigen: new FormControl<string>(undefined),
    description: new FormControl<string>(undefined),
    notes: new FormControl<string>(undefined),
  });
  submitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateForm$: Observable<Folder | HttpErrorResponse>;
  disableAllPipelines$: Observable<boolean>;

  private readonly onSubmitEvent$: Subject<void>;

  constructor(
    private folderService: FolderService,
    private featureSwitchService: FeatureSwitchService,
    public activeModal: NgbActiveModal,
  ) {
    super();
    this.onSubmitEvent$ = new Subject();
    this.disableAllPipelines$ = this.featureSwitchService.isEnabledOnce('disableAllPipelines');
  }

  ngOnInit() {
    this.metadataForm.disable();
    this.metadataForm.controls.targetAntigen.setValue(this.folder.targetAntigen);
    this.metadataForm.controls.description.setValue(this.folder.description);
    this.metadataForm.controls.notes.setValue(this.folder.notes);
    this.metadataForm.enable();

    // Update the form on the server once any changes are made.
    this.updateForm$ = this.onSubmitEvent$.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(() => this.metadataForm.dirty && this.metadataForm.valid),
      switchMap(() => {
        return this.save(this.metadataForm.value).pipe(catchError((err) => observableOf(err)));
      }),
      share(),
    );

    this.updateForm$
      .pipe(
        filter((response) => !('error' in response)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.metadataForm.markAsPristine();

        this.close();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.submitting$.complete();
    this.onSubmitEvent$.complete();
  }

  onSubmit() {
    this.onSubmitEvent$.next();
  }

  save(value: any): Observable<any> {
    this.folder.targetAntigen = value.targetAntigen;
    this.folder.description = value.description;
    this.folder.notes = value.notes;
    this.submitting$.next(true);

    const metadataPayload = {
      targetAntigen: value.targetAntigen,
      description: value.description,
      notes: value.notes,
    };
    return this.folderService
      .updateMetadata(this.folder, metadataPayload)
      .pipe(finalize(() => this.submitting$.next(false)));
  }

  close() {
    this.activeModal.dismiss('Cross click');
  }
}
