import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ImportOptions } from '@geneious/nucleus-api-client';
import { firstValueFrom, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { StepperComponent } from '../../../../shared/stepper/stepper.component';
import { TableParserService } from '../../../assay-data-v2/table-parser/table-parser.service';
import { CustomJobDialog } from '../../../dialogV2/custom-job-dialog';
import { JobStepperDialog } from '../../../dialogV2/job-stepper-dialog';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';
import { UploadManager } from '../../../upload/upload-manager';
import {
  PIPELINE_DIALOG_DATA,
  PipelineDialogData,
} from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { StepComponent } from '../../../../shared/stepper/step/step.component';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../../shared/card/card.component';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../../shared/form-errors/form-errors.component';
import { AsyncPipe } from '@angular/common';
import { DocumentFileImportConfigurationComponent } from './document-file-import-configuration/document-file-import-configuration.component';
import { TableToSequenceListConfigurationComponent } from './sequence-list-file-import-configuration/table-to-sequence-list-configuration.component';

enum ImportFormat {
  SEQUENCE_LIST = 'asSequenceList',
  TABLE_DOCUMENT = 'asTableDocument',
}

@Component({
  selector: 'bx-table-file-import',
  templateUrl: './table-file-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StepperComponent,
    StepComponent,
    NgbAlert,
    CardComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    DocumentFileImportConfigurationComponent,
    TableToSequenceListConfigurationComponent,
    AsyncPipe,
  ],
})
export class TableFileImportComponent extends JobStepperDialog implements CustomJobDialog, OnInit {
  readonly title: string = 'CSV/Excel File Import';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form: FormGroup<{ formatOption: FormControl<ImportFormat> }>;
  readonly files: File[];
  readonly ImportFormat = ImportFormat;
  selectionValidForSequenceListImport$: Observable<boolean>;
  isTableDocumentFormat$: Observable<boolean>;
  totalTables$: Observable<number>;

  private readonly folderID: string;
  private readonly route: string;
  private readonly importOptions$ = new ReplaySubject<ImportOptions>(1);
  private readonly formDefaults: { formatOption: ImportFormat };

  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;

  constructor(
    private readonly uploadManager: UploadManager,
    @Inject(PIPELINE_DIALOG_DATA)
    private readonly dialogData: PipelineDialogData<{
      files: File[];
      folderID: string;
      route: string;
    }>,
    private readonly tableParserService: TableParserService,
  ) {
    super('document-import', PipelineFormID.DOCUMENT_IMPORT);
    this.files = this.dialogData.otherVariables.files;
    this.folderID = this.dialogData.otherVariables.folderID;
    this.route = this.dialogData.otherVariables.route;

    this.form = new FormGroup({
      formatOption: new FormControl<ImportFormat>(
        { value: ImportFormat.TABLE_DOCUMENT, disabled: false },
        Validators.required,
      ),
    });

    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit(): void {
    this.isTableDocumentFormat$ = currentValueAndChanges(this.form.controls.formatOption).pipe(
      map((format) => format === ImportFormat.TABLE_DOCUMENT),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );

    const parsedTables$ = this.files.map((file) =>
      this.tableParserService.parse(file).pipe(map((tableData) => tableData.tables)),
    );

    this.totalTables$ = forkJoin(parsedTables$).pipe(
      map((tables) => tables.flat(1)),
      map((tables) => tables.length),
    );

    // A selection is only valid for Sequence List import if all CSV files being imported have the same header columns.
    this.selectionValidForSequenceListImport$ = forkJoin(parsedTables$).pipe(
      map((allTables) => allTables.flatMap((tables) => tables.map((table) => table.columns))),
      map((columnArray) => {
        const firstColAsStr = columnArray[0].join();
        return columnArray.every((col) => col.join() === firstColAsStr);
      }),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );

    // Async Validator needs to be set AFTER form creation due to a bug in Angular forms with Async Validators causing
    // form status to be stuck in PENDING.
    // Somehow this has been an issue since 2016: https://github.com/angular/angular/issues/13200
    // Perhaps fixed in Angular 18: https://github.com/angular/angular/issues/41519
    setTimeout(() => {
      this.form.controls.formatOption.addAsyncValidators(async (ctrl) => {
        const sequenceListImportValid = await firstValueFrom(
          this.selectionValidForSequenceListImport$,
        );
        return ctrl.value === ImportFormat.SEQUENCE_LIST && !sequenceListImportValid
          ? {
              batchingError:
                'Error: Multiple tables can only be uploaded as a sequence list if they all contain the same columns. Please adjust your file selection or upload files one by one.',
            }
          : null;
      });
      this.form.controls.formatOption.updateValueAndValidity();
    });
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  onImportOptionsChange(options: ImportOptions) {
    this.importOptions$.next(options);
  }

  runCustomJob() {
    return this.importOptions$.pipe(
      tap((importOptions) =>
        this.uploadManager.upload(
          this.folderID,
          this.route,
          this.files,
          PipelineFormID.DOCUMENT_IMPORT,
          importOptions,
        ),
      ),
    );
  }
}
