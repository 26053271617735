import { HttpEventType, HttpHeaders } from '@angular/common/http';
import { BxError } from './BxError';

/**
 * A replacement for Angulars HttpErrorResponse whose message contains the URL. Analytics can use the error
 * message to group similar errors together but most of our URLs contain a unique id so
 * HttpErrorResponse.message will mostly be unique and wont group as wanted.  This class avoids the
 * problem by excluding URL from the message.
 *
 * This class has the same properties as HttpErrorResponse so could usually be used in its place.
 *
 * For our use cases the call stack is usually uninteresting and actually a distraction in analytics as code
 * obfuscation means what is really the same error looks different to analytics for every build. So
 * unless constructed with keepStack=true, this class will delete its stack property.
 *
 * Typical use for an Observable<Foo> is to add this right after the http call:
 *
 *   .catch((error): Observable<Foo> => { throw new BxHttpError('Http error in SOME_LOCATION', error) })
 *
 * We need to specify Observable<Foo> to retain typing info (it prevents errors like TS2339: Property 'data' does not exist on type '{}')
 * but it can be ignored where later calls dont need the type.
 */
export class BxHttpError extends BxError {
  readonly error: any | null;
  readonly headers: HttpHeaders;
  readonly ok = false;
  readonly status: number;
  readonly statusText: string;
  readonly type: HttpEventType.Response | HttpEventType.ResponseHeader;
  readonly url: string | null;

  constructor(message: string, cause: any, keepStack?: boolean) {
    super(`${message} (${cause.status || 'no status code'})`, {
      cause,
      klass: BxHttpError,
      name: 'BxHttpError',
    });

    if (!keepStack) {
      delete this.stack;
    }

    this.error = cause.error;
    this.headers = cause.headers;
    this.status = cause.status;
    this.statusText = cause.statusText;
    this.type = cause.type;
    this.url = cause.url;
  }
}
