import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetchDocumentTables } from '../../../core/document-table-service/document-table-state/document-table-state.actions';
import { filter, map, switchMap } from 'rxjs/operators';
import { hydrate } from './report-state.actions';
import { ReportStateFlattened } from './report-state';
import { isGraphWidget, isResultTableWidget } from '../report.model';
import { from } from 'rxjs';

@Injectable()
export class ReportStateEffects {
  // TODO Should ReportState know about DocumentTableState?
  // Fetch Document Tables on Report State hydration for widgets that need them.
  fetchDocumentTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hydrate),
      map(({ payload }) => this.getDocumentIDsFromDocumentTableWidgets(payload.reportState)),
      filter((documentIDsSet) => documentIDsSet.size > 0),
      // Only dispatch actions for unique document ids (to avoid duplicate fetch table requests)
      // TODO Dispatching multiple actions in an Effect at once is an anti-pattern. Instead a new action that governs the event should be made.
      switchMap((documentIDsSet) =>
        from(
          [...documentIDsSet].map((documentID) =>
            fetchDocumentTables({ documentID, numberOfSequences: this.numberOfSequences }),
          ),
        ),
      ),
    ),
  );

  // DO NOT MODIFY!
  // Number of sequences is deliberately 0 as this information is not known from the Report State.
  // It's only used for reporting an estimated Restore Document Table time and isn't important in the context of Reports for now.
  private numberOfSequences = 0;

  constructor(private actions$: Actions) {}

  /**
   * Get Document IDs for all widgets that need document tables (ResultTableWidget and GraphWidget).
   */
  private getDocumentIDsFromDocumentTableWidgets(reportState: ReportStateFlattened) {
    return new Set(
      reportState.widgets
        .map((widget) => {
          if (isResultTableWidget(widget) || isGraphWidget(widget)) {
            return widget.data.documentID;
          } else {
            return null;
          }
        })
        .filter((documentID) => documentID != null),
    );
  }
}
