<div [class]="'logo-wrapper' + (centered ? ' text-center' : '')">
  @if (href) {
    <a [routerLink]="href">
      <ng-content *ngTemplateOutlet="logo"></ng-content>
    </a>
  } @else {
    <img
      [src]="path"
      [style.position]="fixedAt ? 'fixed' : 'initial'"
      [style.left]="fixedAt ? fixedAt.x + 'px' : 0"
      [style.top]="fixedAt ? fixedAt.y + 'px' : 0"
      [style.transform]="'scale(' + size + ', ' + size + ')'"
      [style.width]="width ? width : 'auto'"
    />
  }
  <ng-template #logo>
    <img
      [src]="path"
      [style.position]="fixedAt ? 'fixed' : 'initial'"
      [style.left]="fixedAt ? fixedAt.x + 'px' : 0"
      [style.top]="fixedAt ? fixedAt.y + 'px' : 0"
      [style.transform]="'scale(' + size + ', ' + size + ')'"
      [style.width]="width ? width : 'auto'"
    />
  </ng-template>
</div>
