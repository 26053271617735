import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../../../../../nucleus/v2/models/item.v2.model';
import { SelectionState } from '../../../../features/grid/grid.component';
import { DocumentUtils } from '../../../document-utils';

@Pipe({
  name: 'exportingRowsSelectedMessage',
  standalone: true,
})
export class ExportingRowsSelectedMessagePipe implements PipeTransform {
  transform(selected: SelectionState): string {
    const documents: Item[] = selected.selectedRows.filter(
      (document) => !DocumentUtils.isUnsupportedExportDocument(document),
    );
    const numberOfExportableDocuments = documents.length;
    const documentTypes = Array.from(
      new Set(documents.map((document) => document.metadata.documentType)),
    ).join(', ');
    return `Exporting ${numberOfExportableDocuments} ${
      numberOfExportableDocuments > 1 ? 'documents' : 'document'
    } of type ${documentTypes}`;
  }
}
