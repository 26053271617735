import { GetContextMenuItemsParams } from '@ag-grid-community/core';

export function arrayFilter(params: GetContextMenuItemsParams): string {
  // Note for assay data it's important to use "colId" (not "field" or "headerName") because that includes any prefixes.
  const empty: any[] = [null];
  const labelsValue = params.value ? params.value : [];
  const labels = labelsValue.map((label: string) => `'${label}'`);
  return (labels.length ? labels : empty)
    .map((wrappedLabel: string) => `['Labels'] = ${wrappedLabel}`)
    .join(' AND ');
}
