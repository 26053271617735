<bx-settings-breadcrumb [crumbs]="crumbs"></bx-settings-breadcrumb>

<div class="bx-page-container bx-page-container-sm">
  <form class="centered" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form">
      <div class="mb-3">
        <label class="form-label" for="name">Name</label>
        <input
          id="name"
          type="text"
          name="groupName"
          class="form-control"
          placeholder="Group Name"
          formControlName="groupName"
          ngFormControlValidator
          [validateOnBlur]="true"
          [enableIsValidClass]="false"
        />
        @if (this.form.controls.groupName.hasError('required')) {
          <div class="invalid-feedback">Name is required</div>
        }
      </div>

      <div class="mb-3">
        <label class="form-label" for="description">Description</label>
        <input
          id="description"
          type="text"
          name="description"
          class="form-control"
          placeholder="e.g. Admin Group"
          formControlName="description"
          ngFormControlValidator
          [validateOnBlur]="true"
        />
      </div>

      <mat-form-field floatLabel="never" class="w-100">
        <input
          matInput
          placeholder="Enter name to add user..."
          [matAutocomplete]="auto"
          [formControl]="searchInput"
          (click)="autocompleteInputClicked($event)"
        />

        <mat-autocomplete
          #auto="matAutocomplete"
          [autoActiveFirstOption]="true"
          [displayWith]="displayFn"
          (optionSelected)="onUserSelected($event)"
        >
          @for (user of filteredUsers$ | async; track user) {
            <mat-option [value]="user">
              <span>{{ user.fullName }} </span>
              <span class="text-muted"> ({{ user.email }})</span>
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>

    @if ((selectedMembers$ | async)?.length > 0) {
      <h5>Members of group</h5>
    }

    <mat-list>
      @for (user of selectedMembers$ | async; track user) {
        <mat-list-item>
          <h4 mat-line>{{ user.givenName }} {{ user.familyName }}</h4>
          <p mat-line class="text-muted">{{ user.email }}</p>
          <div class="action">
            <button type="button" mat-icon-button (click)="removeUser(user)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
      }
    </mat-list>

    @if (errors$ | async; as errors) {
      <mat-error>
        <span> {{ errors }} </span><br />
      </mat-error>
    }

    <div class="d-flex justify-content-end">
      <button
        class="btn btn-primary me-2"
        bx-spinner-button
        [disabled]="(submitting$ | async) || form.invalid"
        [spin]="submitting$ | async"
      >
        {{ isCreating ? 'Create Group' : 'Save Changes' }}
      </button>
      <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
