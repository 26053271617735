@if (error$ | async; as error) {
  <bx-page-message>
    {{ error }}
  </bx-page-message>
}
@if (data$ | async; as data) {
  <bx-stacked-column-chart
    [data]="data.data"
    [title]="data.title"
    xAxisTitle="Gene Family"
    yAxisTitle="Frequency"
    stacking="normal"
    animations="true"
    [showLegend]="showLegend$ | async"
    showDataLabels="false"
  >
  </bx-stacked-column-chart>
}
