import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavConfig,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap,
} from 'rxjs';
import { filter, map, pairwise, shareReplay, toArray } from 'rxjs/operators';
import { ViewerDataService } from '../viewer-data/viewer-data.service';
import { ViewersState } from '../viewers-state/viewers-state';
import { ViewerComponent, ViewersOverlays } from '../viewers-v2.config';
import { ViewersService } from './viewers.service';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { NgComponentOutlet, AsyncPipe } from '@angular/common';
import { OverlaysComponent } from '../../../shared/overlays/overlays.component';

@Component({
  selector: 'bx-viewers',
  templateUrl: './viewers.component.html',
  styleUrls: ['./viewers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewerDataService],
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgComponentOutlet,
    OverlaysComponent,
    NgbNavOutlet,
    AsyncPipe,
  ],
})
export class ViewersComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(NgbNav) viewersNav: NgbNav;
  @Input() data: any | undefined | null;
  @Input() key = 'default';
  @Input() overlays: ViewersOverlays;

  activeTabID: string;
  displayedViewerComponents$: Observable<ViewerComponentMetadata[]>;
  overlay$: Observable<string | null>;
  viewersCached$: BehaviorSubject<{ [type: string]: boolean }>;

  private readonly data$: ReplaySubject<any>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private ngbNavConfig: NgbNavConfig,
    private viewersService: ViewersService,
    private viewerDataService: ViewerDataService,
    private viewersState: ViewersState,
    private featureSwitchService: FeatureSwitchService,
  ) {
    this.viewersCached$ = new BehaviorSubject({});
    this.data$ = new ReplaySubject(1);

    // Animations add a weird delay and cause a "ResizeObserver loop limit exceeded" error in Cypress
    this.ngbNavConfig.animation = false;
  }

  ngOnChanges({ data }: SimpleChanges) {
    if (data) {
      this.data$.next(data.currentValue);
      this.viewerDataService.setData(data.currentValue);
    }
  }

  ngOnInit(): void {
    this.displayedViewerComponents$ = this.data$.asObservable().pipe(
      switchMap((data) =>
        this.selectViewerComponents(
          data,
          this.viewersService.getViewers(data?.isPreviewView, data?.containsComplexDocument),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.subscriptions.add(
      // Unsubscribes viewers from the ViewerDocumentData stream if they are not going to be rendered in the next list of viewers.
      this.displayedViewerComponents$.pipe(pairwise()).subscribe(([previous, current]) => {
        for (const viewer of previous) {
          const viewerDoesNotExist = !current.find((currViewer) => currViewer.key === viewer.key);
          if (viewerDoesNotExist) {
            this.viewerDataService.killStream(viewer.key);
          }
        }
      }),
    );

    this.subscriptions.add(
      // Sets the last used Viewer tab as cached, so it doesn't re-render when visited.
      this.displayedViewerComponents$.subscribe((viewers) => {
        this.viewersCached$.next({});
        const lastActiveTab = this.viewersState.getSelectedTab(this.key);
        const activeViewer = viewers.find((viewer) => viewer.key === lastActiveTab);

        if (activeViewer) {
          this.setViewerAsCached(activeViewer.key);
        } else if (viewers.length > 0) {
          this.setViewerAsCached(viewers[0].key);
        }
      }),
    );

    this.subscriptions.add(
      // Handle selecting the last remembered tab or the first tab by default.
      this.displayedViewerComponents$.subscribe((viewers) => {
        const tabID = this.viewersState.getSelectedTab(this.key);
        if (tabID && viewers.find((viewer) => viewer.key === tabID)) {
          this.activeTabID = tabID;
        } else if (viewers.length > 0) {
          this.activeTabID = viewers[0].key;
        }
        // TODO: Investigate why manual tab selection here is required.
        // We have to manually select the tab here, otherwise
        // the first time we change the document type, the new tab is not selected.
        if (this.activeTabID) {
          this.viewersNav?.select(this.activeTabID);
        }
      }),
    );
    this.overlay$ = combineLatest([
      this.data$.asObservable(),
      this.displayedViewerComponents$,
    ]).pipe(
      map(([data, displayedViewerComponents]) => {
        return typeof this.overlays === 'function'
          ? this.overlays(data, displayedViewerComponents.length)
          : null;
      }),
    );
  }

  ngOnDestroy() {
    this.data$.complete();
    this.viewersCached$.complete();
    this.subscriptions.unsubscribe();
  }

  onTabChange(event: NgbNavChangeEvent) {
    this.setViewerAsCached(event.nextId);
    this.viewersState.onTabChanged(this.key, event.nextId);
  }

  trackByFn(index: number, item: ViewerComponentMetadata): string {
    return item.key;
  }

  private setViewerAsCached(viewerID: string): void {
    const viewersCached = { ...this.viewersCached$.getValue() };
    viewersCached[viewerID] = true;
    this.viewersCached$.next(viewersCached);
  }

  private selectViewerComponents(
    data: any | undefined | null,
    viewerComponents: ViewerComponent[],
  ): Observable<ViewerComponentMetadata[]> {
    // If no data, then assume nothing to show.
    // If a viewer needs to show with when there is no data, then this should needs to be removed.

    if (data == null) {
      return of([]);
    }

    return from(viewerComponents).pipe(
      switchMap((viewer) => {
        let valid = false;
        if ('selector' in viewer.viewerComponentMetadata) {
          valid = viewer.viewerComponentMetadata.selector(data);
        }

        if ('selectors' in viewer.viewerComponentMetadata) {
          valid = viewer.viewerComponentMetadata.selectors.some((selector) => selector(data));
        }

        if ('featureSwitch' in viewer.viewerComponentMetadata) {
          return this.featureSwitchService
            .isEnabledOnce(viewer.viewerComponentMetadata.featureSwitch)
            .pipe(map((enabled) => (enabled && valid ? viewer : null)));
        }
        return of(valid ? viewer : null);
      }),
      filter((viewer) => !!viewer),
      map(this.convertToViewerComponentMetadata(data)),
      toArray(),
    );
  }

  private convertToViewerComponentMetadata(data: unknown) {
    return function (viewer: ViewerComponent): ViewerComponentMetadata {
      return {
        component: viewer,
        ...viewer.viewerComponentMetadata,
        title:
          typeof viewer.viewerComponentMetadata.title === 'function'
            ? viewer.viewerComponentMetadata.title(data)
            : viewer.viewerComponentMetadata.title,
      };
    };
  }
}
interface ViewerComponentMetadata {
  component: any;
  key: string;
  title: string;
}
