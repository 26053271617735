<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'uploads'], label: 'Uploads' }]"
></bx-settings-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="cancelSelected()"
      [disabled]="cancelButtonDisabled$ | async"
    >
      Cancel
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-client-grid
  [rowData]="fileUploads$ | async"
  [gridOptions]="gridOptions"
  [columnDefs]="colDefs"
  (selectionChanged)="onSelectionChanged($event)"
  class="flex-grow-1 flex-shrink-1"
></bx-client-grid>
