import { JobConfiguration } from '@geneious/nucleus-api-client';
import { OutputOptions, SelectionOptionsV1 } from './jobParameters.model';

export class NGSComparisonsJobParametersV1 implements JobConfiguration {
  pipeline = {
    name: 'ngs-comparisons',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: NGSComparisonsJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(
    version: number,
    parameters: {
      selection: SelectionOptionsV1;
      options: NGSComparisonsJobOptionsV1;
      output?: OutputOptions;
    },
  ) {
    this.pipeline.version = version;
    this.parameters = parameters;
  }
}

export interface NGSComparisonsJobOptionsV1 {
  optionValues: any; // map of all option names to values
}
