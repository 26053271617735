import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'bx-accordion-step',
  templateUrl: './accordion-step.component.html',
  styleUrls: ['./accordion-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, MatLegacyButtonModule],
})
export class AccordionStepComponent {
  // Name of this step, shown in the UI.
  @Input() title = '';
  // Array of errors to show. Can't move to next step while there is an error.
  @Input() errors: any[] = [];
  // If this step is expanded.
  @Input() expanded = false;
  // Disable the continue & header buttons.
  @Input() disabled: boolean;
  // If the buttons should be displayed
  @Input() showButtons = true;

  // Called directly before step is completed. Use to perform any validation logic.
  @Output() beforeDone = new EventEmitter<void>();
  // Emitted when the current clicks "Continue" and this step is completed.
  @Output() done = new EventEmitter();
  // Emitted when this step is selected$ (header click).
  @Output() headerClick = new EventEmitter();

  constructor() {}

  /**
   * Called when the current attempts to move to the next step.
   */
  stepDone() {
    this.beforeDone.emit();
    // Let angular push changes to from the parent component
    // into "this.errors" before validating.
    setTimeout(() => {
      if (this.valid) {
        this.done.emit();
      }
    });
  }

  /**
   * @returns {boolean} If this step is valid. Prevents current moving to next
   * step if this one's not done.
   */
  get valid() {
    return this.errors.length === 0;
  }
}
