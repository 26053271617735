import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';

import { OpenDocumentButtonComponent } from '../../../shared/open-document-button/open-document-button.component';

@Component({
  selector: 'bx-ngs-sequence-preview-info-box',
  templateUrl: './ngs-sequence-preview-info-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OpenDocumentButtonComponent],
})
export class NgsSequencePreviewInfoBoxComponent {
  @Input() totalNumberOfSequences: number;
  @Input() maximumNumberOfSequences: number;
  @Input() openQueryParams: ViewerPageURLSelectionState;

  constructor() {}
}
