@if (loginErrorState$ | async; as loginErrorState) {
  <div>
    <form #loginForm="ngForm" (submit)="login($event, loginForm)">
      @if ((mfaRequired$ | async) === false) {
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            #emailInput="ngModel"
            [(ngModel)]="loginModel.email"
            type="email"
            name="email"
            placeholder="Email"
            [disabled]="loginErrorState.loggingIn"
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
            required
          />
          @if (emailInput.hasError('required')) {
            <span class="invalid-feedback text-start">Email is required</span>
          }
        </div>
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            #passwordInput="ngModel"
            [(ngModel)]="loginModel.password"
            type="password"
            name="password"
            placeholder="Password"
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
            required
          />
          @if (passwordInput.hasError('required')) {
            <span class="invalid-feedback text-start">Password is required</span>
          }
        </div>
      } @else {
        <div class="mb-3">
          <input
            class="form-control form-control-lg"
            #mfaInput="ngModel"
            [(ngModel)]="loginModel.mfaCode"
            type="number"
            name="mfaCode"
            placeholder="Enter Code"
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            [enableIsValidClass]="false"
          />
          @if (mfaInput.hasError('required')) {
            <span class="invalid-feedback text-start">Verification code is required</span>
          }
        </div>
      }
      <button
        id="login-button"
        type="submit"
        class="btn btn-lg btn-primary w-100"
        [disabled]="loginErrorState.loggingIn"
        bx-spinner-button
        [spin]="loginErrorState.loggingIn"
      >
        {{ (mfaRequired$ | async) ? 'Verify' : ' Sign In' }}
      </button>
      @if (loginErrorState.error) {
        <div class="text-danger text-center mt-3">
          {{ loginErrorState.error }}
        </div>
      }
    </form>
    @if ((mfaRequired$ | async) === false) {
      <div class="d-flex justify-content-center mt-3">
        <p><a [routerLink]="'/reset-password/request'">Forgot password?</a></p>
        <p class="mx-2 text-muted">•</p>
        <p><a [routerLink]="'/sign-up'">Sign up for an account</a></p>
      </div>
    } @else {
      <div class="d-flex justify-content-center mt-3">
        <p><a [routerLink]="'#'" (click)="backToLogin()">Return to Login</a></p>
        <p class="mx-2 text-muted">•</p>
        <p>
          <a
            target="_blank"
            href="https://help.geneiousbiologics.com/hc/en-us/articles/25275064966420-Multi-factor-authentication#h_01HTXP677EFSTQK2AC902VS1T6"
            >Forgot code?</a
          >
        </p>
      </div>
    }
  </div>
}
