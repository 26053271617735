import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  RemoveUMIDuplicatesJobOptionsV1,
  RemoveUMIDuplicatesJobParametersV1,
} from '../../../../nucleus/services/models/removeUMIDuplicatesOptions.model';
import { delay, takeUntil } from 'rxjs/operators';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../shared/card/card.component';
import { FadeFormElementDirective } from '../../../shared/fade-form-element.directive';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';

import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-remove-umi-duplicates',
  templateUrl: './remove-umi-duplicates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ShowIfDirective,
    NgbTooltip,
    CardComponent,
    FadeFormElementDirective,
    NgFormControlValidatorDirective,
    PipelineOutputComponent,
  ],
})
export class RemoveUmiDuplicatesComponent extends JobDialogContent implements RunnableJobDialog {
  earlyRelease: false;

  form: BxFormGroup;
  title = 'Collapse UMI Duplicates';
  knowledgeBaseArticle: string;
  isLegacyUMI = true;

  readonly startEndOptions = ["5' end", "3' end", 'Both ends'];
  private readonly formDefaults: any;
  private readonly selected: SelectionState;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    featureSwitch: FeatureSwitchService,
  ) {
    super('remove-umi-duplicates', PipelineFormID.REMOVE_UMI_DUPLICATES);
    this.selected = this.dialogData.selected;

    this.form = new BxFormGroup({
      hasAdapater: new BxFormControl(false),
      adapterLength: new BxFormControl(4, [Validators.min(0), Validators.max(9999)]),
      useStart: new BxFormControl(true),
      startLength: new BxFormControl(14, [Validators.min(0), Validators.max(9999)]),
      separateByBarcode: new BxFormControl(false),
      barcodes_barcodeType: new BxFormControl('barcodeByLength'),
      barcodes_barcodeLength: new BxFormControl(16, [Validators.min(0), Validators.max(9999)]),
      barcodes_minimumSequencesPerBarcode: new BxFormControl(100, [
        Validators.min(1),
        Validators.max(1000000),
      ]),
      barcodes_minimumSequencesPerBarcodeDominantPercentage: new BxFormControl(5, [
        Validators.min(0),
        Validators.max(100),
      ]),
      barcodes_onlyKeepNamedBarcodes: new BxFormControl(false),
      barcodes_barcodeNames: new BxFormControl(''),
      barcodes_maximumBarcodesToSave: new FormControl(2_000_000_000),
      hasTSO: new BxFormControl(false),
      TSO: new BxFormControl('TTTCTTATATGGG'),
      startEndProperties: new BxFormControl(this.startEndOptions[0]),
      allowUMIMismatch: new BxFormControl(true),
      trimBarcodeUmiTso: new BxFormControl(false),
      discardShortSequences: new BxFormControl(true),
      discardSequencesShorterThan: new BxFormControl(300, [
        Validators.min(1),
        Validators.max(9999999),
      ]),
      discardLowQualitySequences: new BxFormControl(true),
      discardLowQualitySequencesPercent: new BxFormControl(50, [
        Validators.min(0),
        Validators.max(100),
      ]),
      dontClusterPercent: new BxFormControl(97, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      outputFolderName: JobDialogContent.getResultNameControl(),
    });

    featureSwitch.isEnabledOnce('barcodeSeparation').subscribe((showSeparate) => {
      if (showSeparate) {
        this.title = 'Collapse UMI Duplicates & Separate Barcodes';
        this.isLegacyUMI = !showSeparate;
      }
    });

    this.formDefaults = this.form.getRawValue();

    this.disableEnableOptions();
  }

  run() {
    const { startEndProperties, outputFolderName, ...formValue } = this.form.value;

    const options: RemoveUMIDuplicatesJobOptionsV1 = {
      optionValues: {
        hasStartProperties: startEndProperties !== "3' end",
        hasEndProperties: startEndProperties !== "5' end",
        ...formValue,
      },
    };

    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    return new RemoveUMIDuplicatesJobParametersV1(parameters);
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  /**
   * Should only be called once!
   */
  private disableEnableOptions() {
    const adapterControl = this.form.get('hasAdapater');
    const adapterLengthControl = this.form.get('adapterLength');

    const barcodeControl = this.form.get('separateByBarcode');
    const barcodeTypeControl = this.form.get('barcodes_barcodeType');
    const barcodeLengthControl = this.form.get('barcodes_barcodeLength');
    const barcodeNamesControl = this.form.get('barcodes_barcodeNames');
    const onlyKeepNamedBarcodesControl = this.form.get('barcodes_onlyKeepNamedBarcodes');
    const minimumSequencesPerBarcodeControl = this.form.get('barcodes_minimumSequencesPerBarcode');
    const minimumSequencesPerBarcodeDominantPercentageControl = this.form.get(
      'barcodes_minimumSequencesPerBarcodeDominantPercentage',
    );

    const useStartControl = this.form.get('useStart');
    const startLengthControl = this.form.get('startLength');

    const hasTSOControl = this.form.get('hasTSO');
    const tsoControl = this.form.get('TSO');

    const discardShortSequencesControl = this.form.get('discardShortSequences');
    const discardSequencesShorterThanControl = this.form.get('discardSequencesShorterThan');

    const discardLowQualitySequencesControl = this.form.get('discardLowQualitySequences');
    const discardLowQualitySequencesPercentControl = this.form.get(
      'discardLowQualitySequencesPercent',
    );

    adapterControl.valueChanges
      .pipe(
        // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
        // to apply the disable/enable logic here after that.
        delay(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((enabled) =>
        enabled ? adapterLengthControl.enable() : adapterLengthControl.disable(),
      );

    barcodeControl.valueChanges
      .pipe(
        // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
        // to apply the disable/enable logic here after that.
        delay(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((enabled) => {
        if (enabled) {
          barcodeTypeControl.enable();
          barcodeLengthControl.enable();
          barcodeNamesControl.enable();
          onlyKeepNamedBarcodesControl.enable();
          minimumSequencesPerBarcodeControl.enable();
          minimumSequencesPerBarcodeDominantPercentageControl.enable();
        } else {
          barcodeTypeControl.disable();
          barcodeLengthControl.disable();
          barcodeNamesControl.disable();
          onlyKeepNamedBarcodesControl.disable();
          minimumSequencesPerBarcodeControl.disable();
          minimumSequencesPerBarcodeDominantPercentageControl.disable();
        }
      });

    useStartControl.valueChanges
      .pipe(
        // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
        // to apply the disable/enable logic here after that.
        delay(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((enabled) =>
        enabled ? startLengthControl.enable() : startLengthControl.disable(),
      );

    hasTSOControl.valueChanges
      .pipe(
        // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
        // to apply the disable/enable logic here after that.
        delay(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((enabled) => (enabled ? tsoControl.enable() : tsoControl.disable()));

    barcodeTypeControl.valueChanges
      .pipe(delay(0), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (barcodeControl.value === true) {
          if (value === 'barcodeByLength') {
            barcodeLengthControl.enable();
            barcodeNamesControl.disable();
            onlyKeepNamedBarcodesControl.disable();
          } else if (value === 'barcodeByName') {
            barcodeLengthControl.disable();
            barcodeNamesControl.enable();
            onlyKeepNamedBarcodesControl.enable();
          }
        }
      });

    discardShortSequencesControl.valueChanges
      .pipe(delay(0), takeUntil(this.ngUnsubscribe))
      .subscribe((enabled) =>
        enabled
          ? discardSequencesShorterThanControl.enable()
          : discardSequencesShorterThanControl.disable(),
      );

    discardLowQualitySequencesControl.valueChanges
      .pipe(delay(0), takeUntil(this.ngUnsubscribe))
      .subscribe((enabled) =>
        enabled
          ? discardLowQualitySequencesPercentControl.enable()
          : discardLowQualitySequencesPercentControl.disable(),
      );
  }
}
