import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../shared/cleanup';
import { TemporaryPopupComponent } from '../../shared/temporary-popup/temporary-popup.component';
import { AppState } from '../core.store';
import { UploadsFolder } from '../folders/models/folder.model';
import { folderIsSelected } from '../folders/store/folder.selectors';
import { UploadsTableStoreService } from '../upload/uploads-table/uploads-table.store-service';
import { FolderTreeLinkComponent } from '../folder-tree-link/folder-tree-link.component';
import { RouterLink } from '@angular/router';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
  selector: 'bx-activity-tree',
  templateUrl: './activity-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FolderTreeLinkComponent,
    RouterLink,
    NgClass,
    MatIconModule,
    SpinnerComponent,
    TemporaryPopupComponent,
    AsyncPipe,
  ],
})
export class ActivityTreeComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  @ViewChild(TemporaryPopupComponent) popupComponent: TemporaryPopupComponent;

  status$: Observable<string>;
  selected$: Observable<boolean>;
  folder: UploadsFolder;
  numberOfUploads = 0;

  constructor(
    private uploadsTableStoreService: UploadsTableStoreService,
    private store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit() {
    this.folder = new UploadsFolder();
    this.selected$ = this.store
      .select(folderIsSelected(this.folder.id))
      .pipe(takeUntil(this.ngUnsubscribe));
    this.status$ = this.uploadsTableStoreService.status$.pipe(takeUntil(this.ngUnsubscribe));
    this.uploadsTableStoreService.fileUploadStore$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ status, ids }) => {
        if (status === 'uploading' && ids.length > this.numberOfUploads) {
          this.popupComponent.triggerPopup();
        }
        this.numberOfUploads = ids.length;
      });
  }
}
