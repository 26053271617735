import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-score-screen',
  templateUrl: './score-screen.component.html',
  standalone: true,
})
export class ScoreScreenComponent implements OnInit {
  @Input() sequence: string;

  score: number;
  translation = '';

  translationTable: any = {
    A: ['GCU', 'GCC', 'GCA', 'GCG'],
    G: ['GUU', 'GGC', 'GGA', 'GGG'],
    I: ['AUU', 'AUC', 'AUA'],
    L: ['CUU', 'CUC', 'CUA', 'CUG', 'UUA', 'UUG'],
    P: ['CCU', 'CCC', 'CCA', 'CCG'],
    V: ['GUU', 'GUC', 'GUA', 'GUG'],
    F: ['UUU', 'UUC'],
    W: ['UGG'],
    Y: ['UAU', 'UAC'],
    D: ['GAU', 'GAC'],
    E: ['GAA', 'GAG'],
    R: ['CGU', 'CGC', 'CGA', 'CGG', 'AGA', 'AGG'],
    H: ['CAU', 'CAC'],
    K: ['AAA', 'AAG'],
    S: ['UCU', 'UCC', 'UCA', 'UCG', 'AGU', 'AGC'],
    T: ['ACU', 'ACC', 'ACA', 'ACG'],
    C: ['UGU', 'UGC'],
    M: ['AUG'],
    N: ['AAU', 'AAC'],
    Q: ['CAA', 'CAG'],
    '*': ['UAA', 'UAG', 'UGA'],
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    for (let i = 0; i < this.sequence.length - 2; i += 3) {
      const inputCodon = this.sequence.slice(i, i + 3);
      Object.keys(this.translationTable).some((key) => {
        return this.translationTable[key].some((codon: any) => {
          if (codon === inputCodon) {
            this.translation += key;
            return true;
          }
        });
      });
    }
  }
}
