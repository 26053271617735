import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from '../../core/navigation/navigation.service';
import { AccessCondition } from '../../shared/access-check/access-check-condition.model';
import { AccessCheckService } from '../../shared/access-check/access-check.service';

/**
 * Protect unreleased/premium-only pages by checking if the user has access.
 * Redirects the user back to the workspace page otherwise.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessCheckGuard {
  constructor(
    private readonly accessCheckService: AccessCheckService,
    private readonly navigationService: NavigationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<true | UrlTree> {
    const condition = route.data?.accessCondition as AccessCondition;
    if (!condition) {
      return of(true);
    }
    return this.accessCheckService
      .hasAccessOnce(condition)
      .pipe(
        switchMap((hasAccess) =>
          hasAccess ? of(hasAccess) : this.navigationService.getHomeUrlTree(),
        ),
      );
  }
}
