@if (data$ | async; as data) {
  <bx-grouped-column-chart
    [title]="data.title"
    [subtitle]="data.subtitle"
    [xAxisTitle]="data.xAxisTitle"
    [yAxisTitle]="'Frequency %'"
    [showLegend]="true"
    [data]="data.data"
    [highlightOnlyHoveredColumn]="true"
  ></bx-grouped-column-chart>
}
