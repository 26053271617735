import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColDef, GridOptions } from '@ag-grid-community/core';
import { UploadsTableStoreService } from './uploads-table.store-service';
import { uploadsTableColDefs } from './uploads-table.coldefs';
import {
  ClientGridComponent,
  ClientGridSelection,
} from '../../../features/grid/client-grid/client-grid.component';
import { UploadManager } from '../upload-manager';
import { CleanUp } from '../../../shared/cleanup';
import { getRowId, getRowIdentifier } from '../../ngs/getRowIdentifier';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FileUploadGroup, UploadState } from '../../models/file-upload.model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-uploads',
  templateUrl: './uploads-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    ClientGridComponent,
    AsyncPipe,
  ],
})
export class UploadsTableComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @ViewChild(ClientGridComponent, { static: true }) uploadsGrid: ClientGridComponent;

  fileUploads$: Observable<FileUploadGroup[]>;

  readonly colDefs: ColDef[];
  readonly selectionState$: BehaviorSubject<FileUploadGroup[]>;
  readonly cancelButtonDisabled$: Observable<boolean>;
  readonly gridOptions: GridOptions = { getRowId };

  private cancelSelectedEvent$: Subject<void>;

  constructor(
    private uploadsTableStoreService: UploadsTableStoreService,
    private uploadManager: UploadManager,
  ) {
    super();

    this.colDefs = uploadsTableColDefs;
    this.selectionState$ = new BehaviorSubject<FileUploadGroup[]>([]);
    this.fileUploads$ = this.uploadsTableStoreService.list;
    this.cancelButtonDisabled$ = combineLatest([this.fileUploads$, this.selectionState$]).pipe(
      map(([uploadGroups, selectedUploadGroups]) =>
        this.shouldCancelButtonDisabled(uploadGroups, selectedUploadGroups),
      ),
    );

    this.cancelSelectedEvent$ = new Subject<void>();

    this.cancelSelectedEvent$
      .pipe(
        withLatestFrom(this.selectionState$.asObservable()),
        map(([event, selectedUploads]) => selectedUploads),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((selectedUploads) => {
        selectedUploads.forEach((row) => {
          // Note that this call is not specific to activity and should be removed; it's a pragmatic hack.
          this.uploadManager.cancel(getRowIdentifier(row));
        });
      });
  }

  ngOnInit() {
    this.uploadsTableStoreService.loadFileUpload();
    this.uploadsTableStoreService.status$.pipe(take(1)).subscribe((status) => {
      if (status === 'complete' || status === 'failed') {
        // Reset the status when the user navigates to the uploads view if the
        // status is complete or failed because they have "seen" the uploads now.
        this.uploadsTableStoreService.resetStatus();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onSelectionChanged(selectionState: ClientGridSelection<FileUploadGroup>) {
    this.selectionState$.next(selectionState.rows);
  }

  shouldCancelButtonDisabled(
    uploadGroups: FileUploadGroup[],
    selectedUploadGroups: FileUploadGroup[],
  ) {
    if (selectedUploadGroups.length === 0) {
      return true;
    }
    const selectedUploadGroupIDs = selectedUploadGroups.map((group) => group.id);
    return (
      uploadGroups.filter(
        (group) =>
          selectedUploadGroupIDs.includes(group.id) &&
          ![UploadState.PREPARING, UploadState.QUEUED, UploadState.UPLOADING].includes(
            group.status.kind,
          ),
      ).length > 0
    );
  }

  cancelSelected() {
    this.cancelSelectedEvent$.next();
  }
}
