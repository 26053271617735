import { ExtractionSelectionV2 } from './extractSequencesV3.model';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class RepairSequencesParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'repair-sequences',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: RepairSequencesJobOptions;
    output?: OutputOptions;
  };

  constructor(
    version: number,
    parameters: {
      selection: SelectionOptionsV1;
      options: RepairSequencesJobOptions;
      output?: OutputOptions;
    },
  ) {
    this.pipeline.version = version;
    this.parameters = parameters;
  }
}

export interface RepairSequencesJobOptions {
  selection: ExtractionSelectionV2;
  repairRegions: object;
  resultName: string;
}
