import { FileUploadGroup, UploadState } from '../../models/file-upload.model';
import { EntityAdapter, EntityState } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import {
  UploadActions,
  addFileUpload,
  cancelUpload,
  initFileUploadTable,
  resetFileUploadStatus,
  updateFileUpload,
  updateFileUploadByFileID,
} from './uploads.actions';

export type UploadStatus =
  | 'uploading'
  | 'importing'
  | 'failed'
  | 'complete'
  | 'completeWithErrors'
  | 'idle';

export interface FileUploadStore extends EntityState<FileUploadGroup> {
  uploadProgress: { [uploadGroupID: string]: number };
  currentUploadGroupIDs: string[];

  /**
   * Keep updating the state until the user clicks on Uploads and views it
   * When they click reset the counter.
   *
   * - If any are uploading then show uploading spinner.
   * - When uploads are done, if any failed show failed icon.
   * - Otherwise a complete icon.
   *
   * - Clear the icon completely once we navigate to the view (when the widget is idle).
   *
   */
  status: UploadStatus;
}
export const adapter: EntityAdapter<FileUploadGroup> = createEntityAdapter<FileUploadGroup>({
  sortComparer: (a: FileUploadGroup, b: FileUploadGroup) => {
    return new Date(a.startedAt) > new Date(b.startedAt) ? -1 : 1;
  },
});
const initialState: FileUploadStore = {
  uploadProgress: {},
  currentUploadGroupIDs: [],
  status: 'idle',
  ...adapter.getInitialState(),
};

export function uploadsTableReducer(
  state: FileUploadStore = initialState,
  action: UploadActions,
): FileUploadStore {
  switch (action.type) {
    case addFileUpload.type: {
      const currentUploadGroups = isUploadRunning(state.status)
        ? [action.payload.uploadGroup.id].concat(state.currentUploadGroupIDs)
        : [action.payload.uploadGroup.id];
      return {
        ...adapter.addOne(action.payload.uploadGroup, state),
        currentUploadGroupIDs: currentUploadGroups,
        status: 'uploading',
      };
    }

    case initFileUploadTable.type: {
      const updatedUploadGroups = action.payload.uploadGroups
        .filter(
          (group) =>
            !state.currentUploadGroupIDs.includes(group.id) ||
            group.status.kind === UploadState.COMPLETED,
        )
        .map((group: FileUploadGroup) => {
          const status = group.status.kind;
          if (status === UploadState.UPLOADING) {
            group.status.kind = UploadState.FAILED;
          }
          if (status === UploadState.IMPORTING) {
            group.status.progress = 100;
          }
          return group;
        });
      const updatedStore = adapter.upsertMany(updatedUploadGroups, state);
      const uploadGroups = Object.values(updatedStore.entities);
      const currentUploadGroupIDs = uploadGroups
        .filter((group) =>
          [
            UploadState.IMPORTING,
            UploadState.UPLOADING,
            UploadState.PREPARING,
            UploadState.QUEUED,
          ].includes(group.status.kind),
        )
        .map((group) => group.id);
      return {
        ...updatedStore,
        currentUploadGroupIDs,
        status: calculateStatus(uploadGroups, currentUploadGroupIDs),
      };
    }

    case updateFileUpload.type: {
      let updatedStore: FileUploadStore;
      let updatedCurrentUploadGroupIDs: string[];
      if (action.payload.oldID) {
        updatedStore = adapter.removeOne(
          action.payload.oldID,
          adapter.upsertOne(action.payload.uploadGroup, state),
        );
        updatedCurrentUploadGroupIDs = updatedStore.currentUploadGroupIDs
          .filter((id) => id !== action.payload.oldID)
          .concat(action.payload.uploadGroup.id);
      } else {
        updatedStore = adapter.upsertOne(action.payload.uploadGroup, state);
        updatedCurrentUploadGroupIDs = updatedStore.currentUploadGroupIDs;
      }
      const uploadGroups = Object.values(updatedStore.entities);
      return {
        ...updatedStore,
        currentUploadGroupIDs: updatedCurrentUploadGroupIDs,
        status: calculateStatus(uploadGroups, updatedCurrentUploadGroupIDs),
      };
    }

    case cancelUpload.type: {
      const updatedStore = adapter.updateOne(
        { id: action.payload.uploadGroup.id, changes: { ...action.payload.uploadGroup } },
        state,
      );
      const uploadGroups = Object.values(updatedStore.entities);
      return {
        ...updatedStore,
        status: calculateStatus(uploadGroups, updatedStore.currentUploadGroupIDs),
      };
    }

    case resetFileUploadStatus.type: {
      return {
        ...state,
        status: 'idle',
        currentUploadGroupIDs: [],
      };
    }

    case updateFileUploadByFileID.type: {
      if (
        [UploadState.COMPLETED, UploadState.COMPLETED_WITH_ERRORS, UploadState.FAILED].includes(
          action.payload.status.kind,
        )
      ) {
        const uploadGroups = Object.values(state.entities);
        const uploadGroup = uploadGroups.find((group) =>
          group.fileIDs.includes(action.payload.fileID),
        );
        if (uploadGroup) {
          const totalFinished = (state.uploadProgress[uploadGroup.id] || 0) + 1;
          return {
            ...state,
            uploadProgress: {
              ...state.uploadProgress,
              [uploadGroup.id]: totalFinished,
            },
          };
        } else {
          return state;
        }
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

type Status = 'isUploading' | 'isImporting' | 'isFailed' | 'isComplete' | 'isCompleteWithErrors';
function calculateStatus(
  uploadGroups: FileUploadGroup[],
  currentUploadGroupIDs: string[],
): UploadStatus {
  // Need to be uploads with methods, not just JSON.
  uploadGroups = uploadGroups.map((a) => FileUploadGroup.fromJson(a));
  const currentUploadGroups = uploadGroups.filter((group) =>
    currentUploadGroupIDs.includes(group.id),
  );
  const getLength = (key: Status) => currentUploadGroups.filter((a) => a[key]()).length;

  const [uploading, importing, failed, complete, completeWithErrors] = (
    ['isUploading', 'isImporting', 'isFailed', 'isComplete', 'isCompleteWithErrors'] as Status[]
  ).map(getLength);

  if (uploading > 0) {
    return 'uploading';
  } else if (importing > 0) {
    return 'importing';
  } else if (failed > 0) {
    return 'failed';
  } else if (completeWithErrors > 0) {
    return 'completeWithErrors';
  } else if (complete > 0) {
    return 'complete';
  } else {
    return 'idle';
  }
}

function isUploadRunning(status: UploadStatus) {
  return status === 'uploading' || status === 'importing';
}
