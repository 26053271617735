import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['collapsible-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'card' },
  standalone: true,
  imports: [FaIconComponent, NgClass, NgbCollapse],
})
export class CollapsibleCardComponent implements OnInit {
  /** An Input that can be used to override the default margin-bottom class */
  @Input() @HostBinding('class') customClass = 'mb-3';

  /**
   * Title of the card that will be rendered in the header.
   */
  @Input() headerTitle: string;

  /**
   * Indicate if the card is compact or not. A compact card doesn't have padding in the body.
   */
  @Input() compact: boolean;

  /**
   * Indicate if the card should be collapsed initially.
   */
  @Input() collapseByDefault: boolean;
  @Output() collapseChange = new EventEmitter<boolean>();
  isCollapsed = false;
  readonly faChevronRight = faChevronRight;

  ngOnInit() {
    this.isCollapsed = !!this.collapseByDefault;
  }
}
