<div class="w-100 d-flex flex-nowrap">
  <div class="flex-column flex-grow-1">
    <div>
      <select
        title="Sort column"
        class="form-control form-select-sm mb-1"
        [(ngModel)]="currentSort"
        [disabled]="sortingColumns.length === 1"
      >
        @for (column of sortingColumns; track column) {
          <option [value]="column.name">
            {{ column.label }}
          </option>
        }
      </select>
    </div>

    <div>
      <select
        title="Sort direction"
        class="form-control form-select-sm"
        [(ngModel)]="sortAscending"
        [disabled]="!currentSort"
      >
        <option value="ascending">Ascending</option>
        <option value="descending">Descending</option>
      </select>
    </div>
  </div>

  <button
    class="btn btn-sm align-self-start"
    type="button"
    title="Remove sort criteria"
    (click)="remove()"
  >
    <fa-icon [icon]="'minus'"></fa-icon>
  </button>
</div>
