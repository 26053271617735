<div class="d-flex justify-content-center align-items-stretch">
  <div class="w-static-5 px-4">
    <div
      class="d-flex flex-column justify-content-center min-vh-100 bx-page-container bx-page-container-xs px-0"
    >
      <bx-logo class="d-block py-5 mt-auto mb-3 text-center" [width]="'100%'"></bx-logo>
      <div class="text-center mb-auto">
        @if ((mfaRequired$ | async) === false) {
          <h3 class="fw-bold mb-2">{{ actionText$ | async }}</h3>
          <p class="premium-text mb-4">
            <span class="text-muted">Have a subscription?</span>
            <a class="premium-link" href="https://biologics.geneious.com/login">
              Sign in to Geneious Biologics Premium
            </a>
          </p>
          <div class="mb-4">
            <a
              [href]="microsoftSsoUrl"
              class="btn btn-lg btn-outline-secondary w-100 mb-3 d-flex align-items-center justify-content-between"
            >
              <mat-icon svgIcon="microsoft" class="h-100 mt-n1 bx-btn-icon"></mat-icon>
              <span>Continue with <b>Microsoft</b></span>
              <div class="px-2"></div>
            </a>
            <a
              [href]="googleSsoUrl"
              class="btn btn-lg btn-outline-secondary w-100 d-flex align-items-center justify-content-between"
            >
              <mat-icon svgIcon="google" class="h-100 mt-n1 bx-btn-icon"></mat-icon>
              <span>Continue with <b>Google</b></span>
              <div class="px-2"></div>
            </a>
          </div>
          @if (ssoError$ | async; as ssoError) {
            <div class="text-danger mb-3" [innerHTML]="ssoError"></div>
          }
          <div class="mb-4">
            <small class="text-muted">OR</small>
          </div>
        } @else {
          <bx-mfa-verification></bx-mfa-verification>
        }
        <ng-content></ng-content>
      </div>
      <div class="d-flex justify-content-between mt-5 mb-3">
        <a
          href="https://assets.geneious.com/legal/terms-of-service.pdf"
          target="_blank"
          rel="noopener noreferrer"
          title="Terms & conditions"
          >Terms & conditions</a
        >
        <a
          href="https://www.geneious.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          title="Privacy policy"
          >Privacy policy</a
        >
      </div>
    </div>
  </div>

  <div
    class="flex-grow-1 flex-shrink-1 bg-info d-none d-lg-flex d-xl-flex flex-column justify-content-center align-items-center"
  >
    <div class="bx-page-container p-5">
      <div class="text-light mb-5">
        <h1 class="mb-4">Select Sequences That Matter</h1>
        <p class="lead">
          Free online annotation and visualization of individual antibodies with Geneious Biologics
          (Starter Plan):
        </p>
        <ul class="lead">
          <li>Identify VDJ, VJ, CDR, FR and constant regions</li>
          <li>Compare sequences to germline genes</li>
          <li>Visualize with sequence viewer</li>
          <li>Analyze up to 24 IgG-like molecules at a time</li>
          <li>Access results for 7 days</li>
        </ul>
      </div>
    </div>
  </div>
</div>
