import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { CleanUp } from 'src/app/shared/cleanup';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { FolderService } from '../../folders/folder.service';
import { Folder, NonFolderTreeItem, TreeItem } from '../../folders/models/folder.model';
import { ExpandService } from '../../folders/store/expand.service';
import { NgClass, AsyncPipe } from '@angular/common';
import { AutofocusDirective } from '../../../shared/autofocus.directive';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-folder-tree-name',
  templateUrl: './folder-tree-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, AutofocusDirective, ReactiveFormsModule, NgClass, NgbTooltip, AsyncPipe],
})
export class FolderTreeNameComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'overflow-hidden text-secondary text-truncate';

  /** Note that the ID is only read in ngOnInit. The component should be re-created if you want to input a new folder. */
  @Input() folder!: TreeItem;
  renaming$: Observable<boolean>;
  isSaving: boolean;
  nameError$: Observable<string | null>;
  bold: boolean;

  private _nameControl: FormControl<string>;
  // Lazy-load the form control to improve performance with large folder trees
  get nameControl() {
    if (!this._nameControl) {
      this._nameControl = new FormControl(undefined);
    }
    return this._nameControl;
  }

  @Output() saveStart = new EventEmitter();
  @Output() saveEnd = new EventEmitter();

  constructor(
    private folderService: FolderService,
    private expandService: ExpandService,
  ) {
    super();
  }

  ngOnInit() {
    this.bold =
      this.folder.parentID === 'virtual-root' ||
      (this.folder instanceof NonFolderTreeItem && this.folder.topLevel);

    this.renaming$ = this.expandService
      .isRenaming(this.folder.id)
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe), shareReplay(1));

    // Send form state to the store when renaming
    this.renaming$
      .pipe(
        switchMap((renaming) => (renaming ? currentValueAndChanges(this.nameControl) : EMPTY)),
        filter((newName) => newName != null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((nameControlValue) => this.expandService.updateRenaming(nameControlValue));

    // Initialize form control when starting renaming
    this.renaming$
      .pipe(
        switchMap((renaming) =>
          renaming ? this.expandService.getRenameState().pipe(first()) : EMPTY,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((renameState) => {
        this.nameControl.setValue(renameState?.newName ?? this.folder?.name);
      });

    // Clear state when done renaming
    this.renaming$
      .pipe(
        filter((renaming) => !renaming),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.nameControl.reset();
      });

    this.nameError$ = this.renaming$.pipe(
      switchMap((renaming) => (renaming ? this.expandService.getRenameError() : of(null))),
      distinctUntilChanged(),
    );
  }

  onRenameSubmit() {
    const newName = this.nameControl.value?.trim();
    if (!newName || newName === this.folder?.name) {
      // If the proposed name is empty or the same as the existing folder name, just cancel rename without changing name.
      this.stopRenaming();
      return;
    }
    this.setSaving(true);
    const oldName = this.folder.name;
    this.folder.name = newName;
    this.folderService.rename(this.folder as Folder).subscribe({
      next: () => this.stopRenaming(),
      error: (response) => {
        // Reset folder name to match server.
        this.folder.name = oldName;
        // TODO use nice client side errors; the Nucleus errors aren't very user friendly.
        const errorMessage = response?.error?.error?.message;
        this.expandService.setRenameError(errorMessage);
        this.setSaving(false);
      },
    });
  }

  stopRenaming() {
    this.expandService.stopRenaming();
    this.setSaving(false);
  }

  onBlur() {
    if (!this.isSaving) {
      this.onRenameSubmit();
    }
  }

  setSaving(saving: boolean) {
    this.isSaving = saving;
    if (saving) {
      this.saveStart.emit();
      this.nameControl.disable();
    } else {
      this.saveEnd.emit();
      this.nameControl.enable();
    }
  }
}
