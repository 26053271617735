@if (pdfFileUrl$ | async; as pdfFileUrl) {
  <bx-toolstrip>
    <bx-toolstrip-item>
      <a class="btn btn-light rounded" [href]="pdfFileUrl" target="_blank">Open in new tab</a>
    </bx-toolstrip-item>
  </bx-toolstrip>
  <object
    id="pdf-view"
    [data]="pdfFileUrl"
    type="application/pdf"
    class="flex-grow-1 flex-shrink-1"
  >
    <embed [src]="pdfFileUrl" type="application/pdf" />
  </object>
}
