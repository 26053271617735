import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Observable, firstValueFrom, map, shareReplay, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { sortByString } from 'src/app/shared/sort.util';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { DatabaseTypeEnum } from '../database-type';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { DatabaseTypeControlComponent } from '../database-type-control/database-type-control.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { ClearFileButtonComponent } from '../../../shared/clear-file-button/clear-file-button.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * Presentation component for the form in the first step in the Create Reference
 * Database dialog.
 */
@Component({
  selector: 'bx-create-reference-db-details-form',
  templateUrl: './create-reference-db-details-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    DatabaseTypeControlComponent,
    NgClass,
    ClearFileButtonComponent,
    NgbTooltip,
    AsyncPipe,
  ],
})
export class CreateReferenceDbDetailsFormComponent extends CleanUp implements OnInit, OnDestroy {
  @Input() form: FormGroup<{
    databaseName: FormControl<string>;
    databaseType: FormControl<DatabaseTypeEnum>;
    files: FormControl<File[]>;
    autoAnnotateGermlineGenes: FormControl<boolean>;
  }>;
  enableAutoAnnotationOption$: Observable<boolean>;
  private readonly filesMap$ = new BehaviorSubject<{ [key: string]: File }>({});
  readonly selectedFiles$ = this.filesMap$.pipe(
    map((files) => Object.values(files).sort(sortByString((file) => file.name))),
    takeUntil(this.ngUnsubscribe),
  );
  readonly numFiles$ = this.filesMap$.pipe(
    map((files) => Object.keys(files).length),
    takeUntil(this.ngUnsubscribe),
  );

  ngOnInit(): void {
    this.selectedFiles$.subscribe((files) => this.form.controls.files.setValue(files));

    this.enableAutoAnnotationOption$ = currentValueAndChanges(this.form.controls.databaseType).pipe(
      map((value) => value === DatabaseTypeEnum.GERMLINE || value === DatabaseTypeEnum.TEMPLATE),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.enableAutoAnnotationOption$.subscribe((enableOption) => {
      if (enableOption) {
        this.form.controls.autoAnnotateGermlineGenes.enable();
      } else {
        this.form.controls.autoAnnotateGermlineGenes.setValue(false);
        this.form.controls.autoAnnotateGermlineGenes.disable();
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filesMap$.complete();
  }

  async onFilesSelected(fileList: FileList) {
    const newFiles = Object.fromEntries(
      Array.from(fileList, (file) => [this.getFileKey(file), file]),
    );
    const filesMap = await firstValueFrom(this.filesMap$);
    this.filesMap$.next({ ...newFiles, ...filesMap });
  }

  async onFileDeselected(deselectedFile: File) {
    const { ...filesMap } = await firstValueFrom(this.filesMap$);
    const keyToDelete = this.getFileKey(deselectedFile);
    delete filesMap[keyToDelete];
    this.filesMap$.next(filesMap);
  }

  private getFileKey(file: File): string {
    return file.name + file.size + file.lastModified;
  }
}
