@if (selectionIsEmpty$ | async) {
  <bx-page-message>No rows selected</bx-page-message>
} @else {
  @if (data$ | async; as data) {
    <bx-column-chart
      class="annotation-chart"
      [animations]="true"
      [data]="data.data"
      [title]="data.title | suffix: (titleSuffixForSelectionDependentGraphs$ | async) : ' '"
      [xAxisTitle]="data.xLabel"
      [yAxisTitle]="data.yLabel"
    >
    </bx-column-chart>
  }
}
<ng-template #selected>
  @if (data$ | async; as data) {
    <bx-column-chart
      class="annotation-chart"
      [animations]="true"
      [data]="data.data"
      [title]="data.title | suffix: (titleSuffixForSelectionDependentGraphs$ | async) : ' '"
      [xAxisTitle]="data.xLabel"
      [yAxisTitle]="data.yLabel"
    >
    </bx-column-chart>
  }
</ng-template>
