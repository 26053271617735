<form [formGroup]="form">
  <bx-card headerTitle="Pair By">
    <div class="d-flex align-items-center mb-2">
      <div class="form-check form-check-inline me-2">
        <input
          type="radio"
          name="pairBy"
          formControlName="pairBy"
          id="interlaced"
          value="interlaced"
          class="form-check-input"
          [attr.disabled]="disableInterlacingSequences"
        />
        <label for="interlaced" class="form-check-label">
          Interlaced sequences within each list
        </label>
      </div>
      @if (disableInterlacingSequences) {
        <i class="text-muted small">
          Select only sequence lists with even numbers of sequences to enable this option.
        </i>
      }
    </div>

    <div class="d-flex align-items-center mb-2">
      <div class="form-check form-check-inline me-2">
        <input
          type="radio"
          name="pairBy"
          formControlName="pairBy"
          id="bySequenceList"
          value="bySequenceList"
          class="form-check-input"
          [attr.disabled]="disablePairsOfLists"
        />
        <label for="bySequenceList" class="form-check-label"> Pairs of lists </label>
      </div>
      @if (disablePairsOfLists) {
        <i class="text-muted small">
          Select only an even number of sequence lists with equal numbers of sequences to enable
          this option.
        </i>
      }
    </div>

    <div class="form-check mb-2">
      <input
        type="radio"
        name="pairBy"
        formControlName="pairBy"
        id="splitting"
        value="splitting"
        class="form-check-input"
      />
      <label
        for="splitting"
        ngbTooltip="Splits each sequence in half. This is useful on data produced by the Polonator sequencing machine. All sequences need to be the same length and an even number to use this option"
        class="form-check-label"
        placement="bottom"
      >
        Split each sequence in half
      </label>
    </div>

    <div class="form-check">
      <input
        type="radio"
        name="pairBy"
        formControlName="pairBy"
        id="byName"
        value="byName"
        class="form-check-input"
      />
      <label
        for="byName"
        ngbTooltip="Pairs reads by name. This is intended for use with reads which have been processed or filtered by an external program leaving some reads without a mate. In most situations where you should either pair by sequence list or interlaced."
        class="form-check-label"
        placement="bottom"
      >
        Match sequence names (for standalone sequences, or within each list) (slow)
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Relative Orientation">
    <div class="form-check mb-2">
      <input
        type="radio"
        name="relativeOrientation"
        formControlName="relativeOrientation"
        id="forwardForward"
        value="forwardForward"
        class="form-check-input"
      />
      <label
        for="forwardForward"
        ngbTooltip="Both reads are in the same direction and the 1st read occurs before the 2nd read."
        class="form-check-label"
      >
        Forward/Forward (e.g. SOLID)
      </label>
    </div>

    <div class="form-check mb-2">
      <input
        type="radio"
        name="relativeOrientation"
        formControlName="relativeOrientation"
        id="forwardForwardInvertOrder"
        value="forwardForwardInvertOrder"
        class="form-check-input"
      />
      <label
        for="forwardForwardInvertOrder"
        ngbTooltip="Both reads are in the same direction, but the 2nd read occurs before the 1st read."
        class="form-check-label"
      >
        Forward/Forward with inverted order (e.g. 454)
      </label>
    </div>

    <div class="form-check mb-2">
      <input
        type="radio"
        name="relativeOrientation"
        formControlName="relativeOrientation"
        id="forwardReverse"
        value="forwardReverse"
        class="form-check-input"
      />
      <label
        for="forwardReverse"
        ngbTooltip="The reads are in opposite directions, pointing inwards. Used for example with Illumina paired ends where reads are typically a few hundred bases apart."
        class="form-check-label"
      >
        Forward/Reverse (inward pointing, e.g. Illumina paired end)
      </label>
    </div>

    <div class="form-check">
      <input
        type="radio"
        name="relativeOrientation"
        formControlName="relativeOrientation"
        id="reverseForward"
        value="reverseForward"
        class="form-check-input"
      />
      <label
        for="reverseForward"
        ngbTooltip="The reads are in opposite directions, pointing outwards. Used for example with Illumina mate pairs where reads are typically a few thousand bases apart."
        class="form-check-label"
      >
        Reverse/Forward (outward pointing, e.g. Illumina mate pairs)
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Output Format">
    <div class="mb-2">
      <div class="form-check mb-2">
        <input
          type="radio"
          name="isDoMerge"
          formControlName="isDoMerge"
          id="setAndMerge"
          [value]="true"
          class="form-check-input"
        />
        <label
          for="setAndMerge"
          ngbTooltip="Output merged paired reads, or simply pair reads without merging. Useful if you want to associate sequences with each other, but they are not appropriate to merge. For example, associate Heavy and Light chains before inputting to the NGS pipeline."
          class="form-check-label"
        >
          Set and merge paired reads using
          <a
            target="_blank"
            href="https://jgi.doe.gov/data-and-tools/software-tools/bbtools/bb-tools-user-guide/bbmerge-guide/"
            >BBMerge</a
          >
        </label>
      </div>

      <div class="row mb-2">
        <div class="col-1"></div>
        <label
          for="mergeRate"
          ngbTooltip="Adjust the sensitivity of the merge process. Useful if you want to change how many reads are merged."
          class="col-sm-3 col-lg-2 col-form-label"
        >
          Merge rate:
        </label>
        <div class="col">
          <select formControlName="mergeRate" id="mergeRate" class="form-select">
            <option value="strict">Low (Decrease false positive rate and merging rate)</option>
            <option value="normal">Normal (Merge with default settings)</option>
            <option value="loose">High (Increase false positive rate and merging rate)</option>
            <option value="vloose">
              Very High (Greatly increase false positive rate and merging rate)
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col form-check form-check-inline">
          <input
            type="checkbox"
            formControlName="iterativelyTrim"
            id="iterativelyTrim"
            class="form-check-input"
          />
          <label
            for="iterativelyTrim"
            ngbTooltip="Can increase merge rate for low quality reads. Iteratively trims ends based on increasing quality thresholds if merge unsuccessful."
            class="form-check-label"
          >
            Iteratively trim
          </label>
        </div>
      </div>
    </div>

    <div class="form-check">
      <input
        type="radio"
        name="isDoMerge"
        formControlName="isDoMerge"
        id="setOnly"
        [value]="false"
        class="form-check-input"
      />
      <label
        for="setOnly"
        ngbTooltip="Set paired reads, but do not merge."
        class="form-check-label"
      >
        Set paired reads only
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Expected Distance / Insert Size">
    <div class="row">
      <label
        class="col-sm col-lg-2 col-form-label"
        for="distance"
        ngbTooltip="Sets the expected distance between pairs, inclusive of the length of the reads. <br>For example, if both reads are 30bp long and the distance between them is 40bp, then the expected distance should be 100. Your sequencing provider should have supplied you with this information. If they didn't, ask them for it."
      >
        Distance:
      </label>
      <div class="col-4">
        <input
          type="number"
          min="1"
          max="2147483647"
          id="distance"
          formControlName="expectedDistance"
          class="form-control"
        />
      </div>
      <div class="col-4">
        <img [src]="insertImage" />
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Read Technology">
    <div class="row">
      <label
        class="col-sm col-lg-2 col-form-label"
        for="dataType"
        ngbTooltip="Sets the sequencing machine used to create this data. This information is used by some operations (e.g. de novo assembly) as a guide on what the data error model is likely to produce better results."
      >
        Technology:
      </label>
      <div class="col-4">
        <select id="dataType" formControlName="dataType" class="form-select">
          <option value="Illumina">Illumina</option>
          <option value="Ion Torrent">Ion Torrent</option>
          <option value="PacBio CLR">PacBio CLR</option>
          <option value="PacBio CCS">PacBio CCS</option>
          <option value="Oxford Nanopore">Oxford Nanopore</option>
          <option value="Sanger">Sanger</option>
          <option value="454">454</option>
          <option value="Solid">Solid</option>
          <option value="Unknown">Unknown</option>
        </select>
      </div>
      <div class="col-4">
        <select formControlName="pairedType" class="form-select">
          <option value="Paired End">Paired End</option>
          <option value="Mate Pair">Mate Pair</option>
          <option value="High Quality Mate Pair">High Quality Mate Pair</option>
          <option value="Lucigen NxSeq Mate Pair">Lucigen NxSeq Mate Pair</option>
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [outputNameFormControl]="form.get('outputFolderName')"></bx-pipeline-output>
  </bx-card>
</form>
