<a
  bx-folder-tree-link
  [routerLink]="folder.route"
  linkIcon="uploads"
  linkText="Uploads"
  [ngClass]="{ 'bg-selected-folder-tree': selected$ | async }"
  class="d-flex py-1 pe-2 align-items-center flex-nowrap"
>
  @if (status$ | async; as status) {
    <div class="status my-n1 ms-auto text-nowrap">
      @if (status === 'complete') {
        <mat-icon class="align-middle">done</mat-icon>
      }
      @if (status === 'failed' || status === 'completeWithErrors') {
        <mat-icon class="align-middle"> error </mat-icon>
      }
      @if (status === 'uploading' || status === 'importing') {
        <bx-spinner data-testid="upload-spinner" class="align-middle ms-1"></bx-spinner>
      }
    </div>
  }
  <bx-temporary-popup message="Upload started" class="me-n1"></bx-temporary-popup>
</a>
