import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Observable, of, startWith } from 'rxjs';
import {
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  pairwise,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { CleanUp } from '../../../shared/cleanup';
import {
  AuthenticateAction,
  AuthenticateErrorAction,
  CancelMfaInProgressAction,
} from '../../auth/auth.actions';
import { selectLoginErrorState, selectMFARequired } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { requiredWithMessage } from 'src/app/shared/utils/forms';
import { LoginErrorState } from '../../auth/auth.reducers';
import { AsyncPipe } from '@angular/common';
import { LogoComponent } from '../../../shared/logo/logo.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { EnvironmentSwitcherComponent } from '../environment-switcher/environment-switcher.component';
import { MfaVerificationComponent } from '../mfa-verification/mfa-verification.component';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LogoComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    EnvironmentSwitcherComponent,
    MfaVerificationComponent,
    RouterLink,
    SpinnerButtonComponent,
    MatIconModule,
    AsyncPipe,
  ],
})
export class LoginComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block vh-100 w-100 overflow-auto';

  readonly form = new FormGroup({
    login: new FormGroup({
      email: new FormControl<string>(undefined, requiredWithMessage('Email is required')),
      password: new FormControl<string>(undefined, requiredWithMessage('Password is required')),
    }),
    mfaCode: new FormControl<number>(
      undefined,
      requiredWithMessage('Verification code is required'),
    ),
  });
  loggingIn$: Observable<boolean>;
  lockoutTime$: Observable<number>;
  loginError$: Observable<string | null>;
  mfaRequired$: Observable<boolean>;
  loggingInOrLockedOut$: Observable<boolean>;
  lumaLoginEnabled: boolean;
  private readonly lockoutSeconds = 15;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) {
    super();
    this.lumaLoginEnabled = config.lumaLoginEnabled;
  }

  ngOnInit() {
    this.lockoutTime$ = this.store.pipe(
      select(selectLoginErrorState),
      exhaustMap((loginState) =>
        this.shouldLockout(loginState)
          ? interval(1000).pipe(
              map((count) => this.lockoutSeconds - (count + 1)), // 0 is emitted after 1 second
              startWith(this.lockoutSeconds),
              takeWhile((remainingSeconds) => remainingSeconds >= 0),
            )
          : of(0),
      ),
      startWith(0),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    // If errorCode is still 429 after the lockout ends, clear the error to enable form submission
    this.lockoutTime$
      .pipe(
        pairwise(),
        filter(([prevTime, currTime]) => prevTime > 0 && currTime === 0),
        switchMap(() => this.store.pipe(select(selectLoginErrorState), take(1))),
        filter((loginState) => this.shouldLockout(loginState)),
        tap(() =>
          this.store.dispatch(new AuthenticateErrorAction({ error: null, errorCode: null })),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    const loginErrorMsg$ = this.route.data.pipe(
      take(1),
      map((data) => data.ssoError),
      switchMap(() => this.store.select(selectLoginErrorState)),
      map((loginState) => loginState.error),
      takeUntil(this.ngUnsubscribe),
    );
    this.loginError$ = combineLatest([loginErrorMsg$, this.lockoutTime$]).pipe(
      map(([errorMsg, secondsRemaining]) => {
        if (secondsRemaining === 0) {
          return errorMsg;
        }
        return `Too many failed login attempts. You can retry in ${secondsRemaining} seconds.`;
      }),
    );

    this.loggingIn$ = this.store.select(selectLoginErrorState).pipe(
      map((state) => state.loggingIn),
      startWith(false),
      takeUntil(this.ngUnsubscribe),
    );

    this.mfaRequired$ = this.store
      .pipe(select(selectMFARequired))
      .pipe(startWith(false), takeUntil(this.ngUnsubscribe), shareReplay(1));

    this.loggingInOrLockedOut$ = combineLatest([
      this.loggingIn$,
      this.lockoutTime$.pipe(map((time) => time > 0)),
    ]).pipe(
      map(([loggingIn, lockedOut]) => loggingIn || lockedOut),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    this.loggingInOrLockedOut$.subscribe((loggingInOrLockedOut) => {
      if (loggingInOrLockedOut) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });
  }

  backToLogin() {
    this.store.dispatch(new CancelMfaInProgressAction());
  }

  login(requireMfaCode: boolean) {
    if (this.form.controls.login.invalid) {
      // Show bootstrap feedback if the user hasn't entered any details
      this.form.controls.login.controls.email.markAsDirty();
      this.form.controls.login.controls.email.updateValueAndValidity();
      this.form.controls.login.controls.password.markAsDirty();
      this.form.controls.login.controls.password.updateValueAndValidity();
      return;
    }

    if (requireMfaCode && this.form.controls.mfaCode.invalid) {
      this.form.controls.mfaCode.markAsDirty();
      this.form.controls.mfaCode.updateValueAndValidity();
      return;
    }

    this.store.dispatch(
      new AuthenticateAction({
        ...this.form.controls.login.getRawValue(),
        mfaCode: this.form.controls.mfaCode.value,
      }),
    );
  }

  navigateToAzure() {
    document.location.href = this.addTokenLifespanIfAvailable(
      `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/AzureAd2Client`,
    );
  }

  navigateToGoogle() {
    document.location.href = this.addTokenLifespanIfAvailable(
      `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/GoogleOidcClient`,
    );
  }

  navigateToLumaAuth() {
    document.location.href = this.addTokenLifespanIfAvailable(
      `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/auth/oidc/KeycloakOidcClient`,
    );
  }

  private addTokenLifespanIfAvailable(initialUrl: string) {
    if (this.config.sessionExpiresMinutes) {
      initialUrl += `?tokenLifespanSeconds=${this.config.sessionExpiresMinutes * 60}`;
    }
    return initialUrl;
  }

  private shouldLockout(loginState: LoginErrorState) {
    // Error code 429 is Too Many Requests
    return loginState.errorCode === 429;
  }
}
