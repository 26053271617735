import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { AbstractControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import {
  NGSComparisonsJobOptionsV1,
  NGSComparisonsJobParametersV1,
} from '../../../../nucleus/services/models/ngsComparisonsOptions.model';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { LONG_ANTIBODY_ANNOTATOR_REGIONS } from '../../antibodyAnnotatorRegions.service';
import { largeRegionsWithHighThresholdValidator } from './large-regions-with-high-threshold.validator';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { SelectOption } from '../../models/ui/select-option.model';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { currentValueAndChanges, restrictControlValue } from 'src/app/shared/utils/forms';
import { AsyncPipe } from '@angular/common';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from '../../../shared/card/card.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { SelectComponent } from '../../../shared/select/select.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-ngs-comparisons',
  templateUrl: './ngs-comparisons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbAlert,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgFormControlValidatorDirective,
    NgbTooltip,
    SelectComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class NgsComparisonsComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  earlyRelease: false;
  title = 'Comparison';
  knowledgeBaseArticle = 'https://help.geneiousbiologics.com/hc/en-us/articles/360045069851';

  regions$: Observable<SelectOption[]>;
  warning: String;
  noRegionsError$: Observable<boolean>;

  readonly form = new BxFormGroup({
    inFrameWithoutStopCodonsOnly: new BxFormControl(false),
    // Not required by the pipeline I don't think; used to enable/disable `filterOutCountAcrossSamplesLowerThan`.
    filterOutCountAcrossSamples: new BxFormControl(false),
    filterOutCountAcrossSamplesLowerThan: new BxFormControl(5, [
      Validators.min(1),
      Validators.max(9999999),
    ]),

    normalization: new BxFormControl('total', Validators.required),
    deSeqMinFrequency: new BxFormControl(5, [Validators.min(1), Validators.max(9999999)]),
    referenceSampleDocumentID: new BxFormControl<string>(undefined, Validators.required),
    // I assume this is required for the pipeline.
    // TODO Make the pipeline always return this & remove this option,
    outputValuesToMaximumAccuracy: new BxFormControl(true),

    reclusterBeforeComparison: new BxFormControl(false, Validators.required),
    reclusteringOptions: new BxFormGroup(
      {
        reclustering_clusterMethod: new BxFormControl<
          'similarity' | 'identity' | 'identityByCount'
        >('similarity', Validators.required),
        reclustering_sequenceIdentityThreshold: new BxFormControl(90, [
          Validators.min(50),
          Validators.max(100),
        ]),
        reclustering_region: new BxFormControl('Heavy CDR3', Validators.required),
        // reclusterBeforeComparison: new BxFormControl(true),
      },
      [largeRegionsWithHighThresholdValidator],
    ),
    outputFolderName: JobDialogContent.getResultNameControl(),
  });
  reclusteringOptions = this.form.controls.reclusteringOptions;
  reclusteringSequenceIdentityThreshold =
    this.reclusteringOptions.controls.reclustering_sequenceIdentityThreshold;
  reclusteringSequenceIdentityThresholdErrors$ =
    this.reclusteringSequenceIdentityThreshold.statusChanges.pipe(
      map((_) => this.reclusteringSequenceIdentityThreshold.errors),
    );
  readonly selected: SelectionState;
  private readonly formDefaults: any;
  private subscriptions = new Subscription();
  private reclusteringRegionControl = this.reclusteringOptions.controls.reclustering_region;
  private reclusteringBeforeComparisonControl = this.form.controls.reclusterBeforeComparison;
  private selectedReclusteringRegion$: Observable<string> =
    this.reclusteringRegionControl.valueChanges;
  clusterMethodIsIdentityByCount$: Observable<boolean>;
  thresholdLabel$: Observable<string>;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<{ containsPeptideResults: boolean }>,
    private documentTableStateService: DocumentTableStateService,
  ) {
    super('ngs-comparisons', PipelineFormID.NGS_COMPARISON);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
  }

  ngOnInit() {
    // need to do this first as otherwise tables aren't guaranteed to be in the store.
    const selectedRows: any[] = this.selected.selectedRows;
    const rowIds = selectedRows.map((row) => row.id);
    this.documentTableStateService.fetchTables(rowIds);

    if (this.dialogData.otherVariables.containsPeptideResults) {
      this.form.controls.inFrameWithoutStopCodonsOnly.setValue(false);
      this.form.controls.inFrameWithoutStopCodonsOnly.disable();
    }

    // Only use regions that are present in all docs.
    this.regions$ = of(this.selected.selectedRows).pipe(
      switchMap((rows) =>
        forkJoin(
          rows.map((row) =>
            this.documentTableStateService.getTables(row.id).pipe(
              take(1),
              map((tables) => ({
                fromTableMetadata: new Set(
                  tables
                    .map((table) => table.metadata?.clusters?.columnName)
                    .filter((name) => !!name),
                ),
                clustersInRow: row.metadata?.clustersNames as string | undefined,
              })),
            ),
          ),
        ),
      ),
      map((rows) => {
        let commonClusterRegions: Set<string> = null;
        rows.forEach(({ fromTableMetadata, clustersInRow }) => {
          const thisRowClusterRegion: Set<string> = new Set();
          if (clustersInRow) {
            clustersInRow.split('#').forEach((cluster) => thisRowClusterRegion.add(cluster));
          } else {
            (fromTableMetadata ?? []).forEach((cluster) => thisRowClusterRegion.add(cluster));
          }

          // Perform set intersection on this row and the already found common regions.
          if (commonClusterRegions == null) {
            commonClusterRegions = thisRowClusterRegion;
          } else {
            commonClusterRegions = new Set(
              [...thisRowClusterRegion].filter((i) => commonClusterRegions.has(i)),
            );
          }
        });
        const commonClusterRegionsArray = [...commonClusterRegions];
        restrictControlValue(this.reclusteringRegionControl, commonClusterRegionsArray, {
          takeUntil: this.ngUnsubscribe,
        });
        return commonClusterRegionsArray.map((cluster) => new SelectOption(cluster, cluster));
      }),
    );

    const regionsEnabled$ = this.reclusteringBeforeComparisonControl.valueChanges.pipe(
      startWith(this.reclusteringBeforeComparisonControl.value),
    );

    this.noRegionsError$ = combineLatest([this.regions$, regionsEnabled$]).pipe(
      map(([regions, regionsEnabled]) => regionsEnabled && (!regions || regions.length === 0)),
    );

    const referenceSampleControl = this.form.controls.referenceSampleDocumentID;
    referenceSampleControl.setValue(this.selected.selectedRows[0].id);
    restrictControlValue(
      referenceSampleControl,
      this.selected.selectedRows.map((row) => row.id),
      { takeUntil: this.ngUnsubscribe },
    );

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          // Init the current form value first.
          startWith(this.form.getRawValue()),
        )
        .subscribe((value) => {
          this.enableControl(
            this.form.controls.filterOutCountAcrossSamplesLowerThan,
            value.filterOutCountAcrossSamples,
          );
          this.enableControl(
            this.form.controls.deSeqMinFrequency,
            value.normalization === 'medianOfExpressionRatios',
          );

          this.enableControl(
            this.form.controls.reclusteringOptions,
            value.reclusterBeforeComparison,
          );
        }),
    );
    this.clusterMethodIsIdentityByCount$ = currentValueAndChanges(
      this.reclusteringOptions.controls.reclustering_clusterMethod,
    ).pipe(map((method) => method === 'identityByCount'));

    this.handleReclusteringThreshold();
    this.thresholdLabel$ = this.clusterMethodIsIdentityByCount$.pipe(
      map((isIdentityByCount) => (isIdentityByCount ? 'Mismatches' : 'Threshold')),
    );
    if (this.hasDuplicateNames(this.selected.selectedRows)) {
      this.warning =
        'Warning: Two or more input documents have the same name. Please rename them first.';
      this.form.setErrors({ invalidInputs: true }, { emitEvent: true });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  run() {
    const options = this.convertFormValueToNGSComparisonJobOptionsV1();
    const outputFolderName = options.optionValues.outputFolderName;
    // Use set referenceSampleID to reorder the selected ids.
    const referenceSampleDocumentID = this.form.getRawValue()['referenceSampleDocumentID'];

    const filtered = this.selected.ids.filter((id) => id !== referenceSampleDocumentID);
    const reOrderedSelectedIds = [referenceSampleDocumentID, ...filtered];

    // referenceSampleDocumentID and outputFolderName are not valid options in the pipeline, so we need to remove them before parsing on the the pipeline.
    delete options.optionValues['outputFolderName'];
    delete options.optionValues['referenceSampleDocumentID'];

    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: reOrderedSelectedIds,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    return new NGSComparisonsJobParametersV1(1, parameters);
  }

  getFormDefaults(): any {
    // Form defaults are set in the constructor before `this.selected` are available.
    // Therefore we can't _just_ return `this.formDefaults`, we have to include `referenceSampleDocumentID` too.
    return Object.assign(this.formDefaults, {
      referenceSampleDocumentID: this.selected.selectedRows[0].id,
    });
  }

  private enableControl(targetControl: AbstractControl, condition: boolean) {
    condition
      ? targetControl.enable({ emitEvent: false })
      : targetControl.disable({ emitEvent: false });
  }

  private hasDuplicateNames(list: any[]): boolean {
    const names: string[] = list.map((obj) => obj.name);
    const uniqueNames: any = {};
    names.forEach((value) => (uniqueNames[value] = true));
    return Object.keys(uniqueNames).length < list.length;
  }

  /**
   * If Selected reclustering region is a LARGE/LONG Annotated Region then set the threshold to 95
   * and change the minimum threshold to 90. This to avoid users trying to start comparison runs
   * that take far too long for what they get in return.
   */
  private handleReclusteringThreshold() {
    this.subscriptions.add(
      combineLatest([
        this.selectedReclusteringRegion$.pipe(startWith(this.reclusteringRegionControl.value)),
        this.clusterMethodIsIdentityByCount$,
      ]).subscribe(([region, isIdByCount]) => {
        if (isIdByCount) {
          this.reclusteringSequenceIdentityThreshold.setValue(1);
          this.reclusteringSequenceIdentityThreshold.setValidators([
            Validators.min(1),
            Validators.max(5),
          ]);
        } else if (LONG_ANTIBODY_ANNOTATOR_REGIONS.some((cluster) => cluster.label === region)) {
          this.reclusteringSequenceIdentityThreshold.setValue(95);
          // Minimum threshold now set to 90.
          this.reclusteringSequenceIdentityThreshold.setValidators([
            Validators.min(90),
            Validators.max(100),
          ]);
        } else {
          this.reclusteringSequenceIdentityThreshold.setValidators([
            Validators.min(50),
            Validators.max(100),
          ]);
        }
        this.reclusteringSequenceIdentityThreshold.updateValueAndValidity();
      }),
    );
  }

  /**
   * `reclusteringOptions` formGroup key must be removed before sending the job options to the
   * pipeline. i.e. the form value needs to be flattened.
   */
  private convertFormValueToNGSComparisonJobOptionsV1(): NGSComparisonsJobOptionsV1 {
    const { reclusteringOptions: reclusteringOptions, ...formValue } = this.form.value;

    return {
      optionValues: {
        ...formValue,
        ...reclusteringOptions,
      },
    };
  }
}
