import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LogoComponent } from '../../../shared/logo/logo.component';
import { EnvironmentSwitcherComponent } from '../environment-switcher/environment-switcher.component';
import { ExternalWrapperComponent } from '../external-wrapper/external-wrapper.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'bx-public-page',
  templateUrl: './public-page.component.html',
  // Children of this Component rely on Default Change Detection.
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [LogoComponent, EnvironmentSwitcherComponent, ExternalWrapperComponent, RouterOutlet],
})
export class PublicPageComponent {}
