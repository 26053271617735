import { EMPTY, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { FolderService } from '../folder.service';
import {
  addTopLevelFolderFromServer,
  createFolder,
  deleteFolder,
  fetchFolderChildren,
  fetchFolderIfNeeded,
  fetchTopLevelFolderAccess,
  moveFolder,
  replaceFolders,
  stopWatchingAllFolders,
  stopWatchingFolderChildren,
  stopWatchingFolders,
  updateFolderMetadata,
  updateFoldersAccess,
  updateTopLevelFolderAccess,
  watchFolders,
} from './folder.actions';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import { AppState } from '../../core.store';
import {
  selectIsAuthenticated,
  selectSharedWorkspaceID,
  selectUserID,
} from '../../auth/auth.selectors';
import { selectFolderExpandState } from './expand.selectors';
import {
  selectedFolderID,
  selectFolder,
  selectReferenceSequencesFolderID,
  selectWatchingFolders,
} from './folder.selectors';
import { ExpandService } from './expand.service';
import { PrincipalPermissionsPermissions } from '@geneious/nucleus-api-client';
import { FolderUtils } from '../folder.utils';
import { Folder, FolderTreeItem } from '../models/folder.model';
import { PermissionsService } from '../../permissions/permissions.service';
import { NavigationService } from '../../navigation/navigation.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class FolderEffects {
  readonly sharedWorkspaceID$ = this.store
    .select(selectSharedWorkspaceID)
    .pipe(filter((sharedWorkspaceID) => !!sharedWorkspaceID));

  readonly referenceSequencesID$ = this.store
    .select(selectReferenceSequencesFolderID)
    .pipe(filter((referenceSequencesID) => !!referenceSequencesID));

  /**
   * Listens for {@link fetchFolderChildren}, fetches the folder's children
   * from the server, and emits {@link replaceFolders} for the folder and its
   * children.
   */
  readonly fetchingFolderChildren$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchFolderChildren),
      mergeMap((action) =>
        this.folderService.get(action.id).pipe(
          take(1),
          switchMap((folder) =>
            this.folderService
              .getChildrenFromServer(folder.id, folder.depth)
              .pipe(map((children) => [...children, folder])),
          ),
          map((folders) =>
            replaceFolders({
              parentID: action.id,
              folders,
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * Listens for {@link fetchFolderChildren} and emits {@link watchFolders} for
   * that folder ID.
   */
  readonly watchFolderAfterFetchChildren$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchFolderChildren),
      map((action) => watchFolders({ ids: [action.id] })),
    ),
  );

  /**
   * Listens for {@link replaceFolders}, recursively fetches the IDs of child
   * folders that are visible in the folder tree, and then emits
   * {@link watchFolders} for those child IDs.
   */
  readonly watchExpandedFolders$ = createEffect(() =>
    this.actions.pipe(
      ofType(replaceFolders),
      switchMap((action) => this.getFolderIDsToWatch(action.parentID)),
      map((ids) => watchFolders({ ids })),
    ),
  );

  /**
   * Listens for {@link createFolder}, and if the new folder is visible in the
   * folder tree (i.e. if its parent is expanded), emits {@link watchFolders} for
   * the new folder ID.
   */
  readonly watchCreatedFolders$ = createEffect(() =>
    this.actions.pipe(
      ofType(createFolder),
      mergeMap(({ folder }) =>
        this.isWithinExpandedParentFolder(folder.parentID).pipe(
          map((parentsExpanded) => ({ folder, parentsExpanded })),
        ),
      ),
      filter(({ parentsExpanded }) => parentsExpanded),
      map(({ folder }) => watchFolders({ ids: [folder.id] })),
    ),
  );

  /**
   * Listens for {@link createFolder}, and updates the new folder's permissions.
   * Only direct children of the Shared Workspace folder have unique permissions.
   * Folders that are more deeply nested inherit permissions from their parents.
   * Therefore, if the new folder's parent is the Shared Workspace folder,
   * {@link fetchTopLevelFolderAccess} is dispatched to get permissions from the server.
   * Otherwise, {@link updateFoldersAccess} is dispatched with the parent folder
   * permissions.
   */
  readonly updateCreatedFolderAccessInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(createFolder),
      switchMap(({ folder }) =>
        this.folderService.get(folder.parentID).pipe(
          take(1),
          map((parentFolder) => ({ folder, parentFolder })),
        ),
      ),
      map(({ parentFolder, folder }) => {
        if (
          FolderUtils.isSharedWorkspaceFolder(parentFolder as Folder) ||
          FolderUtils.isReferenceDatabaseRoot(parentFolder as Folder)
        ) {
          return fetchTopLevelFolderAccess({ id: folder.id });
        }
        // Inherit parent folder access information if the folder is not a direct child of shared workspace folder.
        return updateFoldersAccess({
          ids: [folder.id],
          shared: parentFolder.shared,
          permissions: parentFolder.permissions as PrincipalPermissionsPermissions[],
        });
      }),
    ),
  );

  /**
   * Listens for {@link updateTopLevelFolderAccess} (which contains a top-level
   * folder ID and a new set of permissions), fetches the IDs of all children
   * under that folder (at any depth), and then dispatches
   * {@link updateFoldersAccess} with the new permissions for the child IDs.
   */
  readonly updateChildrenFolderAccessInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateTopLevelFolderAccess),
      mergeMap((action) =>
        this.folderService
          .getAllChildrenIDs(action.id)
          .pipe(map((childrenIDs) => ({ action, childrenIDs }))),
      ),
      map(({ action, childrenIDs }) =>
        updateFoldersAccess({
          ids: childrenIDs,
          shared: action.shared,
          permissions: action.permissions,
        }),
      ),
    ),
  );

  /**
   * Listens for {@link fetchTopLevelFolderAccess}, fetches the folder and its
   * access info from the server, and checks the store for the folder ID. If the
   * folder is in the store already, dispatches {@link updateTopLevelFolderAccess}
   * with the access info. Otherwise, it dispatches {@link addTopLevelFolderFromServer}
   * with the access info & folder details from the server.
   */
  readonly fetchTopLevelFolderAccessInfo$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchTopLevelFolderAccess),
      mergeMap(({ id }) =>
        this.store.select(selectFolder(id)).pipe(
          take(1),
          switchMap((folderInStore) =>
            this.getFolderAndAccessInfoFromServer(id).pipe(
              map(({ folder, access }) =>
                folderInStore
                  ? updateTopLevelFolderAccess({ id, ...access })
                  : addTopLevelFolderFromServer({ folder, access }),
              ),
              catchError((err) => {
                if (err.status === 403) {
                  // The folder has been made private
                  return of(deleteFolder({ id, fallbackFolderID: folderInStore.parentID }));
                }
                Sentry.captureException(err);
                return EMPTY; // Some error happened while fetching folder permission. Ignore it for now.
              }),
            ),
          ),
        ),
      ),
    ),
  );

  /**
   * Listens for {@link addTopLevelFolderFromServer} and maps it to
   * {@link updateTopLevelFolderAccess}.
   */
  readonly updateFolderAccessForTopLevelFolderFromServer$ = createEffect(() =>
    this.actions.pipe(
      ofType(addTopLevelFolderFromServer),
      map(({ folder, access }) =>
        updateTopLevelFolderAccess({
          id: folder.id,
          ...access,
        }),
      ),
    ),
  );

  /**
   * Listens for {@link addTopLevelFolderFromServer} and maps it to
   * {@link fetchFolderChildren} to populate newly fetched top level folder.
   */
  readonly fetchChildrenForTopLevelFolderFromServer$ = createEffect(() =>
    this.actions.pipe(
      ofType(addTopLevelFolderFromServer),
      map(({ folder }) =>
        fetchFolderChildren({
          id: folder.id,
        }),
      ),
    ),
  );

  /**
   * Listens for {@link deleteFolder} and emits {@link stopWatchingFolders} with
   * the deleted folder ID.
   */
  readonly stopWatchingDeletedFolders$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteFolder),
      map((action) => stopWatchingFolders({ ids: [action.id] })),
    ),
  );

  readonly selectFallbackFolderWhenCurrentFolderIsDeleted$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(deleteFolder),
        switchMap(({ id, fallbackFolderID }) =>
          this.store.select(selectedFolderID).pipe(
            take(1),
            filter((selectedFolderID) => !!selectedFolderID), // Only perform redirection check if there is indeed a selected folderID
            switchMap((selectedFolderID) =>
              this.store.select(selectFolder(selectedFolderID)).pipe(take(1)),
            ),
            map((selectedFolder) => ({ selectedFolder, fallbackFolderID })),
          ),
        ),
        filter(({ selectedFolder }) => !selectedFolder),
        switchMap(({ fallbackFolderID }) =>
          this.store.select(selectFolder(fallbackFolderID)).pipe(take(1)),
        ),
        filter((fallbackFolder) => !!fallbackFolder),
        tap((fallbackFolder) => {
          this.navigationService.goToFolder(fallbackFolder);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Listens to the folder activity stream and emits the corresponding store
   * action for each event.
   */
  readonly watchFoldersStream$: Observable<Action> = createEffect(() =>
    this.store.select(selectIsAuthenticated).pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => this.activityService.getFolderActivities()),
      withLatestFrom(this.sharedWorkspaceID$, this.referenceSequencesID$),
      mergeMap(([{ event }, sharedWorkspaceID, referenceDatabaseID]) => {
        if (event.kind === 'FolderDeleted') {
          return this.store.select(selectFolder(event.folderID)).pipe(
            take(1),
            filter((folderIsInStore) => !!folderIsInStore), // ignore folder if it's already been removed from the store
            map(() => deleteFolder({ id: event.folderID, fallbackFolderID: event.parentFolderID })),
          );
        }

        if (event.kind === 'FolderCreated') {
          return this.store.select(selectFolder(event.folderID)).pipe(
            take(1),
            filter((folderIsInStore) => !folderIsInStore), // ignore folder if it's already in the store
            switchMap(() => this.folderService.getFromServer(event.folderID)),
            map((folder) => createFolder({ folder })),
          );
        }

        // When a folder permission changed, Nucleus will emit FolderPermissionsChanged event for that folder
        // and all of its descendant. Since users can only change permission of top-level folder, we should
        // only handle this event for the top folder & fetch the permissions only once since the descendant
        // of that folder can just inherit the fetched permissions.
        if (
          event.kind === 'FolderPermissionsChanged' &&
          (event.parentFolderID === sharedWorkspaceID ||
            event.parentFolderID === referenceDatabaseID)
        ) {
          return of(
            fetchTopLevelFolderAccess({
              id: event.folderID,
            }),
          );
        }

        if (event.kind === 'FolderMoved') {
          return this.store.select(selectFolder(event.folderID)).pipe(
            take(1),
            map((folder) =>
              !folder
                ? // Fetch folder if a folder that was not existed in store get moved to the current folder tree
                  fetchFolderIfNeeded({ folderID: event.folderID })
                : // Move folder normally if it's already in store.
                  moveFolder({
                    id: event.folderID,
                    newParentID: event.parentFolderID,
                    newName: event.name,
                  }),
            ),
          );
        }

        if (event.kind === 'FolderMetadataChanged') {
          return of(updateFolderMetadata({ id: event.folderID, metadata: event.newMetadata }));
        }

        return EMPTY;
      }),
    ),
  );

  readonly watchFolders$: Observable<Action> = createEffect(
    () =>
      this.actions.pipe(
        ofType(watchFolders),
        tap((action) => this.activityService.subscribeToFoldersActivity(action.ids)),
      ),
    { dispatch: false },
  );

  readonly stopWatchingAllFolders$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(stopWatchingAllFolders),
        withLatestFrom(this.store.select(selectWatchingFolders)),
        tap(([_, watchingFolderIds]) => {
          this.activityService.unsubscribeToFoldersActivity(watchingFolderIds);
        }),
      ),
    { dispatch: false },
  );

  readonly stopWatchingFolders$: Observable<Action> = createEffect(
    () =>
      this.actions.pipe(
        ofType(stopWatchingFolders),
        tap((action) => this.activityService.unsubscribeToFoldersActivity(action.ids)),
      ),
    { dispatch: false },
  );

  readonly stopWatchingFolderChildren$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(stopWatchingFolderChildren),
      switchMap((action) => this.folderService.getAllChildrenIDs(action.id)),
      map((ids) => stopWatchingFolders({ ids })),
    ),
  );

  readonly fetchFolderIfNeeded$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(fetchFolderIfNeeded),
      switchMap((action) =>
        this.store
          .select(selectFolder(action.folderID))
          .pipe(map((folder) => ({ folder, action }))),
      ),
      filter(({ folder }) => !folder),
      switchMap(({ action }) => this.folderService.getFromServer(action.folderID)),
      map((folder) => createFolder({ folder })),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly folderService: FolderService,
    private readonly activityService: ActivityStreamService,
    private readonly expandService: ExpandService,
    private readonly store: Store<AppState>,
    private readonly navigationService: NavigationService,
  ) {}

  private getFolderIDsToWatch(
    folderID: string,
    includeCurrentFolder = false,
  ): Observable<string[]> {
    return this.folderService.getChildren(folderID).pipe(
      take(1),
      withLatestFrom(this.store.select(selectFolderExpandState(folderID)).pipe(take(1))),
      switchMap(([children, expanded]) => {
        if (expanded) {
          // Recursive!
          return forkJoin(children.map((child) => this.getFolderIDsToWatch(child.id, true))).pipe(
            defaultIfEmpty([] as string[][]),
            map((childrenFolders) => childrenFolders.flat()),
            map((folders) => {
              if (includeCurrentFolder) {
                return [...folders, folderID];
              }
              return folders;
            }),
          );
        }
        return of(includeCurrentFolder ? [folderID] : []);
      }),
    );
  }

  private isWithinExpandedParentFolder(folderID: string): Observable<boolean> {
    return this.folderService.getPathToRootFolder(folderID).pipe(
      switchMap((folders) =>
        forkJoin(folders.map((folder) => this.expandService.isExpanded(folder).pipe(take(1)))),
      ),
      map((expandStatuses) => expandStatuses.every((expanded) => expanded)),
    );
  }

  private getFolderAndAccessInfoFromServer(
    folderID: string,
  ): Observable<{ folder: FolderTreeItem; access: FolderAccessInfo }> {
    return this.folderService.getFromServer(folderID).pipe(
      switchMap((folder) => {
        if (PermissionsService.hasFullAccess(folder.permissions)) {
          return forkJoin([
            this.folderService.listFolderAccessControls(folderID),
            this.store.select(selectUserID).pipe(take(1)),
          ]).pipe(
            map(([acls, userID]) => {
              return {
                folder,
                access: {
                  shared: acls.entries.filter((acl) => acl?.principalID !== userID).length > 0,
                  permissions: folder.permissions as PrincipalPermissionsPermissions[],
                },
              };
            }),
          );
        }
        return of({
          folder,
          access: {
            shared: true, // If the user doesn't have full access then it must be a shared folder
            permissions: folder.permissions as PrincipalPermissionsPermissions[],
          },
        });
      }),
    );
  }
}

interface FolderAccessInfo {
  shared: boolean;
  permissions: PrincipalPermissionsPermissions[];
}
