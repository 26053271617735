import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { handleTokenValidateErrors } from '../token/token-validate-dialog-errors';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AuthService, ValidateTokenResponse } from '@geneious/nucleus-api-client';

@Injectable({
  providedIn: 'root',
})
export class AccountSetupGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const token = route.fragment;
    return this.authService.validateToken({ token }).pipe(
      pluck('data'),
      switchMap((tokenData) => {
        if (isSSOOnlyUser(tokenData)) {
          return this.setupSSOAccount(token);
        } else if (isPasswordResetToken(tokenData)) {
          return of(this.router.createUrlTree(['reset-password/change'], { fragment: token }));
        } else {
          return of(true);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return handleTokenValidateErrors(
          this.dialogService,
          this.router,
          this.config.geneiousProduct,
          error,
          'account-setup',
        );
      }),
    );
  }

  private setupSSOAccount(token: string): Observable<UrlTree> {
    return this.authService.consume({ token }).pipe(
      map(() => this.router.createUrlTree(['account-setup/complete'])),
      catchError(() => {
        this.dialogService.showAlertDialog({
          title: 'Token has expired',
          content: 'Your account may have already been setup',
          buttonText: 'Ok',
        });
        return of(this.router.createUrlTree(['']));
      }),
    );
  }
}

function isPasswordResetToken(tokenData: ValidateTokenResponse): boolean {
  return !tokenData.changeEmail && tokenData.changePassword;
}

function isSSOOnlyUser(tokenData: ValidateTokenResponse): boolean {
  return tokenData.changeEmail && !tokenData.changePassword;
}
