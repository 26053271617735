import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { GeneticCode, geneticCodes } from '../../geneticCodes.constant';
import { SequenceSelectionService } from '../../sequence-viewer/sequence-selection.service';
import { Observable, Subscription } from 'rxjs';
import { combineLatest, distinct, filter, first, map, share, startWith } from 'rxjs/operators';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  AnnotationAlignmentJobOptions,
  AnnotationAlignmentJobParameters,
} from '../../../../nucleus/services/models/AnnotationAlignmentJobParameters';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SequenceUtils } from '../../sequence-utils';
import { SequenceAlphabet } from '../../sequence-alphabet.model';
import {
  AlternativeStartEnum,
  AlternativeStartOption,
  considerStartOptions,
} from '../motif-annotator/motif-annotator.component';
import {
  AntibodyChainOptionChoices,
  ChainQualifierName,
} from '../../../../nucleus/services/models/alignmentOptions.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { CardComponent } from '../../../shared/card/card.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-annotation-alignment',
  templateUrl: './annotation-alignment.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgClass,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class AnnotationAlignmentComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  earlyRelease: false;

  readonly MAX_ITEMS_CHECKED = 200;

  public annotatedRegions$: Observable<{ name: string; numOfSequences: number }[]>;
  cdsSub: Subscription;
  regionsSub: Subscription;
  formSub: Subscription;
  wantsToAlignByCdsButHasNoCdsAnnotations$: Observable<boolean>;
  chains = AntibodyChainOptionChoices;

  title = 'Alignment by Annotations (Alpha)';
  knowledgeBaseArticle: string;

  // Public for Angular testing.
  public geneticCodes: GeneticCode[] = geneticCodes;
  public considerStartOptions: AlternativeStartOption[] = considerStartOptions;
  public form = new BxFormGroup({
    resultName: JobDialogContent.getResultNameControl(),
    outputFolderName: JobDialogContent.getResultNameControl(),
    inputIsNucleotides: new BxFormControl(true, Validators.required),
    alignCds: new BxFormControl(false),
    useCdsWithName: new FormControl<string>(undefined),
    anchorRegions: new FormControl<string[]>([], Validators.required),
    translation: new BxFormGroup({
      frame: new BxFormControl(0),
      geneticCode: new BxFormControl('Standard'),
      considerStart: new BxFormControl(AlternativeStartEnum.AUTO),
    }),
    filterByChain: new BxFormControl(null),
  });

  private readonly formDefaults: any;
  private readonly selected: SelectionState;
  private readonly otherVariables: any;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private sequenceSelectionService: SequenceSelectionService,
  ) {
    super('annotation-alignment', PipelineFormID.ANNOTATION_ALIGNMENT);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
    this.otherVariables = this.dialogData.otherVariables;
  }

  ngOnInit() {
    const annotations$ = this.retrieveAnnotations().pipe(share());
    this.annotatedRegions$ = annotations$.pipe(
      map((annotatedSequences) =>
        SequenceSelectionService.getAllAnnotationNamesForRegionChooser(annotatedSequences, null, [
          'gene',
          'Position',
        ]),
      ),
      share(),
    );

    const alignCdsControl = this.form.get('alignCds');
    const useCdsWithNameControl = this.form.get('useCdsWithName');
    const anchorRegionsControl = this.form.get('anchorRegions');
    const alignCdsControlValueChanges$ = alignCdsControl.valueChanges.pipe(
      startWith(alignCdsControl.value),
    );

    this.wantsToAlignByCdsButHasNoCdsAnnotations$ = this.annotatedRegions$.pipe(
      combineLatest(alignCdsControlValueChanges$),
      filter(([regions]) => !regions.length),
      map(([_, alignByCds]) => alignByCds),
    );

    // If Align CDS is true, then use cds with name should be required.
    alignCdsControlValueChanges$.subscribe((val: boolean) => {
      if (val) {
        useCdsWithNameControl.setValidators([Validators.required]);
      } else {
        useCdsWithNameControl.clearValidators();
      }

      useCdsWithNameControl.updateValueAndValidity();
    });

    this.regionsSub = this.annotatedRegions$
      .pipe(
        first(),
        filter((regions) => !!regions.length),
      )
      .subscribe((regions) => {
        useCdsWithNameControl.setValue(regions[0].name);
        anchorRegionsControl.setValue([regions[0].name]);
      });

    // Set initial view state before any changes have been made to the form by the user.
    const initialValue = this.form.getRawValue();
    this.updateViewState(initialValue);

    // Un-subscription is not necessary.
    this.formSub = this.form.valueChanges
      .pipe(distinct())
      // `getRawValue` gets values of disabled fields which we rely on.
      .subscribe((ignore) => this.updateViewState(this.form.getRawValue()));

    this.form.controls.inputIsNucleotides.patchValue(
      SequenceUtils.isSelectionOfType(SequenceAlphabet.NUCLEOTIDE, this.selected.selectedRows),
    );
  }

  ngOnDestroy() {
    if (this.regionsSub) {
      this.regionsSub.unsubscribe();
    }
    if (this.cdsSub) {
      this.cdsSub.unsubscribe();
    }
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  updateViewState(formValue: any) {
    const useCdsWithNameControl = this.form.controls['useCdsWithName'];
    const filterByChainControl = this.form.controls['filterByChain'];
    const isNucleotidesControl = this.form.controls['inputIsNucleotides'];
    const translationControls: BxFormGroup = this.form.controls['translation'] as BxFormGroup;
    const translationFrameControl = translationControls.controls['frame'];
    const translationGeneticCodeControl = translationControls.controls['geneticCode'];
    const translationConsiderStartControl = translationControls.controls['considerStart'];

    this.toggleEnabled(useCdsWithNameControl, formValue.alignCds);
    this.toggleEnabled(filterByChainControl, formValue.alignCds);

    this.toggleEnabled(translationGeneticCodeControl, formValue.inputIsNucleotides);
    this.toggleEnabled(translationConsiderStartControl, formValue.inputIsNucleotides);
    this.toggleEnabled(
      translationFrameControl,
      formValue.inputIsNucleotides && !formValue.alignCds,
    );

    if (this.otherVariables && this.otherVariables.isResult && !formValue.inputIsNucleotides) {
      // Override any previously saved form values. This will cause updateViewState() to be called again.
      isNucleotidesControl.setValue(true);
    }
  }

  /**
   * Convert the value from the Angular form to what the server expects.
   * Remove any Angular idiosyncrasies (for example that all values are set even when that doesn't make sense.
   *
   * - public for testing.
   */
  public static prepareJobOptions(
    alignmentForm: any,
    otherVariables: any,
  ): AnnotationAlignmentJobOptions {
    const result: AnnotationAlignmentJobOptions = {
      alignCds: alignmentForm.alignCds,
      anchorRegions: alignmentForm.anchorRegions,
      resultName: alignmentForm.resultName,
    };

    if (otherVariables) {
      if (otherVariables.extraction) {
        result.extraction = otherVariables.extraction;
      }
      if (otherVariables.sequenceMetadataOrder) {
        result.sequenceMetadataOrder = otherVariables.sequenceMetadataOrder;
      }
    }

    result.translation = {
      frame: alignmentForm.translation.frame,
      geneticCode: alignmentForm.translation.geneticCode,
      useAlternativeStartCodon: alignmentForm.translation.considerStart,
    };

    if (alignmentForm.alignCds) {
      result.cdsName = alignmentForm.useCdsWithName;
    }

    if (alignmentForm.filterByChain && alignmentForm.filterByChain !== 'null') {
      result.region = {
        qualifierName: ChainQualifierName,
        qualifierValue: alignmentForm.filterByChain,
      };
    }

    return result;
  }

  // TODO Don't use IGridResource here - refactor so that it is not exposed to viewers or dialogs.
  retrieveAnnotations(): Observable<any[]> {
    if (this.otherVariables.getSequences) {
      return this.otherVariables.getSequences();
    } else {
      // Limit the amount of sequence data queries made - we only need a representative list of CDS annotations.
      // Sequence lists will not be checked for annotations as we currently do not have sequence lists that contain annotations (?).
      // TODO Check CDS annotations in sequence lists, when we have a concrete use case for this.

      const filterFunc = (item: any) => item.type === 'sequence' || item.type === 'sequenceList';

      return this.sequenceSelectionService.fetchSequenceData(
        this.selected.selectedRows,
        this.MAX_ITEMS_CHECKED,
        filterFunc,
      );
    }
  }

  /**
   * Called by the pipeline chooser.
   */
  run() {
    // `getRawValue` gets values of disabled fields which we rely on.
    const formValue = this.form.getRawValue();
    const outputFolderName = formValue.outputFolderName;
    const parameters = {
      options: AnnotationAlignmentComponent.prepareJobOptions(formValue, this.otherVariables),
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };
    return new AnnotationAlignmentJobParameters(parameters);
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  private toggleEnabled(control: AbstractControl, enabled: boolean) {
    const action = enabled ? 'enable' : 'disable';
    control[action]({
      // Hack to make `emitEvent` work, otherwise we get `Maximum call stack size exceeded`.
      onlySelf: true,
      // Prevent infinite loop.
      emitEvent: false,
    });
  }
}
