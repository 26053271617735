import { Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { AbstractControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  SetMergePairedReadsJobOptionsV1_1,
  SetMergePairedReadsOptionsModelV1_1,
} from '../../../../nucleus/services/models/setMergePairedReadsOptions.model';
import { distinct } from 'rxjs/operators';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { CardComponent } from '../../../shared/card/card.component';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-set-merge-paired-reads',
  templateUrl: './set-merge-paired-reads.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CardComponent, NgbTooltip, PipelineOutputComponent],
})
export class SetMergePairedReadsComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  earlyRelease: false;
  title = 'Set & Merge Paired Reads';
  knowledgeBaseArticle: string;
  selectedListsCount = 0;
  selectedSingleSequencesCount = 0;
  selectedListsSequenceCounts: Array<number> = [];
  disablePairsOfLists: boolean;
  disableInterlacingSequences: boolean;

  insertImage: string;

  public form = new BxFormGroup({
    pairBy: new BxFormControl('bySequenceList', Validators.required),
    mergeRate: new BxFormControl('normal'),
    relativeOrientation: new BxFormControl('forwardReverse'),
    expectedDistance: new BxFormControl(200, [Validators.min(1), Validators.max(2147483647)]),
    dataType: new BxFormControl('Illumina'),
    pairedType: new BxFormControl('Paired End'),
    isDoMerge: new BxFormControl(true),
    iterativelyTrim: new BxFormControl(false),
    outputFolderName: JobDialogContent.getResultNameControl(),
  });

  private formDefaults: any;
  private readonly selected: SelectionState;

  constructor(@Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData) {
    super('paired-reads', PipelineFormID.MERGE_PAIRED_READS);
    this.selected = this.dialogData.selected;
    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit() {
    this.selectedListsCount = this.selected.selectedRows.reduce(
      (accumulator, doc) => accumulator + (doc.type === 'sequenceList' ? 1 : 0),
      0,
    );
    this.selectedSingleSequencesCount = this.selected.selectedRows.reduce(
      (accumulator, doc) => accumulator + (doc.type === 'sequence' ? 1 : 0),
      0,
    );
    this.selected.selectedRows.forEach((doc) => {
      if (doc.type === 'sequenceList') {
        this.selectedListsSequenceCounts.push(Number(doc.number_of_sequences));
      }
    });

    this.disablePairsOfLists = this.pairsOfListsDisabled;
    this.disableInterlacingSequences = this.interlacingSequencesDisabled;
    this.setPairByDefault();

    this.updateViewState(this.form.getRawValue());

    // Un-subscription is not necessary.
    this.form.valueChanges
      .pipe(distinct())
      // `getRawValue` gets values of disabled fields which we rely on.
      .subscribe((ignore) => this.updateViewState(this.form.getRawValue()));
  }

  /**
   * Called by the pipeline chooser.
   */
  run() {
    // @see https://stackoverflow.com/questions/40148102/angular-2-disabled-controls-do-not-get-included-in-the-form-value
    const formValue = this.form.getRawValue();

    const parameters = {
      options: SetMergePairedReadsComponent.prepareJobOptions(formValue),
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: formValue.outputFolderName,
      },
    };
    return new SetMergePairedReadsOptionsModelV1_1(parameters);
  }

  updateViewState(formValue: any) {
    const expectedDistanceControl = this.form.controls['expectedDistance'];
    const dataTypeControl = this.form.controls['dataType'];
    const pairedTypeControl = this.form.controls['pairedType'];
    const mergeRateControl = this.form.get('mergeRate');
    const iterativelyTrimControl = this.form.get('iterativelyTrim');

    // There are cases where the user might want these options set such as if the set/merge operation outputs un-merged paired reads,
    // but this is an edge case, so probably having those options enabled for set&merge is more confusing than it is worth.
    // We will want to re-enable the options if we ever decide to
    // output the intermediate documents as well (the set, un-merged paired reads)
    // e.g. The options are useful for if you run de novo assembly on the output of the set paired reads operation.
    this.toggleEnabled(expectedDistanceControl, !formValue.isDoMerge);
    this.toggleEnabled(dataTypeControl, !formValue.isDoMerge);
    this.toggleEnabled(pairedTypeControl, !formValue.isDoMerge);
    this.toggleEnabled(mergeRateControl, formValue.isDoMerge);
    this.toggleEnabled(iterativelyTrimControl, formValue.isDoMerge);

    const image = SetMergePairedReadsComponent.calculateInsertImage(formValue.relativeOrientation);
    this.insertImage = `../../../assets/img/paired-reads/${image}`;
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  // @see pairsOfListsDisabled() in pair-chains.component.ts.
  get pairsOfListsDisabled() {
    const numListsOdd = this.selectedListsCount % 2 !== 0;
    // BX doesn't parse sequence list names selected to determine pairs. Only perform sequence number validation if 2 lists are selected.
    const unequalListsSequenceCounts =
      this.selectedListsSequenceCounts.length === 2 &&
      this.selectedListsSequenceCounts[0] !== this.selectedListsSequenceCounts[1];

    // Must return null to enable.
    // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
    return numListsOdd || this.selectedSingleSequencesCount > 0 || unequalListsSequenceCounts
      ? true
      : null;
  }

  // @see interlacingSequencesDisabled() in pair-chains.component.ts.
  get interlacingSequencesDisabled() {
    const sequenceCountInListOdd = this.selectedListsSequenceCounts.some(
      (count) => count % 2 !== 0,
    );

    // Must return null to enable.
    // @see https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for
    return this.selectedSingleSequencesCount > 0 || sequenceCountInListOdd ? true : null;
  }

  setPairByDefault() {
    if (this.disablePairsOfLists && this.form.get('pairBy').value === 'bySequenceList') {
      this.form.get('pairBy').setValue('interlaced');
    }

    if (this.disableInterlacingSequences && this.form.get('pairBy').value === 'interlaced') {
      this.form.get('pairBy').setValue('byName');
    }
  }

  static calculateInsertImage(
    relativeOrientation:
      | 'forwardForward'
      | 'forwardForwardInvertOrder'
      | 'forwardReverse'
      | 'reverseForward',
  ) {
    switch (relativeOrientation) {
      case 'forwardForward':
        return 'PairedEnds_fwd_fwd.png';
      case 'forwardForwardInvertOrder':
        return 'PairedEnds_fwd_fwd.png';
      case 'forwardReverse':
        return 'PairedEnds_fwd_rev.png';
      case 'reverseForward':
        return 'PairedEnds_rev_fwd.png';
    }
  }

  private toggleEnabled(control: AbstractControl, enabled: boolean) {
    const action = enabled ? 'enable' : 'disable';
    control[action]({
      // Hack to make `emitEvent` work, otherwise we get `Maximum call stack size exceeded`.
      onlySelf: true,
      // Prevent infinite loop.
      emitEvent: false,
    });
  }

  static prepareJobOptions(formValue: any): SetMergePairedReadsJobOptionsV1_1 {
    return {
      pairBy: formValue.pairBy,
      relativeOrientation: formValue.relativeOrientation,
      mergeRate: formValue.mergeRate,
      expectedDistance: formValue.expectedDistance,
      dataType: formValue.dataType,
      pairedType: formValue.pairedType,
      isDoMerge: formValue.isDoMerge,
      iterativelyTrim: formValue.iterativelyTrim,
    };
  }
}
