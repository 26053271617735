import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'bx-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss'],
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacyButtonModule],
})
export class FileChooserComponent {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('fileNameInput', { static: true }) fileNameInput: ElementRef;

  @Output() fileChosen = new EventEmitter<string>();

  fileName: string;

  onChangeUpload(fakeFilePath: string) {
    this.fileName = '';
    this.fileName = FileChooserComponent.trimExt(FileChooserComponent.trimFakePath(fakeFilePath));
    this.fileNameInput.nativeElement.value = this.fileName;
    this.fileChosen.emit(this.fileName);
  }

  onChangeTextInput(name: string) {
    this.fileName = name;
    this.fileChosen.emit(this.fileName);
  }

  showFileChooser() {
    this.fileInput.nativeElement.click();
  }

  static trimFakePath(filePath: string): string {
    const fakePathPrefix = 'C:\\fakepath\\'; // Browsers give a fake path. Use this to strip out the file name
    if (filePath.indexOf(fakePathPrefix) !== -1) {
      return filePath.substr(fakePathPrefix.length);
    } else {
      return filePath;
    }
  }

  static trimExt(fileNameWithExtension: string): string {
    const dotIndex: number = fileNameWithExtension.lastIndexOf('.');
    if (dotIndex !== -1) {
      return fileNameWithExtension.substr(0, dotIndex);
    } else {
      return fileNameWithExtension;
    }
  }
}
