import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable, firstValueFrom, map, shareReplay, startWith, takeUntil, tap } from 'rxjs';
import { SelectOption } from 'src/app/core/models/ui/select-option.model';
import { Chip } from '../../chips';
import { POPOVER_DATA } from '../../chips/chip-add-button/chip-add-button.component';
import { ChipsService } from '../../chips/chips.service';
import { CleanUp } from '../../cleanup';
import { currentValueAndChanges } from '../../utils/forms';
import {
  FieldMapper,
  FieldMatchKind,
  FieldReducerKind,
  FieldValueType,
} from '../field-mapper.model';
import { FieldMapperService } from '../field-mapper.service';
import { NgFormControlValidatorDirective } from '../../form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../form-errors/form-errors.component';

@Component({
  selector: 'bx-field-mapper-add-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
  ],
  templateUrl: './field-mapper-add-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldMapperAddFormComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block bx-popover-fs-base';
  readonly form = new FormGroup(
    {
      field: new FormControl<string>(undefined, Validators.required),
      fieldValueType: new FormControl<FieldValueType>('any'),
      reducerKind: new FormControl<FieldReducerKind>(FieldReducerKind.MODE, Validators.required),
    },
    [],
    (ctrl) => this.uniqueChip(ctrl),
  );
  readonly valueTypeOptions: SelectOption<FieldValueType>[];
  readonly fields: SelectOption[];
  readonly chipIDs$: Observable<string[]>;
  readonly trackByValue: TrackByFunction<SelectOption> = (_i, option) => option.value;
  reducerOptions$: Observable<SelectOption<FieldReducerKind>[]>;

  constructor(
    private readonly chipsService: ChipsService<FieldMapper>,
    private readonly ngbPopover: NgbPopover,
    private readonly fieldMapperService: FieldMapperService,
    /** Data object containing field codes (with BX_ prefixes) */
    @Inject(POPOVER_DATA) data: { fields: string[] },
  ) {
    super();
    this.valueTypeOptions = [
      { displayName: 'Any', value: 'any' },
      { displayName: 'Numeric', value: 'number' },
      { displayName: 'Categorical', value: 'string' },
    ];
    this.fields = data.fields.map((field) => ({
      value: field,
      displayName: this.fieldMapperService.getFieldDisplayName(field),
    }));
    this.chipIDs$ = this.chipsService.chipsMap$.pipe(
      map((chipsMap) => Object.keys(chipsMap)),
      startWith([]),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    this.reducerOptions$ = currentValueAndChanges(
      this.form.controls.fieldValueType,
      this.ngUnsubscribe,
    ).pipe(
      map((fieldType) => this.fieldMapperService.getReducerOptions(fieldType)),
      tap((reducerOptions) => {
        const currentReducer = this.form.controls.reducerKind.value;
        if (!reducerOptions.some((option) => option.value === currentReducer)) {
          this.form.controls.reducerKind.setValue(reducerOptions[0].value);
        }
      }),
    );
  }

  /** An async validator that returns an error if the chip to create already exists */
  private async uniqueChip(control: AbstractControl): Promise<ValidationErrors | null> {
    const formValue: ReturnType<(typeof this.form)['getRawValue']> = control.getRawValue();
    if (!formValue || !formValue.field || !formValue.reducerKind) {
      // This should be caught by the control validators
      return null;
    }
    const chip = this.createChip(formValue.reducerKind, formValue.field);
    const existingChipIDs = await firstValueFrom(this.chipIDs$);
    if (existingChipIDs.includes(chip.id)) {
      return { duplicateChip: 'This field has already been added' };
    }
    return null;
  }

  private createChip(reducerKind: FieldReducerKind, field: string): Chip<FieldMapper> {
    const mapper: FieldMapper = {
      reducer: { reducerKind },
      matchers: [{ matchKind: FieldMatchKind.CODE_EQUALS, pattern: field }],
    };
    const label = this.fieldMapperService.getLabel(mapper);
    return { label, id: label, value: mapper };
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAsDirty();
      return;
    }
    const { reducerKind, field } = this.form.getRawValue();
    const chip = this.createChip(reducerKind, field);
    this.chipsService.addChips([chip]);
    this.ngbPopover.close();
  }
}
