@if (icon) {
  <button [class]="btnClass" (click)="forward()" [disabled]="isDisabled">
    <input class="d-none" type="file" file-read />
  </button>
}
@if (!icon) {
  <button [class]="btnClass" (click)="forward()" [disabled]="isDisabled">
    <input
      class="d-none"
      [id]="inputID"
      type="file"
      bxFileRead
      [accept]="fileTypes"
      (filesRead)="onFilesRead($event)"
      [multiple]="multiple"
    />{{ message }}
  </button>
}
