import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { FolderService } from '../../folders/folder.service';
import {
  Folder,
  folderKinds,
  FolderTreeIcon,
  FolderTreeItem,
} from '../../folders/models/folder.model';
import { NewFolderState } from '../../folders/store/expand.reducer';
import { ExpandService } from '../../folders/store/expand.service';
import { FolderTreeIconComponent } from '../folder-tree-icon/folder-tree-icon.component';
import { AutofocusDirective } from '../../../shared/autofocus.directive';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-new-folder',
  templateUrl: './new-folder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FolderTreeIconComponent,
    FormsModule,
    AutofocusDirective,
    ReactiveFormsModule,
    NgClass,
    NgbTooltip,
  ],
})
export class NewFolderComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex align-items-center';
  @Input() parent: Folder;
  @Input() state: NewFolderState;

  readonly nameControl = new FormControl<string>(undefined);

  creating = false;
  error?: string;
  icon: FolderTreeIcon;

  @Output() done = new EventEmitter();

  constructor(
    private folderService: FolderService,
    private expandService: ExpandService,
  ) {
    super();
  }

  ngOnInit() {
    this.icon = new folderKinds[this.state.kind]().getIcon();
    this.error = this.state.error;

    this.nameControl.setValue(this.state.name);
    // Send form state to the store
    currentValueAndChanges(this.nameControl, this.ngUnsubscribe)
      .pipe(filter((name) => name != null))
      .subscribe((name) => this.expandService.updateNewFolderName(name));
  }

  onSubmit() {
    const newName = this.nameControl.value?.trim();
    // If no text has been entered then assume the user wants to quit making a new folder.
    if (!newName) {
      this.done.emit();
    }
    this.creating = true;

    this.folderService.create(this.parent.id, newName, this.state.kind).subscribe({
      next: (folder: FolderTreeItem) => {
        // Send the new folder up so that the folder-tree component (or other consumer) can do what they like with
        // it, such as selecting it.
        this.done.emit(folder);
        // We're all done, so let the consumer know (creating is public).
        this.creating = false;
      },
      error: (response) => {
        this.error = this.validError(response);
        this.expandService.setNewFolderError(this.error);
        this.creating = false;
      },
    });
  }

  onBlur() {
    if (!this.creating) {
      this.onSubmit();
    }
  }

  private validError(httpResponse: any) {
    // TODO use nice client side errors which map from error.code; the Nucleus errors aren't very user friendly.
    const error = httpResponse && httpResponse.error && httpResponse.error.error;
    return error ? error.message : 'Failed to create folder. Try another name.';
  }
}
