import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toasts: Toast[] = [];
  private toasts$: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  getToasts(): Observable<Toast[]> {
    return this.toasts$.asObservable();
  }

  show(content: ToastContent, type: ToastType, options: ToastOptions = {}) {
    this.toasts.push({ content, type, ...options });
    this.toasts$.next(this.toasts);
  }

  danger(content: string, options: ToastOptions = {}) {
    this.show(content, 'danger', options);
  }

  success(content: string, options: ToastOptions = {}) {
    this.show(content, 'success', options);
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toasts$.next(this.toasts);
  }

  /**
   * Removes all toasts.
   */
  removeAll(): void {
    this.toasts = [];
    this.toasts$.next(this.toasts);
  }
}

type ToastContent = string | TemplateRef<any>;

export interface Toast extends ToastOptions {
  content: ToastContent;
  type: ToastType;
}

interface ToastOptions {
  className?: string;
  delay?: number;
  header?: string;
  autohide?: boolean;
  dismissible?: boolean;
}

type ToastType = 'default' | 'success' | 'danger';
