import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { SimulationLinkDatum, SimulationNodeDatum, ZoomTransform } from 'd3';
import { ExcludeMethods, TypedChange, TypedChanges } from '../../../shared/utils/types';
import { D3GraphCanvas } from '../d3-graphs-shared/d3-graph-canvas';
import {
  D3ColorFunction,
  defaultGraphCircularTreeConfig,
  GraphCircularTreeConfig,
  TreeGraphColoringMetadataConfig,
} from '../graph-circular-tree/graph-circular-tree.model';
import { ColorPaletteService } from '../../../core/color/color-palette.service';
import { ExportableChart } from '../exportable-chart';
import { GraphCircularTreeLegend } from '../graph-circular-tree/legend/graph-circular-tree-legend';
import { NgsZoomService } from '../../../core/ngs/ngs-graphs/graph-zoom/ngs-zoom.service';
import {
  AbsoluteDimensions,
  GraphZoomContainerComponent,
} from '../graph-zoom-container/graph-zoom-container.component';

export type NetworkData = { nodes: NodeType[]; links: LinkType[] };
export interface NetworkGraphNodeConfig {
  metadataColouringConfig: TreeGraphColoringMetadataConfig;
  sizeFieldName: string;
  sizeAreaTransform: 'linear' | 'log2' | 'balanced' | 'fixed';
}
export interface NodeType extends SimulationNodeDatum {
  id: string;
  group: string | number;
  size: number;
  name: string;
}
export interface LinkType<T extends NodeType | string = NodeType | string>
  extends SimulationLinkDatum<NodeType> {
  source: T;
  target: T;
  value: number;
}
@Component({
  selector: 'bx-graph-network',
  templateUrl: './graph-network.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GraphZoomContainerComponent],
})
export class GraphNetworkComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, ExportableChart
{
  @Input() data: NetworkData;
  @Input() nodeConfig: NetworkGraphNodeConfig;
  @Input() colourNodes: boolean;
  @Input() freezeLayout: boolean;
  @Input() showLegend: boolean;
  @HostBinding('class') readonly hostClass = 'w-100 d-flex flex-grow-1 flex-shrink-1';
  @ViewChild(GraphZoomContainerComponent, { static: true })
  zoomContainer: GraphZoomContainerComponent;

  ZOOM_MIN = 4 / 5;
  ZOOM_MAX = 10;
  private node: d3.Selection<SVGCircleElement, NodeType, SVGGElement, undefined>;
  private link: d3.Selection<SVGLineElement, LinkType, SVGGElement, undefined>;
  private svg: d3.Selection<SVGSVGElement, undefined, null, undefined>;
  private simulation: d3.Simulation<NodeType, LinkType>;
  // lifting the tooltip stuff from the circular tree
  private graphTooltipContainer: d3.Selection<SVGForeignObjectElement, undefined, null, undefined>;
  // just for legend
  private readonly configForLegend: GraphCircularTreeConfig = defaultGraphCircularTreeConfig();
  private canvas: D3GraphCanvas;
  private legend: GraphCircularTreeLegend;
  private nodeColorFn: D3ColorFunction;
  private nodeColorGroupsAppearanceCount: Map<string | number, number> = new Map();
  private hasZoomed: boolean = false;
  constructor(
    private readonly colorPaletteService: ColorPaletteService,
    private readonly ngsZoomService: NgsZoomService,
  ) {}

  ngOnInit(): void {
    this.canvas = new D3GraphCanvas(12);
    this.legend = new GraphCircularTreeLegend(this.configForLegend, this.canvas);
  }

  ngAfterViewInit(): void {
    this.renderGraph();
    new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        const rect = this.zoomContainer.getContainerRect();
        if (rect.width > 0 && rect.height > 0) {
          this.handleResize();
        }
      });
    }).observe(this.zoomContainer.getContainer().nativeElement);
  }

  ngOnDestroy(): void {
    this.canvas.destroy();
    this.svg?.node()?.remove();
  }

  ngOnChanges(changes: TypedChanges<ExcludeMethods<GraphNetworkComponent>>) {
    if (changes.data?.firstChange) {
      return;
    }
    const dataChange = this.hasSubsequentChange(changes.data);
    const nodeSizeTypeUpdate =
      this.hasPropertyChange(changes.nodeConfig, (data) => data?.sizeAreaTransform) ||
      this.hasPropertyChange(changes.nodeConfig, (data) => data?.sizeFieldName);
    if (dataChange || nodeSizeTypeUpdate) {
      this.renderGraph(true);
    }

    const colourNodeChange = this.hasSubsequentChange(changes.colourNodes);
    const nodeConfigChange = this.hasSubsequentChange(changes.nodeConfig);
    if (colourNodeChange || nodeConfigChange) {
      this.updateNodeColour();
      this.addTooltips();
    }

    const legendChange = colourNodeChange || nodeConfigChange;
    if (legendChange) {
      this.updateNodeColour();
      this.renderLegend();
    }

    if (legendChange || changes.showLegend) {
      this.updateLegendVisibility();
      this.calculateGraphSize();
    }
  }

  resize() {}

  private handleResize() {
    this.calculateGraphSize();
    const { scaleX, scaleY } = this.zoomContainer.getAbsoluteDimensionsForFit();
    if (
      scaleX > this.zoomContainer.getZoomLevelValue() ||
      scaleY > this.zoomContainer.getZoomLevelValue()
    ) {
      this.zoomContainer.zoomToFit();
    }
    const { x, y, k } = this.zoomContainer.zoomTransform;
    this.zoomContainer.setScrollState('zoom');
    this.zoomContainer.setScrollBarsToZoomPosition(x, y, k, false);
    this.zoomContainer.setScrollState('idle');
  }

  private calculateGraphSize() {
    const { width, height } = this.zoomContainer.getDimensionsForZoom();
    this.svg.attr('width', width).attr('height', height).attr('viewBox', [0, 0, width, height]);
    this.zoomContainer.updateAbsoluteDimensions();
  }

  private renderLegend() {
    const groupsByAppearanceCount = [...this.nodeColorGroupsAppearanceCount.keys()].sort(
      (groupA, groupB) =>
        this.nodeColorGroupsAppearanceCount.get(groupB) -
        this.nodeColorGroupsAppearanceCount.get(groupA),
    );
    this.legend.setLegendType(
      this.nodeConfig?.metadataColouringConfig?.isCategorical ? 'categorical' : 'continuous',
    );
    this.legend.render(
      this.nodeConfig?.metadataColouringConfig?.name,
      groupsByAppearanceCount,
      this.nodeColorFn,
    );
  }

  /**
   * Show or hide legend when graph updates
   */
  private updateLegendVisibility() {
    this.legend.visible = this.showLegend;
  }

  private detectNodeGroup(nodeID: string): string | number | null {
    return this.nodeConfig.metadataColouringConfig.values.get(nodeID)?.group ?? null;
  }

  private updateNodeColour() {
    if (this.colourNodes) {
      this.nodeColorGroupsAppearanceCount.clear();
      this.data.nodes.forEach((node) => {
        const group = this.detectNodeGroup(node.id);
        node.group = group;
        this.nodeColorGroupsAppearanceCount.set(
          group,
          1 + (this.nodeColorGroupsAppearanceCount.get(group) ?? 0),
        );
      });
      const groups = Array.from(this.nodeColorGroupsAppearanceCount.keys());
      this.nodeColorFn = this.colorPaletteService.getColorFunction(
        this.nodeConfig.metadataColouringConfig.colorPalette,
        groups,
        this.nodeConfig.metadataColouringConfig.isCategorical,
      );
      this.node.attr('fill', (d: any) => this.nodeColorFn(d.group));
    } else {
      this.node.attr('fill', '#0032ff');
    }
  }
  renderGraph(reload = false) {
    this.hasZoomed = false;
    let hasZoomedToFitBeforeSimulation = false;
    let hasZoomedToFitAfterSimulation = false;
    const { nodes, links } = this.data;

    // Set the position attributes of links and nodes each time the simulation ticks.
    const self = this;

    const ticked = function (this: d3.Simulation<NodeType, LinkType>) {
      // we don't need to render every iteration: one in ten will do.
      for (let i = 0; i < 10; i++) {
        this.tick();
      }
      // update the actual positions of the displayed links and nodes based on the simulation so far
      self.link
        .attr('x1', (d) => (isNodeObject(d.source) ? d.source.x : 0))
        .attr('y1', (d) => (isNodeObject(d.source) ? d.source.y : 0))
        .attr('x2', (d) => (isNodeObject(d.target) ? d.target.x : 0))
        .attr('y2', (d) => (isNodeObject(d.target) ? d.target.y : 0));

      self.node.attr('cx', (d: any) => d.x).attr('cy', (d: any) => d.y);
      if (!hasZoomedToFitBeforeSimulation) {
        self.zoomContainer.zoomToFit();
        hasZoomedToFitBeforeSimulation = true;
      }
    };

    if (reload) {
      this.svg?.node().remove();
      this.legend?.nativeElement.remove();
      this.legend = new GraphCircularTreeLegend(
        this.configForLegend,
        this.canvas,
        this.nodeConfig.metadataColouringConfig.isCategorical ? 'categorical' : 'continuous',
      );
    }
    this.ngsZoomService.registerZoomControls(this.zoomContainer);
    this.svg = d3.create('svg').style('position', 'absolute').style('flex', '0 0 auto');
    this.zoomContainer.setSVG(this.svg);
    this.zoomContainer.setDimensions(
      (container) => new NetworkDimensions(container, this.data, this.svg),
    );
    this.calculateGraphSize();
    const { width, height } = this.zoomContainer.getDimensionsForZoom();
    const tree = createTreeLayout(nodes, links, width);
    const nodesById: Record<string, NodeType> = {};
    nodes.forEach((node) => {
      const thisNode = tree.find((d) => d.id === node.id);
      if (!!thisNode) {
        nodesById[thisNode.id] = node;
        node.x = thisNode.y * Math.cos(thisNode.x);
        node.y = thisNode.y * Math.sin(thisNode.x);
      }
    });
    this.simulation = d3
      .forceSimulation<NodeType, LinkType>(nodes)
      .alpha(0.3)
      .alphaDecay(0.0025)
      .alphaMin(0.1)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d) => (d as NodeType).id)
          .distance(
            (l) =>
              10 * (1 - l.value) +
              this.transformSize((isNodeObject(l.source) ? l.source : nodesById[l.source]).size) +
              this.transformSize((isNodeObject(l.target) ? l.target : nodesById[l.target]).size),
          )
          .strength(2.2)
          .iterations(2),
      )
      .force('charge', d3.forceManyBody<NodeType>().strength(-10))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX())
      .force('y', d3.forceY())
      .force(
        'collide',
        d3.forceCollide((d: NodeType) => this.transformSize(d.size) + 1).iterations(2),
      )
      .on('tick', ticked)
      .on('end', () => {
        if (!hasZoomedToFitAfterSimulation) {
          this.calculateGraphSize();
          this.hasZoomed ? this.zoomContainer.updateZoomScales() : this.zoomContainer.zoomToFit();
          hasZoomedToFitAfterSimulation = true;
        }
      });
    this.zoomContainer.setZoomBehaviour(this.createZoomBehaviour());
    this.svg.call(this.zoomContainer.getZoom());
    const gLink = this.svg.append('g');
    const gNode = this.svg.append('g');
    const tooltipContainer = this.svg.append('foreignObject');
    this.link = gLink
      .attr('stroke', '#999')
      .attr('stroke-opacity', 1)
      .selectAll()
      .data(links)
      .join('line')
      .attr('stroke-width', 1 / this.zoomContainer.zoomTransform.k)
      .on('mouseover', function (_) {
        const line = this as SVGLineElement;
        d3.select(line).attr('stroke', '#ff0000');
      })
      .on('mouseout', function (_) {
        const line = this as SVGLineElement;
        d3.select(line).attr('stroke', '#999');
      });
    const dims = this.zoomContainer.getAbsoluteDimensionsForFit();
    const scale = dims?.scale ?? 1;
    this.node = gNode
      .attr('stroke', '#fff')
      .attr('stroke-width', 0.5 / Math.max(this.zoomContainer.zoomTransform.k / scale, 1))
      .selectAll()
      .data(nodes)
      .join('circle')
      .attr('r', (d) =>
        this.scaleNodeRadiusAfterZoom(d.size, this.zoomContainer.zoomTransform.k, scale),
      )
      .attr('fill', '#0032ff')
      .on('mouseover', (_, d) => {
        this.link.attr('stroke', (l) => {
          if (
            (isNodeObject(l.source) && d.id === l.source?.id) ||
            (isNodeObject(l.target) && d.id === l.target?.id)
          ) {
            return '#ff0000';
          } else {
            return '#999';
          }
        });
      })
      .on('mouseout', () => {
        this.link.attr('stroke', '#999');
      });
    this.zoomContainer.zoomToFit();
    this.zoomContainer.setScrollEventListeners(false);
    this.zoomContainer.setKeyEventListeners();
    const style = `
      .graph-circular-tree-tooltip {
        background: white;
        position: absolute;
        border: 1px solid black;
        border-radius: 2px;
        visibility: hidden;
        font-size: 12px;
        padding: 2px 5px;
        max-width: 500px;
        overflow: visible;
        word-break: break-all;
        word-wrap: break-word;
        z-index: 1000;
      }
    `;
    this.svg.append('style').text(style);
    this.graphTooltipContainer = tooltipContainer
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('pointer-events', 'none');
    this.addTooltips();

    // Add a drag behavior.
    this.node.call(
      d3
        .drag<SVGCircleElement, NodeType, any>()
        .on('start', function (event) {
          event.sourceEvent.stopPropagation();
          if (self.freezeLayout) return;
          event.subject.fx = event.subject.x;
          event.subject.fy = event.subject.y;
        })
        .on('drag', function (event) {
          event.sourceEvent.stopPropagation();
          if (self.freezeLayout) return;
          if (
            self.simulation.alpha() <=
            Math.max(self.simulation.alphaTarget(), self.simulation.alphaMin())
          ) {
            self.simulation.alphaTarget(0.3).alpha(0.35).alphaDecay(0.01).restart();
          }
          const { k } = self.zoomContainer.zoomTransform;
          event.subject.fx += event.dx / k;
          event.subject.fy += event.dy / k;
        })
        .on('end', function (event) {
          if (self.freezeLayout) return;
          if (!event.active) {
            self.simulation.alphaTarget(0);
          }
          event.subject.fx = null;
          event.subject.fy = null;
          self.zoomContainer.updateZoomScales();
        }),
    );

    this.updateLegendVisibility();
    this.updateNodeColour();
    this.renderLegend();
    this.zoomContainer.getContainer().nativeElement.append(this.svg.node());
    this.zoomContainer.getContainer().nativeElement.append(this.legend.nativeElement);
    this.calculateGraphSize();
  }

  private hasSubsequentChange(change?: TypedChange<any>): boolean {
    return change != null && !change.firstChange;
  }

  private hasPropertyChange<T>(
    change: TypedChange<T> | undefined,
    getProperty: (value?: T) => unknown,
  ) {
    return change != null && getProperty(change.currentValue) !== getProperty(change.previousValue);
  }

  private addTooltips() {
    this.node.each((d: NodeType & { tooltip?: string }) => {
      const tooltipLines: string[] = [];
      if (d.id?.length > 0) {
        tooltipLines.push(`ID:\n\t${d.id}`);
      }
      if (d.name?.length > 0) {
        tooltipLines.push(`Sequence:\n\t${d.name}`);
      }
      if (d.size) {
        tooltipLines.push(`${this.nodeConfig.sizeFieldName}:\n\t${d.size}`);
      }
      if (
        this.colourNodes &&
        this.nodeConfig.metadataColouringConfig.name &&
        this.nodeConfig.metadataColouringConfig.name !== 'ID' &&
        this.nodeConfig.metadataColouringConfig.name !== 'Sequence' &&
        this.nodeConfig.metadataColouringConfig.name !== this.nodeConfig.sizeFieldName
      ) {
        const metadataValue =
          this.nodeConfig.metadataColouringConfig.values.get(d.id).value ?? 'Unknown';
        tooltipLines.push(`${this.nodeConfig.metadataColouringConfig.name}:\n\t${metadataValue}`);
      }
      d.tooltip = tooltipLines.join('\n');
    });
    this.link.each((d: LinkType & { tooltip?: string }) => {
      const tooltipLines: string[] = [];
      const { source, target } = getIds(d);
      tooltipLines.push(`${source} – ${target}`);
      tooltipLines.push(`Similarity:\n\t${d.value}`);
      d.tooltip = tooltipLines.join('\n');
    });

    const tooltip = this.graphTooltipContainer
      .append('xhtml:div')
      .attr('class', 'graph-circular-tree-tooltip');
    const show = (text: string, x: number, y: number) => {
      const mouseOffsetX = 10;
      const mouseOffsetY = 10;

      const viewBox = this.zoomContainer.getContainerRect();
      let usedX = x;
      let usedY = y;
      const tooltipBox = (tooltip.node() as HTMLDivElement).getBoundingClientRect();
      if (usedX > viewBox.width - tooltipBox.width) {
        usedX = viewBox.width - tooltipBox.width - mouseOffsetX;
      }

      if (usedY > viewBox.height - tooltipBox.height) {
        usedY = viewBox.height - tooltipBox.height - mouseOffsetY;
      }

      tooltip
        .text(text)
        .style('white-space', 'pre-wrap')
        .style('visibility', 'visible')
        .style('transform', `translate(${usedX}px, ${usedY}px)`);
    };

    const hide = () => {
      tooltip.style('visibility', 'hidden');
    };

    this.node
      .on('mousemove.tooltip', (_, d: any) => {
        if (d.tooltip) {
          let transform = this.zoomContainer.zoomTransform;
          show(d.tooltip, transform.k * d.x + transform.x, transform.k * d.y + transform.y);
        }
      })
      .on('mouseleave.tooltip', () => hide());
    this.link
      .on('mousemove.tooltip', (_, d: LinkType & { tooltip?: string }) => {
        const source = isNodeObject(d.source) ? d.source : null;
        const target = isNodeObject(d.target) ? d.target : null;
        if (d.tooltip && source && target) {
          const x = (source.x + target.x) / 2;
          const y = (source.y + target.y) / 2;
          const transform = this.zoomContainer.zoomTransform;
          show(d.tooltip, transform.k * x + transform.x, transform.k * y + transform.y);
        }
      })
      .on('mouseleave.tooltip', () => hide());
  }

  createZoomBehaviour() {
    const { width, height } = this.zoomContainer.getDimensionsForZoom();
    return d3
      .zoom()
      .filter(
        (event: UIEvent) =>
          !(
            (event.type == 'dblclick' && (<MouseEvent>event).shiftKey) ||
            (event.type === 'click' && (<MouseEvent>event).button !== 0)
          ),
      )
      .scaleExtent([this.ZOOM_MIN, this.ZOOM_MAX])
      .translateExtent([
        [-width / 8, -height / 8],
        [(width * 9) / 8, (height * 9) / 8],
      ])
      .on('start', () => {
        this.zoomContainer.setScrollState(
          this.zoomContainer.getScrollState() === 'scroll' ? 'scroll' : 'zoom',
        );
      })
      .on('zoom', (element: { transform: ZoomTransform }) => {
        const dims = this.zoomContainer.getAbsoluteDimensionsForFit();
        const scale = dims?.scale ?? 1;
        const { transform } = element;
        const { x, y, k } = transform;
        if ([x, y, k].some((num) => num === null || num === undefined || Number.isNaN(num))) {
          return;
        }

        this.link.attr('transform', transform as any).attr('stroke-width', 1 / k);

        this.node
          .attr('transform', transform as any)
          .attr('r', (d: NodeType) => this.scaleNodeRadiusAfterZoom(d.size, k, scale))
          .attr('stroke-width', 0.5 / Math.max(k / scale, 1));

        this.zoomContainer.setCurrentZoom(k);
        if (this.zoomContainer.getScrollState() !== 'scroll') {
          this.zoomContainer.setScrollBarsToZoomPosition(x, y, k, false);
        }
      })
      .on('end', () => {
        this.zoomContainer.setScrollState('idle');
        this.hasZoomed = true;
      });
  }

  downloadImage(documentName?: string) {
    const { minX, minY, maxX, maxY } = this.zoomContainer.getAbsoluteDimensionsForFit();
    const transform = this.zoomContainer.zoomTransform;
    this.svg.call(this.zoomContainer.getZoom().transform, d3.zoomIdentity);
    const graphSVG = this.svg.node().cloneNode(true) as SVGSVGElement;
    const IMAGE_X = 1500;
    const IMAGE_Y = (IMAGE_X * (maxY - minY)) / (maxX - minX);

    graphSVG.setAttribute('width', `${IMAGE_X}px`);
    graphSVG.setAttribute('height', `${IMAGE_Y}px`);
    graphSVG.setAttribute('viewBox', `${minX} ${minY} ${maxX - minX} ${maxY - minY}`);
    if (this.showLegend) {
      const legendSVG = this.legend.nativeElement.cloneNode(true) as SVGSVGElement;
      const legendSVGBounds = legendSVG.getBBox();
      legendSVG.setAttribute('style', `margin-left: 0px; overflow: visible; display: block;`);
      const height = Math.max(IMAGE_Y, legendSVGBounds.height);
      this.canvas
        .downloadSvgsAsImage(
          documentName,
          [
            { svg: graphSVG, x: 0, y: 0 },
            {
              svg: legendSVG,
              x: IMAGE_X,
              y: (height - legendSVGBounds.height) / 2,
            },
          ],
          IMAGE_X + this.legend.width + 2 * this.configForLegend.legendLeftMargin,
          height,
        )
        .then(() => {
          graphSVG.remove();
          legendSVG.remove();
        });
    } else {
      this.canvas
        .downloadSvgsAsImage(documentName, [{ svg: graphSVG, x: 0, y: 0 }], IMAGE_X, IMAGE_Y)
        .then(() => graphSVG.remove());
    }
    this.svg.call(this.zoomContainer.getZoom().transform, transform);
  }

  transformSize(size: number): number {
    let transformedSize;
    if (this.nodeConfig?.sizeAreaTransform === 'balanced') {
      transformedSize = Math.sqrt(Math.log2(size + 1) * size);
    } else if (this.nodeConfig?.sizeAreaTransform === 'log2') {
      transformedSize = Math.log2(size + 1);
    } else if (this.nodeConfig?.sizeAreaTransform === 'linear') {
      transformedSize = size;
    } else if (this.nodeConfig?.sizeAreaTransform === 'fixed') {
      transformedSize = 8;
    }
    return Math.max(Math.sqrt(transformedSize + 1), 1);
  }

  scaleNodeRadiusAfterZoom(untransformedSize: number, k: number, scale: number): number {
    const transformedSize = this.transformSize(untransformedSize);
    // small nodes will scale like 1/k^0.8, large nodes will scale like 1/k^0.2.
    // This makes large nodes shrink less than small nodes when zooming in,
    // which mitigates a strange visual effect where, with linear scaling (1/k), large nodes seem to
    // shrink when zooming in, even though they don't change size.
    const apparentScale = Math.pow(k, 0.2 + 0.8 * Math.exp(-transformedSize / 10));
    return transformedSize / Math.max(apparentScale, scale);
  }

  resetLayout() {
    this.renderGraph(true);
  }
}
function isNodeObject(linkNode: string | NodeType): linkNode is NodeType {
  return typeof linkNode === 'object';
}

function getIds(linkNode: LinkType): { source: string; target: string } {
  const source = isNodeObject(linkNode.source) ? linkNode.source.id : linkNode.source;
  const target = isNodeObject(linkNode.target) ? linkNode.target.id : linkNode.target;
  return { source, target };
}

/**
 * Produces a d3 hierarchy from an unrooted tree (specified as a list of edges which we know to specify a tree)
 * and a specified root. Note that the edges are undirected, so we don't know which nodes are the parents, etc.
 * We basically have to traverse the entire tree from the specified root and correctly "align" the undirected edges
 * so that we can call d3.stratify (which expects us to know what the parents are)
 *
 * @param links a list of edges of an unrooted, undirected tree
 * (this is just an undirected graph for which there is at most one path between any pair of nodes)
 * @param rootNode any node in this tree
 */
function rootTreeAt(
  links: LinkType<NodeType>[],
  rootNode: NodeType,
): d3.HierarchyNode<{ parentId: string; id: string }> {
  const linksSeen: Set<LinkType> = new Set<LinkType>();
  const rootedTreeEdges: { parentId: string; id: string }[] = [];
  const linksContainingEachNode: Record<string, Set<LinkType>> = {};
  const idsOfNodesToVisitStack: string[] = [];
  for (const link of links) {
    const { source, target } = getIds(link);
    if (!linksContainingEachNode[source]) {
      linksContainingEachNode[source] = new Set<LinkType>();
    }
    if (!linksContainingEachNode[target]) {
      linksContainingEachNode[target] = new Set<LinkType>();
    }
    linksContainingEachNode[source].add(link);
    linksContainingEachNode[target].add(link);
  }
  rootedTreeEdges.push({ id: rootNode.id, parentId: null });
  idsOfNodesToVisitStack.push(rootNode.id);

  while (idsOfNodesToVisitStack.length > 0) {
    const next = idsOfNodesToVisitStack.pop();
    const adjacentLinks: { id: string; parentId: string }[] = [];
    for (const link of linksContainingEachNode[next]) {
      const { source, target } = getIds(link);
      if (linksSeen.has(link)) {
        continue;
      }
      if (source === next) {
        adjacentLinks.push({ id: target, parentId: next });
        linksSeen.add(link);
      } else if (target === next) {
        adjacentLinks.push({ id: source, parentId: next });
        linksSeen.add(link);
      }
    }
    adjacentLinks.forEach((link) => idsOfNodesToVisitStack.push(link.id));
    rootedTreeEdges.push(...adjacentLinks);
  }
  return d3
    .stratify<{ id: string; parentId: string }>()
    .id((d) => d.id)
    .parentId((d) => d.parentId)(rootedTreeEdges);
}

/**
 * Finds the central node of the unrooted tree and generates a radial tree layout from a tree rooted at this node.
 * The central node is found using the following algorithm:
 * - Choose any node N; the first will do.
 * - Find the node M most distant from N.
 * - Find the node D most distant from M.
 * - Find the node at the midpoint of the path from M to D.
 *
 */
function createTreeLayout(nodes: NodeType[], links: LinkType[], width: number) {
  // note that if the tree is a forest with several connected components, this will only
  // find one of them (depending on which node is closest to the centre)
  // I'm not sure if this is a real problem: most of the time there should only be
  // one "main" connected component and a few outliers which will be pushed to the outside
  let rooted = rootTreeAt(links as any, nodes[0]);
  const newCentre = rooted.leaves().sort((a, b) => b.depth - a.depth)[0];
  rooted = rootTreeAt(
    links as any,
    nodes.find((node) => node.id === newCentre.id),
  );
  const longestPath = rooted.path(rooted.leaves().sort((a, b) => b.depth - a.depth)[0]);
  const medianNode = longestPath[Math.floor(longestPath.length / 2)];
  rooted = rootTreeAt(
    links as any,
    nodes.find((node) => node.id === medianNode.id),
  );
  return d3
    .tree<{ id: string; parentId: string }>()
    .size([2 * Math.PI, width / 2])
    .separation((a, b) => (a.parent == b.parent ? 1 : 2) / a.depth)(rooted);
}

class NetworkDimensions extends AbsoluteDimensions {
  private static readonly PADDING: number = 25;
  constructor(
    protected container: ElementRef<HTMLDivElement>,
    private data: any,
    private svg: d3.Selection<SVGSVGElement, undefined, null, undefined>,
  ) {
    super(container);
  }

  public forFit() {
    return this.absoluteDimensions;
  }

  public update() {
    const { width, height } = this.svg.node().getBoundingClientRect();
    let minX = Number.POSITIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    for (const node of this.data.nodes) {
      minX = Math.min(node.x, minX);
      maxX = Math.max(node.x, maxX);
      minY = Math.min(node.y, minY);
      maxY = Math.max(node.y, maxY);
    }
    minX -= NetworkDimensions.PADDING;
    maxX += NetworkDimensions.PADDING;
    minY -= NetworkDimensions.PADDING;
    maxY += NetworkDimensions.PADDING;
    const scaleX = Math.max(width / (maxX - minX) || 1, 0.1);
    const scaleY = Math.max(height / (maxY - minY) || 1, 0.1);
    const scale = Math.max(Math.min(scaleX, scaleY) || 1, 0.1);
    this.absoluteDimensions = {
      minX,
      minY,
      maxX,
      maxY,
      scaleX,
      scaleY,
      scale,
    };
  }
}
