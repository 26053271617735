import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, share, switchMap } from 'rxjs/operators';
import { compareStrings } from 'src/app/shared/utils/object';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { DocumentActivityEventKind } from '../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  DocumentEventType,
  DocumentStatusKind,
  DocumentTableStatusUpdatedEvent,
} from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import { AppState } from '../../core.store';
import { selectBulkEditInProgressForDocumentTable } from '../../document-table-edits/document-table-edits.selectors';
import { DocumentTableViewerService } from '../../document-table-service/document-table-viewer.service';

@Injectable({
  providedIn: 'root',
})
export class TableProcessingNotifierService {
  readonly tableStatusNotifications$: Observable<NotifierTableEvent>;
  private readonly tableEvents$: Observable<DocumentEventType>;

  private readonly ADDING_TABLE_MESSAGE = (displayName: string): NotifierTableEvent => ({
    message: `Adding table ${displayName}`,
    detail: `Uploading ${displayName} and indexing it so it can be joined to this table.<br/>(Adding assay data generally takes about 30s)`,
  });
  private readonly INDEXING_MESSAGE: NotifierTableEvent = {
    message: 'Indexing table',
    detail: 'Processing table',
  };
  private readonly NO_STATUS_MESSAGE: NotifierTableEvent = { message: undefined };
  private readonly BULK_ROW_UPDATE_MESSAGE: NotifierTableEvent = {
    message: 'Adding labels',
    detail: 'Processing bulk labels operation',
  };

  constructor(
    private readonly store: Store<AppState>,
    private readonly documentTableViewerService: DocumentTableViewerService,
    private readonly activityStreamService: ActivityStreamService,
  ) {
    const selectedTable$: Observable<DocumentTable> = this.documentTableViewerService
      .getSelectedTable()
      .pipe(
        distinctUntilChanged(compareStrings(({ documentID, name }) => documentID + name)),
        share(),
      );

    /** Emits activity events for the selected document table */
    const tableActivityEvents$ = selectedTable$.pipe(
      switchMap(({ documentID, name }) =>
        this.activityStreamService
          .listenToDocumentActivity(documentID)
          .pipe(filter((event) => (event.event as { tableName?: string })?.tableName === name)),
      ),
      map((activity) => activity.event),
    );

    /**
     * Emits the initial status when a new table is selected. The status is disguised
     * as a TableStatusUpdated event to simplify downstream logic.
     */
    const initialTableStatus$: Observable<DocumentTableStatusUpdatedEvent> = selectedTable$.pipe(
      map((table) => ({
        documentID: { organizationID: '', documentID: table.documentID },
        tableName: table.name,
        kind: DocumentActivityEventKind.TABLE_STATUS_UPDATED,
        status: table.status,
        dateTime: new Date().toString(),
      })),
    );

    this.tableEvents$ = merge(initialTableStatus$, tableActivityEvents$).pipe(share());

    const tableIsIndexing$ = this.tableEvents$.pipe(
      filter(
        ({ kind }) =>
          kind === DocumentActivityEventKind.RE_INDEX_STARTED ||
          kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED,
      ),
      map(
        (event) =>
          !this.tableIsIdle(event) && event.kind === DocumentActivityEventKind.RE_INDEX_STARTED,
      ),
      distinctUntilChanged(),
    );

    const tableIsAdding$ = this.tableEvents$.pipe(
      filter(
        ({ kind }) =>
          kind === DocumentActivityEventKind.INFERRED_TABLE_ADDED ||
          kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED,
      ),
      map(
        (event) =>
          !this.tableIsIdle(event) && event.kind === DocumentActivityEventKind.INFERRED_TABLE_ADDED,
      ),
      distinctUntilChanged(),
    );

    const bulkUpdateIsApplying$ = selectedTable$.pipe(
      switchMap((table) =>
        this.store.select(selectBulkEditInProgressForDocumentTable(table.documentID, table.name)),
      ),
    );

    this.tableStatusNotifications$ = combineLatest({
      table: selectedTable$,
      adding: tableIsAdding$,
      indexing: tableIsIndexing$,
      rowsUpdating: bulkUpdateIsApplying$,
    }).pipe(
      map(({ table, adding, indexing, rowsUpdating }) => {
        if (adding) {
          return this.ADDING_TABLE_MESSAGE(table.displayName);
        }
        if (rowsUpdating) {
          return this.BULK_ROW_UPDATE_MESSAGE;
        }
        if (indexing) {
          return this.INDEXING_MESSAGE;
        }
        return this.NO_STATUS_MESSAGE;
      }),
    );
  }

  private tableIsIdle(event: DocumentEventType): boolean {
    return (
      event.kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED &&
      event.status.kind === DocumentStatusKind.IDLE
    );
  }
}
export interface NotifierTableEvent {
  message?: string;
  detail?: string;
}
