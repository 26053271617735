import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { removeProfile, upsertProfile } from './profiles.actions';
import { map } from 'rxjs/operators';
import { ProfileService } from './profile.service';
import { NewUserSettingShareType } from '@geneious/nucleus-api-client';
import { deleteUserSetting, upsertUserSetting } from '../user-settings.actions';
import { UserSettingsKinds } from '../user-settings-types';

@Injectable()
export class ProfilesEffects {
  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProfile),
      map(({ id }) => deleteUserSetting({ id })),
    ),
  );

  upsertProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProfile),
      map(({ profile }) => {
        const userSettingName = ProfileService.generateProfileID(profile.name, profile.identifier);
        const shareType = profile.isShared
          ? NewUserSettingShareType.ReadOnly
          : NewUserSettingShareType.NotShared;

        return upsertUserSetting({
          kind: profile.feature as UserSettingsKinds,
          name: userSettingName,
          data: profile.data,
          shareType,
        });
      }),
    ),
  );

  constructor(private actions$: Actions) {}
}
