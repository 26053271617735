<form
  class="d-flex align-items-center me-2 flex-grow-1 flex-shrink-1"
  (submit)="onSubmit()"
  [formGroup]="form"
>
  <div class="input-group flex-grow-1 flex-shrink-1">
    <input
      type="search"
      class="form-control"
      id="filter"
      #filterElement
      placeholder="Filter"
      formControlName="filter"
      tabindex="0"
      (search)="onSubmit()"
    />
    <button class="btn btn-primary" type="submit">Filter</button>
  </div>
</form>
<a
  target="_blank"
  class="d-flex align-items-center p-0"
  href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069871"
>
  <mat-icon data-testid="filterHelp">help</mat-icon>
</a>
