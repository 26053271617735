import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../../dialogV2/jobDialogContent.model';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import {
  BxFormControl,
  BxFormGroup,
} from '../../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  AlignmentExportOptions,
  Column,
  ExportJobParameters,
  TableOutputType,
} from '../../../../../nucleus/services/models/exportOptions.model';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { PipelineDialogData } from '../../index';
import { JobResultDownloaderService } from '../../../utils/job-result-downloader.service';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';
import { NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { SelectOption } from '../../../models/ui/select-option.model';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectComponent } from '../../../../shared/select/multi-select.component';

@Component({
  selector: 'bx-export-alignment',
  templateUrl: './export-alignment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MultiSelectComponent],
})
export class ExportAlignmentComponent
  extends JobDialogContent
  implements RunnableJobDialog, OnInit
{
  readonly title = 'Export Alignment';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup({
    tableTypeControl: new BxFormControl<TableOutputType>('xlsx'),
    metadataColumns: new BxFormControl<Column[]>(null),
    eachPositionAsAColumn: new BxFormControl<boolean>(true),
    includeConsensusSequence: new BxFormControl<boolean>(true),
  });
  private readonly formDefaults: unknown;
  allMetadataColumns: SelectOption<Column>[];

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<ExportAlignmentDialogData>,
    private jobResultDownloaderService: JobResultDownloaderService,
  ) {
    super('export', PipelineFormID.EXPORT_ALIGNMENT);
    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit(): void {
    this.allMetadataColumns = this.dialogData.otherVariables.metadataColumns.map(
      (column) =>
        new SelectOption(
          column.groupId ? `${column.groupId}: ${column.name}` : column.name,
          column,
        ),
    );
    this.form.controls.metadataColumns.setValue(this.dialogData.otherVariables.metadataColumns);
  }

  run() {
    const options: AlignmentExportOptions = {
      sortedSequenceIDs: this.dialogData.otherVariables.sortedSequenceIDs,
      sortedIDColumnName: this.dialogData.otherVariables.sortedIDColumnName,
      visibleAnnotationTypes: this.dialogData.otherVariables.visibleAnnotationTypes,
      tableOutputType: this.form.controls.tableTypeControl.value,
      exportFormat: 'alignment',
      eachPositionAsAColumn: this.form.controls.eachPositionAsAColumn.value,
      columnsState: {
        columns: this.form.controls.metadataColumns.value.map((column) => {
          //not so nice conversion to revert the metadata column name changes done in SequenceViewerMetadataService.buildSvRenderer
          if (column.name === 'Liability score' && column.colId === 'Score') {
            return {
              name: column.name,
              colId: 'BX_Score',
              groupId: column.groupId,
            };
          } else {
            return column;
          }
        }),
      },
    };

    if (this.form.controls.includeConsensusSequence.value) {
      options.consensusSequence = this.dialogData.otherVariables.consensusSequence;
    }
    const parameters: ExportJobParameters = {
      options,
      selection: {
        folderId: this.dialogData.folderID,
        ids: this.dialogData.selected.ids,
        selectAll: false,
      },
    };
    return {
      pipeline: { name: 'export', version: VersionEnum.Latest },
      parameters,
    };
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
      newJobResponse.data.jobID,
      'EXPORTED_FILE',
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}

export interface ExportAlignmentDialogData {
  metadataColumns: Column[];
  sortedSequenceIDs: string[];
  sortedIDColumnName: string;
  consensusSequence: string;
  visibleAnnotationTypes: any;
}
