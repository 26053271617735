import { FolderKindsEnum } from '../models/folderKinds';
import {
  expand,
  folderModified,
  startNewFolder,
  startRename,
  stopNewFolder,
  stopRename,
  toggleExpand,
  updateNewFolder,
  updateRename,
} from './expand.actions';
import { createReducer, on } from '@ngrx/store';

export interface FolderExpandState {
  expanded: { [id: string]: boolean };
  menuOpen?: string;
  lastModified?: number;
  rename: RenameFolderState;
  newFolder: NewFolderState;
}

export interface NewFolderState {
  parentID?: string;
  name?: string;
  kind?: FolderKindsEnum;
  error?: string;
}

export interface RenameFolderState {
  folderID?: string;
  error?: string;
  newName?: string;
}

const initialState: FolderExpandState = {
  expanded: {},
  rename: {},
  newFolder: {},
};

export const expandReducer = createReducer(
  initialState,
  on(toggleExpand, (state, { id }) => ({
    ...state,
    expanded: {
      ...state.expanded,
      [id]: !state.expanded[id],
    },
  })),
  on(expand, (state, { ids }) => ({
    ...state,
    expanded: {
      ...state.expanded,
      ...Object.fromEntries(ids.map((id) => [id, true])),
    },
  })),
  on(startRename, (state, { id }) => ({
    ...state,
    rename: {
      folderID: id,
    },
  })),
  on(updateRename, (state, { newName, error }) => ({
    ...state,
    rename: {
      ...state.rename,
      newName: newName || state.rename.newName,
      error: error,
    },
  })),
  on(stopRename, (state) => ({
    ...state,
    rename: {},
  })),
  on(startNewFolder, (state, { parentID, kind }) => ({
    ...state,
    newFolder: {
      parentID,
      kind,
    },
  })),
  on(updateNewFolder, (state, { name, error }) => ({
    ...state,
    newFolder: {
      ...state.newFolder,
      name,
      error,
    },
  })),
  on(stopNewFolder, (state) => ({
    ...state,
    newFolder: {},
  })),
  on(folderModified, (state, { time }) => ({
    ...state,
    lastModified: time,
  })),
);
