import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DocumentServiceTableColumnGroups } from '../../../../nucleus/services/documentService/types';

export function masterDatabaseNewGroupNameValidator(
  groups: DocumentServiceTableColumnGroups,
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const groupMergeRadio: 'existing' | 'new' = control.value.groupMergeRadio;
    if (groupMergeRadio === 'new') {
      const newGroupName: string = control.value.newGroupName;
      if (newGroupName in groups) {
        control.get('newGroupName').setErrors({
          ...control.errors,
          newGroupNameExists: `New Group name '${newGroupName}' already exists`,
        });
      }
    }
    return null;
  };
}
