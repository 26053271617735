import { Chip } from '../chips';
import { CombinationRegionChip } from './regions-selector.component';

export const defaultRegionChips: (Chip | CombinationRegionChip)[] = [
  { id: 'Light CDR3', label: 'Light CDR3' },
  { id: 'Heavy CDR3', label: 'Heavy CDR3' },
  { id: 'Light CDR3', label: 'Light CDR3' },
  { id: 'VDJ Region', label: 'VDJ Region' },
  { id: 'VJ Region', label: 'VJ Region' },
  { id: 'Light V Gene', label: 'Light V Gene' },
  { id: 'Heavy V Gene', label: 'Heavy V Gene' },
  {
    id: 'Heavy-Light CDR3',
    label: 'Heavy-Light CDR3',
    type: 'Combination',
    regions: ['Heavy CDR3', 'Light CDR3'],
  },
];

export const defaultGenericRegionChips: (Chip | CombinationRegionChip)[] = [
  { id: 'Full Sequence', label: 'Full Sequence' },
  { id: 'Template Region', label: 'Template Region' },
  { id: 'Template Matches', label: 'Template Matches' },
];
