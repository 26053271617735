@if (selectRegions$.value) {
  @if (unselectedOptions$ | async; as unselected) {
    <div class="drag-container d-flex flex-row justify-content-start flex-wrap">
      @for (option of unselected; track trackByOptionValue($index, option)) {
        <div class="option m-1 d-flex flex-row">
          <div
            class="d-flex flex-row btn"
            [ngClass]="option.provisionallySelected ? 'btn-primary' : 'btn-light'"
            (click)="selectOption(option)"
          >
            {{ option.option.displayName }}
          </div>
        </div>
      }
    </div>
  }
  <div class="d-flex flex-row justify-content-end">
    <button class="btn btn-secondary mb-1 mt-1 me-2 d-inline-block" (click)="onCancel()">
      Cancel
    </button>
    @if (
      {
        provisionallySelected: regionsAreProvisionallySelected$ | async,
        text: selectRegionsButtonText$ | async,
        overLimit: provisionallySelectedRegionsOverLimit$ | async,
        tooltip: selectRegionsTooltip$ | async,
      };
      as state
    ) {
      <button
        [disabled]="!state.provisionallySelected || state.overLimit"
        [ngbTooltip]="state.tooltip"
        class="btn btn-primary mb-1 mt-1 d-inline-block"
        (click)="onOptionsSelected()"
      >
        {{ state.text }}
      </button>
    }
  </div>
} @else {
  @if ((selectedCount$ | async) > 0) {
    <div
      cdkDropList
      (cdkDropListDropped)="onDropped($event)"
      [cdkDropListData]="selectedOptions$ | async"
      class="mh-static-1 overflow-y-auto border p-2"
      ngbTooltip="Drag to reorder"
    >
      @for (option of selectedOptions$ | async; track trackByValue($index, option)) {
        <div cdkDrag class="option mb-1 mt-1 d-flex align-items-center">
          <div cdkDragHandle class="draggable opacity-25 pe-1">
            <fa-icon [icon]="faGripHorizontal"></fa-icon>
          </div>
          <div class="btn btn-light d-flex flex-nowrap">
            <span class="text-center">{{ option.displayName }}</span>
            <div class="ps-2" (click)="onOptionRemoved(option)">
              <fa-icon [icon]="faTimes"></fa-icon>
            </div>
          </div>
          <div *cdkDragPlaceholder class="placeholder opacity-50 mb-1 mt-1 d-flex flex-row">
            <div cdkDragHandle class="opacity-0 draggable pe-1">
              <fa-icon [icon]="faGripHorizontal"></fa-icon>
            </div>
            <div class="d-flex flex-row btn btn-light">
              <span class="opacity-0">{{ option.displayName }}</span>
            </div>
          </div>
        </div>
      }
    </div>
  }
  @if (
    {
      notAll: notAllRegionsAreSelected$ | async,
      tooltip: addRegionsTooltip$ | async,
    };
    as selectionState
  ) {
    <button
      class="btn btn-primary mb-1 mt-2 d-inline-block float-end"
      (click)="selectRegions$.next(selectionState.notAll)"
      [disabled]="!selectionState.notAll"
      [ngbTooltip]="selectionState.tooltip"
    >
      Add Regions
    </button>
  }
}
