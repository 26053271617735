import { distinctUntilChanged, filter, map, shareReplay, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FileUploadGroup } from '../../models/file-upload.model';
import { Observable } from 'rxjs';
import { UploadStatus, FileUploadStore } from './uploads-table.reducer';
import { AppState } from '../../core.store';
import {
  loadFileUploadTable,
  addFileUpload,
  updateFileUpload,
  updateFileUploadByFileID,
  cancelUpload,
  resetFileUploadStatus,
} from './uploads.actions';
import { selectAllUploads, selectUploadsTable } from './uploads-table.selectors';
import { FileUploadStatus } from '../../../../nucleus/v2/models/activity-events/file-upload-event.model';

@Injectable({
  providedIn: 'root',
})
export class UploadsTableStoreService {
  status$: Observable<UploadStatus>;
  fileUploadStore$: Observable<FileUploadStore>;
  list: Observable<FileUploadGroup[]>;

  constructor(private store: Store<AppState>) {
    this.fileUploadStore$ = this.store.pipe(select(selectUploadsTable));
    this.list = this.store.pipe(select(selectAllUploads));
    this.status$ = this.fileUploadStore$.pipe(
      map((state) => state.status),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  loadFileUpload() {
    this.store.dispatch(loadFileUploadTable());
  }

  addFileUpload(uploadGroup: FileUploadGroup) {
    this.store.dispatch(addFileUpload(uploadGroup));
  }

  updateFileUpload(uploadGroup: FileUploadGroup, oldID?: string) {
    this.store.dispatch(updateFileUpload(uploadGroup, oldID));
  }

  /**
   * Used to to update the state of an upload in the import step because the only link between the upload
   * and the job is the file ids.
   */
  updateUploadsByFileID(fileID: string, status: FileUploadStatus) {
    this.store.dispatch(updateFileUploadByFileID(fileID, status));
  }

  cancelUpload(uploadGroup: FileUploadGroup) {
    this.store.dispatch(cancelUpload(uploadGroup));
  }

  listenToCompletedUploads(params: IGetUploadsObsParams): Observable<FileUploadGroup[]> {
    // This only returns Uploads that are related to this Folder.
    return this.list.pipe(
      skip(1), // Skip what is already in the store as this is for listening.
      map((uploads: FileUploadGroup[]) => {
        return uploads.filter((upload: FileUploadGroup) => {
          return upload.folderID === params.folderID && upload.isDone();
        });
      }),
      distinctUntilChanged(
        (a1: FileUploadGroup[], a2: FileUploadGroup[]) => a1.length === a2.length,
      ),
      // Filters out empty arrays.
      filter((uploads: FileUploadGroup[]) => uploads.length > 0),
    );
  }

  resetStatus() {
    this.store.dispatch(resetFileUploadStatus());
  }
}

export interface IGetUploadsObsParams {
  folderID: string;
}
