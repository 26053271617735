<p class="text-muted px-2">
  Select the columns associated with the sequence information to import. See our
  <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625092"
    >support page</a
  >
  for more information.
</p>
<div [formGroup]="configurationForm">
  <bx-card headerTitle="Input Columns">
    <div class="mb-3 row">
      <label for="sequenceNameColumn" class="col-4 col-form-label"> Sequence Name Column: </label>
      <div class="col-8">
        <select
          id="sequenceNameColumn"
          name="sequenceNameColumn"
          class="form-select"
          formControlName="sequenceNameColumn"
        >
          @for (column of columns$ | async; track column) {
            <option [value]="column">
              {{ column }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-3 row">
      <label class="col-4 col-form-label"> Sequence Type: </label>
      <div class="col-8 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="nucleotideOption"
            formControlName="sequenceType"
            name="sequenceType"
            value="nucleotide"
            class="form-check-input"
          />
          <label for="nucleotideOption" class="form-check-label"> Nucleotide </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="proteinOption"
            formControlName="sequenceType"
            name="sequenceType"
            value="protein"
            class="form-check-input"
          />
          <label for="proteinOption" class="form-check-label"> Protein </label>
        </div>
      </div>
    </div>

    <div class="mb-3 row">
      <div class="col-4 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="singleChainOnly"
            formControlName="multipleChainsAllowed"
            [value]="false"
            (change)="setMultipleChainsAllowed(false)"
          />
          <label class="form-check-label" for="singleChainOnly"> Single Sequence Column: </label>
        </div>
      </div>
      <div
        class="col-8"
        [bxFadeFormElementDirective]="{
          controlName: 'multipleChainsAllowed',
          fadeWhenValueNotMatching: false,
        }"
      >
        <select id="singleChain" class="form-select" formControlName="singleChain">
          @for (column of columns$ | async; track column) {
            <option [value]="column">
              {{ column }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-0">
      <div class="form-check mb-2">
        <input
          class="form-check-input"
          type="radio"
          id="multipleChainAllowed"
          formControlName="multipleChainsAllowed"
          [value]="true"
          (change)="setMultipleChainsAllowed(true)"
        />
        <label class="form-check-label" for="multipleChainAllowed"> Paired Chains Columns </label>
      </div>

      <div
        class="row mb-2"
        [bxFadeFormElementDirective]="{
          controlName: 'multipleChainsAllowed',
          fadeWhenValueNotMatching: true,
        }"
      >
        <div class="col-1"></div>
        <label for="heavyChain" class="col-3 col-form-label"> Heavy Sequence: </label>
        <div class="col-8">
          <select id="heavyChain" class="form-select" formControlName="heavyChain">
            @for (column of columns$ | async; track column) {
              <option [value]="column">
                {{ column }}
              </option>
            }
          </select>
        </div>
      </div>

      <div
        class="row"
        [bxFadeFormElementDirective]="{
          controlName: 'multipleChainsAllowed',
          fadeWhenValueNotMatching: true,
        }"
      >
        <div class="col-1"></div>
        <label for="lightChain" class="col-3 col-form-label"> Light Sequence: </label>
        <div class="col-8">
          <select id="lightChain" class="form-select" formControlName="lightChain">
            @for (column of columns$ | async; track column) {
              <option [value]="column">
                {{ column }}
              </option>
            }
          </select>
        </div>
      </div>
    </div>
  </bx-card>

  <div class="h-static-1 overflow-y-auto d-flex">
    <bx-table-viewer [tablesToShow$]="allTablesAsOne$"> </bx-table-viewer>
  </div>
</div>
