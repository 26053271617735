import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * A simple wrapper around bootstrap's card styling. The `card` class is
 * applied directly to the `bx-card` host element. Projected content is put in
 * the `div.card-body` element. There are two options for setting the card
 * header content:
 * 1. Set the `title` input, and the text will be shown in the card header:
  ```html
    <bx-card headerTitle="My Card">
     <span>Card body content</span>
    </bx-card>
  ```
 * 2. Or you can use the second content projection slot by adding the 
 *    `bxCardHeader` attribute to the content you wish to inject into the 
 *    `div.card-header` element:
  ```html
      <bx-card>
        <span bxCardHeader>My <i>Fancy</i> Card</span>
        <span>Card body content</span>
      </bx-card>
  ```
 */
@Component({
  selector: 'bx-card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  host: { class: 'card' },
})
export class CardComponent {
  /** An Input that can be used to override the default margin-bottom class */
  @Input() @HostBinding('class') customClass = 'mb-3';

  /**
   * The text to display in the card header
   */
  @Input() headerTitle?: string;

  /**
   * Set this to true to hide the card body and display only the header. This
   * is designed for the radio-button cards without content like the one in
   * bx-batch-assemble. If you want a responsive collapsible card, use
   * bx-collapsible-card instead.
   */
  @Input() hideBody = false;
}
