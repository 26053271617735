import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AccordionService {
  _schema: { index: number; steps: any[] } = {
    index: 0,
    steps: [],
  };

  schemaObserver = new Subject();

  constructor() {}

  get schema() {
    return this._schema;
  }

  increment() {
    this.setIndex(this._schema.index + 1);
  }

  decrement() {
    this.setIndex(this._schema.index - 1);
  }

  setIndex(index: number) {
    this._schema = Object.assign({}, this._schema, { index });
    this.schemaObserver.next(this.schema);
  }

  addStep(step: any) {
    this._schema = Object.assign({}, this._schema, this._schema.steps.push(step));
    this.schemaObserver.next(this.schema);
  }
}
