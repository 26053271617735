import { Injectable } from '@angular/core';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NewFolderState, RenameFolderState } from './expand.reducer';
import { FolderKindsEnum } from '../models/folderKinds';
import {
  expand,
  startNewFolder,
  startRename,
  stopNewFolder,
  stopRename,
  toggleExpand,
  updateNewFolder,
  updateRename,
} from './expand.actions';
import { watchFolders } from './folder.actions';
import {
  selectFolderExpandState,
  selectHasNewFolderChild,
  selectIsRenaming,
  selectNewFolderState,
  selectRenameError,
  selectRenameState,
} from './expand.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExpandService {
  constructor(private store: Store<AppState>) {}

  isExpanded(id: string): Observable<boolean> {
    return this.store.select(selectFolderExpandState(id));
  }

  toggleExpanded(id: string) {
    this.store.dispatch(toggleExpand({ id }));
  }

  expand(id: string) {
    this.store.dispatch(expand({ ids: [id] }));
    this.store.dispatch(watchFolders({ ids: [id] }));
  }

  /**
   * Change the folder expanding state
   * @param ids Id's of the folders where the expanding state need to be updated.
   * @param lastFolderID Id of the last folder in the folder tree branch which is being expanded.(this is typically the folder where files table would be poiting to and needs watching)
   */
  expandMultiple(ids: string[], lastFolderID: string) {
    this.store.dispatch(expand({ ids }));
    this.store.dispatch(watchFolders({ ids: [lastFolderID] }));
  }

  isRenaming(id: string): Observable<boolean> {
    return this.store.select(selectIsRenaming(id));
  }

  getRenameState(): Observable<RenameFolderState> {
    return this.store.select(selectRenameState);
  }

  startRenaming(id: string) {
    this.store.dispatch(startRename({ id }));
  }

  setRenameError(error: string) {
    this.store.dispatch(updateRename({ error }));
  }

  getRenameError() {
    return this.store.select(selectRenameError);
  }

  updateRenaming(newName: string) {
    this.store.dispatch(updateRename({ newName }));
  }

  stopRenaming() {
    this.store.dispatch(stopRename());
  }

  hasNewFolderChild(parentID: string): Observable<boolean> {
    return this.store.select(selectHasNewFolderChild(parentID));
  }

  getNewFolderState(): Observable<NewFolderState> {
    return this.store.select(selectNewFolderState);
  }

  startNewFolder(parentID: string, kind: FolderKindsEnum = 0) {
    this.store.dispatch(startNewFolder({ parentID, kind }));
  }

  setNewFolderError(error: string) {
    this.store.dispatch(updateNewFolder({ error }));
  }

  updateNewFolderName(name: string) {
    this.store.dispatch(updateNewFolder({ name, error: '' }));
  }

  cancelNewFolder() {
    this.store.dispatch(stopNewFolder());
  }
}
