import { Inject, Injectable } from '@angular/core';
import { ActivityStreamService } from '../activity/activity-stream.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { JobActivityEventKind } from '../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { JobsService } from '../jobs/job.service';
import { Window } from './window';
import {
  JobFailedActivityEvent,
  JobResultAddedActivityEvent,
} from 'src/nucleus/v2/models/activity-events/job-activity-event.model';

@Injectable({
  providedIn: 'root',
})
export class JobResultDownloaderService {
  constructor(
    private jobsService: JobsService,
    private activityStreamService: ActivityStreamService,
    @Inject(Window) private window: Window,
  ) {}

  /**
   * Automatically handles downloading a job results file once the Job completes based on the given job id.
   * The user will be prompted with a file download handler dialog native to the browser.
   *
   * @param jobID - the job id of the job that has yet to complete.
   * @param blobName - the blob to download from the output of the Job.
   */
  automaticallyDownloadJobResultFiles(jobID: string, blobName: 'EXPORTED_FILE' | 'FILE') {
    this.activityStreamService
      .listenToJobActivity()
      .pipe(
        map((item) => item.event),
        filter(
          (event): event is JobFailedActivityEvent | JobResultAddedActivityEvent =>
            (event.kind === JobActivityEventKind.JOB_RESULT_ADDED ||
              event.kind === JobActivityEventKind.JOB_FAILED) &&
            event.jobID === jobID,
        ),
        switchMap((event) => this.jobsService.getJobResultDownloadLink(event.jobID, blobName)),
        take(1),
      )
      .subscribe((link) => {
        if (link) {
          // Redirect the browser to the file. The server specifies HTTP header
          // `content-disposition:attachment` which causes it to download rather than navigate away.
          this.window.location.href = link;
        }
      });
  }
}
