import { Region } from './alignmentOptions.model';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';
import { ResultSequenceExtractionOptions } from './result-sequence-extraction-options-v1.model';

export class MasterDatabaseSearchParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'master-database-search',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: MasterDatabaseSearchJobOptions;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: MasterDatabaseSearchJobOptions;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface MasterDatabaseSearchJobOptions {
  masterDatabaseIDs: string[];
  maximumHits: number;
  onlyCompareSameLength: boolean;
  minimumSimilarityThreshold: number;
  region: Region;
  compareBy: 'NUCLEOTIDE' | 'PROTEIN';
  resultSequenceExtractionOptions: ResultSequenceExtractionOptions;
  resultName: string;
}
