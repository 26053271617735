<div class="modal-header">
  <h4 class="modal-title">Submit Job Feedback</h4>

  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body px-4">
  <p>Submit feedback to Geneious Biologics staff, we will be in touch to help.</p>

  <form [formGroup]="form">
    <bx-card headerTitle="Problem Description">
      <textarea
        type="text"
        rows="6"
        id="target"
        class="form-control"
        name="userNotes"
        formControlName="userNotes"
        [placeholder]="
          'What were you trying to do? What went wrong?\n\nIs there anything else that might help us help you?'
        "
      ></textarea>
    </bx-card>

    <bx-card headerTitle="Extra Information">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="allowAccess"
          formControlName="allowAccess"
        />
        <label for="allowAccess" class="form-check-label"
          >Allow Geneious to access my data to help investigate this problem</label
        >
      </div>
    </bx-card>
  </form>

  @if (error$ | async) {
    <span class="text-danger">An error occurred while submitting feedback.</span>
  }
</div>

<div class="modal-footer">
  <div class="me-auto ms-2">
    <a
      target="_blank"
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069811-Submitting-Feedback"
    >
      Help
    </a>
  </div>

  <button
    class="btn btn-primary"
    ngbAutofocus
    bx-spinner-button
    [spin]="submitting$ | async"
    [disabled]="submitting$ | async"
    (click)="send()"
  >
    Send
  </button>

  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
</div>
