import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core.store';
import { StackedColumnChartComponent } from '../../../../../features/graphs/stacked-column-chart/stacked-column-chart.component';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { GraphDocumentDataService } from '../../../../../features/graphs/graph-document-data.service';
import { ngsGraphActions } from '../../ngs-graph-data-store/ngs-graph-data-store.actions';
import { StackedColumnColorSchemeHandler } from '../../../../../features/graphs/datasources/stacked-column-colorscheme-handler';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { SeriesColumnOptions } from 'highcharts';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-amino-acid-distribution-chart',
  templateUrl: './ngs-amino-acid-distribution-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StackedColumnChartComponent, AsyncPipe],
})
export class NgsAminoAcidDistributionChartComponent
  extends NgsBaseGraphComponent<(SeriesColumnOptions & { color: any })[], any>
  implements OnInit, OnDestroy
{
  @ViewChild(StackedColumnChartComponent) chartComponent: StackedColumnChartComponent;

  lengths$ = new ReplaySubject<number[]>(1);
  lengthControl$ = new ReplaySubject<number>(1);
  showLegendControl$ = new BehaviorSubject(true);
  showLabelsControl$ = new BehaviorSubject(true);
  colorSchemeControl$ = new BehaviorSubject('Default');
  showLegend$: Observable<boolean>;
  showLabels$: Observable<boolean>;
  colorScheme$: Observable<string>;
  length$: Observable<number>;
  animationsEnabled$ = new BehaviorSubject(true);

  private colorSchemeHandler: StackedColumnColorSchemeHandler;
  constructor(
    protected store: Store<AppState>,
    private graphDocumentDataservice: GraphDocumentDataService,
  ) {
    super(store);
    this.colorSchemeHandler = new StackedColumnColorSchemeHandler('AminoAcid', 'Default');
  }

  ngOnInit(): void {
    super.ngOnInit();
    const selectedTable$ = this.selectedParams$.pipe(
      map((params) => params.currentSelection.selectedTable.value),
      distinctUntilChanged((prev, current) => prev.name === current.name),
      takeUntil(this.ngUnsubscribe),
    );
    this.length$ = combineLatest([
      this.lengthControl$.pipe(distinctUntilChanged()),
      selectedTable$,
    ]).pipe(map(([length, _]) => length));
    this.showLegend$ = this.showLegendControl$.pipe(distinctUntilChanged());
    this.showLabels$ = this.showLabelsControl$.pipe(distinctUntilChanged());
    this.colorScheme$ = this.colorSchemeControl$.pipe(distinctUntilChanged());

    selectedTable$.subscribe(() => this.animationsEnabled$.next(true));
    selectedTable$
      .pipe(
        switchMap((region) =>
          this.graphDocumentDataservice.getClusterLengths(this.documentID, {
            name: region.displayName,
            table: region.name,
            tableType: region.tableType,
            columns: region.columns,
            metadata: region.metadata,
          }),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((len) => {
        if (!len || len.length === 0) {
          this.error$.next('No valid length options could be found');
        }
        this.lengths$.next(len);
      });

    this.lengths$
      .pipe(
        filter((lengths) => !!lengths),
        distinctUntilChanged(),
      )
      .subscribe((lengths) => this.lengthControl$.next(lengths[0]));
    this.length$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((length) => {
      this.store.dispatch(
        ngsGraphActions.params.options.update({
          id: this.documentID,
          graphId: 'aminoAcidDistribution',
          value: {
            options: { length },
          },
        }),
      );
    });
    const rawData$ = this.store.pipe(
      selectDataForNgsDocument<'aminoAcidDistribution'>(this.documentID, 'aminoAcidDistribution'),
      takeUntil(this.ngUnsubscribe),
    );
    rawData$.pipe(distinctUntilChanged()).subscribe(() => this.animationsEnabled$.next(true));
    this.colorScheme$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((color) => (this.colorSchemeHandler.colorSchemeName = color));
    this.data$ = combineLatest([this.colorScheme$, rawData$]).pipe(
      filter(([_, data]) => !!data),
      map(([_, data]) => this.colorSchemeHandler.setColors(data)),
    );
    this.lengths$
      .pipe(
        map((lengths) => [
          {
            name: 'length',
            label: 'Lengths',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: lengths[0],
            options: lengths.map((length) => {
              return {
                displayName: `${length}`,
                value: length,
              };
            }),
          },
        ]),
        map(
          (lengths) =>
            [
              ...lengths,
              ...this.colorSchemeHandler.getStyleControls({ showLabels: true, showLegend: true }),
            ] as GraphSidebarControl[],
        ),
      )
      .subscribe(this.controls$);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.lengths$.complete();
    this.lengthControl$.complete();
    this.showLegendControl$.complete();
    this.showLabelsControl$.complete();
    this.colorSchemeControl$.complete();
    this.animationsEnabled$.complete();
  }

  exportAsTable() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
      )
      .subscribe((documentName) =>
        this.chartComponent.downloadTable({
          documentName,
          headerText: 'Position',
        }),
      );
  }

  onControlsChanged({ length, colorScheme, showLabels, showLegend }: any) {
    this.animationsEnabled$.next(false);
    this.lengths$.pipe(take(1)).subscribe((lengths) => {
      this.lengthControl$.next(lengths.find((len) => len === Number(length)));
    });
    this.colorSchemeControl$.next(colorScheme);
    this.showLabelsControl$.next(showLabels);
    this.showLegendControl$.next(showLegend);
  }
}
