import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NgForm, Validators, FormsModule } from '@angular/forms';
import { Observable, startWith, Subscription } from 'rxjs';
import { AuthenticateAction, CancelMfaInProgressAction } from '../../../auth/auth.actions';
import { AppState } from '../../../core.store';
import { select, Store } from '@ngrx/store';
import { LoginErrorState } from '../../../auth/auth.reducers';
import { selectLoginErrorState, selectMFARequired } from '../../../auth/auth.selectors';
import { shareReplay, take } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';
import { SpinnerButtonComponent } from '../../../../shared/spinner-button/spinner-button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-login-form',
  templateUrl: './login-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgFormControlValidatorDirective,
    SpinnerButtonComponent,
    RouterLink,
    AsyncPipe,
  ],
})
export class LoginFormComponent implements OnDestroy {
  loginModel: {
    email: string;
    password: string;
    mfaCode: number;
  } = {
    email: undefined,
    password: undefined,
    mfaCode: undefined,
  };
  loginErrorState$: Observable<LoginErrorState>;
  mfaRequired$: Observable<boolean>;
  subscriptions = new Subscription();

  constructor(private store: Store<AppState>) {
    this.loginErrorState$ = this.store.pipe(select(selectLoginErrorState));
    this.mfaRequired$ = this.store
      .pipe(select(selectMFARequired))
      .pipe(startWith(false), shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backToLogin() {
    this.loginModel.mfaCode = null;
    this.store.dispatch(new CancelMfaInProgressAction());
  }

  login(e: Event, loginForm: NgForm) {
    e.preventDefault();

    this.subscriptions.add(
      this.mfaRequired$.pipe(take(1)).subscribe((mfaRequired) => {
        if (mfaRequired && !this.loginModel.mfaCode) {
          loginForm.controls.mfaCode.setValidators([Validators.required]);
          loginForm.controls.mfaCode.updateValueAndValidity();
        } else if (loginForm.valid) {
          this.store.dispatch(
            new AuthenticateAction({
              email: this.loginModel.email,
              password: this.loginModel.password,
              mfaCode: this.loginModel.mfaCode,
            }),
          );
        } else {
          loginForm.controls.email.markAsDirty();
          loginForm.controls.password.markAsDirty();
          loginForm.controls.email.updateValueAndValidity();
          loginForm.controls.password.updateValueAndValidity();
        }
      }),
    );
  }
}
