import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'bx-clear-file-button',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './clear-file-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearFileButtonComponent {
  readonly clearIcon = faTimesCircle;
}
