import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PipelineOutputNameControlComponent } from './pipeline-output-name-control/pipeline-output-name-control.component';
import { NgClass } from '@angular/common';

/**
 * Provides sensible defaults for the two most commonly-used output name
 * controls - result name and output folder name. If you want to customize the
 * controls, use {@link PipelineOutputNameControlComponent} directly.
 */
@Component({
  selector: 'bx-pipeline-output',
  templateUrl: './pipeline-output.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PipelineOutputNameControlComponent, NgClass],
})
export class PipelineOutputComponent {
  // This type should be a FormControl, but it's not enforced as it would require too much duplicate code in the consumer of this
  // @Input due to Angular not having Strongly typed reactive forms yet.
  // e.g. form.get('someControl') returns an AbstractControl even though 'someControl' is a FormControl.
  @Input() resultNameFormControl: AbstractControl;
  @Input() outputNameFormControl: AbstractControl;
}
