import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
  ParsedTable,
  ParsedTablesResult,
} from '../../../../assay-data-v2/table-parser/table-parser.model';
import { TableParserService } from '../../../../assay-data-v2/table-parser/table-parser.service';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CleanUp } from '../../../../../shared/cleanup';
import { ImportOptions } from '@geneious/nucleus-api-client';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../../../shared/card/card.component';
import { NgFormControlValidatorDirective } from '../../../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../../../shared/form-errors/form-errors.component';
import { TableViewerComponent } from '../../../../viewer-components/table-viewer/table-viewer.component';

@Component({
  selector: 'bx-document-file-import-configuration',
  templateUrl: './document-file-import-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbAlert,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgFormControlValidatorDirective,
    NgbTooltip,
    FormErrorsComponent,
    TableViewerComponent,
    AsyncPipe,
  ],
})
export class DocumentFileImportConfigurationComponent extends CleanUp implements OnInit {
  @Output() importOptionsChange = new EventEmitter<ImportOptions>();
  @Input() files: File[];

  readonly form = new FormGroup({
    importEachTableSeparately: new FormControl<boolean>(true),
  });

  filesWithTables$: Observable<ParsedTablesResult>[];
  allFilesContain1Table$: Observable<boolean>;
  allTablesAsOne$: Observable<ParsedTablesResult>;

  constructor(private readonly tableParserService: TableParserService) {
    super();
  }

  ngOnInit(): void {
    this.filesWithTables$ = this.files.map((f) => this.tableParserService.parse(f));

    this.allFilesContain1Table$ = forkJoin(this.filesWithTables$).pipe(
      map((tablesParsed) => tablesParsed.every((parsedTable) => parsedTable.tables.length === 1)),
    );

    this.allTablesAsOne$ = forkJoin(this.filesWithTables$).pipe(
      map((tablesParsed) => {
        const tables = [] as ParsedTable[];
        tablesParsed.forEach((file) => {
          tables.push(...file.tables);
        });
        return {
          filename: 'allTablesAsOne.tmp',
          tables: tables,
        };
      }),
    );

    currentValueAndChanges(this.form, this.ngUnsubscribe).subscribe(() => {
      this.onConfigurationChanged();
    });
  }

  onConfigurationChanged() {
    const importOptions = {
      groupSequences: false,
      additionalJobParameters: {
        options: {
          tableImportOptions: {
            tablesToImport: [] as string[],
            importAsMultipleTableDocument: !this.form.value.importEachTableSeparately,
          },
        },
      },
    };

    this.importOptionsChange.emit(importOptions);
  }
}
