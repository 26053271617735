import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GridService } from '../grid.service';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { isColDef } from '../../../core/folders/models/colDefs';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.store';
import { selectCurrentFiles } from '../../../core/files-table/files-table-store/files-table.selectors';

@Injectable()
export class ClientGridV2Service implements OnDestroy {
  total = 0;
  originalColumnDefs: any[];
  readonly CHECKBOX_COLDEF: ColDef = {
    colId: 'selected',
    field: 'selected',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    width: 34,
    minWidth: 34,
    maxWidth: 34,
    resizable: false,
    suppressMovable: true,
    suppressMenu: true,
    sortable: false,
    lockPosition: true,
    lockVisible: true,
    lockPinned: true,
    cellClass: 'checkbox',
    headerClass: 'checkbox',
    pinned: 'left',
  };

  // TODO: make this service and component generic - currently this is heavily tied to the document table
  //  and cannot be used with any other datasource.
  readonly files$: Observable<Item[]>;

  private filesStateSubscription = Subscription.EMPTY;

  constructor(private store: Store<AppState>) {
    this.files$ = this.store.pipe(selectCurrentFiles);

    this.filesStateSubscription = this.files$.subscribe((files) => (this.total = files.length));
  }

  ngOnDestroy(): void {
    this.filesStateSubscription.unsubscribe();
  }

  /**
   * At grid load we need to order the colDefs ("allDefs") according to any order specified by "colState".
   */
  applyColumnState(allDefs: any[], colState: any[] = []) {
    // Find any columns in "allDefs" which have matches in "colState" and order them by the order in "colState".
    const matches = colState
      // TODO Refactor to remove duplicate lookup.
      .filter((stateCol) => allDefs.find((main) => main.field === stateCol.colId))
      // Get the `columnDef` which corresponds to the matching `colState` & return it.
      .map((stateCol) => {
        const match = allDefs.find((main) => main.field === stateCol.colId);
        // Copy any relevant properties across (e.g. width).
        return Object.assign(match, stateCol);
      });
    // Get any remaining columns (e.g. in "allDefs", but not in "colState").
    const others = allDefs.filter(
      (main) => !colState.find((stateCol) => main.field === stateCol.colId),
    );
    // Note that new columns are just tacked on the end; all in "colState" have priority.
    return [...matches, ...others];
  }

  constructColumnDefs(columnDefs: ColDef[]): (ColDef | ColGroupDef)[] {
    const formattedColDefs = GridService.customizeColumnDefinitions(columnDefs).map(
      this.assignAsGeneralColumn,
    );
    return [this.CHECKBOX_COLDEF, ...formattedColDefs];
  }

  updateOriginalColumnDefs(columns: any[]) {
    this.originalColumnDefs = this.constructColumnDefs(columns);
  }

  /** Private API **/

  // TODO Support ColGroupDefs.
  private assignAsGeneralColumn(column: ColDef | ColGroupDef): ColDef | ColGroupDef {
    if (isColDef(column)) {
      return { ...column, enableCellChangeFlash: true, tooltipField: column.field };
    }

    return column;
  }
}
