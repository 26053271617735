import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, retry, switchMap } from 'rxjs';
import { Job } from 'src/nucleus/services/models/job.model';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { JobHistoryService } from '../../jobs/job-history.service';

@Injectable({
  providedIn: 'root',
})
export class AnnotatorParamsService {
  constructor(
    private readonly jobHistoryService: JobHistoryService,
    private readonly documentHttpService: DocumentHttpV2Service,
  ) {}

  hasMotifAnnotatorPairingMetadata(documentId: string): Observable<boolean> {
    return this.documentHttpService.get(documentId).pipe(
      map((document) => document?.metadata?.motifAnnotatorPreservesPairing),
      map((pairing) => !!pairing),
      catchError((_) => of(false)),
    );
  }

  isOldMotifAnnotatorResult(documentId: string): Observable<boolean> {
    return this.jobHistoryService.getJobsForDocument(documentId).pipe(
      switchMap((jobs: Job[]) => {
        if (jobs.length === 0) {
          throw new Error('No jobs found');
        }
        if (jobs.map((job) => job.config.pipeline.name).includes('motif-annotator')) {
          return this.hasMotifAnnotatorPairingMetadata(documentId).pipe(
            map((hasMetadata) => !hasMetadata),
          );
        }
        return of(false);
      }),
      retry(1),
      catchError(() => of(true)),
    );
  }
}
