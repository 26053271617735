import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PrincipalType } from '../../../nucleus/v1-1/models/organizations/principals/principal-type.v1-1';
import { AccessLevels } from '../permissions/access-level.const';
import { AccessLevel } from '../permissions/access-level.interface';

export class ACLEntity implements IACLEntityBase {
  id: string;
  principalId: string;
  principalType: PrincipalType;
  highestAccess: AccessLevel;
  icon: ACLMaterialIcon | IconDefinition;
  readOnly: boolean;
  selected: boolean;
  isCurrentUser: boolean;
  isOrg: boolean;
  private _displayName: string;
  private _description: string;

  constructor(obj?: IACLEntityBase) {
    this.id = obj.id;
    this.principalId = obj.principalId;
    this.principalType = obj.principalType;
    this._displayName = obj.displayName;
    this._description = obj.description;
    this.highestAccess = obj.highestAccess;
    this.icon = obj.icon;
    this.readOnly = obj.readOnly;
    this.selected = obj.selected;
    this.isCurrentUser = obj.isCurrentUser;
    this.isOrg = obj.isOrg;
  }

  get displayName(): string {
    return this.isCurrentUser ? `${this._displayName} (me)` : this._displayName;
  }

  set displayName(name: string) {
    this._displayName = name;
  }

  get description(): string {
    return this._description;
  }

  set description(desc: string) {
    this._description = desc;
  }

  get iconType(): IconType {
    return typeof this.icon === 'string' ? 'material' : 'fa';
  }

  isGroup(): boolean {
    return this.principalType === PrincipalType.GROUP;
  }

  isDeletable(): boolean {
    return !this.isCurrentUser;
  }

  hasFullAccess(): boolean {
    return this.highestAccess === AccessLevels.full;
  }
}

export type IconType = 'material' | 'fa';
export type ACLMaterialIcon = 'person' | 'people' | 'business';

export interface IACLEntityBase {
  id: string;
  principalId: string;
  principalType: PrincipalType;
  displayName: string;
  description: string;
  highestAccess: AccessLevel;
  iconType: IconType;
  icon: ACLMaterialIcon | IconDefinition;
  readOnly?: boolean;
  selected?: boolean;
  isCurrentUser?: boolean;
  isOrg?: boolean;
}
