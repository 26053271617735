<div class="mb-2">
  <input
    #searchInput
    type="text"
    title="Search labels"
    class="form-control"
    placeholder="Search labels..."
    (input)="search($event.target.value)"
    data-spec="label-search"
  />
</div>
@if (displayedLabels$ | async; as displayedLabels) {
  <div class="h-static-1 overflow-y-auto d-block mb-2 me-1">
    @if (displayedLabels.length > 0) {
      @for (label of displayedLabels; track label) {
        <label
          class="form-label d-flex align-items-center my-0 py-1 px-2 list-group-item-action"
          [for]="label.id"
          data-spec="label"
        >
          <input
            type="checkbox"
            [value]="label"
            [id]="label.id"
            [checked]="label.selected"
            (change)="setSelected(label.id, $event.target.checked)"
            data-spec="label-checkbox"
          />
          <fa-icon
            [icon]="swatchIcon"
            [ngStyle]="{ color: label.color }"
            class="mx-2"
            data-spec="label-swatch"
          ></fa-icon>
          <ngb-highlight
            [result]="label.name"
            [title]="label.name"
            [term]="searchInput.value"
            class="text-truncate"
            data-spec="label-name"
          ></ngb-highlight>
        </label>
      }
    } @else {
      <span class="text-muted p-2 d-block w-100 text-center" data-spec="no-matching-labels">
        No matching labels
      </span>
    }
  </div>
}
<bx-inline-label-creator
  class="position-sticky"
  (createLabel)="createLabel($event)"
  data-spec="label-creator"
></bx-inline-label-creator>
