import { Observable, ReplaySubject } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { GroupedColumnChartComponent } from '../../../features/graphs/grouped-column-chart/grouped-column-chart.component';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { isComparisonClusterLengthTable } from '../table-type-filters';
import { SeriesColumnOptions } from 'highcharts';
import { ExportableChartComponent } from '../../../features/graphs/exportable-chart';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import {
  selectDataForComparisonsDocument,
  selectParamsForComparisonsDocument,
} from '../ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.selectors';
import { ComparisonsGraphDocumentState } from '../ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.reducer';
import { SelectionForGraph } from '../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-comparisons-histogram-v2',
  templateUrl: './ngs-comparisons-histogram-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GroupedColumnChartComponent, AsyncPipe],
})
export class NgsComparisonsHistogramV2Component
  extends CleanUp
  implements OnInit, OnDestroy, ExportableChartComponent
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-grow-1 flex-shrink-1 overflow-hidden';
  @ViewChild(GroupedColumnChartComponent) chartComponent: GroupedColumnChartComponent;

  @Input() documentID: string;
  message$ = new ReplaySubject(1);
  selectedParams$: Observable<ComparisonsGraphDocumentState>;
  data$: Observable<NGSGraphData>;
  documentID$: Observable<string>;

  private readonly BLACK_LISTED_COLUMNS: string[] = [`Frequency % Ratio`, `Frequency % Max Ratio`];

  // @see http://colorbrewer2.org/?type=qualitative&scheme=Paired&n=12
  private readonly colors = [
    '#1f78b4',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#a6cee3',
    '#b2df8a',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
  ];

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.selectedParams$ = this.store.select(selectParamsForComparisonsDocument(this.documentID));
    this.data$ = this.selectedParams$.pipe(
      map(({ selection }) => selection.documentId),
      switchMap((id) => this.store.select(selectDataForComparisonsDocument(id))),
      withLatestFrom(this.selectedParams$),
      map(([data, viewerData]) => {
        const documentName = viewerData.selection.documentName;
        return this.process(documentName, viewerData.selectedTable, viewerData.selection, data);
      }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.message$.complete();
  }

  exportAsImage() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.selection?.documentName),
      )
      .subscribe((documentName) => this.chartComponent.downloadImage(documentName));
  }

  exportAsTable() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.selection?.documentName),
      )
      .subscribe((documentName) =>
        this.chartComponent.downloadTable({
          documentName,
        }),
      );
  }

  private process(
    documentName: string,
    selectedTable: DocumentTable,
    selection: SelectionForGraph,
    { data, columns }: Omit<IGridResourceResponse<any>, 'metadata'>,
  ): NGSGraphData {
    // The antibody comparison pipeline outputs frequencies of pan results in columns starting with "Frequency ".
    // We should assume these are present and load data from these columns.
    const frequencyPrefix = 'Frequency ';

    const frequencyKeys = columns
      .map((column) => column.field)
      .filter(
        (name) => name.startsWith(frequencyPrefix) && !this.BLACK_LISTED_COLUMNS.includes(name),
      )
      .sort();

    if (frequencyKeys.length < 1) {
      return;
    }

    // If no rows are selected, we show them a 'top 10' frequency distribution graph based on the
    // 10 highest values in the 'Score' column.
    const scoreKeys = columns.map((column) => column.field).filter((name) => 'Score' === name);
    const onlyShowTop10 =
      data?.length > 10 &&
      scoreKeys.length > 0 &&
      !selection.selectAll &&
      selection.selectedRows.length === 0;
    if (onlyShowTop10) {
      data = data.sort((row1, row2) => {
        const total1 = row1[scoreKeys[0]];
        const total2 = row2[scoreKeys[0]];
        return total2 - total1;
      });
      data = data.slice(0, 10);
    }

    return {
      title: `Frequency distribution ${
        onlyShowTop10
          ? '(Top 10 by Score)'
          : `of ${data.length} ${data.length === 1 ? 'cluster' : 'clusters'}`
      }`,
      subtitle: documentName,
      data: NgsComparisonsHistogramV2Component.removeCommonEndings(frequencyKeys).map(
        (key, index) => ({
          name: key,
          data: this.formatData(data, frequencyKeys, selectedTable).map((d) => [
            d.label,
            d.values[index],
          ]),
          type: 'column',
          color: this.colors[index % this.colors.length],
        }),
      ),
      xAxisTitle: this.formatXAxisTitle(selectedTable.name),
    };
  }

  // TODO Find a better way to do this.
  // This assumes that the region will be in the form of light_j_gene, heavy_cdr3, vdj_region, etc.
  private formatXAxisTitle(label: string): string {
    // substr removes the undesired 'Document Table ' prefix from the region name.
    const strings = label
      .substr(15)
      .split('_')
      .map((string) => string.charAt(0).toUpperCase() + string.substring(1));

    if (label.includes('region')) {
      strings[0] = strings[0].toUpperCase();
    } else {
      strings[1] = strings[1].toUpperCase();
    }
    return strings.join(' ');
  }

  private formatData(
    data: any,
    keys: string[],
    table: DocumentTable,
  ): { label: string; values: number[] }[] {
    const suffix = '%';
    const formattedData = data.map((row: any) => ({
      label: row[table.displayName],
      values: keys
        .map((name) => row[name])
        // Remove any unit suffixes.
        .map((value) =>
          `${value}`.endsWith(suffix) ? value.substring(0, value.length - suffix.length) : value,
        )
        // Ensure it's a number.
        .map((value) => Number(value)),
    }));

    if (isComparisonClusterLengthTable(table)) {
      formattedData.sort((a: any, b: any) => a.label - b.label);
    }

    return formattedData;
  }

  static removeCommonEndings(input: string[]): string[] {
    if (input.length <= 1) {
      // Always return a _new_ array.
      return [...input];
    }

    function removePrefixesAndReverse(names: string[][]): string[][] {
      return removeCommonPrefixes(names).map((name) => name.reverse());
    }

    function removeCommonPrefixes(names: string[][]): string[][] {
      let index = 0;
      const [first, ...rest] = names;
      const length = first.length;

      while (rest.every((name) => name[index] === first[index]) && index < length) {
        index++;
      }

      // Only slice prefixes if there will still be something left.
      return index < length
        ? names.map((name) => name.slice(index))
        : // Always return a _new_ array.
          [...names];
    }

    const split = input.map((name) => name.split('_'));

    // Remove and reverse twice; once for each end.
    const result = removePrefixesAndReverse(removePrefixesAndReverse(split));

    return result.map((name) => name.join('_'));
  }
}

export interface NGSGraphData {
  title: string;
  subtitle: string;
  data: SeriesColumnOptions[];
  xAxisTitle: string;
}
