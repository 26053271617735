<bx-page-message>
  @if (restoreProgress) {
    <div class="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
      @if (restoreProgress.error; as errorMessage) {
        <div>
          @if (errorMessage === 'Failed to restore document table') {
            <h2>An error occurred while restoring the table</h2>
            <p>Try reloading the document</p>
          }
          @if (errorMessage === 'Document table index state is absent') {
            <h3>An error occurred while restoring the table</h3>
            <p>
              Please contact support
              <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a>
            </p>
            >
          }
        </div>
      } @else {
        <div>
          <h3>Restoring your table from storage</h3>
          <p>This table was archived because it was not accessed for 30 days</p>
        </div>
      }
      @if (restoreProgress.progress !== null) {
        <ngb-progressbar
          type="info"
          [value]="restoreProgress.progress"
          [striped]="true"
          [animated]="true"
          class="w-100"
        >
        </ngb-progressbar>
      }
    </div>
  }
</bx-page-message>
