import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class BatchAssembleJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'batch-assemble',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: BatchAssembleJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: BatchAssembleJobOptionsV1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface BatchAssembleJobOptionsV1 {
  groupAssemblies: boolean;
  namePart?: number;
  nameSeparator?: string;
  assembleListsSeparately: boolean;
  assembleByNameScheme: boolean;
  callChromatogramHeterozygotes: boolean;
  chromatogramHeterozygotePercentage: number;
  saveUnusedReads: boolean;
  generateContigs: boolean;
  consensusSequencesAsList: boolean;
  fileNameSchemeID: string;
}
