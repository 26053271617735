import { Component } from '@angular/core';
import { PrimersRootFolderDetailComponent } from '../primers-root-folder-detail/primers-root-folder-detail.component';
import { PrimersGridResource } from '../../primers/grid-resource/primers-grid-resource';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { ExportToolstripItemComponent } from '../../export/export-toolstrip-item/export-toolstrip-item.component';
import { GridComponent } from '../../../features/grid/grid.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-primers-folder-detail',
  templateUrl: './primer-sets-folder-detail.component.html',
  providers: [PrimersGridResource],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    ExportToolstripItemComponent,
    GridComponent,
    AsyncPipe,
  ],
})
export class PrimerSetsFolderDetailComponent extends PrimersRootFolderDetailComponent {
  deletePrimers() {
    const selectedIds = this.selected.ids;
    const selectAllState = this.selected.selectAll;

    this.folder$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap((folder) =>
          this.primersService.removePrimersFromSet(folder.id, selectAllState, selectedIds),
        ),
      )
      .subscribe((response) => this.primersTable.removeRows(selectedIds));
  }
}
