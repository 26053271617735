<div class="bx-page-container bx-page-container-xs h-100 pt-5 mt-5">
  <form #accountSetupForm="ngForm" name="accountSetupForm" (ngSubmit)="submit(accountSetupForm)">
    <bx-logo class="d-block py-5 my-3 text-center" [width]="'100%'"></bx-logo>
    @if (accountSetupErrors) {
      <div class="alert alert-danger">
        {{ accountSetupErrors }}
      </div>
    }
    <div class="mb-3">
      <label class="form-label" for="email">Email</label>
      <input
        class="form-control"
        id="email"
        name="email"
        type="email"
        required
        readonly
        [(ngModel)]="email"
      />
    </div>
    <div class="mb-3">
      <label class="form-label" for="name">Name</label>
      <input
        class="form-control"
        id="name"
        name="name"
        type="text"
        required
        readonly
        [(ngModel)]="name"
      />
    </div>

    @if (canChangePassword) {
      <div class="mb-3">
        <div class="w-100 d-flex justify-content-between">
          <label class="form-label" for="password"
            >Password{{ newPasswordRequired ? '' : ' (Optional)' }}</label
          >
          <bx-password-strength [password]="password" class="mt-1 w-25"></bx-password-strength>
        </div>
        <input
          id="password"
          name="password"
          data-spec="password-input"
          #passwordInput="ngModel"
          placeholder="Password"
          class="form-control"
          type="password"
          [required]="newPasswordRequired"
          bxValidatePassword
          [bxValidateEqual]="'confirm_password'"
          [(ngModel)]="password"
          ngFormControlValidator
          [canMarkPristineInvalid]="false"
        />
        @if (passwordInput.hasError('required')) {
          <div class="invalid-feedback">Password required</div>
        }
        @if (passwordInput.hasError('weakPassword')) {
          <div class="invalid-feedback">
            {{ accountSetupForm.form.controls.password.errors?.weakPassword }}
          </div>
        }
        @if (accountSetupForm.controls.password?.errors?.validateEqual) {
          <div class="invalid-feedback">Passwords must match</div>
        }
      </div>
      <div class="mb-3">
        <input
          id="confirm_password"
          name="confirm_password"
          data-spec="confirm-password-input"
          #confirmPasswordInput="ngModel"
          placeholder="Confirm Password"
          type="password"
          class="form-control"
          [required]="newPasswordRequired || !!accountSetupForm.form.get('password')?.value"
          [bxValidateEqual]="'password'"
          [(ngModel)]="confirmPassword"
          ngFormControlValidator
          [canMarkPristineInvalid]="false"
        />
        @if (confirmPasswordInput.hasError('required')) {
          <div class="invalid-feedback">Confirmation required</div>
        }
        @if (accountSetupForm.controls.confirm_password?.errors?.validateEqual) {
          <div class="invalid-feedback">Passwords must match</div>
        }
      </div>
    }
    <div class="d-flex justify-content-end">
      <button
        type="submit"
        class="btn btn-primary"
        id="account-setup"
        data-spec="account-setup-btn"
        [disabled]="!accountSetupForm.form.valid || processing"
        bx-spinner-button
        [spin]="processing"
      >
        Complete setup
      </button>
    </div>
  </form>
</div>
