<a
  bx-folder-tree-link
  routerLink="/jobs"
  linkIcon="sync"
  linkText="Jobs"
  [ngClass]="{ 'bg-selected-folder-tree': selected$ | async }"
  class="d-flex py-1 pe-2 align-items-center flex-nowrap"
>
  @if (status$ | async; as status) {
    <div class="status my-n1 flex-shrink-1 text-nowrap ms-auto">
      <div
        class="align-middle d-inline-flex flex-column justify-content-center align-items-end me-2"
      >
        @if (status.running) {
          <p class="running small mb-0" data-testid="job-tree-running">
            {{ status.running }} running
          </p>
        }
        @if (status.failed) {
          <p class="failed small mb-0 text-danger" data-testid="job-tree-failed">
            {{ status.failed }} failed
          </p>
        }
      </div>
      @if (status.running) {
        <bx-spinner class="align-middle ms-1"></bx-spinner>
      }
      @if (status.completed && !status.running) {
        <mat-icon data-testid="job-tree-complete" class="align-middle"> done </mat-icon>
      }
    </div>
  }
  <bx-temporary-popup message="Job started" class="me-n1"></bx-temporary-popup>
</a>
