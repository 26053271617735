<bx-toolstrip class="toolbar">
  <bx-toolstrip-item>
    <div class="d-flex flex-nowrap ms-1">
      <label for="graphType" class="col-form-label text-nowrap me-2">Graph Type:</label>
      <select id="graphType" class="form-select" (change)="onGraphChanged($event.target.value)">
        @for (graph of selectableGraphs$ | async; track graph) {
          <option [value]="graph.type">
            {{ graph.name }}
          </option>
        }
      </select>
    </div>
  </bx-toolstrip-item>

  <bx-toolstrip-item>
    <div class="btn-group" ngbDropdown>
      <button
        id="button-basic"
        [disabled]="loading$ | async"
        ngbDropdownToggle
        type="button"
        class="btn btn-light dropdown-toggle"
        aria-controls="dropdown-basic"
      >
        Export <span class="caret"></span>
      </button>
      <ul
        id="dropdown-basic"
        ngbDropdownMenu
        class="dropdown-menu"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li
          role="menuitem"
          ngbTooltip="Export this graph as a png image"
          container="body"
          placement="right"
        >
          <button ngbDropdownItem (click)="exportAsImage()">
            <span>Image (png)</span>
          </button>
        </li>
        <li
          role="menuitem"
          ngbTooltip="Export this graph as a csv"
          container="body"
          placement="right"
        >
          <button ngbDropdownItem (click)="exportAsTable()">Table (csv)</button>
        </li>
      </ul>
    </div>
  </bx-toolstrip-item>
</bx-toolstrip>
@if ({ loading: loading$ | async }; as state) {
  @if (state.loading) {
    <bx-page-message class="h-100">
      <bx-loading></bx-loading>
    </bx-page-message>
  }
  <ng-template #graph>
    <div
      class="d-flex flex-grow-1 flex-shrink-1 overflow-hidden"
      [ngClass]="{ 'd-none': state.loading, 'd-flex': !state.loading }"
    >
      @switch (selectedGraph$ | async) {
        @case ('histogram') {
          <bx-ngs-comparisons-histogram-v2 #exportable [documentID]="documentID$ | async">
          </bx-ngs-comparisons-histogram-v2>
        }
        @case ('scatterplot') {
          <bx-ngs-comparisons-scatterplot
            #exportable
            [documentID]="documentID$ | async"
          ></bx-ngs-comparisons-scatterplot>
        }
      }
    </div>
  </ng-template>
  @if (message$ | async; as message) {
    <bx-page-message>
      {{ message }}
    </bx-page-message>
  } @else {
    <div
      class="d-flex flex-grow-1 flex-shrink-1 overflow-hidden"
      [ngClass]="{ 'd-none': state.loading, 'd-flex': !state.loading }"
    >
      @switch (selectedGraph$ | async) {
        @case ('histogram') {
          <bx-ngs-comparisons-histogram-v2 #exportable [documentID]="documentID$ | async">
          </bx-ngs-comparisons-histogram-v2>
        }
        @case ('scatterplot') {
          <bx-ngs-comparisons-scatterplot
            #exportable
            [documentID]="documentID$ | async"
          ></bx-ngs-comparisons-scatterplot>
        }
      }
    </div>
  }
}
