<bx-breadcrumb [folder]="folder$ | async"></bx-breadcrumb>
<bx-toolstrip *bxHideIf="isFreeOrg">
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="newDatabase()"
      [disabled]="!(folder$ | async)?.hasWriteAccess()"
    >
      New Database
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-page-message>
  <div>
    <h2>{{ title }}</h2>
    <p class="message">{{ messages | bxTextIfElse: isFreeOrg | async }}</p>
  </div>
</bx-page-message>
