<div class="d-flex justify-content-between pt-3 px-3 mb-n5">
  <bx-logo logo="geneious-small-orange.svg" href="/login" [size]="1.5"></bx-logo>
  <bx-environment-switcher></bx-environment-switcher>
</div>
<bx-external-wrapper>
  <div class="bx-page-container bx-page-container-sm d-flex flex-column align-items-center">
    <div class="w-75">
      <bx-logo width="100%"></bx-logo>
    </div>
    <h4 class="py-4 text-muted">The page you're looking for cannot be found</h4>
  </div>
</bx-external-wrapper>
