import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { GridState } from 'src/app/features/grid/grid.interfaces';
import {
  DocumentMetadataExportOptions,
  ExportJobParameters,
  TableOutputType,
} from 'src/nucleus/services/models/exportOptions.model';
import { PipelineDialogData } from '../..';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { toColumnsState } from '../export-helpers';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import { NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { JobResultDownloaderService } from '../../../utils/job-result-downloader.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportTableControlsComponent } from '../export-table-controls/export-table-controls.component';
import { DecimalPipe } from '@angular/common';

/**
 * JobDialogContent for exporting document metadata from the files table.
 * Submits an export job with an exportFormat of "documentMetadata".
 */
@Component({
  selector: 'bx-export-document-metadata',
  templateUrl: './export-document-metadata.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ExportTableControlsComponent, DecimalPipe],
})
export class ExportDocumentMetadataComponent extends JobDialogContent implements RunnableJobDialog {
  readonly title = 'Export Table';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup({
    tableOutputType: new BxFormControl<TableOutputType>('xlsx'),
    exportedColumns: new BxFormControl<'all' | 'visible'>('all'),
  });
  readonly numRows = this.dialogData.selected.noOfRowsSelected;
  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<ExportDocumentMetadataDialogOptions>,
    private jobResultDownloaderService: JobResultDownloaderService,
  ) {
    super('export', PipelineFormID.EXPORT_DOCUMENT_METADATA);
    this.formDefaults = this.form.getRawValue();
  }

  run() {
    const formValue = this.form.getRawValue();

    const gridState = this.dialogData.otherVariables.gridState;
    const options: DocumentMetadataExportOptions = {
      exportFormat: 'documentMetadata',
      tableOutputType: formValue.tableOutputType,
      columnsState: toColumnsState(gridState.columnsState, formValue.exportedColumns === 'all'),
    };
    const parameters: ExportJobParameters = {
      options,
      selection: {
        folderId: this.dialogData.folderID,
        ids: this.dialogData.selected.ids,
        selectAll: this.dialogData.selected.selectAll,
      },
    };
    return {
      pipeline: { name: 'export', version: VersionEnum.Latest },
      parameters,
    };
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
      newJobResponse.data.jobID,
      'EXPORTED_FILE',
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}

export interface ExportDocumentMetadataDialogOptions {
  gridState: GridState;
}
