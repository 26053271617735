import { createSelector } from '@ngrx/store';
import { selectUserSettingsState } from '../user-settings.selectors';
import { SequenceViewerPreferencesState } from './sequence-viewer-preferences.model';
import { mergeNestedObjects } from '../../../shared/utils/object';

export const selectSequenceViewerPreferences = createSelector(
  selectUserSettingsState,
  (state) => state.sequenceViewerPreferences,
);

export const selectSequenceViewerPreferencesGlobal = createSelector(
  selectSequenceViewerPreferences,
  (sequenceViewerPreferences) =>
    sequenceViewerPreferences.global || ({} as SequenceViewerPreferencesState),
);

export const selectSequenceViewerPreference = (target: string) =>
  createSelector(selectSequenceViewerPreferences, (sequenceViewerPreferences) => {
    const preference =
      sequenceViewerPreferences[target] ||
      sequenceViewerPreferences.global ||
      ({} as SequenceViewerPreferencesState);
    // The reason for this merge is because, for each document we only save a subset of sequence viewer preferences.
    //   Things like Translation or Annotations plugin options are saved in global setting & shared for all documents.
    //   Therefore, each document preferences must be combined with the global options to form a complete setting.
    return mergeNestedObjects(preference, sequenceViewerPreferences.global ?? {});
  });
