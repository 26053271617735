import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { OverlayEvent, OverlayEventType, OverlayMessageEvent } from './overlays-events.model';
import { filter } from 'rxjs/operators';

@Injectable()
export class OverlaysService implements OnDestroy {
  private readonly overlayEvents$: ReplaySubject<OverlayEvent>;

  constructor() {
    this.overlayEvents$ = new ReplaySubject(1);
  }

  ngOnDestroy(): void {
    this.overlayEvents$.complete();
  }

  getEvents(): Observable<OverlayEvent> {
    return this.overlayEvents$.asObservable();
  }

  getEventsByKey(id: string): Observable<OverlayEvent> {
    return this.overlayEvents$.asObservable().pipe(filter((event) => event.id === id));
  }

  createMessageEvent(id: string, message: string): void {
    const event: OverlayEvent<OverlayMessageEvent, OverlayEventType.MESSAGE> = {
      id: id,
      type: OverlayEventType.MESSAGE,
      data: {
        message: message,
      },
    };
    this.overlayEvents$.next(event);
  }

  createHTMLMessageEvent(id: string, message: string): void {
    const event: OverlayEvent<OverlayMessageEvent, OverlayEventType.MESSAGE> = {
      id: id,
      type: OverlayEventType.MESSAGE,
      data: {
        html: message,
      },
    };
    this.overlayEvents$.next(event);
  }

  createLoadingEvent(id: string, message?: string): void {
    const event: OverlayEvent<OverlayMessageEvent, OverlayEventType.LOADING> = {
      id: id,
      type: OverlayEventType.LOADING,
      data: {
        message: message,
      },
    };
    this.overlayEvents$.next(event);
  }

  hideOverlay(id: string) {
    this.overlayEvents$.next({ id: id, type: OverlayEventType.HIDE });
  }
}
