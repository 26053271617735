@if (shouldShowPreviewText$ | async) {
  <div class="px-2 pt-2">
    <div
      class="alert alert-info mb-0 d-flex justify-content-between align-items-center"
      role="alert"
    >
      <div></div>
      <div class="text-center">
        <b>Preview Mode:</b> To perform advanced operations, please view the full document.
      </div>
      <bx-open-document-button
        [openQueryParams]="openTabQueryParams$ | async"
        buttonColor="primary"
      ></bx-open-document-button>
    </div>
  </div>
}
<div class="container bx-page-container bx-page-container-sm py-3">
  <bx-document-summary-card
    [documentID$]="documentID$"
    [documentHistory$]="documentHistory$"
    [usersByID$]="usersByID$"
  ></bx-document-summary-card>
  <bx-document-history-card
    [documentHistory$]="documentHistory$"
    [usersByID$]="usersByID$"
    [selectionChanged$]="selectionChanged$"
  ></bx-document-history-card>
  <bx-document-permissions-card [folder$]="folder$"></bx-document-permissions-card>
  <div class="my-5"></div>
</div>
