import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Subject, map, startWith, switchMap } from 'rxjs';
import { CleanUp } from '../cleanup';
import { PasswordStrengthService } from './password-strength.service';
import { NgClass, NgStyle, AsyncPipe } from '@angular/common';

/**
 * This component is just the view of the password meter.
 *
 * To display a password input's strength:
 * 1. Add the bxValidatePassword directive to the input
 * 2. Ensure the input has a name attribute
 * 3. Use that input's name as the [password] input to this component
 * 4. Use the 'weakPassword' error on the form controls to get the password suggestions
 *    (this is populated by the bxValidatePassword directive)
 * 5. Set a width for the component
 *
 * e.g.,
 * <bx-password-strength class="w-100" [password]="myPassword"></bx-password-strength>
 * <input type="password" name="myPassword" bxValidatePassword>
 */
@Component({
  selector: 'bx-password-strength',
  templateUrl: './password-strength.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, AsyncPipe],
})
export class PasswordStrengthComponent extends CleanUp {
  @HostBinding('class') readonly hostClass = 'd-block h-100';

  @Input() set password(password: string) {
    this.password$.next(password);
  }

  readonly password$ = this.completeOnDestroy(new Subject<string>());
  readonly strength$ = this.password$.pipe(
    switchMap((password) => this.passwordStrengthService.score(password)),
    // Always show a little bit of the bar.
    map(({ score }) => 10 + score * 90),
  );
  readonly valid$ = this.password$.pipe(
    switchMap((password) => this.passwordStrengthService.validate(password)),
    startWith(false),
  );

  constructor(private readonly passwordStrengthService: PasswordStrengthService) {
    super();
  }
}
