import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DownloadBlobService } from '../../../core/download-blob.service';
import { GraphUtilService } from '../graph-util.service';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import { PlotOptions, SeriesColumnOptions } from 'highcharts';
import { groupedColumnChartTooltipFormatter } from '../tooltips/grouped-column-chart-tooltip';
import { DownloadTableOptions } from '../exportable-chart';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'bx-grouped-column-chart',
  templateUrl: '../abstract-column-chart/base-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class GroupedColumnChartComponent extends BaseChartComponent<SeriesColumnOptions> {
  @Input() set highlightOnlyHoveredColumn(value: boolean) {
    this.plotOptions = {
      ...this.plotOptions,
      column: {
        inactiveOtherPoints: value,
      } as any, // because this doesn't appear in the PlotColumnOptions interface for some reason.
    };
  }

  tooltipOptions = {
    formatter: groupedColumnChartTooltipFormatter,
  };

  plotOptions: PlotOptions = {
    series: {
      turboThreshold: 0,
    },
    column: {
      events: {
        legendItemClick: () => false,
      },
    },
  };

  downloadTable({ documentName }: DownloadTableOptions): void {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;
    const headers = `${this.xAxisOptions.title.text},${this.series.map((s) => s.name).join(',')}\n`;
    const body = this.series[0].data
      .map((point) => {
        const pointX = GraphUtilService.getPointX(point);
        const values = this.series.map((series) => {
          const match = series.data.find((p) => GraphUtilService.getPointX(p) === pointX);
          return GraphUtilService.getPointY(match);
        });
        return `${pointX},${values.join(',')}`;
      })
      .join('\n');
    DownloadBlobService.download(`${name}.csv`, headers + body);
  }
}
