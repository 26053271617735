import { FilesTableState, FilesTableStateEdit } from './files-table-state.model';
import {
  Actions as FilesTableActions,
  clearFilesTableState,
  clearFilesTableStateFiles,
  replaceAllFilesState,
} from './files-table.actions';
import { adapter as filesAdapter, filesReducer } from './files-store/files.reducer';
import {
  ActionsUnion as FilesActions,
  replaceAllFiles,
  removeFiles,
  updateFileBlobRevision,
  updateFileName,
  updateFileNameSuccess,
  updateFileNameFailure,
  moveFiles,
  moveFilesSuccess,
  moveFilesFailure,
  removeFilesSuccess,
  removeFilesFailure,
  EditActionsUnion,
  addFiles,
} from './files-store/files.actions';
import { FilesState } from './files-store/files-state.model';
import { EntityState } from '@ngrx/entity';
import { Item } from '@geneious/nucleus-api-client';

export const initialState: FilesTableState = {
  files: filesAdapter.getInitialState(),
  pendingEdits: {},
  shortIDs: {},
};

export function filesTableReducer(
  state: FilesTableState = initialState,
  action: FilesTableActions | FilesActions,
): FilesTableState {
  switch (action.type) {
    case replaceAllFilesState.type: {
      const remainingEdits: FilesTableStateEdit[] = [];
      const currentEdits: FilesTableStateEdit[] =
        !state.pendingEdits[action.payload.folderID] || action.payload.removeEdits
          ? []
          : state.pendingEdits[action.payload.folderID];
      const files = currentEdits.reduce(
        (agg, edit) => {
          // If blobRevision from the new files state is greater than an existing edit action,
          // then don't apply the edit action as the server has newer state than the client.
          if ('blobRevision' in edit.action.payload && 'fileID' in edit.action.payload) {
            const existingFile = agg.entities[edit.action.payload.fileID];
            if (
              existingFile &&
              existingFile.metadata.blobRevision > edit.action.payload.blobRevision
            ) {
              return agg;
            }
          }

          const newFilesState = filesReducer(agg, edit.action);
          // Remove edit event if replaceAllFiles payload already has the change.
          if (newFilesState !== agg && !action.payload.removeEdits && !('success' in edit)) {
            remainingEdits.push(edit);
          }
          return newFilesState;
        },
        filesReducer(state.files, replaceAllFiles({ payload: { files: action.payload.files } })),
      );
      return {
        ...state,
        folderID: action.payload.folderID,
        files,
        shortIDs: createShortIDs(files.ids),
        pendingEdits: {
          ...state.pendingEdits,
          [action.payload.folderID]: remainingEdits,
        },
      };
    }

    case clearFilesTableState.type: {
      return initialState;
    }

    case clearFilesTableStateFiles.type: {
      return {
        files: initialState.files,
        pendingEdits: state.pendingEdits,
        shortIDs: {},
      };
    }

    case updateFileName.type: {
      const inverseAction = updateFileName({
        payload: {
          fileID: action.payload.fileID,
          name: state.files.entities[action.payload.fileID].metadata.name,
          blobRevision: action.payload.blobRevision,
        },
      });
      return {
        ...state,
        files: filesReducer(state.files, action),
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: addEditAction(state, action, inverseAction),
        },
      };
    }

    case updateFileNameSuccess.type: {
      const fileID: string = action.payload.action.payload.fileID;
      const existingBlobRevision = state.files.entities[fileID]
        ? state.files.entities[fileID].metadata.blobRevision
        : undefined;
      const validBlobRevision = action.payload.newBlobRevision >= existingBlobRevision;
      const newFiles = validBlobRevision
        ? filesReducer(
            state.files,
            updateFileBlobRevision({
              payload: {
                fileID: fileID,
                blobRevision: action.payload.newBlobRevision,
              },
            }),
          )
        : state.files;
      return {
        ...state,
        files: newFiles,
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: updateEditActionStatus(state, action.payload.action, true),
        },
      };
    }

    case updateFileNameFailure.type: {
      const fileID = action.payload.action.payload.fileID;
      const blobRevision = action.payload.action.payload.blobRevision;
      // `removeEditsActionWithBlobRevisions` causes a mutation to the state and thus needs to run before the ...state destruct occurs.
      const edits = removeEditsActionWithBlobRevisions(state, fileID, blobRevision);

      return {
        ...state,
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: edits,
        },
      };
    }

    case removeFiles.type: {
      const inverseAction = addFiles({
        payload: {
          files: getIDsFromSelectAll(
            state.files,
            action.payload.fileIDs,
            action.payload.selectAll,
          ).map((id) => state.files.entities[id]),
        },
      });

      const files = filesReducer(state.files, action);
      return {
        ...state,
        files,
        shortIDs: createShortIDs(files.ids),
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: addEditAction(state, action, inverseAction),
        },
      };
    }

    case removeFilesSuccess.type: {
      return {
        ...state,
        files: filesReducer(state.files, action),
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: updateEditActionStatus(state, action.payload.action, true),
        },
      };
    }

    case removeFilesFailure.type: {
      return {
        ...state,
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: removeEditAction(state, action.payload.action),
        },
      };
    }

    case moveFiles.type: {
      const inverseAction = addFiles({
        payload: {
          files: Object.keys(state.files.entities)
            .filter((itemID) => action.payload.fileIDs.includes(itemID))
            .map((itemID) => state.files.entities[itemID]),
        },
      });

      const files = filesReducer(state.files, action);
      return {
        ...state,
        files,
        shortIDs: createShortIDs(files.ids),
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: addEditAction(state, action, inverseAction),
        },
      };
    }

    case moveFilesSuccess.type: {
      return {
        ...state,
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: updateEditActionStatus(state, action.payload.action, true),
        },
      };
    }

    case moveFilesFailure.type: {
      return {
        ...state,
        pendingEdits: {
          ...state.pendingEdits,
          [state.folderID]: removeEditAction(state, action.payload.action),
        },
      };
    }

    default:
      return state;
  }
}

function addEditAction(
  state: FilesTableState,
  action: EditActionsUnion,
  inverseAction: EditActionsUnion,
  success?: boolean,
): FilesTableStateEdit[] {
  const currentEdits = state.pendingEdits[state.folderID] ? state.pendingEdits[state.folderID] : [];
  const edit: FilesTableStateEdit = {
    action: action,
    inverseAction: inverseAction,
  };

  if (success != null) {
    edit.success = success;
  }

  return [...currentEdits, ...[edit]];
}

function removeEditAction(state: FilesTableState, action: EditActionsUnion): FilesTableStateEdit[] {
  const edits = state.pendingEdits[state.folderID] ? state.pendingEdits[state.folderID] : [];
  return edits.filter((edit) => {
    // Revert action
    if (edit.action === action) {
      state.files = filesReducer(state.files, edit.inverseAction);
    }

    return edit.action !== action;
  });
}

function removeEditsActionWithBlobRevisions(
  state: FilesTableState,
  fileID: string,
  blobRevision: number,
): FilesTableStateEdit[] {
  const edits = state.pendingEdits[state.folderID] ? state.pendingEdits[state.folderID] : [];
  return edits.filter((edit) => {
    const sameFile =
      'fileID' in edit.action.payload &&
      edit.action.payload.fileID &&
      edit.action.payload.fileID === fileID;
    const editBlobRevision =
      'blobRevision' in edit.action.payload ? edit.action.payload.blobRevision : undefined;
    const sameBlobRevision = editBlobRevision === blobRevision;
    const blobRevisionGreaterThan = editBlobRevision >= blobRevision;

    // Revert action
    if (sameFile && sameBlobRevision) {
      state.files = filesReducer(state.files, edit.inverseAction);
    }

    return !(sameFile && blobRevisionGreaterThan);
  });
}

function updateEditActionStatus(
  state: FilesTableState,
  action: EditActionsUnion,
  success: boolean,
): FilesTableStateEdit[] {
  const edits = state.pendingEdits[state.folderID] ? state.pendingEdits[state.folderID] : [];
  return edits.map((edit) => {
    if (edit.action === action) {
      return {
        ...edit,
        success: success,
      };
    }

    return edit;
  });
}

function getIDsFromSelectAll(files: FilesState, ids: string[], selectAll: boolean): string[] {
  if (selectAll) {
    return Object.keys(files.entities).filter((id) => !ids.includes(id));
  } else {
    return Object.keys(files.entities).filter((something) => ids.includes(something));
  }
}

/**
 * Constructs a map from row IDs to their shortened equivalents. IDs are
 * trimmed from a 36-character GUID to an 8-character string so that 4x as
 * many IDs can fit in the maximum URL length of 2048 characters.
 *
 * This introduces a very small chance that IDs will collide: given a short ID
 * length of 8 hexadecimal characters, and a folder with 100 rows, there is a
 * 0.00012% (or 1 in 859,000) chance of two files having the same short ID
 * (see https://en.wikipedia.org/wiki/Birthday_problem#Approximations).
 *
 * If a clash is detected while shortening IDs, the full GUID will be used for
 * the clashing rows. There is an extremely small chance that a clash could go
 * undetected if a new document was added to the folder after the URL was
 * created, and in this case, rowIDsToSelect$ clears the selection.
 *
 * @param rowIDs a list of row IDs
 * @returns a map from the original IDs to the shortened versions
 */
function createShortIDs(rowIDs: EntityState<Item>['ids']): { [originalID: string]: string } {
  const sortedRowIDs = rowIDs.map((id) => id.toString()).sort();
  const shortIDs: { [guid: string]: string } = {};
  let lastID = '';
  for (let i = 0; i < sortedRowIDs.length; i++) {
    const id = sortedRowIDs[i];
    const shortID = id.slice(0, 8);
    if (shortID !== lastID) {
      shortIDs[id] = shortID;
      lastID = shortID;
    } else {
      // Use full GUIDs in the unlikely event of a clash
      const lastIDFull = sortedRowIDs[i - 1];
      shortIDs[lastIDFull] = lastIDFull;
      shortIDs[id] = id;
    }
  }
  return shortIDs;
}
