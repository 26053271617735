import { createAction, props } from '@ngrx/store';
import { UISettingState } from './ui-settings.model';

export const toggleGridSidebarHiddenState = createAction(
  '[UI Settings] Toggle Grid Sidebar Hidden State',
  props<{ targetComponent: string; hidden: boolean }>(),
);

export const fetchUISetting = createAction(
  '[UI Settings] Fetch from server',
  props<{
    targetComponent: string;
  }>(),
);

export const setUISetting = createAction(
  '[UI Settings] Set',
  props<{
    targetComponent: string;
    setting: UISettingState[string];
  }>(),
);
