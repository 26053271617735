import { createSelector } from '@ngrx/store';
import { JobActivityEventKind } from '../../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { AppState } from '../../../core.store';
import { adapter } from './jobs-status.reducer';

export const selectJobsStatusState = (state: AppState) => state.jobsStatus;

const selectAllJobs = createSelector(selectJobsStatusState, adapter.getSelectors().selectAll);

export const selectJobsStatus = createSelector(selectAllJobs, (jobs) => {
  let running = 0;
  let completed = 0;
  let failed = 0;
  let cancelled = 0;

  for (const job of jobs) {
    switch (job.status) {
      case JobActivityEventKind.JOB_QUEUED:
      case JobActivityEventKind.JOB_STARTED: {
        running++;
        break;
      }
      case JobActivityEventKind.JOB_COMPLETED: {
        completed++;
        break;
      }
      case JobActivityEventKind.JOB_FAILED: {
        failed++;
        break;
      }
      case JobActivityEventKind.JOB_CANCELLED: {
        cancelled++;
        break;
      }
    }
  }

  return {
    running,
    completed,
    failed,
    cancelled,
  };
});

export const selectNewJob = createSelector(selectJobsStatusState, (state) => state.newJob);
