import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import {
  fetchUserSettingFromServer,
  fetchUserSettingNotFound,
  fetchUserSettingSuccess,
  upsertUserSetting,
} from '../user-settings.actions';
import { UserSettingsKinds } from '../user-settings-types';
import { fetchDismissibleState, setDismissibleState } from './dismissible.actions';

@Injectable()
export class DismissibleEffects {
  /** Fetches a single user setting for a dismissible. */
  readonly fetchDismissibleState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDismissibleState),
      map(({ name }) =>
        fetchUserSettingFromServer({ name, kind: UserSettingsKinds.DISMISSIBLE_STATE }),
      ),
    ),
  );

  /** Sets the dismissible state to loading while the request is in progress. */
  readonly setDismissibleStateLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDismissibleState),
      map(({ name }) => setDismissibleState({ name, state: { loading: true, dismissed: null } })),
    ),
  );

  /** Sets the dismissible state to the value contained in the retrieved user setting. */
  readonly fetchDismissibleStateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingSuccess),
      filter(({ userSetting }) => userSetting.kind === UserSettingsKinds.DISMISSIBLE_STATE),
      map(({ userSetting }) =>
        setDismissibleState({
          name: userSetting.name,
          state: {
            loading: false,
            dismissed: (userSetting.data as { dismissed: boolean }).dismissed,
          },
        }),
      ),
    ),
  );

  /** Sets the dismissible state to false, as no user setting was found. */
  readonly fetchDismissibleStateNotFound$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingNotFound),
      filter(({ kind }) => kind === UserSettingsKinds.DISMISSIBLE_STATE),
      map(({ name }) =>
        setDismissibleState({
          name,
          state: { loading: false, dismissed: false },
        }),
      ),
    ),
  );

  /** Scans for user setting updates to dismissibles and applies any changes in state to the store. */
  readonly updateDismissibleState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertUserSetting),
      filter(({ kind }) => kind === UserSettingsKinds.DISMISSIBLE_STATE),
      map(({ name, data }) =>
        setDismissibleState({
          name,
          state: {
            loading: false,
            dismissed: (data as { dismissed: boolean }).dismissed,
          },
        }),
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}
