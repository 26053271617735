import { map, startWith } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CdkStepper } from '@angular/cdk/stepper';
import { SelectionState } from '../../../features/grid/grid.component';
import { AntibodyAnnotatorComponent } from '../../pipeline-dialogs/antibody-annotator/antibody-annotator.component';
import { getSequenceCount, Item } from '../../../../nucleus/v2/models/item.v2.model';
import { AnnotatedPluginDocument } from '../../geneious';
import {
  MasterDatabaseImporterJobOptionsV1,
  MasterDatabaseImporterJobParameters,
} from '../../../../nucleus/services/models/masterDatabaseImporterOptions.model';
import { SelectionOptionsV1 } from '../../../../nucleus/services/models/jobParameters.model';
import { ANTIBODY_TYPES } from '../antibody-type/antibody-type';
import { sequenceImportValidator } from './sequence-import-validator';
import { StepperComponent } from '../../../shared/stepper/stepper.component';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { JobStepperDialog } from '../../dialogV2/job-stepper-dialog';
import {
  PIPELINE_DIALOG_DATA,
  PipelineDialogData,
} from '../../pipeline-dialogs/pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { StepComponent } from '../../../shared/stepper/step/step.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FilePickerComponent } from '../../../shared/file-picker/file-picker.component';

type Alphabet = 'NUCLEOTIDE' | 'PROTEIN';

@Component({
  selector: 'bx-master-database-form-dialog',
  templateUrl: './master-database-form-dialog.component.html',
  providers: [CdkStepper],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StepperComponent,
    StepComponent,
    NgFormControlValidatorDirective,
    FilePickerComponent,
    AntibodyAnnotatorComponent,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class MasterDatabaseFormDialogComponent
  extends JobStepperDialog
  implements OnInit, OnDestroy, RunnableJobDialog
{
  @ViewChild(AntibodyAnnotatorComponent, { static: true })
  antibodyAnnotatorComponent: AntibodyAnnotatorComponent;
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;

  readonly sequenceAlphabet: Readonly<{ name: string; value: Alphabet }>[] = [
    {
      name: 'Nucleotide',
      value: 'NUCLEOTIDE',
    },
    {
      name: 'Amino Acid',
      value: 'PROTEIN',
    },
  ];
  readonly antibodyTypes = ANTIBODY_TYPES;
  readonly MAX_SEQUENCES = 1000;

  title = 'Create new Collection';
  earlyRelease = true;
  knowledgeBaseArticle = 'https://help.geneiousbiologics.com/hc/en-us/articles/360051956892';
  document: AnnotatedPluginDocument;
  creatingDatabase = false;
  noImportSequenceFilesSelectedMessage = 'Select Sequence documents to add to Collection';
  readonly form = new FormGroup({
    details: new FormGroup({
      databaseName: new FormControl<string>(undefined, Validators.required),
      description: new FormControl<string>(undefined),
      alphabet: new FormControl<Alphabet>(this.sequenceAlphabet[0].value, Validators.required),
      antibodyType: new FormControl(this.antibodyTypes[0].value, Validators.required),
    }),
    sequencesImport: new FormGroup({
      sequencesSelected: new FormControl<Item[]>([]),
    }),
    // This is set in ngOnInit
    annotator: new UntypedFormGroup({}),
  });
  selectedDocuments$ = new BehaviorSubject<Item[]>([]);
  selectionState$: Observable<SelectionState>;
  filter$: Observable<(item: Item) => boolean>;
  totalSequences$: Observable<number>;
  annotationOptionsIsDisabled$: Observable<boolean>;

  get sequencesSelectedControl(): FormControl {
    return this.form.get('sequencesImport').get('sequencesSelected') as FormControl;
  }

  private formDefaults: any;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<{ creatingDatabase: boolean }>,
  ) {
    super('master-database-importer', PipelineFormID.MASTER_DATABASE_IMPORTER);
    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit(): void {
    this.document = this.dialogData.selected.selectedRows[0];
    this.creatingDatabase = this.dialogData.otherVariables
      ? this.dialogData.otherVariables.creatingDatabase
      : false;

    this.form.get('sequencesImport').setValidators(sequenceImportValidator(this.creatingDatabase));

    if (this.creatingDatabase) {
      this.noImportSequenceFilesSelectedMessage += ' (optional)';
    } else {
      this.title = 'Import sequences into Collection';
      this.knowledgeBaseArticle =
        'https://help.geneiousbiologics.com/hc/en-us/articles/360051995352';
    }

    this.selectionState$ = this.selectedDocuments$.pipe(
      map((sequences) =>
        sequences.map((sequence) => AnnotatedPluginDocument.fromNucleusItem(sequence)),
      ),
      map((sequences) => {
        return {
          selectedRows: sequences,
          ids: sequences.map((sequence) => sequence.id),
          selectAll: false,
          firstRow: sequences[0],
          noOfRowsSelected: sequences.length,
          totalNoOfRows: sequences.length,
        };
      }),
    );

    this.totalSequences$ = this.selectedDocuments$.pipe(
      map((docs: Item[]) => getSequenceCount(docs)),
    );

    this.annotationOptionsIsDisabled$ = this.totalSequences$.pipe(
      map((sequences) => sequences === 0),
    );

    if (this.creatingDatabase) {
      const alphabet = this.form.get('details').get('alphabet');
      this.filter$ = alphabet.valueChanges.pipe(
        map((type) => this.sequenceFilter(type)),
        startWith(this.sequenceFilter(alphabet.value)),
      );
    } else {
      // TODO Get where this is actually saved. Probably on the database folder.
      const alphabet = this.document.getAllFields().alphabet;

      this.filter$ = of(this.sequenceFilter(alphabet));
    }

    this.addAntibodyAnnotatorFormControls(this.antibodyAnnotatorComponent);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.selectedDocuments$.complete();
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  run() {
    const selectedSequenceIDs = this.selectedDocuments$.value.map((sequence) => sequence.id);
    const pipelineOptions: MasterDatabaseImporterJobOptionsV1 = {};

    if (this.creatingDatabase) {
      pipelineOptions.creationOptions = {
        parentID: this.dialogData.folderID,
        name: this.form.value.details.databaseName,
        description: this.form.value.details.description,
        alphabet: this.form.value.details.alphabet,
        antibodyType: this.form.value.details.antibodyType,
      };
    }

    if (selectedSequenceIDs.length > 0) {
      pipelineOptions.rawSequencesImporterOptions = {
        sequenceDocumentIDs: selectedSequenceIDs,
        antibodyAnnotatorOperationOptions:
          this.antibodyAnnotatorComponent.generateJobOptions().optionValues,
      };
    }

    const selection: SelectionOptionsV1 = {
      selectAll: false,
      folderId: this.dialogData.folderID,
      ids: this.creatingDatabase ? [] : [this.document.id],
    };

    return new MasterDatabaseImporterJobParameters(selection, pipelineOptions);
  }

  sequencesChanged(sequences: Item[]) {
    this.selectedDocuments$.next(sequences);
  }

  sequenceFilter(alphabet: string): (items: Item) => boolean {
    return (item: Item) => {
      const isAminoAcid =
        item.metadata.molType === 'AA' || Number(item.metadata.proteinSequenceCount) > 0;

      return (
        alphabet === undefined ||
        (alphabet === 'PROTEIN' && isAminoAcid) ||
        (alphabet === 'NUCLEOTIDE' && !isAminoAcid)
      );
    };
  }

  private addAntibodyAnnotatorFormControls(antibodyAnnotatorComponent: AntibodyAnnotatorComponent) {
    this.formDefaults = {
      ...this.formDefaults,
      annotator: antibodyAnnotatorComponent.getFormDefaults(),
    };
    this.form.setControl('annotator', antibodyAnnotatorComponent.form);
    antibodyAnnotatorComponent.form.setParent(this.form);
  }
}
