import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NucleusDataResponseV2 } from '../nucleus.model';
import { FileUploadGroup } from '../../app/core/models/file-upload.model';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app/app.config';

@Injectable({
  providedIn: 'root',
})
export class UploadsTableHttpService {
  uploadEndpoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {
    this.uploadEndpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/uploads`;
  }

  getUploadGroups(): Observable<FileUploadGroup[]> {
    return this.http
      .get<NucleusDataResponseV2<FileUploadGroup[]>>(this.uploadEndpoint)
      .pipe(map((response) => response.data));
  }

  getUploadGroup(uploadGroupID: string): Observable<FileUploadGroup> {
    return this.http
      .get<NucleusDataResponseV2<FileUploadGroup>>(this.uploadEndpoint + '/' + uploadGroupID)
      .pipe(map((response) => response.data));
  }
}
