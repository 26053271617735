@if (datasource) {
  <bx-toolstrip class="toolbar d-flex justify-content-between">
    <bx-toolstrip-item>
      <button type="button" class="btn btn-light btn-pad text-nowrap" (click)="exportGraph()">
        Export As Image
      </button>
    </bx-toolstrip-item>
    @if (zoomControls$ | async; as zoomControls) {
      <bx-toolstrip-item>
        <bx-graph-zoom [zoomControls]="zoomControls"></bx-graph-zoom>
      </bx-toolstrip-item>
    }
    <bx-toolstrip-item><div class="p-2"></div></bx-toolstrip-item>
  </bx-toolstrip>
  <bx-chart-presenter [datasource]="datasource"> </bx-chart-presenter>
} @else {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
}
