<form [formGroup]="form">
  <div class="mb-3 row" data-spec="create-ref-db-annotation-ref-control">
    <div class="col-5">
      <label for="referenceDatabase" class="col-form-label"> Annotation reference: </label>
    </div>
    <div class="col-7">
      <select
        [formControl]="form.controls.referenceDatabase"
        class="form-select mt-0"
        id="referenceDatabase"
        data-spec="create-ref-db-annotation-ref-input"
      >
        @for (db of referenceDBs$ | async; track db) {
          <option [value]="db.value" data-spec="create-ref-db-annotation-ref-option">
            {{ db.name }}
          </option>
        }
      </select>
    </div>
  </div>

  @if (databaseType === DatabaseTypeEnum.GERMLINE) {
    <fieldset class="mb-3 row" data-spec="gene-source-control">
      <div class="col-5">
        <legend class="col-form-label">Gene name source:</legend>
      </div>
      <div class="col-7">
        <div class="form-check" data-spec="gene-source-seq-name-option">
          <input
            type="radio"
            id="geneSourceSequenceName"
            value="sequenceName"
            [formControl]="form.controls.geneSourceName"
            name="geneSourceName"
            class="form-check-input"
            data-spec="gene-source-seq-name-input"
          />
          <label for="geneSourceSequenceName" class="form-check-label">
            <span>Sequence Names</span>
            <p class="small text-muted mb-1">
              Detect genes based on IMGT-formatted sequence names (eg. "IGHV1-2").
            </p>
          </label>
        </div>
        <div class="form-check" data-spec="gene-source-seq-list-name-option">
          <input
            type="radio"
            id="geneSourceSequenceListName"
            value="sequenceListName"
            name="geneSourceName"
            [formControl]="form.controls.geneSourceName"
            class="form-check-input"
            data-spec="gene-source-seq-list-name-input"
          />
          <label for="geneSourceSequenceListName" class="form-check-label">
            <span>Sequence List Names</span>
            <p class="small text-muted mb-1">
              Detect genes based on IMGT-formatted sequence list names (e.g. "IGHV").
            </p>
          </label>
        </div>
      </div>
    </fieldset>
  }

  @if (databaseType === DatabaseTypeEnum.TEMPLATE) {
    <fieldset class="mb-3 row" data-spec="sequences-chain-control">
      <div class="col-5">
        <legend class="col-form-label">Selected sequences are:</legend>
      </div>
      <div class="col-7">
        <div class="form-check" data-spec="sequences-chain-heavy-option">
          <input
            type="radio"
            id="heavyChain"
            value="heavyChain"
            [formControl]="form.controls.sequencesChain"
            name="sequencesChain"
            class="form-check-input"
            data-spec="sequences-chain-heavy-input"
          />
          <label for="heavyChain" class="form-check-label">
            <span>Heavy</span>
          </label>
        </div>
        <div class="form-check" data-spec="sequences-chain-light-option">
          <input
            type="radio"
            id="lightChain"
            value="lightChain"
            [formControl]="form.controls.sequencesChain"
            name="sequencesChain"
            class="form-check-input"
            data-spec="sequences-chain-light-input"
          />
          <label for="lightChain" class="form-check-label">
            <span>Light</span>
          </label>
        </div>
        <div class="form-check" data-spec="sequences-chain-unknown-option">
          <input
            type="radio"
            id="singleUnknownChain"
            value="singleUnknownChain"
            [formControl]="form.controls.sequencesChain"
            name="sequencesChain"
            class="form-check-input"
            data-spec="sequences-chain-unknown-input"
          />
          <label for="singleUnknownChain" class="form-check-label">
            <span>Either Heavy or Light</span>
          </label>
        </div>
      </div>
    </fieldset>
  }
</form>
