<div class="modal-header">
  <h4 class="modal-title">Report Successful</h4>

  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body px-4">
  <span>
    We will be in touch as soon as possible.<br />
    To view updates on the support request go to <a target="_blank" [href]="link">{{ link }}</a
    >.<br />
    You will also receive email updates.<br />
  </span>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
</div>
