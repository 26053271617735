import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import { createReducer, on } from '@ngrx/store';
import { comparisonsGraphDataActions } from './ngs-comparisons-graph-data-store.actions';
import { SelectionForGraph } from '../../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';

export const initialState: ComparisonsGraphState = {};

export interface ComparisonsGraphDocumentState {
  selection: SelectionForGraph;
  selectedTable: DocumentTable;
  filter: string;
  data: any[];
  columns: any[];
  loading: boolean;
}

export interface ComparisonsGraphState {
  [documentId: string]: ComparisonsGraphDocumentState;
}

export const ngsComparisonsGraphDataReducer = createReducer(
  initialState,
  on(comparisonsGraphDataActions.updateSelection, (state, newSelection) => {
    const id = newSelection.id;
    return {
      ...state,
      [id]: {
        ...state[id],
        ...newSelection,
        data: [],
        columns: [],
        loading: true,
      },
    };
  }),
  on(comparisonsGraphDataActions.fetchDataSuccess, (state, { id, data }) => {
    return {
      ...state,
      [id]: {
        ...state[id],
        data: data.data,
        columns: data.columns,
        loading: false,
      },
    };
  }),
  on(comparisonsGraphDataActions.fetchDataFail, (state, { id }) => {
    return {
      ...state,
      [id]: {
        ...state[id],
        data: [],
        columns: [],
        loading: false,
      },
    };
  }),
);
