/**
 * Options for mapping an input field into an aggregate output field during an alignment.
 *  The output field's code is generated based on the reducer kind, in the form
 * `BX_AGGREGATE_{OriginalCodeMinusBxPrefix} - {CamelCasedReducerKind}`.
 * e.g.
 * ```
 * - "BX_Score"               ->  "BX_AGGREGATE_Score - Mean"
 * - "BX_ASSAY_DATA_Score"    ->  "BX_AGGREGATE_ASSAY_DATA_Score - Mean"
 * - "Score"                  ->  "BX_AGGREGATE_Score - Mean"
 * ```
 */
export interface FieldMapper {
  /**
   * A field must meet all of these conditions for it to be mapped to an aggregate output field.
   */
  matchers: FieldMatcher[];
  /**
   * The type of reducer that should be used to generate the output field's value.
   * @see {@link FieldReducerKind}
   */
  reducer: { reducerKind: FieldReducerKind };
}

/** Enum containing the types of field matcher */
export const FieldMatchKind = {
  CODE_EQUALS: 'codeEquals',
  CODE_STARTS_WITH: 'codeStartsWith',
  CODE_ENDS_WITH: 'codeEndsWith',
  VALUE_TYPE_IS: 'valueTypeIs',
  NOT: 'not',
} as const;

export type FieldMatchKind = (typeof FieldMatchKind)[keyof typeof FieldMatchKind];

/** Matches if the field's code is equal to the pattern string */
export interface FieldCodeEqualsMatcher {
  matchKind: typeof FieldMatchKind.CODE_EQUALS;
  pattern: string;
}

/** Matches if the field's code starts with the pattern string */
export interface FieldCodeStartsWithMatcher {
  matchKind: typeof FieldMatchKind.CODE_STARTS_WITH;
  pattern: string;
}

/** Matches if the field's code ends with the pattern string */
export interface FieldCodeEndsWithMatcher {
  matchKind: typeof FieldMatchKind.CODE_ENDS_WITH;
  pattern: string;
}

/** The type of the field value */
export type FieldValueType = 'string' | 'number' | 'boolean' | 'any';

/** Matches if the field's value type is one of the allowed types */
export interface FieldValueTypeMatcher {
  matchKind: typeof FieldMatchKind.VALUE_TYPE_IS;
  allowedTypes: FieldValueType[];
}

/** Enum containing the various kinds of reducer for mapping field values. */
export interface FieldNotMatcher {
  matchKind: typeof FieldMatchKind.NOT;
  matcher: Exclude<FieldMatcher, FieldNotMatcher>;
}

/**
 * Represents a requirement that a field must meet for it to be mapped.
 */
export type FieldMatcher =
  | FieldCodeEqualsMatcher
  | FieldCodeStartsWithMatcher
  | FieldCodeEndsWithMatcher
  | FieldValueTypeMatcher
  | FieldNotMatcher;

/** Enum containing the types of field reducer */
export const FieldReducerKind = {
  /** Outputs all values (including null) in sequence order as a comma-separated list. */
  LIST: 'list',
  /** Outputs unique values (excluding null) as a comma-separated list, sorted from most to least common. */
  SET: 'set',
  /** Outputs the mean of the values. Values that are null or non-numeric are excluded from the calculation. */
  MEAN: 'mean',
  /** Outputs the median value. Values that are null or non-numeric are excluded from the calculation. */
  MEDIAN: 'median',
  /** Outputs the most common value (excluding null). Non-numeric values are accepted. */
  MODE: 'mode',
  /** Outputs the minimum value. Values that are null or non-numeric are excluded from the calculation. */
  MIN: 'min',
  /** Outputs the max value. Values that are null or non-numeric are excluded from the calculation. */
  MAX: 'max',
} as const;

export type FieldReducerKind = (typeof FieldReducerKind)[keyof typeof FieldReducerKind];

export interface FieldReducer {
  /** The type of reducer */
  reducerKind: FieldReducerKind;
  /** A short description of the reducer that gets appended to the output field's name */
  fieldSuffix: string;
  /** The field value types that this reducer accepts */
  inputValueTypes: FieldValueType[];
}
