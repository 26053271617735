import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { antibodyComparisonViewerSelector } from '../../viewer-components/viewer-selectors';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ViewerResultData } from '../../viewer-components/viewer-document-data';
import { BehaviorSubject, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { ExportableChartComponent } from '../../../features/graphs/exportable-chart';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { comparisonsGraphDataActions } from './ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.actions';
import { selectLoadingStateForComparisonsDocument } from './ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.selectors';
import { SelectionForGraph } from '../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.reducer';
import { getRowIdentifier, getRowIdentifierColumnName } from '../getRowIdentifier';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { NgsComparisonsHistogramV2Component } from '../ngs-comparisons-histogram-v2/ngs-comparisons-histogram-v2.component';
import { NgsComparisonsScatterplotComponent } from '../ngs-comparisons-scatterplot/ngs-comparisons-scatterplot.component';

@ViewerComponent({
  key: 'ngs-comparisons-graphs-viewer',
  title: 'Graphs',
  selector: antibodyComparisonViewerSelector([
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.COMPARISON_CLUSTERS,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.COMPARISON_CLUSTER_LENGTH,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.COMPARISON_CLUSTER_GENE,
    },
    {
      min: 0,
      max: 2147483647,
      tableType: DocumentTableType.INEXACT_CLUSTER,
    },
  ]),
})
@Component({
  selector: 'bx-ngs-comparisons-graphs',
  templateUrl: './ngs-comparisons-graphs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    FormsModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    PageMessageComponent,
    LoadingComponent,
    NgClass,
    NgsComparisonsHistogramV2Component,
    NgsComparisonsScatterplotComponent,
    AsyncPipe,
  ],
})
export class NgsComparisonsGraphsComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-hidden';

  @ViewChild('exportable') chartComponent: ExportableChartComponent;
  constructor(
    private viewerDataService: ViewerDataService<ViewerResultData>,
    private store: Store<AppState>,
  ) {
    super();
  }

  selectableGraphs$: Observable<ComparisonsGraph[]>;
  loading$: Observable<boolean>;
  message$ = new ReplaySubject<string>();
  selectedGraph$ = new BehaviorSubject<ComparisonsGraph['type']>(HistogramGraphType.type);
  viewerData$: Observable<ViewerResultData>;
  documentID$: Observable<string>;
  ngOnInit(): void {
    this.viewerData$ = this.viewerDataService.getData('ngs-comparisons-graphs-viewer');
    this.viewerData$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, current) => {
          const idSame = prev.selectedTable.documentID === current.selectedTable.documentID;
          const selectionSame = prev.selection.selectedRows === current.selection.selectedRows;
          const filterSame = prev.filter === current.filter;
          const tableSame = prev.selectedTable.name === current.selectedTable.name;
          return idSame && selectionSame && filterSame && tableSame;
        }),
      )
      .subscribe(({ selection, selectedTable, filter }) => {
        const selectionForGraph: SelectionForGraph = {
          selectAll: selection.selectAll,
          selectedRows: selection.selectedRows.map((data) => getRowIdentifier(data)),
          rows: selection.rows.map((data) => getRowIdentifier(data)),
          documentName: selection?.document?.name,
          documentId: selection?.document?.id,
          rowIdentifierColumnName:
            selection.selectedRows
              .map((x: any) => getRowIdentifierColumnName(x, selectedTable.name))
              .find((x: any) => x) ?? 'geneious_row_index',
          total: selection.total,
        };
        this.store.dispatch(
          comparisonsGraphDataActions.updateSelection({
            id: selectedTable.documentID,
            selection: selectionForGraph,
            selectedTable,
            filter,
          }),
        );
      });

    this.documentID$ = this.viewerData$.pipe(map(({ selectedTable }) => selectedTable.documentID));

    this.loading$ = this.documentID$.pipe(
      switchMap((id) => this.store.select(selectLoadingStateForComparisonsDocument(id))),
    );

    this.selectableGraphs$ = of([HistogramGraphType, ScatterplotGraphType]);
  }

  ngOnDestroy() {
    this.selectedGraph$.complete();
  }

  onGraphChanged(graphType: ComparisonsGraph['type']) {
    this.selectedGraph$.next(graphType);
  }

  exportAsTable() {
    this.chartComponent.exportAsTable();
  }

  exportAsImage() {
    this.chartComponent.exportAsImage();
  }
}

export const ScatterplotGraphType = { type: 'scatterplot', name: 'Scatterplot' } as const;
export const HistogramGraphType = { type: 'histogram', name: 'Frequency' } as const;

export type ComparisonsGraph = typeof ScatterplotGraphType | typeof HistogramGraphType;
