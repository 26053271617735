import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { gridSelectionStateReducer } from './grid-selection-state.reducers';
import { GridSelectionStateService } from './grid-selection-state.service';

@NgModule({
  imports: [StoreModule.forFeature('selectionStates', gridSelectionStateReducer)],
  providers: [GridSelectionStateService],
})
export class GridSelectionStateModule {}
