<p class="text-muted">
  Adding clusters is a flexible way of grouping your sequences based on regional similarity. New
  cluster tables and graphs will be added to your current result.
</p>
<ngb-alert
  type="warning"
  class="mb-3"
  [dismissible]="true"
  (closed)="dismissAlert$.next(true)"
  *bxDismissible="{ name: 'add-cluster-no-new-document', dismiss$: dismissAlert$ }"
>
  The Recluster pipeline has been replaced by the new 'Add Cluster' pipeline. Add Cluster is
  designed to add tables and graphs to preexisting analysis results. For more information, see our
  <a href="https://help.geneiousbiologics.com/hc/en-us/articles/16647070905108" target="_blank">
    help article
  </a>
</ngb-alert>
<form [formGroup]="form">
  <div class="mb-0">
    <label
      class="form-label"
      for="regionsSelector"
      ngbTooltip="Allows the specification of arbitrary combinations of FR and/or CDR regions for clustering. "
    >
      Define additional cluster regions or combinations:
      <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/4460366289556">
        <mat-icon class="icon-small">help</mat-icon>
      </a>
    </label>
    <bx-regions-selector
      id="regionsSelector"
      formControlName="regionsSelector"
      [filterRegionsFor]="regionFilter"
      [loading]="tableDataLoading$ | async"
      [readonlyRegions]="readonlyRegions$ | async"
    ></bx-regions-selector>
  </div>
</form>
@if (errors$ | async; as error) {
  <div class="text-danger mt-2">{{ error }}</div>
}
