import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessCondition } from './access-check-condition.model';
import { AccessCheckService } from './access-check.service';

/**
 * Selects text based on an AccessCondition.
 * IMPORTANT: Unlike *ngIf, the AccessCondition is only evaluated once in the
 * lifetime of the component. We assume that feature access and organization
 * profiles will only change between user sessions.
 */
@Pipe({
  name: 'bxTextIfElse',
  standalone: true,
})
export class AccessConditionTextPipe implements PipeTransform {
  constructor(private readonly accessCheckService: AccessCheckService) {}

  /**
   * Selects either the first or the second string depending on the access
   * condition. If the condition resolves to true, the first option will be
   * chosen. Otherwise, the second option.
   *
   * Note that this pipe returns an Observable and therefore needs to be chained
   * with the async pipe.
   *
   * Usage: `<p>{{ ['option1', 'option2'] | bxTextIfElse: 'feature1' | async }}`
   * @param textOptions Tuple containing the first and second text options
   * @param condition The access condition
   * @returns An observable containing the selected text option
   */
  transform(textOptions: [string, string], condition: AccessCondition): Observable<string> {
    return this.accessCheckService
      .hasAccessOnce(condition)
      .pipe(map((hasAccess) => (hasAccess ? textOptions[0] : textOptions[1])));
  }
}
