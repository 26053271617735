import { filter, mapTo } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

/**
 * Combines both operators filter with mapTo.
 * Allows a stream to mapTo some value based on a conditional callback function.
 *
 * @returns {OperatorFunction<T, R>}
 */
export const mapToOn = <T, R>(
  value: R,
  callback: (value: T) => boolean,
): OperatorFunction<T, R> => {
  return (source$) => {
    if (typeof callback !== 'function') {
      throw new TypeError('argument is not a function.');
    }
    return source$.pipe(filter(callback), mapTo(value));
  };
};
