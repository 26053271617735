import { filter, map, startWith, take } from 'rxjs/operators';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilesTableComponent } from '../../files-table/files-table.component';
import { DatabaseFolder } from '../../folders/models/folder.model';
import { combineLatest, Observable } from 'rxjs';
import { FolderDetail } from '../folder-detail';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { MasterDatabaseFormDialogComponent } from '../../master-database/master-database-form-dialog/master-database-form-dialog.component';
import { SelectionState } from '../../../features/grid/grid.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PipelineDialogService } from '../../pipeline-dialogs/pipeline-dialog.service';
import { FilesTableFacade } from '../../files-table/files-table.facade';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { UrlQueryParamsService } from '../../router/url-query-params.service';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

@Component({
  selector: 'bx-blast-folder-detail',
  templateUrl: './blast-folder-detail.component.html',
  standalone: true,
  imports: [BreadcrumbComponent, PageMessageComponent, FilesTableComponent, AsyncPipe],
})
export class BlastFolderDetailComponent extends FolderDetail implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @ViewChild(FilesTableComponent) filesTable: FilesTableComponent;

  selectRowIDs$: Observable<string[]>;
  isMasterDatabase$: Observable<boolean>;
  showCreateMasterDatabasePrompt$: Observable<boolean>;

  private createMasterDatabaseDialogRef: NgbModalRef;

  constructor(
    protected store: Store<AppState>,
    protected route: ActivatedRoute,
    private pipelineDialogService: PipelineDialogService,
    private filesTableFacade: FilesTableFacade,
    private readonly urlQueryParamsService: UrlQueryParamsService,
  ) {
    super(store);
  }

  ngOnInit() {
    this.isMasterDatabase$ = this.folder$.pipe(
      filter((folder) => folder != null),
      map((folder) => (folder as DatabaseFolder).databaseType === DatabaseTypeEnum.MASTER),
    );

    const hasNoFiles$ = this.filesTableFacade.files$.pipe(map((files) => files.length === 0));

    this.showCreateMasterDatabasePrompt$ = combineLatest([
      this.isMasterDatabase$,
      hasNoFiles$,
    ]).pipe(map(([isMasterDatabase, noFiles]) => isMasterDatabase && noFiles));

    this.selectRowIDs$ = this.urlQueryParamsService
      .getFileIDsFromSelectRowID(this.route)
      .pipe(take(1), startWith([]));
  }

  ngOnDestroy() {
    if (this.createMasterDatabaseDialogRef) {
      this.createMasterDatabaseDialogRef.dismiss();
    }
  }

  createMasterDatabase() {
    this.createMasterDatabaseDialogRef = this.pipelineDialogService.showDialog({
      component: MasterDatabaseFormDialogComponent,
      folderID: this.route.snapshot.params.folderID,
      selected: new SelectionState(),
      otherVariables: { creatingDatabase: true },
    });
  }
}
