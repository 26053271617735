<bx-free-tier-login>
  <div>
    <form
      #signUpForm="ngForm"
      name="signUpForm"
      (ngSubmit)="submit(signUpForm)"
      novalidate
      class="d-flex flex-column"
    >
      <div class="mb-3">
        <input
          class="form-control form-control-lg"
          type="email"
          name="email"
          placeholder="Email"
          [(ngModel)]="email"
          [disabled]="submitting$ | async"
          required
        />
      </div>
      <div class="mb-3">
        <input
          class="form-control form-control-lg"
          type="text"
          name="name"
          placeholder="Name"
          [(ngModel)]="name"
          [disabled]="submitting$ | async"
        />
      </div>
      <button
        class="btn btn-lg btn-primary w-100"
        type="submit"
        id="submit-request"
        color="primary"
        [disabled]="signUpForm.invalid || (submitting$ | async)"
        bx-spinner-button
        [spin]="submitting$ | async"
      >
        Sign Up
      </button>
      @if (signUpError$ | async; as signUpError) {
        <div class="text-danger text-center mt-3" data-spec="sign-up-error">
          {{ signUpError }}<br />
          Please try again later. If you continue to have problems, please
          <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>
        </div>
      }
    </form>
    <div class="d-flex justify-content-center mt-3">
      <p>Already have an account? <a [routerLink]="['/login']">Sign in</a></p>
    </div>
  </div>
</bx-free-tier-login>
