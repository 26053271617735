import { Injectable } from '@angular/core';
import {
  DocumentSelectionSignature,
  IDocumentSelectionSignature,
} from '../document-selection-signature/document-selection-signature.model';

@Injectable({
  providedIn: 'root',
})
export class PipelineSelectionSignaturesService {
  private selectionSignatures: DocumentSelectionSignaturesMap = {};
  private pipelineHelpMessages: { [type: string]: string } = {};

  constructor() {}

  setSelectionSignatures(json: PipelinesSelectionSignaturesJSON) {
    const selectionSignatures: DocumentSelectionSignaturesMap = {};
    for (const pipelineId of Object.keys(json)) {
      const pipelineSelectionSigs = json[pipelineId].selectionSignatures;
      selectionSignatures[pipelineId] = pipelineSelectionSigs
        ? pipelineSelectionSigs.map((sig) => DocumentSelectionSignature.fromJSON(sig))
        : [];
    }
    this.selectionSignatures = selectionSignatures;
  }

  isPipelineRegistered(pipelineId: string): boolean {
    return !!this.selectionSignatures[pipelineId];
  }

  getPipelineSelectionSignatures(pipeline: string): DocumentSelectionSignature[] {
    return this.selectionSignatures[pipeline];
  }

  // todo move this to the general pipeline service or rename this service to reflect that it comes from the pipelines at init
  setHelpMessages(json: PipelinesSelectionSignaturesJSON) {
    const helpMessages: { [pipelineId: string]: string } = {};
    for (const pipelineId of Object.keys(json)) {
      const pipelineHelp = json[pipelineId].help;

      if (pipelineHelp) {
        helpMessages[pipelineId] = pipelineHelp;
      }
    }
    this.pipelineHelpMessages = helpMessages;
  }

  getHelpMessage(pipelineId: string): string | undefined {
    return this.pipelineHelpMessages[pipelineId];
  }
}

interface DocumentSelectionSignaturesMap {
  [type: string]: DocumentSelectionSignature[];
}

export interface PipelinesSelectionSignaturesJSON {
  [type: string]: {
    help?: string;
    selectionSignatures: IDocumentSelectionSignature[];
  };
}
