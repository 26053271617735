@if (
  {
    allTablesLoaded: tablesLoaded$ | async,
    tablesLoadingError: tablesLoadingError$ | async,
  };
  as status
) {
  <bx-toolstrip>
    <bx-document-table-picker
      class="m-1"
      [hidden]="isMasterDatabase$ | async"
      [documentID]="documentID$ | async"
      (selectedTableChanged)="onRegionChanged($event)"
      [selectedTable]="selectedTable$ | async"
    ></bx-document-table-picker>
    <bx-toolstrip-item>
      <div class="btn-group" ngbDropdown>
        <button
          ngbDropdownToggle
          type="button"
          class="btn btn-light dropdown-toggle"
          aria-controls="dropdown-basic"
        >
          Export/Extract<span class="caret"></span>
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu">
          <li
            role="menuitem"
            ngbTooltip="Exports selected table to a downloadable file"
            placement="right"
          >
            <button
              class="dropdown-item"
              [disabled]="exportTableButtonDisabled$ | async"
              (click)="openDialog('exportTable')"
            >
              Export Table
            </button>
          </li>
          @if ((isMasterDatabase$ | async) === false) {
            <li
              role="menuitem"
              ngbTooltip="Exports selected sequences to a downloadable file or a new document"
              placement="right"
            >
              <button
                class="dropdown-item"
                [disabled]="exportSequencesButtonDisabled$ | async"
                (click)="openDialog('exportSequences')"
              >
                Export Sequences
              </button>
            </li>
          }
          @if ((isMasterDatabase$ | async) === false) {
            <li role="menuitem" [ngbTooltip]="subsamplingTooltip$ | async" placement="right">
              <button
                class="dropdown-item"
                [disabled]="extractReclusterButtonDisabled$ | async"
                (click)="openDialog('extractRecluster')"
              >
                Extract and Recluster
              </button>
            </li>
          }
          @if ((isAntibodyAnnotatorResult$ | async) && (isFreeOrg$ | async) === false) {
            <li role="menuitem" [ngbTooltip]="generateReportTooltip$ | async" placement="right">
              <button
                class="dropdown-item"
                [disabled]="generateReportButtonDisabled$ | async"
                (click)="openDialog('generateReport')"
              >
                Generate Report
              </button>
            </li>
          }
          @if (showRegisterSequence$ | async) {
            <li role="menuitem" [ngbTooltip]="registerSequenceTooltip$ | async" placement="right">
              <button
                class="dropdown-item"
                [disabled]="registerSequenceDisabled$ | async"
                (click)="openDialog('registerSequences')"
              >
                Register in Dotmatics Bioregister
              </button>
            </li>
          }
          @if (showRegisterSequencesLuma$ | async) {
            <li
              role="menuitem"
              [ngbTooltip]="registerSequencesLumaTooltip$ | async"
              placement="right"
            >
              <button
                class="dropdown-item"
                [disabled]="registerSequencesLumaDisabled$ | async"
                (click)="openDialog('registerSequencesLuma')"
              >
                Send to Luma
              </button>
            </li>
          }
        </ul>
      </div>
    </bx-toolstrip-item>
    <bx-toolstrip-item>
      <div class="btn-group" ngbDropdown>
        <button
          ngbDropdownToggle
          type="button"
          class="btn btn-light dropdown-toggle"
          aria-controls="dropdown-basic"
        >
          Add Metadata<span class="caret"></span>
        </button>
        <ul ngbDropdownMenu class="dropdown-menu" role="menu">
          @if (showAddAssayDataButton$ | async) {
            <li
              role="menuitem"
              ngbTooltip="Join columns of data to the current table"
              placement="right"
            >
              <button
                class="dropdown-item"
                [disabled]="(addAssayDataButtonDisabled$ | async) || (disableAllPipelines$ | async)"
                (click)="openDialog('addAssayData')"
              >
                Add Assay Data
              </button>
            </li>
          }
          @if (bulkAddLabelsButton$ | async; as context) {
            <li role="menuitem" [ngbTooltip]="context.tooltipMessage" placement="right">
              <button
                class="dropdown-item"
                [disabled]="context.disableButton"
                (click)="openDialog('addLabels')"
              >
                Add Labels
              </button>
            </li>
          }
        </ul>
      </div>
    </bx-toolstrip-item>
    @if (isMasterDatabase$ | async) {
      <bx-toolstrip-item>
        <button
          class="btn btn-light"
          (click)="openDialog('addSequences')"
          [disabled]="(readOnly$ | async) || (disableAllPipelines$ | async)"
        >
          Add sequences
        </button>
      </bx-toolstrip-item>
    }
    @if ((isMasterDatabase$ | async) === false) {
      <bx-toolstrip-item>
        <bx-ngs-post-processing-pipelines-chooser
          [extractOptions]="extractOptions$ | async"
          [exportOptions]="sequencesExportOptions$ | async"
          [selectionState]="tableSelectionState$ | async"
          [selectedTable]="selectedTable$ | async"
          [datasource]="sequenceViewerDatasource"
          [documentID]="documentID$ | async"
          [folder]="folder$ | async"
          [disabled]="isAdminView$ | async"
          [sequenceMetadataOrder]="sequenceMetadataOrder$ | async"
          [creationPipeline]="creationPipeline$ | async"
          [creationPipelineOptions]="creationPipelineOptions$ | async"
          [alignmentSequenceOptions]="alignmentSequenceOptions$ | async"
        >
        </bx-ngs-post-processing-pipelines-chooser>
      </bx-toolstrip-item>
    }
    <bx-table-processing-notifier (showNotifier)="showTableProcessingNotifier$.next($event)">
    </bx-table-processing-notifier>
    <bx-toolstrip-item class="ngs-filter flex-grow-1 flex-shrink-1">
      <bx-ngs-filter-toolstrip-item
        [value$]="filterValue$.asObservable()"
        (filterChanged)="onFilterChange($event)"
      ></bx-ngs-filter-toolstrip-item>
    </bx-toolstrip-item>
  </bx-toolstrip>
  @if (status.allTablesLoaded) {
    <as-split
      class="flex-grow-1 flex-shrink-1"
      direction="vertical"
      unit="percent"
      [bxViewersState]="viewersStateKey$ | async"
      [bxViewerIndex]="1"
      data-testid="ngs-sequence-table-split"
    >
      <as-split-area class="overflow-hidden">
        @if (resourceError$ | async; as error) {
          <bx-page-message class="w-100 h-100">
            @if (error.pageMessage) {
              <div [innerHTML]="error.pageMessage"></div>
            } @else {
              <h5>Failed to fetch results</h5>
              <br />
              <span>
                See our
                <a
                  target="_blank"
                  href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069871"
                >
                  Knowledge base
                </a>
                article for further information.
              </span>
            }
          </bx-page-message>
        }
        @if (tableLoaded$ | async) {
          <bx-grid
            class="w-100 h-100"
            [gridOptions]="gridOptions"
            [datasource]="gridDatasource"
            [datasourceParams$]="gridDatasourceParams$"
            [resultSetMax]="RESULT_SET_MAX"
            noRowsOverlayMessage="No matches found."
            (columnsChanged)="handleColumnsChanged($event)"
            (selectionV2Changed)="handleSelectionChanged($event)"
            (gridStateChanged)="handleGridStateChanged($event)"
            [showTimer]="true"
            [tableType]="tableType$ | async"
            [selectable]="(tableType$ | async) !== 'DOCUMENT_TABLE_SUMMARY'"
            [additionMessage]="additionalMessage$ | async"
            [profileComponent]="tableProfileKey$ | async"
            [customContextMenuItems]="gridContextMenuItems.contextMenuItem$ | async"
            bxNotesEditHandler
            data-testid="ngs-sequences-table-grid"
          >
          </bx-grid>
        } @else {
          <bx-ngs-table-restoring-overlay
            [restoreProgress]="restoreProgress$ | async"
          ></bx-ngs-table-restoring-overlay>
        }
      </as-split-area>
      <as-split-area>
        <bx-viewers
          class="w-100 h-100 overflow-hidden"
          [data]="ngsTableViewersData$ | async"
          [overlays]="viewersOverlays"
          [key]="viewersStateKey$ | async"
        >
        </bx-viewers>
      </as-split-area>
    </as-split>
  } @else {
    @if (status.tablesLoadingError) {
      @switch (status.tablesLoadingError) {
        @case ('masterDatabaseNoTables') {
          <bx-page-message>
            <p>Database is empty</p>
            <button class="btn btn-primary" (click)="openDialog('addSequences')">
              Import Sequences
            </button>
          </bx-page-message>
        }
        @default {
          <bx-page-message>
            <h5>Failed to fetch results</h5>
            <br />
            <span>
              Close and try again later. If you continue to have problems, please
              <a href="https://help.geneiousbiologics.com">contact support.</a>
            </span>
          </bx-page-message>
        }
      }
    } @else {
      <bx-page-message>
        <bx-loading></bx-loading>
      </bx-page-message>
    }
  }
}
