import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import {
  ParsedTable,
  ParsedTablesResult,
} from '../../../../assay-data-v2/table-parser/table-parser.model';
import { TableParserService } from '../../../../assay-data-v2/table-parser/table-parser.service';
import { CleanUp } from 'src/app/shared/cleanup';
import { ImportOptions } from '@geneious/nucleus-api-client';
import { CardComponent } from '../../../../../shared/card/card.component';
import { AsyncPipe } from '@angular/common';
import { FadeFormElementDirective } from '../../../../../shared/fade-form-element.directive';
import { TableViewerComponent } from '../../../../viewer-components/table-viewer/table-viewer.component';

type SequenceImportOptions = {
  sequenceNameColumn: string;
  lightChainColumn: string;
  heavyChainColumn: string;
  isProtein: boolean;
};

type TableToSequenceListFormValue = {
  sequenceNameColumn?: string;
  multipleChainsAllowed?: boolean;
  singleChain?: string;
  heavyChain?: string;
  lightChain?: string;
  sequenceType?: 'nucleotide' | 'protein';
};

@Component({
  selector: 'bx-table-to-sequence-list-configuration',
  templateUrl: './table-to-sequence-list-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    FadeFormElementDirective,
    TableViewerComponent,
    AsyncPipe,
  ],
})
export class TableToSequenceListConfigurationComponent extends CleanUp implements OnInit {
  private readonly multipleChainsAllowedDefault = false;
  readonly configurationForm = new FormGroup(
    {
      sequenceNameColumn: new FormControl<string>(undefined),
      multipleChainsAllowed: new FormControl(this.multipleChainsAllowedDefault),
      singleChain: new FormControl<string>(undefined),
      heavyChain: new FormControl<string>(undefined),
      lightChain: new FormControl<string>(undefined),
      sequenceType: new FormControl<'nucleotide' | 'protein'>('nucleotide'),
    },
    (form) => this.validateFormValue(form.value as TableToSequenceListFormValue),
  );

  allTablesAsOne$: Observable<ParsedTablesResult>;
  columns$: Observable<string[]>;
  @Output() importOptionsChange = new EventEmitter<ImportOptions>();
  @Input() files: File[];

  constructor(private tableParserService: TableParserService) {
    super();
    this.setMultipleChainsAllowed(this.multipleChainsAllowedDefault);
  }

  setMultipleChainsAllowed(isMultipleChainsAllowed: boolean) {
    if (isMultipleChainsAllowed) {
      this.configurationForm.controls['singleChain'].disable();
      this.configurationForm.controls['heavyChain'].enable();
      this.configurationForm.controls['lightChain'].enable();
    } else {
      this.configurationForm.controls['singleChain'].enable();
      this.configurationForm.controls['heavyChain'].disable();
      this.configurationForm.controls['lightChain'].disable();
    }
  }

  private convertFormValueToOptions(value: TableToSequenceListFormValue): SequenceImportOptions {
    const isMultipleChainsAllowed = value.multipleChainsAllowed;
    const sequenceNameColumn = value.sequenceNameColumn;
    let lightChain;
    let heavyChain;
    if (isMultipleChainsAllowed) {
      lightChain = value.lightChain;
      heavyChain = value.heavyChain;
    } else {
      // For single chains, we pass the single chain in as the light chain.
      lightChain = value.singleChain;
      heavyChain = undefined;
    }
    return {
      sequenceNameColumn: sequenceNameColumn,
      lightChainColumn: lightChain,
      heavyChainColumn: heavyChain,
      isProtein: value.sequenceType === 'protein',
    };
  }

  private validateFormValue(formValue: TableToSequenceListFormValue): ValidationErrors {
    const { lightChainColumn, heavyChainColumn, sequenceNameColumn } =
      this.convertFormValueToOptions(formValue);
    if ((!lightChainColumn && !heavyChainColumn) || !sequenceNameColumn) {
      return { noChainOrSequenceNameColumn: 'No chain or sequence name columns found' };
    }
    return null;
  }

  ngOnInit(): void {
    this.initializeColumnSelectionSettings();
    this.configurationForm.statusChanges
      .pipe(
        map((status) =>
          status === 'VALID' ? this.convertFormValueToOptions(this.configurationForm.value) : null,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((sequenceImportOptions) => {
        this.importOptionsChange.emit({
          groupSequences: false,
          additionalJobParameters: {
            options: { sequenceImportOptions },
          },
        });
      });
  }

  private initializeColumnSelectionSettings() {
    const filesWithTables$ = this.files.map((f) => this.tableParserService.parse(f));

    this.allTablesAsOne$ = forkJoin(filesWithTables$).pipe(
      map((tablesParsed) => {
        const tables = [] as ParsedTable[];
        tablesParsed.forEach((file) => {
          tables.push(...file.tables);
        });
        return {
          filename: 'allTablesAsOne.tmp',
          tables: tables,
        };
      }),
    );

    const firstTable$ = filesWithTables$[0].pipe(
      map((firstFile) => firstFile.tables[0]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.columns$ = firstTable$.pipe(map((x: ParsedTable) => x.columns));
    this.columns$.pipe(take(1)).subscribe((data) => {
      // Set the defaults as the first column
      this.configurationForm.controls['sequenceNameColumn'].setValue(data[0]);
      this.configurationForm.controls['singleChain'].setValue(data[0]);
      this.configurationForm.controls['heavyChain'].setValue(data[0]);
      this.configurationForm.controls['lightChain'].setValue(data[0]);
    });
  }
}
