import { State, ViewerHeight, ViewerState, ViewerStates } from './viewers-state.interface';
import { createSelector } from '@ngrx/store';

export const selectViewerStates = (state: State) => {
  return state.viewerStates;
};

export const selectViewerState = (key: string) =>
  createSelector(
    selectViewerStates,
    (viewerStates: ViewerStates<ViewerHeight>) => viewerStates[key],
  );

export const selectViewerStateHeight = (key: string) =>
  createSelector(selectViewerState(key), (viewerState: ViewerState<ViewerHeight>) =>
    viewerState ? viewerState.height : undefined,
  );
