import { LONG_ANTIBODY_ANNOTATOR_REGIONS } from '../../antibodyAnnotatorRegions.service';
import { BxFormGroup } from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function largeRegionsWithHighThresholdValidator(control: AbstractControl): ValidatorFn {
  if (!(control instanceof BxFormGroup)) {
    throw new Error('Validator only supported for BxFormGroup');
  }

  const selectedReclusteringRegion = control.get('reclustering_region').value;
  const largeRegionIsSelected = LONG_ANTIBODY_ANNOTATOR_REGIONS.some(
    (cluster) => cluster.label === selectedReclusteringRegion,
  );
  const selectedReclusteringThreshold = control.get('reclustering_sequenceIdentityThreshold').value;
  const isLargeReclusteringThreshold =
    selectedReclusteringThreshold >= 90 && selectedReclusteringThreshold < 95;
  control.warnings =
    largeRegionIsSelected && isLargeReclusteringThreshold
      ? {
          tooLarge: {
            region: selectedReclusteringRegion,
            threshold: selectedReclusteringThreshold,
          },
        }
      : null;
  return null;
}
