import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  GroupSequencesJobOptionsV1,
  GroupSequencesJobParametersV1,
} from '../../../../nucleus/services/models/groupSequencesOptions.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { BxFormGroup } from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-group-sequences',
  templateUrl: './group-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, PipelineOutputComponent],
})
export class GroupSequencesComponent extends JobDialogContent implements RunnableJobDialog {
  earlyRelease: false;

  title = 'Group Sequences';
  knowledgeBaseArticle: string;
  readonly showProfileButtons = false;

  form = new BxFormGroup({
    name: JobDialogContent.getResultNameControl(),
    outputFolderName: JobDialogContent.getResultNameControl(),
  });

  private readonly formDefaults: any;
  private readonly selected: SelectionState;

  constructor(@Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData) {
    super('group-sequences', PipelineFormID.GROUP_SEQUENCES);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
  }

  run() {
    const formValue = this.form.value;
    const { outputFolderName, ...opts } = formValue;
    const options: GroupSequencesJobOptionsV1 = {
      ...opts,
    };

    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    return new GroupSequencesJobParametersV1(
      parameters.selection,
      parameters.options,
      parameters.output,
    );
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }
}
