import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BulkSelectionWithQuery } from '@geneious/nucleus-api-client';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  Observable,
  startWith,
} from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { DocumentTable } from 'src/nucleus/services/documentService/types';
import { AppState } from '../../core.store';
import { bulkRowUpdateEdit } from '../../document-table-edits/document-table-edits.actions';
import { selectBulkEditInProgressForDocumentTable } from '../../document-table-edits/document-table-edits.selectors';
import { LabelMultiSelectComponent } from '../label-picker/label-multi-select/label-multi-select.component';
import { Label } from '../label.model';
import { NgPlural, NgPluralCase, AsyncPipe } from '@angular/common';
import { LabelBadgeComponent } from '../label-badge/label-badge.component';

@Component({
  selector: 'bx-bulk-add-labels',
  templateUrl: './bulk-add-labels.component.html',
  styleUrls: ['./bulk-add-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LabelBadgeComponent,
    LabelMultiSelectComponent,
    NgbTooltip,
    NgPlural,
    NgPluralCase,
    AsyncPipe,
  ],
})
export class BulkAddLabelsComponent extends CleanUp implements OnInit {
  @Input() documentTable: DocumentTableInfo;
  @Input() selection: BulkSelectionWithSqlQuery;
  @Input() totalRowsSelected?: number;

  @ViewChild('labelPicker') labelPicker: LabelMultiSelectComponent;
  submitButton$: Observable<{ disable: boolean; tooltip?: string }>;
  readonly selectedLabels$ = new BehaviorSubject<Label[]>([]);

  constructor(
    private readonly store: Store<AppState>,
    private readonly modalRef: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.submitButton$ = combineLatest([
      this.selectedLabels$.pipe(
        map((labels) => labels.length),
        distinctUntilChanged(),
      ),
      this.store.select(
        selectBulkEditInProgressForDocumentTable(
          this.documentTable.documentID,
          this.documentTable.name,
        ),
      ),
    ]).pipe(
      startWith([0, false]),
      map(([numSelectedLabels, bulkEditInProgress]) => {
        if (numSelectedLabels === 0) {
          return {
            disable: true,
            tooltip: 'Select at least one label',
          };
        }
        if (bulkEditInProgress) {
          return {
            disable: true,
            tooltip: 'Please wait for the current bulk label operation to complete',
          };
        }
        return { disable: false };
      }),
    );
  }

  setSelectedLabels(labels: Label[]) {
    this.selectedLabels$.next(labels);
  }

  deselectLabel(labelID: string) {
    this.labelPicker.setSelected(labelID, false);
  }

  dismiss(): void {
    this.modalRef.dismiss();
  }

  submit(): void {
    const { documentID, name } = this.documentTable;
    this.selectedLabels$
      .pipe(
        first(),
        map((labels) => labels.map((label) => label.name)),
      )
      .subscribe((labelNames) => {
        this.store.dispatch(
          bulkRowUpdateEdit({
            documentID,
            tableName: name,
            column: 'Labels',
            edit: { add: labelNames },
            selection: this.selection,
          }),
        );
        this.modalRef.close();
      });
  }
}

export type BulkSelectionWithSqlQuery = Omit<BulkSelectionWithQuery, 'query'> & {
  sqlQuery: string;
};
export type DocumentTableInfo = Pick<DocumentTable, 'documentID' | 'displayName' | 'name'>;
export type BulkAddLabelsInputs = Pick<
  BulkAddLabelsComponent,
  'documentTable' | 'selection' | 'totalRowsSelected'
>;
