import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, TimeoutError, timer } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivitiesStatus } from './index';
import { catchError, delayWhen, map, mapTo, retryWhen, timeout } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app.config';

/**
 * Provides a means of checking the health status of all nucleus services via an API.
 * In the future more services could be added.
 */
@Injectable({
  providedIn: 'root',
})
export class HealthCheckerService {
  private activity = `${this.config.nucleusApiBaseUrl}/api/activity/v1/health`;
  private core = `${this.config.nucleusApiBaseUrl}/api/v1/health-check`;
  private jobs = `${this.config.nucleusApiBaseUrl}/api/jobs/v2/health`;
  private documentTable = `${this.config.nucleusApiBaseUrl}/api/document-table/v1/health`;
  private ngs = `${this.config.nucleusApiBaseUrl}/api/ngs/v1/health`;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {}

  getAllServicesStatus(): Observable<ActivitiesStatus> {
    return forkJoin({
      activity: this.getHealthStatus(this.activity),
      core: this.getHealthStatus(this.core),
      jobs: this.getHealthStatus(this.jobs),
      documentTable: this.getHealthStatus(this.documentTable),
      ngs: this.getHealthStatus(this.ngs),
    });
  }

  // Note this will always return 'true' and never 'false'.
  // It will poll indefinitely until the subscription is closed or all services are healthy.
  pollUntilAllServicesAreHealthy(pollPeriod: number = 60000): Observable<boolean> {
    return this.getAllServicesStatus().pipe(
      map((services) => {
        if (Object.values(services).every((status) => status)) {
          return true;
        } else {
          throw services;
        }
      }),
      retryWhen((services) => services.pipe(delayWhen(() => timer(pollPeriod)))),
    );
  }

  private getHealthStatus(serviceEndpoint: string): Observable<boolean> {
    return this.http.get(serviceEndpoint).pipe(
      mapTo(true),
      // Avoid potential cases where the health check endpoint is down and hanging the request.
      // 10 seconds should be plenty of time for a health check request to finish.
      timeout(10000),
      catchError((error: HttpErrorResponse | TimeoutError) =>
        // Only a 403 error for the core health-check endpoint is accepted as healthy.
        // Nucleus will eventually patch this so that it responds with a successful response instead when it is healthy: BX-5786
        of(
          error instanceof HttpErrorResponse &&
            serviceEndpoint.endsWith('health-check') &&
            error.status === 403,
        ),
      ),
    );
  }
}
