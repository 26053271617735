import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { MaintenanceInfo } from './maintenance-page';
import { DialogService } from '../../shared/dialog/dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HealthCheckerService } from '../health-checker/health-checker.service';
import { APP_CONFIG, AppConfig } from '../../app.config';

/**
 * Provides methods for handling outages of Nucleus services.
 * There is now helpers for handling unexpected outages (non maintenance).
 */
@Injectable({
  providedIn: 'root',
})
export class OutageHandlerService {
  private maintenancePageModalRef: NgbModalRef;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private healthCheckerService: HealthCheckerService,
    private dialogService: DialogService,
  ) {}

  /**
   * Checks a JSON file on S3 for the current environment that contains information on whether a maintenance period has been set.
   * This file will most likely always be present as it won't be removed after maintenance has finished, thus rely on the startTime/endTime
   * dates.
   */
  checkMaintenanceInfo(): Observable<MaintenanceInfo> {
    return this.http.get<MaintenanceInfo>(this.getMaintenanceInfoURL());
  }

  /**
   * Show the maintenance page dialog with information retrieved from the `checkMaintenanceInfo` method and then periodically check
   * the health of all Nucleus services.
   * If all Nucleus services are healthy, then close the maintenance page dialog.
   * @param maintenanceInfo - data that was most likely retrieved from the `checkMaintenanceInfo` method.
   */
  showMaintenancePage(maintenanceInfo: MaintenanceInfo) {
    if (this.maintenancePageModalRef) {
      return;
    }

    let allowDialogDismiss = false;
    this.maintenancePageModalRef = this.dialogService.showDialogV2({
      component: MaintenancePageComponent,
      injectableData: maintenanceInfo,
      options: {
        centered: true,
        size: 'lg',
        // Avoid the user closing the dialog
        beforeDismiss: () => allowDialogDismiss,
      },
    });

    // Poll server status every 60 seconds
    setTimeout(() => {
      const subscription = this.healthCheckerService
        .pollUntilAllServicesAreHealthy()
        .subscribe((serverUp) => {
          if (serverUp) {
            subscription.unsubscribe();
            allowDialogDismiss = true;
            this.maintenancePageModalRef.dismiss();
            this.maintenancePageModalRef = undefined;
          }
        });
    }, 60000);
  }

  private getMaintenanceInfoURL(): string {
    return `https://biomatters-biologics-ui-${this.getCurrentNucleusEnvironment()}-maintenance.s3.amazonaws.com/data.json`;
  }

  private getCurrentNucleusEnvironment(): string {
    if (this.config.NUCLEUS_ENVIRONMENT) {
      return this.config.NUCLEUS_ENVIRONMENT;
    } else {
      console.warn('NUCLEUS_ENVIRONMENT env variable is not set');
      return 'dev';
    }
  }
}
