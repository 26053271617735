import {
  Tooltip,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from 'highcharts';
import { roundForGraph } from '../../../shared/utils/rounding';

export const scatterplotChartTooltipFormatter: TooltipFormatterCallbackFunction = function (
  tooltip: Tooltip,
) {
  const { point, series } = this as TooltipFormatterContextObject;
  const xLabel = `<b>${series.xAxis.options.title.text}: ${point.options.x}</b></br>`;
  const yLabel = `<b>${series.yAxis.options.title.text}: ${point.options.y}</b></br>`;
  const rowLabel = `<b>${point.options.name}</b></br>`;
  return rowLabel + xLabel + yLabel;
};
