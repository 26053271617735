<div class="bx-page-container bx-page-container-xs">
  <bx-logo class="d-block mb-5" [width]="'100%'"></bx-logo>
  <form
    #changePasswordForm="ngForm"
    name="changePasswordForm"
    (ngSubmit)="submit(changePasswordForm)"
  >
    @if (changePasswordErrors) {
      <div class="alert alert-danger">
        {{ changePasswordErrors }}
      </div>
    }
    <div class="mb-3">
      <div class="w-100 d-flex justify-content-between">
        <label class="form-label" for="password">Enter new password</label>
        <bx-password-strength [password]="password" class="mt-1 w-25"></bx-password-strength>
      </div>
      <input
        id="password"
        name="password"
        placeholder="Password"
        class="form-control"
        type="password"
        required
        bxValidatePassword
        [bxValidateEqual]="'confirm_password'"
        [disabled]="waiting"
        [(ngModel)]="password"
        ngFormControlValidator
        [canMarkPristineInvalid]="false"
      />
      @if (changePasswordForm.controls?.password?.errors?.required) {
        <div class="invalid-feedback">Enter a password.</div>
      }
      @if (changePasswordForm.controls?.password?.errors?.validateEqual) {
        <div class="invalid-feedback">Passwords don't match.</div>
      }
      @if (changePasswordForm.controls?.password?.errors?.weakPassword) {
        <div class="invalid-feedback">
          <!-- The value contains the message to show to the user-->
          {{ changePasswordForm.controls?.password?.errors?.weakPassword }}
        </div>
      }
    </div>
    <div class="mb-3">
      <input
        id="confirm_password"
        name="confirm_password"
        placeholder="Confirm Password"
        type="password"
        class="form-control"
        required
        [disabled]="waiting"
        [bxValidateEqual]="'password'"
        [(ngModel)]="confirmPassword"
        ngFormControlValidator
        [canMarkPristineInvalid]="false"
      />
      @if (changePasswordForm.controls?.confirm_password?.errors?.required) {
        <div class="invalid-feedback">Enter a password.</div>
      }
      @if (changePasswordForm.controls?.confirm_password?.errors?.validateEqual) {
        <div class="invalid-feedback">Passwords don't match.</div>
      }
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-secondary me-2" id="cancel-button" routerLink="/" type="button">
        Cancel
      </button>
      <button
        class="btn btn-primary"
        color="primary"
        id="change-submit"
        type="submit"
        [disabled]="!changePasswordForm.form.valid"
        bx-spinner-button
        [spin]="waiting"
      >
        Change Password
      </button>
    </div>
  </form>
</div>
