@if ((errorMessage$ | async) === null) {
  <form class="row w-100 mx-0 px-3 pt-2 pb-1">
    <div class="d-flex align-items-center flex-nowrap flex-shrink-1 my-1 me-3">
      <label for="column" class="form-label text-nowrap me-2">Assay data column</label>
      <select [formControl]="columnControl" id="column" class="form-select">
        @for (column of columns$ | async; track column) {
          <option [value]="column.colID">
            {{ column.headerName || column.colID }}
          </option>
        }
      </select>
    </div>
    <div class="d-flex align-items-center flex-nowrap flex-shrink-1 my-1 me-3">
      <label for="motif" class="form-label text-nowrap me-2">Motif</label>
      <select [formControl]="motifControl" id="motif" class="form-select">
        @for (motif of motifs$ | async; track motif) {
          <option [value]="motif">
            {{ motif }}
          </option>
        }
      </select>
    </div>
    <div class="flex-grow-1 d-flex align-items-center justify-content-end">
      @if (warningMessage$ | async; as warning) {
        <small class="text-muted fw-bold d-inline-block">
          {{ warning }}
        </small>
      }
    </div>
  </form>
  @if (graphData$ | async; as data) {
    <bx-graph-boxplot [data]="data" [type]="'motif-plot'" class="flex-grow-1"> </bx-graph-boxplot>
  } @else {
    <bx-page-message>
      Select a column and a motif to plot the distribution of sequences including and excluding the
      motif sequence by the selected data column.
    </bx-page-message>
  }
}
