import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import {
  AbstractControl,
  ValidationErrors,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  MasterDatabaseSearchJobOptions,
  MasterDatabaseSearchParametersV1,
} from '../../../../nucleus/services/models/master-database-search.model';
import { SelectionOptionsV1 } from '../../../../nucleus/services/models/jobParameters.model';
import { combineLatest, Observable, of } from 'rxjs';
import { MasterDatabaseService } from '../../master-database/master-database.service';
import { MasterDatabaseFolderAndFiles } from '../../master-database';
import { map, share, shareReplay, take, takeUntil } from 'rxjs/operators';
import {
  AnnotationNameAndCount,
  SequenceSelectionService,
} from '../../sequence-viewer/sequence-selection.service';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import { arrayToMap } from '../../../../bx-common-extensions/array';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  ChainNameQualifierName,
  ChainQualifierName,
} from '../../../../nucleus/services/models/alignmentOptions.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { ExtractSequencesDialogOptionsV3 } from 'src/nucleus/services/models/extractSequencesV3.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import { getRegionOptionLabel } from '../alignment/alignment-helper';
import { SequenceData } from '@geneious/sequence-viewer/types';
import {
  restrictControlValue,
  currentValueAndChanges,
  restrictControlValues,
} from 'src/app/shared/utils/forms';
import { CardComponent } from '../../../shared/card/card.component';
import { AsyncPipe } from '@angular/common';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { SelectComponent } from '../../../shared/select/select.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

interface DialogVariables {
  extraction: ExtractSequencesDialogOptionsV3;
  isDisabled: boolean;
  getSequences: () => Observable<SequenceData[]>;
}

type Alphabet = 'NUCLEOTIDE' | 'PROTEIN';

/**
 * JobDialogContent for `master-database-search`. Note that a "Master Database"
 * is now called a "Collection" in the UI.
 */
@Component({
  selector: 'bx-master-database-search',
  templateUrl: './master-database-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    SelectComponent,
    NgbTooltip,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class MasterDatabaseSearchComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  earlyRelease = true;
  title = 'Search Collections';
  knowledgeBaseArticle = 'https://help.geneiousbiologics.com/hc/en-us/articles/360052420591';
  otherVariables: DialogVariables;
  readonly form = new BxFormGroup({
    masterDatabaseIDs: new BxFormControl<string[]>([]),
    region: new BxFormControl<AnnotationNameAndCount>(undefined, Validators.required),
    compareBy: new BxFormControl<Alphabet>('PROTEIN', Validators.required),
    minimumSimilarityThreshold: new BxFormControl(90, [
      Validators.required,
      Validators.min(50),
      Validators.max(100),
    ]),
    maximumHits: new BxFormControl(10, [
      Validators.required,
      Validators.min(1),
      Validators.max(20),
    ]),
    onlyCompareSameLength: new BxFormControl(false),
    resultName: JobDialogContent.getResultNameControl(),
  });
  masterDatabaseFolders$: Observable<MasterDatabaseFolderAndFiles[]>;
  masterDatabaseSelectOptions$: Observable<SelectGroup[]>;
  regions$: Observable<AnnotationNameAndCount[]>;
  isAllNucleotide$: Observable<boolean>;
  annotateRegionsOptions$: Observable<SelectGroup<AnnotationNameAndCount>[]>;

  private readonly formDefaults: any;
  private readonly selected: SelectionState;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData<DialogVariables>,
    private masterDatabaseService: MasterDatabaseService,
  ) {
    super('master-database-search', PipelineFormID.MASTER_DATABASE_SEARCH);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
    this.otherVariables = this.dialogData.otherVariables;
  }

  ngOnInit() {
    this.masterDatabaseFolders$ = this.masterDatabaseService
      .getDatabases()
      .pipe(takeUntil(this.ngUnsubscribe), shareReplay(1));

    this.masterDatabaseSelectOptions$ = this.masterDatabaseFolders$.pipe(
      map((collections) =>
        collections
          .filter((collection) => collection.databases.length > 0)
          .map(
            ({ folder, databases }) =>
              new SelectGroup(
                databases.map(({ name, id }) => ({ displayName: name, value: id })),
                folder.name,
              ),
          ),
      ),
    );

    this.regions$ = this.retrieveRegions().pipe(takeUntil(this.ngUnsubscribe), share());

    this.annotateRegionsOptions$ = this.regions$.pipe(
      map((regions) =>
        regions.reduce((acc, curr) => {
          const option = new SelectOption<AnnotationNameAndCount>(getRegionOptionLabel(curr), curr);
          const currentChainName = curr.chainName ?? curr.chain ?? 'Unknown';
          const chainIndex = acc.findIndex(({ label }) => label === currentChainName);
          if (chainIndex === -1) {
            acc.push(new SelectGroup<AnnotationNameAndCount>([option], currentChainName));
          } else {
            acc[chainIndex].options.push(option);
          }
          return acc;
        }, [] as SelectGroup<AnnotationNameAndCount>[]),
      ),
      map((options) => {
        const unknownRegionIndex = options.findIndex(({ label }) => label === 'Unknown');
        if (unknownRegionIndex !== -1) {
          delete options[unknownRegionIndex].label;
        }
        return options;
      }),
    );

    this.selectFirstMasterDatabaseByDefault();
    this.selectFirstRegionByDefault();

    const masterDatabasesMap$ = this.masterDatabaseFolders$.pipe(
      map((masterDatabases) =>
        masterDatabases.flatMap((masterDatabase) => masterDatabase.databases),
      ),
      map((masterDatabases) => arrayToMap(masterDatabases, 'id')),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    // Validate IDs in case an applied profile refers to a deleted collection
    masterDatabasesMap$
      .pipe(
        take(1),
        map((databaseMap) => Object.keys(databaseMap)),
      )
      .subscribe((validIDs) =>
        restrictControlValues(this.form.controls.masterDatabaseIDs, validIDs, {
          takeUntil: this.ngUnsubscribe,
        }),
      );

    const selectedMasterDatabases$: Observable<Item[]> = combineLatest([
      currentValueAndChanges(this.form.controls.masterDatabaseIDs),
      masterDatabasesMap$,
    ]).pipe(
      map(([masterDatabaseIDs, masterDatabasesMap]) => {
        const validIDs = masterDatabaseIDs.filter((id) => id in masterDatabasesMap);
        return validIDs.map((id: string) => masterDatabasesMap[id]);
      }),
      takeUntil(this.ngUnsubscribe),
    );

    // Side effects for when the selection is not all nucleotides
    this.isAllNucleotide$ = combineLatest([of(this.selected), selectedMasterDatabases$]).pipe(
      map(([selectionSequencesState, selectedMasterDatabases]) => {
        const selectedDocumentMetadata = selectionSequencesState.selectedRows[0].metadata;
        const resultDocumentIsAllNucleotides =
          Number(selectedDocumentMetadata.nucleotideSequenceCount) > 0 &&
          Number(selectedDocumentMetadata.proteinSequenceCount) === 0;
        return (
          resultDocumentIsAllNucleotides &&
          selectedMasterDatabases.every((database) => database.metadata.alphabet === 'NUCLEOTIDE')
        );
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.isAllNucleotide$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isAllNucleotides) => {
      if (isAllNucleotides) {
        this.form.controls.compareBy.removeValidators(this.mustBeProtein);
      } else {
        this.form.controls.compareBy.setValue('PROTEIN');
        this.form.controls.compareBy.addValidators(this.mustBeProtein);
      }
    });
  }

  compareRegion(a: AnnotationNameAndCount, b: AnnotationNameAndCount): boolean {
    if (a === b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return a.name === b.name && a.chainName === b.chainName && a.chain === b.chain;
  }

  run() {
    const formValue = this.form.value;

    const options: MasterDatabaseSearchJobOptions = {
      masterDatabaseIDs: formValue.masterDatabaseIDs,
      maximumHits: formValue.maximumHits,
      onlyCompareSameLength: formValue.onlyCompareSameLength,
      minimumSimilarityThreshold: formValue.minimumSimilarityThreshold,
      region: {
        name: formValue.region.name,
        qualifierFilters: [],
      },
      compareBy: formValue.compareBy,
      resultSequenceExtractionOptions: {
        documentTableName: this.otherVariables.extraction.documentTableName,
        documentTableQuery: this.otherVariables.extraction.documentTableQuery,
        selection: this.otherVariables.extraction.selection,
      },
      resultName: formValue.resultName,
    };

    if (formValue.region.chain) {
      options.region.qualifierFilters.push({
        name: ChainQualifierName,
        value: formValue.region.chain,
      });
    }

    if (formValue.region.chainName) {
      options.region.qualifierFilters.push({
        name: ChainNameQualifierName,
        value: formValue.region.chainName,
      });
    }

    const selection: SelectionOptionsV1 = {
      selectAll: this.selected.selectAll,
      folderId: this.dialogData.folderID,
      ids: this.selected.ids,
    };

    return new MasterDatabaseSearchParametersV1({ selection, options });
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  private retrieveRegions(): Observable<AnnotationNameAndCount[]> {
    return this.otherVariables
      .getSequences()
      .pipe(
        map((annotatedSequences: SequenceData[]) =>
          SequenceSelectionService.getAllAnnotationNames(annotatedSequences),
        ),
      );
  }

  private selectFirstMasterDatabaseByDefault() {
    const masterDatabaseIDsControl = this.form.controls.masterDatabaseIDs;
    masterDatabaseIDsControl.disable();
    this.masterDatabaseFolders$
      .pipe(
        take(1),
        map((masterDatabaseFolders) =>
          masterDatabaseFolders.find(
            (masterDatabaseFolder) => masterDatabaseFolder.databases.length > 0,
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((masterDatabase) => {
        if (masterDatabase && masterDatabase.databases[0]) {
          masterDatabaseIDsControl.setValue([masterDatabase.databases[0].id]);
        }
        masterDatabaseIDsControl.addValidators((ctrl) => {
          if (!ctrl.value?.length) {
            return { required: 'At least one non-empty Collection must be selected' };
          }
          return null;
        });
        masterDatabaseIDsControl.enable();
      });
  }

  private selectFirstRegionByDefault() {
    const regionControl = this.form.controls.region;
    regionControl.disable();
    this.regions$.pipe(take(1)).subscribe((regions) => {
      restrictControlValue(regionControl, regions, {
        isEqual: this.compareRegion,
        takeUntil: this.ngUnsubscribe,
      });
      regionControl.addValidators((ctrl) => {
        if (!ctrl.value) {
          return { required: 'Select a region to search on' };
        }
      });
      if (regions.length) {
        regionControl.setValue(regions[0]);
        regionControl.enable();
      }
    });
  }

  private mustBeProtein(ctrl: AbstractControl): ValidationErrors | null {
    if (ctrl.value !== 'PROTEIN') {
      return {
        mustBeProtein: 'All query sequences must be Nucleotides to compare by Nucleotide',
      };
    }
    return null;
  }
}
