import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  HostBinding,
} from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { CleanUp } from '../../../shared/cleanup';
import { RestoreState } from '../../document-table-service/document-table-state/document-table-state';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-ngs-document-restore-screen',
  templateUrl: './ngs-document-restore-screen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, NgbProgressbar, AsyncPipe],
})
export class NgsDocumentRestoreScreenComponent
  extends CleanUp
  implements OnInit, OnDestroy, OnChanges
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @Input() documentID: string;
  @Input() tableName: string;

  isRestoreScreenVisible$: Observable<boolean>;
  documentID$: ReplaySubject<string>;
  tableName$: ReplaySubject<string>;
  restoreProgress$: Observable<RestoreState>;
  private tableIdentifier$: Observable<[string, string]>;

  constructor(private documentTableStateService: DocumentTableStateService) {
    super();
    this.documentID$ = new ReplaySubject(1);
    this.tableName$ = new ReplaySubject(1);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.documentID$.complete();
    this.tableName$.complete();
  }

  ngOnChanges({ documentID, tableName }: SimpleChanges) {
    if (documentID && documentID.currentValue) {
      this.documentID$.next(documentID.currentValue);
    }

    if (tableName && tableName.currentValue) {
      this.tableName$.next(tableName.currentValue);
    }
  }

  ngOnInit(): void {
    this.tableIdentifier$ = combineLatest([this.documentID$, this.tableName$]);
    const tableStates = this.tableIdentifier$.pipe(
      switchMap(([documentID, tableName]) =>
        combineLatest([
          this.documentTableStateService.getTableIndexState(documentID, tableName),
          this.documentTableStateService.getRestoreTableState(documentID, tableName),
        ]),
      ),
    );

    this.isRestoreScreenVisible$ = tableStates.pipe(
      map(([indexState, restoringState]) => [indexState === 'archived', restoringState.restoring]),
      map(([isTableArchived, isTableRestoring]) => isTableArchived && !isTableRestoring),
    );

    this.restoreProgress$ = tableStates.pipe(
      map(([_, restoringState]) => restoringState),
      filter((restoringState) => restoringState.restoring),
    );
  }

  restoreDefaultTable() {
    this.tableIdentifier$
      .pipe(
        switchMap(([documentID, tableName]) =>
          this.documentTableStateService
            .forceRestoreTable(documentID, tableName)
            // If the table doesn't exist, it will throw an error.
            .pipe(catchError(() => of(undefined))),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }
}
