import { PageMetaData } from './page-metadata.model';
import { Entity } from '../entity.model';

export class PageTableResponse<T> {
  data: T[];
  metadata: PageMetaData;

  static fromJson<T extends Entity>(json: any, Class: typeof Entity): PageTableResponse<T> {
    const tableResponse = new PageTableResponse<T>();
    tableResponse.metadata = json.metadata;
    tableResponse.data = json.data.map((item: any) => Class.fromJson(item));
    return tableResponse;
  }
}
