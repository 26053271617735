<form [formGroup]="form">
  <p class="text-muted px-2">
    Antibody Annotator identifies and annotates your sequences using a reference database. It has a
    number of advanced options to allow you to tweak annotation so please see its
    <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069571"
      >Support page</a
    >
    for more information.
  </p>
  <ngb-alert
    type="warning"
    [dismissible]="true"
    (closed)="dismissAlert$.next(true)"
    *bxDismissible="{ name: 'aa-positional-liabilities', dismiss$: dismissAlert$ }"
  >
    New Feature: Potential Positional Liabilities. Click "Reset to Defaults" to try now, or
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/11185576529940"
      target="_blank"
      class="alert-link"
    >
      go here
    </a>
    to learn more.
  </ngb-alert>
  @if (showNGSRecommendation$ | async) {
    <ngb-alert type="warning" [dismissible]="false">
      NGS Antibody Annotator is recommended for Illumina datasets and other documents with millions
      of sequences. For more information on NGS Antibody Annotator and how to try it, see
      <a
        href="https://help.geneiousbiologics.com/hc/en-us/articles/14409955057556"
        target="_blank"
        class="alert-link"
        >this article</a
      >.
    </ngb-alert>
  }

  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label
        class="col-3 col-form-label pe-1"
        for="database_customDatabase"
        ngbTooltip="The database(s) selected will be used as the reference for identifying and annotating query sequences."
      >
        Reference database(s):
        <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069531">
          <mat-icon class="icon-small">help</mat-icon>
        </a>
      </label>
      <div class="col-9">
        <bx-multi-select
          id="database_customDatabase"
          formControlName="database_customDatabase"
          [selectOptions]="referenceDatabases$ | async"
          entityName="reference database"
          emptyMessage="No reference databases are available"
          [showMultiple]="multipleRefDbsEnabled$ | async"
          [showFormErrors]="true"
          [canMarkPristineInvalid]="false"
        >
        </bx-multi-select>
      </div>
    </div>

    <div class="mb-3 row align-items-center">
      <label
        class="col-3 col-form-label"
        for="chain"
        ngbTooltip="The expected chain type(s) present in query sequences. The selected option allows Antibody Annotator to apply the appropriate part of the database to increase accuracy."
      >
        Selected sequences are:
      </label>
      <div class="col-9">
        <select id="chain" formControlName="sequences_chain" class="form-select">
          <option value="singleUnknownChain">Single chain (either heavy or light)</option>
          <option value="lightChain">Single chain (light)</option>
          <option value="heavyChain">Single chain (heavy)</option>
          <option value="bothChainsNoLinker">Both chains in single sequence</option>
          <option value="bothChainsNoLinkerOppositeDirections">
            Both chains in single sequence (opposite directions)
          </option>
          <option value="bothChainsWithLinker">
            Both chains in single sequence with linker (scFv)
          </option>
          <option value="bothChainsAssociatedSequencesUnknownOrder">
            Both chains in associated sequences
          </option>
          @if (twoHeavyChainsEnabled$ | async) {
            <option value="twoHeavyChainsNoLinker">Two heavy chains in single sequence</option>
            <option value="twoHeavyChainsWithLinker">
              Two heavy chains in single sequence with linker
            </option>
          }
        </select>
      </div>
    </div>

    @if (linkersEnabled$ | async) {
      <div class="mb-3 row">
        <label
          class="col-sm-6 col-lg-3 col-form-label mb-sm-2 mb-md-0"
          for="database_linkerDatabase"
          ngbTooltip="Select a linker database to annotate"
        >
          Linker database:
          <a target="_blank" href="#">
            <mat-icon class="icon-small">help</mat-icon>
          </a>
        </label>
        <div class="col-sm-6 col-lg-4 mb-sm-2 mb-lg-0">
          <select
            id="database_linkerDatabase"
            formControlName="database_linkerDatabase"
            class="form-select"
            data-testid="aa-linker-database"
          >
            <option [ngValue]="null">No Linker</option>
            @for (linkerDatabase of linkerDatabases$ | async; track linkerDatabase) {
              <option [value]="linkerDatabase.value">
                {{ linkerDatabase.displayName }}
              </option>
            }
          </select>
        </div>
        <div class="col-sm-12 col-lg-5 d-flex justify-content-end">
          <label
            for="database_linkerDatabaseMismatches"
            class="col-form-label me-2 text-nowrap"
            ngbTooltip="The maximum allowed mismatches that will be tolerated."
          >
            Maximum mismatches:
          </label>
          <div class="d-block">
            <div class="input-group has-validation">
              <input
                type="number"
                id="database_linkerDatabaseMismatches"
                formControlName="database_linkerDatabaseMismatches"
                ngFormControlValidator
                min="0"
                max="60"
                class="form-control"
              />
              <span class="input-group-text">%</span>
              <div class="invalid-feedback">Min. 0%, max. 60%</div>
            </div>
          </div>
        </div>
      </div>
    }

    <div class="mb-3 row align-items-center">
      <label
        class="col-3 col-form-label"
        for="results_fullyAnnotatedStart"
        [ngbTooltip]="fullyAnnotatedTooltip$ | async"
      >
        Sequence region of interest is between:
      </label>
      <div class="col-2">
        <select
          formControlName="results_fullyAnnotatedStart"
          id="results_fullyAnnotatedStart"
          class="form-select"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="CDR3">CDR3</option>
          <option value="FR4">FR4</option>
        </select>
      </div>
      <span class="col-auto col-form-label">and</span>
      <div class="col-2">
        <select
          formControlName="results_fullyAnnotatedEnd"
          id="results_fullyAnnotatedEnd"
          class="form-select"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="CDR3">CDR3</option>
          <option value="FR4">FR4</option>
          <option value="Constant Region">Constant Region</option>
        </select>
      </div>

      <span class="col-auto col-form-label">inclusive</span>
    </div>

    <bx-name-scheme-select
      *bxShowIf="'nameSchemes'"
      [parent]="form"
      controlName="fileNameSchemeID"
      [associatedNameSchemes]="associatedNameSchemes$ | async"
      class="mb-3"
    ></bx-name-scheme-select>

    <div class="row align-items-center mb-0">
      <label
        class="col-3 col-form-label"
        for="sequences_whenThreeOrMoreSequencesInGroup"
        ngbTooltip=""
      >
        If there are three or more sequences in a pair:
      </label>
      <div class="col-9 d-flex align-items-center">
        <select
          id="sequences_whenThreeOrMoreSequencesInGroup"
          formControlName="sequences_whenThreeOrMoreSequencesInGroup"
          class="form-select"
        >
          <option value="leaveSequencesUnpaired">Leave sequences unpaired</option>
          <option value="createAllCombinations">Show all possible Heavy/Light combinations</option>
        </select>
      </div>
    </div>
  </bx-card>

  <bx-collapsible-card headerTitle="Analysis Options" [collapseByDefault]="true">
    <div class="mb-3 row align-items-center">
      <label
        for="sequences_annotationStyle"
        class="col-lg-4 col-sm-3 col-form-label"
        ngbTooltip="Antibody numbering scheme will be used to define CDR boundaries"
      >
        Antibody numbering:
      </label>
      <div class="col-4">
        <select
          id="sequences_annotationStyle"
          formControlName="sequences_annotationStyle"
          class="form-select"
        >
          @for (style of annotationStyleOptions; track style) {
            <option [value]="style.value">
              {{ style.label }}
            </option>
          }
        </select>
      </div>
      <div class="col-lg-4 col-sm-5 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="sequences_addNumberingAnnotations"
            formControlName="sequences_addNumberingAnnotations"
            class="form-check-input"
          />
          <label
            for="sequences_addNumberingAnnotations"
            ngbTooltip="Number amino acids in antibodies according to the numbering scheme. Slows analysis with large datasets."
            class="form-check-label"
          >
            Annotate Numbers
          </label>
        </div>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="genes_annotateGermlineGeneDifferences"
        formControlName="genes_annotateGermlineGeneDifferences"
        class="form-check-input"
      />
      <label
        for="genes_annotateGermlineGeneDifferences"
        class="form-check-label"
        ngbTooltip="Selecting this option will make Antibody Annotator annotate how each query sequence differs from its best match in the reference database. Differences will be annotated for differing nucleotides and amino acids. Slows analysis with large datasets."
      >
        Annotate variants from reference database
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="results_calculateProteinStatistics"
        formControlName="results_calculateProteinStatistics"
        class="form-check-input"
      />
      <label
        for="results_calculateProteinStatistics"
        class="form-check-label"
        ngbTooltip="Calculate protein statistics such as Molecular Weight and Isoelectric Point for the VJ, VDJ and scFv regions."
      >
        Calculate protein statistics
      </label>
    </div>

    <div class="mb-3 row align-items-center">
      <div class="col-4">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="findLiabilities"
            formControlName="findLiabilities"
            class="form-check-input"
          />
          <label
            for="findLiabilities"
            ngbTooltip="Search and score motifs liable to post-translational modifications or any other types of modifications or beneficial motifs. Slows analysis with large datasets."
            class="form-check-label"
          >
            Find liabilities and assets:
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070271"
            >
              <mat-icon class="icon-small">help</mat-icon>
            </a>
          </label>
        </div>
      </div>
      <div class="col-8">
        <textarea formControlName="liabilities_liabilitiesText" rows="3" class="form-control">
        </textarea>
      </div>
    </div>

    @if (isNucleotides) {
      <div class="row align-items-center mb-0">
        <div class="col-sm-12 col-lg-6 d-flex align-items-center flex-nowrap flex-grow-1 mb-2">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="database_findAdditionalFeatures"
              formControlName="database_findAdditionalFeatures"
              class="form-check-input"
            />
            <label
              for="database_findAdditionalFeatures"
              ngbTooltip="Select a reference database to annotate additional features (such as signal peptides and promoters) on the query sequences."
              class="form-check-label text-nowrap"
            >
              Additional features:
              <a
                target="_blank"
                href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069831"
              >
                <mat-icon class="icon-small">help</mat-icon>
              </a>
            </label>
          </div>
          <bx-select
            id="database_featureDatabase"
            formControlName="database_featureDatabase"
            class="flex-grow-1"
            [selectOptions]="featureDatabases$ | async"
            [showFormErrors]="true"
          ></bx-select>
        </div>
        <div
          class="col-sm-12 col-lg-6 d-flex align-items-center flex-nowrap justify-content-end mb-2"
        >
          <label
            for="database_featureDatabaseMismatches"
            ngbTooltip="The maximum allowed mismatches that will be tolerated for reference feature annotation."
            class="col-form-label ms-3 me-2 text-nowrap"
          >
            Mismatches %:
          </label>
          <input
            type="number"
            id="database_featureDatabaseMismatches"
            formControlName="database_featureDatabaseMismatches"
            ngFormControlValidator
            min="0"
            max="99"
            class="form-control me-3"
          />
          <label
            for="database_featureDatabaseGapSize"
            ngbTooltip="The maximum allowed gap size that will be tolerated for reference feature annotation."
            class="col-form-label me-2 text-nowrap"
          >
            Gap size:
          </label>
          <input
            type="number"
            id="database_featureDatabaseGapSize"
            formControlName="database_featureDatabaseGapSize"
            ngFormControlValidator
            min="0"
            max="99"
            class="form-control"
          />
        </div>
      </div>
    }
  </bx-collapsible-card>

  @if (!forMasterDatabase) {
    <bx-clustering-options-card
      [filterRegionsFor]="selectedChain$ | async"
      [collapseByDefault]="true"
    >
    </bx-clustering-options-card>
  }

  <bx-collapsible-card headerTitle="Advanced Options" [collapseByDefault]="true">
    @if (isNucleotides) {
      <div class="mb-3 row align-items-center">
        <label
          for="sequences_queryGeneticCode"
          ngbTooltip="The genetic code to use for translating nucleotide sequences. Codes obtained from NCBI."
          class="col-4 col-form-label"
        >
          Genetic code:
        </label>
        <div class="col-8">
          <select
            id="sequences_queryGeneticCode"
            formControlName="sequences_queryGeneticCode"
            class="form-select"
          >
            @for (geneticCode of geneticCodes$ | async; track geneticCode) {
              <option [value]="geneticCode.value">
                {{ geneticCode.displayName }}
              </option>
            }
          </select>
        </div>
      </div>
    }
    <div class="mb-3 row align-items-center">
      <label
        for="genes_ambiguousGenesStrategy"
        class="col-4 col-form-label"
        ngbTooltip="How to handle cases where query sequences are equally close to multiple reference genes."
      >
        Record equal gene matches as:
      </label>
      <div class="col-8">
        <select
          formControlName="genes_ambiguousGenesStrategy"
          id="genes_ambiguousGenesStrategy"
          class="form-select"
        >
          <option value="partialFrequency">
            Each gene with partial frequency (e.g. frequency of 0.5 for each gene)
          </option>
          <option value="groupOfGenes">
            Group of genes (e.g. assigned to a cluster called IGHD1-26/IGHD2-15)
          </option>
          <option value="unknown">
            Unknown (treat it the same as if it didn't match any gene)
          </option>
        </select>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="genes_onlyAnnotateAminoAcidDifferences"
        formControlName="genes_onlyAnnotateAminoAcidDifferences"
        class="form-check-input"
      />
      <label
        for="genes_onlyAnnotateAminoAcidDifferences"
        ngbTooltip="Optimises performance for long read sequences by reducing the number of sequence annotations."
        class="form-check-label"
      >
        Long reads (PacBio/Nanopore)
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="genes_includePseudoGenes"
        formControlName="genes_includePseudoGenes"
        class="form-check-input"
      />
      <label
        for="genes_includePseudoGenes"
        ngbTooltip="Some reference databases include pseudogenes in addition to functional genes. Selecting this option will allow classification of query sequences using these pseudogenes."
        class="form-check-label"
      >
        Include pseudogenes from database
      </label>
    </div>
    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="genes_includeOrfGenes"
        formControlName="genes_includeOrfGenes"
        class="form-check-input"
      />
      <label
        for="genes_includeOrfGenes"
        ngbTooltip="Some reference databases include ORF genes in addition to functional genes. Selecting this option will allow classification of query sequences using these ORFs."
        class="form-check-label"
      >
        Include ORF genes from database
      </label>
    </div>

    @if (isNucleotides) {
      <div class="mb-3 row align-items-center">
        <div class="col-auto">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="trimSequences"
              formControlName="results_trimSequences"
              class="form-check-input"
            />
            <label
              for="trimSequences"
              ngbTooltip="Select this option to trim a specified number of bases upstream and downstream in fully annotated query sequences."
              class="form-check-label"
            >
              Trim each side of fully annotated region if over:
            </label>
          </div>
        </div>
        <div class="col-auto">
          <input
            type="number"
            id="trimSequencesLength"
            formControlName="results_trimSequencesLength"
            ngFormControlValidator
            min="0"
            max="9999"
            class="form-control me-2"
          />
          <div class="invalid-feedback">Base pairs must be between 0 and 9999</div>
        </div>
        <div class="col-auto">
          <label class="col-form-label" for="trimSequencesLength">base pairs</label>
        </div>
      </div>
    }

    @if (isNucleotides) {
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          id="genes_largeVGaps"
          formControlName="genes_largeVGaps"
          class="form-check-input"
        />
        <label
          for="genes_largeVGaps"
          ngbTooltip="Selecting this option will allow annotation of query sequences with large gaps inside the V gene (e.g. for knottins)."
          class="form-check-label"
        >
          Allow large V gene gaps (e.g. for custom feature insertions)
        </label>
      </div>
    }

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="forceFullRegionAnnotationOutsideCDR3"
        formControlName="forceFullRegionAnnotationOutsideCDR3"
        class="form-check-input"
      />
      <label
        for="forceFullRegionAnnotationOutsideCDR3"
        ngbTooltip="If a CDR or FR annotation would be truncated due to mismatches, instead force it to end at the boundary of the respective CDR/FR region. That region will be complete and not truncated."
        class="form-check-label"
      >
        Always annotate entire regions (except CDR3)
      </label>
    </div>

    <hr />

    <bx-fr-adjustment-options
      class="mb-3"
      formControlNamePrefix="sequences_"
    ></bx-fr-adjustment-options>

    <div class="d-flex" *bxShowIf="'showCustomAdvancedOptionsTextField'">
      <label
        ngbTooltip="Allows specifying custom options separated by a space, with = between each option name and values. If an option value needs to contain a space use '\s'"
        class="col-form-label text-nowrap me-2"
      >
        Custom Options:
      </label>
      <input
        type="text"
        id="customAdvancedOptions"
        formControlName="customAdvancedOptions"
        ngFormControlValidator
        class="form-control"
      />
    </div>
  </bx-collapsible-card>

  @if (!forMasterDatabase) {
    <bx-card headerTitle="Output" customClass="mb-0">
      <bx-pipeline-output
        [resultNameFormControl]="form.get('resultName')"
        [outputNameFormControl]="form.get('outputFolderName')"
      ></bx-pipeline-output>
    </bx-card>
  }
</form>
