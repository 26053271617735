import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, NEVER, Observable, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ChartPresenterComponent } from '../../../features/graphs/chart-presenter/chart-presenter.component';
import { hasChanged } from '../../viewer/viewer-state/viewer-state.model';
import {
  AbaAllChartOptions,
  GraphAbaDataService,
} from '../../../features/graphs/graph-aba-data.service';
import { DocumentUtils } from '../../document-utils';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { CleanUp } from '../../../shared/cleanup';
import { annotatedPluginDocumentViewerSelector } from '../../viewer-components/viewer-selectors';
import {
  ViewerDocumentData,
  ViewerDocumentSelection,
} from '../../viewer-components/viewer-document-data';
import { GraphDatasource } from '../../../features/graphs/graph-sidebar';
import AbaColumnGraphDatasource from '../../../features/graphs/datasources/aba-column-graph-datasource';
import AbaAminoAcidDistributionGraphDatasource from '../../../features/graphs/datasources/aba-amino-acid-distribution-graph-datasource';
import AbaHeatmapGraphDatasource from '../../../features/graphs/datasources/aba-heatmap-graph-datasource';
import AbaGeneUsageDatasource from '../../../features/graphs/datasources/aba-gene-usage-datasource';
import { SummaryGraphType } from '../../../features/report/report.model';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import {
  RestoreProgress,
  NgsTableRestoringOverlayComponent,
} from '../ngs-table-restoring-overlay/ngs-table-restoring-overlay.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { GraphZoomComponent, ZoomableChart } from '../ngs-graphs/graph-zoom/graph-zoom.component';
import { NgsZoomService } from '../ngs-graphs/graph-zoom/ngs-zoom.service';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { SelectComponent } from '../../../shared/select/select.component';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbTooltip,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';

@ViewerComponent({
  key: 'ngs-summary-graph-viewer',
  title: 'Graphs',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
        1,
        1,
      ),
    ],
    (data) => {
      return (
        !data.isPreviewView &&
        !data.isFreeOrg &&
        data.selection.rows[0].getAllFields().hideNGSGraphs !== 'true'
      );
    },
  ),
})
@Component({
  selector: 'bx-ngs-summary-graph-viewer',
  templateUrl: './ngs-summary-graph-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PageMessageComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    NgsTableRestoringOverlayComponent,
    ChartPresenterComponent,
    AsyncPipe,
    GraphZoomComponent,
  ],
})
export class NgsSummaryGraphViewerComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-hidden';

  readonly form = new FormGroup({
    graph: new FormControl<SummaryGraphChoice>(null),
  });
  allOptions$: Observable<AbaAllChartOptions>;

  graphs$: Observable<SelectGroup<SummaryGraphChoice>[]>;
  graph$: Observable<SummaryGraphChoice | null>;
  document$: BehaviorSubject<AnnotatedPluginDocument>;
  resizeSubscription = Subscription.EMPTY;
  stateSub = Subscription.EMPTY;

  @ViewChild('graph') graph: ChartPresenterComponent;

  progress: number = null;
  errorMessage$ = new BehaviorSubject<string | null>(null);
  loading$ = new BehaviorSubject<boolean>(true);
  datasource$: Observable<GraphDatasource>;
  restoreProgress$: Observable<RestoreProgress>;
  restoringTable$: Observable<boolean>;
  zoomControls$: Observable<ZoomableChart | null>;
  private progressCounter: any;
  private openedAt: number;
  private state$: Observable<ViewerDocumentSelection>;
  private readonly tables$: Observable<Record<string, DocumentTable>>;

  constructor(
    private dataService: GraphAbaDataService,
    private cd: ChangeDetectorRef,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private documentTableStateService: DocumentTableStateService,
    private ngsZoomService: NgsZoomService,
  ) {
    super();

    this.document$ = new BehaviorSubject(null);
    this.state$ = this.viewerDataService
      .getData('ngs-summary-graph-viewer')
      .pipe(map((data) => data.selection));
    this.tables$ = this.document$.asObservable().pipe(
      map((document) => document.id),
      switchMap((documentID) => this.documentTableStateService.getTablesMap(documentID)),
    );

    this.graphs$ = this.document$.pipe(
      filter((document) => !!document),
      map((document) => {
        const documentCreationOptions = document.getAnnotatorCreationOptions();
        const isPeptideOrProteinResult =
          documentCreationOptions?.sequences_chain === 'genericSequence';
        return [
          new SelectGroup<SummaryGraphChoice>(
            [
              {
                name: 'Annotation Rates',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'annotationRates' },
              },
              {
                name: 'Number Of Clusters',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'clusters', type: 'aminoacid' },
              },
              {
                name: 'Number Of Clusters (Nucleotide)',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'clusters', type: 'nucleotide' },
                hidden: document.hasNoNucleotides(),
              },
              {
                name: 'Number Of Genes',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'genes' },
                hidden: isPeptideOrProteinResult,
              },
              {
                name: 'Gene Combinations',
                code: SummaryGraphType.HEATMAP,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'gene_combinations' },
                hidden: isPeptideOrProteinResult,
              },
              {
                name: 'Gene Usage',
                code: SummaryGraphType.STACKED_BAR_CHART,
                exportAsTable: true,
                params: { source: 'json', jsonChart: 'geneFamilyUsage' },
                hidden: isPeptideOrProteinResult,
              },
            ]
              .filter((graph) => !graph.hidden)
              .map((graph) => new SelectOption(graph.name, graph)),
            'Overview',
          ),
          new SelectGroup<SummaryGraphChoice>(
            [
              {
                name: 'Cluster Diversity',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'cluster', type: 'cluster_diversity' },
                hidden: false,
              },
              {
                name: 'Cluster Lengths',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'cluster', type: 'cluster_lengths' },
              },
              {
                name: 'Cluster Sizes',
                code: SummaryGraphType.BAR_CHART,
                exportAsTable: true,
                params: { source: 'cluster', type: 'cluster_sizes' },
              },
              {
                name: 'Amino Acid Distribution Chart',
                code: SummaryGraphType.STACKED_BAR_CHART,
                exportAsTable: true,
                params: { source: 'aminoAcid' },
              },
              {
                name: 'Codon Distribution Chart',
                code: SummaryGraphType.HEATMAP,
                exportAsTable: true,
                params: { source: 'codon' },
              },
            ]
              .filter((graph) => !graph.hidden)
              .map((graph) => new SelectOption(graph.name, graph)),
            'By Cluster (Region Or Gene)',
          ),
        ];
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.graph$ = currentValueAndChanges(this.form.controls.graph, this.ngUnsubscribe).pipe(
      tap((graph) => {
        this.ngsZoomService.deregisterZoomControls();
        if (graph !== null) {
          this.renderNewGraph(graph);
        }
      }),
    );
  }

  ngOnInit() {
    this.ngsZoomService.deregisterZoomControls();
    this.openedAt = Date.now();

    let lastId: string;

    // state$ is not ready until at least ngOnInit().
    const anyDoc$: Observable<AnnotatedPluginDocument> = this.state$.pipe(
      filter((state) => state && state.rows.length > 0),
      filter((state) => hasChanged(lastId, state.rows[0].id, () => (lastId = state.rows[0].id))),
      map((state) => state.rows[0]),
      filter((doc) => !!doc),
      filter((doc) => DocumentUtils.isResultDoc(doc)),
    );

    this.stateSub = anyDoc$.pipe(filter((doc) => !this.docIsLegacy(doc))).subscribe((doc) => {
      // Only pass valid non-legacy documents through.
      this.document$.next(doc);
      this.errorMessage$.next(null);
    });

    this.allOptions$ = this.document$.pipe(
      filter((doc) => !!doc),
      distinctUntilChanged((previous, current) => previous.id === current.id),
      switchMap((doc) =>
        this.dataService.getAllBarOptions(doc.id).pipe(
          catchError((error) => {
            if (error.status === 404) {
              this.showError('Graphs are not available for this document.');
            } else {
              this.showError();
            }
            return NEVER;
          }),
        ),
      ),
    );

    const tableRestorations = this.document$.pipe(
      switchMap((document) => this.documentTableStateService.getRestoreTableStates(document.id)),
      share(),
    );

    this.restoringTable$ = tableRestorations.pipe(
      map((restoreStates) => restoreStates.some((state) => state.restoring)),
      startWith(false),
    );

    this.restoreProgress$ = tableRestorations.pipe(
      map((restoringStates) => {
        const restoringCount = restoringStates.length;
        const totalProgress = restoringStates
          .filter((state) => !state.restored)
          .map((state) => state.progress)
          .reduce((prev, curr) => prev + curr, 0);

        const overallProgress = totalProgress / restoringCount;

        return {
          progress: overallProgress,
        };
      }),
    );

    // // Render the first graph in the list initially.
    setTimeout(() => {
      this.graphs$.pipe(take(1)).subscribe((graphOptions) => {
        this.form.controls.graph.setValue(graphOptions[0]?.options[0].value);
      });
    });
    this.zoomControls$ = this.ngsZoomService.getZoomControls();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    clearInterval(this.progressCounter);
    this.resizeSubscription.unsubscribe();
    this.stateSub.unsubscribe();
  }

  exportGraph() {
    if (this.graph) {
      this.graph.exportGraph(this.document$.getValue()?.name);
    }
  }

  get isGraphAvailable() {
    if (!this.graph) {
      return false;
    }
    return this.graph.isGraphAvailable;
  }

  exportGraphAsTable() {
    if (this.graph) {
      const graphName = this.form.controls.graph.value.name;
      const documentName = this.document$.getValue()?.name;
      if (graphName === 'Gene Usage') {
        this.graph.exportGraphAsTable({
          documentName,
          headerText: 'Gene Family',
        });
      } else if (graphName === 'Amino Acid Distribution Chart') {
        this.graph.exportGraphAsTable({
          documentName,
          headerText: 'Position',
        });
      } else {
        this.graph.exportGraphAsTable({
          documentName,
        });
      }
    }
  }

  renderNewGraph(newGraph: SummaryGraphChoice) {
    switch (newGraph.code) {
      case 'stacked-bar-chart':
        if (newGraph.params.source === 'json' && newGraph.params.jsonChart === 'geneFamilyUsage') {
          this.createDatasource(AbaGeneUsageDatasource, newGraph);
        } else {
          this.createDatasource(AbaAminoAcidDistributionGraphDatasource, newGraph);
        }
        break;
      case 'heatmap':
        this.createDatasource(AbaHeatmapGraphDatasource, newGraph);
        break;
      case 'error':
        this.showError(newGraph.params?.message);
        break;
      default:
        this.createDatasource(AbaColumnGraphDatasource, newGraph);
        break;
    }
  }

  private docIsLegacy(doc: any) {
    return doc.documentParts.find((ref: any) => ref.name === 'NGS_SEQUENCES');
  }

  private createDatasource(
    datasource: new (
      dataService: GraphAbaDataService,
      documentID: string,
      tables: Record<string, DocumentTable>,
      params: SummaryGraphParams,
      allOptions: AbaAllChartOptions,
    ) => GraphDatasource,
    graph: SummaryGraphChoice,
  ) {
    this.loading$.next(true);
    this.errorMessage$.next(null);
    this.datasource$ = this.allOptions$.pipe(
      withLatestFrom(this.tables$),
      map(
        ([allOptions, tables]) =>
          new datasource(
            this.dataService,
            this.document$.getValue().id,
            tables,
            graph.params,
            allOptions,
          ),
      ),
      tap(() => this.loading$.next(false)),
    );
    this.cd.markForCheck();
  }

  private showError(message: string = 'An unexpected error occurred.') {
    this.errorMessage$.next(message);
  }
}

export interface SummaryGraphChoice {
  // Friendly name.
  name: string;
  // This should match one of the available presenters, or "placeholder" if it is a non-functional menu item.
  code: string;
  // Params that may be required by the presenter in order to retrieve data or customise the graph.
  params: SummaryGraphParams;
  // If this exporter supports being exported to csv.
  exportAsTable: boolean;
  // If this graph option should be hidden
  hidden?: boolean;
}

export interface SummaryGraphParams {
  source?: string;
  jsonChart?: string;
  type?: string;
  message?: string;
}
