import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieInterceptor } from './cookie-interceptor';
import { OutageInterceptor } from './outage.interceptor';
import { BuildInfoInterceptor } from './build-info-interceptor';

export const httpInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: BuildInfoInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: OutageInterceptor, multi: true },
];
