import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ChartOptions } from 'highcharts';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import { DownloadTableOptions } from '../exportable-chart';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'bx-sankey-plot',
  templateUrl: '../abstract-column-chart/base-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class SankeyPlotComponent extends BaseChartComponent<any> {
  @ViewChild(ChartComponent) chart: ChartComponent;

  chartOptions: ChartOptions = {
    type: 'sankey',
    marginBottom: 50,
  };
  tooltipOptions = {};
  plotOptions = {};
  downloadTable(options: DownloadTableOptions): void {}
}
