<bx-settings-breadcrumb
  [crumbs]="[
    { link: ['', 'users'], label: 'Users' },
    { link: ['', 'users', 'api-keys'], label: 'API Keys' },
  ]"
></bx-settings-breadcrumb>

@if (apiKeysEnabled$ | async) {
  <bx-api-keys-table
    class="flex-grow-1 w-100"
    [apiKeysSource]="'currentOrg'"
    [showRefreshButton]="true"
    [showRevokeButton]="true"
  ></bx-api-keys-table>
} @else {
  <bx-page-message>
    <div [outerHTML]="apiKeysService.getDefaultApiKeysDisabledMessage()"></div>
  </bx-page-message>
}
