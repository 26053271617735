import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { switchMap, take } from 'rxjs';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { AuthService } from '@geneious/nucleus-api-client';

@Component({
  selector: 'bx-email-change-confirmation',
  standalone: true,
  imports: [RouterLink, SpinnerComponent],
  templateUrl: './email-change-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailChangeConfirmationComponent {
  activatedRoute = inject(ActivatedRoute);
  authService = inject(AuthService);

  isEmailChangeSuccess = signal<boolean>(null);
  isDuplicateEmailError = signal<boolean>(null);

  constructor() {
    this.activatedRoute.fragment
      .pipe(
        take(1),
        switchMap((emailChangeToken) => this.authService.consume({ token: emailChangeToken })),
      )
      .subscribe({
        next: () => {
          this.isEmailChangeSuccess.set(true);
        },
        error: (response) => {
          if (response.error?.error?.code === 'DuplicateEmailAddress') {
            this.isDuplicateEmailError.set(true);
          }
          this.isEmailChangeSuccess.set(false);
        },
      });
  }
}
