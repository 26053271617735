import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ApiKeysService } from '../api-keys.service';
import { Observable } from 'rxjs';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { AsyncPipe } from '@angular/common';
import { ApiKeysTableComponent } from '../api-keys-table/api-keys-table.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

@Component({
  selector: 'bx-users-api-keys-manager',
  templateUrl: './users-api-keys-manager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SettingsBreadcrumbComponent, ApiKeysTableComponent, PageMessageComponent, AsyncPipe],
})
export class UsersApiKeysManagerComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  apiKeysEnabled$: Observable<boolean>;

  constructor(public apiKeysService: ApiKeysService) {}

  ngOnInit(): void {
    this.apiKeysEnabled$ = this.apiKeysService.isApiKeysEnabled();
  }
}
