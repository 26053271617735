import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, zip } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PipelineService } from '../../pipeline/pipeline.service';
import { SelectOption } from '../../models/ui/select-option.model';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FolderService } from '../../folders/folder.service';
import { FolderKindsEnum } from '../../folders/models/folderKinds';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { FormatterService } from '../../../shared/formatter.service';
import { ActivityStreamService } from '../../activity/activity-stream.service';
import { JobActivityEventKind } from '../../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  JobActivityEvent,
  JobProgressedActivityEvent,
  JobQueuedActivityEvent,
  JobResultAddedActivityEvent,
  isNonStageJobEvent,
} from '../../../../nucleus/v2/models/activity-events/job-activity-event.model';
import { JobConfiguration } from '@geneious/nucleus-api-client';
import { NucleusPipelineID } from '../../pipeline/pipeline-constants';
import { PipelineAssociationsService } from '../../pipeline/pipeline-associations/pipeline-associations.service';
import { PipelineFormControlValidatorsService } from '../../pipeline-dialogs/pipeline-form-control-validators.service';
import { OrgProfileCheckService } from '../../../shared/access-check/org-profile-check/org-profile-check.service';
import {
  getFreeOptions,
  SequencesAnnotationStyle,
  SequencesAnnotationStyles,
} from '../../pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';
import { DocumentImportService } from '../../pipeline-dialogs/document-import/document-import.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated, selectUserEmail } from '../../auth/auth.selectors';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { CleanUp } from 'src/app/shared/cleanup';
import { AsyncPipe } from '@angular/common';
import { ClearFileButtonComponent } from '../../../shared/clear-file-button/clear-file-button.component';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-quick-analysis',
  templateUrl: './quick-analysis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ClearFileButtonComponent,
    MultiSelectComponent,
    FormErrorsComponent,
    RouterLink,
    MatIconModule,
    AsyncPipe,
  ],
})
export class QuickAnalysisComponent extends CleanUp implements OnInit, OnDestroy {
  static readonly INVALID_FASTA_FILE_FORMAT_MESSAGE =
    'Invalid FASTA sequence format. A valid FASTA file should contain alternate lines of sequence name and the corresponding sequence.';
  static readonly INVALID_CHARACTERS_IN_RAW_SEQUENCE_MESSAGE =
    'The entered sequence contains invalid characters. Only alphabetical characters are allowed.';
  static readonly INVALID_CHARACTERS_IN_FASTA_SEQUENCE_MESSAGE =
    'The entered sequence contains invalid characters. Only alphabetical characters and *,- and (i) are allowed.';

  referenceDatabases$: Observable<SelectOption[][]>;
  isAnalyseDisabled$: Observable<boolean>;
  jobProgress$: Observable<number>;
  resultFolder$: Observable<FolderTreeItem>;
  viewResultParams$: Observable<ViewerPageURLSelectionState>;
  result$: Observable<{ show: boolean; params: ViewerPageURLSelectionState }>;
  maxInputSequenceCount$: Observable<number | null>;
  multipleRefDbsEnabled$: Observable<boolean>;
  readonly showResultPrompt$ = new BehaviorSubject(false);
  readonly analyseSubmission$ = new Subject();
  readonly isAnalysisInProgress$ = new BehaviorSubject(false);
  readonly isAnalysisComplete$ = new BehaviorSubject(false);
  readonly validationWarnings$ = new BehaviorSubject<string[]>([]);
  readonly jobErrors$ = new BehaviorSubject<string[]>([]);
  @ViewChild('fileInput') fileInput: ElementRef;
  readonly sequenceInputText = new BxFormControl('');
  readonly sequenceInputFile = new BxFormControl(undefined);
  readonly referenceDatabase = new BxFormControl<string[]>([], Validators.required);

  readonly availableAnnotationSchemes: readonly SequencesAnnotationStyle[] =
    SequencesAnnotationStyles;
  readonly annotationScheme = new BxFormControl<SequencesAnnotationStyle>(
    'IMGT',
    Validators.required,
  );

  form = new BxFormGroup({
    sequenceInputText: this.sequenceInputText,
    sequenceInputFile: this.sequenceInputFile,
    referenceDatabase: this.referenceDatabase,
    annotationScheme: this.annotationScheme,
  });

  private sequenceFileToUpload$: Observable<File>;

  private jobID$ = new BehaviorSubject<string | null>(null);
  private readonly formDefaults: any;
  private pipelineID: NucleusPipelineID = 'import-and-annotate';

  private subscription = new Subscription();

  constructor(
    private readonly pipelineService: PipelineService,
    private readonly documentImportService: DocumentImportService,
    private readonly folderService: FolderService,
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly activityStreamService: ActivityStreamService,
    private readonly documentHttpService: DocumentHttpV2Service,
    private pipelineAssociationService: PipelineAssociationsService,
    private readonly validatorService: PipelineFormControlValidatorsService,
    private readonly orgProfileCheckService: OrgProfileCheckService,
    private readonly store: Store<AppState>,
  ) {
    super();
    this.formDefaults = this.form.getRawValue();
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
  }

  static getMaxAllowedSequenceCountExceededMessage = (maxInputSequenceCount: number) =>
    `Up to ${maxInputSequenceCount} sequences can be analysed at once with your plan (Geneious Biologics Starter Plan). Please select less sequences, or contact us to request an upgrade.`;

  ngOnInit(): void {
    this.referenceDatabases$ = this.pipelineService.getReferenceDatabases().pipe(
      first(),
      tap((options) => {
        const firstDB = options[0][0]?.value;
        if (firstDB) {
          this.form.controls.referenceDatabase.setValue([firstDB]);
        }
      }),
      share(),
    );

    this.jobProgress$ = this.listenToJob().pipe(
      filter(
        (jobActivityEvent) => jobActivityEvent.event.kind === JobActivityEventKind.JOB_PROGRESSED,
      ),
      map((jobActivityEvent) => jobActivityEvent.event as JobProgressedActivityEvent),
      map((event) => event.progress),
      startWith(0),
    );

    this.maxInputSequenceCount$ = this.pipelineAssociationService
      .getProfilePipelineAssociationParameters(this.pipelineID)
      .pipe(
        map((params: { maxInputSequenceCount?: number }) => params.maxInputSequenceCount ?? null),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

    this.sequenceFileToUpload$ = this.getInputSequenceFile();
    this.isAnalyseDisabled$ = this.sequenceFileToUpload$.pipe(
      startWith(null),
      map((file) => !file),
    );

    this.resultFolder$ = this.getResultFolder();

    this.handleOpenResultDocumentRequest();
    this.handleAnalyseSubmission();
    this.handleJobComplete();
    this.result$ = combineLatest([this.showResultPrompt$, this.viewResultParams$]).pipe(
      map(([show, params]) => ({
        show,
        params,
      })),
    );

    // Async Validator needs to be set AFTER form creation due to a bug in Angular forms with Async Validators causing
    // form status to be stuck in PENDING.
    // Hopefully fixed soon:
    // https://github.com/angular/angular/pull/20806
    // https://github.com/angular/angular/pull/22575
    // https://github.com/angular/angular/issues/13200
    setTimeout(() => {
      this.form
        .get('referenceDatabase')
        .setAsyncValidators(this.validatorService.databaseValidator());
      this.form.get('referenceDatabase').updateValueAndValidity();
    });
  }

  handleSequenceUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    this.sequenceInputText.setValue('');
    this.form.patchValue({ sequenceInputFile: file });
  }

  clearSelectedFile() {
    this.fileInput.nativeElement.value = '';
    this.sequenceInputFile.setValue(undefined);
  }

  handleClearFile(event: MouseEvent) {
    const input = event.target as HTMLInputElement;
    input.value = '';
    this.clearSelectedFile();
  }

  reset() {
    this.isAnalysisComplete$.next(false);
    this.showResultPrompt$.next(false);
    this.resetFormState();
  }

  ngOnDestroy() {
    this.jobID$.complete();
    this.isAnalysisInProgress$.complete();
    this.isAnalysisComplete$.complete();
    this.showResultPrompt$.complete();
    this.subscription.unsubscribe();
    this.validationWarnings$.complete();
    this.jobErrors$.complete();
  }

  private getInputSequenceFile(): Observable<File> {
    const inputSequenceText$ = this.sequenceInputText.valueChanges as Observable<string>;
    const inputSequenceFile$ = this.sequenceInputFile.valueChanges as Observable<File | undefined>;
    return combineLatest([
      inputSequenceText$.pipe(
        startWith(this.sequenceInputText.value),
        tap((sequenceText) => {
          if (sequenceText && sequenceText.length !== 0) {
            this.clearSelectedFile();
          }
        }),
        map((sequenceText) => this.getCleanedSequenceText(sequenceText)),
        withLatestFrom(this.maxInputSequenceCount$),
        distinctUntilChanged(),
      ),
      inputSequenceFile$.pipe(startWith(this.sequenceInputFile.value), distinctUntilChanged()),
    ]).pipe(
      map(([[sequenceText, maxInputSequenceCount], sequenceFile]) => {
        this.validationWarnings$.next([]);
        this.jobErrors$.next([]);
        if (sequenceText) {
          this.validationWarnings$.next(
            this.validateSequenceText(sequenceText, maxInputSequenceCount),
          );
          const formattedDate = FormatterService.formatDateString(new Date().toISOString());
          const sequenceFileExtension = this.isRawSequence(sequenceText) ? '.txt' : '.fasta';
          return new File(
            [sequenceText],
            `Input Sequence ${formattedDate}${sequenceFileExtension}`,
          );
        }
        return sequenceFile;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private validateSequenceText(
    sequenceText: string,
    maxInputSequenceCount: number | null,
  ): string[] {
    const errors: string[] = [];
    if (this.isRawSequence(sequenceText)) {
      if (sequenceText.length > 0 && !sequenceText.match('^[a-zA-Z]+$')) {
        errors.push(QuickAnalysisComponent.INVALID_CHARACTERS_IN_RAW_SEQUENCE_MESSAGE);
      }
    } else {
      if (
        maxInputSequenceCount !== null &&
        this.getSequenceCount(sequenceText) > maxInputSequenceCount
      ) {
        errors.push(
          QuickAnalysisComponent.getMaxAllowedSequenceCountExceededMessage(maxInputSequenceCount),
        );
      }
      errors.push(...this.validateFASTAText(sequenceText));
    }
    return errors;
  }

  private validateFASTAText(sequenceText: string): string[] {
    const errors: string[] = [];
    const parts = sequenceText.split('\n').filter((x) => x);
    const containsOddNumberOfElements = parts.length % 2 === 1;
    // elements at odd index positions should not contain > character
    const sequenceElements = parts.filter((_, index) => index % 2 === 1);
    const containsInvalidSequenceElements =
      sequenceElements.filter((x) => x.startsWith('>')).length > 0;
    // elements at even index positions should start with > character
    const containsInvalidNameElements =
      parts.filter((_, index) => index % 2 === 0).filter((x) => !x.startsWith('>')).length > 0;
    if (
      containsOddNumberOfElements ||
      containsInvalidSequenceElements ||
      containsInvalidNameElements
    ) {
      errors.push(QuickAnalysisComponent.INVALID_FASTA_FILE_FORMAT_MESSAGE);
    } else if (
      sequenceElements
        .map((sequence) => /^([a-zA-Z\*\-]|\(i\))+$/i.test(sequence))
        .some((isValid) => isValid === false)
    ) {
      errors.push(QuickAnalysisComponent.INVALID_CHARACTERS_IN_FASTA_SEQUENCE_MESSAGE);
    }
    return errors;
  }

  private getSequenceCount(sequenceString: string): number {
    return (sequenceString.match(/>/g) || []).length;
  }

  private isRawSequence(sequenceText: string): boolean {
    return !sequenceText.trim().startsWith('>');
  }

  private getCleanedSequenceText(sequenceText: string): string {
    return this.isRawSequence(sequenceText) ? sequenceText.replace(/\s/g, '') : sequenceText.trim();
  }
  private getResultFolder(): Observable<FolderTreeItem | null> {
    return this.getResultFolderName().pipe(
      switchMap((folderName) =>
        this.folderService
          .filterFolders((folder) => {
            return folder.kind === FolderKindsEnum.FOLDER && folder.name === folderName;
          })
          .pipe(
            map((resultFolder) => {
              if (resultFolder.length > 0) {
                return resultFolder[0];
              }
              return null;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
          ),
      ),
    );
  }

  private createResultFolder(): Observable<FolderTreeItem> {
    return this.createResultFolderWithSuffix();
  }

  private createResultFolderWithSuffix(): Observable<FolderTreeItem> {
    return zip(this.folderService.sharedWorkspaceFolderID$, this.getResultFolderName()).pipe(
      switchMap(([sharedWorkspaceID, folderName]) => {
        return this.folderService.create(sharedWorkspaceID, folderName, FolderKindsEnum.FOLDER);
      }),
      take(1),
    );
  }

  private getResultFolderName(): Observable<string> {
    const resultFolderPrefix = 'Quick Analysis Results';
    return this.orgProfileCheckService.hasOrgProfileCategory('free').pipe(
      switchMap((isFreeOrg) => {
        if (isFreeOrg) {
          return of(resultFolderPrefix);
        } else {
          return this.store.select(selectIsAuthenticated).pipe(
            filter((isAuthenticated) => isAuthenticated),
            switchMap(() => this.store.select(selectUserEmail)),
            map((email) => this.replaceSpecialCharacters(`${resultFolderPrefix} - ${email}`)),
          );
        }
      }),
    );
  }

  private replaceSpecialCharacters(str: string) {
    return str.replace(/[\/\\+:*?<>{}|\[\]]/g, '_');
  }

  private createResultFolderIfNotExist(): Observable<FolderTreeItem> {
    return this.resultFolder$.pipe(
      take(1),
      switchMap((resultFolder) => (resultFolder ? of(resultFolder) : this.createResultFolder())),
    );
  }

  private handleAnalyseSubmission() {
    this.subscription.add(
      this.analyseSubmission$
        .pipe(
          withLatestFrom(this.sequenceFileToUpload$),
          switchMap(([_, file]) =>
            this.createResultFolderIfNotExist().pipe(
              map((resultFolder) => ({ resultFolder, file })),
            ),
          ),
        )
        .subscribe(({ resultFolder, file }) => {
          const optionValues = getFreeOptions({
            database_customDatabase: this.referenceDatabase.value,
            sequences_annotationStyle: this.annotationScheme.value,
            sequences_chain: 'singleUnknownChain',
          });
          const importOptions = {
            groupSequences: false,
            additionalJobParameters: {
              options: {
                optionValues,
              },
            },
          };
          this.documentImportService.import(
            resultFolder.id,
            '/folders',
            [file],
            this.pipelineID,
            importOptions,
          );
          this.isAnalysisInProgress$.next(true);
        }),
    );
  }

  private resetFormState() {
    this.form.reset(this.formDefaults);
    setTimeout(() => this.form.get('referenceDatabase').updateValueAndValidity());
  }

  private isQuickAnalysisJob(config: JobConfiguration): boolean {
    return config.pipeline.name === this.pipelineID.toString();
  }

  private getJobResultID(): Observable<string> {
    return this.listenToJob().pipe(
      filter(
        (jobActivityEvent) => jobActivityEvent.event.kind === JobActivityEventKind.JOB_RESULT_ADDED,
      ),
      map((jobActivityEvent) => jobActivityEvent.event as JobResultAddedActivityEvent),
      switchMap((event) => this.documentHttpService.get(event.resultID)),
      map((item) => AnnotatedPluginDocument.fromNucleusItemV2(item)),
      filter((document) => document.isAnnotatedResult()),
      map((document) => document.id),
    );
  }

  private handleOpenResultDocumentRequest() {
    this.viewResultParams$ = this.getJobResultID().pipe(
      withLatestFrom(this.resultFolder$),
      map(([resultID, resultFolder]) => ({
        folderID: resultFolder.id,
        ids: [resultID],
        selectAll: false,
      })),
    );
  }

  private handleJobComplete() {
    this.subscription.add(
      this.listenToJob()
        .pipe(
          filter(
            (jobActivityEvent) =>
              jobActivityEvent.event.kind === JobActivityEventKind.JOB_COMPLETED ||
              jobActivityEvent.event.kind === JobActivityEventKind.JOB_FAILED ||
              jobActivityEvent.event.kind === JobActivityEventKind.JOB_CANCELLED,
          ),
        )
        .subscribe((jobActivityEvent) => {
          if (jobActivityEvent.event.kind === JobActivityEventKind.JOB_COMPLETED) {
            this.showResultPrompt$.next(true);
          }
          this.clearCurrentJob();
          if (jobActivityEvent.event.kind === JobActivityEventKind.JOB_COMPLETED) {
            this.isAnalysisComplete$.next(true);
            this.resetFormState();
          } else if (jobActivityEvent.event.kind === JobActivityEventKind.JOB_FAILED) {
            this.jobErrors$.next(jobActivityEvent.event.messages);
          }
        }),
    );
  }

  private clearCurrentJob() {
    this.isAnalysisInProgress$.next(false);
    this.jobID$.next(null);
  }

  private listenToJob(): Observable<JobActivityEvent> {
    return this.activityStreamService.listenToJobActivity().pipe(
      withLatestFrom(this.jobID$),
      filter(([jobActivityEvent, jobID]) => {
        if (jobID) {
          return (
            isNonStageJobEvent(jobActivityEvent.event) && jobActivityEvent.event.jobID === jobID
          );
        }

        if (jobActivityEvent.event.kind === JobActivityEventKind.JOB_QUEUED) {
          const jobQueueEvent = jobActivityEvent.event as JobQueuedActivityEvent;

          if (this.isQuickAnalysisJob(jobQueueEvent.jobConfig)) {
            this.jobID$.next(jobQueueEvent.jobID);
            return true;
          }
        }
        return false;
      }),
      map(([event]) => event),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
