import { ChangeDetectionStrategy, Component, HostBinding, Output } from '@angular/core';
import { Label } from '../../label.model';
import { LabelPickerComponentStore } from '../label-picker-store.service';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { NgStyle, AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { InlineLabelCreatorComponent } from '../../inline-label-creator/inline-label-creator.component';

@Component({
  selector: 'bx-label-multi-select',
  templateUrl: './label-multi-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LabelPickerComponentStore],
  standalone: true,
  imports: [FaIconComponent, NgStyle, NgbHighlight, InlineLabelCreatorComponent, AsyncPipe],
})
export class LabelMultiSelectComponent {
  @HostBinding('class') readonly hostClass = 'd-block';
  @Output() readonly selectedLabels = this.componentStore.selectedLabels$;
  readonly displayedLabels$ = this.componentStore.displayedLabels$;
  readonly swatchIcon = faCircle;

  constructor(private readonly componentStore: LabelPickerComponentStore) {}

  setSelected(labelID: string, selected: boolean): void {
    this.componentStore.setSelected({ ids: [labelID], selected });
  }

  search(term: string): void {
    this.componentStore.filterDisplayed(term);
  }

  createLabel(label: Omit<Label, 'id'>): void {
    this.componentStore.createLabel(label);
  }
}
