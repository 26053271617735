import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-reset-request-sent',
  templateUrl: './request-sent.component.html',
  standalone: true,
  imports: [RouterLink],
})
export class RequestSentComponent {}
