import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ChipsService } from '../chips.service';
import { Chip } from '../index';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-chip',
  templateUrl: './chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent],
})
export class ChipComponent {
  @Input() chip: Chip;
  @Input() readonly: boolean;
  @HostBinding('class') readonly hostClass =
    'badge badge-lg border bg-secondary-subtle fw-normal p-2';
  @HostBinding('class.opacity-75') @Input() disabled = false;

  faTimes = faTimes;

  constructor(private chipsService: ChipsService) {}

  removeChip() {
    if (!this.disabled) {
      this.chipsService.removeChip(this.chip.id);
    }
  }
}
