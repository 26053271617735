import { ExtractionAndPreprocessingSequenceOptions } from './alignmentOptions.model';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class MotifAnnotatorJobParameters implements JobConfigurationWithSelection {
  pipeline = {
    name: 'motif-annotator',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: MotifAnnotatorJobOptions;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: MotifAnnotatorJobOptions;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface MotifAnnotatorJobOptions extends ExtractionAndPreprocessingSequenceOptions {
  minMotifCount: number;
  minMotifLength: number;
  maxMotifLength: number;
  numMotifs: number;
  splitOn: string;
  includeClassMotif: boolean;
}
