<bx-collapsible-card
  headerTitle="Clustering Options"
  [collapseByDefault]="collapseByDefault"
  [formGroup]="form"
>
  <div class="mb-3">
    <label
      class="form-label"
      for="regionsSelector"
      ngbTooltip="Allows the specification of arbitrary combinations of FR and/or CDR regions for clustering. "
    >
      Define cluster regions and combos in order to group your sequences:
      <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/4460366289556">
        <mat-icon class="icon-small">help</mat-icon>
      </a>
    </label>
    <bx-regions-selector
      id="regionsSelector"
      formControlName="regionsSelector"
      [filterRegionsFor]="filterRegionsFor"
      [loading]="chipsLoading$ | async"
      data-testid="clustering-regions-selector"
    ></bx-regions-selector>
  </div>

  <div class="row align-items-center" [ngClass]="{ 'mb-3': clusterSummaryFilterEnabled }">
    <div class="col-auto">
      <div class="form-check form-check-inline">
        <input
          type="checkbox"
          id="results_applySummaryScoreFilter"
          formControlName="results_applySummaryScoreFilter"
          class="form-check-input"
        />
        <label
          for="results_applySummaryScoreFilter"
          ngbTooltip="Sequences with asset and liability scores below the value specified will not be included in clustering."
          class="form-check-label"
        >
          Only cluster results with asset and liability score of at least:
        </label>
      </div>
    </div>
    <div class="col-auto">
      <input
        type="number"
        id="results_summaryScoreThreshold"
        formControlName="results_summaryScoreThreshold"
        ngFormControlValidator
        min="-99999"
        max="99999"
        class="form-control"
      />
      <div class="invalid-feedback">Score threshold must be between -99999 and 99999</div>
    </div>
  </div>

  @if (clusterSummaryFilterEnabled) {
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="results_applySummaryFilter"
            formControlName="results_applySummaryFilter"
            class="form-check-input"
          />
          <label
            for="results_applySummaryFilter"
            ngbTooltip="Allows the specification of conditions that will exclude sequences from clustering."
            class="form-check-label"
          >
            Only cluster results which are:
          </label>
        </div>
      </div>
      <div class="col-auto">
        <select
          formControlName="results_summaryFilter"
          id="results_summaryFilter"
          class="form-select flex-grow-1"
        >
          <option value="fullyAnnotated">Fully annotated</option>
          <option value="inFrame">Fully annotated and in frame</option>
          <option value="withoutStopCodons">
            Fully annotated, in frame, and without stop codons
          </option>
        </select>
      </div>
    </div>
  }
</bx-collapsible-card>
