import { DocumentPart } from '@geneious/nucleus-api-client';
import { DocumentPartOld } from './blob.v2.model';

/**
 * Represents the structures that makes up the data field array in a Nucleus response
 */
export interface Item {
  id: string;
  parentID: string;
  depth: number;
  type: string;
  name: string;
  data?: any[]; // haven't come across an example with this set yet
  documentParts: DocumentPartOld[];
  metadata: any;
  permissions: string[];
  shared: boolean;
  createdAt?: string; // this field was introduced later so some old documents will not have this field.
  modifiedAt?: string;
}

export interface ItemV2 {
  documentID: string;
  name: string;
  parentID: string;
  revision: number;
  documentType: string;
  createdAt: string;
  modifiedAt?: string;
  canExpire?: boolean;
  metadata: { [key: string]: string };
  documentParts: DocumentPart[];
}

export function getSequenceCount(items: Item[]) {
  return items.reduce((sum: number, curr: Item) => {
    return sum + (curr.type === 'sequenceList' ? Number(curr.metadata.number_of_sequences) : 1);
  }, 0);
}
