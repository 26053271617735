import {
  ActionsUnion,
  addFile,
  moveFiles,
  removeFiles,
  replaceAllFiles,
  updateFileBlobRevision,
  updateFileName,
} from './files.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { FilesState } from './files-state.model';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Item } from '../../../../../nucleus/v2/models/item.v2.model';

export const adapter: EntityAdapter<Item> = createEntityAdapter<Item>();

export const initialState: FilesState = adapter.getInitialState();

export function filesReducer(state: FilesState = initialState, action: ActionsUnion): FilesState {
  switch (action.type) {
    case replaceAllFiles.type: {
      return adapter.setAll(action.payload.files, state);
    }

    case addFile.type: {
      return adapter.addOne(action.payload.file, state);
    }

    case updateFileBlobRevision.type: {
      const entity = state.entities[action.payload.fileID];
      const metadata = entity ? entity.metadata : {};
      const update: UpdateStr<Item> = {
        id: action.payload.fileID,
        changes: {
          metadata: {
            ...metadata,
            blobRevision: action.payload.blobRevision,
          },
        },
      };
      return adapter.updateOne(update, state);
    }

    case updateFileName.type: {
      const entity = state.entities[action.payload.fileID];
      const metadata = entity ? entity.metadata : {};
      const update: UpdateStr<Item> = {
        id: action.payload.fileID,
        changes: {
          name: action.payload.name,
          metadata: {
            ...metadata,
            name: action.payload.name,
            // TODO Should the blob revision be set in this action?
            // CURRENTLY only serves as a work around for using updateFileName action as inverseAction for the files table
            // when reverting an updateFileName action with the old blob revision number.
            blobRevision: action.payload.blobRevision,
          },
        },
      };
      return adapter.updateOne(update, state);
    }

    case removeFiles.type: {
      return adapter.removeMany((item) => {
        return action.payload.selectAll
          ? !action.payload.fileIDs.includes(item.id)
          : action.payload.fileIDs.includes(item.id);
      }, state);
    }

    case moveFiles.type: {
      return adapter.removeMany(action.payload.fileIDs, state);
    }

    default:
      return state;
  }
}
