import { JobResultsStore } from './job-results.model';
import {
  ActionsUnion,
  fetchJobResults,
  fetchJobResultsFailure,
  fetchJobResultsSuccess,
} from './job-results.actions';

const initial: JobResultsStore = {
  results: {},
  loading: {},
};

export function jobResultsReducer(state = initial, action: ActionsUnion): JobResultsStore {
  switch (action.type) {
    case fetchJobResults.type: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.id]: true,
        },
      };
    }

    case fetchJobResultsSuccess.type: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.id]: [...action.payload.jobResults],
        },
        loading: {
          ...state.loading,
          [action.payload.id]: false,
        },
      };
    }

    case fetchJobResultsFailure.type: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.id]: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
