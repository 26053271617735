import { Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { AbstractControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { distinct } from 'rxjs/operators';
import { BatchJobParametersV1 } from '../../../../nucleus/services/models/batchRenameOptions.model';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { DocumentUtils } from '../../document-utils';
import { AnnotatedPluginDocument } from '../../geneious';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { CardComponent } from '../../../shared/card/card.component';

@Component({
  selector: 'bx-batch-rename',
  templateUrl: './batch-rename.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CardComponent],
})
export class BatchRenameComponent extends JobDialogContent implements OnInit, RunnableJobDialog {
  earlyRelease: false;
  title = 'Batch Rename';
  knowledgeBaseArticle: string;

  // Public for testing.
  public readonly form = new BxFormGroup({
    selectedRenameMethod: new BxFormControl('renamableOption2'),
    // TODO What is the key for "propertyToRename"
    attributeProcedure: new BxFormControl('nameProcedure'),
    // Rename original sequences.
    // TODO Only show this if it's sequence lists with references.
    // followAndRenameReferences: new FormControl(true),

    basicOptions: new BxFormGroup({
      // Should we replace, add or remove.
      task: new BxFormControl('appendRadioButton'),
      // TODO Replace options will go here if and when we support that.
      // Add options.
      appendTextField: new BxFormControl<string>(undefined),
      appendPositionComboBox: new BxFormControl('endValue'),
      // Remove options.
      removeHowMuchIntegerOption: new BxFormControl(1, [Validators.min(1)]),
      removePositionComboBox: new BxFormControl('endValue'),
    }),

    advancedCheckbox: new BxFormControl(false),

    advancedOptions: new BxFormGroup({
      // Replace entire field or parts matching?
      replaceWhatTypeRadioOption: new BxFormControl('entireFieldOption'),

      // Values for if matching.
      replaceWhatTextField: new BxFormControl<string>(undefined),
      ignoreCaseCheckbox: new BxFormControl(false),
      isRegexCheckbox: new BxFormControl(false),

      // What to replace with
      replaceWithTextField: new BxFormControl<string>(undefined),
    }),
  });

  private readonly formDefaults: any;
  private readonly selected: SelectionState;

  constructor(@Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData) {
    super('batch-rename', PipelineFormID.BATCH_RENAME);

    this.formDefaults = this.form.getRawValue();
    this.selected = dialogData.selected;
  }

  ngOnInit() {
    const initialValue = this.form.getRawValue();
    this.updateViewState(initialValue);

    this.form.valueChanges
      .pipe(distinct())
      // `getRawValue` gets values of disabled fields which we rely on.
      .subscribe((ignore) => this.updateViewState(this.form.getRawValue()));
  }

  run() {
    // `getRawValue` gets values of disabled fields which we rely on.
    const options = this.form.getRawValue();

    const selection = {
      selectAll: this.selected.selectAll,
      folderId: this.dialogData.folderID,
      ids: this.selected.ids,
    };

    return new BatchJobParametersV1({
      selection: selection,
      options: options,
    });
  }

  override getFormDefaults() {
    return this.formDefaults;
  }

  get isSequenceList() {
    const apds = this.selected.selectedRows.map((apd) =>
      AnnotatedPluginDocument.fromNucleusEntity(apd),
    );
    return !apds.some(DocumentUtils.isResultDoc);
  }

  private updateViewState(formValue: any) {
    const basicOptions = this.form.controls['basicOptions'] as BxFormGroup;
    const taskRadio = basicOptions.controls['task'];
    // Add options.
    const appendTextField = basicOptions.controls['appendTextField'];
    const appendPositionComboBox = basicOptions.controls['appendPositionComboBox'];
    // Remove options.
    const removeHowMuchIntegerOption = basicOptions.controls['removeHowMuchIntegerOption'];
    const removePositionComboBox = basicOptions.controls['removePositionComboBox'];

    const advancedOptions = this.form.controls['advancedOptions'] as BxFormGroup;

    const replaceWhatTextField = advancedOptions.controls['replaceWhatTextField'];
    const replaceWhatTypeRadioOption = advancedOptions.controls['replaceWhatTypeRadioOption'];
    const ignoreCaseCheckbox = advancedOptions.controls['ignoreCaseCheckbox'];
    const isRegexCheckbox = advancedOptions.controls['isRegexCheckbox'];

    const replaceWithTextField = advancedOptions.controls['replaceWithTextField'];

    // Basic options.
    const isAppend = formValue.basicOptions.task === 'appendRadioButton';
    this.toggleEnabled(taskRadio, !formValue.advancedCheckbox);
    // Add options.
    this.toggleEnabled(appendTextField, !formValue.advancedCheckbox && isAppend);
    this.toggleEnabled(appendPositionComboBox, !formValue.advancedCheckbox && isAppend);
    // Remove options.
    this.toggleEnabled(removeHowMuchIntegerOption, !formValue.advancedCheckbox && !isAppend);
    this.toggleEnabled(removePositionComboBox, !formValue.advancedCheckbox && !isAppend);

    // Advanced options.
    this.toggleEnabled(replaceWhatTypeRadioOption, formValue.advancedCheckbox);

    const replaceByMatch = formValue.advancedOptions.replaceWhatTypeRadioOption === 'matchesOption';
    this.toggleEnabled(replaceWhatTextField, formValue.advancedCheckbox && replaceByMatch);
    this.toggleEnabled(ignoreCaseCheckbox, formValue.advancedCheckbox && replaceByMatch);
    this.toggleEnabled(isRegexCheckbox, formValue.advancedCheckbox && replaceByMatch);

    this.toggleEnabled(replaceWithTextField, formValue.advancedCheckbox);
  }

  private toggleEnabled(control: AbstractControl, enabled: boolean) {
    const action = enabled ? 'enable' : 'disable';
    control[action]({
      // Hack to make `emitEvent` work, otherwise we get `Maximum call stack size exceeded`.
      onlySelf: true,
      // Prevent infinite loop.
      emitEvent: false,
    });
  }
}
