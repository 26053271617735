import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { GaalActionType, GaalServerStore } from './GaalServer.store';
import { GaalServerHttpService } from './GaalServerHttp.service';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { AnnotationRecord } from './GaalServer.service';

@Injectable()
export class GaalServerEffects {
  public crudDocumentAnnotations$: Observable<Action> = createEffect(() =>
    this.actions
      // CRUD of annotation must be picked up here. Edits for any document are processed together.
      .pipe(
        ofType(
          GaalActionType.ADD_ANNOTATION,
          GaalActionType.EDIT_ANNOTATION,
          GaalActionType.REMOVE_ANNOTATION,
        ),
        // Important to use mergemap so all requests make it through to the server.
        mergeMap((action: any) => {
          // Replace all the annotations for this sequence with the new ones (added, removed or edited - doesn't matter).
          // Otherwise some edits to various documents wont make it while others will.
          const { documentId, sequenceIndexInDocument } = action.payload;
          return this.gaalServerStoreService.getAllAnnotationsForSequence(
            documentId,
            sequenceIndexInDocument,
          );
        }),
        map((payload) => {
          return {
            type: GaalActionType.DOCUMENT_UPDATE_TRIGGER,
            payload,
          };
        }),
      ),
  );

  public replaceDocumentAnnotations$: Observable<Action> = createEffect(() =>
    this.actions
      // CRUD of annotation must be picked up here. Edits for any document are processed together.
      .pipe(
        ofType(GaalActionType.DOCUMENT_UPDATE_TRIGGER),
        debounceTime(1000),
        // Important to use mergemap so all requests make it through to the server.
        // Otherwise some edits to various documents wont make it while others will.
        mergeMap((action: any) => {
          // Replace all the annotations for this sequence with the new ones (added, removed or edited - doesn't matter).
          const { documentId, sequenceIndexInDocument, annotationRecords } = action.payload;
          const annotations = this.annotationsFromAnnotationRecords(annotationRecords);
          return this.gaalServerHttpService
            .setAnnotations(documentId, sequenceIndexInDocument, annotations)
            .pipe(
              map((newAnnotations) => {
                return {
                  type: GaalActionType.DOCUMENT_UPDATE_SUCCESSFUL,
                  payload: newAnnotations,
                };
              }),
              catchError((error, caught) => {
                console.log('Failed to update document:', error);
                // TODO Should we throw here or continue as I have??
                // return Observable.of({
                //   type: GaalActionType.DOCUMENT_UPDATE_SUCCESSFUL,
                //   payload: []
                // });
                // We have to return `caught` or the effect wont work any more after this.
                // TODO why?
                return caught;
              }),
            );
        }),
      ),
  );

  constructor(
    private actions: Actions,
    private gaalServerHttpService: GaalServerHttpService,
    private gaalServerStoreService: GaalServerStore,
  ) {}

  // TODO Sequence viewer, GAAL, Geneious definitions of annotation do not agree.
  // Therefore have to return any[] and not anntation[] otherwise get wrong bits.
  private annotationsFromAnnotationRecords(annotationRecords: AnnotationRecord[]): any[] {
    return annotationRecords.map((annotationRecord) => {
      return {
        intervals: annotationRecord.annotation.intervals.map((interval: any) => {
          // TODO Talk with josh about always sending the same objects.
          // Also what types we should actually be using (I assume the server side ones)
          return {
            min: interval.range ? interval.range.start : interval.min,
            max: interval.range ? interval.range.end : interval.max,
            direction: interval.direction,
            truncatedMax: interval.truncatedMax,
            truncatedMin: interval.truncatedMin,
          };
        }),
        name: annotationRecord.annotation.name,
        qualifiers: annotationRecord.annotation.qualifiers.map((qualifier: any) => {
          return {
            name: qualifier.name,
            value: qualifier.value,
          };
        }),
        type:
          (annotationRecord.annotation as any).type ||
          (annotationRecord.annotation as any).normalType,
      };
    });
  }
}
