<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'settings'], label: 'Settings' }, { label: 'Change Password' }]"
></bx-settings-breadcrumb>
<div class="bx-page-container bx-page-container-sm">
  <h3 class="mb-4">Change password</h3>
  <form #changePasswordForm="ngForm" class="centered" (ngSubmit)="submit(changePasswordForm)">
    @if (changePasswordResponse.error) {
      <div class="alert alert-danger">
        {{ changePasswordResponse.error }}
      </div>
    }
    @if (changePasswordResponse.success) {
      <div class="alert alert-success">
        {{ changePasswordResponse.success }}
      </div>
    }
    <div class="mb-3">
      <label class="form-label" for="current-pass">Current password</label>
      <input
        id="current-pass"
        type="password"
        name="currentPassword"
        class="form-control"
        placeholder="Current password"
        #currentPasswordInput="ngModel"
        [disabled]="waiting"
        [(ngModel)]="currentPassword"
        ngFormControlValidator
        [validateOnBlur]="true"
        autocomplete="off"
        maxlength="200"
        required
      />
      @if (currentPasswordInput.hasError('required')) {
        <div class="invalid-feedback">Enter your current password</div>
      }
    </div>
    <div class="mb-3">
      <div class="w-100 d-flex justify-content-between">
        <label class="form-label" for="new-pass">New password</label>
        <bx-password-strength [password]="newPassword" class="mt-1 w-25"></bx-password-strength>
      </div>
      <input
        id="new-pass"
        type="password"
        name="newPassword"
        class="form-control"
        placeholder="New password"
        #newPasswordInput="ngModel"
        [disabled]="waiting"
        [(ngModel)]="newPassword"
        ngFormControlValidator
        [validateOnBlur]="true"
        autocomplete="off"
        maxlength="200"
        bxValidatePassword
        [bxValidateEqual]="'confirmPassword'"
        required
      />
      @if (newPasswordInput.hasError('required')) {
        <div class="invalid-feedback">A new password is required.</div>
      }
      @if (newPasswordInput.hasError('weakPassword')) {
        <div class="invalid-feedback">
          Password is too weak.
          {{ changePasswordForm.form.controls.newPassword.errors?.weakPassword }}
        </div>
      }
      @if (newPasswordInput.hasError('validateEqual')) {
        <div class="invalid-feedback">Passwords don't match.</div>
      }
    </div>
    <div class="mb-3">
      <label class="form-label" for="confirm-pass">Confirm password</label>
      <input
        id="confirm-pass"
        type="password"
        name="confirmPassword"
        class="form-control"
        placeholder="Confirm password"
        [disabled]="waiting"
        [(ngModel)]="confirmPassword"
        #confirmPasswordInput="ngModel"
        ngFormControlValidator
        autocomplete="off"
        maxlength="200"
        [validateOnBlur]="true"
        [bxValidateEqual]="'newPassword'"
        required
      />
      @if (confirmPasswordInput.hasError('required')) {
        <div class="invalid-feedback">Confirmation password is required.</div>
      }
      @if (confirmPasswordInput.hasError('validateEqual')) {
        <div class="invalid-feedback">Passwords don't match.</div>
      }
    </div>

    <div class="d-flex w-100 justify-content-end">
      <button
        class="btn btn-primary me-2"
        bx-spinner-button
        [spin]="waiting"
        [disabled]="!changePasswordForm.form.valid"
      >
        Change password
      </button>
      <button class="btn btn-secondary" type="button" routerLink="/settings">Cancel</button>
    </div>
  </form>
</div>
