import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { combineLatest, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  scan,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserGroupsFacade } from '../user-groups.facade';
import { UsersService } from '../../users/users.service';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
  MatLegacyAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { ColDef } from '@ag-grid-community/core';
import { UserGroup, UserGroupMember } from '../../../../nucleus/v2/groups-http.v2.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Crumb } from '../../../shared/breadcrumb/breadcrumb.component';
import User from '../../user-editor/user.model';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { CleanUp } from 'src/app/shared/cleanup';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLineModule } from '@angular/material/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

type UserGroupsFormValue = { groupName: string; description?: string };

@Component({
  selector: 'bx-user-group-form',
  templateUrl: './user-groups-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,
    MatLegacyOptionModule,
    MatLegacyListModule,
    MatLineModule,
    MatLegacyButtonModule,
    MatIconModule,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class UserGroupsFormComponent extends CleanUp implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) autocompleteComponent: MatAutocompleteTrigger;

  crumbs: Crumb[];
  readonly form = new FormGroup({
    groupName: new FormControl<string>(undefined, Validators.required),
    description: new FormControl<string>(undefined),
  });
  readonly listColumns: ColDef[];
  searchInput: FormControl;
  isCreating = true;

  userEvent$: Subject<UserGroupMemberAction>;
  errors$: Subject<string>;

  searchInput$: Observable<string>;
  filteredUsers$: Observable<User[]>;
  selectedMembers$: Observable<UserGroupMember[]>;
  submitting$: Observable<boolean>;

  private groupID: string;
  private onSubmit$: Subject<{
    formValue: UserGroupsFormValue;
    isCreating: boolean;
  }>;
  private users$: Observable<User[]>;
  private subscriptions = new Subscription();

  constructor(
    private userGroupsFacade: UserGroupsFacade,
    private usersService: UsersService,
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.crumbs = [
      { link: ['', 'user-groups'], label: 'Groups' },
      { link: ['user-groups', this.groupID], label: '' },
    ];

    this.listColumns = [
      {
        field: 'id',
        headerName: '',
        width: 40,
        resizable: false,
        suppressMovable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        valueGetter: () => '',
      },
      {
        colId: 'givenName',
        field: 'givenName',
        headerName: 'Given Name',
      },
      {
        colId: 'familyName',
        field: 'familyName',
        headerName: 'Family Name',
      },
      {
        colId: 'email',
        field: 'email',
        headerName: 'Email',
      },
    ];

    this.searchInput = new FormControl('');
    this.searchInput$ = this.searchInput.valueChanges.pipe(
      map((value) => (value instanceof User ? value.fullName : value)),
      startWith(''),
      takeUntil(this.ngUnsubscribe),
    );

    this.userEvent$ = this.completeOnDestroy(new Subject());
    this.selectedMembers$ = this.userEvent$.pipe(
      scan((acc, action) => action(acc), {}),
      map((users: { [type: string]: UserGroupMember }) =>
        Object.keys(users).map((id) => users[id]),
      ),
      startWith([]),
      takeUntil(this.ngUnsubscribe),
    );

    this.users$ = this.store.pipe(
      select(selectOrganizationID),
      switchMap((organizationID) => this.usersService.getUsers(organizationID)),
    );

    this.filteredUsers$ = combineLatest([
      this.users$,
      this.searchInput$,
      this.selectedMembers$,
    ]).pipe(
      map(([users, searchTerm, selectedUsers]) => {
        const filteredUsers = this.filterUsersBySearchTerm(searchTerm, users);
        return filteredUsers
          .filter((user) => !selectedUsers.find((selectedUser) => selectedUser.id === user.id))
          .sort((user1, user2) => user1.fullName.localeCompare(user2.fullName));
      }),
      takeUntil(this.ngUnsubscribe),
    );

    this.errors$ = new Subject();
    this.onSubmit$ = new Subject();
  }

  ngOnInit() {
    this.groupID = this.activatedRoute.snapshot.params.groupId;
    if (this.groupID !== 'new') {
      this.isCreating = false;
      this.userGroupsFacade.getGroup(this.groupID).subscribe((group) => {
        this.form.patchValue({
          groupName: group.name,
          description: group.description,
        });
        this.userEvent$.next(addMembers(group.members));
        this.crumbs = [
          { link: ['', 'groups'], label: 'Groups' },
          { link: ['', 'groups', this.groupID], label: group.name },
        ];
      });
    } else {
      this.crumbs = [{ link: ['', 'groups'], label: 'Groups' }, { label: 'New' }];
    }

    const selectedUserIDs$ = this.selectedMembers$.pipe(
      map((users) => users.map((user) => user.id)),
    );
    const submissionResult$: Observable<UserGroup | undefined> = this.onSubmit$.pipe(
      withLatestFrom(selectedUserIDs$, this.store.pipe(select(selectOrganizationID))),
      map(([{ formValue, isCreating }, selectedUsers, organizationID]) => ({
        formValue: formValue,
        isCreating: isCreating,
        selectedUsers: selectedUsers,
        organizationID: organizationID,
      })),
      switchMap(({ formValue, isCreating, selectedUsers, organizationID }) =>
        this.saveGroup(isCreating, organizationID, this.groupID, formValue, selectedUsers),
      ),
      share(),
    );

    this.submitting$ = merge(
      this.onSubmit$.pipe(mapTo(true)),
      submissionResult$.pipe(mapTo(false)),
    );

    this.subscriptions.add(submissionResult$.subscribe());

    this.form.controls.groupName.valueChanges.subscribe((value) => {
      if (!this.isCreating) {
        this.crumbs = [
          { link: ['', 'groups'], label: 'Groups' },
          { link: ['groups', this.groupID], label: value },
        ];
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
    this.onSubmit$.complete();
  }

  autocompleteInputClicked(event: Event) {
    event.stopPropagation();
    this.autocompleteComponent.openPanel();
  }

  displayFn(user?: User): string | undefined {
    return user ? user.fullName : undefined;
  }

  onCancel() {
    this.router.navigate(['/groups']);
  }

  onUserSelected($event: MatAutocompleteSelectedEvent) {
    this.userEvent$.next(addUser($event.option.value));
    this.searchInput.reset('');
  }

  removeUser(user: UserGroupMember) {
    this.userEvent$.next(removeUser(user.id));
  }

  submit() {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      this.onSubmit$.next({ formValue, isCreating: this.isCreating });
    }
  }

  private createGroup(
    organizationID: string,
    formValue: UserGroupsFormValue,
    members: string[],
  ): Observable<UserGroup> {
    return this.userGroupsFacade.createGroup({
      organizationID: organizationID,
      name: formValue.groupName,
      description: formValue.description,
      members: members,
    });
  }

  private filterUsersBySearchTerm(searchTerm: string, users: User[]): User[] {
    if (searchTerm === '') {
      return users;
    }

    return users.filter(
      (user) =>
        user.fullName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        user.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1,
    );
  }

  private updateGroup(
    groupID: string,
    formValue: UserGroupsFormValue,
    members: string[],
  ): Observable<UserGroup> {
    return this.userGroupsFacade.updateGroup({
      id: groupID,
      name: formValue.groupName,
      description: formValue.description,
      members: members,
    });
  }

  private saveGroup(
    isCreating: boolean,
    organizationID: string,
    groupID: string,
    formValue: UserGroupsFormValue,
    members: string[],
  ): Observable<UserGroup | undefined> {
    return of(isCreating).pipe(
      switchMap((creating) => {
        if (creating) {
          return this.createGroup(organizationID, formValue, members);
        } else {
          return this.updateGroup(groupID, formValue, members);
        }
      }),
      tap(() => this.router.navigate(['/groups'])),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.errors$.next('Group with that name already exists');
        } else {
          this.errors$.next('Failed to save this Group');
        }
        return of(undefined);
      }),
    );
  }
}

function removeUser(id: string): UserGroupMemberAction {
  return function (state) {
    const { [id]: removedUser, ...otherUsers } = state;
    return otherUsers;
  };
}

function addUser(user: User): UserGroupMemberAction {
  return function (state) {
    return {
      ...state,
      [user.id]: {
        ...convertUserToUserGroupMember(user),
      },
    };
  };
}

function addMembers(users: UserGroupMember[]): UserGroupMemberAction {
  return function (state) {
    return {
      ...state,
      ...users.reduce((acc, user) => {
        acc[user.id] = { ...user };
        return acc;
      }, {} as UserGroupMembers),
    };
  };
}

function convertUserToUserGroupMember(user: User): UserGroupMember {
  return {
    id: user.id,
    email: user.email,
    givenName: user.givenName,
    familyName: user.familyName,
  };
}

interface UserGroupMembers {
  [type: string]: UserGroupMember;
}

type UserGroupMemberAction = (state: UserGroupMembers) => UserGroupMembers;
