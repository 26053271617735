import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-pipeline-output-name-control',
  templateUrl: './pipeline-output-name-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbTooltip,
    FormsModule,
    NgFormControlValidatorDirective,
    ReactiveFormsModule,
    AsyncPipe,
  ],
})
export class PipelineOutputNameControlComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'row';
  /** Display name used to describe the control */
  @Input() displayName: string;
  /** Form control to use for input */
  @Input() nameFormControl: FormControl<string>;
  /** Form control ID used on the input and label */
  @Input() inputID: string;
  /** Label text to display next to input. Defaults to `displayName + " name:"` */
  @Input() label: string;
  /** Optional override for label container class. Useful for changing vertical alignment. */
  @Input() labelContainerClass = 'col-4';
  /** Optional override for input container class. Useful for changing vertical alignment. */
  @Input() inputContainerClass = 'col-8';
  /** Optional tooltip to display on hover */
  @Input() tooltip: string | null = null;

  errors$: Observable<ValidationErrors>;

  ngOnInit(): void {
    if (!this.label) {
      this.label = `${this.displayName} name:`;
    }
    this.errors$ = this.nameFormControl.statusChanges.pipe(
      startWith(this.nameFormControl.status),
      map(() => this.nameFormControl.errors),
    );
  }
}
