import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { AppState } from '../../core.store';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { selectedFolder } from '../../folders/store/folder.selectors';
import { CreateCollectionsFolderFormComponent } from '../../master-database/create-collections-folder-form/create-collections-folder-form.component';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-master-database-root-folder-detail',
  templateUrl: './master-database-root-folder-detail.component.html',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    PageMessageComponent,
    AsyncPipe,
  ],
})
export class MasterDatabaseRootFolderDetailComponent implements OnInit, OnDestroy {
  folder$: Observable<FolderTreeItem>;
  newDatabaseFolderEvent$ = new Subject<void>();

  private createDatabaseModalRef: NgbModalRef;
  private subscriptions = new Subscription();

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.folder$ = this.store.pipe(
      select(selectedFolder),
      filter((folder) => folder != null),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.subscriptions.add(
      this.newDatabaseFolderEvent$
        .pipe(
          withLatestFrom(this.folder$),
          map(([_, folder]) => folder),
        )
        .subscribe((folder) => {
          this.createDatabaseModalRef = this.dialogService.showDialogV2({
            component: CreateCollectionsFolderFormComponent,
            injectableData: { folder },
          });
        }),
    );
  }

  ngOnDestroy() {
    if (this.createDatabaseModalRef) {
      this.createDatabaseModalRef.close();
    }
    this.subscriptions.unsubscribe();
    this.newDatabaseFolderEvent$.complete();
  }
}
