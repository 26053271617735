import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ColDef } from '@ag-grid-community/core';
import { GridComponent, SelectionState } from '../../../features/grid/grid.component';
import { CleanUp } from '../../../shared/cleanup';
import { UtilService } from '../../../shared/util.service';
import { getRowIdentifier } from '../../ngs/getRowIdentifier';
import { takeUntil } from 'rxjs/operators';
import { IGridResource } from '../../../features/grid/datasource/grid.resource';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import {
  selectLabels,
  selectNameSchemes,
} from '../../organization-settings/organization-settings.selectors';
import { nameSchemeActions } from '../../organization-settings/organization-settings.actions';
import { OrganizationSettingsResource } from '../../organization-settings/organization-settings.resource';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-name-schemes',
  templateUrl: './name-schemes-manager.component.html',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    RouterLink,
    GridComponent,
  ],
})
export class NameSchemesManagerComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'w-100 h-100 d-flex flex-column';
  @ViewChild(GridComponent, { static: true }) gridComponent: GridComponent;

  ready: boolean;
  selectionState: SelectionState = {
    noOfRowsSelected: 0,
    totalNoOfRows: 0,
    selectAll: false,
    ids: [],
    selectedRows: [],
    firstRow: null,
  };
  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      suppressMenu: true,
      valueGetter: (params: any) =>
        params && params.data && params.data.name
          ? this.utilService.sanitizeCell(params.data.name)
          : '',
    },
    {
      field: 'delimiter',
      headerName: 'Delimiter',
      suppressMenu: true,
      valueGetter: (params) => {
        return params.data ? params.data.data.delimiter : '';
      },
    },
    {
      field: 'fields',
      headerName: 'Fields',
      suppressMenu: true,
      valueGetter: (params) => {
        return (
          (params.data &&
            params.data.data.fields
              .filter((entry: any) => !!entry)
              .map((entry: any) => {
                const name = entry.name;
                // Note: Sometimes the ".type" field is not defined and we can get a null pointer here.
                const type = (entry.type || '').toLowerCase();
                const template = entry.template;
                return this.utilService.sanitizeCell(`${name} : ${type} : ${template}`);
              })
              .join(', ')) ||
          ''
        );
      },
    },
  ];

  nameSchemeResource: IGridResource;

  constructor(
    private store: Store<AppState>,
    private utilService: UtilService,
    public dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.nameSchemeResource = new OrganizationSettingsResource(this.store, selectNameSchemes);
  }

  onGridReady() {
    this.store
      .select(selectLabels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.gridComponent.refresh(true);
      });
  }

  deleteNamingSchemes() {
    const numSelected = this.selectionState.selectedRows.length;
    this.dialogService
      .showConfirmationDialog$({
        title:
          numSelected === 1
            ? 'Delete name scheme permanently?'
            : `Delete ${numSelected} name schemes permanently?`,
        confirmationButtonText: 'Delete',
        confirmationButtonColor: 'danger',
        content:
          'Deletes the name scheme for everyone in your organization. This cannot be undone.',
      })
      .subscribe((result: boolean) => {
        if (result === true) {
          for (const row of this.selectionState.selectedRows) {
            this.store.dispatch(nameSchemeActions.remove({ id: getRowIdentifier(row) }));
          }
        }
      });
  }

  selectionChanged(selection: any) {
    this.selectionState = selection;
  }
}
