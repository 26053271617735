@if (currentReport$ | async; as report) {
  <bx-toolstrip>
    <bx-toolstrip-item>
      <a
        class="btn btn-light rounded"
        target="_blank"
        routerLink="/report"
        [queryParams]="{ id: report.documentID, source: report.source }"
      >
        Export to PDF
      </a>
    </bx-toolstrip-item>
  </bx-toolstrip>
}

@if (errorMessage$ | async; as error) {
  <bx-page-message>
    <span>{{ error }}</span>
  </bx-page-message>
}

@if (isLoading$ | async) {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
}

<div class="overflow-auto flex-grow-1 flex-shrink-1">
  @if (currentReport$ | async; as report) {
    <div class="bx-page-container bx-page-container-md">
      <div>
        @for (param of report.params; track param) {
          <div>
            {{ param.label }}:
            @if (!param.link) {
              <span>{{ param.value }}</span>
            }
            @if (param.link) {
              <a routerLink="{{ param.link }}">{{ param.value }}</a>
            }
          </div>
        }
      </div>
      <div class="text-muted">
        {{ report.description }}
      </div>
      @for (chart of report.barCharts; track chart) {
        <div>
          <bx-column-chart
            class="mb-4"
            [data]="chart.data"
            [title]="chart.title"
            [xAxisTitle]="chart.xLabel"
            [yAxisTitle]="chart.yLabel"
          >
          </bx-column-chart>
        </div>
      }
    </div>
  }
</div>
