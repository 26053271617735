import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { AppState } from '../../core.store';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavigationService } from '../../navigation/navigation.service';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-search-back-button',
  templateUrl: './search-back-button.component.html',
  standalone: true,
  imports: [FaIconComponent, AsyncPipe],
})
export class SearchBackButtonComponent extends CleanUp {
  readonly referrerPath$: Observable<any>;
  readonly backArrowIcon = faArrowLeft;

  constructor(
    private readonly store: Store<AppState>,
    private readonly navigationService: NavigationService,
  ) {
    super();
    this.referrerPath$ = this.store.select('searchReducer').pipe(
      map((state) => state.referrerPath),
      startWith('/workspace'),
      takeUntil(this.ngUnsubscribe),
    );
  }

  back(referrerPath: string) {
    this.navigationService.goToLocation(referrerPath);
  }
}
