<div [class]="labelContainerClass">
  <label class="col-form-label" [for]="inputID" [ngbTooltip]="tooltip" [disableTooltip]="!tooltip">
    {{ label }}
  </label>
</div>
<div [class]="inputContainerClass">
  <input
    type="text"
    class="form-control"
    [id]="inputID"
    ngFormControlValidator
    [formControl]="nameFormControl"
    placeholder="Default"
    [attr.data-spec]="'pipeline-output-' + inputID"
  />
  @if (errors$ | async; as errors) {
    @if (errors?.maxlength; as maxLength) {
      <div class="invalid-feedback">
        {{ displayName }} name can only be a maximum of {{ maxLength.requiredLength }} characters
      </div>
    }
    @if (errors?.pattern; as pattern) {
      @switch (pattern.actualValue) {
        @case ('.') {
          <div class="invalid-feedback">{{ displayName }} name cannot contain only '.'</div>
        }
        @case ('..') {
          <div class="invalid-feedback">{{ displayName }} name cannot contain only '..'</div>
        }
        @default {
          <div class="invalid-feedback">
            {{ displayName }} name cannot contain characters '/' or '\'
          </div>
        }
      }
    }
  }
</div>
