@if (!historyEvent.placeholder) {
  <div>
    <h6 class="mb-1 text-dark" data-spec="history-event-title">
      {{ historyEvent.eventType }} {{ pipelineName }}
    </h6>
    @if (
      insightsEnabled &&
      historyEvent.logSummary?.infoCount + historyEvent.logSummary?.warningCount > 0
    ) {
      <div>
        <small class="text-muted mb-1" data-spec="history-event-summary">
          @if (historyEvent.logSummary?.warningCount > 0) {
            <fa-icon class="text-warning" [icon]="faExclamationTriangle"></fa-icon>
            {{ historyEvent.logSummary?.warningCount }}
          }
          @if (historyEvent.logSummary?.infoCount > 0) {
            <fa-icon class="text-info" [icon]="faInfoCircle"></fa-icon>
            {{ historyEvent.logSummary?.infoCount }}
          }
        </small>
      </div>
    }
    <small class="text-muted mb-1" data-spec="history-event-summary">
      @if (historyEvent.createdAt) {
        <span data-spec="history-event-date">
          {{ historyEvent.createdAt | date: 'medium' }}
        </span>
      }
      @if (historyEvent.createdAt && historyEvent.userName) {
        <span>&nbsp;•&nbsp;</span>
      }
      @if (historyEvent.userName) {
        <span data-spec="history-event-user">
          {{ historyEvent.userName }}
        </span>
      }
    </small>
  </div>
  <fa-icon [icon]="faInfoCircle" class="text-secondary"></fa-icon>
} @else {
  <bx-spinner class="my-1"></bx-spinner>
}

<ng-template #placeholder>
  <bx-spinner class="my-1"></bx-spinner>
</ng-template>
