import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectUserSettingsLoadedStatus } from '../user-settings/user-settings.selectors';

/**
 * SecureResolver
 *
 * Fetches all required state information from the server before finally navigating to the route destination.
 * e.g. Retrieves Grid/Form states that the user requires before the Secure part of the application can be navigated to and rendered.
 */
@Injectable({
  providedIn: 'root',
})
export class SecureResolver {
  constructor(private store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.select(selectUserSettingsLoadedStatus).pipe(
      filter((loaded) => !!loaded),
      take(1),
    );
  }
}
