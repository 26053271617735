import { Injectable } from '@angular/core';
import { TINK } from './sounds';

/**
 * Plays sounds. This service mainly exists so it can be mocked in unit tests,
 * as Audio.play throws an error in Karma.
 */
@Injectable()
export class AudioService {
  /**
   * Plays a sound from a base64-encoded URL.
   * @param url the base-64 encoded audio data url
   */
  play(url: string): Promise<void> {
    const sound = new Audio(url);
    return sound.play();
  }

  /**
   * Plays a soft beep sound.
   */
  softBeep(): Promise<void> {
    return this.play(TINK);
  }
}
