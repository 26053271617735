import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, map, Observable, shareReplay, take, takeUntil, withLatestFrom } from 'rxjs';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import {
  ANNOTATED_RESULT_DOCUMENT_TYPES,
  DocumentUtils,
  SEQUENCE_DOCUMENT_TYPES,
} from 'src/app/core/document-utils';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { FeatureSwitchService } from 'src/app/features/feature-switch/feature-switch.service';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { partitionArray } from 'src/bx-common-extensions/array';
import {
  DocumentFileExportOptions,
  ExportJobParameters,
  SequencesOutputType,
} from 'src/nucleus/services/models/exportOptions.model';
import { PipelineDialogData } from '../..';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { sequencesOutputTypeOptions } from '../export-helpers';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import { Item, NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { JobResultDownloaderService } from '../../../utils/job-result-downloader.service';
import { tap } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CardComponent } from '../../../../shared/card/card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ExportingRowsSelectedMessagePipe } from '../exporting-rows-selected-message/exporting-rows-selected-message.pipe';

/**
 * JobDialogContent for exporting files from the files table. Submits an export
 * job with an exportFormat of "documentFile".
 */
@Component({
  selector: 'bx-export-document-file',
  templateUrl: './export-document-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbTooltip,
    AsyncPipe,
    ExportingRowsSelectedMessagePipe,
  ],
})
export class ExportDocumentFileComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  readonly title = 'Export Document';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup({
    sequencesFormatOptions: new BxFormGroup({
      sequencesOutputType: new BxFormControl<SequencesOutputType>('Geneious', [
        Validators.required,
      ]),
      mergeGeneiousDocuments: new BxFormControl(false),
    }),
    annotatorResultOptions: new BxFormGroup({
      exportResultDocuments: new BxFormControl(false, [Validators.required]),
    }),
  });
  sequenceOutputTypes = sequencesOutputTypeOptions;

  documentIDsToExport: string[];
  unsupportedDocuments: {
    count: number;
    namesToDisplay: string;
    undisplayedCount: number;
  };
  showSequencesFormatOptions: boolean;
  enableMergeGeneiousDocumentsOption$: Observable<boolean>;
  showAnnotatorResultsOptions$: Observable<boolean>;

  /** Maximum number of unsupported document names to display */
  private readonly MAX_UNSUPPORTED_NAMES = 3;

  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<undefined>,
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly jobResultDownloaderService: JobResultDownloaderService,
  ) {
    super('export', PipelineFormID.EXPORT_DOCUMENT_FILE);
    this.formDefaults = this.form.getRawValue();
  }

  ngOnInit(): void {
    const [unsupported, supported] = partitionArray(
      this.dialogData.selected.selectedRows as Item[],
      DocumentUtils.isUnsupportedExportDocument,
    );
    this.documentIDsToExport = supported.map((item) => item.id);

    const fastqDocumentTypes = [
      'Nucleotide Sequence List',
      'Nucleotide Sequence',
      'Chromatogram',
      'Sequence List',
    ];
    const allDocumentsSupportFastq = supported.every((item) =>
      fastqDocumentTypes.includes(item.metadata.documentType),
    );
    const allSequenceListsSupportFastq = supported
      .filter((item) => item.metadata.documentType === 'Sequence List')
      .every((item) => parseInt(item.metadata.nucleotideSequenceCount) ?? 0 > 0);

    const allDocumentsSupportNewick =
      supported.filter((item) => item.metadata.documentType !== 'Tree').length === 0;

    if (!allDocumentsSupportFastq || !allSequenceListsSupportFastq) {
      this.sequenceOutputTypes = this.sequenceOutputTypes.filter(
        (outputType) => outputType.value !== 'Fastq' && outputType.value !== 'FastqCompressed',
      );
    }
    if (!allDocumentsSupportNewick) {
      this.sequenceOutputTypes = this.sequenceOutputTypes.filter(
        (outputType) => outputType.value !== 'Newick',
      );
    }

    /*
     * Initialize template-bound properties
     */
    this.unsupportedDocuments = {
      count: unsupported.length,
      namesToDisplay: unsupported
        .slice(0, this.MAX_UNSUPPORTED_NAMES)
        .map((doc) => doc.name)
        .join(', '),
      undisplayedCount: Math.max(0, unsupported.length - this.MAX_UNSUPPORTED_NAMES),
    };

    this.showSequencesFormatOptions = supported.some(({ type }) =>
      SEQUENCE_DOCUMENT_TYPES.includes(type),
    );

    const sequencesOutputTypeControl = this.form.get('sequencesFormatOptions.sequencesOutputType');
    const sequencesOutputTypeIsGeneious$ = currentValueAndChanges(sequencesOutputTypeControl).pipe(
      tap((value: SequencesOutputType) => {
        if (!this.sequenceOutputTypes.some((outputType) => outputType.value === value)) {
          sequencesOutputTypeControl.setValue(this.sequenceOutputTypes[0].value);
        }
      }),
      map((value: SequencesOutputType) => value === 'Geneious'),
      shareReplay({ refCount: true, bufferSize: 1 }),
      takeUntil(this.ngUnsubscribe),
    );
    this.enableMergeGeneiousDocumentsOption$ = sequencesOutputTypeIsGeneious$;

    this.showAnnotatorResultsOptions$ = this.featureSwitchService
      .isEnabledOnce('exportResultDocuments')
      .pipe(
        map(
          (featureEnabled) =>
            featureEnabled &&
            supported.some(({ type }) => ANNOTATED_RESULT_DOCUMENT_TYPES.includes(type)),
        ),
      );

    // In cases where the exportResultDocuments option is set to true by the saved form state but the export document is
    // not an annotator result document then we want to ignore the saved form state and set it to false.
    this.showAnnotatorResultsOptions$.pipe(take(1)).subscribe((annotatorResultsOptionsEnabled) => {
      const exportResultDocumentsControl = this.form.get(
        'annotatorResultOptions.exportResultDocuments',
      );

      if (exportResultDocumentsControl.value && !annotatorResultsOptionsEnabled) {
        exportResultDocumentsControl.setValue(false);
      }
    });

    /*
     * Initialize side effects that manipulate the form
     */
    // Disable sequencesFormatOptions when exportResultDocuments is true
    // and enable mergeGeneiousDocuments only when sequences output type is Geneious
    const mergeDocumentsControl = this.form.get('sequencesFormatOptions.mergeGeneiousDocuments');
    const exportResultDocuments$ = currentValueAndChanges(
      this.form.get('annotatorResultOptions.exportResultDocuments'),
      this.ngUnsubscribe,
    );
    combineLatest([exportResultDocuments$, sequencesOutputTypeIsGeneious$]).subscribe(
      ([exportResultDocuments, sequencesOutputTypeIsGeneious]) => {
        if (exportResultDocuments) {
          /* Enabling exportResultDocuments overrides the sequencesFormatOptions to the values
           * that the pipeline requires. Otherwise it ignores exportResultDocuments. */
          sequencesOutputTypeControl.setValue('Geneious');
          sequencesOutputTypeControl.disable();
          mergeDocumentsControl.setValue(false);
          mergeDocumentsControl.disable();
        } else {
          sequencesOutputTypeControl.enable();
          // Merge Geneious Documents is only enabled when sequencesOutputType is Geneious
          if (sequencesOutputTypeIsGeneious) {
            mergeDocumentsControl.enable();
          } else {
            mergeDocumentsControl.disable();
          }
        }
      },
    );
  }

  run() {
    // Use getRawValue because it includes the value of disabled controls
    const formValue = this.form.getRawValue() as {
      sequencesFormatOptions: {
        sequencesOutputType: SequencesOutputType;
        mergeGeneiousDocuments: boolean;
      };
      annotatorResultOptions: {
        exportResultDocuments: boolean;
      };
    };
    const options: DocumentFileExportOptions = { exportFormat: 'documentFile' };
    if (this.showSequencesFormatOptions) {
      options.sequencesFormatOptions = {
        ...formValue.sequencesFormatOptions,
        ...formValue.annotatorResultOptions,
      };
    }
    const parameters: ExportJobParameters = {
      options,
      selection: {
        folderId: this.dialogData.folderID,
        ids: this.documentIDsToExport,
        selectAll: false,
      },
    };
    return {
      pipeline: { name: 'export', version: VersionEnum.Latest },
      parameters,
    };
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
      newJobResponse.data.jobID,
      'EXPORTED_FILE',
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}
