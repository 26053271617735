import { EMPTY, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, exhaustMap } from 'rxjs/operators';
import { OutageHandlerService } from '../outage-handler/outage-handler.service';
import { MaintenanceInfo } from '../outage-handler/maintenance-page';

/**
 * Intercepts all requests that go through HttpClient.
 * 50x errors are marked as a server failure and will trigger a check for whether Nucleus is a maintenance period.
 * It will show the maintenance page dialog if it is.
 *
 * TODO Handle cases where Nucleus is down for an unknown reason.
 */
@Injectable({
  providedIn: 'root',
})
export class OutageInterceptor implements HttpInterceptor {
  private readonly httpError$ = new Subject<HttpErrorResponse>();

  constructor(private outageHandlerService: OutageHandlerService) {
    this.httpError$.subscribe(() => {
      console.log('Biologics may be offline, checking status...');
    });

    this.httpError$
      .pipe(
        exhaustMap(() =>
          this.outageHandlerService.checkMaintenanceInfo().pipe(
            catchError(() => {
              console.log('App is possibly down');
              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe((maintenanceInfo) => {
        if (this.appIsInMaintenance(maintenanceInfo)) {
          this.outageHandlerService.showMaintenancePage(maintenanceInfo);
        } else {
          console.log('App is possibly down');
        }
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err) => this.handle50xError(err)));
  }

  private handle50xError(error: HttpEvent<any>) {
    if (error instanceof HttpErrorResponse) {
      if (this.serverPossiblyDown(error)) {
        this.httpError$.next(error);
      }
    }
    return observableThrowError(error);
  }

  // These are the possible status codes that could be returned if the server is down.
  // 0 status could possibly be a CORS error if the API gateway is down.
  // 500 error with a specific message about the proxy failure occurs when a service is down.
  private serverPossiblyDown(error: HttpErrorResponse): boolean {
    return (
      [0, 502, 503, 504].includes(error.status) ||
      (error.status === 500 && error.error.error === 'There was a problem proxying the request')
    );
  }

  private appIsInMaintenance(maintenanceInfo: MaintenanceInfo): boolean {
    const currentTime = new Date().getTime();
    const startTime = maintenanceInfo.startTime * 1000;
    const endTime = maintenanceInfo.endTime * 1000;
    return maintenanceInfo && currentTime >= startTime && currentTime < endTime;
  }
}
