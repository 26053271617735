@if (loading) {
  <div class="align-self-center mx-auto">
    <bx-spinner></bx-spinner>
  </div>
} @else {
  <div class="d-flex flex-column p-1">
    <bx-chips
      [addButtonPopoverContentComponent]="regionsSelectorAddFormComponent"
      [formControl]="chipsControl"
      [readonlyChips]="readonlyRegions"
    ></bx-chips>
    @if (showWarning$ | async) {
      <div class="warning">
        <fa-icon class="text-warning" [icon]="faExclamationTriangle"></fa-icon> Analysis will be
        quicker when there are 10 or less clusters
      </div>
    }
  </div>
}
