import { ModuleWithProviders, NgModule } from '@angular/core';
import { ViewerDataService } from './viewer-data/viewer-data.service';
import { ViewersState } from './viewers-state/viewers-state';
import {
  FULL_VIEW_MODE_VIEWER_COMPONENTS_TOKEN,
  PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS_TOKEN,
  PREVIEW_MODE_VIEWER_COMPONENTS_TOKEN,
} from './viewers-v2.config';
import { ViewersService } from './viewers/viewers.service';

@NgModule()
export class ViewersV2Module {
  static withComponents(
    previewModeViewerComponents: any[],
    previewModeComplexViewerComponents: any[],
    fullViewModeViewerComponents: any[],
  ): ModuleWithProviders<ViewersV2Module> {
    return {
      ngModule: ViewersV2Module,
      providers: [
        {
          provide: PREVIEW_MODE_VIEWER_COMPONENTS_TOKEN,
          useValue: previewModeViewerComponents,
        },
        {
          provide: PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS_TOKEN,
          useValue: previewModeComplexViewerComponents,
        },
        {
          provide: FULL_VIEW_MODE_VIEWER_COMPONENTS_TOKEN,
          useValue: fullViewModeViewerComponents,
        },
        ViewersService,
        ViewerDataService,
        ViewersState,
      ],
    };
  }
}
