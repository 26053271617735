import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Item } from '../../../nucleus/v2/models/item.v2.model';

@Injectable({
  providedIn: 'root',
})
export class FilePickerService implements OnDestroy {
  readonly selectedChanged$: BehaviorSubject<Item[]> = new BehaviorSubject([]);

  private selected = new Map();

  constructor() {}

  isSelected(id: string): boolean {
    return this.selected.has(id);
  }

  reset() {
    this.selected.clear();
  }

  selectSequence(sequence: Item) {
    this.selected.set(sequence.id, sequence);
    this.selectedChanged$.next([...this.selected.values()]);
  }

  unselectSequence(sequence: Item) {
    this.selected.delete(sequence.id);
    this.selectedChanged$.next([...this.selected.values()]);
  }

  ngOnDestroy() {
    this.selectedChanged$.complete();
  }
}
