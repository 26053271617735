<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  id="field-mapper-creator"
  [ngClass]="{ 'is-invalid': form.invalid, 'is-valid': form.valid }"
>
  <div class="mb-2">
    <label for="field" class="form-label" data-spec="field-label"> Metadata field: </label>
    <select
      id="field"
      class="form-select"
      [formControl]="form.controls.field"
      ngFormControlValidator
      [validateOnBlur]="true"
      [canMarkPristineInvalid]="true"
      data-spec="field-select"
    >
      @for (option of fields; track trackByValue($index, option)) {
        <option [value]="option.value" data-spec="field-option">
          {{ option.displayName }}
        </option>
      }
    </select>
  </div>

  <div class="mb-2">
    <label for="field-value-type" class="form-label" data-spec="field-value-type-label">
      Field value type:
    </label>
    <select
      id="field-value-type"
      class="form-select"
      [formControl]="form.controls.fieldValueType"
      ngFormControlValidator
      [validateOnBlur]="true"
      [canMarkPristineInvalid]="true"
      data-spec="field-value-type-select"
    >
      @for (option of valueTypeOptions; track trackByValue($index, option)) {
        <option [value]="option.value" data-spec="field-value-type-option">
          {{ option.displayName }}
        </option>
      }
    </select>
  </div>

  <div class="mb-3">
    <label for="reducer" class="form-label" data-spec="reducer-label"> Represent values as: </label>
    <select
      id="reducer"
      class="form-select"
      [formControl]="form.controls.reducerKind"
      data-spec="reducer-select"
    >
      @for (option of reducerOptions$ | async; track trackByValue($index, option)) {
        <option [value]="option.value" data-spec="reducer-option">
          {{ option.displayName }}
        </option>
      }
    </select>
  </div>

  <button
    class="btn btn-primary w-100"
    type="submit"
    [disabled]="form.invalid"
    data-spec="submit-btn"
  >
    Add
  </button>
</form>
<bx-form-errors [control]="form" data-spec="form-error"></bx-form-errors>
