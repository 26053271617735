import { ChangeDetectorRef, Component, HostBinding, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of as observableOf } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { CleanUp } from '../../../shared/cleanup';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerDocumentData, ViewerDocumentSelection } from '../viewer-document-data';
import { annotatedPluginDocumentViewerSelector } from '../viewer-selectors';
import { NgClass } from '@angular/common';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';

@ViewerComponent({
  key: 'html-viewer',
  title: 'Assembly Report',
  selector: annotatedPluginDocumentViewerSelector([
    DocumentSelectionSignature.forDocumentClass(
      'com.biomatters.plugins.alignment.assembly.AssemblyReportDocument',
      1,
      1,
    ),
  ]),
})
@Component({
  selector: 'bx-html-viewer',
  templateUrl: './html-viewer.component.html',
  standalone: true,
  imports: [NgClass, SpinnerComponent],
})
export class HtmlViewerComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block overflow-auto';
  html: string;

  id: string;
  isLoading: boolean;

  helpText = 'Please select an assembly report document.';

  private readonly classMap: Record<string, string> = {
    prgmKeyword: 'fw-bold',
    optionTitle: 'fw-bold',
    flatBorder: 'border',
    articleTitle: 'fw-bold',
    SummaryText: 'lead',
  };

  constructor(
    private cd: ChangeDetectorRef,
    private documentHttpService: DocumentHttpV2Service,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private readonly domSanitizer: DomSanitizer,
  ) {
    super();
  }

  ngOnInit(): void {
    this.viewerDataService
      .getData('html-viewer')
      .pipe(
        map((data) => data.selection),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state) => {
        this.updateViewer(state);
      });
  }

  updateViewer(viewerState: ViewerDocumentSelection) {
    this.reset();
    if (viewerState.rows.length === 1) {
      this.isLoading = true;
      this.id = viewerState.rows[0].id;

      this.documentHttpService
        .getDocumentPart(this.id, 'TEXT_VIEW', 'text')
        .pipe(
          catchError(() => {
            // Most likely isn't an assembly report (404).
            this.reset();
            this.cd.markForCheck();
            return observableOf(null);
          }),
          filter((response) => !!response),
        )
        .subscribe((response) => {
          this.isLoading = false;
          this.html = this.tidyUpHtml(response);
          this.cd.markForCheck();
        });
    }
  }

  private tidyUpHtml(geneiousHtml: string): string {
    const sanitizedInput = this.domSanitizer.sanitize(SecurityContext.HTML, geneiousHtml);
    const reportHTML = new DOMParser().parseFromString(sanitizedInput, 'text/html');
    const tidiedHTML = this.mapNode(reportHTML.documentElement);
    if (!this.isElement(tidiedHTML)) {
      throw new Error('Something went wrong while parsing the report');
    }
    return tidiedHTML.outerHTML;
  }

  private mapNode(node: Node) {
    if (node.nodeType === Node.TEXT_NODE) {
      return document.createTextNode(node.textContent);
    }
    if (!this.isElement(node)) {
      return node.cloneNode(true);
    }

    const newElement = this.createNewElement(node);
    if (newElement == null) {
      return null;
    }

    Array.from(node.attributes).forEach((attr) => {
      if (attr.name !== 'color') {
        newElement.setAttribute(attr.name, attr.value);
      }
    });

    node.classList.forEach((oldClass) => {
      const newClass = this.classMap[oldClass] ?? oldClass;
      newElement.classList.add(newClass);
    });

    node.childNodes.forEach((child) => {
      const mappedNode = this.mapNode(child);
      if (mappedNode != null) {
        newElement.appendChild(mappedNode);
      }
    });

    return newElement;
  }

  private createNewElement(oldElement: Element) {
    switch (oldElement.tagName.toLowerCase()) {
      case 'html':
      case 'body':
        return document.createElement('div');
      case 'head':
      case 'style':
        return null;
      case 'h1':
        return document.createElement('h3');
      case 'h2':
        return document.createElement('h4');
      case 'h3':
        return document.createElement('h5');
      case 'h4':
        return document.createElement('h6');
      case 'font':
        const element = document.createElement('p');
        if (oldElement.getAttribute('color') === 'gray') {
          element.classList.add('text-muted');
        }
        return element;
      default:
        return document.createElement(oldElement.tagName);
    }
  }

  isElement(node: Node): node is Element {
    return node.nodeType === Node.ELEMENT_NODE;
  }

  reset() {
    this.id = null;
    this.isLoading = false;
  }
}
