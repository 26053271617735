import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../../features/grid/datasource/grid.resource';
import { Observable } from 'rxjs';
import { Search } from '../model/search.model';
import { Searcher } from '../model/searcher';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { SortModel } from '../../../features/grid/grid.interfaces';
import { FormatterService } from '../../../shared/formatter.service';
import { UtilService } from '../../../shared/util.service';
import { map } from 'rxjs/operators';

export class SearchResultsGridResource implements IGridResource {
  private firstColumn = {
    headerName: 'Parent document',
    field: '_documentName',
    width: 200,
    // TODO Deduplicate from SearchResultsTableComponent?
    cellRenderer: (cell: any) => {
      if (cell.data && cell.data._documentID) {
        // Filter for result document sequences table, intended to show relevant sequences only.
        const link = `/view?ids=${cell.data._documentID}&filter=TRUE`;
        return this.util.openNewLink(cell.value || 'Go to result', link);
      } else if (cell.value) {
        return this.util.sanitizeCell(FormatterService.emptyIfMissing(cell.value));
      }
    },
  };

  constructor(
    private searcher: Searcher,
    private util: UtilService,
  ) {}

  query(
    gridParams: IGetRowsRequestMinimal | any,
    optionalParams: any,
  ): Observable<IGridResourceResponse<Search.Result>> {
    // Support calling this method from both bx-grid and fetchService.
    const pageLimit = gridParams.pageLimit
      ? gridParams.pageLimit
      : gridParams.endRow - gridParams.startRow;
    const pageOffset = gridParams.pageOffset ? gridParams.pageOffset : gridParams.startRow;
    const query: Search.GridQueryParameters = {
      pagination: {
        offset: pageOffset,
        limit: pageLimit,
      },
      sort: gridParams.sortModel.length > 0 ? this.formatSortParams(gridParams.sortModel) : '',
    };

    return this.searcher.search(query).pipe(
      map((data) => {
        // Search for files/folders doesn't need this. Only CDR3 search results need it.
        if (data.columns) {
          // TODO Does the mutation matter?
          data.columns.unshift(this.firstColumn);
        }
        return data;
      }),
    );
  }

  private formatSortParams(sort: SortModel[]): string {
    // * indicates to perform natural sort, which we need at the moment since the fields are all strings (even the numerical fields).
    return sort
      .map(({ colId, sort }) => {
        let sortString = colId;
        if (colId !== 'modifiedAt' && colId !== 'createdAt') {
          sortString = `*${colId}`;
        }
        return sort === 'asc' ? sortString : `-${sortString}`;
      })
      .join(',');
  }
}
