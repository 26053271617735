import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import Token from '../../../tokenizer-preview/Token';
import { NameSchemeFieldsTemplateParser } from './name-scheme-fields-template-parser';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nameSchemeFieldsTemplateConstructorValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NameSchemeFieldsTemplateConstructorValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class NameSchemeFieldsTemplateConstructorValidatorDirective implements Validator {
  @Input('nameSchemeFieldsTemplateConstructorValidator') tokens: Token[];

  validate(control: AbstractControl): ValidationErrors | null {
    const parsed = NameSchemeFieldsTemplateParser.parse(control.value, this.tokens);
    return parsed.errors.length > 0 ? { invalidTemplate: { value: control.value } } : null;
  }
}
