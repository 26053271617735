import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DocumentHistoryData, JobService, LogLevel } from '@geneious/nucleus-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import {
  HistoryItemData,
  HistoryItemPlaceholder,
  DocumentHistoryItemComponent as DocumentHistoryItemComponent_1,
} from '../document-history-item/document-history-item.component';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { AsyncPipe } from '@angular/common';
import { JobDetailsComponent } from '../../../shared/job-details/job-details.component';

/**
 * Displays a list of Document History events in a card. This component formats
 * the events it receives as an Input, and appends job data for the children to
 * display (see {@link DocumentHistoryItemComponent}).
 *
 * While the card is collapsed, the history data is still emitted but the Jobs
 * API is not called. This means that the history items are already rendered
 * when the user expands the card, but the job details (e.g. pipeline name)
 * are lazily loaded.
 */
@Component({
  selector: 'bx-document-history-card',
  templateUrl: './document-history-card.component.html',
  styleUrls: ['./document-history-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CollapsibleCardComponent,
    DocumentHistoryItemComponent_1,
    JobDetailsComponent,
    AsyncPipe,
  ],
})
export class DocumentHistoryCardComponent extends CleanUp implements OnInit {
  @Input() documentHistory$: Observable<DocumentHistoryData[]>;
  @Input() usersByID$: Observable<Record<string, { id: string; name: string }>>;
  @Input() selectionChanged$: Observable<void>;

  historyEvents$: Observable<HistoryItemData[] | HistoryItemPlaceholder[]>;
  collapsed$ = new Subject<boolean>();
  jobInsightsEnabled$: Observable<boolean>;
  constructor(
    private readonly store: Store<AppState>,
    private readonly jobService: JobService,
    public readonly modalService: NgbModal,
    private readonly router: Router,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({ next: () => this.modalService.dismissAll('navigation') });

    const placeholder$: Observable<HistoryItemPlaceholder[]> = this.selectionChanged$.pipe(
      mapTo([{ placeholder: true }]),
    );
    const historyEventData$: Observable<HistoryItemData[]> = combineLatest([
      this.documentHistory$,
      this.usersByID$,
      this.collapsed$.pipe(startWith(false)),
    ]).pipe(
      distinctUntilChanged((e1, e2) => !this.dataChangedOrCardNewlyExpanded(e1, e2)),
      switchMap(([history, usersByID, collapsed]) => {
        if (history.length === 0) {
          return of([]); // forkJoin doesn't emit if passed an empty array
        }
        return forkJoin(
          history.map((event) => this.getHistoryItemData(event, usersByID, collapsed)),
        );
      }),
    );
    this.historyEvents$ = merge(placeholder$, historyEventData$);
  }

  /**
   * Retrieves HistoryItemData, with job details if the card is expanded
   *
   * @param history Document History event
   * @param usersByID Map of user IDs to UserV2 detail objects
   * @param collapsed True if card is collapsed
   * @returns Single-emission observable of HistoryItemData
   */
  private getHistoryItemData(
    history: DocumentHistoryData,
    usersByID: Record<string, { id: string; name: string }>,
    collapsed: boolean,
  ): Observable<HistoryItemData> {
    const dataWithoutJob = {
      eventType: history.eventType,
      createdAt: history.createdAt,
      userName: usersByID[history.userID]?.name,
    };
    if (collapsed) {
      return of(dataWithoutJob);
    }
    const orgID$ = this.store.pipe(select(selectOrganizationID), first());
    this.jobInsightsEnabled$ = this.featureSwitchService.isEnabledOnce('jobInsights');
    const jobLogs$ = orgID$.pipe(
      switchMap((orgID) => this.jobService.getOrganizationJobLogs(orgID, history.jobID, [])),
      map((response) => response.data),
    );
    const job$ = orgID$.pipe(
      switchMap((orgID) => this.jobService.getOrganizationJob(orgID, history.jobID)),
    );
    return forkJoin([jobLogs$, job$]).pipe(
      map(([jobLogs, job]) => {
        return {
          ...dataWithoutJob,
          job: job.data,
          logSummary: {
            warningCount: jobLogs.filter((log) => log.level == LogLevel.Warning).length,
            infoCount: jobLogs.filter((log) => log.level == LogLevel.Info).length,
            errorCount: jobLogs.filter((log) => log.level == LogLevel.Error).length,
            debugCount: jobLogs.filter((log) => log.level == LogLevel.Debug).length,
          },
        };
      }),
      catchError(() => of(dataWithoutJob)),
    );
  }

  /**
   * Returns true if the data (documentHistory or usersByID) has changed since
   * the previous emission, OR if the data is the same but the card has just
   * been expanded. In the latter case, the data will be re-emitted with
   * additional job details fetched from the JobService to display to the user.
   * See this class' JSDocument for more context.
   *
   * This is to be used _negated_ with distinctUntilChanged, which interprets
   * `false` as "allow this emission". This method is written like a filter
   * function, where `true` means "allow", because it's easier to read.
   *
   * @param previous The previous emission that successfully passed distinctUntilChanged
   * @param latest The latest emission to test
   * @returns True if the data has changed (and the emission should be allowed).
   */
  private dataChangedOrCardNewlyExpanded(
    previous: [DocumentHistoryData[], Record<string, { id: string; name: string }>, boolean],
    latest: [DocumentHistoryData[], Record<string, { id: string; name: string }>, boolean],
  ) {
    const [history1, users1, collapsed1] = previous;
    const [history2, users2, collapsed2] = latest;
    const newlyExpanded = collapsed1 && !collapsed2;
    return newlyExpanded || history1 !== history2 || users1 !== users2;
  }
}
