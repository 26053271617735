import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadGroup } from '../../models/file-upload.model';
import { Component } from '@angular/core';
import { FolderService } from '../../folders/folder.service';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

/**
 * AgGrid Renderer component, shown in the uploads table.
 * @see https://www.ag-grid.com/javascript-grid-cell-rendering-components/#cell-renderer-component
 */
@Component({
  selector: 'bx-link-header',
  templateUrl: './upload-link.component.html',
  standalone: true,
  imports: [RouterLink, AsyncPipe],
})
export class UploadLinkComponent implements IHeaderAngularComp {
  link$: Observable<string[]>;
  folderName$: Observable<string>;

  constructor(private folderService: FolderService) {}

  agInit(params: IHeaderParams): void {
    const data: FileUploadGroup = (params as any).data;
    let folder$: Observable<FolderTreeItem | null> = of(null);

    if (data.folderID) {
      folder$ = this.folderService.get(data.folderID);
    }

    this.folderName$ = folder$.pipe(map((folder) => (folder ? folder.name : '-')));
    this.link$ = folder$.pipe(
      map((folder) => (folder ? [data.route || '/folders', folder.id] : [])),
    );
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
