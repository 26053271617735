import { FormStates } from '../form-state.model';
import { PipelineFormID } from '../../../pipeline/pipeline-constants';

export function ngsDefaultLiabilitiesMigration(formStates: FormStates): FormStates {
  const ngsFormState = formStates[PipelineFormID.ANTIBODY_ANNOTATOR];
  if (ngsFormState && 'liabilities_liabilitiesText' in ngsFormState.options) {
    const { liabilities_liabilitiesText, ...remainingOptions } = ngsFormState.options;

    return {
      ...formStates,
      [PipelineFormID.ANTIBODY_ANNOTATOR]: {
        ...formStates[PipelineFormID.ANTIBODY_ANNOTATOR],
        options: {
          ...remainingOptions,
          liabilities_liabilitiesText: replaceOldLiabilities(liabilities_liabilitiesText),
        },
      },
    };
  }
  return formStates;
}

function replaceOldLiabilities(liabilities: string): string {
  return liabilities.replace(
    /(Isomerization DG DS\n)|(Deamidation NG NS NA\n)/g,
    (liability) =>
      ({
        'Isomerization DG DS\n': 'Isomerization DG DS (Heavy_CDR2 Heavy_CDR3 Light_CDR1)\n',
        'Deamidation NG NS NA\n': 'Deamidation NG NS NA (Heavy_CDR2 Light_CDR1)\n',
      })[liability],
  );
}
