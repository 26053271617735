<form [formGroup]="form">
  <bx-card>
    <div bxCardHeader class="form-check">
      <input
        type="radio"
        id="groupAssemblies"
        value="groupAssemblies"
        formControlName="groupAssemblies"
        class="form-check-input"
      />
      <label for="groupAssemblies" class="form-check-label"> Batch by Name </label>
    </div>
    <div class="row mb-2">
      <label for="namePart" class="col-3 col-form-label"> Name part: </label>
      <div class="col-9">
        <select formControlName="namePart" id="namePart" class="form-select">
          @for (namePart of nameParts; track namePart) {
            <option [value]="namePart.value">
              {{ namePart.label }}
            </option>
          }
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3"></div>
      <div class="col-9">
        <span id="namePartHelp" class="form-text text-muted">
          Each name is split into segments by the given separator, then the n-th segment is used to
          identify the sequence's group.
        </span>
      </div>
    </div>

    <div class="row mb-2">
      <label for="nameSeparator" class="col-3 col-form-label"> Name separator: </label>
      <div class="col-9">
        <input
          id="nameSeparator"
          type="text"
          class="form-control"
          formControlName="nameSeparator"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-3"></div>
      <div class="col-9">
        <span id="nameSeparatorHelp" class="form-text text-muted">
          The character at which each name is split (there should be one of these on either side of
          the identifier in each name).
        </span>
      </div>
    </div>
    <div class="row mb-0">
      <label class="col-3 col-form-label">Example:</label>
      <div class="col-9 preview-column">
        @if (previewError$ | async) {
          <div class="text-danger">Error: failed to load the example.</div>
        } @else {
          @if (preview$ | async; as preview) {
            <span>
              <span>For example, your sequence </span>
              <span class="border bg-light">
                @for (part of preview.fileNameParts; track part; let i = $index) {
                  <span
                    ><span [ngClass]="{ 'fw-bold': part.isTarget }">{{ part.text }}</span>
                    @if (i < preview.fileNameParts.length - 1) {
                      <span>{{ preview.nameSeparator }}</span>
                    }
                  </span>
                }
              </span>
              <span> will be matched by any other sequences containing </span>
              <span class="border bg-light">{{ preview?.match }}</span>
              <span> at position </span>
              <span class="border bg-light">{{ preview?.targetNumber }}</span>
              <span>.</span>
            </span>
          } @else {
            <bx-spinner></bx-spinner>
          }
        }
      </div>
    </div>
  </bx-card>

  <bx-card *bxShowIf="'nameSchemes'">
    <div bxCardHeader class="form-check">
      <input
        type="radio"
        id="assembleByNameScheme"
        value="assembleByNameScheme"
        formControlName="groupAssemblies"
        class="form-check-input"
        [attr.disabled]="nameSchemeRadioDisabled"
        data-testid="assemble-name-scheme"
      />
      <label for="assembleByNameScheme" class="form-check-label">
        Batch by Name Scheme
        <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625292">
          <mat-icon class="icon-small my-n2">help</mat-icon>
        </a>
      </label>
    </div>

    <div class="row mb-2">
      <label
        class="col-3 col-form-label"
        ngbTooltip="Only name schemes with Common Identifier classification fields defined are available for use"
      >
        Name scheme:
      </label>
      <div class="col-9">
        <select
          formControlName="fileNameSchemeID"
          id="nameScheme"
          class="form-select"
          ngFormControlValidator
          data-testid="assemble-name-scheme-options"
        >
          @for (nameScheme of nameSchemes$ | async; track nameScheme) {
            <option [value]="nameScheme.value">
              {{ nameScheme.displayName }}
            </option>
          }
        </select>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-3"></div>
      <div class="col-9">
        <span id="nameSchemeHelp" class="form-text text-muted">
          Apply the selected name scheme to each sequence to determine the common identifier for
          batching. The name scheme will be used by further analyses downstream.
          @if (nameSchemeWarningMessage) {
            <br /><br />
            <fa-icon [icon]="exclamationIcon" class="text-warning"></fa-icon>
            {{ nameSchemeWarningMessage }}
          }
        </span>
      </div>
    </div>
  </bx-card>

  <bx-card [hideBody]="true">
    <div bxCardHeader class="form-check">
      <input
        type="radio"
        id="assembleListsSeparately"
        value="assembleListsSeparately"
        formControlName="groupAssemblies"
        class="form-check-input"
      />
      <label for="assembleListsSeparately" class="form-check-label"> Batch by List </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Assembly Options">
    <div class="mb-3 row align-items-center">
      <div class="col-auto">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="callChromatogramHeterozygotes"
            formControlName="callChromatogramHeterozygotes"
            name="callChromatogramHeterozygotes"
            class="form-check-input"
          />
          <label
            for="callChromatogramHeterozygotes"
            class="form-check-label"
            ngbTooltip="When a Sanger trace has an alternative peak that is at least as high as this percentage of the best peak, that sequence will contribute a heterozygous call to the consensus calculation. Base calls with a quality score of at least 63 (i.e. those manually edited) will not be analysed for heterozygotes"
          >
            Consensus: call Sanger heterozygotes
          </label>
        </div>
      </div>
      <div class="col-auto">
        <div class="input-group">
          <span title="Greater than" class="input-group-text">&gt;</span>
          <input
            type="number"
            min="0"
            max="100"
            id="chromatogramHeterozygotePercentage"
            formControlName="chromatogramHeterozygotePercentage"
            class="form-control"
            ngFormControlValidator
          />
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="saveUnusedReads"
        formControlName="saveUnusedReads"
        name="saveUnusedReads"
        class="form-check-input"
      />
      <label
        for="saveUnusedReads"
        class="form-check-label"
        ngbTooltip="Saves any unassembled reads into a sequence list document. This option will be disabled on algorithms that don't support it."
      >
        Save list of unused reads
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="generateContigs"
        formControlName="generateContigs"
        name="generateContigs"
        class="form-check-input"
      />
      <label
        for="generateContigs"
        class="form-check-label"
        ngbTooltip="Save a contig for each assembly showing how sequences were assembled."
      >
        Generate a contig for each assembly
      </label>
    </div>

    <div class="form-check mb-0">
      <input
        type="checkbox"
        id="consensusSequencesAsList"
        formControlName="consensusSequencesAsList"
        name="consensusSequencesAsList"
        class="form-check-input"
      />
      <label
        for="consensusSequencesAsList"
        class="form-check-label"
        ngbTooltip="Save all consensus sequences from the assembly into a single list."
      >
        Output consensus sequences as list
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [outputNameFormControl]="form.get('outputFolderName')"></bx-pipeline-output>
  </bx-card>
</form>
