import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  clearNGSState,
  fetchSequences,
  fetchSequencesFailure,
  fetchSequencesSuccess,
} from './ngs.actions';
import { NgsSequenceViewerService } from '../ngs-sequence-viewer.service';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { selectNGS } from './ngs.selectors';
import { FolderService } from '../../folders/folder.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { NGSSequenceData, NGSStoreState } from './index';

@Injectable()
export class NGSEffects {
  clearNGSState$ = createEffect(() =>
    this.folderService.folderSelectionState$.pipe(
      filter((state) => state != null),
      map((state) =>
        state.totalSelected === 1 ? (state.rows[0] as AnnotatedPluginDocument).id : undefined,
      ),
      distinctUntilChanged(),
      map(() => clearNGSState()),
    ),
  );

  fetchSequences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSequences),
      withLatestFrom(this.store.select(selectNGS)),
      switchMap(([{ documentID, ids }, ngsState]) => {
        return this.fetchNGSSequences(documentID, ids, ngsState).pipe(
          // Ensure we cancel the request if NGS State is cleared.
          takeUntil(this.actions$.pipe(ofType(clearNGSState))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ngsSequenceViewerService: NgsSequenceViewerService,
    private folderService: FolderService,
    private store: Store<AppState>,
  ) {}

  private createFetchSequencesSuccessAction(
    documentID: string,
    currentNGSStateDocumentID: string,
    sequenceItems: NGSSequenceData[],
  ) {
    if (documentID !== currentNGSStateDocumentID) {
      // If the documentID doesn't match, then reset the store with empty sequences and the currentNGSStateDocumentID.
      // This is extremely unlikely to happen, but it's just a safety net.
      return fetchSequencesSuccess({ documentID: currentNGSStateDocumentID, sequences: [] });
    } else {
      return fetchSequencesSuccess({ documentID, sequences: sequenceItems });
    }
  }

  private fetchNGSSequences(documentID: string, ids: number[], ngsState: NGSStoreState) {
    const uncachedIDs = ids.filter((id) => !ngsState.entities[id]);
    if (uncachedIDs.length > 0) {
      return this.ngsSequenceViewerService.getS3SequencesView(documentID, uncachedIDs).pipe(
        map((sequences) => {
          const sequenceItems: NGSSequenceData[] = sequences.map((sequence) => ({
            id: sequence.index,
            ...sequence.data,
          }));
          return this.createFetchSequencesSuccessAction(
            documentID,
            ngsState.documentID,
            sequenceItems,
          );
        }),
        catchError(() => of(fetchSequencesFailure({ documentID }))),
      );
    } else {
      return of(this.createFetchSequencesSuccessAction(documentID, ngsState.documentID, []));
    }
  }
}
