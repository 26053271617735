@if (totalSequencesSelected > 1000) {
  <div class="alert alert-warning">
    Warning: It is not recommended to align over 1000 rows. Please try aligning from a cluster table
    instead, or
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://help.geneiousbiologics.com/hc/en-us/requests/new "
      >contact us</a
    >
    for further alternatives.
  </div>
}
@if (message) {
  <p class="text-muted px-2">
    {{ message }}
  </p>
}
<form [formGroup]="form">
  <bx-card headerTitle="Main Options">
    @if (isAlignClusters) {
      <div formGroupName="clusterOptions">
        <div class="mb-3 row">
          <label
            for="representClusterBy"
            class="col-md-5 col-lg-4 col-form-label"
            data-spec="alignment-cluster-label"
          >
            Represent cluster by:
          </label>
          <div class="col-md-7 col-lg-8">
            <select
              id="representClusterBy"
              formControlName="representClusterBy"
              class="form-select"
              data-spec="alignment-cluster-input"
            >
              @for (option of clusterAlignOptions; track option) {
                <option [value]="option.key">
                  {{ option.label }}
                </option>
              }
            </select>
          </div>
        </div>
        @if (showClusterThresholdOption) {
          <div class="mb-3 row">
            <label
              for="thresholdByValue"
              class="form-label col-md-5 col-lg-4"
              data-spec="alignment-threshold-label"
            >
              Threshold:
            </label>
            @let isClusterFrequencyEnabled = isClusterFrequency();
            <div class="col-md-7 col-lg-8">
              <div class="input-group has-validation">
                <input
                  id="thresholdByValue"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': form.controls.clusterOptions.controls.thresholdByValue.invalid,
                  }"
                  type="number"
                  formControlName="thresholdByValue"
                  min="0"
                  [max]="isClusterFrequencyEnabled ? 100 : undefined"
                  data-spec="alignment-threshold-input"
                />
                @if (isClusterFrequencyEnabled) {
                  <span class="input-group-text">%</span>
                }
                <bx-form-errors
                  [control]="form.controls.clusterOptions.controls.thresholdByValue"
                ></bx-form-errors>
              </div>
              @if (
                showAlignedPerClusterWarning() &&
                form.controls.clusterOptions.controls.thresholdByValue.valid
              ) {
                <small class="form-text text-muted">
                  Up to 100 sequences will be aligned per cluster
                </small>
              }
            </div>
          </div>
        }
      </div>
    }

    <div class="mb-3 row align-items-center" formGroupName="regionsToAlign">
      <label
        class="col-form-label col-md-3 col-lg-4"
        for="regions"
        data-spec="alignment-select-regions-label"
      >
        Align regions:
      </label>

      <div
        class="col-md-9 col-lg-8"
        [ngbTooltip]="alignRegionTooltip$ | async"
        [disableTooltip]="disableAlignRegionTooltip$ | async"
        placement="bottom"
      >
        <bx-multi-select
          id="regions"
          data-spec="alignment-select-regions-input"
          [showSelectionSummary]="false"
          formControlName="regions"
          [selectOptions]="annotateRegionsOptions()"
          ngFormControlValidator
        >
        </bx-multi-select>
        <bx-form-errors
          #formErrors
          [control]="form.controls.regionsToAlign.controls.regions"
        ></bx-form-errors>
        @let regionsToAlign = selectedRegionsToAlign();
        @if (!(formErrors.errors$ | async)?.length) {
          <div class="d-flex mt-1">
            <span class="alignment-order">
              Region order:
              <span class="text-muted">
                {{ regionsToAlign | selectedRegionsOrderDisplay }}
              </span>
            </span>
            @if (loadingRegions()) {
              <bx-spinner class="ms-2"></bx-spinner>
            }
          </div>
        }
      </div>
    </div>

    <div class="mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          id="translateFirst"
          formControlName="translateFirst"
          class="form-check-input"
          data-spec="alignment-translate-input"
        />
        <label for="translateFirst" class="form-check-label" data-spec="alignment-translate-label">
          Translate nucleotides before aligning
        </label>
      </div>
    </div>

    @if (supportsCombineDuplicateSequences) {
      <div class="mb-3">
        <div class="form-check">
          <input
            type="checkbox"
            id="combineDuplicateSequences"
            formControlName="combineDuplicateSequences"
            name="combineDuplicateSequences"
            class="form-check-input"
            data-spec="alignment-combine-sequences-input"
          />
          <label
            for="combineDuplicateSequences"
            ngbTooltip="Combines duplicate sequences into a single reference sequence before aligning"
            class="form-check-label"
            data-spec="alignment-combine-sequences-label"
            >Combine duplicate sequences
            @if (totalSequencesSelected > 1000) {
              <span> (must be enabled if aligning over 1000 rows)</span>
            }
          </label>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="representDuplicatesBy"
          class="col-md-5 col-lg-4 col-form-label"
          ngbTooltip="The algorithm to use for selecting a representative from duplicate sequences"
          data-spec="alignment-algorithm-label"
        >
          Represent duplicates by:
        </label>
        <div class="col-md-7 col-lg-8">
          <select
            id="representDuplicatesBy"
            name="representDuplicatesBy"
            formControlName="representDuplicatesBy"
            class="form-select"
            data-spec="represent-duplicates-by-input"
          >
            <option value="HIGHEST_LIABILITY_SCORE">
              Representative Sequence (by Liability Score)
            </option>
            <option value="FIRST_IN_LIST">First occurrence (for the current sort order)</option>
          </select>
        </div>
      </div>
    }

    <div class="row mb-0">
      <label
        for="frame"
        class="col-md-5 col-lg-4 col-form-label"
        ngbTooltip="The algorithm to use for aligning your sequences. A default is automatically selected based on the number of sequences selected."
        data-spec="alignment-algorithm-label"
      >
        Alignment algorithm:
      </label>
      <div class="col-md-7 col-lg-8">
        <select
          id="alignmentAlgorithm"
          name="alignmentAlgorithm"
          formControlName="alignmentAlgorithm"
          class="form-select"
          data-spec="alignment-algorithm-input"
        >
          <option value="MUSCLE">MUSCLE v5.1</option>
          <option value="MAFFT">MAFFT</option>
        </select>
      </div>
    </div>
  </bx-card>

  <bx-collapsible-card headerTitle="Visualization Options">
    <div class="row">
      <div class="col-md-5 col-lg-4 mt-1">
        <div class="form-check">
          <input
            type="checkbox"
            id="buildTree"
            formControlName="buildTree"
            name="buildTree"
            class="form-check-input"
            data-spec="alignment-tree-checkbox-input"
          />
          <label for="buildTree" class="form-check-label" data-spec="alignment-tree-label">
            Build tree from alignment with:
          </label>
        </div>
      </div>
      <div class="col-md-7 col-lg-8">
        <div class="" [ngClass]="{ 'mb-0': !supportsCombineDuplicateSequences }">
          <select
            id="treeAlgorithm"
            formControlName="treeAlgorithm"
            class="form-select"
            [ngClass]="{ 'is-invalid': form.controls.treeAlgorithm.invalid }"
            data-spec="alignment-tree-algorithm-input"
          >
            <option value="RAXML">RAxML Tree Builder Algorithm</option>
            <option value="GENEIOUS">Geneious Tree Builder Algorithm</option>
          </select>
          <bx-form-errors
            [control]="form.controls.treeAlgorithm"
            data-spec="alignment-tree-algorithm-error"
          ></bx-form-errors>
        </div>
      </div>
    </div>

    @if (supportsCombineDuplicateSequences) {
      <div class="mb-0">
        <label
          class="form-label"
          for="fieldMappers"
          ngbTooltip="Select how metadata is represented when multiple sequences are collapsed during alignment"
          data-spec="alignment-field-mappers-label"
        >
          Show table columns in alignment:
        </label>
        <div
          class="d-flex py-2 px-3 border"
          [ngbTooltip]="fieldMappersDisabledMsg()"
          [disableTooltip]="fieldMappersDisabledMsg() === null"
          placement="bottom"
        >
          <bx-chips
            [addButtonPopoverContentComponent]="fieldMapperPopoverComponent"
            [addButtonPopoverContentData]="fieldMapperData()"
            [formControl]="form.controls.fieldMappers"
            data-spec="alignment-field-mappers-input"
          ></bx-chips>
        </div>
      </div>
    }
  </bx-collapsible-card>

  <bx-collapsible-card
    headerTitle="Advanced"
    [collapseByDefault]="true"
    data-testid="alignment-advanced-card"
  >
    @if (isNucleotideAlignment()) {
      <div class="mb-3 row" formGroupName="translation">
        <label
          for="geneticCode"
          class="col-md-5 col-lg-4 col-form-label"
          data-spec="alignment-genetic-code-label"
        >
          Genetic code:
        </label>
        <div
          class="col-md-7 col-lg-8"
          [ngbTooltip]="tooltips.translationDisabled"
          [disableTooltip]="isTranslateEnabled()"
        >
          <select
            id="geneticCode"
            formControlName="geneticCode"
            class="form-select"
            data-spec="alignment-genetic-code-input"
          >
            @for (code of geneticCodes; track code) {
              <option [value]="code.id">
                {{ code.name }}
              </option>
            }
          </select>
        </div>
      </div>
    }

    @if (isNucleotideAlignment()) {
      <div class="mb-3 row" formGroupName="translation">
        <label
          for="considerStart"
          class="col-md-5 col-lg-4 col-form-label"
          data-spec="alignment-start-codon-label"
        >
          Consider alternative start codons:
        </label>
        <div
          class="col-md-7 col-lg-8"
          [ngbTooltip]="tooltips.translationDisabled"
          [disableTooltip]="isTranslateEnabled()"
        >
          <select
            id="considerStart"
            formControlName="considerStart"
            class="form-select"
            data-spec="alignment-start-codon-input"
          >
            @for (option of considerStartOptions; track option) {
              <option [value]="option.key">
                {{ option.label }}
              </option>
            }
          </select>
        </div>
      </div>
    }

    <div class="" [ngClass]="{ 'mb-0': isNucleotideAlignment() === false }">
      <div class="form-check">
        <input
          type="checkbox"
          id="alignEntireSequence"
          formControlName="alignEntireSequence"
          class="form-check-input"
          data-spec="alignment-entire-sequence-input"
        />
        <label
          for="alignEntireSequence"
          class="form-check-label"
          data-spec="alignment-entire-sequence-label"
        >
          Align entire sequence
        </label>
      </div>
    </div>

    @if (isNucleotideAlignment()) {
      <div class="row mb-0" formGroupName="translation">
        <label
          for="frame"
          class="col-md-5 col-lg-4 col-form-label"
          data-spec="alignment-frame-label"
        >
          Use frame:
        </label>
        <div
          class="col-md-7 col-lg-8"
          [ngbTooltip]="translationFrameDisabledMsg()"
          [disableTooltip]="!translationFrameDisabledMsg()"
        >
          <select
            id="frame"
            formControlName="frame"
            class="form-select"
            data-spec="alignment-frame-input"
          >
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>
          </select>
        </div>
      </div>
    }
  </bx-collapsible-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.controls.resultName"
      [outputNameFormControl]="form.controls.outputFolderName"
      data-spec="alignment-output-controls"
    ></bx-pipeline-output>
  </bx-card>
</form>
