@if (viewModel$ | async; as vm) {
  <div class="container">
    <dl class="row mt-2 mb-0 mx-auto">
      <dt class="col-6" data-spec="summary-db-name-label">Database Name</dt>
      <dd class="col-6" data-spec="summary-db-name-value">
        @if (vm.databaseName) {
          <span>
            {{ vm.databaseName }}
          </span>
        } @else {
          <i>Not Defined</i>
        }
      </dd>
      <dt class="col-6" data-spec="summary-ref-db-label">Annotation Reference</dt>
      <dd class="col-6" data-spec="summary-ref-db-value">
        @if (vm.referenceDatabase) {
          <span>
            {{ vm.referenceDatabase }}
          </span>
        } @else {
          <i>Not Defined</i>
        }
      </dd>
      @if (vm.geneSource) {
        <dt class="col-6" data-spec="summary-gene-source-label">Gene Name Source</dt>
        <dd class="col-6" data-spec="summary-gene-source-value">
          <span>
            {{ vm.geneSource }}
          </span>
        </dd>
      }
      @if (vm.sequencesChain) {
        <dt class="col-6" data-spec="summary-sequences-chain-label">Sequences Chain</dt>
        <dd class="col-6" data-spec="summary-sequences-chain-value">
          <span>
            {{ vm.sequencesChain }}
          </span>
        </dd>
      }
      <dt class="col-6" data-spec="summary-files-label">Files</dt>
    </dl>
    @if (vm.files.length > 0) {
      <div class="row" data-spec="summary-files-value">
        <div class="col">
          <ul>
            @for (file of vm.files; track file) {
              <li>
                <i class="text-truncate small" [title]="file">{{ file }}</i>
              </li>
            }
          </ul>
        </div>
      </div>
    }
    <form>
      <div class="mb-3 row" data-spec="summary-disclaimer-control">
        <div class="col col-form-label d-flex align-items-center">
          <input
            type="checkbox"
            id="acceptDisclaimer"
            [formControl]="form.controls.acceptDisclaimer"
            class="form-check-inline"
            data-spec="summary-disclaimer-input"
            ngFormControlValidator
          />
          <label for="acceptDisclaimer" class="form-check-label">
            I understand that I need to inspect and curate the formatted output
          </label>
        </div>
        <bx-form-errors
          [control]="form.controls.acceptDisclaimer"
          [showAllErrors]="false"
        ></bx-form-errors>
      </div>
    </form>
  </div>
}
