import { ChangeDetectionStrategy, Component, Injectable, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SelectGroup } from '../../../../core/models/ui/select-option.model';
import { ColorPaletteID } from '../../../../core/color/color-palette.model';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../../shared/cleanup';
import { Observable } from 'rxjs';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { TreeMetadataExtractor } from '../../datasources/tree-metadata-extractor';
import { TreeGraphColoringHeatmapConfig } from '../graph-circular-tree.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AsyncPipe } from '@angular/common';
import { CollapsibleCardComponent } from '../../../../shared/collapsible-card/collapsible-card.component';
import { ColorPaletteComponent } from '../../../../core/color/color-palette/color-palette.component';
import { SelectComponent } from '../../../../shared/select/select.component';
import { SelectColorPaletteComponent } from '../../../../core/color/select-color-palette/select-color-palette.component';

/**
 * This is a custom control that will be displayed in the graph side to control the circular tree heatmaps.
 */
@Injectable()
export class CircularTreeHeatmapConfigOptions {
  constructor(
    public metadataGroups: SelectGroup[],
    public form: FormControl<TreeGraphColoringHeatmapConfig[]>,
    public maxRows: number,
    public defaultColumns: string[],
    public previousConfig: TreeGraphColoringHeatmapConfig[] = [],
  ) {}
}

type HeatmapControlGroup = FormGroup<{
  metadataKey: FormControl<string | null>;
  colorPalette: FormControl<ColorPaletteID | null>;
  collapsed: FormControl<boolean>;
}>;

@Component({
  selector: 'bx-circular-tree-heatmap-config',
  templateUrl: './circular-tree-heatmap-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbTooltip,
    FaIconComponent,
    CollapsibleCardComponent,
    ColorPaletteComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    SelectColorPaletteComponent,
    AsyncPipe,
  ],
})
export class CircularTreeHeatmapConfigComponent extends CleanUp implements OnInit {
  heatmapConfigControls: FormArray<HeatmapControlGroup> = new FormArray<HeatmapControlGroup>([]);
  metadataOptions: SelectGroup[];

  isAddingHeatmapDisabled$: Observable<boolean>;
  isHeatmapLimitReached$: Observable<boolean>;

  readonly faPlus = faPlus;

  constructor(
    public heatmapConfigOptions: CircularTreeHeatmapConfigOptions,
    private treeMetadataExtractor: TreeMetadataExtractor,
  ) {
    super();
    this.metadataOptions = heatmapConfigOptions.metadataGroups;
    this.isHeatmapLimitReached$ = this.heatmapConfigControls.valueChanges.pipe(
      map((configs) => configs.length === heatmapConfigOptions.maxRows),
      startWith(
        this.heatmapConfigControls.value.length >= heatmapConfigOptions.maxRows ||
          heatmapConfigOptions.previousConfig?.length >= heatmapConfigOptions.maxRows,
      ),
    );
    this.isAddingHeatmapDisabled$ = this.isHeatmapLimitReached$.pipe(
      map(
        (isHeatmapLimitReached) =>
          isHeatmapLimitReached ||
          this.metadataOptions.length === 0 ||
          this.metadataOptions.every((option) => option.isEmpty()),
      ),
    );
  }

  mapMetadataKeyToName(metadataKey: string) {
    const options = this.heatmapConfigOptions.metadataGroups.flatMap((group) => group.options);
    return options.find((option) => option.value === metadataKey)?.displayName ?? metadataKey;
  }

  handleRemoveHeatmapRow(index: number) {
    this.heatmapConfigControls.removeAt(index);
  }

  handleAddHeatmapRow() {
    this.addHeatmapRow(this.metadataOptions[0].options[0].value);
  }

  ngOnInit() {
    const options = this.heatmapConfigOptions.metadataGroups.flatMap((group) => group.options);
    // Reconstruct the form controls from the form value when the component is re-rendered
    for (const config of this.heatmapConfigOptions.form.value) {
      if (options.map((option) => option.value).includes(config.metadataField)) {
        this.heatmapConfigControls.push(
          this.createHeatmapControlGroup(
            config.metadataField,
            config.colorPalette,
            config.collapsed,
          ),
        );
      }
    }

    // Set the actual form value whenever the config is changed by users.
    this.heatmapConfigControls.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((controls) => {
        const configs = controls.reduce((acc, control) => {
          acc.push({
            ...this.getHeatmapConfig(control.metadataKey, control.colorPalette),
            collapsed: control.collapsed,
          });
          return acc;
        }, [] as TreeGraphColoringHeatmapConfig[]);
        this.heatmapConfigOptions.form.setValue(configs);
      });

    if (this.heatmapConfigControls.length === 0) {
      let foundDefaultColumn = false;
      for (const option of options) {
        if (this.heatmapConfigOptions.defaultColumns.includes(option.value)) {
          this.addHeatmapRow(option.value);
          foundDefaultColumn = true;
          break;
        }
      }
      if (!foundDefaultColumn && options.length > 0) {
        this.addHeatmapRow(options[0].value);
      }
    }
  }

  private addHeatmapRow(metadataKey: string) {
    const initialMetadata = metadataKey;
    const initialColouringPalette: ColorPaletteID = 'plasma';
    const initialCollapsedState: boolean = true;
    const controlGroup = this.createHeatmapControlGroup(
      initialMetadata,
      initialColouringPalette,
      initialCollapsedState,
    );

    this.heatmapConfigControls.push(controlGroup);
  }

  private createHeatmapControlGroup(
    defaultMetadataKey: string,
    defaultColorPalette: ColorPaletteID,
    defaultCollapsedState: boolean,
  ): HeatmapControlGroup {
    return new FormGroup({
      metadataKey: new FormControl<string>(defaultMetadataKey),
      colorPalette: new FormControl<ColorPaletteID>(defaultColorPalette),
      collapsed: new FormControl<boolean>(defaultCollapsedState),
    });
  }

  getHeatmapConfig(metadataKey: string, colorPalette: ColorPaletteID) {
    const metadata = this.treeMetadataExtractor.getMetadataValues(metadataKey);
    return this.treeMetadataExtractor.getColouringMetadataConfig(
      metadataKey,
      colorPalette,
      metadata,
    );
  }

  protected readonly faTimes = faTimes;
}
