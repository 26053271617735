import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MaintenanceInfo } from './index';
import { MODAL_DATA } from '../../../shared/dialog';
import { LogoComponent } from '../../../shared/logo/logo.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'bx-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LogoComponent, DatePipe],
})
export class MaintenancePageComponent {
  @HostBinding('class') readonly hostClass =
    'w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center py-5';

  constructor(@Inject(MODAL_DATA) public maintenanceInfo: MaintenanceInfo) {}
}
