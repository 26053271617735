<div
  class="bx-page-container h-100 text-center d-flex flex-column align-items-center justify-content-center"
>
  @if (isEmailChangeSuccess() === true) {
    <h3 data-spec="activated-msg">Email Change Complete!</h3>
    <p>Your email address has been successfully updated.</p>
    <p><button routerLink="/login" class="btn btn-primary">Login</button></p>
  } @else if (isEmailChangeSuccess() === false) {
    @if (isDuplicateEmailError()) {
      <h3 data-spec="activate-fail-msg">The Email You Trying To Activate Is Already in Use</h3>
      <p>
        Please request an email change again with a different email address or
        <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>.
      </p>
    } @else {
      <h3 data-spec="activate-fail-msg">There Was an Error While Activating Your Email</h3>
      <p>
        Please try using the activation link again or
        <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>.
      </p>
    }
    <p><a routerLink="/login">Back to Login</a></p>
  } @else {
    <h3>
      <span class="me-2" data-spec="activating-msg"><bx-spinner></bx-spinner></span>Activating Your
      Email Address
    </h3>
    <p>This should not take long.</p>
  }
</div>
