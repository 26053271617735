import { Component, OnInit, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  publishReplay,
  refCount,
  switchMap,
} from 'rxjs/operators';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { CleanUp } from '../../../shared/cleanup';
import { merge, Observable } from 'rxjs';
import { AnnotatedPluginDocument } from '../../geneious/models/annotatedPluginDocument.model';
import { GenericReportModel } from './generic-report.models';
import { JsonReportService } from './json-report.service';
import { annotatedPluginDocumentViewerSelector } from '../viewer-selectors';
import { ViewerDocumentData, ViewerDocumentSelection } from '../viewer-document-data';
import { DocumentPartOld } from '../../../../nucleus/v2/models/blob.v2.model';
import { AsyncPipe } from '@angular/common';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { RouterLink } from '@angular/router';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';
import { ColumnChartComponent } from '../../../features/graphs/column-chart/column-chart.component';

@ViewerComponent({
  key: 'json-report-viewer',
  title: 'Report',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.geneious.publicapi.implementations.TextDocument',
        1,
        1,
      ),
    ],
    (data) => {
      const row = data.selection.rows[0];
      return (
        row.documentParts.find((blob: DocumentPartOld) => blob.name.startsWith('JSON_REPORT_')) !=
        null
      );
    },
  ),
})
@Component({
  selector: 'bx-json-report-viewer',
  templateUrl: './json-report-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ToolstripComponent,
    ToolstripItemComponent,
    RouterLink,
    PageMessageComponent,
    LoadingComponent,
    ColumnChartComponent,
    AsyncPipe,
  ],
})
export class JsonReportViewerComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-hidden';

  state$: Observable<ViewerDocumentSelection>;
  currentReport$: Observable<GenericReportModel>;
  errorMessage$: Observable<string>;
  isLoading$: Observable<boolean>;

  DEFAULT_ERROR_MESSAGE = 'Report failed to load. Please try again or contact support.';

  constructor(
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private jsonReportService: JsonReportService,
  ) {
    super();
    this.state$ = viewerDataService
      .getData('json-report-viewer')
      .pipe(map((data) => data.selection));
  }

  ngOnInit() {
    // Valid state ensures that we don't reload the viewer when documents come/go in the files table.
    const validState$ = this.state$.pipe(
      filter((state) => state && state.rows.length > 0),
      distinctUntilChanged((prevState, currState) => prevState.rows[0].id === currState.rows[0].id),
      publishReplay(1),
      refCount(),
    );

    const selectedReport$ = validState$.pipe(
      switchMap((state) => this.getSelectedReport(state)),
      publishReplay(1),
      refCount(),
    );

    this.currentReport$ = merge(validState$.pipe(mapTo(null)), selectedReport$);

    this.errorMessage$ = merge(
      validState$.pipe(mapTo('')),
      selectedReport$.pipe(map((report) => (report ? '' : this.DEFAULT_ERROR_MESSAGE))),
    );

    this.isLoading$ = merge(validState$.pipe(mapTo(true)), selectedReport$.pipe(mapTo(false)));
  }

  getSelectedReport(viewerSelection: ViewerDocumentSelection): Observable<GenericReportModel> {
    const activeDocument: AnnotatedPluginDocument = viewerSelection.rows[0];
    const reportBlob = this.getReportBlobName(viewerSelection);
    return this.jsonReportService.getGenericReport(activeDocument.id, reportBlob);
  }

  getReportBlobName(viewerSelection: ViewerDocumentSelection) {
    const activeDocument: AnnotatedPluginDocument = viewerSelection.rows[0];
    return activeDocument.documentParts.find((blob: DocumentPartOld) =>
      blob.name.startsWith('JSON_REPORT_'),
    ).name;
  }
}
