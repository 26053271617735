<form [formGroup]="form">
  <div class="mb-3 px-2">
    <div class="form-check">
      <input type="checkbox" id="download" formControlName="download" class="form-check-input" />
      <label for="download" class="form-check-label">Automatically download image</label>
    </div>
  </div>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [resultNameFormControl]="form.get('resultName')"></bx-pipeline-output>
  </bx-card>
</form>
