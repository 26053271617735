<div class="icon-wrapper">
  <div class="icon">
    <mat-icon>{{ item.icon }}</mat-icon>
  </div>
</div>
<div class="content">
  <h4 matLine>{{ item.displayName }}</h4>
  <p matLine>{{ item.description }}</p>
</div>
<div class="access-level">
  <mat-form-field>
    <mat-select
      placeholder="Access Level"
      floatPlaceholder="always"
      [(ngModel)]="item.highestAccess"
      (selectionChange)="onAccessLevelChanged()"
    >
      <mat-select-trigger>
        {{ item.highestAccess.displayName }}
      </mat-select-trigger>
      @for (accessLevel of accessLevels; track accessLevel) {
        <mat-option [value]="accessLevel" class="access-level-option">
          <bx-access-level-option [accessLevel]="accessLevel"></bx-access-level-option>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div class="action">
  @if (item.isDeletable()) {
    <button mat-icon-button (click)="onRemovePermission()">
      <mat-icon>delete</mat-icon>
    </button>
  }
</div>
