import { Injectable } from '@angular/core';
import { AnnotatedPluginDocument } from '../../geneious';
import { TableForGraph } from './ngs-graph-data-store/ngs-graph-data-store.reducer';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import {
  isAllSequencesTable,
  isChainCombinationsTable,
  isClusterTable,
  isGeneClusterTable,
  isInexactClusterTable,
} from '../table-type-filters';
import { hasClusterLengthColumn } from '../table-column-filters';
import {
  CHAIN_COMBINATIONS_GRAPHS,
  CLUSTER_GENE_GRAPHS,
  CLUSTER_GRAPHS,
  NgsGraph,
  OVERVIEW_GRAPHS,
} from './ngs-graphs.model';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { ResultDocumentFieldEnum as FIELDS } from '../../../features/graphs/result-document-field.enum';
import { map, switchMap, tap } from 'rxjs/operators';
import { DocumentTableQueryService } from '../../document-table-service/document-table-state/document-table-query.service';
import { GraphDocumentDataService } from '../../../features/graphs/graph-document-data.service';
import { GraphOption } from '../../../features/graphs/graph-aba-data.service';
import { NgsClusterLengthsGraphComponent } from './ngs-cluster-graphs/ngs-cluster-lengths-graph/ngs-cluster-lengths-graph.component';
import { assertNever } from '../../../shared/utils/never';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { sanitizeDTSTableOrColumnName } from '../../../../nucleus/services/documentService/document-service.v1';

@Injectable({
  providedIn: 'root',
})
export class NgsGraphCompatibilityService {
  isLoading$ = new ReplaySubject<boolean>(1);
  constructor(
    private documentTableQueryService: DocumentTableQueryService,
    private graphDocumentDataService: GraphDocumentDataService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    this.isLoading$.next(false);
  }

  static isGenericSequenceChainDocument(document: AnnotatedPluginDocument): boolean {
    return document?.metadata?.antibodyAnnotatorOptionValues
      ? JSON.parse(document.metadata.antibodyAnnotatorOptionValues).sequences_chain ===
          'genericSequence'
      : false;
  }

  graphIsCompatible(
    graphType: NgsGraph,
    document: AnnotatedPluginDocument,
    thisTable: TableForGraph,
    tables: Record<string, DocumentTable>,
  ): Observable<boolean> {
    switch (graphType.id) {
      case 'noGraph':
      case 'annotationRates':
      case 'clusterSizes':
      case 'clusterDiversity':
      case 'clusterNumbers':
        return of(true);
      case 'sankey':
        return this.featureSwitchService.isEnabledOnce('sankey');
      case 'numberOfGenes':
      case 'geneCombinations':
      case 'geneFamilyUsage':
        return of(!NgsGraphCompatibilityService.isGenericSequenceChainDocument(document));
      case 'clusterNumbersNucleotide':
        return of(!document.hasNoNucleotides());
      case 'codonDistribution':
        return Object.keys(tables).includes('DOCUMENT_TABLE_CODON_USAGE')
          ? this.isCodonUsageGraphAvailable(document.id, thisTable.displayName)
          : of(false);
      case 'clusterLengths':
        return !isInexactClusterTable(tables[thisTable.name]) &&
          hasClusterLengthColumn(tables[thisTable.name])
          ? this.isClusterLengthsGraphAvailable(document.id, thisTable)
          : of(false);
      case 'aminoAcidDistribution':
        if (this.isNucleotideCluster(tables[thisTable.name])) {
          return of(false);
        }
        return hasClusterLengthColumn(tables[thisTable.name])
          ? this.isAminoAcidGraphAvailable(document.id, {
              name: thisTable.displayName,
              table: thisTable.name,
              tableType: thisTable.tableType,
              columns: thisTable.columns,
              metadata: thisTable.metadata,
            })
          : of(false);
      case 'clusterSummaryTree':
      case 'clusterSummaryNetwork':
        return this.featureSwitchService
          .isEnabledOnce(graphType.id)
          .pipe(
            map(
              (enabled) =>
                enabled &&
                document.documentParts.some(
                  (part) =>
                    part.name ===
                    `${sanitizeDTSTableOrColumnName(thisTable.displayName).replaceAll(
                      ' ',
                      '_',
                    )}_CLUSTER_SUMMARY`,
                ),
            ),
          );
      default:
        return assertNever(graphType.id, 'Unexpected case');
    }
  }

  getSelectableGraphsForDocumentAndTable(
    selectedTable: TableForGraph,
    document: AnnotatedPluginDocument,
    otherTables: Record<string, DocumentTable>,
  ): Observable<NgsGraph[]> {
    this.isLoading$.next(true);
    let defaultSelectableGraphs: NgsGraph[] = [];
    if (isChainCombinationsTable(selectedTable)) {
      defaultSelectableGraphs = CHAIN_COMBINATIONS_GRAPHS;
    } else if (isGeneClusterTable(selectedTable)) {
      defaultSelectableGraphs = CLUSTER_GENE_GRAPHS;
    } else if (isClusterTable(selectedTable) || isInexactClusterTable(selectedTable)) {
      defaultSelectableGraphs = CLUSTER_GRAPHS;
    } else if (isAllSequencesTable(selectedTable)) {
      defaultSelectableGraphs = OVERVIEW_GRAPHS;
    }
    return of(defaultSelectableGraphs).pipe(
      switchMap((graphs) =>
        forkJoin(
          graphs.map((graph) =>
            this.graphIsCompatible(graph, document, selectedTable, otherTables).pipe(
              map((compatible) => (compatible ? graph : null)),
            ),
          ),
        ),
      ),
      map((compatibleGraphs) => compatibleGraphs.filter((x) => !!x)),
      tap(() => this.isLoading$.next(false)),
    );
  }

  private isCodonUsageGraphAvailable(documentID: string, region: string): Observable<boolean> {
    const query = {
      fields: [FIELDS.LENGTH],
      where: `${FIELDS.REGION}='${region}'`,
      groupBy: [FIELDS.LENGTH],
    };

    return this.documentTableQueryService
      .queryTableAggregate(documentID, 'DOCUMENT_TABLE_CODON_USAGE', query)
      .pipe(
        map(
          (result) =>
            GraphDocumentDataService.getRowsFromAggregationResponse(result, FIELDS.LENGTH).length >
            0,
        ),
      );
  }

  isNucleotideCluster(table: TableForGraph) {
    return table?.metadata?.clusters?.containsNucleotides;
  }

  private isAminoAcidGraphAvailable(documentID: string, region: GraphOption): Observable<boolean> {
    return this.graphDocumentDataService
      .getClusterLengths(documentID, region)
      .pipe(map((lengths) => lengths.length > 0));
  }

  private isClusterLengthsGraphAvailable(
    documentID: string,
    table: TableForGraph,
  ): Observable<boolean> {
    const clusterLengthsQuery = NgsClusterLengthsGraphComponent.aggregateQuery(
      table.metadata,
      table.columns.map((col) => col.displayName),
    );
    const query = { ...clusterLengthsQuery, fields: [clusterLengthsQuery.fields[0]], limit: 1 };
    return this.documentTableQueryService.queryTableAggregate(documentID, table.name, query).pipe(
      map((result) => {
        return (
          GraphDocumentDataService.getRowsFromAggregationResponse(result, FIELDS.LENGTH).length > 0
        );
      }),
    );
  }
}
