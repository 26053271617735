import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackService } from '../../feedback/feedback-service';
import { BehaviorSubject } from 'rxjs';
import { FeedbackConfirmationDialogComponent } from '../../feedback/feedback-confirmation-dialog/feedback-confirmation-dialog.component';
import { CleanUp } from '../../../shared/cleanup';
import { Feedback } from '../../../../nucleus/services/models/feedback.model';
import { CardComponent } from '../../../shared/card/card.component';
import { AsyncPipe } from '@angular/common';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

@Component({
  selector: 'bx-job-feedback-dialog',
  templateUrl: './job-feedback-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CardComponent, SpinnerButtonComponent, AsyncPipe],
})
export class JobFeedbackDialogComponent extends CleanUp implements OnDestroy {
  @Input() jobID: string;
  @Input() pipelineName: string;

  readonly form = new FormGroup({
    userNotes: new FormControl<string>(undefined),
    allowAccess: new FormControl(true),
  });
  submitting$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<boolean>(false);
  feedbackConfirmationModalRef: NgbModalRef;

  constructor(
    private feedbackService: FeedbackService,
    private ngbModal: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.submitting$.complete();
    this.error$.complete();
  }

  send() {
    this.submitting$.next(true);
    this.error$.next(false);

    const rawValue = this.form.getRawValue();
    const feedbackPayload: Feedback = {
      subject: `Job Error Report: ${this.pipelineName}`,
      jobID: this.jobID,
      userNotes: rawValue.userNotes || 'No description provided by user.',
      accessLogs: rawValue.allowAccess,
      accessJobInputs: rawValue.allowAccess,
      accessJobResults: rawValue.allowAccess,
    };

    this.feedbackService.submitFeedback(feedbackPayload).subscribe(
      (feedback) => {
        this.submitting$.next(false);
        const zendeskReference = feedback.data.zendeskReference;
        if (zendeskReference == null) {
          console.error(
            'Submit Job Feedback: No zendesk link setup in this environment, so cannot submit feedback',
          );
          this.error$.next(true);
        } else {
          this.feedbackConfirmationModalRef = this.ngbModal.open(
            FeedbackConfirmationDialogComponent,
            { centered: true },
          );
          this.feedbackConfirmationModalRef.componentInstance.zendeskReference = zendeskReference;
          this.activeModal.close();
        }
      },
      (error) => {
        console.log(error);
        this.error$.next(true);
        this.submitting$.next(false);
      },
    );
  }

  close() {
    this.activeModal.close();
  }
}
