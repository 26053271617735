import { Inject, Injectable } from '@angular/core';
import { UploadsTableStoreService } from './uploads-table/uploads-table.store-service';
import { GroupUpload } from './upload.model';
import { Actions } from '@ngrx/effects';
import { ActiveService } from '../active.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../shared/cleanup';
import { HttpClient } from '@angular/common/http';
import { ActivityStreamService } from '../activity/activity-stream.service';
import { FileUploadActivityEventKind } from '../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { NEVER } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { ImportOptions } from '@geneious/nucleus-api-client';
import { NucleusPipelineID } from '../pipeline/pipeline-constants';
import { AppState } from '../core.store';
import { Store } from '@ngrx/store';
import { selectIsAuthenticatedAfterVerification } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class UploadManager extends CleanUp {
  private readonly enabled: boolean;
  private worker: Worker;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private actions: Actions,
    private uploadsTableStoreService: UploadsTableStoreService,
    private activityStreamService: ActivityStreamService,
    private activeService: ActiveService,
  ) {
    super();

    // The auth session should be active while uploading.
    // Status currently only refers to upload activity, but
    // could be changed to accommodate other types of activity.
    this.uploadsTableStoreService.status$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((status) => status === 'uploading' || status === 'importing'),
      )
      .subscribe(() => {
        this.activeService.updateLatestActivity();
      });

    this.store
      .pipe(
        selectIsAuthenticatedAfterVerification,
        switchMap((authenticated) => {
          if (authenticated) {
            return this.activityStreamService.listenToFileUploadActivity();
          } else {
            return NEVER;
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(({ event }) => {
        if (event.kind === FileUploadActivityEventKind.FILE_UPLOAD_STATUS_CHANGED) {
          this.uploadsTableStoreService.updateUploadsByFileID(event.fileUploadID, event.status);
        }
      });

    this.enabled = true;

    this.worker = new Worker(new URL('./upload.worker.ts', import.meta.url), {
      type: 'module',
      name: 'Upload Worker',
    });

    this.worker.postMessage({
      message: 'initialize',
      apiBaseUrl: `${this.config.nucleusApiBaseUrl}/api/nucleus`,
    });

    // Receive events from the worker thread.
    this.worker.onmessage = (e: any) => {
      handlers[e.data.message](e.data);
    };

    // Log output from the worker thread so that we learn about problems.
    this.worker.onerror = (e: any) =>
      console.error('ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message);

    let previousGroup: any = { done: 0 };
    const handlers: any = {
      // Forward on to other handlers.
      groupProgress: (data: any) => {
        const upload = new GroupUpload(data.group).toUpload();
        this.uploadsTableStoreService.updateFileUpload(upload, data.oldID);
        // Only fire fileAdded if it is likely that the file count has actually changed.
        // Simple check to help filter out partial file uploads and upload starts.
        const newProgress =
          data.group.ID !== previousGroup.ID || data.group.done > previousGroup.done;
        if (newProgress && data.group.done > 0) {
          previousGroup = Object.assign({}, data.group);
        }
      },
      // Called when a group is first added to the queue.
      groupAdded: (data: any) => {
        const activity = new GroupUpload(data.group).toUpload();
        this.uploadsTableStoreService.addFileUpload(activity);
      },
    };

    this.store.pipe(selectIsAuthenticatedAfterVerification).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        // Tell the worker to cancel all uploads on logout.
        this.cancel();
      }
    });
  }

  cancel(groupID?: string) {
    this.worker.postMessage({ message: 'cancel', groupID });
  }

  /**
   * Send files to the web worker to be uploaded
   * @param folderID
   * @param route
   * @param files
   * @param pipeline Import pipeline to run on the documents after uploaded
   * @param importOptions Options to pass to import pipeline
   * @private
   */
  _addFiles(
    folderID: string,
    route: string,
    files: File[],
    pipeline?: string,
    importOptions?: ImportOptions,
  ) {
    if (this.enabled && files.length > 0) {
      this.worker.postMessage({
        message: 'upload',
        folderID,
        route,
        files,
        pipeline,
        importOptions,
      });
    }
  }

  upload(
    folderID: string,
    route: string,
    files: File[],
    pipeline?: NucleusPipelineID,
    importOptions?: ImportOptions,
  ) {
    this._addFiles(folderID, route, files, pipeline, importOptions);
  }
}
