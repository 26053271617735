import Token from '../../../tokenizer-preview/Token';

export class NameSchemeFieldsTemplateParser {
  /**
   * Parses strings in the format of ${1}-${3}--${2} by substituting the variables
   * with their values.
   *
   * @param {string} template
   * @param {Token[]} tokens
   * @returns {{errors: {token: string, message: string}[], result: string}}
   */
  static parse(
    template: string,
    tokens: Token[],
  ): { errors: { token: string; message: string }[]; result: string } {
    const regex: RegExp = /(\$\{(\d+)\})/g;
    let match = regex.exec(template);
    const errors = [];
    let result = template;

    while (match != null) {
      const token = tokens.find((tok) => tok.index === parseInt(match[2], 10));

      if (token) {
        result = result.replace(match[0], token.value);
      } else {
        errors.push({ token: match[2], message: `Token ${match[2]} doesn't exist.` });
      }

      match = regex.exec(template);
    }

    return { errors, result };
  }
}

export interface TemplateParseResult {
  errors: { token: string; message: string }[];
  result: string;
}
