<div class="btn-group">
  <button
    ngbTooltip="Zoom in"
    placement="bottom"
    aria-label="Zoom in"
    class="btn btn-light"
    (click)="zoomControls.zoomIn()"
  >
    <mat-icon svgIcon="zoom-in" class="small"></mat-icon>
  </button>
  @if (zoomLevel$ | async; as zoomLevel) {
    <div class="btn btn-light" style="width: 4em; pointer-events: none !important">
      <small>{{ zoomLevel | percent }}</small>
    </div>
  }
  <button
    ngbTooltip="Zoom out"
    placement="bottom"
    aria-label="Zoom out"
    class="btn btn-light"
    (click)="zoomControls.zoomOut()"
  >
    <mat-icon svgIcon="zoom-out" class="small"></mat-icon>
  </button>
  <button
    ngbTooltip="Zoom to fit"
    placement="bottom"
    aria-label="Zoom to fit"
    class="btn btn-light"
    (click)="zoomControls.zoomToFit(true)"
  >
    <mat-icon svgIcon="zoom-fit" class="small"></mat-icon>
  </button>
</div>
