import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { ColumnChartComponent } from '../../../../../features/graphs/column-chart/column-chart.component';
import { SeriesColumnOptions } from 'highcharts';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { GraphOption } from '../../../../../features/graphs/graph-aba-data.service';
import { filter, map, take } from 'rxjs/operators';
import {
  selectDataForNgsDocument,
  selectGraphParamsForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { GraphDataFor } from '../../ngs-graphs.model';
import { NgsReportService } from '../../../ngs-report-viewer/ngs-report.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-number-of-genes-graph',
  templateUrl: './ngs-number-of-genes-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ColumnChartComponent, AsyncPipe],
})
export class NgsNumberOfGenesGraphComponent
  extends NgsBaseGraphComponent<NGSNumberOfGenesGraphData, ColumnChartComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(ColumnChartComponent) chartComponent: ColumnChartComponent;

  regions$: Observable<GraphOption[]>;
  region$ = new ReplaySubject<GraphOption>(1);

  ngOnInit(): void {
    super.ngOnInit();
    this.regions$ = this.getRegions();
    this.regions$
      .pipe(
        filter((regions) => !!regions),
        take(1),
      )
      .subscribe((regions) => {
        this.region$.next(regions[0]);
      });
    this.selectedParams$ = this.store.select(selectGraphParamsForNgsDocument(this.documentID));
    this.data$ = combineLatest([
      this.store.pipe(selectDataForNgsDocument<'numberOfGenes'>(this.documentID, 'numberOfGenes')),
      this.region$,
    ]).pipe(
      filter(([data, region]) => !!data && !!region),
      map(([data, { name }]) => this.process(data, name)),
    );

    this.regions$
      .pipe(
        map((regions) => [
          {
            name: 'region',
            label: 'Filter By',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: regions[0]?.name,
            options: regions.map(({ name }) => {
              return {
                displayName: name,
                value: name,
              };
            }),
          } as GraphSidebarControl,
        ]),
      )
      .subscribe(this.controls$);
  }

  private getRegions() {
    return this.store
      .pipe(selectDataForNgsDocument<'numberOfGenes'>(this.documentID, 'numberOfGenes'))
      .pipe(
        filter((data) => !!data),
        map((data) =>
          Object.keys(data.statistics.overview.genes[0])
            .filter((header) => header !== 'Region')
            .map((header) => ({ name: header, tableType: undefined, columns: undefined })),
        ),
      );
  }

  private process(
    reportData: GraphDataFor<'numberOfGenes'>,
    region: string,
  ): NGSNumberOfGenesGraphData {
    const chartData = NgsReportService.getBarChartOfTypeWithAxis(reportData, region, 'genes');
    return {
      title: chartData.title,
      xAxisTitle: chartData.xLabel,
      yAxisTitle: chartData.yLabel,
      data: chartData.data,
    };
  }

  onControlsChanged({ region }: any) {
    this.regions$.pipe(take(1)).subscribe((regions) => {
      if (!!region) {
        this.region$.next(regions.find((reg) => reg.name === region));
      } else {
        this.region$.next(null);
      }
    });
  }
}

export interface NGSNumberOfGenesGraphData {
  title: string;
  subtitle?: string;
  data: SeriesColumnOptions[];
  xAxisTitle: string;
  yAxisTitle: string;
}
