import { ViewersOverlays } from '../viewers-v2/viewers-v2.config';

// TODO Add tests for these
/**
 * For Use with Files Table Viewers.
 */
export const annotatedPluginDocumentViewerOverlays: ViewersOverlays = (
  data,
  numberOfDisplayedViewers,
) => {
  if (data?.selection?.totalSelected > 0 && numberOfDisplayedViewers === 0) {
    return `<h3>Invalid selection</h3><p>Try selecting a different document/s</p>`;
  } else if (numberOfDisplayedViewers === 0) {
    return `<h3>No documents selected</h3><p>Select documents in the table above to view</p>`;
  } else {
    return null;
  }
};

/**
 * For User with Annotated Result Viewers (e.g. NGS, Comparisons).
 */
export const annotatedResultViewerOverlays: ViewersOverlays = (data, numberOfDisplayedViewers) => {
  if (data?.selection?.totalSelected > 0 && numberOfDisplayedViewers === 0) {
    return `<h3>Invalid selection</h3><p>Try selecting a different row/s</p>`;
  } else if (numberOfDisplayedViewers === 0) {
    return `<h3>No rows selected</h3><p>Select rows in the document table above to view</p>`;
  } else {
    return null;
  }
};
