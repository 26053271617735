import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { CleanUp } from 'src/app/shared/cleanup';
import { rawValueChanges, restrictControlValue, setEnabled } from 'src/app/shared/utils/forms';
import { AppState } from '../../core.store';
import { NameSchemeField } from '../../models/settings/name-scheme-field.model';
import { SelectOption } from '../../models/ui/select-option.model';
import {
  orgSettingsToSelectOptions,
  selectNameSchemes,
} from '../../organization-settings/organization-settings.selectors';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';

type SavedNameScheme = {
  name: string;
  id: string;
  includesChain: boolean;
  deleted: boolean;
};

@Component({
  selector: 'bx-name-scheme-select',
  standalone: true,
  imports: [
    CommonModule,
    FormErrorsComponent,
    NgFormControlValidatorDirective,
    MatIconModule,
    NgbTooltipModule,
    ReactiveFormsModule,
  ],
  templateUrl: './name-scheme-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameSchemeSelectComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'row';
  /** The form group containing the name scheme control */
  @Input() parent: FormGroup;
  /** The name of the form control to bind to the select dropdown */
  @Input() controlName: string;
  /** Name schemes associated with the input documents */
  @Input() associatedNameSchemes: Set<string>;
  /** The classes that set the column widths of the label and the control. */
  @Input() colClasses = { label: 'col-3', control: 'col-9' };

  /** The name scheme control */
  control: FormControl<string>;
  /** Name scheme options */
  options$: Observable<SelectOption[]>;
  /** Info message regarding name schemes */
  info$: Observable<string | null>;
  /** Warning message regarding name schemes */
  warning$: Observable<string | null>;
  /** Emits the name scheme associated with the input documents (if present) */
  nameSchemeOverride$: Observable<SavedNameScheme | null>;

  constructor(private readonly store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.control = this.parent.get(this.controlName) as FormControl<string>;
    if (!this.control) {
      throw new Error('Failed to get name scheme control with name ' + this.controlName);
    }

    const nameSchemes$ = this.store.select(selectNameSchemes).pipe(
      filter((nameSchemes) => nameSchemes != null),
      take(1),
      takeUntil(this.ngUnsubscribe),
    );
    this.options$ = nameSchemes$.pipe(
      map((nameSchemes) => orgSettingsToSelectOptions(nameSchemes)),
      tap((options) => {
        restrictControlValue(
          this.control,
          options.map((option) => option.value),
          {
            defaultValue: null,
            takeUntil: this.ngUnsubscribe,
          },
        );
      }),
    );

    const associatedNameSchemes$: Observable<SavedNameScheme[]> = nameSchemes$.pipe(
      take(1),
      map((nameSchemes) =>
        Array.from(this.associatedNameSchemes, (id) => {
          const matchingScheme = nameSchemes.find((nameScheme) => nameScheme.id === id);
          if (!matchingScheme) {
            return { id, name: 'a deleted name scheme', includesChain: false, deleted: true };
          }
          const includesChain = (matchingScheme.data.fields as NameSchemeField[]).some(
            (field) => field.classification === 'chain',
          );
          return {
            id,
            name: matchingScheme.name,
            includesChain,
            deleted: false,
          };
        }),
      ),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.nameSchemeOverride$ = associatedNameSchemes$.pipe(
      map((associatedNameSchemes) => {
        if (associatedNameSchemes.length !== 1 || associatedNameSchemes[0].deleted) {
          return null;
        }
        return associatedNameSchemes[0];
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    this.info$ = this.nameSchemeOverride$.pipe(
      map((associatedNameScheme) => {
        if (associatedNameScheme == null) {
          return null;
        }
        if (associatedNameScheme.includesChain) {
          return 'This name scheme has a chain classification field. Select the "Both chains in associated sequences" option to pair heavy and light chains.';
        }
        return 'Found name scheme associated with the document selection.';
      }),
    );

    this.warning$ = associatedNameSchemes$.pipe(
      map((associatedNameSchemes) => {
        if (associatedNameSchemes.length < 1) {
          // The documents selected have no name schemes associated with them
          return null;
        }
        if (associatedNameSchemes.length > 1) {
          // The documents selected have more than 1 unique name scheme associated with them.
          const savedSchemeNames = associatedNameSchemes.map((scheme) => scheme.name).join(', ');
          return `The selected documents have multiple associated name schemes (${savedSchemeNames})`;
        }
        // The documents selected have 1 unique name scheme associated with them.
        const associatedScheme = associatedNameSchemes[0];
        if (associatedScheme.deleted) {
          // Saved scheme no longer exists
          return 'The name scheme associated with the document selection no longer exists. You may select another.';
        }
        return null;
      }),
    );

    // Side-effects for form control values relating to name schemes
    this.nameSchemeOverride$
      .pipe(
        take(1),
        filter((nameScheme) => nameScheme != null),
      )
      .subscribe((nameScheme) => {
        // Force the user to use the associated name scheme
        this.control.setValue(nameScheme.id);
        // Despite disabling the name scheme control below, its value can be set by applying an options profile
        // in this case, we enable the name scheme control and mark it invalid
        this.control.addValidators((ctrl) => {
          if (ctrl.value === nameScheme.id) {
            return null;
          }
          return {
            wrongNameScheme: `The applied profile has a name scheme that differs from the one associated with the document selection (${nameScheme.name}).`,
          };
        });
        // When a control is disabled, validators do not run and valueChanges events are not emitted
        // However, its value can still be changed programmatically, which occurs when a profile is applied
        // We want to prevent users from using a different name scheme than the associated one
        // Therefore, we watch for changes via the form's raw value and enable the control when its value
        // is changed. Then the above validator can run and display an error.
        rawValueChanges(
          this.parent,
          (formValue) => formValue[this.controlName] as string,
          this.ngUnsubscribe,
        ).subscribe((selectedNameSchemeID) =>
          setEnabled(this.control, selectedNameSchemeID !== nameScheme.id),
        );
      });
  }

  /**
   * Sets the name scheme control to the specified value. This is called by the
   * template to reset the control value to the associated name scheme.
   *
   * @param nameSchemeID the name scheme ID to set on the control
   */
  resetNameScheme(nameSchemeID: string) {
    this.control.setValue(nameSchemeID);
  }
}
