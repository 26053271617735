<form [formGroup]="form">
  @for (section of layout.sections; track section) {
    <div class="card mb-3">
      @if (section.collapse === undefined) {
        <div class="card-header px-3">
          <h5 class="mb-0">{{ section.title }}</h5>
        </div>
      } @else {
        <div class="card-header px-3" (click)="toggle(section.title)">
          <fa-icon
            class="ms-2 me-3"
            [icon]="faChevronRight"
            [rotate]="isCollapsed(section.title) ? undefined : 90"
          ></fa-icon>
          <h5 class="mb-0 d-inline">{{ section.title }}</h5>
        </div>
      }
      <div class="card-body pb-0" [ngbCollapse]="section.collapse && isCollapsed(section.title)">
        @for (row of section.rows; track row) {
          <div class="mb-3 row">
            @for (element of row.elements; track element) {
              @if (element.type === type.LABEL_WITH_CHECKBOX) {
                <div
                  [ngClass]="'col-' + (element.size || 'auto')"
                  class="d-flex align-items-center"
                >
                  <div class="form-check form-check-inline">
                    <input
                      type="checkbox"
                      [attr.id]="element.id"
                      formControlName="{{ element.formControlName || element.id }}"
                      class="form-check-input"
                    />
                    <label
                      class="form-check-label"
                      [for]="element.id"
                      ngbTooltip="{{ element.tooltip }}"
                    >
                      {{ element.label }}
                    </label>
                  </div>
                </div>
              }
              @if (element.type === type.LABEL) {
                <div [attr.class]="'col-' + (element.size || 'auto')">
                  <label
                    class="col-form-label"
                    [for]="element.id"
                    ngbTooltip="{{ element.tooltip }}"
                  >
                    {{ element.label }}
                  </label>
                </div>
              }
              @if (element.type === type.RADIO_GROUP) {
                <label
                  [ngClass]="'col-' + (element.size || 'auto')"
                  class="col-form-label"
                  [for]="element.id"
                  ngbTooltip="{{ element.tooltip }}"
                >
                  {{ element.label }}
                </label>
                <div class="col">
                  @for (option of element.value; track option) {
                    <div class="form-check mb-1">
                      <input
                        formControlName="{{ element.formControlName || element.id }}"
                        type="radio"
                        class="form-check-input"
                        [id]="option.id"
                        [value]="option.value"
                      />
                      <label
                        [for]="option.id"
                        class="form-check-label"
                        [ngbTooltip]="option.tooltip"
                        [disableTooltip]="!option.tooltip"
                      >
                        {{ option.label }}
                      </label>
                    </div>
                  }
                </div>
              }
              @if (element.type === type.TEXT) {
                <div [attr.class]="'px-0 col-form-label  col-' + (element.size || 'auto')">
                  {{ element.value }}
                </div>
              }
              @if (element.type === type.SELECT) {
                <div [attr.class]="'col-' + (element.size || 'auto')">
                  <select
                    [attr.id]="element.id"
                    formControlName="{{ element.formControlName || element.id }}"
                    class="form-select"
                  >
                    @for (option of element.value; track option) {
                      <option [value]="option.value">
                        {{ option.label }}
                      </option>
                    }
                  </select>
                </div>
              }
              @if (element.type === type.NUMBER_INPUT) {
                <div [class]="'col-' + (element.size || 'auto')">
                  @if (element.append) {
                    <div class="input-group">
                      <input
                        type="number"
                        [attr.id]="element.id"
                        formControlName="{{ element.formControlName || element.id }}"
                        ngFormControlValidator
                        class="form-control"
                      />
                      <span class="input-group-text">{{ element.append }}</span>
                    </div>
                  } @else {
                    <input
                      type="number"
                      [attr.id]="element.id"
                      formControlName="{{ element.formControlName || element.id }}"
                      ngFormControlValidator
                      class="form-control"
                    />
                  }
                </div>
              }
              @if (element.type === type.TEXT_INPUT) {
                <div [attr.class]="'col-' + (element.size || 'auto')">
                  <input
                    type="text"
                    [attr.id]="element.id"
                    formControlName="{{ element.formControlName || element.id }}"
                    ngFormControlValidator
                    class="form-control"
                    [placeholder]="element.placeholder"
                  />
                </div>
              }
            }
          </div>
        }
        @if (section.title === 'Output Options') {
          <bx-pipeline-output
            [outputNameFormControl]="form.get('outputFolderName')"
            class="d-block mb-3"
          ></bx-pipeline-output>
        }
      </div>
    </div>
  }
</form>
