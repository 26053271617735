import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class SingleCellAntibodyAnalysisJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'single-cell-antibody-analysis',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: Record<string, unknown>;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: Record<string, unknown>,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}
