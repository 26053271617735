import { Component, ChangeDetectionStrategy, HostListener, HostBinding } from '@angular/core';
import { CursorMessageService } from './cursor-message.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Point2D } from '../interfaces';
import { CleanUp } from '../cleanup';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-cursor-message-container',
  templateUrl: './cursor-message-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class CursorMessageContainerComponent extends CleanUp {
  @HostBinding('class') readonly hostClass = 'fixed-top';

  readonly message$ = this.cursorMessageService.getMessage().pipe(takeUntil(this.ngUnsubscribe));
  readonly cursorPosition$ = this.completeOnDestroy(new BehaviorSubject<Point2D>({ x: 0, y: 0 }));
  readonly transformStyle$: Observable<string> = this.cursorPosition$.pipe(
    map(({ x, y }) => `translate(${x - 5}px, ${y + 10}px)`),
  );

  constructor(public cursorMessageService: CursorMessageService) {
    super();
  }

  @HostListener('document:pointerdown')
  onGlobalMouseDown() {
    this.cursorMessageService.setAllowMessageDisplay(true);
  }

  @HostListener('document:pointerup')
  @HostListener('document:mouseup')
  @HostListener('document:dragend')
  onGlobalMouseUp() {
    this.cursorMessageService.clear();
    this.cursorMessageService.setAllowMessageDisplay(false);
  }

  @HostListener('document:pointermove', ['$event'])
  @HostListener('document:dragover', ['$event'])
  onGlobalMouseMove(event: MouseEvent) {
    this.cursorPosition$.next({ x: event.clientX, y: event.clientY });
  }
}
