import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MetadataColumnOrGroup, RemoveSvOption } from './sequence-viewer.interfaces';
import { SequenceTopology, SequenceViewerService } from './sequence-viewer.service';
import { BehaviorSubject, Observable, of, startWith, Subject } from 'rxjs';
import { catchError, exhaustMap, map, takeUntil, tap } from 'rxjs/operators';
import { CleanUp } from '../../shared/cleanup';
import { ContextMenuItem, ContextMenuComponent } from './context-menu/context-menu.component';
import { DialogService } from '../../shared/dialog/dialog.service';
import { EditSequenceDialogComponent } from './edit-sequence-dialog/edit-sequence-dialog.component';
import { RRange, RenderNodeData, SequenceData, SequenceWrapper } from '@geneious/sequence-viewer';
import { AudioService } from 'src/app/shared/audio/audio.service';
import Range from '@geneious/sequence-viewer/includes/Range/Range';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SequenceViewerDirective } from './sequence-viewer.directive';
import { OptionsPanelComponent } from './options-panel/options-panel.component';

/**
 * Angular component for the Sequence Viewer UI.
 *
 * Responsible for;
 * - Form controls;
 *   - Translations-enabled checkbox and;v
 *     - Translation frame select menu
 *     - Translation table select menu
 *   - Annotations-enabled checkbox and;
 *     - checkboxes per annotation type
 *   - Quality-enabled checkbox
 *   - Zoom controls;
 *     - Zoom in
 *     - Zoom out
 *     - Zoom to residues
 *     - Show all
 *   - Sequence viewer (delegated on to sequence-viewer.directive.ts
 */
@Component({
  selector: 'bx-sequence-viewer',
  templateUrl: './sequence-viewer.component.html',
  providers: [SequenceViewerService, AudioService],
  standalone: true,
  imports: [
    NgbTooltip,
    FaIconComponent,
    SequenceViewerDirective,
    OptionsPanelComponent,
    ContextMenuComponent,
    AsyncPipe,
  ],
})
export class SequenceViewerComponent extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';
  @Input() sequences: SequenceData[];
  @Input() trees: string[];
  @Input() consensus: any;
  @Input() reference: number;
  @Input() metadataColumns: MetadataColumnOrGroup[];
  @Input() metadataColumnOrder: string[];
  @Input() documentsType: string | null = null;
  @Input() sequenceTopology: SequenceTopology = 'linear';
  @Input() helpLink: string | null = null;
  @Input() readonly = false;
  @Input() isInEditMode = false;
  @Input() sortingEnabled = false;
  @Input() optionChangedSideBar = {};
  @Input() optionChangedSV = {};
  @Input() savedOptionsRemove = {};
  @Input() savedOptions = {};
  @Input() folderId: string;
  @Input() isSequenceSelectionEnabled: boolean;
  @Input() isCircularModeEnabled: boolean = false;
  @Input() toolbarWarningMsg?: string;

  @Output() sequencesSelectionChanged: EventEmitter<number[]> = new EventEmitter();
  @Output() optionChanged: EventEmitter<Object> = new EventEmitter();
  @Output() optionRemoved: EventEmitter<RemoveSvOption> = new EventEmitter();
  @Output() liabilityThresholdChanged: EventEmitter<{
    liabilityScoreLow: number;
    liabilityScoreHigh: number;
  }> = new EventEmitter();

  @Output() sequenceEditingCanceled: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('sequenceViewer') sequenceViewer: ElementRef;

  searchString = '';

  contextMenuPosition = {
    x: 0,
    y: 0,
  };

  contextMenuItems$: Observable<ContextMenuItem[]>;
  contextMenuVisible$: Observable<boolean>;
  sequenceUnit: 'Base' | 'Residue';
  readonly keydownEvent$ = new Subject<KeyboardEvent>();
  readonly contextMenuData$ = new BehaviorSubject<{ node: RenderNodeData } | null>(null);
  /** Used to filter keypress events in {@link handleKeyDown} */
  readonly validKeys = /^([a-zA-Z]|ArrowLeft|ArrowRight|Backspace|Delete)$/;
  readonly warningIcon = faExclamationTriangle;

  constructor(
    private ng: ChangeDetectorRef,
    private sequenceViewerService: SequenceViewerService,
    private dialogService: DialogService,
    private readonly audioService: AudioService,
  ) {
    super();
  }

  ngOnInit() {
    this.sequenceViewerService.editDialogEvents$
      .pipe(
        // exhaustMap ignores triggered dialogs while there is already one open
        exhaustMap(({ kind, data }) => {
          let openDialog$: Observable<unknown>;
          if (kind === 'insert') {
            openDialog$ = this.getInsertDialog(data?.defaultSequence);
          } else if (kind === 'replace') {
            openDialog$ = this.getReplaceDialog(data?.defaultSequence);
          } else if (kind === 'delete') {
            openDialog$ = this.getDeleteConfirmationDialog();
          } else {
            throw new Error('Unrecognized edit dialog kind: ' + kind);
          }
          return openDialog$.pipe(catchError((_dismissReason) => of(null)));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.sequenceViewerService.initialize(
      this.sequences,
      this.documentsType,
      this.trees,
      this.sortingEnabled,
      this.isCircularModeEnabled,
      this.sequenceTopology,
    );
    this.sequenceViewerService.sequenceViewerState = {
      reference: this.reference,
      documentsType: this.documentsType,
    };

    this.keydownEvent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!this.isInEditMode || event.altKey) {
        return;
      }

      if (event.key.toLowerCase() === 'z' && (event.ctrlKey || event.metaKey)) {
        if (event.shiftKey) {
          this.sequenceViewerService.redo();
        } else {
          this.sequenceViewerService.undo();
        }
        return;
      }
      // We're done handling ctrl/cmd key modifiers
      if (event.metaKey || event.ctrlKey) {
        return;
      }

      const selection = this.sequenceViewerService.selection.current;
      const hasCursor = selection.length > 0;
      const hasSelection = selection.toString().length > 0;
      // Everything below this point expects a cursor
      if (!hasCursor) {
        this.audioService.softBeep();
        return;
      }
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const selectionShift = event.key === 'ArrowRight' ? 1 : -1;
        if (event.shiftKey) {
          this.transformSelectionRange((r) => r.growAt(selectionShift, true));
        } else {
          this.transformSelectionRange((r) => r.shift(selectionShift));
        }
        return;
      }
      if (event.key === 'Backspace' || event.key === 'Delete') {
        if (!hasSelection) {
          // Select the next base on Delete, or the previous base on Backspace
          this.transformSelectionRange((r) => r.growAt(1, event.key === 'Delete'));
        }
        this.sequenceViewerService.requestEditDialog('delete');
        return;
      }
      if (hasCursor && !hasSelection) {
        this.sequenceViewerService.requestEditDialog('insert');
      } else if (hasSelection) {
        this.sequenceViewerService.requestEditDialog('replace');
      }
    });

    this.contextMenuItems$ = this.contextMenuData$.pipe(
      map((data) => {
        if (!data || !this.isInEditMode) {
          return [];
        }
        const { node } = data;
        const items: ContextMenuItem[] = [];

        // Only show context menu when users right-clicked on the sequences
        if (node.type === 'sequence channel') {
          const selection = this.sequenceViewerService.selection.current;
          const hasSelection = selection.toString().length > 0;

          if (hasSelection) {
            items.push({
              label: `Replace...`,
              action: () => this.sequenceViewerService.requestEditDialog('replace'),
            });
            items.push({
              label: `Delete...`,
              action: () => this.sequenceViewerService.requestEditDialog('delete'),
            });
          } else {
            items.push({
              label: `Insert...`,
              action: () => this.sequenceViewerService.requestEditDialog('insert'),
            });
          }
        }

        return items;
      }),
    );

    this.contextMenuVisible$ = this.contextMenuItems$.pipe(
      map((items) => items.length > 0),
      startWith(false),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.sequenceViewerService.cleanup();
  }

  liabilityScoreThresholdChanged() {
    const thresholds = {
      liabilityScoreLow: this.sequenceViewerService.liabilityScoreLow,
      liabilityScoreHigh: this.sequenceViewerService.liabilityScoreHigh,
    };
    this.liabilityThresholdChanged.emit(thresholds);
  }

  onNewAPI(sv: any) {
    this.sequenceViewerService.newApi(sv);
    this.sequenceUnit = this.sequenceViewerService.isDNA ? 'Base' : 'Residue';
    sv.bind('ready', () => {
      if (
        !SequenceViewerService.isAlignmentDocument(this.documentsType) ||
        (this.sequenceViewerService.annotations.filter == 'reference' &&
          this.sequenceViewerService.referenceSequenceIndex == null)
      ) {
        this.sequenceViewerService.annotations.filter = 'all';
      }
      // Force Change Detection as the inputs are reliant on it whenever the SV API changes.
      this.ng.detectChanges();
    });

    sv.bind('context menu', (event: any) => {
      this.contextMenuPosition = event.position;

      const selectedSequenceWrapper =
        sv.selection.current.length > 0 ? sv.selection.current[0].wrappers[0] : null;
      const rightClickedSequenceWrapper = (event.renderNode.reference as any).wrapper;

      // If users right-clicked on a sequence different from the selected sequence.
      if (selectedSequenceWrapper !== rightClickedSequenceWrapper) {
        this.clearSelection();
      }

      this.contextMenuData$.next({ node: event.renderNode });
    });

    sv.bind('sequences selection changed', () => {
      const indices = sv.sequenceSelection.selectedSequences.map(
        (sequence: SequenceWrapper) => sequence.originalIndex,
      );
      this.sequencesSelectionChanged.emit(indices);
    });
  }

  @HostListener('document:click')
  handleClick() {
    this.contextMenuData$.next(null);
  }

  get selectionAnchor() {
    const selection = this.sequenceViewerService.selection;
    return selection && selection.anchor;
  }

  get selectionSequenceIndex(): number | undefined {
    if (!this.isSequenceSelectionEnabled) {
      const selection = this.sequenceViewerService.selection;
      return (selection?.anchorWrapper as SequenceWrapper)?.originalIndex;
    }
    return this.sequenceViewerService.sequenceSelection?.selectedSequences[0]?.originalIndex;
  }

  previous() {
    this.sequenceViewerService.search.previous(this.searchString);
  }

  next() {
    this.sequenceViewerService.search.next(this.searchString);
  }

  searchKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        this.previous();
      } else {
        this.next();
      }
    }
  }

  mouseClick() {
    this.updateView();
  }

  zoomIn() {
    this.sequenceViewerService.zoomIn();
  }

  zoomOut() {
    this.sequenceViewerService.zoomOut();
  }

  zoomToShowResidues() {
    this.sequenceViewerService.zoomToShowResidues();
  }

  zoomToShowAll() {
    this.sequenceViewerService.zoomToShowAll();
  }

  clearSelection() {
    this.sequenceViewerService.selection.clear();
  }

  get selectReferenceEditable(): boolean {
    // Hide the button for non-alignments.
    return this.sequenceViewerService.showAlignmentControls;
  }

  get selectReferenceButtonEnabled(): boolean {
    // Use sequence selection plugin for reference setting if it's enabled.
    if (this.isSequenceSelectionEnabled) {
      return this.sequenceViewerService.sequenceSelection?.selectedSequences.length === 1;
    }

    if (this.selectionAnchor == null) {
      // Disable the button when there is no anchor.
      return false;
    }

    // Disable editing when the selection/anchor is on the Consensus channel.
    return this.sequenceViewerService.selection.anchorWrapper.type === 'sequence';
  }

  get selectReferenceButtonTooltip(): string {
    if (this.selectReferenceButtonEnabled) {
      return '';
    }
    return this.sequenceViewerService.sequenceSelection?.selectedSequences.length !== 1
      ? 'Please select one sequence to set as reference'
      : '';
  }

  selectReferenceButtonClicked(): void {
    if (this.sequenceViewerService.referenceSequenceIndex === this.selectionSequenceIndex) {
      // Unset reference.
      this.sequenceViewerService.unsetReferenceSequence();
      this.sequenceViewerService.highlighting.useConsensus = true;
      this.sequenceViewerService.annotations.filter = 'all';

      this.optionRemoved.emit({
        path: [],
        key: 'reference',
        logError: false,
      });
    } else {
      // Set new reference.
      this.sequenceViewerService.setReferenceSequence(this.selectionSequenceIndex);
      this.sequenceViewerService.highlighting.useConsensus = false;

      this.optionChanged.emit({ reference: this.selectionSequenceIndex });
    }
  }

  get selectReferenceButtonText() {
    if (
      this.selectionSequenceIndex != null &&
      this.sequenceViewerService.referenceSequenceIndex === this.selectionSequenceIndex
    ) {
      return 'Unset reference';
    } else {
      return 'Set as reference';
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement ||
      !this.validKeys.test(event.key)
    ) {
      return;
    }
    this.keydownEvent$.next(event);
  }

  @HostListener('document:paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    if (!this.isInEditMode) {
      return;
    }

    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
      return;
    }

    event.preventDefault();

    const selection = this.sequenceViewerService.selection.current;
    const hasCursor = selection.length > 0;
    const hasSelection = selection.toString().length > 0;

    const defaultSequence = event.clipboardData.getData('text');

    if (hasCursor && !hasSelection) {
      this.sequenceViewerService.requestEditDialog('insert', { defaultSequence });
    } else if (hasSelection) {
      this.sequenceViewerService.requestEditDialog('replace', { defaultSequence });
    }
  }

  private updateView() {
    // NOTE: this.ng.detectChanges() will cause BX-1391 where the view has been destroyed
    // but we still try to detect changes.
    this.ng.markForCheck();
  }

  private getSelection() {
    const selection = this.sequenceViewerService.selection;
    return selection.current[0];
  }

  /**
   * Edit the current selection range. Beeps if the new selection is invalid.
   * Silently exits if there is no selection.
   * @param transform the mapping function to apply to the selection
   */
  private transformSelectionRange(transform: (range: Range) => Range): void {
    const selection = this.getSelection();
    const sequenceWrapper = this.sequenceViewerService.selection.anchorWrapper as SequenceWrapper;
    if (!selection || sequenceWrapper.type !== 'sequence') {
      return;
    }
    try {
      const newRange = transform(selection.range);
      const bounds = new RRange(0, sequenceWrapper.sequenceCache.length);
      if (bounds.contains(newRange)) {
        this.sequenceViewerService.setSelection(newRange, sequenceWrapper);
        return;
      }
    } catch (error) {
      // Likely a reversed RRange - handled with the beep below
    }
    this.audioService.softBeep();
  }

  private getSelectedSequenceIndex(): number {
    const selection = this.sequenceViewerService.selection;
    if (selection.anchorWrapper && selection.anchorWrapper.type === 'sequence') {
      const sequenceWrapper = selection.anchorWrapper;
      return (sequenceWrapper as SequenceWrapper).originalIndex;
    }
    throw new Error('Selected sequence index not found. This should not happen.');
  }

  private getInsertDialog(defaultSequence?: string): Observable<unknown> {
    const range = this.getSelection()?.range;
    if (!range) {
      return of(null);
    }
    const sequenceIndex = this.getSelectedSequenceIndex();
    return this.dialogService
      .showDialogV2$({
        component: EditSequenceDialogComponent,
        injectableData: {
          sequenceViewerService: this.sequenceViewerService,
          editRange: { min: range.start, max: range.end },
          sequenceIndex,
          defaultSequence,
        },
      })
      .pipe(tap({ next: () => this.sequenceViewer.nativeElement.focus() }));
  }

  private getReplaceDialog(defaultSequence?: string): Observable<unknown> {
    const range = this.getSelection()?.range;
    if (!range) {
      return of(null);
    }
    const sequenceIndex = this.getSelectedSequenceIndex();
    return this.dialogService
      .showDialogV2$({
        component: EditSequenceDialogComponent,
        injectableData: {
          sequenceViewerService: this.sequenceViewerService,
          editRange: { min: range.start, max: range.end },
          sequenceIndex,
          defaultSequence,
        },
      })
      .pipe(tap({ next: () => this.sequenceViewer.nativeElement.focus() }));
  }

  private getDeleteConfirmationDialog(): Observable<unknown> {
    const selection = this.getSelection();
    const range = selection?.range;
    if (!range) {
      return of(null);
    }
    const sequenceIndex = this.getSelectedSequenceIndex();
    const sequence = selection.toString();
    const sequenceWrapper = this.sequenceViewerService.getSequenceWrapperAtIndex(sequenceIndex);
    const sequenceName = sequenceWrapper.name;

    return this.dialogService
      .showConfirmationDialog$({
        title: `Delete ${range.length} ${this.sequenceUnit}${range.length === 1 ? '' : 's'}?`,
        content: `The sequence ${sequence} will be deleted from ${sequenceName}`,
        confirmationButtonColor: 'danger',
        confirmationButtonText: 'Delete',
      })
      .pipe(
        tap({
          next: () => {
            this.sequenceViewerService.applyEdit({
              sequenceIndex,
              newResidues: '',
              min: range.start,
              max: range.end,
            });
            this.sequenceViewer.nativeElement.focus();
          },
        }),
      );
  }
}
