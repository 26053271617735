<div class="h-100 w-100 d-flex">
  @if (scatterplotData$ | async; as data) {
    <bx-scatterplot-chart
      class="scatterplot-chart"
      [ngStyle]="{ minWidth: 0 }"
      [title]="data.title"
      [subtitle]="data.subtitle"
      [xAxisTitle]="data.xAxisTitle"
      [yAxisTitle]="data.yAxisTitle"
      [showLegend]="false"
      [data]="data.scatterplotOptions"
    ></bx-scatterplot-chart>
  }
  @if (controls$ | async; as controls) {
    <bx-graph-sidebar
      class="controls"
      [controls]="controls"
      [hideControls]="false"
      (toggle)="onSidebarToggled()"
      (controlsChanged)="onControlsChanged($event)"
    >
    </bx-graph-sidebar>
  }
</div>
