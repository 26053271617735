<div class="d-flex w-100 align-items-center justify-content-between p-1 border-bottom">
  <div class="d-flex flex-nowrap">
    <div class="m-1 d-flex">
      <ng-content></ng-content>
    </div>

    @if (selectReferenceEditable && isInEditMode === false) {
      <button
        (click)="selectReferenceButtonClicked()"
        [disabled]="!selectReferenceButtonEnabled"
        [ngbTooltip]="selectReferenceButtonTooltip"
        placement="bottom"
        class="btn btn-light m-1 text-nowrap"
      >
        {{ selectReferenceButtonText }}
      </button>
    }
  </div>

  <div class="btn-group m-1">
    <button
      (click)="zoomIn()"
      ngbTooltip="Zoom in"
      placement="bottom"
      aria-label="Zoom in"
      class="btn btn-light text-dark"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path
          d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zM14 6h-4v4h-4v4h4v4h4v-4h4v-4h-4z"
        ></path>
      </svg>
    </button>

    <button
      (click)="zoomOut()"
      ngbTooltip="Zoom out"
      placement="bottom"
      aria-label="Zoom out"
      class="btn btn-light text-dark"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path
          d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zM6 10h12v4h-12z"
        ></path>
      </svg>
    </button>

    <button
      (click)="zoomToShowResidues()"
      ngbTooltip="Show residues/bases"
      placement="bottom"
      aria-label="Show residues/bases"
      class="btn btn-light text-dark"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path
          d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
        ></path>
        <path d="M13.305 4.871l-2.791-0.046-4.796 12.010 2.791 0.046 4.796-12.010z"></path>
        <path d="M10.513 4.825l2.791-0.046 4.796 12.010-2.791 0.046-4.796-12.010z"></path>
        <path d="M9.531 11.637h4.9v2.583h-4.9v-2.583z"></path>
      </svg>
    </button>

    <button
      (click)="zoomToShowAll()"
      ngbTooltip="Fit to screen"
      placement="bottom"
      aria-label="Fit to screen"
      class="btn btn-light text-dark"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path d="M32 0h-13l5 5-6 6 3 3 6-6 5 5z"></path>
        <path d="M32 32v-13l-5 5-6-6-3 3 6 6-5 5z"></path>
        <path d="M0 32h13l-5-5 6-6-3-3-6 6-5-5z"></path>
        <path d="M0 0v13l5-5 6 6 3-3-6-6 5-5z"></path>
      </svg>
    </button>
  </div>

  <div class="toolbar-group search m-1">
    @if (toolbarWarningMsg) {
      <div type="warning" class="alert alert-warning px-2 py-1 mb-0" role="alert">
        <small class="text-truncate mx-1">
          <fa-icon [icon]="warningIcon" class="me-1"></fa-icon>
          {{ toolbarWarningMsg }}
        </small>
      </div>
    } @else {
      <div class="px-5 mx-5"></div>
    }

    <!-- TODO: Re-enable once find feature is fixed -->
    <!--    <div class="input-group">-->
    <!--      <input-->
    <!--        placeholder="Find"-->
    <!--        (keyup)="searchKey($event)"-->
    <!--        class="form-control"-->
    <!--        [(ngModel)]="searchString"-->
    <!--      />-->
    <!--      <button class="btn  btn-light" (click)="previous()">-->
    <!--        <fa-icon [icon]="'chevron-left'"></fa-icon>-->
    <!--      </button>-->
    <!--      <button class="btn  btn-light" (click)="next()">-->
    <!--        <fa-icon [icon]="'chevron-right'"></fa-icon>-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</div>

@if (sequences) {
  <div class="d-flex flex-grow-1 flex-shrink-1 overflow-auto position-relative">
    <div
      id="sequence-viewer"
      class="d-flex flex-column flex-grow-1"
      #sequenceViewer
      tabindex="-1"
      bxSequenceViewer
      [sequences]="sequences"
      [trees]="trees"
      [consensus]="consensus"
      [reference]="reference"
      [metadataColumns]="metadataColumns"
      [metadataColumnOrder]="metadataColumnOrder"
      [documentsType]="documentsType"
      [savedOptions]="savedOptions"
      [enableSequenceSelection]="isSequenceSelectionEnabled"
      [circularModeEnabled]="isCircularModeEnabled"
      (newViewer)="onNewAPI($event)"
      (mousedown)="mouseClick()"
      (mouseup)="mouseClick()"
      (savedOptionsRemove)="optionRemoved.emit($event)"
      (optionChangedSV)="optionChanged.emit($event)"
    ></div>
    <!-- Pass through when a user changes a sidebar option to BX. -->
    <sv-options-panel
      (optionChangedSideBar)="optionChanged.emit($event)"
      (liabilityScoreThresholdChanged)="liabilityScoreThresholdChanged()"
      [metadataColumnOrder]="metadataColumnOrder"
      [circularModeEnabled]="isCircularModeEnabled"
      [isInEditingMode]="isInEditMode"
    >
    </sv-options-panel>
    @if (contextMenuVisible$ | async) {
      <bx-context-menu
        [items]="contextMenuItems$ | async"
        [left]="contextMenuPosition.x"
        [top]="contextMenuPosition.y"
      ></bx-context-menu>
    }
  </div>
}
