<div class="alert alert-info">Register {{ numberOfSequences }} selected sequences.</div>
<form [formGroup]="form">
  <bx-card headerTitle="Experiment Info">
    <div class="mb-3 row align-items-center">
      <label for="project" class="col-4 col-form-label">Project:</label>
      @if (projects$ | async; as projects) {
        <div class="col-7">
          @switch (projects.state) {
            @case (ItemState.RESOLVED) {
              <bx-select
                [selectOptions]="projects.options"
                id="project"
                formControlName="project"
              ></bx-select>
            }
            @case (ItemState.NO_OPTION) {
              <em>No project available to register your sequence to.</em>
            }
            @case (ItemState.LOADING) {
              <ng-template [ngTemplateOutlet]="loading"></ng-template>
            }
            @case (ItemState.ERROR) {
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            }
          }
        </div>
      }
    </div>
    <div class="mb-3 row align-items-center">
      <label for="target" class="col-4 col-form-label">Target:</label>
      @if (targets$ | async; as targets) {
        <div class="col-7">
          @switch (targets.state) {
            @case (ItemState.RESOLVED) {
              <bx-select
                [selectOptions]="targets.options"
                id="target"
                formControlName="target"
              ></bx-select>
            }
            @case (ItemState.NO_OPTION) {
              <em>No target available.</em>
            }
            @case (ItemState.LOADING) {
              <ng-template [ngTemplateOutlet]="loading"></ng-template>
            }
            @case (ItemState.ERROR) {
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            }
          }
        </div>
      }
    </div>
    <div class="mb-3 row align-items-center">
      <label for="species" class="col-4 col-form-label">Species:</label>
      @if (species$ | async; as species) {
        <div class="col-7">
          @switch (species.state) {
            @case (ItemState.RESOLVED) {
              <bx-select
                [selectOptions]="species.options"
                id="species"
                formControlName="species"
              ></bx-select>
            }
            @case (ItemState.NO_OPTION) {
              <em>No species available.</em>
            }
            @case (ItemState.LOADING) {
              <ng-template [ngTemplateOutlet]="loading"></ng-template>
            }
            @case (ItemState.ERROR) {
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            }
          }
        </div>
      }
    </div>
    <div class="row align-items-center">
      <label for="experimentId" class="col-4 col-form-label">Experiment ID:</label>
      <div class="col-7">
        <input class="form-control" id="experimentId" type="text" formControlName="experimentId" />
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Sequence Info">
    <div class="mb-3 row align-items-center">
      <label for="nameColumn" class="col-4 col-form-label">Name Column:</label>
      <div class="col-7">
        <bx-select
          [selectOptions]="nameColumns"
          id="nameColumn"
          formControlName="nameColumn"
        ></bx-select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label for="sampleIDColumn" class="col-4 col-form-label">Sample ID Column:</label>
      <div class="col-7">
        <bx-select
          [selectOptions]="sampleIdColumns"
          id="sampleIdColumn"
          formControlName="sampleIdColumn"
        ></bx-select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label for="heavyChainClonotypeColumn" class="col-4 col-form-label"
        >Heavy Chain Clonotype Column:</label
      >
      <div class="col-7">
        <bx-select
          [selectOptions]="heavyChainClonotypeColumns"
          id="heavyChainClonotypeColumn"
          formControlName="heavyChainClonotypeColumn"
        ></bx-select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label for="lightChainClonotypeColumn" class="col-4 col-form-label"
        >Light Chain Clonotype Column:</label
      >
      <div class="col-7">
        <bx-select
          [selectOptions]="lightChainClonotypeColumns"
          id="lightChainClonotypeColumn"
          formControlName="lightChainClonotypeColumn"
        ></bx-select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label class="col-4 col-form-label">Register As:</label>
      <div class="col-7 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="nucleotideOption"
            formControlName="sequenceType"
            name="sequenceType"
            value="nucleotide"
            class="form-check-input"
            [attr.disabled]="isProteinDocument || null"
          />
          <label for="nucleotideOption" class="form-check-label"> Nucleotide </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="proteinOption"
            formControlName="sequenceType"
            name="sequenceType"
            value="protein"
            class="form-check-input"
          />
          <label for="proteinOption" class="form-check-label"> Protein </label>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <label for="isotype" class="col-4 col-form-label">Isotype:</label>
      @if (isotypes$ | async; as isotypes) {
        <div class="col-7">
          @switch (isotypes.state) {
            @case (ItemState.RESOLVED) {
              <bx-multi-select
                class="flex-grow-1 flex-shrink-1"
                [selectOptions]="isotypes.options"
                formControlName="isotype"
                id="isotype"
                [showSelectionSummary]="false"
              ></bx-multi-select>
            }
            @case (ItemState.NO_OPTION) {
              <em>No isotype available.</em>
            }
            @case (ItemState.LOADING) {
              <ng-template [ngTemplateOutlet]="loading"></ng-template>
            }
            @case (ItemState.ERROR) {
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            }
          }
        </div>
      }
    </div>
  </bx-card>
</form>

<ng-template #loading>
  <bx-spinner></bx-spinner>
</ng-template>

<ng-template #error>
  <span class="text-muted">Error fetching data</span>
</ng-template>
