<bx-settings-breadcrumb
  [crumbs]="[
    { link: ['', 'name-schemes'], label: 'Name Schemes' },
    { link: ['', 'name-schemes/new'], label: 'new' },
  ]"
></bx-settings-breadcrumb>

<div class="form">
  <bx-name-scheme-editor (valid)="validityChanged($event)"></bx-name-scheme-editor>

  <mat-error>
    {{ errorMessage$ | async }}
  </mat-error>

  <div class="indent">
    <button
      mat-raised-button
      color="primary"
      [disabled]="creating || !valid"
      (click)="createScheme()"
      data-testid="name-scheme-create"
    >
      @if (creating) {
        <mat-spinner></mat-spinner>
      }
      Create
    </button>

    <button mat-button color="primary" (click)="cancel()">Cancel</button>
  </div>
</div>
