import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportStateEffects } from './report-store/report-state.effects';
import { reportStateReducer } from './report-store/report-state.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('reportState', reportStateReducer),
    EffectsModule.forFeature([ReportStateEffects]),
  ],
})
export class ReportModule {}
