<bx-breadcrumb [folder]="folder$ | async"></bx-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="newDatabaseFolderEvent$.next()"
      [disabled]="!(folder$ | async)?.hasWriteAccess()"
    >
      New Collection
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-page-message>
  <div>
    <h2>{{ (folder$ | async)?.name }}</h2>
    <p class="message">{{ 'Shared with your entire organization.' }}</p>
  </div>
</bx-page-message>
