<form [formGroup]="form">
  <bx-card headerTitle="Pair By">
    <div class="d-flex align-items-center mb-2">
      <div class="form-check form-check-inline me-3">
        <input
          type="radio"
          name="pairBy"
          formControlName="pairBy"
          id="interlaced"
          value="interlaced"
          class="form-check-input"
          [attr.disabled]="disableInterlacingSequences"
        />
        <label for="interlaced" class="form-check-label">
          Interlaced sequences within each list
        </label>
      </div>
      <i class="text-muted small">
        {{ disableInterlacingSequencesMessage }}
      </i>
    </div>

    <div class="d-flex align-items-center mb-2">
      <div class="form-check form-check-inline me-3">
        <input
          type="radio"
          name="pairBy"
          formControlName="pairBy"
          id="bySequenceList"
          value="bySequenceList"
          class="form-check-input"
          [attr.disabled]="disablePairsOfLists"
        />
        <label for="bySequenceList" class="form-check-label"> Pairs of lists </label>
      </div>
      <i class="text-muted small">
        {{ disablePairsOfListsMessage }}
      </i>
    </div>

    <div *bxShowIf="'nameSchemes'" class="mb-2">
      <div class="form-check mb-2">
        <input
          type="radio"
          name="pairBy"
          formControlName="pairBy"
          id="byNameScheme"
          value="byNameScheme"
          class="form-check-input"
          [attr.disabled]="disableNameScheme"
        />
        <label
          for="byNameScheme"
          ngbTooltip="Only name schemes with Common Identifier classification fields defined are available for use"
          class="form-check-label"
        >
          Name scheme:
          <a
            target="_blank"
            class="badge help-badge"
            href="https://help.geneiousbiologics.com/hc/en-us/articles/360044625292"
          >
            <mat-icon class="icon-small my-n2">help</mat-icon>
          </a>
        </label>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-sm col-lg-7">
          <select
            formControlName="fileNameSchemeID"
            id="nameScheme"
            class="form-select"
            ngFormControlValidator
          >
            @for (nameScheme of nameSchemesWithId$ | async; track nameScheme) {
              <option [value]="nameScheme.value">
                {{ nameScheme.displayName }}
              </option>
            }
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-1"></div>
        <div class="col-11">
          <span class="text-muted">
            {{ nameSchemeStatusMessage }}
          </span>
          @if (nameSchemeWarningMessage) {
            <small id="nameSchemeHelp" class="form-text text-muted">
              <fa-icon [icon]="exclamationIcon" class="text-warning"></fa-icon>
              {{ nameSchemeWarningMessage }}
            </small>
          }
        </div>
      </div>
    </div>

    <div class="form-check">
      <input
        type="radio"
        name="pairBy"
        formControlName="pairBy"
        id="byName"
        value="byName"
        class="form-check-input"
        [attr.disabled]="disableName"
      />
      <label for="byName" class="form-check-label">
        Match sequence names (for standalone sequences, or within each list) (slow)
      </label>
    </div>
  </bx-card>
  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output [outputNameFormControl]="form.get('outputFolderName')"></bx-pipeline-output>
  </bx-card>
</form>
