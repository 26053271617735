import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AppState } from '../../core.store';
import { Folder } from '../../folders/models/folder.model';
import { UrlQueryParamsService } from '../../router/url-query-params.service';
import { FolderDetail } from '../folder-detail';
import { spy } from 'src/bx-operators/log';
import { compareStrings } from 'src/app/shared/utils/object';
import { AsyncPipe } from '@angular/common';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { FilesTableComponent } from '../../files-table/files-table.component';

@Component({
  selector: 'bx-files-folder-detail',
  templateUrl: './files-folder-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BreadcrumbComponent, FilesTableComponent, AsyncPipe],
})
export class FilesFolderDetailComponent extends FolderDetail implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column flex-grow-1 flex-shrink-1 h-100';
  readonly tableType = 'files';
  /** Row IDs parsed from the URL on load and sent to the files table to select */
  rowIDsToSelect$: Observable<string[]>;
  /** The selected folder */
  folder$: Observable<Folder>;
  /** Selected row IDs sent from the files table  */
  readonly selectedRowIDs$ = new Subject<string[]>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    protected store: Store<AppState>,
    protected route: ActivatedRoute,
    private readonly urlQueryParamsService: UrlQueryParamsService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    const urlFileIDs$ = this.urlQueryParamsService
      .getFileIDsFromSelectRowID(this.route)
      .pipe(
        distinctUntilChanged(compareStrings(JSON.stringify)),
        takeUntil(this.destroy$),
        shareReplay(1),
      );

    /** Row IDs in the URL, converted back to long GUIDs. Sent to the files table as the initial selection state. */
    this.rowIDsToSelect$ = urlFileIDs$.pipe(take(1), startWith([]));

    // Listen for selection state changes, and apply them to the URL
    this.selectedRowIDs$
      .pipe(
        withLatestFrom(urlFileIDs$),
        // Prevent infinite loop
        filter(([selected, current]) => !this.rowIDsAreEqualIgnoreOrder(selected, current)),
        switchMap(([selected]) => this.urlQueryParamsService.setSelectRowID(this.route, selected)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.selectedRowIDs$.complete();
  }

  /**
   * Handles changes in selection in files table.
   * @param ids New selection of document IDs
   */
  onSelectRowIDsChanged(ids: string[]): void {
    this.selectedRowIDs$.next(ids);
  }

  private rowIDsAreEqualIgnoreOrder(ids: string[], urlIDs: string[]): boolean {
    if (ids.length !== urlIDs.length) {
      return false;
    }

    const sortedArray1: string[] = [...ids].sort();
    const sortedArray2: string[] = [...urlIDs].sort();
    return sortedArray1.every((value, index) => value === sortedArray2[index]);
  }
}
