import { ColorPaletteID, ColorPaletteKind } from '../../../core/color/color-palette.model';
import {
  TreeGraphColoringMetadataConfig,
  TreeGraphMetadataMap,
  TreeGraphMetadataMapValue,
} from '../graph-circular-tree/graph-circular-tree.model';
import { MetadataColumnAbomination } from '../../sequence-viewer-angular/sequence-viewer.interfaces';
import { ColorPaletteService } from '../../../core/color/color-palette.service';
import { PrimitiveMap } from '@geneious/sequence-viewer/types';

/**
 * This class specialise in extracting metadata from a provided tree & document.
 * Currently, the only reason why this exist is to avoid cyclic dependencies between
 * TreeGraphDatasource & CircularTreeHeatmapConfigComponent
 *
 */
export class TreeMetadataExtractor {
  /** Fields that should always be treated as categorical, even if they only contain numeric values. */
  private readonly CATEGORICAL_FIELDS = ['BX_Subcluster Ranking', 'BX_Unique'];

  constructor(
    private document: { sequences: Record<number, { metadata: PrimitiveMap }> },
    private sortedMetadataColumns: MetadataColumnAbomination[],
    private flatTree: { id: string }[],
    private readonly colorPaletteService = new ColorPaletteService(),
  ) {}

  getMetadataValues(coloringKey: string) {
    const result = new Map();
    this.flatTree.forEach((node) => {
      if (!node.id) {
        return;
      }
      result.set(node.id, this.getMetadataValue(node, coloringKey));
    });
    return result;
  }

  getColouringMetadataConfig(
    metadataKey: string,
    colourPalette: ColorPaletteID,
    metadataValuesMap: TreeGraphMetadataMap,
  ): TreeGraphColoringMetadataConfig {
    const metadataValues = [...metadataValuesMap.values()];
    const metadataIsCategorical =
      metadataKey?.endsWith('ID') ||
      this.CATEGORICAL_FIELDS.includes(metadataKey) ||
      metadataValues.some(({ value }) => value != null && typeof value !== 'number');

    const numUniqueMetadataValues = metadataIsCategorical
      ? new Set(metadataValues.map((value) => value.group)).size
      : null;
    // If the palette sent to the graph is invalid, it throws an error
    const colorPalette = this.colorPaletteService.validPalette(
      colourPalette,
      metadataIsCategorical
        ? { numCategories: numUniqueMetadataValues }
        : { kind: ColorPaletteKind.SEQUENTIAL },
    );

    const coloringMetadataName =
      this.sortedMetadataColumns.find((col) => col.name === metadataKey)?.label ?? metadataKey;

    return {
      name: coloringMetadataName,
      metadataField: metadataKey,
      values: metadataValuesMap,
      colorPalette,
      isCategorical: metadataIsCategorical,
      numGroups: numUniqueMetadataValues,
    };
  }

  getMetadataValue(node: { id: string }, metadataKey: string): TreeGraphMetadataMapValue {
    const sequenceData = this.document.sequences[parseInt(node.id)];
    const value = sequenceData.metadata[metadataKey];
    if (value === null || value === undefined) {
      return { group: null, value: null };
    }
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      return { group: numericValue, value: numericValue };
    }
    const stringValue = value.toString();
    if (metadataKey.endsWith('Gene')) {
      // To group gene metadata based on family, we need to trim off the gene suffix (after dash or semicolon)
      return { group: stringValue.split(/[-;]/)[0], value: stringValue };
    }
    return { group: stringValue, value: stringValue };
  }
}
