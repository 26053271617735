/**
 * Represents what an editor is currently in the process of doing
 */
export enum EditorState {
  IDLE,
  /**
   * The editor is currently creating an entity/object but has not yet sent a request to the server
   */
  CREATING,
  /**
   * The editor is current editing an entity/object but has not yet sent a request to the server
   */
  EDITING,
  /**
   * The editor has sent a request to the server and is awaiting a response/is processing the response
   */
  PROCESSING,
}
