import { ChangeDetectionStrategy, Component, Inject, OnInit, TrackByFunction } from '@angular/core';
import { VersionEnum } from '@geneious/nucleus-api-client';
import {
  Observable,
  catchError,
  map,
  of,
  startWith,
  takeUntil,
  switchMap,
  combineLatest,
} from 'rxjs';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { SelectionStateV2 } from 'src/app/features/grid/grid.component';
import { CursorDocumentQuery } from 'src/nucleus/services/documentService/document-service.v1.http';
import { DocumentTableType } from 'src/nucleus/services/documentService/document-table-type';
import { SelectionOptionsV1 } from 'src/nucleus/services/models/jobParameters.model';
import {
  RegisterSequencesLumaJobConfig,
  RegisterSequencesLumaOptions,
} from 'src/nucleus/services/models/registerSequencesLuma.model';
import { PipelineDialogData } from '..';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import {
  LumaAPIService,
  LumaApplicationVersion,
  LumaDataSource,
} from '../../luma/luma-api-service';
import { PIPELINE_DIALOG_DATA } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { SelectOption } from '../../models/ui/select-option.model';
import { shareReplay, take } from 'rxjs/operators';
import { currentValueAndChanges } from '../../../shared/utils/forms';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CardComponent } from '../../../shared/card/card.component';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';

const LoadingStatus = {
  LOADING: 'loading',
  ERROR: 'error',
  COMPLETE: 'complete',
  WAITING: 'waiting',
} as const;
type LoadingStatus = (typeof LoadingStatus)[keyof typeof LoadingStatus];

/**
 * JobDialogContent for registering sequences in Luma.
 */
@Component({
  selector: 'bx-register-sequences-luma',
  templateUrl: './register-sequences-luma.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    SpinnerComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class RegisterSequencesLumaComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  readonly title = 'Send Sequences to Luma';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup(
    {
      setName: new BxFormControl<string>(undefined),
      appVersionID: new FormControl<string | undefined>(undefined),
      dataSourceID: new FormControl<string | undefined>(undefined),
      overrideDestination: new FormControl<boolean>(false),
    },
    { validators: destinationValidator },
  );
  /** Template-bound variables for the banner text at the top of the dialog */
  readonly banner = {
    table: this.dialogData.otherVariables.table.displayName,
    numRows: this.dialogData.otherVariables.table.selection.totalSelected,
  };
  readonly trackByValue: TrackByFunction<SelectOption> = (_index, item) => item.value;

  private apps$: Observable<{ apps: LumaApplicationVersion[]; status: LoadingStatus }>;
  appsStatus$: Observable<LoadingStatus>;
  appOptions$: Observable<SelectOption[]>;

  private dataSources$: Observable<{ dataSources: LumaDataSource[]; status: LoadingStatus }>;
  dataSourcesStatus$: Observable<LoadingStatus>;
  dataSourceOptions$: Observable<SelectOption[]>;

  isLumaConfigExist$: Observable<boolean>;

  isLocalOrDevEnvironment = false;

  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    private readonly dialogData: PipelineDialogData<RegisterSequencesLumaDialogData>,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly lumaApiService: LumaAPIService,
  ) {
    super('register-sequences-luma', PipelineFormID.REGISTER_SEQUENCES_LUMA);

    this.isLocalOrDevEnvironment =
      this.appConfig.NUCLEUS_ENVIRONMENT === 'dev' ||
      this.appConfig.NUCLEUS_ENVIRONMENT === 'local';
  }

  ngOnInit(): void {
    this.formDefaults = this.form.getRawValue();

    this.lumaApiService.getConfig().subscribe((lumaConfig) => {
      if (!lumaConfig.lumaURL || !lumaConfig.lumaAPIKey) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });

    this.isLumaConfigExist$ = this.lumaApiService
      .getConfig()
      .pipe(map((config) => !!config.lumaURL && !!config.lumaAPIKey));

    if (this.isLocalOrDevEnvironment) {
      this.apps$ = this.lumaApiService.latestApplicationVersionsWithDrafts({}).pipe(
        map((apps) => ({ apps, status: 'complete' as const })),
        startWith({ apps: [], status: 'loading' as const }),
        catchError((error) => {
          console.error(error);
          return of({ apps: [], status: 'error' as const });
        }),
        shareReplay(1),
      );
    } else {
      this.apps$ = of({ apps: [], status: 'complete' });
    }

    this.appsStatus$ = this.apps$.pipe(
      map(({ status }) => status),
      takeUntil(this.ngUnsubscribe),
    );

    this.appOptions$ = this.apps$.pipe(
      map(({ apps }) =>
        apps.map(
          ({ name, version, state, applicationVersionId }) =>
            new SelectOption(
              `${name} v${version.version} (${state.toLowerCase()})`,
              applicationVersionId,
            ),
        ),
      ),
      takeUntil(this.ngUnsubscribe),
    );

    this.dataSources$ = currentValueAndChanges(this.form.controls.appVersionID).pipe(
      switchMap((applicationVersionId) => {
        // The default option when an application is not selected sets applicationVersionId to an
        // empty string rather than null or undefined. This is falsy, hence the boolean coercion.
        if (applicationVersionId) {
          return this.lumaApiService.dataSources({ applicationVersionId }).pipe(
            map((dataSources) => ({ dataSources, status: 'complete' as const })),
            startWith({ dataSources: [], status: 'loading' as const }),
            catchError((error) => {
              console.error(error);
              return of({ dataSources: [], status: 'error' as const });
            }),
          );
        }
        return of({ dataSources: [], status: 'waiting' as const });
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.dataSourceOptions$ = this.dataSources$.pipe(
      map(({ dataSources }) =>
        dataSources.map((source) => new SelectOption(source.name, source.id)),
      ),
    );
    this.dataSourcesStatus$ = this.dataSources$.pipe(
      map(({ status }) => status),
      takeUntil(this.ngUnsubscribe),
    );

    combineLatest([
      currentValueAndChanges(this.form.controls.overrideDestination),
      this.appsStatus$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([isOverrideDestination]) => {
        if (isOverrideDestination) {
          this.form.controls.appVersionID.enable();
          this.form.controls.dataSourceID.enable();
        } else {
          this.form.controls.appVersionID.setValue(undefined);
          this.form.controls.dataSourceID.setValue(undefined);
          this.form.controls.appVersionID.disable();
          this.form.controls.dataSourceID.disable();
        }
      });
  }

  run(): Observable<RegisterSequencesLumaJobConfig> {
    const { table, fileSelection } = this.dialogData.otherVariables;

    const isOverrideDestinationSpecified =
      this.isLocalOrDevEnvironment &&
      this.form.controls.overrideDestination.value &&
      this.form.controls.appVersionID.value &&
      this.form.controls.dataSourceID.value;

    const options: RegisterSequencesLumaOptions = {
      tableName: table.name,
      tableQuery: table.query,
      selection: {
        ids: table.selection.ids,
        selectAll: table.selection.selectAll,
      },
      setName: this.form.controls.setName.value,
    };

    if (isOverrideDestinationSpecified) {
      const appVersionID = this.form.controls.appVersionID.value;
      const dataSourceId = this.form.controls.dataSourceID.value;
      const appID$ = this.apps$.pipe(
        take(1),
        map(({ apps }) => {
          const app = apps.find((app) => app.applicationVersionId === appVersionID);
          return app.applicationId;
        }),
      );

      return appID$.pipe(
        map((appId) => ({
          pipeline: {
            name: 'register-sequences-luma',
            version: VersionEnum.Latest,
          },
          parameters: {
            options: {
              ...options,
              destination: {
                appId,
                dataSourceId,
              },
            },
            selection: fileSelection,
          },
        })),
      );
    }

    return of({
      pipeline: {
        name: 'register-sequences-luma',
        version: VersionEnum.Latest,
      },
      parameters: {
        options,
        selection: fileSelection,
      },
    });
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}

const destinationValidator = (control: AbstractControl): ValidationErrors | null => {
  const overrideDestination = control.get('overrideDestination');
  const appVersionID = control.get('appVersionID');
  const dataSourceID = control.get('dataSourceID');

  if (overrideDestination && !overrideDestination.value) {
    return null;
  }

  return appVersionID.value && dataSourceID.value
    ? null
    : { required: 'An override destination is required' };
};

export interface RegisterSequencesLumaDialogData {
  table: {
    name: string;
    displayName: string;
    type: DocumentTableType;
    query: CursorDocumentQuery;
    selection: SelectionStateV2;
  };
  fileSelection: SelectionOptionsV1;
}
