export const fieldTypes: string[] = [
  'Text',
  'Number',
  '96 well plate location',
  '384 well plate location',
  'True/false',
  'Date',
];

export const CLASSIFICATION_OPTIONS: { readonly label: string; readonly value: string }[] = [
  {
    label: 'Common Identifier',
    value: 'id',
  },
  {
    label: 'Chain',
    value: 'chain',
  },
  {
    label: 'Direction',
    value: 'direction',
  },
];
