import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
  Annotation,
  Direction,
  Sequence,
  SequenceType,
  SequenceWithAnnotations,
} from '@geneious/nucleus-api-client';
import { SequenceViewerService } from '../sequence-viewer.service';
import { GaalServerService } from '../../../core/GaalServer.service';
import { CleanUp } from '../../../shared/cleanup';
import { first, map, startWith, takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.store';
import { selectFileRevisionByID } from '../../../core/files-table/files-table-store/files-table.selectors';
import { SequenceDataInDocument } from '../../../core/sequence-viewer/sequence-selection.service';
import { InputAnnotationData, SequenceData } from '@geneious/sequence-viewer/types';
import { SequenceEditingConflictDialogComponent } from '../sequence-editing-conflict-dialog/sequence-editing-conflict-dialog.component';
import {
  faBackspace,
  faPen,
  faPlus,
  faRedo,
  faSave,
  faTimesCircle,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

export interface EditedSequences {
  [sequenceIndex: number]: SequenceWithAnnotations;
}

interface EditSequenceResponse {
  documentID: string;
  newDocumentID: string;
  revision: number;
}

@Component({
  selector: 'bx-sequence-editing-controls',
  templateUrl: './sequence-editing-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, FaIconComponent, SpinnerButtonComponent, AsyncPipe],
})
export class SequenceEditingControlsComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'h-100 d-block';

  @Input() readonly = false;
  @Input() enterEditModeInitially = false;

  @Output() enterEditMode: EventEmitter<void> = new EventEmitter<void>();
  @Output() enterSavingMode: EventEmitter<void> = new EventEmitter<void>();
  @Output() exitEditMode: EventEmitter<void> = new EventEmitter<void>();
  @Output() exitSavingMode: EventEmitter<void> = new EventEmitter<void>();

  readonly isSaving$ = new BehaviorSubject(false);
  readonly isInSequenceEditMode$ = new BehaviorSubject(false);
  readonly undoDisabled$: Observable<boolean>;
  readonly redoDisabled$: Observable<boolean>;
  readonly insertDisabled$: Observable<boolean>;
  readonly deleteDisabled$: Observable<boolean>;

  protected readonly icon = {
    edit: faPen,
    save: faSave,
    cancel: faTimesCircle,
    undo: faUndo,
    redo: faRedo,
    insert: faPlus,
    delete: faBackspace,
  } as const;

  sequenceEditTooltip: string;

  // Ideally, revisions should always be retrieved from store. However, without document reload, the store may have
  // the latest document revision (due to document polling), but the current state of SV is still of the previous revision,
  // causing the old state to override the latest state when a user with the SV state of the previous revision hit save.
  // Therefore, it's better to use a locally stored revisions that reload with the documents.
  private currentDocumentRevisions: Map<string, number>;

  constructor(
    private sequenceViewerService: SequenceViewerService<SequenceDataInDocument>,
    private gaalServerService: GaalServerService,
    private dialogService: DialogService,
    private store: Store<AppState>,
  ) {
    super();
    this.currentDocumentRevisions = new Map();
    this.sequenceEditTooltip = this.readonly ? 'Editing is not allowed on read-only sequences' : '';

    this.isInSequenceEditMode$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isInEditMode) => {
      if (isInEditMode) {
        this.enterEditMode.next();
      } else {
        this.exitEditMode.next();
      }
    });

    this.isSaving$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isSaving) => {
      if (isSaving) {
        this.enterSavingMode.next();
      } else {
        this.exitSavingMode.next();
      }
    });

    this.undoDisabled$ = this.sequenceViewerService.canUndo$.pipe(map((canUndo) => !canUndo));
    this.redoDisabled$ = this.sequenceViewerService.canRedo$.pipe(map((canRedo) => !canRedo));

    this.insertDisabled$ = this.sequenceViewerService.selection$.pipe(
      map((selection) => !selection || selection.length === 0),
      startWith(true),
      takeUntil(this.ngUnsubscribe),
    );
    this.deleteDisabled$ = this.sequenceViewerService.selection$.pipe(
      map((selection) => !selection || selection.toString().length === 0),
      startWith(true),
      takeUntil(this.ngUnsubscribe),
    );
  }

  ngOnInit() {
    if (this.enterEditModeInitially) {
      this.enterSequenceEditMode();
    }
    this.sequenceViewerService.sequences.forEach((sequence) => {
      this.store
        .select(selectFileRevisionByID(sequence.documentId))
        .pipe(
          first((revision) => !isNaN(revision)),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((revision) => {
          this.currentDocumentRevisions.set(sequence.documentId, revision);
        });
    });
  }

  enterSequenceEditMode() {
    this.isInSequenceEditMode$.next(true);
  }

  handleRedo() {
    this.sequenceViewerService.redo();
  }

  handleUndo() {
    this.sequenceViewerService.undo();
  }

  handleInsert() {
    const selection = this.sequenceViewerService.selection.current[0];
    if (!selection) {
      return;
    }
    if (selection.range.isEmpty) {
      this.sequenceViewerService.requestEditDialog('insert');
    } else {
      this.sequenceViewerService.requestEditDialog('replace');
    }
  }

  handleDelete() {
    this.sequenceViewerService.requestEditDialog('delete');
  }

  handleCancel() {
    if (this.sequenceViewerService.canUndo || this.sequenceViewerService.canRedo) {
      this.dialogService
        .showConfirmationDialog({
          title: `Are you sure you want to discard all your changes?`,
          content: 'All of your changes will be reverted.',
          confirmationButtonText: 'Discard Changes',
          confirmationButtonColor: 'danger',
        })
        .result.then(() => {
          this.sequenceViewerService.resetEdits();
          this.sequenceViewerService.clearEditHistory();
          this.isInSequenceEditMode$.next(false);
        })
        .catch(() => {
          /* Do nothing if people hit cancel */
        });
    } else {
      this.isInSequenceEditMode$.next(false);
    }
  }

  handleSave() {
    if (!this.sequenceViewerService.canUndo) {
      this.sequenceViewerService.clearEditHistory();
      this.isInSequenceEditMode$.next(false);
      return;
    }

    const saveRequests = this.buildSaveRequests();

    this.isSaving$.next(true);
    forkJoin(saveRequests).subscribe({
      next: (responses) => {
        this.isSaving$.next(false);
        this.isInSequenceEditMode$.next(false);
        this.sequenceViewerService.clearEditHistory();

        for (const response of responses) {
          this.currentDocumentRevisions.set(response.documentID, response.revision);
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.error?.code === 'REVISION_CONFLICT') {
          this.dialogService
            .showDialogV2({
              component: SequenceEditingConflictDialogComponent,
            })
            .result.then(() => this.isSaving$.next(false))
            .catch(() => this.isSaving$.next(false));
          return;
        }

        const errorMessage = err.error.message ? `${err.error.message}. ` : '';
        const error =
          err.error.code === 'INVALID_EDITS'
            ? `${errorMessage}Please adjust your changes or contact our customer support.`
            : `${errorMessage}Please try again later or contact our customer support.`;

        this.dialogService
          .showAlertDialog({
            title: 'Error While Saving Sequences',
            content: error,
            buttonText: 'Ok',
            buttonColor: 'primary',
          })
          .result.then(() => this.isSaving$.next(false))
          .catch(() => this.isSaving$.next(false));
      },
    });
  }

  /***
   * Build a list of requests to send to GAAL to save the edits for edited sequences.
   * @private
   */
  private buildSaveRequests(): Observable<EditSequenceResponse>[] {
    const editedSequences = this.sequenceViewerService.getEditedSequenceData();
    if (Object.keys(editedSequences).length === 0) {
      this.isInSequenceEditMode$.next(false);
      return;
    }
    /** Edited sequences grouped by document ID */
    const groupedEditedSequences: Map<string, SequenceDataInDocument[]> = new Map();

    for (const [indexKey, editedSequence] of Object.entries(editedSequences)) {
      const index = parseInt(indexKey);
      const { documentId, sequenceIndexInDocument } = this.sequenceViewerService.sequences[index];
      if (!groupedEditedSequences.has(documentId)) {
        groupedEditedSequences.set(documentId, []);
      }
      groupedEditedSequences.get(documentId).push({
        documentId,
        sequenceIndexInDocument,
        ...editedSequence,
      });
    }

    const savingRequests: Observable<EditSequenceResponse>[] = [];
    for (const [documentID, editedSequenceInfo] of groupedEditedSequences.entries()) {
      savingRequests.push(this.saveSequencesForDocument(documentID, editedSequenceInfo));
    }
    return savingRequests;
  }

  /***
   * For each of the edited sequence, collect all annotations & sequence data from the current
   * state of SV into a save request to send to GAAL server.
   *
   * @param documentID The ID of the edited document.
   * @param editedSequenceData the edited sequences of the provided document
   * @private
   */
  private saveSequencesForDocument(
    documentID: string,
    editedSequenceData: SequenceDataInDocument[],
  ): Observable<EditSequenceResponse> {
    const nucleusEditedSequences: EditedSequences = {};
    for (const sequenceData of editedSequenceData) {
      nucleusEditedSequences[sequenceData.sequenceIndexInDocument] = {
        sequence: this.mapSVSequenceToNucleusSequence(sequenceData.sequence),
        metadata: {}, // Send no metadata since the backend doesn't use it, but the types (in backend) requires it.
        annotations: sequenceData.annotations.map((a) =>
          this.mapSVAnnotationDataToNucleusAnnotation(a),
        ),
        annotationTracks: sequenceData.annotationTracks.map((track) => ({
          ...track,
          annotations: track.annotations.map((a) => this.mapSVAnnotationDataToNucleusAnnotation(a)),
        })),
      };
    }
    return this.saveSequenceEdits(documentID, nucleusEditedSequences).pipe(
      map((response) => ({
        documentID,
        newDocumentID: response.newDocumentId,
        revision: response.revision,
      })),
    );
  }

  private mapSVSequenceToNucleusSequence(sequence: SequenceData['sequence']): Sequence {
    const sequenceType =
      sequence.sequenceType === 'Nucleotide' ? SequenceType.Nucleotide : SequenceType.AminoAcid;
    const nucleusSequence: Sequence = {
      name: sequence.name,
      circular: sequence.circular,
      sequence: sequence.sequence.trim(),
      sequenceType,
    };
    if (sequence.pairInformation) {
      nucleusSequence.pairInformation = {
        mateIndex: sequence.pairInformation.mateIndex,
      };
    }
    if (sequence.qualities) {
      nucleusSequence.qualities = sequence.qualities;
    }
    if (sequence.chromatogram) {
      const chromatogram = sequence.chromatogram;
      nucleusSequence.chromatogram = {
        baseCallPositions: chromatogram.baseCallPositions,
        // These properties are optional in SV, but not Nucleus
        a: chromatogram.a ?? [],
        c: chromatogram.c ?? [],
        t: chromatogram.c ?? [],
        g: chromatogram.g ?? [],
      };
    }
    return nucleusSequence;
  }

  /**
   * Map SV Annotation Data to Nucleus Annotation.
   * @param annotationData the annotation data to transform
   * @private
   */
  private mapSVAnnotationDataToNucleusAnnotation(annotationData: InputAnnotationData): Annotation {
    // Nucleus Annotation doesn't contains id
    const { id, ...data } = annotationData;
    return {
      ...data,
      name: data.name as string,
      intervals: data.intervals.map((interval) => ({
        ...interval,
        direction: this.mapSVIntervalDirectionToBackendDirection(interval.direction as number),
        truncatedMax: !!interval.truncatedMax,
        truncatedMin: !!interval.truncatedMin,
      })),
      qualifiers: data.qualifiers ? data.qualifiers : [],
    };
  }

  /**
   * Map SV normalised interval direction to backend compatible direction.
   *
   * @param direction the direction value from SV (usually 0, 1, -1)
   * @private
   */
  private mapSVIntervalDirectionToBackendDirection(direction: number): Direction {
    if (direction < 0) {
      return Direction.Left;
    }
    if (direction === 0) {
      return Direction.Undirected;
    }
    return Direction.Right;
  }

  /**
   * Call to GAAL server to save the sequence edits
   *
   * @param documentID the ID of the document that was edited.
   * @param sequences an object containing sequences that was edited.
   * @private
   */
  private saveSequenceEdits(documentID: string, sequences: EditedSequences) {
    return this.gaalServerService.editSequencesInPlace(
      documentID,
      this.currentDocumentRevisions.get(documentID),
      sequences,
    );
  }
}
