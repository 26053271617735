import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, first, map } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { compareStrings } from 'src/app/shared/utils/object';
import { AppState } from '../../core.store';
import { selectReferenceDatabases } from '../../folders/store/folder.selectors';
import { CreateReferenceDbForm } from '../create-reference-db-dialog/create-reference-db-dialog.component';
import { DatabaseTypeEnum } from '../database-type';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';

/**
 * Presentation component for the form in the second step in the Create Reference
 * Database dialog.
 */
@Component({
  selector: 'bx-create-reference-db-format-form',
  templateUrl: './create-reference-db-format-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, AsyncPipe],
})
export class CreateReferenceDbFormatFormComponent extends CleanUp implements OnInit {
  @Input() form: CreateReferenceDbForm['controls']['format'];
  @Input() databaseType: DatabaseTypeEnum;

  readonly referenceDBs$ = this.store.select(selectReferenceDatabases).pipe(
    map((refDBs) =>
      refDBs
        .map(({ name, id }) => ({ name, value: id }))
        .sort((o1, o2) => o1.name.localeCompare(o2.name)),
    ),
    // The folder store is prone to re-emissions that sometimes clear the selected DB
    distinctUntilChanged(compareStrings((obj) => JSON.stringify(obj))),
  );
  /** For use in the template */
  readonly DatabaseTypeEnum = DatabaseTypeEnum;

  constructor(private readonly store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.referenceDBs$
      .pipe(first())
      .subscribe((refDBs) => this.form.controls.referenceDatabase.setValue(refDBs[0]?.value));
  }
}
