import { createReducer, on } from '@ngrx/store';
import { OrganizationSettingsState, SettingState } from '../models/settings/setting.model';
import {
  loadOrganizationSettings,
  clearOrganizationSettings,
  labelActions,
  nameSchemeActions,
  bioRegisterConfigurationActions,
  lumaConfigurationActions,
} from './organization-settings.actions';

export const initialState: OrganizationSettingsState = {
  settings: {},
  settingStates: {},
};
export const organizationSettingsReducer = createReducer(
  initialState,
  on(loadOrganizationSettings, (_, { settings }) => {
    const ids = Object.keys(settings);
    const settingStates: Record<string, SettingState> = {};
    ids.forEach((id) => {
      settingStates[id] = 'SAVED';
    });
    return {
      settings,
      settingStates,
    };
  }),
  on(clearOrganizationSettings, (_) => initialState),
  on(
    labelActions.create,
    nameSchemeActions.create,
    bioRegisterConfigurationActions.create,
    lumaConfigurationActions.create,
    (state, { setting }) => ({
      settings: {
        ...state.settings,
        [setting.id]: setting,
      },
      settingStates: {
        ...state.settingStates,
        [setting.id]: 'SAVING' as const,
      },
    }),
  ),
  on(
    labelActions.update,
    nameSchemeActions.update,
    bioRegisterConfigurationActions.update,
    lumaConfigurationActions.update,
    (state, { settingToUpdate }) => {
      const { [settingToUpdate.id]: _, ...settingStates } = state.settingStates;
      return {
        settings: {
          ...state.settings,
        },
        settingStates: {
          ...settingStates,
          [settingToUpdate.id]: 'UPDATING' as const,
        },
      };
    },
  ),
  on(
    labelActions.remove,
    nameSchemeActions.remove,
    bioRegisterConfigurationActions.remove,
    lumaConfigurationActions.remove,
    (state, { id }) => {
      const { [id]: _, ...settings } = state.settingStates;
      return {
        ...state,
        settingStates: {
          ...settings,
          [id]: 'DELETING' as const,
        },
      };
    },
  ),
  on(
    labelActions.createSuccess,
    nameSchemeActions.createSuccess,
    bioRegisterConfigurationActions.createSuccess,
    lumaConfigurationActions.createSuccess,
    (state, { oldId, newId }) => {
      const { [oldId]: _, ...states } = state.settingStates;
      const { [oldId]: setting, ...settings } = state.settings;
      const { id, ...settingFields } = setting;
      return {
        settingStates: {
          ...states,
          [newId]: 'SAVED' as const,
        },
        settings: {
          ...settings,
          [newId]: {
            ...settingFields,
            id: newId,
          },
        },
      };
    },
  ),
  on(
    labelActions.updateSuccess,
    nameSchemeActions.updateSuccess,
    bioRegisterConfigurationActions.updateSuccess,
    lumaConfigurationActions.updateSuccess,
    (state, { updatedSetting }) => {
      const { [updatedSetting.id]: _, ...states } = state.settingStates;
      const { [updatedSetting.id]: outdated, ...settings } = state.settings;
      return {
        settingStates: {
          ...states,
          [updatedSetting.id]: 'SAVED' as const,
        },
        settings: {
          ...settings,
          [updatedSetting.id]: updatedSetting,
        },
      };
    },
  ),
  on(
    labelActions.createFail,
    nameSchemeActions.createFail,
    bioRegisterConfigurationActions.createFail,
    lumaConfigurationActions.createFail,
    (state, { failedSetting }) => {
      const { [failedSetting.id]: _state, ...settingStates } = state.settingStates;
      const { [failedSetting.id]: _setting, ...settings } = state.settings;
      return {
        settings,
        settingStates,
      };
    },
  ),
  on(
    labelActions.removeSuccess,
    nameSchemeActions.removeSuccess,
    bioRegisterConfigurationActions.removeSuccess,
    lumaConfigurationActions.removeSuccess,
    (state, { id }) => {
      const { [id]: _state, ...settingStates } = state.settingStates;
      const { [id]: _setting, ...settings } = state.settings;
      return {
        settings,
        settingStates,
      };
    },
  ),
  on(
    labelActions.removeFail,
    nameSchemeActions.removeFail,
    bioRegisterConfigurationActions.removeFail,
    lumaConfigurationActions.removeFail,
    (state, { id }) => {
      const { [id]: _, ...states } = state.settingStates;
      return {
        ...state,
        settingStates: {
          ...states,
          [id]: 'SAVED' as const,
        },
      };
    },
  ),
  on(
    labelActions.updateFail,
    nameSchemeActions.updateFail,
    bioRegisterConfigurationActions.updateFail,
    lumaConfigurationActions.updateFail,
    (state, { id }) => {
      const { [id]: _, ...states } = state.settingStates;
      return {
        ...state,
        settingStates: {
          ...states,
          [id]: 'SAVED' as const,
        },
      };
    },
  ),
);
