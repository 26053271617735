import { NgsSequencesTableViewerV2Component } from '../ngs/ngs-sequences-table-viewer-v2/ngs-sequences-table-viewer-v2.component';
import { FilesSequenceViewerComponent } from './files-sequence-viewer/files-sequence-viewer.component';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';
import { SequenceLogoViewerComponent } from '../../features/graphs/sequence-logo/sequence-logo-viewer/sequence-logo-viewer.component';
import { NgsSequenceViewerComponent } from '../ngs/ngs-sequence-viewer/ngs-sequence-viewer.component';
import { MotifBoxPlotV2Component } from '../ngs/motif-box-plot-v2/motif-box-plot-v2.component';
import { NgsSummaryGraphViewerComponent } from '../ngs/ngs-summary-graph-viewer/ngs-summary-graph-viewer.component';
import { NgsReportViewerComponent } from '../ngs/ngs-report-viewer/ngs-report-viewer.component';
import { MotifReportViewerComponent } from './motif-report-viewer/motif-report-viewer.component';
import { JsonReportViewerComponent } from './json-report-viewer/json-report-viewer.component';
import { PdfViewerComponent } from './pdf-report-viewer/pdf-viewer.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { MasterDatabaseSearchResultViewerComponent } from '../master-database/master-database-search-result-viewer/master-database-search-result-viewer.component';
import { TableViewerComponent } from './table-viewer/table-viewer.component';
import { DocumentInfoViewerComponent } from '../document-info/document-info-viewer/document-info-viewer.component';
import { NgsSequencesTablePreviewViewerComponent } from '../ngs/ngs-sequences-table-preview-viewer/ngs-sequences-table-preview-viewer.component';
import { NgsSequenceViewerPreviewComponent } from '../ngs/ngs-sequence-viewer/ngs-sequence-viewer-preview.component';
import { NgsComparisonsGraphsComponent } from '../ngs/ngs-comparisons-graphs/ngs-comparisons-graphs.component';
import { TreeGraphViewerComponent } from '../../features/graphs/tree-graph-viewer/tree-graph-viewer.component';
import { NgsGraphsComponent } from '../ngs/ngs-graphs/ngs-graphs.component';

const DEFAULT_COMPONENTS = [
  FilesSequenceViewerComponent,
  NgsSequenceViewerPreviewComponent,
  NgsSequenceViewerComponent,
  HtmlViewerComponent,
  JsonReportViewerComponent,
  SequenceLogoViewerComponent,
  TreeGraphViewerComponent,
  NgsSequencesTablePreviewViewerComponent,
  NgsSequencesTableViewerV2Component,
  MotifBoxPlotV2Component,
  NgsSummaryGraphViewerComponent,
  MotifReportViewerComponent,
  NgsGraphsComponent,
  NgsComparisonsGraphsComponent,
  PdfViewerComponent,
  ImageViewerComponent,
  MasterDatabaseSearchResultViewerComponent,
  TableViewerComponent,
];

/**
 * Order of these components in the array below determine the order they will show in the viewers.
 */
export const PREVIEW_MODE_VIEWER_COMPONENTS: any[] = [
  ...DEFAULT_COMPONENTS,
  DocumentInfoViewerComponent,
];

/**
 * Order of these components in the array below determine the order they will show in the viewers in preview mode.
 * But these components are only used for complex documents, which are: ngsResult, ngsComparison, alignment, tree, masterDatabase
 */
export const PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS: any[] = [
  DocumentInfoViewerComponent,
  NgsReportViewerComponent,
  ...DEFAULT_COMPONENTS,
];

/**
 * Order of these components in the array below determine the order they will show in the viewers in full view mode.
 */
export const FULL_VIEW_MODE_VIEWER_COMPONENTS: any[] = [
  ...DEFAULT_COMPONENTS,
  NgsReportViewerComponent,
  DocumentInfoViewerComponent,
];
