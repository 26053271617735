<div class="bx-page-container bx-page-container-md">
  <div class="p-2" [ngClass]="{ 'd-none': !id }" [innerHTML]="html"></div>

  @if (!id) {
    <div class="w-100 text-center">
      @if (isLoading) {
        <span> <bx-spinner></bx-spinner></span>
      } @else {
        <span>{{ helpText }}</span>
      }
    </div>
  }
</div>
