import { AntibodyType } from '../../../app/core/master-database/antibody-type/antibody-type';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';
import { ResultSequenceExtractionOptions } from './result-sequence-extraction-options-v1.model';

export class MasterDatabaseImporterJobParameters implements JobConfigurationWithSelection {
  pipeline = {
    name: 'master-database-importer',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: MasterDatabaseImporterJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: MasterDatabaseImporterJobOptionsV1,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}

export interface MasterDatabaseImporterJobOptionsV1 {
  creationOptions?: CreationOptions;
  rawSequencesImporterOptions?: RawSequencesImporterOptions;
  annotatedSequencesImporterOptions?: AnnotatedSequencesImporterOptions;
}

export interface CreationOptions {
  parentID: string;
  name: string;
  description?: string;
  alphabet: 'NUCLEOTIDE' | 'PROTEIN';
  antibodyType: AntibodyType;
}

export interface RawSequencesImporterOptions {
  sequenceDocumentIDs: string[];
  antibodyAnnotatorOperationOptions: { [type: string]: any };
}

export interface AnnotatedSequencesImporterOptions {
  tableQueryOptions: ResultSequenceExtractionOptions;
  groupModifications: GroupModification[];
}

export interface GroupModification {
  originalGroupName: string;
  existingGroupName?: string;
  newGroupName?: string;
}
