import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  publishReplay,
  refCount,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BoxplotData,
  GraphBoxplotComponent,
} from '../../../features/graphs/graph-boxplot/graph-boxplot.component';
import { ExactMotif } from '../../viewer-components/motif-report-viewer/motif-report-viewer.component';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { parseSequenceId } from '../ngs.operators';
import { DocumentServiceResource } from '../../../../nucleus/services/documentService/document-service.resource';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { AnnotatedPluginDocument } from '../../geneious';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { OverlaysService } from '../../../shared/overlays/overlays.service';
import { antibodyAnnotatorViewerSelector } from '../../viewer-components/viewer-selectors';
import {
  ViewerDataColumn,
  ViewerMultipleTableDocumentSelection,
  ViewerResultData,
} from '../../viewer-components/viewer-document-data';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { SortModel } from '../../../features/grid/grid.interfaces';
import { quantile } from '../../../shared/utils/math-utils';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

@ViewerComponent({
  key: 'motif-box-plot-viewer',
  title: 'Motif Box Plot',
  selector: antibodyAnnotatorViewerSelector([
    {
      min: 1,
      max: 2147483647,
      tableType: DocumentTableType.SEQUENCES,
    },
    {
      min: 1,
      max: 2147483647,
      tableType: DocumentTableType.ANNOTATOR_RESULT_CHAIN_COMBINATIONS,
    },
  ]),
})
@Component({
  selector: 'bx-motif-box-plot-v2',
  templateUrl: './motif-box-plot-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DocumentServiceResource],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    GraphBoxplotComponent,
    PageMessageComponent,
    AsyncPipe,
  ],
})
export class MotifBoxPlotV2Component implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-auto';
  resource: DocumentServiceResource;

  motifs$: Observable<string[]>;
  motifControl = new FormControl<string>(null);
  motif$ = new BehaviorSubject<String>(null);

  columnControl = new FormControl<string>(null);
  column$ = new BehaviorSubject<string>(null);

  warningMessage$ = new Subject<String>();
  graphData$: Observable<BoxplotData>;
  loading = false;
  errorMessage$ = new BehaviorSubject<string | null>('Loading options...');
  subscriptions: Subscription[] = [];
  BOXPLOT_DATA = 'BX_MOTIF_PRESENCE';
  columns$: Observable<ViewerDataColumn[]>;

  private state$: Observable<ViewerMultipleTableDocumentSelection>;
  private document$: Observable<AnnotatedPluginDocument>;

  constructor(
    private documentHttpService: DocumentHttpV2Service,
    private featureSwitchService: FeatureSwitchService,
    private documentServiceResource: DocumentServiceResource,
    private viewerDataService: ViewerDataService<ViewerResultData>,
    private overlaysService: OverlaysService,
  ) {
    this.resource = this.documentServiceResource;

    const data$ = this.viewerDataService.getData('motif-box-plot-viewer');
    this.state$ = data$.pipe(map((data) => data.selection));

    this.columns$ = data$.pipe(map((data) => data.columns));

    this.document$ = this.state$.pipe(map((state) => state.document));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.columnControl.valueChanges.subscribe((value) => this.column$.next(value)),
    );
    this.subscriptions.push(
      this.motifControl.valueChanges.subscribe((value) => this.motif$.next(value)),
    );

    const rawData$: Observable<MotifPresenceJson> = this.document$
      .pipe(distinctUntilChanged((doc1, doc2) => doc1.id === doc2.id))
      .pipe(
        tap(() => {
          this.errorMessage$.next('Loading options...');
          this.overlaysService.createMessageEvent('motif-box-plot-viewer', 'Loading options...');
        }),
        switchMap((doc) => {
          if (doc.documentParts.find((part) => part.name === this.BOXPLOT_DATA)) {
            return this.documentHttpService.getDocumentPart(doc.id, this.BOXPLOT_DATA, 'json');
          } else {
            this.errorMessage$.next(
              'Motifs not found. Please run Motif Discovery on selected sequences from this result.',
            );
            const message =
              'Motifs not found.\nPlease run Motif Discovery on selected sequences from this result.';
            this.overlaysService.createMessageEvent('motif-box-plot-viewer', message);
            return of(null);
          }
        }),
        publishReplay(1),
        refCount(),
      );

    this.motifs$ = combineLatest(rawData$, this.state$).pipe(
      map(([json, state]) => {
        if (json && json['motifs']) {
          const selectedIds = state.rows
            .map((row) => parseSequenceId(row['Associated Sequences']))
            .reduce((a, b) => a.concat(b), []);
          let displayedMotifs = json.motifs;
          if (selectedIds.length) {
            const filtered = json.motifs.filter((motif) => {
              // Check motif exists at least once in selected sequences
              const selectedVals = motif.values.filter((value, index) =>
                selectedIds.includes(index),
              );
              return Math.max(...selectedVals) > 0;
            });
            // If their selection has no motifs or we've got something wrong, show all motifs, otherwise show only those that are present.
            displayedMotifs = filtered.length ? filtered : displayedMotifs;
          }
          return displayedMotifs
            .map((motif) => motif.motif)
            .sort((motif1, motif2) => motif1.count - motif2.count)
            .map((motif) => motif.sequence);
        } else {
          return [];
        }
      }),
      publishReplay(1),
      refCount(),
    );

    this.subscriptions.push(
      combineLatest(this.motifs$, this.columns$)
        .pipe(
          tap(([motifs, cols]) => {
            if (!cols.length) {
              // TODO When would this happen? What should we do here?
              // this.errorMessage$.next('No assay data columns found. Please add assay data to this result.');
            }
          }),
        )
        .pipe(filter(([motifs, cols]) => motifs.length > 0 && cols.length > 0))
        .subscribe(([motifs, cols]) => {
          this.updateSelectors(motifs, cols);
          this.errorMessage$.next(null);
          this.overlaysService.hideOverlay('motif-box-plot-viewer');
        }),
    );

    this.graphData$ = combineLatest([
      this.column$.asObservable().pipe(distinctUntilChanged()),
      this.motif$.asObservable().pipe(distinctUntilChanged()),
      rawData$,
      this.state$.pipe(distinctUntilChanged()),
    ])
      .pipe(withLatestFrom(this.document$))
      .pipe(
        filter(([[column, motif, rawData, state], doc]) => !!column && !!motif),
        tap(() => (this.loading = true)),
        switchMap(([[colField, motif, rawData, state], doc]) => {
          this.loading = false;
          return this.getOriginalRowData(doc).pipe(
            map((response) =>
              this.computeBoxplotData(response, motif, colField, rawData, state.rows),
            ),
          );
        }),
        publishReplay(1),
        refCount(),
      );
  }

  computeBoxplotData(
    response: any,
    motif: any,
    colField: string,
    rawData: any,
    selectedRows: AnnotatedPluginDocument[],
  ): BoxplotData {
    let column = response.columns.find((col: any) => col.field === colField);
    if (!column) {
      // Might be a grouped column
      response.columns
        .filter((col: any) => col.children)
        .forEach((group: any) => {
          const maybeColumn = group.children.find((col: any) => col.field === colField);
          if (maybeColumn) {
            column = maybeColumn;
          }
        });
    }
    const motifPresences = rawData.motifs.find(
      (mp: MotifAndPresence) => mp.motif.sequence === motif,
    ).values;
    const showSelected = selectedRows.length > 0;
    let boxes = this.combineMotifAndSequences(
      response.data,
      rawData,
      colField,
      motifPresences,
      motif,
      'original',
      !showSelected,
    );
    if (showSelected) {
      const selectedBoxes = this.combineMotifAndSequences(
        selectedRows,
        rawData,
        colField,
        motifPresences,
        motif,
        'selected',
        true,
      );
      boxes = selectedBoxes.concat(boxes);
    }

    // Sometimes "column" is null, if the column list hasn't loaded yet.
    const colName = column && column.headerName ? column.headerName : 'Values';
    const formattedData = boxes.map(({ label, data }) => {
      return [
        label,
        quantile(data, 0),
        quantile(data, 0.25),
        quantile(data, 0.5),
        quantile(data, 0.75),
        quantile(data, 1),
      ];
    });
    return {
      title: rawData.name,
      label: colName,
      data: [
        {
          type: 'boxplot',
          data: formattedData,
        },
      ],
    };
  }

  combineMotifAndSequences(
    sequences: any[],
    motifData: any,
    colField: string,
    presences: number[],
    motif: string,
    label: string,
    updateMissing = false,
  ) {
    const dataWith: number[] = [];
    const dataWithout: number[] = [];
    let numMissingMotif = 0;
    let numMissingColumn = 0;
    sequences.forEach((row) => {
      const seqIds = parseSequenceId(row['Associated Sequences']);
      seqIds.forEach((seqId) => {
        const indexOfIndex = motifData.sequenceIndices.indexOf(seqId);
        // Check that the given sequence has both been annotated with motifs, and has a sensible assay data value.
        if (indexOfIndex > -1) {
          const seqColumnValue = row[colField];
          if (typeof seqColumnValue !== 'undefined' && !isNaN(parseFloat(seqColumnValue))) {
            const seqMotifCount = presences[indexOfIndex];
            if (seqMotifCount > 0) {
              dataWith.push(parseFloat(seqColumnValue));
            } else {
              dataWithout.push(parseFloat(seqColumnValue));
            }
          } else {
            numMissingColumn += 1;
          }
        } else {
          numMissingMotif += 1;
        }
      });
    });

    if (updateMissing) {
      // Ignore missing motif sequences for the "original" plot, as these should be filtered out anyway.
      numMissingMotif = label === 'original' ? 0 : numMissingMotif;
      this.warningMessage$.next(this.getWarningMessage(numMissingMotif, numMissingColumn, label));
    }

    const suffix = label ? `: ${label} sequences` : '';
    return [
      {
        label: `With ${motif}${suffix}`,
        data: dataWith,
      },
      {
        label: `Without ${motif}${suffix}`,
        data: dataWithout,
      },
    ];
  }

  getOriginalRowData(doc: any) {
    // TODO Support more than 100,000, may need a more sophisticated query for this -
    // ie have a boolean "hasMotifs" column on the sequence data.
    const options = {
      startRow: 0,
      endRow: 1000, // todo when it's working push it back to 100,000
      sortModel: [] as SortModel[], // todo bring this back up [{colId: 'id', sort: 'asc'}],
      filterModel: null as any,
    };

    const params = {
      filterModel: null as any,
      documentId: doc.id,
      documentTableName: 'DOCUMENT_TABLE_ALL_SEQUENCES', // motif box plots haven't been setup for other tables yet
    };
    return this.resource.query(options, params);
  }

  updateSelectors(motifs: any, cols: ViewerDataColumn[]) {
    const currentMotifValue = this.motif$.getValue();
    if (!currentMotifValue || !motifs.includes(currentMotifValue.toString())) {
      // Required as setValue does not trigger a change event.
      this.motif$.next(motifs[0]);
      this.motifControl.setValue(motifs[0], { emitEvent: true });
    }

    const currentColValue = this.column$.getValue();
    // Reset column value if it is unset or not present in the current column list.
    if (!currentColValue || !cols.map((col) => col.colID).includes(currentColValue.toString())) {
      const startCol = cols[0].colID;
      this.column$.next(startCol);
      this.columnControl.setValue(startCol, { emitEvent: true });
    }
  }

  getWarningMessage(motifs: any, cols: any, label: any) {
    let message = '';

    if (cols || motifs) {
      message = `${cols + motifs} ${label} sequences were skipped - `;
    }
    if (cols) {
      message += motifs ? `${cols} invalid column data, ` : 'invalid column data.';
    }
    if (motifs) {
      message += cols ? `${motifs} missing motif data.` : 'missing motif data.';
    }
    return message;
  }

  ngOnDestroy() {
    this.errorMessage$.complete();
    this.warningMessage$.complete();
    this.motif$.complete();
    this.column$.complete();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

interface MotifPresenceJson {
  name: string;
  sequenceIndices: number[];
  motifs: MotifAndPresence[];
}

interface MotifAndPresence {
  motif: ExactMotif;
  values: number[];
}
