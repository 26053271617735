import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

/**
 * The dropdown for switching environments. Only visible on Prod EU, Prod US, and localhost.
 */
@Component({
  selector: 'bx-environment-switcher',
  templateUrl: './environment-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, FormsModule, AsyncPipe],
})
export class EnvironmentSwitcherComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  currentEnvironment: Environment;
  readonly hostname: string;

  /** Allow hostname to be overridden in tests */
  constructor(@Optional() @Inject('hostname') hostname: string) {
    this.hostname = hostname ?? location.hostname;
  }

  ngOnInit() {
    this.currentEnvironment = this.getCurrentEnvironment();
  }

  handleEnvironmentChange(environment: Environment) {
    if (environment === 'US') {
      this.navigateTo('https://biologics.geneious.com');
    } else if (environment === 'EU') {
      this.navigateTo('https://biologics.geneious.eu');
    }
  }

  navigateTo(url: string) {
    location.href = url;
  }

  private getCurrentEnvironment(): Environment {
    if (this.hostname === 'biologics.geneious.eu') {
      return 'EU';
    }
    if (this.hostname === 'biologics.geneious.com') {
      return 'US';
    }
    if (this.hostname === 'localhost') {
      return 'localhost';
    }
    return 'other';
  }
}

type Environment = 'US' | 'EU' | 'localhost' | 'other';
