import { createAction, props, union } from '@ngrx/store';
import { Item } from '../../../../../nucleus/v2/models/item.v2.model';

export const replaceAllFiles = createAction(
  '[Files] Replace All',
  props<{ payload: { files: Item[] } }>(),
);

export const addFile = createAction('[Files] Add File', props<{ payload: { file: Item } }>());

export const addFiles = createAction('[Files] Add Files', props<{ payload: { files: Item[] } }>());

export const updateFileName = createAction(
  '[Files] Update File Name',
  props<{ payload: { fileID: string; name: string; blobRevision: number } }>(),
);

export const updateFileNameSuccess = createAction(
  '[Files] Update File Name Success',
  props<{ payload: { action: ReturnType<typeof updateFileName>; newBlobRevision: number } }>(),
);

export const updateFileNameFailure = createAction(
  '[Files] Update File Name Failure',
  props<{ payload: { action: ReturnType<typeof updateFileName>; errorStatus: number } }>(),
);

export const updateFileBlobRevision = createAction(
  '[Files] Update File Blob Revision',
  props<{ payload: { fileID: string; blobRevision: number } }>(),
);

export const removeFiles = createAction(
  '[Files] Remove Files',
  props<{ payload: { fileIDs: string[]; selectAll: boolean } }>(),
);

export const removeFilesSuccess = createAction(
  '[Files] Remove Files Success',
  props<{ payload: { action: ReturnType<typeof removeFiles> } }>(),
);

export const removeFilesFailure = createAction(
  '[Files] Remove Files Failure',
  props<{ payload: { action: ReturnType<typeof removeFiles> } }>(),
);

export const moveFiles = createAction(
  '[Files] Moves Files',
  props<{ payload: { fileIDs: string[]; toFolderID: string; selectAll: boolean } }>(),
);

export const moveFilesSuccess = createAction(
  '[Files] Moves Files Success',
  props<{ payload: { action: ReturnType<typeof moveFiles> } }>(),
);

export const moveFilesFailure = createAction(
  '[Files] Moves Files Failure',
  props<{ payload: { action: ReturnType<typeof moveFiles> } }>(),
);

const actions = union({
  replaceAllFiles,
  addFile,
  addFiles,
  updateFileName,
  updateFileNameSuccess,
  updateFileNameFailure,
  updateFileBlobRevision,
  removeFiles,
  removeFilesSuccess,
  removeFilesFailure,
  moveFiles,
  moveFilesSuccess,
  moveFilesFailure,
});

const editActions = union({
  addFile,
  addFiles,
  updateFileName,
  updateFileBlobRevision,
  removeFiles,
  moveFiles,
});

export type ActionsUnion = typeof actions;

export type EditActionsUnion = typeof editActions;
