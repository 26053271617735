/**
 * Extends built in Error class with
 * - Fixes for exception inheritance
 * - Exception chaining
 *
 * The original error is available via ngOriginalError (which is the property Angular uses for exception chaining).
 *
 * When subclassing we should pass in via opts.klass the class we expect to be created.  Apparently ES2015 fixes Error subclassing
 * then this wouldn't be needed.
 */
export class BxError extends Error {
  readonly ngOriginalError: any;

  constructor(
    message: string,
    opts: {
      cause?: any;
      klass?: any;
      name?: string;
    },
  ) {
    super(message);

    // Kludge until we target ES2015 or higher
    // See https://stackoverflow.com/questions/41102060/typescript-extending-error-class
    Object.setPrototypeOf(this, (opts.klass || BxError).prototype);

    // Not sure if this is a kludge or if we are supposed to set name in our own errors but it seems necessary
    // Cant do it via klass.name as thats obfuscated in production
    this.name = opts.name || 'BxError';

    this.ngOriginalError = opts.cause;
  }
}
