import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbAlertModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store, select } from '@ngrx/store';
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  distinctUntilChanged,
} from 'rxjs';

import { CleanUp } from 'src/app/shared/cleanup';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { OrganizationSetting } from '../../models/settings/setting.model';
import { lumaConfigurationActions } from '../../organization-settings/organization-settings.actions';
import {
  selectLumaConfig,
  selectLumaSettingsByName,
} from '../../organization-settings/organization-settings.selectors';
import { LumaAPIService } from '../luma-api-service';
import { LumaConfig } from './luma-config.model';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { compareStrings } from '../../../shared/utils/object';
import { debounceTime } from 'rxjs/operators';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';

@Component({
  selector: 'bx-luma-config',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    SpinnerComponent,
    NgbAlertModule,
    NgbCollapseModule,
  ],
  templateUrl: './luma-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LumaConfigComponent extends CleanUp implements OnInit {
  readonly form = new FormGroup({
    connection: new FormGroup({
      url: new FormControl<string>(undefined, [this.requiredString('URL'), this.validURL]),
      apiKey: new FormControl<string>(undefined, [
        this.requiredString('API Key'),
        this.validAPIKey,
      ]),
    }),
  });
  /** Actions containing changes to organization settings to dispatch on submit */
  submitActions$: Observable<Action[]>;
  /** True if the form is invalid or if no changes have been made */
  disableSubmit$: Observable<boolean>;
  /** True if no changes have been made */
  disableReset$: Observable<boolean>;

  showConnectionError$: Observable<boolean>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly lumaAPIService: LumaAPIService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.resetFormToStoreValues();

    const connectionConfig$: Observable<LumaConfig | null> = currentValueAndChanges(
      this.form.controls.connection,
    ).pipe(
      map(() => {
        if (this.form.controls.connection.valid) {
          return this.getConnectionConfig(this.form.controls.connection.getRawValue());
        }
        return null;
      }),
      distinctUntilChanged(compareStrings(({ lumaURL, lumaAPIKey }) => lumaURL + lumaAPIKey)),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    const apps$ = connectionConfig$.pipe(
      switchMap((connectionConfig) => {
        if (connectionConfig === null) {
          return of({ apps: [], status: 'waiting' as const });
        }
        return this.lumaAPIService.latestApplicationVersionsWithDrafts({}, connectionConfig).pipe(
          map((apps) => ({ apps, status: 'complete' as const })),
          startWith({ apps: [], status: 'loading' as const }),
          catchError((error) => {
            console.error(error);
            return of({ apps: [], status: 'error' as const });
          }),
        );
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    this.showConnectionError$ = apps$.pipe(
      debounceTime(500),
      map(({ status }) => status === 'error'),
      startWith(false),
    );

    this.submitActions$ = combineLatest([
      currentValueAndChanges(this.form),
      this.store.select(selectLumaSettingsByName),
      this.store.select(selectOrganizationID).pipe(take(1)),
    ]).pipe(
      map(([formValue, existingConfig, organizationID]) => {
        if (this.form.invalid) {
          return [];
        }

        const config = this.getFormValueAsConfig(
          formValue as ReturnType<typeof this.form.getRawValue>,
        );

        return Object.entries(config)
          .map(([name, configValue]) =>
            this.getSettingAction(
              name,
              organizationID,
              configValue,
              existingConfig[name as keyof LumaConfig],
            ),
          )
          .filter((action) => action != null);
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    this.disableSubmit$ = combineLatest([apps$, this.submitActions$]).pipe(
      map(([apps, actions]) => apps.status !== 'complete' || actions.length === 0),
      startWith(true),
      takeUntil(this.ngUnsubscribe),
    );

    this.disableReset$ = combineLatest([
      currentValueAndChanges(this.form, this.ngUnsubscribe),
      this.store.pipe(
        select(selectLumaConfig),
        map((config) => this.getConfigAsFormValue(config)),
      ),
    ]).pipe(
      map(([formValue, storeValue]) => JSON.stringify(formValue) === JSON.stringify(storeValue)),
    );
  }

  resetFormToStoreValues() {
    this.store
      .select(selectLumaConfig)
      .pipe(
        map((config) => this.getConfigAsFormValue(config)),
        take(1),
      )
      .subscribe((storeValues) => this.form.patchValue(storeValues));
  }

  /**
   * Called via ngSubmit when the Save button is clicked.
   */
  submit(): void {
    this.dialogService
      .showConfirmationDialog$({
        title: `Are you sure?`,
        content:
          'Updated configuration will be used for everyone in your organization. Are you sure you want to save the changes?',
        confirmationButtonText: 'Save',
        confirmationButtonColor: 'danger',
      })
      .pipe(
        catchError(() => of(false)),
        filter((confirmed) => confirmed),
        switchMap(() => this.submitActions$.pipe(take(1))),
      )
      .subscribe((actions) =>
        actions.forEach((action) => {
          this.store.dispatch(action);
        }),
      );
  }

  private getConnectionConfig(value: { url: string; apiKey: string }): LumaConfig {
    const url = value.url.trim();
    return {
      lumaURL: url.endsWith('/') ? url.slice(0, -1) : url,
      lumaAPIKey: value.apiKey.trim(),
    };
  }

  /**
   * Sanitizes the form value and returns it as a Luma config. Assumes the value
   * has passed form validation.
   *
   * @param value the form value
   * @returns the Luma config
   */
  private getFormValueAsConfig(value: ReturnType<typeof this.form.getRawValue>): LumaConfig {
    return {
      ...this.getConnectionConfig(value.connection),
    };
  }

  private getConfigAsFormValue(config: Partial<LumaConfig>): (typeof this.form)['value'] {
    return {
      connection: {
        url: config.lumaURL ?? null,
        apiKey: config.lumaAPIKey ?? null,
      },
    };
  }

  /**
   * Creates an NGRX action that creates or updates an organization setting so
   * it will be set to the new value. If the new value is the same as the one in
   * the store, it returns null, as no action needs to be dispatched.
   *
   * @param name the name of the setting
   * @param organizationID organization ID
   * @param newValue the new setting value from the form
   * @param existingSetting the existing organization setting (if it exists)
   * @returns an action to create or update the organization setting, or null if
   *    the new setting value is equal to the existing one
   */
  private getSettingAction(
    name: string,
    organizationID: string,
    newValue: string,
    existingSetting?: OrganizationSetting<string>,
  ): Action | null {
    if (!existingSetting) {
      return lumaConfigurationActions.create({ name, organizationID, data: newValue });
    }
    if (existingSetting.data === newValue) {
      return null;
    }
    const settingToUpdate = { ...existingSetting, data: newValue };
    return lumaConfigurationActions.update({ settingToUpdate });
  }

  private requiredString(friendlyName: string): ValidatorFn {
    const errorMessage = friendlyName + ' is required';
    return (ctrl) => {
      const value = (ctrl.value as string)?.trim();
      if (!value || value === 'null') {
        return { required: errorMessage };
      }
      return null;
    };
  }

  private validURL(ctrl: AbstractControl): ValidationErrors | null {
    const url = (ctrl.value as string)?.trim();
    if (!url || url.startsWith('https://')) {
      return null;
    }
    return { needsHttps: 'The URL must start with https://' };
  }

  private validAPIKey(ctrl: AbstractControl): ValidationErrors | null {
    const apiKey = (ctrl.value as string)?.trim();
    if (!apiKey || apiKey.length > 36) {
      return null;
    }
    return { tooShort: 'Invalid API Key (too short)' };
  }
}
