import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { SequenceViewerService } from '../sequence-viewer.service';
import { MODAL_DATA } from '../../../shared/dialog';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sequenceValidator } from './sequence.validator';
import { SequenceType } from '@geneious/nucleus-api-client';
import { RRange } from '@geneious/sequence-viewer/index';

import { MonospaceBoxComponent } from '../../../shared/monospace-box/monospace-box.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';

interface EditSequenceModalData {
  sequenceViewerService: SequenceViewerService;
  editRange: { min: number; max: number };
  sequenceIndex: number;
  defaultSequence?: string;
}

@Component({
  selector: 'bx-edit-sequence-dialog',
  templateUrl: './edit-sequence-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MonospaceBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
  ],
})
export class EditSequenceDialogComponent implements OnInit {
  sequenceName: string;
  sequenceUnit: 'bp' | 'aa';
  sequenceUnitName: 'Base' | 'Residue';
  editRange: RRange;

  editAction: 'Insert' | 'Replace';
  originalBases?: string;

  title: string;
  content: string;

  readonly sequenceControl = new FormControl('');
  readonly form = new FormGroup({
    sequence: this.sequenceControl,
  });

  private sequenceViewerService: SequenceViewerService;

  constructor(
    @Inject(MODAL_DATA) private modalData: EditSequenceModalData,
    public activeModal: NgbActiveModal,
  ) {
    this.sequenceViewerService = modalData.sequenceViewerService;
    this.editRange = new RRange(this.modalData.editRange.min, this.modalData.editRange.max);
  }

  ngOnInit() {
    const sequenceWrapper = this.sequenceViewerService.getSequenceWrapperAtIndex(
      this.modalData.sequenceIndex,
    );
    this.sequenceName = sequenceWrapper.name;

    const isDNASequence = this.sequenceViewerService.isDNA;
    const sequenceType = isDNASequence ? SequenceType.Nucleotide : SequenceType.AminoAcid;
    this.sequenceControl.setValidators([Validators.required, sequenceValidator(sequenceType)]);
    this.sequenceUnit = isDNASequence ? 'bp' : 'aa';
    this.sequenceUnitName = this.sequenceUnit === 'bp' ? 'Base' : 'Residue';
    const { isEmpty, start, end } = this.editRange;

    if (isEmpty) {
      this.editAction = 'Insert';
      this.title = `Insert ${this.sequenceUnitName}s into ${this.sequenceName}`;
      this.content = `Insert ${this.sequenceUnitName}s after ${this.sequenceUnitName} ${start}`;
    } else {
      this.editAction = 'Replace';
      this.title = `Replace ${this.sequenceUnitName}s in ${this.sequenceName}`;
      this.content = `Replace ${this.sequenceUnitName}s ${start}..${end}`;
      this.originalBases = sequenceWrapper.sequenceCache.getRange(this.editRange).join('');
    }

    if (this.modalData.defaultSequence) {
      this.sequenceControl.markAsDirty();
      this.sequenceControl.setValue(this.modalData.defaultSequence);
    }
  }

  close() {
    this.activeModal.close();
  }

  applyEdit() {
    const sequenceIndex = this.modalData.sequenceIndex;
    const newResidues = this.sequenceControl.value.toUpperCase();
    this.sequenceViewerService.applyEdit({
      sequenceIndex,
      newResidues,
      min: this.editRange.start,
      max: this.editRange.end,
    });
    this.close();
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.applyEdit();
    }
  }
}
