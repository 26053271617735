import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class TrimEndsJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'trim-ends',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: TrimEndsJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: TrimEndsJobOptionsV1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface TrimEndsJobOptionsV1 {
  resultSuffix: string;
  optionValues: {
    primerTrim_primerScreening: boolean;
    primerTrim_primerTrim_primers: string;
    primerTrim_primerTrim_allowMismatches: boolean;
    primerTrim_primerTrim_maxMismatches: number;
    primerTrim_primerTrim_minLength: number;
    errorProbability_errorProbability: boolean;
    errorProbability_errorLimit: number;
    lowQualityTrim_lowQuality: boolean;
    lowQualityTrim_lowQualityLimit: number;
    ambiguityTrim_ambiguity: boolean;
    ambiguityTrim_ambiguityLimit: number;
    trimStart_startTrim: boolean;
    trimStart_startTrimAtLeast: boolean;
    trimStart_startTrimMinimum: number;
    trimEnd_endTrim: boolean;
    trimEnd_endTrimAtLeast: boolean;
    trimEnd_endTrimMinimum: number;
    minLength_use: boolean;
    minLength_minLength: number;
    maxLength_use: boolean;
    maxLength_maxLength: number;
  };
}
