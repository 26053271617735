import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SelectionState } from '../../../features/grid/grid.component';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { FindHeterozygotesJobParamtersV1 } from '../../../../nucleus/services/models/findHeterozygotesOptions.model';
import {
  GenericDialogComponent,
  ModalElementType,
  ModalLayout,
} from '../generic-dialog/generic-dialog.component';
import { PipelineDialogData } from '..';
import { PIPELINE_DIALOG_DATA } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbCollapse, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-find-heterozygotes',
  templateUrl: '../generic-dialog/generic-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgIf,
    FaIconComponent,
    NgbCollapse,
    NgClass,
    NgbTooltip,
    NgFormControlValidatorDirective,
    PipelineOutputComponent,
  ],
})
export class FindHeterozygotesComponent
  extends GenericDialogComponent
  implements RunnableJobDialog
{
  earlyRelease: false;
  title = 'Find Heterozygotes';
  knowledgeBaseArticle: string;

  private readonly selected: SelectionState;

  constructor(@Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData) {
    super('generic-geneious', PipelineFormID.FIND_HETEROZYGOTES, FindHeterozygotesModalLayout);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
  }

  run() {
    // `getRawValue` gets values of disabled fields which we rely on.
    const formValue = this.form.getRawValue();

    const parameters = {
      options: {
        optionValues: formValue,
        resultSuffix: this.form.get('resultSuffix').value || '(found heterozygotes)',
      },
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: formValue.outputFolderName,
      },
    };

    // Remove pipeline options from the GaaL options list.
    delete parameters.options.optionValues['resultSuffix'];
    delete parameters.options.optionValues['outputFolderName'];

    return new FindHeterozygotesJobParamtersV1(parameters);
  }
}

const FindHeterozygotesModalLayout: ModalLayout = {
  sections: [
    {
      title: 'Find Heterozygotes',
      rows: [
        {
          elements: [
            {
              id: 'similarity',
              type: ModalElementType.LABEL,
              label: 'Peak Similarity:',
              tooltip:
                'A position will be considered heterozygotic if the height of its second highest trace ' +
                'value is within this percent of the peak value or if the highest peak is for a nucleotide different ' +
                'from the base that has been already been called.',
              size: 3,
            },
            {
              id: 'similarity',
              type: ModalElementType.NUMBER_INPUT,
              append: '%',
              value: 90,
              validators: {
                min: 0,
                max: 100,
              },
              size: 4,
            },
          ],
        },
        {
          elements: [
            {
              id: 'action',
              type: ModalElementType.RADIO_GROUP,
              label: 'Action to take:',
              size: 3,
              value: [
                {
                  id: 'annotate',
                  label: 'Annotate',
                  tooltip:
                    'Add an annotation to heterozygotic (or low quality) positions in the sequence',
                  value: 'annotate',
                },
                {
                  id: 'edit',
                  label: 'Change bases to ambiguities & annotate',
                  tooltip:
                    'Change the sequence to use ambiguities at heterozygotic (or low quality) positions and add an annotation where this has occurred',
                  value: 'edit',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Advanced Options',
      collapse: true,
      rows: [
        {
          elements: [
            {
              id: 'peakDetectionHeight',
              type: ModalElementType.LABEL,
              label: 'Peak Detection Height:',
              tooltip:
                'A peak will be detected if the trace is at least this much higher than the trace on both ' +
                'sides in the vicinity of a nucleotide.',
              size: 7,
            },
            {
              id: 'peakDetectionHeight',
              type: ModalElementType.NUMBER_INPUT,
              append: '%',
              value: 10,
              validators: {
                min: 0,
                max: 100,
              },
              size: 3,
            },
          ],
        },
        {
          elements: [
            {
              id: 'enableMinimumConfidenceToCall',
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              label: 'Minimum confidence to call heterozygote:',
              tooltip:
                'If the confidence (ie. quality or "Phred" score) for a base call is below this then ' +
                'heterozygotes will not be annotated.',
              value: false,
              size: 7,
            },
            {
              id: 'minimumConfidenceToCall',
              type: ModalElementType.NUMBER_INPUT,
              value: 20,
              validators: {
                min: 0,
                max: 100,
              },
              disable: [
                {
                  element: 'enableMinimumConfidenceToCall',
                  state: false,
                },
              ],
              size: 3,
            },
          ],
        },
        {
          elements: [
            {
              id: 'enableMinimumConfidence',
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              label: 'Flag all positions with confidence below:',
              tooltip:
                'If the confidence (ie. quality or "Phred" score) for a base call is below this then ' +
                'annotate it or change it to an N ambiguity.',
              value: false,
              size: 7,
            },
            {
              id: 'minimumConfidence',
              type: ModalElementType.NUMBER_INPUT,
              value: 10,
              validators: {
                min: 0,
                max: 100,
              },
              disable: [
                {
                  element: 'enableMinimumConfidence',
                  state: false,
                },
              ],
              size: 3,
            },
          ],
        },
        {
          elements: [
            {
              id: 'findIncorrectBaseCalls',
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              label: 'Identify incorrect base calls when alternative peak height is:',
              tooltip:
                'If the peak for an alternative base is this much higher than the recorded base call, flag it ' +
                'as possibly an incorrect base call.',
              value: false,
              size: 7,
            },
            {
              id: 'incorrectBaseCallHeight',
              type: ModalElementType.NUMBER_INPUT,
              append: '% higher',
              value: 100,
              validators: {
                min: 0,
                max: 10000,
              },
              disable: [
                {
                  element: 'findIncorrectBaseCalls',
                  state: false,
                },
              ],
              size: 5,
            },
          ],
        },
      ],
    },
    {
      title: 'Output Options',
      rows: [
        {
          elements: [
            {
              id: 'resultSuffix',
              type: ModalElementType.LABEL,
              label: 'Result name suffix:',
              size: 4,
            },
            {
              id: 'resultSuffix',
              type: ModalElementType.TEXT_INPUT,
              placeholder: 'Default',
              size: 8,
            },
          ],
        },
      ],
    },
  ],
};
