<div class="d-flex justify-content-center">
  <bx-profile-buttons
    [identifier]="targetComponent"
    [feature]="ProfileFeature.TABLE_PREFERENCES"
    [data]="visibleGridState$ | async"
    (profileSaved)="saveRecentChanges($event)"
    (profileApplied)="applyProfile($event)"
    [allowCreateSharedProfile]="true"
  ></bx-profile-buttons>
</div>

@if (currentProfileState$ | async; as state) {
  <div class="text-truncate w-100 text-center">
    @if (state.lastUsedProfile?.editable && state.isDirty) {
      <button
        type="button"
        class="btn btn-sm btn-link"
        data-spec="save-changes-to-profile-button"
        (click)="saveRecentChanges(state.lastUsedProfile)"
      >
        Save changes to: {{ state.lastUsedProfile.name }}
      </button>
    }
  </div>
}
