import { createAction, props } from '@ngrx/store';
import { ViewerStates, ViewerHeight } from './viewers-state.interface';

export const setAllViewerStates = createAction(
  '[Viewer States] Update All',
  props<{ viewerStates: ViewerStates<ViewerHeight> }>(),
);

export const updateViewerHeight = createAction(
  '[Viewer States] Update Height',
  props<{ key: string; height: ViewerHeight }>(),
);

export const updateViewerSelectedTab = createAction(
  '[Viewer States] Update Selected Tab',
  props<{ key: string; selectionType: string; selectedTab: string }>(),
);
