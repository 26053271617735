import { Observable } from 'rxjs';

/**
 * Checks that all elements in the stream match the given boolean returning function
 *
 * @param matchFn {(any) => boolean} a check that all elements must pass
 * @returns {Observable<boolean>}
 */
export const allMatch =
  (matchFn: (value: any) => boolean): ((source: Observable<any[]>) => Observable<boolean>) =>
  (source: Observable<any[]>) => {
    return new Observable((subscriber) => {
      return source.subscribe(
        (items) => {
          try {
            if (!matchFn) {
              subscriber.error('Match function missing');
            }
            const sanitisedItems = Array.isArray(items) ? items : [items];
            const isAllMatching = sanitisedItems.reduce(
              (matching, val) => matching && matchFn(val),
              true,
            );
            subscriber.next(isAllMatching);
          } catch (err) {
            subscriber.error(err);
          }
        },
        (err) => subscriber.error(err),
        () => subscriber.complete(),
      );
    });
  };
