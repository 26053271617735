@if (status$ | async; as status) {
  @if (!status.error && status.progress > 0) {
    <ngb-progressbar
      class="w-100 bx-progress-bar-lg"
      type="success"
      [value]="status.progress"
      [showValue]="false"
      [striped]="true"
      [animated]="true"
    >
      <div class="position-absolute w-100 text-dark">{{ status.message }}</div>
    </ngb-progressbar>
  }
  @if (status.error) {
    <div class="text-danger text-truncate" [title]="status.message">
      {{ status.message }}
    </div>
  }
}
