import { ExtractionAndPreprocessingSequenceOptions } from './alignmentOptions.model';
import {
  JobConfigurationWithSelection,
  SelectionOptionsV1,
  OutputOptions,
} from './jobParameters.model';

export class AnnotationAlignmentJobParameters implements JobConfigurationWithSelection {
  pipeline = {
    name: 'annotation-alignment',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: AnnotationAlignmentJobOptions;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: AnnotationAlignmentJobOptions;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface AnnotationAlignmentJobOptions extends ExtractionAndPreprocessingSequenceOptions {
  anchorRegions: string[];
  sequenceMetadataOrder?: string[];
  resultName: string;
}
