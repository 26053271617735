import { map } from 'rxjs/operators';
import {
  IGetRowsRequestMinimal,
  IGridResource,
} from '../../../features/grid/datasource/grid.resource';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';
import { SortModel } from '../../../features/grid/grid.interfaces';
import { APP_CONFIG, AppConfig } from '../../../app.config';

@Injectable()
export class PrimersGridResource implements IGridResource {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {}

  query(
    params: IGetRowsRequestMinimal,
    extraParams: { [folderId: string]: string },
  ): Observable<IGridResourceResponse<any>> {
    const limit = params.endRow - params.startRow;
    const offset = params.startRow;
    const sort = this.parseSortModel(params.sortModel);
    let endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/folders/${extraParams.folderId}/children?page.limit=${limit}&page.offset=${offset}&page.includeTotal=true&includeType=sequence`;

    if (sort.length > 0) {
      endpoint += `&sort=${sort}`;
    }

    return this.http.get(endpoint).pipe(
      map(
        (resp: any): IGridResourceResponse<any> => ({
          data: resp.data.map((data: any) => ({
            id: data.id,
            metadata: data.metadata,
            type: data.type,
            ...data.metadata,
          })),
          metadata: resp.metadata.page,
        }),
      ),
    );
  }

  // TODO Move this logic into a shared service for all IGridResource.
  private parseSortModel(sortModel: SortModel[]) {
    return sortModel.map((model) => (model.sort === 'desc' ? `-${model.colId}` : model.colId));
  }
}
