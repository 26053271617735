@if (errorMessage$ | async; as error) {
  <bx-page-message>
    {{ error }}
  </bx-page-message>
} @else {
  <bx-toolstrip class="toolbar">
    <!-- Here the graph drop-down and access to the column picker -->
    <bx-toolstrip-item>
      <form class="d-flex flex-nowrap ms-1" [formGroup]="form">
        <label for="graphType" class="col-form-label text-nowrap me-2">Graph Type:</label>
        <bx-select
          id="graphType"
          [selectOptions]="graphs$ | async"
          [formControl]="form.controls.graph"
          [smallSize]="false"
        ></bx-select>
      </form>
    </bx-toolstrip-item>

    @if (graph$ | async; as graph) {
      <bx-toolstrip-item>
        <div class="btn-group" ngbDropdown>
          <button
            id="button-basic"
            [disabled]="loading$ | async"
            ngbDropdownToggle
            type="button"
            class="btn btn-light dropdown-toggle"
            aria-controls="dropdown-basic"
          >
            Export <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            ngbDropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            @if (isGraphAvailable) {
              <li
                role="menuitem"
                ngbTooltip="Export this graph as a png image"
                container="body"
                placement="right"
              >
                <button ngbDropdownItem (click)="exportGraph()">
                  <span>Image (png)</span>
                </button>
              </li>
            }
            @if (graph.exportAsTable) {
              <li
                role="menuitem"
                ngbTooltip="Export this graph as a csv"
                container="body"
                placement="right"
              >
                <button ngbDropdownItem (click)="exportGraphAsTable()">Table (csv)</button>
              </li>
            }
          </ul>
        </div>
      </bx-toolstrip-item>
    }
    @if (zoomControls$ | async; as zoomControls) {
      <bx-toolstrip-item>
        <bx-graph-zoom [zoomControls]="zoomControls"></bx-graph-zoom>
      </bx-toolstrip-item>
    }
  </bx-toolstrip>
  @if ((restoringTable$ | async) === true) {
    <bx-ngs-table-restoring-overlay
      [restoreProgress]="restoreProgress$ | async"
    ></bx-ngs-table-restoring-overlay>
  } @else {
    @if (datasource$ | async; as datasource) {
      <bx-chart-presenter #graph [datasource]="datasource"> </bx-chart-presenter>
    }
  }
}
