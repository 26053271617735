import { FileUploadGroup, UploadState } from '../models/file-upload.model';

export class GroupUpload {
  state: UploadState;
  queued: number;
  failed: number;
  uploading: number;
  total: number;
  done: number;
  groupID: string;
  startedAt: number;
  finishedAt: number;
  folderID: string;
  folderName: string;
  route: string;
  totalSize: number;
  uploadedSize: number;
  finished: boolean;
  fileIDs: string[];
  zippingEnabled: boolean;
  zippingProgress: number;
  zipFileSize: number;
  errors: string;

  constructor(data: any) {
    this.groupID = data.ID;
    this.queued = data.queued;
    this.uploading = data.uploading;
    this.done = data.done;
    this.failed = data.failed;
    this.total = this.queued + this.uploading + this.done + this.failed;
    this.startedAt = data.startedAt;
    this.finishedAt = data.finishedAt;
    this.folderID = data.folderID;
    this.folderName = data.folderName;
    this.route = data.route;
    this.totalSize = data.totalSize;
    this.uploadedSize = data.uploadedSize;
    this.fileIDs = data.uploadMap && Object.keys(data.uploadMap);
    this.zippingEnabled = data.zippingEnabled;
    this.zippingProgress = data.zippingProgress;
    this.zipFileSize = data.zipFileSize;
    this.errors = data.errors;

    this.setState();
  }

  setState() {
    if (this.zippingEnabled && this.zippingProgress < 100 && this.queued > 0) {
      this.state = UploadState.PREPARING;
    } else if (this.queued === this.total) {
      this.state = UploadState.QUEUED;
    } else if (this.uploading > 0) {
      this.state = UploadState.UPLOADING;
    } else if (this.done === this.total) {
      // After the file has been uploaded it goes to an importing step before it is considered to have been completed.
      this.state = UploadState.IMPORTING;
    } else if (this.failed > 0) {
      this.state = UploadState.FAILED;
    } else {
      this.state = UploadState.COMPLETED;
    }
    this.finished = this.isFinished();
  }

  isFinished() {
    return [
      UploadState.CANCELLED,
      UploadState.IMPORTING,
      UploadState.FAILED,
      UploadState.COMPLETED,
    ].includes(this.state);
  }

  toUpload() {
    const upload = new FileUploadGroup();
    upload.id = this.groupID;
    upload.startedAt = new Date(this.startedAt).toISOString();
    upload.finishedAt = this.finishedAt ? new Date(this.finishedAt).toISOString() : null;
    upload.route = this.route;
    upload.folderName = this.folderName;
    upload.folderID = this.folderID;
    upload.totalSize = this.totalSize;
    upload.status = {
      kind: this.state,
      messages: [],
      progress: this.calculateProgress(),
      updatedAt: new Date().toISOString(),
    };
    upload.fileIDs = this.fileIDs;
    upload.fileCount = this.total;
    upload.errors = this.errors;

    return upload;
  }

  private calculateProgress(): number {
    const totalSize = this.zippingEnabled ? this.zipFileSize : this.totalSize;
    // Can be NaN if zipping and the zip file is in the progress of being created.
    const uploadProgress = isNaN(totalSize) ? 0 : Math.round((this.uploadedSize / totalSize) * 100);
    if (this.zippingEnabled) {
      // Make overall progress a 20/80 composite of zipping and uploading.
      return Math.min(100, Math.round(this.zippingProgress * 0.2 + uploadProgress * 0.8));
    } else {
      return uploadProgress;
    }
  }

  static formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 bytes';
    }
    const k = 1024;
    const dm = decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
