import { Observable, of, of as observableOf, switchMap } from 'rxjs';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  AuthenticateActionLabel,
  AuthenticateErrorAction,
  AuthenticateSuccessAction,
  AuthenticateSuccessActionLabel,
  LogoutActionLabel,
  LogoutErrorAction,
  LogoutErrorActionLabel,
  LogoutSuccessAction,
  LogoutSuccessActionLabel,
  MfaRequiredAction,
  SubmitForgotPasswordRequestAction,
  SubmitForgotPasswordRequestActionLabel,
  SubmitForgotPasswordRequestFailureAction,
  SubmitForgotPasswordRequestFailureActionLabel,
  SubmitForgotPasswordRequestSuccessAction,
  VerifyAuthenticationActionLabel,
  VerifyAuthenticationFailureAction,
  VerifyAuthenticationSuccessAction,
  VerifyAuthenticationSuccessActionLabel,
} from './auth.actions';
import { ActiveService } from '../active.service';
import { FolderService } from '../folders/folder.service';
import { NavigationService } from '../navigation/navigation.service';
import { Router } from '@angular/router';
import { ActionResponse } from '../actionResponse.model';
import { catchError, exhaustMap, map, mapTo, pluck, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService, CurrentUserInfo } from '@geneious/nucleus-api-client';
import { BxHttpError } from '../BxHttpError';
import { AppState } from '../core.store';
import { stopWatchingAllFolders } from '../folders/store/folder.actions';
import { RedirectedURL } from './redirected-url';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { CredentialsLoginRequest } from '@geneious/nucleus-api-client/model/credentials-login-request';

@Injectable()
export class AuthEffects {
  loginSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AuthenticateSuccessActionLabel),
        tap(() => console.log('auth: username/password login success')),
        tap(() => this.activeService.start()),
        tap(() => this.navigationService.goToHome().subscribe()),
      ),
    { dispatch: false },
  );

  // This action is ONLY for username/password attempts.
  authenticate$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticateActionLabel),
      exhaustMap((action: ActionResponse<any>) => {
        console.log('auth: username/password login attempt', this.config.sessionExpiresMinutes);
        // We can't trust that local storage was cleared on logout.
        // It's safest to clear it now before we login to remove any old schemas
        // lying around. (Except for the redirect url, we want to persist that...)
        this.clearAllLocalStorageExceptRedirectUrl();

        const payload = action.payload;
        const credentialsLoginRequest: CredentialsLoginRequest = {
          identifier: payload.email,
          password: payload.password,
          tokenLifespanSeconds: this.config.sessionExpiresMinutes
            ? this.config.sessionExpiresMinutes * 60 // (x60 to convert to seconds)
            : 1800,
          autoRefresh: true,
        };
        if (payload.mfaCode) {
          credentialsLoginRequest.mfaCode = payload.mfaCode;
        }
        return this.authService.login(credentialsLoginRequest).pipe(
          pluck('data'),
          // TODO Move these to login success effects.
          map((user) => new AuthenticateSuccessAction(user)),
          catchError(this.handleLoginErrorResponse),
        );
      }),
    ),
  );

  private clearAllLocalStorageExceptRedirectUrl() {
    const redirectUrl = this.redirectedURL.getRedirectURL();
    localStorage.clear();
    this.redirectedURL.setRedirectURL(redirectUrl);
  }

  logout$ = createEffect(() =>
    this.actions.pipe(
      ofType(LogoutActionLabel),
      // Clear configured user
      tap(() => this.clearSentryScope()),
      exhaustMap(() => {
        console.log('auth: logout attempt');
        this.store.dispatch(stopWatchingAllFolders());
        return this.makeLogoutRequest().pipe(
          map(() => new LogoutSuccessAction()),
          catchError(() => observableOf(new LogoutErrorAction({ error: 'error' }))),
        );
      }),
    ),
  );

  logoutError$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LogoutErrorActionLabel),
        tap(() => console.log('auth: logout error')),
        tap(() => this.always()),
      ),
    { dispatch: false },
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LogoutSuccessActionLabel),
        tap(() => console.log('auth: logout success')),
        tap(() => this.always()),
      ),
    { dispatch: false },
  );

  verifyAuthentication$ = createEffect(() =>
    this.actions.pipe(
      ofType(VerifyAuthenticationActionLabel),
      exhaustMap(() => {
        return this.currentUserWithJWT().pipe(
          map(({ userInfo, jwt }) => new VerifyAuthenticationSuccessAction({ userInfo, jwt })),
          catchError(() => observableOf(new VerifyAuthenticationFailureAction())),
        );
      }),
    ),
  );

  authSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(VerifyAuthenticationSuccessActionLabel),
        tap(({ payload }: { type: string; payload: { userInfo: CurrentUserInfo; jwt: string } }) =>
          this.setSentryScope(payload.userInfo),
        ),
      ),
    { dispatch: false },
  );

  redirectToNewPage$ = createEffect(
    () =>
      this.actions.pipe(
        // We hook into VerifyAuthenticationSuccessActionLabel as those events are triggered by both username/password and
        // also sso logins.
        ofType(VerifyAuthenticationSuccessActionLabel),
        switchMap(() => this.navigationService.getHomeUrl()),
        tap((homeUrl) => this.navigationService.redirectToLastUrl(homeUrl)),
      ),
    { dispatch: false },
  );

  submitForgotPasswordRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitForgotPasswordRequestActionLabel),
      switchMap(({ email }: SubmitForgotPasswordRequestAction) =>
        this.makeLogoutRequest().pipe(
          catchError((err) => {
            this.errorHandler.handleError(
              new BxHttpError('Logout error in ForgotPasswordComponent.submit', err),
            );
            return observableOf(); // If logout fails, still send forgot password email.
          }),
          switchMap(() =>
            this.authService.createPasswordToken({ emailAddress: email }).pipe(
              map(() => new SubmitForgotPasswordRequestSuccessAction()),
              catchError(() => of(new SubmitForgotPasswordRequestFailureAction())),
            ),
          ),
          tap(() => this.router.navigate(['/reset-password/sent'])),
        ),
      ),
    ),
  );

  submitForgotPasswordRequestSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SubmitForgotPasswordRequestFailureActionLabel),
        tap(() => this.router.navigate(['/reset-password/sent'])),
      ),
    { dispatch: false },
  );

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private actions: Actions,
    private router: Router,
    private navigationService: NavigationService,
    private authService: AuthService,
    private activeService: ActiveService,
    private folderService: FolderService,
    private errorHandler: ErrorHandler,
    private store: Store<AppState>,
    private redirectedURL: RedirectedURL,
  ) {}

  private always() {
    this.activeService.stop();
    this.folderService.disablePolling();
    this.clearAllLocalStorageExceptRedirectUrl();

    this.router.navigate(['/login']);
  }

  /**
   * Clears configured user as well as set organization tags.
   */
  private clearSentryScope() {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
      scope.setTags({
        organization_id: undefined,
        organization_name: undefined,
      });
    });
  }

  /**
   * Sets current user in Sentry along with the user's organization id and name.
   */
  private setSentryScope(userInfo: CurrentUserInfo) {
    Sentry.setUser({
      id: userInfo.user.id,
      email: userInfo.user.email,
      // Keys nicely formatted for display in Sentry (consistent with other fields in Sentry).
      'Organization ID': userInfo.user.organizationID,
      'Organization Name': userInfo.organizationName,
    });
    Sentry.setTags({
      organization_id: userInfo.user.organizationID,
      organization_name: userInfo.organizationName,
    });
  }

  private handleLoginErrorResponse(httpErrorResponse: HttpErrorResponse): Observable<Action> {
    let errorMsg: string;
    const code: ErrorCode = httpErrorResponse.error?.error?.code;
    const status = httpErrorResponse.status;

    console.warn('Authentication Error:', httpErrorResponse);
    if (code === 'InvalidIdentifierOrPassword') {
      errorMsg = 'Incorrect email address or password';
    } else if (code === 'AuthMethodNotAllowed') {
      errorMsg =
        "Only Single sign-on is accepted for your organization. Please contact your organization's Geneious Biologics admin if you require this method of login";
    } else if (code === 'UserNotFound') {
      errorMsg = 'This user has been deactivated';
    } else if (code === 'MfaCodeInvalid') {
      errorMsg = 'The specified multi-factor authorization code is invalid.';
    } else if (code === 'MfaCodeRequired') {
      return observableOf(new MfaRequiredAction());
    } else if (status === 429) {
      errorMsg = 'Too many login attempts.';
    } else if (status === 502 || status === 504) {
      errorMsg =
        'Gateway/Proxy error - please check your network connection. If you continue to have problems, contact support';
    } else if (status >= 500 && status <= 599) {
      errorMsg = 'Server error - please try again later';
    } else if (status === 0) {
      errorMsg = 'Connection error - check your connection and try again';
    } else if (status) {
      errorMsg = `Something went wrong - please try again later.
            If you continue to have problems, contact support and quote error code ${status}`;
    } else {
      errorMsg = `Something went wrong - please try again later.
            If you continue to have problems, contact support`;
    }

    return observableOf(new AuthenticateErrorAction({ error: errorMsg, errorCode: status }));
  }

  private currentUserWithJWT(): Observable<{ userInfo: CurrentUserInfo; jwt?: string }> {
    return this.authService.currentUser('response').pipe(
      map((response) => {
        const jwt = response.headers.get('x-auth-token');
        const userInfo = response.body.data;
        return { userInfo, jwt };
      }),
    );
  }

  /**
   * Called from the auth effects.
   * Also called in a few other unusual places but should not be.
   *
   * Observable produces true when logout succeeded or user was already logged out
   * otherwise produces the http error
   */
  private makeLogoutRequest(): Observable<boolean> {
    return this.authService.logout().pipe(
      catchError((error) => {
        if (error.status && error.status === 401) {
          // 401 is expected, it happens when we were already logged out
          return of(true);
        } else {
          throw new BxHttpError('Http error in AuthEffects.makeLogoutRequest', error);
        }
      }),
      mapTo(true),
    );
  }
}

type ErrorCode = 'InvalidIdentifierOrPassword' | 'AuthMethodNotAllowed' | 'UserNotFound';
