@if (data$ | async; as data) {
  <bx-column-chart
    class="annotation-chart"
    [animations]="true"
    [data]="data.data"
    [title]="data.title"
    [xAxisTitle]="data.xAxisTitle"
    [yAxisTitle]="data.yAxisTitle"
  >
  </bx-column-chart>
}
