import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { OverlaysService } from './overlays.service';
import { OverlayEvent, OverlayEventType } from './overlays-events.model';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../cleanup';
import { AsyncPipe } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'bx-overlays',
  templateUrl: './overlays.component.html',
  styleUrls: ['./overlays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LoadingComponent, AsyncPipe],
})
export class OverlaysComponent extends CleanUp implements OnInit {
  @Input() key: string;
  // Hide by default
  @HostBinding('class.hide') hide = true;

  overlayEvents$: Observable<OverlayEvent>;

  constructor(private overlaysService: OverlaysService) {
    super();
  }

  ngOnInit(): void {
    this.overlayEvents$ = this.overlaysService.getEventsByKey(this.key);
    this.overlayEvents$
      .pipe(
        map((event) => event.type !== OverlayEventType.HIDE),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((showOverlay) => {
        this.hide = !showOverlay;
      });
  }
}
