import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgForm, FormsModule } from '@angular/forms';
import { ErrorFormatter } from '../../../error-formatter.service';
import { LogoComponent } from '../../../../shared/logo/logo.component';

import { PasswordStrengthComponent } from '../../../../shared/password-strength/password-strength.component';
import { ValidatePasswordDirective } from '../../../../shared/password-strength/validate-password.directive';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';
import { ValidateEqualDirective } from '../../../../shared/equals/validate-equal.directive';
import { SpinnerButtonComponent } from '../../../../shared/spinner-button/spinner-button.component';
import { AuthService } from '@geneious/nucleus-api-client';

@Component({
  selector: 'bx-change-password',
  templateUrl: './change-password.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    PasswordStrengthComponent,
    ValidatePasswordDirective,
    NgFormControlValidatorDirective,
    ValidateEqualDirective,
    RouterLink,
    SpinnerButtonComponent,
  ],
})
export class ChangePasswordComponent implements OnInit {
  public password: string;
  public confirmPassword: string;
  waiting: boolean;
  private token: string;
  changePasswordErrors: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private errorFormatter: ErrorFormatter,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.fragment;
  }

  public submit(changePasswordForm: NgForm) {
    if (changePasswordForm.valid) {
      this.waiting = true;
      this.changePasswordErrors = null;
      this.authService.consume({ token: this.token, newPassword: this.password }).subscribe({
        next: () => {
          this.router.navigate(['/reset-password/complete']);
          this.waiting = false;
        },
        error: (error) => {
          this.waiting = false;
          this.changePasswordErrors = this.errorFormatter.toUserReadableError(
            error,
            'Password could not be changed.',
          );
        },
      });
    }
  }
}
