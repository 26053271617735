import { AfterViewInit, Directive, Host, HostListener, Input, OnInit } from '@angular/core';
import { IOutputData, SplitComponent } from 'angular-split';
import { IArea } from 'angular-split';
import { first, map } from 'rxjs/operators';
import {
  VIEWERS_DEFAULT_HEIGHT_PERCENT,
  VIEWERS_MIN_HEIGHT_PERCENT,
  VIEWERS_MIN_HEIGHT_PIXELS,
} from '../viewers-v2/viewers/viewers-helpers';
import { ViewersStateStoreService } from './viewer-state-store/viewers-state-store.service';
import { ViewerHeight } from './viewer-state-store/viewers-state.interface';

/**
 * Handles ViewerState operations for viewer size. Adding this directive to an as-split
 * component means that you do not have to set or update the size & minSize inputs for
 * the as-split-area containing the viewer.
 */
@Directive({
  selector: 'as-split[bxViewersState]',
  standalone: true,
})
export class ViewersStateDirective implements AfterViewInit, OnInit {
  /** The key that represents this viewer in the app state. */
  @Input() bxViewersState!: string;
  /** The 0-based index of the split area that the viewer is in. */
  @Input() bxViewerIndex: number;

  constructor(
    @Host() private readonly host: SplitComponent,
    private readonly viewersStateStoreService: ViewersStateStoreService,
  ) {}

  ngOnInit() {
    if (!this.bxViewerIndex) {
      throw new Error('bxViewerIndex not set');
    }
    if (this.host.unit !== 'percent') {
      throw new Error(
        'as-split components using the bxViewersState directive must use percentage units.',
      );
    }
  }

  ngAfterViewInit(): void {
    this.viewersStateStoreService
      .getViewerStateHeight(this.bxViewersState)
      .pipe(
        first(),
        map((size) => this.processSavedHeight(size)),
      )
      .subscribe((size) => this.restoreViewerSize(size));
    if (!this.viewerArea.minSize) {
      this.viewerArea.component.minSize =
        this.host.unit === 'pixel' ? VIEWERS_MIN_HEIGHT_PIXELS : VIEWERS_MIN_HEIGHT_PERCENT;
    }
  }
  /**
   * Returns object with information about the SplitArea that contains the
   * viewer. To update values you must set them on the enclosed
   * SplitAreaDirective component rather than the top-level IArea object.
   *
   * @returns The status of the SplitArea containing the viewer
   */
  get viewerArea(): IArea {
    return this.host.displayedAreas[this.bxViewerIndex];
  }
  /**
   * Called when the user finishes resizing a gutter. Updates the viewer state
   * store with the new size of the viewer.
   *
   * @param event Drag event data containing area sizes
   */
  @HostListener('dragEnd', ['$event'])
  onViewerResizeEnd(event: IOutputData): void {
    const size = event.sizes[this.bxViewerIndex];
    if (size !== '*') {
      this.viewersStateStoreService.updateHeight(this.bxViewersState, {
        unit: 'percent',
        height: size,
      });
    }
  }

  private processSavedHeight(size: ViewerHeight): ViewerHeight<'percent'> | undefined {
    if (!size) {
      return undefined;
    }
    const windowSize = this.currentWindowSize();
    let height: number = size.height;
    if (this.host.unit === 'percent' && size.unit === 'pixel') {
      height = [(height * 100) / windowSize, 80, VIEWERS_MIN_HEIGHT_PERCENT].sort(
        (a, b) => a - b,
      )[1];
    }
    if (this.host.unit === 'pixel' && size.unit === 'percent') {
      height = [(height * windowSize) / 100, 0.8 * windowSize, VIEWERS_MIN_HEIGHT_PIXELS].sort(
        (a, b) => a - b,
      )[1];
    }
    return {
      unit: 'percent',
      height: height,
    };
  }

  private currentWindowSize(): number {
    return this.host.direction === 'horizontal' ? window.innerWidth : window.innerHeight;
  }

  /**
   * Sets the viewer size to the value from the store. If there is no stored
   * value, the default value is used.
   *
   * @param savedSize The saved size from the store
   */
  private restoreViewerSize(savedSize: ViewerHeight<'percent'> | undefined): void {
    // Percentage-based sizes must be set on all areas at once, as they must add up to 100
    const targetSize = savedSize?.height ?? VIEWERS_DEFAULT_HEIGHT_PERCENT;
    // Assert sizes are numbers - wildcards aren't allowed for percentage-based heights
    const areaSizes = this.host.getVisibleAreaSizes() as number[];
    // Check if size is already correct. Likely to happen on an initial load as the default is 50.
    const existingSize = areaSizes[this.bxViewerIndex];
    if (targetSize === existingSize) {
      return;
    }
    // Share the difference in size amongst other areas
    const deltaSize = (existingSize - targetSize) / (areaSizes.length - 1);
    const newSizes = areaSizes.map((size) => size + deltaSize);
    newSizes[this.bxViewerIndex] = targetSize;
    const successful = this.host.setVisibleAreaSizes(newSizes);
    if (!successful) {
      throw new Error(`Failed to set viewer sizes from ${areaSizes.join()} to ${newSizes.join()}`);
    }
  }
}
