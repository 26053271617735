import { JobConfiguration } from '@geneious/nucleus-api-client';
import { AntibodyAnnotatorOptionValues } from 'src/app/core/pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import { OutputOptions, SelectionOptionsV1 } from './jobParameters.model';
import { NGSJobOptionsV1 } from './ngsOptions.model';

export class AddClusterParams implements JobConfiguration {
  pipeline = {
    name: 'add-cluster',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: NGSJobOptionsV1<AntibodyAnnotatorOptionValues>;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: NGSJobOptionsV1<AntibodyAnnotatorOptionValues>;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}
