import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import {
  GraphControlTypeEnum,
  GraphSidebarControl,
} from '../../../../../features/graphs/graph-sidebar';
import { AppState } from '../../../../core.store';
import { Store } from '@ngrx/store';
import {
  selectDataForNgsDocument,
  selectGraphParamsForNgsDocument,
} from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GraphHeatmapComponent } from '../../../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { ngsGraphActions } from '../../ngs-graph-data-store/ngs-graph-data-store.actions';
import { HeatmapInfo } from '../../../../../features/graphs/graph-heatmap/heatmap-info.model';
import { DownloadBlobService } from '../../../../download-blob.service';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { GraphUtilService, GridData } from '../../../../../features/graphs/graph-util.service';
import { NgsReportService } from '../../../ngs-report-viewer/ngs-report.service';
import { RowWithGene } from '../../../../../features/graphs/gene-combinations-heatmap.service';
import { isChainCombinationsTable } from '../../../table-type-filters';
import { DocumentTableStateService } from '../../../../document-table-service/document-table-state/document-table-state.service';
import { DocumentTable } from '../../../../../../nucleus/services/documentService/types';
import { GeneCombinationsHeatmapService } from '../../../../../features/graphs/gene-combinations-heatmap.service';
import { sortGeneCombinationName } from '../../../../../shared/sort.util';
import { AsyncPipe } from '@angular/common';
import { ClientGridComponent } from '../../../../../features/grid/client-grid/client-grid.component';

@Component({
  selector: 'bx-ngs-gene-combinations-graph',
  templateUrl: './ngs-gene-combinations-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClientGridComponent, GraphHeatmapComponent, AsyncPipe],
})
export class NgsGeneCombinationsGraphComponent
  extends NgsBaseGraphComponent<HeatmapInfo, GraphHeatmapComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(GraphHeatmapComponent) chartComponent: GraphHeatmapComponent;

  rawData$: Observable<Record<string, RowWithGene[]>>;
  data$: Observable<HeatmapInfo>;
  regionControl$ = new ReplaySubject<string>(1);
  region$: Observable<string>;
  chainCombinationsRegions$: Observable<string[]>;
  regions$: Observable<string[]>;
  isTableControl$ = new BehaviorSubject(false);
  isTable$: Observable<boolean>;
  isWrapped$: Observable<boolean>;
  isWrappedControl$ = new BehaviorSubject(false);

  isTransposedControl$ = new BehaviorSubject(false);
  isReducedControl$ = new BehaviorSubject(false);
  isTransposed$: Observable<boolean>;
  isReduced$: Observable<boolean>;
  computeHeatmapFromTable$: Observable<boolean>;

  chainCombinationsTable$: Observable<DocumentTable | null>;

  constructor(
    protected store: Store<AppState>,
    private documentTableStateService: DocumentTableStateService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(
      ngsGraphActions.params.options.update({
        id: this.documentID,
        graphId: 'geneCombinations',
        value: {
          options: {
            computeHeatmapFromTable: false,
            tableData: null,
          },
        },
      }),
    );
    this.chainCombinationsTable$ = this.documentTableStateService
      .getTablesMap(this.documentID)
      .pipe(map((tables) => Object.values(tables).find((x) => isChainCombinationsTable(x))));
    this.region$ = this.regionControl$.pipe(distinctUntilChanged());
    this.chainCombinationsRegions$ = this.getRegionsFromChainCombinationsTable();
    this.regions$ = combineLatest([
      this.getRegionsFromReport(),
      this.chainCombinationsRegions$,
    ]).pipe(map(([report, cc]) => [...report, ...cc].sort(sortGeneCombinationName)));
    this.regions$.pipe(take(1)).subscribe((regions) => this.regionControl$.next(regions[0]));
    this.rawData$ = this.store.pipe(
      selectDataForNgsDocument<'geneCombinations'>(this.documentID, 'geneCombinations'),
      takeUntil(this.ngUnsubscribe),
      filter((data) => !!data),
      map((data) => data.statistics.geneCombinations),
    );
    this.isTable$ = this.isTableControl$.pipe(distinctUntilChanged());
    this.isTable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isTable) => {
      this.pngExportEnabled$.next(!isTable);
    });
    this.isTransposed$ = this.isTransposedControl$.pipe(distinctUntilChanged());
    this.isReduced$ = this.isReducedControl$.pipe(distinctUntilChanged());
    this.data$ = combineLatest([
      this.rawData$,
      this.region$,
      this.isTable$,
      this.isTransposed$,
      this.isReduced$,
    ]).pipe(
      filter(([data, region]) => !!data[region]),
      map(([data, region, isTable, transposed, reduced]) => {
        const thisData = data[region];
        const sharedInfo = GeneCombinationsHeatmapService.getGeneCombinationTitleAndAxes(
          region,
          transposed,
        );
        if (isTable) {
          return {
            ...sharedInfo,
            data: GraphUtilService.rowsToTable(thisData, 'Gene', sortGeneColumnFirst),
            type: 'table',
          };
        }
        return {
          ...sharedInfo,
          data: NgsReportService.transformHeatmapData(
            reduced ? GeneCombinationsHeatmapService.collapseHeatmapData(thisData) : thisData,
            transposed,
          ),
          type: 'heatmap',
        };
      }),
    );
    this.computeHeatmapFromTable$ = combineLatest([
      this.region$,
      this.chainCombinationsRegions$,
    ]).pipe(map(([region, ccRegions]) => ccRegions.includes(region)));
    combineLatest([
      this.computeHeatmapFromTable$,
      this.region$.pipe(distinctUntilChanged()),
      this.isReduced$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([computeHeatmapFromTable, geneCombination, reduced]) => {
        this.store.dispatch(
          ngsGraphActions.params.options.update({
            id: this.documentID,
            graphId: 'geneCombinations',
            value: {
              options: {
                computeHeatmapFromTable,
                tableData: {
                  geneCombination,
                  reduced,
                },
              },
            },
          }),
        );
      });
    this.selectedParams$ = this.store.select(selectGraphParamsForNgsDocument(this.documentID));

    const controlsBeforeFilter$ = this.regions$.pipe(
      map(
        (regions) =>
          [
            {
              name: 'region',
              label: 'Genes',
              type: GraphControlTypeEnum.SELECT,
              defaultOption: regions[0],
              options: regions.map((name) => ({
                displayName: name,
                value: name,
              })),
            },
            {
              name: 'table',
              label: 'View Table',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: false,
            },

            {
              name: 'wrapped',
              label: 'Wrapped',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: false,
            },
            {
              name: 'reduced',
              label: 'Show Gene Family only',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: false,
            },
            {
              name: 'transposed',
              label: 'Swap axes',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: false,
            },
          ] as GraphSidebarControl[],
      ),
    );
    combineLatest([this.isTable$, controlsBeforeFilter$])
      .pipe(
        withLatestFrom(this.region$, this.isTransposed$, this.isReduced$),
        map(([[table, controlsBeforeFilter], region, transposed, reduced]) => {
          return controlsBeforeFilter
            .filter((c) => !table || !['wrapped', 'reduced', 'transposed'].includes(c.name))
            .map((c) => {
              if (table && c.name === 'table') {
                return { ...c, defaultOption: true };
              } else if (c.name === 'region') {
                return { ...c, defaultOption: region };
              } else if (c.name === 'transposed') {
                return { ...c, defaultOption: transposed };
              } else if (c.name === 'reduced') {
                return { ...c, defaultOption: reduced };
              }
              return c;
            }) as GraphSidebarControl[];
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.controls$);

    this.isWrapped$ = this.isWrappedControl$.pipe(distinctUntilChanged());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.isTableControl$.complete();
    this.isWrappedControl$.complete();
    this.regionControl$.complete();
    this.isTransposedControl$.complete();
    this.isReducedControl$.complete();
  }

  onControlsChanged({ table, wrapped, reduced, transposed, region }: any) {
    this.isTableControl$.next(table);
    this.regionControl$.next(region);
    if (reduced !== undefined) {
      this.isReducedControl$.next(reduced);
    }
    if (transposed !== undefined) {
      this.isTransposedControl$.next(transposed);
    }
    if (wrapped !== undefined) {
      this.isWrappedControl$.next(wrapped && !table);
    }
  }

  exportAsTable() {
    combineLatest([this.rawData$, this.region$])
      .pipe(
        take(1),
        map(([data, region]) => {
          return [
            GraphUtilService.rowsToTable(data[region], 'Gene', sortGeneColumnFirst),
            region,
          ] as [GridData, string];
        }),
      )
      .subscribe(([table, region]) => {
        const fileName = `Gene Combinations ${region}`;
        const headers = table.cols.map((col) => col.field);
        const rows = table.rows.map((row) => headers.map((header) => row[header]));

        const CSVheader = headers.join(',');
        const CSVbody = rows.map((row: any) => row.join(',')).join('\n');

        DownloadBlobService.download(`${fileName}.csv`, CSVheader + '\n' + CSVbody);
      });
  }

  private getRegionsFromReport(): Observable<string[]> {
    return this.store.pipe(
      selectDataForNgsDocument<'geneCombinations'>(this.documentID, 'geneCombinations'),
      filter((data) => !!data),
      take(1),
      map((data) => Object.keys(data.statistics.geneCombinations)),
    );
  }

  private getRegionsFromChainCombinationsTable() {
    return this.chainCombinationsTable$.pipe(
      map((chainCombinations) => {
        return (
          chainCombinations?.columns
            .filter((col) => col.name.endsWith(' Gene') && col.name.includes('Heavy-Light'))
            .map((col) => col.name) ?? []
        );
      }),
      take(1),
    );
  }
}

const sortGeneColumnFirst = (a: string, b: string) => {
  if (a === 'Gene') {
    return -1;
  }

  if (b === 'Gene') {
    return 1;
  }

  return 0;
};
