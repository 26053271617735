import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getSequenceCount } from '../../../../nucleus/v2/models/item.v2.model';

export function sequenceImportValidator(isCreatingDatabase: boolean): ValidatorFn {
  return function (group: AbstractControl): ValidationErrors | null {
    if (group instanceof FormGroup && group.get('sequencesSelected')) {
      const numberOfSequencesSelected: number = getSequenceCount(
        group.get('sequencesSelected').value,
      );
      if (numberOfSequencesSelected === 0) {
        return { empty: 'No sequences selected' };
      } else if (!isCreatingDatabase && numberOfSequencesSelected > 1000) {
        return { invalidSelection: 'Can only add up to 1000 sequences at a time to a Collection' };
      } else {
        return null;
      }
    } else {
      throw new Error(
        'Validator only supported for FormGroups with child control named "sequencesSelected"',
      );
    }
  };
}
