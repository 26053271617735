import { DialogService } from '../../../shared/dialog/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

export function handleTokenValidateErrors(
  dialogService: DialogService,
  router: Router,
  geneiousProduct: string,
  error: HttpErrorResponse,
  context: 'reset-password' | 'account-setup' | 'change-email',
): Observable<UrlTree> {
  if (error.status === 502 || error.status === 504) {
    showAlertDialog(
      dialogService,
      'Server Unreachable',
      'You were unable to contact the Geneious Biologics server. We may be having server trouble, or your organisation may' +
        'have a configuration that blocks you accessing Geneious. Please contact your system administrator or' +
        '<a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">Geneious Support</a> for help',
    );
    return of(router.createUrlTree(['']));
  } else if (error.status === 503) {
    showAlertDialog(
      dialogService,
      'Server Temporary Unavailable',
      'Geneious Biologics servers may be down for scheduled maintenance. Please contact <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">Geneious Support</a> if you require assistance',
    );
    return of(router.createUrlTree(['']));
  } else if (error.status === 404 && context === 'reset-password') {
    showAlertDialog(
      dialogService,
      'Link invalid or expired',
      'You can get a new link by resetting your password again',
    );
    return of(router.createUrlTree(['/reset-password/request']));
  } else if (error.status === 404 && context === 'account-setup') {
    showAlertDialog(
      dialogService,
      'Link invalid or expired',
      `Either your user is already created or the link has expired. Contact your administrator if you require a new link`,
    );
    if (geneiousProduct === 'prime') {
      return of(router.createUrlTree(['/reset-password/request']));
    } else {
      return of(router.createUrlTree(['']));
    }
  } else if (error.status === 404 && context === 'change-email') {
    showAlertDialog(
      dialogService,
      'Link invalid or expired',
      `Either your email address is already updated or the link has expired. Contact your administrator if you require a new link`,
    );
    return of(router.createUrlTree(['']));
  } else {
    showAlertDialog(
      dialogService,
      'Unexpected Server Error',
      `Contact support if this error continues <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">https://help.geneiousbiologics.com/hc/en-us/requests/new</a>`,
    );
    return of(router.createUrlTree(['']));
  }
}

function showAlertDialog(dialogService: DialogService, title: string, content: string) {
  dialogService.showAlertDialog({
    title,
    content,
    buttonText: 'Ok',
  });
}
