import { clearGridState, setVisibleGridState, updateGridState } from './grid-state.actions';
import { GridServerStates } from './grid-state.interfaces';
import { createReducer, on } from '@ngrx/store';

export const initialGridStates: GridServerStates = {};

export const gridStateReducer = createReducer(
  initialGridStates,
  on(updateGridState, (state, { id, data }) => {
    return {
      ...state,
      [id]: {
        columnsState: data.columnsState
          .filter((col) => col.colId !== 'selected')
          .map((col) => ({ ...col })),
      },
    };
  }),
  on(setVisibleGridState, (state, { id, columns }) => {
    return {
      ...state,
      [id]: {
        ...state[id],
        columnsState: state[id].columnsState.map((col) => {
          if (columns.includes(col.colId)) {
            return {
              ...col,
              hide: false,
            };
          }
          return col;
        }),
      },
    };
  }),
  on(clearGridState, (state, { id }) => {
    return {
      ...state,
      [id]: {
        columnsState: [],
      },
    };
  }),
);
