import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { NgsSequenceViewerService } from '../ngs-sequence-viewer.service';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { annotatedPluginDocumentViewerSelector } from '../../viewer-components/viewer-selectors';
import {
  isViewerResultData,
  ViewerDocumentData,
  ViewerMultipleTableDocumentSelection,
} from '../../viewer-components/viewer-document-data';
import { AnnotatedPluginDocument } from '../../geneious';
import { DocumentSelectionSignature } from '../../document-selection-signature/document-selection-signature.model';
import { selectionStateV2ToViewerMultipleTableDocumentSelection } from '../../viewer-components/viewers-helper';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import { SelectionStateV2 } from '../../../features/grid/grid.component';
import { DocumentTableQueryService } from '../../document-table-service/document-table-state/document-table-query.service';
import {
  NgsSequenceViewerBaseComponent,
  NGSSequenceViewerData,
} from './ngs-sequence-viewer-base.component';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { SequenceViewerPreferencesService } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.service';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';
import { SequenceViewerMetadataService } from '../../sequence-viewer/sequence-viewer-metadata.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { AsyncPipe } from '@angular/common';
import { NgsDocumentRestoreScreenComponent } from '../ngs-document-restore-screen/ngs-document-restore-screen.component';
import { NgsSequencePreviewInfoBoxComponent } from '../ngs-sequence-preview-info-box/ngs-sequence-preview-info-box.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { SequenceViewerComponent } from '../../../features/sequence-viewer-angular/sequence-viewer.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@ViewerComponent({
  key: 'ngs-sequence-viewer',
  title: 'Sequences Preview',
  selector: annotatedPluginDocumentViewerSelector(
    [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyAnnotatorDocument',
        1,
        1,
      ),
    ],
    (viewerData) => viewerData.isPreviewView,
  ),
})
@Component({
  selector: 'bx-ngs-sequence-viewer-preview',
  templateUrl: './ngs-sequence-viewer-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgsDocumentRestoreScreenComponent,
    NgsSequencePreviewInfoBoxComponent,
    PageMessageComponent,
    SequenceViewerComponent,
    LoadingComponent,
    AsyncPipe,
  ],
})
export class NgsSequenceViewerPreviewComponent
  extends NgsSequenceViewerBaseComponent<ViewerDocumentData>
  implements OnInit, OnDestroy
{
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden';
  isPreviewTableArchived$: Observable<boolean>;
  documentID$: Observable<string>;
  tablesLoaded$: Observable<boolean>;
  tablesLoadingError$: Observable<string>;
  exportAsImageButtonDisabled$: Observable<boolean>;
  openQueryParams$: Observable<ViewerPageURLSelectionState>;

  static readonly MAX_NUMBER_OF_ROWS_FOR_PREVIEW = 100;
  readonly PREVIEW_ROW_LIMIT = NgsSequenceViewerPreviewComponent.MAX_NUMBER_OF_ROWS_FOR_PREVIEW;
  readonly DEFAULT_TABLE = 'DOCUMENT_TABLE_ALL_SEQUENCES';

  private annotatorDocument$: Observable<AnnotatedPluginDocument>;

  constructor(
    viewerDataService: ViewerDataService<ViewerDocumentData>,
    sequenceViewerPreferencesService: SequenceViewerPreferencesService,
    svMetadataService: SequenceViewerMetadataService,
    store: Store<AppState>,
    private sequenceViewerService: NgsSequenceViewerService,
    private documentTableStateService: DocumentTableStateService,
    private documentTableQueryService: DocumentTableQueryService,
  ) {
    super(viewerDataService, sequenceViewerPreferencesService, svMetadataService, store);
    this.documentID$ = this.annotatorDocument$.pipe(map((document) => document.id));
    this.isPreviewTableArchived$ = this.documentID$.pipe(
      switchMap((documentID) =>
        this.documentTableStateService
          .getTable(documentID, this.DEFAULT_TABLE)
          // If the table doesn't exist, it will throw an error.
          .pipe(catchError(() => of(undefined))),
      ),
      map((table) => table?.indexState === 'archived'),
      startWith(false),
    );
    const fetchingState$ = this.documentID$.pipe(
      switchMap((documentID) => this.documentTableStateService.getTablesFetchingState(documentID)),
    );
    this.tablesLoadingError$ = fetchingState$.pipe(
      filter((fetchingState) => fetchingState.error != null),
      map((fetchingState) => fetchingState.error),
    );

    this.tablesLoaded$ = fetchingState$.pipe(
      map((fetchingState) => !fetchingState.fetching && !fetchingState.error),
    );
  }

  ngOnInit() {
    super.ngOnInit();
    // TODO Remove this limit once BX-5983 is finished.
    this.exportAsImageButtonDisabled$ = combineLatest([this.sequences$, this.viewerData$]).pipe(
      map(
        ([sequences, data]) =>
          sequences.length > 200 || ((isViewerResultData(data) && data.isAdminView) ?? false),
      ),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  transformViewerData$(
    initialData$: Observable<ViewerDocumentData>,
  ): Observable<NGSSequenceViewerData> {
    this.openQueryParams$ = initialData$.pipe(map((data) => data.openQueryParams));

    // The first row is guaranteed to be annotator result because of the viewer selector
    this.annotatorDocument$ = initialData$.pipe(
      map((data) => data.selection),
      map((selection) => selection.rows[0]),
    );

    const allSequencesTable$ = this.annotatorDocument$.pipe(
      switchMap((document) =>
        this.documentTableStateService
          .getQueryableTable(document.id, this.DEFAULT_TABLE)
          // If the table doesn't exist, it will throw an error.
          .pipe(catchError(() => of(undefined))),
      ),
      filter((table) => !!table),
    );

    const annotatorResultFirstRows$ = allSequencesTable$.pipe(
      switchMap((table) =>
        this.documentTableQueryService.queryTable(table.documentID, table.name, {
          limit: this.PREVIEW_ROW_LIMIT,
        }),
      ),
      map((response) => response.data),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return combineLatest([
      allSequencesTable$,
      this.annotatorDocument$,
      annotatorResultFirstRows$,
    ]).pipe(
      map(([allSequencesTable, annotatorResultDocument, rows]) => {
        const tableSelection = new SelectionStateV2();
        tableSelection.ids = rows.map((row: any) => row.row_number);
        tableSelection.selectedRows = rows;
        tableSelection.rows = rows;
        tableSelection.totalSelected = rows.length;
        tableSelection.total = rows.length;
        tableSelection.selectAll = false;
        const selection: ViewerMultipleTableDocumentSelection =
          selectionStateV2ToViewerMultipleTableDocumentSelection(
            tableSelection,
            allSequencesTable.tableType,
            annotatorResultDocument,
          );

        return {
          selection,
          resource: this.sequenceViewerService,
          selectedTable: allSequencesTable,
          columns: [],
        };
      }),
    );
  }
}
