import { Injectable } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { first, map, shareReplay, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { AppState } from '../core.store';
import { selectLabels } from '../organization-settings/organization-settings.selectors';

/** Contains form validator functions for Labels. */
@Injectable()
export class LabelValidators extends CleanUp {
  readonly names$ = this.store.pipe(
    select(selectLabels),
    map((settings) => settings.map((setting) => setting.name.toLowerCase())),
    shareReplay(1),
    takeUntil(this.ngUnsubscribe),
  );

  constructor(private readonly store: Store<AppState>) {
    super();
  }

  /** Returns all label name validators as an AbstractControlOptions object. */
  nameValidators(): AbstractControlOptions {
    return {
      asyncValidators: [this.nameIsUnique],
      validators: [this.nameIsNotEmpty, this.nameDoesNotExceedMaxLength],
    };
  }

  /**
   * Validator function for the label name control that ensures the value is not
   * empty or whitespace only.
   *
   * @param control label name control to validate
   * @returns the error if present, otherwise null
   */
  nameIsNotEmpty: ValidatorFn = (control) => {
    const name = control.value;
    if (!name || name.trim().length === 0) {
      return { empty: 'Set a name for your new label' };
    }
    return null;
  };

  /**
   * Validator function for the label name control that ensures that the value
   * does not exceed 128 characters. There is no limit on label name length in
   * Nucleus, but we impose a limit to prevent UI glitches.
   *
   * @param control label name control to validate
   * @returns the error if present, otherwise null
   */
  nameDoesNotExceedMaxLength: ValidatorFn = (control) => {
    if (control.value && control.value.trim().length > 100) {
      return { tooLong: 'Label name exceeds maximum length' };
    }
    return null;
  };

  /**
   * Async validator function for the label name control that ensures the name
   * does not clash with an existing label.
   *
   * @param control label name control to validate
   * @returns the error if present, otherwise null
   */
  nameIsUnique: AsyncValidatorFn = (control) => {
    const name = control.value?.trim().toLowerCase();
    return this.names$.pipe(
      first(),
      map((names) =>
        names.includes(name) ? { unique: 'A label with this name already exists' } : null,
      ),
    );
  };
}
