@if ((numberOfTables$ | async) > 1) {
  <div class="row p-2" data-spec="table-switcher">
    <div class="col"></div>
    @if ((numberOfTables$ | async) > 0) {
      <div class="btn-group btn-group-sm col-sm-8 col-md-7 col-lg-6 col-xl-5">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="previousTable()"
          [disabled]="(currentTableIndex$ | async) === 0"
        >
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
        <div
          class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-bottom"
          [title]="(currentTable$ | async)?.displayName"
        >
          <div class="d-inline-block text-center text-truncate py-1">
            {{ (currentTable$ | async)?.displayName }}
          </div>
        </div>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="nextTable()"
          [disabled]="currentTableIndex$.getValue() + 1 >= (numberOfTables$ | async)"
        >
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>
    }
    <div class="col"></div>
  </div>
}

<small class="d-flex align-items-center text-muted fw-bold text-truncate p-2">
  {{ message$ | async }}
</small>

@if ((isLoading$ | async) === false) {
  <bx-client-grid
    [columnDefs]="tableHeaders$ | async"
    [rowData]="tableRows$ | async"
    [showTotalSelected]="false"
    class="flex-grow-1 flex-shrink-1"
  ></bx-client-grid>
} @else {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
}
