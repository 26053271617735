import { Component, HostBinding } from '@angular/core';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { CleanUp } from '../../../shared/cleanup';
import { GlobalSearchParams, SearchBarComponent } from '../search-bar/search-bar.component';

@Component({
  selector: 'bx-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [SearchBarComponent],
})
export class SearchComponent extends CleanUp {
  @HostBinding('class') readonly hostClass = 'd-block';
  constructor(
    private searchService: SearchService,
    private router: Router,
  ) {
    super();
  }

  search(query: GlobalSearchParams) {
    const params = {
      queryString: query.term,
      queryType: query.type,
      pagination: { offset: 0, limit: 1000 },
      sort: '',
    };

    this.searchService.updateQuery(params, this.router.url);
    if (this.router.url !== '/search-results') {
      this.router.navigate(['/search-results']);
    }
  }
}
