import { Directive, HostListener, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { filter, first, map, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Folder, TreeItem } from '../../folders/models/folder.model';
import { FolderUtils } from '../../folders/folder.utils';
import { PermissionsService } from '../../permissions/permissions.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { FolderService } from '../../folders/folder.service';
import { FolderMovingService } from './folder-moving.service';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { CursorMessageService } from '../../../shared/cursor-message/cursor-message.service';

const MOVE_FOLDER_ERROR_HEADER = 'Folder moving error';

@Directive({
  selector: '[bxFolderMove]',
  standalone: true,
})
export class FolderMovingDirective implements OnInit, OnDestroy {
  @Input() bxFolderMove: TreeItem;
  @Input() dragOverClass = 'folder-drag-over';
  @Input() draggingClass = 'dragging-folder';

  private folder: Folder;
  private isDraggingOver$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isImmutable$: Observable<boolean>;
  private isInvalidDropTarget$: Observable<boolean>;
  private disablePointerEvent$: Observable<boolean>;
  private isDraggingFolder$: Observable<boolean>;
  private eventStream$: ReplaySubject<FolderMovingEvent> = new ReplaySubject<FolderMovingEvent>(1);
  private subscriptions = new Subscription();

  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    private toastService: ToastService,
    private folderService: FolderService,
    private folderMovingService: FolderMovingService,
    private dialogService: DialogService,
    private cursorMessageService: CursorMessageService,
  ) {}

  ngOnInit() {
    this.folder = this.bxFolderMove as Folder;
    this.elementRef.nativeElement.draggable = true;
    this.disablePointerEvent$ = this.folderMovingService.draggingFolder$.pipe(
      map((folder) => !!folder),
    );
    this.isImmutable$ = this.folderMovingService.isFolderImmutable(this.folder);

    this.isInvalidDropTarget$ = this.folderMovingService.draggingFolder$.pipe(
      switchMap((draggingFolder) => {
        if (!draggingFolder) {
          // No folder is currently dragging, anything is possible, don't gray out anything
          return of(false);
        }

        return this.isFolderMoveValid(draggingFolder, this.folder).pipe(
          map((canMoveStatus) => canMoveStatus !== true),
        );
      }),
    );

    this.isDraggingFolder$ = this.folderMovingService.draggingFolder$.pipe(
      map((folder) => folder && folder.id === this.folder.id),
    );

    const immutableClassBinding$ = combineLatest([
      this.isImmutable$.pipe(startWith(false)),
      this.isInvalidDropTarget$.pipe(startWith(false)),
    ]).pipe(map(([isImmutable, isInvalidDropTarget]) => isImmutable || isInvalidDropTarget));

    this.bindClass(immutableClassBinding$, 'immutable');
    this.bindClass(this.isDraggingOver$, ...this.dragOverClass.split(' '));
    this.bindClass(this.disablePointerEvent$, 'disable-pointer-event');
    this.bindClass(this.isDraggingFolder$, ...this.draggingClass.split(' '));

    this.subscriptions.add(
      this.eventStream$
        .pipe(
          withLatestFrom(
            this.isImmutable$.pipe(startWith(false)),
            this.isInvalidDropTarget$.pipe(startWith(false)),
          ),
          filter(([event, isImmutable, isInvalidDropTarget]) => {
            if (event.eventType === DragAndDropEventType.DragEnter) {
              // In drag enter event, you have to call preventDefault to allow drag enter
              if (!isImmutable) {
                event.nativeEvent.preventDefault();
              } else {
                // Clear cursor message if hover an immutable folder
                this.cursorMessageService.clear();
                return false;
              }
            } else if (event.eventType === DragAndDropEventType.Drop) {
              if (isImmutable || isInvalidDropTarget) {
                return false;
              }
            }
            return true;
          }),
          map(([event, _]) => event),
        )
        .subscribe(this.handleMovingEvent.bind(this)),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.isDraggingOver$.complete();
    this.eventStream$.complete();
  }

  @HostListener('dragstart', ['$event']) onDragStart(event: DragEvent) {
    this.emitFolderMovingEvent(event, DragAndDropEventType.DragStart);
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: DragEvent) {
    this.emitFolderMovingEvent(event, DragAndDropEventType.DragEnd);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    this.emitFolderMovingEvent(event, DragAndDropEventType.DragLeave);
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    this.emitFolderMovingEvent(event, DragAndDropEventType.DragEnter);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.emitFolderMovingEvent(event, DragAndDropEventType.Drop);
  }

  private bindClass(shouldBind$: Observable<boolean>, ...classesToBind: string[]) {
    this.subscriptions.add(
      shouldBind$.subscribe((shouldBind) => {
        if (shouldBind) {
          classesToBind.forEach((classToBind) =>
            this.elementRef.nativeElement.classList.add(classToBind),
          );
        } else {
          classesToBind.forEach((classToBind) =>
            this.elementRef.nativeElement.classList.remove(classToBind),
          );
        }
      }),
    );
  }

  private emitFolderMovingEvent(event: DragEvent, eventType: DragAndDropEventType) {
    this.eventStream$.next({
      nativeEvent: event,
      eventType: eventType,
    });
  }

  private handleMovingEvent(event: FolderMovingEvent) {
    switch (event.eventType) {
      case DragAndDropEventType.DragStart:
        this.handleDragStart(event);
        break;
      case DragAndDropEventType.DragEnd:
        this.handleDragEnd();
        break;
      case DragAndDropEventType.DragLeave:
        this.handleDragLeave();
        break;
      case DragAndDropEventType.DragEnter:
        this.handleDragEnter();
        break;
      case DragAndDropEventType.Drop:
        this.handleDrop();
        break;
    }
  }

  private showMoveFolderWithoutValidPermissionError() {
    this.cursorMessageService.show(`🚫 You don't have permission to move "${this.folder.name}"`);
  }

  private handleDragStart(event: FolderMovingEvent) {
    const isNotDraggable =
      FolderUtils.isDatabaseRelatedFolder(this.folder) ||
      FolderUtils.isSharedWorkspaceFolder(this.folder) ||
      !PermissionsService.hasWriteAccess(this.folder.permissions);

    if (isNotDraggable) {
      if (
        FolderUtils.isFolder(this.folder) &&
        !PermissionsService.hasWriteAccess(this.folder.permissions)
      ) {
        this.showMoveFolderWithoutValidPermissionError();
      }
      event.nativeEvent.preventDefault();
      return;
    }
    this.folderMovingService.setDraggingFolder(this.folder);
  }

  private handleDragEnd() {
    this.isDraggingOver$.next(false);
    this.folderMovingService.clearDraggingFolder();
  }

  private handleDragLeave() {
    this.isDraggingOver$.next(false);
  }

  private handleDragEnter() {
    this.isInvalidDropTarget$.pipe(take(1)).subscribe((isInvalidDropTarget) => {
      if (!isInvalidDropTarget) {
        this.isDraggingOver$.next(true);
      }
    });

    this.folderMovingService.draggingFolder$
      .pipe(
        filter((folder) => !!folder),
        switchMap((draggingFolder) => this.isFolderMoveValid(draggingFolder, this.folder)),
        take(1),
      )
      .subscribe((canMoveStatus) => {
        if (canMoveStatus === true) {
          this.cursorMessageService.clear();
          return;
        }

        this.cursorMessageService.show(`🚫 ${canMoveStatus}`);
      });
  }

  private handleDrop() {
    this.isDraggingOver$.next(false);

    this.folderMovingService.draggingFolder$
      .pipe(
        filter((folder) => !!folder),
        switchMap((draggingFolder) =>
          this.isFolderMoveValid(draggingFolder, this.folder).pipe(
            map((canMoveStatus) => ({ draggingFolder, canMoveStatus })),
          ),
        ),
        switchMap(({ draggingFolder, canMoveStatus }) =>
          this.folderService
            .isBothFoldersWithinSameTree(draggingFolder, this.folder)
            .pipe(map((isWithinSameTree) => ({ draggingFolder, canMoveStatus, isWithinSameTree }))),
        ),
        first(),
      )
      .subscribe(({ draggingFolder, canMoveStatus, isWithinSameTree }) => {
        if (this.folder.id === draggingFolder.parentID) {
          // Do nothing if the dragging folder is dropped to its current parent folder
          return;
        }
        const canResultInPermissionChange =
          !isWithinSameTree && !FolderUtils.isSharedWorkspaceFolder(this.folder);

        if (canMoveStatus === true) {
          if (!canResultInPermissionChange) {
            this.moveFolder(draggingFolder, this.folder);
          } else {
            this.dialogService
              .showConfirmationDialog({
                title: 'Folder Permission Change',
                content: `The folder sharing permissions for "${draggingFolder.name}" will be updated to match "${this.folder.name}". This may affect other people’s access.`,
                confirmationButtonText: 'Move',
              })
              .result.then((confirm: boolean) => {
                if (confirm) {
                  this.moveFolder(draggingFolder, this.folder);
                }
              })
              .catch(() => {});
          }
        }
      });
  }

  private moveFolder(folder: Folder, parentFolder: Folder) {
    this.folderService.move(folder, parentFolder).subscribe({
      error: (error: Error) => {
        this.toastService.danger(error.message, {
          header: MOVE_FOLDER_ERROR_HEADER,
          autohide: false,
        });
      },
    });
  }

  private isFolderMoveValid(folder: Folder, parentFolder: Folder): Observable<true | string> {
    return this.folderService.getChildren(parentFolder.id).pipe(
      first(),
      withLatestFrom(this.folderService.isBothFoldersWithinSameTree(folder, parentFolder)),
      map(([children, isWithinSameTree]) => {
        const canResultInPermissionChange = !isWithinSameTree;

        // If a move can result in permission change, you're required to have full access
        if (canResultInPermissionChange && !PermissionsService.hasFullAccess(folder.permissions)) {
          return `You need full permission over folder "${folder.name}" to move it to "${parentFolder.name}"`;
        }

        // Can't move to parent folder if you don't have write access to
        if (
          !PermissionsService.hasWriteAccess(parentFolder.permissions) &&
          !FolderUtils.isSharedWorkspaceFolder(parentFolder)
        ) {
          return `You don't have permission to move files to "${parentFolder.name}" folder.`;
        }

        // Can't move folder if you don't have write access to that folder
        if (!PermissionsService.hasWriteAccess(folder.permissions)) {
          return `You don't have the permission to move "${folder.name}" folder.`;
        }

        // Can't move to parent folder because it already has a folder with the same name
        if (children.some((child) => child.name === folder.name && child.id !== folder.id)) {
          return `The folder "${parentFolder.name}" already contained a folder with the name "${folder.name}". Please rename either your folder or that folder before moving.`;
        }

        return true;
      }),
    );
  }
}

interface FolderMovingEvent {
  nativeEvent: DragEvent;
  eventType: DragAndDropEventType;
}

enum DragAndDropEventType {
  DragStart,
  DragEnd,
  DragEnter,
  DragLeave,
  Drop,
}
