import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { GridState } from 'src/app/features/grid/grid.interfaces';
import { CursorDocumentQuery } from 'src/nucleus/services/documentService/document-service.v1.http';
import {
  ExportJobParameters,
  NGSTableExportOptions,
  TableOutputType,
} from 'src/nucleus/services/models/exportOptions.model';
import { PipelineDialogData } from '../..';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { toColumnsState } from '../export-helpers';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import { NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { JobResultDownloaderService } from '../../../utils/job-result-downloader.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportTableControlsComponent } from '../export-table-controls/export-table-controls.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'bx-export-ngs-table',
  templateUrl: './export-ngs-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ExportTableControlsComponent, DecimalPipe],
})
export class ExportNGSTableComponent extends JobDialogContent implements RunnableJobDialog {
  readonly title = 'Export Table';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly form = new BxFormGroup({
    tableOutputType: new BxFormControl<TableOutputType>('xlsx'),
    exportedColumns: new BxFormControl<'all' | 'visible'>('all'),
    removeWellIDPadding: new BxFormControl(true),
  });
  readonly tableName = this.dialogData.otherVariables.tableDisplayName;
  readonly numRows = this.dialogData.selected.noOfRowsSelected;
  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<ExportNGSTableDialogData>,
    private jobResultDownloaderService: JobResultDownloaderService,
  ) {
    super('export', PipelineFormID.EXPORT_NGS_TABLE);
    this.formDefaults = this.form.getRawValue();
  }

  run() {
    // Use getRawValue because it includes the value of disabled controls
    const formValue = this.form.getRawValue();

    const { tableName, tableQuery, selection, documentID, gridState } =
      this.dialogData.otherVariables;
    const options: NGSTableExportOptions = {
      exportFormat: 'ngsTable',
      columnsState: toColumnsState(gridState.columnsState, formValue.exportedColumns === 'all'),
      removeWellIDPadding: formValue.removeWellIDPadding,
      tableOutputType: formValue.tableOutputType,
      tableName,
      tableQuery,
      selection,
    };
    const parameters: ExportJobParameters = {
      options,
      selection: {
        folderId: this.dialogData.folderID,
        ids: [documentID],
        selectAll: false,
      },
    };
    return {
      pipeline: { name: 'export', version: VersionEnum.Latest },
      parameters,
    };
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
      newJobResponse.data.jobID,
      'EXPORTED_FILE',
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }
}

export interface ExportNGSTableDialogData {
  gridState: GridState;
  tableDisplayName: string;
  tableName: string;
  tableQuery: CursorDocumentQuery;
  documentID: string;
  selection: {
    ids: string[];
    selectAll: boolean;
  };
}
