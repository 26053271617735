// TODO Remove all instances of Code that uses this.
export default class ViewerState {
  selectAll = false; // Select All State.
  total = 0; // Total number of rows of the source.
  ids: string[] = []; // Selected rows ids.
  rows: any[] = []; // Selected rows objects.
  totalSelected = 0; // No Of selected rows.
}

// TODO Remove all instances of Code that uses this.
// Replace with distinctUntilChanged.
export function hasChanged(lastId: string, newId: string, callback: () => void) {
  const changed = newId !== lastId;
  if (changed) {
    callback();
  }
  return changed;
}
