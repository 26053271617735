@if (
  {
    expanded: expanded$ | async,
    isSaving: isSaving$ | async,
    children: children$ | async,
  };
  as state
) {
  <div class="wrapper">
    <div
      role="button"
      class="header d-flex align-items-center bx-hover-parent position-relative"
      [ngClass]="{ 'bg-selected-folder-tree': selected$ | async }"
      [bxFolderMove]="folder"
      [bxUploadDrop]="folder.id"
      [bxUploadRoute]="folder.route"
      [bxUploadCanDrop]="folder.features.canUpload"
      dragOverClass="folder-drag-over"
      draggingClass="dragging-folder bg-secondary-subtle opacity-50"
      [attr.data-testid]="'folder-' + path"
    >
      <div
        class="dropzone border border-2"
        [ngClass]="folder.features.canUpload ? 'border-primary' : 'border-danger'"
      ></div>
      <fa-icon
        (click)="toggleExpand()"
        [icon]="icons.toggleExpand"
        [rotate]="state.expanded ? 90 : 0"
        size="xs"
        class="px-2 small flex-shrink-0"
        [ngClass]="{ invisible: !state.children?.length }"
        data-testid="folder-expand"
        [attr.aria-label]="state.expanded ? 'Collapse Folder' : 'Expand Folder'"
      ></fa-icon>
      @if (this.folder.getIcon(); as icon) {
        <bx-folder-tree-icon
          [icon]="icon"
          (click)="onClick()"
          (dblclick)="toggleExpand()"
        ></bx-folder-tree-icon>
      }
      <bx-folder-tree-name
        class="flex-grow-1 flex-shrink-1 py-1 pe-1 overflow-hidden"
        [folder]="folder"
        [bxClickManager]
        (bxSingleClick)="onClick()"
        (bxDoubleClick)="toggleExpand()"
        (saveStart)="onSaveStart()"
        (saveEnd)="onSaveEnd()"
      ></bx-folder-tree-name>
      @if (secondaryIcon) {
        <mat-icon class="align-middle flex-shrink-0 text-muted me-1 icon-small pt-1">
          {{ secondaryIcon }}
        </mat-icon>
      }
      @if ((isSaving$ | async) || (isMoving$ | async)) {
        <bx-spinner [small]="true" class="me-2"></bx-spinner>
      }
      @if (folder.actions.length > 0) {
        <span ngbDropdown #moreMenu="ngbDropdown" (openChange)="setMenuShown($event)">
          <span
            (click)="moreMenu.toggle()"
            role="button"
            class="ps-1 pe-2 bx-display-on-parent-hover"
            [ngClass]="{ 'd-block': menuOpen }"
            data-testid="folder-menu-btn"
          >
            <span
              class="invisible"
              ngbDropdownAnchor
              [ngClass]="{ 'dropdown-toggle': false }"
            ></span>
            <fa-icon [icon]="icons.more" size="xs" class="text-muted"></fa-icon>
          </span>
          <div ngbDropdownMenu>
            @if (menuOpen) {
              @for (action of folder.actions; track trackById($index, action)) {
                <button
                  ngbDropdownItem
                  type="button"
                  [ngClass]="{ 'fw-bold': action.bold }"
                  (click)="action.callback()"
                  [attr.data-testid]="'folder-action-' + action.id"
                >
                  {{ action.name }}
                </button>
              }
            }
          </div>
        </span>
      }
    </div>
    @if (createChild$ | async) {
      <bx-new-folder
        class="ms-2"
        [parent]="folder"
        [state]="newFolder$ | async"
        (done)="onStoppedMakingNewChild($event)"
      ></bx-new-folder>
    }
    <!-- DANGER! It's important not to load all the children ahead of time or we use up browser resources
    *ngIf prevents loading all the children -->
    @if (expanded$ | async) {
      <div class="children ms-2">
        @for (child of state.children; track trackById($index, child)) {
          <bx-folder-tree [folder]="child" [parentPath]="path"></bx-folder-tree>
        }
      </div>
    }
  </div>
}
