<div class="modal-header">
  <h4
    class="modal-title d-flex align-items-center"
    data-spec="create-collections-folder-modal-title"
  >
    Create Folder for Collections
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360051956892"
      target="_blank"
      rel="noopener noreferrer"
      class="ms-2 d-flex align-items-center text-decoration-none"
    >
      <mat-icon>info</mat-icon>
    </a>
  </h4>

  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="close('canceled')"
    data-spec="create-collections-folder-close-btn"
  ></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" class="px-3">
    <div class="row align-items-center mb-2">
      <label for="folderName" class="col-4 col-form-label"> Folder name: </label>
      <div class="col-8">
        <input
          type="text"
          class="form-control"
          id="folderName"
          name="folderName"
          formControlName="folderName"
          placeholder="Enter a name"
          data-spec="create-collections-folder-name-input"
        />
      </div>
    </div>
  </form>

  @if ((submitState$ | async) === 'error') {
    <span class="text-danger" data-spec="create-collections-folder-error"
      >An error occurred while creating the folder. Please try again.
    </span>
  }
</div>

@if (submitState$ | async; as submitState) {
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      ngbAutofocus
      bx-spinner-button
      [spin]="submitState === 'submitting'"
      [disabled]="submitState === 'disabled' || submitState === 'submitting'"
      (click)="submit()"
      data-spec="create-collections-folder-submit-btn"
    >
      Create
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="close('canceled')"
      data-spec="create-collections-folder-cancel-btn"
    >
      Close
    </button>
  </div>
}
