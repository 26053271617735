import { GridService } from './grid.service';
import { ICellRendererParams, IHeaderParams } from '@ag-grid-community/core';
import { ColumnState } from '@ag-grid-community/core';

export interface GridState {
  columnsState: ColumnState[];
}

/**
 * Note that this used to be part of grid state prior to AG Grid v28. Now, sort
 * is stored in column state, and this interface should only be used for old
 * IGridResource queries.
 */
export interface SortModel {
  colId: string;
  sort: 'asc' | 'desc';
}

/**
 * Selection state that is determinate as it doesn't use select all.
 * When select all is selected in the Grid, this selection state should contain all the ids/rows that are selected,
 * only if all rows are in memory.
 *
 * If using this SelectionState, then all rows should be forced to be loaded initially (i.e. don't use pagination).
 */
export class DeterminateSelectionState {
  // Total number of rows selected.
  totalSelected = 0;
  // Total number of rows in the table.
  total = 0;
  // ids of each row selected.
  ids: string[] = [];
  // rows of each row selected.
  rows: any[] = [];
}

export interface IHeaderParamsWithGridService extends IHeaderParams {
  gridService: GridService;
}

export interface ICellRendererParamsWithGridService extends ICellRendererParams {
  gridService: GridService;
}
