import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { fetchUISetting, setUISetting, toggleGridSidebarHiddenState } from './ui-settings.actions';
import { selectUISettingsForComponent } from './ui-settings.selectors';
import {
  fetchUserSettingFromServer,
  fetchUserSettingSuccess,
  upsertUserSetting,
} from '../user-settings.actions';
import { UserSettingsKinds } from '../user-settings-types';
import { UISettingState } from './ui-settings.model';

@Injectable()
export class UISettingsEffects {
  fetchUISetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUISetting),
      map(({ targetComponent }) =>
        fetchUserSettingFromServer({
          name: targetComponent,
          kind: UserSettingsKinds.UI_SETTINGS,
        }),
      ),
    ),
  );

  fetchUISettingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingSuccess),
      filter(({ userSetting }) => userSetting.kind === UserSettingsKinds.UI_SETTINGS),
      map(({ userSetting }) =>
        setUISetting({
          targetComponent: userSetting.name,
          setting: userSetting.data as UISettingState[string],
        }),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleGridSidebarHiddenState),
      switchMap(({ targetComponent, hidden }) =>
        this.store.select(selectUISettingsForComponent(targetComponent)).pipe(
          take(1),
          map((uiSettings) =>
            upsertUserSetting({
              kind: UserSettingsKinds.UI_SETTINGS,
              name: targetComponent,
              data: uiSettings,
            }),
          ),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}
}
