<div class="spacer d-inline px-2 ms-2"></div>
<bx-folder-tree-icon [icon]="icon"></bx-folder-tree-icon>
<form
  name="newFolderForm"
  (submit)="onSubmit()"
  class="d-flex align-items-center flex-grow-1 flex-shrink-1 px-1"
>
  <input
    [formControl]="nameControl"
    name="folderName"
    class="name form-control form-control-sm w-100"
    [ngClass]="{ 'is-invalid': error != null }"
    (blur)="onBlur()"
    required
    bxAutofocus
    [ngbTooltip]="error"
    [disableTooltip]="error == null"
    data-testid="new-folder-input"
  />
</form>
