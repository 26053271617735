import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { AnnotatedPluginDocument } from '../geneious';
import { AuthGuard } from './auth.guard';

/**
 * Protect advanced report path.
 */
@Injectable({
  providedIn: 'root',
})
export class AdvancedReportGuard {
  constructor(
    private authGuard: AuthGuard,
    private documentHttpService: DocumentHttpV2Service,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const pdfDocumentID = route.queryParamMap.get('pdfDocumentID');
    return this.authGuard
      .canActivate(route, state)
      .pipe(switchMap((res) => (res === true ? this.hasDocumentAccess(pdfDocumentID) : of(res))));
  }

  hasDocumentAccess(documentID: string): Observable<boolean> {
    return this.documentHttpService.get(documentID).pipe(
      map((item) => AnnotatedPluginDocument.fromNucleusItemV2(item)),
      map((response) => !!response),
      catchError(() => of(false)),
      tap((hasDocumentAccess) =>
        console.log(
          `${
            hasDocumentAccess ? 'Has' : 'Does not have'
          } document access for documentID: ${documentID}`,
        ),
      ),
    );
  }
}
