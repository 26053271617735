import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CleanUp } from '../../../../shared/cleanup';
import {
  ExportableChart,
  ExportableChartComponentWithControls,
} from '../../../../features/graphs/exportable-chart';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  GraphSelectionData,
  NgsGraphDocumentState,
  TableForGraph,
} from '../ngs-graph-data-store/ngs-graph-data-store.reducer';
import { distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GraphSidebarControl } from '../../../../features/graphs/graph-sidebar';
import { AppState } from '../../../core.store';
import { Store } from '@ngrx/store';
import { selectGraphParamsForNgsDocument } from '../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { replaceAll } from '../../../../shared/string-util';

@Directive({
  standalone: true,
})
export abstract class NgsBaseGraphComponent<Data, Chart extends ExportableChart>
  extends CleanUp
  implements OnInit, OnDestroy, ExportableChartComponentWithControls<Chart>
{
  @HostBinding('class') readonly hostClass = 'd-flex flex-grow-1 flex-shrink-1 overflow-hidden';
  @Input() documentID: string;
  selectedParams$: Observable<NgsGraphDocumentState>;
  selectedTable$: Observable<TableForGraph>;
  data$: Observable<Data>;
  selectionIsEmpty$: Observable<boolean>;
  controls$ = new BehaviorSubject<GraphSidebarControl[]>([]);
  chartComponent: Chart;
  error$ = new BehaviorSubject<string | null>(null);
  tableExportEnabled$ = new ReplaySubject<boolean>(1);
  pngExportEnabled$ = new ReplaySubject<boolean>(1);
  titleSuffixForSelectionDependentGraphs$: Observable<string>;

  @Output() controlsUpdated = new EventEmitter<GraphSidebarControl[]>();
  @Output() pngExportEnabledUpdated = new EventEmitter<boolean>();
  @Output() tableExportEnabledUpdated = new EventEmitter<boolean>();
  @Output() graphWarningUpdated = new EventEmitter<string>();

  constructor(protected store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.tableExportEnabled$.next(true);
    this.pngExportEnabled$.next(true);
    this.selectedParams$ = this.store.select(selectGraphParamsForNgsDocument(this.documentID));
    this.selectedTable$ = this.selectedParams$.pipe(
      map(({ currentSelection }) => currentSelection.selectedTable.value),
      distinctUntilChanged((x, y) => x.name === y.name),
      takeUntil(this.ngUnsubscribe),
    );
    this.controls$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((controls) => {
      this.controlsUpdated.next(controls);
    });
    this.tableExportEnabled$
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((enabled) => this.tableExportEnabledUpdated.next(enabled));
    this.pngExportEnabled$
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((enabled) => this.pngExportEnabledUpdated.next(enabled));
    this.titleSuffixForSelectionDependentGraphs$ = this.selectedParams$.pipe(
      map(({ currentSelection }) => generateSuffix(currentSelection)),
    );
    this.selectionIsEmpty$ = this.selectedParams$.pipe(
      map(({ currentSelection }) => {
        const selection = currentSelection?.tableSelection.value;
        return (
          currentSelection.selectionDisplayType.value === 'selected' &&
          !(selection.selectAll || selection.rows.length > 0)
        );
      }),
    );
    this.graphWarningUpdated.next(null);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.error$.complete();
    this.controls$.complete();
    this.tableExportEnabled$.complete();
    this.pngExportEnabled$.complete();
  }

  exportAsImage() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
        withLatestFrom(this.pngExportEnabled$),
      )
      .subscribe(([documentName, enabled]) => {
        if (enabled) {
          this.getChartComponent().downloadImage(documentName);
        }
      });
  }

  exportAsTable() {
    this.selectedParams$
      .pipe(
        take(1),
        map((data) => data?.currentSelection?.documentName?.value),
        filter((x) => !!x),
        withLatestFrom(this.tableExportEnabled$),
      )
      .subscribe(([documentName, enabled]) => {
        if (enabled) {
          this.getChartComponent().downloadTable({
            documentName,
          });
        }
      });
  }

  onSidebarToggled() {
    // setTimeout is necessary to let the sidebar expand out before resizing the graph.
    setTimeout(() => this.resize(), 0);
  }

  resize(): void {
    if (this.getChartComponent()) {
      this.getChartComponent().resize();
    }
  }

  onControlsChanged(_: any): void {}

  getControls() {
    return this.controls$;
  }

  getChartComponent(): Chart {
    return this.chartComponent;
  }

  getErrors() {
    return this.error$;
  }
}

/**
 * Converts a filter string into something more appropriate for a graph title.
 * @param filter
 */
function makeFilterStringSuitableForTitle(filter: string): string {
  const replacements: Record<string, string> = {
    '_ ': ': ',
    '.keyword': '',
    ' IN ': ' in ',
    ' IN': ' in ',
    ' AND ': ' and ',
    ' AND': ' and ',
    ' OR ': ' or ',
    ' OR': ' or ',
    '<>': '≠', // unicode for != (U+2260)
    '<=': '≤', // unicode for <= (U+2264)
    '>=': '≥', // unicode for >= (U+2265)
    "['": '',
    "']": '',
  };
  return Object.keys(replacements).reduce(
    (acc, val) => replaceAll(acc, val, replacements[val]),
    filter,
  );
}

function generateSuffix(currentSelection: GraphSelectionData): string {
  const { selectAll, selectedRows, total, rows } = currentSelection?.tableSelection?.value;
  const filter = makeFilterStringSuitableForTitle(currentSelection?.filter?.value ?? '');
  const numRows = (selectAll ? total - rows.length : selectedRows?.length) ?? 0;
  let filterSuffix = `${!!filter && filter.length > 0 ? ` with ${filter}` : ''}`;
  if (currentSelection?.selectionDisplayType?.value === 'all') {
    return '';
  } else if (numRows > 0) {
    return ` (${numRows} of ${total} selected${filterSuffix})`;
  } else {
    return `${filterSuffix}`;
  }
}
