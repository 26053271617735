import { JobConfiguration } from '@geneious/nucleus-api-client';
import { ExtractSequencesOptions } from './extractSequencesV3.model';
import { OutputOptions, SelectionOptionsV1 } from './jobParameters.model';

export class RegisterSequencesParams implements JobConfiguration {
  pipeline = {
    name: 'register-sequences',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: RegisterSequencesOptions;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: RegisterSequencesOptions;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface RegisterSequencesOptions {
  project: number;
  target: string;
  species: string;
  sampleIdColumn: string;
  nameColumn: string;
  heavyChainClonotypeColumn: string;
  lightChainClonotypeColumn: string;
  isotype: string[];
  isProtein: boolean;
  selectionParams: ExtractSequencesOptions;
  experimentId: string;
}
