import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnState,
  GridOptions,
  IRowNode,
} from '@ag-grid-community/core';
import { CLIENT_GRID_DEFAULTS_OPTIONS, CUSTOM_OVERLAY_COMPONENTS } from './client-grid-v2.defaults';
import { ClientGridV2Service } from './client-grid-v2.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { DeterminateSelectionState, GridState } from '../grid.interfaces';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { RowWithID, getRowIdentifier } from '../../../core/ngs/getRowIdentifier';
import {
  ColumnsVisibilityChangedEvent,
  GridSidebarComponent,
} from '../grid-sidebar/grid-sidebar.component';
import { GridStateService } from '../../../core/grid-state/grid-state.service';
import { CHECKBOX_COLUMN_STATE } from '../grid.constants';
import { isModifierKeyPressed, isShiftKeyPressed } from '../../../shared/utils/keyboard-events';
import { FolderService } from '../../../core/folders/folder.service';
import { AsyncPipe } from '@angular/common';
import { TotalSelectedComponent } from '../total-selected/total-selected.component';
import { GridModule } from '../grid.module';

/**
 * This is solely used for the {@link FilesTableComponent}.
 * It was originally intended to be a replacement for the ClientGridComponent,
 * hence the name. However, it ended up be tightly coupled to the {@link FilesTableComponent}.
 */
@Component({
  selector: 'bx-client-grid-v2',
  templateUrl: './client-grid-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ClientGridV2Service],
  standalone: true,
  imports: [TotalSelectedComponent, GridModule, GridSidebarComponent, AsyncPipe],
})
export class ClientGridV2Component extends CleanUp implements OnChanges, OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100 w-100';

  @Input() gridOptions?: GridOptions;
  @Input() initialColumnDefs: (ColDef | ColGroupDef)[] = [];
  @Input() tableType: string;

  @Output() cellEditingStopped = new EventEmitter<CellEditingStoppedEvent>();
  @Output() gridStateChanged = new EventEmitter<GridState>();
  @Output() rowDoubleClicked = new EventEmitter<void>();
  @Output() resetGridState = new EventEmitter<void>();
  @Output() selectionChanged = new EventEmitter<DeterminateSelectionState>();

  readonly columnsChanged$ = new ReplaySubject<Column[]>(1);
  gridLoadedWithRows$: Observable<any>;
  readonly totalSelectedState$ = new BehaviorSubject<TotalSelectedState>({
    totalSelected: 0,
    totalRows: 0,
  });
  readonly loading$ = new BehaviorSubject(false);
  readonly overlayComponents = CUSTOM_OVERLAY_COMPONENTS;
  // The following must reference keys in `overlayComponents`.
  readonly loadingOverlayComponent = 'loadingOverlay';
  readonly noRowsOverlayComponent = 'noFilesUploadOverlay';
  protected state$: Observable<any>;
  gridState$ = new ReplaySubject<GridState>(1);

  /** Used to debounce gridState change events they can be triggered many times per second */
  private readonly gridStateChanged$ = this.completeOnDestroy(new Subject<void>());
  private shiftSelectionAnchor: IRowNode | undefined;
  private selectAllState: SelectAllState;
  private selectRowsOnNextLoad: string[] = [];
  private tableType$: Subject<string>;

  /** Subscriptions **/
  private requestAllRowsSubscription = Subscription.EMPTY;
  private folderFilesStateSubscription = Subscription.EMPTY;
  private readonly gridReady$ = new Subject<void>();

  constructor(
    private gridService: ClientGridV2Service,
    private gridStateService: GridStateService,
    private folderService: FolderService,
  ) {
    super();

    this.selectAllState = { checked: false, indeterminate: false };
    this.tableType$ = new ReplaySubject(1);
  }

  ngOnChanges({ tableType }: SimpleChanges): void {
    if (tableType) {
      this.tableType$.next(tableType.currentValue);
    }
  }

  ngOnInit() {
    // Up to parent component whether they want to pass in gridOptions or not.
    this.gridOptions = this.gridOptions || {};

    Object.assign(this.gridOptions, CLIENT_GRID_DEFAULTS_OPTIONS);

    this.gridStateChanged$.pipe(debounceTime(450)).subscribe(() => {
      const gridState = this.getGridState();
      this.gridStateService.storeGridState(this.tableType, gridState);
      this.gridStateChanged.emit(gridState);
      this.gridState$.next(gridState);
    });

    this.setInitialColumnDefs();

    this.selectionChanged.emit(new DeterminateSelectionState());

    // Implement custom menu items.
    // Overrides Reset Columns so that side effects such as emitting the Grid State can be done.
    // https://www.ag-grid.com/javascript-grid-column-menu/
    this.gridOptions.getMainMenuItems = () => {
      return [
        'pinSubMenu',
        'separator',
        'autoSizeThis',
        'autoSizeAll',
        'separator',
        {
          name: 'Reset Columns State',
          action: () => this.resetToGridStateDefault(),
        },
      ];
    };

    this.requestAllRowsSubscription.unsubscribe();

    this.clearData();
    // Update row total (e.g ... of 0 selected).
    this.onSelectionChanged();

    // TODO Apply sort model.
    const requestAllRows$ = this.gridService.files$.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const rows$ = requestAllRows$.pipe(
      takeUntil(this.ngUnsubscribe),
      finalize(() => {
        this.loading$.next(true);
        // TODO This causes the total to be updated late.
        // And arguably selection is already changed well before this line is hit.
      }),
    );

    this.gridReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      // Start loading after first request.
      this.loading$.next(true);

      const immutableFileTableDefs = this.initialColumnDefs.map((colDef) => ({ ...colDef }));

      // TODO Handle all of this in the ClientGridServiceV2.
      this.gridService.updateOriginalColumnDefs(immutableFileTableDefs);
      this.setColumnDefs(immutableFileTableDefs);

      this.gridStateService
        .getGridStateOrdered(this.tableType, this.gridOptions.columnApi.getColumnState())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((gridState) => {
          this.setGridState({
            columnsState: gridState.columnsState,
          });
        });

      this.updateAndEmitGridState();
    });

    this.folderService.selectedFolderID$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => this.gridReady$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (this.gridOptions.api) this.gridOptions.api.showLoadingOverlay();
      });

    this.state$ = this.gridReady$.pipe(
      switchMap(() => rows$),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.requestAllRowsSubscription = this.state$.subscribe((files) => {
      this.gridService.total = files.length;
      // this.loading$.next(files.length !== state.total);

      // Update the total rows shown.
      this.updateTotalSelectedState();
      // Note: Calling `setRowData` hides the loading overlay, no need to call 'hide'.
      // @see https://www.ag-grid.com/javascript-grid-overlays/
      this.gridOptions.api.setRowData(files.map((row: any) => Object.assign(row, row.metadata)));
      // this.gridOptions.api.refreshInMemoryRowModel();

      // Can't select a row until the grid is fully loaded, so we must do it here.
      if (this.selectRowsOnNextLoad.length) {
        this.selectRowsByID();
      }
    });

    // Only show if the grid is both loaded and has rows
    // - state.folderID is only set when the folder has data. So only show the sidebar when there are rows.
    this.gridLoadedWithRows$ = this.totalSelectedState$.pipe(
      filter((state) => !!state),
      map((state) => state.totalRows > 0),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.requestAllRowsSubscription.unsubscribe();
    if (this.folderFilesStateSubscription) {
      this.folderFilesStateSubscription.unsubscribe();
    }
    this.tableType$.complete();
    this.loading$.complete();
    this.totalSelectedState$.complete();
    this.gridReady$.complete();
    this.gridState$.complete();
    this.columnsChanged$.complete();
  }

  /**
   * AG-Grid is ready/loaded if the grid API is defined.
   */
  get gridLoaded() {
    return this.gridOptions && this.gridOptions.api;
  }

  setColumnDefs(columnDefs: ColDef[]) {
    if (this.gridLoaded) {
      this.gridOptions.api.setColumnDefs(this.gridService.constructColumnDefs(columnDefs));
    }
  }

  onColumnsVisibilityChanged(event: ColumnsVisibilityChangedEvent) {
    const { visible, hidden } = event.visibilityChanged;
    if (visible?.length) {
      this.gridOptions.columnApi.setColumnsVisible(visible, true);
    }
    if (hidden?.length) {
      this.gridOptions.columnApi.setColumnsVisible(hidden, false);
    }
  }

  /**
   * Selects rows in the table.
   * Defaults to 0 retries, which means than it will try to apply the selection only once.
   * If this fails (say the rows are not yet loaded in the grid) the selection will still be
   * applied the next time the grid data refreshes. TODO: Should we force empty
   * `selectRowsOnNextLoad` at some point (e.g. if the id doesn't match because that row has been
   * deleted).
   *
   * @param {string[]} idsToSelect should correspond to each row's 'id' property.
   * @param {number} retriesLeft Number of times to try selecting rows in timeout loop.
   */

  selectRowsByIDWithRetry(idsToSelect: string[], retriesLeft = 0) {
    let hasRows = false;
    this.selectRowsOnNextLoad = idsToSelect;
    if (this.gridLoaded) {
      this.gridOptions.api.forEachNode(() => {
        hasRows = true;
      });
    }
    if (hasRows && idsToSelect.length > 0) {
      this.selectRowsByID();
    } else if (retriesLeft > 0) {
      setTimeout(() => this.selectRowsByIDWithRetry(idsToSelect, retriesLeft - 1), 200);
    }
  }

  /** AG-Grid Events **/

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    this.cellEditingStopped.next(event);
  }

  onColumnPinned = () => this.updateAndEmitGridState();

  onColumnResized = () => this.updateAndEmitGridState();

  onColumnVisible = () => this.updateAndEmitGridState();

  /**
   * Updates column state for subcomponents. Don't bind the displayedColumnsChanged event to this
   * method, because it fires 100s of times a second for resizes, and consumers of the output
   * don't care about the size of the column.
   */
  onColumnsChanged() {
    // We don't want the Selected Column to be exposed.
    const columns = this.gridOptions.columnApi
      .getAllGridColumns()
      .filter((col) => col.getColDef().field !== 'selected');

    this.columnsChanged$.next(columns);
  }

  onDragStopped = () => this.updateAndEmitGridState();

  /**
   * Called once ag-grid API is ready.
   */
  onGridReady() {
    this.gridReady$.next();
  }

  /**
   * DANGER: Don't call this without talking to Alan.
   *
   * This method is called by ag-grid whenever selection changes.
   * Calling this affects the entire app as everything listens to the selection changed event
   * emitted from this.
   */
  onSelectionChanged() {
    // Update the total selected shown.
    this.updateTotalSelectedState();

    // Prevent unsubscription error. See Owen/Alan.
    if (this.gridOptions && this.gridOptions.api) {
      const total = this.gridService.total;
      const selectedRows: any[] = [];
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node, _) => {
        if (node.isSelected()) {
          selectedRows.push(node.data);
        }
      });
      const selectedIds = selectedRows.map((row) => getRowIdentifier(row));
      this.selectionChanged.emit({
        totalSelected: selectedRows.length,
        total: total,
        ids: selectedIds,
        rows: selectedRows,
      });
    }
  }

  onSortChanged = () => this.updateAndEmitGridState();

  onCellClicked(e: CellClickedEvent<RowWithID>): void {
    // If there is no data, then it must not be a valid column (i.e. it's a loading column) so ignore selection.
    if (!e.data) {
      return;
    }

    const isCheckboxSelection = e.colDef.colId === 'selected';

    const shiftKeyPressed = isShiftKeyPressed(e.event as MouseEvent);
    const modifierKeyPressed = isModifierKeyPressed(e.event as MouseEvent);
    // Note: Check box selection works like selection with a modifier key pressed.
    // In both cases, if the shift key is pressed, then shift behaviour takes priority.
    const behavesAsModifier = modifierKeyPressed || isCheckboxSelection;
    let newState = !behavesAsModifier ? true : !e.node.isSelected();

    // Selecting a row resets selectAll, unless using a checkbox or modifier key.
    if (this.selectAllState.checked && !behavesAsModifier) {
      this.selectAllState = { checked: false, indeterminate: false };
      newState = true;
    }

    e.node.setSelected(newState, !behavesAsModifier);

    // Only use check box selection or modifier-pressed selection as a shift anchor if no anchor is currently set.
    if (!shiftKeyPressed) {
      this.shiftSelectionAnchor = e.node;
    }

    if (!this.selectAllState.checked && shiftKeyPressed) {
      this.handleShiftSelect(e.node);
    }
  }

  setGridState(gridState: GridState): void {
    if (this.gridOptions && this.gridOptions.api) {
      const diff: ColumnState[] = this.getGridState()
        .columnsState.filter(
          (masterColumnState) =>
            !gridState.columnsState.find(
              (columnState) => columnState.colId === masterColumnState.colId,
            ),
        )
        .map((masterColumnState) => ({ ...masterColumnState, ...{ hide: true } }));

      const columnsState = gridState.columnsState.concat(diff);

      this.gridOptions.columnApi.applyColumnState({
        // Always keep Checkbox Select column at the beginning pinned.
        state: [CHECKBOX_COLUMN_STATE, ...columnsState],
        applyOrder: true,
        defaultState: { sort: null, sortIndex: null },
      });
    }
  }

  protected focusColumn(colId: string) {
    this.gridOptions.api.ensureColumnVisible(colId);
  }

  /** Private API **/

  private clearData() {
    this.shiftSelectionAnchor = undefined;
  }

  private getGridState(): GridState {
    const columnsState = this.gridOptions.columnApi
      .getColumnState()
      .filter((col) => col.colId !== 'selected');
    return { columnsState };
  }

  private handleShiftSelect(clickedRow: IRowNode) {
    let startIndex = this.shiftSelectionAnchor ? this.shiftSelectionAnchor.rowIndex : 0;
    let endIndex = clickedRow.rowIndex;

    // startIndex must be lower than endIndex
    if (startIndex > endIndex) {
      endIndex = this.shiftSelectionAnchor.rowIndex;
      startIndex = clickedRow.rowIndex;
    }

    this.gridOptions.api.forEachNode((node) => {
      const index = node.rowIndex;
      if (index >= startIndex && index <= endIndex) {
        node.setSelected(true);
      }
    });
  }

  private setInitialColumnDefs() {
    if (this.initialColumnDefs) {
      this.setColumnDefs(this.initialColumnDefs);
    }
  }

  private selectRowsByID() {
    let isFirst = true;
    this.gridOptions.api.forEachNode((node: IRowNode) => {
      const nodePositionInList = this.selectRowsOnNextLoad.indexOf(node.data.id);
      if (nodePositionInList > -1) {
        // Found a match - select row and remove it from the waitlist.
        node.setSelected(true, isFirst);
        this.selectRowsOnNextLoad.splice(nodePositionInList, 1);
        if (isFirst) {
          this.gridOptions.api.ensureNodeVisible(node, 'middle');
          isFirst = false;
        }
      }
    });
  }

  protected resetToGridStateDefault() {
    const allVisible: ColDef[] = this.gridService.originalColumnDefs.map((def: ColDef) => {
      if (def.colId === 'selected') {
        // TODO Invert dependency rather that require an if statement handle CHECKBOX_COLDEF outside
        // the map.
        return this.gridService.CHECKBOX_COLDEF;
      } else {
        // Remove any sort State.
        delete def.sort;
        delete def.sortIndex;
        return def;
      }
    });

    this.gridOptions.columnApi.resetColumnState();
    if (allVisible.length) {
      const columnsState = this.gridOptions.columnApi.getColumnState();
      const column = columnsState.find((column) => column.colId === allVisible[1].field);
      if (column) {
        this.gridOptions.columnApi.applyColumnState({
          state: columnsState,
          applyOrder: true,
          defaultState: { sort: null, sortIndex: null },
        });
      }
    }
    this.gridStateService.clearGridState(this.tableType);
    this.resetGridState.emit();
  }

  /**
   * Store and emit the current grid state.
   */
  private updateAndEmitGridState() {
    this.gridStateChanged$.next();
  }

  /**
   * Used for updating the information in the TotalSelectedComponent.
   */
  private updateTotalSelectedState(): void {
    if (this.gridOptions && this.gridOptions.api) {
      const total = this.gridService.total;
      const selectedRows = this.gridOptions.api.getSelectedRows();

      this.totalSelectedState$.next({
        totalSelected: selectedRows.length,
        totalRows: total,
      });
    }
  }
}

interface SelectAllState {
  checked: boolean;
  indeterminate: boolean;
}

interface TotalSelectedState {
  totalSelected: number;
  totalRows: number;
}
