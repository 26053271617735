@if (errorMessage$ | async; as error) {
  <bx-page-message>
    <span>{{ error }}</span>
  </bx-page-message>
}

@if (isLoading$ | async) {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
} @else {
  <div>{{ title$ | async }}</div>
}

<ng-template #title>
  <div>{{ title$ | async }}</div>
</ng-template>

<bx-client-grid [hidden]="isLoading$ | async" class="no-selection w-100 h-100"></bx-client-grid>
