import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class NgsAntibodyAnnotatorJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'ngs-antibody-annotator',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: NgsAntibodyAnnotatorJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: NgsAntibodyAnnotatorJobOptionsV1,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}

export interface NgsAntibodyAnnotatorJobOptionsV1 {
  resultName: string;
  optionValues: Record<string, boolean | string | number>;
}
