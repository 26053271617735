import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import Token from '../../../tokenizer-preview/Token';
import { NgForm, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { INameSchemeField } from '../../../../models/settings/name-scheme-field.model';
import {
  NameSchemeFieldsTemplateParser,
  TemplateParseResult,
} from '../name-scheme-fields-template-parser/name-scheme-fields-template-parser';
import { BehaviorSubject, Observable } from 'rxjs';
import { CLASSIFICATION_OPTIONS } from '../../name-scheme-editor.model';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { NgFormControlValidatorDirective } from '../../../../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { NameSchemeFieldsTemplateConstructorValidatorDirective } from '../name-scheme-fields-template-parser/name-scheme-fields-template-constructor-validator.directive';

@Component({
  selector: 'bx-name-scheme-configure-fields-edit-dialog',
  templateUrl: './name-scheme-configure-fields-edit-dialog.component.html',
  styleUrls: ['./name-scheme-configure-fields-edit-dialog.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgFormControlValidatorDirective,
    NgbTooltip,
    ReactiveFormsModule,
    NameSchemeFieldsTemplateConstructorValidatorDirective,
    AsyncPipe,
  ],
})
export class NameSchemeConfigureFieldsEditDialogComponent implements AfterContentInit, OnDestroy {
  modalData: { field: INameSchemeField; existingFields: INameSchemeField[]; tokens: Set<Token> };
  field: INameSchemeField = { name: '', type: 'text', template: '' };
  selectedNamePart: string;
  classifications: { label: string; value: string }[] = [...CLASSIFICATION_OPTIONS];
  tokensAsList: Token[] = [];
  parsedTemplate$ = new BehaviorSubject<TemplateParseResult>({ errors: [], result: '' });
  selectNamePartDisabled$: Observable<boolean>;
  templateConstructionDisabled$: Observable<boolean>;

  templateConstructionChoice = new FormControl<'constructTemplateChoice' | 'selectNamePartChoice'>(
    'selectNamePartChoice',
    Validators.required,
  );

  constructor(public modalRef: NgbActiveModal) {
    const templateConstructionChoiceValueChanges$ =
      this.templateConstructionChoice.valueChanges.pipe(
        startWith(this.templateConstructionChoice.value),
      );
    this.selectNamePartDisabled$ = templateConstructionChoiceValueChanges$.pipe(
      map((choice) => choice === 'constructTemplateChoice'),
    );
    this.templateConstructionDisabled$ = templateConstructionChoiceValueChanges$.pipe(
      map((choice) => choice === 'selectNamePartChoice'),
    );
  }

  ngAfterContentInit() {
    if (this.modalData && this.modalData.field) {
      this.field = this.modalData.field;
    }

    if (!this.modalData || !this.modalData.tokens) {
      this.modalData = Object.assign({}, this.modalData, { tokens: new Set<Token>() });
    }

    if (this.modalData && this.modalData.existingFields) {
      this.classifications = this.classifications.filter(
        (classification) =>
          !this.modalData.existingFields.find(
            (field) => field.classification === classification.value,
          ),
      );
    }

    this.tokensAsList = Array.from(this.modalData.tokens);
    this.selectedNamePart = '${0}';
  }

  ngOnDestroy() {
    this.parsedTemplate$.complete();
  }

  onTemplateChanged(template: string) {
    this.parsedTemplate$.next(NameSchemeFieldsTemplateParser.parse(template, this.tokensAsList));
  }

  submit(form: NgForm) {
    if (form.valid) {
      const field = { ...this.field };
      if (
        this.templateConstructionChoice.value === 'constructTemplateChoice' &&
        this.parsedTemplate$.getValue().errors.length === 0
      ) {
        this.modalRef.close(field);
      } else if (this.selectedNamePart) {
        field.template = this.selectedNamePart;
        this.modalRef.close(field);
      }
    }
  }
}
