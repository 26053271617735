@if (
  {
    password: password$ | async,
    strength: strength$ | async,
    valid: valid$ | async,
  };
  as state
) {
  <div class="progress" [ngClass]="{ invisible: !state.password }">
    <div
      class="progress-bar h-100"
      [ngClass]="{ 'bg-danger': !state.valid, 'bg-success': state.valid }"
      [ngStyle]="{ width: state.strength + '%' }"
    ></div>
  </div>
}
