/**
 * Prevents user from accessing app and shows a Browser Unsupported message in its place.
 * User can still bypass this message by clicking the `Proceed Anyway?` link.
 */
export function softBlockUnsupportedBrowsers() {
  document.addEventListener('DOMContentLoaded', function () {
    const isChromium =
      !!(window as any).chrome ||
      navigator.userAgent.includes('HeadlessChrome') ||
      navigator.webdriver;
    const unsupportedBrowserBypassed = window.localStorage.getItem('unsupportedBrowserBypass');

    if (!isChromium && !unsupportedBrowserBypassed) {
      document.getElementsByTagName('bx-root').item(0).remove();
      document.getElementsByTagName('body')[0].innerHTML = `
          <div class="text-center" style="display: flex;flex-direction: column;justify-content: center;align-items:center;height: 100%;">
            <img src="assets/branding/geneious-biologics.png" width="300px" style="margin-bottom: 50px" />
            <h1>Browser not supported</h1>
            <p>Use <a href="https://www.google.com/chrome/">Google Chrome</a>
            or the latest <a href="https://www.microsoft.com/edge">Microsoft Edge</a> to access Geneious Biologics.</p>
          </div>
        `;
      const proceedAnywayLink = document.createElement('a');
      proceedAnywayLink.id = 'proceed-anyway-link';
      proceedAnywayLink.href = '/';
      proceedAnywayLink.text = 'Proceed Anyway?';
      proceedAnywayLink.onclick = function () {
        localStorage.setItem('unsupportedBrowserBypass', 'true');
      };
      document.querySelector('div.text-center').appendChild(proceedAnywayLink);
    }
  });
}
