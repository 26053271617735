import { Entity } from '../entity.model';

export type OrganizationSettingKind =
  | 'label'
  | 'file-naming-scheme'
  | 'scaffoldv2'
  | 'bioregister'
  | 'luma';

export class OrganizationSetting<T = any> extends Entity {
  organizationID: string;
  kind: OrganizationSettingKind;
  data: T;

  static fromJson(json: any) {
    const setting = new OrganizationSetting();
    setting.id = json.id;
    setting.name = json.name;
    setting.kind = json.kind;
    if (json.data && json.data.created) {
      setting.created = new Date(json.data.created);
    }
    if (json.organizationID) {
      setting.organizationID = json.organizationID;
    }
    setting.data = json.data;
    return setting;
  }
}

/**
 * SAVING -Indicates a setting being saved with temporary id which will be replaced with the actual setting id from nucleus when the setting becomes SAVED
 * SAVED - Indicates a setting saved in nucleus
 * DELETING - Indicates a setting being deleted
 * UPDATING - Indicates a setting being changed
 */
export type SettingState = 'SAVING' | 'SAVED' | 'DELETING' | 'UPDATING';
export interface OrganizationSettingsState {
  settings: Record<string, OrganizationSetting>;
  settingStates: Record<string, SettingState>;
}
