@if ({ folder: folder$ | async, title: selectionTitle$ | async }; as selection) {
  <div class="p-3">
    @if (selection.folder && selection.title) {
      <h5 class="mb-0" data-testid="viewer-page-heading">
        @if (isDocumentDetailsView$ | async) {
          <a
            [routerLink]="[selection.folder.route, selection.folder.id]"
            class="text-muted me-2"
            data-testid="back-btn"
          >
            <fa-icon [icon]="faArrowLeft" class="d-inline-block"></fa-icon>
          </a>
        }
        {{ selection.title }} from
        @if ((isAdminView$ | async) === false) {
          <a [routerLink]="[selection.folder.route, selection.folder.id]">{{
            selection.folder.name
          }}</a>
        } @else {
          <span>{{ selection.folder.name }}</span>
        }
        <bx-document-expiry-badge
          class="ms-2 align-middle"
          [remainingDays]="remainingDays$ | async"
          [expirable]="expirable$ | async"
          [showAsBadge]="true"
        ></bx-document-expiry-badge>
      </h5>
    }
  </div>
}

@if ((error$ | async) === null) {
  @if ((isRetrievingDocuments$ | async) === false) {
    <bx-viewers
      [data]="viewersSelection$ | async"
      class="w-100 flex-grow-1 flex-shrink-1 overflow-hidden"
    ></bx-viewers>
  } @else {
    <bx-page-message>Retrieving Documents...</bx-page-message>
  }
} @else {
  <bx-page-message>
    <div class="text-danger mb-4">{{ error$ | async }}</div>
    Go to <a [routerLink]="['/workspace']">Shared Workspace</a>
  </bx-page-message>
}

<ng-template #error>
  <bx-page-message>
    <div class="text-danger mb-4">{{ error$ | async }}</div>
    Go to <a [routerLink]="['/workspace']">Shared Workspace</a>
  </bx-page-message>
</ng-template>
