@if (warning) {
  <ngb-alert type="danger" [dismissible]="false" class="mb-0">
    {{ warning }}
  </ngb-alert>
}

@if (!warning) {
  <form [formGroup]="form">
    <bx-card headerTitle="Filtering">
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          id="inFrameWithoutStopCodonsOnly"
          value=""
          formControlName="inFrameWithoutStopCodonsOnly"
          class="form-check-input"
        />
        <label for="inFrameWithoutStopCodonsOnly" class="form-check-label">
          Filter out sequences which are not: "Without Stop Codons & In Frame & Fully Annotated"
        </label>
      </div>
      <div class="row mb-0">
        <div class="col-9 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="filterOutCountAcrossSamples"
              value=""
              formControlName="filterOutCountAcrossSamples"
              class="form-check-input"
            />
            <label for="filterOutCountAcrossSamples" class="form-check-label">
              Filter out sequences where the sum of counts for all samples is lower than:
            </label>
          </div>
        </div>
        <div class="col-3">
          <input
            type="number"
            id="filterOutCountAcrossSamplesLowerThan"
            formControlName="filterOutCountAcrossSamplesLowerThan"
            class="form-control"
          />
        </div>
      </div>
    </bx-card>
    <bx-card headerTitle="Normalization">
      <div class="row mb-2">
        <label for="normalization" class="col-3 col-form-label"> Method: </label>
        <div class="col-9">
          <select formControlName="normalization" id="normalization" class="form-select">
            <option value="total">Total count</option>
            <option value="normalizationByTotalCountExcludingUpperQuartile">
              Total count excluding upper quartile
            </option>
            <option value="medianOfExpressionRatios">Median of frequency ratios</option>
          </select>
        </div>
      </div>
      <div class="row">
        <!-- TODO Confirm with MattK if we can remove this option. I think it's confusing. -->
        <div class="col-3"></div>
        <label for="deSeqMinFrequency" class="col-6 col-form-label">
          of frequencies at least:
        </label>
        <div class="col-3">
          <input
            type="number"
            id="deSeqMinFrequency"
            min="1"
            max="9999999"
            ngFormControlValidator
            formControlName="deSeqMinFrequency"
            class="form-control"
          />
          <div class="invalid-feedback">Must be between 1 and 9999999</div>
        </div>
      </div>
    </bx-card>
    <bx-card headerTitle="Additional Clustering">
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          id="reclusterBeforeComparison"
          value=""
          formControlName="reclusterBeforeComparison"
          class="form-check-input"
        />
        <label
          for="reclusterBeforeComparison"
          placement="right"
          ngbTooltip="Clusters all sequences of the selected region by a given threshold. Result of the clustering is added as a additional group-by table."
          class="form-check-label"
        >
          Group similar sequences across all samples
        </label>
      </div>
      <ng-container formGroupName="reclusteringOptions">
        <div class="mb-3 row">
          <label for="reclustering_clusterMethod" class="col-3 col-form-label"> Method: </label>
          <div class="col-6">
            <select
              formControlName="reclustering_clusterMethod"
              id="reclustering_clusterMethod"
              class="form-select"
            >
              <option value="similarity">Similarity-based Clustering</option>
              <option value="identity">Identity-based Clustering</option>
              <option value="identityByCount">Identity-based Clustering (by count)</option>
            </select>
          </div>
        </div>
        @if (
          {
            label: thresholdLabel$ | async,
            isIdByCount: clusterMethodIsIdentityByCount$ | async,
            errors: reclusteringSequenceIdentityThresholdErrors$ | async,
          };
          as thresholdState
        ) {
          <div class="mb-3 row">
            @if (thresholdState.label; as thresholdLabel) {
              <label
                for="reclustering_sequenceIdentityThreshold"
                class="col-3 col-form-label"
                data-spec="threshold-label"
              >
                {{ thresholdLabel }}:
              </label>
            }
            <div class="col-6">
              <div class="input-group">
                <input
                  type="number"
                  id="reclustering_sequenceIdentityThreshold"
                  ngFormControlValidator
                  formControlName="reclustering_sequenceIdentityThreshold"
                  class="form-control"
                />
                @if (!thresholdState.isIdByCount) {
                  <span class="input-group-text">%</span>
                }
                @if (thresholdState.errors; as errors) {
                  @if (thresholdState.label; as thresholdLabel) {
                    <div class="invalid-feedback" data-spec="threshold-error">
                      @if (errors.min) {
                        {{ thresholdLabel }} must be above {{ errors.min.min }}
                      }
                      @if (errors.max) {
                        {{ thresholdLabel }} must be below {{ errors.max.max }}
                      }
                    </div>
                  }
                }
              </div>
            </div>
          </div>
        }
        <div class="row mb-0">
          <label for="reclustering_region" class="col-3 col-form-label"> Region: </label>
          <div class="col-6">
            <bx-select
              [selectOptions]="regions$ | async"
              [smallSize]="false"
              id="reclustering_region"
              formControlName="reclustering_region"
            ></bx-select>
          </div>
          @if (this.noRegionsError$ | async) {
            <span class="col offset-3 text-danger">
              No common regions could be found in selected documents
            </span>
          }
          @if (reclusteringOptions?.warnings?.tooLarge; as tooLarge) {
            <span class="text-muted">
              Additional clustering on {{ tooLarge.region }} may have reduced performance,
              particularly for larger datasets. To improve performance, increase the Additional
              Cluster threshold, or choose a different region.
            </span>
          }
        </div>
      </ng-container>
    </bx-card>
    <!-- "Reference samples" section starts. -->
    <bx-card headerTitle="Experiment">
      <div class="row mb-0">
        <label for="referenceSampleDocumentID" class="col-3 col-form-label">
          Reference sample:
        </label>
        <div class="col-9">
          <select
            formControlName="referenceSampleDocumentID"
            id="referenceSampleDocumentID"
            class="form-select"
            ngFormControlValidator
          >
            @for (sample of selected.selectedRows; track sample) {
              <option [value]="sample.id">
                {{ sample.name }}
              </option>
            }
          </select>
        </div>
      </div>
    </bx-card>
    <bx-card headerTitle="Output" customClass="mb-0">
      <bx-pipeline-output
        [outputNameFormControl]="form.get('outputFolderName')"
      ></bx-pipeline-output>
    </bx-card>
  </form>
}
