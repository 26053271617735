import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DocumentHistoryDataEventType } from '@geneious/nucleus-api-client';
import { Job } from '../../../../nucleus/services/models/job.model';
import { getFriendlyJobName, NucleusPipelineID } from '../../pipeline/pipeline-constants';
import { JobLogSummary } from '@geneious/nucleus-api-client/model/job-log-summary';
import { DatePipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';

/**
 * This component displays a summary of a document history event. It is designed
 * to be nested within {@link DocumentHistoryCardComponent}. It does not have to
 * handle input data changes, because each component is replaced by the *ngFor
 * loop when there is a data update in the parent.
 */
@Component({
  selector: 'bx-document-history-item',
  templateUrl: './document-history-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, SpinnerComponent, DatePipe],
})
export class DocumentHistoryItemComponent implements OnInit {
  @HostBinding('class') readonly hostClass = [
    'w-100',
    'd-flex',
    'justify-content-between',
    'align-items-center',
  ];
  /**
   * Input data to display. Set placeholder to true to display a loading spinner.
   */
  @Input() historyEvent: HistoryItemData | HistoryItemPlaceholder;
  @Input() insightsEnabled: boolean;

  pipelineName: string;
  readonly faInfoCircle = faInfoCircle;
  readonly faExclamationTriangle = faExclamationTriangle;
  ngOnInit(): void {
    if (this.historyEvent.placeholder === true || !this.historyEvent.job) {
      this.pipelineName = '';
    } else {
      this.pipelineName =
        'with ' +
        getFriendlyJobName(
          this.historyEvent.job.config.pipeline.name as NucleusPipelineID,
          this.historyEvent.job.config.parameters.options,
        );
    }
  }
}

/**
 * The relevant fields from a DocumentHistoryEvent with an added userName field.
 */
export interface HistoryItemData {
  eventType: DocumentHistoryDataEventType;
  createdAt?: string;
  userName?: string;
  job?: Job;
  placeholder?: false;
  logSummary?: JobLogSummary;
}

/**
 * Input data type to use when the component should only display a loading spinner.
 */
export interface HistoryItemPlaceholder {
  placeholder: true;
}
