<bx-toolstrip>
  <bx-toolstrip-item>
    <button mat-button (click)="openNewFieldDialog()" data-testid="name-scheme-new-field">
      New
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button mat-button [disabled]="!selectedItem" (click)="openEditFieldDialog()">Edit</button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button mat-button [disabled]="!selectedItems.length" (click)="removeFields()">Delete</button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-client-grid
  class="flex-grow-1 flex-shrink-1"
  [columnDefs]="fieldsColumnDefs"
  [rowData]="fields"
  (selectionChanged)="onSelectionChanged($event)"
></bx-client-grid>
