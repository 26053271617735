import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectUserIsNucleusAdmin } from './auth.selectors';

/**
 * Protect internal application pages.
 */
@Injectable({
  providedIn: 'root',
})
export class NucleusAdminAuthGuard {
  constructor(
    private readonly store: Store<AppState>,
    private readonly navigationService: NavigationService,
  ) {}

  canActivate() {
    return this.store
      .select(selectUserIsNucleusAdmin)
      .pipe(switchMap((isAdmin) => (isAdmin ? of(true) : this.navigationService.getHomeUrlTree())));
  }
}
