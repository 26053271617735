import { Component, ElementRef, NgZone, OnInit, Renderer2 } from '@angular/core';
import { CleanUp } from './shared/cleanup';
import {
  PipelineSelectionSignaturesService,
  PipelinesSelectionSignaturesJSON,
} from './core/pipeline-dialogs/pipeline-selection-signatures.service';
import { BehaviorSubject } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { IconsService } from './core/icons.service';
import { NgbDropdownConfig, NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { ToastsContainerComponent } from './shared/toast/toasts-container.component';
import { UpdateNotifierComponent } from './core/update-notifier/update-notifier.component';
import selectionSignaturesJSON from './core/document-selection-signature/pipeline-operation-selection-signatures.json';

@Component({
  selector: 'bx-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, ToastsContainerComponent, UpdateNotifierComponent],
})
export class AppComponent extends CleanUp implements OnInit {
  loading$: BehaviorSubject<boolean>;

  constructor(
    private elemRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private router: Router,
    private pipelineSelectionSignaturesService: PipelineSelectionSignaturesService,
    private iconsService: IconsService,
    private readonly ngbDropdownConfig: NgbDropdownConfig,
    private readonly ngbPopoverConfig: NgbPopoverConfig,
    private readonly ngbTooltipConfig: NgbTooltipConfig,
  ) {
    super();

    this.loading$ = new BehaviorSubject<boolean>(false);

    // Ignore drop events on the body of the page; required to prevent loading the file and
    // redirecting away from the site.
    // Drop events should be handled by components which support it.
    // Run outside of Angular zone to avoid continuous change detection eating up performance.
    this.ngZone.runOutsideAngular(() => {
      this.renderer.listen(this.elemRef.nativeElement, 'drop', (e) => e.preventDefault());
      this.renderer.listen(this.elemRef.nativeElement, 'dragover', (e) => e.preventDefault());
    });

    this.iconsService.registerIcons();

    // Update Pipeline Selection Signatures store from JSON file.
    this.pipelineSelectionSignaturesService.setSelectionSignatures(
      selectionSignaturesJSON as PipelinesSelectionSignaturesJSON,
    );
    this.pipelineSelectionSignaturesService.setHelpMessages(
      selectionSignaturesJSON as PipelinesSelectionSignaturesJSON,
    );

    // Set dropdowns and popovers to always be on top, even when overflow: hidden
    this.ngbDropdownConfig.container = 'body';
    this.ngbPopoverConfig.container = 'body';
    this.ngbTooltipConfig.container = 'body';
  }

  ngOnInit(): void {
    // Show Loading screen when an asynchronous route navigation occurs...e.g. route that has a resolver.
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading$.next(true);
        }

        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.loading$.next(false);
        }
      });
  }
}
