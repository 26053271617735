import { ViewerHeight, ViewerState, ViewerStates } from './viewers-state.interface';

export function runHeightMigration(viewerStates: ViewerStates): ViewerStates<ViewerHeight> {
  const migrateOldHeightState = ({
    height,
    selectedTabs,
  }: ViewerState): ViewerState<ViewerHeight> => {
    return {
      height: typeof height === 'number' ? { unit: 'pixel', height: height } : height,
      selectedTabs,
    };
  };
  return Object.fromEntries(
    Object.entries(viewerStates).map(([k, v], _) => [k, migrateOldHeightState(v)]),
  );
}
