import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParsedTablesResult } from './table-parser.model';

@Injectable({
  providedIn: 'root',
})
export class TableParserService {
  constructor() {}

  /**
   * Parses XLSX/XLS/CSV files into a JSON result with columns and rows.
   *
   * @param file - XLSX/XLS/CSV file blob.
   * @param sheet -optional sheet to parse if undefined or null the first sheet would be returned
   */
  parse(file: File): Observable<ParsedTablesResult> {
    return new Observable<ParsedTablesResult>((observer) => {
      const worker = new Worker(new URL('./table-parser.worker.ts', import.meta.url), {
        type: 'module',
        name: 'Table Parser',
      });
      worker.onmessage = (event: MessageEvent) => {
        const parsedResult = event.data as ParsedTablesResult;
        observer.next(parsedResult);
        worker.terminate();
        observer.complete();
      };
      worker.postMessage({ file: file });
    });
  }
}
