import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, first, forkJoin, map, of, switchMap } from 'rxjs';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { AppState } from '../../core.store';
import { selectFolder } from '../../folders/store/folder.selectors';
import { CreateReferenceDbForm } from '../create-reference-db-dialog/create-reference-db-dialog.component';
import { DatabaseTypeEnum } from '../database-type';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';

/**
 * Presentation component for the final summary step in the Create Reference
 * Database dialog.
 */
@Component({
  selector: 'bx-create-reference-db-summary',
  templateUrl: './create-reference-db-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgFormControlValidatorDirective,
    ReactiveFormsModule,
    FormErrorsComponent,
    AsyncPipe,
  ],
})
export class CreateReferenceDbSummaryComponent implements OnInit {
  @Input() form: CreateReferenceDbForm;
  viewModel$: Observable<SummaryViewModel>;
  /** For use in the template */
  readonly DatabaseTypeEnum = DatabaseTypeEnum;
  /** User-friendly translations of option values */
  private readonly optionsText = {
    geneSourceName: {
      sequenceListName: 'Sequence List Name',
      sequenceName: 'Sequence Name',
    },
    sequencesChain: {
      singleUnknownChain: 'Either Heavy or Light',
      heavyChain: 'Heavy',
      lightChain: 'Light',
    },
  } as const;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.viewModel$ = currentValueAndChanges(this.form).pipe(
      switchMap((formValue) => {
        const refDatabaseID = formValue?.format?.referenceDatabase;
        const refDatabaseName$ = refDatabaseID
          ? this.store.pipe(
              select(selectFolder(refDatabaseID)),
              map((folder) => folder?.name),
              first(),
            )
          : of(null);
        return forkJoin([of(formValue), refDatabaseName$]);
      }),
      map(([formValue, referenceDatabase]) => {
        const model: SummaryViewModel = {
          databaseName: formValue?.details?.databaseName,
          referenceDatabase,
          files: formValue?.details?.files?.map((file) => file.name),
        };
        if (formValue?.details?.databaseType === DatabaseTypeEnum.GERMLINE) {
          model.geneSource = this.optionsText.geneSourceName[formValue.format?.geneSourceName];
        } else if (formValue?.details?.databaseType === DatabaseTypeEnum.TEMPLATE) {
          model.sequencesChain = this.optionsText.sequencesChain[formValue.format?.sequencesChain];
        }
        return model;
      }),
    );
  }
}

type SummaryViewModel = Partial<{
  databaseName: string;
  referenceDatabase: string;
  geneSource: 'Sequence Name' | 'Sequence List Name';
  sequencesChain: 'Heavy' | 'Light' | 'Either Heavy or Light';
  files: string[];
}>;
