export class Entity {
  id: string;
  name: string;
  created?: Date;

  constructor(entity?: IEntity) {
    if (entity) {
      this.id = entity.id;
      this.name = entity.name;
      this.created = entity.created;
    }
  }

  static fromJson(json: any) {
    const entity = new Entity();
    entity.id = json.id;
    entity.name = json.name;
    entity.created = new Date(json.created);
    return entity;
  }
}

export interface IEntity {
  id: string;
  name: string;
  created: Date;
}
