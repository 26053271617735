import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ANNOTATOR_CLUSTER_REGIONS } from '../regions-order';

const CLUSTER_GROUP_REGEX = /^(?:.+):/gm;
const ANNOTATOR_CLUSTER_REGIONS_LOWER_CASE = ANNOTATOR_CLUSTER_REGIONS.map((region) =>
  region.toLowerCase(),
);

// TODO Add more validation to the cluster combo string
export function clusterComboValidator(control: AbstractControl): ValidationErrors | null {
  const clusterCombosValue: string = control.value ?? '';
  const match = clusterCombosValue.match(CLUSTER_GROUP_REGEX);

  if (!match) {
    return null;
  }

  // Remove colons from each cluster combo group string.
  const clusterComboGroups = match.map((group) => group.substring(0, group.length - 1));

  if (new Set(clusterComboGroups).size !== clusterComboGroups.length) {
    return {
      clusterCombos:
        'Duplicate cluster combination name. Please ensure cluster combination names are unique.',
    };
  }

  const existingClusterCombos = clusterComboGroups.filter((group) =>
    ANNOTATOR_CLUSTER_REGIONS_LOWER_CASE.includes(group.toLowerCase()),
  );
  if (existingClusterCombos.length === 1) {
    return {
      clusterCombos: `${existingClusterCombos[0]} is a duplicate or reserved cluster name. Please rename this cluster combination.`,
    };
  } else if (existingClusterCombos.length > 1) {
    return {
      clusterCombos: `${existingClusterCombos.join(
        ', ',
      )} are duplicate or reserved cluster names. Please rename these cluster combinations.`,
    };
  } else {
    return null;
  }
}
