import { Injectable } from '@angular/core';
import { JobService } from '@geneious/nucleus-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import { fetchJobResults, fetchJobResultsSuccess } from './job-results.actions';

@Injectable()
export class JobsEffects {
  private static readonly JOB_RESULTS_FETCH_CONCURRENCY = 10;

  fetchJobResults$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchJobResults),
      mergeMap((action) => {
        const jobResults$ = this.jobService.getOrganizationJobResults(
          action.payload.organizationID,
          action.payload.id,
        );
        return jobResults$.pipe(
          map((response) => response.data),
          switchMap((jobResults) => {
            // Sometimes there can be a race condition where Job Results are not
            // actually ready to be retrieved when a Job has been completed.
            // Therefore throw an error if there are no job results to retry retrieving them once 5 seconds later (in the retryWhen below).
            if (jobResults.length === 0) {
              return throwError('No Job results');
            } else {
              return of(jobResults);
            }
          }),
          map((jobResults) =>
            fetchJobResultsSuccess({ payload: { id: action.payload.id, jobResults: jobResults } }),
          ),
          // Retry once if there is an error.
          retryWhen(() =>
            interval(4000).pipe(
              mergeMap((count) => (count === 1 ? throwError('Giving up') : of(count))),
            ),
          ),
          // Failed Job Results event is not yet supported, so for now emit a success with an empty array.
          catchError(() =>
            of(fetchJobResultsSuccess({ payload: { id: action.payload.id, jobResults: [] } })),
          ),
          // catchError(() => of(new JobsFetchJobResultsFailure({id: action.payload.id}))),
        );
      }, JobsEffects.JOB_RESULTS_FETCH_CONCURRENCY),
    ),
  );

  constructor(
    private actions: Actions,
    private jobService: JobService,
  ) {}
}
