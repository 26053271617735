import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NucleusPipelineID, PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  AmbiguousGenesStrategy,
  AnnotatedGeneRange,
  AntibodyAnnotatorOptionValues,
  defaultClusterCombos,
  defaultClusterCombosGenes,
  defaultPAndPLiabilities,
  gaalOptions,
  getOnlyClusterTablesFor,
  SequencesAnnotationStyle,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { Observable } from 'rxjs';
import { first, map, share, take, takeUntil } from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { defaultGenericRegionChips } from '../../../shared/regions-selector/regions-selector';
import { toggleControlsAvailabilityOnBoolean } from '../../../shared/form-helpers/toggle-controls-availability-on-boolean';
import { clusterComboValidator } from '../../ngs/validators/cluster-combo-validator';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  PeptideAnnotatorJobOptionsV1,
  PeptideAnnotatorJobParametersV1,
} from '../../../../nucleus/services/models/peptideAnnotatorOptions.model';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { restrictControlValues } from 'src/app/shared/utils/forms';
import { CardComponent } from '../../../shared/card/card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { NoGeneralTemplateDatabaseMessageComponent } from './no-general-template-database-message/no-general-template-database-message.component';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NameSchemeSelectComponent } from '../../name-schemes/name-scheme-select/name-scheme-select.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { AsyncPipe } from '@angular/common';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-peptide-annotator',
  templateUrl: './peptide-and-protein-annotator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbTooltip,
    MatIconModule,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    NoGeneralTemplateDatabaseMessageComponent,
    FormErrorsComponent,
    ShowIfDirective,
    NameSchemeSelectComponent,
    CollapsibleCardComponent,
    ClusteringOptionsCardComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class PeptideAndProteinAnnotatorComponent extends JobDialogContent implements OnInit {
  title: string;
  knowledgeBaseArticle: string;
  earlyRelease: boolean;

  referenceDatabases$: Observable<SelectOption[][]>;
  geneticCodes$: Observable<SelectOption[]>;
  associatedNameSchemes: Set<string>;
  multipleRefDbsEnabled$: Observable<boolean>;
  readonly form = this.initFormControls();

  private readonly formDefaults = this.form.getRawValue();
  private readonly selected: SelectionState;

  constructor(
    private readonly pipelineService: PipelineService,
    private readonly validatorService: PipelineFormControlValidatorsService,
    private readonly featureSwitchService: FeatureSwitchService,
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<PeptideAndProteinDialogData>,
  ) {
    super(dialogData.otherVariables.pipelineId, dialogData.otherVariables.pipelineFormId);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
    this.selected = dialogData.selected;

    this.title = this.dialogData.otherVariables.title;
    this.knowledgeBaseArticle = this.dialogData.otherVariables.knowledgeBaseArticle;
    this.earlyRelease = this.dialogData.otherVariables.earlyRelease;
  }

  ngOnInit() {
    this.toggleControlsAvailability();

    this.setReferenceDatabaseFields();

    this.geneticCodes$ = this.pipelineService.externalServices.geneticCodesMapToGeneiousPrimeName
      .valueSource()
      .pipe(first(), share());

    this.associatedNameSchemes = this.pipelineService.nameSchemesSetOnDocuments(this.selected);

    this.form.setControl('results_clusterCombos', new FormControl(defaultClusterCombosGenes));
  }

  private setReferenceDatabaseFields() {
    const emptyDatabaseOption = new SelectOption('No Reference Database', undefined);
    this.referenceDatabases$ = this.pipelineService
      .getDatabases(DatabaseTypeEnum.GENERAL_TEMPLATE)
      .pipe(
        map((dbs) => {
          if (dbs[0].length === 0 && dbs[1].length === 0) {
            return [];
          }
          dbs[0].push(emptyDatabaseOption);
          return dbs;
        }),
        share(),
      );
    this.referenceDatabases$
      .pipe(
        take(1),
        map((groups) => groups.flatMap((group) => group.map((option) => option.value))),
      )
      .subscribe((allowedValues) =>
        restrictControlValues(this.form.controls.database_customDatabase, allowedValues, {
          takeUntil: this.ngUnsubscribe,
        }),
      );

    setTimeout(() => {
      this.form.controls.database_customDatabase.setAsyncValidators(
        this.validatorService.databaseValidator(true),
      );
      this.form.controls.database_customDatabase.updateValueAndValidity();
    });

    // disabled gene differences option if there is no reference database selected.
    this.form.controls.database_customDatabase.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dbs) => {
        if (!dbs || dbs.length === 0 || dbs.filter((db) => !!db).length === 0) {
          this.form.controls.genes_annotateGermlineGeneDifferences.disable();
        } else {
          this.form.controls.genes_annotateGermlineGeneDifferences.enable();
        }
      });
  }

  run() {
    const formValues = this.form.value;

    // We need to do this as an empty database is an undefined value in the array, eg [undefined]
    if (formValues.database_customDatabase) {
      formValues.database_customDatabase = formValues.database_customDatabase.filter((i) => !!i);
    }

    const formValue = {
      ...formValues,
      ...gaalOptions,
      results_applyOnlyClusterTablesFor: true,
      sequences_chain: 'genericSequence',
      results_onlyClusterTablesFor: getOnlyClusterTablesFor(
        this.form.controls.regionsSelector.value,
      ),
    };

    const outputFolderName = this.form.get('outputFolderName').value;
    const resultName = this.form.get('resultName').value;

    // Remove form keys which aren't option values
    delete formValue.resultName;
    delete formValue.regionsSelector;
    delete formValue.outputFolderName;

    const options: PeptideAnnotatorJobOptionsV1 = {
      optionValues: formValue as AntibodyAnnotatorOptionValues,
      resultName: resultName,
    };

    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    return new PeptideAnnotatorJobParametersV1(
      parameters.selection,
      parameters.options,
      parameters.output,
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  private initFormControls() {
    const requiredNumber = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.required, Validators.min(min), Validators.max(max)]);

    return new BxFormGroup({
      fileNameSchemeID: new BxFormControl<string>(undefined),
      resultName: JobDialogContent.getResultNameControl(),
      database_customDatabase: new BxFormControl<string[]>(undefined),
      genes_annotateGermlineGeneDifferences: new BxFormControl(false),
      sequences_queryGeneticCode: new BxFormControl('universal', Validators.required),
      sequences_annotationStyle: new BxFormControl<SequencesAnnotationStyle>(
        'IMGT',
        Validators.required,
      ),
      sequences_addNumberingAnnotations: new BxFormControl(true),
      genes_ambiguousGenesStrategy: new BxFormControl<AmbiguousGenesStrategy>(
        'partialFrequency',
        Validators.required,
      ),
      results_calculateProteinStatistics: new BxFormControl(false),

      findLiabilities: new BxFormControl(false),
      liabilities_liabilitiesText: new BxFormControl(defaultPAndPLiabilities, Validators.required),

      // Note 'regionsSelector' value needs to be parsed into results_onlyClusterTablesFor before being sent to the
      // Antibody Annotator pipeline as parameters.
      regionsSelector: new BxFormControl(defaultGenericRegionChips, Validators.required),

      results_clusterCombos: new BxFormControl(defaultClusterCombos, clusterComboValidator),

      results_trimSequences: new BxFormControl(false),
      results_trimSequencesLength: requiredNumber(10, 0, 9999),

      results_applySummaryScoreFilter: new BxFormControl(false),
      results_summaryScoreThreshold: requiredNumber(-1000, -99999, 99999),

      results_applySummaryFilter: new BxFormControl(false),
      results_summaryFilter: new BxFormControl('fullyAnnotated', Validators.required),

      results_fullyAnnotatedStart: new BxFormControl<AnnotatedGeneRange>(
        'FR1',
        Validators.required,
      ),
      results_fullyAnnotatedEnd: new BxFormControl<AnnotatedGeneRange>('FR4', Validators.required),
      outputFolderName: JobDialogContent.getResultNameControl(),
    });
  }

  /**
   * Handle toggling the availability (disable/enable) for each of these form controls.
   */
  private toggleControlsAvailability() {
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.findLiabilities,
      [this.form.controls.liabilities_liabilitiesText],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryScoreFilter,
      [this.form.controls.results_summaryScoreThreshold],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryFilter,
      [this.form.controls.results_summaryFilter],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_trimSequences,
      [this.form.controls.results_trimSequencesLength],
      this.ngUnsubscribe,
    );
  }
}

export interface PeptideAndProteinDialogData {
  // Note. If these two dialogs start to diverge a lot, we should probably copy + paste them into two separate
  // components.
  title: string;
  knowledgeBaseArticle?: string;
  earlyRelease?: boolean;
  pipelineId: NucleusPipelineID;
  pipelineFormId: PipelineFormID;
}
