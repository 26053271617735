@if (totalSelectedState$ | async; as selection) {
  <bx-total-selected
    [totalSelected]="selection.totalSelected"
    [totalRows]="selection.totalRows"
  ></bx-total-selected>
}
<div class="d-flex h-100" style="min-height: 0">
  <ag-grid-angular
    class="ag-theme-balham flex-grow-1 w-100 overflow-hidden"
    [gridOptions]="gridOptions"
    [components]="overlayComponents"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [tooltipShowDelay]="500"
    (gridReady)="onGridReady()"
    (displayedColumnsChanged)="onColumnsChanged()"
    (columnMoved)="onColumnsChanged()"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (columnPinned)="onColumnPinned()"
    (columnResized)="onColumnResized()"
    (columnVisible)="onColumnVisible()"
    (dragStopped)="onDragStopped()"
    (cellClicked)="onCellClicked($event)"
    (rowDoubleClicked)="rowDoubleClicked.emit($event.data)"
    (selectionChanged)="onSelectionChanged()"
    (sortChanged)="onSortChanged()"
  >
  </ag-grid-angular>
  @if (gridLoadedWithRows$ | async) {
    <bx-grid-sidebar
      [columns]="columnsChanged$ | async"
      [gridState]="gridState$ | async"
      (columnsVisibilityChanged)="onColumnsVisibilityChanged($event)"
      (newGridState)="setGridState($event.gridState)"
      (resetGridState)="resetToGridStateDefault()"
      (focusColumn)="focusColumn($event.colId)"
      [targetComponent]="tableType"
    ></bx-grid-sidebar>
  }
</div>
