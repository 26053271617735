import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, timer } from 'rxjs';
import { distinct, map, shareReplay, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BuildInformationService {
  /**
   * Information about the current build.
   */
  buildInformation$: Observable<BuildInformation>;
  /**
   * An observable which periodically checks whether there is a newer buildHash available and emits
   * a true event when there is.
   */
  newUpdateAvailable$: Observable<boolean>;

  constructor(private http: HttpClient) {
    this.buildInformation$ = this.getBuildInfo().pipe(take(1), shareReplay(1));
    this.newUpdateAvailable$ = this.getUpdatePoller();
  }

  private getBuildInfo(): Observable<BuildInformation> {
    // Avoids spamming local host with failed requests of buildInformation file.
    if (window.location.href.includes('localhost:')) {
      return of({
        buildHash: '0',
        buildDate: 0,
      });
    }

    // This buildInformation.json file gets set in dist builds (see custom-webpack.config.ts), with
    // all the build information in it.
    return this.http.get<BuildInformation>('buildInformation.json', {
      responseType: 'json',
    });
  }

  private getUpdatePoller(): Observable<boolean> {
    const timeBetweenChecks: number = 60; // time in seconds between each check for updates.
    return timer(0, timeBetweenChecks * 1000).pipe(
      switchMap(() => this.getBuildInfo()),
      withLatestFrom(this.buildInformation$),
      map(([latestBuild, existingBuild]) => existingBuild.buildHash !== latestBuild.buildHash),
      distinct(),
    );
  }
}

export type BuildInformation = {
  buildHash: string;
  buildDate: number; // The date in epoch milliseconds
  releaseVersion?: string;
};
