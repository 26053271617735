import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { EMPTY, forkJoin, Subject, Subscription } from 'rxjs';
import { catchError, exhaustMap, switchMap, take } from 'rxjs/operators';
import { PipelineDialogService } from '../pipeline-dialogs/pipeline-dialog.service';
import { SelectionState } from '../../features/grid/grid.component';
import { FolderService } from '../folders/folder.service';
import { GridSelectionState } from '../grid-selection-state/grid-selection-state.interfaces';
import {
  ExportSequenceViewerDialogComponent,
  ExportSequenceViewerDialogOptions,
} from './export-sequence-viewer-dialog/export-sequence-viewer-dialog.component';
import {
  isViewerMasterDatabaseSearchSelection,
  isViewerMultipleTableDocumentSelection,
  ViewerMasterDatabaseSearchData,
  ViewerResultData,
} from '../viewer-components/viewer-document-data';
import { SequenceViewerService } from '../../features/sequence-viewer-angular/sequence-viewer.service';
import { MasterDatabaseSequencesResource } from '../master-database/master-database-search-result-viewer/master-database-sequences-resource.service';
import { ExportDocumentFileComponent } from '../pipeline-dialogs/export-v2/export-document-file/export-document-file.component';
import {
  ExportAlignmentDialogData,
  ExportAlignmentComponent,
} from '../pipeline-dialogs/export-v2/export-alignment/export-alignment.component';
import { Column } from '../../../nucleus/services/models/exportOptions.model';
import { SequenceViewerMetadataService } from './sequence-viewer-metadata.service';
import {
  ExportSequencesComponent,
  ExportSequencesDialogData,
} from '../pipeline-dialogs/export-v2/export-sequences/export-sequences.component';

@Directive({
  selector: '[bxSequenceViewerExportButton]',
  standalone: true,
})
export class SequenceViewerExportButtonDirective implements OnDestroy {
  // Determines if this is a result document.
  // Also used in the Pipeline.
  @Input() viewerData?: ViewerResultData | ViewerMasterDatabaseSearchData;
  @Input() exportType?: 'image' | 'document' | 'table' | 'sequences';
  @Input() exportSequencesDialogData?: ExportSequencesDialogData;

  private onClick$ = new Subject<void>();
  private subscription = new Subscription();

  constructor(
    private pipelineDialogService: PipelineDialogService,
    private folderService: FolderService,
    private sequenceViewerService: SequenceViewerService,
    private sequenceViewerMetadataService: SequenceViewerMetadataService,
  ) {
    this.subscription.add(
      this.onClick$.pipe(exhaustMap(() => this.openPipelineDialog())).subscribe(),
    );
  }

  ngOnDestroy() {
    this.onClick$.complete();
    this.subscription.unsubscribe();
  }

  @HostListener('click')
  onClick() {
    this.onClick$.next();
  }

  private openPipelineDialog() {
    return forkJoin({
      folder: this.folderService.selectedFolder$.pipe(take(1)),
      selectionState: this.folderService.folderSelectionState$.pipe(take(1)),
    }).pipe(
      switchMap(({ folder, selectionState }) => {
        if (this.exportType === 'document') {
          return this.pipelineDialogService.showDialog$({
            component: ExportDocumentFileComponent,
            folderID: folder.id,
            selected: this.gridSelectionStateToSelectionState(selectionState),
          });
        } else if (this.exportType === 'table') {
          return this.pipelineDialogService.showDialog$<ExportAlignmentDialogData>({
            component: ExportAlignmentComponent,
            folderID: folder.id,
            selected: this.gridSelectionStateToSelectionState(selectionState),
            otherVariables: this.getExportAlignmentDialogData(),
          });
        } else if (this.exportType === 'sequences') {
          return this.pipelineDialogService.showDialog$<ExportSequencesDialogData>({
            component: ExportSequencesComponent,
            folderID: folder.id,
            selected: this.gridSelectionStateToSelectionState(selectionState),
            otherVariables: this.exportSequencesDialogData,
          });
        } else {
          return this.pipelineDialogService.showDialog$<ExportSequenceViewerDialogOptions>({
            component: ExportSequenceViewerDialogComponent,
            folderID: folder.id,
            selected: this.gridSelectionStateToSelectionState(selectionState),
            otherVariables: this.getExportSequenceViewerDialogOptions(),
          });
        }
      }),
      // Ignore modal closing
      catchError(() => EMPTY),
    );
  }

  private getExportSequenceViewerDialogOptions(): ExportSequenceViewerDialogOptions {
    const serializedPreferences: any = this.sequenceViewerService.serializedPreferences;
    const otherVariables: ExportSequenceViewerDialogOptions = {
      sequenceViewerState: {
        ...this.sequenceViewerService.sequenceViewerState,
        savedOptions: {
          ...serializedPreferences,
          // SV Doesn't return the rawTypes for annotation types and thus all annotations end up being enabled.
          // This is a hack to save the proper annotation types with the correct label key.
          annotations: {
            enabled: this.sequenceViewerService.annotations.enabled,
            filter: this.sequenceViewerService.annotations.filter,
            types: this.sequenceViewerService.annotations.typesArray.reduce(
              (acc, type) => {
                acc[type.label] = type.visible;
                return acc;
              },
              {} as { [type: string]: boolean },
            ),
          },
          metadataColumns: serializedPreferences.metadataColumns.filter(
            (column: any) => column.enabled,
          ),
        },
      },
    };

    if (this.viewerData) {
      if (isViewerMasterDatabaseSearchSelection(this.viewerData.selection)) {
        otherVariables.masterDatabaseSearchResultSelection = {
          rows: this.viewerData.selection.rows,
          subTableRows: this.viewerData.selection.subTableRows,
          totalSelected: this.viewerData.selection.totalSelected,
          blobName: (this.viewerData.resource as MasterDatabaseSequencesResource).getBlobName(),
        };
      } else if (isViewerMultipleTableDocumentSelection(this.viewerData.selection)) {
        otherVariables.multipleTableDocumentSelection = {
          selectAll: this.viewerData.selection.selectAll,
          selectedRows: this.viewerData.selection.selectedRows,
          total: this.viewerData.selection.total,
          totalSelected: this.viewerData.selection.totalSelected,
          tableType: this.viewerData.selection.tableType,
        };
      }
    }
    return otherVariables;
  }

  private getExportAlignmentDialogData(): ExportAlignmentDialogData {
    const consensusSequence = this.sequenceViewerService.getConsensusSequence();
    const metadataColumns: Column[] = this.sequenceViewerService.metadata.enabledColumns.map(
      (column) => {
        return {
          name: column.label,
          colId: column.name,
          groupId: column.groupName,
        };
      },
    );
    const annotations = this.sequenceViewerService.annotations.types;
    const visibleAnnotationTypes: string[] = [];
    for (const key in annotations) {
      if (annotations[key].visible) {
        visibleAnnotationTypes.push(annotations[key].rawType);
      }
    }
    const sortedSequences = this.sequenceViewerService.getSortedSequences();
    let idColumnName = this.sequenceViewerMetadataService.findUniqueMetadataColumnName(
      sortedSequences[0].metadata,
      sortedSequences[0].name,
    );

    // the second filter is to get the unique values as the alignments created from annotated paired sequences(without combining duplicates) includes both heavy and light chain as separate sequences with same BX_ID
    const sortedSequenceIDs = sortedSequences
      .filter((wrapper) => wrapper.metadata && wrapper.metadata[idColumnName])
      .map((wrapper) => {
        return wrapper.metadata[idColumnName] as string;
      })
      .filter((value: any, index: any, self: any[]) => {
        return self.indexOf(value) === index;
      });

    return {
      metadataColumns,
      sortedSequenceIDs: sortedSequenceIDs,
      sortedIDColumnName: idColumnName,
      consensusSequence,
      visibleAnnotationTypes,
    };
  }

  private gridSelectionStateToSelectionState(state: GridSelectionState): SelectionState {
    return {
      noOfRowsSelected: state.totalSelected,
      totalNoOfRows: state.total,
      selectAll: false,
      ids: state.ids,
      selectedRows: state.rows,
      firstRow: state.rows[0],
    };
  }
}
