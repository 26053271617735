import { createAction, props } from '@ngrx/store';
import { JobActivityEventKind } from '../../../../../nucleus/v2/models/activity-events/activity-event-kind.model';

export const clearAllJobs = createAction('[Jobs Status] Clear All Jobs');

export const clearNotInProgressJobs = createAction('[Jobs Status] Clearing Not In Progress Jobs');

export const fetchRunningJobsStatuses = createAction('[Jobs Status] Fetch Running Job Statuses');

export const fetchRunningJobsStatusesSuccess = createAction(
  '[Jobs Status] Fetch Running Job Statuses Success',
  props<{ jobs: { id: string; status: JobActivityEventKind }[] }>(),
);
