import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, ViewerStates, ViewerHeight } from './viewers-state.interface';
import { selectViewerStateHeight, selectViewerStates } from './viewers-state.selectors';
import { updateViewerHeight } from './viewers-state.actions';

@Injectable({
  providedIn: 'root',
})
export class ViewersStateStoreService {
  readonly viewerStates$: Observable<ViewerStates<ViewerHeight>>;

  constructor(private store: Store<State>) {
    this.viewerStates$ = this.store.select(selectViewerStates);
  }

  /**
   * Update single ViewerState's height property.
   *
   * @param key
   * @param height
   */
  updateHeight(key: string, height: ViewerHeight<'percent'>) {
    this.store.dispatch(
      updateViewerHeight({
        key: key,
        height: height,
      }),
    );
  }

  getViewerStates(): Observable<ViewerStates<ViewerHeight>> {
    return this.store.select(selectViewerStates);
  }

  /**
   * Get single ViewerState's height by key
   *
   * @param key string that was used to store the saved ViewerState (e.g. ngsViewers).
   */
  getViewerStateHeight(key: string): Observable<ViewerHeight> {
    return this.store.select(selectViewerStateHeight(key));
  }
}
