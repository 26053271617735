import { Injectable } from '@angular/core';
import { NucleusPipelineID } from '../../pipeline/pipeline-constants';
import { TableFileImportComponent } from './table-file-import/table-file-import.component';
import { PipelineAssociationsService } from '../../pipeline/pipeline-associations/pipeline-associations.service';
import { ToastService } from '../../../shared/toast/toast.service';
import { PipelineDialogService } from '../pipeline-dialog.service';
import { UploadManager } from '../../upload/upload-manager';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';

interface DocumentImportOptions {
  groupSequences: boolean;
  additionalJobParameters?: unknown;
  templateDatabase?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentImportService {
  constructor(
    private pipelineAssociationsService: PipelineAssociationsService,
    private toastService: ToastService,
    private pipelineDialogService: PipelineDialogService,
    private uploadManager: UploadManager,
    private featureSwitchService: FeatureSwitchService,
  ) {}

  import(
    folderID: string,
    route: string,
    files: File[],
    pipeline?: NucleusPipelineID,
    importOptions?: DocumentImportOptions,
  ) {
    this.pipelineAssociationsService
      .getProfilePipelineAssociationParameters(pipeline ?? 'document-import')
      .subscribe((param) => {
        if (
          param['maxFileUploadSizeMB'] &&
          files.some((file) => file.size > param.maxFileUploadSizeMB * 1024 * 1024)
        ) {
          this.toastService.danger(
            `Files of up to ${param.maxFileUploadSizeMB} MB can be uploaded with your current plan (Geneious Biologics Starter Plan). Please choose a smaller file, or contact us to request an upgrade.`,
            {
              header: 'File upload error!',
              autohide: false,
            },
          );
        } else if (
          files.some((file) =>
            ['csv', 'xls', 'xlsx'].includes(file.name.substring(file.name.lastIndexOf('.') + 1)),
          )
        ) {
          this.pipelineDialogService.showDialog({
            component: TableFileImportComponent,
            folderID: folderID,
            selected: null,
            otherVariables: {
              files: files,
              folderID: folderID,
              route: route,
            },
          });
        } else {
          this.uploadManager.upload(folderID, route, files, pipeline, importOptions);
        }
      });
  }
}
