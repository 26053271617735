<bx-breadcrumb [folder]="folder$ | async"></bx-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <bx-export-toolstrip-item
      [folderID]="(folder$ | async)?.id"
      [disabled]="false"
      [selectionState]="selected"
      [gridState$]="primersTable.gridState$"
    ></bx-export-toolstrip-item>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="deletePrimers()"
      [disabled]="selected.totalSelected <= 0 || !canWrite || !loaded"
    >
      Delete
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-grid
  class="w-100 flex-grow-1 flex-shrink-1"
  [initialColumnDefs]="columnDefs"
  [datasource]="datasource"
  [datasourceParams$]="datasourceParams$"
  (selectionV2Changed)="onSelectionChanged($event)"
  tableType="primers"
>
</bx-grid>
