@if (
  {
    allTablesLoaded: tablesLoaded$ | async,
    tablesLoadingError: tablesLoadingError$ | async,
    loaded: (tableLoaded$ | async),
    openQueryParams: openQueryParams$ | async,
  };
  as status
) {
  @if (status.allTablesLoaded) {
    @if (resourceError$ | async; as error) {
      <bx-page-message class="error-message">
        @if (error.pageMessage) {
          <div [innerHTML]="error.pageMessage"></div>
        } @else {
          <h5>Failed to fetch results</h5>
          <br />
          <span>
            See our
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069871"
            >
              Knowledge base
            </a>
            article for further information.
          </span>
        }
      </bx-page-message>
    }
    @if (status.loaded) {
      <bx-ngs-sequence-preview-info-box
        class="mb-2"
        [totalNumberOfSequences]="totalNumberOfRows"
        [maximumNumberOfSequences]="RESULT_SET_MAX"
        [openQueryParams]="status.openQueryParams"
      ></bx-ngs-sequence-preview-info-box>
    }
    @if (status.loaded) {
      <bx-grid
        class="w-100 flex-grow-1 flex-shrink-1"
        [selectable]="false"
        [gridOptions]="gridOptions"
        [datasource]="gridDatasource"
        [datasourceParams$]="gridDatasourceParams$"
        [resultSetMax]="RESULT_SET_MAX"
        noRowsOverlayMessage="No matches found."
        [showTimer]="true"
        [showTotalSelected]="false"
        [tableType]="tableType$ | async"
        [additionMessage]="additionalMessage$ | async"
        [profileComponent]="tableProfileKey$ | async"
        (gridStateChanged)="handleGridStateChanged()"
        bxNotesEditHandler
        data-testid="ngs-sequences-table-grid"
      >
      </bx-grid>
    } @else {
      <bx-ngs-document-restore-screen
        [tableName]="defaultTableName$ | async"
        [documentID]="documentID$ | async"
      >
      </bx-ngs-document-restore-screen>
    }
  } @else {
    @if (status.tablesLoadingError) {
      @switch (status.tablesLoadingError) {
        @case ('masterDatabaseNoTables') {
          <bx-page-message>
            <p>Database is empty</p>
            <button class="btn btn-outline-primary" (click)="importSequences()">
              Import Sequences
            </button>
          </bx-page-message>
        }
        @default {
          <bx-page-message>
            <h5>Failed to fetch results</h5>
            <br />
            <span>
              Close and try again later. If you continue to have problems, please
              <a href="https://help.geneiousbiologics.com">contact support.</a>
            </span>
          </bx-page-message>
        }
      }
    } @else {
      <bx-page-message>
        <bx-loading></bx-loading>
      </bx-page-message>
    }
  }
}
