import { Component } from '@angular/core';
import { LogoComponent } from '../../shared/logo/logo.component';
import { EnvironmentSwitcherComponent } from '../external/environment-switcher/environment-switcher.component';
import { ExternalWrapperComponent } from '../external/external-wrapper/external-wrapper.component';

@Component({
  selector: 'bx-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [LogoComponent, EnvironmentSwitcherComponent, ExternalWrapperComponent],
})
export class NotFoundComponent {
  constructor() {}
}
