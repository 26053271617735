import { takeUntil } from 'rxjs/operators';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { AccordionStepComponent } from './accordion-step/accordion-step.component';
import { AccordionService } from './accordion.service';
import { CleanUp } from '../cleanup';

@Component({
  selector: 'bx-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  providers: [AccordionService],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class AccordionComponent extends CleanUp implements OnChanges, AfterContentInit, OnDestroy {
  // Query list of all child accordionstep elements.
  @ContentChildren(AccordionStepComponent) stepComponents: any[] = [];

  // If the accordion should be disabled.
  @Input() disabled: boolean;

  constructor(private accordionService: AccordionService) {
    super();
  }

  /**
   * Lifecycle hook, called when all accordionSteps are initialized.
   */
  ngAfterContentInit() {
    // Loop through each child step and bind subscriptions so we can manage them all.
    this.stepComponents.forEach((component: AccordionStepComponent, index: number) => {
      // Record the step so we can track it.
      this.accordionService.addStep({ errors: component.errors || [] });

      // The step is completed with no errors.
      component.done
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((status) => this.onStepDone(index));

      // The current wants to select this component.
      component.headerClick
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((status) => this.makeActive(index));
    });

    this.updateComponents();

    // Update state of child elements when the state changes here.
    this.accordionService.schemaObserver
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.updateComponents());
  }

  /**
   * Lifecycle hook.
   */
  ngOnChanges() {
    // Required to pass changes in "disabled" down to accordion steps.
    this.updateComponents();
  }

  /**
   * Deduplicate common code used to update elements when state changes.
   * This can be achieved in html too, but wanted to keep the interface clean.
   */
  updateComponents() {
    this.stepComponents.forEach((component: AccordionStepComponent, index: number) => {
      component.disabled = this.disabled || index > this.accordionService.schema.index;
      component.expanded = index === this.accordionService.schema.index;
    });
  }

  /**
   * Called when the current step is done and we should move to the next step.
   * @param index of the step that changed.
   */
  onStepDone(index: number) {
    const isValid = this.accordionService.schema.steps[index].errors.length;
    const last = this.stepComponents.length - 1;
    if (isValid === 0 && index < last) {
      this.accordionService.increment();
    }
  }

  /**
   * Select a specific step.
   * @param index of step to select.
   */
  makeActive(index: number) {
    if (index < this.accordionService.schema.index) {
      this.accordionService.setIndex(index);
    }
  }
}
