import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { ColDef, ColGroupDef, IHeaderParams } from '@ag-grid-community/core';
import { isColDef } from '../../folders/models/colDefs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-assay-data-file-header',
  templateUrl: './assay-data-file-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip],
})
export class AssayDataFileHeaderComponent implements IHeaderAngularComp {
  @HostBinding('class') readonly hostClass = 'w-100';

  include: boolean;
  name: string;
  isIdColumn: boolean;
  tooltip: string;
  private params: IHeaderParams;

  constructor() {}

  agInit(params: IHeaderParams): void {
    this.name = params.displayName;
    this.params = params;
    const metadata: AssayDataHeaderMetadata = (params.column.getColDef() as AssayDataColDef)
      .metadata as AssayDataHeaderMetadata;
    this.isIdColumn = metadata.idColumn === true;
    this.include = this.isIdColumn || metadata.include === true;
    this.tooltip = this.isIdColumn
      ? 'Match column must be included'
      : 'Deselect to exclude this column';
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  checkboxClicked(event: any) {
    const assayDataColDef: AssayDataColDef = this.params.column.getColDef() as AssayDataColDef;
    assayDataColDef.metadata.include = event.target.checked;
  }
}

export interface AssayDataColDef extends ColDef {
  metadata: AssayDataHeaderMetadata;
}

export function isAssayDataColDef(col: ColDef | ColGroupDef): col is AssayDataColDef {
  return isColDef(col) && (<AssayDataColDef>col).metadata !== undefined;
}

export interface AssayDataHeaderMetadata {
  fileColumn?: boolean;
  idColumn?: boolean;
  include: boolean;
}
