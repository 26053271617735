import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { CellClassParams, ColDef, GridOptions } from '@ag-grid-community/core';
import { UtilService } from '../../../../shared/util.service';
import { FormatterService } from '../../../../shared/formatter.service';
import { SearchResultsGridResource } from '../search-results-grid.resource';
import { IGridResource } from '../../../../features/grid/datasource/grid.resource';
import { Searcher } from '../../model/searcher';
import { fileTableDefs } from '../../../folders/models/colDefs';
import { CleanUp } from '../../../../shared/cleanup';
import { map, takeUntil } from 'rxjs/operators';
import { ClientGridComponent } from '../../../../features/grid/client-grid/client-grid.component';
import { Search } from '../../model/search.model';
import { GlobalSearchTypes } from '../../search-bar/search-bar.component';
import { SearchBackButtonComponent } from '../../search-back-button/search-back-button.component';
import { GridComponent } from '../../../../features/grid/grid.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-search-results-table',
  templateUrl: './search-results-table.component.html',
  standalone: true,
  imports: [SearchBackButtonComponent, GridComponent, AsyncPipe],
})
export class SearchResultsTableComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @ViewChild(ClientGridComponent) clientGrid: ClientGridComponent;
  @Input() searcher$: Observable<Searcher>;
  @Input() query$: Observable<Search.Query>;

  columnDefs$: Observable<ColDef[]>;
  gridOptions: GridOptions = {};
  datasource$: Observable<IGridResource>;
  // datasourceParams has to be a behavior subject so that we can get the value in a synchronous fashion for `isASearchMatch()`.
  datasourceParams$: BehaviorSubject<Search.Query>;
  tableType$: Observable<string>;

  constructor(private utilService: UtilService) {
    super();
    this.gridOptions = {
      cacheBlockSize: 1000,
    };
    this.tableType$ = observableOf('global-search');
    // Need to be a behavior subject so that it is accessible synchronously inside table cells for highlighting.
    this.datasourceParams$ = new BehaviorSubject<Search.Query>({
      queryString: '',
      queryType: GlobalSearchTypes.FILE_FOLDER,
      pagination: {
        offset: 0,
        limit: 1000,
      },
    });
  }

  ngOnInit() {
    this.query$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((query) => {
      // Updating datasource params will trigger a refresh of grid data.
      // The params aren't actually used in the search though - the store is the source of truth.
      this.datasourceParams$.next(query);
    });
    this.tableType$ = this.query$.pipe(
      map((query) =>
        query.queryType === GlobalSearchTypes.FILE_FOLDER
          ? 'global-search'
          : 'global-search-' + query.queryType.toString(),
      ),
    );
    this.columnDefs$ = this.query$.pipe(map((query) => this.getColumns(query.queryType)));
    this.datasource$ = this.searcher$.pipe(
      map((searcher) => {
        return new SearchResultsGridResource(searcher, this.utilService);
      }),
    );

    this.gridOptions.context = { getQuery: () => this.datasourceParams$.getValue().queryString };
  }

  /**
   * Show common columns in the search results
   * Note that currently we don't index any columns defined via "add metadata" or
   * any columns from scaffolds.
   *
   * TODO Should these columns be here? We should refactor so that they are returned to the grid with the data request instead.
   * @returns {ColDef[]}
   */
  getColumns(type: GlobalSearchTypes): ColDef[] {
    if (type === GlobalSearchTypes.FILE_FOLDER) {
      const specialColumns = ['name', 'documentType', 'description', 'notes'];
      const ignoreColumns = specialColumns.concat([
        'id',
        '_bxUniqueIdentifier',
        'parentID',
        'sequence_length',
      ]);
      const otherDefs: ColDef[] = fileTableDefs.filter((def) => !ignoreColumns.includes(def.field));

      const matchCellRules: {
        [cssClassName: string]: ((params: CellClassParams) => boolean) | string;
      } = {
        'table-brand': (params: any) => SearchResultsTableComponent.isASearchMatch(params),
      };

      return [
        {
          headerName: 'Name',
          field: 'name',
          width: 200,
          cellClassRules: matchCellRules,
          ...this.utilService.linkRenderer((cell) => {
            if (cell.data && cell.data.parentID && cell.value) {
              // Sometimes `documentType` isn't set so important we handle that case.
              const isFolderType = (cell.data.documentType + '').toLowerCase() === 'folder';
              const folderID = isFolderType ? cell.data.id : cell.data.parentID;
              const maybeSelectDocuments = isFolderType ? '' : `?selectRowID=${cell.data.id}`;
              return {
                text: cell.value,
                href: `/folders/${folderID}/files${maybeSelectDocuments}`,
              };
            } else if (cell.value) {
              return {
                text: this.utilService.sanitizeCell(FormatterService.emptyIfMissing(cell.value)),
              };
            }
            return null;
          }),
        },
        {
          headerName: 'Item type',
          field: 'documentType',
          cellClass: 'fileType-cell',
        },
        {
          headerName: 'Parent folder',
          field: 'parentName',
        },
        {
          headerName: 'Description',
          field: 'description',
          cellClassRules: matchCellRules,
          width: 200,
        },
        {
          headerName: 'Notes',
          field: 'notes',
          cellClassRules: matchCellRules,
        },
        {
          headerName: ' Target antigen',
          field: 'targetAntigen',
          cellClassRules: matchCellRules,
        },
        ...otherDefs,
      ];
    } else {
      // For CDR3 search table. Most of the columns are already generated, we just need to add any special columns.
      return [
        {
          headerName: 'Parent document',
          field: '_documentName',
          width: 200,
          cellRenderer: (cell: any) => {
            if (cell.data && cell.data._documentID) {
              // Filter for result document sequences table, intended to show relevant sequences only.
              const link = `/view?ids=${cell.data._documentID}&filter=TRUE`;
              return this.utilService.openNewLink(cell.value || 'Go to result', link);
            } else {
              return this.utilService.sanitizeCell(FormatterService.emptyIfMissing(cell.value));
            }
          },
        },
      ];
    }
  }

  static isASearchMatch(params: any) {
    if (params) {
      const value: string = params.value || '';
      const filterBy: string = params.context.getQuery();
      return value.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
    }
  }
}
