<div class="card-header px-3">
  @if (headerTitle) {
    <h5 class="mb-0 align-middle">{{ headerTitle }}</h5>
  }
  <ng-content select="[bxCardHeader]"></ng-content>
</div>
@if (!hideBody) {
  <div class="card-body">
    <ng-content></ng-content>
  </div>
}
