import { Component, Inject } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { PrimersService } from '../primers.service';
import { MODAL_DATA } from 'src/app/shared/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';

import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

@Component({
  selector: 'bx-primers-create-set-dialog',
  templateUrl: './primers-create-set-dialog.component.html',
  standalone: true,
  imports: [FormsModule, NgFormControlValidatorDirective, SpinnerButtonComponent],
})
export class PrimersCreateSetDialogComponent {
  setName: string;
  submitting = false;

  constructor(
    @Inject(MODAL_DATA) readonly data: IPrimersCreateSetDialogData,
    readonly modal: NgbActiveModal,
    readonly primersService: PrimersService,
  ) {}

  createSet(createSetForm: NgForm): void {
    if (createSetForm.valid) {
      this.submitting = true;
      this.primersService
        .createSet(this.data.parentID, this.setName, this.data.ids)
        .pipe(
          finalize(() => {
            this.submitting = false;
          }),
        )
        .subscribe(() => this.close('completed'));
    }
  }

  close(reason: 'canceled' | 'completed') {
    if (reason === 'canceled') {
      this.modal.dismiss(reason);
    } else {
      this.modal.close();
    }
  }
}

export interface IPrimersCreateSetDialogData {
  parentID: string;
  ids: string[];
}
