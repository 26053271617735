<div class="bx-page-container bx-page-container-sm">
  <h2>Sign up request submitted</h2>
  <div class="mb-4">
    <p class="text-muted">
      We have sent a confirmation to your chosen email address. To activate your account, please
      follow the instructions in the email.
    </p>
    <p class="text-muted">
      <span> If you do not receive an email, please see our </span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.geneiousbiologics.com/hc/en-us/articles/4413468513172"
        >help article</a
      >
      or
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.geneiousbiologics.com/hc/en-us/requests/new "
        >contact us.</a
      >
    </p>
  </div>
  <div class="w-100 d-flex justify-content-end">
    <button class="btn btn-primary me-2" type="button" color="primary" routerLink="/login">
      Sign in
    </button>
    <button class="btn btn-secondary" type="button" [routerLink]="['/sign-up']">
      Sign up with a different email
    </button>
  </div>
</div>
