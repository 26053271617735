import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ACLEntity } from '../manage-sharing.models';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule } from '@angular/material/core';

@Component({
  selector: 'bx-manage-sharing-option',
  templateUrl: './manage-sharing-option.component.html',
  styleUrls: ['./manage-sharing-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, MatLineModule],
})
export class ManageSharingOptionComponent {
  @Input() entity: ACLEntity;
  @Input() searchText: string;
}
