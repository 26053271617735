import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  BxFormControl,
  BxFormGroup,
} from '../user-settings/form-state/bx-form-group/bx-form-group';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectBioregisterSettings } from '../organization-settings/organization-settings.selectors';
import { distinctUntilChanged, first, map, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject, startWith, Subscription } from 'rxjs';
import { bioRegisterConfigurationActions } from '../organization-settings/organization-settings.actions';
import { OrganizationSetting } from '../models/settings/setting.model';
import { selectOrganizationID } from '../auth/auth.selectors';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DialogService } from '../../shared/dialog/dialog.service';
import { NgFormControlValidatorDirective } from '../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { CollapsibleCardComponent } from '../../shared/collapsible-card/collapsible-card.component';
import { EditableJsonAreaComponent } from '../editable-json-area/editable-json-area.component';
import { MonospaceBoxComponent } from 'src/app/shared/monospace-box/monospace-box.component';

@Component({
  selector: 'bx-bioregister-config',
  templateUrl: './bioregister-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    CollapsibleCardComponent,
    EditableJsonAreaComponent,
    AsyncPipe,
    MonospaceBoxComponent,
  ],
})
export class BioregisterConfigComponent implements OnDestroy {
  private sequenceEntityName = new BxFormControl<string>(undefined);
  private sequenceEntityID = new BxFormControl<number>(undefined, [Validators.pattern('^[0-9]*$')]);
  private complexEntityName = new BxFormControl<string>(undefined);
  private complexEntityID = new BxFormControl<number>(undefined, [Validators.pattern('^[0-9]*$')]);
  mappingConfiguration = new BxFormControl<string>(undefined);
  readonly form = new BxFormGroup({
    bioregisterURL: new BxFormControl<string>(undefined, [Validators.required]),
    sequenceEntity: new BxFormGroup(
      {
        name: this.sequenceEntityName,
        id: this.sequenceEntityID,
      },
      { validators: nameOrIDValidator },
    ),
    complexEntity: new BxFormGroup(
      {
        name: this.complexEntityName,
        id: this.complexEntityID,
      },
      { validators: nameOrIDValidator },
    ),
    mappingConfiguration: this.mappingConfiguration,
  });
  saveButtonDisabled$ = new ReplaySubject<boolean>();
  private subscriptions = new Subscription();
  private bioRegisterConfiguration: Observable<BioregisterConfiguration>;
  readonly sampleMapping = JSON.stringify(
    {
      'Heavy Chain': [
        {
          BxColumnName: 'Heavy CDR1',
          BioregisterField: 'cdr1',
        },
        {
          BxColumnName: 'Heavy FR1',
          BioregisterField: 'framework 1',
        },
      ],
    },
    null,
    2,
  );

  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {
    this.bioRegisterConfiguration = this.store.select(selectBioregisterSettings).pipe(
      first(),
      map((value) => {
        return {
          bioregisterURL: value.find((setting) => setting.name === 'bioregisterURL')?.data,
          sequenceEntity: {
            name: value.find((setting) => setting.name === 'sequenceEntityName')?.data,
            id: value.find((setting) => setting.name === 'sequenceEntityId')?.data,
          },
          complexEntity: {
            name: value.find((setting) => setting.name === 'complexEntityName')?.data,
            id: value.find((setting) => setting.name === 'complexEntityId')?.data,
          },
          mappingConfiguration: value.find(
            (setting) => setting.name === 'bioregisterMappingConfiguration',
          )?.data,
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.subscriptions.add(
      this.bioRegisterConfiguration.subscribe((config) => {
        this.form.patchValue(config);
      }),
    );
    this.subscriptions.add(
      this.sequenceEntityID.valueChanges.subscribe((value) => {
        if (value) {
          this.sequenceEntityName.reset();
        }
      }),
    );
    this.subscriptions.add(
      this.sequenceEntityName.valueChanges.subscribe((value) => {
        if (value) {
          this.sequenceEntityID.reset();
        }
      }),
    );
    this.subscriptions.add(
      this.complexEntityID.valueChanges.subscribe((value) => {
        if (value) {
          this.complexEntityName.reset();
        }
      }),
    );
    this.subscriptions.add(
      this.complexEntityName.valueChanges.subscribe((value) => {
        if (value) {
          this.complexEntityID.reset();
        }
      }),
    );

    this.subscriptions.add(
      combineLatest([
        this.bioRegisterConfiguration,
        this.form.valueChanges.pipe(startWith(this.form.value)),
        this.form.statusChanges.pipe(distinctUntilChanged()),
      ])
        .pipe(
          map(([config, values, _]) => {
            if (this.form.invalid) {
              return true;
            } else {
              return (
                this.isEqual(config.bioregisterURL, values.bioregisterURL) &&
                this.isEqual(config.complexEntity.id, values.complexEntity.id) &&
                this.isEqual(config.complexEntity.name, values.complexEntity.name) &&
                this.isEqual(config.sequenceEntity.id, values.sequenceEntity.id) &&
                this.isEqual(config.sequenceEntity.name, values.sequenceEntity.name) &&
                this.isEqual(
                  JSON.stringify(config.mappingConfiguration),
                  values.mappingConfiguration,
                )
              );
            }
          }),
        )
        .subscribe((isDisabled) => {
          this.saveButtonDisabled$.next(isDisabled);
        }),
    );
  }

  isEqual(obj1: any, obj2: any) {
    return (!obj1 && !obj2) || obj1 == obj2;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit() {
    const confirmationDialogRef = this.dialogService.showConfirmationDialog({
      title: `Are you sure?`,
      content:
        'Updated configuration will be used for everyone in your organization. Are you sure you want to save the changes?',
      confirmationButtonText: 'Save',
      confirmationButtonColor: 'danger',
    });

    confirmationDialogRef.result.then((confirmed) => {
      if (confirmed) {
        combineLatest([
          this.store.select(selectBioregisterSettings).pipe(first()),
          this.store.pipe(select(selectOrganizationID)),
        ]).subscribe(([settings, organizationID]) => {
          this.updateSettings(settings, organizationID);
        });
      }
    });
  }

  updateSettings(settings: OrganizationSetting[], organizationID: string) {
    this.updateSetting(settings, 'bioregisterURL', 'bioregisterURL', organizationID);
    this.updateSetting(settings, 'sequenceEntityName', 'sequenceEntity.name', organizationID);
    this.updateSetting(settings, 'sequenceEntityId', 'sequenceEntity.id', organizationID);
    this.updateSetting(settings, 'complexEntityName', 'complexEntity.name', organizationID);
    this.updateSetting(settings, 'complexEntityId', 'complexEntity.id', organizationID);
    let mappingConfiguration = JSON.parse(this.form.get('mappingConfiguration').value);
    this.updateSettingByValue(
      settings,
      'bioregisterMappingConfiguration',
      mappingConfiguration,
      organizationID,
    );

    this.saveButtonDisabled$.next(true);
  }

  updateSettingByValue(
    settings: OrganizationSetting[],
    settingName: string,
    newValue: any,
    organizationID: string,
  ) {
    const existingSetting = settings.find((setting) => setting.name === settingName);

    if (newValue && !existingSetting) {
      this.store.dispatch(
        bioRegisterConfigurationActions.create({
          name: settingName,
          organizationID: organizationID,
          data: newValue,
        }),
      );
    } else if (newValue && existingSetting.data != newValue) {
      existingSetting.data = newValue;
      this.store.dispatch(
        bioRegisterConfigurationActions.update({ settingToUpdate: existingSetting }),
      );
    } else if (!newValue && existingSetting) {
      this.store.dispatch(
        bioRegisterConfigurationActions.remove({
          id: existingSetting.id,
        }),
      );
    }
  }
  updateSetting(
    settings: OrganizationSetting[],
    settingName: string,
    path: string,
    organizationID: string,
  ) {
    let newValue = this.form.get(path).value;
    this.updateSettingByValue(settings, settingName, newValue, organizationID);
  }
}

export interface BioregisterConfiguration {
  bioregisterURL: string;
  sequenceEntity: {
    name: string;
    id: number;
  };
  complexEntity: {
    name: string;
    id: number;
  };
  mappingConfiguration: string;
}

export const nameOrIDValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const name = control.get('name');
  const id = control.get('id');
  if (name.value || id.value) {
    id.setErrors(clearedErrors(id.errors));
    name.setErrors(clearedErrors(name.errors));
    return null;
  } else {
    control.setErrors({ idOrNameRequired: true });
    id.setErrors(addError(id.errors));
    name.setErrors(addError(name.errors));
    return { idOrNameRequired: true };
  }

  function clearedErrors(errors: ValidationErrors): ValidationErrors {
    if (errors && Object.keys(errors).length > 0) {
      const { ['idOrNameRequired']: _, ...cleared } = errors;
      return cleared;
    } else {
      return null;
    }
  }

  function addError(errors: ValidationErrors): ValidationErrors {
    if (errors && Object.keys(errors).length > 0) {
      errors['idOrNameRequired'] = true;
      return errors;
    } else {
      return { idOrNameRequired: true };
    }
  }
};
