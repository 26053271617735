import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class GroupSequencesJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'group-sequences',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: GroupSequencesJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: GroupSequencesJobOptionsV1,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}

export interface GroupSequencesJobOptionsV1 {
  name?: string;
}
