import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, map, startWith, takeUntil } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { MODAL_DATA } from 'src/app/shared/dialog';
import { DatabaseFolder } from '../../folders/models/folder.model';
import { BlastService } from '../blast.service';
import { MatIconModule } from '@angular/material/icon';
import { DatabaseTypeControlComponent } from '../database-type-control/database-type-control.component';
import { AsyncPipe } from '@angular/common';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';

type SubmitState = 'disabled' | 'enabled' | 'submitting' | 'error';

@Component({
  selector: 'bx-update-reference-db-dialog',
  templateUrl: './update-reference-db-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    DatabaseTypeControlComponent,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class UpdateReferenceDbFormComponent extends CleanUp implements OnInit {
  readonly databaseTypeControl: FormControl;
  readonly submitState$ = new BehaviorSubject<SubmitState>('disabled');
  readonly folder: DatabaseFolder;

  constructor(
    @Inject(MODAL_DATA) modalData: { folder: DatabaseFolder },
    private readonly modal: NgbActiveModal,
    private readonly blastService: BlastService,
  ) {
    super();
    this.folder = modalData.folder;
    this.databaseTypeControl = new FormControl(this.folder.databaseType, [
      Validators.required,
      ({ value }) => (value === this.folder.databaseType ? { sameAsOriginalType: true } : null),
    ]);
  }

  ngOnInit(): void {
    this.databaseTypeControl.statusChanges
      .pipe(
        startWith(this.databaseTypeControl.status),
        map((status) => (status === 'VALID' ? 'enabled' : 'disabled')),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((submitState) => this.submitState$.next(submitState));
  }

  submit() {
    const type = this.databaseTypeControl.value;
    this.submitState$.next('submitting');
    this.blastService.updateDatabaseType(this.folder, type).subscribe({
      next: () => {
        this.folder.databaseType = type;
        this.submitState$.next('enabled');
        this.close('completed');
      },
      error: () => this.submitState$.next('error'),
    });
  }

  close(reason: 'canceled' | 'completed') {
    this.submitState$.complete();
    if (reason === 'canceled') {
      this.modal.dismiss(reason);
    } else {
      this.modal.close();
    }
  }
}
