import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[bxClickManager]',
  standalone: true,
})
export class SingleClickDirective implements OnInit, OnDestroy {
  @Input() bxClickManager: any;
  @Output() bxSingleClick = new EventEmitter();
  @Output() bxDoubleClick = new EventEmitter();
  private singleClicked = false;
  private doubleClicked = false;
  private clickListener: Function;
  private dblClickListener: Function;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.clickListener = this.renderer.listen(this.element.nativeElement, 'click', (e) =>
      this.onClick(e),
    );
    this.dblClickListener = this.renderer.listen(this.element.nativeElement, 'dblclick', (e) =>
      this.onDoubleClick(e),
    );
  }

  // Destroy Listeners.
  ngOnDestroy() {
    this.clickListener();
    this.dblClickListener();
  }

  onClick(e: Event) {
    // A double click fires 2x single click events and 1x double click event.
    // The following if statement prevents the body being executed twice.
    if (this.singleClicked) {
      return;
    }
    e.stopPropagation();

    setTimeout(() => {
      if (!this.doubleClicked) {
        // Has been single clicked.
        this.bxSingleClick.emit(e);
      }
      this.singleClicked = false;
      this.doubleClicked = false;
    }, 200);
    this.singleClicked = true;
  }

  onDoubleClick(e: Event) {
    this.doubleClicked = true;
    this.bxDoubleClick.emit(e);
  }
}
