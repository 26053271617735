import { PipelineIdentifier } from '@geneious/nucleus-api-client';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class NGSJobParametersV1<OptionValues> implements JobConfigurationWithSelection {
  pipeline: PipelineIdentifier = {
    name: 'ngs',
    version: 1,
  };

  constructor(
    version: number,
    public parameters: {
      selection: SelectionOptionsV1;
      options: NGSJobOptionsV1<OptionValues>;
      output?: OutputOptions;
    },
  ) {
    this.pipeline.version = version;
  }
}

export interface NGSJobOptionsV1<OptionValues> {
  optionValues: OptionValues; // map of all option names to values
  resultName?: string;
}
