import { AppState } from '../../../core.store';
import {
  ComparisonsGraphDocumentState,
  ComparisonsGraphState,
} from './ngs-comparisons-graph-data-store.reducer';
import { createSelector } from '@ngrx/store';

export const selectComparisonsData = (state: AppState) => state.ngsComparisonsGraphData;

export const selectParamsForComparisonsDocument = (id: string) =>
  createSelector(selectComparisonsData, (state: ComparisonsGraphState) => ({ ...state[id], id }));

export const selectDataForComparisonsDocument = (id: string) =>
  createSelector(
    selectParamsForComparisonsDocument(id),
    ({ data, columns }: ComparisonsGraphDocumentState) => ({ data, columns }),
  );

export const selectLoadingStateForComparisonsDocument = (id: string) =>
  createSelector(selectParamsForComparisonsDocument(id), ({ loading }) => loading);
