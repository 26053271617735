import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, concatMap, forkJoin, Observable, of, Subscription } from 'rxjs';
import { FilesTableFacade } from '../../files-table/files-table.facade';
import { filter, map, take, tap } from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { Item } from '@geneious/nucleus-api-client';
import { SelectComponent } from '../../../shared/select/select.component';
import { AsyncPipe } from '@angular/common';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { FileButtonComponent } from '../../../shared/file-button/file-button.component';
import { ClearFileButtonComponent } from '../../../shared/clear-file-button/clear-file-button.component';

type CloudFile = Pick<Item, 'id' | 'name'>;

@Component({
  selector: 'bx-assay-data-file-picker',
  templateUrl: './assay-data-file-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SelectComponent,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectComponent,
    FileButtonComponent,
    ClearFileButtonComponent,
    AsyncPipe,
  ],
})
export class AssayDataFilePickerComponent implements OnInit, OnDestroy {
  @Output() newFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() fetchStart: EventEmitter<void> = new EventEmitter();
  @Output() fetchEnd: EventEmitter<void> = new EventEmitter();
  readonly fileTypes: string[] = ['.xlsx', '.xls', '.csv'];

  availableAssayDataFilesOnCloud$: Observable<SelectOption<CloudFile>[]>;
  availableAssayDataSources: SelectOption[] = [
    { displayName: 'File System', value: 'fileSystem' },
    { displayName: 'Current Folder', value: 'cloud' },
  ];

  assayDataSource = new FormControl('fileSystem');
  existingAssayDataFiles = new FormControl<CloudFile[]>(null);

  selectedAssayFiles$ = new BehaviorSubject<File[]>([]);

  subscriptions = new Subscription();

  private documentPartCache = new Map<string, any>();

  constructor(
    private filesTableFacade: FilesTableFacade,
    private documentHttpService: DocumentHttpV2Service,
  ) {}

  ngOnInit(): void {
    this.availableAssayDataFilesOnCloud$ = this.filesTableFacade.files$.pipe(
      take(1),
      map((fileItems) =>
        fileItems
          .filter(
            (item) =>
              item.metadata.documentType === 'CSV' || item.metadata.documentType === 'EXCEL',
          )
          .map((item) => new SelectOption(item.name, { name: item.name, id: item.id })),
      ),
    );

    this.subscriptions.add(
      this.selectedAssayFiles$.subscribe((files) => {
        this.newFiles.emit(files);
      }),
    );

    this.subscriptions.add(
      this.assayDataSource.valueChanges.subscribe(() => {
        this.selectedAssayFiles$.next([]);
      }),
    );

    this.subscriptions.add(
      this.existingAssayDataFiles.valueChanges
        .pipe(
          filter((files) => !!files),
          filter((files) => !!files[0]),
          tap(() => {
            this.fetchStart.emit();
            this.selectedAssayFiles$.next([]);
          }),
          concatMap((files) => {
            const fileRequests = files.map(({ name, id }) =>
              this.getDocumentBlobForFileID(id, name),
            );
            return forkJoin(fileRequests);
          }),
          map((files) => files.map(({ name, blob }) => new File([blob], name))),
          tap(() => this.fetchEnd.emit()),
        )
        .subscribe((assayFiles) => {
          this.selectedAssayFiles$.next(assayFiles);
        }),
    );
  }

  private getDocumentBlobForFileID(id: string, name: string) {
    // We store a local cache of document blobs because we don't want to spam nucleus.
    let documentBlob;
    if (this.documentPartCache.has(id)) {
      documentBlob = of(this.documentPartCache.get(id));
    } else {
      documentBlob = this.documentHttpService
        .getDocumentPart(id, 'FILE', 'blob')
        .pipe(tap((blob) => this.documentPartCache.set(id, blob)));
    }
    return documentBlob.pipe(map((blob) => ({ name, blob })));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleFileUpload(files: File[]) {
    this.selectedAssayFiles$.next(files);
  }

  handleClearFile(file: File) {
    this.selectedAssayFiles$.next(
      this.selectedAssayFiles$.value.filter((selectedFile) => selectedFile.name !== file.name),
    );
  }
}
