import { Injectable, OnDestroy } from '@angular/core';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { delay } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NucleusPipelineID, PipelineFormID } from '../../pipeline/pipeline-constants';

@Injectable({
  providedIn: 'root',
})
export abstract class GenericDialogComponent extends JobDialogContent implements OnDestroy {
  form: BxFormGroup;
  elements = new Map<string, Element>();
  type = ModalElementType;
  faChevronRight = faChevronRight;

  protected formDefaults: any;

  private subscriptions = new Subscription();
  private collapsed = new Map<string, boolean>();

  protected constructor(
    nucleusPipelineID: NucleusPipelineID,
    pipelineFormID: PipelineFormID,
    public layout: ModalLayout,
  ) {
    super(nucleusPipelineID, pipelineFormID);

    const controls: { [type: string]: AbstractControl } = {};
    this.getControls(layout).forEach((element) => {
      this.elements.set(element.id, element);

      const validators = [];
      if (element.validators) {
        if (element.validators.min !== undefined) {
          validators.push(Validators.min(element.validators.min));
        }
        if (element.validators.max !== undefined) {
          validators.push(Validators.max(element.validators.max));
        }
        if (element.validators.others !== undefined) {
          element.validators.others.forEach((e) => validators.push(e));
        }
      }

      controls[element.id] = new BxFormControl(element.value, validators);
      if (element.type === ModalElementType.RADIO_GROUP) {
        const options = element.value;
        if (options != null && Array.isArray(options) && options.length > 0) {
          controls[element.id].setValue(options[0].value);
        }
      }
    });
    controls['outputFolderName'] = JobDialogContent.getResultNameControl();
    this.form = new BxFormGroup(controls);
    this.formDefaults = this.form.getRawValue();

    this.elements.forEach((element) => {
      if (element.disable) {
        const valueChanges = element.disable.map(
          (condition) => this.form.get(condition.element).valueChanges,
        );
        this.subscriptions.add(
          combineLatest(valueChanges)
            .pipe(
              // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
              // to apply the disable/enable logic here after that.
              delay(0),
            )
            .subscribe((values) => {
              for (let i = 0; i < values.length; i++) {
                if (values[i] === element.disable[i].state) {
                  this.form.get(element.id).disable();
                  return;
                } else {
                  this.form.get(element.id).enable();
                }
              }
            }),
        );
      }
    });

    this.layout.sections.forEach((section) => {
      if (section.collapse !== undefined) {
        this.collapsed.set(section.title, section.collapse);
      }
    });
  }

  toggle(id: string) {
    this.collapsed.set(id, !this.collapsed.get(id));
  }

  isCollapsed(id: string) {
    return this.collapsed.get(id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  protected getControls(layout: ModalLayout): Element[] {
    return layout.sections
      .map((section) => this.getFormControlElements(section))
      .reduce((acc, val) => acc.concat(val), []);
  }

  private getFormControlElements(section: Section): Element[] {
    return section.rows
      .map((row) =>
        row.elements.filter(
          (element) =>
            element.type !== ModalElementType.LABEL && element.type !== ModalElementType.TEXT,
        ),
      )
      .reduce((acc, val) => acc.concat(val), []);
  }
}

export enum ModalElementType {
  TEXT_INPUT = 'textInput',
  NUMBER_INPUT = 'numberInput',
  SELECT = 'select',
  LABEL = 'label',
  TEXT = 'text',
  LABEL_WITH_CHECKBOX = 'labelWIthCheckbox',
  RADIO_GROUP = 'radioGroup',
}

export interface ModalLayout {
  sections: Section[];
}

interface Section {
  title: string;
  rows: Row[];
  collapse?: boolean;
}

interface Row {
  elements: Element[];
}

interface Element {
  type: ModalElementType;
  size?: number;
  id?: string;
  formControlName?: string;
  value?: string | number | boolean | Option[];
  label?: string;
  tooltip?: string;
  placeholder?: string;
  append?: string;
  validators?: {
    min?: number;
    max?: number;
    others?: ValidatorFn[];
  };
  disable?: {
    element: string;
    state: boolean;
  }[];
}

interface Option {
  id?: string;
  value: any;
  label: string;
  tooltip?: string;
}
