<div class="modal-header">
  <h4 class="modal-title">Folder Information</h4>

  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <div class="px-3 text-muted">
    <p>
      Enter information for the selected folder and click Save to save it. This information is
      useful for searching for a specific experiment and keeping track of projects.
    </p>
  </div>

  <form [formGroup]="metadataForm">
    <div class="mb-3 row" ngbTooltip="Used for search." placement="right">
      <label for="target" class="col-4 col-form-label"> Target antigen: </label>
      <div class="col-8">
        <input
          type="text"
          id="target"
          class="form-control"
          name="targetAntigen"
          formControlName="targetAntigen"
        />
      </div>
    </div>

    <div class="mb-3 row" ngbTooltip="Reasons for carrying out this experiment." placement="right">
      <label for="description" class="col-4 col-form-label"> Description: </label>
      <div class="col-8">
        <input
          type="text"
          id="description"
          class="form-control"
          name="description"
          formControlName="description"
        />
      </div>
    </div>

    <div class="mb-3 row" ngbTooltip="Record findings for yourself and others." placement="right">
      <label for="notes" class="col-4 col-form-label"> Notes: </label>
      <div class="col-8">
        <textarea
          type="text"
          id="notes"
          class="form-control"
          name="notes"
          formControlName="notes"
          rows="5"
        >
        </textarea>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  @if ((updateForm$ | async)?.error && (submitting$ | async) === false) {
    <div class="text-error text-center">Updating failed</div>
  }

  <button class="btn btn-danger" *bxShowIf="'disableAllPipelines'" disabled="disabled">
    Readonly mode
  </button>

  <button
    class="btn btn-primary"
    type="submit"
    bx-spinner-button
    [disabled]="(submitting$ | async) || metadataForm.pristine || (disableAllPipelines$ | async)"
    [spin]="submitting$ | async"
    (click)="onSubmit()"
    data-testid="folder-info-save"
  >
    Save
  </button>

  <button
    type="button"
    class="btn btn-secondary"
    (click)="close()"
    data-testid="folder-info-cancel"
  >
    Cancel
  </button>
</div>
