import { Observable, switchMap } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { BuildInformationService } from '../build-information.service';

@Injectable({
  providedIn: 'root',
})
export class BuildInfoInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private buildInfo: BuildInformationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't add any headers for non-nucleus routes.
    if (!req.url.startsWith(this.config.nucleusApiBaseUrl)) {
      return next.handle(req.clone());
    }

    return this.buildInfo.buildInformation$.pipe(
      switchMap((buildInfo) => {
        let userAgentValue = `web-ui/${buildInfo.buildHash}:${buildInfo.buildDate}`;
        if (buildInfo.releaseVersion) {
          userAgentValue += `:${buildInfo.releaseVersion}`;
        }

        const userAgentHeader = {
          'X-Biomatters-User-Agent': userAgentValue,
        };

        const modified = req.clone({
          setHeaders: userAgentHeader,
        });
        return next.handle(modified);
      }),
    );
  }
}
