import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { APP_CONFIG, AppConfig, fetchConfig } from './app/app.config';
import { softBlockUnsupportedBrowsers } from './browser-support';
import { browserTracingIntegration } from '@sentry/angular-ivy';
import { Window } from './app/core/utils/window';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CoreModule } from './app/core/core.module';
import { RouterModule } from '@angular/router';
import { SentryModule } from './app/core/sentry/sentry.module';
import { AppComponent } from './app/app.component';

softBlockUnsupportedBrowsers();

fetchConfig().then((config) => {
  bootstrapApp(config);
});

function bootstrapApp(config: AppConfig) {
  if (config.sentryDSN && config.sentryRelease && config.sentryDSN !== 'SENTRY_DSN_PLACEHOLDER') {
    Sentry.init({
      dsn: config.sentryDSN,
      release: config.sentryRelease,
      integrations: [browserTracingIntegration()],
      environment: config.APP_ENVIRONMENT,
      // TODO Reduce frequency for production. Maybe 0.25?
      tracesSampleRate: 1.0,
      maxBreadcrumbs: 20,
    });
  } else {
    console.info('Missing Sentry DSN or release. Will not enable Sentry tracking.');
  }

  if (environment.production) {
    enableProdMode();
  }

  bootstrapApplication(AppComponent, {
    providers: [
      { provide: APP_CONFIG, useValue: config },
      importProvidersFrom(BrowserModule, CoreModule, RouterModule, SentryModule),
      { provide: Window, useValue: window },
    ],
  }).catch((err) => console.error(err));
}
