import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  AnnotationNameAndCount,
  SequenceSelectionService,
} from '../../sequence-viewer/sequence-selection.service';
import { Observable, Subscription } from 'rxjs';
import { combineLatest, distinct, filter, map, share, startWith } from 'rxjs/operators';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  MotifAnnotatorJobOptions,
  MotifAnnotatorJobParameters,
} from '../../../../nucleus/services/models/MotifAnnotatorJobParameters.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SequenceUtils } from '../../sequence-utils';
import { SequenceAlphabet } from '../../sequence-alphabet.model';
import {
  AntibodyChainOptionChoices,
  ChainNameQualifierName,
  ChainQualifierName,
  Region,
} from '../../../../nucleus/services/models/alignmentOptions.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import { getRegionOptionLabel } from '../alignment/alignment-helper';
import { SequenceData } from '@geneious/sequence-viewer/types';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { SelectComponent } from '../../../shared/select/select.component';

@Component({
  selector: 'bx-motif-annotator',
  templateUrl: './motif-annotator.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CardComponent, SelectComponent, AsyncPipe],
})
export class MotifAnnotatorComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  earlyRelease = true;

  readonly MAX_ITEMS_CHECKED = 200;

  annotateRegionsOptions$: Observable<SelectGroup<AnnotationNameAndCount>[]>;
  allRegions$: Observable<AnnotationNameAndCount[]>;
  formSub: Subscription;
  wantsToAlignByCdsButHasNoCdsAnnotations$: Observable<boolean>;
  chains = AntibodyChainOptionChoices;

  title = 'Motif Discovery';
  message = '';
  knowledgeBaseArticle: string;

  public readonly form = new BxFormGroup({
    inputIsNucleotides: new BxFormControl(true, Validators.required),
    alignCds: new BxFormControl(false),
    region: new FormControl<AnnotationNameAndCount>(undefined),
    minMotifLength: new FormControl(2, Validators.min(1)),
    maxMotifLength: new FormControl(10, Validators.min(1)),
    minMotifCount: new FormControl(2, Validators.min(1)),
    numMotifs: new FormControl(10, Validators.min(1)),
    splitOn: new FormControl(''),
    includeClassMotif: new BxFormControl(true, Validators.required),
    translation: new BxFormGroup({
      frame: new BxFormControl(0),
      geneticCode: new BxFormControl('Standard'),
      considerStart: new BxFormControl(AlternativeStartEnum.AUTO),
    }),
  });

  private readonly formDefaults: any;
  private readonly selected: SelectionState;
  private readonly otherVariables: any;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private sequenceSelectionService: SequenceSelectionService,
  ) {
    super('motif-annotator', PipelineFormID.MOTIF_ANNOTATOR);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
    this.otherVariables = this.dialogData.otherVariables;
  }

  ngOnInit() {
    const resultWarning =
      'Note: Re-running pipeline will erase any previous motif annotations on this document.';
    const nonResultWarning =
      'Note: This pipeline creates a new Sequence List document with motif annotations.';
    this.message =
      this.otherVariables && this.otherVariables.isResult ? resultWarning : nonResultWarning;

    const regions$ = this.retrieveRegions().pipe(share());
    this.allRegions$ = regions$;
    this.annotateRegionsOptions$ = regions$.pipe(
      map((regions) =>
        regions.reduce((acc, curr) => {
          const option = new SelectOption<AnnotationNameAndCount>(getRegionOptionLabel(curr), curr);
          const currentChainName = curr.chainName ?? curr.chain ?? 'Unknown';
          const chainIndex = acc.findIndex(({ label }) => label === currentChainName);
          if (chainIndex === -1) {
            acc.push(new SelectGroup<AnnotationNameAndCount>([option], currentChainName));
          } else {
            acc[chainIndex].options.push(option);
          }
          return acc;
        }, [] as SelectGroup<AnnotationNameAndCount>[]),
      ),
      map((options) => {
        const unknownRegionIndex = options.findIndex(({ label }) => label === 'Unknown');
        if (unknownRegionIndex !== -1) {
          delete options[unknownRegionIndex].label;
        }
        return options;
      }),
    );

    const alignCdsControl = this.form.get('alignCds');
    const useCdsWithNameControl = this.form.get('region');
    const alignCdsControlValueChanges$ = alignCdsControl.valueChanges.pipe(
      startWith(alignCdsControl.value),
    );

    this.wantsToAlignByCdsButHasNoCdsAnnotations$ = regions$.pipe(
      combineLatest(alignCdsControlValueChanges$),
      filter(([regions]) => !regions.length),
      map(([_, alignByCds]) => alignByCds),
    );

    // If Align CDS is true, then use cds with name should be required.
    alignCdsControlValueChanges$.subscribe((val: boolean) => {
      if (val) {
        useCdsWithNameControl.setValidators([Validators.required]);
      } else {
        useCdsWithNameControl.clearValidators();
      }

      useCdsWithNameControl.updateValueAndValidity();
    });

    // Set initial view state before any changes have been made to the form by the user.
    const initialValue = this.form.getRawValue();
    this.updateViewState(initialValue);

    // Un-subscription is not necessary.
    this.formSub = this.form.valueChanges
      .pipe(distinct())
      // `getRawValue` gets values of disabled fields which we rely on.
      .subscribe((ignore) => this.updateViewState(this.form.getRawValue()));

    this.form.controls.inputIsNucleotides.patchValue(
      SequenceUtils.isSelectionOfType(SequenceAlphabet.NUCLEOTIDE, this.selected.selectedRows),
    );
  }

  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  compareRegion(a: AnnotationNameAndCount, b: AnnotationNameAndCount): boolean {
    if (!a || !b) {
      return false;
    }
    return a.name === b.name && a.chainName === b.chainName && a.chain === b.chain;
  }

  updateViewState(formValue: any) {
    const useCdsWithNameControl = this.form.controls['region'];
    const isNucleotidesControl = this.form.controls['inputIsNucleotides'];

    this.toggleEnabled(useCdsWithNameControl, formValue.alignCds);

    if (this.otherVariables && this.otherVariables.isResult && !formValue.inputIsNucleotides) {
      // Override any previously saved form values. This will cause updateViewState() to be called again.
      isNucleotidesControl.setValue(true);
    }
  }

  /**
   * Convert the value from the Angular form to what the server expects.
   * Remove any Angular idiosyncrasies (for example that all values are set even when that doesn't make sense.
   *
   * - public for testing.
   */
  public static prepareJobOptions(
    alignmentForm: any,
    otherVariables: any,
  ): MotifAnnotatorJobOptions {
    const result: MotifAnnotatorJobOptions = {
      alignCds: alignmentForm.alignCds,
      numMotifs: alignmentForm.numMotifs,
      minMotifCount: alignmentForm.minMotifCount,
      minMotifLength: alignmentForm.minMotifLength,
      maxMotifLength: alignmentForm.maxMotifLength,
      splitOn: alignmentForm.splitOn,
      includeClassMotif: alignmentForm.includeClassMotif,
    };

    if (otherVariables && otherVariables.extraction) {
      result.extraction = otherVariables.extraction;
    }

    result.translation = {
      frame: alignmentForm.translation.frame,
      geneticCode: alignmentForm.translation.geneticCode,
      useAlternativeStartCodon: alignmentForm.translation.considerStart,
    };

    if (alignmentForm.alignCds) {
      const region: Region = {
        name: alignmentForm.region.name,
        qualifierFilters: [],
      };

      if (alignmentForm.region.chain) {
        region.qualifierFilters.push({
          name: ChainQualifierName,
          value: alignmentForm.region.chain,
        });
      }

      if (alignmentForm.region.chainName) {
        region.qualifierFilters.push({
          name: ChainNameQualifierName,
          value: alignmentForm.region.chainName,
        });
      }

      result.regions = [region];
    }

    return result;
  }

  private retrieveRegions(): Observable<AnnotationNameAndCount[]> {
    return this.retrieveAnnotations().pipe(
      map((annotatedSequences: SequenceData[]) =>
        SequenceSelectionService.getAllAnnotationNames(annotatedSequences),
      ),
    );
  }

  // TODO Don't use IGridResource here - refactor so that it is not exposed to viewers or dialogs.
  retrieveAnnotations(): Observable<SequenceData[]> {
    if (this.otherVariables.getSequences) {
      return this.otherVariables.getSequences();
    } else {
      // Limit the amount of sequence data queries made - we only need a representative list of CDS annotations.
      // Sequence lists will not be checked for annotations as we currently do not have sequence lists that contain annotations (?).
      // TODO Check CDS annotations in sequence lists, when we have a concrete use case for this.

      const filterFunc = (item: any) => item.type === 'sequence' || item.type === 'sequenceList';

      return this.sequenceSelectionService.fetchSequenceData(
        this.selected.selectedRows,
        this.MAX_ITEMS_CHECKED,
        filterFunc,
      );
    }
  }

  /**
   * Called by the pipeline chooser.
   */
  run() {
    // `getRawValue` gets values of disabled fields which we rely on.
    const formValue = this.form.getRawValue();

    const parameters = {
      options: MotifAnnotatorComponent.prepareJobOptions(formValue, this.otherVariables),
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {},
    };
    return new MotifAnnotatorJobParameters(parameters);
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  private toggleEnabled(control: AbstractControl, enabled: boolean) {
    const action = enabled ? 'enable' : 'disable';
    control[action]({
      // Hack to make `emitEvent` work, otherwise we get `Maximum call stack size exceeded`.
      onlySelf: true,
      // Prevent infinite loop.
      emitEvent: false,
    });
  }
}

export enum AlternativeStartEnum {
  AUTO = 'AUTO',
  USE = 'USE',
  IGNORE = 'IGNORE',
}

export class AlternativeStartOption {
  label: string;
  key: AlternativeStartEnum;
}

export const considerStartOptions = [
  { label: 'Auto-detect', key: AlternativeStartEnum.AUTO },
  { label: 'Always consider', key: AlternativeStartEnum.USE },
  { label: 'Always ignore', key: AlternativeStartEnum.IGNORE },
];
