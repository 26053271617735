import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  NgbDropdown,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { ShowIfDirective } from 'src/app/shared/access-check/directives/show/show-if.directive';

@Component({
  selector: 'bx-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    FaIconComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    AsyncPipe,
    ShowIfDirective,
  ],
})
export class SearchBarComponent {
  @HostBinding('class.focused') focused: boolean = false;
  @Input() searchQuery = '';
  @Output() search = new EventEmitter<GlobalSearchParams>();

  readonly GlobalSearchTypes = GlobalSearchTypes;
  readonly searchTypeLabels: Record<GlobalSearchTypes, string> = {
    FILE_FOLDER: 'Files and folders',
    RESULT: 'CDR3 regions',
  };
  selectedType = signal(GlobalSearchTypes.FILE_FOLDER);
  readonly searchIcon = faSearch;
  readonly clearIcon = faTimes;

  onSubmit() {
    this.search.emit({ term: this.searchQuery, type: this.selectedType() });
  }

  clearSearch() {
    this.searchQuery = '';
  }

  setFocused(value: boolean) {
    this.focused = value;
  }
}

export enum GlobalSearchTypes {
  FILE_FOLDER = 'FILE_FOLDER',
  RESULT = 'RESULT',
}

export interface GlobalSearchParams {
  term: string;
  type: GlobalSearchTypes;
}
