import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { AuthService, NewSignUpRequest } from '@geneious/nucleus-api-client';
import { switchMap } from 'rxjs/operators';
import { Router, RouterLink } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Subject } from 'rxjs';
import { FreeTierLoginComponent } from '../free-tier-login/free-tier-login.component';
import { SpinnerButtonComponent } from '../../../../shared/spinner-button/spinner-button.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-signup-with-email',
  templateUrl: './signup-with-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FreeTierLoginComponent, FormsModule, SpinnerButtonComponent, RouterLink, AsyncPipe],
})
export class SignupWithEmailComponent implements OnInit, OnDestroy {
  email: string;
  name?: string;
  submitting$ = new BehaviorSubject<boolean>(false);
  signUpError$ = new Subject<string>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  ngOnInit() {
    document.body.classList.add('show-captcha-badge');
  }

  ngOnDestroy() {
    document.body.classList.remove('show-captcha-badge');
  }

  submit(signUpForm: NgForm) {
    if (signUpForm.valid) {
      this.submitting$.next(true);
      const action = 'sign_up';
      this.recaptchaV3Service
        .execute(action)
        .pipe(
          switchMap((token) => {
            const signUp: NewSignUpRequest = {
              givenName: this.name,
              email: this.email,
              recaptchaToken: token,
            };
            return this.authService.createSignUpRequest(signUp);
          }),
        )
        .subscribe(
          () => this.router.navigate(['/sign-up/complete']),
          (errorResponse) => {
            this.signUpError$.next(errorResponse.error.error.message);
            this.submitting$.next(false);
          },
        );
    }
  }
}
