import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DownloadTableOptions } from '../exportable-chart';
import { GraphUtilService } from '../graph-util.service';
import { DownloadBlobService } from '../../../core/download-blob.service';
import { scatterplotChartTooltipFormatter } from '../tooltips/scatterplot-chart-tooltip';
import { BaseChartComponent } from '../abstract-column-chart/base-chart.component';
import {
  ChartOptions,
  SeriesClickCallbackFunction,
  SeriesClickEventObject,
  SeriesScatterOptions,
  XAxisOptions,
} from 'highcharts';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'bx-scatterplot-chart',
  templateUrl: '../abstract-column-chart/base-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ChartComponent],
})
export class ScatterplotChartComponent extends BaseChartComponent<SeriesScatterOptions> {
  tooltipOptions = {
    formatter: scatterplotChartTooltipFormatter,
  };

  plotOptions = {
    series: {
      turboThreshold: 0,
    },
    scatter: {
      events: {
        legendItemClick: () => false,
        click: (event: SeriesClickEventObject) => {},
      },
    },
    jitter: {
      x: 0.05,
    },
  };

  chartOptions: ChartOptions = {
    type: 'scatter',
  };
  xAxisOptions: XAxisOptions = {
    type: 'linear',
    labels: {
      overflow: 'justify',
    },
  };

  yAxisOptions: XAxisOptions = {
    type: 'linear',
    labels: {
      overflow: 'justify',
    },
  };

  @Input() set onPointClick(fn: SeriesClickCallbackFunction) {
    this.plotOptions = {
      ...this.plotOptions,
      scatter: {
        ...this.plotOptions?.scatter,
        events: {
          ...this.plotOptions?.scatter?.events,
          click: fn,
        },
      },
    };
  }

  downloadTable({ documentName }: DownloadTableOptions): void {
    const name = documentName
      ? `${this.titleOptions.text} (${documentName})`
      : this.titleOptions.text;

    const xAxisTitle = this.replaceInvalidCharacters(this.xAxisOptions.title.text);
    const yAxisTitle = this.replaceInvalidCharacters(this.yAxisOptions.title.text);

    const headers = `${xAxisTitle},${yAxisTitle}\n`;
    const body = this.series[0].data
      .map((point) => {
        const pointX = GraphUtilService.getPointX(point);
        const values = this.series.map((series) => {
          const match = series.data.find((p) => GraphUtilService.getPointX(p) === pointX);
          return GraphUtilService.getPointY(match);
        });
        return `${pointX},${values.join(',')}`;
      })
      .join('\n');
    DownloadBlobService.download(`${name}.csv`, headers + body);
  }

  // Some programs (Eg excell) don't work if there are symbols or equal signs at the start of a
  // column in csv format
  private replaceInvalidCharacters(text: string) {
    return text.replace(/^=/, '').replace(/-/, 'neg').replace(/\+/, 'pos');
  }
}
