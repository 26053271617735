import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  fetchUserSettingsByKindFromServer,
  fetchUserSettingsSuccess,
  upsertUserSetting,
} from '../user-settings.actions';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { fetchAllFormState, setFormStates, updateFormState } from './form-state.actions';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { UserSettingsKinds } from '../user-settings-types';
import { runMigrations } from './migrations/form-state-migrations';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { FormState } from './form-state.model';

@Injectable()
export class FormStateEffects {
  fetchAllFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllFormState),
      map(() => fetchUserSettingsByKindFromServer({ kind: UserSettingsKinds.FORM_STATE })),
    ),
  );

  fetchFormStatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingsSuccess),
      filter(({ userSettings, kind }) => kind === UserSettingsKinds.FORM_STATE),
      switchMap(({ userSettings }) =>
        this.featureSwitchService.features$.pipe(
          take(1),
          map((features) => {
            const formStates = userSettings.reduce(
              (acc, userSetting) => {
                acc[userSetting.name as PipelineFormID] = userSetting.data as FormState;
                return acc;
              },
              {} as Partial<Record<PipelineFormID, FormState>>,
            );
            return runMigrations(formStates, features);
          }),
        ),
      ),
      map((formStates) => setFormStates({ formStates })),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFormState),
      map(({ name, formState }) =>
        upsertUserSetting({ kind: UserSettingsKinds.FORM_STATE, name, data: formState }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private featureSwitchService: FeatureSwitchService,
  ) {}
}
