import { IGridResource } from './grid.resource';
import { BehaviorSubject } from 'rxjs';
import { IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { first, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { IGridResourceResponse } from '../../../../nucleus/services/models/response.model';

/**
 * Used by {@link GridComponent} to fetch columns/rows for displaying in the table.
 */
export class GridDatasource extends CleanUp implements IDatasource {
  readonly total$: BehaviorSubject<number>;

  private hasSetColumns: boolean;
  private hasSentFirstResponse: boolean;

  constructor(
    private resource: IGridResource,
    private extraParams: any,
    private firstResponse: any,
    private resultSetMax: number,
  ) {
    super();

    this.total$ = new BehaviorSubject(0);

    this.hasSetColumns = false;
  }

  getRows(params: IGetRowsParams) {
    if (this.firstResponse && !this.hasSentFirstResponse) {
      this.hasSentFirstResponse = true;
      this.onSuccess(this.firstResponse, params.successCallback);
      return;
    }

    this.resource
      .query(params, this.extraParams)
      .pipe(first(), takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => this.onSuccess(response, params.successCallback),
        (error) => {
          params.failCallback();
        },
      );
  }

  destroy(): void {
    this.ngOnDestroy();
    this.total$.complete();
  }

  private onSuccess(response: IGridResourceResponse<unknown>, successCallback: any) {
    // Total used for ag-grid's scrolling limit.
    const total =
      response.metadata.total > this.resultSetMax && this.resultSetMax > 0
        ? this.resultSetMax
        : response.metadata.total;

    // This updates the row count shown to user.
    if (total !== null) {
      // total$ is for the true server total.
      this.total$.next(response.metadata.total);
    }

    successCallback(response.data, total);
  }
}
