<bx-settings-breadcrumb
  [crumbs]="[
    { link: ['', 'labels'], label: 'Labels' },
    { link: [''], label: 'Create' },
  ]"
></bx-settings-breadcrumb>
<div class="bx-page-container bx-page-container-md">
  <h3 class="mb-4">Create Label</h3>
  <form [formGroup]="form">
    <div class="mb-3 row">
      <label for="label-name" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input
          type="text"
          class="form-control"
          id="label-name"
          formControlName="labelName"
          placeholder="Name"
          ngFormControlValidator
          [canMarkPristineInvalid]="false"
        />
        <bx-form-errors
          [control]="form.controls.labelName"
          [showAllErrors]="false"
        ></bx-form-errors>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="label-color" class="col-sm-2 col-form-label">Color</label>
      <div class="col-sm-10">
        <input type="color" id="label-color" class="form-control" formControlName="color" />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="onSubmit()">
        Create Label
      </button>
    </div>
  </form>
</div>
