import { Action } from '@ngrx/store';

const prefix = '[Search]';
export const SAVE_SEARCH = `${prefix} Save search`;
export const SAVE_REFERRER = `${prefix} Save referrer`;

export class SaveSearch implements Action {
  readonly type = SAVE_SEARCH;

  constructor(public payload: any) {}
}

export class SaveReferrer implements Action {
  readonly type = SAVE_REFERRER;

  constructor(public payload: any) {}
}

export type SearchActions = SaveSearch | SaveReferrer;
