import { FileUploadGroup, UploadState } from '../../models/file-upload.model';
import { DatePipe } from '@angular/common';
import { ColDef } from '@ag-grid-community/core';
import { UploadLinkComponent } from './upload-link.component';
import {
  ProgressRendererComponent,
  ProgressRendererData,
} from '../../../features/grid/progress-renderer/progress-renderer.component';
import { ValueGetterParams } from '@ag-grid-community/core';
import { GroupUpload } from '../upload.model';

const datePipe = new DatePipe('en');

export class UploadProgressRendererData implements ProgressRendererData {
  isStarting(data: any): boolean {
    return (
      data.status?.kind === UploadState.PREPARING ||
      data.status?.kind === UploadState.QUEUED ||
      (data.status?.kind === UploadState.UPLOADING && data.status.progress === 0)
    );
  }

  isCompleted(data: any): boolean {
    return data?.status?.kind === UploadState.COMPLETED;
  }
  isCompletedWithErrors(data: any): boolean {
    return data?.status?.kind === UploadState.COMPLETED_WITH_ERRORS;
  }
  isFailed(data: any): boolean {
    return data?.status?.kind === UploadState.FAILED;
  }
  isCancelled(data: any): boolean {
    return false;
  }
}

export const uploadsTableColDefs: ColDef[] = [
  {
    field: 'id',
    headerName: '',
    width: 34,
    resizable: true,
    suppressMovable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    valueGetter: () => '',
  },
  {
    field: 'link',
    headerName: 'Folder',
    cellRenderer: UploadLinkComponent,
    width: 250,
  },
  {
    field: 'fileCount',
    headerName: '# of Files',
    valueGetter: (params) =>
      params && params.data && params.data['fileCount'] ? params.data['fileCount'] + '' : 'Unknown',
    width: 100,
  },
  {
    field: 'totalSize',
    headerName: 'Total Size',
    valueGetter: (params) =>
      params?.data?.totalSize ? GroupUpload.formatBytes(params.data.totalSize) : 'Unknown',
    width: 110,
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) =>
      params?.data?.status?.kind
        ? params.data.status.kind === UploadState.COMPLETED_WITH_ERRORS
          ? 'Completed'
          : params.data.status.kind
        : 'Unknown',
    width: 100,
  },
  {
    field: 'progress',
    headerName: 'Progress',
    valueGetter: (params) => params?.data?.status?.progress ?? 0,
    cellRenderer: ProgressRendererComponent,
    cellRendererParams: new UploadProgressRendererData(),
    width: 150,
  },
  {
    field: 'startedAt',
    headerName: 'Started',
    valueGetter: (params) => {
      if (params && params.data) {
        const upload: FileUploadGroup = params.data;
        return datePipe.transform(new Date(upload.startedAt), 'medium');
      } else {
        return '';
      }
    },
  },
  {
    field: 'finishedAt',
    headerName: 'Finished',
    valueGetter: (params) => {
      const upload = params?.data;
      if (upload?.finishedAt) {
        return datePipe.transform(new Date(upload?.finishedAt), 'medium');
      } else if (
        [
          UploadState.FAILED,
          UploadState.COMPLETED,
          UploadState.CANCELLED,
          UploadState.COMPLETED_WITH_ERRORS,
        ].includes(upload?.status?.kind) &&
        upload?.status?.updatedAt
      ) {
        return datePipe.transform(new Date(upload.status.updatedAt), 'medium');
      } else {
        return '';
      }
    },
  },
  {
    field: 'errors',
    headerName: 'Errors',
    valueGetter: getErrors,
    tooltipValueGetter: (params) => params.value,
    width: 300,
  },
];

function getErrors(params: ValueGetterParams): string {
  const upload = params?.data;
  if (
    upload?.status?.kind &&
    [UploadState.FAILED, UploadState.CANCELLED, UploadState.COMPLETED_WITH_ERRORS].includes(
      upload?.status?.kind,
    )
  ) {
    return params?.data?.status?.messages ? params?.data?.status?.messages.join(', ') : '';
  }
  return '';
}
