<br />
<p>
  For information on how to create Name Schemes, see
  <a
    href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069671-How-to-Create-a-Name-Scheme"
    target="_blank"
    >here.</a
  >
</p>

<bx-accordion>
  <bx-accordion-step
    title="Enter a name for the new scheme"
    [errors]="errors.nameSchemeName"
    (beforeDone)="validateNameField()"
  >
    <mat-form-field class="mid-width">
      <input
        matInput
        placeholder="Name"
        [(ngModel)]="schemeName"
        (keyup)="validateNameField()"
        required
        data-testid="name-scheme-name"
      />
    </mat-form-field>
  </bx-accordion-step>

  <bx-accordion-step
    title="Specify example name"
    [errors]="errors.fileName"
    (beforeDone)="validateFileName()"
  >
    <div class="mat-hint">
      Enter an example sequence name for the new scheme to parse. You can either enter the name
      below, or click the upload button to select a file on your computer which contains the
      sequence name in the filename.
    </div>
    <bx-file-chooser (fileChosen)="onFileChosen($event)"></bx-file-chooser>
  </bx-accordion-step>

  <bx-accordion-step
    title="Choose delimiter"
    [errors]="errors.delimiter"
    (beforeDone)="validateDelimiter()"
  >
    <div class="mat-hint">
      Specify one or more individual characters to use as delimiters. If multiple characters are
      used, the name will be split at every character.
    </div>
    <mat-form-field class="full-width">
      <input
        matInput
        placeholder="Delimiter"
        type="text"
        [formControl]="delimiter"
        [matAutocomplete]="auto"
        (ngModelChange)="onTokenized()"
        data-testid="name-scheme-delimiters"
      />
    </mat-form-field>
    <div class="mat-hint">Delimiters applied to example name</div>
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filteredDelimiters$ | async; track option) {
        <mat-option [value]="option.value"> {{ option.text }} ({{ option.value }}) </mat-option>
      }
    </mat-autocomplete>

    <bx-tokenizer-preview [tokens]="tokens" [labels]="labels"></bx-tokenizer-preview>
  </bx-accordion-step>

  <bx-accordion-step
    title="Configure fields"
    [errors]="errors['fields']"
    [showButtons]="false"
    (beforeDone)="validateFields()"
  >
    <div class="mat-hint">Delimiters applied to example name</div>
    <bx-tokenizer-preview [tokens]="tokens" [labels]="labels"></bx-tokenizer-preview>

    <div class="mat-hint">Define fields</div>
    <bx-name-scheme-configure-fields
      [tokens]="tokens"
      (fieldsChanged)="onFieldsChanged($event)"
    ></bx-name-scheme-configure-fields>
  </bx-accordion-step>
</bx-accordion>
