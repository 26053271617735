import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { map, Observable, of, shareReplay, startWith, switchMap, takeUntil } from 'rxjs';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { currentValueAndChanges, restrictControlValue } from 'src/app/shared/utils/forms';
import { CursorDocumentQuery } from 'src/nucleus/services/documentService/document-service.v1.http';
import { Region } from 'src/nucleus/services/models/alignmentOptions.model';
import {
  ClusterTableRowExtractionMethod,
  DocumentSequencesExportOptions,
  ExportJobParameters,
  NGSSequencesExportOptions,
  SequencesOutputType,
  TableOutputType,
} from 'src/nucleus/services/models/exportOptions.model';
import { PipelineDialogData } from '../..';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { sequencesOutputTypeOptions } from '../export-helpers';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import { NewJobResponse, VersionEnum } from '@geneious/nucleus-api-client';
import { JobResultDownloaderService } from '../../../utils/job-result-downloader.service';
import {
  isAllSequencesTable,
  isClusterTable,
  isComparisonClusterTable,
  isComparisonTable,
  isComparisonTableThatSupportsSequenceViewer,
  isExactClusterTable,
} from 'src/app/core/ngs/table-type-filters';
import { ExportSequencesService } from './export-sequences.service';
import { FeatureSwitchService } from '../../../../features/feature-switch/feature-switch.service';
import { filter, withLatestFrom } from 'rxjs/operators';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SequenceWrapper } from '@geneious/sequence-viewer';
import { DocumentTable } from '../../../../../nucleus/services/documentService/types';
import { CardComponent } from '../../../../shared/card/card.component';
import { AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../../shared/form-errors/form-errors.component';
import { PipelineOutputNameControlComponent } from '../../../pipeline/pipeline-output/pipeline-output-name-control/pipeline-output-name-control.component';

/**
 * JobDialogContent for exporting sequences from the Sequences Table of an
 * Annotated Result. Submits an export job with an exportFormat of "ngsSequences".
 */
@Component({
  selector: 'bx-export-ngs-sequences',
  templateUrl: './export-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ExportSequencesService],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    PipelineOutputNameControlComponent,
    AsyncPipe,
  ],
})
export class ExportSequencesComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  readonly title = 'Export/Extract Sequences';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly sequenceOutputTypes = sequencesOutputTypeOptions.filter(
    (option) => option.value !== 'Newick',
  );
  extractRegionOptions: { label: string; value: ExtractRegionsValue }[] = [
    { label: 'Entire Sequence (Not trimmed)', value: 'ENTIRE_SEQUENCE' },
  ];
  extractSequencesOptions: {
    label: string;
    value: ClusterTableRowExtractionMethod | 'currentAlignedRegions';
  }[] = [];
  readonly form = new BxFormGroup({
    sequenceOptions: new BxFormGroup({
      extractSequences: new BxFormControl<
        ClusterTableRowExtractionMethod | 'currentAlignedRegions'
      >(null, (ctrl) => {
        if (
          ctrl.value === 'allAssociatedSequences' &&
          this.dialogData.otherVariables.extractionData?.isFromCombinedSequencesAlignment &&
          !this.dialogData.otherVariables.extractionData?.isAlignmentContainsCombinedSequenceIDs
        ) {
          return {
            extractSequences:
              'Export of the Original Sequences is not supported on alignments created before August 2024. Please re-run the alignment or export the Current Aligned Regions.',
          };
        }
        return null;
      }),
      extractRegions: new BxFormControl(this.extractRegionOptions[0].value),
      translate: new BxFormControl(false),
    }),
    outputOptions: new BxFormGroup({
      resultName: JobDialogContent.getResultNameControl(),
      sequencesOutputType: new BxFormControl(this.sequenceOutputTypes[0].value),
      autoDownload: new BxFormControl(true),
      exportMetadataAsTable: new BxFormControl(false),
      tableOutputType: new BxFormControl<TableOutputType>('xlsx'),
      saveAsNewDocument: new BxFormControl(false),
      outputFolderName: JobDialogContent.getResultNameControl(),
    }),
  });
  readonly resultNameControl = this.form.controls.outputOptions.controls.resultName;
  readonly outputFolderNameControl = this.form.controls.outputOptions.controls.outputFolderName;
  readonly extractSequencesControl = this.form.controls.sequenceOptions.controls.extractSequences;
  readonly extractRegionsControl = this.form.controls.sequenceOptions.controls.extractRegions;
  readonly exportMessage: string;
  readonly extractOptionsLabel: string;
  readonly isAlignmentSequencesExport: boolean;
  isExtractOptionsHidden: boolean;
  saveAsNewDocumentEnabled$: Observable<boolean>;
  outputFolderNameTooltip$: Observable<string>;
  sequenceEditingEnabled$: Observable<boolean>;
  sequenceEditingWarning$: Observable<boolean>;
  trimSequencesDisabled$: Observable<boolean>;
  translateSequencesDisabled$: Observable<boolean>;
  exportMetadataDisabled$: Observable<boolean>;
  isClusterTableSelected: boolean;
  isComparisonTable: boolean;
  private formDefaults: unknown;
  extractSequenceType$: Observable<string>;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<ExportSequencesDialogData>,
    private readonly jobResultDownloaderService: JobResultDownloaderService,
    private exportNGSSequencesService: ExportSequencesService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    const pipeline = dialogData.otherVariables.exportPipeline;
    super('export', pipeline);

    this.isAlignmentSequencesExport =
      dialogData.otherVariables.exportPipeline === PipelineFormID.EXPORT_ALIGNMENT_SEQUENCES;
    if (this.isAlignmentSequencesExport) {
      this.extractOptionsLabel = 'Extract selection as';
      const noOfSequencesSelected = dialogData.otherVariables.sequenceViewerSelection.length;
      this.exportMessage = `Exporting ${noOfSequencesSelected} selected sequences.`;
    } else {
      this.extractOptionsLabel = 'Extract from cluster';
      this.exportMessage = `Exporting ${dialogData.selected.noOfRowsSelected} row${
        dialogData.selected.noOfRowsSelected === 1 ? '' : 's'
      } from the ${dialogData.otherVariables.extractionData.table.displayName} table.`;
    }
  }

  ngOnInit(): void {
    this.formDefaults = this.form.getRawValue();

    if (this.dialogData.otherVariables.isReadOnlyFolder) {
      this.form.controls.outputOptions.controls.saveAsNewDocument.disable();
    }

    if (this.isAlignmentSequencesExport) {
      this.extractSequencesOptions.push({
        label: `Current Aligned Region${this.isClusterTableSelected ? '' : 's'}`,
        value: 'currentAlignedRegions',
      });
    }

    if (this.dialogData.otherVariables.extractionData) {
      if (this.dialogData.otherVariables.extractionData.isGenericSequenceChainDocument) {
        this.extractRegionOptions = [
          ...this.extractRegionOptions,
          { label: 'Template Region', value: 'Template Region' },
        ];
      } else {
        this.extractRegionOptions = [
          ...this.extractRegionOptions,
          { label: 'VDJ or VJ Region', value: 'VDJ_VJ' },
          { label: 'VDJC or VJC Region', value: 'VDJC_VJC' },
        ];
        if (this.dialogData.otherVariables.extractionData?.hasSCFV) {
          this.extractRegionOptions.push({ label: 'ScFv Region', value: 'SCFV' });
        } else {
          this.extractRegionOptions.push({
            label: 'Variable regions including linker (Multichain Region)',
            value: 'MULTI_CHAIN_REGION',
          });
        }
      }
      /*
       * Initialize template-bound variables
       */
      const tableToExtract = this.dialogData.otherVariables.extractionData.table;

      this.isComparisonTable = isComparisonTable(tableToExtract);

      this.isClusterTableSelected =
        isClusterTable(tableToExtract) || isComparisonClusterTable(tableToExtract);

      this.isExtractOptionsHidden =
        !this.isClusterTableSelected && !this.isAlignmentSequencesExport;

      // Indicate whether it's allowed to export sequences from the parent annotator result.
      // This will be removed when we support those cases.
      const isAlignmentExportSequenceFromParentAllowed =
        isExactClusterTable(tableToExtract) || isAllSequencesTable(tableToExtract);

      if (
        !this.isAlignmentSequencesExport ||
        (this.isAlignmentSequencesExport && isAlignmentExportSequenceFromParentAllowed)
      ) {
        this.extractSequencesOptions.push({
          label: 'Original Sequences',
          value: 'allAssociatedSequences',
        });
      }

      if (
        !this.isAlignmentSequencesExport ||
        (this.isAlignmentSequencesExport && isAlignmentExportSequenceFromParentAllowed)
      ) {
        //Representative Sequence (by Liability Score) is not applicable to comparison tables
        if (
          !isComparisonTableThatSupportsSequenceViewer(tableToExtract) &&
          this.isClusterTableSelected
        ) {
          this.extractSequencesOptions.push({
            label: 'Representative Sequence (by Liability Score)',
            value: 'highestLiabilityScore',
          });
        }
      }
    }

    this.extractSequencesControl.valueChanges
      .pipe(
        filter((value) => value === 'currentAlignedRegions'),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        // extractRegionsControl value(Trim sequences to) not applicable for this case and disabled in UI for this case.
        // However, it is better to  reset the value of the disabled control otherwise it could confuse the user
        this.extractRegionsControl.setValue(this.extractRegionOptions[0].value);
      });

    this.trimSequencesDisabled$ = this.extractSequencesControl.valueChanges.pipe(
      startWith(this.extractSequencesControl.value),
      map((value) => value === 'currentAlignedRegions'),
    );

    this.translateSequencesDisabled$ = this.extractSequencesControl.valueChanges.pipe(
      startWith(this.extractSequencesControl.value),
      map((value) => value === 'currentAlignedRegions'),
    );

    this.exportMetadataDisabled$ = this.extractSequencesControl.valueChanges.pipe(
      startWith(this.extractSequencesControl.value),
      map((value) => value === 'currentAlignedRegions'),
    );

    const extractSequencesOptionValues = this.extractSequencesOptions.map((option) => option.value);
    restrictControlValue(this.extractSequencesControl, extractSequencesOptionValues, {
      takeUntil: this.ngUnsubscribe,
      defaultValue: extractSequencesOptionValues[0],
    });

    this.saveAsNewDocumentEnabled$ = currentValueAndChanges(
      this.form.get('outputOptions.saveAsNewDocument'),
    ).pipe(shareReplay({ refCount: true, bufferSize: 1 }), takeUntil(this.ngUnsubscribe));

    this.sequenceEditingEnabled$ = this.featureSwitchService.isEnabledOnce('sequenceEditing');

    this.sequenceEditingWarning$ = this.saveAsNewDocumentEnabled$.pipe(
      withLatestFrom(this.sequenceEditingEnabled$),
      map(
        ([isSequenceEditingEnabled, saveAsNewDocument]) =>
          isSequenceEditingEnabled &&
          saveAsNewDocument &&
          this.dialogData.otherVariables.estimatedNumberOfSequencesMoreThan1000,
      ),
    );

    this.outputFolderNameTooltip$ = this.saveAsNewDocumentEnabled$.pipe(
      map((enabled) =>
        enabled
          ? '(Optional) Save document in a new subfolder with the specified name'
          : 'Only applicable when extracting to a new document',
      ),
    );

    /*
     * Initialize side effects that manipulate the form
     */
    // Enable output folder name controls when saveAsNewDocument is checked
    this.saveAsNewDocumentEnabled$.subscribe((saveAsNewDocument) => {
      if (saveAsNewDocument) {
        this.outputFolderNameControl.enable();
      } else {
        this.outputFolderNameControl.disable();
      }
    });

    const extractRegionsOption: AbstractControl<ExtractRegionsValue> = this.form.get(
      'sequenceOptions.extractRegions',
    );
    if (!this.extractRegionOptions.some((option) => option.value === extractRegionsOption.value)) {
      extractRegionsOption.setValue(this.extractRegionOptions[0].value);
    }
  }

  run() {
    const { documentID } = this.dialogData.otherVariables;

    // Use getRawValue because it includes the value of disabled controls
    const formValue = this.form.getRawValue() as FormValues;

    const options$: Observable<NGSSequencesExportOptions | DocumentSequencesExportOptions> =
      this.extractSequencesControl.value === 'currentAlignedRegions'
        ? this.getSequencesExportOptions(formValue)
        : this.getNGSSequencesExportOptions(formValue);

    return options$.pipe(
      map((options) => {
        const parameters: ExportJobParameters = {
          options,
          selection: {
            folderId: this.dialogData.folderID,
            ids: [documentID],
            selectAll: false,
          },
        };
        if (options.saveAsNewDocument) {
          parameters.output = {
            outputFolderName: formValue.outputOptions.outputFolderName,
          };
        }
        return {
          pipeline: { name: 'export', version: VersionEnum.Latest },
          parameters,
        };
      }),
    );
  }

  afterJobRun(newJobResponse: NewJobResponse) {
    if (this.form.getRawValue().outputOptions.autoDownload) {
      this.jobResultDownloaderService.automaticallyDownloadJobResultFiles(
        newJobResponse.data.jobID,
        'EXPORTED_FILE',
      );
    }
  }

  override getFormDefaults(): any {
    //need to set to form defaults to some valid options in case of the values coming from the form state are not valid for the current export
    if (
      !this.extractSequencesOptions.some(
        (option) => option.value === (this.formDefaults as any).sequenceOptions.extractSequences,
      )
    ) {
      (this.formDefaults as any).sequenceOptions.extractSequences =
        this.extractSequencesOptions[0].value;
    }
    if (
      !this.extractRegionOptions.some(
        (option) => option.value === (this.formDefaults as any).sequenceOptions.extractRegions,
      )
    ) {
      (this.formDefaults as any).sequenceOptions.extractRegions =
        this.extractRegionOptions[0].value;
    }
    return this.formDefaults;
  }

  private getRegionsForOptionValue(value: ExtractRegionsValue): Region[] {
    switch (value) {
      case 'SCFV':
        return [{ name: 'scFv Region' }];
      case 'MULTI_CHAIN_REGION':
        return [{ name: 'Multi-Chain Region' }];
      case 'VDJ_VJ':
        return [{ name: 'VDJ-REGION' }, { name: 'VJ-REGION' }];
      case 'VDJC_VJC':
        return [{ name: 'VDJC-REGION' }, { name: 'VJC-REGION' }];
      case 'Template Region':
        return [{ name: 'Template Region' }];
      case 'ENTIRE_SEQUENCE':
      default:
        return [];
    }
  }

  private getSequencesExportOptions(
    formValue: FormValues,
  ): Observable<DocumentSequencesExportOptions> {
    const { sequenceViewerSelection } = this.dialogData.otherVariables;
    const selection = this.isAlignmentSequencesExport
      ? sequenceViewerSelection.map(
          (sequence) => `${sequence.name}+${sequence.sequence.replace(/\s/g, '-')}`,
        )
      : sequenceViewerSelection.map((sequence) => sequence.originalIndex.toString());
    const options: DocumentSequencesExportOptions = {
      exportFormat: 'sequences',
      selection: {
        ids: selection,
        selectAll: false,
      },
      sequencesOutputType: formValue.outputOptions.sequencesOutputType,
      saveAsNewDocument: formValue.outputOptions.saveAsNewDocument,
    };
    if (formValue.outputOptions.resultName?.trim()) {
      options.resultName = formValue.outputOptions.resultName;
    }
    return of(options);
  }

  private getNGSSequencesExportOptions(
    formValue: FormValues,
  ): Observable<NGSSequencesExportOptions> {
    const { table, tableQuery, selection } = this.dialogData.otherVariables.extractionData;
    const options: NGSSequencesExportOptions = {
      exportFormat: 'ngsSequences',
      tableName: table.name,
      tableQuery,
      selection,
      sequencesOutputType: formValue.outputOptions.sequencesOutputType,
      saveAsNewDocument: formValue.outputOptions.saveAsNewDocument,
      regions: this.getRegionsForOptionValue(formValue.sequenceOptions.extractRegions),
      translate: formValue.sequenceOptions.translate,
    };
    if (formValue.outputOptions.resultName?.trim()) {
      options.resultName = formValue.outputOptions.resultName;
    }
    if (this.isClusterTableSelected) {
      options.clusterTableOptions = {
        rowExtractionMethod: formValue.sequenceOptions.extractSequences,
      };
    }

    return of(options).pipe(
      switchMap((options) => {
        if (formValue.outputOptions.exportMetadataAsTable && !this.isComparisonTable) {
          return this.exportNGSSequencesService
            .getColumnsStateFromAllSequencesTable(
              this.dialogData.otherVariables.extractionData.annotatorDocumentID,
            )
            .pipe(
              map((columnsState) => ({
                ...options,
                sequenceMetadataTableOptions: {
                  tableOutputType: 'xlsx' as const,
                  columnsState: { columns: columnsState },
                },
              })),
            );
        } else {
          return of(options);
        }
      }),
    );
  }
}

type FormValues = {
  sequenceOptions: {
    extractSequences: ClusterTableRowExtractionMethod;
    extractRegions: ExtractRegionsValue;
    translate: boolean;
  };
  outputOptions: {
    resultName?: string;
    sequencesOutputType: SequencesOutputType;
    autoDownload: boolean;
    exportMetadataAsTable: boolean;
    saveAsNewDocument: boolean;
    outputFolderName?: string;
  };
};

export type ExtractRegionsValue =
  | 'ENTIRE_SEQUENCE'
  | 'VDJ_VJ'
  | 'VDJC_VJC'
  | 'SCFV'
  | 'MULTI_CHAIN_REGION'
  | 'Template Region';

export type ExportSequencesDialogData = {
  exportPipeline: PipelineFormID.EXPORT_ALIGNMENT_SEQUENCES | PipelineFormID.EXPORT_NGS_SEQUENCES;
  isReadOnlyFolder: boolean;
  estimatedNumberOfSequencesMoreThan1000: boolean;
  documentID: string;
  sequenceViewerSelection?: SequenceWrapper[];
  extractionData?: NGSExtractionData;
};

export type NGSExtractionData = {
  table: Pick<DocumentTable, 'name' | 'displayName' | 'metadata' | 'columns' | 'tableType'>;
  tableQuery: CursorDocumentQuery;
  selection: {
    ids: string[];
    selectAll: boolean;
  };
  annotatorDocumentID: string;
  hasSCFV: boolean;
  isGenericSequenceChainDocument: boolean;
  isFromCombinedSequencesAlignment: boolean;
  isAlignmentContainsCombinedSequenceIDs: boolean;
};
