import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ApiKeysService } from '../api-keys.service';
import { Observable, ReplaySubject } from 'rxjs';
import { NgForm, FormsModule } from '@angular/forms';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { ApiKeyCreatedModalComponent } from '../api-key-created-modal/api-key-created-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';

@Component({
  selector: 'bx-api-key-creator',
  templateUrl: './api-key-creator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    SpinnerButtonComponent,
    RouterLink,
    PageMessageComponent,
    AsyncPipe,
  ],
})
export class ApiKeyCreatorComponent implements OnInit, OnDestroy {
  isApiKeysEnabled$: Observable<boolean>;
  apiKeyName: string;
  processingRequest$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  apiKeyDialog?: NgbModalRef;

  constructor(
    public apiKeysService: ApiKeysService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isApiKeysEnabled$ = this.apiKeysService.isApiKeysEnabled();
  }

  ngOnDestroy() {
    this.processingRequest$.complete();
    this.apiKeyDialog?.close('logout');
  }

  submit(userForm: NgForm) {
    if (userForm.valid) {
      this.processingRequest$.next(true);
      this.apiKeysService
        .createApiKey(this.apiKeyName)
        .subscribe({
          next: (apiKey) => {
            this.apiKeyDialog = this.dialogService.showDialogV2({
              component: ApiKeyCreatedModalComponent,
              injectableData: apiKey,
              options: {
                size: 'md',
              },
            });

            this.apiKeyDialog.result.finally(() => this.router.navigate(['/settings']));
          },
          error: (err: HttpErrorResponse) => {
            const message = err.error.error.message;

            this.apiKeyDialog = this.dialogService.showAlertDialog({
              title: 'Error while creating API key',
              content: message,
            });
          },
        })
        .add(() => {
          this.processingRequest$.next(false);
        });
    }
  }
}
