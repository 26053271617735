import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { map, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import {
  getNucleusPipelineID,
  NucleusPipelineID,
  PipelineFormID,
} from '../../pipeline/pipeline-constants';
import { AlignmentComponent } from '../../pipeline-dialogs/alignment/alignment.component';
import { MotifAnnotatorComponent } from '../../pipeline-dialogs/motif-annotator/motif-annotator.component';
import { PipelineItem } from '../../dialogV2/pipelineItem.model';
import {
  SelectionStateV2,
  selectionStateV2ToSelectionState,
} from '../../../features/grid/grid.component';
import { Folder } from '../../folders/models/folder.model';
import { AnnotationAlignmentComponent } from '../../pipeline-dialogs/annotation-alignment/annotation-alignment.component';
import { RepairSequencesComponent } from '../../pipeline-dialogs/repair-sequences/repair-sequences.component';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import {
  isAlignmentSupportedComparisonTable,
  isAllSequencesTable,
  isChainCombinationsTable,
  isExactClusterTable,
  isInexactClusterTable,
} from '../table-type-filters';
import { MasterDatabaseSearchComponent } from '../../pipeline-dialogs/master-database-search/master-database-search.component';
import { MasterDatabaseAnnotatedSequencesImporterDialogComponent } from '../../master-database/master-database-annotated-sequences-importer-dialog/master-database-annotated-sequences-importer-dialog.component';
import { PipelineAssociationsService } from '../../pipeline/pipeline-associations/pipeline-associations.service';
import { ExtractSequencesDialogOptionsV3 } from 'src/nucleus/services/models/extractSequencesV3.model';
import { AddClustersComponent } from '../../pipeline-dialogs/add-clusters/add-clusters.component';
import { AntibodyAnnotatorOptionValues } from '../../pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';
import { ExportSequencesDialogData } from '../../pipeline-dialogs/export-v2/export-sequences/export-sequences.component';
import { ExportNgsSequencesForEditingComponent } from '../../pipeline-dialogs/export-v2/export-ngs-sequences-for-editing/export-ngs-sequences-for-editing.component';
import {
  AlignmentClusterOptions,
  AlignmentSequenceOptions,
} from '../../../../nucleus/services/models/alignmentOptions.model';
import { BxPipelineChooserV2Component } from '../../dialogV2/bx-pipeline-chooser-v2/bx-pipeline-chooser-v2.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-ngs-post-processing-pipelines-chooser',
  templateUrl: './ngs-post-processing-pipelines-chooser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BxPipelineChooserV2Component, AsyncPipe],
})
export class NgsPostProcessingPipelinesChooserComponent implements OnInit, OnDestroy, OnChanges {
  private static readonly MAX_SUPPORTED_NO_SEQUENCES = 200;
  private static readonly NO_ANNOTATOR_OPTIONS_MESSAGE =
    'The options used to create this document could not be identified';

  @Input() disabled: boolean;
  @Input() extractOptions: ExtractSequencesDialogOptionsV3;
  @Input() exportOptions: ExportSequencesDialogData;
  @Input() selectedTable: DocumentTable;
  @Input() selectionState: SelectionStateV2;
  @Input() datasource: any;
  @Input() documentID: string;
  @Input() folder: Folder;
  @Input() sequenceMetadataOrder: string[];
  @Input() creationPipeline: NucleusPipelineID;
  @Input() creationPipelineOptions: AntibodyAnnotatorOptionValues;
  @Input() alignmentSequenceOptions: AlignmentClusterOptions;

  postProcessingPipelines$: Observable<PipelineItem[]>;
  noPipelinesPresent$: Observable<boolean>;

  private extractOptions$ = new ReplaySubject<ExtractSequencesDialogOptionsV3>(1);
  private exportOptions$ = new ReplaySubject<ExportSequencesDialogData>(1);
  private readonly selectedTable$ = new ReplaySubject<DocumentTable>(1);
  private readonly sequenceMetadataOrder$ = new ReplaySubject<string[]>(1);
  private readonly alignmentSequenceOptions$ = new ReplaySubject<AlignmentSequenceOptions>(1);

  constructor(
    private featureSwitchService: FeatureSwitchService,
    private pipelineAssociationService: PipelineAssociationsService,
  ) {}

  ngOnChanges({
    extractOptions,
    exportOptions,
    selectedTable,
    sequenceMetadataOrder,
    alignmentSequenceOptions,
  }: SimpleChanges) {
    if (extractOptions && extractOptions.currentValue) {
      this.extractOptions$.next(extractOptions.currentValue);
    }
    if (exportOptions && exportOptions.currentValue) {
      this.exportOptions$.next(exportOptions.currentValue);
    }
    if (selectedTable && selectedTable.currentValue) {
      this.selectedTable$.next(selectedTable.currentValue);
    }
    if (sequenceMetadataOrder && sequenceMetadataOrder.currentValue) {
      this.sequenceMetadataOrder$.next(sequenceMetadataOrder.currentValue);
    }
    if (alignmentSequenceOptions && alignmentSequenceOptions.currentValue) {
      this.alignmentSequenceOptions$.next(alignmentSequenceOptions.currentValue);
    }
  }

  ngOnInit() {
    const getSequences = () => {
      if (this.datasource == null) {
        // TODO Comparison tables should have a datasource after BX-4136
        return of([]);
      }
      const state = this.selectionState;
      const resource: any = this.datasource;
      return resource.getSequences(this.documentID, state);
    };

    this.postProcessingPipelines$ = combineLatest([
      this.selectedTable$,
      this.extractOptions$,
      this.exportOptions$,
      this.sequenceMetadataOrder$,
      this.featureSwitchService.isEnabledOnce('alignAnchorByAnnotations'),
      this.featureSwitchService.isEnabledOnce('sequenceEditing'),
      this.alignmentSequenceOptions$,
    ])
      .pipe(
        map(
          ([
            selectedTable,
            extractOptions,
            exportOptions,
            sequenceMetadataOrder,
            alignAnchorByAnnotations,
            sequenceEditingEnabled,
            alignmentSequenceOptions,
          ]) => {
            const isAlignmentSupported =
              NgsPostProcessingPipelinesChooserComponent.supportsAlignment(
                selectedTable,
                this.selectionState.totalSelected,
              );
            const alignmentHelpMessage =
              !NgsPostProcessingPipelinesChooserComponent.isAlignmentSupportedTableType(
                selectedTable,
              )
                ? 'Alignment is not supported for sequences in this table. Please select sequences from a different table.'
                : null;

            const annotationAlignmentHelpMessage =
              !isAllSequencesTable(selectedTable) && !isChainCombinationsTable(selectedTable)
                ? 'Annotation alignment is not supported for sequences in this table. Please select sequences from a different table.'
                : null;

            const extractSequenceForEditingDisabled =
              this.selectionState.totalSelected === 0 ||
              exportOptions.estimatedNumberOfSequencesMoreThan1000;
            let extractSequenceForEditingHelpMessage = null;

            if (this.selectionState.totalSelected === 0) {
              extractSequenceForEditingHelpMessage = 'Extract selected sequences for editing';
            }

            if (exportOptions.estimatedNumberOfSequencesMoreThan1000) {
              extractSequenceForEditingHelpMessage =
                'Only documents with 1000 sequences or less are editable.';
            }

            const pipelines: PipelineItem[] = [
              {
                id: PipelineFormID.ALIGNMENT,
                label: 'Align...',
                component: AlignmentComponent,
                disabled: !isAlignmentSupported,
                helpMessage: alignmentHelpMessage,
                options: {
                  isResult: true,
                  selectedTable: selectedTable,
                  // Uses same function as toMotifOptions as they are the same options structure.
                  extraction: extractOptions,
                  getSequences: getSequences,
                  sequenceMetadataOrder: sequenceMetadataOrder,
                  documentTableSelection: this.selectionState,
                  alignmentSequenceOptions: alignmentSequenceOptions,
                },
              },
              {
                id: PipelineFormID.ADD_CLUSTER,
                label: 'Add Clusters (Recluster)...',
                component: AddClustersComponent,
                disabled:
                  !this.creationPipelineOptions || this.creationPipeline === 'ngs-comparisons',
                helpMessage:
                  this.creationPipeline === 'ngs-comparisons'
                    ? 'Add clusters is not available on comparison documents'
                    : NgsPostProcessingPipelinesChooserComponent.NO_ANNOTATOR_OPTIONS_MESSAGE,
                options: {
                  documentID: this.documentID,
                  creationPipeline: this.creationPipeline,
                  creationPipelineOptions: this.creationPipelineOptions,
                },
              },
            ];
            if (sequenceEditingEnabled) {
              pipelines.push({
                id: PipelineFormID.EXPORT_FOR_EDITING,
                label: 'Extract & Edit Sequences',
                disabled: extractSequenceForEditingDisabled,
                helpMessage: extractSequenceForEditingHelpMessage,
                component: ExportNgsSequencesForEditingComponent,
                selectionState: selectionStateV2ToSelectionState(this.selectionState),
                options: { ...exportOptions, exportForEditing: true },
              });
            }
            if (alignAnchorByAnnotations) {
              pipelines.push({
                id: PipelineFormID.ANNOTATION_ALIGNMENT,
                label: 'Align - Anchor by Annotations (Alpha)...',
                component: AnnotationAlignmentComponent,
                disabled: !NgsPostProcessingPipelinesChooserComponent.supportsAnnotationAlignment(
                  selectedTable,
                  this.selectionState.totalSelected,
                ),
                helpMessage: annotationAlignmentHelpMessage,
                options: {
                  isResult: true,
                  extraction: extractOptions,
                  getSequences: getSequences,
                  sequenceMetadataOrder: sequenceMetadataOrder,
                },
              });
            }
            pipelines.push({
              id: PipelineFormID.MOTIF_ANNOTATOR,
              label: 'Discover Motifs (Alpha)...',
              component: MotifAnnotatorComponent,
              disabled: !this.supportsMotifAnnotator(
                selectedTable,
                this.selectionState.totalSelected,
              ),
              options: {
                isResult: true,
                extraction: extractOptions,
                getSequences: getSequences,
              },
            });

            const repairSequences = this.getRepairSequencesPipeline(selectedTable, getSequences);
            pipelines.push(repairSequences);

            return pipelines;
          },
        ),
        switchMap((pipelines) => {
          return this.featureSwitchService.isEnabledOnce('masterDatabases').pipe(
            map((enabled) => {
              if (!enabled) {
                return pipelines;
              }

              let searchCollectionHelpMessage = null;
              let addToCollectionHelpMessage = null;
              if (this.maxSequencesSelected()) {
                searchCollectionHelpMessage = `Select less than ${NgsPostProcessingPipelinesChooserComponent.MAX_SUPPORTED_NO_SEQUENCES} sequences to search`;
              } else if (isChainCombinationsTable(this.selectedTable)) {
                searchCollectionHelpMessage =
                  'Search Collections is not currently available for the Chain Combinations table';
                addToCollectionHelpMessage =
                  'Add to Collections is not currently available for the Chain Combinations table';
              }

              pipelines.push({
                id: PipelineFormID.MASTER_DATABASE_SEARCH,
                label: 'Search Collections...',
                component: MasterDatabaseSearchComponent,
                disabled:
                  !this.sequencesSelected() ||
                  !isAllSequencesTable(this.selectedTable) ||
                  this.maxSequencesSelected(),
                helpMessage: searchCollectionHelpMessage,
                options: {
                  selection: this.selectionState,
                  extraction: this.extractOptions,
                  getSequences: getSequences,
                },
              });

              pipelines.push({
                id: PipelineFormID.MASTER_DATABASE_IMPORTER,
                label: 'Add to Collection...',
                component: MasterDatabaseAnnotatedSequencesImporterDialogComponent,
                disabled: !NgsPostProcessingPipelinesChooserComponent.supportsImportToCollection(
                  this.selectedTable,
                  this.selectionState.totalSelected,
                ),
                helpMessage: addToCollectionHelpMessage,
                options: {
                  documentTableQuery: this.extractOptions.documentTableQuery,
                  selection: this.extractOptions.selection,
                  selectedTable: this.extractOptions.documentTableName,
                  getSequences: getSequences,
                },
              });

              return pipelines;
            }),
          );
        }),
      )
      .pipe(
        switchMap((items) =>
          this.pipelineAssociationService
            .getProfilePipelineAssociations()
            .pipe(
              map((associations) =>
                items.filter((item) => associations.includes(getNucleusPipelineID(item.id))),
              ),
            ),
        ),
      );
    this.noPipelinesPresent$ = this.postProcessingPipelines$.pipe(
      map((pipelines) => !pipelines || pipelines.length === 0),
      startWith(true),
    );
  }

  ngOnDestroy() {
    this.extractOptions$.complete();
    this.selectedTable$.complete();
  }

  private getRepairSequencesPipeline(
    selectedTable: DocumentTable,
    getSequences: () => Observable<any[]>,
  ): PipelineItem {
    // Repair sequences requires the options of the pipeline that created this doc.
    const hasCreationPipelineOptions = !!this.creationPipelineOptions;
    const isPPAnnotatorResult = selectedTable.metadata?.analysisType === 'GenericAnnotator';

    // Repair sequences is only supported on ngs docs, defaults to true if creationPipeline doesn't exist, which should
    // only be the case for imported documents.
    // Note the protein and peptide annotators have a creation pipeline of 'ngs' as that's what they run. But we want to
    // disable repair sequences for them too.
    const isNgsResult =
      !isPPAnnotatorResult && (this.creationPipeline ? this.creationPipeline === 'ngs' : true);

    let helpMessage;
    if (!hasCreationPipelineOptions) {
      helpMessage = NgsPostProcessingPipelinesChooserComponent.NO_ANNOTATOR_OPTIONS_MESSAGE;
    } else if (!isNgsResult) {
      helpMessage = 'This operation is only supported on antibody annotator results';
    }

    return {
      id: PipelineFormID.REPAIR_SEQUENCES,
      label: 'Repair Sequences (Alpha)...',
      component: RepairSequencesComponent,
      disabled:
        !this.sequencesSelected() ||
        (!isAllSequencesTable(selectedTable) && !isChainCombinationsTable(selectedTable)) ||
        !hasCreationPipelineOptions ||
        !isNgsResult,
      helpMessage,
      options: {
        isResult: true,
        extraction: this.extractOptions,
        creationPipelineOptions: this.creationPipelineOptions,
        getSequences: getSequences,
      },
    };
  }

  /**
   * This method determines whether the alignment operation supports aligning rows from the current
   * selected result table or not.
   *
   * TODO Port multi table selection signatures over from the viewers and use here instead of this
   * hard coded logic.
   *
   * @param {DocumentTable} table
   * @param {number} totalSelected Total rows selected in table
   * @returns {boolean} True if alignment is supported.
   */
  static supportsAlignment(table: DocumentTable, totalSelected: number): boolean {
    // There is no point in aligning less than two sequences.
    // Most table rows represent a single sequence only, but similarity cluster table rows can represent more than one sequence.
    const isTableContainsInexactCluster = isInexactClusterTable(table);
    const minimumRows = isTableContainsInexactCluster ? 1 : 2;
    // Sanity check - aligning more than 10000 sequences would be very slow.
    const maximumRows = 10000;
    const isValidTotal = totalSelected >= minimumRows && totalSelected <= maximumRows;
    const isValidTableType = this.isAlignmentSupportedTableType(table);

    return isValidTotal && isValidTableType;
  }

  static isAlignmentSupportedTableType(table: DocumentTable): boolean {
    return (
      isAllSequencesTable(table) ||
      isChainCombinationsTable(table) ||
      isInexactClusterTable(table) ||
      isAlignmentSupportedComparisonTable(table) ||
      isExactClusterTable(table)
    );
  }

  /**
   * This method determines whether the annotation alignment operation supports aligning rows from
   * the current selected result table or not.
   *
   * We only support Aligning with Annotations from the All sequences table & Chain combination
   * table at the moment, for the simple reason that our extractions from the cluster tables do not
   * have annotations yet, thus kind of defeating the point.
   *
   * TODO Port multi table selection signatures over from the viewers and use here instead of this
   * hard coded logic.
   *
   * @param {DocumentTable} table
   * @param {number} totalSelected Total rows selected in table
   * @returns {boolean} True if annotation alignment is supported.
   */
  static supportsAnnotationAlignment(table: DocumentTable, totalSelected: number): boolean {
    // There is no point in aligning less than two sequences. The All Sequence Table rows usually represent a single sequence only.
    const minimumRows = 2;
    // Sanity check - aligning more than 1000 sequences would be very slow.
    const maximumRows = 1000;
    const isValidTotal = totalSelected >= minimumRows && totalSelected <= maximumRows;
    return (isValidTotal && isAllSequencesTable(table)) || isChainCombinationsTable(table);
  }

  /**
   * This method determines whether the Collection import operation supports importing from the
   * current selected result table and its sequences.
   *
   * Only a maximum of 1000 sequences is supported for Collection importing to avoid overloading
   * the system. Users can always perform subsequent import to the same Collection if they require
   * more to add.
   */
  static supportsImportToCollection(table: DocumentTable, totalSelected: number): boolean {
    const isValidTotal = totalSelected >= 1 && totalSelected <= 1000;
    return isValidTotal && isAllSequencesTable(table);
  }

  private sequencesSelected(): boolean {
    const selectionState = this.selectionState;
    return selectionState.totalSelected > 0 || selectionState.selectAll;
  }

  private maxSequencesSelected(): boolean {
    return (
      this.selectionState.totalSelected >
      NgsPostProcessingPipelinesChooserComponent.MAX_SUPPORTED_NO_SEQUENCES
    );
  }

  private supportsMotifAnnotator(table: DocumentTable, totalSelected: number): boolean {
    return (
      totalSelected <= 5000 &&
      this.sequencesSelected() &&
      (isAllSequencesTable(table) || isChainCombinationsTable(table))
    );
  }
}
