@if (
  {
    inProgress: isAnalysisInProgress$ | async,
    complete: isAnalysisComplete$ | async,
  };
  as state
) {
  @if (!state.inProgress && !state.complete) {
    <form
      [formGroup]="form"
      (ngSubmit)="analyseSubmission$.next()"
      class="border rounded d-flex flex-column"
    >
      @if ((validationWarnings$ | async)?.length > 0) {
        <div class="pt-4 d-flex ps-0 mat-error">
          <ul>
            @for (warning of validationWarnings$ | async; track warning) {
              <li data-spec="warnings">
                {{ warning }}
              </li>
            }
          </ul>
        </div>
      }
      <textarea
        class="font-monospace form-control p-4 border border-start-0 border-end-0 border-top-0"
        formControlName="sequenceInputText"
        placeholder="Enter your protein or nucleotide sequence(s)"
        rows="3"
        spellcheck="false"
        data-spec="sequence-input"
      ></textarea>
      <div class="px-4 pt-4 d-flex align-items-center">
        <span>Or upload sequence(s) from file: </span>
        <label class="form-label btn btn-light btn-file mx-2 mb-0">
          Browse
          <input
            type="file"
            (change)="handleSequenceUpload($event)"
            style="display: none"
            id="sequence-file"
            #fileInput
          />
        </label>
        @if (form.get('sequenceInputFile').value; as file) {
          <span>{{ file.name }}</span>
          <bx-clear-file-button
            class="ms-1"
            (click)="handleClearFile($event)"
          ></bx-clear-file-button>
        }
      </div>
      <div class="px-4 pt-4">
        <p class="text-muted">
          <span>
            Text can be in form of raw sequence, or FASTA format. Supported upload file types
            include ab1, FASTA, genbank, or geneious. See our
            <a
              href="https://help.geneiousbiologics.com/hc/en-us/articles/7221467293460"
              target="_blank"
              >help article</a
            >
            for more information.
          </span>
          @if (maxInputSequenceCount$ | async; as maxInputSequenceCount) {
            <span>Up to {{ maxInputSequenceCount }} sequences can be analyzed at a time.</span>
          }
        </p>
      </div>
      <div class="p-4 row">
        <div class="d-flex flex-column col-8">
          <div class="d-flex justify-content-between align-items-start row mb-2">
            <label for="referenceDatabase" class="form-label col-5">Reference Sequences:</label>
            <div class="col-7 p-0">
              <bx-multi-select
                id="referenceDatabase"
                formControlName="referenceDatabase"
                [selectOptions]="referenceDatabases$ | async"
                emptyMessage="No reference databases are available"
                entityName="reference database"
                [showMultiple]="multipleRefDbsEnabled$ | async"
                [showFormErrors]="true"
                [canMarkPristineInvalid]="false"
              >
              </bx-multi-select>
              <bx-form-errors [control]="form.get('referenceDatabase')"></bx-form-errors>
            </div>
          </div>
          <div class="mb-3 d-flex justify-content-between align-items-center row">
            <label for="annotationScheme" class="col-5 col-form-label">Annotation Scheme:</label>
            <select
              id="annotationScheme"
              formControlName="annotationScheme"
              class="form-select col"
            >
              @for (scheme of availableAnnotationSchemes; track scheme) {
                <option [value]="scheme">
                  {{ scheme }}
                </option>
              }
            </select>
          </div>
        </div>
        <div class="col-auto">
          <div class="h-100 border-end"></div>
        </div>
        <div class="col-auto d-flex justify-content-end align-items-end">
          <button
            class="btn btn-primary px-5 py-1"
            type="submit"
            [disabled]="!form.valid || (isAnalyseDisabled$ | async)"
          >
            Analyze {{ (validationWarnings$ | async)?.length > 0 ? ' Anyway' : '' }}
          </button>
        </div>
      </div>
      @if ((jobErrors$ | async)?.length > 0) {
        <div class="pt-4 d-flex ps-0 mat-error">
          <ul>
            @for (error of jobErrors$ | async; track error) {
              <li>
                {{ error }}
              </li>
            }
          </ul>
        </div>
      }
    </form>
  }
  @if (state.inProgress) {
    <div class="d-flex border">
      <div class="p-4">
        <h5 class="fw-bold" data-spec="analyse-title-loading">Analyzing data...</h5>
        <div class="progress my-3">
          @if (jobProgress$ | async; as progress) {
            <div class="progress-bar" role="progressbar" [style]="{ width: progress + '%' }">
              {{ progress }}%
            </div>
          }
        </div>
        <p>
          You can navigate away while the sequence is being processed. When completed, the job will
          be available on the <a routerLink="/jobs">job page</a>.
        </p>
      </div>
    </div>
  }
  @if (result$ | async; as result) {
    @if (result.show) {
      <div class="border p-4">
        <h5 class="d-flex m-0 justify-content-between">
          <span class="fw-bold" data-spec="analyse-title-complete">Analysis complete!</span>
          <div class="d-flex justify-content-end gap">
            <button class="btn btn-secondary me-2" (click)="reset()" [disabled]="state.inProgress">
              New Analysis
            </button>
            <a
              class="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              routerLink="/view"
              [queryParams]="result.params"
            >
              <mat-icon class="bx-btn-icon">open_in_new</mat-icon>
              View Results
            </a>
          </div>
        </h5>
      </div>
    }
  }
}
