import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { SelectGroup, SelectOption } from '../../../core/models/ui/select-option.model';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ChipsService } from '../../chips/chips.service';
import {
  heavyGenes,
  lightGenes,
  nameBasedLinkers,
  Regions,
  UnprefixedRegions,
} from '../../../core/antibodyAnnotatorRegions.service';
import { CombinationRegionChip } from '../regions-selector.component';
import { combinationNameValidator } from './combination-name.validator';
import { RegionsSelectorService } from '../regions-selector.service';
import {
  NgbPopover,
  NgbNav,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { sortAntibodyRegionByName } from '../../sort.util';
import { capitalize, replaceAll } from '../../string-util';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { MultiSelectComponent } from '../../select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';

// Maximum combination regions that can be selectable.
const MAX_COMBINATION_REGIONS_SELECTABLE = 10;

/**
 * The chips.component.ts will render this component in the popover whenever the add button is clicked.
 */
@Component({
  selector: 'bx-regions-selector-add-form',
  templateUrl: './regions-selector-add-form.component.html',
  styleUrls: ['./regions-selector-add-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    NgbNavOutlet,
    AsyncPipe,
  ],
})
export class RegionsSelectorAddFormComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-block bx-popover-fs-base';

  readonly selectedSingleRegionsControl = new FormControl<Regions[]>([], Validators.required);
  readonly singleRegionsForm = new FormGroup({
    regions: this.selectedSingleRegionsControl,
  });

  readonly regionsNotAvailableForCombinationCluster: Regions[] = ['Template Matches'];

  readonly combinationNameControl = new FormControl<string>(undefined, Validators.required);
  readonly clusterByControl = new FormControl<'nucleotides' | 'aminoAcids'>(
    'aminoAcids',
    Validators.required,
  );
  readonly clusterMethodControl = new FormControl<ClusteringMethod>('exact', Validators.required);
  readonly clusterPercentageControl = new FormControl(95, [
    Validators.required,
    Validators.min(1),
    Validators.max(100),
  ]);
  readonly noOfMismatchesControl = new FormControl(1, [
    Validators.required,
    Validators.pattern('^[0-5]*$'),
  ]);
  readonly clusterMismatchControl = new FormControl<ClusteringMismatchRegion>(
    'all',
    Validators.required,
  );
  readonly selectedCombinationRegionsControl = new FormControl<Regions[]>(
    [],
    [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(MAX_COMBINATION_REGIONS_SELECTABLE),
    ],
  );
  readonly combinationRegionsForm = new FormGroup({
    name: this.combinationNameControl,
    regions: this.selectedCombinationRegionsControl,
    clusterBy: this.clusterByControl,
    clusterMethod: this.clusterMethodControl,
    clusterPercentage: this.clusterPercentageControl,
    clusterMismatch: this.clusterMismatchControl,
    noOfMismatches: this.noOfMismatchesControl,
  });

  currentActiveNavID$ = new BehaviorSubject<'single-regions' | 'combination-regions'>(
    'single-regions',
  );
  availableRegionOptions$: Observable<SelectGroup[]>;
  regionOptions$: Observable<SelectGroup[]>;
  selectedSingleRegions$: Observable<Regions[]>;
  selectedCombinationRegions$: Observable<Regions[]>;

  allGenesSelected$: Observable<boolean>;

  addEvent$ = new Subject<void>();
  combineEvent$ = new Subject<void>();

  addRegionsButtonLabel$: Observable<string>;
  combineRegionsButtonLabel$: Observable<string>;
  singleRegionButtonDisabled$: Observable<boolean>;
  combineRegionsButtonDisabled$: Observable<boolean>;

  availableClusterMethodOptions$: Observable<SelectOption<ClusteringMethod>[]>;
  showClusteringPercentageConfig$: Observable<boolean>;
  showNoOfMismatches$: Observable<boolean>;
  availableClusterMismatchOptions$: Observable<SelectOption<ClusteringMismatchRegion>[]>;
  readonlyClusterName$: Observable<boolean>;

  selectedClusteringMethod$: Observable<ClusteringMethod>;
  selectedClusteringPercentage$: Observable<number>;
  selectedNoOfMismatches$: Observable<number>;
  selectedMismatchRegion$: Observable<ClusteringMismatchRegion>;

  selectedRegionsString$: Observable<string>;
  clusteringInfoString$: Observable<string | null>;

  clusterByNucleotides$: Observable<boolean>;
  hasCustomName$ = new Subject<boolean>();

  private subscriptions = new Subscription();

  constructor(
    @Inject(ChipsService) private chipsService: ChipsService,
    @Inject(NgbPopover) private ngbPopover: NgbPopover,
    private regionsSelectorService: RegionsSelectorService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    this.regionOptions$ = this.regionsSelectorService.regionOptions$.pipe(
      map((options: { name: string; option: SelectOption }[]) =>
        [...new Set(options.map((opt) => opt.name))].reduce(
          (acc, val) => [
            ...acc,
            new SelectGroup(
              options.filter((opt) => opt.name === val).map((opt) => opt.option),
              val,
            ),
          ],
          [] as SelectGroup[],
        ),
      ),
    );
    this.combinationNameControl.setAsyncValidators(
      combinationNameValidator(this.chipsService, this.hasCustomName$),
    );

    this.selectedSingleRegions$ = this.selectedSingleRegionsControl.valueChanges;
    this.selectedCombinationRegions$ = this.selectedCombinationRegionsControl.valueChanges.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.availableRegionOptions$ = combineLatest([
      this.regionOptions$,
      this.chipsService.chips$,
      this.chipsService.readonlyChips$,
      this.currentActiveNavID$,
    ]).pipe(
      map(([optionGroups, newChips, readonlyChips, currentClusterMode]) => {
        const displayedChips = [...newChips, ...readonlyChips];
        if (currentClusterMode === 'combination-regions') {
          return optionGroups.map(
            (optionGroup) =>
              new SelectGroup(
                optionGroup.options.filter(
                  (option) =>
                    !this.regionsNotAvailableForCombinationCluster.includes(
                      option.value as Regions,
                    ),
                ),
                optionGroup.label,
              ),
          );
        }
        return optionGroups
          .map(
            (optionGroup) =>
              new SelectGroup(
                optionGroup.options.filter(
                  (option) => !displayedChips.find((chip) => chip.label === option.value),
                ),
                optionGroup.label,
              ),
          )
          .filter((group) => group.options.length > 0);
      }),
    );

    this.clusterByNucleotides$ = this.clusterByControl.valueChanges.pipe(
      startWith(this.clusterByControl.value),
      map((value) => value === 'nucleotides'),
    );

    this.availableClusterMethodOptions$ = this.clusterByNucleotides$.pipe(
      map((clusterByNucleotides) => {
        return [
          new SelectOption<ClusteringMethod>('Exact', 'exact'),
          new SelectOption<ClusteringMethod>('Identity (by percent)', 'identity'),
          new SelectOption<ClusteringMethod>('Identity (by count)', 'mismatches'),
        ].concat(
          clusterByNucleotides
            ? []
            : [new SelectOption<ClusteringMethod>('Similarity (BLOSUM)', 'similarity')],
        );
      }),
    );
    this.showClusteringPercentageConfig$ = this.clusterMethodControl.valueChanges.pipe(
      map((clusterMethod) => clusterMethod === 'identity' || clusterMethod === 'similarity'),
    );
    this.showNoOfMismatches$ = this.clusterMethodControl.valueChanges.pipe(
      startWith(false),
      map((clusterMethod) => clusterMethod === 'mismatches'),
    );
    this.availableClusterMismatchOptions$ = this.selectedCombinationRegions$.pipe(
      startWith([] as Regions[]),
      map((regions) => {
        const genes: UnprefixedRegions[] = [...lightGenes, ...heavyGenes];
        return regions.filter((region) => !genes.some((gene) => region.includes(gene)));
      }),
      map((regions) => {
        const isSingleRegionSelected = regions.length === 1;
        const allRegionsOption = new SelectOption<ClusteringMismatchRegion>('All Regions', 'all');

        if (isSingleRegionSelected) {
          return [new SelectOption(regions[0], regions[0])];
        }
        return [allRegionsOption, ...regions.map((region) => new SelectOption(region, region))];
      }),
    );
    this.readonlyClusterName$ = combineLatest([
      this.featureSwitchService.isEnabledOnce('customClusterName'),
      this.selectedCombinationRegions$.pipe(startWith([])),
    ]).pipe(map(([customNameEnabled, regions]) => !customNameEnabled || regions.length === 1));

    this.allGenesSelected$ = this.selectedCombinationRegions$.pipe(
      startWith([]),
      map((regions) => {
        const genes = [...lightGenes, ...heavyGenes, ...nameBasedLinkers];
        return regions.every((region) => genes.some((gene) => region.includes(gene)));
      }),
      tap((allGenesSelected) => {
        if (allGenesSelected) {
          this.clusterMethodControl.setValue('exact');
        }
      }),
    );

    this.subscriptions.add(
      this.availableClusterMethodOptions$.subscribe((methods) => {
        if (methods && methods.length > 0) {
          this.clusterMethodControl.setValue(methods[0].value);
        }
      }),
    );

    this.subscriptions.add(
      this.availableClusterMismatchOptions$.subscribe((clusters) => {
        if (clusters && clusters.length > 0) {
          this.clusterMismatchControl.setValue(clusters[0].value);
        }
      }),
    );

    this.selectedClusteringMethod$ = this.clusterMethodControl.valueChanges.pipe(
      startWith(this.clusterMethodControl.value),
    );
    this.selectedClusteringPercentage$ = this.clusterPercentageControl.valueChanges.pipe(
      startWith(this.clusterPercentageControl.value),
    );
    this.selectedNoOfMismatches$ = this.noOfMismatchesControl.valueChanges.pipe(
      startWith(this.noOfMismatchesControl.value),
    );
    this.selectedMismatchRegion$ = this.clusterMismatchControl.valueChanges.pipe(
      startWith(this.clusterMismatchControl.value),
    );

    this.selectedRegionsString$ = this.selectedCombinationRegions$.pipe(
      map((regions) => regions.join(', ')),
    );

    this.clusteringInfoString$ = combineLatest([
      this.selectedCombinationRegions$,
      this.selectedClusteringMethod$,
      this.selectedClusteringPercentage$,
      this.selectedMismatchRegion$,
      this.selectedNoOfMismatches$,
    ]).pipe(
      map(
        ([regions, clusteringMethod, clusteringPercentage, mismatchRegion, noOfMismatches]: [
          Regions[],
          ClusteringMethod,
          number,
          ClusteringMismatchRegion,
          number,
        ]) => {
          if (regions.length === 0 || clusteringMethod === 'exact') {
            return null;
          }
          const displayMismatchRegion = mismatchRegion === 'all' ? 'whole cluster' : mismatchRegion;
          const percentageOrCount =
            clusteringMethod === 'mismatches'
              ? `up to ${noOfMismatches} Mismatch${noOfMismatches === 1 ? '' : 'es'}`
              : `${clusteringPercentage ?? 0}% ${capitalize(clusteringMethod)}`;
          return `${displayMismatchRegion} with ${percentageOrCount} `;
        },
      ),
    );

    this.subscriptions.add(
      this.combinationNameControl.valueChanges.pipe(distinctUntilChanged()).subscribe((name) => {
        this.hasCustomName$.next(name !== '');
      }),
    );
    this.availableRegionOptions$
      .pipe(
        take(1),
        filter((regions) => regions.length === 0),
      )
      .subscribe(() => {
        this.currentActiveNavID$.next('combination-regions');
      });
  }

  ngOnInit(): void {
    this.handleButtonLabelState();
    this.handleButtonDisabledState();
    this.handleSubmitEvents();
    this.setCombinationRegionName();
  }

  ngOnDestroy() {
    this.addEvent$.complete();
    this.combineEvent$.complete();
    this.hasCustomName$.complete();
    this.subscriptions.unsubscribe();
  }

  private handleButtonLabelState() {
    this.addRegionsButtonLabel$ = this.selectedSingleRegions$.pipe(
      startWith([]),
      map((selectedRegions) => {
        if (selectedRegions.length === 0) {
          return 'Select Region/s';
        }
        return `Add ${selectedRegions.length} Region` + (selectedRegions.length > 1 ? 's' : '');
      }),
    );

    this.combineRegionsButtonLabel$ = this.selectedCombinationRegions$.pipe(
      startWith([]),
      map((selectedRegions) => {
        if (selectedRegions.length < 1) {
          return 'Select at least 1 Region';
        } else if (selectedRegions.length > MAX_COMBINATION_REGIONS_SELECTABLE) {
          return `Select ${MAX_COMBINATION_REGIONS_SELECTABLE} Regions or less`;
        }

        if (selectedRegions.length === 1) {
          return `Add ${selectedRegions.length} Region`;
        }
        return `Add ${selectedRegions.length} Region Combination`;
      }),
    );
  }

  private handleButtonDisabledState() {
    this.singleRegionButtonDisabled$ = this.singleRegionsForm.statusChanges.pipe(
      startWith(this.singleRegionsForm.status),
      map((status) => status !== 'VALID'),
    );

    this.combineRegionsButtonDisabled$ = this.combinationRegionsForm.statusChanges.pipe(
      startWith(this.combinationRegionsForm.status),
      map((status) => status !== 'VALID'),
    );
  }

  /**
   * Add clustering suffix to a region. Allowing overriding the region name in the final output.
   * Note: This function will not add the suffix if the region override name already included it.
   *
   * For example:
   *
   * - Input: 'all' | 'identity' | 'all' | 95 | 'ComboOverallInexact' will Output: 'ComboOverallInexact (95% Identity)'
   * - Input: 'all' | 'identity' | 'Heavy CDR3' | 95 | 'ComboOverallInexact' will Output: 'ComboOverallInexact'
   * - Input: 'Heavy CDR3' | 'identity' | 'Heavy CDR3' | 93 will Output: 'Heavy CDR3 (93% Identity)'
   * */
  private static addClusteringSuffixToRegionIfRequired(
    region: ClusteringMismatchRegion,
    selectedClusteringMethod: ClusteringMethod,
    selectedMismatchRegion: ClusteringMismatchRegion,
    selectedMismatchPercentage: number,
    selectedNoOfMismatches: number,
    isNucleotides: boolean,
    overrideRegionName?: string,
  ) {
    const finalRegionName = overrideRegionName ?? region;
    const countOrPercentage =
      selectedClusteringMethod === 'mismatches'
        ? `${selectedNoOfMismatches}`
        : `${selectedMismatchPercentage}%`;
    const clusteringSuffix = ` (${countOrPercentage} ${capitalize(selectedClusteringMethod)})`;

    // Default name for overall region can already included the clustering suffix.
    const shouldIncludeSuffix =
      selectedClusteringMethod !== 'exact' &&
      selectedMismatchRegion === region &&
      !finalRegionName.endsWith(clusteringSuffix);
    return (
      finalRegionName +
      (isNucleotides ? ' Nucleotides' : '') +
      (shouldIncludeSuffix ? clusteringSuffix : '')
    );
  }

  private handleSubmitEvents() {
    this.subscriptions.add(
      this.addEvent$
        .pipe(
          withLatestFrom(this.selectedSingleRegions$),
          map(([_, regions]: [any, Regions[]]) => regions),
          tap(() => {
            this.selectedSingleRegionsControl.reset([]);
          }),
        )
        .subscribe((regions) =>
          this.chipsService.addChips(regions.map((region) => ({ id: region, label: region }))),
        ),
    );

    this.subscriptions.add(
      this.combineEvent$
        .pipe(
          withLatestFrom(
            this.selectedCombinationRegions$,
            this.selectedClusteringMethod$,
            this.selectedMismatchRegion$,
            this.selectedClusteringPercentage$,
            this.clusterByNucleotides$,
            this.selectedNoOfMismatches$,
          ),
        )
        .subscribe(
          ([
            _,
            regions,
            clusteringMethod,
            mismatchRegion,
            mismatchPercentage,
            clusterByNucleotides,
            noOfMismatches,
          ]) => {
            if (regions.length === 1) {
              const chipId = RegionsSelectorAddFormComponent.addClusteringSuffixToRegionIfRequired(
                regions[0],
                clusteringMethod,
                mismatchRegion,
                mismatchPercentage,
                noOfMismatches,
                clusterByNucleotides,
              );
              const singlePercentageRegionChip = {
                id: chipId,
                label: this.combinationNameControl.value,
              };
              this.chipsService.addChips([singlePercentageRegionChip]);
            } else {
              const chipId = RegionsSelectorAddFormComponent.addClusteringSuffixToRegionIfRequired(
                'all',
                clusteringMethod,
                mismatchRegion,
                mismatchPercentage,
                noOfMismatches,
                false,
                this.combinationNameControl.value,
              );

              const allGenes = [...lightGenes, ...heavyGenes];
              const regionsWithClusteringPercentage = regions.map((region) =>
                RegionsSelectorAddFormComponent.addClusteringSuffixToRegionIfRequired(
                  region,
                  clusteringMethod,
                  mismatchRegion,
                  mismatchPercentage,
                  noOfMismatches,
                  clusterByNucleotides && !allGenes.some((gene) => region.includes(gene)),
                ),
              );

              const combinationRegionChip: CombinationRegionChip = {
                id: chipId,
                label: this.combinationNameControl.value,
                type: 'Combination',
                regions: regionsWithClusteringPercentage,
              };
              this.chipsService.addChips([combinationRegionChip]);
            }
            this.combinationNameControl.reset();
            this.selectedCombinationRegionsControl.reset([]);
          },
        ),
    );

    this.subscriptions.add(
      merge(this.addEvent$, this.combineEvent$).subscribe(() => this.ngbPopover.close()),
    );
  }

  /**
   * Group similar continuous region names into shorter name.
   * Note: that this function assume the input regions are sorted with sortAntibodyRegionByName().
   *
   * For example:
   *
   * - Heavy CDR3, Heavy CDR2, Heavy CDR1 => Heavy CDR3 CDR2 CDR1
   * - Heavy CDR3, Heavy FR1, Light CDR2 => Heavy CDR3 FR1, Light CDR2
   * */
  private static groupRegionNames(regions: Regions[]): string[] {
    return regions
      .map((x) => replaceAll(x, ': ', 'CHAIN-PREFIX-DELIMITER'))
      .reduce((acc, region, index) => {
        if (index === 0) {
          return [...acc, region];
        }
        const lastRegion = acc[acc.length - 1] ?? region;
        const lastGroup = lastRegion.split(' ')[0];
        const currentGroup = region.startsWith(lastGroup) ? lastGroup : region.split(' ')[0];
        if (lastGroup !== currentGroup) {
          return [...acc, region];
        }
        const simplifiedRegion = region.replace(currentGroup, '').trim();
        const updatedLastRegion = `${lastRegion} ${simplifiedRegion}`;
        return [...acc.slice(0, -1), updatedLastRegion];
      }, [] as string[])
      .map((x) => replaceAll(x, 'CHAIN-PREFIX-DELIMITER', ': '));
  }

  /**
   * Automatically sets the combination name based on the currently selected regions.
   * e.g. If Heavy CDR1 and Heavy CDR2 is selected, then the name will be `Heavy CDR1 CDR2`.
   *
   * If the user has written their own combination name, then it won't get overwritten when changing selection of regions.
   * If the user has removed their custom combination name, then auto setting the combination name will work again.
   */
  private setCombinationRegionName() {
    const selectedRegionsChanges$ = this.selectedCombinationRegions$;
    const clusteringMethodChanges$ = this.selectedClusteringMethod$;
    const clusteringPercentageChanges$ = this.selectedClusteringPercentage$;
    const mismatchRegionChanges$ = this.selectedMismatchRegion$;
    const noOfMismatchesChanges$ = this.selectedNoOfMismatches$;

    const hasCustomName$ = this.hasCustomName$.pipe(distinctUntilChanged(), startWith(false));

    this.subscriptions.add(
      combineLatest([
        hasCustomName$,
        selectedRegionsChanges$,
        clusteringMethodChanges$,
        clusteringPercentageChanges$,
        mismatchRegionChanges$,
        this.clusterByNucleotides$,
        noOfMismatchesChanges$,
      ])
        .pipe(
          filter(([hasCustomName, regions, ..._]) => !hasCustomName || regions?.length <= 1),
          map(
            ([
              _,
              regionsInCluster,
              clusteringMethod,
              clusteringPercentage,
              mismatchRegion,
              clusterByNucleotides,
              noOfMismatches,
            ]) => {
              return this.getClusterDisplayName(
                regionsInCluster,
                clusterByNucleotides,
                clusteringMethod,
                clusteringPercentage,
                mismatchRegion,
                noOfMismatches,
              );
            },
          ),
        )
        .subscribe((value) => {
          this.combinationNameControl.markAsDirty();
          this.combinationNameControl.setValue(value);
          this.hasCustomName$.next(false);
        }),
    );
  }

  /**
   * This is the display name that is used for a cluster.
   *
   * @param regionsInCluster
   * @param clusterByNucleotides
   * @param clusteringMethod
   * @param clusteringPercentage
   * @param mismatchRegion
   * @param noOfMismatches
   * @private
   */
  private getClusterDisplayName(
    regionsInCluster: Regions[],
    clusterByNucleotides: boolean,
    clusteringMethod: ClusteringMethod,
    clusteringPercentage: number,
    mismatchRegion: ClusteringMismatchRegion,
    noOfMismatches: number,
  ) {
    let combinationName = RegionsSelectorAddFormComponent.groupRegionNames(
      regionsInCluster.sort(sortAntibodyRegionByName),
    ).join(', ');

    if (clusterByNucleotides) {
      let nucleotideSuffix = '';
      if (regionsInCluster.length > 1) {
        nucleotideSuffix = ' NT Combo';
      } else {
        nucleotideSuffix = ' Nucleotides';
      }
      combinationName = combinationName + nucleotideSuffix;
    }

    if (clusteringMethod !== 'exact') {
      const clusteringPercentageString =
        clusteringMethod === 'mismatches' ? `${noOfMismatches}` : `${clusteringPercentage ?? 0}%`;
      const clusteringMethodString = capitalize(clusteringMethod);

      let clusteringMethodSuffix = ` (${clusteringPercentageString} ${clusteringMethodString}`;
      if (mismatchRegion === 'all' || regionsInCluster.length === 1) {
        clusteringMethodSuffix = clusteringMethodSuffix + ')';
      } else {
        clusteringMethodSuffix = clusteringMethodSuffix + ` on ${mismatchRegion})`;
      }
      combinationName = combinationName + clusteringMethodSuffix;
    }

    return combinationName;
  }
}

type ClusteringMethod = 'exact' | 'identity' | 'similarity' | 'mismatches';
type ClusteringMismatchRegion = Regions | 'all';
