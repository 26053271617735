@if (showProgressBar$ | async) {
  <mat-progress-bar class="dialog-progress-bar" mode="indeterminate"> </mat-progress-bar>
}
@if (showDialogForm$ | async) {
  <div>
    <h3 mat-dialog-title>Manage sharing</h3>
    <ng-container *bxHideIf="isPremiumOrg">
      <mat-dialog-content>
        <p>Upgrade to Premium to share data and results with your colleagues.</p>
      </mat-dialog-content>
      <mat-dialog-actions class="end">
        <a
          mat-raised-button
          id="upgrade-btn"
          color="primary"
          href="https://www.geneious.com/biopharma/plans"
          target="_blank"
          >Upgrade</a
        >
        <button mat-button mat-dialog-close color="primary" (click)="cancel()">Cancel</button>
      </mat-dialog-actions>
    </ng-container>
    <ng-container *bxHideIf="isFreeOrg">
      <mat-dialog-content>
        <section class="list">
          <mat-list>
            <h3 mat-subheader>Who has access</h3>
            @for (entity of selectedEntities$ | async; track entity) {
              <bx-manage-sharing-list-item
                [item]="entity"
                (itemRemoved)="onEntityRemoved($event)"
                (accessLevelChanged)="onEntityAccessLevelChanged(entity)"
              >
              </bx-manage-sharing-list-item>
            }
          </mat-list>
        </section>
        <section>
          <mat-form-field floatLabel="never" (keydown)="onKeyDown($event)">
            <input
              matInput
              placeholder="Enter name/email to add..."
              [matAutocomplete]="auto"
              [formControl]="searchInput"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [autoActiveFirstOption]="true"
              (optionSelected)="onEntitySelectionChanged($event)"
            >
              <mat-optgroup label="Groups">
                @for (entity of filteredGroupEntities$ | async; track entity) {
                  <mat-option [value]="entity">
                    <bx-manage-sharing-option
                      [entity]="entity"
                      [searchText]="searchInput.valueChanges | async"
                    ></bx-manage-sharing-option>
                  </mat-option>
                }
              </mat-optgroup>
              <mat-optgroup label="Users">
                @for (entity of filteredUserEntities$ | async; track entity) {
                  <mat-option [value]="entity">
                    <bx-manage-sharing-option
                      [entity]="entity"
                      [searchText]="searchInput.valueChanges | async"
                    ></bx-manage-sharing-option>
                  </mat-option>
                }
              </mat-optgroup>
            </mat-autocomplete>
          </mat-form-field>
        </section>
      </mat-dialog-content>
      <mat-dialog-actions class="end">
        @if (errorMessage$ | async; as errorMessage) {
          <mat-error>{{ errorMessage }}</mat-error>
        }
        <button
          mat-raised-button
          color="primary"
          (click)="submitForm()"
          [disabled]="formSubmitDisabled$ | async"
          data-testid="manage-sharing-save"
        >
          Save
        </button>
        <button
          mat-button
          mat-dialog-close
          color="primary"
          (click)="cancel()"
          data-testid="manage-sharing-cancel"
        >
          Cancel
        </button>
      </mat-dialog-actions>
    </ng-container>
  </div>
}
