import { ColDef } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { IGridResource } from '../../../features/grid/datasource/grid.resource';
import { GridComponent, SelectionState } from '../../../features/grid/grid.component';
import { CleanUp } from '../../../shared/cleanup';
import { FormatterService } from '../../../shared/formatter.service';
import { AppState } from '../../core.store';
import { OrganizationSetting } from '../../models/settings/setting.model';
import { getRowIdentifier } from '../../ngs/getRowIdentifier';
import { labelActions } from '../../organization-settings/organization-settings.actions';
import { OrganizationSettingsResource } from '../../organization-settings/organization-settings.resource';
import { selectLabels } from '../../organization-settings/organization-settings.selectors';
import { BackgroundColorRendererComponent } from '../background-color-renderer/background-color-renderer.component';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

@Component({
  selector: 'bx-labels',
  templateUrl: './labels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    RouterLink,
    GridComponent,
  ],
})
export class LabelsComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100';
  @ViewChild(GridComponent, { static: true }) gridComponent: GridComponent;

  labelsResource: IGridResource;
  labelsColumnDefs: ColDef[];
  numSelected: number;
  rows: any[];

  constructor(
    readonly dialogService: DialogService,
    private store: Store<AppState>,
  ) {
    super();

    this.labelsColumnDefs = [
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Color',
        field: 'color',
        cellRenderer: BackgroundColorRendererComponent,
        valueGetter: (params) =>
          params.data && params.data.data.color ? params.data.data.color : '-',
      },
      {
        headerName: 'Creation Date',
        field: 'created',
        valueGetter: (params) => {
          return params.data && params.data.data.created
            ? FormatterService.formatDateString(params.data.data.created)
            : '-';
        },
      },
    ];
  }

  ngOnInit(): void {
    this.labelsResource = new OrganizationSettingsResource(this.store, selectLabels);
  }

  onGridReady() {
    this.store
      .select(selectLabels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.gridComponent.refresh(true);
      });
  }

  deleteSelectedLabels(): void {
    const labelNames = this.rows.map((row) => `"${(row as OrganizationSetting).name}"`).join(', ');
    const dialogData =
      this.rows.length === 1
        ? { title: `Delete label ${labelNames} permanently?` }
        : {
            title: `Delete ${this.rows.length} labels permanently?`,
            content: `Labels: ${labelNames}`,
          };
    this.dialogService
      .showConfirmationDialog$({ confirmationButtonText: 'Delete', ...dialogData })
      .subscribe((result: boolean) => {
        if (result) {
          this.rows.forEach((row) => this.deleteLabel(getRowIdentifier(row)));
        }
      });
  }

  onSelectionChanged(e: SelectionState) {
    this.numSelected = e.selectedRows.length;
    this.rows = e.selectedRows;
  }

  private deleteLabel(id: string) {
    this.store.dispatch(labelActions.remove({ id }));
  }
}
