<div class="px-3 pt-3 pb-2">
  <h5 class="mb-0">
    <bx-search-back-button></bx-search-back-button>
    Search Results
  </h5>
</div>

<bx-grid
  class="w-100 flex-grow-1 flex-shrink-1"
  [gridOptions]="gridOptions"
  [datasource]="datasource$ | async"
  [datasourceParams$]="datasourceParams$"
  [initialColumnDefs]="columnDefs$ | async"
  noRowsOverlayMessage="No matches found."
  [resultSetMax]="1000"
  [showTimer]="true"
  [tableType]="tableType$ | async"
>
</bx-grid>
