import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ViewerPageURLSelectionState } from '../../core/viewer-page/viewer-page.component';
import { Router, RouterLink } from '@angular/router';
import { faExternalLinkAlt, faPen } from '@fortawesome/free-solid-svg-icons';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-open-document-button',
  templateUrl: './open-document-button.component.html',
  styleUrls: ['./open-document-button.component.scss'],
  standalone: true,
  imports: [RouterLink, NgClass, NgbTooltip, FaIconComponent],
})
export class OpenDocumentButtonComponent implements OnInit, OnChanges {
  @Input() text: string = 'Open Full Document';
  @Input() editButtonVisible: boolean;
  @Input() openQueryParams: ViewerPageURLSelectionState;
  @Input() buttonStyle?: 'outline';
  @Input() buttonColor: 'primary' | 'secondary' | 'success' | 'info' | 'danger' = 'primary';
  @Input() tooltipTextButton: string = 'Open full document';
  @Input() tooltipPopoutButton: string = 'Open full document in new tab';
  @Input() tooltipEditButton: string = 'Open full document in edit mode';

  buttonClass: string;
  editQueryParams: ViewerPageURLSelectionState;
  readonly newTabIcon = faExternalLinkAlt;
  readonly editIcon = faPen;

  constructor(private router: Router) {}

  ngOnInit() {
    this.editQueryParams = { ...this.openQueryParams, edit: true };
    this.setButtonClass();
  }

  ngOnChanges({ openQueryParams, buttonColor, buttonStyle }: SimpleChanges) {
    if (openQueryParams) {
      this.editQueryParams = { ...openQueryParams.currentValue, edit: true };
    }
    if (buttonColor || buttonStyle) {
      this.setButtonClass();
    }
  }

  /**
   * Open document in pop-out view.
   *
   * The reason why we do this instead of using a `<a>` with `target=_blank` is because of the URL preview toast
   * of the browser that show up when you hover over hyperlink. Our URL can be pretty long & that preview end up
   * covering our open document button.
   *
   * @param params the query params to append to the pop-out view URL
   */
  openInNewWindow(params: ViewerPageURLSelectionState) {
    const url = this.router.createUrlTree(['view'], {
      queryParams: params,
    });
    window.open(this.router.serializeUrl(url), '_blank');
  }

  private setButtonClass() {
    if (this.buttonStyle) {
      this.buttonClass = `btn-${this.buttonStyle}-${this.buttonColor}`;
    } else {
      this.buttonClass = `btn-${this.buttonColor}`;
    }
  }
}
