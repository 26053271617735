import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, Validator } from '@angular/forms';
import { PasswordStrengthService } from './password-strength.service';

@Directive({
  selector: '[bxValidatePassword]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => ValidatePasswordDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class ValidatePasswordDirective implements Validator {
  constructor(private passwordStrengthService: PasswordStrengthService) {}

  validate(c: AbstractControl): Promise<{ [key: string]: any }> {
    const password = c.value;

    if (!password) {
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      this.passwordStrengthService.validate(password).then((result) => {
        if (result) {
          resolve(null);
        } else {
          this.passwordStrengthService
            .score(password)
            .then((scoreResult: { suggestions: string; score: number }) => {
              resolve({
                weakPassword: scoreResult.suggestions,
              });
            });
        }
      });
    });
  }
}
