import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.store';
import { selectReferenceSequencesFolderID } from '../../../folders/store/folder.selectors';
import { map } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-no-general-template-database-message',
  templateUrl: './no-general-template-database-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, AsyncPipe],
})
export class NoGeneralTemplateDatabaseMessageComponent {
  referenceSequencesFolderURL$: Observable<string>;

  constructor(private store: Store<AppState>) {
    this.referenceSequencesFolderURL$ = this.store
      .select(selectReferenceSequencesFolderID)
      .pipe(map((referenceSequencesFolderID) => `/database-root/${referenceSequencesFolderID}`));
  }
}
