import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FolderTreeIcon } from '../../folders/models/folder.model';

@Component({
  selector: 'bx-folder-tree-icon',
  standalone: true,
  imports: [FontAwesomeModule, MatIconModule],
  templateUrl: './folder-tree-icon.component.html',
  styleUrl: './folder-tree-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderTreeIconComponent implements OnChanges {
  @Input() icon!: FolderTreeIcon;

  isSvgIcon: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.isSvgIcon = typeof this.icon === 'string';
    }
  }
}
