<div class="d-flex flex-nowrap mt-3 mb-2 mx-0">
  <mat-icon class="avatar ms-2 flex-shrink-0">account_circle</mat-icon>
  @if (userInfo$ | async; as userInfo) {
    <div class="flex-grow-1 flex-shrink-1 px-3 overflow-hidden">
      <h3 class="mb-2">{{ userInfo.user.givenName }} {{ userInfo.user.familyName }}</h3>
      <h6 class="text-muted">{{ userInfo.user.email }}</h6>
      <h6><fa-icon [icon]="faBuilding"></fa-icon> {{ userInfo.organizationName }}</h6>
    </div>
  }
</div>
<div class="d-flex align-items-center justify-content-end">
  <a class="btn btn-outline-primary" routerLink="/settings" (click)="clickUserAccount.emit()">
    My Account
  </a>
  <button
    type="button"
    class="btn btn-outline-secondary ms-2"
    (click)="logout()"
    bx-spinner-button
    [spin]="loggingOut"
  >
    Logout
  </button>
</div>
