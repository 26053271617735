import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { JobStepperDialog } from '../../dialogV2/job-stepper-dialog';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SelectionState } from '../../../features/grid/grid.component';
import { StepperComponent } from '../../../shared/stepper/stepper.component';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import {
  MasterDatabaseImporterJobOptionsV1,
  MasterDatabaseImporterJobParameters,
} from '../../../../nucleus/services/models/masterDatabaseImporterOptions.model';
import { SelectionOptionsV1 } from '../../../../nucleus/services/models/jobParameters.model';
import { SequenceExtractionSelectionOptions } from '../../../../nucleus/services/models/result-sequence-extraction-options-v1.model';
import { DocumentTableQuery } from '../../../../nucleus/services/models/extractSequencesV3.model';
import { AnnotatedPluginDocument } from '../../geneious';
import { MasterDatabaseFolderAndFiles } from '../index';
import { MasterDatabaseService } from '../master-database.service';
import { masterDatabaseSequenceTypeValidator } from '../form-validators/master-database-sequence-type.validator';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  catchError,
  filter,
  first,
  map,
  mapTo,
  share,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { DocumentServiceHttpV1 } from '../../../../nucleus/services/documentService/document-service.v1.http';
import {
  DocumentServiceTableColumnGroups,
  DocumentServiceTableInfo,
  DocumentTable,
} from '../../../../nucleus/services/documentService/types';
import { masterDatabaseNewGroupNameValidator } from '../form-validators/master-database-new-group-name.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { FolderService } from '../../folders/folder.service';
import { masterDatabaseGroupColumnConflictsValidator } from '../form-validators/master-database-group-column-conflicts.validator';
import { FormControlWarning } from '../../../shared/form-helpers/abstract-control-warning/abstract-control-warning';
import {
  PIPELINE_DIALOG_DATA,
  PipelineDialogData,
} from '../../pipeline-dialogs/pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { SelectGroup } from '../../models/ui/select-option.model';
import { StepComponent } from '../../../shared/stepper/step/step.component';
import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { SelectComponent } from '../../../shared/select/select.component';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';

interface DialogVariables {
  documentTableQuery: DocumentTableQuery;
  selection: SequenceExtractionSelectionOptions;
  selectedTable: string;
}

@Component({
  selector: 'bx-master-database-annotated-sequences-importer-dialog',
  templateUrl: './master-database-annotated-sequences-importer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StepperComponent,
    StepComponent,
    SelectComponent,
    PageMessageComponent,
    CollapsibleCardComponent,
    NgFormControlValidatorDirective,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class MasterDatabaseAnnotatedSequencesImporterDialogComponent
  extends JobStepperDialog
  implements OnInit, OnDestroy, RunnableJobDialog
{
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;
  readonly form = new FormGroup({
    collection: new FormControl<string>(null, Validators.required),
    columnGroups: new FormArray<
      FormGroup<{
        groupMergeRadio: FormControl<any>;
        groupToMergeInto: FormControlWarning<string>;
        newGroupName: FormControl<string>;
      }>
    >([]),
  });

  title = 'Add to Collection';
  earlyRelease = true;
  knowledgeBaseArticle = 'https://help.geneiousbiologics.com/hc/en-us/articles/360052418151';

  readonly columnGroupsFormArray = this.form.controls.columnGroups;
  masterDatabases$: Observable<MasterDatabaseFolderAndFiles[]>;
  masterDatabaseSelectOptions$: Observable<SelectGroup[]>;
  masterDatabaseGroups$: Observable<DocumentServiceTableColumnGroups>;
  resultDocumentGroups$: Observable<DocumentTable[]>;
  groupsLoaded$: Observable<boolean>;
  summary$: Observable<Summary>;
  getCollectionsError$ = new BehaviorSubject<String | null>(null);

  faChevronRight = faChevronRight;

  private masterDatabaseTable$: Observable<DocumentServiceTableInfo | undefined>;
  private masterDatabaseName$: Observable<string | undefined>;

  private formDefaults: any;
  private document: AnnotatedPluginDocument;
  private subscriptions = new Subscription();
  private readonly selected: SelectionState;
  private readonly otherVariables: DialogVariables;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData<DialogVariables>,
    private masterDatabaseService: MasterDatabaseService,
    private documentServiceHttpV1: DocumentServiceHttpV1,
    private folderService: FolderService,
  ) {
    super('master-database-importer', PipelineFormID.MASTER_DATABASE_IMPORTER);
    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
    this.otherVariables = this.dialogData.otherVariables;
  }

  ngOnInit() {
    this.document = AnnotatedPluginDocument.fromNucleusEntity(this.selected.selectedRows[0]);
    const folder$: Observable<FolderTreeItem> = this.folderService
      .get(this.dialogData.folderID)
      .pipe(first());

    this.form.controls.collection.setAsyncValidators(
      masterDatabaseSequenceTypeValidator(this.document, this.masterDatabaseService),
    );

    const selectedCollectionID$: Observable<string> =
      this.form.controls.collection.valueChanges.pipe(filter((collectionID) => !!collectionID));

    this.masterDatabases$ = this.masterDatabaseService.getDatabases().pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        let message = 'An error occurred while attempting to get list of available Collections';
        if (error.status) {
          message += ` (Error code: ${error.status})`;
        }
        this.getCollectionsError$.next(message);
        return of([]);
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.masterDatabaseSelectOptions$ = this.masterDatabases$.pipe(
      map((collections) =>
        collections
          .filter((collection) => collection.databases.length > 0)
          .map(
            ({ folder, databases }) =>
              new SelectGroup(
                databases.map(({ name, id }) => ({ displayName: name, value: id })),
                folder.name,
              ),
          ),
      ),
    );

    this.masterDatabaseTable$ = selectedCollectionID$.pipe(
      switchMap((masterDatabaseID) =>
        this.masterDatabaseService.getTable(masterDatabaseID).pipe(
          catchError((error: HttpErrorResponse) =>
            folder$.pipe(
              map((folder) => {
                if (error.status === 403 && folder && folder.hasWriteAccess()) {
                  return undefined;
                } else {
                  throw error;
                }
              }),
            ),
          ),
        ),
      ),
      share(),
    );
    this.masterDatabaseName$ = selectedCollectionID$.pipe(
      withLatestFrom(this.masterDatabases$),
      map(([masterDatabaseID, masterDatabases]) => {
        for (const masterDatabaseFolder of masterDatabases) {
          for (const database of masterDatabaseFolder.databases) {
            if (database.id === masterDatabaseID) {
              return database.name;
            }
          }
        }
      }),
    );
    this.masterDatabaseGroups$ = this.masterDatabaseTable$.pipe(
      map((table) => (table ? table.columnGroups : {})),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.resultDocumentGroups$ = this.documentServiceHttpV1.getTables(this.document.id).pipe(
      map((tables) => {
        const documentID = this.document.id;
        function getAllJoins(tableName: string): DocumentTable[] {
          const table = tables[tableName];
          return Object.keys(table.joins).reduce(
            (acc, key) => {
              return acc.concat(getAllJoins(key));
            },
            tableName === 'DOCUMENT_TABLE_ALL_SEQUENCES'
              ? []
              : [{ ...table, ...{ documentID: documentID, name: tableName } }],
          );
        }

        return getAllJoins('DOCUMENT_TABLE_ALL_SEQUENCES');
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const masterDatabaseGroupsLoaded$ = merge(
      selectedCollectionID$.pipe(mapTo(false)),
      this.masterDatabaseGroups$.pipe(mapTo(true)),
    );

    this.groupsLoaded$ = combineLatest([
      masterDatabaseGroupsLoaded$,
      this.resultDocumentGroups$,
    ]).pipe(map(([masterDatabaseGroupsLoaded]) => masterDatabaseGroupsLoaded));

    this.constructColumnGroupsFormArray();
    this.updateColumnGroupsFormArrayValues();

    this.summary$ = combineLatest([
      this.form.valueChanges,
      this.masterDatabaseName$,
      this.resultDocumentGroups$,
    ]).pipe(
      map(([value, masterDatabaseName, resultDocumentGroups]) => ({
        collectionName: masterDatabaseName,
        groupsToMerge: value.columnGroups.map((group: any, i: number) => ({
          groupName: resultDocumentGroups[i].displayName,
          mergingInto: group.groupToMergeInto,
          newGroupName: group.newGroupName,
        })),
      })),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.subscriptions.unsubscribe();
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  getGroupToMergeIntoControl(index: number): FormControlWarning {
    return this.columnGroupsFormArray.at(index).get('groupToMergeInto') as FormControlWarning;
  }

  run() {
    return this.resultDocumentGroups$.pipe(
      map((groups) => {
        const pipelineOptions: MasterDatabaseImporterJobOptionsV1 = {
          annotatedSequencesImporterOptions: {
            tableQueryOptions: {
              documentTableName: this.otherVariables.selectedTable,
              documentTableQuery: this.otherVariables.documentTableQuery,
              selection: this.otherVariables.selection,
            },
            groupModifications: this.form
              .getRawValue()
              .columnGroups.map((group: any, i: number) => ({
                // Get original name of the result document group that will contain the ASSAY_DATA_ or CHERRY_PICKING_
                // prefix which is important for the pipeline to identify the original result document group.
                originalGroupName: groups[i].name,
                existingGroupName:
                  group.groupMergeRadio === 'existing' ? group.groupToMergeInto : undefined,
                newGroupName: group.groupMergeRadio === 'new' ? group.newGroupName : undefined,
              })),
          },
        };

        const selection: SelectionOptionsV1 = {
          selectAll: false,
          folderId: undefined,
          // Have to get the raw value of the form as the entire form gets disabled after this method is called.
          ids: [this.form.getRawValue().collection],
        };

        return new MasterDatabaseImporterJobParameters(selection, pipelineOptions);
      }),
    );
  }

  private constructColumnGroupsFormArray() {
    this.subscriptions.add(
      this.resultDocumentGroups$.subscribe((resultDocumentGroups) => {
        this.columnGroupsFormArray.clear();
        resultDocumentGroups.forEach((group) => {
          const formGroup = new FormGroup({
            groupMergeRadio: new FormControl(),
            groupToMergeInto: new FormControlWarning<string>(undefined, Validators.required),
            newGroupName: new FormControl(group.displayName, Validators.required),
          });
          this.columnGroupsFormArray.push(formGroup);
          formGroup.get('groupMergeRadio').valueChanges.subscribe((value) => {
            if (value === 'existing') {
              formGroup.controls.groupToMergeInto.enable();
              formGroup.controls.newGroupName.disable();
            } else {
              formGroup.controls.groupToMergeInto.disable();
              formGroup.controls.newGroupName.enable();
            }
          });
        });
      }),
    );
  }

  private updateColumnGroupsFormArrayValues() {
    this.subscriptions.add(
      this.masterDatabaseTable$
        .pipe(withLatestFrom(this.resultDocumentGroups$))
        .subscribe(([masterDatabaseTable, resultDocumentGroups]) => {
          const masterDatabaseGroups = masterDatabaseTable?.columnGroups ?? {};
          this.columnGroupsFormArray.controls.forEach((control, index) => {
            const resultDocumentGroup = resultDocumentGroups[index];
            control.setValidators([
              masterDatabaseNewGroupNameValidator(masterDatabaseGroups),
              masterDatabaseGroupColumnConflictsValidator(masterDatabaseTable, resultDocumentGroup),
            ]);
          });
          const values = resultDocumentGroups.map((group) => ({
            groupMergeRadio: group.displayName in masterDatabaseGroups ? 'existing' : 'new',
            groupToMergeInto:
              group.displayName in masterDatabaseGroups ? group.displayName : undefined,
            newGroupName: group.displayName,
          }));
          this.columnGroupsFormArray.patchValue(values);
          this.formDefaults = {
            ...this.formDefaults,
            columnGroups: this.columnGroupsFormArray.getRawValue(),
          };
        }),
    );
  }
}

interface Summary {
  collectionName: string;
  groupsToMerge: { groupName: string; mergingInto?: string; newGroupName?: string }[];
}
