import { combineLatest, map, merge, Observable, of, shareReplay, Subject, zip } from 'rxjs';
import { SequenceViewerMetadataService } from '../../sequence-viewer/sequence-viewer-metadata.service';
import {
  isViewerMasterDatabaseSearchSelection,
  isViewerMultipleTableDocumentSelection,
  isViewerResultData,
  ViewerMasterDatabaseSearchData,
  ViewerMasterDatabaseSearchSelection,
  ViewerMultipleTableDocumentSelection,
  ViewerResultData,
} from '../../viewer-components/viewer-document-data';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { NgsSequenceViewerService } from '../ngs-sequence-viewer.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { MasterDatabaseSequencesResource } from '../../master-database/master-database-search-result-viewer/master-database-sequences-resource.service';
import {
  MetadataColumnAbomination,
  RemoveSvOption,
} from '../../../features/sequence-viewer-angular/sequence-viewer.interfaces';
import { SequenceViewerPreferencesService } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.service';
import { CleanUp } from '../../../shared/cleanup';
import { SequenceData } from '@geneious/sequence-viewer/types';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectSequenceViewerPreference } from '../../user-settings/sequence-viewer-preferences/sequence-viewer-preferences.selectors';

export type NGSSequenceViewerData = ViewerResultData | ViewerMasterDatabaseSearchData;

@Directive()
export abstract class NgsSequenceViewerBaseComponent<T = NGSSequenceViewerData>
  extends CleanUp
  implements OnInit, OnDestroy
{
  isLoading$: Observable<boolean>;
  showSequenceViewer$: Subject<boolean>;
  sequences$: Observable<SequenceData[]>;
  messages$: Subject<string>;
  metadataColumns$: Observable<MetadataColumnAbomination[]>;
  gotAllSequences$: Observable<boolean>;
  isComparison$: Observable<boolean>;
  isAlignment$: Observable<boolean>;
  sequenceViewerPreferencesState$: Observable<any>;
  toolbarWarningMsg$: Observable<string | null>;
  docID: string;
  viewerData$: Observable<NGSSequenceViewerData>;
  columnIDs$: Observable<string[]>;

  private readonly refetchSequences$: Observable<true>;

  private readonly viewerSelection$: Observable<
    ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection
  >;
  private readonly viewerResource$: Observable<
    NgsSequenceViewerService | MasterDatabaseSequencesResource
  >;
  private sequenceTypeSelected: string;

  protected constructor(
    private readonly viewerDataService: ViewerDataService<T>,
    private readonly sequenceViewerPreferencesService: SequenceViewerPreferencesService,
    private readonly svMetadataService: SequenceViewerMetadataService,
    private readonly store: Store<AppState>,
  ) {
    super();
    this.showSequenceViewer$ = new Subject();
    this.messages$ = new Subject();
    const initialViewerData$ = this.viewerDataService.getData('ngs-sequence-viewer');
    this.viewerData$ = this.transformViewerData$(initialViewerData$);
    this.refetchSequences$ = this.viewerData$.pipe(map(() => true));
    this.viewerSelection$ = this.viewerData$.pipe(
      map((data) => data.selection),
      distinctUntilChanged(),
    );
    this.viewerResource$ = this.viewerData$.pipe(
      map((data) => data.resource),
      distinctUntilChanged(),
    );
    this.columnIDs$ = this.viewerData$.pipe(
      map((data) => (isViewerResultData(data) && data.columns) ?? []),
      map((columns) => columns.map((column) => column.colID)),
    );
  }

  abstract transformViewerData$(initialData$: Observable<T>): Observable<NGSSequenceViewerData>;

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.messages$.complete();
    this.showSequenceViewer$.complete();
  }

  ngOnInit() {
    const numberOfRowsSelected$ = this.viewerSelection$.pipe(
      map((state) => state.rows.length),
      distinctUntilChanged(),
    );

    const totalSelected$ = this.viewerSelection$.pipe(
      map((state) => state.totalSelected),
      distinctUntilChanged(),
    );

    this.gotAllSequences$ = combineLatest([numberOfRowsSelected$, totalSelected$]).pipe(
      map(([numberOfRowsSelected, totalSelected]) => numberOfRowsSelected === totalSelected),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.isComparison$ = this.viewerSelection$.pipe(
      map(
        (selection) =>
          isViewerMultipleTableDocumentSelection(selection) &&
          selection.tableType === DocumentTableType.COMPARISON_CLUSTERS,
      ),
    );

    this.isAlignment$ = this.viewerSelection$.pipe(
      map(
        (selection) =>
          isViewerMasterDatabaseSearchSelection(selection) &&
          (selection.subTableRows.length === 1 ||
            (selection.rows.length === 1 && selection.rows[0].best_match_urn != null)),
      ),
    );

    this.toolbarWarningMsg$ = this.viewerSelection$.pipe(
      map((selection) => {
        if (isViewerMasterDatabaseSearchSelection(selection)) {
          return false;
        }
        const isValidSelection = this.isValidSelection(
          selection,
          NgsSequenceViewerService.MAX_NUMBER_OF_SEQUENCES,
        );
        const isClusterRow =
          selection.tableType === DocumentTableType.CLUSTERS ||
          selection.tableType === DocumentTableType.CLUSTER_GENE ||
          selection.tableType === DocumentTableType.CODON_USAGE ||
          selection.tableType === DocumentTableType.INEXACT_CLUSTER;
        const totalSequences = selection.rows.reduce(
          (total, row) => total + (row.Total || row.Chain === 'Both' ? 2 : 1),
          0,
        );
        return isValidSelection && isClusterRow && (selection.selectAll || totalSequences > 50);
      }),
      map((clusterRowLimitReached) =>
        clusterRowLimitReached ? 'Showing first 50 clusters only' : null,
      ),
    );

    this.sequences$ = combineLatest([this.viewerSelection$, this.viewerResource$]).pipe(
      // Both Viewer Selection and Viewer Resource come from the same stream and thus it's possible
      // they could emit at the same time. If we debounce with a time of 0, it ensures we don't get
      // a double emission from combineLatest. It will just take the last emission after the current
      // javascript execution stack.
      debounceTime(0),
      tap(([selection]) => {
        this.docID = selection.document.id;
      }),
      // Reset error stream as new request is being made.
      tap(([selection]) =>
        this.messages$.next(
          this.getSequenceViewerHelpText(
            selection,
            NgsSequenceViewerService.MAX_NUMBER_OF_SEQUENCES,
          ),
        ),
      ),
      switchMap(([selection, resource]) => {
        if (this.isValidSelection(selection, NgsSequenceViewerService.MAX_NUMBER_OF_SEQUENCES)) {
          const entityID: string = (<
            ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection
          >selection).document.id;
          return this.prepareSequences(selection, entityID, resource).pipe(
            catchError((e) => {
              console.error(e);
              this.messages$.next('Failed to retrieve sequence(s).');
              return of([]);
            }),
          );
        } else {
          return of([]);
        }
      }),
      tap((sequences) => {
        sequences.forEach((sequence: any) => {
          // Determine the data type selected by the user. Qualities are sticky if found and combinations of AA and DNA aren't allowed.
          if (this.sequenceTypeSelected !== 'QUAL') {
            if (sequence.sequence && sequence.sequence.qualities) {
              this.sequenceTypeSelected = 'QUAL';
            } else if (sequence.sequence && sequence.sequence.sequenceType === 'Nucleotide') {
              this.sequenceTypeSelected = 'DNA';
            } else if (sequence.sequence && sequence.sequence.sequenceType === 'AminoAcid') {
              this.sequenceTypeSelected = 'AA';
            }
          }
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.sequenceViewerPreferencesState$ = this.viewerSelection$.pipe(
      switchMap((selection) =>
        this.store.select(selectSequenceViewerPreference(selection.document.id)).pipe(take(1)),
      ),
      map((data) => {
        // Inject the server-side quality colour scheme if selected documents include quality.
        if (
          this.sequenceTypeSelected === 'QUAL' &&
          data.sequencesPlugin &&
          data.sequencesPlugin.qualityColorScheme
        ) {
          return {
            ...data,
            ...{
              sequencesPlugin: {
                ...data.sequencesPlugin,
                DNAColorScheme: data.sequencesPlugin.qualityColorScheme,
              },
            },
          };
        }
        return data;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.svMetadataService.liabilityScoreThresholdChanged({
      liabilityScoreLow: -2000,
      liabilityScoreHigh: -1000,
    });
    this.metadataColumns$ = combineLatest([this.sequences$, this.columnIDs$]).pipe(
      withLatestFrom(this.isComparison$),
      map(([[sequences, columnIDs], isComparison]) =>
        this.svMetadataService.buildMetadataColumnsFromSequences(
          sequences,
          !isComparison,
          columnIDs,
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.isLoading$ = merge(
      this.refetchSequences$,
      zip(this.sequenceViewerPreferencesState$, this.sequences$).pipe(map(() => false)),
    );

    combineLatest([this.isLoading$, this.sequences$])
      .pipe(
        map(([isLoading, sequences]) => !isLoading && sequences && sequences.length > 0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.showSequenceViewer$);
  }

  optionChanged(option: any) {
    if (option['clear_global_preferences']) {
      this.sequenceViewerPreferencesService.clearOptions();

      this.reloadSequenceViewer();
    } else if (option['clear_all_preferences']) {
      this.sequenceViewerPreferencesService.clearOptions([this.docID]);

      this.reloadSequenceViewer();
    } else {
      this.sequenceViewerPreferencesService.upsertOption(option, this.sequenceTypeSelected, [
        this.docID,
      ]);
    }
  }

  optionRemoved(event: RemoveSvOption) {
    this.sequenceViewerPreferencesService.removeOption(event, [this.docID]);
  }

  liabilityScoreThresholdChanged(event: any) {
    this.svMetadataService.liabilityScoreThresholdChanged(event);
  }

  reloadSequenceViewer() {
    this.showSequenceViewer$.next(false);
    // setTimeout to add a wait time to make sure the off/on toggling triggers.
    setTimeout(() => this.showSequenceViewer$.next(true));
  }

  private prepareSequences(
    state: ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection,
    entityID: string,
    resource: NgsSequenceViewerService | MasterDatabaseSequencesResource,
  ) {
    return resource
      .getSequences(entityID, state)
      .pipe(
        map((sequences) =>
          this.svMetadataService.addAssayDataToSelectedSequences(sequences, state),
        ),
      );
  }

  getSequenceViewerHelpText(
    selectionState: ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection,
    limit: number,
  ) {
    if (!selectionState || selectionState.totalSelected === 0) {
      return `Select one or more sequence documents to view them (max ${limit})`;
    } else if (selectionState.totalSelected > limit) {
      return `Select ${limit} or fewer sequences to view them`;
    } else {
      return undefined;
    }
  }

  isValidSelection(
    selectionState: ViewerMultipleTableDocumentSelection | ViewerMasterDatabaseSearchSelection,
    limit: number,
  ) {
    return (
      selectionState && selectionState.totalSelected > 0 && selectionState.totalSelected <= limit
    );
  }
}
