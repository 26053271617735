import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bxFileRead]',
  standalone: true,
})
export class FileReadDirective implements OnDestroy {
  @Output() filesRead: EventEmitter<File[]> = new EventEmitter();

  private changeListener: Function;

  constructor(el: ElementRef, renderer: Renderer2) {
    this.changeListener = renderer.listen(el.nativeElement, 'change', (changeEvent) => {
      const files = changeEvent.target.files;
      this.filesRead.emit(files);
      // Reset the button so selecting the same file twice in a row
      // fires a change event.
      changeEvent.target.value = null;
    });
  }

  // Destroy listener.
  ngOnDestroy() {
    // @see https://dotmatics.atlassian.net/browse/BX-4464
    if (this.changeListener) {
      this.changeListener();
    }
  }
}
