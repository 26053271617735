<button type="button" class="header" [disabled]="disabled" (click)="headerClick.emit()">
  <div class="title">{{ title }}</div>
</button>

<div class="body" [ngClass]="{ expanded: expanded }" data-testid="accordion-step-body">
  <ng-content></ng-content>
  <div class="errors">
    @for (error of errors; track error) {
      <div class="error">{{ error }}</div>
    }
  </div>
  @if (showButtons) {
    <button
      type="button"
      mat-button
      class="continue"
      (click)="stepDone()"
      data-testid="accordion-step-continue"
    >
      Continue
    </button>
  }
</div>
