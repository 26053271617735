import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ACLEntity } from '../manage-sharing.models';
import { AccessLevels } from '../../permissions/access-level.const';
import { AccessLevel, AccessLevels as ALs } from '../../permissions/access-level.interface';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectModule,
} from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLineModule } from '@angular/material/core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule } from '@angular/forms';

import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { AccessLevelOptionComponent } from '../access-level-option/access-level-option.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'bx-manage-sharing-list-item',
  templateUrl: './manage-sharing-list-item.component.html',
  styleUrls: ['./manage-sharing-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatLineModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    FormsModule,
    MatLegacyOptionModule,
    AccessLevelOptionComponent,
    MatLegacyButtonModule,
  ],
})
export class ManageSharingListItemComponent implements OnInit {
  @Input() item: ACLEntity;
  @Output() itemRemoved: EventEmitter<ACLEntity> = new EventEmitter<ACLEntity>();
  @Output() accessLevelChanged: EventEmitter<ACLEntity> = new EventEmitter<ACLEntity>();
  @ViewChild(MatSelect, { read: ElementRef }) selectBox: ElementRef;

  accessLevels: AccessLevel[] = Object.keys(AccessLevels)
    // No Permission should be allowed to be set to none. Instead they should be removed.
    .filter((key) => key !== 'none')
    .map((key) => AccessLevels[key as keyof ALs]);

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.accessLevels.forEach((level: AccessLevel) => {
      if (this.item.highestAccess.value === level.value) {
        this.item.highestAccess = level;
      }
    });
  }

  onRemovePermission() {
    this.itemRemoved.emit(this.item);
  }

  onAccessLevelChanged() {
    this.accessLevelChanged.emit(this.item);
  }

  focus() {
    this.elementRef.nativeElement.scrollIntoView(false);
    this.selectBox.nativeElement.focus();
  }
}
