import { Observable, of as observableOf } from 'rxjs';
import { Component } from '@angular/core';
import { Search } from '../model/search.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { SearchService } from '../search.service';
import { Searcher } from '../model/searcher';
import { distinctUntilChanged, map, publishReplay, refCount } from 'rxjs/operators';
import { SearchResultsTableComponent } from './search-results-table/search-results-table.component';

@Component({
  selector: 'bx-search-results',
  templateUrl: './search-results.component.html',
  standalone: true,
  imports: [SearchResultsTableComponent],
})
export class SearchResultsComponent {
  query$: Observable<Search.Query>;
  searcher$: Observable<Searcher>;

  constructor(
    public searchService: SearchService,
    private store: Store<AppState>,
  ) {
    this.query$ = this.store.select('searchReducer').pipe(
      map((state: Search.State) => state.query),
      // Don't fire a new event of only the sort has changed - the table determines sort itself internally so it already knows.
      distinctUntilChanged(
        (a, b) => a.queryString === b.queryString && a.queryType === b.queryType,
      ),
      publishReplay(1),
      refCount(),
    );

    // TODO In the future we might want to switch between different global search implementations ie elasticsearch vs nucleus.
    this.searcher$ = observableOf(this.searchService);
  }
}
