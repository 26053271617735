import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ProfileService } from '../../../../core/user-settings/profiles/profile.service';
import { Profile, ProfileFeature } from '../../../../core/user-settings/profiles/profiles.model';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, scan, startWith, take } from 'rxjs/operators';
import { GridState } from '../../grid.interfaces';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfileInfo } from 'src/app/shared/profile-buttons/profile-buttons.component';
import { ProfileButtonsComponent } from '../../../../shared/profile-buttons/profile-buttons.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-grid-sidebar-profiles-management',
  templateUrl: './grid-sidebar-profiles-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfileButtonsComponent, AsyncPipe],
})
export class GridSidebarProfilesManagementComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-block';
  private readonly appliedProfile$ = new Subject<ProfileInfo>();

  @Input() targetComponent: string;
  @Input() gridState: GridState;
  @Output() profileApplied = this.appliedProfile$.pipe(map((profile) => profile.data as GridState));

  visibleGridState$: Observable<GridState>;
  currentProfileState$: Observable<{
    isDirty: boolean;
    lastUsedProfile?: ProfileInfo;
  }>;
  readonly ProfileFeature = ProfileFeature;

  private readonly savedProfile$ = new Subject<Profile>();
  private readonly gridState$ = new BehaviorSubject({ columnsState: [] });
  private newProfileModalRef: NgbModalRef;
  private deleteConfirmationModalRef: NgbModalRef;
  private subscription = new Subscription();

  constructor(private profileService: ProfileService) {
    this.visibleGridState$ = this.gridState$.pipe(
      map((gridState) => ({
        columnsState: gridState.columnsState.filter((gridState) => !gridState.hide),
      })),
    );
  }

  ngOnChanges({ gridState }: SimpleChanges): void {
    if (gridState.currentValue) {
      this.gridState$.next(gridState.currentValue);
    }
  }

  ngOnInit() {
    // The count is used to track when the user applies a new profile, even if they're re-applying the current one
    const lastUsedProfileWithCount$: Observable<{ profile: ProfileInfo | null; count: number }> =
      merge(this.appliedProfile$, this.savedProfile$).pipe(
        scan((acc, profile) => ({ profile, count: acc.count + 1 }), { profile: null, count: 0 }),
        filter(({ profile }) => !!profile),
      );

    this.currentProfileState$ = combineLatest([
      lastUsedProfileWithCount$,
      this.visibleGridState$,
    ]).pipe(
      scan(
        (acc, [profileState, gridState]) => ({
          profileState,
          gridState,
          gridStatePristine: profileState.count > acc.profileState.count,
        }),
        {
          profileState: { profile: null as ProfileInfo | null, count: 0 },
          gridState: null,
          gridStatePristine: true,
        },
      ),
      map(({ gridState, profileState, gridStatePristine }) => ({
        lastUsedProfile: profileState.profile,
        isDirty:
          !gridStatePristine &&
          GridSidebarProfilesManagementComponent.gridStateHasChanged(
            gridState,
            profileState.profile.data as GridState,
          ),
      })),
      startWith({ isDirty: false }),
    );
  }

  ngOnDestroy(): void {
    this.savedProfile$.complete();
    this.gridState$.complete();
    this.appliedProfile$.complete();
    this.subscription.unsubscribe();
    if (this.newProfileModalRef) {
      this.newProfileModalRef.dismiss();
    }
    if (this.deleteConfirmationModalRef) {
      this.deleteConfirmationModalRef.dismiss();
    }
  }

  saveRecentChanges(profile: Profile) {
    this.visibleGridState$.pipe(take(1)).subscribe((gridState) => {
      const newProfile: Profile = {
        ...profile,
        feature: ProfileFeature.TABLE_PREFERENCES,
        data: {
          columnsState: gridState?.columnsState ?? [],
        },
      };
      this.profileService.upsertProfile(newProfile);
      this.savedProfile$.next(newProfile);
    });
  }

  applyProfile(profile: ProfileInfo) {
    this.appliedProfile$.next(profile);
  }

  static gridStateHasChanged(previousGridState: GridState, gridState: GridState): boolean {
    if (previousGridState.columnsState.length !== gridState.columnsState.length) {
      return true;
    }

    const objectEquals = (a: Record<string, any>, b: Record<string, any>) => {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) {
        return false;
      }
      return aKeys.every((key) => a[key] === b[key]);
    };

    for (let i = 0; i < previousGridState.columnsState.length; i++) {
      if (!objectEquals(gridState.columnsState[i], previousGridState.columnsState[i])) {
        return true;
      }
    }
    return false;
  }
}
