<mat-form-field class="mid-width">
  <input
    matInput
    type="text"
    name="fileName"
    placeholder="Sequence name"
    (change)="onChangeTextInput($event.target.value)"
    required
    #fileNameInput
    mat-no-asterisk
    data-testid="file-chooser-input"
  />
</mat-form-field>
<button type="button" mat-button (click)="showFileChooser()">
  <input type="file" class="hidden" (change)="onChangeUpload($event.target.value)" #fileInput />
  <i class="material-icons">file_upload</i>
</button>
