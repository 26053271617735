<ng-template #popoverContent>
  <div (mouseenter)="onMouseEnter()" (mouseleave)="popover.close()">
    <p>
      This job has been queued for longer than 5 minutes. This may be due to an unusually high level
      of activity or because your organization has reached its limit for the number of jobs that may
      be running concurrently. Your job will remain queued until capacity is available.
    </p>
    <p class="mb-0">
      See
      <a
        href="https://help.geneiousbiologics.com/hc/en-us/articles/360058793951"
        target="_blank"
        rel="noopener noreferrer"
        >here</a
      >
      for more information
    </p>
  </div>
</ng-template>
@if (showWarning) {
  <fa-icon
    class="text-warning"
    [icon]="faExclamationTriangle"
    [ngbPopover]="popoverContent"
    container="body"
    placement="bottom"
    popoverClass="w-static-3"
    (mouseenter)="popover.open()"
    (mouseleave)="onMouseLeave($event)"
  ></fa-icon>
}
{{ statusMessage }}
