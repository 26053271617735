import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * A custom ngbTooltip with manual triggers and a close button. Used for
 * notifications about jobs and uploads. Once opened, it closes after five
 * seconds, or when the user clicks the `x`.
 */
@Component({
  selector: 'bx-temporary-popup',
  templateUrl: './temporary-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip],
})
export class TemporaryPopupComponent {
  @Input() message: string;
  @ViewChild(NgbTooltip, { static: true }) tooltip: NgbTooltip;

  readonly closeIcon = faTimes;
  private timer: number;

  hidePopup() {
    this.tooltip.close();
  }

  triggerPopup() {
    this.tooltip.open();

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = window.setTimeout(() => {
      this.tooltip.close();
    }, 5000);
  }
}
