import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, groupBy, map, mergeMap, take } from 'rxjs/operators';
import { UserSettingsKinds } from '../user-settings-types';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.store';
import { selectSequenceViewerPreference } from './sequence-viewer-preferences.selectors';
import {
  clearSequencePreferences,
  removeSequencePreferencesOption,
  setAllSequenceViewerPreferences,
  upsertSequencePreferences,
} from './sequence-viewer-preferences.actions';
import { loadInitialUserSettingsSuccess, upsertUserSetting } from '../user-settings.actions';
import { SequenceViewerPreferencesStates } from './sequence-viewer-preferences.model';

@Injectable()
export class SequenceViewerPreferencesEffects {
  /**
   * Updates Sequence Viewer Preference in the User Settings on the server if anything updates.
   */
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertSequencePreferences, removeSequencePreferencesOption),
      groupBy((action) => action.target),
      // Debounce per target actions to reduce unnecessary load
      mergeMap((group) => group.pipe(debounceTime(5000))),
      mergeMap(({ target }) =>
        this.store.select(selectSequenceViewerPreference(target)).pipe(
          take(1),
          map((preferences) =>
            upsertUserSetting({
              kind: UserSettingsKinds.SEQUENCE_VIEWER_PREFERENCES,
              name: target,
              data: preferences,
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * Removes all requested Sequence Viewer Preferences in the User Settings on the server.
   */
  clearSequencePreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearSequencePreferences),
      debounceTime(5000),
      map(({ target }) =>
        upsertUserSetting({
          kind: UserSettingsKinds.SEQUENCE_VIEWER_PREFERENCES,
          name: target,
          data: {},
        }),
      ),
    ),
  );

  setAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialUserSettingsSuccess),
      map(({ sequenceViewerPreferences }) => {
        const sequenceViewerPreferencesStates = sequenceViewerPreferences.reduce((acc, setting) => {
          acc[setting.name] = setting.data;
          return acc;
        }, {} as SequenceViewerPreferencesStates);
        return setAllSequenceViewerPreferences({ sequenceViewerPreferencesStates });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}
}
