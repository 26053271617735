// @ts-ignore
import { GeneticCodes } from '@geneious/shared-constants';

export const TABLE_OPTIONS = Object.keys(GeneticCodes).map((id) => {
  const label = GeneticCodes[id].name;
  return { id, label };
});

export const FRAME_OPTIONS = [
  // `null` is not handled well by mat-select.
  { id: '', label: 'By selection or annotation' },
  { id: '1,2,3,-1,-2,-3', label: 'All frames' },
  { id: '1,2,3', label: 'Forward frames' },
  { id: '-1,-2,-3', label: 'Reverse frames' },
  { id: '1', label: 'Frame 1' },
  { id: '2', label: 'Frame 2' },
  { id: '3', label: 'Frame 3' },
  { id: '-1', label: 'Frame 1 reverse' },
  { id: '-2', label: 'Frame 2 reverse' },
  { id: '-3', label: 'Frame 3 reverse' },
  { id: '1,2', label: 'Frames 1 & 2' },
  { id: '1,3', label: 'Frames 1 & 3' },
  { id: '2,3', label: 'Frames 2 & 3' },
  { id: '-1,-2', label: 'Frames 1 & 2 reverse' },
  { id: '-1,-3', label: 'Frames 1 & 3 reverse' },
  { id: '-2,-3', label: 'Frames 2 & 3 reverse' },
];
