import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { VerifyAuthenticationSuccessActionLabel } from '../../../core/auth/auth.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FeatureSwitchService } from '../feature-switch.service';
import { loadFeatures, loadFeaturesFailure, loadFeaturesSuccess } from './features.actions';
import { of } from 'rxjs';
import { CurrentUserInfo } from '@geneious/nucleus-api-client';

@Injectable()
export class FeaturesEffects {
  loadFeaturesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyAuthenticationSuccessActionLabel),
      map(({ payload }: { payload: { userInfo: CurrentUserInfo } }) =>
        loadFeatures({ organizationID: payload.userInfo.user.organizationID }),
      ),
    ),
  );

  loadFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatures),
      exhaustMap((action) => this.featureSwitchService.getFeatures(action.organizationID)),
      map((features) => {
        const featureState: { [key: string]: boolean } = {};
        features.forEach((feature) => (featureState[feature.code] = feature.enabled));
        return loadFeaturesSuccess({ features: featureState });
      }),
      catchError(() => of(loadFeaturesFailure())),
    ),
  );

  constructor(
    private actions$: Actions,
    private featureSwitchService: FeatureSwitchService,
  ) {}
}
