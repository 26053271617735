<form [formGroup]="form">
  <bx-card headerTitle="Region to Align">
    <div class="mb-3">
      <div class="form-check">
        <input
          type="radio"
          id="alignEntireSequence"
          [value]="false"
          formControlName="alignCds"
          name="alignCds"
          class="form-check-input"
        />
        <label for="alignEntireSequence" class="form-check-label">Entire sequence</label>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-4">
        <div class="form-check">
          <input
            type="radio"
            id="alignCds"
            [value]="true"
            formControlName="alignCds"
            name="alignCds"
            class="form-check-input"
          />
          <label for="alignCds" class="form-check-label">Extract region with name:</label>
        </div>
      </div>
      <div class="col-5">
        <select
          id="useCdsWithName"
          [ngClass]="{ 'is-invalid': (wantsToAlignByCdsButHasNoCdsAnnotations$ | async) }"
          class="form-select"
          formControlName="useCdsWithName"
        >
          @for (region of annotatedRegions$ | async; track region) {
            <option [value]="region.name">
              {{ region.name }} - {{ region.numOfSequences }} sequences
            </option>
          }
        </select>
        @if (!(annotatedRegions$ | async)?.length) {
          <div class="invalid-feedback">No valid annotations found on selected sequences.</div>
        }
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-1"></div>
      <label for="filterByChain" class="col-3 col-form-label"> Filter by: </label>
      <div class="col-5">
        <select formControlName="filterByChain" id="filterByChain" class="form-select">
          @for (chain of chains; track chain) {
            <option [value]="chain.key">
              {{ chain.label }}
            </option>
          }
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Select Anchor Regions">
    <div class="row mb-0">
      <label for="anchorRegions" class="col-4 col-form-label"> With annotation name: </label>
      <div class="col-5">
        <select multiple class="form-select" id="anchorRegions" formControlName="anchorRegions">
          @for (region of annotatedRegions$ | async; track region) {
            <option [value]="region.name">
              {{ region.name }} - {{ region.numOfSequences }} sequences
            </option>
          }
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card
    headerTitle="Translate Nucleotides Sequence(s) Prior to Alignment"
    formGroupName="translation"
  >
    <div class="mb-3 row">
      <label for="geneticCode" class="col-4 col-form-label"> Genetic code: </label>
      <div class="col-5">
        <select formControlName="geneticCode" id="geneticCode" class="form-select">
          @for (code of geneticCodes; track code) {
            <option [value]="code.id">
              {{ code.name }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="considerStart" class="col-4 col-form-label">
        Consider alternative start codons:
      </label>
      <div class="col-5">
        <select formControlName="considerStart" id="considerStart" class="form-select">
          @for (option of considerStartOptions; track option) {
            <option [value]="option.key">
              {{ option.label }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="row mb-0">
      <label for="frame" class="col-4 col-form-label"> Use frame: </label>
      <div class="col-5">
        <select formControlName="frame" id="frame" class="form-select">
          <option [value]="0">1</option>
          <option [value]="1">2</option>
          <option [value]="2">3</option>
        </select>
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
