import { Inject, Injectable } from '@angular/core';
import {
  PREVIEW_MODE_VIEWER_COMPONENTS_TOKEN,
  PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS_TOKEN,
  FULL_VIEW_MODE_VIEWER_COMPONENTS_TOKEN,
} from '../viewers-v2.config';
import { ViewerDocumentData } from '../../viewer-components/viewer-document-data';

@Injectable()
export class ViewersService {
  constructor(
    @Inject(PREVIEW_MODE_VIEWER_COMPONENTS_TOKEN) public previewModeComponents: any[],
    @Inject(PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS_TOKEN)
    public previewModeComplexComponents: any[],
    @Inject(FULL_VIEW_MODE_VIEWER_COMPONENTS_TOKEN) public fullViewModeComponents: any[],
  ) {}

  getViewers(isPreviewMode: boolean, isComplexDocument: boolean = false): any[] {
    if (isPreviewMode && isComplexDocument) {
      return this.previewModeComplexComponents;
    }

    if (isPreviewMode && !isComplexDocument) {
      return this.previewModeComponents;
    }

    return this.fullViewModeComponents;
  }

  verifyValidSelectionForViewers(data: ViewerDocumentData) {
    return this.getViewers(data.isPreviewView, data.containsComplexDocument).some((viewer) => {
      if ('selector' in viewer.viewerComponentMetadata) {
        return viewer.viewerComponentMetadata.selector(data);
      }

      if ('selectors' in viewer.viewerComponentMetadata) {
        return viewer.viewerComponentMetadata.selectors.some((selector: any) => selector(data));
      }
      return false;
    });
  }
}
