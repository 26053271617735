import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { map, of } from 'rxjs';
import { JobDialogContent } from 'src/app/core/dialogV2/jobDialogContent.model';
import { PipelineFormID } from 'src/app/core/pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from 'src/app/core/user-settings/form-state/bx-form-group/bx-form-group';
import { Region } from 'src/nucleus/services/models/alignmentOptions.model';
import {
  ClusterTableRowExtractionMethod,
  ExportJobParameters,
  NGSSequencesExportOptions,
  SequencesOutputType,
  TableOutputType,
} from 'src/nucleus/services/models/exportOptions.model';
import { PipelineDialogData } from '../..';
import { PIPELINE_DIALOG_DATA } from '../../pipeline-dialog-v2/pipeline-dialog-v2';
import { sequencesOutputTypeOptions } from '../export-helpers';
import { RunnableJobDialog } from '../../../dialogV2/runnable-job-dialog';
import { VersionEnum } from '@geneious/nucleus-api-client';
import {
  isClusterTable,
  isComparisonClusterTable,
  isComparisonTableThatSupportsSequenceViewer,
} from 'src/app/core/ngs/table-type-filters';
import { ExportSequencesService } from '../export-sequences/export-sequences.service';
import {
  ExportSequencesDialogData,
  ExtractRegionsValue,
} from '../export-sequences/export-sequences.component';
import { AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from '../../../../shared/card/card.component';
import { DecimalPipe } from '@angular/common';
import { PipelineOutputNameControlComponent } from '../../../pipeline/pipeline-output/pipeline-output-name-control/pipeline-output-name-control.component';

/**
 * JobDialogContent for exporting sequences from the Sequences Table of an
 * Annotated Result. Submits an export job with an exportFormat of "ngsSequences".
 */
@Component({
  selector: 'bx-export-ngs-sequences',
  templateUrl: './export-ngs-sequences-for-editing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ExportSequencesService],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    PipelineOutputNameControlComponent,
    DecimalPipe,
  ],
})
export class ExportNgsSequencesForEditingComponent
  extends JobDialogContent
  implements OnInit, RunnableJobDialog
{
  readonly title = 'Extract Sequences for Editing';
  readonly earlyRelease = false;
  readonly knowledgeBaseArticle?: string = undefined;
  readonly sequenceOutputTypes = sequencesOutputTypeOptions;
  extractRegionOptions: { label: string; value: ExtractRegionsValue }[] = [
    { label: 'Entire Sequence (Not trimmed)', value: 'ENTIRE_SEQUENCE' },
  ];
  extractFromClusterOptions: { label: string; value: ClusterTableRowExtractionMethod }[] = [
    { label: 'All Sequences', value: 'allAssociatedSequences' },
  ];
  readonly form = new BxFormGroup({
    sequenceOptions: new BxFormGroup({
      extractFromCluster: new BxFormControl(this.extractFromClusterOptions[0].value),
      extractRegions: new BxFormControl(this.extractRegionOptions[0].value),
      translate: new BxFormControl(false),
    }),
    outputOptions: new BxFormGroup({
      resultName: JobDialogContent.getResultNameControl(),
      sequencesOutputType: new BxFormControl(this.sequenceOutputTypes[0].value),
      autoDownload: new BxFormControl(true),
      exportMetadataAsTable: new BxFormControl(false),
      tableOutputType: new BxFormControl<TableOutputType>('xlsx'),
      saveAsNewDocument: new BxFormControl(true),
      outputFolderName: JobDialogContent.getResultNameControl(),
    }),
  });
  readonly resultNameControl = this.form.controls.outputOptions.controls.resultName;
  readonly outputFolderNameControl = this.form.controls.outputOptions.controls.outputFolderName;
  /** Template-bound variables for the banner text at the top of the dialog */
  readonly banner = {
    table: this.dialogData.otherVariables.extractionData.table.displayName,
    numRows: this.dialogData.selected.noOfRowsSelected,
  };
  outputFolderNameTooltip = '(Optional) Save document in a new subfolder with the specified name';
  isClusterTableSelected: boolean;
  private formDefaults: unknown;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    readonly dialogData: PipelineDialogData<ExportSequencesDialogData>,
  ) {
    super('export-for-editing', PipelineFormID.EXPORT_FOR_EDITING);
  }

  ngOnInit(): void {
    this.formDefaults = this.form.getRawValue();

    if (this.dialogData.otherVariables.extractionData.isGenericSequenceChainDocument) {
      this.extractRegionOptions = [
        ...this.extractRegionOptions,
        { label: 'Template Region', value: 'Template Region' },
      ];
    } else {
      this.extractRegionOptions = [
        ...this.extractRegionOptions,
        { label: 'VDJ or VJ Region', value: 'VDJ_VJ' },
        { label: 'VDJC or VJC Region', value: 'VDJC_VJC' },
      ];
      if (this.dialogData.otherVariables.extractionData.hasSCFV) {
        this.extractRegionOptions.push({ label: 'ScFv Region', value: 'SCFV' });
      } else {
        this.extractRegionOptions.push({
          label: 'Variable regions including linker (Multichain Region)',
          value: 'MULTI_CHAIN_REGION',
        });
      }
    }
    /*
     * Initialize template-bound variables
     */
    const tableToExtract = this.dialogData.otherVariables.extractionData.table;
    this.isClusterTableSelected =
      isClusterTable(tableToExtract) || isComparisonClusterTable(tableToExtract);

    //Representative Sequence (by Liability Score) is not applicable to comparison tables
    if (!isComparisonTableThatSupportsSequenceViewer(tableToExtract)) {
      this.extractFromClusterOptions.push({
        label: 'Representative Sequence (by Liability Score)',
        value: 'highestLiabilityScore',
      });
    }

    const extractRegionsOption: AbstractControl<ExtractRegionsValue> = this.form.get(
      'sequenceOptions.extractRegions',
    );
    if (!this.extractRegionOptions.some((option) => option.value === extractRegionsOption.value)) {
      extractRegionsOption.setValue(this.extractRegionOptions[0].value);
    }
  }

  run() {
    // Use getRawValue because it includes the value of disabled controls
    const formValue = this.form.getRawValue() as {
      sequenceOptions: {
        extractFromCluster: ClusterTableRowExtractionMethod;
        extractRegions: ExtractRegionsValue;
        translate: boolean;
      };
      outputOptions: {
        resultName?: string;
        sequencesOutputType: SequencesOutputType;
        autoDownload: boolean;
        exportMetadataAsTable: boolean;
        saveAsNewDocument: boolean;
        outputFolderName?: string;
      };
    };

    const { table, tableQuery, selection } = this.dialogData.otherVariables.extractionData;
    const { documentID } = this.dialogData.otherVariables;
    const options: NGSSequencesExportOptions = {
      exportFormat: 'ngsSequences',
      tableName: table.name,
      tableQuery,
      selection,
      sequencesOutputType: formValue.outputOptions.sequencesOutputType,
      resultName: formValue.outputOptions.resultName,
      saveAsNewDocument: formValue.outputOptions.saveAsNewDocument,
      regions: this.getRegionsForOptionValue(formValue.sequenceOptions.extractRegions),
      translate: formValue.sequenceOptions.translate,
    };
    if (this.isClusterTableSelected) {
      options.clusterTableOptions = {
        rowExtractionMethod: formValue.sequenceOptions.extractFromCluster,
      };
    }

    return of(options).pipe(
      map((options) => {
        const parameters: ExportJobParameters = {
          options,
          selection: {
            folderId: this.dialogData.folderID,
            ids: [documentID],
            selectAll: false,
          },
          output: {
            outputFolderName: formValue.outputOptions.outputFolderName,
          },
        };
        return {
          pipeline: { name: 'export', version: VersionEnum.Latest },
          parameters,
        };
      }),
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  private getRegionsForOptionValue(value: ExtractRegionsValue): Region[] {
    switch (value) {
      case 'SCFV':
        return [{ name: 'scFv Region' }];
      case 'MULTI_CHAIN_REGION':
        return [{ name: 'Multi-Chain Region' }];
      case 'VDJ_VJ':
        return [{ name: 'VDJ-REGION' }, { name: 'VJ-REGION' }];
      case 'VDJC_VJC':
        return [{ name: 'VDJC-REGION' }, { name: 'VJC-REGION' }];
      case 'ENTIRE_SEQUENCE':
      default:
        return [];
    }
  }
}
