import { Injectable, NgZone } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TreeItem } from '../folders/models/folder.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GETTING_STARTED_URL } from '../router/app.routing.model';
import { RedirectedURL } from '../auth/redirected-url';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private redirectedURL: RedirectedURL,
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {}

  /**
   * Navigates to a new route corresponding to a folder details view.
   * Once the route is change the selected$ folder in the folder tree will be updated.
   */
  goToFolder(folder: TreeItem) {
    const route = folder.getDetailsRoute();
    this.router.navigate(route, { queryParams: folder.queryParams });
  }

  /**
   * Redirection is a bit of pain, essentially we want to redirect if the following conditions are
   * met:
   *
   * 1. Authentication is successful (this is what triggers the call to this method)
   * 2. A redirect URL (set by a previous session) exists.
   * 3. We are currently on, or navigating to the home/landing page.
   *
   * There's pretty decent test coverage for the redirect behaviour, but be sure to test it with
   * SSO.
   */
  redirectToLastUrl(landingPage: string) {
    const redirectUrl = this.redirectedURL.getRedirectURL();

    // We only want to redirect if we are hitting the landing page.
    // (we don't want to redirect if opening a document in a new tab)
    let currentUrl = this.router.url;

    const inProgressNavigation = this.router.getCurrentNavigation();
    if (inProgressNavigation && inProgressNavigation.finalUrl) {
      currentUrl = inProgressNavigation.finalUrl.toString();
    }

    if (redirectUrl && currentUrl && (currentUrl === '/' || currentUrl === '/' + landingPage)) {
      this.redirectedURL.clearRedirectURL(); // This is to avoid any infinite loops which might be caused if the redirect URL gets redirect to home.
      this.router.navigateByUrl(redirectUrl);
    }
  }

  /**
   * Gets the home URL for the current user. This differs depending on whether
   * the user belongs to a free-tier organization.
   *
   * @returns A single-emission observable containing the home URL.
   */
  getHomeUrl(): Observable<string> {
    return of(GETTING_STARTED_URL);
  }

  /**
   * Maps the URL returned from {@link getHomeUrl} to a UrlTree. Useful for
   * guards implementing CanActivate.
   *
   * @returns A single-emission observable containing the home URL in a UrlTree.
   */
  getHomeUrlTree(): Observable<UrlTree> {
    return this.getHomeUrl().pipe(map((homeUrl) => this.router.createUrlTree([`/${homeUrl}`])));
  }

  /**
   * Navigates to the default page for logged-in users.
   *
   * @returns A cold observable that emits true when navigation succeeds or false if it fails
   */
  goToHome(): Observable<boolean> {
    return this.getHomeUrl().pipe(switchMap((homeUrl) => this.router.navigate([homeUrl])));
  }

  goToLocation(absolutePath: string) {
    if (absolutePath && absolutePath !== '') {
      const path = absolutePath.startsWith('/') ? absolutePath : `/${absolutePath}`;
      return this.navigateByUrl(path);
    } else {
      throw new Error('Cannot navigate to an undefined path');
    }
  }

  /**
   * Need to wrap in ngZone.run() as navigateByUrl() sometimes doesn't invoke Angular Change
   * detection, thus ngOnInit doesn't get called. Unresolved issue here
   * https://github.com/angular/angular/issues/18254 (Closed due to inactivity).
   */
  private navigateByUrl(path: string) {
    this.ngZone.run(() => {
      this.router
        .navigateByUrl(path)
        .then((success) => {
          if (!success) {
            this.goToHome().subscribe();
          }
        })
        .catch(() => this.goToHome().subscribe());
    });
  }
}
