<fieldset class="mb-3 row">
  <div class="col-4 col-lg-3">
    <legend class="col-form-label" data-spec="table-output-type-legend">Table output type:</legend>
  </div>
  <div
    class="col-8 col-lg-4"
    ngbTooltip="The row selection exceeds Excel's maximum row count of {{ maxExcelRows | number }}"
    [disableTooltip]="!disableExcel"
    placement="right"
  >
    <div class="form-check" data-spec="table-output-type-xlsx-option">
      <input
        type="radio"
        id="tableOutputTypeExcel"
        value="xlsx"
        name="tableOutputType"
        [formControl]="tableTypeControl"
        class="form-check-input"
        data-spec="table-output-type-xlsx-input"
        [attr.disabled]="disableExcel ? true : null"
      />
      <label for="tableOutputTypeExcel" class="form-check-label w-100"> Excel (.xlsx) </label>
    </div>
    <div class="form-check" data-spec="table-output-type-csv-option">
      <input
        type="radio"
        id="tableOutputTypeCsv"
        value="csv"
        name="tableOutputType"
        [formControl]="tableTypeControl"
        class="form-check-input"
        data-spec="table-output-type-csv-input"
      />
      <label for="tableOutputTypeCsv" class="form-check-label w-100">
        Comma-Separated Values (.csv)
      </label>
    </div>
  </div>
</fieldset>

<fieldset class="mb-3 row">
  <div class="col-4 col-lg-3">
    <legend class="col-form-label" data-spec="exported-columns-legend">Columns to export:</legend>
  </div>
  <div class="col-8">
    <div class="form-check" data-spec="exported-columns-all-option">
      <input
        type="radio"
        id="exportedColumnsAll"
        value="all"
        [formControl]="exportedColumnsControl"
        name="exportedColumns"
        class="form-check-input"
        data-spec="exported-columns-all-input"
      />
      <label for="exportedColumnsAll" class="form-check-label w-100">
        All (Including hidden)
      </label>
    </div>
    <div class="form-check" data-spec="exported-columns-visible-option">
      <input
        type="radio"
        id="exportedColumnsVisible"
        value="visible"
        name="exportedColumns"
        [formControl]="exportedColumnsControl"
        class="form-check-input"
        data-spec="exported-columns-visible-input"
      />
      <label for="exportedColumnsVisible" class="form-check-label w-100"> Only Visible </label>
    </div>
  </div>
</fieldset>
