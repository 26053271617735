<ng-template #controls let-region="region" let-label="label">
  <div class="d-flex flex-nowrap col-auto">
    <label class="col-form-label pe-2">{{ label }}:</label>
    <input
      type="number"
      [id]="region + 'Start'"
      [formControl]="form.controls[formControlNamePrefix + region + 'Start']"
      ngFormControlValidator
      min="-99"
      max="99"
      class="form-control me-2"
      title="Start"
      [attr.data-spec]="region + '-start-input'"
    />
    <input
      type="number"
      [id]="region + 'End'"
      [formControl]="form.controls[formControlNamePrefix + region + 'End']"
      ngFormControlValidator
      min="-99"
      max="99"
      class="form-control"
      title="End"
      [attr.data-spec]="region + '-end-input'"
    />
  </div>
</ng-template>

<label
  class="form-label pe-2"
  ngbTooltip="Allows the adjustment of start and/or end positions of Heavy FR annotations by the specified number of amino acids."
  placement="right"
>
  Heavy adjustment:
</label>
<div class="row ps-sm-1 ps-lg-3 gx-3 gy-2 mb-2">
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR1', region: 'HeavyFR1' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR2', region: 'HeavyFR2' }"
  ></ng-container>
</div>
<div class="row ps-sm-1 ps-lg-3 gx-3 gy-2 mb-3">
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR3', region: 'HeavyFR3' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR4', region: 'HeavyFR4' }"
  ></ng-container>
</div>

<label
  class="form-label pe-2"
  ngbTooltip="Allows the adjustment of start and/or end positions of Light FR annotations by the specified number of amino acids."
  placement="right"
>
  Light adjustment:
</label>
<div class="row ps-sm-1 ps-lg-3 gx-3 gy-2 mb-2">
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR1', region: 'LightFR1' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR2', region: 'LightFR2' }"
  ></ng-container>
</div>
<div class="row ps-sm-1 ps-lg-3 gx-3 gy-2">
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR3', region: 'LightFR3' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="controls; context: { label: 'FR4', region: 'LightFR4' }"
  ></ng-container>
</div>
