@if (
  {
    swatchColor: swatchColor$ | async,
    hasNameError: hasNameError$ | async,
  };
  as vm
) {
  <form [formGroup]="form" class="d-flex align-items-center" data-spec="label-creator-form">
    <div class="input-group has-validation flex-grow-1">
      <button
        class="btn"
        data-spec="label-color-swatch"
        title="Set new label color"
        type="button"
        [ngStyle]="{ 'background-color': vm.swatchColor }"
        (click)="colorInput.click()"
      >
        <input
          #colorInput
          id="color-input"
          type="color"
          formControlName="color"
          class="w-25 h-25 invisible"
          data-spec="label-color-input"
        />
      </button>
      <input
        #nameInput
        id="name-input"
        type="text"
        class="form-control"
        title="New label name"
        placeholder="New Label"
        formControlName="name"
        aria-required="true"
        [ngClass]="{ 'is-invalid': vm.hasNameError }"
        data-spec="label-name-input"
      />
      <button
        title="Create new label"
        type="submit"
        class="btn btn-outline-primary create-btn"
        (click)="create()"
        data-spec="label-creator-submit"
      >
        Create
      </button>
      <bx-form-errors
        [control]="form.controls.name"
        [showAllErrors]="false"
        data-spec="label-creator-error-msg"
      ></bx-form-errors>
    </div>
  </form>
}
