@if (displayedViewerComponents$ | async; as displayedViewerComponents) {
  <div class="d-flex flex-column position-relative h-100 pt-1">
    <ul
      ngbNav
      #nav="ngbNav"
      class="nav-tabs flex-grow-0 flex-shrink-0"
      [activeId]="activeTabID"
      [destroyOnHide]="true"
      (navChange)="onTabChange($event)"
      [hidden]="displayedViewerComponents.length === 0"
    >
      @for (
        viewerComponent of displayedViewerComponents;
        track trackByFn($index, viewerComponent)
      ) {
        <li [ngbNavItem]="viewerComponent.key" [id]="viewerComponent.key">
          <a ngbNavLink>{{ viewerComponent.title }}</a>
          @if (viewersCached$ | async; as viewersCached) {
            <ng-template ngbNavContent>
              @if (viewersCached[viewerComponent.key]) {
                <ng-container *ngComponentOutlet="viewerComponent.component"></ng-container>
              }
              <bx-overlays [key]="viewerComponent.key"></bx-overlays>
            </ng-template>
          }
        </li>
      }
    </ul>
    <div [ngbNavOutlet]="nav"></div>
    <ng-content></ng-content>
    <div class="viewer-options">
      @if (displayedViewerComponents.length > 0) {
        <ng-content select="bx-viewers-options"></ng-content>
      }
    </div>
    @if (overlay$ | async; as overlay) {
      <div
        class="h-100 w-100 flex-grow-1 flex-shrink-1 overflow-hidden d-flex justify-content-center align-items-center"
        data-spec="viewers-overlay"
      >
        <div class="text-center text-muted" [innerHTML]="overlay"></div>
      </div>
    }
  </div>
}
