import { Injectable } from '@angular/core';
import { Folder } from '../../folders/models/folder.model';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { FolderService } from '../../folders/folder.service';
import { FolderUtils } from '../../folders/folder.utils';

@Injectable({
  providedIn: 'root',
})
export class FolderMovingService {
  draggingFolder$: ReplaySubject<Folder | undefined> = new ReplaySubject<Folder>(1);

  constructor(private folderService: FolderService) {}

  isFolderImmutable(folder: Folder): Observable<boolean> {
    return this.draggingFolder$.pipe(
      switchMap((draggingFolder) => {
        if (!draggingFolder) {
          return of(false);
        }
        if (!FolderUtils.isFolder(folder) && !FolderUtils.isSharedWorkspaceFolder(folder)) {
          return of(true);
        }
        if (draggingFolder.id === folder.id) {
          return of(true);
        }
        return this.isChildrenOfFolder(folder, draggingFolder);
      }),
    );
  }

  setDraggingFolder(folder: Folder) {
    this.draggingFolder$.next(folder);
  }

  clearDraggingFolder() {
    this.draggingFolder$.next(undefined);
  }

  private isChildrenOfFolder(child: Folder, parent: Folder): Observable<boolean> {
    if (!child.parentID) {
      return of(false);
    }

    if (child.parentID === parent.id) {
      return of(true);
    }

    return this.folderService.get(child.parentID).pipe(
      first(),
      switchMap((childParent) => this.isChildrenOfFolder(childParent as Folder, parent)),
    );
  }
}
