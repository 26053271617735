import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SequenceViewerService } from '../../sequence-viewer.service';
import { SortCriteria } from '@geneious/sequence-viewer/types';
import SortingPlugin from '@geneious/sequence-viewer/plugins/Sorting/SortingPlugin';
import { FormsModule } from '@angular/forms';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'sv-sort',
  templateUrl: './sort.component.html',
  standalone: true,
  imports: [FormsModule, FaIconComponent],
})
export class SortComponent {
  @HostBinding('class') readonly hostClass = 'd-flex';
  @Input() criteria: SortCriteria;
  @Output() sortChanged = new EventEmitter<SortCriteria>();
  @Output() criteriaRemoved = new EventEmitter();

  constructor(public sequenceViewerService: SequenceViewerService) {}

  get showAlignmentControls() {
    return this.sequenceViewerService.showAlignmentControls;
  }

  remove() {
    this.criteriaRemoved.emit();
  }

  get sortingColumns() {
    const columns = [];
    if (!this.criteria.type) {
      columns.push({ name: '', label: '' });
    }

    if (this.showAlignmentControls) {
      if (this.savedSortingPosition !== undefined) {
        columns.push({
          name: 'sortbyposition',
          label: `Residue at position ${this.savedSortingPosition + 1}`,
        });
      }
      if (
        this.savedSortingPosition !== this.newSortingPosition &&
        this.notSortingOn('position', this.newSortingPosition)
      ) {
        columns.push({
          name: 'sortbyposition',
          label: `Residue at selected position ${this.newSortingPosition + 1}`,
        });
      }
    }

    return columns.concat(
      this.sequenceViewerService.sortedColumns.filter((column) => {
        return this.criteria.type === 'metadata' && this.criteria.value === column.name
          ? true
          : this.notSortingOn('metadata', column.name);
      }),
    );
  }

  private notSortingOn(type: any, value: any) {
    return !this.sorting.currentSort.find((criterion) => {
      return criterion.type === type && criterion.value === value;
    });
  }

  get currentSort() {
    if (this.criteria.type === 'position') {
      return 'sortbyposition';
    }
    return this.criteria.value;
  }

  set currentSort(raw) {
    if (raw === '') {
      return;
    } else if (raw === 'sortbyposition') {
      this.criteria = {
        type: 'position',
        value: this.newSortingPosition,
        ascending: this.criteria.ascending,
      };
    } else {
      this.criteria = {
        type: 'metadata',
        value: raw,
        ascending: this.criteria.ascending,
      };
    }

    this.sortChanged.emit(this.criteria);
  }

  get sortAscending() {
    return this.criteria.ascending === false ? 'descending' : 'ascending';
  }

  set sortAscending(raw) {
    this.criteria.ascending = raw === 'ascending';
    this.sortChanged.emit(this.criteria);
  }

  get savedSortingPosition() {
    if (this.criteria.type === 'position') {
      return Number(this.criteria.value);
    }
  }

  get newSortingPosition() {
    if (this.selectionAnchor === undefined) {
      // User has not set an anchor.
      return 0;
    } else if (this.endAnchor && this.endAnchor < this.selectionAnchor) {
      // Left selection.
      return this.selectionAnchor - 1;
    } else {
      // No selection or right selection.
      return this.selectionAnchor;
    }
  }

  get selectionAnchor() {
    const selection = this.sequenceViewerService.selection;
    return selection && selection.anchor;
  }

  get endAnchor() {
    const selection = this.sequenceViewerService.selection;
    return selection && selection.endAnchor;
  }

  get sorting(): SortingPlugin {
    return this.sequenceViewerService.sorting;
  }
}
