<bx-collapsible-card
  headerTitle="Summary"
  (collapseChange)="collapsed$.next($event)"
  [compact]="true"
>
  <div class="p-2">
    @if (summary$ | async; as summary) {
      <dl class="row mt-2 mb-0 mx-auto">
        <dt class="col-md-4 col-sm-6">File Type</dt>
        <dd class="col-md-8 col-sm-6" data-spec="summary-document-type">
          @if (summary.documentType) {
            <span>{{ summary.documentType }}</span>
          } @else {
            <bx-spinner></bx-spinner>
          }
        </dd>
        <dt class="col-md-4 col-sm-6">Created At</dt>
        <dd class="col-md-8 col-sm-6" data-spec="summary-created">
          @switch (summary.createdAt) {
            @case (undefined) {
              <ng-template [ngTemplateOutlet]="loading"></ng-template>
            }
            @case (DATA_MISSING) {
              <span
                title="Document creation date was not recorded before August 2019"
                class="text-muted"
              >
                Not Found
              </span>
            }
            @default {
              <span>{{ summary.createdAt | date: 'medium' }}</span>
            }
          }
          @if (summary.createdBy) {
            <span>&ensp;•&ensp;{{ summary.createdBy }}</span>
          }
        </dd>
        <dt class="col-md-4 col-sm-6">Last Modified At</dt>
        <dd class="col-md-8 col-sm-6" data-spec="summary-modified">
          @if (summary.modifiedAt) {
            <span>{{ summary.modifiedAt | date: 'medium' }}</span>
            @if (summary.modifiedBy) {
              <span>&ensp;•&ensp;{{ summary.modifiedBy }}</span>
            }
          } @else {
            <bx-spinner></bx-spinner>
          }
        </dd>
      </dl>
    }

    <ng-template #loading>
      <bx-spinner></bx-spinner>
    </ng-template>
  </div>
</bx-collapsible-card>
