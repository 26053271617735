@if (selectedLabels$ | async; as selectedLabels) {
  <div class="modal-header">
    <h4 class="modal-title">Bulk Add Labels</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body pb-0">
    @if (totalRowsSelected; as numRows) {
      <div class="alert alert-primary mb-0" role="alert" data-spec="bulk-labels-info">
        Adding labels to {{ numRows }} row{{ numRows === 1 ? '' : 's' }} of the
        {{ documentTable.displayName }} table
      </div>
    }
    <div class="label-container d-flex flex-wrap m-2">
      @if (selectedLabels.length > 0) {
        @for (label of selectedLabels; track label) {
          <bx-label-badge
            [labelName]="label.name"
            [colorHex]="label.color"
            (removeLabel)="deselectLabel(label.id)"
            class="me-2 my-2"
            data-spec="selected-label"
          ></bx-label-badge>
        }
      } @else {
        <div class="d-flex align-items-center" data-spec="no-labels-selected">
          <i class="text-muted">No labels selected</i>
        </div>
      }
    </div>
    <bx-label-multi-select
      #labelPicker
      class="w-100 mt-2 mb-3"
      (selectedLabels)="setSelectedLabels($event)"
      data-spec="label-picker"
    ></bx-label-multi-select>
  </div>
  @if (submitButton$ | async; as submitButton) {
    <div class="modal-footer">
      <div [ngbTooltip]="submitButton.tooltip" [disableTooltip]="!submitButton.disable">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="submitButton.disable"
          (click)="submit()"
          data-spec="submit-btn"
        >
          <span [ngPlural]="selectedLabels.length">
            <ng-template ngPluralCase="=0">Add Labels</ng-template>
            <ng-template ngPluralCase="=1">Add Label</ng-template>
            <ng-template ngPluralCase="other">Add {{ selectedLabels.length }} Labels</ng-template>
          </span>
        </button>
      </div>
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="dismiss()"
        data-spec="cancel-btn"
      >
        Cancel
      </button>
    </div>
  }
}
