import { Component, Input } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { isNullOrUndefined } from '../../../bx-common-extensions/is-null-or-undefined';
import { first, switchMap } from 'rxjs/operators';
import { UsersService } from '../users/users.service';
import { Observable } from 'rxjs';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectUserID } from '../auth/auth.selectors';
import { ErrorFormatter } from '../error-formatter.service';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';

import { NgFormControlValidatorDirective } from '../../shared/form-helpers/ng-form-control-validator.directive';
import { PasswordStrengthComponent } from '../../shared/password-strength/password-strength.component';
import { ValidatePasswordDirective } from '../../shared/password-strength/validate-password.directive';
import { ValidateEqualDirective } from '../../shared/equals/validate-equal.directive';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-password-changer',
  templateUrl: './password-changer.component.html',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    PasswordStrengthComponent,
    ValidatePasswordDirective,
    ValidateEqualDirective,
    SpinnerButtonComponent,
    RouterLink,
  ],
})
export class PasswordChangerViewComponent {
  @Input() cancelAction: Function;

  waiting = false;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  displayCancelButton: boolean;

  isApiKeysFeatureEnabled$: Observable<boolean>;

  changePasswordResponse: { success?: string; error?: string } = {};

  constructor(
    private store: Store<AppState>,
    private usersService: UsersService,
    private errorFormatter: ErrorFormatter,
  ) {
    this.displayCancelButton = !isNullOrUndefined(this.cancelAction);
  }

  submit(changePasswordForm: NgForm) {
    if (changePasswordForm.valid) {
      if (this.currentPassword && this.newPassword) {
        this.waiting = true;
        this.changePasswordResponse = {}; // wipe responses from the previous round

        this.store
          .pipe(
            select(selectUserID),
            first(),
            switchMap((userID) =>
              this.usersService.changePassword(userID, this.currentPassword, this.newPassword),
            ),
          )
          .subscribe({
            next: () => {
              this.changePasswordResponse.success = 'Password successfully changed.';
              this.waiting = false;
            },
            error: (error) => {
              this.waiting = false;
              this.changePasswordResponse.error = this.errorFormatter.toUserReadableError(
                error,
                'Password could not be changed.',
              );
            },
          });
      }
    }
  }
}
