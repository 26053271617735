import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FileReadDirective } from './file-reader/file-read.directive';

@Component({
  selector: 'bx-file-button',
  templateUrl: './file-button.component.html',
  standalone: true,
  imports: [FileReadDirective],
})
export class FileButtonComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() icon: string;
  @Input() message: string;
  @Input() fileTypes: string[];
  @Input() multiple = false;
  @Input() btnClass = 'btn btn-light ba-icon ba-icon-upload mat-fab mat-primary mat-mini';
  @Input() inputID = 'files';
  @Output() newFiles: EventEmitter<File[]> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.message = this.message || 'Browse';
  }

  onFilesRead(files: File | FileList) {
    if (files instanceof FileList) {
      this.newFiles.emit(Array.from(files));
    } else {
      this.newFiles.emit([files]);
    }
  }

  forward() {
    this.trigger(this.elementRef.nativeElement.querySelector('input[type="file"]'), 'click');
  }

  trigger(element: any, type: any) {
    let event;

    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      const cancellable = true;
      const bubbles = false;
      event.initEvent(type, bubbles, cancellable);
    } else {
      interface Document {
        createEventObject?: Function;
      }
      event = (document as Document).createEventObject();
      event.eventType = type;
    }

    event.eventName = type;

    if (element.dispatchEvent) {
      // IE fails on `new MouseEvent`, but there doesn't seem to be an obvious way to sniff for that.
      // So instead, try it, and if it fails, dispatch the event in an IE friendly manner.
      // Unfortunately the IE friendly approach doesn't work on Chrome.
      try {
        element.dispatchEvent(
          new MouseEvent(type, {
            // Important to disable `bubbles` to prevent recursive calling.
            bubbles: false,
            cancelable: true,
          }),
        );
      } catch (ex) {
        element.dispatchEvent(event);
      }
    } else {
      element.fireEvent('on' + type, event);
    }
  }
}
