import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterDatabaseService } from '../master-database.service';
import { AnnotatedPluginDocument } from '../../geneious';

export function masterDatabaseSequenceTypeValidator(
  importSequencesResultDocument: AnnotatedPluginDocument,
  masterDatabaseService: MasterDatabaseService,
): AsyncValidatorFn {
  return function (control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      const invalidAlphabetMessage =
        importSequencesResultDocument.getAllFields().molType === 'AA' ||
        importSequencesResultDocument.getAllFields().proteinSequenceCount > 0
          ? 'Cannot add protein sequences to a nucleotide collection'
          : 'Cannot add nucleotide sequences to a protein collection';
      return masterDatabaseService
        .validateSequenceTypeFromAnnotatedSequences(control.value, importSequencesResultDocument)
        .pipe(
          map((validImportMatch) => {
            if (validImportMatch.matchesAntibodyType && validImportMatch.matchesAlphabet) {
              return null;
            } else if (!validImportMatch.matchesAntibodyType && !validImportMatch.matchesAlphabet) {
              return {
                invalidDatabaseMatch: `${invalidAlphabetMessage}. Sequences do not match Collection's Antibody Type`,
              };
            } else if (!validImportMatch.matchesAlphabet) {
              return { invalidDatabaseMatch: `${invalidAlphabetMessage}` };
            } else {
              return { invalidDatabaseMatch: `Sequences do not match Collection's Antibody Type` };
            }
          }),
        );
    } else {
      return of(null);
    }
  };
}
