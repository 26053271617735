<div class="modal-header border-bottom-0">
  <h4 class="modal-title">Add Assay Data</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="close()"></button>
</div>

@if (stepper) {
  <bx-stepper-steps
    [steps]="steps$ | async"
    [selectedIndex]="stepper.selectedIndex"
    color="secondary"
  >
  </bx-stepper-steps>
}

<bx-stepper linear>
  <bx-step label="Select File">
    <bx-assay-data-file-picker
      (newFiles)="selectedFiles$.next($event)"
      (fetchStart)="handleFetchStart()"
      (fetchEnd)="handleFetchEnd()"
    >
    </bx-assay-data-file-picker>
    <div class="modal-footer">
      @if ({ value: isFetchingAssayData$ | async }; as isFetchingAssayData) {
        <button
          class="btn btn-primary"
          type="button"
          bx-spinner-button
          [disabled]="!((selectedFiles$ | async).length > 0)"
          [spin]="isFetchingAssayData.value"
          (click)="nextStep()"
        >
          {{ isFetchingAssayData.value ? 'Fetching Assay Data' : 'Next' }}
        </button>
      }
      <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    </div>
  </bx-step>
  <bx-step
    label="Add Assay Data"
    [disabled]="(selectedFiles$ | async).length < 1"
    disabledTooltip="Add assay data step is only enabled once you've selected an assay data file"
  >
    @if (stepper.selectedIndex === 1) {
      <bx-assay-data-add-form
        [document]="document"
        [documentTable]="documentTable"
        [resource]="resource"
        [files]="selectedFiles$ | async"
      ></bx-assay-data-add-form>
    }
  </bx-step>
</bx-stepper>
