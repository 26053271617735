<form [formGroup]="form">
  <p class="text-muted px-2">
    The Protein and Peptide annotators can be used to annotate large datasets, cluster duplicates
    and highlight liabilities. They support both shorter nucleotides, or peptides, as well as
    annotating larger protein sequences.
  </p>

  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label
        class="col-4 col-form-label"
        for="database_customDatabase"
        ngbTooltip="Select 1 or more general template databases"
      >
        Reference database(s):
        <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069531">
          <mat-icon class="icon-small">help</mat-icon>
        </a>
      </label>
      <div class="col">
        <bx-multi-select
          id="database_customDatabase"
          formControlName="database_customDatabase"
          [selectOptions]="referenceDatabases$ | async"
          entityName="reference database"
          [showMultiple]="multipleRefDbsEnabled$ | async"
          ngFormControlValidator
        >
          <bx-no-general-template-database-message
            bxCustomEmptyMessage
          ></bx-no-general-template-database-message>
        </bx-multi-select>
        <bx-form-errors [control]="form.get('database_customDatabase')"></bx-form-errors>
      </div>
    </div>

    <bx-name-scheme-select
      *bxShowIf="'nameSchemes'"
      [parent]="form"
      controlName="fileNameSchemeID"
      [associatedNameSchemes]="associatedNameSchemes"
      [colClasses]="{ label: 'col-4', control: 'col' }"
    ></bx-name-scheme-select>
  </bx-card>

  <bx-collapsible-card headerTitle="Analysis Options" [collapseByDefault]="true">
    <div class="mb-3 row">
      <label for="sequences_addNumberingAnnotations" class="col-4 col-form-label">
        Numbering Annotations:
      </label>
      <div class="col">
        <select
          formControlName="sequences_addNumberingAnnotations"
          id="sequences_addNumberingAnnotations"
          class="form-select"
        >
          <option [value]="true">Simple Numbering</option>
          <option [value]="false">No Numbering</option>
        </select>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="genes_annotateGermlineGeneDifferences"
        formControlName="genes_annotateGermlineGeneDifferences"
        class="form-check-input"
      />
      <label
        for="genes_annotateGermlineGeneDifferences"
        ngbTooltip="Selecting this option will make Antibody Annotator annotate how each query sequence differs from its best match in the reference database. Differences will be annotated for differing nucleotides and amino acids. Slows analysis with large datasets."
        class="form-check-label"
      >
        Annotate variants from reference database
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="results_calculateProteinStatistics"
        formControlName="results_calculateProteinStatistics"
        class="form-check-input"
      />
      <label
        for="results_calculateProteinStatistics"
        ngbTooltip="Calculate protein statistics such as Molecular Weight and Isoelectric Point for the VJ, VDJ and scFv regions."
        class="form-check-label"
      >
        Calculate protein statistics
      </label>
    </div>

    <div class="row align-items-center mb-0">
      <div class="col-4 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="findLiabilities"
            formControlName="findLiabilities"
            class="form-check-input"
          />
          <label
            for="findLiabilities"
            ngbTooltip="Search and score motifs liable to post-translational modifications or any other types of modifications or beneficial motifs. Slows analysis with large datasets."
            class="form-check-label"
          >
            Find liabilities and assets:
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070271"
            >
              <mat-icon class="icon-small">help</mat-icon>
            </a>
          </label>
        </div>
      </div>
      <div class="col">
        <textarea formControlName="liabilities_liabilitiesText" rows="3" class="form-control">
        </textarea>
      </div>
    </div>
  </bx-collapsible-card>

  <bx-clustering-options-card filterRegionsFor="genericSequence" [collapseByDefault]="true">
  </bx-clustering-options-card>

  <bx-collapsible-card headerTitle="Advanced Options" [collapseByDefault]="true">
    <div class="mb-3 row">
      <label
        for="sequences_queryGeneticCode"
        class="col-4 col-form-label"
        ngbTooltip="The genetic code to use for translating nucleotide sequences. Codes obtained from NCBI."
      >
        Genetic code:
      </label>
      <div class="col-8">
        <select
          id="sequences_queryGeneticCode"
          formControlName="sequences_queryGeneticCode"
          class="form-select"
        >
          @for (geneticCode of geneticCodes$ | async; track geneticCode) {
            <option [value]="geneticCode.value">
              {{ geneticCode.displayName }}
            </option>
          }
        </select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label
        for="genes_ambiguousGenesStrategy"
        class="col-4 col-form-label"
        ngbTooltip="How to handle cases where query sequences are equally close to multiple reference genes."
      >
        Record equal reference sequence match as:
      </label>
      <div class="col-8">
        <select
          formControlName="genes_ambiguousGenesStrategy"
          id="genes_ambiguousGenesStrategy"
          class="form-select"
        >
          <option value="partialFrequency">
            Each sequence with partial frequency (e.g. frequency of 0.5 for each sequence)
          </option>
          <option value="groupOfGenes">
            Group of sequences (e.g. assigned to a cluster called IGHD1-26/IGHD2-15)
          </option>
          <option value="unknown">
            Unknown (treat it the same as if it didn't match any sequence)
          </option>
        </select>
      </div>
    </div>

    <div class="row gx-0 mb-0">
      <div class="col-auto d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="trimSequences"
            formControlName="results_trimSequences"
            class="form-check-input"
          />
          <label
            for="trimSequences"
            ngbTooltip="Select this option to trim a specified number of bases upstream and downstream in fully annotated query sequences."
            class="form-check-label"
          >
            Trim each side of fully annotated region if over:
          </label>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center ms-3">
        <input
          type="number"
          id="trimSequencesLength"
          formControlName="results_trimSequencesLength"
          ngFormControlValidator
          min="0"
          max="9999"
          class="form-control me-2"
        />
        <label for="trimSequencesLength" class="col-form-label text-nowrap">base pairs</label>
        <div class="invalid-feedback">Base pairs must be between 0 and 9999</div>
      </div>
    </div>
  </bx-collapsible-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
