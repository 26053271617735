import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';

/**
 * Redirects to the home page for logged-in users, which differs depending on the
 * user's organization profile category.
 */
@Injectable({
  providedIn: 'root',
})
export class HomeRedirectGuard {
  constructor(private readonly navigationService: NavigationService) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.navigationService.getHomeUrlTree();
  }
}
