import { ICellRendererParams } from '@ag-grid-community/core';
import { Job } from '../../../../../nucleus/services/models/job.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  ViewChild,
} from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';

/**
 * Handles rendering Job Status in the jobs table.
 */
@Component({
  selector: 'bx-jobs-status-renderer',
  templateUrl: './job-status-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, NgbPopover],
})
export class JobStatusRendererComponent implements AgRendererComponent {
  @ViewChild(NgbPopover) popover: NgbPopover;

  readonly faExclamationTriangle = faExclamationTriangle;
  statusMessage: string;
  showWarning = false;

  private readonly MAX_JOB_QUEUE_TIME = 5 * 60000; // 5 minutes in milliseconds
  private job: Job | undefined;
  private params: ICellRendererParams;
  private queueCheckTimerRef: any;
  private popoverCloseTimerRef: any;

  constructor(
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  agInit(params: ICellRendererParams) {
    this.job = params.data;
    this.params = params;
    this.statusMessage = this.params.value;

    if (this.job?.status?.kind === 'Queued') {
      this.showWarningIfJobQueuedTooLong();
    }
  }

  onDestroy() {
    if (this.queueCheckTimerRef) {
      clearTimeout(this.queueCheckTimerRef);
    }
    if (this.popoverCloseTimerRef) {
      clearTimeout(this.popoverCloseTimerRef);
    }
  }

  onMouseEnter() {
    clearTimeout(this.popoverCloseTimerRef);
  }

  // Close popover if the user doesn't move their mouse down into the popover from the arrow.
  onMouseLeave(event: MouseEvent) {
    const relatedTarget: HTMLElement = event.relatedTarget as HTMLElement;
    this.popoverCloseTimerRef = setTimeout(() => {
      if (!relatedTarget.className.includes('arrow')) {
        this.popover.close();
      }
    }, 100);
  }

  refresh(params: ICellRendererParams): boolean {
    this.job = params.data;
    this.params = params;
    this.statusMessage = this.params.value;

    if (this.job?.status?.kind !== 'Queued' && this.queueCheckTimerRef) {
      clearTimeout(this.queueCheckTimerRef);
    }
    return true;
  }

  private getRemainingJobQueueTime(): number {
    return Math.max(
      this.MAX_JOB_QUEUE_TIME -
        (new Date().getTime() - new Date(this.job?.status?.dateTime).getTime()),
      0,
    );
  }

  private showWarningIfJobQueuedTooLong() {
    const remainingJobQueueTime = this.getRemainingJobQueueTime();
    if (remainingJobQueueTime > 0) {
      this.ngZone.runOutsideAngular(() => {
        // Wait till queue time has taken too long to update status with warning.
        this.queueCheckTimerRef = setTimeout(() => {
          this.showWarning = true;
          this.statusMessage = this.params.value;
          this.cd.markForCheck();
        }, this.getRemainingJobQueueTime());
      });
    } else {
      this.showWarning = true;
    }
  }
}
