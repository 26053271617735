@if (
  {
    isLoading: isLoading$ | async,
    sequences: sequences$ | async,
    messages: messages$ | async,
    showSequenceViewer: showSequenceViewer$ | async,
    preferences: sequenceViewerPreferencesState$ | async,
  };
  as state
) {
  @if (state.isLoading === false && state.messages) {
    <bx-page-message class="messages">
      <span>{{ state.messages }}</span>
    </bx-page-message>
  }
  @if (state.isLoading && !state.messages) {
    <div class="w-100 flex-grow-1 flex-shrink-1 d-flex justify-content-center align-items-center">
      <bx-loading></bx-loading>
    </div>
  }
  @if (!state.isLoading && state.showSequenceViewer && !state.messages) {
    <bx-sequence-viewer
      [metadataColumns]="metadataColumns$ | async"
      [metadataColumnOrder]="columnIDs$ | async"
      [sequences]="state.sequences"
      [documentsType]="(isAlignment$ | async) ? 'Alignment' : 'NGS'"
      [sortingEnabled]="gotAllSequences$ | async"
      [savedOptions]="state.preferences"
      [toolbarWarningMsg]="toolbarWarningMsg$ | async"
      (optionChanged)="optionChanged($event)"
      (optionRemoved)="optionRemoved($event)"
      (liabilityThresholdChanged)="liabilityScoreThresholdChanged($event)"
      class="w-100 flex-grow-1 flex-shrink-1 overflow-hidden"
    >
      <span
        [ngbTooltip]="
          'Sequence View image export is only supported for a maximum of 200 sequences.'
        "
        placement="top"
        container="body"
        [disableTooltip]="(exportAsImageButtonDisabled$ | async) !== true"
      >
        <button
          class="btn btn-light"
          bxSequenceViewerExportButton
          [viewerData]="viewerData$ | async"
          [disabled]="exportAsImageButtonDisabled$ | async"
        >
          Export As Image
        </button>
      </span>
    </bx-sequence-viewer>
  }
}
