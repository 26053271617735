<div class="alert alert-info">
  {{ exportMessage }}
</div>

<form name="exportNgsSequencesForm" [formGroup]="form">
  <bx-card headerTitle="Sequence Options" formGroupName="sequenceOptions">
    @if (!isExtractOptionsHidden) {
      <div class="mb-3 row">
        <label for="extractSequences" class="col-lg-3 col-sm-4 col-form-label">
          {{ extractOptionsLabel }}:
        </label>
        <div class="col-lg-6 col-sm-8">
          <select
            class="form-select"
            id="extractSequences"
            formControlName="extractSequences"
            data-spec="export-extract-from-cluster"
            ngFormControlValidator
          >
            @for (option of extractSequencesOptions; track option) {
              <option [value]="option.value">
                {{ option.label }}
              </option>
            }
          </select>
          <bx-form-errors
            [control]="form.controls.sequenceOptions.controls.extractSequences"
          ></bx-form-errors>
        </div>
      </div>
    }

    <div class="mb-3 row">
      <label for="extractRegions" class="col-lg-3 col-sm-4 col-form-label">
        Trim sequences to:
      </label>
      <div class="col-lg-6 col-sm-8">
        <select
          class="form-select"
          id="extractRegions"
          formControlName="extractRegions"
          data-spec="export-extract-regions"
          [attr.disabled]="(trimSequencesDisabled$ | async) ? true : null"
        >
          @for (option of extractRegionOptions; track option) {
            <option [value]="option.value">
              {{ option.label }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        id="translate"
        formControlName="translate"
        class="form-check-input"
        data-spec="export-translate"
        [attr.disabled]="(translateSequencesDisabled$ | async) ? true : null"
      />
      Translate output
      <label for="translate" class="form-check-label"> </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0" formGroupName="outputOptions">
    <bx-pipeline-output-name-control
      [nameFormControl]="resultNameControl"
      inputID="result-name"
      displayName="File"
      tooltip="The name of the exported sequences document"
      labelContainerClass="col-lg-3 col-sm-4"
      inputContainerClass="col-lg-6 col-sm-8"
      class="mb-3"
    ></bx-pipeline-output-name-control>

    <div class="mb-3 row">
      <label for="sequencesOutputType" class="col-lg-3 col-sm-4 col-form-label"> File type: </label>
      <div class="col-lg-6 col-sm-8">
        <select
          class="form-select"
          id="sequencesOutputType"
          formControlName="sequencesOutputType"
          data-spec="export-sequences-output-type"
        >
          @for (outputFormat of sequenceOutputTypes; track outputFormat) {
            <option [value]="outputFormat.value">
              {{ outputFormat.label }}
            </option>
          }
        </select>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="autoDownload"
        formControlName="autoDownload"
        class="form-check-input"
        data-spec="export-auto-download"
      />
      <label for="autoDownload" class="form-check-label">
        Automatically download exported document
      </label>
    </div>

    @if (!this.isComparisonTable) {
      <div class="mb-3 form-check">
        <input
          type="checkbox"
          id="exportMetadataAsTable"
          formControlName="exportMetadataAsTable"
          class="form-check-input"
          data-spec="export-metadata-as-table"
          [attr.disabled]="(exportMetadataDisabled$ | async) ? true : null"
        />
        <label for="exportMetadataAsTable" class="form-check-label">
          Also export sequence metadata (Excel)
        </label>
      </div>
    }

    <div class="form-check mb-3">
      <input
        type="checkbox"
        id="saveAsNewDocument"
        formControlName="saveAsNewDocument"
        class="form-check-input"
        value=""
        data-spec="export-save-as-new-result"
      />
      <label for="saveAsNewDocument" class="form-check-label">
        Extract sequences to a new document in Geneious Biologics
        @if (sequenceEditingEnabled$ | async) {
          for editing
        }
      </label>
    </div>
    <bx-pipeline-output-name-control
      class="mb-0"
      [nameFormControl]="outputFolderNameControl"
      inputID="folder-name"
      displayName="Folder"
      [tooltip]="outputFolderNameTooltip$ | async"
      labelContainerClass="col-lg-3 col-sm-4 ps-5"
      inputContainerClass="col-lg-6 col-sm-8"
    ></bx-pipeline-output-name-control>
  </bx-card>
</form>

@if (sequenceEditingWarning$ | async) {
  <div class="alert alert-warning">Only documents with 1000 sequences or less are editable.</div>
}
