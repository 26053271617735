<div class="modal-body">
  <p>Score: {{ (sequence.length - 3) * 10 }}</p>
  <p class="text-break">Sequence: {{ sequence }}</p>
  <p class="text-break">Translation: {{ translation }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(0)">Classic</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(1)">
    Sequence Builder
  </button>
  <button type="button" class="btn btn-danger" (click)="activeModal.dismiss()">Quit</button>
</div>
