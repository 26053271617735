import { Injectable } from '@angular/core';
import { ExpandService } from '../folders/store/expand.service';
import { FolderService } from '../folders/folder.service';

@Injectable({
  providedIn: 'root',
})
export class FolderTreeService {
  constructor(
    private folderService: FolderService,
    private expandService: ExpandService,
  ) {}

  /**
   * Expand all folders to the specified folder by its ID.
   * Store folder expanding state in the store.
   *
   * @param {string} folderID
   */
  expandToFolder(folderID: string) {
    this.folderService
      .getPathToRootFolder(folderID)
      .subscribe((folders) => this.expandService.expandMultiple(folders, folderID));
  }
}
