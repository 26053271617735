<div class="modal-header">
  <h4 class="modal-title d-flex align-items-center" data-spec="create-ref-db-modal-title">
    Create Reference Database
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069531"
      target="_blank"
      rel="noopener noreferrer"
      class="ms-2 d-flex align-items-center text-decoration-none"
    >
      <mat-icon>info</mat-icon>
    </a>
  </h4>

  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="close('canceled')"
    data-spec="create-ref-db-close-btn"
  ></button>
</div>

<div class="modal-body">
  <div [ngbCollapse]="(enableStepper$ | async) === false" data-spec="create-ref-db-stepper-header">
    <bx-stepper-steps
      [steps]="stepper.steps"
      [selectedIndex]="stepper.selectedIndex"
      [maxNavigableIndex]="stepper.maxNavigableIndex | async"
      color="secondary"
      class="mb-3"
    ></bx-stepper-steps>
    <div
      #collapsibleDisclaimer="ngbCollapse"
      [ngbCollapse]="false"
      data-spec="create-ref-db-disclaimer-container"
    >
      <ngb-alert
        type="warning"
        class="mx-3 mx-lg-5"
        [animation]="false"
        [dismissible]="true"
        (closed)="collapsibleDisclaimer.toggle()"
        data-spec="create-ref-db-disclaimer"
      >
        <span>
          Your sequences will be annotated with CDRs, FRs and constant regions consistent with the
          default settings of Antibody Annotator.
          <b>
            The formatted reference sequences need inspection and curation, particularly around
            FR/CDR boundaries.
          </b>
          More information can be found
          <a
            href="https://help.geneiousbiologics.com/hc/en-us/articles/15653916268820"
            target="_blank"
            rel="noopener noreferrer"
            class="alert-link"
            >here</a
          >.
        </span>
      </ngb-alert>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <bx-stepper [linear]="true" class="bx-page-container bx-page-container-sm pb-0 pt-2 w-100">
      <bx-step label="Details" [stepControl]="stepControls[0]">
        <bx-create-reference-db-details-form
          [form]="stepControls[0]"
        ></bx-create-reference-db-details-form>
      </bx-step>

      <bx-step label="Format" [stepControl]="stepControls[1]">
        <bx-create-reference-db-format-form
          [form]="stepControls[1]"
          [databaseType]="databaseType$ | async"
        ></bx-create-reference-db-format-form>
      </bx-step>

      <bx-step label="Summary" [stepControl]="stepControls[2]">
        <bx-create-reference-db-summary [form]="stepControls[2]"></bx-create-reference-db-summary>
      </bx-step>
    </bx-stepper>
  </div>

  @if ((submitState$ | async) === 'error') {
    <ngb-alert
      type="danger"
      [dismissible]="false"
      class="mx-3 mx-lg-5 my-2"
      data-spec="create-ref-db-error"
    >
      An error occurred while creating the database. Please try again in a minute.
    </ngb-alert>
  }
</div>

@if ({ stepComplete: stepper.canProceed | async }; as state) {
  <div class="modal-footer">
    <bx-pipeline-version-selector
      pipelineFormID="create-reference-db-dialog"
      [nucleusPipelineID]="basePipelineID$ | async"
      (pipelineVersionChanged)="pipelineID$.next($event)"
    ></bx-pipeline-version-selector>
    @if (enableStepper$ | async) {
      @if ((stepper.isFirstStep | async) === false) {
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="stepper.previous()"
          data-spec="create-ref-db-previous-btn"
        >
          Previous
        </button>
      }
      @if ((stepper.isLastStep | async) === false) {
        <button
          class="btn btn-primary"
          (click)="stepper.next()"
          [disabled]="!state.stepComplete"
          data-spec="create-ref-db-next-btn"
        >
          Next
        </button>
      } @else {
        <button
          bx-spinner-button
          class="btn btn-primary"
          (click)="submitForm()"
          [disabled]="!state.stepComplete"
          [spin]="(submitState$ | async) === 'loading'"
          data-spec="create-ref-db-submit-btn"
        >
          Create
        </button>
      }
    } @else {
      <button
        bx-spinner-button
        class="btn btn-primary"
        (click)="submitForm()"
        [disabled]="!state.stepComplete"
        [spin]="(submitState$ | async) === 'loading'"
        data-spec="create-ref-db-submit-btn"
      >
        Create
      </button>
    }
  </div>
}
