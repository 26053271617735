import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class FindHeterozygotesJobParamtersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'generic-geneious',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: FindHeterozygotesJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: FindHeterozygotesJobOptionsV1;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface FindHeterozygotesJobOptionsV1 {
  optionValues: {
    similarity: number;
    peakDetectionHeight: number;
    enableMinimumConfidenceToCall: boolean;
    minimumConfidenceToCall: number;
    enableMinimumConfidence: boolean;
    minimumConfidence: number;
    findIncorrectBaseCalls: boolean;
    incorrectBaseCallHeight: number;
    action: string;
  };
}
