import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ClientGridComponent } from '../../../features/grid/client-grid/client-grid.component';
import { AnnotatedPluginDocument } from '../../geneious';
import { DocumentServiceResource } from '../../../../nucleus/services/documentService/document-service.resource';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AssayDataV2Service, InitializedData } from '../assay-data-v2.service';
import { AssayDataLoggerService } from '../assay-data-logger.service';
import {
  AddAndJoinTableEvent,
  DocumentService,
  sanitizeDTSTableOrColumnName,
} from '../../../../nucleus/services/documentService/document-service.v1';
import { DocumentTableService } from '../../document-table-service/document-table.service';
import { NgbActiveModal, NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DocumentTableStateService } from '../../document-table-service/document-table-state/document-table-state.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  share,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isMasterDatabaseTable } from '../../ngs/table-type-filters';
import { isColDef, isColGroupDef } from '../../folders/models/colDefs';
import {
  AssayDataColDef,
  isAssayDataColDef,
} from '../assay-data-file-header/assay-data-file-header.component';
import { DocumentTableType } from '../../../../nucleus/services/documentService/document-table-type';
import { GridComponent } from '../../../features/grid/grid.component';
import { DocumentHttpV2Service } from 'src/nucleus/v2/document-http.v2.service';
import { SelectGroup, SelectOption } from '../../models/ui/select-option.model';
import { SelectComponent } from '../../../shared/select/select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { AssayDataProgressBarComponent } from '../assay-data-progress-bar/assay-data-progress-bar.component';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { currentValueAndChanges } from '../../../shared/utils/forms';
import { MergeRowsService } from '../merge-rows.service';

@Component({
  selector: 'bx-assay-data-add-form',
  templateUrl: './assay-data-add-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AssayDataV2Service, MergeRowsService],
  standalone: true,
  imports: [
    NgbAlert,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    NgFormControlValidatorDirective,
    NgbTooltip,
    ClientGridComponent,
    AssayDataProgressBarComponent,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class AssayDataAddFormComponent implements OnInit, OnDestroy {
  @ViewChild(ClientGridComponent, { static: true }) mergedGrid: ClientGridComponent;

  @Input() files: File[];
  @Input() document: AnnotatedPluginDocument;
  @Input() resource: DocumentServiceResource;
  @Input() documentTable: DocumentTable;

  assayFileName: string;
  resultsName: string;

  resultColumns$ = new BehaviorSubject<(ColDef | ColGroupDef)[]>([]);
  fileColumns$ = new BehaviorSubject<ColDef[]>([]);

  resultColumnOptions$: Observable<SelectGroup[]>;
  fileColumnOptions$: Observable<SelectOption[]>;
  assayGroupOptions$: Observable<SelectOption<AssayGroup>[]>;

  fileIDColumnControl = new FormControl<string>(undefined, Validators.required);
  resultIDColumnControl = new FormControl<string>(undefined, Validators.required);
  isMergingToExistingGroup = new FormControl<boolean>(false, Validators.required);
  newGroupNameControl = new FormControl<string>(undefined, [Validators.required]);
  /** Value type: {@link AssayGroup }*/
  existingGroupControl = new FormControl<AssayGroup>(undefined, Validators.required);
  form = new FormGroup({
    fileIDColumn: this.fileIDColumnControl,
    resultIDColumn: this.resultIDColumnControl,
    isMergingToExistingGroup: this.isMergingToExistingGroup,
    newGroupName: this.newGroupNameControl,
    existingGroup: this.existingGroupControl,
  });

  submitting$ = new BehaviorSubject<boolean>(false);

  initializeData$: Observable<InitializedData>;
  selectColumn$: Observable<any>;
  selectingColumn$: Observable<boolean>;

  spinnerButtonDisabled$: Observable<boolean>;

  message$ = new BehaviorSubject<{ error?: string; warning?: string }>({});

  gridLoading$: Observable<boolean>;
  assayGroups$: BehaviorSubject<AssayGroup[]> = new BehaviorSubject([]);
  isMultipleTablesPerFile$: Observable<boolean>;

  private reloadGridEvent$ = new Subject<void>();

  private subscriptions = new Subscription();

  constructor(
    private assayDataLogger: AssayDataLoggerService,
    private documentService: DocumentService,
    private documentTableService: DocumentTableService,
    private documentHttpService: DocumentHttpV2Service,
    private activeModal: NgbActiveModal,
    private assayDataService: AssayDataV2Service,
    private documentTableStateService: DocumentTableStateService,
  ) {
    this.handleMergeOptionStateChanges();

    this.resultColumnOptions$ = combineLatest([
      this.resultColumns$,
      currentValueAndChanges(this.form.controls.existingGroup),
      currentValueAndChanges(this.form.controls.isMergingToExistingGroup),
    ]).pipe(
      map(([columns, existingGroup, isMergingToExistingGroup]) =>
        columns
          .filter((column) => {
            // We don't want to allow users to use a column in the existing assay group that they are merging into.
            // That would create a self-reference and is an invalid use case.
            if (isMergingToExistingGroup) {
              if (isColGroupDef(column) && column.groupId === existingGroup.id) {
                return false;
              }
            }
            return true;
          })
          .map((column) => {
            if (isColGroupDef(column) && column.children.length > 0) {
              const childrenOptions = column.children.map((child: ColDef) => {
                return new SelectOption(child.headerName, child.field);
              });
              return new SelectGroup(childrenOptions, column.headerName);
            }
            return new SelectGroup([new SelectOption(column.headerName, (column as ColDef).field)]);
          }),
      ),
    );

    this.fileColumnOptions$ = this.fileColumns$.pipe(
      map((columns) => columns.map((column) => new SelectOption(column.headerName, column.field))),
    );

    this.assayGroupOptions$ = this.assayGroups$.pipe(
      map((groups) => groups.map((group) => new SelectOption(group.name, group))),
    );
  }

  ngOnInit() {
    this.assayFileName = this.files[0].name;
    this.resultsName = this.document.name;
    // Remove file extension from name
    this.newGroupNameControl.setValue(this.assayFileName.replace(/\.[^/.]+$/, ''));

    this.initializeData$ = this.assayDataService
      .initializeData(this.files, this.document.id, this.resource, this.documentTable)
      .pipe(share());

    this.isMultipleTablesPerFile$ = this.initializeData$.pipe(
      map((data) => data.multipleTablesPerFile),
    );

    const dataAvailable$ = this.initializeData$.pipe(
      map(() => true),
      startWith(false),
    );
    this.subscriptions.add(
      combineLatest([dataAvailable$, this.submitting$])
        .pipe(map(([isDataAvailable, isSubmitting]) => !isDataAvailable || isSubmitting))
        .subscribe((controlsDisabled) => this.disableControls(controlsDisabled)),
    );

    this.subscriptions.add(
      this.initializeData$.subscribe({
        next: (data) => {
          this.onDataLoad(data.resultColumns, data.fileColumns);
          // Start listening to option changes AFTER the grid has initialised to avoid duplicate server queries.
          this.handleMergeColumnOptionChanges();
        },
        error: (error) => this.onLoadFail(error),
      }),
    );

    const newGroupName$ = this.newGroupNameControl.valueChanges.pipe(
      debounceTime(600),
      startWith(this.newGroupNameControl.value),
      distinctUntilChanged(),
    );
    this.subscriptions.add(
      combineLatest([newGroupName$, this.assayGroups$]).subscribe(
        ([newGroupName, existingAssayGroups]) => {
          this.reloadGrid();
          if (existingAssayGroups.some((group) => group.name === newGroupName)) {
            this.message$.next({
              error: 'The group name you entered has already been added to this document.',
            });
          }
        },
      ),
    );

    this.handleExistingGroupStateChanges();

    this.selectColumn$ = this.reloadGridEvent$.asObservable().pipe(
      tap(() => this.mergedGrid.gridOptions.api.showLoadingOverlay()),
      switchMap(() =>
        this.assayDataService.selectColumn(
          this.resultIDColumnControl.value,
          this.fileIDColumnControl.value,
          this.isMergingToExistingGroup.value && this.existingGroupControl.value
            ? (this.existingGroupControl.value as AssayGroup).id
            : undefined,
        ),
      ),
      share(),
    );

    this.selectingColumn$ = merge(
      this.reloadGridEvent$.pipe(map(() => true)),
      this.selectColumn$.pipe(map(() => false)),
    );

    this.handleSelectColumnEvent();

    // Spinner button disabled while form is submitting or the column selection is being requested on the server.
    this.spinnerButtonDisabled$ = combineLatest([
      this.submitting$,
      this.selectingColumn$,
      // Disable submit button if form is invalid.
      this.form.statusChanges.pipe(startWith(this.form.status)),
      this.message$.pipe(map((message) => message.error)),
    ]).pipe(
      map(([submitting, selectingColumn, formStatus, errorMessage]) => {
        return submitting || selectingColumn || formStatus === 'INVALID' || errorMessage != null;
      }),
      startWith(true),
    );

    this.gridLoading$ = this.initializeData$.pipe(
      switchMap(() => this.selectingColumn$),
      catchError(() => {
        return observableOf(false);
      }),
      startWith(true),
    );

    this.handleGridLoadingEvent();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    this.resultColumns$.complete();
    this.fileColumns$.complete();
    this.reloadGridEvent$.complete();
    this.assayDataLogger.stop();
  }

  finishAssayDataSubmission() {
    this.documentTableStateService.refreshTables(this.document.id);
    // Hide dialog once table adding is complete.
    this.activeModal.close();
    this.assayDataLogger.stop();
  }

  trackByColField(index: number, col: any): string {
    return col.field;
  }

  get isMasterDatabase() {
    return isMasterDatabaseTable(this.documentTable);
  }

  submitAssayData() {
    if (this.form.invalid) {
      return;
    }

    const resultHeader =
      this.resultIDColumnControl.value === 'row_id' ? 'id' : this.resultIDColumnControl.value;
    const fileHeader = this.fileIDColumnControl.value.split('&&&')[0];
    const includedColumns = this.getIncludedColumns();
    const file = this.assayDataService.getParsedFile(includedColumns);
    this.submitAssayDataNUC(resultHeader, fileHeader, file);
  }

  close() {
    this.activeModal.dismiss();
  }
  private getIncludedColumns(): { [field: string]: boolean } {
    return this.mergedGrid.gridOptions.api.getColumnDefs().reduce(
      (agg, col) => {
        return this._getIncludedColumns(col, agg);
      },
      {} as { [field: string]: boolean },
    );
  }

  private _getIncludedColumns(
    col: ColDef | ColGroupDef,
    agg: { [field: string]: boolean },
  ): { [field: string]: boolean } {
    if (isColGroupDef(col)) {
      return col.children.reduce((a, child) => {
        // Recursion.
        return this._getIncludedColumns(child, a);
      }, agg);
    } else if (isAssayDataColDef(col)) {
      agg[col.field] = (col as AssayDataColDef).metadata?.include;
    }
    return agg;
  }

  private submitAssayDataNUC(baseColumnId: string, joinedColumnId: string, parsedFile: File) {
    // Reset all the things that need to be reset.
    this.assayDataLogger.start();
    this.message$.next({});
    this.submitting$.next(true);

    const originalBaseTable = this.documentTable.name;
    let baseTableName = this.documentTable.name;
    let groupName: string;
    let groupID: string;
    let groupTableType: DocumentTableType | undefined;
    if (this.isMergingToExistingGroup.value) {
      const selectedTable: string = this.existingGroupControl.value.id;
      groupID = this.getDeepestTable(selectedTable);
      groupName = this.existingGroupControl.value.name;
      groupTableType = this.existingGroupControl.value.tableType
        ? this.existingGroupControl.value.tableType
        : DocumentTableType.ASSAY_DATA;
    } else {
      groupName = this.newGroupNameControl.value.trim();
      groupID = `ASSAY_DATA_${sanitizeDTSTableOrColumnName(groupName)}`;
      groupTableType = DocumentTableType.ASSAY_DATA;
    }

    // If joining on column from an existing assay table.
    if (baseColumnId.includes(':')) {
      const split = DocumentTableService.splitAssayColumnKey(baseColumnId);
      baseColumnId = split.columnName;
      baseTableName = split.tableName;
    }

    // 1. Upload assay data csv to the nucleus result doc as a blob.
    this.documentHttpService
      .uploadAndCommitDocumentPart(this.document.id, groupID, parsedFile)
      .pipe(
        tap(() => this.assayDataLogger.setProgress(10, 'Upload complete')),
        switchMap((response) => {
          // Need to remove the existing table if merging into existing group, so it can be replaced with the new one.
          if (this.isMergingToExistingGroup.value && !this.isMasterDatabase) {
            return this.documentTableService.removeTable(this.document.id, groupID);
          } else {
            return of(response);
          }
        }),
        // 2. Add the assay data table to table service and then join it on the base table.
        switchMap(() => {
          return this.isMasterDatabase
            ? this.documentService.mergeTableWithProgress(
                this.document.id,
                baseTableName,
                groupID,
                groupName,
                baseColumnId,
                joinedColumnId,
              )
            : this.documentService.addAndJoinTable(
                this.document.id,
                groupTableType,
                baseTableName,
                groupID,
                groupName,
                baseColumnId,
                joinedColumnId,
                originalBaseTable,
              );
        }),
        filter((event) => event === AddAndJoinTableEvent.TABLE_JOIN_COMPLETE),
        finalize(() => {
          this.submitting$.next(false);
        }),
      )
      .subscribe(() => {
        this.finishAssayDataSubmission();
      });
  }

  private disableControls(disable: boolean) {
    const options = { emitEvent: false };
    if (disable) {
      this.resultIDColumnControl.disable(options);
      this.fileIDColumnControl.disable(options);
      this.existingGroupControl.disable(options);
      this.newGroupNameControl.disable(options);
    } else {
      this.resultIDColumnControl.enable(options);
      this.fileIDColumnControl.enable(options);
      if (this.assayGroups$.getValue().length > 0) {
        this.existingGroupControl.enable(options);
      }
      this.newGroupNameControl.enable(options);
    }
  }

  private onDataLoad(resultColumns: ColDef[], fileColumns: AssayDataColDef[]) {
    this.assayDataLogger.reset();

    const assayGroups = GridComponent.filterOutInvalidColDefs(resultColumns)
      .filter(isColGroupDef)
      .map((colDef) => ({
        id: colDef.groupId,
        name: colDef.headerName,
        isDTSColGroup: this.documentTable.columnGroups[colDef.groupId] != null,
        tableType: this.resource.getTableType(this.getDeepestTable(colDef.groupId)),
      }))
      .filter(
        (colDef) =>
          colDef.tableType === DocumentTableType.ASSAY_DATA ||
          colDef.tableType === DocumentTableType.CHERRY_PICKING ||
          colDef.isDTSColGroup,
      );

    this.assayGroups$.next(assayGroups);
    if (assayGroups.length > 0) {
      this.existingGroupControl.setValue(assayGroups[0]);
    }

    const filteredResultColumns: (ColDef | ColGroupDef)[] = GridComponent.filterOutInvalidColDefs(
      resultColumns,
    )
      // Labels column shouldn't be an option to merge on (nice if we did though!).
      .filter((col) => ('colId' in col ? col.colId !== 'labels' : true));

    this.resultColumns$.next(filteredResultColumns);
    this.fileColumns$.next([...fileColumns]);

    this.resultIDColumnControl.setValue(filteredResultColumns.filter(isColDef)[0].field);
    this.fileIDColumnControl.setValue(fileColumns[0].field);

    this.reloadGrid();
  }

  private onLoadFail(error: any) {
    this.message$.next({ error: error });
  }

  private onColIDChange() {
    this.assayDataService.merged = null;
    this.reloadGrid();
  }

  private reloadGrid() {
    this.reloadGridEvent$.next(undefined);
  }

  private updateColumns(resultColumns: ColDef[], fileColumns: ColDef[]) {
    const classes: Record<string, string> = {
      // Status describing if a row has been overwritten from merging into an existing assay group.
      '⚠': 'status overwrite',
      // Status describing successful/clean match.
      '✓': 'status matched',
      '?': 'status no-file',
      '✘': 'status no-result',
    };

    // This column indicates whether the row is a match or not.
    const matchCol: ColDef = {
      field: 'match',
      valueGetter: (params) => params.data.status,
      cellClass: (params) => classes[params.data.status],
      cellStyle: (params) => {
        if (params.data.status === '⚠') {
          return { color: 'orange' };
        } else if (params.data.status === '✓') {
          return { color: 'green' };
        } else if (params.data.status === '✘') {
          return { color: 'red' };
        }
        return null;
      },
      tooltipValueGetter: (params) =>
        params.value === '⚠' ? 'This row will be overwritten' : null,
      headerName: 'Match',
      pinned: 'left',
      suppressMovable: true,
      width: 100,
      // Match column should be sorted by default.
      sort: 'desc',
      suppressAutoSize: true,
    };
    const resultColGroup: ColGroupDef = {
      headerName: 'Document',
      headerTooltip: this.document.name,
      children: resultColumns.map((col) => ({
        ...col,
        pinned: 'left',
        cellClass: 'match-column',
        headerClass: 'match-column',
        suppressMovable: true,
        sortable: false,
      })),
      marryChildren: true,
    };
    const fileColGroup: ColGroupDef = {
      headerName: this.isMergingToExistingGroup.value
        ? (this.existingGroupControl.value as AssayGroup).name
        : this.newGroupNameControl.value,
      children: fileColumns.map((col) => ({
        ...col,
        sortable: false,
        // turn off data-type inference as it doesn't work well with mixed type columns
        cellDataType: false,
      })),
      marryChildren: true,
    };

    this.mergedGrid.setColumnDefs([resultColGroup, matchCol, fileColGroup]);
    this.mergedGrid.gridOptions.columnApi.autoSizeAllColumns();
    this.mergedGrid.gridOptions.onRowDataUpdated = () => {
      // Set timeout because Angular change detection and ag-grid is just frustrating
      setTimeout(() => {
        // Resize the columns to fit all their content whenever the row data changes.
        this.mergedGrid.gridOptions.columnApi.autoSizeAllColumns();
        // Ensure that the important column is visible.
        this.mergedGrid.gridOptions.api.ensureColumnVisible(this.fileIDColumnControl.value);
      });
    };
  }

  private updateRows() {
    const mergeOption: boolean = this.isMergingToExistingGroup.value;
    const groupToMerge: AssayGroup = this.existingGroupControl.value;
    const response = this.assayDataService.matchRows(
      this.resultIDColumnControl.value,
      this.fileIDColumnControl.value,
      mergeOption && groupToMerge ? groupToMerge.id : undefined,
    );
    if (response) {
      this.mergedGrid.setRowData(response.mergedRows);
      if (response.errors && response.errors.length > 0) {
        this.message$.next({ error: response.errors[0] });
      } else if (groupToMerge && response.mergedRows.length > AssayDataV2Service.ROW_LIMIT) {
        this.message$.next({ warning: 'Only the first 10,000 rows will be merged.' });
      } else if (response.warning) {
        this.message$.next({ warning: response.warning });
      }
    } else {
      this.message$.next({
        error: 'Invalid document; please check it is a valid csv/excel file and is not empty',
      });
    }
  }

  /**
   * Disable/Enable related controls based on the state of the merge option control.
   */
  private handleMergeOptionStateChanges() {
    this.subscriptions.add(
      this.isMergingToExistingGroup.valueChanges
        .pipe(startWith(this.isMergingToExistingGroup.value as boolean))
        .subscribe((mergeOption: boolean) => {
          if (mergeOption) {
            this.newGroupNameControl.clearValidators();
            this.newGroupNameControl.disable();
            this.existingGroupControl.enable();
            this.existingGroupControl.setValidators([Validators.required]);
            this.newGroupNameControl.updateValueAndValidity();
            this.existingGroupControl.updateValueAndValidity();
          } else {
            this.newGroupNameControl.setValidators([Validators.required]);
            this.newGroupNameControl.enable();
            this.existingGroupControl.disable();
            this.existingGroupControl.clearValidators();
            this.newGroupNameControl.updateValueAndValidity();
            this.existingGroupControl.updateValueAndValidity();
          }
        }),
    );
  }

  /**
   * Whenever the two control states change, then refresh the grid with the new related data.
   */
  private handleMergeColumnOptionChanges() {
    this.subscriptions.add(
      merge(
        this.fileIDColumnControl.valueChanges.pipe(distinctUntilChanged()),
        this.resultIDColumnControl.valueChanges.pipe(distinctUntilChanged()),
      ).subscribe(() => this.onColIDChange()),
    );
  }

  /**
   * Refresh the grid whenever the merge option and then the existing group control state changes.
   */
  private handleExistingGroupStateChanges() {
    this.subscriptions.add(
      this.isMergingToExistingGroup.valueChanges
        .pipe(
          switchMap((mergeOptionEnabled: boolean) => {
            if (mergeOptionEnabled) {
              return this.existingGroupControl.valueChanges.pipe(
                startWith(this.existingGroupControl.value),
              );
            } else {
              return of(undefined);
            }
          }),
        )
        .subscribe((existingGroup) => {
          if (existingGroup && this.documentTable.tableType !== DocumentTableType.MASTER_DATABASE) {
            this.setResultIDColumnToBaseColumnFromJoin(existingGroup.id);
          } else {
            this.onColIDChange();
          }
        }),
    );
  }

  /**
   * When an existing group to merge into is selected, then the base table column that is in the
   * current table's join information should be chosen from that table by default. This it makes
   * the user's job easier as for the vast majority of the time they will use the existing join
   * column.
   */
  private setResultIDColumnToBaseColumnFromJoin(tableKey: string) {
    const joinedColumnInfo = this.resource.getJoinedColumnInfo(tableKey);
    const fullColumnID =
      joinedColumnInfo.baseTableKey !== 'DOCUMENT_TABLE_ALL_SEQUENCES' &&
      joinedColumnInfo.joinTableKey !== 'DOCUMENT_TABLE_CHAIN_COMBINATIONS'
        ? joinedColumnInfo.baseTableKey + ':' + joinedColumnInfo.joinInfo.baseColumn
        : joinedColumnInfo.joinInfo.baseColumn;
    if (fullColumnID !== this.resultIDColumnControl.value) {
      this.resultIDColumnControl.setValue(fullColumnID);
    } else {
      this.onColIDChange();
    }
  }

  /**
   * Whenever a new column is selected, update the grid with new data.
   */
  private handleSelectColumnEvent() {
    this.subscriptions.add(
      this.selectColumn$.subscribe(({ result, file }) => {
        // Define the columns to represent the results table.
        this.updateColumns(result.columns, file.columns);
        this.updateRows();
      }),
    );
  }

  /**
   * Reset any messages such as errors etc whenever the grid starts loading.
   */
  private handleGridLoadingEvent() {
    // Whenever the grid is loading, reset the error message in the template.
    this.gridLoading$.pipe(filter((loading) => loading)).subscribe(() => this.message$.next({}));
  }

  /**
   * selectedTable could either be the base table such as `DOCUMENT_TABLE_ALL_SEQUENCES` or
   * a nested table such as `ASSAY_DATA_Assay Data 1:ASSAY_DATA_Assay Data 2`, where
   * `ASSAY_DATA_Assay Data 2` is the deeply nested table that is joined onto `ASSAY_DATA_Assay
   * Data 1`.
   */
  private getDeepestTable(tableID: string): string {
    return tableID.includes(':') ? tableID.split(':').pop() : tableID;
  }
}

interface AssayGroup {
  id: string;
  name: string;
  isDTSColGroup: boolean;
  tableType?: DocumentTableType;
}
