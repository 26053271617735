import * as NameSchemeActions from './name-scheme-editor.actions';
import { NameScheme } from '../../models/settings/name-scheme.model';

export interface State {
  // model
  nameScheme: NameScheme;

  // actions
  creating: boolean;

  // UI
}

const initialState = {
  nameScheme: new NameScheme(),
  creating: false,
};

export function nameSchemeEditor(state: State = initialState, action: NameSchemeActions.All) {
  switch (action.type) {
    case NameSchemeActions.CREATE:
      return { ...state, creating: true };
    case NameSchemeActions.FINALIZE_CREATION:
      return { ...state, creating: false, nameScheme: action.payload };
    case NameSchemeActions.CANCEL:
      return { ...state, creating: false };
    case NameSchemeActions.HANDLE_ERROR:
      return state;
    default:
      return state;
  }
}
