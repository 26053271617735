import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { comparisonsGraphDataActions } from './ngs-comparisons-graph-data-store.actions';
import { catchError, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectParamsForComparisonsDocument } from './ngs-comparisons-graph-data-store.selectors';
import { AppState } from '../../../core.store';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../../../nucleus/services/documentService/document-service.resource';
import { IGetRowsRequestMinimal } from '../../../../features/grid/datasource/grid.resource';
import { of } from 'rxjs';
import { GridStateService } from '../../../grid-state/grid-state.service';
import { getDatasourceParamsForSelection } from '../../ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.effects';

@Injectable()
export class NgsComparisonsGraphDataStoreEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private documentServiceResource: DocumentServiceResource,
    private gridStateService: GridStateService,
  ) {}

  private updateData$ = createEffect(() =>
    this.actions.pipe(
      ofType(comparisonsGraphDataActions.updateSelection),
      map(({ id }) => comparisonsGraphDataActions.fetchData({ id })),
    ),
  );

  private loadData$ = createEffect(() =>
    this.actions.pipe(
      ofType(comparisonsGraphDataActions.fetchData),
      switchMap(({ id }) =>
        this.store.select(selectParamsForComparisonsDocument(id)).pipe(take(1)),
      ),
      switchMap(({ id, ...params }) => {
        const endRow =
          params.selection.selectedRows.length === 0
            ? DocumentServiceResource.RESULT_SET_MAX
            : Math.min(
                params.selection.selectedRows.length,
                DocumentServiceResource.RESULT_SET_MAX,
              );
        const datasourceParams: DocumentDatasourceParams = getDatasourceParamsForSelection(
          id,
          params.selection,
          params.filter,
          params.selectedTable,
        );

        return this.gridStateService.getGridStateSortModel(params.selectedTable.name).pipe(
          takeWhile((state) => state == undefined, true),
          map((sortModel) => sortModel ?? []),
          switchMap((sortModel) => {
            const requestParams: IGetRowsRequestMinimal = {
              startRow: 0,
              endRow,
              sortModel: sortModel,
              filterModel: null,
            };
            return this.documentServiceResource.query(requestParams, datasourceParams, true);
          }),
          map((data) => comparisonsGraphDataActions.fetchDataSuccess({ id, data })),
          catchError(() => of(comparisonsGraphDataActions.fetchDataFail({ id }))),
        );
      }),
    ),
  );
}
