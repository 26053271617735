import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import Token from '../tokenizer-preview/Token';
import { NameScheme } from '../../models/settings/name-scheme.model';
import { INameSchemeField, NameSchemeField } from '../../models/settings/name-scheme-field.model';
import Label from '../tokenizer-preview/Label';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CLASSIFICATION_OPTIONS } from './name-scheme-editor.model';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { Observable } from 'rxjs';
import { nameSchemeActions } from '../../organization-settings/organization-settings.actions';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { AccordionComponent } from '../../../shared/accordion/accordion.component';
import { AccordionStepComponent } from '../../../shared/accordion/accordion-step/accordion-step.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { FileChooserComponent } from '../../file-chooser/file-chooser.component';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { AsyncPipe } from '@angular/common';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { TokenizerPreviewComponent } from '../tokenizer-preview/tokenizer-preview.component';
import { NameSchemeConfigureFieldsComponent } from './name-scheme-configure-fields/name-scheme-configure-fields.component';

const DEFAULT_DELIMITERS = [
  { value: '_', text: 'Underscore' },
  { value: '-', text: 'Hyphen' },
  { value: ',', text: 'Comma' },
  { value: '.', text: 'Period' },
];

@Component({
  selector: 'bx-name-scheme-editor',
  templateUrl: './name-scheme-editor.component.html',
  styleUrls: ['./name-scheme-editor.component.scss'],
  standalone: true,
  imports: [
    AccordionComponent,
    AccordionStepComponent,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    FormsModule,
    FileChooserComponent,
    MatLegacyAutocompleteModule,
    ReactiveFormsModule,
    MatLegacyOptionModule,
    TokenizerPreviewComponent,
    NameSchemeConfigureFieldsComponent,
    AsyncPipe,
  ],
})
export class NameSchemeEditorComponent extends CleanUp implements OnInit {
  organizationID: string;
  fileName = '';
  delimiter = new FormControl('-', Validators.required);
  fields: NameSchemeField[] = [];
  schemeName = '';
  filteredDelimiters$: Observable<typeof DEFAULT_DELIMITERS>;
  direction = new FormControl<string>(undefined);
  id = new FormControl<string>(undefined);

  // fixme 2017-07-17: it may be possible to refactor these two maps into simpler objects but that can come later
  // Tokens are one per index.
  tokens: Set<Token> = new Set<Token>();
  // Labels are one per name.
  labels: Set<Label> = new Set<Label>();

  filteredFields: NameSchemeField[] = [];

  @Output() valid = new EventEmitter<boolean>();
  errors: any;

  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {
    super();
    this.errors = {
      nameSchemeName: [],
      fileName: [],
      delimiter: [],
      fields: [],
    };
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectOrganizationID), takeUntil(this.ngUnsubscribe))
      .subscribe((organizationID) => (this.organizationID = organizationID));
    this.filteredDelimiters$ = this.delimiter.valueChanges.pipe(
      startWith(''),
      map((value) => DEFAULT_DELIMITERS.filter((delimiter) => delimiter.value.includes(value))),
    );
  }

  onFileChosen(fileName: string) {
    this.fileName = fileName;
    // Reset tokens for next step as they may now be invalid.
    this.tokens = new Set<Token>();
    this.tokens.add(new Token(0, fileName));

    if (this.delimiter) {
      this.tokens = this.tokenize(this.fileName, this.delimiter.value);
    }

    // Reset values for the configure fields step as they are possibly now invalid.
    this.fields = [];
  }

  onTokenized() {
    this.tokens = this.tokenize(this.fileName, this.delimiter.value);

    // Reset values for the next step as they are possibly now invalid.
    this.fields = [];
  }

  onFieldsChanged(fields: INameSchemeField[]) {
    this.fields = fields.map((field) => {
      return new NameSchemeField(field.name, field.type, field.template, field.classification);
    });

    this.validateFields();
    this.validateScheme(); // Validate Scheme since it is the final step.
  }

  validateFileName() {
    const errors = this.errors['fileName'];
    // Clear errors from previous validation.
    errors.length = 0;
    if (!this.fileName) {
      errors.push('An example sequence name must be specified');
    }

    this.validateScheme();
  }

  validateDelimiter() {
    const errors = this.errors['delimiter'];
    // Clear errors from the previous validation.
    errors.length = 0;

    if (!this.delimiter) {
      errors.push('A delimiter must be provided.');
    }

    this.validateScheme();
  }

  validateFields() {
    const errors = this.errors['fields'];
    // Clear errors from the previous round.
    errors.length = 0;

    const fieldNames: Set<string> = new Set<string>();

    this.fields.forEach((field) => {
      if (field.name) {
        if (fieldNames.has(field.name)) {
          errors.push(
            `A field with the name "${field.name}" already exists. Names must be unique.`,
          );
        } else {
          fieldNames.add(field.name);
        }
      }
    });

    if (fieldNames.size === 0) {
      errors.push('There must be at least one field');
    }

    // Validate field classifications.
    CLASSIFICATION_OPTIONS.forEach((option) => {
      if (this.fields.filter((field) => field.classification === option.value).length > 1) {
        errors.push(`There can only be 1 field with the classification "${option.label}"`);
      }
    });

    // Reset values for the next step as they are possibly now invalid.
    this.filteredFields = this.fields.filter((f: NameSchemeField) => f.type !== 'Ignore');

    this.validateScheme();
  }

  validateNameField() {
    const errors = this.errors.nameSchemeName;
    errors.length = 0; // clear errors from the previous round

    if (!this.schemeName) {
      errors.push('You must enter a scheme name');
    }

    this.validateScheme();
  }

  validateScheme() {
    const valid =
      this.schemeName &&
      this.fileName &&
      this.delimiter &&
      this.fields.length > 0 &&
      this.errors.fields.length === 0;
    this.valid.emit(valid);
  }

  /**
   * Splits a string into tokens using the delimiter
   * @param inputString   the string to tokenize. Must not be null or undefined
   * @param delimiter     the delimiter. Must not be null or undefined
   * @returns {string[]}  an array of the tokens, or null if the input string was null or defined, or the input if the
   *                      delimiter was null or undefined
   */
  tokenize(inputString: string, delimiter: string): Set<Token> {
    const tokens = new Set<Token>();
    if (!inputString) {
      return tokens;
    }
    if (!delimiter) {
      tokens.add(new Token(0, inputString));
      return tokens;
    }
    inputString
      .split(new RegExp(`[${delimiter.replace(/([^a-zA-Z0-9])/g, '\\$1')}]`, 'g'))
      .forEach((token: string, i: number) => tokens.add(new Token(i, token)));
    return tokens;
  }

  createScheme() {
    const scheme = new NameScheme();
    scheme.name = this.schemeName;
    scheme.delimiter = this.delimiter.value;
    scheme.fields = this.fields;
    scheme.organizationID = this.organizationID;
    this.store.dispatch(nameSchemeActions.create(scheme.toSetting()));
  }
}
