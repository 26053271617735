@if (
  {
    isLoading: isLoading$ | async,
    data: data$ | async,
    docType: documentsType$ | async,
    topology: sequenceTopology$ | async,
    metadataColumns: metadataColumns$ | async,
    metadataColumnOrder: sequenceMetadataOrder$ | async,
    referencePosition: referencePosition$ | async,
    showSequenceViewer: showSequenceViewer$ | async,
    preferences: sequenceViewerPreferencesState$ | async,
    toolbarWarningMsg: toolbarWarningMsg$ | async,
    documents: documents$ | async,
    documentType: documentsType$ | async,
  };
  as state
) {
  @if (!state.isLoading) {
    @if (isComplexDocument$ | async) {
      <div class="px-2 pt-2">
        <div
          class="alert alert-info d-flex justify-content-between align-items-center"
          role="alert"
        >
          <div></div>
          <div class="text-center">
            <b>Preview Mode:</b> To perform advanced operations, please view the full document.
          </div>
          <bx-open-document-button
            [openQueryParams]="openDocumentQuery$ | async"
            buttonColor="primary"
          ></bx-open-document-button>
        </div>
      </div>
    }
    @if (
      state.showSequenceViewer && state.data?.sequences?.length > 0 && state.preferences !== null
    ) {
      <bx-sequence-viewer
        [trees]="state.data.trees"
        [sequences]="state.data.sequences"
        [consensus]="state.data.consensus"
        [reference]="state.referencePosition"
        [metadataColumns]="state.metadataColumns"
        [metadataColumnOrder]="state.metadataColumnOrder"
        [documentsType]="state.docType"
        [sequenceTopology]="state.topology"
        [readonly]="isReadonlyDocument$ | async"
        [savedOptions]="state.preferences"
        [isInEditMode]="isInEditMode"
        [isSequenceSelectionEnabled]="sequenceSelectionEnabled$ | async"
        [isCircularModeEnabled]="circularModeEnabled$ | async"
        [toolbarWarningMsg]="state.toolbarWarningMsg"
        (optionChanged)="optionChanged($event)"
        (optionRemoved)="optionRemoved($event)"
        (sequencesSelectionChanged)="sequencesSelectionChanged($event)"
        (liabilityThresholdChanged)="liabilityScoreThresholdChanged($event)"
        class="w-100 flex-grow-1 flex-shrink-1 overflow-hidden"
      >
        @if (!isInEditMode) {
          <bx-files-sequence-viewer-export-menu
            [documentCount]="state.documents.length"
            [documentType]="state.documentType"
            [documentID]="state.documents[0].id"
            class="me-1"
          ></bx-files-sequence-viewer-export-menu>
        }
        @if (sequenceEditingEnabled$ | async) {
          <div class="flex-shrink-0">
            <bx-sequence-editing-controls
              class="mx-1"
              [enterEditModeInitially]="(openDocumentQuery$ | async).edit"
              [readonly]="isReadonlyDocument$ | async"
              (enterEditMode)="enableEditing()"
              (exitEditMode)="disableEditing()"
              (enterSavingMode)="disableEditing()"
              (exitSavingMode)="enableEditing()"
            ></bx-sequence-editing-controls>
          </div>
        }
      </bx-sequence-viewer>
    }
  }
}
