import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { FolderService } from './folders/folder.service';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

/**
 * For tables: Show links to folders (e.g. in the job table you can find the folder corresponding to the job.
 */
@Component({
  selector: 'bx-link-header',
  templateUrl: './link.component.html',
  standalone: true,
  imports: [RouterLink, AsyncPipe],
})
export class LinkComponent implements IHeaderAngularComp {
  folderName$: Observable<string>;
  link: string[];

  constructor(private folderService: FolderService) {}

  agInit(params: IHeaderParams): void {
    const job = (params as any).data;
    // TODO Handle case that jobs don't have selection config more concisely (or even require them all to).
    // TODO Generalize so works for jobs/activity to share it.
    if (job && job.config && job.config.parameters && job.config.parameters.selection) {
      const folder$ = this.folderService.get(job.config.parameters.selection.folderId);
      const selection = job.config.parameters.selection;
      const maybeFolderId = selection ? selection.folderId : undefined;
      const folderId = maybeFolderId ? maybeFolderId : '-';
      this.link = ['/folders', folderId];
      this.folderName$ = folder$.pipe(map((folder) => folder.name));
    } else {
      // Just show an empty column as per our UI guidelines.
      this.folderName$ = observableOf('');
    }
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
