import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DataManagementService, DocumentHistoryData } from '@geneious/nucleus-api-client';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { fullName } from '../../../shared/utils/user';
import { FolderService } from '../../folders/folder.service';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { OrganizationService } from '../../organisation/organization.service';
import { ViewerDocumentData } from '../../viewer-components/viewer-document-data';
import { viewerDocumentSelector } from '../../viewer-components/viewer-selectors';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerPageURLSelectionState } from '../../viewer-page/viewer-page.component';
import { DocumentUtils } from '../../document-utils';
import { AsyncPipe } from '@angular/common';
import { OpenDocumentButtonComponent } from '../../../shared/open-document-button/open-document-button.component';
import { DocumentSummaryCardComponent } from '../document-summary-card/document-summary-card.component';
import { DocumentHistoryCardComponent } from '../document-history-card/document-history-card.component';
import { DocumentPermissionsCardComponent } from '../document-permissions-card/document-permissions-card.component';
import isComplexDocumentType = DocumentUtils.isComplexDocumentType;

export const DOCUMENT_INFO_VIEWER_KEY = 'document-info-viewer';

@ViewerComponent({
  key: DOCUMENT_INFO_VIEWER_KEY,
  title: 'Info',
  selector: viewerDocumentSelector((data) => data.selection.totalSelected === 1),
})
@Component({
  selector: 'bx-document-info-viewer',
  templateUrl: './document-info-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    OpenDocumentButtonComponent,
    DocumentSummaryCardComponent,
    DocumentHistoryCardComponent,
    DocumentPermissionsCardComponent,
    AsyncPipe,
  ],
})
export class DocumentInfoViewerComponent implements OnInit {
  @HostBinding('class') readonly hostClass =
    'd-flex flex-column flex-grow-1 flex-shrink-1 overflow-auto';

  documentID$: Observable<string>;
  folder$: Observable<FolderTreeItem>;
  documentHistory$: Observable<DocumentHistoryData[]>;
  usersByID$: Observable<Record<string, { id: string; name: string }>>;
  selectionChanged$: Observable<void>;
  shouldShowPreviewText$: Observable<boolean>;
  openTabQueryParams$: Observable<ViewerPageURLSelectionState>;
  readonly faChevronRight = faChevronRight;

  private viewerData$: Observable<ViewerDocumentData>;

  constructor(
    private readonly dataManagementService: DataManagementService,
    private readonly folderService: FolderService,
    private readonly organizationService: OrganizationService,
    private readonly viewerDataService: ViewerDataService<ViewerDocumentData>,
  ) {}

  ngOnInit(): void {
    this.viewerData$ = this.viewerDataService.getData(DOCUMENT_INFO_VIEWER_KEY);
    this.openTabQueryParams$ = this.viewerData$.pipe(map((data) => data.openQueryParams));

    const isPreviewMode$ = this.viewerData$.pipe(map((data) => data.isPreviewView));
    const isCorrectDocumentTypeForPreview$ = this.viewerData$.pipe(
      map((data) => data.selection.rows[0].type),
      map((documentType) => isComplexDocumentType(documentType)),
      distinctUntilChanged(),
    );
    this.shouldShowPreviewText$ = combineLatest([
      isCorrectDocumentTypeForPreview$,
      isPreviewMode$,
    ]).pipe(
      map(([isCorrectDocumentType, isPreviewMode]) => isCorrectDocumentType && isPreviewMode),
    );

    this.folder$ = this.viewerData$.pipe(
      map((data) => data.selection.rows[0].parentID),
      distinctUntilChanged(),
      switchMap((id) => this.folderService.get(id)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.usersByID$ = this.organizationService.getPrincipalUsers().pipe(
      map((principals) =>
        Object.fromEntries(
          principals.map((principal) => [
            principal.principalId,
            { id: principal.principalId, name: fullName(principal.principalData) },
          ]),
        ),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.documentID$ = this.viewerData$.pipe(
      map((data) => data.selection.rows[0].id as string),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.selectionChanged$ = this.documentID$.pipe(mapTo(undefined));

    this.documentHistory$ = this.documentID$.pipe(
      switchMap((documentID) => this.dataManagementService.getHistory(documentID)),
      map((response) => response.data),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Collapses/expands a card in the accordion.
   *
   * @param card NgbCollapse component
   * @param icon Chevron (arrow) icon component
   */
  toggleCard(card: NgbCollapse, icon: FaIconComponent): void {
    icon.rotate = card.collapsed ? 90 : null;
    card.toggle();
    icon.render();
  }
}
