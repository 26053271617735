import { Directive, HostListener, OnDestroy } from '@angular/core';
import { CellEditingStoppedEvent } from '@ag-grid-community/core';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UpdateRowsBody } from '../../../../nucleus/services/documentService/document-service.v1.http';
import { retryOnErrorWithDelay } from '../../../../bx-operators/retry-on-error-with-delay';
import { CleanUp } from '../../../shared/cleanup';
import { DocumentService } from '../../../../nucleus/services/documentService/document-service.v1';
import { FolderService } from '../../folders/folder.service';
import { DocumentTableViewerService } from '../document-table-viewer.service';

@Directive({
  selector: '[bxNotesEditHandler]',
  standalone: true,
})
export class NotesEditHandlerDirective extends CleanUp implements OnDestroy {
  private static DELAY_BETWEEN_RETRIES = 5000;
  private static MAX_RETRIES = 5;
  private static NOTES_COLUMN_ID = 'Notes';

  private onCellEditingStopped$ = new Subject<CellEditingStoppedEvent>();

  constructor(
    private documentTableViewerService: DocumentTableViewerService,
    private documentService: DocumentService,
    private folderService: FolderService,
  ) {
    super();

    const getSelectedTable$ = this.documentTableViewerService.getSelectedTable();

    const documentID$ = this.folderService.folderSelectionState$.pipe(
      filter((state) => !!state),
      map((state) => state.ids[0]),
    );

    this.onCellEditingStopped$
      .asObservable()
      .pipe(
        filter((event) => event.column.getColId() === NotesEditHandlerDirective.NOTES_COLUMN_ID),
        filter((event) => event.oldValue !== event.newValue),
        withLatestFrom(documentID$, getSelectedTable$),
        takeUntil(this.ngUnsubscribe),
        mergeMap(([event, documentID, table]) => {
          const updateRow: UpdateRowsBody = {
            [event.data.row_number]: {
              columns: {
                [event.column.getColId()]: {
                  value: event.value === undefined ? null : event.value,
                },
              },
            },
          };
          return this.documentService
            .updateRows(documentID, table.name, updateRow)
            .pipe(
              retryOnErrorWithDelay(
                NotesEditHandlerDirective.DELAY_BETWEEN_RETRIES,
                NotesEditHandlerDirective.MAX_RETRIES,
                true,
              ),
            );
        }),
      )
      .subscribe();
  }

  @HostListener('cellEditingStopped', ['$event']) onCellEditingStopped(
    event: CellEditingStoppedEvent,
  ) {
    this.onCellEditingStopped$.next(event);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onCellEditingStopped$.complete();
  }
}
