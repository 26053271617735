import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewerDataService<D = any> implements OnDestroy {
  private data$: ReplaySubject<D>;
  private listeners: Set<string>;
  private streamKiller: Subject<string>;

  constructor() {
    this.data$ = new ReplaySubject(1);
    this.listeners = new Set();
    this.streamKiller = new Subject();
  }

  ngOnDestroy(): void {
    this.data$.complete();
    this.streamKiller.complete();
  }

  /**
   * Get ViewerDocumentData stream.
   * Can be killed by provider by using killStream(key: string)
   *
   * @param listener key of the ViewerComponent. Used to have reference to the subscriber so it can be managed.
   */
  getData(listener: string): Observable<D> {
    this.listeners.add(listener);
    return this.data$
      .asObservable()
      .pipe(takeUntil(this.streamKiller.asObservable().pipe(filter((key) => key === listener))));
  }

  setData(data: D) {
    this.data$.next(data);
  }

  /**
   * Unsubscribes from all streams subscribed to the viewer data$ stream.
   */
  killAllStreams() {
    this.listeners.forEach((listener) => {
      this.streamKiller.next(listener);
    });
  }

  /**
   * Unsubscribes from a specific stream referenced by the ViewerComponent's key.
   *
   * @param key of the ViewerComponent that is listening to the ViewerDocumentData stream.
   */
  killStream(key: string) {
    this.streamKiller.next(key);
  }
}
