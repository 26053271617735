import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CleanUp } from '../../../../../../shared/cleanup';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SelectOption } from '../../../../../models/ui/select-option.model';
import { map } from 'rxjs/operators';
import { faGripHorizontal, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { partitionArray } from '../../../../../../../bx-common-extensions/array';
import { sortAntibodyRegionByName } from '../../../../../../shared/sort.util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class RegionsOptions {
  constructor(
    public regions: string[],
    public initialRegions: string[],
    public form: FormControl,
    public maxSelection: number,
  ) {}
}

@Component({
  selector: 'bx-sankey-regions-selector',
  templateUrl: './sankey-regions-selector.component.html',
  styleUrls: ['./sankey-regions-selector.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DragDropModule, FontAwesomeModule, NgbTooltipModule, NgClass],
})
export class SankeyRegionsSelectorComponent extends CleanUp implements OnInit, OnDestroy {
  allOptions$: BehaviorSubject<SelectOption<string>[]>;
  initialRegions: string[];
  selectRegions$ = new BehaviorSubject(false);
  selectedOptions$ = new BehaviorSubject<SelectOption[]>([]);
  unselectedOptions$: BehaviorSubject<{ option: SelectOption; provisionallySelected: boolean }[]>;
  selectedCount$: Observable<number>;
  provisionallySelectedCount$: Observable<number>;
  notAllRegionsAreSelected$: Observable<boolean>;
  regionsAreProvisionallySelected$: Observable<boolean>;
  provisionallySelectedRegionsOverLimit$: Observable<boolean>;
  selectRegionsButtonText$: Observable<string>;
  addRegionsTooltip$: Observable<string>;
  selectRegionsTooltip$: Observable<string>;
  formControl: FormControl;
  MAX_SELECTION: number;

  constructor(private regions: RegionsOptions) {
    super();
    this.initialRegions = regions.initialRegions;
    this.MAX_SELECTION = regions.maxSelection;
    this.formControl = regions.form;
    this.formControl.setValue([]);
    this.allOptions$ = new BehaviorSubject(
      this.regions.regions.map((region) => new SelectOption(region, region)),
    );
    this.unselectedOptions$ = new BehaviorSubject<
      {
        option: SelectOption;
        provisionallySelected: boolean;
      }[]
    >(this.allOptions$.value.map((option) => ({ option, provisionallySelected: false })));
  }

  ngOnInit() {
    this.selectedCount$ = this.selectedOptions$.pipe(map((selected) => selected.length));
    this.provisionallySelectedCount$ = this.unselectedOptions$.pipe(
      map((selected) =>
        selected.reduce((acc, val) => (val.provisionallySelected ? acc + 1 : acc), 0),
      ),
    );
    this.notAllRegionsAreSelected$ = this.selectedCount$.pipe(
      map((count) => count < Math.min(this.MAX_SELECTION, this.allOptions$.value.length)),
    );
    this.regionsAreProvisionallySelected$ = this.provisionallySelectedCount$.pipe(
      map((count) => count > 0),
    );
    this.provisionallySelectedRegionsOverLimit$ = combineLatest([
      this.provisionallySelectedCount$,
      this.selectedCount$,
    ]).pipe(
      map(
        ([provisionalCount, selectedCount]) =>
          provisionalCount + selectedCount > this.MAX_SELECTION,
      ),
    );

    this.selectRegionsButtonText$ = this.provisionallySelectedCount$.pipe(
      map((count) =>
        count === 0 ? 'Select regions' : `Select ${count} region${count > 1 ? 's' : ''}`,
      ),
    );

    this.addRegionsTooltip$ = combineLatest([
      this.notAllRegionsAreSelected$,
      this.selectedCount$,
    ]).pipe(
      map(([notAll, selected]) =>
        notAll
          ? `Select up to ${this.MAX_SELECTION - selected} more region${
              this.MAX_SELECTION - selected > 1 ? 's' : ''
            }.`
          : `At most ${this.MAX_SELECTION} regions can be selected at once.`,
      ),
    );

    this.selectRegionsTooltip$ = combineLatest([
      this.selectedCount$,
      this.provisionallySelectedRegionsOverLimit$,
    ]).pipe(
      map(([selected, over]) =>
        over
          ? `At most ${this.MAX_SELECTION} regions can be selected at once. ${selected} region${
              selected > 1 ? 's were' : ' was'
            } previously added, so at most ${this.MAX_SELECTION - selected} more region${
              this.MAX_SELECTION - selected > 1 ? 's' : ''
            } can be added.`
          : null,
      ),
    );
    this.handleInitialRegions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.allOptions$.complete();
    this.selectRegions$.complete();
    this.selectedOptions$.complete();
    this.unselectedOptions$.complete();
    this.selectRegions$.complete();
  }

  onDropped(event: any) {
    const newSelectedOptions = [...this.selectedOptions$.value];
    moveItemInArray(newSelectedOptions, event.previousIndex, event.currentIndex);
    this.selectedOptions$.next(newSelectedOptions);
    this.setFormValue(newSelectedOptions);
  }

  setFormValue(options: SelectOption[]) {
    this.formControl.setValue(options.map(({ value }) => value));
  }

  selectOption(option: { option: SelectOption; provisionallySelected: boolean }) {
    const newUnselected = this.unselectedOptions$.value;
    const index = newUnselected.findIndex((opt) => opt.option.value === option.option.value);
    newUnselected[index].provisionallySelected = !newUnselected[index].provisionallySelected;
    this.unselectedOptions$.next(newUnselected);
  }

  onOptionRemoved(option: SelectOption) {
    let newSelected = this.selectedOptions$.value;
    let newUnselected = this.unselectedOptions$.value;
    const removeIndex = newSelected.findIndex((opt) => opt.value === option.value);
    newSelected = newSelected.filter((_, i) => i !== removeIndex);
    this.selectedOptions$.next(newSelected);
    newUnselected = [...newUnselected, { option, provisionallySelected: false }];
    newUnselected.sort((a, b) => sortAntibodyRegionByName(a.option.value, b.option.value));
    this.unselectedOptions$.next(newUnselected);
    this.setFormValue(newSelected);
  }

  onCancel() {
    const unselected = this.unselectedOptions$.value;
    this.unselectedOptions$.next(
      unselected.map(({ option }) => ({ option, provisionallySelected: false })),
    );
    this.selectRegions$.next(false);
  }

  onOptionsSelected() {
    const newSelected = this.selectedOptions$.value;
    const [toAdd, newUnselected] = partitionArray(
      this.unselectedOptions$.value,
      ({ provisionallySelected }) => provisionallySelected,
    );
    newSelected.push(...toAdd.map(({ option }) => option));
    this.unselectedOptions$.next(newUnselected);
    this.setFormValue(newSelected);
    this.selectRegions$.next(false);
  }

  trackByOptionValue(index: number, optionAndIndex: { option: SelectOption }) {
    return `${optionAndIndex.option.value}`;
  }

  trackByValue(index: number, option: SelectOption) {
    return `${option.value}`;
  }

  handleInitialRegions() {
    if (this.initialRegions.length > 0) {
      this.selectRegions$.next(true);
    }
    for (let initialRegion of this.initialRegions) {
      this.selectOption({
        option: new SelectOption(initialRegion, initialRegion),
        provisionallySelected: false,
      });
    }
    if (this.initialRegions.length > 0) {
      this.onOptionsSelected();
    }
    const newSelectedOptions = this.initialRegions.map(
      (region) => new SelectOption(region, region),
    );
    this.selectedOptions$.next(newSelectedOptions);
    this.setFormValue(newSelectedOptions);
  }

  protected readonly faGripHorizontal = faGripHorizontal;
  protected readonly faTimes = faTimes;
}
