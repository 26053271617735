import { UploadsTableStoreService } from '../../upload/uploads-table/uploads-table.store-service';
import { FileUploadGroup } from '../../models/file-upload.model';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import {
  FolderTreeItem,
  PrimerSetsFolder,
  PrimersRootFolder,
} from '../../folders/models/folder.model';
import { GridComponent, SelectionStateV2 } from '../../../features/grid/grid.component';
import { ColDef } from '@ag-grid-community/core';
import { CleanUp } from '../../../shared/cleanup';
import { PermissionsService } from '../../permissions/permissions.service';
import { Observable, Subscription } from 'rxjs';
import { FolderService } from '../../folders/folder.service';
import { PrimersGridResource } from '../../primers/grid-resource/primers-grid-resource';
import { FormatterService } from '../../../shared/formatter.service';
import { PrimersService } from '../../primers/primers.service';
import { UtilService } from '../../../shared/util.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { PrimersCreateSetDialogComponent } from '../../primers/create-set-dialog/primers-create-set-dialog.component';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { selectedFolderID } from '../../folders/store/folder.selectors';
import { Item } from '@geneious/nucleus-api-client';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { UploadButtonDirective } from '../../upload/upload-button.directive';
import { ExportToolstripItemComponent } from '../../export/export-toolstrip-item/export-toolstrip-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-primers-root-folder-detail',
  templateUrl: './primers-root-folder-detail.component.html',
  providers: [PrimersGridResource],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ToolstripComponent,
    ToolstripItemComponent,
    UploadButtonDirective,
    ExportToolstripItemComponent,
    GridComponent,
    AsyncPipe,
  ],
})
export class PrimersRootFolderDetailComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column h-100 w-100';

  folder$: Observable<PrimersRootFolder | PrimerSetsFolder>;
  loaded: boolean;
  canWrite: boolean;
  datasourceParams$: Observable<{ folderId: string }>;
  selected: SelectionStateV2<Item> = {
    totalSelected: 0,
    total: 0,
    ids: [],
    selectedRows: [],
    rows: [],
    selectAll: false,
  };

  @ViewChild(GridComponent, { static: true }) primersTable: GridComponent;

  columnDefs: ColDef[];
  datasource: PrimersGridResource;

  private filesUploaded$: Observable<FileUploadGroup[]>;
  private filesUploadedSubscription = Subscription.EMPTY;

  constructor(
    datasource: PrimersGridResource,
    protected store: Store<AppState>,
    protected primersService: PrimersService,
    protected folderService: FolderService,
    protected uploadsTableStoreService: UploadsTableStoreService,
    private utilService: UtilService,
    readonly dialogService: DialogService,
  ) {
    super();
    this.datasource = datasource;

    // NB: value getter's `param.data` object maps the column headers to values e.g., { name: 'foo', 'sequence_length': 10 }
    //     as such the property of `data` to use should be the same value used for the column definition's `field` property
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'name',
        cellClass: 'name-cell',
        width: 200,
        valueGetter: (params) =>
          params.data && params.data.name
            ? this.utilService.sanitizeCell(FormatterService.emptyIfMissing(params.data.name))
            : '',
      },

      {
        headerName: 'Sequence Length',
        field: 'sequence_length',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['sequence_length']) : '',
      },
      {
        headerName: 'Oligo Type',
        field: 'oligoType',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['oligoType']) : '',
      },
      {
        headerName: `Sequence (5'-3')`,
        field: 'sequence_residues',
        width: 250,
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['sequence_residues']) : '',
        valueFormatter: (cell) => {
          // Only show first 100 nucleotides - if it is longer than that it is likely not a primer.
          const sequence =
            cell.value && cell.value.length > 100
              ? `${cell.value.substring(0, 100)}...`
              : cell.value;
          return FormatterService.dashIfMissing(sequence);
        },
      },
      {
        headerName: 'Modified Date',
        field: 'modifiedAt',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['modifiedAt']) : '',
      },
      {
        headerName: '%GC',
        // NOTE gcPercent field is not useful as it pertains to the whole file.
        // Instead use percentGc pertains to the individual sequence.
        field: 'percentGc',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['gcPercent']) : '',
      },
      {
        headerName: 'Tm',
        field: 'meltingPoint',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['meltingPoint']) : '',
      },
      {
        headerName: 'Hairpin Tm',
        field: 'hairpin',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['hairpin']) : '',
      },
      {
        headerName: 'Self Dimer Tm',
        field: 'primerDimer',
        valueGetter: (params) =>
          params.data ? FormatterService.emptyIfMissing(params.data['primerDimer']) : '',
      },
      {
        headerName: 'Description',
        field: 'description',
        cellClass: 'description-cell',
        width: 200,
        valueGetter: (params) =>
          params.data && params.data.description
            ? this.utilService.sanitizeCell(
                FormatterService.emptyIfMissing(params.data.description),
              )
            : '',
      },
    ];
  }

  ngOnInit() {
    const folderId$ = this.store.select(selectedFolderID).pipe(
      // Execute the first load synchronously, so that we do not need to add *ngIfs to child components.
      tap((folderID) => this.resetData(folderID)),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.ngUnsubscribe),
    );

    this.datasourceParams$ = folderId$.pipe(map((folderId: any) => Object.assign({ folderId })));

    this.folder$ = folderId$.pipe(
      switchMap((folderId) => this.folderService.get(folderId)),
      distinctUntilChanged((first, last) => first.id === last.id),
    );

    this.folder$.subscribe((folder) => this.onParamChange(folder));

    folderId$.subscribe();
  }

  onParamChange(folder: any) {
    this.resetData(folder.id);
    this.updateFolder(folder);
  }

  onSelectionChanged(selectionState: SelectionStateV2) {
    this.selected = selectionState as unknown as SelectionStateV2<Item>;
  }

  deletePrimers() {
    const selectedIds = this.selected.ids;
    const selectAllState = this.selected.selectAll;
    const selectedRowsCount = this.selected.totalSelected;

    const noun =
      selectedRowsCount === 1 ? this.selected.selectedRows[0].name : `${selectedRowsCount} primers`;

    this.dialogService
      .showConfirmationDialog$({ title: `Delete ${noun} permanently?` })
      .pipe(
        filter((result) => !!result),
        switchMap(() => this.folder$),
        switchMap((folder) =>
          this.primersService
            .deletePrimers(folder.id, selectedIds, selectAllState)
            .pipe(map(() => this.primersTable.removeRows(selectedIds))),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  createPrimersSet() {
    this.folder$
      .pipe(
        map((folder) => ({
          parentID: folder.id,
          ids: this.selected.ids,
        })),
        switchMap((dialogData) =>
          this.dialogService.showDialogV2$({
            component: PrimersCreateSetDialogComponent,
            injectableData: dialogData,
          }),
        ),
        take(1),
      )
      .subscribe();
  }

  private resetData(folderID: string) {
    this.selected = {
      ids: [],
      selectAll: false,
      totalSelected: 0,
      total: 0,
      rows: [],
      selectedRows: [],
    };
    // This only returns Done Upload Activities that are related to this Folder.
    this.filesUploaded$ = this.uploadsTableStoreService.listenToCompletedUploads({
      folderID: folderID,
    });

    this.filesUploadedSubscription.unsubscribe();
    // TODO workout how to get the actual upload complete activity for primers as there is a large delay until it is actually completed.
    this.filesUploadedSubscription = this.filesUploaded$
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(2000))
      .subscribe(() => {
        this.primersTable.refresh();
      });
  }

  private updateFolder(folderResponse: FolderTreeItem) {
    if (folderResponse) {
      this.loaded = true;
      this.canWrite = PermissionsService.hasWriteAccess(folderResponse.permissions);
    } else {
      this.canWrite = false;
      this.loaded = false;
    }
  }
}
