import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'bx-context-menu',
  templateUrl: './context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class ContextMenuComponent implements OnInit, OnChanges {
  @HostBinding('class') readonly hostClass = 'position-absolute dropdown';
  @HostBinding('style.left') leftPosition = '0px';
  @HostBinding('style.top') topPosition = '0px';

  @Input() left: number;
  @Input() top: number;
  @Input() items: ContextMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.leftPosition = `${this.left}px`;
    this.topPosition = `${this.top}px`;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.leftPosition = `${this.left}px`;
    this.topPosition = `${this.top}px`;
  }
}

export interface ContextMenuItem {
  label: string;
  action: () => void;
}
