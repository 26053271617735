export interface OverlayEvent<
  V extends OverlayEventData = OverlayEventData,
  T extends OverlayEventType = OverlayEventType,
> {
  // String Key. So the overlay knows which component to generate for.
  id: string;
  data?: V;
  type: T;
}

export enum OverlayEventType {
  MESSAGE = 'message',
  LOADING = 'loading',
  HIDE = 'hide',
}

export type OverlayEventData = OverlayMessageEvent | OverlayLoadingEvent;

export interface OverlayMessageEvent {
  message?: string;
  html?: string;
  textAlign?: 'left' | 'center' | 'right';
}

export interface OverlayLoadingEvent {
  message?: string;
  html?: string;
}
