import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { ErrorFormatter } from '../../error-formatter.service';
import { LogoComponent } from '../../../shared/logo/logo.component';

import { PasswordStrengthComponent } from '../../../shared/password-strength/password-strength.component';
import { ValidatePasswordDirective } from '../../../shared/password-strength/validate-password.directive';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { ValidateEqualDirective } from '../../../shared/equals/validate-equal.directive';
import { SpinnerButtonComponent } from '../../../shared/spinner-button/spinner-button.component';
import { AuthService } from '@geneious/nucleus-api-client';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'bx-account-setup',
  templateUrl: './account-setup.component.html',
  standalone: true,
  imports: [
    FormsModule,
    LogoComponent,
    PasswordStrengthComponent,
    ValidatePasswordDirective,
    NgFormControlValidatorDirective,
    ValidateEqualDirective,
    SpinnerButtonComponent,
  ],
})
export class AccountSetupComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'h-100';
  email: string;
  name: string;
  confirmPassword: string;
  password: string;
  processing = false;
  canChangePassword = false;
  newPasswordRequired = true;

  private token: string;
  private dialogRef: NgbModalRef;
  accountSetupErrors?: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private errorFormatter: ErrorFormatter,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.fragment;
    this.authService
      .validateToken({ token: this.token })
      .pipe(pluck('data'))
      .subscribe((tokenInfo) => {
        this.canChangePassword = tokenInfo.changePassword;
        this.newPasswordRequired = tokenInfo.newPasswordRequired ?? true;
        this.email = tokenInfo.user.email;
        this.name = `${tokenInfo.user.givenName} ${tokenInfo.user.familyName ?? ''}`;
      });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.dismiss();
    }
  }

  public submit(accountForm: NgForm) {
    if (accountForm.valid) {
      this.processing = true;
      this.accountSetupErrors = null;
      this.authService.consume({ token: this.token, newPassword: this.password }).subscribe({
        next: () => this.router.navigate(['/account-setup/complete']),
        error: (error) => {
          this.processing = false;
          this.accountSetupErrors = this.errorFormatter.toUserReadableError(
            error,
            'Account set up cannot be completed.',
          );
        },
      });
    }
  }
}
