<div class="modal-header">
  <h4 class="modal-title">Deactivate User</h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="ngbModalRef.dismiss(false)"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="text-center mb-3">
    <label class="form-label d-block"
      >Choose what Folders you'd like to transfer from the User</label
    >
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="folder-transfer-strategy-all"
        formControlName="folderTransferStrategy"
        value="transferAll"
      />
      <label class="form-check-label" for="folder-transfer-strategy-all">All</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="folder-transfer-strategy-shared"
        formControlName="folderTransferStrategy"
        value="transferShared"
      />
      <label class="form-check-label" for="folder-transfer-strategy-shared">Shared</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="folder-transfer-strategy-none"
        formControlName="folderTransferStrategy"
        value="none"
      />
      <label class="form-check-label" for="folder-transfer-strategy-none">None</label>
    </div>
  </form>
  <p class="text-center" [innerHTML]="description$ | async"></p>
</div>
<div class="modal-footer">
  <button
    class="btn btn-danger"
    bx-spinner-button
    [spin]="submitting$ | async"
    [disabled]="submitting$ | async"
    (click)="deactivateUserEvent$.next()"
  >
    Deactivate
  </button>
  <button class="btn btn-secondary" (click)="ngbModalRef.dismiss(false)" ngbAutofocus>
    Cancel
  </button>
</div>
