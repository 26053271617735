<form [formGroup]="form" class="h-100">
  <div class="input-group">
    <input
      type="search"
      formControlName="filter"
      placeholder="Filter columns..."
      class="form-control form-control-sm mt-1 mb-2"
      data-testid="filter-columns"
    />
  </div>
  <div class="btn-group btn-group-sm mb-2">
    <button type="button" class="btn btn-sm btn-light" (click)="setAllVisible()">all</button>
    <button type="button" class="btn btn-sm btn-light" (click)="setAllVisible(false)">none</button>
    <button type="button" class="btn btn-sm btn-light" (click)="reset()">default</button>
  </div>
  <div class="columns-list" formGroupName="columns" class="w-100">
    @for (group of filteredGroupedColumns$ | async; track group.name) {
      <div class="w-100">
        <div class="d-flex align-items-center">
          <small class="text-muted">{{ group.value.headerName }}</small>
          @if (group.value.deletable) {
            <button
              class="btn py-0 px-2 delete-assay-data text-muted"
              ngbTooltip="Delete Assay Data"
              (click)="removeGroup(group)"
            >
              ×
            </button>
          }
        </div>
        <div class="ps-2 mb-2">
          @for (column of group.value.cols; track column.getColId()) {
            <div
              [attr.data-testid]="'column-management-sidebar-row-' + column?.getColDef().headerName"
              class="w-100 bx-hover-parent"
            >
              <div class="form-check">
                <input
                  [id]="inputIDPrefix + column.getColId()"
                  type="checkbox"
                  [formControlName]="column.getColId()"
                  class="form-check-input"
                />
                <label
                  [for]="inputIDPrefix + column.getColId()"
                  class="form-check-label d-inline-flex flex-nowrap w-100"
                >
                  <small
                    class="flex-shrink-1 text-truncate"
                    [title]="column.getColDef().headerName"
                  >
                    {{ column.getColDef().headerName }}
                  </small>
                  @if (column.isVisible()) {
                    <fa-icon
                      [icon]="focusIcon"
                      role="button"
                      class="text-muted small bx-display-on-parent-hover ms-auto ps-1 align-middle"
                      (click)="onFocusColumn($event, column.getColId())"
                      ngbTooltip="Focus Column"
                      data-testid="focus-column-btn"
                    ></fa-icon>
                  }
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</form>
