import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Observable, of, Subscription, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  share,
  shareReplay,
  startWith,
  tap,
} from 'rxjs/operators';
import { restrictControlValue } from 'src/app/shared/utils/forms';
import {
  BatchAssembleJobOptionsV1,
  BatchAssembleJobParametersV1,
} from '../../../../nucleus/services/models/batchAssembleOptions.model';
import { DocumentHttpV2Service } from '../../../../nucleus/v2/document-http.v2.service';
import { SelectionState } from '../../../features/grid/grid.component';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { PipelineService } from '../../pipeline/pipeline.service';
import { SequenceDataService } from '../../sequence-viewer/sequence-data.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { CardComponent } from '../../../shared/card/card.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-batch-assemble',
  templateUrl: './batch-assemble.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgClass,
    SpinnerComponent,
    ShowIfDirective,
    MatIconModule,
    NgbTooltip,
    NgFormControlValidatorDirective,
    FaIconComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class BatchAssembleComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  earlyRelease: false;
  title = 'Batch Assemble Sanger Sequences';
  knowledgeBaseArticle: string;
  // Enabled is null.
  nameSchemeRadioDisabled: boolean | null = null;
  nameSchemeWarningMessage: string;
  exclamationIcon = faExclamationTriangle;

  public readonly nameParts = [
    { value: 0, label: '1st' },
    { value: 1, label: '2nd' },
    { value: 2, label: '3rd' },
    { value: 3, label: '4th' },
    { value: 4, label: '5th' },
    { value: 5, label: '6th' },
    { value: 6, label: '7th' },
    { value: 7, label: '8th' },
    { value: 8, label: '9th' },
    { value: 9, label: '10th' },
  ];
  readonly form = new BxFormGroup({
    outputFolderName: JobDialogContent.getResultNameControl(),
    groupAssemblies: new BxFormControl<
      'groupAssemblies' | 'assembleListsSeparately' | 'assembleByNameScheme'
    >('groupAssemblies'),
    namePart: new BxFormControl(this.nameParts[0].value, Validators.required),
    nameSeparator: new BxFormControl('-', Validators.required),
    callChromatogramHeterozygotes: new BxFormControl(true),
    chromatogramHeterozygotePercentage: new BxFormControl(50),
    saveUnusedReads: new BxFormControl(false),
    generateContigs: new BxFormControl(false),
    consensusSequencesAsList: new BxFormControl(true),
    fileNameSchemeID: new BxFormControl<string>(undefined, Validators.required),
  });
  preview$: Observable<any>;
  previewError$: Observable<boolean>;
  nameSchemes$: Observable<SelectOption[]>;

  private readonly formDefaults: any;
  private readonly selected: SelectionState;
  private subscriptions = new Subscription();

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private sequenceDataService: SequenceDataService,
    private pipelineService: PipelineService,
    private documentHttpV2Service: DocumentHttpV2Service,
  ) {
    super('batch-assemble', PipelineFormID.BATCH_ASSEMBLE);

    this.formDefaults = this.form.getRawValue();
    this.selected = this.dialogData.selected;
  }

  ngOnInit() {
    this.resetIfOldSettings();
    // User must select at least one sequence to open the dialog; no need to handle null check.
    const firstRow = this.selected.ids[0];
    const sequence$ = this.sequenceDataService
      .getSequenceForEntity(firstRow)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const form$ = this.form.valueChanges.pipe(
      // Set the first value.
      startWith(this.form.getRawValue()),
      filter((value) => typeof value.namePart !== 'undefined' && !!value.nameSeparator),
    );

    this.subscriptions.add(this.toggleEnabledStateByGroupAssemblies());

    this.preview$ = sequence$.pipe(
      mergeMap((sequences) =>
        form$.pipe(
          map((formValue) => {
            // Sequences should always return an array for non-alignment sequences.
            if (Array.isArray(sequences)) {
              return { sequences, formValue };
            } else {
              return {
                sequences: [],
                formValue: formValue,
              };
            }
          }),
        ),
      ),
      map((all) =>
        BatchAssembleComponent.formatPreviewString(all.sequences[0].sequence.name, all.formValue),
      ),
      share(),
    );

    this.previewError$ = this.preview$.pipe(
      mapTo(false),
      catchError(() => of(true)),
    );

    this.nameSchemes$ = this.pipelineService.externalServices.nameSchemes.valueSource(['id']).pipe(
      first(),
      // Restore the last used name scheme from the document(s) selected.
      tap((nameSchemes: SelectOption[]) => {
        const fileNameSchemeIDControl = this.form.controls.fileNameSchemeID;
        const nameSchemesSetOnDocuments = this.pipelineService.nameSchemesSetOnDocuments(
          this.selected,
        );

        if (nameSchemesSetOnDocuments.size > 0) {
          const firstScheme = nameSchemesSetOnDocuments.values().next().value;

          // Restore the single saved scheme if there is only one and it exists on the server.
          if (
            nameSchemesSetOnDocuments.size === 1 &&
            nameSchemes.find((scheme) => scheme.value === firstScheme)
          ) {
            fileNameSchemeIDControl.reset(firstScheme);
          } else {
            fileNameSchemeIDControl.reset(null);
          }

          this.nameSchemeWarningMessage =
            'The selected document(s) already have one or more name schemes associated. Running this operation will overwrite them with the selected name scheme.';
        } else if (nameSchemes.length === 0) {
          if (this.form.controls.groupAssemblies.value === 'assembleByNameScheme') {
            this.form.controls.groupAssemblies.setValue('groupAssemblies');
          }
          this.nameSchemeRadioDisabled = true;
          this.nameSchemeWarningMessage =
            'No name schemes present, please create one or talk to your organization administrator.';
          // The documents selected have no name schemes associated with them, set the last used scheme.
        }
        restrictControlValue(
          fileNameSchemeIDControl,
          nameSchemes.map((option) => option.value),
          {
            takeUntil: this.ngUnsubscribe,
          },
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  static formatPreviewString(
    exampleFileName: string,
    { namePart, nameSeparator }: { namePart?: number; nameSeparator?: string },
  ): {
    fileNameParts: { text: string; isTarget: boolean }[];
    match: string;
    targetNumber: number;
    nameSeparator: string;
  } {
    // Name part is one based.
    const targetIndex = Number(namePart);
    const nameParts = exampleFileName.split(nameSeparator);
    const match = nameParts[targetIndex];
    const fileNameParts = nameParts.map((text, index) => ({
      text,
      isTarget: targetIndex === index,
    }));
    return { fileNameParts, match, targetNumber: targetIndex + 1, nameSeparator };
  }

  run() {
    const formValue = this.form.value;

    const jobOptions: BatchAssembleJobOptionsV1 = {
      namePart: formValue.namePart,
      nameSeparator: formValue.nameSeparator,
      groupAssemblies: formValue.groupAssemblies === 'groupAssemblies',
      assembleListsSeparately: formValue.groupAssemblies === 'assembleListsSeparately',
      assembleByNameScheme: formValue.groupAssemblies === 'assembleByNameScheme',
      callChromatogramHeterozygotes: formValue.callChromatogramHeterozygotes,
      chromatogramHeterozygotePercentage: formValue.chromatogramHeterozygotePercentage,
      saveUnusedReads: formValue.saveUnusedReads,
      generateContigs: formValue.generateContigs,
      consensusSequencesAsList: formValue.consensusSequencesAsList,
      fileNameSchemeID: formValue.fileNameSchemeID,
    };

    const outputFolderName = formValue.outputFolderName;

    // Handle saving the selected name scheme for the selected document(s), if required.
    if (formValue.groupAssemblies === 'assembleByNameScheme') {
      this.selected.selectedRows.forEach((doc) => {
        if (
          !doc.metadata.fileNameSchemeID ||
          doc.metadata.fileNameSchemeID !== formValue.fileNameSchemeID
        ) {
          this.documentHttpV2Service
            .upsertMetadata(doc.id, { fileNameSchemeID: formValue.fileNameSchemeID })
            .subscribe();
        }
      });
    }

    const parameters = {
      options: jobOptions,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    // BX-5018 found that there is some combination of actions in a new org that can result in a name scheme appearing in the dropdown
    // but the value sent to pipelines is null. In case this makes it past the validator, this if statement will catch these occurrences.
    if (
      formValue.groupAssemblies === 'assembleByNameScheme' &&
      formValue.fileNameSchemeID == null
    ) {
      return throwError({ error: { name: 'BatchAssemblyNameSchemeNull' } });
    } else {
      return new BatchAssembleJobParametersV1(parameters);
    }
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  /**
   * The form options for this pipeline have been changed multiple times. That means there could be
   * users with old saved form settings for this pipeline.
   * groupAssemblies being a boolean is a good indication the form settings are old.
   */
  private resetIfOldSettings() {
    if (typeof this.form.value.groupAssemblies === 'boolean') {
      this.form.patchValue(this.getFormDefaults());
    }
  }

  private toggleEnabledStateByGroupAssemblies(): Subscription {
    return this.form
      .get('groupAssemblies')
      .valueChanges.pipe(startWith(this.form.value.groupAssemblies))
      .subscribe((value) => {
        const namePartControl = this.form.get('namePart');
        const nameSeparatorControl = this.form.get('nameSeparator');
        const fileNameSchemeIDControl = this.form.get('fileNameSchemeID');
        if (value !== 'groupAssemblies') {
          namePartControl.disable();
          nameSeparatorControl.disable();
        } else {
          namePartControl.enable();
          nameSeparatorControl.enable();
        }

        if (value !== 'assembleByNameScheme') {
          fileNameSchemeIDControl.disable();
        } else {
          fileNameSchemeIDControl.enable();
        }
      });
  }
}
