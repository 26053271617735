import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScoreScreenComponent } from './score-screen/score-screen.component';
import { Point2D } from '../../../shared/interfaces';

interface Nucleotide {
  letter: string;
  color: string;
  position: Point2D;
}

@Component({
  selector: 'bx-snake',
  templateUrl: './snake.component.html',
  standalone: true,
})
export class SnakeComponent implements OnInit {
  @HostBinding('class') readonly hostClass =
    'h-100 d-flex justify-content-center align-items-center';
  @Output() stop = new EventEmitter<boolean>();

  private currentFrame = 0;
  private snakeLength = 3;
  private snake: Point2D[] = [];
  private cellSize = 20;
  private areaX: number[] = [];
  private areaY: number[] = [];
  private special: any[] = [
    { letter: '-', color: 'black', position: null, ability: () => this.halfSnake() },
    { letter: '⚡', color: '#AFA101', position: null, ability: () => this.speedUp() },
    { letter: '🐌', color: 'green', position: null, ability: () => this.slowDown() },
  ];
  private currentSnake: any[] = [];
  private loop: any;
  private nucleotides: Nucleotide[] = [
    { letter: 'A', color: '#B20000', position: null },
    { letter: 'U', color: '#00B200', position: null },
    { letter: 'G', color: '#AFA101', position: null },
    { letter: 'C', color: '#0000B2', position: null },
  ];
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private scoreIs: HTMLElement;
  private direction: string;
  private directionQueue: string;
  private mode: number;
  private modalRef: NgbModalRef;
  private framesPerMove = 4;
  private readonly framesPerSecond = 60;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d');
    this.scoreIs = document.getElementById('score');

    this.canvas.width = 501;
    this.canvas.height = 501;

    for (let i = 0; i <= this.canvas.width - this.cellSize; i += this.cellSize) {
      this.areaX.push(i);
      this.areaY.push(i);
    }

    this.ctx.font = "24px 'Open Sans', sans-serif";
    this.ctx.textBaseline = 'alphabetic';
    this.ctx.textAlign = 'center';

    this.newGame();
  }

  onKeyDown(evt: any) {
    evt = evt || window.event;
    this.changeDirection(evt.keyCode);
  }

  get getSequence(): string {
    let sequence = '';
    this.currentSnake.forEach((nucleotide) => {
      sequence += nucleotide.letter;
    });
    return sequence;
  }

  private game() {
    const head = this.snake[0];
    if (
      head.x < 0 ||
      head.x > this.canvas.width - this.cellSize ||
      head.y < 0 ||
      head.y > this.canvas.height - this.cellSize
    ) {
      this.gameOver();
    }

    for (let i = 1; i < this.snake.length; i++) {
      if (head.x === this.snake[i].x && head.y === this.snake[i].y) {
        this.gameOver();
      }
    }

    this.nucleotides.forEach((nucleotide) => {
      if (
        nucleotide.position &&
        this.checkCollision(head.x, head.y, nucleotide.position.x, nucleotide.position.y)
      ) {
        this.currentSnake[this.currentSnake.length] = {
          letter: nucleotide.letter,
          color: nucleotide.color,
        };
        this.snake[this.snake.length] = { ...this.snake[this.snake.length - 1] };
        nucleotide.position = null;
        this.createObjects();
        this.drawObject();
      }
    });

    this.special.forEach((special) => {
      if (
        special.position &&
        this.checkCollision(head.x, head.y, special.position.x, special.position.y)
      ) {
        special.position = null;
        special.ability();
      }
    });

    this.ctx.beginPath();
    this.setBackground();
    this.drawSnake();
    this.drawObject();
    this.moveSnake();
  }

  private newGame(value = 0) {
    this.canvas.setAttribute('tabindex', '1');
    this.canvas.style.outline = 'none';
    this.canvas.focus();

    this.mode = value;
    this.direction = 'right';
    this.directionQueue = 'right';
    this.ctx.beginPath();
    this.createSnake();
    this.createObjects();

    if (typeof this.loop !== 'undefined') {
      clearInterval(this.loop);
    } else {
      this.loop = setInterval(() => this.game(), 1000 / this.framesPerSecond);
    }
  }

  private gameOver() {
    this.modalRef = this.modalService.open(ScoreScreenComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });
    this.modalRef.componentInstance.sequence = this.getSequence;
    this.modalRef.result.then(
      (value) => this.newGame(value),
      () => this.stop.emit(true),
    );
    clearInterval(this.loop);
    this.loop = undefined;
  }

  private checkCollision(x1: number, y1: number, x2: number, y2: number) {
    return x1 === x2 && y1 === y2;
  }

  private setBackground() {
    this.ctx.fillStyle = '#FFF';
    this.ctx.strokeStyle = '#DDD';

    this.ctx.fillRect(0, 0, this.canvas.height, this.canvas.width);

    for (let x = 0.5; x < this.canvas.width; x += this.cellSize) {
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvas.height);
    }
    for (let y = 0.5; y < this.canvas.height; y += this.cellSize) {
      this.ctx.moveTo(0, y);
      this.ctx.lineTo(this.canvas.width, y);
    }

    this.ctx.stroke();
  }

  private createSnake() {
    this.snake = [];
    this.currentSnake = [];
    for (let i = this.snakeLength; i > 0; i--) {
      const k = i * this.cellSize;
      this.snake.push({ x: k, y: 0 });
    }
    this.currentSnake.push(this.nucleotides[0]);
    this.currentSnake.push(this.nucleotides[1]);
    this.currentSnake.push(this.nucleotides[2]);
  }

  private drawSnake() {
    for (let i = 0; i < this.snake.length; i++) {
      let x = this.snake[i].x;
      let y = this.snake[i].y;
      if (i === 0) {
        if (this.direction === 'right') {
          x += (this.cellSize / this.framesPerMove) * this.currentFrame;
        } else if (this.direction === 'left') {
          x -= (this.cellSize / this.framesPerMove) * this.currentFrame;
        } else if (this.direction === 'up') {
          y -= (this.cellSize / this.framesPerMove) * this.currentFrame;
        } else if (this.direction === 'down') {
          y += (this.cellSize / this.framesPerMove) * this.currentFrame;
        }
      } else {
        x += ((this.snake[i - 1].x - this.snake[i].x) / this.framesPerMove) * this.currentFrame;
        y += ((this.snake[i - 1].y - this.snake[i].y) / this.framesPerMove) * this.currentFrame;
      }
      const { letter, color } = this.currentSnake[i];
      this.ctx.fillStyle = color;
      this.ctx.fillText(letter, x + this.cellSize / 2, y + this.cellSize);
    }
  }

  private changeDirection(keycode: number) {
    if (keycode === 37 && this.direction !== 'right') {
      this.directionQueue = 'left';
    } else if (keycode === 38 && this.direction !== 'down') {
      this.directionQueue = 'up';
    } else if (keycode === 39 && this.direction !== 'left') {
      this.directionQueue = 'right';
    } else if (keycode === 40 && this.direction !== 'up') {
      this.directionQueue = 'down';
    }
  }

  private moveSnake() {
    if (this.currentFrame++ >= this.framesPerMove - 1) {
      let x = this.snake[0].x;
      let y = this.snake[0].y;

      if (this.direction === 'right') {
        x += this.cellSize;
      } else if (this.direction === 'left') {
        x -= this.cellSize;
      } else if (this.direction === 'up') {
        y -= this.cellSize;
      } else if (this.direction === 'down') {
        y += this.cellSize;
      }

      this.direction = this.directionQueue;
      this.currentFrame = 0;

      const tail = this.snake.pop();
      tail.x = x;
      tail.y = y;
      this.snake.unshift(tail);
    }
  }

  private createObjects() {
    if (this.mode === 0) {
      const nucleotide = this.nucleotides[Math.floor(Math.random() * this.nucleotides.length)];
      this._createObject(nucleotide);
    } else if (this.mode === 1) {
      if (Math.random() > 0.8) {
        const powerUp = this.special[Math.floor(Math.random() * this.special.length)];
        this._createObject(powerUp);
      }
      this.nucleotides.forEach((nucleotide) => {
        if (!nucleotide.position) {
          this._createObject(nucleotide);
        }
      });
    }
  }

  private _createObject(nucleotide: any) {
    nucleotide.position = {
      x: this.areaX[Math.floor(Math.random() * this.areaX.length)],
      y: this.areaY[Math.floor(Math.random() * this.areaY.length)],
    };

    for (let i = 0; i < this.snake.length; i++) {
      if (
        this.checkCollision(
          nucleotide.position.x,
          nucleotide.position.y,
          this.snake[i].x,
          this.snake[i].y,
        )
      ) {
        this._createObject(nucleotide);
      }
    }
  }

  private drawObject() {
    this.nucleotides.forEach((nucleotide) => {
      this.drawText(nucleotide);
    });

    this.special.forEach((special) => {
      this.drawText(special);
    });
  }

  private drawText({ letter, color, position }: any) {
    if (position) {
      this.ctx.fillStyle = color;
      this.ctx.fillText(letter, position.x + this.cellSize / 2, position.y + this.cellSize);
    }
  }

  private halfSnake() {
    const half = Math.ceil(this.snake.length / 2);
    this.snake = this.snake.slice(0, half);
    this.currentSnake = this.currentSnake.slice(0, half);
  }

  private speedUp() {
    this.framesPerMove = 2;
    setTimeout(() => {
      this.framesPerMove = 4;
    }, 10000);
  }

  private slowDown() {
    this.framesPerMove = 6;
    setTimeout(() => {
      this.framesPerMove = 4;
    }, 10000);
  }
}
