import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';
import { AntibodyAnnotatorOptionValues } from '../../../app/core/pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';

export class PeptideAnnotatorJobParametersV1 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'peptide-annotator',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: PeptideAnnotatorJobOptionsV1;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: PeptideAnnotatorJobOptionsV1,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}

export interface PeptideAnnotatorJobOptionsV1 {
  resultName: string;
  optionValues: AntibodyAnnotatorOptionValues; // For now, might want to extend this type later...
}
