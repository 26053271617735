import { Entity } from './entity.model';

export enum UploadState {
  PREPARING = 'Preparing',
  QUEUED = 'Queued',
  CANCELLED = 'Cancelled',
  CANCELLING = 'Cancelling',
  UPLOADING = 'Uploading',
  IMPORTING = 'Importing',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
  COMPLETED_WITH_ERRORS = 'CompletedWithErrors',
}

export interface UploadGroupStatus {
  kind: UploadState;
  messages: string[];
  progress?: number;
  updatedAt: string;
}

export interface UploadingStatus extends UploadGroupStatus {
  kind: UploadState.UPLOADING;
}

export interface CompletedStatus extends UploadGroupStatus {
  kind: UploadState.COMPLETED;
}

export interface CompletedWithErrorsStatus extends UploadGroupStatus {
  kind: UploadState.COMPLETED_WITH_ERRORS;
}

export interface ImportingStatus extends UploadGroupStatus {
  kind: UploadState.IMPORTING;
}

export interface FailedStatus extends UploadGroupStatus {
  kind: UploadState.FAILED;
}

export interface CancelledStatus extends UploadGroupStatus {
  kind: UploadState.CANCELLED;
}

export interface CancellingStatus extends UploadGroupStatus {
  kind: UploadState.CANCELLING;
}

export class FileUploadGroup extends Entity {
  status: UploadGroupStatus;
  errors: string;
  startedAt: string;
  finishedAt: string;
  progress: number;
  route: string;
  folderName: string;
  folderID: string;
  fileIDs: string[];
  fileCount: number;
  totalSize: number;

  constructor() {
    super();
  }

  isUploading() {
    return (
      [
        UploadState.PREPARING,
        UploadState.QUEUED,
        UploadState.CANCELLING,
        UploadState.UPLOADING,
      ].indexOf(this.status.kind) !== -1
    );
  }

  isImporting() {
    return [UploadState.IMPORTING].indexOf(this.status.kind) !== -1;
  }

  isFailed() {
    return [UploadState.FAILED].indexOf(this.status.kind) !== -1;
  }

  isComplete() {
    return [UploadState.COMPLETED].indexOf(this.status.kind) !== -1;
  }

  isCompleteWithErrors() {
    return [UploadState.COMPLETED_WITH_ERRORS].indexOf(this.status.kind) !== -1;
  }

  isDone() {
    return (
      [
        UploadState.COMPLETED,
        UploadState.FAILED,
        UploadState.CANCELLED,
        UploadState.COMPLETED_WITH_ERRORS,
      ].indexOf(this.status.kind) !== -1
    );
  }

  static fromJson(json: any) {
    const uploadGroup = new FileUploadGroup();
    uploadGroup.id = json.id;
    uploadGroup.status = json.status;
    uploadGroup.errors = json.errors;
    uploadGroup.startedAt = json.startedAt;
    uploadGroup.finishedAt = json.finishedAt;
    uploadGroup.route = json.route;
    uploadGroup.folderName = json.folderName;
    uploadGroup.folderID = json.folderID;
    uploadGroup.fileIDs = json.fileIDs;
    uploadGroup.fileCount = json.fileCount;
    return uploadGroup;
  }
}
