<bx-collapsible-card
  headerTitle="History"
  (collapseChange)="collapsed$.next($event)"
  [compact]="true"
>
  @if (historyEvents$ | async; as historyEvents) {
    <ul class="list-group list-group-flush mb-0">
      @if (historyEvents.length > 0) {
        @for (historyEvent of historyEvents; track historyEvent) {
          <button
            class="list-group-item list-group-item-action py-0 d-flex flex-row w-100 h-100"
            title="View Event Details"
            [disabled]="historyEvent.placeholder || !historyEvent.job"
            (click)="modalService.open(jobDetailsModal, { size: 'lg' })"
          >
            <div class="d-flex flex-row w-100 h-100">
              <div class="timeline-segment d-flex flex-column me-4">
                <div class="timeline-line timeline-line-top"></div>
                <div class="timeline-dot"></div>
                <div class="timeline-line timeline-line-bottom"></div>
              </div>
              <bx-document-history-item
                #item
                class="py-3"
                [historyEvent]="historyEvent"
                [insightsEnabled]="jobInsightsEnabled$ | async"
              ></bx-document-history-item>
            </div>
            <ng-template #jobDetailsModal let-modal>
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Event Details</h4>
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  ></button>
                </div>
                <div class="modal-body">
                  <h5 class="pb-2">{{ historyEvent.eventType }} {{ item.pipelineName }}</h5>
                  <bx-job-details
                    #jobDetails
                    [job]="historyEvent.job"
                    [compact]="true"
                  ></bx-job-details>
                </div>
              </div>
            </ng-template>
          </button>
        }
      } @else {
        <span class="px-4 py-3" data-spec="history-empty-msg">
          No history events were found for this document.
        </span>
      }
    </ul>
  }
</bx-collapsible-card>
