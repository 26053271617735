import { ViewerDocumentData } from './viewer-document-data';
import {
  DocumentSelectionSignature,
  selectionSignatureMatches,
} from '../document-selection-signature/document-selection-signature.model';

export function documentTableViewerTitle(data: ViewerDocumentData): string | undefined {
  const selection = data.selection;
  if (
    selectionSignatureMatches(selection.rows, [
      DocumentSelectionSignature.forDocumentClass(
        'com.biomatters.plugins.nextgenBiologics.AntibodyComparisonDocument',
        1,
        1,
      ),
    ])
  ) {
    return 'Comparison Table';
  } else {
    return 'Sequences Table';
  }
}
