import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IGetRowsRequestMinimal } from 'src/app/features/grid/datasource/grid.resource';
import { SortModel } from 'src/app/features/grid/grid.interfaces';
import { IGridResourceResponse } from 'src/nucleus/services/models/response.model';
import { AppState } from '../core.store';
import { OrganizationSetting } from '../models/settings/setting.model';

export class OrganizationSettingsResource {
  constructor(
    private store: Store<AppState>,
    private selector: MemoizedSelector<
      AppState,
      OrganizationSetting[],
      DefaultProjectorFn<OrganizationSetting[]>
    >,
  ) {}
  query(params: IGetRowsRequestMinimal): Observable<IGridResourceResponse<OrganizationSetting>> {
    const sortModel: SortModel[] = params.sortModel;
    const firstSort = sortModel.length ? sortModel[0] : { colId: 'name', sort: 'asc' };
    const direction = firstSort.sort === 'asc' ? 1 : -1;

    return this.store.select(this.selector).pipe(
      // Prevent infinite loop, automatically deregister etc. Just good.
      first(),
      map((labels) => ({
        data: labels.sort((a, b) => this.sort(a, b, firstSort.colId, direction)),
        metadata: {
          total: labels.length,
          limit: labels.length,
          offset: 0,
        },
      })),
    );
  }

  private sort(a: any, b: any, key: string | number, direction = 1) {
    const aName = a && a[key] ? a[key] : '';
    const bName = b && b[key] ? b[key] : '';

    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return direction * collator.compare(aName, bName);
  }
}
