import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { addProfiles } from '../profiles/profiles.actions';
import { GridState } from '../../../features/grid/grid.interfaces';
import { Profile, ProfileFeature } from '../profiles/profiles.model';
import { UserSetting } from '../../../../nucleus/v2/models/user-setting.v2.model';
import { ProfileService } from '../profiles/profile.service';
import { UserSettingShareType } from '@geneious/nucleus-api-client';
import { loadInitialUserSettingsSuccess } from '../user-settings.actions';

@Injectable()
export class TablePreferencesEffects {
  setAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialUserSettingsSuccess),
      map(({ tablePreferences }) => {
        const profiles: Profile[] = this.profilesFromUserSettings(tablePreferences);
        return addProfiles({ profiles });
      }),
    ),
  );

  constructor(private readonly actions$: Actions) {}

  private profilesFromUserSettings(userSettings: UserSetting<GridState>[]): Profile[] {
    return userSettings.map((userSetting) => {
      const { identifier, name } = ProfileService.splitProfileUserSettingID(userSetting.name);
      return {
        id: userSetting.id,
        feature: ProfileFeature.TABLE_PREFERENCES,
        identifier,
        name: name,
        data: userSetting.data,
        isShared: userSetting.shareType !== UserSettingShareType.NotShared,
        userID: userSetting.userID,
      };
    });
  }
}
