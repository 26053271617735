<bx-settings-breadcrumb
  [crumbs]="[{ link: ['', 'groups'], label: 'Groups' }]"
></bx-settings-breadcrumb>
<bx-toolstrip>
  <bx-toolstrip-item>
    <button class="btn btn-light" (click)="createGroup()">New</button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="editFirstSelectedGroup()"
      [disabled]="selection.noOfRowsSelected !== 1"
    >
      Edit
    </button>
  </bx-toolstrip-item>
  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      (click)="deleteGroups()"
      [disabled]="selection.noOfRowsSelected < 1"
    >
      Delete
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

<bx-grid
  class="w-100 flex-grow-1 flex-shrink-1"
  [initialColumnDefs]="columnDefs"
  [datasource]="datasource"
  (rowDoubleClicked)="editGroup($event)"
  (selectionChanged)="onSelectionChanged($event)"
  tableType="groups"
>
</bx-grid>
