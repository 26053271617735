import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SequenceType } from '@geneious/nucleus-api-client';
// @ts-ignore
import { Alphabets } from '@geneious/shared-constants';

const nucleotideNames = Object.keys(Alphabets.Nucleotide.names);
const nucleotideAmbiguities = Object.keys(Alphabets.Nucleotide.ambiguities);
const extraValidNucleotideCharacters = [Alphabets.Nucleotide.wildcard, '?'];
const validNucleotideCharacters = [
  ...nucleotideNames,
  ...nucleotideAmbiguities,
  ...extraValidNucleotideCharacters,
];
const invalidNucleotideCharacterRegex = new RegExp(
  `[^${validNucleotideCharacters.join('|')}]`,
  'ig',
);

const aminoAcidNames = Object.keys(Alphabets.AminoAcid.names);
const aminoAcidAmbiguities = Object.keys(Alphabets.AminoAcid.ambiguities);
const extraValidAminoAcidCharacters = [Alphabets.AminoAcid.wildcard, '?', '*'];
const validAminoAcidCharacters = [
  ...aminoAcidNames,
  ...aminoAcidAmbiguities,
  ...extraValidAminoAcidCharacters,
];
const invalidAminoAcidCharacterRegex = new RegExp(`[^${validAminoAcidCharacters.join('|')}]`, 'ig');

export function sequenceValidator(sequenceType: SequenceType) {
  return (control: AbstractControl): ValidationErrors | null => {
    const sequence = control.value as string;
    if (sequenceType === SequenceType.Nucleotide) {
      return validateSequence(sequence, invalidNucleotideCharacterRegex, 'nucleotide');
    }
    return validateSequence(sequence, invalidAminoAcidCharacterRegex, 'amino acid');
  };
}

function validateSequence(
  sequence: string,
  invalidCharacterRegex: RegExp,
  unit: string,
): ValidationErrors | null {
  const invalidCharacters = Array.from(new Set(sequence.match(invalidCharacterRegex) ?? []))
    .map((character) => character.toUpperCase())
    .join(', ');

  if (invalidCharacters.length > 1) {
    return {
      invalid: `The characters ${invalidCharacters} in the input sequence are not valid ${unit} characters.`,
    };
  } else if (invalidCharacters.length === 1) {
    return {
      invalid: `The character ${invalidCharacters} in the input sequence is not a valid ${unit} character.`,
    };
  }
}
