import { Injectable } from '@angular/core';
import {
  FieldMapper,
  FieldMatchKind,
  FieldMatcher,
  FieldReducer,
  FieldReducerKind,
  FieldValueType,
} from './field-mapper.model';
import { SelectOption } from 'src/app/core/models/ui/select-option.model';
import { DocumentTableService } from 'src/app/core/document-table-service/document-table.service';

/**
 * Contains utility methods for dealing with field mapper options.
 */
@Injectable({
  providedIn: 'root',
})
export class FieldMapperService {
  private readonly PREFIX_PATTERN = /^(BX_)?(AGGREGATE_)?(CHERRY_PICKING_)?(ASSAY_DATA_)?/;
  private readonly REDUCERS: FieldReducer[] = [
    {
      reducerKind: FieldReducerKind.MODE,
      fieldSuffix: 'Most Common',
      inputValueTypes: ['any'],
    },
    {
      reducerKind: FieldReducerKind.LIST,
      fieldSuffix: 'All Values',
      inputValueTypes: ['any'],
    },
    {
      reducerKind: FieldReducerKind.SET,
      fieldSuffix: 'Unique Values',
      inputValueTypes: ['any'],
    },
    {
      reducerKind: FieldReducerKind.MEAN,
      fieldSuffix: 'Mean',
      inputValueTypes: ['number'],
    },
    {
      reducerKind: FieldReducerKind.MEDIAN,
      fieldSuffix: 'Median',
      inputValueTypes: ['number'],
    },
    {
      reducerKind: FieldReducerKind.MIN,
      fieldSuffix: 'Min',
      inputValueTypes: ['number'],
    },
    {
      reducerKind: FieldReducerKind.MAX,
      fieldSuffix: 'Max',
      inputValueTypes: ['number'],
    },
  ];

  /**
   * Returns all reducers that accept the specified field value type as SelectOptions.
   *
   * @param valueType the type of the field
   * @returns the reducer options
   */
  getReducerOptions(valueType: FieldValueType = 'any'): SelectOption<FieldReducerKind>[] {
    let reducers: FieldReducer[];
    if (valueType === 'any') {
      reducers = this.REDUCERS;
    } else {
      reducers = this.REDUCERS.filter(({ inputValueTypes }) =>
        inputValueTypes.some(
          (inputValueType) => inputValueType === valueType || inputValueType === 'any',
        ),
      );
    }
    return reducers.map((reducer) => ({
      displayName: reducer.fieldSuffix,
      value: reducer.reducerKind,
    }));
  }

  /**
   * Returns a user-friendly label describing the field mapper in the form
   * `matcherConditions: reducerToApply`
   *
   * @param mapper the mapper to describe
   * @returns a descriptive label
   */
  getLabel(mapper: FieldMapper): string {
    const reducer = this.REDUCERS.find(
      (reducer) => reducer.reducerKind === mapper.reducer.reducerKind,
    );
    if (!reducer) {
      throw new Error('Unrecognized reducerKind: ' + JSON.stringify(mapper));
    }
    const reducerDisplayName = reducer.fieldSuffix;
    if (mapper.matchers.length === 0) {
      return `All fields - ${reducerDisplayName}`;
    }
    const description = mapper.matchers
      .map((matcher) => this.getMatcherDescription(matcher))
      .join(' & ');
    const firstMatcher = mapper.matchers[0];
    // codeEquals has a shorthand description which is just the field name
    if (
      firstMatcher.matchKind === FieldMatchKind.CODE_EQUALS ||
      (firstMatcher.matchKind === FieldMatchKind.NOT &&
        firstMatcher.matcher.matchKind === FieldMatchKind.CODE_EQUALS)
    ) {
      return `${description} - ${reducerDisplayName}`;
    }
    // Other matchers need a "Fields" prefix so the description makes sense
    return `Fields ${description} - ${reducerDisplayName}`;
  }

  private getMatcherDescription(matcher: FieldMatcher): string {
    switch (matcher.matchKind) {
      case FieldMatchKind.CODE_EQUALS:
        return this.getFieldDisplayName(matcher.pattern);

      case FieldMatchKind.CODE_ENDS_WITH:
        return `ending with "${matcher.pattern}"`;

      case FieldMatchKind.CODE_STARTS_WITH:
        return `starting with "${this.getFieldDisplayName(matcher.pattern)}"`;

      case FieldMatchKind.VALUE_TYPE_IS:
        return `of type ${matcher.allowedTypes.join(' or ')}`;

      case FieldMatchKind.NOT:
        return `not ${this.getMatcherDescription(matcher.matcher)}`;

      default:
        throw new Error('Unrecognized matchKind: ' + JSON.stringify(matcher));
    }
  }

  getFieldDisplayName(fieldCode: string): string {
    const strippedCode = fieldCode.replace(this.PREFIX_PATTERN, '');
    if (fieldCode.includes('ASSAY_DATA_') || fieldCode.includes('CHERRY_PICKING_')) {
      const assaySplit = DocumentTableService.splitAssayColumnKey(strippedCode);
      if (assaySplit) {
        return `${assaySplit.tableName}: ${assaySplit.columnName}`;
      }
    }
    return strippedCode;
  }
}
