import { FolderTreeItem } from '../folders/models/folder.model';
import { Observable, takeUntil } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../core.store';
import { Store } from '@ngrx/store';
import { selectedFolder } from '../folders/store/folder.selectors';
import { CleanUp } from 'src/app/shared/cleanup';

@Injectable({
  providedIn: 'root',
})
export class FolderDetail extends CleanUp {
  folder$: Observable<FolderTreeItem>;

  constructor(protected store: Store<AppState>) {
    super();
    this.folder$ = store.select(selectedFolder).pipe(takeUntil(this.ngUnsubscribe));
  }
}
