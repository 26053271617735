import { FilesTableState } from './files-table-state.model';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Item } from '../../../../nucleus/v2/models/item.v2.model';
import { adapter } from './files-store/files.reducer';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { selectedFolderID } from '../../folders/store/folder.selectors';

export const { selectAll, selectEntities } = adapter.getSelectors();

export const selectFilesTableState = createFeatureSelector<FilesTableState>('filesTable');

export const selectFilesTableStateFiles = createSelector(
  selectFilesTableState,
  (state) => state.files,
);

export const selectFilesTableFolderID = createSelector(
  selectFilesTableState,
  (state) => state.folderID,
);

export const selectFiles = createSelector(selectFilesTableStateFiles, selectAll);

export const selectFileEntities = createSelector(selectFilesTableStateFiles, selectEntities);

export const selectFileByID = (id: string) =>
  createSelector(selectFileEntities, (entities) => entities[id]);

const selectFilesTableStateFilesByFolderID = createSelector(
  selectFilesTableFolderID,
  selectedFolderID,
  selectFiles,
  (folderID: string, selectedFolderID: string, files: Item[]) => {
    if (folderID === selectedFolderID) {
      return files;
    }
  },
);

export const selectCurrentFiles = pipe(
  select(selectFilesTableStateFilesByFolderID),
  filter((files) => files != null),
);

export const selectFileRevisionByID = (id: string) =>
  createSelector(selectFileEntities, (entities) =>
    entities[id] ? parseInt(entities[id].metadata.blobRevision) : undefined,
  );

export const selectLongFileIDs = (parentFolderID: string, shortFileIDs: string[]) =>
  createSelector(selectFilesTableState, ({ folderID, files }) => {
    if (folderID !== parentFolderID) {
      return null;
    }
    const allFileIDs = files.ids.map((id) => id.toString());
    const expandedIDs: string[] = [];
    for (const shortID of shortFileIDs) {
      // Sometimes the app creates URLs with full size IDs. If they're new files,
      // they can be missing from the store at navigation time. Just putting them
      // in the URL anyway solves this ussue.
      if (shortID.length === 36) {
        expandedIDs.push(shortID);
        continue;
      }
      const matchingIDs = allFileIDs.filter((id) => id.startsWith(shortID));
      if (matchingIDs.length === 1) {
        expandedIDs.push(matchingIDs[0]);
      } else if (matchingIDs.length > 1) {
        // Exit early - multiple IDs match the short ID. This is very unlikely.
        return [];
      } else {
        // No matches found - the file was likely moved out of the folder
      }
    }
    return expandedIDs;
  });

export const selectShortFileIDs = (parentFolderID: string, longFileIDs: string[]) =>
  createSelector(selectFilesTableState, ({ folderID, shortIDs }) => {
    if (folderID !== parentFolderID) {
      return null;
    }
    return longFileIDs.map((id) => shortIDs[id] ?? id);
  });
