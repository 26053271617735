import { mergeMap, retryWhen } from 'rxjs/operators';
import { EMPTY, MonoTypeOperatorFunction, throwError, timer } from 'rxjs';

/**
 * Retries on error.
 *
 * @param {number} delayBetweenRetries - the delay of time in milliseconds between each retry.
 * @param {number} maximumRetries - the number of retries to make.
 * @param {boolean} swallowErrors - whether it should complete the stream after
 * the maximum number of retries has been exhausted. Since this is within a mergeMap, it will essentially emit nothing and swallow errors.
 * @returns {MonoTypeOperatorFunction<any>}
 */
export const retryOnErrorWithDelay = <T>(
  delayBetweenRetries: number,
  maximumRetries: number = 1,
  swallowErrors: boolean = false,
): MonoTypeOperatorFunction<T> =>
  retryWhen((errors) => {
    return errors.pipe(
      mergeMap((error, attempt) => {
        const retryAttempt = attempt + 1;

        if (retryAttempt > maximumRetries) {
          return swallowErrors ? EMPTY : throwError(error);
        }

        return timer(delayBetweenRetries);
      }),
    );
  });
