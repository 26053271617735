import { ViewerHeight, ViewerStates } from './viewers-state.interface';
import {
  setAllViewerStates,
  updateViewerHeight,
  updateViewerSelectedTab,
} from './viewers-state.actions';
import { VIEWERS_DEFAULT_HEIGHT_PERCENT } from '../../viewers-v2/viewers/viewers-helpers';
import { createReducer, on } from '@ngrx/store';

export const initialViewerStates: ViewerStates<ViewerHeight<'percent' | 'pixel'>> = {
  default: {
    height: { unit: 'percent', height: VIEWERS_DEFAULT_HEIGHT_PERCENT },
    selectedTabs: {},
  },
};

export const viewerStatesReducer = createReducer(
  initialViewerStates,
  on(setAllViewerStates, (_, { viewerStates }) => viewerStates),
  on(updateViewerHeight, (state, { key, height }) => ({
    ...state,
    [key]: {
      height,
      selectedTabs: {},
    },
  })),
  on(updateViewerSelectedTab, (state, { key, selectedTab, selectionType }) => ({
    ...state,
    [key]: {
      height: state[key] ? state[key].height : initialViewerStates.default.height,
      selectedTabs: {
        [selectionType]: selectedTab,
      },
    },
  })),
);
