<div
  [ngbTooltip]="helpMessage$ | async"
  placement="bottom"
  [disableTooltip]="(helpMessage$ | async) === null"
>
  <div class="btn-group" ngbDropdown>
    <button
      ngbDropdownToggle
      type="button"
      class="btn btn-light dropdown-toggle"
      aria-controls="export-dropdown"
      [disabled]="disableDropdown$ | async"
    >
      Export <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" ngbDropdownMenu class="dropdown-menu" role="menu">
      <li role="menuitem">
        <button
          ngbDropdownItem
          (click)="openExportDialog('documentFile')"
          data-testid="filesTableDocumentExportButton"
        >
          Export Documents...
        </button>
      </li>
      <li role="menuitem">
        <button ngbDropdownItem (click)="openExportDialog('documentMetadata')">
          Export Files Table...
        </button>
      </li>
    </ul>
  </div>
</div>
