import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';

/**
 * Response for calculating the Query running time loading indicator used in {@link GridComponent}.
 */
@Injectable()
export class TimerService {
  loadingMessage$: Observable<string>;
  private queryCounter: Subscription;
  private queryTime$: BehaviorSubject<number>;
  private stop$: Subject<void>;

  constructor() {
    this.stop$ = new Subject();
    this.queryTime$ = new BehaviorSubject(0);
    this.loadingMessage$ = this.queryTime$.pipe(map((s) => this.getLoadingMessage(s)));
  }

  start() {
    if (this.queryCounter) {
      this.queryCounter.unsubscribe();
    }
    this.queryTime$.next(0);
    this.queryCounter = interval(1000)
      .pipe(
        map((count) => count + 1),
        takeUntil(this.stop$),
      )
      .subscribe((time) => this.queryTime$.next(time));
  }

  stop() {
    this.stop$.next();
    this.queryTime$.next(0);
  }

  getLoadingMessage(time: number) {
    return time || time === 0
      ? `Query running for ${this.formatSecondsFriendly(time)}...`
      : 'Loading...';
  }

  private formatSecondsFriendly(seconds: number) {
    const mins = Math.floor(seconds / 60);
    return seconds < 60
      ? `${seconds} seconds`
      : `${mins} minute${mins === 1 ? '' : 's'}, ${seconds % 60} seconds`;
  }
}
