<bx-toolstrip class="toolbar">
  <div class="d-flex align-items-center search-results">
    <label class="form-label d-inline-block mx-2">Search Result:</label>
    <select class="form-select d-inline-block me-1" [formControl]="selectedSearchResultTable">
      @for (searchResultTable of searchResultTables$ | async; track searchResultTable) {
        <option [ngValue]="searchResultTable">
          {{
            searchResultTable.displayName +
              ' - ' +
              (searchResultTable.createdAt | date: 'dd MMM yyyy, h:mma')
          }}
        </option>
      }
    </select>
  </div>

  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      [disabled]="addToCollectionButtonDisabled$ | async"
      (click)="addToCollectionsDialogOpenEvent$.next()"
    >
      Add to Collection
    </button>
  </bx-toolstrip-item>

  <bx-toolstrip-item>
    <button
      class="btn btn-light"
      [disabled]="setBestMatchButtonDisabled$ | async"
      (click)="setBestMatchClicked$.next()"
    >
      {{ setBestMatchButtonText$ | async }}
    </button>
  </bx-toolstrip-item>
</bx-toolstrip>

@if (gridLoaded$ | async) {
  <as-split
    direction="vertical"
    unit="percent"
    [bxViewersState]="viewerStateKey"
    [bxViewerIndex]="1"
    class="flex-grow-1 flex-shrink-1"
  >
    <as-split-area class="overflow-hidden">
      <bx-client-grid
        [gridOptions]="gridOptions"
        [columnDefs]="masterDatabaseColumnDefs$ | async"
        [detailColDefs]="searchResultTableMatchesColumns$ | async"
        [rowData]="rows$ | async"
        [tableType]="'MASTER_DATABASE_SEARCH_RESULT'"
        (selectionChanged)="selection$.next($event)"
        (rowDoubleClicked)="doubleClick$.next($event)"
        class="w-100 h-100"
      >
      </bx-client-grid>
    </as-split-area>
    <as-split-area>
      <bx-viewers
        class="w-100 h-100 overflow-hidden"
        [data]="viewersData$ | async"
        [overlays]="viewersOverlays"
        key="masterListSearchResultsTable"
      >
      </bx-viewers>
    </as-split-area>
  </as-split>
} @else {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
}
