<div class="btn-group" ngbDropdown>
  <button
    id="button-basic"
    ngbDropdownToggle
    type="button"
    class="btn btn-light dropdown-toggle"
    aria-controls="dropdown-basic"
    [disabled]="disabled"
  >
    {{ name }} <span class="caret"></span>
  </button>
  <ul
    id="dropdown-basic"
    ngbDropdownMenu
    class="dropdown-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    @for (item of processedItems$ | async; track uniqueId($index, item)) {
      <li
        role="menuitem"
        [ngbTooltip]="tooltipTemplate"
        container="body"
        placement="right"
        [disableTooltip]="!item.disabled || item.helpMessage === ''"
      >
        <ng-template #tooltipTemplate><div [innerHtml]="item.helpMessage"></div></ng-template>
        <button ngbDropdownItem [disabled]="item.disabled" (click)="showDialog(item)">
          {{ item.label }}
        </button>
      </li>
    }
  </ul>
</div>
