import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { SingleCellAntibodyAnalysisJobParametersV1 } from '../../../../nucleus/services/models/singleCellAntibodyAnalysisOptions.model';
import {
  AmbiguousGenesStrategy,
  annotationStyleOptions,
  defaultLiabilities,
  getOnlyClusterTablesFor,
  parseAndSetCustomAdvancedOptions,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  first,
  map,
  share,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { defaultRegionChips } from 'src/app/shared/regions-selector/regions-selector';
import { toggleControlsAvailabilityOnBoolean } from 'src/app/shared/form-helpers/toggle-controls-availability-on-boolean';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { restrictControlValue, restrictControlValues } from 'src/app/shared/utils/forms';
import { DismissibleDirective } from '../../user-settings/dismissible/dismissible.directive';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { MatIconModule } from '@angular/material/icon';
import { SelectComponent } from '../../../shared/select/select.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { FRAdjustmentOptionsComponent } from '../shared/fr-adjustment-options/fr-adjustment-options.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-single-cell-antibody-analysis',
  templateUrl: './single-cell-antibody-analysis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DismissibleDirective,
    NgbAlert,
    CardComponent,
    NgbTooltip,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    CollapsibleCardComponent,
    MatIconModule,
    SelectComponent,
    ClusteringOptionsCardComponent,
    ShowIfDirective,
    FRAdjustmentOptionsComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class SingleCellAntibodyAnalysisComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  static title = 'Single Cell Antibody Annotator';

  readonly labelCDR = '15bp required for identifying CDR';
  readonly minBufferCDR = 15;
  faChevronRight = faChevronRight;

  earlyRelease = false;
  readonly form = this.initFormControls();
  title = SingleCellAntibodyAnalysisComponent.title;
  knowledgeBaseArticle: string;

  primerDatabases$: Observable<SelectOption[]>;
  antibodyDatabases$: Observable<SelectOption[][]>;
  featureDatabases$: Observable<SelectOption[][]>;
  geneticCodes$: Observable<SelectOption[]>;
  nameSchemes$: any;
  nameSchemeWarningMessage: string;
  fullyAnnotatedStartCDR$: Observable<boolean>;
  fullyAnnotatedEndCDR$: Observable<boolean>;

  liabilitiesTextDisabled$: Observable<boolean>;
  multipleRefDbsEnabled$: Observable<boolean>;
  showLargeDataWarning: boolean;
  readonly annotationStyleOptions = annotationStyleOptions;
  readonly dismissAlert$: Subject<boolean> = new Subject();

  private readonly formDefaults: any;
  private readonly selected: SelectionState;
  private subscriptions = new Subscription();

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private pipelineService: PipelineService,
    private validatorService: PipelineFormControlValidatorsService,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super('single-cell-antibody-analysis', PipelineFormID.SINGLE_CELL_ANTIBODY_ANALYSIS);
    this.selected = this.dialogData.selected;

    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );

    this.formDefaults = this.form.getRawValue();

    this.primerDatabases$ = this.pipelineService.externalServices.primerSets.valueSource().pipe(
      first(),
      tap((databases: SelectOption[]) => {
        restrictControlValue(
          this.form.controls.primers,
          databases.map((option) => option.value),
          { takeUntil: this.ngUnsubscribe, defaultValue: databases[0]?.value },
        );
      }),
      share(),
    );

    this.antibodyDatabases$ = this.pipelineService.getReferenceDatabases().pipe(
      first(),
      tap((options) => {
        const refDBControl = this.form.controls.antibodyDatabase;
        const databaseIDs = options.flatMap((group) => group.map((option) => option.value));
        restrictControlValues(refDBControl, databaseIDs, { takeUntil: this.ngUnsubscribe });
        if (databaseIDs[0] && !refDBControl.value?.length) {
          refDBControl.setValue([databaseIDs[0]]);
        }
      }),
      share(),
    );
    this.featureDatabases$ = this.pipelineService.getDatabases(DatabaseTypeEnum.FEATURE).pipe(
      first(),
      tap((options) => {
        const values = options.flatMap((group) => group.map((option) => option.value));
        return restrictControlValue(
          this.form.controls.antibodyAnnotator_database_featureDatabase,
          values,
          { takeUntil: this.ngUnsubscribe, defaultValue: values[0] },
        );
      }),
      share(),
    );
    this.geneticCodes$ = this.pipelineService.externalServices.geneticCodesMapToGeneiousPrimeName
      .valueSource()
      .pipe(
        first(),
        tap((geneticCodes: SelectOption[]) =>
          restrictControlValue(
            this.form.controls.antibodyAnnotator_sequences_queryGeneticCode,
            geneticCodes.map((option) => option.value),
            { takeUntil: this.ngUnsubscribe, defaultValue: geneticCodes[0]?.value },
          ),
        ),
        share(),
      );

    this.nameSchemes$ = this.pipelineService.externalServices.nameSchemes.valueSource().pipe(
      first(),
      tap((nameSchemes: SelectOption[]) => {
        restrictControlValue(
          this.form.controls.fileNameSchemeID,
          nameSchemes.map((option) => option.value),
          { takeUntil: this.ngUnsubscribe },
        );
        const nameSchemesOnDoc = this.pipelineService.nameSchemesSetOnDocuments(this.selected);
        if (nameSchemesOnDoc.size > 0) {
          this.nameSchemeWarningMessage =
            ' The previously applied name scheme will be ignored. ' +
            'The chosen name scheme will be applied to document names instead.';
        }
      }),
      share(),
    );

    this.subscriptions.add(
      this.form
        .get('trimPrimers')
        .valueChanges.pipe(
          // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
          // to apply the disable/enable logic here after that.
          delay(0),
        )
        .subscribe((value) => {
          value ? this.form.get('primers').enable() : this.form.get('primers').disable();
        }),
    );

    this.fullyAnnotatedStartCDR$ = this.form.controls.fullyAnnotatedStart.valueChanges.pipe(
      delay(0),
      startWith(this.form.controls.fullyAnnotatedStart.value),
      map((value) => value.startsWith('CDR')),
      share(),
    );
    this.fullyAnnotatedEndCDR$ = this.form.controls.fullyAnnotatedEnd.valueChanges.pipe(
      delay(0),
      startWith(this.form.controls.fullyAnnotatedEnd.value),
      map((value) => value.startsWith('CDR')),
      share(),
    );

    this.liabilitiesTextDisabled$ = this.form.get('includeLiabilities').valueChanges.pipe(
      delay(0),
      map((value) => !value || null),
      startWith(true),
    );

    // The downstream length control is disabled/enabled depending on the retain downstream control.
    const downstreamControl = this.form.controls.retainDownstream;
    downstreamControl.valueChanges
      .pipe(
        delay(0),
        map((value) => !value || null),
        startWith(!downstreamControl.value),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isDownstreamUnChecked) => {
        if (isDownstreamUnChecked && !downstreamControl.disabled) {
          this.form.controls.retainDownstreamLength.disable();
        } else if (downstreamControl.disabled) {
          this.form.controls.retainDownstreamLength.enable();
          this.form.controls.retainDownstreamLength.setValidators([
            Validators.min(15),
            Validators.max(1000),
            Validators.required,
          ]);
        } else {
          this.form.controls.retainDownstreamLength.enable();
          this.form.controls.retainDownstreamLength.setValidators([
            Validators.min(0),
            Validators.max(1000),
            Validators.required,
          ]);
        }
        this.form.controls.retainDownstreamLength.updateValueAndValidity();
      });

    // The upstream length is disabled/enabled depending on the retain upstream control.
    const upstreamControl = this.form.controls.retainUpstream;
    upstreamControl.valueChanges
      .pipe(
        delay(0),
        map((value) => !value || null),
        startWith(!upstreamControl.value),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isUpstreamUnchecked) => {
        if (isUpstreamUnchecked && !upstreamControl.disabled) {
          this.form.controls.retainUpstreamLength.disable();
        } else if (upstreamControl.disabled) {
          this.form.controls.retainUpstreamLength.enable();
          this.form.controls.retainUpstreamLength.setValidators([
            Validators.min(15),
            Validators.max(1000),
            Validators.required,
          ]);
        } else {
          this.form.controls.retainUpstreamLength.enable();
          this.form.controls.retainUpstreamLength.setValidators([
            Validators.min(0),
            Validators.max(1000),
            Validators.required,
          ]);
        }
        this.form.controls.retainUpstreamLength.updateValueAndValidity();
      });

    this.fullyAnnotatedEndCDR$
      .pipe(
        map((fullyAnno) => fullyAnno || null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isFullyAnno) => {
        if (isFullyAnno) {
          this.form.controls.retainDownstream.disable();
        } else {
          this.form.controls.retainDownstream.enable();
        }
      });

    this.fullyAnnotatedStartCDR$
      .pipe(
        map((fullyAnno) => fullyAnno || null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isFullyAnno) => {
        if (isFullyAnno) {
          this.form.controls.retainUpstream.disable();
        } else {
          this.form.controls.retainUpstream.enable();
        }
      });
    // Selecting just a CDR as the fully annotated region requires at least 15 bases upstream and downstream. This is enforced in Prime
    // and mirrored here so the user isn't confused why there are upstream/downstream bases in their sequences.

    this.subscriptions.add(
      this.fullyAnnotatedStartCDR$.subscribe((selected) => {
        if (selected) {
          this.form.get('retainUpstream').setValue(true);
          if (this.form.get('retainUpstreamLength').value < this.minBufferCDR) {
            this.form.get('retainUpstreamLength').setValue(this.minBufferCDR);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.fullyAnnotatedEndCDR$.subscribe((selected) => {
        if (selected) {
          this.form.get('retainDownstream').setValue(true);
          if (this.form.get('retainDownstreamLength').value < this.minBufferCDR) {
            this.form.get('retainDownstreamLength').setValue(this.minBufferCDR);
          }
        }
      }),
    );

    const numberOfSelectedSequences = this.selected.selectedRows.reduce(
      (acc, row) => acc + parseInt((row.number_of_sequences as string) ?? '1'),
      0,
    );
    this.showLargeDataWarning = numberOfSelectedSequences > 100_000_000;

    // Async Validator needs to be set AFTER form creation due to a bug in Angular forms with Async Validators causing
    // form status to be stuck in PENDING.
    // Hopefully fixed soon:
    // https://github.com/angular/angular/pull/20806
    // https://github.com/angular/angular/pull/22575
    // https://github.com/angular/angular/issues/13200
    setTimeout(() => {
      this.form.get('primers').setAsyncValidators(this.validatorService.databaseValidator());
      this.form.get('primers').updateValueAndValidity();
      this.form
        .get('antibodyDatabase')
        .setAsyncValidators(this.validatorService.databaseValidator());
      this.form.get('antibodyDatabase').updateValueAndValidity();
      this.form
        .get('antibodyAnnotator_database_featureDatabase')
        .setAsyncValidators(this.validatorService.databaseValidator());
      this.form.get('antibodyAnnotator_database_featureDatabase').updateValueAndValidity();
    });
  }

  ngOnInit() {
    toggleControlsAvailabilityOnBoolean(
      this.form.get('results_applySummaryScoreFilter') as FormControl,
      [this.form.get('results_summaryScoreThreshold') as FormControl],
      this.ngUnsubscribe,
    );
    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((formValues) => !!formValues.antibodyAnnotator_database_findAdditionalFeatures),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((result) => {
        if (result) {
          this.form.controls.antibodyAnnotator_database_featureDatabase.enable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseMismatches.enable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseGapSize.enable();
        } else {
          this.form.controls.antibodyAnnotator_database_featureDatabase.disable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseMismatches.disable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseGapSize.disable();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }

  run() {
    const formValue: (typeof this.form)['value'] & Record<string, unknown> = this.form.value;

    // Retain upstream/downstream tickboxes can have true values but disabled to prevent user interaction. Explicitly send their
    // values as disabling stops them being sent by default.
    formValue.retainUpstream = this.form.get('retainUpstream').value;
    formValue.retainDownstream = this.form.get('retainDownstream').value;
    formValue.antibodyAnnotator_genes_truncatedVJMismatchesOutsideCDR3 = !this.form.get(
      'forceFullRegionAnnotationOutsideCDR3',
    );
    formValue.antibodyAnnotator_results_applyOnlyClusterTablesFor = true;
    formValue.antibodyAnnotator_results_onlyClusterTablesFor = getOnlyClusterTablesFor(
      this.form.get('regionsSelector').value,
    );

    formValue.antibodyAnnotator_results_applySummaryScoreFilter = this.form.get(
      'results_applySummaryScoreFilter',
    ).value;
    formValue.antibodyAnnotator_results_summaryScoreThreshold = this.form.get(
      'results_summaryScoreThreshold',
    ).value;

    delete formValue.results_applySummaryScoreFilter;
    delete formValue.results_summaryScoreThreshold;
    delete formValue.regionsSelector;

    const options = {
      optionValues: formValue,
      resultName: this.form.get('resultName').value,
    };

    // Remove pipeline options from the GaaL options list.
    delete options.optionValues.resultName;
    delete options.optionValues.forceFullRegionAnnotationOutsideCDR3;
    delete options.optionValues.outputFolderName;
    parseAndSetCustomAdvancedOptions(options.optionValues);
    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: this.form.get('outputFolderName').value,
      },
    };

    return new SingleCellAntibodyAnalysisJobParametersV1(
      parameters.selection,
      parameters.options,
      parameters.output,
    );
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  private initFormControls() {
    const numberCtrl = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.min(min), Validators.max(max)]);

    const requiredNumberCtrl = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.required, Validators.min(min), Validators.max(max)]);

    return new BxFormGroup({
      antibodyAnnotator_liabilities_liabilitiesText: new BxFormControl(
        defaultLiabilities,
        Validators.required,
      ),
      antibodyAnnotator_genes_includePseudoGenes: new BxFormControl(true),
      antibodyAnnotator_genes_includeOrfGenes: new BxFormControl(false),
      antibodyAnnotator_genes_ambiguousGenesStrategy: new BxFormControl<AmbiguousGenesStrategy>(
        'partialFrequency',
        Validators.required,
      ),
      antibodyAnnotator_sequences_annotationStyle: new BxFormControl('IMGT', Validators.required),
      antibodyAnnotator_sequences_addNumberingAnnotations: new BxFormControl(false),
      antibodyAnnotator_sequences_queryGeneticCode: new BxFormControl(
        'universal',
        Validators.required,
      ),
      calculateProteinStatistics: new BxFormControl(false),
      resultName: JobDialogContent.getResultNameControl(),
      fileNameSchemeID: new BxFormControl(null),
      trimPrimers: new BxFormControl(false),
      primers: new BxFormControl(null, Validators.required),
      keepUnmerged: new BxFormControl(true),
      minLength: numberCtrl(100, 1, 999999),
      onlyUseLongest: new BxFormControl(true),
      onlyUseLongestLength: numberCtrl(500, 2, 999999),
      antibodyDatabase: new BxFormControl([], Validators.required),
      antibodyAnnotator_database_findAdditionalFeatures: new BxFormControl(false),
      antibodyAnnotator_database_featureDatabase: new BxFormControl<string>(
        undefined,
        Validators.required,
      ),
      antibodyAnnotator_database_featureDatabaseMismatches: requiredNumberCtrl(10, 0, 99),
      antibodyAnnotator_database_featureDatabaseGapSize: requiredNumberCtrl(3, 0, 99),
      deNovoAssemblyRequired: new BxFormControl(true),
      clusterVDJ: new BxFormControl(true),
      clusterVDJthresholdPercent: numberCtrl(97, 0, 100),
      minimumSignificantOfCellPercent: numberCtrl(1, 0, 100),
      minimumSignificantReadCount: numberCtrl(10, 1, 1000000),
      minimumVariantSupport: numberCtrl(20, 0, 100),
      strictMinimumVariantSupport: numberCtrl(10, 0, 100),
      fullyAnnotatedStart: new BxFormControl('FR1', Validators.required),
      fullyAnnotatedEnd: new BxFormControl('FR4', Validators.required),
      retainUpstream: new BxFormControl(false),
      retainUpstreamLength: numberCtrl(10, 0, 1000),
      retainDownstream: new BxFormControl(false),
      retainDownstreamLength: numberCtrl(30, 0, 1000),
      includeLiabilities: new BxFormControl(false),
      includeGermlines: new BxFormControl(true),
      associateDominantSignificantHeavyLightChains: new BxFormControl(false),
      forceFullRegionAnnotationOutsideCDR3: new BxFormControl(true),
      regionsSelector: new BxFormControl(defaultRegionChips, Validators.required),
      results_applySummaryScoreFilter: new BxFormControl(false),
      results_summaryScoreThreshold: requiredNumberCtrl(-1000, -99999, 99999),
      antibodyAnnotator_sequences_HeavyFR1Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR1End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR2Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR2End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR3Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR3End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR4Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR4End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR1Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR1End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR2Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR2End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR3Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR3End: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR4Start: requiredNumberCtrl(0, -99, 99),
      antibodyAnnotator_sequences_LightFR4End: requiredNumberCtrl(0, -99, 99),
      customAdvancedOptions: new BxFormControl(''),
      outputFolderName: JobDialogContent.getResultNameControl(),
    });
  }
}
