<bx-collapsible-card headerTitle="Permissions" [compact]="true">
  @if (acls$ | async; as acls) {
    <ul class="list-group list-group-flush mb-0">
      @for (acl of acls; track acl) {
        <div class="list-group-item py-0 w-100 h-100" data-spec="acl-item">
          <div class="acl-container">
            <div class="acl-icon-wrapper me-3 bg-secondary">
              <fa-icon [icon]="acl.icon" class="text-light"></fa-icon>
            </div>
            <div class="acl-details py-3">
              <h6 class="mb-0" data-spec="acl-name">{{ acl.displayName }}</h6>
              <small class="text-muted mb-1" data-spec="acl-desc">{{ acl.description }}</small>
            </div>
            <div class="acl-level ms-auto" [title]="acl.highestAccess.description">
              <span class="text-secondary" data-spec="acl-level-name">
                {{ acl.highestAccess.displayName }}</span
              >
              <fa-icon [icon]="faQuestionCircle" class="text-secondary ps-2"></fa-icon>
            </div>
          </div>
        </div>
      }
    </ul>
  }
</bx-collapsible-card>
