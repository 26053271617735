import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PrincipalType } from '../../../../nucleus/v1-1/models/organizations/principals/principal-type.v1-1';
import { AccessControlPrincipalUser } from '../../../../nucleus/v2/models/folders/access-controls/access-controls.v2.response';
import { FolderTreeItem } from '../../folders/models/folder.model';
import { ACLEntity } from '../../manage-sharing/manage-sharing.models';
import { ManageSharingService } from '../../manage-sharing/manage-sharing.service';
import { PermissionsService } from '../../permissions/permissions.service';
import { AppState } from '../../core.store';
import { Store } from '@ngrx/store';
import { selectUser } from '../../auth/auth.selectors';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-document-permissions-card',
  templateUrl: './document-permissions-card.component.html',
  styleUrls: ['./document-permissions-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CollapsibleCardComponent, FaIconComponent, AsyncPipe],
})
export class DocumentPermissionsCardComponent implements OnInit {
  @Input() folder$: Observable<FolderTreeItem>;

  acls$: Observable<ACLEntity[]>;
  userAccessLevel$: Observable<string>;
  readonly faQuestionCircle = faQuestionCircle;

  constructor(
    private readonly store: Store<AppState>,
    private readonly sharingService: ManageSharingService,
    private readonly permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    const folderPermissionsChanged$ = this.folder$.pipe(
      distinctUntilChanged(
        (p1, p2) => p1 === p2,
        (folder) => folder.id + folder.permissions.join(),
      ),
    );

    this.acls$ = folderPermissionsChanged$.pipe(
      withLatestFrom(this.store.select(selectUser)),
      switchMap(([folder, user]) => {
        if (folder.permissions.includes('full')) {
          return this.permissionsService
            .getPermissions(folder.id)
            .pipe(
              map((accessControls) =>
                accessControls.entries
                  .map((ac) =>
                    this.sharingService.buildACLEntityFromAccessControl(ac, user.id, 'fa'),
                  )
                  .sort(this.sortAcls),
              ),
            );
        }
        // Cannot request ACLs without full permissions. Only display current user's permissions.
        const accessControl: AccessControlPrincipalUser = {
          principalType: PrincipalType.USER,
          permissions: folder.permissions,
          principalID: user.id,
          ...user,
        };
        return of([
          this.sharingService.buildACLEntityFromAccessControl(accessControl, user.id, 'fa'),
        ]);
      }),
    );
  }

  private sortAcls(acl1: ACLEntity, acl2: ACLEntity): number {
    const priority1 = acl1.highestAccess.priority;
    const priority2 = acl2.highestAccess.priority;
    if (priority1 === priority2) {
      return acl1.displayName.localeCompare(acl2.displayName);
    }
    return priority2 - priority1;
  }
}
