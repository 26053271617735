import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableOutputType } from 'src/nucleus/services/models/exportOptions.model';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

/**
 * Contains the template and logic for the two options that are shared by
 * ngsTable and documentMetadata export types: tableOutputType and exportedColumns.
 * Both of these options are displayed as radio button form controls.
 * Excel export format is automatically disabled when the selection exceeds
 * the maximum of rows.
 */
@Component({
  selector: 'bx-export-table-controls',
  templateUrl: './export-table-controls.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, FormsModule, ReactiveFormsModule, DecimalPipe],
})
export class ExportTableControlsComponent implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-block';
  /** The control for the tableOutputType option */
  @Input() tableTypeControl: FormControl<TableOutputType>;
  /** The control for the exportedColumns option */
  @Input() exportedColumnsControl: FormControl<'all' | 'visible'>;
  /** The number of table rows being exported */
  @Input() numRows: number;

  readonly maxExcelRows = 1_048_576;
  disableExcel: boolean;

  ngOnInit(): void {
    this.disableExcel = this.numRows > this.maxExcelRows;
    if (this.disableExcel) {
      this.tableTypeControl.setValue('csv');
    }
  }
}
