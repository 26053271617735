import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'bx-background-color-renderer',
  templateUrl: './background-color-renderer.component.html',
  styles: ['div.cell { width: 100%; height: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle],
})
export class BackgroundColorRendererComponent implements ICellRendererAngularComp {
  color: string;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    // params.data is of type Setting, hence the double data
    this.color = params.data ? params.data.data.color : '#fff';
  }

  refresh(params: any): boolean {
    return false;
  }
}
