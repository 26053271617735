import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { parseRegion } from '../../antibodyAnnotatorRegions.service';

const TABLES_ORDER: Record<string, number> = {
  Summary: -24,
  'All Sequences': -24,
  'Chain Combinations': -23,
  'Heavy FR1': -22,
  'Heavy CDR1': -21,
  'Heavy FR2': -20,
  'Heavy CDR2': -19,
  'Heavy FR3': -18,
  'Heavy CDR3': -17,
  'Heavy FR4': -16,
  'VDJ Region': -15,
  'Light FR1': -14,
  'Light CDR1': -13,
  'Light FR2': -12,
  'Light CDR2': -11,
  'Light FR3': -10,
  'Light CDR3': -9,
  'Light FR4': -8,
  'Heavy V Gene': -7,
  'Heavy D Gene': -6,
  'Heavy J Gene': -5,
  'Heavy VJ Gene': -4,
  'Light V Gene': -3,
  'Light J Gene': -2,
  'Light VJ Gene': -1,
  'Multi-Chain Linker': 1,
  'Multi-Chain Region': 2,
};

/**
 * Sorts regions based on the natural order of a Human Antibody.
 * e.g. 'Heavy FR1', 'Heavy CDR1', 'Heavy FR2', 'Heavy CDR3'....
 * TODO make this generic somehow.
 */
export function sortByNaturalHumanAntibody(entities: DocumentTable[]) {
  const knownRegions = entities
    .filter((region) => region.displayName in TABLES_ORDER)
    .sort((a, b) => {
      const aRegion = parseRegion(a.displayName);
      const bRegion = parseRegion(b.displayName);
      if (aRegion.chain !== bRegion.chain) {
        return (aRegion.chain ?? '').localeCompare(bRegion.chain ?? '', undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }
      return TABLES_ORDER[aRegion.name] - TABLES_ORDER[bRegion.name];
    });

  const otherRegions = entities
    .filter((region) => !(region.displayName in TABLES_ORDER))
    .sort((a, b) => {
      return a.displayName.localeCompare(b.displayName, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });

  return knownRegions.concat(otherRegions);
}
