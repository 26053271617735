import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith, takeUntil, withLatestFrom } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-ngs-filter-toolstrip-item',
  templateUrl: './ngs-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, FaIconComponent, MatIconModule],
})
export class NgsFilterComponent extends CleanUp implements OnInit {
  @HostBinding('class') readonly hostClass = 'd-flex flex-grow-1 flex-shrink-1';

  @Input() value$: Observable<string>;
  @Output() filterChanged = new EventEmitter<string>();
  @ViewChild('filterElement', { static: true }) filterElement: ElementRef;

  readonly filterControl = new FormControl('');
  readonly form = new FormGroup({
    filter: this.filterControl,
  });

  ngOnInit() {
    const filterControlValue$ = this.filterControl.valueChanges.pipe(
      startWith(this.filterControl.value),
      map((value) => value.trim()),
      takeUntil(this.ngUnsubscribe),
    );
    this.value$
      .pipe(withLatestFrom(filterControlValue$), takeUntil(this.ngUnsubscribe))
      .subscribe(([value, existingFilters]) => {
        const filterValue = existingFilters ? existingFilters + ' AND ' + value : value;
        this.filterControl.setValue(filterValue);
        this.focusFilterElement();
      });
  }

  focusFilterElement() {
    this.filterElement.nativeElement.focus();
  }

  clearFilter() {
    this.filterControl.setValue('');
    this.onSubmit();
  }

  onSubmit() {
    this.filterChanged.emit(this.filterControl.value);
  }
}
