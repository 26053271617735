@if (isSvgIcon) {
  <mat-icon class="align-middle flex-shrink-0 text-muted me-1" [svgIcon]="icon"></mat-icon>
} @else {
  <span class="fontawesome-icon">
    <fa-icon
      class="align-middle"
      [class]="'icon-' + (icon.style ?? 'secondary')"
      [icon]="icon.faIcon"
    ></fa-icon>
  </span>
}

<ng-template #faIcon>
  <span class="fontawesome-icon">
    <fa-icon
      class="align-middle"
      [class]="'icon-' + (icon.style ?? 'secondary')"
      [icon]="icon.faIcon"
    ></fa-icon>
  </span>
</ng-template>
