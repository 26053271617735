import { Column } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  output,
} from '@angular/core';
import { outputFromObservable, takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { APP_NAME } from 'src/app/app.constants';
import { currentValueAndChanges } from 'src/app/shared/utils/forms';
import { compareStrings } from 'src/app/shared/utils/object';
import { partitionArray } from 'src/bx-common-extensions/array';
import { DocumentTableViewerService } from '../../../core/document-table-service/document-table-viewer.service';
import { DocumentTableService } from '../../../core/document-table-service/document-table.service';
import { JoinedTableHeaderComponent } from '../../../core/document-table-service/joined-table-header.component';
import { FormatterService } from '../../../shared/formatter.service';
import {
  ColumnsVisibilityChangedEvent,
  FocusColumnEvent,
  NewGridStateEvent,
  ResetGridStateEvent,
} from '../grid-sidebar/grid-sidebar.component';

/**
 * Displays given columns as a checkbox list to allow users to show/hide columns
 * in a table.
 */
@Component({
  selector: 'bx-column-management-sidebar-item',
  templateUrl: './column-management-sidebar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, NgbTooltipModule],
})
export class ColumnManagementSidebarItemComponent {
  @HostBinding('class') readonly hostClass = 'd-block h-100';
  protected readonly form = new FormGroup({
    filter: new FormControl<string>(undefined),
    columns: new FormGroup<{ [k: string]: FormControl<boolean> }>({}),
  });

  readonly targetComponent = input<string | undefined>();
  readonly columns = input.required<Column[]>();

  readonly newGridState = output<NewGridStateEvent>();
  readonly resetGridState = output<ResetGridStateEvent>();
  readonly focusColumn = output<FocusColumnEvent>();
  readonly columnsVisibilityChanged = outputFromObservable<ColumnsVisibilityChangedEvent>(
    this.form.controls.columns.valueChanges.pipe(
      map((columns) => {
        const [visibleEntries, hiddenEntries] = partitionArray(
          Object.entries(columns),
          ([_, visible]) => visible,
        );
        return {
          event: 'ColumnsVisibilityChanged',
          visibilityChanged: {
            visible: visibleEntries.map(([colId]) => colId),
            hidden: hiddenEntries.map(([colId]) => colId),
          },
        };
      }),
      distinctUntilChanged(compareStrings(JSON.stringify)),
      takeUntilDestroyed(),
    ),
  );

  /** Used to display the list of columns filtered by the search box */
  protected readonly filteredGroupedColumns$: Observable<{ name: string; value: GroupedColumn }[]>;
  /** Used to prefix input `id` & label `for` attributes to prevent clashes */
  protected readonly inputIDPrefix = computed(() =>
    this.targetComponent == null ? 'colMgmt_' : `${this.targetComponent}_`,
  );
  protected readonly focusIcon = faBullseye;

  constructor(
    private documentTableService: DocumentTableService,
    private documentTableViewerService: DocumentTableViewerService,
  ) {
    const filterText$ = currentValueAndChanges(
      this.form.controls.filter,
      takeUntilDestroyed(),
    ).pipe(map((value) => (value == null ? '' : value.trim().toLowerCase())));
    const groupedColumns$ = toObservable(this.columns).pipe(
      tap((columns) => this.updateColumnsControl(columns)),
      map((columns) => this.groupColumns(columns)),
    );
    this.filteredGroupedColumns$ = combineLatest([filterText$, groupedColumns$]).pipe(
      map(([filterText, groupedColumns]) =>
        Object.entries(groupedColumns)
          .map(([name, group]) => ({
            name,
            value: {
              ...group,
              cols: group.cols.filter((col) =>
                col.getColDef().headerName?.toLowerCase().includes(filterText),
              ),
            },
          }))
          .sort(this.columnGroupCompare),
      ),
      takeUntilDestroyed(),
      shareReplay(1),
    );
  }

  /**
   * Scroll to column by column id.
   *
   * @param event
   * @param colId
   */
  onFocusColumn(event: MouseEvent, colId: string) {
    event.preventDefault();
    event.stopPropagation();

    this.focusColumn.emit({
      event: 'FocusColumnEvent',
      colId: colId,
    });
  }

  removeGroup(group: { key: string; value: GroupedColumn }) {
    this.documentTableViewerService
      .getSelectedTable()
      .pipe(
        take(1),
        switchMap((table) =>
          this.documentTableService.removeAssayTableAction(
            table,
            group.key,
            group.value.headerName,
          ),
        ),
      )
      .subscribe();
  }

  /**
   * Reset to default grid state.
   */
  reset() {
    this.resetGridState.emit({ event: 'ResetGridState' });
  }

  /**
   * Sets the currently filtered columns to be visible or hidden
   *
   * @param visible whether the filtered columns should be visible
   */
  setAllVisible(visible = true): void {
    this.filteredGroupedColumns$
      .pipe(
        take(1),
        map((groups) => groups.flatMap(({ value }) => value.cols.map((col) => col.getColId()))),
        map((filteredColIds) =>
          Object.fromEntries(filteredColIds.map((colId) => [colId, visible])),
        ),
      )
      .subscribe((colVisibilityPatch) => {
        this.form.controls.columns.patchValue(colVisibilityPatch);
      });
  }

  private updateColumnsControl(columns: Column[] | undefined): void {
    if (columns == null) {
      return;
    }
    const existingColIds = new Set(Object.keys(this.form.controls.columns.controls));
    const newFormValue = Object.fromEntries(
      columns.map((col) => [col.getColId(), col.isVisible()]),
    );
    for (const colId of existingColIds) {
      if (!(colId in newFormValue)) {
        this.form.controls.columns.removeControl(colId, { emitEvent: false });
      }
    }
    for (const column of columns) {
      const colId = column.getColId();
      if (!existingColIds.has(colId)) {
        this.form.controls.columns.addControl(colId, new FormControl(column.isVisible()), {
          emitEvent: false,
        });
      }
    }
    this.form.controls.columns.patchValue(newFormValue);
  }

  private columnGroupCompare(
    a: { name: string; value: GroupedColumn },
    b: { name: string; value: GroupedColumn },
  ) {
    if (a.name === APP_NAME) {
      return 0;
    }
    if (b.name === APP_NAME) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  }

  private groupColumns(columns: Column[] | undefined): GroupedColumns {
    if (columns == null) {
      return {};
    }
    return columns.reduce((groupedColumns, col) => {
      // agGrid will set up columns in our result grid with group IDs of a single number when there are column groups. While it's
      // possible for users to add assay data from files whose name is "10", for example, I am going to assume this is rare and use
      // this to distinguish assay data columns from standard table columns (assay data will fail int parsing) until we can have our
      // own Column object who has properties for these things. Note if there are no column groups i.e., no assay data, the 'ordinary'
      // columns will have a null parent
      // todo remove this assumption and assign groups in the grid itself
      const groupId =
        col.getParent() &&
        col.getParent().getGroupId() &&
        !FormatterService.isNumeric(col.getParent().getGroupId())
          ? col.getParent().getGroupId()
          : APP_NAME;

      const groupHeaderName =
        groupId === APP_NAME ? APP_NAME : col.getParent().getColGroupDef()?.headerName;

      const deletable =
        col.getParent()?.getColGroupDef()?.headerGroupComponent === JoinedTableHeaderComponent;
      if (groupedColumns[groupId]) {
        groupedColumns[groupId] = {
          headerName: groupHeaderName,
          deletable,
          cols: groupedColumns[groupId].cols.concat([col]),
        };
      } else {
        groupedColumns[groupId] = {
          headerName: groupHeaderName,
          deletable,
          cols: [col],
        };
      }

      return groupedColumns;
    }, {} as GroupedColumns);
  }
}

interface GroupedColumns {
  [type: string]: GroupedColumn;
}

interface GroupedColumn {
  headerName: string;
  deletable: boolean;
  cols: Column[];
}
