@for (chip of chipsService.readonlyChips$ | async; track trackByID($index, chip)) {
  <bx-chip [chip]="chip" [readonly]="true" [disabled]="disabled$ | async" class="m-1"></bx-chip>
}
@for (chip of chipsService.chips$ | async; track trackByID($index, chip)) {
  <bx-chip [chip]="chip" [disabled]="disabled$ | async" class="m-1"></bx-chip>
}
<bx-chip-add-button
  [addButtonPopoverContentComponent]="addButtonPopoverContentComponent"
  [addButtonPopoverContentData]="addButtonPopoverContentData"
  [disabled]="disabled$ | async"
  class="mx-1"
></bx-chip-add-button>
