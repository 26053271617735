import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Token from './Token';
import Label from './Label';
import { isNullOrUndefined } from '../../../../bx-common-extensions/is-null-or-undefined';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bx-tokenizer-preview',
  templateUrl: './tokenizer-preview.component.html',
  styleUrls: ['./tokenizer-preview.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class TokenizerPreviewComponent implements OnInit, OnChanges {
  // NB: The component shouldn't be opinionated about the labels etc.
  //     It isn't caring about selected$ indices either because once an entry is flagged for selection it doesn't
  //     matter if it's marked for a second or subsequent time
  /**
   * The tokens to display grouped by index.
   */
  @Input() tokens: Set<Token> = new Set<Token>();
  @Input() labels: Set<Label> = new Set<Label>();

  tokensAsList: Array<Token> = [];
  labelsAsList: Array<Label> = [];

  constructor() {}

  ngOnInit() {
    this.tokensAsList = Array.from(this.tokens);
    this.labelsAsList = Array.from(this.labels);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  /**
   * @param index token index of interest
   * @returns {boolean} whether or not the token at the specified index is selected$
   */
  isSelected(index: number): boolean {
    let isSelected = false;
    this.labels.forEach((lbl: Label) => {
      if (index !== -1 && lbl.index === index && !isNullOrUndefined(lbl.value)) {
        isSelected = true;
        return;
      }
    });
    return isSelected;
  }

  /**
   *
   * @param index
   * @returns {string} the label for the selectedGroup
   */
  getLabel(index: number) {
    if (!this.labels) {
      return index;
    }

    const labelsAtIndex: string[] = [];
    this.labels.forEach((lbl: Label) => {
      if (index === lbl.index) {
        labelsAtIndex.push(lbl.value);
      }
    });

    if (labelsAtIndex.length === 0) {
      return index;
    } else if (labelsAtIndex.length === 1) {
      return labelsAtIndex;
    } else {
      return labelsAtIndex.join(', ');
    }
  }
}
