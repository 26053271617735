import { createReducer, on } from '@ngrx/store';
import {
  changeFeature,
  loadFeatures,
  loadFeaturesFailure,
  loadFeaturesSuccess,
} from './features.actions';
import { FeaturesStoreState } from './features-state';

export const initialState: FeaturesStoreState = {
  fetching: false,
  features: {},
  loaded: false,
};

export const featuresReducer = createReducer(
  initialState,
  on(loadFeatures, (state) => ({ ...state, fetching: true })),
  on(loadFeaturesSuccess, (state, { features }) => ({
    ...state,
    fetching: false,
    features,
    loaded: true,
  })),
  on(loadFeaturesFailure, (state) => ({ ...state, fetching: false, loaded: true })),
  on(changeFeature, (state, { code, enabled }) => ({
    ...state,
    features: { ...state.features, [code]: enabled },
  })),
);
