import { UISettingState } from './ui-settings.model';
import { createReducer, on } from '@ngrx/store';
import { setUISetting, toggleGridSidebarHiddenState } from './ui-settings.actions';

export const initialState: UISettingState = {};

export const uiSettingsReducer = createReducer(
  initialState,
  on(setUISetting, (state, { targetComponent, setting }) => ({
    ...state,
    [targetComponent]: setting,
  })),
  on(toggleGridSidebarHiddenState, (state, { targetComponent, hidden }) => {
    return {
      ...state,
      [targetComponent]: {
        sidebarHidden: hidden,
      },
    };
  }),
);
