import { createAction, createActionGroup, props } from '@ngrx/store';
import { GraphDataFor, GraphId } from '../ngs-graphs.model';
import { GraphSelectionData, GraphSelectionDataFor } from './ngs-graph-data-store.reducer';

export type ParamWithoutValidation<T extends GraphId, U extends keyof GraphSelectionDataFor<T>> = {
  [key in keyof Pick<GraphSelectionDataFor<T>, U>]: GraphSelectionDataFor<T>[U]['value'];
};
type UpdateSelectionPayloadFor<T extends GraphId, U extends keyof GraphSelectionDataFor<T>> = {
  id: string;
  graphId: T;
  param: U;
  value: ParamWithoutValidation<T, U>;
};

type FetchGraphDataPayloadFor<T extends GraphId> = {
  id: string;
  graphId: T;
  tableName: string;
};

type FetchGraphDataSuccessPayloadFor<T extends GraphId> = {
  id: string;
  graphId: T;
  tableName: string;
  data: GraphDataFor<T>;
};

type FetchGraphDataFailPayloadFor<T extends GraphId> = {
  id: string;
  graphId: T;
  tableName: string;
};

const prefixWithLabel = (
  param: keyof GraphSelectionData,
): `NGS Graph Data - ${keyof GraphSelectionData}` => `NGS Graph Data - ${param}`;
export const possibleParams: Array<keyof GraphSelectionData> = [
  'selectedTable',
  'tableSelection',
  'selectedGraph',
  'filter',
  'documentName',
  'options',
  'selectionDisplayType',
];

const events = <U extends keyof GraphSelectionData>(param: U) => ({
  Update: <T extends GraphId>(payload: Omit<UpdateSelectionPayloadFor<T, U>, 'param'>) => ({
    ...payload,
    param,
  }),
});

function paramActions(param: keyof GraphSelectionData) {
  return createActionGroup({
    source: prefixWithLabel(param),
    events: events(param),
  });
}

/**
 * Defines a separate (correctly-typed) update action for each graph param.
 * For instance, params.selectedTable.update({id, graphId, value: { selectedTable: {name: ..., displayName:..., ...} }})
 * and params.options.update({id, graphId, value: { options: {length: 1} } })
 *
 * When these actions are dispatched, the current value is updated in the ngs graph data store,
 * so it can e.g. be passed to the appropriate data-fetching method for that graph.
 */
const params = possibleParams.reduce(
  (acc, val) => ({
    ...acc,
    [val]: paramActions(val),
  }),
  {} as {
    [key in keyof GraphSelectionDataFor<any>]: ReturnType<
      typeof createActionGroup<`NGS Graph Data - ${key}`, ReturnType<typeof events<key>>>
    >;
  },
);

const data = createActionGroup({
  source: 'NGS Graph Data',
  events: {
    'Fetch Graph Data': <T extends GraphId>(payload: FetchGraphDataPayloadFor<T>) => payload,
    'Fetch Graph Data Success': <T extends GraphId>(payload: FetchGraphDataSuccessPayloadFor<T>) =>
      payload,
    'Fetch Graph Data Fail': <T extends GraphId>(payload: FetchGraphDataFailPayloadFor<T>) =>
      payload,
  },
});

const setLoading = createAction(
  '[NGS Graph Data] Set Loading',
  props<{ id: string; loading: boolean }>(),
);

export const ngsGraphActions = {
  params,
  data,
  setLoading,
};
