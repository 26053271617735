import { Route } from '@angular/router';
import { AccessCondition } from '../../shared/access-check/access-check-condition.model';

/**
 * Biologics-specific properties in a Route's data
 */
export interface RouteData {
  accessCondition?: AccessCondition;
  selectedData?: string;
  nonFolder?: boolean;
  [x: string]: any;
}

// Provides type checking & hints for optional data
export type BxRoute = Route & { data?: RouteData };

export const GETTING_STARTED_URL = 'getting-started';
export const WORKSPACE_URL = 'workspace';
