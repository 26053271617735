<div class="bx-page-container">
  <h3 class="mb-4">Luma Configuration</h3>
  <form name="luma-config" [formGroup]="form">
    <div class="mb-3 row">
      <label for="lumaURL" class="col-md-3 col-lg-2 col-form-label" data-spec="luma-url-label">
        URL
      </label>
      <div class="col-md-9 col-lg-10">
        <input
          type="url"
          id="lumaURL"
          name="lumaURL"
          [formControl]="form.controls.connection.controls.url"
          class="form-control"
          ngFormControlValidator
          [validateOnBlur]="true"
          data-spec="luma-url-input"
        />
        <bx-form-errors
          [control]="form.controls.connection.controls.url"
          [showAllErrors]="false"
          data-spec="luma-url-error"
        ></bx-form-errors>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="apiKey" class="col-md-3 col-lg-2 col-form-label" data-spec="luma-api-key-label">
        API Key
      </label>
      <div class="col-md-9 col-lg-10">
        <input
          id="apiKey"
          name="apiKey"
          type="password"
          [formControl]="form.controls.connection.controls.apiKey"
          class="form-control"
          ngFormControlValidator
          [validateOnBlur]="true"
          data-spec="luma-api-key-input"
        />
        <bx-form-errors
          [control]="form.controls.connection.controls.apiKey"
          [showAllErrors]="false"
          data-spec="luma-api-key-error"
        ></bx-form-errors>
      </div>
    </div>

    <div class="w-100 mt-3" [ngbCollapse]="(showConnectionError$ | async) === false">
      <ngb-alert type="danger" [dismissible]="false">
        <span>
          Failed to connect to Luma. Check the URL and API Key values are correct and try again, or
          <a
            href="https://help.geneiousbiologics.com/hc/en-us/requests/new"
            class="alert-link"
            target="_blank"
            >contact support</a
          >
          for assistance.
        </span>
      </ngb-alert>
    </div>

    <div class="d-flex my-2 justify-content-end">
      <button
        type="button"
        class="btn btn-secondary me-2"
        [disabled]="disableReset$ | async"
        (click)="resetFormToStoreValues()"
      >
        Reset
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="disableSubmit$ | async"
        (click)="submit()"
        data-spec="luma-submit-btn"
      >
        Save
      </button>
    </div>
  </form>
</div>
