import * as UserEditorActions from './user-editor.actions';
import { EditorState } from './editor-state.enum';
import User from './user.model';

export interface State {
  id: string;
  organizationId: string;
  email: string;
  givenName: string;
  familyName?: string;
  isAdmin: boolean;
  editorState: EditorState;
}

const initialState: State = {
  id: undefined,
  organizationId: undefined,
  email: undefined,
  givenName: undefined,
  familyName: undefined,
  isAdmin: false,
  editorState: EditorState.IDLE,
};

export function userEditorReducer(state: State = initialState, action: UserEditorActions.All) {
  // TODO remove the notion of the editor having a state and use a local boolean in the component instead.
  // This should make things a bit clearer and removes a bunch boilerplate
  switch (action.type) {
    case UserEditorActions.SET_EDITOR_STATE:
      return { ...state, editorState: action.payload };
    case UserEditorActions.DISABLE_USER:
      return { ...state, id: action.payload, editorState: EditorState.PROCESSING };
    case UserEditorActions.UPDATE_USER:
      const user: User = action.payload;
      return {
        ...state,
        id: user.id,
        organizationId: user.organizationId,
        email: user.email,
        givenName: user.givenName,
        familyName: user.familyName,
        isAdmin: user.isAdmin,
      };
    case UserEditorActions.FORGET_USER:
      return {
        ...state,
        id: undefined,
        email: undefined,
        givenName: undefined,
        familyName: undefined,
        isAdmin: undefined,
      };
    case UserEditorActions.USER_DISABLED:
    case UserEditorActions.CANCEL:
    case UserEditorActions.HANDLE_ERROR:
      return { ...state, editorState: EditorState.IDLE };
    default:
      return state;
  }
}
