import { forkJoin, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteUserSetting,
  fetchUserSettingFromServer,
  fetchUserSettingNotFound,
  fetchUserSettingsByKindFromServer,
  fetchUserSettingsSuccess,
  fetchUserSettingSuccess,
  loadInitialUserSettings,
  loadInitialUserSettingsSuccess,
  upsertUserSetting,
  upsertUserSettingOnServer,
} from './user-settings.actions';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { UserSettingsService } from './user-settings.service';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectUserID } from '../auth/auth.selectors';
import { VerifyAuthenticationSuccessActionLabel } from '../auth/auth.actions';
import { UserSettingsKinds } from './user-settings-types';
import { GridState } from '../../features/grid/grid.interfaces';
import { FormState } from './form-state/form-state.model';
import { ViewerState } from '../viewers-state/viewer-state-store/viewers-state.interface';
import { SequenceViewerPreferencesState } from './sequence-viewer-preferences/sequence-viewer-preferences.model';
import { fetchUISetting } from './ui-settings/ui-settings.actions';
import { fetchGridState } from '../grid-state/grid-state.actions';
import { fetchAllFormState } from './form-state/form-state.actions';

@Injectable()
export class UserSettingsEffects {
  delete$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(deleteUserSetting),
        mergeMap(({ id }) => {
          return this.userSettingsService.delete(id).pipe(
            catchError((err) => {
              console.error(err);
              // Prevent errors from crashing the app.
              return of();
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  update$ = createEffect(() =>
    this.actions.pipe(
      ofType(upsertUserSetting),
      mergeMap(({ kind, name, data, shareType }) => {
        return this.userSettingsService.update(kind, name, data, shareType).pipe(
          catchError((err) => {
            console.error(err);
            return of();
          }),
        );
      }),
      map((setting) => upsertUserSettingOnServer({ userSetting: setting })),
    ),
  );

  fetchUserSetting$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchUserSettingFromServer),
      mergeMap(({ kind, name }) =>
        this.store.select(selectUserID).pipe(
          take(1),
          filter((userID) => !!userID),
          switchMap((userID) => this.userSettingsService.getAllForUser(userID, kind, name)),
          map((settings) => {
            if (!settings || settings.length === 0) {
              return fetchUserSettingNotFound({ kind, name });
            }
            return fetchUserSettingSuccess({ userSetting: settings[0] });
          }),
          catchError((error) => {
            console.error(error);
            return of(fetchUserSettingNotFound({ kind, name }));
          }),
        ),
      ),
    ),
  );

  fetchUserSettingsByKind$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchUserSettingsByKindFromServer),
      mergeMap(({ kind }) =>
        this.store.select(selectUserID).pipe(
          take(1),
          filter((userID) => !!userID),
          map((userID) => ({ userID, kind })),
        ),
      ),
      mergeMap(({ userID, kind }) =>
        this.userSettingsService.getAllForUser(userID, kind).pipe(
          map((settings) => ({
            settings,
            kind,
          })),
        ),
      ),
      map(({ settings, kind }) => fetchUserSettingsSuccess({ userSettings: settings, kind })),
    ),
  );

  loadInitialSettings$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadInitialUserSettings),
      tap(() => {
        // Some lazily loaded user settings also need to be eagerly loaded (in background) for better UX
        this.store.dispatch(fetchUISetting({ targetComponent: 'files' }));
        this.store.dispatch(fetchUISetting({ targetComponent: 'jobs' }));
        this.store.dispatch(fetchGridState({ id: 'files' }));
        this.store.dispatch(fetchGridState({ id: 'jobs' }));

        // Some eagerly loaded user settings can be loaded in background for better UX
        this.store.dispatch(fetchAllFormState());
      }),
      switchMap(() => this.store.select(selectUserID)),
      filter((userID) => !!userID),
      switchMap((userID) =>
        // These user settings are required user settings that needed to be eagerly-loaded.
        forkJoin([
          this.userSettingsService.getAllForUser<GridState>(
            userID,
            UserSettingsKinds.TABLE_PREFERENCES,
            undefined,
            true,
          ),
          this.userSettingsService.getAllForUser<FormState>(
            userID,
            UserSettingsKinds.PIPELINE_OPTIONS,
            undefined,
            true,
          ),
          this.userSettingsService.getAllForUser<ViewerState>(
            userID,
            UserSettingsKinds.VIEWER_STATE,
            undefined,
            false,
          ),
          this.userSettingsService.getAllForUser<SequenceViewerPreferencesState>(
            userID,
            UserSettingsKinds.SEQUENCE_VIEWER_PREFERENCES,
            undefined,
            false,
          ),
        ]),
      ),
      catchError((err) => {
        console.error(err);
        return of();
      }),
      map(([tablePreferences, pipelineOptions, viewerStates, sequenceViewerPreferences]) =>
        loadInitialUserSettingsSuccess({
          tablePreferences,
          pipelineOptions,
          viewerStates,
          sequenceViewerPreferences,
        }),
      ),
    ),
  );

  loadInitialSettingsAfterAuthenticated$ = createEffect(() =>
    this.actions.pipe(
      ofType(VerifyAuthenticationSuccessActionLabel),
      map(() => loadInitialUserSettings()),
    ),
  );

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private userSettingsService: UserSettingsService,
  ) {}
}
