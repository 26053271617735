import { ColDef, GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { SelectionStateV2 } from '../../../features/grid/grid.component';
import { FormatterService } from '../../../shared/formatter.service';
import { MAX_ROWS_IN_BULK_UPDATE } from '../../document-table-edits/document-table-edits.reducer';
import { DialogKey } from '../../ngs/ngs-sequences-table-viewer-v2/ngs-sequences-table-viewer-v2.component';
import { arrayFilter } from './grid-context-menu-filters/array-filter';
import { replaceAll } from '../../../shared/string-util';

// Add ID to menu items to make tests more readable and less brittle
export type MenuItemID = 'addLabels' | 'filterByValue' | 'viewSequences';
export type MenuItemDefWithID = MenuItemDef & { id: MenuItemID };
export type GetContextMenuItemsWithID = (
  params: GetContextMenuItemsParams,
) => (string | MenuItemDefWithID | null)[];

export class DocumentTableServiceGridContextMenuItems {
  /** Outputs items for the NGS Grid right-click menu */
  readonly contextMenuItem$: Observable<GetContextMenuItemsWithID>;
  private readonly destroy$ = new Subject<void>();
  private readonly bulkAddLabels: MenuItemDefWithID = {
    id: 'addLabels',
    name: 'Add labels to selected rows',
    action: () => this.menuCallbacks.openDialog('addLabels'),
  };

  constructor(
    private readonly menuCallbacks: {
      setFilter: (filter: string) => void;
      jumpToSequences: (sequencesFilter: string, table: DocumentTable) => void;
      openDialog: (dialog: DialogKey) => void;
    },
    tableSelectionState$: Observable<SelectionStateV2>,
    selectedTable$: Observable<DocumentTable>,
    defaultTable$: Observable<DocumentTable>,
  ) {
    this.contextMenuItem$ = combineLatest([
      tableSelectionState$,
      selectedTable$,
      defaultTable$,
    ]).pipe(
      map(([selection, table, defaultTable]) =>
        this.getContextMenuItems(selection, table, defaultTable),
      ),
      takeUntil(this.destroy$),
    );
  }

  cleanup() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getContextMenuItems(
    selection: SelectionStateV2,
    table: DocumentTable,
    defaultTable?: DocumentTable,
  ): GetContextMenuItemsWithID {
    return (params: GetContextMenuItemsParams) => {
      const columnAndDataReady = !!params.column;
      return columnAndDataReady ? this.buildAllItems(params, selection, table, defaultTable) : [];
    };
  }

  /**
   * Wraps valid strings with quotes as long as they are non-numerical, defined and non-empty.
   * Intended to prevent "undefined" or '' (empty quotes) from appearing in the returned value.
   *
   * @param value Any value
   */
  private formatFilterValue(value: unknown): string | number {
    if (FormatterService.isNullOrEmpty(value)) {
      // null should not be wrapped with quotes - it shouldn't be parsed as a string.
      return 'null';
    } else if (FormatterService.isNumeric(value)) {
      return parseFloat(value as string);
    } else {
      return `'${value}'`;
    }
  }

  private buildKey(colDef: ColDef) {
    const colId = colDef.colId || colDef.field;
    function escapeApostrophe(stringToEscape: string) {
      return stringToEscape.replace(/'/g, `''`);
    }
    // Always wrap column field in square brackets to escape characters such as colons from assay data columns.
    return `['${escapeApostrophe(colId)}']`;
  }

  private buildFilterFromSelection(selectedRows: Record<string, unknown>[], table: DocumentTable) {
    const tableName = replaceAll(table.metadata?.displayName ?? table.displayName, ': ', '_ ');
    const clusterColumnValues = selectedRows
      .map((row) => this.formatFilterValue(row[`${tableName} ID`]))
      .join(',');
    return `['${tableName.replace(/'/g, '')} ID'] IN (${clusterColumnValues})`;
  }

  private buildFilterItem(filterParams: GetContextMenuItemsParams): MenuItemDefWithID {
    const column = filterParams.column;
    const colDef = column.getColDef();
    const key = this.buildKey(colDef);

    // Make sure value is defined and correctly formatted for filtering with Document Table Service.
    const formattedValue = this.formatFilterValue(filterParams.value);

    const filterThisColumn =
      filterParams.column.getColId() === 'Labels'
        ? arrayFilter(filterParams)
        : // Note for assay data it's important to use "colId" (not "field" or "headerName") because that includes any prefixes.
          `${key} = ${formattedValue}`;

    return {
      id: 'filterByValue',
      // Show a preview in the dropdown so users know what to expect.
      name: `Filter (${filterThisColumn})`,
      action: () => this.menuCallbacks.setFilter(filterThisColumn),
    };
  }

  private viewSequencesInDefaultTable(
    filterParams: GetContextMenuItemsParams,
    table: DocumentTable,
    defaultTable: DocumentTable,
    selection: SelectionStateV2,
  ): MenuItemDefWithID {
    const rows = !!selection.selectedRows.length
      ? selection.selectedRows
      : [filterParams.node.data];
    const filter = this.buildFilterFromSelection(rows, table);
    return {
      id: 'viewSequences',
      name: `View sequences${!!selection.selectedRows.length ? ' in selection' : ''}`,
      action: () => this.menuCallbacks.jumpToSequences(filter, defaultTable),
    };
  }

  private buildAllItems(
    filterParams: GetContextMenuItemsParams,
    selection: SelectionStateV2,
    table: DocumentTable,
    defaultTable?: DocumentTable,
  ): (string | MenuItemDefWithID | null)[] {
    const disableAddLabels =
      selection.totalSelected <= 0 || selection.totalSelected > MAX_ROWS_IN_BULK_UPDATE;

    // For now, only the all sequences table support view sequences.
    // Other default table such as Summary Table of Comparison Result are not supported for this feature.
    const validDefaultTablesForViewSequence = ['DOCUMENT_TABLE_ALL_SEQUENCES'];
    return [
      {
        ...this.bulkAddLabels,
        disabled: disableAddLabels,
        tooltip: disableAddLabels ? 'Select the rows that you wish to label' : null,
      },
      this.buildFilterItem(filterParams),
      'separator',
      table.name !== 'DOCUMENT_TABLE_ALL_SEQUENCES' &&
      defaultTable &&
      validDefaultTablesForViewSequence.includes(defaultTable.name)
        ? this.viewSequencesInDefaultTable(filterParams, table, defaultTable, selection)
        : null,
    ];
  }
}
