import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { MODAL_DATA } from '../../../shared/dialog';
import { ApiKey } from '../api-key.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-api-key-created-modal',
  templateUrl: './api-key-created-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FaIconComponent, AsyncPipe],
})
export class ApiKeyCreatedModalComponent implements OnDestroy {
  @ViewChild('apiKeyInput') apiKeyInput: ElementRef;
  readonly copyIcon = faCopy;
  showApiKeyCopied: ReplaySubject<boolean> = new ReplaySubject(1);
  showApiKeyCopyError: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(MODAL_DATA) public apiKey: ApiKey,
    public activeModal: NgbActiveModal,
  ) {}

  selectAll(e: FocusEvent) {
    const input = e.target as HTMLInputElement;
    input.select();
  }

  copyApiKey() {
    const input = this.apiKeyInput.nativeElement as HTMLInputElement;
    input.select();

    navigator.clipboard
      .writeText(input.value)
      .then(() => {
        this.showApiKeyCopied.next(true);
        this.showApiKeyCopyError.next(false);

        setTimeout(() => {
          this.showApiKeyCopied.next(false);
        }, 3000);
      })
      .catch(() => {
        this.showApiKeyCopyError.next(true);
        this.showApiKeyCopied.next(false);
      });
  }

  ngOnDestroy() {
    this.showApiKeyCopied.complete();
    this.showApiKeyCopyError.complete();
  }
}
