<form [formGroup]="form" class="p-2">
  <bx-stepper linear>
    @if (creatingDatabase) {
      <bx-step [stepControl]="form.controls.details" formGroupName="details" label="Details">
        <div class="mb-3 row">
          <label for="databaseName" class="col-4 col-form-label"> Collection name: </label>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              id="databaseName"
              name="databaseName"
              formControlName="databaseName"
              ngbAutofocus
              ngFormControlValidator
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="database_description" class="col-4 col-form-label"> Description: </label>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              id="database_description"
              name="description"
              formControlName="description"
              ngFormControlValidator
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="alphabet" class="col-4 col-form-label"> Sequence type: </label>
          <div class="col-8">
            <select class="form-select" id="alphabet" name="type" formControlName="alphabet">
              @for (type of sequenceAlphabet; track type) {
                <option [value]="type.value">
                  {{ type.name }}
                </option>
              }
            </select>
          </div>
        </div>
        <div class="row mb-0">
          <label for="antibody_type" class="col-4 col-form-label"> Antibody type: </label>
          <div class="col-8">
            <select
              class="form-select"
              id="antibody_type"
              name="type"
              formControlName="antibodyType"
            >
              @for (type of antibodyTypes; track type) {
                <option [value]="type.value">
                  {{ type.name }}
                </option>
              }
            </select>
          </div>
        </div>
      </bx-step>
    }

    <bx-step
      [stepControl]="form.controls.sequencesImport"
      [optional]="creatingDatabase"
      formGroupName="sequencesImport"
      label="Add Sequences"
    >
      @if (!creatingDatabase && form.get('sequencesImport').errors; as errors) {
        @for (error of errors | keyvalue; track error) {
          <span class="text-danger" [innerHTML]="error.value"> </span>
        }
      }
      <bx-file-picker
        [fileTypes]="['sequence', 'sequenceList']"
        [filter]="filter$ | async"
        [control]="sequencesSelectedControl"
        [noFilesSelectedMessage]="noImportSequenceFilesSelectedMessage"
        (filesSelected)="sequencesChanged($event)"
      ></bx-file-picker>
    </bx-step>

    <bx-step
      [stepControl]="form.controls.annotator"
      [disabled]="annotationOptionsIsDisabled$ | async"
      disabledTooltip="Annotating Sequences is only applicable once any Sequences have been selected for Import"
      label="Annotate"
    >
      <bx-antibody-annotator
        [forMasterDatabase]="true"
        [selection$]="selectionState$"
      ></bx-antibody-annotator>
    </bx-step>

    <bx-step label="Summary">
      <dl class="container row mt-2 mb-0 mx-auto">
        @if (form.value?.details?.name; as name) {
          <dt class="col-6">Name</dt>
          <dd class="col-6">{{ name }}</dd>
        }

        @if (form.value?.details?.description; as description) {
          <dt class="col-6">Description</dt>
          <dd class="col-6">{{ description }}</dd>
        }

        <dt class="col-6">Sequence Type</dt>
        <dd class="col-6">{{ form.value?.details?.alphabet }}</dd>

        <dt class="col-6">Antibody Type</dt>
        <dd class="col-6">{{ form.value?.details?.antibodyType }}</dd>

        @if (form.value?.sequencesImport?.sequencesSelected?.length > 0) {
          <dt class="col-6">Number of sequence documents to import</dt>
          <dd class="col-6">{{ form.value?.sequencesImport?.sequencesSelected?.length }}</dd>
        }
      </dl>
    </bx-step>
  </bx-stepper>
</form>
