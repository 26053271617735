import { Injectable } from '@angular/core';
import {
  BulkRowUpdate,
  BulkSelectionWithQuery,
  DocumentTableService,
  OrderByTableQueryKind,
} from '@geneious/nucleus-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import {
  asyncScheduler,
  combineLatest,
  forkJoin,
  merge,
  Observable,
  of,
  race,
  SchedulerLike,
  timer,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  pairwise,
  share,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { compareStrings } from 'src/app/shared/utils/object';
import { DocumentActivityEventKind } from 'src/nucleus/v2/models/activity-events/activity-event-kind.model';
import {
  DocumentEvent,
  DocumentStatusKind,
  DocumentTableBulkRowUpdateCompletedEvent,
  DocumentTableCellValueReplacedEvent,
} from 'src/nucleus/v2/models/activity-events/document-activity-event.model';
import { ActivityStreamService } from '../activity/activity-stream.service';
import { AppState } from '../core.store';
import { documentTableSelected } from '../document-table-service/document-table-state/document-table-state.actions';
import * as DTEActions from './document-table-edits.actions';
import { DocumentTableID, MAX_ROWS_IN_BULK_UPDATE } from './document-table-edits.reducer';
import { selectDocumentIDsWithEdits } from './document-table-edits.selectors';

export interface AsyncEffectParams {
  /**
   * The scheduler to use for this effect. Exposing this as a parameter allows
   * us to override the scheduler for testing purposes (see https://ngrx.io/guide/effects/testing).
   */
  scheduler: SchedulerLike;
}

/** Parameters for the {@link DocumentTableEditEffects.createReplaceCellValueRequest$} Effect. */
export interface CreateReplaceCellValueRequestParams extends AsyncEffectParams {
  /**
   * Time (in ms) to wait for subsequent cell value edits before a request is created.
   * This debounce is applied to each cell independently via the groupBy operator.
   */
  cellDebounce: number;
  /**
   * Period of inactivity before a cell is cleared from the groupBy operator.
   */
  groupDuration: number;
}

/** Parameters for the effects that retry requests. */
export interface RetryRequestParams extends AsyncEffectParams {
  /**
   * Cooldown time (in ms) to wait after a failed request before retrying.
   */
  retryDelay: number;
  /**
   * Maximum number of times to attempt sending a request before giving up.
   * Starts at 1.
   */
  maxAttempts: number;
}

@Injectable()
export class DocumentTableEditEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly documentTableService: DocumentTableService,
    private readonly activityStreamService: ActivityStreamService,
  ) {}

  private readonly selectedDocumentTable$: Observable<DocumentTableID> = this.actions$.pipe(
    ofType(documentTableSelected),
    distinctUntilChanged(compareStrings(({ documentID, tableName }) => documentID + tableName)),
    share(),
  );

  /** Contains activity events for all documents with edits + the currently selected one */
  private readonly selectedDocumentEvents$: Observable<DocumentEvent> = combineLatest([
    this.selectedDocumentTable$.pipe(
      map(({ documentID }) => documentID),
      distinctUntilChanged(),
    ),
    this.store.select(selectDocumentIDsWithEdits),
  ]).pipe(
    map(([selectedDocumentID, documentIDsWithEdits]) =>
      Array.from(new Set([selectedDocumentID, ...documentIDsWithEdits])),
    ),
    distinctUntilChanged(
      (prev, current) => prev.length === current.length && prev.join() === current.join(),
    ),
    switchMap((documentIDs) =>
      merge(...documentIDs.map((id) => this.activityStreamService.listenToDocumentActivity(id))),
    ),
    map((activity) => activity.event),
    share(),
  );

  /**
   * Listens for {@link DTEActions.replaceCellValueEdit} actions and then
   * dispatches a {@link DTEActions.replaceCellValuePostRequest} action containing
   * the request body.
   */
  readonly createReplaceCellValueRequest$ = createEffect(
    () =>
      ({
        cellDebounce = 1500,
        groupDuration = 60_000,
        scheduler = asyncScheduler,
      }: Partial<CreateReplaceCellValueRequestParams> = {}) =>
        this.actions$.pipe(
          ofType(DTEActions.replaceCellValueEdit),
          groupBy(
            ({ documentID, tableName, row, column }) => documentID + tableName + row + column,
            // Clear group after 60 seconds of inactivity to avoid memory leaks
            { duration: (group$) => group$.pipe(debounceTime(groupDuration, scheduler)) },
          ),
          mergeMap((group$) => group$.pipe(debounceTime(cellDebounce, scheduler))),
          map((action) => DTEActions.replaceCellValuePostRequest({ ...action, attempt: 1 })),
        ),
  );

  /**
   * Listens for {@link DTEActions.replaceCellValuePostRequest} actions, and
   * sends the update request contained in the action payload. Dispatches one of
   * the following:
   * - {@link DTEActions.replaceCellValuePostRequestAcked} if the response is OK
   * - {@link DTEActions.replaceCellValuePostRequestError} if the response is an error
   */
  readonly sendReplaceCellValueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DTEActions.replaceCellValuePostRequest),
      mergeMap((request) => {
        const { documentID, tableName, column, row, value } = request;
        return this.documentTableService
          .replaceCellValue(documentID, tableName, column, row, { value })
          .pipe(
            map((response) =>
              DTEActions.replaceCellValuePostRequestAcked({ ...request, response }),
            ),
            catchError((error) =>
              of(DTEActions.replaceCellValuePostRequestError({ ...request, error })),
            ),
          );
      }),
    ),
  );

  /**
   * Listens for {@link DTEActions.replaceCellValueRequestError} actions, and
   * dispatches one of the following:
   * - {@link DTEActions.replaceCellValuePostRequest} if the max number of retries
   *     has not been exceeded
   * - {@link DTEActions.replaceCellValueEditFailed} if the max number of retries
   *     has been exceeded
   */
  readonly retryReplaceCellValueRequest$ = createEffect(
    () =>
      ({
        retryDelay = 5000,
        maxAttempts = 3,
        scheduler = asyncScheduler,
      }: Partial<RetryRequestParams> = {}) =>
        this.actions$.pipe(
          ofType(DTEActions.replaceCellValuePostRequestError),
          mergeMap((action) => {
            if (action.attempt >= maxAttempts) {
              const { attempt, ...restOfAction } = action;
              return of(DTEActions.replaceCellValueEditFailed(restOfAction));
            }
            const sendAnotherRequest$ = timer(retryDelay, scheduler).pipe(
              switchMap(() => this.waitForDocumentTableToBeIdle$(action)),
              map(() => {
                const { error, attempt, ...restOfAction } = action;
                return DTEActions.replaceCellValuePostRequest({
                  ...restOfAction,
                  attempt: attempt + 1,
                });
              }),
            );
            const abortOnUserEdit$: Observable<null> = this.actions$.pipe(
              ofType(DTEActions.replaceCellValueEdit),
              take(1),
              map(() => null),
            );
            return race(sendAnotherRequest$, abortOnUserEdit$).pipe(
              take(1),
              filter((action) => action !== null),
            );
          }),
        ),
  );

  /**
   * Listens for the Document CellValueReplaced events that confirm the cell edit
   * has been applied in Nucleus. Emits {@link DTEActions.replaceCellValueEditSuccess}.
   */
  readonly listenForCellValueReplacedEvents$ = createEffect(() =>
    this.selectedDocumentEvents$.pipe(
      filter(
        (event): event is DocumentTableCellValueReplacedEvent =>
          event.kind === DocumentActivityEventKind.CELL_VALUE_REPLACED,
      ),
      map((event) =>
        DTEActions.replaceCellValueEditSuccess({
          column: event.columnName,
          documentID: event.documentID.documentID,
          row: event.rowNumber,
          tableName: event.tableName,
          value: event.value.value,
        }),
      ),
    ),
  );

  private waitForDocumentTableToBeIdle$({
    documentID,
    tableName,
  }: DocumentTableID): Observable<boolean> {
    return this.documentTableService.getTableStatus(documentID, tableName).pipe(
      switchMap((response) => {
        if (response.data.kind === DocumentStatusKind.IDLE) {
          return of(true);
        }
        return this.activityStreamService.listenToDocumentActivity(documentID).pipe(
          filter(({ event }) => 'tableName' in event && event.tableName === tableName),
          map(
            ({ event }) =>
              event.kind === DocumentActivityEventKind.TABLE_STATUS_UPDATED &&
              event.status.kind === DocumentStatusKind.IDLE,
          ),
          startWith(false),
          distinctUntilChanged(),
        );
      }),
      first((isIdle) => isIdle),
      // The table status request could fail if there's no internet
      catchError(() => of(false)),
    );
  }

  readonly listenForDocumentTableFocusLost$ = createEffect(() =>
    this.selectedDocumentTable$.pipe(
      pairwise(),
      map(([prev, _current]) => DTEActions.documentTableFocusLost(prev)),
    ),
  );

  /**
   * Listens for {@link DTEActions.bulkRowUpdateEdit} actions and then
   * dispatches a {@link DTEActions.bulkRowUpdatePostRequest} action containing
   * the request body.
   */
  readonly createBulkRowUpdateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DTEActions.bulkRowUpdateEdit),
      switchMap((action) => forkJoin([of(action), this.getRowSelection(action)])),
      map(([action, rowSelection]) => {
        // This is a picky API endpoint...
        const selection: BulkSelectionWithQuery = { selectAll: action.selection.selectAll };
        // If an empty rowNumbers array is included, the API will return 400 Bad Request.
        // For future reference, if selectAll is false and a query is included, that's also 400 Bad Request
        if (!!action.selection.rowNumbers?.length) {
          selection.rowNumbers = action.selection.rowNumbers;
        }
        // If there's a query and selectAll is true, we need to use the rows from getRowSelection
        // We're not using the query because it needs to be in Lucene query string format rather than SQL
        // We have the SqlToQueryStringConverterService, but it needs work - see BX-7591
        if (selection.selectAll && !!action.selection.sqlQuery?.trim()) {
          if (rowSelection.rows.length > 0) {
            selection.rowNumbers = rowSelection.rows;
          }
          selection.selectAll = rowSelection.selectAll;
        }
        const request: BulkRowUpdate = {
          selection,
          update: {
            columns: { Labels: action.edit },
          },
        };
        return DTEActions.bulkRowUpdatePostRequest({
          documentID: action.documentID,
          tableName: action.tableName,
          edit: action.edit,
          request,
          column: action.column,
          rows: rowSelection.rows,
          selectAll: rowSelection.selectAll,
          attempt: 1,
        });
      }),
    ),
  );

  /**
   * Returns the row numbers that the bulk row update will be applied to.
   */
  private getRowSelection({
    documentID,
    tableName,
    selection,
  }: DTEActions.DocumentTableBulkEdit): Observable<{
    rows: number[];
    selectAll: boolean;
  }> {
    const sqlQuery = selection.sqlQuery ?? '';
    /* - When selectAll is false, the set of selected rows is equal to rowNumbers, regardless of sqlQuery
     * - When selectAll is true, the selected rows are those NOT in rowNumbers.
     * - When selectAll is true and there's a sqlQuery, the selected rows are the ones that
     *    match the query AND are not in rowNumbers. This is the only case that requires us to execute
     *    execute the query with the filter to get the set of selected rows */
    if (selection.selectAll && sqlQuery.length > 0) {
      return this.documentTableService
        .queryTable(documentID, tableName, {
          where: selection.sqlQuery,
          fields: ['row_number'],
          offset: 0,
          limit: MAX_ROWS_IN_BULK_UPDATE,
          orderBy: [{ field: 'row_number', kind: OrderByTableQueryKind.Ascending }],
        })
        .pipe(
          map((res) => {
            const visibleRows = res.data.map((row) => row['row_number'] as number);
            const deselectedRows = new Set(selection.rowNumbers);
            return {
              rows: visibleRows.filter((row) => !deselectedRows.has(row)),
              selectAll: false, // Set to false as we've uninverted the selection
            };
          }),
        );
    }
    return of({ rows: selection.rowNumbers, selectAll: selection.selectAll });
  }

  /**
   * Listens for {@link DTEActions.bulkRowUpdatePostRequest} actions, and
   * sends the update request contained in the action payload. Dispatches one of
   * the following:
   * - {@link DTEActions.bulkRowUpdatePostRequestAcked} if the response is OK
   * - {@link DTEActions.bulkRowUpdatePostRequestError} if the response is an error
   */
  readonly sendBulkRowUpdateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DTEActions.bulkRowUpdatePostRequest),
      mergeMap((action) => {
        const { documentID, tableName, request } = action;
        return this.documentTableService
          .updateRowsWithBulkSelection(documentID, tableName, request)
          .pipe(
            map((response) => DTEActions.bulkRowUpdatePostRequestAcked({ ...action, response })),
            catchError((error) =>
              of(DTEActions.bulkRowUpdatePostRequestError({ ...action, error })),
            ),
          );
      }),
    ),
  );

  /**
   * Listens for {@link DTEActions.bulkRowUpdatePostRequestError} actions, and
   * dispatches one of the following:
   * - {@link DTEActions.bulkRowUpdatePostRequest} if the max number of retries
   *     has not been exceeded
   * - {@link DTEActions.bulkRowUpdateEditFailed} if the max number of retries
   *     has been exceeded
   */
  readonly retryBulkRowUpdateRequest$ = createEffect(
    () =>
      ({
        retryDelay = 5000,
        maxAttempts = 3,
        scheduler = asyncScheduler,
      }: Partial<RetryRequestParams> = {}) =>
        this.actions$.pipe(
          ofType(DTEActions.bulkRowUpdatePostRequestError),
          mergeMap((action) => {
            if (action.attempt >= maxAttempts) {
              const { attempt, ...restOfAction } = action;
              return of(DTEActions.bulkRowUpdateEditFailed(restOfAction));
            }
            return timer(retryDelay, scheduler).pipe(
              switchMap(() => this.waitForDocumentTableToBeIdle$(action)),
              map(() => {
                const { error, attempt, ...restOfAction } = action;
                return DTEActions.bulkRowUpdatePostRequest({
                  ...restOfAction,
                  attempt: attempt + 1,
                });
              }),
            );
          }),
        ),
  );

  readonly listenForBulkUpdateCompletion$ = createEffect(() =>
    this.selectedDocumentEvents$.pipe(
      filter(
        (event): event is DocumentTableBulkRowUpdateCompletedEvent =>
          event.kind === DocumentActivityEventKind.BULK_ROW_UPDATE_COMPLETED,
      ),
      map((event) =>
        DTEActions.bulkRowUpdateEditSuccess({
          documentID: event.documentID.documentID,
          tableName: event.tableName,
        }),
      ),
    ),
  );
}
