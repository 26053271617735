import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DocumentImportService } from '../pipeline-dialogs/document-import/document-import.service';

@Directive({
  selector: '[bxUploadButton]',
  standalone: true,
})
export class UploadButtonDirective implements OnInit, OnDestroy {
  // Target folderId to upload files into.
  @Input() bxUploadButton: string;
  @Input() bxUploadRoute: string;

  private changeListener: Function;
  private clickListener: Function;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private documentImportService: DocumentImportService,
  ) {}

  /**
   * Create a child input[type="file"] element.
   * - Forward click events on the host element to that file input.
   * - Listen for change events to the file input and use them to trigger file uploads.
   */
  ngOnInit() {
    // Build the file input element.
    const input = this.renderer.createElement('input');
    input.setAttribute('id', 'file-input');
    input.setAttribute('type', 'file');
    // Seems to be the only way to apply styles from a directive.
    input.setAttribute('style', 'display: none; font-size:0; width:0; height:0;');
    // Uploading of multiple files is enabled.
    input.setAttribute('multiple', 'true');

    // Listen for change events to the file input and use them to trigger file uploads.
    this.changeListener = this.renderer.listen(input, 'change', (e) => {
      const files: File[] = Array.from(e.target.files);
      this.documentImportService.import(this.bxUploadButton, this.bxUploadRoute, files);
      // Reset the button so selecting the same file twice in a row
      // fires a change event.
      e.target.value = null;
    });

    // Forward clicks to the hidden file element.
    this.clickListener = this.renderer.listen(this.element.nativeElement, 'click', (e) => {
      e.stopPropagation();
      input.click();
    });

    // Append the input element to the host element.
    this.renderer.appendChild(this.element.nativeElement, input);
  }

  // Destroy listeners.
  ngOnDestroy() {
    // ngOnInit() may not get called before ngOnDestroy() is called.
    // @see https://dotmatics.atlassian.net/browse/BX-4464
    // @see https://www.bennadel.com/blog/3356-ngoninit-may-not-get-called-before-ngondestroy-is-called-in-angular-4-4-6.htm
    if (this.changeListener) {
      this.changeListener();
    }

    if (this.clickListener) {
      this.clickListener();
    }
  }
}
