<span class="chip-label text-dark mx-1" [class.readonly]="readonly">{{ chip.label }}</span>
@if (!readonly) {
  <button
    class="btn btn-sm p-0 ms-1 my-n2"
    [disabled]="disabled"
    (click)="removeChip()"
    aria-label="Remove"
    data-spec="remove-chip-btn"
  >
    <fa-icon class="small" [icon]="faTimes"></fa-icon>
  </button>
}
