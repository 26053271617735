import { Injectable } from '@angular/core';
import { CellValue } from './table-parser/table-parser.model';

@Injectable()
export class MergeRowsService {
  constructor() {}

  mergeResults(
    _existingRows: { [colField: string]: string | number }[],
    _newRows: { [colField: string]: CellValue }[],
    existingColumn: string,
    newColumn: string,
    existingHeaderName: string,
    newHeaderName: string,
  ): MergeResults {
    // Don't modify the originals.
    const existingRows = this.cloneObject(_existingRows);
    const newRows = this.cloneObject(_newRows);

    const errors = [];
    const statistics = {
      matched: 0,
      fileTotal: newRows.length,
      resultsTotal: existingRows.length,
    };

    // Can never send a null or undefined. Send empty string in the event that the row we are testing
    // does not have a value in the selected column.
    const existingTestCellValue = existingRows[0][existingColumn] || '';
    const newTextCellValue = newRows[0][newColumn] || '';

    // Determine how to best match the columns; strings, numbers or wells. Fallback is string comparison.
    const cellMatcher = this.chooseCellMatcher(existingTestCellValue, newTextCellValue);

    let ambiguousFileMatch = false;
    let ambiguousExistingMatch = false;

    const merged = existingRows.map((existing) => {
      const existingValue = existing[existingColumn];
      const duplicate = this.isADuplicate(existingValue, newRows, newColumn, cellMatcher);

      // Ignore duplicates and rows with empty match values.
      if (!existingValue) {
        // Render row as unmatched in the table, without changing original input data.
        return this.merge(existing, { status: '?' });
      } else {
        const matches = this.findMatchingRows(existingValue, newRows, newColumn, cellMatcher);
        if (matches.length === 1) {
          if (duplicate) {
            // Only return an error for duplicates if it actually interferes with a possible match.
            ambiguousExistingMatch = true;
            // Render row as unmatched in the table, without changing original input data.
            return this.merge(existing, { status: '?' });
          } else {
            statistics.matched++;
            const match = matches[0];
            return this.merge(existing, match, { status: '✓' });
          }
        } else if (matches.length > 1) {
          ambiguousFileMatch = true;
          // Duplicate matches. Render row as unmatched in the table, without changing original input data.
          return this.merge(existing, { status: '?' });
        } else {
          // No match. Render row as unmatched in the table, without changing original input data.
          return this.merge(existing, { status: '?' });
        }
      }
    });

    // Confirm that the file column contains unique values.
    if (ambiguousFileMatch) {
      errors.push(`Can't merge - duplicate values in column "${newHeaderName}".`);
    }
    if (ambiguousExistingMatch) {
      errors.push(`Can't merge - duplicate values in column "${existingHeaderName}".`);
    }

    const noResults = '✘';
    // Add non-matched file rows to the merged data set.
    newRows.forEach((newRow) => {
      const matches = this.findMatchingRows(newRow[newColumn], merged, newColumn, cellMatcher);
      // Prevent adding matched rows but allows adding rows with duplicate values in the current file id column.
      if (matches.filter((row: any) => row.status !== noResults).length === 0) {
        // Render row as unmatched in the table, without changing original input data.
        merged.push(this.merge(newRow, { status: noResults }));
      }
    });
    return { mergedRows: merged, statistics, errors };
  }

  private merge(...parameters: Record<string, CellValue>[]): Record<string, CellValue> {
    // Modify the output but not the row itself, so that the input data can be re-used.
    return Object.assign.apply({}, parameters as any);
  }

  private findMatchingRows(
    targetCellId: CellValue,
    rowsToSearch: Record<string, CellValue>[],
    searchColumn: string,
    equals: CellMatcher,
  ) {
    return rowsToSearch.filter((row) => {
      const cellId = row[searchColumn];
      // TODO: check what datatypes we actually support for CellValues
      return equals(cellId as any, targetCellId);
    });
  }

  private isADuplicate(
    targetCellId: CellValue,
    rowsToSearch: Record<string, CellValue>[],
    searchColumn: string,
    equals: CellMatcher,
  ) {
    // TODO Improve performance by only looking for first duplicate.
    const matches = this.findMatchingRows(targetCellId, rowsToSearch, searchColumn, equals);
    return matches.length > 1;
  }

  /**
   * Determine whether match values should be compared as numbers or as strings.
   * @param targetCellValue
   * @param csvCellValue
   * @returns comparison to be used to compare id cells of results with csv
   */
  private chooseCellMatcher(
    targetCellValue: string | number,
    csvCellValue: CellValue,
  ): CellMatcher {
    // If is an integer or float.
    // WARNING: This regards an empty string as a number of 0.
    const targetIsNumber = !isNaN(Number(targetCellValue));
    const csvIsNumber = !isNaN(Number(csvCellValue));

    // If is a well
    const wellRegex = /^[a-zA-Z]+[0-9]+$/;
    const targetIsWell = hasMatch(targetCellValue, wellRegex);
    const csvIsWell = hasMatch(csvCellValue, wellRegex);

    if (targetIsNumber && csvIsNumber) {
      // Convert strings to numbers (parsed as ints) and compare as numbers.
      // Examples:
      //    "0010" === "10"
      //    "10"   === "10"
      //    10     === 10
      return (targetCell: string | number, csvCell: CellValue) => {
        const parsedTargetCell = Number(targetCell);
        const parsedCsvCell = Number(csvCell);
        return (
          !isNaN(parsedTargetCell) && !isNaN(parsedCsvCell) && parsedTargetCell === parsedCsvCell
        );
      };
    } else if (targetIsWell && csvIsWell) {
      // Compare Well (A10) by the letter and the integer component separately.
      // Examples:
      //    "A01" === "A1"
      //    "A1"  === "A01"
      //    "A2"  !== "A01"

      const letterRegex = /^[a-zA-Z]+/;
      const integerRegex = /[0-9]+$/;

      const convertToWell = function (cell: CellValue) {
        return {
          x: cell.toString().match(integerRegex)[0],
          y: cell.toString().match(letterRegex)[0].toLowerCase(),
        };
      };

      return (targetCell: string | number, csvWell: CellValue) => {
        if (targetCell && csvWell) {
          // Pull out the x and y coordinates from the string.
          const target = convertToWell(targetCell);
          const csv = convertToWell(csvWell);
          // Compare the x and y coordinates independently.
          // Case insensitive match.
          const validWells =
            !isNaN(parseInt(csv.x)) && csv.y && !isNaN(parseInt(target.x)) && target.y;
          return validWells && csv.x === target.x && csv.y === target.y;
        } else {
          return false;
        }
      };
    } else {
      // Compare values as strings. Empty strings do not confer equality.
      // e.g. "a01"  === "a01"
      // e.g. "0010" !== "10"
      // e.g. "10"   === "10"
      // Compare values as strings. Empty strings do not confer equality.
      // e.g. "a01"  === "a01"
      // e.g. "0010" !== "10"
      // e.g. "10"   === "10"
      return (targetCell: any, csvCell: any) => {
        // Handle the case that csvCell is not a string or that targetCell is not a string (and therefore don't have the method `toLowerCase`).
        const safeCsvCell = csvCell + '';
        const safeTargetCell = targetCell + '';
        const bothHaveData = targetCell && csvCell;
        const theyMatch = () =>
          safeTargetCell.toLowerCase().trim() === safeCsvCell.toLowerCase().trim();
        return bothHaveData && theyMatch();
      };
    }

    function hasMatch(target: any, regex: any) {
      const result = (target + '').match(regex);
      return result && result.length > 0;
    }
  }

  private cloneObject<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }
}

export interface MergeResults {
  mergedRows: Record<string, CellValue>[];
  statistics: {
    matched: number;
    fileTotal: number;
    resultsTotal: number;
  };
  errors: string[];
  warning?: string;
}

type CellMatcher = (targetCell: string | number, csvCell: CellValue) => boolean;
