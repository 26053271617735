import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { ViewerComponent } from '../../viewers-v2/viewers-v2.config';
import { ViewerDataService } from '../../viewers-v2/viewer-data/viewer-data.service';
import { map, mapTo, shareReplay } from 'rxjs/operators';
import { ViewerDocumentData } from '../viewer-document-data';
import { AnnotatedPluginDocument } from '../../geneious';
import { merge, Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { viewerDocumentSelector } from '../viewer-selectors';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AsyncPipe } from '@angular/common';
import { PageMessageComponent } from '../../../shared/page-message/page-message.component';
import { LoadingComponent } from '../../../shared/loading/loading.component';

@ViewerComponent({
  key: 'image-viewer',
  title: 'Image',
  selectors: [
    viewerDocumentSelector(
      (data) =>
        data.selection.totalSelected === 1 &&
        data.selection.rows[0].getAllFields().documentType === 'Image',
    ),
  ],
})
@Component({
  selector: 'bx-image-viewer',
  templateUrl: './image-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PageMessageComponent, LoadingComponent, AsyncPipe],
})
export class ImageViewerComponent implements OnInit {
  @HostBinding('class') readonly hostClass =
    'flex-grow-1 flex-shrink-1 d-flex flex-column overflow-auto p-3';
  imageURLs$: Observable<SafeUrl[]>;
  loading$: Observable<boolean>;

  private apds$: Observable<AnnotatedPluginDocument[]>;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private viewerDataService: ViewerDataService<ViewerDocumentData>,
    private sanitizer: DomSanitizer,
  ) {
    this.apds$ = viewerDataService.getData('image-viewer').pipe(map((data) => data.selection.rows));
  }

  ngOnInit() {
    this.imageURLs$ = this.apds$.pipe(
      map((apds) =>
        apds.map((apd) => {
          return this.sanitizer.bypassSecurityTrustUrl(
            `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/documents/${apd.id}/parts/FILE/data`,
          );
        }),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.loading$ = merge(this.apds$.pipe(mapTo(true)), this.imageURLs$.pipe(mapTo(false)));
  }
}
