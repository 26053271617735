import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bx-external-redirect',
  templateUrl: './external-redirect.component.html',
  standalone: true,
})
export class ExternalRedirectComponent implements OnInit {
  url = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.url = this.route.snapshot.data.redirectTo;
    console.log('Redirecting to ', this.url);
    window.location.href = this.url;
  }
}
