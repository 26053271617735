import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
} from '@angular/core';
import { DocumentImportService } from '../pipeline-dialogs/document-import/document-import.service';

@Directive({
  selector: '[bxUploadDrop]',
  standalone: true,
})
export class UploadDropDirective {
  // Target folderId to upload files into.
  @Input() bxUploadDrop: string;
  // Needed for permissions.
  @Input() bxUploadCanDrop = true;
  // Needed for upload redirects.
  @Input() bxUploadRoute = '';

  private dragEnterCount = 0;

  constructor(
    private elemRef: ElementRef,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private documentImportService: DocumentImportService,
  ) {}

  @HostListener('dragover', ['$event'])
  dragOverEvent(e: any) {
    if (!this.isFileDragEvent(e)) {
      return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();
  }

  @HostListener('dragenter', ['$event'])
  dragEnterEvent(e: any) {
    if (!this.isFileDragEvent(e)) {
      return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();

    this.elemRef.nativeElement.classList.add('dragging');
    this.dragEnterCount++;
  }

  @HostListener('dragleave', ['$event'])
  dragLeaveEvent(e: any) {
    if (!this.isFileDragEvent(e)) {
      return;
    }

    // Save resources by reducing propagation.
    e.stopImmediatePropagation();

    this.dragEnterCount--;
    if (this.dragEnterCount === 0) {
      this.elemRef.nativeElement.classList.remove('dragging');
    }
  }

  @HostListener('drop', ['$event'])
  drop(e: any) {
    if (!this.isFileDragEvent(e)) {
      return;
    }
    // Save resources by reducing propagation.
    e.stopImmediatePropagation();
    e.preventDefault();
    if (this.bxUploadCanDrop) {
      this.upload(e);
    }
    this.elemRef.nativeElement.classList.remove('dragging');
    this.dragEnterCount = 0;
  }

  private isFileDragEvent(e: DragEvent): boolean {
    return e.dataTransfer.types.some((type) => type === 'Files');
  }

  /**
   * Copied from flowJs
   * @private
   */
  private upload(event: any) {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer.items && dataTransfer.items[0] && dataTransfer.items[0].webkitGetAsEntry) {
      this.webkitReadDataTransfer(event);
    } else {
      this.documentImportService.import(this.bxUploadDrop, this.bxUploadRoute, dataTransfer.files);
    }
  }

  /**
   * Copied from flowJs
   * @param event
   */
  private webkitReadDataTransfer(event: any) {
    const self = this;
    let queue = event.dataTransfer.items.length;
    const files: File[] = [];
    for (const item of event.dataTransfer.items) {
      const entry = item.webkitGetAsEntry();
      if (!entry) {
        decrement();
        return;
      }
      if (entry.isFile) {
        // due to a bug in Chrome's File System API impl - #149735
        fileReadSuccess(item.getAsFile(), entry.fullPath);
      } else {
        readDirectory(entry.createReader());
      }
    }

    function readDirectory(reader: any) {
      reader.readEntries(function (entries: any) {
        if (entries.length) {
          queue += entries.length;
          for (const entry of entries) {
            if (entry.isFile) {
              const fullPath = entry.fullPath;
              entry.file(function (file: File) {
                fileReadSuccess(file, fullPath);
              }, readError);
            } else if (entry.isDirectory) {
              readDirectory(entry.createReader());
            }
          }
          readDirectory(reader);
        } else {
          decrement();
        }
      }, readError);
    }

    function fileReadSuccess(file: any, fullPath: string) {
      // relative path should not start with "/"
      file.relativePath = fullPath.substring(1);
      files.push(file);
      decrement();
    }

    function readError(fileError: any) {
      throw fileError;
    }

    function decrement() {
      if (--queue === 0) {
        self.documentImportService.import(self.bxUploadDrop, self.bxUploadRoute, files);
      }
    }
  }
}
