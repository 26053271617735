import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrganizationService } from '../organisation/organization.service';
import { ACLEntity, ACLMaterialIcon, IACLEntityBase, IconType } from './manage-sharing.models';
import { PermissionsService } from '../permissions/permissions.service';
import { AccessLevels } from '../permissions/access-level.const';
import { v4 as UUID } from 'uuid';
import { ACLV2Service } from '../acl/acl.v2.service';
import { AuthState } from '../auth/auth.reducers';
import {
  AccessControl,
  AccessControls,
} from '../../../nucleus/v2/models/folders/access-controls/access-controls.v2.response';
import {
  Principal,
  PrincipalGroup,
  PrincipalUser,
} from '../../../nucleus/v1-1/models/organizations/principals/principal.v1-1';
import { PrincipalType } from '../../../nucleus/v1-1/models/organizations/principals/principal-type.v1-1';
import {
  faBuilding,
  faUser,
  faUserFriends,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class ManageSharingService {
  constructor(
    private aclService: ACLV2Service,
    private orgService: OrganizationService,
  ) {}

  getSharingInformation(id: string): Observable<SharingInformationResponse> {
    return observableForkJoin({
      permissions: this.aclService.getPermissions(id),
      groups: this.orgService.getPrincipalGroups(),
      users: this.orgService.getPrincipalUsers(),
    });
  }

  buildACLEntityFromAccessControl(
    accessControl: AccessControl,
    userID: string,
    iconType: IconType = 'material',
  ): ACLEntity {
    const isCurrentUser = userID === accessControl.principalID;
    const entity: IACLEntityBase = {
      id: UUID(),
      principalId: accessControl.principalID,
      principalType: accessControl.principalType,
      displayName: this.getDisplayName(accessControl),
      description: this.getDescription(accessControl),
      highestAccess: PermissionsService.getHighestPriorityLevel(accessControl.permissions),
      icon: this.getIcon(accessControl, iconType),
      iconType,
      readOnly: false,
      selected: true,
      isCurrentUser: isCurrentUser,
      isOrg: accessControl.principalType === PrincipalType.ORGANIZATION,
    };
    return new ACLEntity(entity);
  }

  buildACLEntityFromPrincipal(
    principal: Principal,
    userID: string,
    iconType: IconType = 'material',
  ): ACLEntity {
    const isCurrentUser = userID === principal.principalId;

    // This is for the 'All of your organisation' option as it is not always set as selected.
    const isOrg = principal.principalType === PrincipalType.ORGANIZATION;

    const entity: IACLEntityBase = {
      id: UUID(),
      principalId: principal.principalId,
      principalType: principal.principalType,
      displayName: this.getDisplayName(principal),
      description: this.getDescription(principal),
      // Highest access is read level. The ACL should be removed instead of set to none.
      highestAccess: AccessLevels.read,
      icon: this.getIcon(principal, iconType),
      iconType,
      readOnly: false,
      selected: false,
      isCurrentUser: isCurrentUser,
      isOrg: isOrg,
    };
    return new ACLEntity(entity);
  }

  private getDescription(principal: AccessControl | Principal): string | undefined {
    if (principal.principalType === PrincipalType.USER) {
      return 'principalData' in principal ? principal.principalData.email : principal.email;
    } else if (principal.principalType === PrincipalType.GROUP) {
      return 'principalData' in principal
        ? principal.principalData.description
        : principal.description;
    } else if (principal.principalType === PrincipalType.ORGANIZATION) {
      return `All members of ${
        'principalData' in principal
          ? principal.principalData.organizationName
          : principal.organizationName
      }`;
    }
  }

  private getDisplayName(principal: AccessControl | Principal): string {
    if (principal.principalType === PrincipalType.USER) {
      return 'principalData' in principal
        ? `${principal.principalData.givenName} ${principal.principalData.familyName ?? ''}`
        : `${principal.givenName} ${principal.familyName ?? ''}`;
    } else if (principal.principalType === PrincipalType.GROUP) {
      return 'principalData' in principal ? principal.principalData.groupName : principal.groupName;
    } else if (principal.principalType === PrincipalType.ORGANIZATION) {
      return 'principalData' in principal
        ? principal.principalData.organizationName
        : principal.organizationName;
    }
  }

  private getIcon(acl: AccessControl | Principal, iconType: IconType) {
    return iconType === 'fa' ? this.getFaIcon(acl) : this.getMaterialIcon(acl);
  }

  private getMaterialIcon(principal: AccessControl | Principal): ACLMaterialIcon {
    if (principal.principalType === PrincipalType.USER) {
      return 'person';
    } else if (principal.principalType === PrincipalType.GROUP) {
      return 'people';
    } else if (principal.principalType === PrincipalType.ORGANIZATION) {
      return 'business';
    }
  }

  /**
   * Returns FontAwesome icons instead of Material.
   *
   * @param principal The AccessControl
   * @returns The faIcon for the ACL
   */
  private getFaIcon(principal: AccessControl | Principal): IconDefinition {
    if (principal.principalType === PrincipalType.USER) {
      return faUser;
    } else if (principal.principalType === PrincipalType.GROUP) {
      return faUserFriends;
    } else if (principal.principalType === PrincipalType.ORGANIZATION) {
      return faBuilding;
    }
  }
}

export interface SharingInformationResponse {
  permissions: AccessControls;
  groups: PrincipalGroup[];
  users: PrincipalUser[];
}
