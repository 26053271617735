<bx-breadcrumb [folder]="folder$ | async"></bx-breadcrumb>

@if (showCreateMasterDatabasePrompt$ | async) {
  <bx-page-message>
    <button class="btn btn-primary" (click)="createMasterDatabase()">Create a Collection</button>
  </bx-page-message>
} @else {
  @if (folder$ | async) {
    <bx-files-table
      tableType="files"
      [showCreateNewMasterDatabaseButton]="isMasterDatabase$ | async"
      [folder]="folder$ | async"
      [selectRowIDs]="selectRowIDs$ | async"
      [showUpload]="(isMasterDatabase$ | async) === false"
    >
    </bx-files-table>
  }
}

<ng-template #files>
  @if (folder$ | async) {
    <bx-files-table
      tableType="files"
      [showCreateNewMasterDatabaseButton]="isMasterDatabase$ | async"
      [folder]="folder$ | async"
      [selectRowIDs]="selectRowIDs$ | async"
      [showUpload]="(isMasterDatabase$ | async) === false"
    >
    </bx-files-table>
  }
</ng-template>
