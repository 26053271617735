import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { Column } from '../../../../../nucleus/services/models/exportOptions.model';
import { DocumentTableStateService } from '../../../document-table-service/document-table-state/document-table-state.service';
import { getAllColumnsWithJoins } from '../../../document-table-service/document-table-columns/get-all-columns-with-joins';
import { first, map, switchMap, take } from 'rxjs/operators';
import { selectNameSchemeByID } from '../../../organization-settings/organization-settings.selectors';
import { DataManagementService } from '@geneious/nucleus-api-client';
import { AppState } from '../../../core.store';
import { Store } from '@ngrx/store';
import { GridStateService } from '../../../grid-state/grid-state.service';
import { isColGroupDef } from '../../../folders/models/colDefs';
import { ColDef } from '@ag-grid-community/core';

@Injectable()
export class ExportSequencesService {
  constructor(
    private documentTableStateService: DocumentTableStateService,
    private dataManagementService: DataManagementService,
    private gridStateService: GridStateService,
    private store: Store<AppState>,
  ) {}

  /** NOTE: This won't work for Column Groups yet! Only Table Joins **/
  getColumnsStateFromAllSequencesTable(documentID: string): Observable<Column[]> {
    this.documentTableStateService.fetchTables([documentID]);
    const tables$ = this.documentTableStateService.getTablesMap(documentID).pipe(take(1));
    const nameScheme$ = this.dataManagementService.getDocument(documentID).pipe(
      map((result) => result.data.metadata.fileNameSchemeID),
      switchMap((fileNameSchemeID) =>
        fileNameSchemeID
          ? this.store.select(selectNameSchemeByID(fileNameSchemeID)).pipe(first())
          : of(null),
      ),
    );
    const allColumns$: Observable<Column[]> = forkJoin([tables$, nameScheme$]).pipe(
      map(([tables, nameScheme]) =>
        getAllColumnsWithJoins('DOCUMENT_TABLE_ALL_SEQUENCES', tables, nameScheme),
      ),
      map((columns) =>
        columns.flatMap((column) =>
          // There is no nested column groups in Biologics.
          isColGroupDef(column)
            ? (column.children as ColDef[]).map((child) => ({
                colId: child.colId ?? child.field,
                name: child.headerName,
                groupId: column.groupId,
              }))
            : { colId: column.colId ?? column.field, name: column.headerName },
        ),
      ),
      map((columns) =>
        columns.filter(
          (column) =>
            ![
              'geneious_row_index',
              'associated_sequences',
              'Associated Sequences',
              'row_uuid',
              'row_number',
              'row_id',
              'noSvJson',
              'noSvJsonReason',
              'No sequence viewer JSON available',
              'Reason why no sequence viewer JSON is available',
              'row_index',
              'query_sequence_row_index',
              'collection_id',
            ].includes(column.colId),
        ),
      ),
    );
    const columnsState$ = allColumns$.pipe(
      switchMap((allColumns) =>
        this.gridStateService.getGridStateOrdered('DOCUMENT_TABLE_ALL_SEQUENCES', allColumns),
      ),
      map((gridState) => gridState.columnsState),
      take(1),
    );
    return forkJoin([allColumns$, columnsState$]).pipe(
      map(([allColumns, columnsState]) => {
        return columnsState
          .filter((colState) => !colState.hide)
          .map((colState) => allColumns.find((column) => column.colId === colState.colId))
          .filter((column) => !!column);
      }),
    );
  }
}
