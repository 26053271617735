<p class="text-muted px-2">
  Create a new subset of sequences. This pipeline extracts selected sequences to a new result and
  recalculates clusters and graphs.
</p>

<form [formGroup]="form">
  @if ((referenceDbsFromOriginalOptionsAreValid$ | async) === false) {
    <bx-card headerTitle="Reference Databases">
      <div class="mb-3 row">
        <label
          class="col-lg-3 col-sm-4 col-form-label"
          for="antibodyDatabase"
          ngbTooltip="The original database(s) used for this document could not be located, please specify another set."
        >
          Reference database(s):
        </label>
        <div class="col-lg-6 col-sm-8">
          <bx-multi-select
            id="antibodyDatabase"
            data-testid="reference_database"
            formControlName="database_customDatabase"
            [selectOptions]="antibodyDatabases$ | async"
            emptyMessage="No reference databases are available"
            entityName="reference database"
            [showMultiple]="multipleRefDbsEnabled$ | async"
          >
          </bx-multi-select>
        </div>
      </div>
    </bx-card>
  }

  <bx-clustering-options-card [filterRegionsFor]="selectedChain$ | async">
  </bx-clustering-options-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
  @if (errors$ | async; as error) {
    <span class="text-danger mt-2">{{ error }}</span>
  }
</form>
