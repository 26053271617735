<div class="modal-header">
  <h4 class="modal-title pull-left">Generate Report</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()"></button>
</div>

<div class="modal-body overflow-hidden flex-grow-1 p-0 d-flex">
  <div class="h-100 report-wrapper overflow-auto bg-light flex-shrink-1 flex-grow-1">
    <div class="report p-4">
      <bx-report class="bg-white shadow"></bx-report>
    </div>
  </div>
  <bx-collapsible-panel class="h-100" panelClass="w-static-2 p-3 overflow-auto">
    <h3>Widgets</h3>
    <div>
      <bx-collapsible-card
        headerTitle="Custom Tables"
        customClass="mb-2"
        [collapseByDefault]="true"
      >
        <input
          type="text"
          name="search"
          class="form-control mb-2"
          [(ngModel)]="customTableFilter"
          placeholder="Filter"
        />
        @for (table of filteredCustomTables; track table) {
          <button
            class="dropdown-item text-truncate px-2 widget-selection__add-button"
            (click)="addWidget(table)"
          >
            {{ table.title }}
          </button>
        }
      </bx-collapsible-card>

      @if (resultTables?.length > 0) {
        <bx-collapsible-card
          headerTitle="Result Tables"
          customClass="mb-2"
          [collapseByDefault]="true"
          data-spec="result-table-widget-panel"
        >
          <input
            type="text"
            name="search"
            class="form-control mb-2"
            [(ngModel)]="resultTableFilter"
            placeholder="Filter"
          />
          @for (resultTable of filteredResultTables; track resultTable) {
            <button
              class="dropdown-item text-truncate px-2 widget-selection__add-button"
              data-spec="result-table-widget-add-btn"
              (click)="addWidget(resultTable)"
            >
              {{ resultTable.title }}
            </button>
          }
        </bx-collapsible-card>
      }

      @if (sequenceLogos?.length > 0) {
        <bx-collapsible-card
          headerTitle="Sequence Logos"
          customClass="mb-2"
          [collapseByDefault]="true"
        >
          <input
            type="text"
            name="search"
            class="form-control mb-2"
            [(ngModel)]="sequenceLogoFilter"
            placeholder="Filter"
          />
          @for (sequenceLogo of filteredSequenceLogos; track sequenceLogo) {
            <button
              class="dropdown-item text-truncate px-2 widget-selection__add-button"
              (click)="addWidget(sequenceLogo)"
            >
              {{ sequenceLogo.title }}
            </button>
          }
        </bx-collapsible-card>
      }

      @if (hasNGSGraphs && graphs?.length > 0) {
        <bx-collapsible-card headerTitle="Graphs" customClass="mb-2" [collapseByDefault]="true">
          <input
            type="text"
            name="search"
            class="form-control mb-2"
            [(ngModel)]="graphFilter"
            placeholder="Filter"
          />
          @for (graph of filteredGraphs; track graph) {
            <button
              class="dropdown-item text-truncate px-2 text-truncate widget-selection__add-button"
              (click)="addWidget(graph)"
            >
              {{ graph.title }}
            </button>
          }
        </bx-collapsible-card>
      }

      <bx-collapsible-card headerTitle="Images" customClass="mb-2" [collapseByDefault]="true">
        <input
          type="text"
          name="search"
          class="form-control mb-2"
          [(ngModel)]="imageFilter"
          placeholder="Filter"
        />
        @for (image of filteredImages; track image) {
          <button
            class="dropdown-item text-truncate px-2 widget-selection__add-button"
            (click)="addWidget(image)"
          >
            {{ image.title }}
          </button>
        }
      </bx-collapsible-card>
    </div>
  </bx-collapsible-panel>
</div>

<div class="modal-footer">
  <bx-pipeline-version-selector
    [nucleusPipelineID]="basePipelineID"
    (pipelineVersionChanged)="pipelineID$.next($event)"
  ></bx-pipeline-version-selector>
  <button
    class="btn btn-primary"
    type="button"
    [disabled]="createButtonDisabled$ | async"
    bx-spinner-button
    [spin]="submitting$ | async"
    (click)="confirm()"
  >
    Create
  </button>
  <button class="btn btn-secondary" [disabled]="submitting$ | async" (click)="cancel()">
    Cancel
  </button>
</div>
