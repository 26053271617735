import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Handles Error Redirects from the SSO service of Nucleus. Nucleus will attach an error code in the form of
 * '?errorCode=someCode' in the query parameters of the URL.
 * Based on the error code, it will set the appropriate resolver data key with the related user friendly message.
 */
@Injectable({
  providedIn: 'root',
})
export class SSORedirectResolver {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | undefined {
    const errorCode: ErrorCodes | undefined = route.queryParams.errorCode;
    const errorDetail: string | undefined = route.queryParams.errorDetail;
    switch (errorCode) {
      case undefined:
        return undefined;
      case 'UserNotFound':
        return `Geneious couldn't find any user with that email address. Please contact your organization's Geneious Biologics admin for access, or request a trial <a href="https://www.geneious.com/biopharma/request-demo/">here</a>`;
      case 'AuthMethodNotAllowed':
        return `This method of SSO is not yet enabled for your organization. Please contact support <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a> to opt in`;
      case 'OidcProviderError':
        return `SSO sign in unsuccessful. Please contact your SSO provider for further information${
          errorDetail ? ': ' + errorDetail : ''
        }`;
      case 'InvalidIdentifierOrPassword':
        return `This user is not currently active. Either it has not yet been activated, is temporarily locked, or has been permanently deactivated`;
      default:
        return 'Something went wrong - please try again later. If you continue to have problems, contact support <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">here</a>';
    }
  }
}

type ErrorCodes =
  | 'UserNotFound'
  | 'AuthMethodNotAllowed'
  | 'OidcProviderError'
  | 'InvalidIdentifierOrPassword'
  | 'InternalServerError';
