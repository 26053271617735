import { Injectable } from '@angular/core';
import { ViewerHeight } from '../../viewers-state/viewer-state-store/viewers-state.interface';
import { VIEWERS_DEFAULT_HEIGHT_PERCENT } from '../viewers/viewers-helpers';

@Injectable()
export class ViewersState {
  private state: { [type: string]: { height?: ViewerHeight; selectedTab?: string } };

  constructor() {
    this.state = {};
  }

  onHeightChanged(key: string, height: ViewerHeight): void {
    if (key in this.state) {
      this.state[key].height = height;
    } else {
      this.state[key] = { height: height };
    }
  }

  onTabChanged(key: string, tab: string): void {
    if (key in this.state) {
      this.state[key].selectedTab = tab;
    } else {
      this.state[key] = { selectedTab: tab };
    }
  }

  getHeight(key: string): ViewerHeight {
    return this.state[key] && this.state[key].height
      ? this.state[key].height
      : { unit: 'percent', height: VIEWERS_DEFAULT_HEIGHT_PERCENT };
  }

  getSelectedTab(key: string): string | null {
    return this.state[key] ? this.state[key].selectedTab : null;
  }
}
