import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ChipsService } from '../../chips/chips.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

const invalidCharactersRegex = /[^a-zA-Z0-9!@$%^&*():\-_=+.,\[\]{}\s]/; // It's a reverse regex of valid characters
const combinationNameMaxLength = 80;

export function combinationNameValidator(
  chipsService: ChipsService,
  hasCustomName$: Observable<boolean>,
) {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const combinationName = control.value as string;
    return combineLatest([
      chipsService.chipsMap$,
      chipsService.readonlyChips$,
      hasCustomName$,
    ]).pipe(
      take(1),
      map(([currentChips, readonlyChips, hasCustomName]) => {
        const existingChip = [...Object.values(currentChips), ...readonlyChips].find(
          (chip) => chip.label === combinationName,
        );
        if (existingChip) {
          return {
            exists: `Combination Cluster Name ${existingChip.label} already exists`,
          };
        }

        if (invalidCharactersRegex.test(combinationName)) {
          const invalidCharacter = combinationName.match(invalidCharactersRegex)[0];
          return {
            exists: `Combination Cluster Name cannot contain '${invalidCharacter}' character`,
          };
        }

        if (hasCustomName && combinationName.length > combinationNameMaxLength) {
          return {
            exists: `Custom cluster name cannot exceed ${combinationNameMaxLength} characters`,
          };
        }

        if (hasCustomName && (combinationName.includes('(') || combinationName.includes(')'))) {
          return {
            exists: `Custom cluster name cannot contain parentheses`,
          };
        }

        return null;
      }),
    );
  };
}
