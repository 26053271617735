import { JobConfiguration } from '@geneious/nucleus-api-client';
import { DocumentTableType } from '../documentService/document-table-type';
import { SelectionOptionsV1 } from './jobParameters.model';
import Options from '@geneious/sequence-viewer/includes/misc/Options';

export class ExportSequenceViewerImageParameters implements JobConfiguration {
  pipeline = {
    name: 'export-sequence-viewer-image',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: ExportSequenceViewerImageJobOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: ExportSequenceViewerImageJobOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface ExportSequenceViewerImageJobOptions {
  state: SequenceViewerExportState;
  multipleTableDocumentSelection?: SVImageExportMultipleTableDocumentSelection;
  masterDatabaseSearchResultSelection?: SVImageExportMasterDatabaseSearchResultSelection;
  resultName: string;
}

export interface SVImageExportMultipleTableDocumentSelection {
  selectAll: boolean;
  total: number;
  totalSelected: number;
  tableType: DocumentTableType;
  selectedRows: any[];
}

export interface SVImageExportMasterDatabaseSearchResultSelection {
  rows: any[];
  subTableRows: any[];
  totalSelected: number;
  blobName: string;
}

export interface SequenceViewerExportState {
  reference: number;
  documentsType: string | null;
  savedOptions: Options;
}
