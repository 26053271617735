<ul
  ngbNav
  #nav="ngbNav"
  class="nav-tabs"
  (activeIdChange)="currentActiveNavID$.next($event)"
  [activeId]="currentActiveNavID$ | async"
  [destroyOnHide]="false"
>
  <li ngbNavItem="single-regions">
    <a ngbNavLink>Quick Select</a>
    <ng-template ngbNavContent>
      <form [formGroup]="singleRegionsForm" class="mt-2">
        <bx-multi-select
          class="mb-2 h-static-2"
          [selectOptions]="availableRegionOptions$ | async"
          formControlName="regions"
          [showSelectionSummary]="false"
          emptyMessage="No more Regions to add"
          [smallSize]="false"
          [resizable]="false"
        ></bx-multi-select>
        <button
          type="submit"
          class="btn btn-primary w-100"
          (click)="addEvent$.next()"
          [disabled]="singleRegionButtonDisabled$ | async"
        >
          {{ addRegionsButtonLabel$ | async }}
        </button>
      </form>
    </ng-template>
  </li>

  <li ngbNavItem="combination-regions">
    <a ngbNavLink>Advanced</a>
    <ng-template ngbNavContent>
      <div class="container-fluid bx-full-width-popover">
        <form [formGroup]="combinationRegionsForm" class="row mb-2 mt-3">
          <div class="col-4 ps-2 pe-0 d-flex flex-column">
            <label class="form-label" for="regions">Select Region(s):</label>
            <bx-multi-select
              class="flex-grow-1 flex-shrink-1 h-static-2"
              [selectOptions]="availableRegionOptions$ | async"
              formControlName="regions"
              id="regions"
              [showSelectionSummary]="false"
              [smallSize]="false"
              [resizable]="false"
            ></bx-multi-select>
          </div>
          <div class="col ps-3 pe-0 d-flex flex-column justify-content-between">
            <div class="d-block mb-2 px-2 py-0 mh-static-2 overflow-y-auto">
              <div class="mb-2">
                <label class="form-label" for="name">Cluster Name:</label>
                <input
                  class="form-control"
                  id="name"
                  placeholder="Cluster Name"
                  formControlName="name"
                  ngFormControlValidator
                  [canMarkPristineInvalid]="false"
                  [readOnly]="readonlyClusterName$ | async"
                />
                @if (combinationRegionsForm.get('name').errors?.exists) {
                  <div class="invalid-feedback">
                    {{ combinationRegionsForm.get('name').errors.exists }}
                  </div>
                }
              </div>
              @if ((allGenesSelected$ | async) === false) {
                <div class="mb-2 row align-items-center">
                  <label for="clusterBy" class="col-form-label col-6"> Cluster By: </label>
                  <div class="col">
                    <select
                      class="form-select"
                      id="clusterBy"
                      formControlName="clusterBy"
                      data-spec="regions-cluster-by"
                    >
                      <option id="amino-acids" value="aminoAcids">Amino Acids</option>
                      <option id="nucleotides" value="nucleotides">Nucleotides</option>
                    </select>
                  </div>
                </div>
                <div class="mb-2 row align-items-center">
                  <label for="method" class="col-form-label col-6"> Cluster Method: </label>
                  <div class="col">
                    <select class="form-select" id="method" formControlName="clusterMethod">
                      @for (option of availableClusterMethodOptions$ | async; track option.value) {
                        <option [value]="option.value">
                          {{ option.displayName }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
              }
              @if (showClusteringPercentageConfig$ | async) {
                <div class="mb-2 row align-items-center">
                  <label for="threshold" class="col-form-label col-6">
                    Similarity Threshold:
                  </label>
                  <div class="input-group col">
                    <input
                      type="number"
                      id="threshold"
                      class="form-control"
                      placeholder="Similarity Threshold"
                      formControlName="clusterPercentage"
                      ngFormControlValidator
                      [canMarkPristineInvalid]="false"
                      min="0"
                      max="100"
                    />
                    <span class="input-group-text">%</span>
                    @if (combinationRegionsForm.get('clusterPercentage').errors) {
                      <div class="invalid-feedback">Input value must be between 1 and 100</div>
                    }
                  </div>
                </div>
              }
              @if (showNoOfMismatches$ | async) {
                <div class="mb-2 row align-items-center">
                  <label for="threshold" class="col-form-label col-6"> No. Of Mismatches: </label>
                  <div class="col">
                    <div class="input-group">
                      <input
                        type="number"
                        id="noOfMismatches"
                        class="form-control"
                        placeholder="No. of mismatches"
                        formControlName="noOfMismatches"
                        ngFormControlValidator
                        [canMarkPristineInvalid]="false"
                        min="1"
                        max="5"
                      />
                      @if (combinationRegionsForm.controls.noOfMismatches.errors) {
                        <div class="invalid-feedback">
                          Input value must be an integer between 1 and 5
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
              @if ((showClusteringPercentageConfig$ | async) || (showNoOfMismatches$ | async)) {
                <div class="mb-2 row align-items-center">
                  <label for="mismatch" class="col-form-label col-6"> Allow Mismatches In: </label>
                  <div class="col">
                    <select class="form-select" id="mismatch" formControlName="clusterMismatch">
                      @for (
                        option of availableClusterMismatchOptions$ | async;
                        track option.value
                      ) {
                        <option [value]="option.value">
                          {{ option.displayName }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
              }
              @if (clusteringInfoString$ | async; as clusteringInfo) {
                <div class="alert alert-info mt-1 mb-0 p-3">
                  <p class="mb-2"><b>Regions:</b> {{ selectedRegionsString$ | async }}.</p>
                  <span>Clustering on {{ clusteringInfo }}</span>
                </div>
              }
            </div>
            <button
              type="submit"
              class="btn btn-primary w-100 flex-shrink-0 mt-2"
              (click)="combineEvent$.next()"
              [disabled]="combineRegionsButtonDisabled$ | async"
            >
              {{ combineRegionsButtonLabel$ | async }}
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
