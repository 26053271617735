<bx-settings-breadcrumb
  [crumbs]="[
    { link: ['', 'settings'], label: 'Settings' },
    { link: ['', 'settings', 'create-api-key'], label: 'Create API Key' },
  ]"
></bx-settings-breadcrumb>
@if (isApiKeysEnabled$ | async) {
  <div class="bx-page-container bx-page-container-sm">
    <h3 class="mb-4">Create API Key</h3>
    <form #usersForm="ngForm" (ngSubmit)="submit(usersForm)">
      <div class="mb-3">
        <label for="apiKeyName" class="form-label">API Key Name</label>
        <input
          id="apiKeyName"
          type="text"
          name="apiKeyName"
          class="form-control"
          placeholder="My API key"
          [(ngModel)]="apiKeyName"
          ngFormControlValidator
          [validateOnBlur]="true"
          autocomplete="off"
          required
        />
      </div>
      <div class="d-flex w-100 justify-content-end">
        <button class="btn btn-primary me-2" bx-spinner-button [spin]="processingRequest$ | async">
          Create
        </button>
        <button class="btn btn-secondary" routerLink="/settings">Cancel</button>
      </div>
    </form>
  </div>
}

@if ((isApiKeysEnabled$ | async) === false) {
  <bx-page-message>
    <div [outerHTML]="apiKeysService.getDefaultApiKeysDisabledMessage()"></div>
  </bx-page-message>
}
