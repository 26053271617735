import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DataChangedEvent } from '@ag-grid-community/core/dist/esm/es6/interfaces/iRowNode';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CleanUp } from '../../../shared/cleanup';
import { AsyncPipe } from '@angular/common';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';

export interface ProgressRendererData {
  isStarting: (any: any) => boolean;
  isCompleted: (any: any) => boolean;
  isCompletedWithErrors: (any: any) => boolean;
  isFailed: (any: any) => boolean;
  isCancelled: (any: any) => boolean;
}

type ProgressStatus =
  | 'starting'
  | 'running'
  | 'completed'
  | 'completedWithErrors'
  | 'failed'
  | 'cancelled';

type ProgressBarState = {
  /** The progress value to display as a percentage */
  progress: number;
  barColor: 'primary' | 'success' | 'warning' | 'danger' | 'light';
  textColor: 'dark' | 'light';
};

const STATUS_COLOR: Record<ProgressStatus, Pick<ProgressBarState, 'barColor' | 'textColor'>> = {
  starting: { barColor: 'light', textColor: 'dark' },
  running: { barColor: 'primary', textColor: 'dark' },
  completed: { barColor: 'success', textColor: 'light' },
  completedWithErrors: { barColor: 'warning', textColor: 'light' },
  cancelled: { barColor: 'warning', textColor: 'light' },
  failed: { barColor: 'danger', textColor: 'light' },
};

@Component({
  selector: 'bx-progress-renderer',
  templateUrl: './progress-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbProgressbar, AsyncPipe],
})
export class ProgressRendererComponent extends CleanUp implements AgRendererComponent, OnDestroy {
  @HostBinding('class') readonly hostClass = 'w-100 h-100 d-flex align-items-center';
  progress: number;
  params: ICellRendererParams;
  readonly state$ = this.completeOnDestroy(
    new BehaviorSubject<ProgressBarState>({ ...STATUS_COLOR.starting, progress: 0 }),
  );

  ngOnDestroy() {
    super.ngOnDestroy();
    this.params.node.removeEventListener('dataChanged', this.updateProgressStatus.bind(this));
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    params.node.addEventListener('dataChanged', this.updateProgressStatus.bind(this));
    this.render(params);
  }

  updateProgressStatus(event: DataChangedEvent<unknown>) {
    const status = this.getProgressStatusFromRowData(event.newData);
    this.emitState(status);
  }

  render(params: ICellRendererParams) {
    if (params.data) {
      this.progress = params.value;
      const status = this.isProgressRendererData(params.colDef.cellRendererParams)
        ? this.getProgressStatusFromRowData(params.data)
        : this.getProgressStatusFromProgressValue();
      this.emitState(status);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  private isProgressRendererData(params: unknown): params is ProgressRendererData {
    return params != null && (params as ProgressRendererData).isCompleted !== undefined;
  }

  private getProgressStatusFromRowData(data: unknown): ProgressStatus {
    const cellRendererParams: ProgressRendererData = this.params.colDef.cellRendererParams;
    if (cellRendererParams.isCompleted(data)) {
      return 'completed';
    }
    if (cellRendererParams.isFailed(data)) {
      return 'failed';
    }
    if (cellRendererParams.isCancelled(data)) {
      return 'cancelled';
    }
    if (cellRendererParams.isCompletedWithErrors(data)) {
      return 'completedWithErrors';
    }
    if (cellRendererParams.isStarting(data)) {
      return 'starting';
    }
    return 'running';
  }

  private getProgressStatusFromProgressValue(): ProgressStatus {
    return this.progress >= 100 ? 'completed' : 'running';
  }

  private emitState(status: ProgressStatus) {
    this.state$.next({ ...STATUS_COLOR[status], progress: this.progress });
  }
}
