<form [formGroup]="form">
  <p class="px-2 text-muted">
    This pipeline allows you to repair low quality regions in your selected sequences by replacing
    them with their corresponding germline sequences from the reference database used to annotate
    the sequences.
    <a href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069911" target="_blank"
      >See the Support page for this pipeline for more information.</a
    >
  </p>

  @if (invalidDataError) {
    <ngb-alert type="danger" [dismissible]="false">
      The annotator result document you have selected is too old to repair. Please re-run Antibody
      Annotator to create a new result document that will be eligible for sequence repair.
    </ngb-alert>
  }

  <bx-card headerTitle="Repair Precision">
    <div class="form-check mb-2">
      <input
        type="radio"
        id="replaceAlways"
        value="replaceAlways"
        formControlName="replacePrecision"
        class="form-check-input"
      />
      <label
        for="replaceAlways"
        class="form-check-label"
        ngbTooltip="The selected regions for repair will be replaced with their germline counterparts, regardless of quality."
      >
        Replace entire region for all selected repair regions
      </label>
    </div>

    <div class="form-check mb-2">
      <input
        type="radio"
        id="replaceEntire"
        value="replaceEntire"
        formControlName="replacePrecision"
        class="form-check-input"
      />
      <label
        for="replaceEntire"
        class="form-check-label"
        ngbTooltip="The selected regions for repair will be replaced with their germline counterparts only when one or more poor quality bases are present."
      >
        Replace entire region when poor quality bases are present
      </label>
    </div>

    <div class="form-check">
      <input
        type="radio"
        id="replaceBases"
        value="replaceBases"
        formControlName="replacePrecision"
        class="form-check-input"
      />
      <label
        for="replaceBases"
        class="form-check-label"
        ngbTooltip="The selected regions for repair will have only low quality bases replaced with their germline counterparts. If this is not possible, the entire region will be replaced."
      >
        Replace only poor quality bases (where possible)
      </label>
    </div>
  </bx-card>

  <bx-card headerTitle="Options">
    @if ((referenceDbsFromOptionsAreAvailable$ | async) === false) {
      <div class="mb-3 row align-items-center">
        <div class="col-3 col-form-label">
          <label
            class="form-label"
            for="antibodyDatabase"
            ngbTooltip="The original database(s) used for this document could not be located, please specify another one."
          >
            Reference database(s):
          </label>
        </div>
        <div class="col-9">
          <bx-multi-select
            id="antibodyDatabase"
            formControlName="overrideReferenceDatabaseID"
            [selectOptions]="antibodyDatabases$ | async"
            emptyMessage="No reference databases are available"
            entityName="reference database"
            [showMultiple]="multipleRefDbsEnabled$ | async"
          >
          </bx-multi-select>
        </div>
      </div>
    }

    <div class="mb-3 row align-items-center" formGroupName="repairRegions">
      <label
        class="col-3 col-form-label"
        ngbTooltip="Select one or more regions to repair in the selected sequences using reference database sequences."
      >
        Regions to repair:
      </label>
      <div class="col-4">
        <label
          class="col-form-label text-muted"
          ngbTooltip="Control/Command click to select more than one region."
          for="repairRegionsLight"
        >
          Light chains
        </label>
        <select
          formControlName="repairRegionsLight"
          multiple
          id="repairRegionsLight"
          size="6"
          class="form-select"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="FR4">FR4</option>
        </select>
      </div>

      <div class="col-4">
        <label
          class="col-form-label text-muted"
          ngbTooltip="Control/Command click to select more than one region."
          for="repairRegionsHeavy"
        >
          Heavy chains
        </label>
        <select
          formControlName="repairRegionsHeavy"
          multiple
          id="repairRegionsHeavy"
          size="6"
          class="form-select"
        >
          <option value="FR1">FR1</option>
          <option value="CDR1">CDR1</option>
          <option value="FR2">FR2</option>
          <option value="CDR2">CDR2</option>
          <option value="FR3">FR3</option>
          <option value="FR4">FR4</option>
        </select>
      </div>
    </div>

    <div class="row align-items-center">
      <label
        for="qualityThreshold"
        class="col-3 col-form-label"
        ngbTooltip="All bases in a selected region to repair must have at least this quality value or the region will be marked for repair. Set to zero for no repair based on base quality."
      >
        Base quality threshold:
      </label>
      <div class="col-2">
        <input
          type="number"
          id="qualityThreshold"
          formControlName="qualityThreshold"
          ngFormControlValidator
          class="form-control"
        />
      </div>
    </div>
  </bx-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
