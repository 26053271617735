import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CursorMessageService {
  private message$ = new BehaviorSubject<string | null>(null);
  private allowMessageDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getMessage(): Observable<string | null> {
    return this.allowMessageDisplay$.pipe(
      filter((allowDisplay) => allowDisplay),
      switchMap(() => this.message$),
    );
  }

  show(content: string) {
    this.message$.next(content);
  }

  setAllowMessageDisplay(value: boolean) {
    this.allowMessageDisplay$.next(value);
  }

  clear() {
    this.message$.next(null);
  }
}
