import { AppState } from '../../../core.store';
import { createSelector, select } from '@ngrx/store';
import { NgsGraphState } from './ngs-graph-data-store.reducer';
import { GraphId } from '../ngs-graphs.model';
import { pipe } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { allParamsValid } from './ngs-graph-data-store.effects';

export const selectNgsGraphData = (state: AppState) => state.ngsGraphData;

export const selectGraphParamsForNgsDocument = (id: string) =>
  createSelector(selectNgsGraphData, (state: NgsGraphState) => ({ ...state[id], id }));

export const selectCurrentSelectionForNgsDocument = (id: string) =>
  createSelector(selectGraphParamsForNgsDocument(id), ({ currentSelection, id }) => ({
    ...currentSelection,
    id,
  }));

export const selectDataWithSelectionForNgsDocument = <T extends GraphId>(id: string, graphId: T) =>
  pipe(
    select(selectGraphParamsForNgsDocument(id)),
    filter(
      ({ currentSelection }) =>
        currentSelection?.selectedGraph?.value &&
        allParamsValid(currentSelection.selectedGraph.value, currentSelection) &&
        currentSelection.selectedGraph.value === graphId,
    ),
    map(({ graphData, currentSelection }) => {
      const tableName = currentSelection.selectedTable.value.name;
      const graph: T = currentSelection.selectedGraph.value;
      const dataForTable = graphData[tableName];
      return {
        data: dataForTable && dataForTable[graph] ? dataForTable[graph].data : null,
        selection: currentSelection,
        updatedAt: dataForTable && dataForTable[graph] ? dataForTable[graph]?.updatedAt : null,
      };
    }),
    distinctUntilChanged((oldData, newData) => oldData?.updatedAt === newData?.updatedAt),
  );

export const selectDataForNgsDocument = <T extends GraphId>(id: string, graphId: T) =>
  pipe(
    selectDataWithSelectionForNgsDocument(id, graphId),
    map(({ data }) => data),
  );

export const selectGraphLoadingStateForNgsDocument = (id: string) =>
  createSelector(selectGraphParamsForNgsDocument(id), ({ loading }) => loading);
