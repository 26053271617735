import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { GridComponent, SelectionState } from '../../features/grid/grid.component';
import { IGridResource } from '../../features/grid/datasource/grid.resource';
import { ColDef } from '@ag-grid-community/core';
import { from, Observable } from 'rxjs';
import { IGridResourceResponse } from '../../../nucleus/services/models/response.model';
import { UserGroupsFacade } from './user-groups.facade';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilService } from '../../shared/util.service';
import { UserGroup } from '../../../nucleus/v2/groups-http.v2.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';
import { ToolstripComponent } from '../../shared/toolstrip/toolstrip.component';
import { ToolstripItemComponent } from '../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

@Component({
  selector: 'bx-user-groups',
  templateUrl: './user-groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SettingsBreadcrumbComponent, ToolstripComponent, ToolstripItemComponent, GridComponent],
})
export class UserGroupsComponent implements OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column w-100 h-100';

  @ViewChild(GridComponent) gridComponent: GridComponent;

  selection: SelectionState;
  datasource: IGridResource;
  columnDefs: ColDef[];

  private deleteConfirmationDialogRef: NgbModalRef;

  constructor(
    private userGroupsFacade: UserGroupsFacade,
    private utilService: UtilService,
    private dialogService: DialogService,
    private router: Router,
  ) {
    this.selection = new SelectionState();
    this.datasource = this.createDatasource();
    this.columnDefs = [
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        ...this.utilService.linkRenderer((cell) => ({
          text: cell.value,
          href: cell.data ? `/groups/${cell.data.id}` : null,
        })),
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.deleteConfirmationDialogRef) {
      this.deleteConfirmationDialogRef.dismiss();
    }
  }

  createGroup() {
    this.router.navigate(['/groups/new']);
  }

  deleteGroups() {
    const confirmationObservable: Observable<any> = from(
      this.selection.selectedRows.map((row) => row.id),
    ).pipe(
      mergeMap((id) => this.userGroupsFacade.deleteGroup(id)),
      tap(() => this.gridComponent.refresh()),
    );

    this.deleteConfirmationDialogRef = this.dialogService.showConfirmationDialog({
      title: `Permanently delete ${
        this.selection.noOfRowsSelected > 1
          ? 'all selected groups'
          : `${this.selection.firstRow.name}`
      }?`,
      confirmationButtonText: 'Delete',
      confirmationButtonColor: 'danger',
      confirmationObservable: confirmationObservable,
    });
  }

  editFirstSelectedGroup() {
    this.editGroup(this.selection.selectedRows[0]);
  }

  onSelectionChanged(selectionState: SelectionState) {
    this.selection = selectionState;
  }

  editGroup(row: UserGroup) {
    this.router.navigate(['/groups/', row.id]);
  }

  private createDatasource(): IGridResource {
    return {
      // Pagination is not yet supported for the get groups API thus the entire list is retrieved in one request.
      query: (): Observable<IGridResourceResponse<UserGroup>> => {
        return this.userGroupsFacade.getGroups().pipe(
          map((userGroups) => {
            return {
              data: userGroups,
              columns: [
                {
                  field: 'name',
                  colId: 'name',
                  headerName: 'Name',
                },
              ],
              metadata: {
                total: userGroups.length,
                limit: userGroups.length,
                offset: 0,
              },
            };
          }),
        );
      },
    };
  }
}
