import { AsyncPipe, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

export interface ZoomableChart {
  zoomIn(): void;
  zoomOut(): void;
  zoomToFit(performZoom: boolean): void;
  getZoomLevel(): Observable<number>;
}

@Component({
  selector: 'bx-graph-zoom',
  templateUrl: './graph-zoom.component.html',
  styleUrls: ['graph-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, MatIconModule, AsyncPipe, PercentPipe],
})
export class GraphZoomComponent implements OnInit {
  @Input() zoomControls: ZoomableChart;

  zoomLevel$: Observable<number>;

  ngOnInit() {
    this.zoomLevel$ = this.zoomControls.getZoomLevel();
  }
}
