import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { CamelCaseToTitleCasePipe } from '../../../shared/camelCaseToTitleCase.pipe';
import { ICellRendererParamsWithGridService } from '../../../features/grid/grid.interfaces';

@Component({
  selector: 'bx-user-status-renderer',
  templateUrl: './user-status-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CamelCaseToTitleCasePipe],
})
export class UserStatusRendererComponent implements AgRendererComponent {
  value = '';

  constructor() {}

  agInit(params: ICellRendererParamsWithGridService): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParamsWithGridService): boolean {
    this.value = params.getValue() ?? '';
    return true;
  }
}
