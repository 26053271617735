import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-account-completed',
  templateUrl: './account-completed.component.html',
  standalone: true,
  imports: [RouterLink],
})
export class AccountCompletedComponent {}
