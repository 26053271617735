import { FormStates } from './form-state.model';
import { createReducer, on } from '@ngrx/store';
import { setFormStates, updateFormState } from './form-state.actions';

export const initialState: FormStates = {};

export const formStateReducer = createReducer(
  initialState,
  on(updateFormState, (state, { name, formState }) => ({
    ...state,
    [name]: {
      options: formState.options,
    },
  })),
  on(setFormStates, (_, { formStates }) => formStates),
);
