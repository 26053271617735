import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private readonly faIconLibrary: FaIconLibrary,
  ) {}

  registerIcons() {
    // Register custom SVG icons for use with angular materials mat-icon component.
    this.registerIcon('folder');
    this.registerIcon('folder-shared');
    this.registerIcon('folder-readonly');
    this.registerIcon('experiment');
    this.registerIcon('experiment-shared');
    this.registerIcon('experiment-shared-readonly');
    this.registerIcon('primers-root');
    this.registerIcon('primer-set');
    this.registerIcon('database-root');
    this.registerIcon('database');
    this.registerIcon('database-readonly');
    this.registerIcon('database-feature');
    this.registerIcon('database-feature-readonly');
    this.registerIcon('database-linker');
    this.registerIcon('database-linker-readonly');
    this.registerIcon('database-scaffold');
    this.registerIcon('database-scaffold-readonly');
    this.registerIcon('database-master');
    this.registerIcon('database-master-readonly');
    this.registerIcon('database-unclassified');
    this.registerIcon('database-unclassified-readonly');
    this.registerIcon('users', 'people');
    this.registerIcon('schemes');
    this.registerIcon('live-help');
    this.registerIcon('notification');
    this.registerIcon('label');
    this.registerIcon('sync');
    this.registerIcon('uploads');
    this.registerIcon('move');
    this.registerIcon('grade');
    this.registerIcon('zoom-in');
    this.registerIcon('zoom-out');
    this.registerIcon('zoom-fit');
    // The FontAwesome icons below are licensed under the Creative Commons Attribution 4.0 International license
    // https://fontawesome.com/license
    this.registerIcon('fa-caret-left-solid');
    this.registerIcon('fa-caret-right-solid');
    this.registerIcon('fa-times-solid');
    this.registerIcon('us-flag');
    this.registerIcon('eu-flag');
    this.registerIcon('gears-solid');
    // SSO Icons
    this.registerSSOIcon('microsoft');
    this.registerSSOIcon('google');
    this.registerSSOIcon('luma');

    // Font Awesome icons
    this.faIconLibrary.addIcons(
      faCaretDown,
      faCaretUp,
      faCaretLeft,
      faCaretRight,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faChevronDown,
      faMinus,
      faPlus,
    );
  }

  /**
   * Registers custom SVG icons for use with angular materials mat-icon component.
   *
   * Example:
   * @code
   * <mat-icon [svgIcon]="experiment-shared"></mat-icon>
   *
   * @param iconName
   * @param fileName
   */
  private registerIcon(iconName: string, fileName?: string) {
    fileName = fileName || iconName;
    const url = this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/' + fileName + '.svg');
    this.iconRegistry.addSvgIcon(iconName, url);
  }

  private registerSSOIcon(iconName: string) {
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/sso-icons/' + iconName + '.svg',
    );
    this.iconRegistry.addSvgIcon(iconName, url);
  }
}
