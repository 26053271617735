import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, takeUntil } from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { selectOrganizationID } from '../../auth/auth.selectors';
import { AppState } from '../../core.store';
import { labelActions } from '../../organization-settings/organization-settings.actions';
import { selectLabelByNameAndColor } from '../../organization-settings/organization-settings.selectors';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { LabelValidators } from '../label-validators';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';

@Component({
  selector: 'bx-label-editor',
  templateUrl: './label-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LabelValidators],
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
  ],
})
export class LabelEditorComponent extends CleanUp {
  isCreating: boolean;
  organizationID: string;

  form = new BxFormGroup({
    labelName: new BxFormControl(null, this.labelValidators.nameValidators()),
    color: new BxFormControl('#000000', Validators.required),
  });

  constructor(
    private readonly store: Store<AppState>,
    private readonly labelValidators: LabelValidators,
    private readonly router: Router,
  ) {
    super();
    this.isCreating = router.url.endsWith('create');

    this.store
      .pipe(select(selectOrganizationID))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((organizationID) => (this.organizationID = organizationID));
  }

  onSubmit() {
    const { labelName, color } = this.form.getRawValue();
    this.store.dispatch(
      labelActions.create({
        name: labelName,
        organizationID: this.organizationID,
        data: { color, created: new Date() },
      }),
    );
    // Navigate back to labels page when creation is complete
    this.store
      .select(selectLabelByNameAndColor(labelName, color))
      .pipe(first((label) => !!label))
      .subscribe(() => this.router.navigateByUrl('/labels'));
  }
}
