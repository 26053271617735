@if (activity$ | async; as activity) {
  @if (activity.message) {
    <bx-toolstrip-item>
      <button
        class="btn btn-sm btn-primary"
        data-testid="tableIndexingSpinner"
        type="button"
        bx-spinner-button
        [spin]="true"
        [ngbTooltip]="tooltipContent"
        placement="bottom"
        container="body"
      >
        {{ activity.message }}
      </button>
    </bx-toolstrip-item>
  }
  <ng-template #tooltipContent><span [innerHTML]="activity.detail"></span></ng-template>
}
