@if (loading$ | async) {
  <bx-page-message>
    <bx-loading></bx-loading>
  </bx-page-message>
} @else {
  @for (imageURL of imageURLs$ | async; track imageURL) {
    <div class="position-relative">
      <img [src]="imageURL" class="position-absolute" />
    </div>
  }
}
