import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  initFileUploadTable,
  loadFileUploadTable,
  updateFileUpload,
  updateFileUploadByFileID,
  UploadActions,
} from './uploads.actions';
import { exhaustMap, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { UploadsTableHttpService } from '../../../../nucleus/v2/uploads-table-http.service';
import { State } from '../../files-table/files-table-store/files-table-state.model';
import { selectUploadsTable } from './uploads-table.selectors';
import { retryOnErrorWithDelay } from '../../../../bx-operators/retry-on-error-with-delay';

@Injectable()
export class FileUploadEffects {
  loadUploadTable$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadFileUploadTable.type),
      exhaustMap(() => {
        return this.uploadsTableHttpService.getUploadGroups().pipe(map(initFileUploadTable));
      }),
    ),
  );

  finaliseUploadGroupStatus = createEffect(() =>
    this.actions.pipe(
      ofType(updateFileUploadByFileID.type),
      withLatestFrom(this.store.pipe(select(selectUploadsTable))),
      filter(([action, fileUploadStore]) => {
        const uploadGroups = Object.values(fileUploadStore.entities);
        const uploadGroup = uploadGroups.find((group) =>
          group.fileIDs.includes(action.payload.fileID),
        );
        if (uploadGroup) {
          const totalFinished = fileUploadStore.uploadProgress[uploadGroup.id] || 0;
          return totalFinished === uploadGroup.fileIDs.length;
        } else {
          return false;
        }
      }),
      mergeMap(([action, fileUploadStore]) => {
        const uploadGroups = Object.values(fileUploadStore.entities);
        const uploadGroup = uploadGroups.find((group) =>
          group.fileIDs.includes(action.payload.fileID),
        );
        return this.uploadsTableHttpService.getUploadGroup(uploadGroup.id).pipe(
          map((group) => updateFileUpload(group)),
          retryOnErrorWithDelay(5000, 2, true),
        );
      }),
    ),
  );

  constructor(
    private actions: Actions<UploadActions>,
    private uploadsTableHttpService: UploadsTableHttpService,
    private store: Store<State>,
  ) {}
}
