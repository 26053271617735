import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-sequence-editing-conflict-dialog',
  templateUrl: './sequence-editing-conflict-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SequenceEditingConflictDialogComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal,
  ) {}

  /**
   * Reload sequence data by adding a random hash to query params to trigger a document re-fetch in viewer page.
   *
   * This is a semi-hacky way but probably one of the cleanest way to do document reload from within SV
   * since viewer page will automatically re-fetch the documents when the URL params changes. Naturally, this won't
   * work with pages that doesn't rely on URL parameters like files table. But we currently don't support editing
   * from within the files table.
   * */
  reloadSequence() {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          // This query name is intentionally long to avoid conflict with any query that may exist in the future.
          reloadSVFromConflictDialog: Math.random().toString(36).substring(2),
        },
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      })
      .then(() => this.activeModal.close());
  }
}
