import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NameSchemeEditorComponent } from '../name-scheme-editor/name-scheme-editor.component';
import { Router } from '@angular/router';
import { SettingsBreadcrumbComponent } from '../../../shared/breadcrumb/settings-breadcrumb.component';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { AsyncPipe } from '@angular/common';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// TODO Replace this with a more generic 'Forms' component wrapper for all forms.
@Component({
  selector: 'bx-name-schemes-new',
  templateUrl: './name-schemes-creator.component.html',
  styleUrls: ['./name-schemes-creator.component.scss'],
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    NameSchemeEditorComponent,
    MatLegacyFormFieldModule,
    MatLegacyButtonModule,
    MatLegacyProgressSpinnerModule,
    AsyncPipe,
  ],
})
export class NameSchemesCreatorComponent implements OnDestroy {
  creating: boolean;
  valid: boolean;
  readonly errorMessage$ = new Subject<string>();

  @ViewChild(NameSchemeEditorComponent, { static: true })
  nameSchemeEditor: NameSchemeEditorComponent;

  constructor(private router: Router) {}

  ngOnDestroy() {
    this.errorMessage$.complete();
  }

  validityChanged(event: boolean) {
    this.valid = event;
  }

  createScheme() {
    this.creating = true;
    this.nameSchemeEditor.createScheme();
  }

  cancel() {
    this.navigateBack();
  }

  private navigateBack() {
    this.router.navigate(['/name-schemes']);
  }
}
