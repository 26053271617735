import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SelectionState } from '../../../features/grid/grid.component';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { TrimEndsJobParametersV1 } from '../../../../nucleus/services/models/trimEndsOptions.model';
import { Subscription } from 'rxjs';
import { first, share, tap } from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  GenericDialogComponent,
  ModalElementType,
  ModalLayout,
} from '../generic-dialog/generic-dialog.component';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbCollapse, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-trim-ends',
  templateUrl: '../generic-dialog/generic-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
    NgbCollapse,
    NgClass,
    NgbTooltip,
    NgFormControlValidatorDirective,
    PipelineOutputComponent,
  ],
})
export class TrimEndsComponent
  extends GenericDialogComponent
  implements OnDestroy, OnInit, RunnableJobDialog
{
  title = 'Trim Ends';
  earlyRelease = false;
  knowledgeBaseArticle: string;

  private primersSub: Subscription;
  private readonly selected: SelectionState;

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private pipelineService: PipelineService,
  ) {
    super('trim-ends', PipelineFormID.TRIM_ENDS, TrimEndsModalLayout);
    this.selected = this.dialogData.selected;
  }

  ngOnInit() {
    const primers = this.getControls(TrimEndsModalLayout).find(
      (element) => element.type === ModalElementType.SELECT,
    ).id;
    this.primersSub = this.pipelineService.externalServices.primerSets
      .valueSource()
      .pipe(
        first(),
        tap((databases: SelectOption[]) => {
          // If there is already an existing formValue set for primers controls,
          // then check if it exists in the available options, otherwise set the form control
          // to the first primers database.
          const customDatabaseFormValue = this.form.get(primers).value;
          if (
            !customDatabaseFormValue ||
            !databases.find((option) => option.value === customDatabaseFormValue)
          ) {
            this.form.controls[primers].setValue(databases[0].value);
          }
          this.formDefaults[primers] = databases[0].value;

          const element = this.elements.get(primers);
          if (element) {
            element.value = databases.map((database) => ({
              value: database.value,
              label: database.displayName,
            }));
          }
        }),
        share(),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.primersSub.unsubscribe();
  }

  run() {
    const formValue = this.form.value;

    const parameters = {
      options: {
        optionValues: formValue,
        resultSuffix: '(Trimmed)',
      },
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: formValue.outputFolderName,
      },
    };

    delete parameters.options.optionValues['outputFolderName'];

    return new TrimEndsJobParametersV1(parameters);
  }
}

const TrimEndsModalLayout: ModalLayout = {
  sections: [
    {
      title: 'Trim By Primers',
      rows: [
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'primerTrim_primerScreening',
              label: 'Trim Primers:',
              value: false,
              size: 4,
            },
            {
              id: 'primerTrim_primerTrim_primers',
              type: ModalElementType.SELECT,
              size: 6,
              disable: [
                {
                  element: 'primerTrim_primerScreening',
                  state: false,
                },
              ],
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'primerTrim_primerTrim_allowMismatches',
              label: 'Allow Mismatches:',
              value: true,
              size: 4,
              tooltip: 'The maximum number of mismatches (including indels)',
              disable: [
                {
                  element: 'primerTrim_primerScreening',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'primerTrim_primerTrim_maxMismatches',
              value: 5,
              validators: {
                min: 1,
                max: 999999,
              },
              size: 3,
              disable: [
                {
                  element: 'primerTrim_primerScreening',
                  state: false,
                },
                {
                  element: 'primerTrim_primerTrim_allowMismatches',
                  state: false,
                },
              ],
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL,
              id: 'primerTrim_primerTrim_minLength',
              label: 'Minimum Match Length:',
              size: 4,
              tooltip:
                'When the primer extends past the end of the sequence, this is the minimum overlap of the primer with the' +
                ' sequence. Partial primers will only be found in the middle of the sequence if the missing part of the primer would' +
                ' cover a region trimmed for other reasons.',
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'primerTrim_primerTrim_minLength',
              value: 5,
              validators: {
                min: 1,
                max: 999999,
              },
              disable: [
                {
                  element: 'primerTrim_primerScreening',
                  state: false,
                },
              ],
              size: 3,
            },
          ],
        },
      ],
    },
    {
      title: 'Trim By Quality',
      rows: [
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'errorProbability_errorProbability',
              label: 'Error Probability Limit:',
              value: false,
              size: 4,
              tooltip:
                'Trim bases up until the point where trimming further bases will only improve the error rate by' +
                ' less than the limit.',
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'errorProbability_errorLimit',
              value: 0.05,
              validators: {
                min: 0,
                max: Number.MAX_VALUE,
              },
              size: 3,
              disable: [
                {
                  element: 'errorProbability_errorProbability',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.TEXT,
              value: '(Decrease to trim more)',
              size: 5,
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'lowQualityTrim_lowQuality',
              label: 'Maximum Low Quality Bases:',
              value: false,
              size: 4,
              tooltip:
                'Finds the shortest possible trims where the rest of the sequence has no more than this number' +
                ' of low quality bases',
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'lowQualityTrim_lowQualityLimit',
              value: 5,
              validators: {
                min: 1,
                max: 999999,
              },
              size: 3,
              disable: [
                {
                  element: 'lowQualityTrim_lowQuality',
                  state: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Trim By Ambiguities',
      rows: [
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'ambiguityTrim_ambiguity',
              label: 'Maximum Ambiguities:',
              value: false,
              size: 4,
              tooltip:
                'Finds the shortest possible trims where the rest of the sequence has no more than this number of ambiguities',
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'ambiguityTrim_ambiguityLimit',
              value: 5,
              validators: {
                min: 1,
                max: 999999,
              },
              size: 3,
              disable: [
                {
                  element: 'ambiguityTrim_ambiguity',
                  state: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'Trim Settings',
      rows: [
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'trimStart_startTrim',
              label: "Trim 5' End:",
              value: true,
              size: 4,
            },
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'trimStart_startTrimAtLeast',
              label: 'At least',
              value: false,
              disable: [
                {
                  element: 'trimStart_startTrim',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'trimStart_startTrimMinimum',
              value: 0,
              validators: {
                min: 0,
                max: Number.MAX_VALUE,
              },
              append: 'bp',
              size: 3,
              disable: [
                {
                  element: 'trimStart_startTrim',
                  state: false,
                },
                {
                  element: 'trimStart_startTrimAtLeast',
                  state: false,
                },
              ],
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'trimEnd_endTrim',
              label: "Trim 3' End:",
              value: true,
              size: 4,
            },
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'trimEnd_endTrimAtLeast',
              label: 'At least',
              value: false,
              disable: [
                {
                  element: 'trimEnd_endTrim',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'trimEnd_endTrimMinimum',
              value: 0,
              validators: {
                min: 0,
                max: Number.MAX_VALUE,
              },
              append: 'bp',
              size: 3,
              disable: [
                {
                  element: 'trimEnd_endTrim',
                  state: false,
                },
                {
                  element: 'trimEnd_endTrimAtLeast',
                  state: false,
                },
              ],
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'minLength_use',
              label: 'Minimum length after trim:',
              value: false,
              size: 4,
              tooltip:
                'For paired reads, if either read is too short, then both reads are discarded.',
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'minLength_minLength',
              value: 20,
              validators: {
                min: 0,
                max: 1000,
              },
              size: 3,
              disable: [
                {
                  element: 'minLength_use',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.TEXT,
              value: '(Discard shorter reads)',
              size: 5,
            },
          ],
        },
        {
          elements: [
            {
              type: ModalElementType.LABEL_WITH_CHECKBOX,
              id: 'maxLength_use',
              label: 'Maximum length after trim:',
              value: false,
              size: 4,
            },
            {
              type: ModalElementType.NUMBER_INPUT,
              id: 'maxLength_maxLength',
              value: 1000,
              validators: {
                min: 0,
                max: 999999,
              },
              size: 3,
              disable: [
                {
                  element: 'maxLength_use',
                  state: false,
                },
              ],
            },
            {
              type: ModalElementType.TEXT,
              value: '(Trim excess from 3` end)',
              size: 5,
            },
          ],
        },
      ],
    },
    {
      title: 'Output Options',
      rows: [],
    },
  ],
};
