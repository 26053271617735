import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnotatedPluginDocument } from '../geneious';
import { IGridResource } from '../../features/grid/datasource/grid.resource';
import { DocumentTable } from '../../../nucleus/services/documentService/types';
import { StepperComponent } from '../../shared/stepper/stepper.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { BXStep } from '../../shared/stepper';
import { DocumentServiceResource } from '../../../nucleus/services/documentService/document-service.resource';
import { AsyncPipe } from '@angular/common';
import { StepperStepsComponent } from '../../shared/stepper/stepper-steps/stepper-steps.component';
import { StepComponent } from '../../shared/stepper/step/step.component';
import { AssayDataFilePickerComponent } from './assay-data-file-picker/assay-data-file-picker.component';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { AssayDataAddFormComponent } from './assay-data-add-form/assay-data-add-form.component';

@Component({
  selector: 'bx-assay-data-v2',
  templateUrl: './assay-data-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    StepperStepsComponent,
    StepperComponent,
    StepComponent,
    AssayDataFilePickerComponent,
    SpinnerButtonComponent,
    AssayDataAddFormComponent,
    AsyncPipe,
  ],
})
export class AssayDataV2Component implements OnInit, AssayDataDialogState {
  document: AnnotatedPluginDocument;
  documentTable: DocumentTable;
  resource: DocumentServiceResource;

  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;
  steps$: Observable<BXStep[]>;
  selectedFiles$: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

  isFetchingAssayData$ = new BehaviorSubject(false);

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.steps$ = this.stepper.stepsChanges.asObservable();
  }

  close() {
    this.activeModal.dismiss();
  }

  nextStep() {
    this.stepper.selectedIndex = Math.min(
      this.stepper.selectedIndex + 1,
      this.stepper.steps.length - 1,
    );
  }

  handleFetchStart() {
    this.isFetchingAssayData$.next(true);
  }

  handleFetchEnd() {
    this.isFetchingAssayData$.next(false);
  }
}

// Interface for the state that gets passed to AssayDataV2Component when opening it in a modal.
export interface AssayDataDialogState {
  document: AnnotatedPluginDocument;
  resource: IGridResource;
  // Required for the new document service.
  documentTable: DocumentTable;
}
