import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FeedbackHttpV2NucleusService,
  SubmitFeedbackResponse,
} from '../../../nucleus/services/feedback.http-v2.nucleus-service';
import { Feedback } from '../../../nucleus/services/models/feedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private feedbackV2HttpService: FeedbackHttpV2NucleusService) {}

  submitFeedback(feedback: Feedback): Observable<SubmitFeedbackResponse> {
    return this.feedbackV2HttpService.submitFeedback(feedback);
  }
}
